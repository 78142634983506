import { Component, OnInit } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import { Router } from '@angular/router';
import { UnderSupplierHttpService } from '../state/services/undersupplier.http.service';
import { UnderSupplierService } from '../state/services/undersupplier.service';
import { TranslateService } from '@ngx-translate/core';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-undersupplier-list',
  templateUrl: './undersupplier-list.component.html',
  styleUrls: ['./undersupplier-list.component.scss']
})
export class UndersupplierListComponent implements OnInit {

  header: any;
  supplierList: any = [];
  supplierdatacount: any;
  actions: any = ['editdata', 'delete'];
  submitted = false;
  filterFields: any = [];
  suppliers: any = [];
  supplierID: any = "";
  customer: any = [];
  customerID: any = "";
  undersupplierName = "";
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  activePage: number = 1;
  rowactivePage: number = 1;
  resetStatus: boolean = false;
  searchCriteria: any = {};
  filterData: any = {};
  passFilterresult: any = [];
  commonRemoveFilter: any;
  showLoader: Boolean = false;
  tableName: string = 'undersupplierList';
  webshopDetail: any;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'Name',
      header: 'Under Supplier Name'
    },
    {
      primaryKey: 'QualityLevel',
      header: '	Under Supplier quality level',
      format: 'supplierquality'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];

  constructor(private undersupplierHttpService: UnderSupplierHttpService, private undersupplierService: UnderSupplierService,
    private apiService: ApiService, private productHttpService: ProductHttpService, private translate: TranslateService, private router: Router, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.getCustomerSupplierlist();
    //  this.getCustomerlist()
  }

  ngOnInit() {
    this.header = 'UnderSupplier Details'; // Multilingual to be implemented
    const searchkey = sessionStorage.getItem('searchUnderSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      sessionStorage.setItem('searchUnderSupplierKey', JSON.stringify(this.searchCriteria));
    }
    this.onLoadUnderSuppliers();
  }

  /** Customer Based Supplier List in filter section dropdown */
  getCustomerSupplierlist() {
    this.undersupplierHttpService.getCustomerUnderSupplier(this.webshopDetail.CustomerID).subscribe(
      (data: any) => {
        if (data) {
          this.suppliers = [];
          this.suppliers.push({ label: this.translate.instant('USERBUDGETEDIT.SELECTSUPPLIER'), value: '' });
          data.ItemsCollection.forEach(element => {
            const langVal = {
              value: element.SupplierID,
              label: element.Name,
            };
            this.suppliers.push(langVal);
          });
          this.setfilterData();
        }
      },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  // Customer data list
  getCustomerlist() {
    this.undersupplierHttpService.getcustomerlist().subscribe(
      (data: any) => {
        if (data) {
          this.customer = [];
          this.customer.push({ label: this.translate.instant('USERBUDGETEDIT.SELECTCUSTOMER'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.CustomerID,
              label: element.Name,
            };
            this.customer.push(langVal);
          });
        }
      },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  onLoadUnderSuppliers() {
    const searchkey = sessionStorage.getItem('searchUnderSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.getUnderSupplierList(JSON.parse(searchkey));
    } else {
      this.searchCriteria = {
        SupplierID: '',
        name: '',
        customerID: this.webshopDetail.CustomerID,
        pageSize: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchUnderSupplierKey', JSON.stringify(this.searchCriteria));
      // this.userService.loadUsers(this.searchCriteria);
      this.getUnderSupplierList(this.searchCriteria);
    }
  }

  /** Supplier Table List Data */
  getUnderSupplierList(searchCriteria?) {
    const searchdata = {
      activePage: this.activePage,
      pageSize: (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10,
      customerID: this.webshopDetail.CustomerID,
      undersupplierId: (searchCriteria.supplierID !== null && searchCriteria.supplierID !== undefined) ? searchCriteria.supplierID : '',
      name: (searchCriteria.name !== null && searchCriteria.name !== undefined) ? searchCriteria.name : '',
    };
    this.showLoader = true;
    this.undersupplierHttpService.getUnderSupplierList(searchdata).subscribe((datas: any) => {
      if (datas) {
        this.showLoader = false;
        this.supplierList = [];
        this.supplierList = datas.ItemsCollection;
        this.supplierdatacount = datas.TotalItemsCount;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }



  /** Paginaion code Section Functions */
  getPerPage(event) {
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(this.searchCriteria);
  }

  paginationNum(event) {
    this.activePage = event;
    const searchkey = sessionStorage.getItem('searchUnderSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    this.searchCriteria.activePage = event;
    this.perPagedata = this.searchCriteria.pageIndex;
    this.getUnderSupplierList(this.searchCriteria);
  }


  /** Filter code Section Functions */
  setfilterData() {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Name',
        type: 'text',
        class: 'col-md-12',
        required: false,
        key: 'name',
        id: 'name',
        filterkey: 'name',
        selected: this.searchCriteria.name
      },

      {
        label: 'Select by Supplier',
        type: 'select',
        class: 'col-md-12',
        key: 'supplier',
        data: this.suppliers,
        selected: this.supplierID,
        required: false,
        filterkey: 'supplier'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      },
    ];

    const searchResult = sessionStorage.getItem('searchUnderSupplierResult')
    if (searchResult !== null) {
      const searchUnderSupplierResult = JSON.parse(sessionStorage.getItem('searchUnderSupplierResult'))
      /** To Prefill Webshop dropdown value(based on Login) */
      // tslint:disable-next-line:max-line-length
      const getIndex = searchUnderSupplierResult.findIndex(ind => ind.key === 'supplier');
      this.filterFields[getIndex].selected = searchUnderSupplierResult[getIndex].selected;
    }
  }

  getSelectData(data) {
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        supplierID: data.supplier !== undefined && data.supplier.selectMethod === 'supplier' ? data.supplier.value : '',
        name: data.name !== undefined && data.name.selectMethod === 'name' ? data.name.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    } else {
      this.searchCriteria = {
        supplierID: '',
        name: '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }
    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'name') {
        element.selected = this.searchCriteria.name;
      } else if (element.filterkey === 'supplier') {
        element.selected = this.searchCriteria.supplierID;
      }
    });
  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    this.getUnderSupplierList(this.filterData);
    sessionStorage.setItem('searchUnderSupplierKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchUnderSupplierResult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    // this.filterFields.forEach(element => {
    //   if (element.filterkey === 'supplier') {
    //     element.selected = '';
    //   }
    //   if (element.filterkey === 'name') {
    //     element.selected = '';
    //   }
    // });
    this.resetStatus = true;
    this.getSelectData(undefined);
    this.getPerPage(10);
    sessionStorage.setItem('searchUnderSupplierKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchUnderSupplierResult', JSON.stringify(this.filterFields));
    this.setfilterData();
    this.onLoadUnderSuppliers();
  }


  /** Remove Filter Tags Section Code */
  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchkey = sessionStorage.getItem('searchUnderSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    if (val !== undefined) {
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = this.webshopDetail.CustomerID;
      } else if (val.key === "name") {
        this.searchCriteria.name = '';
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
      });
      sessionStorage.setItem('searchUnderSupplierKey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchUnderSupplierResult', JSON.stringify(this.filterFields));
      this.getUnderSupplierList(this.filterData);
    }
  }

  getFilterResult(filterresult) {
    this.passFilterresult = filterresult;
  }

  /** Table Actions(Edit, Delete, View, Add) Functions */
  addUnderSupplier(event: boolean) {
    this.submitted = event;
  }

  viewUnderSupplier(event: boolean) {
    this.submitted = event;
  }

  editUnderSupplier(data: any) {
    if (data !== '') {
      const searchkey = sessionStorage.getItem('searchUnderSupplierKey');
      if (searchkey !== null && searchkey !== '[object Object]') {
        this.searchCriteria = JSON.parse(searchkey);
        this.perPagedata = 10;
        this.searchCriteria.pageIndex = 10;
        this.resetStatus = true;
        sessionStorage.setItem('searchUnderSupplierKey', JSON.stringify(this.searchCriteria));
      }
      data.undersupplierEditPage = true;
      this.router.navigate(['/admin-undersupplier-edit'], { state: { data }, replaceUrl: false });
    }
  }

  deleteUnderSupplier(deletedData: any) {
    this.showLoader = true;
    this.undersupplierHttpService.deleteUnderSupplier(deletedData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.toast.success('Undersupplier deleted successfully');
        this.getCustomerSupplierlist();
        this.onLoadUnderSuppliers();
      } else {
        this.toast.error('Undersupplier cannot be deleted');
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);
      });
  }
  /** Page Reedirect Functions */
  pageredirect() {
    this.router.navigate(['/admin-undersupplier-add'], { replaceUrl: true });
  }

  ratingUpdate(data) {
    this.showLoader = true;
    this.undersupplierHttpService.addUnderSupplier(data).subscribe((datas: any) => {
      this.showLoader = false;
      this.toast.success('Supplier Rating updated successfully');
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }
}
