import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../state/services/category.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';

@Component({
  selector: 'rewardkart-admin-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})

export class CategoryEditComponent implements OnInit {

  header: any;
  showCategoryEditpage = false;
  categoryList: any = [];
  submitted = false;
  tableEvent: any;
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy'
  };

  categoryData: any = {};

  constructor(private categoryService: CategoryService, private linkTableService: LinkTableService, private router: Router) {
  }

  ngOnInit() {
    this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
      if (data) {
        this.categoryData = data;
      }
    });
  }

  goBack(event: boolean) {

  }

  goNext(event: boolean) {
  }

  enableDateField() {
    this.categoryData.IsVisible = this.categoryData.IsVisible = true ? false : true;
  }

  doSave() {
    this.categoryService.getCategoryUpdateSuccess().subscribe(data => {
      if (data) {
        this.categoryService.loadCategorys(1);
        this.router.navigate(['/admin-category-list'], { replaceUrl: false });
      }
    });
  }

  ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }
}
