import { Block } from '../models/index';
import { BlockActions } from '../actions/index';

export interface BlockState {
    blocks: Block[];
    updateBlockSuccess: any;
}

export const initialState: BlockState = {
    blocks: null,
    updateBlockSuccess: null
};

export function blockReducers(state: BlockState = initialState, action: BlockActions.All) {
    switch (action.type) {
        case BlockActions.LOAD_BLOCKS_SUCCESS:
            return Object.assign({}, state, { blocks: action.payload });

        case BlockActions.SAVE_BLOCK_SUCCESS:
            return Object.assign({}, state, { updateBlockSuccess: action.payload });

        default: {
            return state;
        }
    }
}
