import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ProductService } from '../../state/services/product.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-product-properties',
  templateUrl: './product-properties.component.html',
  styleUrls: ['./product-properties.component.scss']
})
export class ProductPropertiesComponent implements OnInit, OnChanges {
  selectlabel: any;
  productPropertiesForm: FormGroup;
  items: FormArray;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  optionsSelectColor: any = [];
  model: any;
  propertyName: any;
  productColors: any = [];
  selectedColor: any;
  propertyOptionValue: any = [];
  @Input() productDetails: any;
  @Input() loadApi: any;
  @Output() sendProductPropertyDetails = new EventEmitter<any>();
  optionsSelectPropertyOption: any = [];
  optionsSelectProductOptions: any = [];
  resetManagePropertiesModal: Boolean = false
  selectedProperty: any;
  productPropertyInfo: any;
  showProductOptions: boolean;
  showManageProperties: boolean;
  productProperties: any;
  editPropertyIndex: any;
  productPropertiesValue: any = [];
  deleteDataIndex: any;
  deleteData: any;
  loadManageApi: any;
  loadApiFlag: Boolean = false
  showManagePropertiesModal: Boolean = false;
  showOptionModal: Boolean = false;
  resultMessage: any;
  searchKey: any;
  propertyOptions: any[];
  optLabel: any;
  optValue: any;
  obja: any
  arrayStore: any;
  popupList: any;
  dropdownData: any = {};
  constructor(public formBuilder: FormBuilder,
    public toast: ToastService,
    public productService: ProductService) {
    this.loadManageApi = {
      manageProp: false
    }
  }



  ngOnChanges() {
    if (this.loadApi === true) {
      this.clearFormItems();
      this.setProductProperties()
    }
    // if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
    //   this.loadApiFlag = true
    //   this.getProductProperties();
    // }
  }

  resetDropDown() {
    this.getProductProperties()
  }

  ngOnInit() {
    this.loadForm();
    this.setProductProperties();
    this.getPropertyValues();

    this.productPropertiesForm.valueChanges.subscribe(() => {
      this.getPropertyValues();
    });

    this.getProductProperties();

    this.popupList = {
      apiurl: 'all/ProductPropertiesWIthPagination',
      key: 'ProductPropertyID',
      label: 'Properties',
      autoComplete: {
        apiUrl: 'AutoCompleteProductProperties',
        searchKey: 'name'
      }
    }
  }

  loadForm() {
    this.productPropertiesForm = this.formBuilder.group({
      propertyName: ['', Validators.required],
      items: this.formBuilder.array([]),
      options: ['', Validators.required],
      moq: ['', Validators.required],
      optionalForBuyer: [false, Validators.required],
    })
    this.items = this.productPropertiesForm.get('items') as FormArray;
  }

  clearFormItems() {
    const control = <FormArray>this.productPropertiesForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  searchList() {
    this.items.value.filter((data, index) => {
      let value = (data.propertyName).toLowerCase();
      let searchData = (this.searchKey).toLowerCase();
      if (((value).includes(searchData))) {
        data.search = 'show';
      }
      else {
        data.search = 'hide';
      }
    });
    const noresult = this.items.value.filter(get => get.search === 'show');
    this.resultMessage = noresult;
  }


  resetFilter() {
    this.searchKey = '';
    this.items.value.filter((data, index) => {
      data.search = 'show';
    });
    this.resultMessage = this.items;
  }

  createItem(propertyVal?): FormGroup {
    return this.formBuilder.group({
      propertyName: propertyVal ? propertyVal.propertyName : '',
      propertyOptionsName: propertyVal ? propertyVal.propertyOptionsName : '',
      data: propertyVal ? propertyVal.data : '',
      status: propertyVal ? propertyVal.status : '',
      moq: propertyVal ? propertyVal.moq : '',
    });
  }
  lastIndexRemove() {

  }
  setProductProperties() {

    if (this.productDetails.ProductProperties !== undefined) {
      if (this.productDetails.ProductProperties.length > 0) {
        this.productDetails.ProductProperties.forEach(item => {
          let name = '';
          let moq = '';
          if (item.ProductOptions.length > 0) {
            item.ProductOptions.forEach(val => {
              name += val.Name + '<br>';
              moq += val.MOQ + '<br>'
              // name.toString().replace(/,/g, "");
            });
          }
          const properties = {
            propertyName: item.Name,
            // propertyOptionsName: name.substring(0, name.length - 2),
            propertyOptionsName: name,
            data: item,
            // moq: moq.substring(0, moq.length - 2),
            moq: moq,
            status: 'saved'
          }
          this.items.push(this.createItem(properties));
        })
      }

    }

  }

  getProductProperties() {
    this.productService.loadProductProperties();
    this.productService.getProductProperties().subscribe((data) => {
      if (data !== null) {
        this.productProperties = data;
        const optionsSelectPropertyOption = []
        data.forEach(element => {
          const colorVal = {
            value: element.ProductPropertyID,
            label: element.Translations[0].PropertyValue,
            data: element
          };
          optionsSelectPropertyOption.push(colorVal);
        });
        this.optionsSelectPropertyOption = optionsSelectPropertyOption;
      }
    });
  }
  getPropertyValues() {
    this.productPropertiesValue = [];
    this.productPropertiesForm.value.items.forEach((data) => {
      this.productPropertiesValue.push(data.data);
      this.sendProductPropertyDetails.emit(this.productPropertiesValue);
    })
  }

  addProductProperties() {
    let isPropertyExists = false;
    if (this.selectedProperty !== undefined && Object.keys(this.selectedProperty).length !== 0 &&
      this.productPropertiesForm.value.moq !== '' && this.productPropertiesForm.value.options !== '') {
      this.productPropertiesForm.get('items')['controls'].forEach(data => {
        if (data.value.data.ProductPropertyID == this.selectedProperty.data.ProductPropertyID && data.value.data.Action != "Delete") {
          this.toast.error('Property already exists');
          this.selectedProperty = {}
          this.productPropertiesForm.controls['propertyName'].setValue('');
          this.productPropertiesForm.controls['options'].setValue('');
          this.productPropertiesForm.controls['moq'].setValue('');
          this.productPropertiesForm.controls['optionalForBuyer'].setValue(false);
          isPropertyExists = true;
        }
      });
      this.arrayStore = []
      this.obja = {
        ProductOptionID: this.productPropertiesForm.value.options,
        MOQ: this.productPropertiesForm.value.moq,
        isDefault: this.productPropertiesForm.value.optionalForBuyer,
        Name: this.optLabel,
        Action: "Insert"
      }
      this.arrayStore.push(this.obja)
      if (!isPropertyExists) {
        const options = {
          propertyName: this.selectedProperty.label,
          propertyOptionsName: this.optLabel,
          data: {
            Name: this.selectedProperty.label,
            Action: "Insert",
            ProductPropertyID: this.selectedProperty.data.ProductPropertyID,
            ProductOptions: this.arrayStore,
          },
          moq: this.productPropertiesForm.value.moq,
          optionalForBuyer: this.productPropertiesForm.value.optionalForBuyer,
          status: 'added'
        }

        this.items.push(this.createItem(options));
        this.selectedProperty = {};
        this.productPropertiesForm.controls.propertyName.reset();
        this.getPropertyValues();
        this.productPropertiesForm.controls['options'].setValue('');
        this.productPropertiesForm.controls['moq'].setValue('');
        this.productPropertiesForm.controls['optionalForBuyer'].setValue(false);
        this.propertyOptions = []
      }
    }
    else {
      this.toast.error('Please fill the mandatory fields');
    }


  }


  resetManageProperties(event) {
    this.resetManagePropertiesModal = true
    setTimeout(() => {
      this.resetManagePropertiesModal = false
    }, 1000)
  }

  getPropertyOptionValues(value) {
    this.selectedProperty = value;
    const arraylists = this.selectedProperty['data']['ProductOptions']
    this.propertyOptions = []
    arraylists.forEach((item) => {
      const check = item.Translations.filter(data => data.LanguageCode === 'en');
      const option = {
        value: item.ProductOptionID,
        label: check[0].PropertyValue,
        data: item
      }
      this.propertyOptions.push(option);
    })
  }


  getSelectedPropertyValue(index) {
    // this.setProductProperties();
    this.showProductOptions = true;
    this.editPropertyIndex = index;
    this.productPropertyInfo = this.productPropertiesForm.value['items'][index];
  }



  getOptionsList(val) {
    let options: string = "";
    let moqoptions: string = "";
    val.forEach((item) => {
      if (item.Action !== "Delete") {
        options += item.Name + '<br>';
        moqoptions += item.MOQ + '<br>';
        // options.replace(/,+/g,",").replace(/(,\s*$)|(^,*)/, "");
      }
    })
    this.productPropertiesForm.value['items'][this.editPropertyIndex].ProductOptions = val;
    this.productPropertiesForm.value['items'][this.editPropertyIndex].data.ProductOptions = val;
    this.productPropertiesForm.controls['items']['controls'][this.editPropertyIndex].controls.propertyOptionsName.setValue(options.substring(0, options.length - 2));
    this.productPropertiesForm.controls['items']['controls'][this.editPropertyIndex].controls.moq.setValue(moqoptions.substring(0, moqoptions.length - 2));
    this.getPropertyValues();
  }



  deleteProductProperties(productProperties, index) {
    this.deleteData = {
      productProperty: productProperties,
      index: index
    }
    this.deleteModal.show();
  }

  getDeleteStatus(status) {

    if (status) {
      if (this.deleteData.productProperty.items.value[this.deleteData.index].status == 'saved') {
        this.deleteData.productProperty.items.controls[this.deleteData.index].controls.status.value = 'deleted';
        const deleteData = {
          ProductPropertyID: this.deleteData.productProperty.items.value[this.deleteData.index].data.ProductPropertyID,
          Name: this.deleteData.productProperty.items.value[this.deleteData.index].data.Name,
          ProductOptions: this.deleteData.productProperty.items.value[this.deleteData.index].data.ProductOptions,
          Action: "Delete",
        }
        this.deleteData.productProperty.items.value[this.deleteData.index].data = deleteData;
      } else if (this.deleteData.productProperty.items.value[this.deleteData.index].status == 'added') {
        this.deleteData.productProperty.items.removeAt(this.deleteData.index);
      }
      this.getPropertyValues();
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }
  getOptionValue(value) {
    this.optLabel = value.label
    this.optValue = value.value

  }


  // getPropertyOptionValues(value) {
  //   this.selectedProperty = value;
  // }


  showProductOptionsFun() {
    this.showProductOptions = false;
  }

  checkHeaderRequired() {
    let value = false;
    this.productPropertiesForm.get('items')['controls'].forEach((data) => {
      if (data.controls.status.value != 'deleted') {
        value = true;
      }
    });
    return value;
  }


  getSelectedDropdown(dropdown) {

    this.selectedProperty = {
      data: dropdown,
      label: dropdown.Name,
      value: dropdown.ProductPropertyID
    }
    const arraylists = this.selectedProperty['data']['ProductOptions']
    this.propertyOptions = [{ label: 'Select option', value: '' }]
    arraylists.forEach((item) => {
      const check = item.Translations.filter(data => data.LanguageCode === 'en');
      const option = {
        value: item.ProductOptionID,
        label: check[0].PropertyValue,
        data: item
      }
      this.propertyOptions.push(option);
    })
  }

  resetDropdownPopup() {
    this.selectedProperty = {};
    this.productPropertiesForm.controls.propertyName.reset();
    this.getPropertyValues();

    this.productPropertiesForm.controls['options'].setValue('');
    this.productPropertiesForm.controls['moq'].setValue('');
    this.productPropertiesForm.controls['optionalForBuyer'].setValue(false);
    this.propertyOptions = [];
  }

  setOptionalForBuyer(data) {
    this.productPropertiesForm.patchValue({
      optionalForBuyer: data.checked
    })
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
