import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'appOrderBy'
})
export class OrderBy implements PipeTransform {
  transform(array: Array<string>, args: string): Array<string> {
    array.sort((a: any, b: any) => {
      if (a[args] != undefined && b[args] != undefined) {
        let X = a[args].toLowerCase();
        let Y = b[args].toLowerCase();
        if (X < Y) {
          return -1;
        } else if (X >= Y) {
          return 1;
        }
      }
    });
    return array;
  }
}