import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { WebService } from 'src/app/restful-service/web.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-category-addedit',
  templateUrl: './category-addedit.component.html',
  styleUrls: ['./category-addedit.component.scss']
})
export class CategoryAddeditComponent implements OnInit, OnChanges {
  // @ViewChild('myInput', { static: true }) myInputVariable: ElementRef;
  // @ViewChild('myInputicon', { static: true }) myInputicon: ElementRef;
  @Output() closeModal = new EventEmitter<any>();
  @Output() toggleModal = new EventEmitter<any>();
  @Input('resetFormData') resetFormData: Boolean;
  @Input() categoryName: any;
  @Input() customerID: any;
  @Input() parentID: any;
  @Input() getData: any;
  categoryForm: FormGroup;
  selectedLang: any = 'en';
  languageOption: any = [];
  translateForm: any = [];
  translateFormDesc: any = [];
  translateFormMetaDesc: any = [];
  translateFormItems: any = [];
  translateFormItemsDescr: any = [];
  translateItemMetaDescr: any = [];
  setImg: boolean = false;
  setImgIcon: boolean = false;
  getImgName: any;
  getImgIcon: any;
  previewFileUrlIcon: string;
  public previewFileUrl;
  totaldata: any;
  imgControl: any;
  imgControlIcon: any;
  iconType: any;
  imgType: any;
  imgResponse: any;
  imgName: boolean;
  imgNameicon: boolean;
  imgResponseIcon: any;
  editName: Boolean = false;
  catName: any = '';
  constructor(public apiService: ApiService, public formBuilder: FormBuilder, public categoryService: CategoryHttpService, public toast: ToastService, public webService: WebService, private translate: TranslateService) { }

  ngOnInit() {
    this.buildForm();
    this.setFormControlName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.resetFormData && (changes.resetFormData.currentValue !== changes.resetFormData.previousValue)) || !this.getData) {
      this.resetForm();
      if (this.categoryForm) {
        this.categoryForm.patchValue({
          language: 'en'
        });
      }

      this.selectedLang = 'en';
      this.editName = false;
    }
    if (this.getData) {
      this.catName = this.getData.Name;
      this.categoryForm.patchValue({
        language: 'en'
      });
      this.selectedLang = 'en';
      this.editName = true;
      this.getDatas();
    }
  }

  getCurrentLang(lang) {
    this.selectedLang = lang.value;
  }

  //add category
  buildForm() {
    let getlang = this.apiService.getLanguages();
    let translationFieldJson = [
      {
        formcontrolname: 'CategoryName',
        label: 'Name',
        PropertyName: "CategoryName",
      }
    ];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.Code };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })

    //descrition form builder
    let translationFieldJsondesc = [
      {
        formcontrolname: 'CategoryDescription',
        label: 'Name',
        PropertyName: "CategoryDescription",
      }
    ];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.Code };
      // this.languageOption.push(langData)
      translationFieldJsondesc.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateFormDesc.push(formJson)
      });
    })

    //metadescrtions
    let translationFieldJsonMeta = [
      {
        formcontrolname: 'CategoryMetaDescription',
        label: 'Name',
        PropertyName: "CategoryMetaDescription",
      }
    ];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.Code };
      // this.languageOption.push(langData)
      translationFieldJsonMeta.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateFormMetaDesc.push(formJson)
      });
    })

    let group: any = {
      language: new FormControl(1),
      items: this.formBuilder.array([]),
      ImageName: new FormControl(),
      IconName: new FormControl()
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.translateFormDesc.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.translateFormMetaDesc.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.categoryForm = new FormGroup(group);
  }

  update() {
    if ((this.categoryForm.value.CategoryNameen === '' || this.categoryForm.value.CategoryNameen === null) && (!this.previewFileUrlIcon) && (!this.previewFileUrl)) {
      this.translate.get('CATEGORIES.PLEASE_FILL_MANDATORY_FIELDS').subscribe((res: string) => {
        this.toast.error(res);
      });
      return null
    }
    if (this.categoryForm.value.CategoryNameen === '' || this.categoryForm.value.CategoryNameen === null) {
      this.translate.get('CATEGORIES.PLEASE_FILL_NAME_FOR_ENGLISH').subscribe((res: string) => {
        this.toast.error(res);
      });
      return null
    }
    let translations = [];
    let flag = true;
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: item.TranslationID,
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,

        ObjectID: item.ObjectID,
        ObjectType: "Categories",
        PropertyName: item.PropertyName,
        PropertyValue: this.categoryForm.value[item.formcontrolname],
        Action: "Update"
      }
      if (data.PropertyValue && flag) {
        flag = false
      }
      translations.push(data);
    })
    //descriptions
    this.translateFormItemsDescr.forEach(item => {
      const data = {
        TranslationID: item.TranslationID,
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: item.ObjectID,
        ObjectType: "Categories",
        PropertyName: item.PropertyName,
        PropertyValue: this.categoryForm.value[item.formcontrolname],
        Action: "Update"
      }
      if (data.PropertyValue && flag) {
        flag = false
      }
      translations.push(data);
    })
    //metadiscriptions
    this.translateItemMetaDescr.forEach(item => {
      const data = {
        TranslationID: item.TranslationID,
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: item.ObjectID,
        ObjectType: "Categories",
        PropertyName: item.PropertyName,
        PropertyValue: this.categoryForm.value[item.formcontrolname],
        Action: "Update"
      }
      if (data.PropertyValue && flag) {
        flag = false
      }
      translations.push(data);
    })

    let requestData: any = {
      Action: "update",
      "isDeleted": false,
      ParentID: this.getData.ParentID ? this.getData.ParentID : '',
      ImageName: this.getImgName,
      IconName: this.getImgIcon,
      Translations: translations
    }
    if (this.customerID !== null && this.customerID !== undefined && this.customerID !== '') {
      requestData.CustomerID = this.customerID
    }

    this.categoryService.updateCategory(requestData).subscribe((data) => {
      if (data !== null) {
        this.translate.get('CATEGORIES.CATEGORY_UPDATED_SUCCESSFULLY').subscribe((res: string) => {
          this.toast.success(res);
        });
        // this.myInputVariable.nativeElement.value = "";
        // this.myInputicon.nativeElement.value = "";
        // this.populateCategoryList()
        // this.hideModel();
        this.closeModal.emit(true);
      }
    },
      error => {
        //this.toast.error('Please fill mandatory  fields')
      }
    );
  }

  resetForm() {
    this.previewFileUrl = '';
    this.previewFileUrlIcon = '';
    this.setImgIcon = false;
    this.setImg = false;
    if (this.categoryForm) this.categoryForm.reset();
  }

  setFormControlName() {
    let getlang = this.apiService.getLanguages();
    this.translateFormItems = [];
    this.translateFormItemsDescr = []
    this.translateItemMetaDescr = []
    getlang.forEach(lang => {
      // let isFound = stockVal.Translations.find(data => data.LanguageCode == lang.Code);
      let formJson;
      formJson = {
        formcontrolname: 'CategoryName' + lang.Code,
        translationID: 'TranslationID' + lang.Code,
        objectID: 'ObjectID' + lang.Code,
        class: lang.Code,
        name: '',
        LanguageID: lang.LanguageID,
        LanguageCode: lang.Code,
        PropertyName: 'CategoryName',
        PropertyValue: '',
        TranslationID: '',
        ObjectID: ''
      }
      this.translateFormItems.push(formJson);

      //description field json array
      let formJsonDescr;
      formJsonDescr = {
        formcontrolname: 'CategoryDescription' + lang.Code,
        translationID: 'TranslationID' + lang.Code,
        objectID: 'ObjectID' + lang.Code,
        class: lang.Code,
        name: '',
        LanguageID: lang.LanguageID,
        LanguageCode: lang.Code,
        PropertyName: 'CategoryDescription',
        PropertyValue: '',
        TranslationID: '',
        ObjectID: ''
      }
      this.translateFormItemsDescr.push(formJsonDescr);

      //metadescriptions fields
      let formJsonMeta;
      formJsonMeta = {
        formcontrolname: 'CategoryMetaDescription' + lang.Code,
        translationID: 'TranslationID' + lang.Code,
        objectID: 'ObjectID' + lang.Code,
        class: lang.Code,
        name: '',
        LanguageID: lang.LanguageID,
        LanguageCode: lang.Code,
        PropertyName: 'CategoryMetaDescription',
        PropertyValue: '',
        TranslationID: '',
        ObjectID: ''
      }
      this.translateItemMetaDescr.push(formJsonMeta);

    });
  }

  getDatas() {
    this.translateFormItems.forEach((formData, index) => {
      if (this.getData['Translations'] !== undefined) {
        this.getData['Translations'].forEach(editData => {
          if (editData.PropertyName === formData.PropertyName && formData.LanguageID === editData.LanguageID) {
            this.translateFormItems[index].TranslationID = editData.TranslationID
            this.translateFormItems[index].ObjectID = editData.ObjectID
          }
          if (formData.PropertyName === editData.PropertyName && formData.LanguageID === editData.LanguageID) {
            this.categoryForm.get(formData.formcontrolname).patchValue(editData.PropertyValue);
          }
        })
      }
    })
    // translateFormDesc
    this.translateFormItemsDescr.forEach((formData, index) => {
      this.getData['Translations'].forEach(editData => {
        if (editData.PropertyName === formData.PropertyName && formData.LanguageID === editData.LanguageID) {
          this.translateFormItemsDescr[index].TranslationID = editData.TranslationID
          this.translateFormItemsDescr[index].ObjectID = editData.ObjectID
        }
        if (formData.PropertyName === editData.PropertyName && formData.LanguageID === editData.LanguageID) {
          this.categoryForm.get(formData.formcontrolname).patchValue(editData.PropertyValue);
        }
      })
    })
    // translateFormMetaDesc 
    this.translateItemMetaDescr.forEach((formData, index) => {
      this.getData['Translations'].forEach(editData => {
        if (editData.PropertyName === formData.PropertyName && formData.LanguageID === editData.LanguageID) {
          this.translateItemMetaDescr[index].TranslationID = editData.TranslationID
          this.translateItemMetaDescr[index].ObjectID = editData.ObjectID
        }
        if (formData.PropertyName === editData.PropertyName && formData.LanguageID === editData.LanguageID) {
          this.categoryForm.get(formData.formcontrolname).patchValue(editData.PropertyValue);
        }
      })
    })
    this.previewFileUrl = this.getData['ImageURL']
    this.previewFileUrlIcon = this.getData['IconURL']
    this.getImgName = this.getData['ImageName']
    this.getImgIcon = this.getData['IconName']
  }
  addCategory() {
    if ((this.categoryForm.value.CategoryNameen === '' || this.categoryForm.value.CategoryNameen === null) && (!this.previewFileUrlIcon) && (!this.previewFileUrl)) {
      this.translate.get('CATEGORIES.PLEASE_FILL_MANDATORY_FIELDS').subscribe((res: string) => {
        this.toast.error(res);
      });
      return null
    }
    if (this.categoryForm.value.CategoryNameen === '' || this.categoryForm.value.CategoryNameen === null) {
      this.translate.get('CATEGORIES.PLEASE_FILL_NAME_FOR_ENGLISH').subscribe((res: string) => {
        this.toast.error(res);
      });
      return null
    }
    else {
      let translations = [];
      let flag = true;
      this.translateFormItems.forEach(item => {
        const data = {
          LanguageID: item.LanguageID,
          LanguageCode: item.LanguageCode,
          ObjectType: "Categories",
          PropertyName: item.PropertyName,
          PropertyValue: this.categoryForm.value[item.formcontrolname],
          Action: "Insert"
        }
        if (data.PropertyValue && flag) {
          flag = false
        }
        translations.push(data);
      })
      //descriptions
      this.translateFormItemsDescr.forEach(item => {
        const data = {
          LanguageID: item.LanguageID,
          LanguageCode: item.LanguageCode,
          ObjectType: "Categories",
          PropertyName: item.PropertyName,
          PropertyValue: this.categoryForm.value[item.formcontrolname],
          Action: "Insert"
        }
        if (data.PropertyValue && flag) {
          flag = false
        }
        translations.push(data);
      })
      //metadiscriptions
      this.translateItemMetaDescr.forEach(item => {
        const data = {
          LanguageID: item.LanguageID,
          LanguageCode: item.LanguageCode,
          ObjectType: "Categories",
          PropertyName: item.PropertyName,
          PropertyValue: this.categoryForm.value[item.formcontrolname],
          Action: "Insert"
        }
        if (data.PropertyValue && flag) {
          flag = false
        }
        translations.push(data);
      })

      let requestData = {
        CustomerID: this.customerID ? this.customerID : null,
        ParentID: this.parentID ? this.parentID : '',
        ImageName: this.imgControl,
        IconName: this.imgControlIcon,
        Translations: translations
      }

      // if (this.setImgIcon && this.setImg) {
      this.categoryService.addCategory(requestData).subscribe((data) => {
        if (data !== null) {
          this.translate.get('CATEGORIES.CATEGORY_ADDED_SUCCESSFULLY').subscribe((res: string) => {
            this.toast.success(res);
          });
          // this.myInputVariable.nativeElement.value = "";
          // this.myInputicon.nativeElement.value = "";
          this.categoryForm.reset()
          this.previewFileUrl = ''
          this.previewFileUrlIcon = ''
          this.setImgIcon = false
          this.setImg = false
          this.closeModal.emit(true);
        }
      },
        error => {
          this.translate.get('CATEGORIES.PLEASE_FILL_MANDATORY_FIELDS').subscribe((res: string) => {
            this.toast.error(res);
          });
        }
      );
      // }
      // else {
      //   if(!this.setImgIcon){
      //     this.translate.get('CATEGORIES.PLEASE_UPLOAD_IMAGE').subscribe((res: string) => {
      //       this.toast.error(res);
      //     });
      //     return false;
      //   }
      //   else if(!this.setImg){
      //     this.translate.get('CATEGORIES.PLEASE_UPLOAD_ICON').subscribe((res: string) => {
      //       this.toast.error(res);
      //     });
      //     return false;
      //   }
      //   this.translate.get('CATEGORIES.PLEASE_UPLOAD_IMAGE_ICON').subscribe((res: string) => {
      //     this.toast.error(res);
      //   });
      // }
    }
  }

  selectedImageChanged(files, event) {
    this.setImgIcon = true
    this.imgType = event.target.files[0].type
    // this.showPreImg = true 
    if (this.imgType === "image/svg+xml") {
      this.imgName = true
      // this.showPreImg = true 
      this.imgResponse = files.item(0);
      const fileReader = new FileReader();
      fileReader.onload = async e => {
        this.previewFileUrl = fileReader.result as string;
      }
      fileReader.readAsDataURL(event.target.files[0]);
      const formData = new FormData();
      formData.append('file', this.imgResponse);
      formData.append('filePath', 'CategoryImages');
      this.webService.uploadImg(formData).subscribe((data: any) => {
        this.imgControl = data[1]
        this.getImgName = data[1]
      })

    }
    else {
      this.translate.get('CATEGORIES.INVALID_TYPE_FORMAT').subscribe((res: string) => {
        this.toast.error(res);
      });
      return false
    }
  }
  //for upload category image icon
  selectedImageChangedIcon(files, event) {
    this.setImg = true;
    // this.showPreIcon = true
    this.iconType = event.target.files[0].type
    if (this.iconType === "image/svg+xml") {
      this.imgNameicon = true
      // this.showPreIcon = true
      this.imgResponseIcon = files.item(0);
      const fileReader = new FileReader();
      fileReader.onload = async e => {
        this.previewFileUrlIcon = fileReader.result as string;
      }
      fileReader.readAsDataURL(event.target.files[0]);
      const formData = new FormData();
      formData.append('file', this.imgResponseIcon);
      formData.append('filePath', 'CategoryImages');
      this.webService.uploadImg(formData).subscribe((data: any) => {
        this.imgControlIcon = data[1]
        this.getImgIcon = data[1]
      })
    }
    else {
      this.translate.get('CATEGORIES.INVALID_TYPE_FORMAT').subscribe((res: string) => {
        this.toast.error(res);
      });
      return false
    }
  }

  hideModel() {
    this.toggleModal.emit();
    // this.categoryForm.reset()
    this.previewFileUrl = ''
    this.previewFileUrlIcon = ''
    // this.populateCategoryList()
  }

}
