import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss']
})
export class FilterTagsComponent implements OnInit, OnChanges {
  @Input() filters: any[];
  @Output() getRemoveFilter = new EventEmitter<any>();
  getTags: any = [];
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.getTags = this.filters;
    const getFilter = this.filters.filter(data => /\S/.test(data.label))
    this.getTags = getFilter;
  }

  removeFilter(data, index) {
    this.getRemoveFilter.emit(data);
    this.filters.splice(index, 1)
  }

}
