import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rewardkart-admin-production-line-item-summary',
  templateUrl: './production-line-item-summary.component.html',
  styleUrls: ['./production-line-item-summary.component.scss']
})
export class ProductionLineItemSummaryComponent implements OnInit {
  @Input() product = null;
  @Input() customerID: any;
  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirectProductPage(productId) {
    const productData = {
      ProductID: productId,
      dashboardRedirectStatus: false,
      CustomerName: this.product.Orders && this.product.Orders[0] ? this.product.Orders[0].WebShop : null
    };
    let productionLine = history.state;
    console.log(history.state);
    console.log(this.customerID)
    this.router.navigate(['/admin-product-edit'], { state: { data: productData, productionLine }, replaceUrl: false });
  }

}
