import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductService } from '../state/services/product.service';
import { IMyOptions, MDBDatePickerComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../state/services/product.http.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { debug } from 'util';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { LoginService } from 'src/app/login/state/services/login.service';

@Component({
  selector: 'app-product-add-marketing',
  templateUrl: './product-add-marketing.component.html',
  styleUrls: ['./product-add-marketing.component.scss']
})
export class ProductAddMarketingComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input() productDetails: any;
  @Input() productCustID: any;
  @Output() basicInfoData = new EventEmitter<any>();
  addMarketingProductForm: FormGroup;
  languageOption: any = [];
  translateForm: any = [];
  showManualValidation: boolean = false;
  selectedLang: any = 'en';
  supplierList: any[];
  customerlist: any[];
  productUnitList: any[];
  getCustomerId: any;
  categoryList: any[];
  categoryData: any;
  optionsSelectSubCategories: any[];
  getSelectedCategoryObj: any;
  getCategoryId: any = 0;
  date = new Date();
  group: any = {};
  ProductDescription: any;
  selectedLanguageId: number = 1;

  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  }
  optionsUnderSupplier: any[];
  translateDataArray: any[];
  languageList: any[];
  urlList: any = [];
  loadPrefill: any = { supplier: false, undersupplier: false, customerlist: false };
  getStatus: Boolean = false;
  getUrlvalidStatus: Boolean = false;
  disablebtn: Boolean = true;
  deleteIndex: any;
  metaDescription: any;
  webshopDetail: any;
  constructor(private apiService: ApiService, private productService: ProductService, private loginService: LoginService, public productHttpService: ProductHttpService, private toast: ToastService, private router: Router) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {

    let langData = { label: 'English', value: 1, code: 'en' };

    this.getCurrentLang(langData)

    this.getAllLanguages(); // Get language list
    // this.getSupplierList() // Get Supplier list
    this.getCategoryList(); // Category List


    if (this.productDetails !== undefined) {
      //  const selectedSupplier =  

      if (this.productDetails.SupplierID !== undefined && this.productDetails.SupplierID !== '') {
        this.getUnderSuppliers(this.productCustID, this.productDetails.SupplierID);
      }
      this.getSelectedCustomer({ value: this.productCustID });
      this.prefillData()
      this.addMarketingProductForm.valueChanges.subscribe(() => {
        this.addMarketProduct();
      })
      this.addMarketProduct();
    }

  }

  ngOnChanges() {
    // this.addMarketProduct.valueChanges.subscribe(() => {

    // })
  }

  cancel() {
    this.router.navigate(['/admin-product-list'], { replaceUrl: false });
  }

  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }

  prefillData() {
    if (this.productDetails.ProductCategories.length > 0) {
      if (this.productDetails.ProductCategories[0].SubCategoryName !== undefined) {

        if (this.categoryData !== undefined) {
          let parentID = '';
          this.categoryData.forEach(cat => {
            if (cat.SubCategories !== undefined) {
              if (cat.SubCategories.length > 0) {
                const getParentCategory = cat.SubCategories.filter(subcat => subcat.CategoryID === this.productDetails.ProductCategories[0]['CategoryID']);
                if (getParentCategory.length > 0) {
                  parentID = getParentCategory[0]['ParentID'];
                }
              }
            }
          });
          this.addMarketingProductForm.patchValue({
            selectCategories: parentID,
          });
          let parentObj = {
            value: parentID
          }
          if (parentID !== '') {
            this.getSelectedCategory(parentObj, 'passdata')
          }
          this.addMarketingProductForm.patchValue({
            selectSubCategories: this.productDetails.ProductCategories[0]['CategoryID'],
          });
        }

      }
      else {
        this.addMarketingProductForm.patchValue({
          selectCategories: this.productDetails.ProductCategories[0]['CategoryID'],
        });
        if (this.productDetails.ProductCategories[0]['CategoryID'] !== undefined && this.productDetails.ProductCategories[0]['CategoryID'] !== '') {
          let parentObj = {
            value: this.productDetails.ProductCategories[0]['CategoryID']
          }
          this.getSelectedCategory(parentObj);
        }
      }
    }
    // this.addMarketingProductForm.patchValue({
    //   selectSubCategories: 4473,
    // });
    this.addMarketingProductForm.patchValue({
      manualId: this.productDetails.ProductIdManual,
      selectSupplier: this.productDetails.SupplierID,
      selectCustomers: this.productCustID ? this.productCustID : this.productDetails.CreatedByCustomerID,
      productNewEndDate: this.productDetails.ProductNewEndDate ? this.getDateFormat(this.productDetails.ProductNewEndDate) : '',
      updateReminderDate: this.productDetails.UpdateReminderDate ? this.getDateFormat(this.productDetails.UpdateReminderDate) : '',
      productFeaturedEndDate: this.productDetails.ProductFeaturedEndDate ? this.getDateFormat(this.productDetails.ProductFeaturedEndDate) : ''
    });
    this.productDetails.Translations.forEach(element => {
      if (element.PropertyName !== "ImageAlternativeText" && element.PropertyValue !== '' && element.PropertyValue !== null && element.PropertyValue !== undefined) {
        const controlName = element.PropertyName + element.LanguageCode;
        this.addMarketingProductForm.get(controlName).patchValue(element.PropertyValue !== null ? element.PropertyValue : '');
      }
    });


    //Url name
    this.urlList = [];
    this.productDetails.ProductDownloads.forEach((trans, index) => {
      // this.group['urlName' + element.LanguageCode] = new FormControl('');
      // element.Translations.forEach(element => {
      //   this.group['urlName' + element.LanguageCode+index] = new FormControl('as');
      // });
      const urlNameArray = [];
      trans.Translations.forEach(element => {
        const urlName = {
          "Name": element.Name,
          "LanguageID": element.LanguageID,
          "LanguageCode": element.LanguageCode,
          "ObjectType": "ProductDownloads",
          "PropertyName": "ProductDownloadName",
          "PropertyValue": element.PropertyValue,
        }
        if (element.PropertyValue !== '' && element.PropertyValue !== null && element.PropertyValue !== undefined) {
          // this.addMarketingProductForm.get('urlName' + element.LanguageCode+index).patchValue(element.PropertyValue !== null ? element.PropertyValue : '');
        }
        urlNameArray.push(urlName);
      });

      let obj = {
        "URL": trans.URL,
        "Translations": urlNameArray,
      }
      this.urlList.push(obj);
    });



    // this.productDetails.ProductDownloads.forEach(trans => {
    //   trans.Translations.forEach(element => {
    //     const urlName = {
    //       "Name": element.Name,
    //       "LanguageID": element.LanguageID,
    //       "LanguageCode": element.LanguageCode,
    //       "ObjectType": "ProductDownloads",
    //       "PropertyName": "ProductDownloadName",
    //       "PropertyValue": this.addMarketingProductForm.value['urlName' + element.LanguageCode],
    //     }
    //     urlNameArray.push(urlName);
    //   });
    // });



    this.urlList.forEach((element, index) => {
      this.group['url' + index] = new FormControl(element.URL);
      element.Translations.forEach(eleTranslate => {

        this.group['urlname' + eleTranslate.LanguageCode + index] = new FormControl(eleTranslate.PropertyValue);
      });
    })

    this.addMarketingProductForm = new FormGroup(this.group);

    // if (this.productDetails !== undefined) {
    //   this.addMarketProduct();
    // }

  }

  getCurrentLang(lang) {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    getlang.forEach(langItem => {
      if (lang.label == langItem.Name) {
        let langData = { label: langItem.Name, value: langItem.LanguageID, code: langItem.Code };
        this.selectedLang = langData.code;
        console.log('langid', langData)
        this.selectedLanguageId = langData.value;
        return;
      }
    })
    this.selectedLang = lang.code;
    console.log('langid', lang)
    this.selectedLanguageId = lang.value;
  }

  validateManualId(manualid) {
    if (manualid !== '') {
      const manualData = {
        "manualid": manualid,
        "productid": 0
      }
      this.productService.loadManualId(manualData);
      this.productService.validateManualId().subscribe((data) => {
        if (data !== null && data !== '') {
          this.showManualValidation = false;
          if (Boolean(data) === false) {
            this.showManualValidation = true;
          }

        }
      })
    }

  }

  getAllLanguages() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.languageList = getlang;
    let translationFieldJson = [
      {
        formcontrolname: 'ProductName',
        label: 'PRODUCTADD.PRODUCTNAME',
        PropertyName: "ProductName",
        isRequired: true
      },
      {
        formcontrolname: 'ProductDescription',
        label: 'PRODUCTADD.PRODUCTDESCRIPTION',
        PropertyName: "ProductDescription",
        isRequired: true
      },
      {
        formcontrolname: 'ProductCardLabel',
        label: 'PRODUCTADD.PRODUCTCARDLABEL',
        PropertyName: "ProductCardLabel",
        isRequired: true
      }
    ];
    let productNameArray = [];
    const urlTranslation = [];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)

      translationFieldJson.forEach(jsonField => {
        if (jsonField.PropertyName !== "ImageAlternativeText") {
          let requiredField = false;
          if ((lang.Code === 'en' && (jsonField.formcontrolname === "ProductName" || jsonField.formcontrolname === "ProductCardLabel" || jsonField.formcontrolname === "ProductDescription"))) {
            requiredField = true;
          }

          let formJson = { formcontrolname: jsonField.formcontrolname + lang.Code, label: jsonField.label, class: lang.Name, name: jsonField.formcontrolname, required: requiredField, LanguageID: lang.LanguageID, LanguageCode: lang.Code, PropertyName: jsonField.PropertyName, isRequired: jsonField.isRequired }
          if (jsonField.formcontrolname === "ProductName") {
            productNameArray.push(formJson)
          }
          this.translateForm.push(formJson)
        }

      });

    })
    this.group = {
      manualId: new FormControl('', Validators.required),
      selectSupplier: new FormControl('', Validators.required),
      // customerID: new FormControl('', Validators.required),
      underSupplierProductID: new FormControl(''),
      // underSupplier: new FormControl(''),
      selectCustomers: new FormControl('', Validators.required),
      selectCategories: new FormControl('', Validators.required),
      selectSubCategories: new FormControl(''),
      productNewEndDate: new FormControl(''),
      updateReminderDate: new FormControl(''),
      productFeaturedEndDate: new FormControl(''),
      addUrl: new FormControl('')
    }

    this.translateForm.forEach(element => {
      this.group['urlName' + element.LanguageCode] = new FormControl('');
      if (element.required) {
        this.group[element.formcontrolname] = new FormControl('', Validators.required);
      }
      else {
        this.group[element.formcontrolname] = new FormControl('');
      }
    })


    this.addMarketingProductForm = new FormGroup(this.group);

  }

  addurl() {
    const urlNameArray = [];

    this.languageList.forEach(element => {
      const urlName = {
        "Name": element.Name,
        "LanguageID": element.LanguageID,
        "LanguageCode": element.Code,
        "ObjectType": "ProductDownloads",
        "PropertyName": "ProductDownloadName",
        "PropertyValue": this.addMarketingProductForm.value['urlName' + element.Code],
        "Action": "Insert"
      }
      urlNameArray.push(urlName);
    });
    const obj = {
      "URL": this.addMarketingProductForm.value.addUrl,
      "Translations": urlNameArray,
      "Action": "Insert"
    }
    this.urlList.push(obj);
    this.disablebtn = true;

    if (this.productDetails !== undefined) {
      this.productDetails.ProductDownloads.push(obj);
    }

    this.urlList.forEach((element, index) => {
      this.group['url' + index] = new FormControl(element.URL);
      element.Translations.forEach(eleTranslate => {
        this.group['urlname' + eleTranslate.LanguageCode + index] = new FormControl(eleTranslate.PropertyValue);
      });
    })
    // this.addMarketingProductForm = new FormGroup(this.group);


    this.addMarketingProductForm = new FormGroup(this.group);
    this.languageList.forEach(element => {
      this.group['urlName' + element.Code] = new FormControl('');
    });
    this.group['addUrl'] = new FormControl('');
    this.addMarketingProductForm = new FormGroup(this.group);

  }


  getSupplierList(customerID) {
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getSuppliersList(customerID, this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.supplierList = [{ label: 'Select Supplier', value: '' }];
        data.ItemsCollection.forEach(supplier => {
          let supplierListData = {
            label: supplier.Name,
            value: supplier.SupplierID
          }
          this.supplierList.push(supplierListData)
        })
        this.loadPrefill.supplier = true;
      }
    })
  }

  selectedSupplier(supplier) {
    this.addMarketingProductForm.patchValue({
      underSupplierProductID: ''
    })
    this.getUnderSuppliers(this.addMarketingProductForm.value.selectCustomers, supplier.value);
    if (this.productDetails !== undefined) {
      this.addMarketProduct();
    }
  }

  getUnderSuppliers(customerID, supplier) {
    // const loginData = this.apiService.getLoginData();
    const request = {
      customerID: customerID,
      supplierID: supplier
    }
    this.productHttpService.getUnderSupplierList(request).subscribe((data: any) => {
      if (data) {
        this.optionsUnderSupplier = [{ label: 'Select under supplier', value: '' }];
        data.ItemsCollection.forEach(element => {
          const customerVal = {
            value: element.UndersupplierID,
            label: element.Name,
          };
          this.optionsUnderSupplier.push(customerVal);
        });
        this.loadPrefill.undersupplier = true;

        if (this.productDetails !== undefined) {
          this.addMarketingProductForm.patchValue({
            underSupplierProductID: this.productDetails.UndersupplierID
          })
        }
      }
    });
  }

  getCategoryList() {
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getChildChildCustomer(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        const customerlist = [{ label: 'Select webshop', value: '' }];
        data.forEach(cust => {
          let custData = {
            label: cust.Name,
            value: cust.CustomerID
          }
          customerlist.push(custData)
        })
        this.customerlist = customerlist;
        this.loadPrefill.customerlist = true;
        if (this.productDetails !== undefined) {
          this.prefillData();
        }
      }
    })




    // this.productService.loadUnits();
    this.productHttpService.getUnits().subscribe((data: any) => {
      if (data !== null) {
        this.productUnitList = [];
        data.forEach(units => {
          let unitsData = {
            label: units.Name,
            value: units.ProductUnitID,
            selected: true
          }
          this.productUnitList.push(unitsData);
        })
      }
    })




  }

  getSelectedCustomer(customer) {
    this.getCustomerId = customer.value;
    this.addMarketingProductForm.patchValue({
      selectSupplier: '',
      underSupplier: ''
    })
    this.optionsUnderSupplier = [];
    // this.getUnderSuppliers(customer.value, '');
    this.getSupplierList(customer.value);
    this.getCategory(customer.value)

  }

  getCategory(data) {
    const categoryData = {
      customerID: data
    }
    this.productService.loadProductCategory(categoryData);
    this.productService.getProductCategory().subscribe((data: any) => {
      if (data) {
        this.categoryData = data;
        const categoryList = [{ label: 'Select category', value: '' }];
        data.forEach(element => {
          let categoryObject = {
            label: element.Name,
            value: element.CategoryID
          }
          categoryList.push(categoryObject);
        });
        this.categoryList = categoryList;
        if (this.productDetails !== undefined) {
          this.prefillData();
        }
        // if (this.productDetails !== undefined && this.productDetails.ProductCategories.length > 0) {
        //   this.addMarketingProductForm.patchValue({
        //     selectCategories: this.productDetails.ProductCategories[0].CategoryID
        //   })
        // }
      }
    })
  }

  getSelectedCategory(event, status?) {
    this.optionsSelectSubCategories = [{ label: 'Select Subcategory', value: '' }];
    if (this.categoryData !== undefined) {
      const getSelectedCategoryObj = this.categoryData.filter(data => data.CategoryID === event.value);
      if (getSelectedCategoryObj.length > 0) {
        this.getSelectedCategoryObj = getSelectedCategoryObj[0];
        this.getCategoryId = getSelectedCategoryObj[0].CategoryID;
        this.getCustomerId = getSelectedCategoryObj[0].CustomerID;
        getSelectedCategoryObj[0].SubCategories.forEach(item => {
          const option = {
            value: item.CategoryID,
            label: item.Name
          }
          this.optionsSelectSubCategories.push(option);
        });
      }
    }

    if (status === undefined) {
      if (this.productDetails !== undefined) {
        this.productDetails.ProductCategories[0]['CategoryID'] = event.value;
        this.addMarketProduct();
      }
    }
  }

  subCategory(event) {
    if (this.productDetails !== undefined) {
      this.productDetails.ProductCategories[0]['CategoryID'] = event.value;
      this.productDetails.ProductCategories[0]['SubCategoryName'] = event.label;
      this.addMarketProduct();
    }
  }


  dateFormatchange(getDate) {
    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    return myDate;
  }

  translateFieldValue() {
    this.translateDataArray = [];
    const setArray = []
    this.translateForm.forEach(translate => {
      let translateData = {
        "LanguageID": translate.LanguageID,
        "LanguageCode": translate.LanguageCode,
        "ObjectType": "Products",
        "PropertyName": translate.PropertyName,
        "PropertyValue": this.addMarketingProductForm.value[translate.formcontrolname],
        "Action": "Insert"
      }
      setArray.push(translateData)
    })
    this.translateDataArray = setArray;
  }

  addMarketProduct() {
    this.translateFieldValue();
    const loginData = this.apiService.getLoginData();
    // this.getCustomerId = loginData.CustomerID;
    const addMarketProduct = {
      "SupplierID": this.addMarketingProductForm.value.selectSupplier,
      "CustomerID": this.addMarketingProductForm.value.selectCustomers,
      "Translations": this.translateDataArray,
      "Action": "Insert",
      "ProductIdManual": this.addMarketingProductForm.value.manualId,
      "UndersupplierID": this.addMarketingProductForm.value.underSupplierProductID,
      "SelectedCategory": this.getSelectedCategoryObj,
      "SelectedSubCategoryID": this.getSelectedCategoryObj !== undefined ? this.getSelectedCategoryObj.SubCategories : [],
      "ProductNewEndDate": this.addMarketingProductForm.value.productNewEndDate !== '' ? this.dateFormatchange(this.addMarketingProductForm.value.productNewEndDate) : '',
      "UpdateReminderDate": this.addMarketingProductForm.value.updateReminderDate !== '' ? this.dateFormatchange(this.addMarketingProductForm.value.updateReminderDate) : '',
      "ProductFeaturedEndDate": this.addMarketingProductForm.value.productFeaturedEndDate !== '' ? this.dateFormatchange(this.addMarketingProductForm.value.productFeaturedEndDate) : '',
      "ProductCategories": [
        {
          "CustomerID": this.getSelectedCategoryObj !== undefined ? this.getSelectedCategoryObj.CustomerID : '',
          "Action": "Insert",
          "CategoryID": this.addMarketingProductForm.value.selectSubCategories ? this.addMarketingProductForm.value.selectSubCategories : this.getSelectedCategoryObj !== undefined ? this.getSelectedCategoryObj.CategoryID : ''
        }
      ],
      "isMarketingProduct": true,
      "ImageName": "No_Image_Available.png",
      "ProductCustomers": [
        {
          "CustomerID": this.getCustomerId,
          "Action": "Insert"
        }
      ],
      "CreatedByUserID": loginData.UserID,
      "ProductImages": [
        {
          "ImageName": "No_Image_Available.png",
          "Position": 1,
          "ImageAlternativeText": this.addMarketingProductForm.value.productNameen,
          "Action": "Insert",
          "Translations": [
            {
              "LanguageID": 1,
              "LanguageCode": "en",
              "ObjectID": 0,
              "ObjectType": "ProductImages",
              "PropertyName": "ImageAlternativeText",
              "PropertyValue": this.addMarketingProductForm.value.productNameen,
              "Action": 1
            },
            {
              "LanguageID": 2,
              "LanguageCode": "dk",
              "ObjectID": 0,
              "ObjectType": "ProductImages",
              "PropertyName": "ImageAlternativeText",
              "PropertyValue": this.addMarketingProductForm.value.productNameen,
              "Action": 1
            }
          ]
        }
      ],
      "AllowOrderSampleWithLogo": false,
      "AllowOrderSampleWithoutLogo": false,
      "AllowOrderSketch": false,
      "PrintPricesCurrencyID": 2,
      "isIndexedBySearchEngines": false,
      "VisibleOnLinkNetwork": false,
      "MinimumOrder": 1,
      "MinimumProductionOrder": 1,
      "DeliveryWeeksFrom": 0,
      "DeliveryWeeksTo": 0,
      "UnitWeight": 0,
      "PiecesPerUnit": 1,
      "CurrencyID": 2,
      "ProductDownloads": this.urlList,
      "VisibleDate": ""
    }

    if (this.productDetails === undefined) {
      this.productHttpService.addProduct(addMarketProduct).subscribe((data) => {
        if (data) {
          if (typeof (data) === "number") {
            this.cancel();
            this.toast.success('Product created successfully');
          }
        }
      },
        (error) => {
          let errMsg = '';
          for (var key in error.error.ModelState) {
            errMsg += error.error.ModelState[key] + ' ';
          }
          this.toast.error(errMsg);
        })
    }
    else {
      this.productDetails.Translations.forEach(element => {
        if (element.PropertyName !== "ImageAlternativeText") {
          if (this.addMarketingProductForm.value[element.PropertyName + element.LanguageCode] === element.PropertyValue) {
            element.PropertyValue = this.addMarketingProductForm.value[element.PropertyName + element.LanguageCode];
            element.Action = 'Update';
          }
        }
      });

      if (this.productDetails.ProductCategories.length > 0) {
        this.productDetails.ProductCategories.forEach(element => {
          element.Action = 'Update';
        });
      }
      this.productDetails.ProductDownloads.forEach((element, index) => {
        element.URL = this.addMarketingProductForm.value['url' + index]
        element.Translations.forEach(ele => {
          ele.PropertyValue = this.addMarketingProductForm.value['urlname' + ele.LanguageCode + index],
            ele.Action = 'Update'
        });
        element.Action = 'Update'
      });

      const sendBasicData = {
        formdata: addMarketProduct,
        response: this.productDetails
      }
      this.basicInfoData.emit(sendBasicData);
    }

  }

  deleteUrl(index) {
    this.deleteIndex = index;
    this.deleteModal.show();
  }

  isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null) {
      return false;
    }
    else {
      return true;
    }

  }

  validateAddedUrl(value) {
    const getUrlvalidStatus = this.isUrlValid(value);
    if (getUrlvalidStatus) {
      this.getUrlvalidStatus = false;
    }
    else {
      this.getUrlvalidStatus = true;
    }
  }

  validateUrl(value) {
    const getStatus = this.isUrlValid(value);
    if (getStatus) {
      this.getStatus = false;
      this.disablebtn = false;
    }
    else {
      this.getStatus = true;
      this.disablebtn = true;
    }
  }


  getDeleteStatus(status) {
    if (status) {
      this.urlList.splice(this.deleteIndex, 1);
      this.deleteModal.hide();
      this.productDetails.ProductDownloads.forEach((element, index) => {
        if (index === this.deleteIndex && element.Action !== 'Delete') {
          element.Action = 'Delete';
        }
      });
    }
    else {
      this.deleteModal.hide();
    }
  }

  getUnderSupplier(data) {
    if (this.productDetails !== undefined) {
      this.addMarketProduct();
    }
  }

  passData() {
    if (this.productDetails !== undefined) {
      this.addMarketProduct();


      this.productDetails.Translations.forEach(element => {
        if (element.PropertyName !== "ImageAlternativeText") {
          element.PropertyValue = this.addMarketingProductForm.value[element.PropertyName + element.LanguageCode];
          element.Action = 'Update';
        }
      });

      this.productDetails.ProductDownloads.forEach((element, index) => {
        element.URL = this.addMarketingProductForm.value['url' + index]
        element.Translations.forEach(ele => {
          ele.PropertyValue = this.addMarketingProductForm.value['urlname' + ele.LanguageCode + index],
            ele.Action = 'Update'
        });
        element.Action = 'Update'
      });

    }
  }


  dateFieldChange(event, control) {
    if (this.productDetails !== undefined) {
      this.addMarketingProductForm.controls[control].setValue(event.actualDateFormatted);
      this.passData();
    }
  }

}
