import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionLineComponent } from './production-line.component';
import { ProductionLineItemSummaryComponent } from './production-line-item-summary/production-line-item-summary.component';
import { ProductionLineItemTimeLineComponent } from './production-line-item-timeline/production-line-item-timeline.component';
import { ProductionLineItemComponent } from './production-line-item/production-line-item.component';
import { ModalModule, ButtonsModule, WavesModule, CardsModule, CollapseModule, StepperModule, AccordionModule } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';
// tslint:disable-next-line: max-line-length
import { ProdctionLineStatusModalComponent } from './production-line-item/production-line-status-modal/production-line-status-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ProductionLineComponent,
    ProductionLineItemSummaryComponent, ProductionLineItemComponent, ProductionLineItemTimeLineComponent, ProdctionLineStatusModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule, WavesModule, CardsModule,
    CollapseModule, AccordionModule,
    SharedModule,
    StepperModule,
    ModalModule
  ],
  entryComponents: [ProdctionLineStatusModalComponent]
})
export class ProductionLineModule { }
