import { Component, OnInit, Input, Output, EventEmitter, Directive, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { WebService } from '../../restful-service/web.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageInboxService } from '../message-inbox.service';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { ApiService } from '../../shared/common-services/api.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-message-box-list',
    templateUrl: './message-box-list.component.html',
    styleUrls: ['./message-box-list.component.scss']
})
export class MessageBoxListComponent implements OnInit, OnDestroy {
    // public unreadmessage: any = [];
    unreadmessagenumber: any = {};
    activeTab: any = 'inbox';

    @Output() unreadmessage = new EventEmitter<any>();
    @Output() currentTab = new EventEmitter<any>();
    unreadMsgCntSubscription$: Subscription;
    inboxCount: any = '';
    webshopDetail: any;
    constructor(public webservice: WebService, public messageInboxService: MessageInboxService, public messageBoxService: MessageboxService, public translate: TranslateService, private messageboxhttpservice: MessageboxHttpService, public toast: ToastService, public apiService: ApiService) {
        //this.unreadmessages();
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    ngOnInit() {
        this.unreadMsgCntSubscription$ = this.messageInboxService.updateReadUnreadCount.subscribe((data) => {
            this.unreadmessages();
        })

        this.messageInboxService.setMenuActive.subscribe((data) => {
            this.activeTab = 'inbox';
        })


        this.currentTab.emit('inbox');
        this.unreadmessages();
    }

    unreadmessages() {
        // this.inboxCount = sessionStorage.getItem('inboxcount');
        const loginData = this.apiService.getLoginData();
        const getSession = JSON.parse(sessionStorage.getItem('inboxrolecode'));
        let childcustomerstatus = true;
        if (getSession !== null && getSession !== undefined && getSession !== '') {
            childcustomerstatus = getSession.type === 'ANY' ? true : false;
            let custID = getSession.type === 'ANY' ? this.webshopDetail.CustomerID : getSession.customerID;
            if (custID !== undefined) {

                this.webservice.getUnreadMessageData(this.webshopDetail.CustomerID, childcustomerstatus).subscribe(
                    (data: any) => {
                        if (data) {
                            if (getSession.type === 'ANY') {
                                this.inboxCount = data.NumberOfUnreadByWebshop;
                                sessionStorage.removeItem('inboxrolecode');
                            }
                            this.unreadmessage.emit(data);
                            this.unreadmessagenumber = data;
                        }
                    });


                if (getSession.type !== 'ANY') {
                    this.webservice.getUnreadMessageData(custID, childcustomerstatus).subscribe(
                        (data: any) => {
                            if (data) {
                                this.inboxCount = data.NumberOfUnreadByWebshop;
                                sessionStorage.removeItem('inboxrolecode');
                            }
                        });
                }
            }
        }
        else {
            if (this.webshopDetail.CustomerID !== undefined) {
                this.webservice.getUnreadMessageData(this.webshopDetail.CustomerID, childcustomerstatus).subscribe(
                    (data: any) => {
                        if (data) {
                            this.unreadmessage.emit(data);
                            this.inboxCount = data.NumberOfUnreadByWebshop;
                            this.unreadmessagenumber = data;
                        }

                    }
                )
            }
        }


    }

    onClick(check) {
        this.unreadmessages();
        this.activeTab = check;
        this.currentTab.emit(this.activeTab);
    }

    onDroppedData(data: any) {
        const EmailIDs = data.map((email: any) => email.EmailID)
        const loginData: any = this.apiService.getLoginData()
        const emailAction = {
            ActionType: "TRASH",
            Value: false,
            EmailIDs
        }
        this.messageboxhttpservice.bulkAction(emailAction, loginData.UserID).subscribe(resp => {
            if (resp) {
                this.toast.success('Email moved successfully');
            }
            this.messageBoxService.reloadTrashMessages.next(resp);
            this.unreadmessages();
        });

    }

    ngOnDestroy() {
        this.unreadMsgCntSubscription$.unsubscribe()
    }
}



