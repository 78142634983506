import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderItemComponent } from './order-item.component';
import { OrderItemSummaryComponent } from './order-item-summary/order-item-summary.component';
import { SuborderItemComponent } from './suborder-item/suborder-item.component';
import { SuborderItemSummaryComponent } from './suborder-item/suborder-item-summary/suborder-item-summary.component';
import { OrderItemDetailsComponent } from './order-item-details/order-item-details.component';
import { SuborderItemDetailsComponent } from './suborder-item/suborder-item-details/suborder-item-details.component';
import { FormsModule } from '@angular/forms';
import { OrderItemModalComponent } from './order-item-modal/order-item-modal.component';
import { DeliveryNoteModalComponent } from './delivery-note-modal/delivery-note-modal.component';
import { CreditNoteModalComponent } from './credit-note-modal/credit-note-modal.component';
import { PerformaInvoiceComponent } from './modals/performa-invoice/performa-invoice.component';
import { TrackOrderComponent } from './track-order/track-order.component';

@NgModule({
    declarations: [
        OrderItemComponent,
        OrderItemSummaryComponent,
        TrackOrderComponent,
        SuborderItemComponent,
        SuborderItemSummaryComponent,
        OrderItemDetailsComponent,
        SuborderItemDetailsComponent,
        OrderItemModalComponent,
        DeliveryNoteModalComponent,
        CreditNoteModalComponent,
        PerformaInvoiceComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
    ],
    entryComponents: [OrderItemComponent, OrderItemModalComponent, DeliveryNoteModalComponent, CreditNoteModalComponent, PerformaInvoiceComponent],
    exports: [OrderItemComponent]
})

export class OrderItemModule { }