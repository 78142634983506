import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderHttpService } from '../state/services/order.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { OrderProcessService } from '../state/services/order.process.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';

@Component({
  selector: 'rewardkart-admin-order-process',
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss']
})
export class OrderProcessComponent implements OnInit {
  header: any;
  searchLoader: boolean = true;
  orderList: any = [];
  orderItemCount: any;
  pageLimit: any = {};
  itemsPerPage: number = 30;
  currentPage: number = 1;
  resetSearchFlag: string;
  orderProcessPayload: any = {};
  transportationIncludes = false;
  unauthorizedIncludes = false;
  deletedOrders = false;
  tableEvent: any;
  constructor(private route: ActivatedRoute, private router: Router, private orderHttpService: OrderHttpService,
    private toaster: ToastService, private orderProcessService: OrderProcessService,
    private linkTableService: LinkTableService) { }

  ngOnInit() {
    this.header = 'Order Process';
    const resetSearch = this.route.snapshot.paramMap.get('resetSearch');
    const { resetSearchValue } = history.state;
    this.resetSearchFlag = resetSearch || resetSearchValue;
    this.onLoad(this.resetSearchFlag);
    if (sessionStorage.getItem('dashboardRedirectStatus') !== undefined && sessionStorage.getItem('dashboardRedirectStatus') !== null) {
      sessionStorage.removeItem('dashboardRedirectStatus');
    }
  }

  onLoad(resetSearchFlag) {
    if (resetSearchFlag === 'true') {
      this.pageLimit = this.orderProcessService.defaultPageLimit;
      this.currentPage = this.orderProcessService.defaultPageLimit.pageIndex;
      this.itemsPerPage = this.orderProcessService.defaultPageLimit.pageSize;
    } else {
      this.pageLimit = this.orderProcessService.updatedPageLimit;
      this.itemsPerPage = this.orderProcessService.updatedPageLimit.pageSize;
      this.currentPage = this.orderProcessService.updatedPageLimit.pageIndex;
    }
  }

  onChangeRowPerPage(event: any) {
    this.itemsPerPage = event.pageSize;
    this.pageLimit.pageIndex = event.pageIndex;
    this.pageLimit.pageSize = event.pageSize;
    this.orderProcessService.updatedPageLimit.pageSize = event.pageSize;
    this.orderProcessService.updatedPageLimit.pageIndex = event.pageIndex;
    this.getOrdersList(this.orderProcessPayload);
  }

  onChangeCurrentPage(event: any) {
    this.currentPage = event.pageIndex;
    this.pageLimit.pageIndex = event.pageIndex;
    this.pageLimit.pageSize = event.pageSize;
    this.orderProcessService.updatedPageLimit.pageIndex = event.pageIndex;
    this.orderProcessService.updatedPageLimit.pageSize = event.pageSize;
    this.getOrdersList(this.orderProcessPayload);
  }

  handleOrderProcess(event: any) {
    this.orderProcessPayload.customerId = event.customerId;
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.orderProcessPayload.searchQueryParam = event.searchQueryParam;
    this.orderProcessPayload.advanceSearchPayload = event.advanceSearchPayload;
    this.orderProcessPayload.includChildShopOrders = event.includChildShopOrders;
    this.orderProcessPayload.loggedInCustomerID = getWebshopDetails.CustomerID;
    this.pageLimit.pageIndex = 1;
    this.pageLimit.pageSize = 30;
    this.currentPage = 1;
    this.itemsPerPage = 30;
    //console.log(this.currentPage, 'curre1')
    this.getOrdersList(this.orderProcessPayload);
  }


  getOrdersList(event: any) {
    if (sessionStorage.getItem('backcliked') == 'true') {
      var pageLimitSess = JSON.parse(sessionStorage.getItem("pageLimit"));

      this.currentPage = pageLimitSess.pageIndex;
      this.pageLimit.pageIndex = pageLimitSess.pageIndex;
      this.pageLimit.pageSize = pageLimitSess.pageSize;
      this.orderProcessService.updatedPageLimit.pageIndex = pageLimitSess.pageIndex;
      this.orderProcessService.updatedPageLimit.pageSize = pageLimitSess.pageSize;

      //console.log(this.pageLimit, "true3")
    }
    //console.log(this.pageLimit, "true1")
    this.searchLoader = true;
    this.transportationIncludes = event.searchQueryParam.includes('onlyOrdersWithoutTransportation=true');
    this.unauthorizedIncludes = event.searchQueryParam.includes('onlyUnauthorisedOrders=true');
    this.deletedOrders = event.searchQueryParam.includes('onlyDeletedOrders=true');
    //console.log(this.pageLimit, 'true2')
    this.orderHttpService.getOrdersList(this.pageLimit,
      event.searchQueryParam, event.advanceSearchPayload, this.orderProcessPayload).subscribe((data: any) => {
        this.processOrderList(data);
      }, error => {
        this.searchLoader = false;
        this.toaster.error('unable to get orders list');
      });
  }

  processOrderList(searchResult: any) {
    if (sessionStorage.getItem('backcliked') == 'true') {
      sessionStorage.setItem('backcliked', "false");
    }
    if (searchResult && searchResult.ItemsCollection.length > 0) {
      this.orderList = searchResult.ItemsCollection.map(orderItem => {
        let productNames = this.getSubOrderProductNames(orderItem.Suborders);
        if (productNames === '') {
          productNames = this.getOrderProductNames(orderItem.OrderItems);
        }
        const orderItems = Object.assign(orderItem, {
          subOrderItems: orderItem.Suborders ? orderItem.Suborders.length : 0, productsNames: productNames
        });
        return orderItems;
      });
      this.orderList.forEach(e=>{
        e.PointStatus = "Redeemed";
      })
      this.orderItemCount = searchResult.TotalItemsCount;
      this.searchLoader = false;
    } else {
      this.orderList = [];
      this.orderItemCount = 0;
      this.searchLoader = false;
      this.toaster.error('No Orders Found');
    }
  }

  getSubOrderProductNames(subOrders: any) {
    let productNames = '';
    let index = 0;
    subOrders.forEach(subOrderItem => {
      subOrderItem.OrderItems.forEach(orderItem => {
        if (index > 0) {
          productNames += ', ';
        }
        productNames = productNames + orderItem.ProductName;
        index++;
      });
    });
    return productNames;
  }

  getOrderProductNames(orders: any) {
    let productNames = '';
    let index = 0;
    orders.forEach(orderItem => {
      if (index > 0) {
        productNames += ', ';
      }
      productNames = productNames + orderItem.ProductName;
      index++;
    });
    return productNames;
  }

  viewOrderItem(event) {
    try {
      if (event) {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(selectedRow => {
          if (selectedRow && selectedRow.OrderID) {
            const id = selectedRow.OrderID;
            sessionStorage.setItem("pageLimit", JSON.stringify(this.pageLimit));
            sessionStorage.setItem("backcliked", "false");
            if (this.transportationIncludes || this.unauthorizedIncludes) {
              const customerID = this.orderProcessPayload.customerId;
              this.router.navigate([`/admin-order-process/unauthorised/${id}`], {
                state: { customerID }, replaceUrl: false
              });
            } else if (this.deletedOrders) {
              this.router.navigate([`/admin-order-process/deletedOrders/${id}`],
                { replaceUrl: false });
            } else {
              this.router.navigate([`/admin-order-item/${id}`], {
                state: { id },
                replaceUrl: false
              });
            }
          }
        });
      }
    } catch (error) { }
  }

  ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }
}