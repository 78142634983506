import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ColumnSetting } from '../../shared/link-table-border/models/link-table-model';
import { ReportsHttpService } from '../services/reports-http.service';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ToastService } from 'ng-uikit-pro-standard';


@Component({
    selector: 'rewardkart-report-campaign-usage',
    templateUrl: './report-campaign-usage.component.html',
    styleUrls: ['./report-campaign-usage.component.scss']
})
export class ReportCampaignUsageComponent implements OnInit {
    fromDate: any;
    toDate: any;
    date = new Date();
    userName: string = '';
    customerName: string = '';
    campaignID: any;
    domainName: any = '';
    activePage: number = 1;
    isParentCustomer: boolean = true;
    webshop: any;
    childwebshop: any;
    childShop: any;
    showLoader: boolean = false;
    campaignList: any;
    showTable: boolean = false;
    usageReport: any;
    noData: boolean = false;
    public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public fileExtension = '.xlsx';
    public exportData: any = [];
    columns: ColumnSetting[] = [];
    totalPointsCredited: any = '0';
    totalPointsUsed: any = '0';
    totalBalancePoints: any = '0';
    customerid: any;
    header:string = "Campaign Usage Report";
    perPagedata:number = 10;
    enablepagination:boolean = true;
    searchCriteria: any = {};
    filterData: any = {};
    filterFields: any = [];
    rowPagepage: number = 10;
    webshopdatacount: any;
    constructor(private datePipe: DatePipe, private reportHttpService: ReportsHttpService, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        let user = JSON.parse(sessionStorage.getItem('userdetails'));
        this.customerid = user.CustomerID;
        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.getCampaigns(user.UserID);
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }
    }
    ngOnInit() {

        this.getChildShops();
        this.onLoadParentshopList();
    }

    getChildShops() {
        const searchdata = {
            activePage: this.activePage,
            pageSize: 1000,
        };
        this.showLoader = true;
        this.webshophttpservice.getCustomerDet().subscribe((res: any) => {
            this.showLoader = false;
            // this.getReport();
            if (res) {
                let data = res;
                // let childcustomer = data.ChildCustomers
                this.childwebshop = [];
                // this.childwebshop.push({ label: 'All Child Shop', value: 'All Child Shop' });
                data.forEach(element => {
                    const langVal = {
                        value: element.CustomerID,
                        label: element.Name,
                    };
                    this.childwebshop.push(langVal);
                });
                this.childShop = this.childwebshop[0].value;
            }
        }, _err => {
            this.showLoader = false;
        });
    }

    getCampaigns(id) {
        let param = 'pageSize=100&pageIndex=1&userId=' + id;
        this.showLoader = true;
        this.campaignHttpService.getCampaignList(param).subscribe((res: any) => {
            this.showLoader = false;
            this.campaignList = [];
            this.campaignID = '';
            if (res && res.length > 0) {
                let data = res;
                // this.campaignList.push({ label: 'All Campaign', value: 'All Campaign' });
                data.forEach(element => {
                    const c = {
                        value: element.CampaignId,
                        label: element.CampaignName,
                    };
                    this.campaignList.push(c);
                });
                this.campaignID = this.campaignList[0].value;
            }
        }, _e => {
            this.showLoader = false;
        });
    }
    // getPerPage(event) {
    //     this.perPagedata = event;
    //     this.searchCriteria.pageIndex = event;
    //     this.getSubmit(this.searchCriteria);
    //   }
    getPerPage(event) {
        console.log("Get Per Page : ", event);
        this.perPagedata = event;
        this.searchCriteria.pageIndex = event;
        this.getSubmit();
    }
    getSubmit() {
        this.activePage = 1;
        this.searchCriteria.activePage = this.activePage;
        this.filterData = this.searchCriteria;
        this.getReport(this.filterData);
        sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
        sessionStorage.setItem('searchWebshopListResult', JSON.stringify(this.filterFields));
    }

    onLoadParentshopList() {
        const searchkey = sessionStorage.getItem('searchWebshopListKey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.getReport(JSON.parse(searchkey));
        } else {
            this.searchCriteria = {
                childShopId: '',
                pageSize: 10,
                rowpageIndex: this.rowPagepage,
                activePage: this.activePage,
                pageIndex: 10
            };
            this.filterData = this.searchCriteria;
            sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
            // this.userService.loadUsers(this.searchCriteria);
            this.getReport(this.searchCriteria);
        }
    }

    paginationNum(event) {
        console.log("paginationNum : ", event);
        this.activePage = event;
        const searchkey = sessionStorage.getItem('searchWebshopListKey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.searchCriteria = JSON.parse(searchkey);
        }
        this.searchCriteria.activePage = event;
        this.perPagedata = this.searchCriteria.pageIndex;
        this.getReport(this.searchCriteria);
    }
    getReport(searchCriteria?) {
        let params = {
            'campaignID': this.campaignID,
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'customerID': this.childShop,
            "activePage": this.activePage,
            "pageSize": (searchCriteria && searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10
        }
        this.showLoader = true;
        this.reportHttpService.getCampaignUsageReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.usageReport = '';
            this.showTable = false;
            if (data && data.IsSuccess) {

                this.totalPointsCredited = data.UserReportDetails[0].TotalInitialPoints;
                this.totalPointsUsed = data.UserReportDetails[0].TotalUsedPoint;
                this.totalBalancePoints = data.UserReportDetails[0].TotalBalancePoints;
                this.columns = [
                    {
                        primaryKey: "ParentShop",
                        header: 'Parent Shop',
                        footer: true,
                        footerKey: 'Total'
                    },
                    {
                        primaryKey: "Childhop",
                        header: 'Child Shop',
                    },
                    {
                        primaryKey: "USERNAME",
                        header: 'Name'
                    },
                    {
                        primaryKey: "UserCreatedDate",
                        header: 'User Created Date',
                        format: 'date'
                    },
                    {
                        primaryKey: "CampaignName",
                        header: 'Campaign Name'
                    },
                    {
                        primaryKey: "InitialPoint",
                        header: 'Initial Point',
                        // format: 'number',
                        // footer: true,
                        // footerKey: this.totalPointsCredited
                    },
                    {
                        primaryKey: "UsedPoint",
                        header: 'Points Used',
                        // format: 'number',
                        // footer: true,
                        // footerKey: this.totalPointsUsed
                    },
                    {
                        primaryKey: "BalancePoints",
                        header: 'Balance Point',
                        // format: 'number',
                        // footer: true,
                        // footerKey: this.totalBalancePoints
                    }

                ]
                this.usageReport = data.UserReportDetails;
                this.webshopdatacount = this.usageReport[0].TOTALUSER;
                this.showTable = true;
                this.noData = false;
            } else {
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })
    }

    download() {
        this.exportData = [];

        this.usageReport.forEach(e => {
            this.exportData.push({
                'Parent Shop': e.ParentShop,
                'Child Shop': e.Childhop,
                'Name': e.USERNAME,
                'User Created Date': this.datePipe.transform(e.UserCreatedDate, 'dd/MM/yyyy'),
                'Campaign Name': e.CampaignName,
                'Initial Point': e.InitialPoint,
                'Points Used': e.UsedPoint,
                'Balance Point': e.BalancePoints,
            })
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, 'campaign-usage-report');
    }

    saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }

    getSelectedValue(event) {
        this.childwebshop.forEach((element) => {
            if (element.value === event) {
                this.domainName = element.label;
            }
        })
        if (event === 'All Child Shop') {
            this.campaignID = '';
            if (this.isParentCustomer) {
                this.domainName = '';
            }
        } else {
            this.getCampaigns(event);
        }
    }

    getCampaignSelectedValue(_event) {

    }
}