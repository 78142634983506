import { Supplier } from '../models/index';
import { SupplierActions } from '../actions/index';

export interface SupplierState {
    supplierList: Supplier[];
    suppliers: any;
}

export const initialState: SupplierState = {
    supplierList: null,
    suppliers: null
};

export function supplierReducers(state: SupplierState = initialState, action: SupplierActions.All) {

    switch (action.type) {

        case SupplierActions.LOAD_SUPPLIER_LIST_SUCCESS:
            return Object.assign({}, state, { supplierList: action.payload });

        case SupplierActions.LOAD_SUPPLIERS_SUCCESS:
            return Object.assign({}, state, { suppliers: action.payload });

        default: {
            return state;
        }
    }
}
