import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'rewardkart-admin-brand-search',
    templateUrl: './brand-search.component.html',
    styleUrls: ['./brand-search.component.scss']
})

export class BrandSearchComponent implements OnInit {
    ngOnInit(): void {

    }
}