import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable()
export class SupplierHttpService {
    loginCustomerId: any;
    webshopDetail: any;
    constructor(private apiService: ApiService) {
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            this.loginCustomerId = webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.loginCustomerId = loginData.CustomerID;
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getSupplierList(searchData: any) {
        console.log("getSupplierList searchData : ", searchData);
        let loginCustomerId;

        /** For CustomerID Query Parameter **/
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = this.webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }

        /** For IncludeChildCustomers Query Parameter **/
        let includChildShopUsers = '&IncludeChildCustomers=false';
        if (searchData.customerId === '') {
            includChildShopUsers = '&IncludeChildCustomers=true';
        }

        /** For IncludeChildCustomers Query Parameter **/
        let supplierName = '';
        if (searchData.supplierName !== undefined && searchData.supplierName !== null) {
            if (searchData.supplierName !== '') {
                supplierName = '&name=' + searchData.supplierName;
            }
        }
        const searchCriteria = {
            activePage: searchData.activePage !== undefined ? searchData.activePage : '',
            pageSize: searchData.pageSize !== undefined ? searchData.pageSize : '',
            // tslint:disable-next-line:max-line-length
            customerId: (searchData.customerId !== undefined && searchData.customerId !== '') ? searchData.customerId : this.webshopDetail.CustomerID,
        }
        // tslint:disable-next-line:max-line-length
        const supplierId = (searchData.supplierId !== null && searchData.supplierId !== undefined && searchData.supplierId !== '') ? searchData.supplierId : '';
        let path = '';
        if (supplierId !== '') {
            path = 'en/suppliers/' + searchData.supplierId + '?' +
                'pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&returnOnlyCustomerSupplier=true&customerId=' + loginCustomerId + '&LoggedInCustomerID=' + loginCustomerId;
        } else {
            // tslint:disable-next-line:max-line-length
            // path = 'en/suppliers?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&returnOnlyCustomerSupplier=true&customerId=' + searchCriteria.customerId + '&LoggedInCustomerID=' + loginCustomerId + includChildShopUsers;

            // tslint:disable-next-line:max-line-length
            path = 'en/supplierswithedit?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerId + '&LoggedInCustomerID=' + loginCustomerId + includChildShopUsers + supplierName;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        }, error => {
            return error;
        });
    }

    getCustomerSupplier(customerId = null) {
        let loginCustomerId;
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
            }
        }
        // tslint:disable-next-line:max-line-length
        const path = 'en/suppliers/CustomerSupplier?isOrderbyname=true&CustomerId=' + customerId + '&LoggedInCustomerID=' + loginCustomerId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    SupplierList(customerId = null) {
        let loginCustomerId;
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
            }
        }
        // tslint:disable-next-line:max-line-length
        // const path = 'en/suppliers/CustomerSupplier?isOrderbyname=true&CustomerId=' + customerId + '&LoggedInCustomerID=' + loginCustomerId;
        const path = 'en/suppliers?returnOnlyCustomerSupplier=true&CustomerId=' + customerId + '&LoggedInCustomerID=' + loginCustomerId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    setCustomerSupplierProductCount(data: any) {
        const path = 'en/suppliers/GetSupplierProductCount';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getWebshopSupplier(customerId = null) {
        let loginCustomerId;
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
            }
        }
        const logincustomerid = '&LoggedInCustomerID=' + loginCustomerId;
        const path = 'en/suppliers?returnOnlyCustomerSupplier=true&CustomerId=' + customerId + logincustomerid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllSuppliers() {
        const path = 'en/suppliers';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCountries() {
        const path = 'en/countries';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addSupplier(data: any) {
        let path = '';
        if (data.supplierId !== null && data.supplierId !== undefined && data.supplierId !== '') {
            path = 'en/suppliers/UpdateSupplierWithAction/' + data.supplierId;
        } else {
            path = 'en/suppliers/CreateSupplierWithAction';
        }
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    deleteSupplier(data: any) {
        const path = 'en/suppliers/' + data.SupplierID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    /**
    * Service to Check Postal Code for Selected Country
    */

    checkPostalCode(countryCode, postalCode) {
        // const userSettings = this.globalService.getUserSettings();
        const path = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCurrencies() {
        const path = 'en/currencies?isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    supplierRatingUpdata(data, supplierId, customerId) {
        let customerID = '';
        if (customerId !== '') {
            customerID = '?customerID=' + customerId;
        }
        const path = 'en/suppliers/updatequalitylevel/' + supplierId + customerID;
        return this.apiService.put(path, data).map((res) => {
            return res;
        });
    }


    setCustomerSupplier(data: any) {
        const path = 'en/suppliers/CustomerSupplier';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getCustomersList() {
        const path = 'en/customers/list';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }


    supplierDropdownWithPagination(search, customerId = null) {
        let loginCustomerId;
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
            }
        }
        const logincustomerid = '&LoggedInCustomerID=' + loginCustomerId;
        const customerID = '&customerId=' + loginCustomerId;
        // let createdByCustomerId = '';
        let childShopSupplier = '';
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            if (webshopDetail.ParentCustomerID !== null) {
                // createdByCustomerId += '&CreatedByCustomerId=' + webshopDetail.CustomerID;
                childShopSupplier = '&ReturnOnlyCustomerSupplier=true&IncludeNotMappedSuppliers=true'
            }
        }
        // tslint:disable-next-line:max-line-length
        let queryparam = `?pageIndex=${search.PageIndex}&pageSize=${search.PageSize}&Name=${search.SupplierID ? search.SupplierID : ''}`;
        if (customerId !== null && customerId !== '') {
            if (Number(customerId) === 0) {
                customerId = null;
            }
            queryparam += '&returnOnlyCustomerSupplier=true&CustomerId=' + customerId;
        }

        const path = 'en/suppliers' + queryparam + logincustomerid + customerID + childShopSupplier;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }
    supplierAutoComplete(request, customerID) {
        let loginCustomerId;
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
            }
        }
        const logincustomerid = '&LoggedInCustomerID=' + loginCustomerId;
        const customerId = '&customerId=' + loginCustomerId;

        let createdByCustomerId = '';
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            console.log("webshopDetail.ParentCustomerID : ", webshopDetail.ParentCustomerID);
            if (webshopDetail.ParentCustomerID === null && Number(webshopDetail.CustomerID) !== Number(customerID)) {
                createdByCustomerId += '&CreatedByCustomerId=' + webshopDetail.CustomerID;
            } else {
                if (webshopDetail.ParentCustomerID !== null) {
                    createdByCustomerId += '&CreatedByCustomerId=' + webshopDetail.CustomerID;
                }
            }
        }
        const path = `en/suppliers?name=${request.name}` + customerId + logincustomerid + createdByCustomerId;
        return this.apiService.get(path);
    }

    getParentCustomersList(customerId) {
        const path = 'en/customers/list?ParentCustomerID=' + customerId + '&isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomersListInclParent(customerId) {
        const path = 'en/customers/list?ParentCustomerID=' + customerId + '&returnParentCustomer=true&isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
}
