import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from '../state/services/campaign.service';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CampaignHttpService } from '../state/services/campaign.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { UserHttpService } from 'src/app/user/state/services/user.http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BillboardHttpService } from 'src/app/billboard/state/services/billboard.http.service'
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { CookieService } from 'ngx-cookie-service';
import { LoginHttpService } from 'src/app/login/state/services/login.http.service';
import { LeftSidbarComponent } from 'src/app/left-sidbar/left-sidbar.component';
import { error } from 'console';
@Component({
  selector: 'rewardkart-admin-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss'],
})
export class CampaignEditComponent implements OnInit {
  header: any;
  @ViewChild('bulkCreationModal', { static: true }) bulkCreationModal: ModalDirective;
  @ViewChild('bulkCreationModal1', { static: true }) bulkCreationModal1: ModalDirective;
  webshopDetail: any;
  campaignFormGroup: FormGroup;
  billBoardFormGroup: FormGroup;
  isVisible: any;
  disableTextbox = false;
  totalBudget: any = 0;
  balanceReward = 0;
  totalRewardPoints: any = 0;
  updateRewardPoints = 0;
  EmailList = [];
  RewardPointList = [];
  updateData = [];
  Mailvalidation = [];
  selectedImg: any;
  arrayBuffer: any;
  file: File;
  Excelupload = false;
  excelDatas = false;
  BudgetAmount = 0;
  userBudgetTransactions: any = [''];
  rewardPointsDetails: any = [''];
  TodayDate: any;
  isHidden: any;
  importData: any = [''];
  urls = [];
  Roles: any = [];
  roleName: any;
  emailVar = [];
  Comparedata = [];
  loginUserId: any;
  customerID: any;
  aprovelStatus: any;
  userBudgetList: any = [];
  loginData: any;
  radioInput: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  uploadImage: any;
  fileUpld: boolean = false;
  isParentCustomer: boolean = true;
  campaignDefaultValue = {
    CustomerID: '',
    Translations: [],
    IsVisible: false,
    CampaignExpiredFrom: '',
    CampaignExpiredTo: '',
    CampaignColor: '',
    TextColor: '',
    ProductType: 'Restricted'
  };
  date = new Date();
  public datePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
    disableUntil: {
      year: this.date.getFullYear(),
      month: this.date.getMonth() + 1,
      day: this.date.getDate() - 1,
    },
  };
  selectedProducts = [];
  campaignData: any = { TextColor: '', CampaignColor: '' };
  translations = [];
  searchResult = [];
  showImageError: Boolean = false;
  isLoading = false;
  isSaas: boolean = false;
  beneficiary: string = 'I';
  columnsNew: ColumnSetting[] = [
    {
      primaryKey: 'UserName',
      header: 'Name',
      width: '160px'
    },
    {
      primaryKey: 'InitialPoint',
      header: 'Initial Point',
      width: '160px'
    },
    {
      primaryKey: 'ExpiryDate',
      header: 'Expired From',
      format: 'date',
      width: '160px'
    },
    {
      primaryKey: 'Email',
      header: 'Email ID',
      width: '160px'
    },
  ]
  tableName = 'getRewardPoints';


  columns: ColumnSetting[] = [
    {
      primaryKey: 'languageName',
      header: 'Language',
      primaryKeyItem: 'languageName',
      style: { colborder: 'col-border' },
    },
    {
      primaryKey: 'PropertyValue',
      header: 'Campaign Name*',
      primaryKeyItem: 'PropertyValue',
      format: 'access',
      style: { colborder: 'col-border' },
    },
  ];
  languageList = {};
  saveButtonLabel = 'Save';
  campaignID: number = 0;
  campaignNameEmpty: any;
  campaignImage: any;
  status: boolean = false;
  billboardImageURL: any;
  isEdit: boolean = false;
  campaignEdit: boolean = false;
  excelFile: any;
  transactionID: any;
  pbase:string;
  CID: string;
  atomID: string;
  bankRefNo: string;
  SaasPaymentSuccess: string = "";
  SaasAtomId:any;
  SaasbankRefNum:any;
  saasPayment:boolean = false;
  payClicked: boolean = false;
  paymentData: any;
  discountValue: any;
  totalAmt: any;
  amtPayable: any;
  @ViewChild('sideBar', { static: true }) sideBar : LeftSidbarComponent;
  saaSTransId: any;
  saascampaignUsername: any;
  discountAmt: any;
  additionalCost: boolean = false;
  addCost: any;
  planId:any;
  userAdditionalCost:any;
  userAdditionalLimit: any;
  constructor(
    public formBuilder: FormBuilder,
    public campaignService: CampaignService,
    public campaignHttpService: CampaignHttpService,
    public billboardHttpService: BillboardHttpService,
    public apiService: ApiService,
    public toaster: ToastService,
    public router: Router,
    private sanitizer: DomSanitizer,
    private userHttpService: UserHttpService,private cookieService: CookieService, private loginHttpService: LoginHttpService,
  ) {
    const userDetails = JSON.parse(sessionStorage.getItem('userdetails'));
    if(userDetails.ISSaas === "Y"){
      this.isSaas = true;
      if(userDetails.SubScriptionId && userDetails.Additionaluser && userDetails.Additionalusercost){
        this.planId = userDetails.SubScriptionId;
        this.userAdditionalCost  = userDetails.Additionalusercost;
        this.userAdditionalLimit = userDetails.Additionaluser;
      }
    }else{
      this.isSaas = false;
    }
    let domain = (location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[1];
        if (domain === 'rewardkartadmin') {
            this.pbase =  'https://rewardkartapi.in:8061/'
        }
        else if(domain === 'uk-constient'){
          this.pbase =  'https://uk-constient.com:8885/'
        }
        else{
            this.pbase = 'https://dotnet.constient.com/RewardKartPayWeb/';
        }
   }

  ngOnInit() {

    let isEdit:boolean = JSON.parse(sessionStorage.getItem("saasEdit"));
    if(this.isSaas && isEdit){
      let SaasTransID = sessionStorage.getItem("SaasTransID");
      let status = sessionStorage.getItem("status");
      // let saasStatus = sessionStorage.getItem("SaasPayment")
      
        setTimeout(() => {
          if(SaasTransID){
            if(status && status === "Y"){
              this.updatePaymentSaasUser();
            }
            else if(status && status !== "Y"){
              // this.toaster.error("Payment Failed");
              this.paymentFailed();
            }
          }
          
        }, 1500);
      }
    let saasStatus = sessionStorage.getItem("SaasPayment");
    let saasAtomId = sessionStorage.getItem("SaasatomID");
    let SaasbankRefNo = sessionStorage.getItem("SaasbankRefNo");
    if(saasStatus && !isEdit){
      let status = sessionStorage.getItem("status");
      setTimeout(() =>{
        if(status && status === "Y"){
          this.updateSassPayment();
        }
        else if(status && status !== "Y"){
          // this.toaster.error("Payment Failed");
          this.paymentFailed();
        }
      },500)
      
    }
    
    
    
    this.aprovelStatus = 'Pending'
    this.Excelupload = false;
    this.excelDatas = false;
    this.loginData = this.apiService.getLoginData();
    if (this.loginData !== undefined && this.loginData !== null && this.loginData !== '') {
      this.loginUserId = this.loginData.UserID;
      this.customerID = this.loginData.CustomerID;
      this.Roles = this.loginData.Roles;
      //console.log(this.Roles[0].RoleName)
      this.roleName = this.Roles[0].RoleName
      //console.log(this.roleName)
    }
    this.getUserTransactions();
    this.getUserbudgetList();
    this.isHidden = true;
    this.TodayDate = (this.date.getFullYear() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.createForm(this.campaignDefaultValue);
    this.getLanguages();
    const id = history.state.id;
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (getWebshopDetails.DomainName !== getWebshopDetails.ParentCustomerDomainName) {
      this.isParentCustomer = false;
    } else {
      this.isParentCustomer = true;
    }
    if (id) {
      this.campaignID = id;
      this.isEdit = true;
      if(this.isSaas){
        sessionStorage.setItem("saasEdit",JSON.stringify(this.isEdit));
        sessionStorage.setItem("saasCampaignID",JSON.stringify(this.campaignID));
      }
      if (history.state.status === 'Aproved' || history.state.status === 'Approved' || this.isParentCustomer) {
        this.status = true;
        this.campaignFormGroup.controls['CampaignName'].disable();
        this.campaignFormGroup.controls['CampaignExpiredFrom'].disable();
        this.campaignFormGroup.controls['CampaignExpiredTo'].disable();
        this.campaignFormGroup.controls['UnitRate'].disable();
        this.campaignFormGroup.controls['CampaignPoint'].disable();
        this.campaignFormGroup.controls['Product'].disable();
        this.campaignFormGroup.controls['ProductType'].disable();
      }
      this.saveButtonLabel = 'Update';
      this.campaignImage = history.state.icon;
      if(this.isSaas){
        sessionStorage.setItem("saasIconimage",this.campaignImage);
      }
      if (this.campaignImage) {
        let i = this.campaignImage.split('CampaignIcon/');
        this.uploadImage = i[1];
      }
      this.getCampaignData(id);
    } else {
      this.isEdit = false;
      if(this.isSaas){
        sessionStorage.setItem("saasEdit",JSON.stringify(this.isEdit))
      }
    }
  }

  handleproudctsChange(addStatus: Boolean) {
    if (addStatus) {
      const { id } = history.state;
      this.getCampaignData(id);
    }
  }

  getLanguages() {
    this.campaignHttpService.getLanguages().subscribe(
      (languageResponse: any) => {
        if (languageResponse) {
          let translations = [];
          let getData = {};
          languageResponse.forEach((language, index) => {
            let langcode = language.Code;
            getData[langcode] = { name: language.Name, index: index };
            translations.push({
              LanguageID: language.LanguageID,
              LanguageCode: language.Code,
              ObjectType: 'ProductCampaigns',
              languageName: language.Name,
              PropertyName: 'ProductCampaignName',
              PropertyValue: '',
              Action: 'Insert',
            });
          });
          this.languageList = getData;
          this.translations = translations;
        }
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }

  getCampaignData(id) {
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (getWebshopDetails.DomainName !== getWebshopDetails.ParentCustomerDomainName) {
      this.isParentCustomer = false;
    } else {
      this.isParentCustomer = true;
    }
    const request = {
      CustomerID: this.customerID,
      id: id,
    };
    this.loginData = true;
    this.campaignHttpService.getCampaignDataByCampaignId(request).subscribe((data) => {
      this.loginData = false;
      if (data) {
        this.campaignData = data[0];
        if(this.isEdit && this.isSaas){
          sessionStorage.setItem("SaasCampaignName",this.campaignData.CampaignDesc);
        }
        this.campaignData.Translations.forEach((item: any) => {
          const languageToMap = this.languageList[item.LanguageCode];
          this.translations[languageToMap.index] = {
            ...this.translations[languageToMap.index],
            ...item,
            Action: 'Update',
            languageName: languageToMap.name,
          };
        });
        this.campaignData.CampaignExpiredFrom = this.campaignData.CampaignExpiredFrom ? this.customDateFormat(this.campaignData.CampaignExpiredFrom) : '';
        this.campaignData.CampaignExpiredTo = this.campaignData.CampaignExpiredTo ? this.customDateFormat(this.campaignData.CampaignExpiredTo) : '';
        this.campaignEdit = true;
        this.createForm(this.campaignData);
      }
    }, error => {
      this.loginData = false;
      this.toaster.error(error.error);
    });
  }

  getTwoDigitDateFormat(monthOrDate) {
    return monthOrDate < 10 ? '0' + monthOrDate : '' + monthOrDate;
  }

  customDateFormat(data) {
    const getDate = new Date(data);
    const dateFormat =
      this.getTwoDigitDateFormat(getDate.getDate()) +
      '/' +
      this.getTwoDigitDateFormat(getDate.getMonth() + 1) +
      '/' +
      getDate.getFullYear();
    return dateFormat;
  }

  createForm(campaignItem) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (
      webshop !== null &&
      webshop !== undefined &&
      webshop !== '' &&
      webshop !== 'undefined'
    ) {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    const {
      CampaignName,
      CampaignDesc,
      CampaignPoint,
      IsVisible,
      CampaignExpiredFrom,
      CampaignExpiredTo,
      CampaignId,
      UnitRate,
      Product,
      ProductType,
      balRewards,
    } = campaignItem;
    this.campaignFormGroup = this.formBuilder.group({
      CampaignName: [CampaignName],
      CampaignDesc: [CampaignDesc],
      CampaignPoint: [CampaignPoint],
      UserID: [this.loginUserId, Validators.required],
      IsVisible: [IsVisible || false],
      CampaignExpiredFrom: [CampaignExpiredFrom || ''],
      CampaignExpiredTo: [CampaignExpiredTo || ''],
      UnitRate: [1],
      Product: Product,
      ProductType: [ProductType],
      draganddrop: [],
      balRewards: 0,

    });

    this.billBoardFormGroup = this.formBuilder.group({
      ImageName: ['', Validators.required],
      AdURL: ['', Validators.required],
      VisibleOnLinkNetwork: ['']
    });

    if (this.campaignID != null || this.campaignID != 0) {
      this.disableTextbox = true;
      this.getUserRewardPoints()
    }
  }

  validateCampaignName(prop, data) {
    let result = -1;
    data.forEach((obj, index) => {
      if (prop in obj && obj[prop] !== '') {
        result = index;
        return false;
      }
    });
    return result;
  }

  validateForm() {
    if (this.campaignFormGroup.valid) {
      const campaigns = {
        CampaignId: this.campaignID,
        Icon: this.uploadImage,
        CampaignName: this.campaignFormGroup.value.CampaignName,
        CampaignDesc: this.campaignFormGroup.value.CampaignName,
        CampaignPoint: this.campaignFormGroup.value.CampaignPoint,
        validFrom: this.campaignFormGroup.value.CampaignExpiredFrom === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredFrom),
        validTo: this.campaignFormGroup.value.CampaignExpiredTo === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredTo),
        IsActive: 1,
        CreatedBy: this.campaignFormGroup.value.UserID,
        UnitRate: this.campaignFormGroup.value.UnitRate,
        Product: this.campaignFormGroup.value.Product,
        ProductType: this.campaignFormGroup.value.ProductType,
        balRewards: this.campaignFormGroup.value.balRewards,
        Aprovel: this.aprovelStatus,
      };
      this.isLoading = true;
      this.submitForm(campaigns);
    } else {
      this.toaster.error('Please Enter Campaign Name');
    }
  }

  dateFormatchange(value) {
    if (value !== '') {
      const myDate = moment(value, 'DD/MM/YYYY').format('MM/DD/YYYY');
      return myDate;
    } else {
      return value;
    }
  }

  updtCampaign(formValue) {
    this.campaignHttpService.updateCampaign(formValue).subscribe((editResponse) => {
      if (!this.Excelupload) {
        this.isLoading = false;
        this.router.navigate(['/admin-campaign-list'], { replaceUrl: false });
      } else {
        if (!this.updateRewardPoints) {
          this.toaster.error("Users already exist or insufficient budget allocation");
          this.isLoading = false;
        }
        else if (this.updateRewardPoints > this.campaignFormGroup.value.balRewards) {
          this.toaster.error("Budget allocation exceeded");
          this.isLoading = false;
        }
        else {
          this.updateUsers(formValue);
        }
      }
    }, error => {
      this.toaster.error(error.error);
    });
  }

  submitForm(formValue) {
    if (typeof formValue.IsVisible === 'undefined') {
      formValue.IsVisible = false;
    }
    if (this.campaignID) {
      if (this.uploadImage) {
        this.updtCampaign(formValue);
      } else {
        if (!this.Excelupload) {
          this.toaster.error("Please Upload The Excel First");
          this.isLoading = false;
        }
        else if (!this.updateRewardPoints) {
          this.toaster.error("Users already exist or insufficient budget allocation");
          this.isLoading = false;
        }
        else if (this.updateRewardPoints > this.campaignFormGroup.value.balRewards) {
          this.toaster.error("Budget allocation exceeded");
          this.isLoading = false;
        }
        else {
          this.updtCampaign(formValue);
        }
      }

    }
    else {
      if (this.totalBudget < this.campaignFormGroup.value.CampaignPoint) {
        this.isLoading = false;
        this.toaster.error("You Don't Have Sufficiant Budget, Please Purchse More Budget ")
      }
      else {
        this.campaignHttpService.addCampaign(formValue).subscribe(
          (addResponse: any) => {
            if(this.isSaas){
              sessionStorage.removeItem("Saasmessage");
              // sessionStorage.removeItem("SaasTransID");
              // sessionStorage.removeItem("SaasatomID");
              sessionStorage.removeItem("SaasReq");
              // sessionStorage.removeItem("saasTransId");
              // sessionStorage.removeItem("SaasbankRefNo");
              // sessionStorage.removeItem("SaasPayment");
              // this.sideBar.ngOnInit();
            }
            this.addUserBudgetTransaction();
            this.campaignID = addResponse;
            if(this.campaignID && this.isSaas){
              let loggedInData = JSON.parse(sessionStorage.getItem("userdetails")); 
              let userName = JSON.parse(sessionStorage.getItem("saasCampaignUserName"));
              let users = [];
              userName.forEach(obj => {
                const { UserName, UserId } = obj; // Extract UserName and CampaignUserId from each object
                users.push({ UserName, UserId });  // Push them into the users array
              });

              let params = {
                "Users": users.map(user => ({
                  "UserName": user.UserName,
                  "CampaignUserId": user.UserId,
                  "CampaignId": this.campaignID
                }))
              };
              // console.log(params,"new Params");
              
              this.campaignHttpService.updateCampaignID(params).subscribe((data:any) =>{
                if(data){
                  console.log(data);
                  
                }
              })
            }
            this.saveButtonLabel = 'Update';
            this.campaignData.Products = [];
            this.toaster.success('Campaign Added Successfully');
            this.getCampaignData(addResponse);
            this.updateUsers(formValue);
            setTimeout(() => {
              if (this.campaignFormGroup.value.balRewards) {
                this.userHttpService.setBudget(this.campaignFormGroup.value.balRewards)
              }
              this.router.navigate(['/admin-campaign-list'], { replaceUrl: false });
            }, 1000)
          },
          (error) => {
            this.isLoading = false;
            this.toaster.error(error.error);
            if(this.isSaas){
              // location.reload();
              sessionStorage.removeItem("Saasmessage");
              // sessionStorage.removeItem("SaasTransID");
              sessionStorage.removeItem("SaasatomID");
              sessionStorage.removeItem("SaasReq");
              // sessionStorage.removeItem("saasTransId");
              // sessionStorage.removeItem("SaasbankRefNo");
              // sessionStorage.removeItem("SaasPayment");
              // this.sideBar.ngOnInit();
            }
          }
        );
      }

    }

  }

  onSelectFile(event) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.jpeg' && getImg.toLowerCase() !== '.jpg' && getImg.toLowerCase() !== '.png' && getImg.toLowerCase() !== '.gif')) {
      this.showImageError = true;
    } else {
      this.showImageError = false;
      if (event.target.files && event.target.files[0]) {
        this.isLoading = true;
        var filesAmount = event.target.files.length;

        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();

          reader.onload = (event: any) => {
            const imgurl = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);

            this.selectedImg = event.target.result;

            this.urls.push(event.target.result);
          }

          reader.readAsDataURL(event.target.files[i]);
          let httpHeaders = new HttpHeaders();
          httpHeaders.set('Content-Type', null);
          httpHeaders.set('Accept', "multipart/form-data");
          const formData = new FormData();
          formData.append('file', event.target.files[0]);
          formData.append('filePath', 'CampaignIcon');
          event.target.value = '';
          //this.userService.loaduploadFile(event.target.files[0].name, httpParams, httpHeaders);
          this.campaignHttpService.uploadFile(formData).subscribe((data: any) => {
            if (data) {
              this.uploadImage = data[1];
              this.campaignImage = data[2];
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            this.toaster.error(error.error);
          })
        }
      }
    }
  }



  onImportUser(event) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.xlsx' && getImg.toLowerCase() !== '.xls' && getImg.toLowerCase() !== '.csv')) {
      this.showImageError = true;
      return;
    } else {
      this.Excelupload = true;
      this.showImageError = false;
      this.file = event.target.files[0];
      event.target.value = '';
      this.Upload();
    }
  }

  createUsers() {

    if (this.file) {
      let httpHeaders = new HttpHeaders();
      httpHeaders.set('Content-Type', null);
      httpHeaders.set('Accept', "multipart/form-data");
      const formData = new FormData();
      formData.append('CustomerId', this.webshopDetail.CustomerID);
      formData.append('Domain', this.webshopDetail.DomainName);
      formData.append('CampaignId', this.campaignID.toString());
      formData.append('File', this.file);
      this.isLoading = false;
      this.userHttpService.loaduploadFile(formData).subscribe((data: any) => {
        if (data.creationError) {
          let l = data.creationError.length;
          for (let i = 0; i < l; i++) {
            this.toaster.warning(data.creationError[i].UserName + ' ' + data.creationError[i].Error);
          }
          //this.toast.error('Please provide data in valid format');

        } else {
          this.fileUpld = false;
          this.bulkCreationModal.hide();
          this.toaster.success('File imported successfully');
        }
        this.toaster.success('Campaign updated.');
        if (this.campaignFormGroup.value.balRewards) {
          this.userHttpService.setBudget(this.campaignFormGroup.value.balRewards)
        }
        this.router.navigate(['/admin-campaign-list'], { replaceUrl: false });
        /*if (this.isEdit) {
          this.userHttpService.updateRewards(this.updateData).subscribe((data: any) => {
            this.toaster.success('Campaign updated.');

            this.router.navigate(['/admin-campaign-list'], { replaceUrl: false });
          },
            error => {
              this.toaster.error(error.error.Message);
            });
        } else {
          this.toaster.success('Campaign updated.');

          this.router.navigate(['/admin-campaign-list'], { replaceUrl: false });
        }*/

      }, _error => {
        this.toaster.error('Please provide data in valid format');
      });

    }
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      const ExcelFile = XLSX.utils.sheet_to_json(worksheet, { raw: true })
      this.excelFile = ExcelFile;
      console.log(this.excelFile,"this.excelFile")

      this.importData = [...new Map(ExcelFile.map((m) => [m['Email ID'], m])).values()];

      this.Comparedata = [];

      if (this.rewardPointsDetails != "") {
        //console.log("Good")
        let EmailList = []
        let EmailList1 = []
        this.importData.forEach(data => EmailList.push(data['Email ID']))
        //console.log(EmailList, "List")
        this.rewardPointsDetails.forEach(data => EmailList1.push(data.Email))
        //console.log(EmailList1, "List1")
        var filteredKeywords = EmailList.filter((word) => !EmailList1.includes(word));
        //console.log(filteredKeywords)
        let excelData = [];
        this.importData.map((a: any) => {
          filteredKeywords.map((b: any) => {
            if (b == a['Email ID']) {
              excelData.push(a)
            }
          })
        })

        this.Comparedata = excelData;
        this.fileUpld = false;
        this.bulkCreationModal.hide();
        this.toaster.success('Users imported successfully');
        this.rewardPointsValidation();
      }
      else {
        this.Comparedata = this.importData;
        this.rewardPointsValidation();
      }
      //console.log(this.Comparedata, "Excel")
    }


    fileReader.readAsArrayBuffer(this.file);
  }

  approve() {

    this.aprovelStatus = 'Aproved';
    console.log(this.rewardPointsDetails)
    if (this.rewardPointsDetails.length > 0) {
      this.approvelReject()
    } else {
      this.toaster.error('User list is empty');
    }

  }
  reject() {
    this.aprovelStatus = 'Reject';
    this.approvelReject()

  }

  approvelReject() {
    if (this.campaignFormGroup.valid) {

      const campaigns = {
        CampaignId: this.campaignID,
        Icon: this.uploadImage,
        CampaignName: this.campaignFormGroup.value.CampaignName,
        CampaignDesc: this.campaignFormGroup.value.CampaignName,
        CampaignPoint: this.campaignFormGroup.value.CampaignPoint,
        validFrom: this.campaignFormGroup.value.CampaignExpiredFrom === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredFrom),
        validTo: this.campaignFormGroup.value.CampaignExpiredTo === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredTo),
        IsActive: 1,
        CreatedBy: this.campaignFormGroup.value.UserID,
        UnitRate: this.campaignFormGroup.value.UnitRate,
        Product: this.campaignFormGroup.value.Product,
        ProductType: this.campaignFormGroup.value.ProductType,
        balRewards: this.campaignFormGroup.value.balRewards,
        Aprovel: this.aprovelStatus,
      };

      this.campaignHttpService.updateCampaign(campaigns).subscribe(
        (editResponse) => {
          if (this.aprovelStatus == 'Aproved') {
            this.toaster.success('Approved successfully!');
          }
          else {
            this.toaster.success('Campaign rejected!');
          }
        },
        (error) => {
          //this.toaster.error('Unable to update campaign');
          this.toaster.error(error.error);
        }
      );
    }
  }

  goBack() {
    this.router.navigate(['/admin-campaign-list'], { replaceUrl: false });
  }

  addBulkUser() {
    this.fileUpld = true;
    this.bulkCreationModal.show();
  }
  paymentFailed(){
    this.isLoading = true;
    let loggedInData = JSON.parse(sessionStorage.getItem("userdetails"));
    let params = {
      "IsCancel" :"Y",
      "UserId" : loggedInData.UserID ? loggedInData.UserID : ""
    }
    this.userHttpService.cancelPayment(params).subscribe((data:any) => {
      this.isLoading = false;
      if(data && data.IsSuccess){
        this.toaster.error(data.Message);
        sessionStorage.removeItem("SaasatomID");
        sessionStorage.removeItem("SaasbankRefNo");
        sessionStorage.removeItem("SaasTransID");
        sessionStorage.removeItem("SaasPayment");
        sessionStorage.removeItem("SaasbankRefNo");
        this.goBack();
      }else{
        this.goBack();
      }
    },(error) =>{
      this.toaster.error(error.error.Message || "Something Went Wrong!");
    }
  )
  }

  updatePaymentSaasUser(){
    this.isLoading = true;
    let loggedInData = JSON.parse(sessionStorage.getItem("userdetails")); 
    let transid = sessionStorage.getItem("SaasTransID");
    let Status = sessionStorage.getItem("status");
    let AtomId = sessionStorage.getItem("atomID");
    let bankRefNo = sessionStorage.getItem("bankRefNo");
    let additionalCost = sessionStorage.getItem("additionalCost");
    let totalAmt = sessionStorage.getItem("TotalAmt");
    let params = {
      "TransId": transid,
      "PaymentStatus":Status,
      "AtomTranId":AtomId,
      "BankRefNo":bankRefNo,
      "UserId":loggedInData ? loggedInData.UserID : "",
      "SasscreatedBY": loggedInData.UserID ? loggedInData.UserID : "",
      "Additionalusercost": additionalCost ? additionalCost : "",
      "Amount": totalAmt ? totalAmt : ""
    }
    this.userHttpService.updateSaasPayment(params).subscribe((data:any) => {
      this.isLoading = false
      if(data.IsSuccess){
          sessionStorage.removeItem("SaasatomID");
          sessionStorage.removeItem("SaasbankRefNo");
          sessionStorage.removeItem("SaasTransID");
          sessionStorage.removeItem("SaasPayment");
          sessionStorage.removeItem("SaasbankRefNo");
        setTimeout(() => {
          this.updateSaasRewardPoints();
        }, 1000);
        this.toaster.success(data.Message);
        let id = JSON.parse(sessionStorage.getItem("saasCampaignID"));
        this.campaignID = id;
        this.isEdit = true;
        this.status = true;
        this.campaignFormGroup.controls['CampaignName'].disable();
        this.campaignFormGroup.controls['CampaignExpiredFrom'].disable();
        this.campaignFormGroup.controls['CampaignExpiredTo'].disable();
        this.campaignFormGroup.controls['UnitRate'].disable();
        this.campaignFormGroup.controls['CampaignPoint'].disable();
        this.campaignFormGroup.controls['Product'].disable();
        this.campaignFormGroup.controls['ProductType'].disable();
      
      this.saveButtonLabel = 'Update';
      this.campaignImage = sessionStorage.getItem("saasIconimage");
      if (this.campaignImage) {
        let i = this.campaignImage.split('CampaignIcon/');
        this.uploadImage = i[1];
      }
      this.getCampaignData(id);
      }else{
        this.toaster.error("Something Went Wrong!!")
      }
    })
  }

  updateSassPayment(){
    
      this.isLoading = true;
      let transid = sessionStorage.getItem("SaasTransID");
      let saasStatus = sessionStorage.getItem("SaasPayment");
      let saasAtomId = sessionStorage.getItem("SaasatomID");
      let SaasbankRefNo = sessionStorage.getItem("SaasbankRefNo");
      let loggedInData = JSON.parse(sessionStorage.getItem("userdetails")); 
      let saasPayload = JSON.parse(sessionStorage.getItem("SaasReq"));
      let additionalCost = sessionStorage.getItem("additionalCost");
      let totalAmt = sessionStorage.getItem("TotalAmt");
      let params = {
        "TransId": transid,
        "PaymentStatus": saasStatus,
        "AtomTranId": saasAtomId, 
        "BankRefNo": SaasbankRefNo,
        "UserId": loggedInData ? loggedInData.UserID : "",
        // "validFrom": saasPayload.validFrom,
        // "validTo": saasPayload.validTo,
        "SasscreatedBY": loggedInData.UserID,
        "Additionalusercost": additionalCost ? additionalCost : "",
        "Amount": totalAmt ? totalAmt : ""
      }
  
      this.userHttpService.updateSaasPayment(params).subscribe((data:any) => {
        this.isLoading = false;
        if(data.IsSuccess){
          this.saasPayment = true;
          this.updateSaasRewardPoints();
          sessionStorage.removeItem("SaasatomID");
          sessionStorage.removeItem("SaasbankRefNo");
          sessionStorage.removeItem("SaasTransID");
          sessionStorage.removeItem("SaasPayment");
          sessionStorage.removeItem("SaasbankRefNo");
          // this.validateForm();
          
          // console.log(saasPayload,"saasPayload")
          saasPayload.CustomerId = loggedInData.CustomerID;
          saasPayload.SasscreatedBY = loggedInData.UserID;
          saasPayload.SassTransAcNo = sessionStorage.getItem("saasTransId");
          this.submitForm(saasPayload);
        }else{
          this.saasPayment = false;
        }
      }, error => {
        this.isLoading = false;
        for (const key in error.error.ModelState) {
          if (error.error.ModelState.hasOwnProperty(key)) {
              const errors = error.error.ModelState[key];
              errors.forEach(errorMessage => {
                  this.toaster.error(errorMessage);
              });
          }
      }
      })
    
    

  }
  updateSaasRewardPoints(){
    this.isLoading = true;
    let req = JSON.parse(sessionStorage.getItem("saasGetUserDetails"));
    let campaign = JSON.parse(sessionStorage.getItem("SaasReq"));
    let cName = "";
    if(campaign){
      cName = campaign.CampaignName;
    }
    let val = sessionStorage.getItem("SaasCampaignName");
    req.forEach(user => {
      user.CampaignName = cName && !this.isEdit ? cName : this.isEdit && val ? val : "";
    });
    let params = {
      "userlist": req ? req : ""
    }
    this.userHttpService.updateSaasRewardPoints(params).subscribe((data:any) => {
      this.isLoading = false;
      if(data){
        console.log(data);
      }
    })
  }

  inrFormat(val: number) {
    return Number(val).toLocaleString("en-IN");
  }

  saasTransaction(){
    this.isLoading = true;
    let loggedInData = JSON.parse(sessionStorage.getItem("userdetails"));
    this.userHttpService.saastransaction(loggedInData.UserID).subscribe((data:any) => {
      // console.log(data,"adfssdafdata");
      this.isLoading = false;
      if(data){
        this.saaSTransId = data[0].SassTransAcNo;
        if(this.saaSTransId){
          this.userUpload(this.saaSTransId);
        }
      }
    },(error) => {
      this.isLoading = false;
      this.toaster.error(error.error.Message || "Something Went Wrong");
    }
  )
  }

  userUpload(transID){
    if(this.excelFile.length > 0){
      let loggedInData = JSON.parse(sessionStorage.getItem("userdetails"));
      const formData = new FormData();
      formData.append("CustomerId", loggedInData.CustomerID);
      formData.append("Domain", loggedInData.DomainName);
      formData.append("CampaignId", this.campaignID.toString());
      formData.append("SasscreatedBY", loggedInData.UserID);
      formData.append("SassTransAcNo", transID);
      formData.append("SubscriptionPlanId", loggedInData.SubScriptionId);
      formData.append('file', this.file);
      
      this.loginHttpService.getUser().subscribe(userdata => {
        this.cookieService.set('payment', userdata.password);
      });
      // CustomerID
      this.isLoading = true;
      this.userHttpService.isSaasPayment(formData).subscribe((data:any)=>{
        this.isLoading = false;
        console.log("data",data)
        if(data.IsSuccess){
          this.payClicked = true;
          this.transactionID = data.TransAcNo;
          if(data.userlist.length >= 1){
            sessionStorage.setItem("saasGetUserDetails",JSON.stringify(data.userlist));
          }
          setTimeout(() => {
            this.paymentData = data.userlist;
            // console.log(this.paymentData.length);
            if(data.AdditionalUserAmount){
              this.additionalCost = true;
              this.addCost = data.AdditionalUserAmount;
              sessionStorage.setItem("additionalCost",this.addCost);
            }else{
              this.additionalCost = false;
            }
            // if(this.planId){
            //   if(this.paymentData.length > this.userAdditionalLimit){
            //       this.addCost = (this.paymentData.length - this.userAdditionalLimit) * this.userAdditionalCost;
            //       this.additionalCost = true;
            //       if(this.addCost){
            //         sessionStorage.setItem("additionalCost",this.addCost);
            //       }
            //   }
            //   else{
            //     this.additionalCost = false;
            //   }
            // }
            if(this.paymentData){
              sessionStorage.setItem("saasCampaignUserName", JSON.stringify(this.paymentData));
            }
            this.totalAmt = data.TotalAmount;
            this.amtPayable = data.DiscountAmount;
            if(this.amtPayable){
              // console.log("total amout",this.amtPayable)
              sessionStorage.setItem("TotalAmt",this.amtPayable);
            }
            this.discountValue = data.DiscountPercentage;
            // this.discountAmt = data.TotalAmount - data.DiscountAmount;
            this.discountAmt = (data.TotalAmount  * data.DiscountPercentage)/100;
            // data.DiscountPercentage = data.DiscountPercentage.toFixed(2);
            // this.discountValue = Math.round(this.discountValue);
            // console.log(this.discountValue,"this.discountValue")
          }, 0);
          // this.paymentData = data;
          
          if(this.transactionID){
            sessionStorage.setItem("saasTransId",this.transactionID)
          }
          // this.validateSaas();
          if (this.campaignFormGroup.valid) {
            const campaigns = {
              CampaignId: this.campaignID,
              Icon: this.uploadImage,
              CampaignName: this.campaignFormGroup.value.CampaignName,
              CampaignDesc: this.campaignFormGroup.value.CampaignName,
              CampaignPoint: this.campaignFormGroup.value.CampaignPoint,
              validFrom: this.campaignFormGroup.value.CampaignExpiredFrom === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredFrom),
              validTo: this.campaignFormGroup.value.CampaignExpiredTo === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredTo),
              IsActive: 1,
              CreatedBy: this.campaignFormGroup.value.UserID,
              UnitRate: this.campaignFormGroup.value.UnitRate,
              Product: this.campaignFormGroup.value.Product,
              ProductType: this.campaignFormGroup.value.ProductType,
              balRewards: this.campaignFormGroup.value.balRewards,
              Aprovel: this.aprovelStatus,
            };
            let saasreq = JSON.stringify(campaigns)
            if(saasreq && !this.isEdit){
              sessionStorage.setItem("SaasReq",saasreq);
            }
           
          }else{
            this.toaster.error("Please Enter Campaign name")
          }
          
          if(this.transactionID){
            sessionStorage.setItem("SaasTransID",this.transactionID)
          }
          



          // this.excelFile[0].TransactionId = this.transactionID;
          //this.userModal.show();
          // this.payClicked = true;
          // this.paymentData = data;
          // this.discountValue = (data.TotalAmount  * data.DiscountPercenate)/100;
          // data.DiscountPercenate = data.DiscountPercenate.toFixed(2);
          // this.discountValue = Math.round(this.discountValue);
        }
      }, error => {
        this.isLoading = false;
        for (const key in error.error.ModelState) {
          if (error.error.ModelState.hasOwnProperty(key)) {
              const errors = error.error.ModelState[key];
              errors.forEach(errorMessage => {
                  this.toaster.error(errorMessage);
              });
          }
      }
      })

    }else{
      this.toaster.error('Please provide user data')
    }
  }
  // getSaasUserDetails(){
  //   let loggedInData = JSON.parse(sessionStorage.getItem("userdetails"));
  //   let users = JSON.parse(sessionStorage.getItem("saasGetUserDetails"));
  //   let data = {
  //       "userlist": users ? users : ""
  //   }4
  //   this.userHttpService.getSaasUsers(data).subscribe((data:any) => {
  //     if(data.IsSuccess){
  //       this.paymentData = data.Data;
  //       if(this.paymentData){
  //         sessionStorage.setItem("saasCampaignUserName", JSON.stringify(this.paymentData));
  //       }
  //     }
  //   })
  // }

  makePayment(){
    this.isLoading = true;
    let loggedInData = JSON.parse(sessionStorage.getItem("userdetails"));
    this.userHttpService.webTinyUrl().subscribe(dataweb =>{
      let query = "OrderId="+ this.transactionID +"&customerID="+loggedInData.UserID+"&IsRewardKart=Y&Domain="+loggedInData.DomainName+"&Pre=SASS&ReturnURL="+dataweb+"&Email="+loggedInData.Email+"&Mobile="+loggedInData.PhoneNumber+"&campaignID=298";
      sessionStorage.setItem('subscriptionid', this.transactionID);
      const paymentURL = this.pbase + 'AtomPayment/AtomPaymentRequest?' + query;
      this.isLoading = false;
      window.location.href = paymentURL;   
    });
  }

  validateSaas(){
    this.isLoading = true;
    let params = {
      "TransId": this.transactionID,
      "validFrom": this.campaignFormGroup.value.CampaignExpiredFrom === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredFrom),
      "validTo": this.campaignFormGroup.value.CampaignExpiredTo === undefined ? this.dateFormatchange(new Date()) : this.dateFormatchange(this.campaignFormGroup.value.CampaignExpiredTo),
    }
    this.userHttpService.validateSaas(params).subscribe((data:any)=>{
      this.isLoading = false;
      console.log(data)
    }, error => {
      this.isLoading = false;
      for (const key in error.error.ModelState) {
        if (error.error.ModelState.hasOwnProperty(key)) {
            const errors = error.error.ModelState[key];
            errors.forEach(errorMessage => {
                this.toaster.error(errorMessage);
            });
        }
    }
    })
  }
  getParameterByName(name, url) {
    //if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
    results = regex.exec(url);
    if (!results)
      return null;
    if (!results[2])
      return '';
    return results[2].replace(/\+/g, " ");
  }
  handleProductSelection(event) {
    if (event.operation === 'Add') {
      this.addProductToCampaign(event);
    }
  }

  addProductToCampaign(event) {
    const {
      product: { ProductID },
      index,
    } = event;
    const loginData = this.apiService.getLoginData();
    const userID = loginData.UserID;
    this.campaignService.addProductToCampaign(this.campaignID, ProductID, userID).subscribe((res) => {
      if (res) {
        this.toaster.success('Product added to campaign successfully');
        this.getCampaignData(this.campaignID);
      } else {
        this.toaster.error('Unable to add product');
        this.campaignData.Products.splice(index, 1);
      }
    }, (_error) => {
      this.campaignData.Products.splice(index, 1);
      this.toaster.error('Unable to add product');
    }
    );
  }


  deleteProductFromCampaign(event) {
    const {
      product: { ProductID },
      index,
    } = event;
    const userID = this.loginData.UserID;
    this.campaignService.removeProductFromCampaign(this.campaignID, ProductID, userID).subscribe((res) => {
      if (res) {
        this.campaignData.Products.splice(index, 1);
        this.toaster.success('Product deleted from campaign successfully');
        this.getCampaignData(this.campaignID);
      } else {
        this.toaster.error('Unable to delete product');
      }
    },
      (error) => {
        this.toaster.error('Unable to delete product');
      }
    );
  }

  clickRestircted(ev) {
    this.radioInput = ev.target.value
    this.isHidden = "false";
  }

  updateUsers(formValue) {
    let FormexpairyDate = [];
    let mcampainID = [];
    let camactive = [];
    let RPoints = [];

    for (let i = 0; i < this.RewardPointList.length; i++) {
      if (this.RewardPointList[i] == "" || this.RewardPointList[i] == null) {
        RPoints.push(0)
      }
      else {
        RPoints.push(Number(this.RewardPointList[i] * this.campaignFormGroup.value.UnitRate));
      }

    }
    //console.log(RPoints)
    let formatedDate = moment(this.campaignData.CampaignExpiredTo, 'DD/MM/YYYY').format('MM/DD/YYYY');
    for (let i = 0; i < this.Comparedata.length; i++) {
      FormexpairyDate.push(formatedDate),
        mcampainID.push(this.campaignID),
        camactive.push(1)

    }
    let update = {

      rewardPoints: [(this.Comparedata.map(a => a['Reward Points']))],
      expairyDate: FormexpairyDate,
      campaignId: mcampainID,
      userEmail: this.Comparedata.map(a => a['Email ID']),
      userNname: this.Comparedata.map(a => a['First Name']),
      isActive: camactive,
    }
    // console.log(update, "VAlid Data")
    this.excelDatas = false;
    for (let i = 0; i < update.campaignId.length; i++) {
      if (RPoints[i] == "" || RPoints[i] == null) {
        // this.toaster.error(" Error : Empty Column Available in Reward Points ")
        this.excelDatas = true;
      }
      else if (update.userEmail[i] == "" || update.userEmail[i] == null) {
        //this.toaster.error(" Error : Empty Column Available in Email ")
        this.excelDatas = true;
      }
      else if (update.userNname[i] == "" || update.userNname[i] == null) {
        //this.toaster.error(" Error : Empty Column Available in UserName ")
        this.excelDatas = true;
      }
      else {
        let updateorder = {
          RewardPoints: (RPoints[i]),
          ExpairyDate: update.expairyDate[i],
          CampaignId: update.campaignId[i],
          CustomerID: this.webshopDetail.CustomerID,
          Email: update.userEmail[i],
          UserName: update.userNname[i]
        }
        this.updateData.push(updateorder)
      }
    }

    if (!this.excelDatas) {
      this.createUsers();

    } else {
      this.toaster.error('Users import failed. Please check required fields.');
      this.isLoading = false;
    }
  }

  getUserTransactions() {
    this.loginData = true;
    this.userHttpService.getUserbudgetTransaction(this.loginUserId).subscribe(
      data => {
        this.loginData = false;
        if (data) {
          this.userBudgetTransactions = data;
          this.userBudgetTransactions.forEach(element => {
            this.BudgetAmount += Number(element.AmountDebit);
          });

        };
      }, error => {
        this.loginData = false;
      });

  }

  getUserbudgetList() {
    this.loginData = true;
    this.userHttpService.getUsersBudgetList(this.loginUserId).subscribe((data: any) => {
      this.loginData = false;
      if (data.length > 0) {
        this.totalBudget = data[data.length - 1].AmountLeft;
      }
      else {
        this.totalBudget = 0;
      }
    }, error => {
      this.loginData = false;
      this.toaster.error(error.error);
    });
  }

  addUserBudgetTransaction() {
    const addUserBudgetData = {
      "UserID": this.loginUserId,
      "CurrencyID": 20,
      "AmountCredit": 0,
      "TransactionDate": this.TodayDate,
      "Spent": " Add Campaign " + this.campaignFormGroup.value.CampaignName,
      "AssignedUserID": 0,
      "IsActive": 1,
      "Balance": (Number(this.totalBudget) - Number(this.campaignFormGroup.value.CampaignPoint)),
      "AmountDebit": Number(this.campaignFormGroup.value.CampaignPoint),
    }
    this.totalBudget = Number(this.totalBudget) - Number(this.campaignFormGroup.value.CampaignPoint);
    this.userHttpService.setBudget(this.totalBudget);
    this.campaignFormGroup.controls['balRewards'].setValue(this.totalBudget);
    this.userHttpService.addUserbudgetTransaction(addUserBudgetData).subscribe((data: any) => {
      if (data) {
        this.toaster.success(data);

      }
      else {
        this.toaster.error(data.message);
      }
    },
      err => {

        this.toaster.error(err.error.Message);
      });
  }

  campaignPointvalue() {
    this.userHttpService.getBudget().subscribe(data => {
      this.campaignFormGroup.controls['balRewards'].setValue(data)
    });
  }

  campaignPointvaluechange() {
    setTimeout(() => {
      const budget = this.totalBudget - this.campaignFormGroup.value.CampaignPoint;
      if (budget < 0) {
        this.toaster.error("You Don't Have Sufficiant Budget, Please Purchse More Budget");
        this.campaignFormGroup.controls['CampaignPoint'].setValue(null);
      } else {
        this.campaignFormGroup.controls['balRewards'].setValue(budget)
      }
    }, 100)
  }

  viewUser() {
    this.bulkCreationModal1.show();
  }
  close() {
    this.fileUpld = false;
    this.bulkCreationModal.hide();
  }

  closeModel() {
    this.bulkCreationModal1.hide();
  }

  getUserRewardPoints() {
    if (this.campaignID) {
      this.loginData = true;
      this.userHttpService.getRewardPoints(this.campaignID).subscribe(
        data => {
          this.loginData = false;
          if (data) {
            this.rewardPointsDetails = data;
            this.rewardPointsDetails.forEach(element => {
              this.totalRewardPoints += Number(element.InitialPoint);
            });
            this.campaignPointvalue();
          };
        }, error => {
          this.loginData = false;
          this.toaster.error(error.error);
        });
    }
  }

  checkusers() {
    if (this.rewardPointsDetails.Email) {
    }
  }

  rewardPointsValidation() {
    this.RewardPointList = [];
    this.Comparedata.forEach(data => this.RewardPointList.push(data['Reward Points']))
    let reward = 0;
    this.RewardPointList.forEach(element => {
      if (element == "") {

      }
      else {
        reward += Number(element);
      }
    });
    this.updateRewardPoints = (Number(reward) * Number(this.campaignFormGroup.value.UnitRate))
    if (this.updateRewardPoints) {
      this.loginData = true;
      let budget = this.userHttpService.getBudget().subscribe(data => {
        this.loginData = false;
        this.totalBudget = data;
        /*if (this.campaignID) {
          this.totalBudget = Number(this.totalBudget) + Number(this.campaignFormGroup.value.CampaignPoint);
        }*/
        if (!this.campaignFormGroup.value.CampaignPoint) {
          this.campaignFormGroup.controls['CampaignPoint'].setValue(this.updateRewardPoints);
          let bal = this.totalBudget - this.updateRewardPoints;
          this.campaignFormGroup.controls['balRewards'].setValue(bal);
        } else {
          let sum = this.campaignFormGroup.value.CampaignPoint + this.updateRewardPoints;
          this.campaignFormGroup.controls['CampaignPoint'].setValue(sum);
          let bal = this.totalBudget - this.updateRewardPoints;
          this.campaignFormGroup.controls['balRewards'].setValue(bal);
        }
        this.bulkCreationModal.hide();
        this.toaster.success('Users imported successfully');
        if(this.isSaas){
          this.saasTransaction();
        }
      }, error => {
        this.loginData = false;
        this.toaster.error(error.error);
      });
      budget.unsubscribe();
    }
  }

  handleUploadedImage(event) {
    const { data, error } = event;
    if (error) {
      //console.log('unable to upload image', event.error);
    } else {
      this.billBoardFormGroup.controls.ImageName.setValue(data[1] || '');
    }
  }

  addBillboard() {
    let data = { campaignid: this.campaignID, name: this.campaignFormGroup.value.CampaignName, icon: history.state.icon, status: history.state.status }
    this.router.navigate(['/admin-campaign-billboard-edit'], { state: { data }, replaceUrl: false });
  }

  viewBillboard() {
    let data = { campaignid: this.campaignID, name: this.campaignFormGroup.value.CampaignName, icon: history.state.icon, status: history.state.status }
    this.router.navigate(['/admin-campaign-billboard-list'], { state: { data }, replaceUrl: false });
  }

  checkType() {
    setTimeout(() => {
      if (this.campaignFormGroup.value.ProductType === 'UnRestricted' && this.loginData.InvoiceDiscount) {
        this.campaignFormGroup.controls['Product'].setValue('0');
        this.campaignFormGroup.controls['Product'].disable();
      } else {
        this.campaignFormGroup.controls['Product'].setValue('');
        this.campaignFormGroup.controls['Product'].enable();
      }
    }, 10)
  }

}
