import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { BillboardItem } from '../models';
@Injectable()
export class BillboardHttpService {
  baseUrl = 'en/billboard';
  saasBaseUrl = 'en/billboardsass';
  webshopDetail: any;
  isSaas: boolean = false;
  userdetails:any
  constructor(private apiService: ApiService) { }

  getBillboardList() {
    let path = "";
    // const loginData = this.apiService.getLoginData();
    const webshop = sessionStorage.getItem('webshopDetail');
    const saas = sessionStorage.getItem('userdetails'); 
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    if (saas !== null && saas !== undefined && saas !== '' && saas !== 'undefined') {
      this.userdetails = JSON.parse(sessionStorage.getItem('userdetails'));
    }
    if(this.userdetails && this.userdetails.ISSaas === "Y" && (this.userdetails.SubScriptionId === 1 || this.userdetails.SubScriptionId === "1" || this.userdetails.SubScriptionId === "2" || this.userdetails.SubScriptionId === 2)){
       path = `en/billboard?admin=true&customerID=${this.webshopDetail.CustomerID}&pageIndex=1&pageSize=25&Userid=${this.userdetails.UserID}`;
    }else{
       path = `en/billboard?admin=true&customerID=${this.webshopDetail.CustomerID}&pageIndex=1&pageSize=25`;
    }
    return this.apiService.get(path).map(res => {
      return res;
    });
  }

  getCampaignBillboardList(campaignid) {
    // const loginData = this.apiService.getLoginData();
    const customerDetail = JSON.parse(sessionStorage.getItem('userdetails'));
    const webshop = sessionStorage.getItem('webshopDetail');
    let path = "";
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    if(customerDetail && customerDetail.ISSaas === "Y" && (this.userdetails.SubScriptionId === 1 || this.userdetails.SubScriptionId === "1" || this.userdetails.SubScriptionId === "2" || this.userdetails.SubScriptionId === 2)){
      path = `en/billboard?admin=true&customerID=${this.webshopDetail.CustomerID}&Userid=${customerDetail.UserID}&pageIndex=1&pageSize=25`;
    }else{
      path = `en/billboard?admin=true&customerID=${this.webshopDetail.CustomerID}&campaignID=${campaignid}&pageIndex=1&pageSize=25`;
    }
    return this.apiService.get(path).map(res => {
      return res;
    });
  }

  getBillboard(id: number) {
    const path = `${this.baseUrl}/${id}`;
    return this.apiService.get(path).map(res => {
      return res;
    });
  }

  addBillboard(billboardItem: [BillboardItem]) {
    // const loginData = this.apiService.getLoginData();
    const webshop = sessionStorage.getItem('webshopDetail');
    const customerDetail = JSON.parse(sessionStorage.getItem('userdetails'));
    let path = "";
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    if(customerDetail && customerDetail.ISSaas === "Y"){
      path = `${this.saasBaseUrl}/add/${this.webshopDetail.CustomerID}/${customerDetail.UserID}`;
    }else{
      path = `${this.baseUrl}/add/${this.webshopDetail.CustomerID}`;
    }
    return this.apiService.post(path, billboardItem).map(res => {
      return res;
    });
  }

  addBillboardCampaign(billboardItem: [BillboardItem], campaignID) {
    // const loginData = this.apiService.getLoginData();
    let path = "";
    const webshop = sessionStorage.getItem('webshopDetail');
    const customerDetail = JSON.parse(sessionStorage.getItem('userdetails'));
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    if(customerDetail && customerDetail.ISSaas === "Y"){
      path = `${this.saasBaseUrl}/add/${this.webshopDetail.CustomerID}/${campaignID}/${customerDetail.UserID}`;
    }else{
      path = `${this.baseUrl}/add/${this.webshopDetail.CustomerID}/${campaignID}`;
    }
    return this.apiService.post(path, billboardItem).map(res => {
      return res;
    });
  }

  updateBillboard(id: number, billboardItem: BillboardItem) {
    const path = `${this.baseUrl}/${id}`;
    return this.apiService.post(path, billboardItem).map(res => {
      return res;
    });
  }

  deleteBillboard(id: number) {
    const path = `${this.baseUrl}/${id}`;
    return this.apiService.delete(path).map(res => {
      return res;
    });
  }

  changePositionOfBillBoard(data) {
    // const { CustomerID } = this.apiService.getLoginData();
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    const payload = { ...data, CreatedByCustomerID: this.webshopDetail.CustomerID };
    return this.apiService.put('en/billboard/changePosition', payload).map((res) => {
      return res;
    });
  }
}
