import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'rewardkart-admin-transportation-hub',
  templateUrl: './transportation-hub.component.html',
  styleUrls: ['./transportation-hub.component.scss']
})
export class TransportationHubComponent implements OnInit {
  transportationDetails: FormGroup;
  config: any;
  @Input() webshopData: any;
  @Input() transportationData: any;
  @Input() countriesList: any;
  @Output() getTransportationData = new EventEmitter<any>();
  transportationOriginCountries = [];
  originaltransportationOriginCountries = [];
  destinationCountries = [];
  originaldestinationCountries = [];
  transportationSearch = '';
  searchDataResult = [];
  destinationSearch = '';
  destinationSearchDataResult = [];
  destinationAllCountry: Boolean = false;
  public postalCodeErrorCount = 0;
  showLoader: Boolean = false;
  constructor(private webshopHttpService: WebshopHttpService, private formBuilder: FormBuilder, private translate: TranslateService, private toast: ToastService) { }

  ngOnInit() {
    this.transportationOriginCountries = this.transportationData.TransportationOriginCountries;
    this.originaltransportationOriginCountries = this.transportationData.TransportationOriginCountries;
    this.destinationCountries = this.transportationData.DestinationCountries;
    this.originaldestinationCountries = this.transportationData.DestinationCountries;
    this.loadForm();
  }

  loadForm() {
    this.transportationDetails = this.formBuilder.group({
      TransportationHubCompanyName: [''],
      TransportationHubContactPerson: [''],
      TransportationHubEmail: [''],
      TransportationHubPhoneNumber: [''],
      TransportationHubAddress: [''],
      TransportationHubCity: [''],
      TransportationHubPostalCode: [''],
      TransportationHubCountry: [''],
      TransportationHubAverageCustomFeePercentage: ['']
    });


    this.transportationDetails.patchValue({
      TransportationHubCompanyName: this.transportationData.TransportationHubCompanyName,
      TransportationHubContactPerson: this.transportationData.TransportationHubContactPerson,
      TransportationHubEmail: this.transportationData.TransportationHubEmail,
      TransportationHubPhoneNumber: this.transportationData.TransportationHubPhoneNumber,
      TransportationHubAddress: this.transportationData.TransportationHubAddress,
      TransportationHubCity: this.transportationData.TransportationHubCity,
      TransportationHubPostalCode: this.transportationData.TransportationHubPostalCode,
      TransportationHubCountry: this.transportationData.TransportationHubCountry,
      TransportationHubAverageCustomFeePercentage: this.transportationData.TransportationHubAverageCustomFeePercentage
    });

    if (this.originaldestinationCountries.length > 0) {
      this.selectAllStatus();
    }

  }

  saveWebshop() {
    this.getTransportationData.emit(this.transportationDetails.value);
  }

  selectAllStatus() {
    let count = 0;
    this.originaldestinationCountries.forEach((_data, index) => {
      if (this.originaldestinationCountries[index]['isSelected']) {
        count += 1;
      }
    });
    this.destinationAllCountry = false;
    if (count === this.originaldestinationCountries.length) {
      this.destinationAllCountry = true;
    }
  }

  destinationSelectAllCountrys(event: boolean) {
    this.destinationCountries.forEach((_data, index) => {
      this.destinationCountries[index]['isSelected'] = event;
    });
  }

  transportationSearchFunc(searchdata) {
    if (searchdata !== '') {
      // tslint:disable-next-line:max-line-length
      this.searchDataResult = this.originaltransportationOriginCountries.filter(item => item.Name.toLowerCase().indexOf(searchdata.toLowerCase()) > -1);
      if (this.searchDataResult.length > 0) {
        this.transportationOriginCountries = this.searchDataResult;
      }
    } else {
      this.transportationOriginCountries = this.originaltransportationOriginCountries;
    }
  }

  destinationSearchFunc = function (searchdata) {
    if (searchdata !== '') {
      // tslint:disable-next-line:max-line-length
      this.destinationSearchDataResult = this.originaldestinationCountries.filter(item => item.Name.toLowerCase().indexOf(searchdata.toLowerCase()) > -1);
      if (this.destinationSearchDataResult.length > 0) {
        this.destinationCountries = this.destinationSearchDataResult;
      }
    } else {
      this.destinationCountries = this.originaldestinationCountries;
    }
  }

  updateTransportationHub() {
    let updateTransportationHubData = {};
    updateTransportationHubData['customerID'] = this.webshopData.CustomerID;
    updateTransportationHubData['TransportationOriginCountries'] = this.transportationOriginCountries;
    updateTransportationHubData['DestinationCountries'] = this.originaldestinationCountries;
    updateTransportationHubData['TransportationHubContactPerson'] = this.transportationDetails.value.TransportationHubContactPerson;
    updateTransportationHubData['TransportationHubCompanyName'] = this.transportationDetails.value.TransportationHubCompanyName;
    updateTransportationHubData['TransportationHubEmail'] = this.transportationDetails.value.TransportationHubEmail;
    updateTransportationHubData['TransportationHubPhoneNumber'] = this.transportationDetails.value.TransportationHubPhoneNumber;
    updateTransportationHubData['TransportationHubAddress'] = this.transportationDetails.value.TransportationHubAddress;
    updateTransportationHubData['TransportationHubCity'] = this.transportationDetails.value.TransportationHubCity;
    updateTransportationHubData['TransportationHubCountry'] = this.transportationDetails.value.TransportationHubCountry;
    updateTransportationHubData['TransportationHubPostalCode'] = this.transportationDetails.value.TransportationHubPostalCode;
    updateTransportationHubData['TransportationHubAverageCustomFeePercentage'] = this.transportationDetails.value.TransportationHubAverageCustomFeePercentage;

    let errorCount = 0;
    if (this.transportationDetails.value.TransportationHubEmail !== '') {
      // tslint:disable-next-line:max-line-length
      if (!this.transportationDetails.value.TransportationHubEmail.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
        errorCount += 1;
        this.toast.error('Enter valid email address');
      }
    }

    // tslint:disable-next-line:max-line-length
    if (this.transportationDetails.value.TransportationHubPostalCode !== '') {
      if (this.transportationDetails.value.TransportationHubCountry === '') {
        this.toast.error('Select Hub Country');
        return false;
      }
      let proceedStatus = false;
      let invoicePostalcodeError = '';
      let postalcodeErrorIs = '';
      let postalcodeErrorExample = '';
      let postalcodeProceedText = '';
      this.translate.get('USER.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
        invoicePostalcodeError = res;
      });
      this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
        postalcodeErrorIs = res;
      });
      this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
        postalcodeErrorExample = res;
      });
      this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
        postalcodeProceedText = res;
      });
      // tslint:disable-next-line:max-line-length
      this.validatePostalCodeCheck(this.transportationDetails.value.TransportationHubCountry, this.transportationDetails.value.TransportationHubPostalCode).subscribe((data) => {
        if (data['ValidPostalRegex']) {
          proceedStatus = true;
        } else {
          this.postalCodeErrorCount += 1;
          if (Number(this.postalCodeErrorCount) > 1) {
            proceedStatus = true;
          } else {
            proceedStatus = false;
            // tslint:disable-next-line:max-line-length
            const countryResponse = this.countriesList.find(item => item.value === this.transportationDetails.value.TransportationHubCountry);
            if (data['VaildPostalcode'] !== '') {
              const displayText = invoicePostalcodeError + countryResponse.label + ' ' + postalcodeErrorIs + ' ' +
                data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;
              // tslint:disable-next-line:max-line-length
              this.toast.error(this.translate.instant('USERADDEDIT.ERROR') + ': ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus = true;
            }
          }
        }
        if (Number(errorCount) === 0 && proceedStatus) {
          this.getTransportationData.emit(updateTransportationHubData);
        }
      });

    } else {
      this.getTransportationData.emit(updateTransportationHubData);
    }
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    var subject = new Subject<any>();
    this.webshopHttpService.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  isPhoneNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode < 40 || charCode > 57) || (charCode === 42) || (charCode === 44)) {
      return false;
    }
    return true;
  }

}
