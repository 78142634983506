import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderHttpService } from '../../state/services/order.http.service';
import { Observable, Subject } from 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';
import { WebshopService } from 'src/app/restful-service/webshop-services/webshop.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rewardkart-admin-edit-orderitems',
  templateUrl: './edit-orderitems.component.html',
  styleUrls: ['./edit-orderitems.component.scss']
})
export class EditOrderitemsComponent implements OnInit {
  @ViewChild('deleteUserLogoModal', { static: true }) deleteUserLogoModal: ModalDirective;
  @ViewChild('deleteLogoModal', { static: true }) deleteLogoModal: ModalDirective;
  @ViewChild('deleteLogoColorModal', { static: true }) deleteLogoColorModal: ModalDirective;
  @ViewChild('shippingMethodModal', { static: true }) shippingMethodModal: ModalDirective;
  showLoader = false;
  paymentTypes: any = [];

  // $scope.orderItemID=$stateParams.itemId;
  // $scope.SuborderID=$stateParams.suborderId;

  /** Declare order, Suborder variables */
  isSuborder = false;
  isMainorder = false;
  isOrderItems = true;
  uploadInput: any;
  orderID: any = 5739; // Main Order Detail ID
  orderItemID: any = 7949;   // Specific order item Id (Example order items contains two orders ex., based on size)
  SuborderID: any;    // Specific sub order item Id
  order: any = [];
  Userlogos: any = [];
  countries: any = [];
  countryList = [];

  /** For Delivery & Invoice Address Section */
  vatMandatoryFieldStatus = false;
  postalCodeDeliveryCountryErrorCount = 0;
  postalCodeErrorCount = 0;

  /** For Logo Section Variables */
  disableLogoPositions = false;
  disableLogoMaxAreas = false;
  order_item_index: any;
  order_logo_index: any;
  logosection: any = [];
  logosectionId: any = [];
  UploadedLogoUrl: any;
  showCustomLogoPosition: any = [[], []];
  showLogoPos: any = [[], []];
  ProductLogoPositions: any = []; // Logo position Master Value list from product Detail API
  ProductLogoMaxAreas: any = []; // Logo Areas Master Value list from product Detail API
  ProductPrintPrice: any = []; // ProductPrintPrice Master Value list from product Detail API
  pantoneCodes = [];
  deleteUserLogoId: any;
  deleteLogoId: any = [];
  deleteLogoColorDetails: any = [];
  optionsSelectParentColor: any = [{
    value: '',
    label: '',
  }];

  /** For Logo Section => Color variables */
  // tslint:disable-next-line:variable-name
  pantone_codes: any;
  panthomeColor: any = [];

  /** order Product Properties */
  SelectProductProperties: any = [];
  propertiesFuncCallStatus = true;

  /** For temporaray Variables */
  new_pantone_codes: any;
  getCountryListStatus = true;
  orderLogoColorsLength = [[], []];
  grandTotalAmount: any = 0;
  adjustedAmount;
  viewDetails = false;
  emailNotificationComment = true;
  webshopDetail: any;
  // shippingOptions: any = [{ label: "Air", value: "Air" }, { label: "Rail", value: "Rail" }, { label: 'Ship', value: "Ship" }]
  // selectedShippingType: any = "Air";
  subOrderItemData: any;

  shippingOptions: any = [{ label: "Courier", value: "Courier" }, { label: "Express", value: "Express" }, { label: "Air", value: "Air" }, { label: "Rail", value: "Rail" }, { label: 'Sea', value: "Sea" }]
  selectedShippingType: any = "Express";
  // subOrderItemData: any;
  showField: boolean = false;
  paymentName: any = '';
  subIndex: any;
  itemIndex: any;
  checkPL = true;
  constructor(private route: ActivatedRoute, private orderHttpService: OrderHttpService, private toast: ToastService,
    private router: Router, private translate: TranslateService, public _httpWebshopService: WebshopService) {

    /** Check if it is Sub order item */
    if (this.SuborderID > 0) {
      // this.isSuborder = true;
      //  this.isOrderItems = false;
    }

    if (history.state.data !== undefined) {
      console.log("History State : ", history);
      if (history.state.data !== undefined && history.state.data !== null) {
        if (history.state.data.isSubOrder !== undefined && history.state.data.isSubOrder !== null) {
          this.isSuborder = history.state.data.isSubOrder;
          if (this.isSuborder === true) {
            this.isOrderItems = false;
          }
        }
        if (history.state.data.orderID !== undefined && history.state.data.orderID !== null) {
          this.orderID = history.state.data.orderID;
        }
        if (history.state.data.orderItemID !== undefined && history.state.data.orderItemID !== null) {
          this.orderItemID = history.state.data.orderItemID;
        }
        if (history.state.data.viewDetails !== undefined && history.state.data.orderItemID !== null) {
          this.viewDetails = true;
        }
        if (history.state.data.subOrderID !== undefined && history.state.data.subOrderID !== null) {
          this.SuborderID = history.state.data.subOrderID;
        }
        if (history.state.subOrderItemData !== undefined && history.state.subOrderItemData !== null) {
          this.subOrderItemData = history.state.subOrderItemData;
        }
      }
    }
    this.pantoneCodes = this._httpWebshopService.pantoneCodes;
  }

  ngOnInit() {
    /** get Order Details */
    this.getOrderDetails(this.orderID);
    this.getShippingMethod(this.selectedShippingType)
  }

  getShippingMethod(value, itemIndex = null, subIndex = null) {
    this.selectedShippingType = value;
    if (itemIndex != null) {
      let updatedProp = this.order;
      this.itemIndex = itemIndex;
      this.subIndex = subIndex;
      if (this.isSuborder) {
        if (value != 'Courier') {
          if ((updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost !== null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost !== 0) || (updatedProp.Suborders[subIndex].OrderItems[itemIndex].AdditionalTransporationCost !== null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].AdditionalTransporationCost !== 0) || (updatedProp.Suborders[subIndex].OrderItems[itemIndex].UpdatedTransportationCost !== null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].UpdatedTransportationCost !== 0) || (updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderitemCustomFee !== null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderitemCustomFee !== 0)
            || (updatedProp.Suborders[subIndex].OrderItems[itemIndex].ShippingFee !== null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].ShippingFee !== 0)) {
            this.shippingMethodModal.show();
          }
          else if ((updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost === null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost === 0) && (updatedProp.Suborders[subIndex].OrderItems[itemIndex].AdditionalTransporationCost === null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].AdditionalTransporationCost === 0) && (updatedProp.Suborders[subIndex].OrderItems[itemIndex].UpdatedTransportationCost === null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].UpdatedTransportationCost === 0) && (updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderitemCustomFee === null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderitemCustomFee === 0) && (updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost === null && updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost === 0) && (updatedProp.Suborders[subIndex].OrderItems[itemIndex].ShippingFee === null || updatedProp.Suborders[subIndex].OrderItems[itemIndex].ShippingFee === 0)) {
            updatedProp.Suborders[subIndex].OrderItems[itemIndex].isTransportationCalculated = false;
            updatedProp.Suborders[subIndex].OrderItems[itemIndex].AdditionalTransporationCost = 0;
            updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderItemTransportationCost = 0;
            updatedProp.Suborders[subIndex].OrderItems[itemIndex].UpdatedTransportationCost = 0;
            updatedProp.Suborders[subIndex].OrderItems[itemIndex].OrderitemCustomFee = 0;
            updatedProp.Suborders[subIndex].OrderItems[itemIndex].ShippingFee = 0;
          }
        }
        else {
          updatedProp.Suborders[subIndex].OrderItems[itemIndex].isTransportationCalculated = true;
        }
      }
      else {
        if (value != 'Courier') {
          if ((updatedProp.OrderItems[itemIndex].OrderItemTransportationCost !== null && updatedProp.OrderItems[itemIndex].OrderItemTransportationCost !== 0) || (updatedProp.OrderItems[itemIndex].AdditionalTransporationCost !== null && updatedProp.OrderItems[itemIndex].AdditionalTransporationCost !== 0) || (updatedProp.OrderItems[itemIndex].UpdatedTransportationCost !== null && updatedProp.OrderItems[itemIndex].UpdatedTransportationCost !== 0) || (updatedProp.OrderItems[itemIndex].OrderitemCustomFee !== null && updatedProp.OrderItems[itemIndex].OrderitemCustomFee !== 0) || (updatedProp.OrderItems[itemIndex].ShippingFee !== null && updatedProp.OrderItems[itemIndex].ShippingFee !== 0)) {
            this.shippingMethodModal.show();
          }
          else if ((updatedProp.OrderItems[itemIndex].OrderItemTransportationCost === null && updatedProp.OrderItems[itemIndex].OrderItemTransportationCost === 0) && (updatedProp.OrderItems[itemIndex].AdditionalTransporationCost === null && updatedProp.OrderItems[itemIndex].AdditionalTransporationCost === 0) && (updatedProp.OrderItems[itemIndex].UpdatedTransportationCost === null && updatedProp.OrderItems[itemIndex].UpdatedTransportationCost === 0) && (updatedProp.OrderItems[itemIndex].OrderitemCustomFee === null && updatedProp.OrderItems[itemIndex].OrderitemCustomFee === 0) && (updatedProp.OrderItems[itemIndex].ShippingFee === null && updatedProp.OrderItems[itemIndex].ShippingFee === 0)) {
            updatedProp.OrderItems[itemIndex].isTransportationCalculated = false;
            updatedProp.OrderItems[itemIndex].AdditionalTransporationCost = 0;
            updatedProp.OrderItems[itemIndex].OrderItemTransportationCost = 0;
            updatedProp.OrderItems[itemIndex].UpdatedTransportationCost = 0;
            updatedProp.OrderItems[itemIndex].OrderitemCustomFee = 0;
            updatedProp.OrderItems[itemIndex].ShippingFee = 0;
          }
        }
        else {
          updatedProp.Suborders[subIndex].OrderItems[itemIndex].isTransportationCalculated = true;
        }
      }
      console.log('updatedProp', updatedProp);
      this.order = updatedProp;
    }
  }

  updateTransportationCostOnShipping() {
    if (this.itemIndex != null) {
      let updatedProp = this.order;
      if (this.isSuborder) {
        updatedProp.Suborders[this.subIndex].OrderItems[this.itemIndex].isTransportationCalculated = false;
        updatedProp.Suborders[this.subIndex].OrderItems[this.itemIndex].AdditionalTransporationCost = 0;
        updatedProp.Suborders[this.subIndex].OrderItems[this.itemIndex].OrderItemTransportationCost = 0;
        updatedProp.Suborders[this.subIndex].OrderItems[this.itemIndex].UpdatedTransportationCost = 0;
        updatedProp.Suborders[this.subIndex].OrderItems[this.itemIndex].OrderitemCustomFee = 0;
        updatedProp.Suborders[this.subIndex].OrderItems[this.itemIndex].ShippingFee = 0;
      }
      else {
        updatedProp.OrderItems[this.itemIndex].isTransportationCalculated = false;
        updatedProp.OrderItems[this.itemIndex].AdditionalTransporationCost = 0;
        updatedProp.OrderItems[this.itemIndex].OrderItemTransportationCost = 0;
        updatedProp.OrderItems[this.itemIndex].UpdatedTransportationCost = 0;
        updatedProp.OrderItems[this.itemIndex].OrderitemCustomFee = 0;
        updatedProp.OrderItems[this.itemIndex].ShippingFee = 0;
      }
      this.order = updatedProp;
    }
    this.shippingMethodModal.hide();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.color.toLocaleLowerCase().indexOf(term) > -1;
  }

  getOrderDetails(orderId) {
    debugger
    this.showLoader = true;
    let isUnauthorised = (history.state.returnPage === 'unauthorised') ? true : false;
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    this.orderHttpService.getOrderDetail(orderId, this.isSuborder, isUnauthorised, this.webshopDetail.CustomerID).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.order = data;
        this.paymentName = data.PaymentTypeName;
        this.getPaymentTypes();
        if (this.webshopDetail.DomainName == 'wsa' || this.order.domainName == 'wsa' || (Number(this.webshopDetail.CustomerID) == 15) || (Number(this.webshopDetail.CustomerID) == 3) || this.order.domainName == 'gn' || this.order.domainName == 'bo') {
          console.log('truee')
          this.showField = true;
          if (this.order.Suborders) {
            this.order.Suborders = this.order.Suborders.map(x => {
              x.OrderItems = x.OrderItems.map(r => {
                if (!r.ShippingMethod) {
                  r.ShippingMethod = 'Express'
                }
                return r;
              })
              return x;
            })
          } else {
            this.order.OrderItems = this.order.OrderItems.map(x => {
              if (!x.ShippingMethod) {
                x.ShippingMethod = 'Express'
              }
              return x;
            })
          }
        }

        this.shippingOptions = [{ label: "Courier", value: "Courier" }, { label: "Express", value: "Express" }, { label: "Air", value: "Air" }, { label: "Rail", value: "Rail" }, { label: 'Sea', value: "Sea" }]

        if (this.getCountryListStatus) {
          this.getCountryList();
        }
        /** To Set Grand Total Amount */
        this.grandTotalAmount = this.order.OrderCurrentAmountWithVAT;
        this.adjustedAmount = this.order.AdjustedAmount;

        /** Set User Id into LastUpdatedByUserID */
        if (sessionStorage.getItem('userdetails') !== null && sessionStorage.getItem('userdetails') !== undefined) {
          const loginDetails = JSON.parse(sessionStorage.getItem('userdetails'));
          this.order.LastUpdatedByUserID = loginDetails.UserID;
        }
        this.order.Action = 'Update';

        /** getuser Logos */
        if (data.UserID !== '' && Number(data.UserID) > 0) {
          this.getUserLogos(data.UserID);
        }

        this.disableLogoPositions = false;
        this.disableLogoMaxAreas = false;

        /** get products */
        if (this.isSuborder) {
          /** get order Details */
          this.order.Suborders.forEach((suborders, i) => {
            suborders.OrderItems.forEach((data, j) => {
              if (Number(this.orderItemID) === Number(data.OrderItemID)) {
                this.showCustomLogoPosition[j] = new Array();
                this.showLogoPos[j] = new Array();
                if (data.ProductID !== '' && data.ProductID !== undefined && data.ProductID !== null && Number(data.ProductID) > 0) {
                  this.getProductDetail(data.ProductID).subscribe((productResult) => {
                    this.order.Suborders[i].OrderItems[j].product = productResult;
                    /** Product properties => instead of ng-init we use this part of code for Product Properties section */
                    if (data.product.ProductProperties !== undefined && data.product.ProductProperties !== null) {
                      if (data.product.ProductProperties.length > 0) {
                        if (Number(this.orderItemID) === Number(data.OrderItemID)) {
                          data.product.ProductProperties.forEach((property, j) => {
                            property.ProductOptions.forEach((option, k) => {
                              this.checkSelectedOptions(option.ProductOptionID, property.ProductPropertyID, data.ProductProperties, j);
                            });
                          });
                        }
                      }
                    }

                    /** For Logo Section prefill for Logo Position */
                    if (data.product.ProductLogoPositions !== undefined && data.product.ProductLogoPositions !== null) {
                      if (data.product.ProductLogoPositions.length > 0) {
                        if (Number(this.orderItemID) === Number(data.OrderItemID)) {
                          this.ProductLogoPositions = [];
                          // tslint:disable-next-line:max-line-length
                          this.ProductLogoPositions.push({ label: this.translate.instant('EDITORDERSITEMS.SELECT_LOGO_POSITION'), value: '' });
                          data.product.ProductLogoPositions.forEach((ProductLogoPosition, j) => {
                            // Logo position Master Value list from product Detail API
                            // tslint:disable-next-line:max-line-length
                            this.ProductLogoPositions.push({ label: ProductLogoPosition.Description, value: ProductLogoPosition.LogoPositionID })
                          });
                        }
                      }
                    }

                    /** For Logo Section prefill for Logo Areas */
                    if (data.product.ProductLogoMaxAreas !== undefined && data.product.ProductLogoMaxAreas !== null) {
                      if (data.product.ProductLogoMaxAreas.length > 0) {
                        if (Number(this.orderItemID) === Number(data.OrderItemID)) {
                          this.ProductLogoMaxAreas = [];
                          this.ProductLogoMaxAreas.push({ label: this.translate.instant('EDITORDERSITEMS.SELECT_LOGO_AREA'), value: '' });
                          data.product.ProductLogoMaxAreas.forEach((ProductLogoMaxArea, j) => {
                            // Logo Areas Master Value list from product Detail API
                            // tslint:disable-next-line:max-line-length
                            this.ProductLogoMaxAreas.push({ label: ProductLogoMaxArea.Description, value: ProductLogoMaxArea.LogoMaxAreaID })
                          });
                        }
                      }
                    }

                    /** For Logo Section => Preffered Logo Position */
                    data.OrderLogos.forEach((OrderLogo, k) => {
                      this.panthomeColor[k] = '';
                      OrderLogo.pantoneColorLength = OrderLogo.OrderLogoColors.length;
                      this.onloadstateChanged(OrderLogo.LogoPositionComment, j, k, i);
                    });
                  });
                }
              } else {
                this.showCustomLogoPosition[j] = new Array();
                this.showLogoPos[j] = new Array();
                this.showCustomLogoPosition[j][0] = false;
                this.showLogoPos[j][0] = false;
              }
            });
          });

          /** get production Line Details for order */
          this.order.Suborders.forEach((suborders, i) => {
            suborders.OrderItems.forEach((data, j) => {
              if (Number(this.orderItemID) === Number(data.OrderItemID)) {
                if (data.ProductionLineID !== '' && data.ProductionLineID !== undefined && data.ProductionLineID !== null &&
                  Number(data.ProductionLineID) > 0) {
                  // tslint:disable-next-line:max-line-length
                  this.getProductionLineDetail(data.ProductionLineID, this.order.CustomerID, this.order.CurrencyID).subscribe((productionLineResult) => {
                    console.log("productionLineResult : ", productionLineResult);
                    if (productionLineResult !== null && productionLineResult !== undefined && productionLineResult !== '') {
                      this.order.Suborders[i].OrderItems[j].productionLine = productionLineResult;
                      console.log("Order Result : ", this.order);

                      /** For Logo Section prefill for Print Price */
                      // tslint:disable-next-line:max-line-length
                      if (this.order.Suborders[i].OrderItems[j].productionLine !== undefined && this.order.Suborders[i].OrderItems[j].productionLine !== null) {
                        this.order.Suborders[i].OrderItems[j].productionLine.ProductionLinePrices.forEach((ProductPrice, i) => {
                          // Logo Areas Master Value list from product Detail API
                          // tslint:disable-next-line:max-line-length
                          if (Number(ProductPrice.MinimumQuantity) <= Number(data.OrderedQuantity) && Number(data.OrderedQuantity) <= Number(ProductPrice.MaximumQuantity) && Number(this.orderItemID) === Number(data.OrderItemID)) {
                            this.ProductPrintPrice = [];
                            this.ProductPrintPrice.push({ label: this.translate.instant('EDITORDERSITEMS.SELECT_PRINT_PRICE'), value: '' });
                            ProductPrice.ProductionLinePrintPrices.forEach((prints, j) => {
                              let LogoPositionDescription = '';
                              let LogoDescription = '';
                              let LogoMaxAreaDescription = '';
                              if (prints.LogoPositionDescription !== undefined && prints.LogoPositionDescription !== null &&
                                prints.LogoPositionDescription !== '') {
                                LogoPositionDescription = prints.LogoPositionDescription + '-';
                              }
                              if (prints.Description !== undefined && prints.Description !== null &&
                                prints.Description !== '') {
                                LogoDescription = prints.Description;
                              }
                              if (prints.LogoMaxAreaDescription !== undefined && prints.LogoMaxAreaDescription !== null &&
                                prints.LogoMaxAreaDescription !== '') {
                                LogoMaxAreaDescription = prints.LogoMaxAreaDescription;
                              }
                              const labelValue = LogoPositionDescription + LogoDescription + LogoMaxAreaDescription +
                                ' (' + prints.Price + ' ' + this.order.CurrencyCode + ')';

                              let extraCostLabel = '';
                              if (Number(prints.ScreenChargesCost) > 0 || Number(prints.SetupCost) > 0 ||
                                Number(prints.HandlingCost) > 0 || Number(prints.ToolCost) > 0) {
                                let screenCostlabel = '';
                                let handlingCostlabel = '';
                                let toolCostlabel = '';
                                let setupCostlabel = '';
                                this.translate.get('EDITORDERSITEMS.SCREENCOSTLABEL').subscribe((res: string) => {
                                  screenCostlabel = res;
                                });
                                this.translate.get('EDITORDERSITEMS.HANDLINGCOSTLABEL').subscribe((res: string) => {
                                  handlingCostlabel = res;
                                });
                                this.translate.get('EDITORDERSITEMS.TOOLCOSTLABEL').subscribe((res: string) => {
                                  toolCostlabel = res;
                                });
                                this.translate.get('EDITORDERSITEMS.SETUPCOSTLABEL').subscribe((res: string) => {
                                  setupCostlabel = res;
                                });
                                extraCostLabel += ' (';
                                if (Number(prints.ScreenChargesCost) > 0) {
                                  extraCostLabel += screenCostlabel + ' :' + prints.ScreenChargesCost.toFixed(2) + ', ';
                                }
                                if (Number(prints.HandlingCost) > 0) {
                                  extraCostLabel += handlingCostlabel + ' :' + prints.HandlingCost.toFixed(2) + ', ';
                                }
                                if (Number(prints.ToolCost) > 0) {
                                  extraCostLabel += toolCostlabel + ' :' + prints.ToolCost.toFixed(2) + ', ';
                                }
                                if (Number(prints.SetupCost) > 0) {
                                  extraCostLabel += setupCostlabel + ' :' + prints.SetupCost.toFixed(2);
                                }
                                extraCostLabel += ')';
                              }
                              this.ProductPrintPrice.push({ label: labelValue + extraCostLabel, value: prints.ProductionLinePrintPriceID });
                            });
                            console.log("Final Dropdoen here : ", this.ProductPrintPrice);
                          }
                        });

                        data.OrderLogos.forEach((OrderLogo, h) => {
                          if (this.order.Suborders[i].OrderItems[j].productionLine !== undefined) {
                            this.order.Suborders[i].OrderItems[j].productionLine.ProductionLinePrices.forEach((ProductPrice, i) => {
                              // tslint:disable-next-line:max-line-length
                              if (Number(ProductPrice.MinimumQuantity) <= Number(data.OrderedQuantity) && Number(data.OrderedQuantity) <= Number(ProductPrice.MaximumQuantity) && Number(this.orderItemID) === Number(data.OrderItemID)) {
                                ProductPrice.ProductionLinePrintPrices.forEach((prints, k) => {
                                  this.logooption(h, prints, OrderLogo);
                                });
                              }
                            });
                          }
                        });
                      }
                      /** For Logo Section prefill for Print Price  - End Line */

                      if (productionLineResult.ProductionLinePrices[0].ProductionLinePrintPrices.length > 0) {
                        if (productionLineResult.ProductionLinePrices[0].ProductionLinePrintPrices[0].LogoPositionID !== undefined) {
                          this.disableLogoPositions = true;
                        }
                        if (productionLineResult.ProductionLinePrices[0].ProductionLinePrintPrices[0].LogoMaxAreaID !== undefined) {
                          this.disableLogoMaxAreas = true;
                        }
                      }
                    }
                  });
                }
              }
            });
          });
        } else {
          this.order.OrderItems.forEach((obj, index) => {
            if (Number(this.orderItemID) === Number(obj.OrderItemID)) {
              this.showCustomLogoPosition[index] = new Array();
              this.showLogoPos[index] = new Array();
              /** get order Details */
              if (obj.ProductID !== '' && obj.ProductID !== undefined && obj.ProductID !== null && Number(obj.ProductID) > 0) {
                this.getProductDetail(obj.ProductID).subscribe((productResult) => {
                  console.log("product Detail Result : ", productResult);
                  this.order['OrderItems'][index].product = productResult;

                  /** Product properties => instead of ng-init we use this part of code for Product Properties section */
                  if (obj.product.ProductProperties !== undefined && obj.product.ProductProperties !== null) {
                    if (obj.product.ProductProperties.length > 0) {
                      if (Number(this.orderItemID) === Number(obj.OrderItemID)) {
                        obj.product.ProductProperties.forEach((property, j) => {
                          property.ProductOptions.forEach((option, k) => {
                            this.checkSelectedOptions(option.ProductOptionID, property.ProductPropertyID, obj.ProductProperties, j);
                          });
                        });
                      }
                    }
                  }

                  /** For Logo Section prefill for Logo Position */
                  if (obj.product.ProductLogoPositions !== undefined && obj.product.ProductLogoPositions !== null) {
                    if (obj.product.ProductLogoPositions.length > 0) {
                      if (Number(this.orderItemID) === Number(obj.OrderItemID)) {
                        this.ProductLogoPositions = [];
                        // tslint:disable-next-line:max-line-length
                        this.ProductLogoPositions.push({ label: this.translate.instant('EDITORDERSITEMS.SELECT_LOGO_POSITION'), value: '' });
                        obj.product.ProductLogoPositions.forEach((ProductLogoPosition, j) => {
                          // Logo position Master Value list from product Detail API
                          // tslint:disable-next-line:max-line-length
                          this.ProductLogoPositions.push({ label: ProductLogoPosition.Description, value: ProductLogoPosition.LogoPositionID })
                        });
                      }
                    }
                  }

                  /** For Logo Section prefill for Logo Areas */
                  if (obj.product.ProductLogoMaxAreas !== undefined && obj.product.ProductLogoMaxAreas !== null) {
                    if (obj.product.ProductLogoMaxAreas.length > 0) {
                      if (Number(this.orderItemID) === Number(obj.OrderItemID)) {
                        this.ProductLogoMaxAreas = [];
                        this.ProductLogoMaxAreas.push({ label: this.translate.instant('EDITORDERSITEMS.SELECT_LOGO_AREA'), value: '' });
                        obj.product.ProductLogoMaxAreas.forEach((ProductLogoMaxArea, j) => {
                          // Logo Areas Master Value list from product Detail API
                          // tslint:disable-next-line:max-line-length
                          this.ProductLogoMaxAreas.push({ label: ProductLogoMaxArea.Description, value: ProductLogoMaxArea.LogoMaxAreaID })
                        });
                      }
                    }
                  }

                  /** For Logo Section => Preffered Logo Position */
                  obj.OrderLogos.forEach((OrderLogo, j) => {
                    this.panthomeColor[j] = '';
                    OrderLogo.pantoneColorLength = OrderLogo.OrderLogoColors.length;
                    this.onloadstateChanged(OrderLogo.LogoPositionComment, index, j);
                  });
                });
              }
            } else {
              this.showCustomLogoPosition[index] = new Array();
              this.showLogoPos[index] = new Array();
              this.showCustomLogoPosition[index][0] = false;
              this.showLogoPos[index][0] = false;
            }
          });

          /** get production Line Details for order */
          this.order.OrderItems.forEach((obj, index) => {
            if (Number(this.orderItemID) === Number(obj.OrderItemID)) {
              if (obj.ProductionLineID !== '' && obj.ProductionLineID !== undefined && obj.ProductionLineID !== null &&
                Number(obj.ProductionLineID) > 0) {
                // tslint:disable-next-line:max-line-length
                this.getProductionLineDetail(obj.ProductionLineID, this.order.CustomerID, this.order.CurrencyID).subscribe((productionLineResult) => {
                  console.log("productionLineResult : ", productionLineResult);
                  if (productionLineResult !== null && productionLineResult !== undefined && productionLineResult !== '') {
                    this.order['OrderItems'][index].productionLine = productionLineResult;

                    /** For Logo Section prefill for Print Price */
                    // tslint:disable-next-line:max-line-length
                    if (this.order['OrderItems'][index].productionLine !== undefined && this.order['OrderItems'][index].productionLine !== null) {
                      this.order['OrderItems'][index].productionLine.ProductionLinePrices.forEach((ProductPrice, i) => {
                        // Logo Areas Master Value list from product Detail API
                        // tslint:disable-next-line:max-line-length
                        if (Number(ProductPrice.MinimumQuantity) <= Number(obj.OrderedQuantity) && Number(obj.OrderedQuantity) <= Number(ProductPrice.MaximumQuantity)) {
                          this.ProductPrintPrice = [];
                          this.ProductPrintPrice.push({ label: this.translate.instant('EDITORDERSITEMS.SELECT_PRINT_PRICE'), value: '' });
                          ProductPrice.ProductionLinePrintPrices.forEach((prints, j) => {
                            let LogoPositionDescription = '';
                            let LogoDescription = '';
                            let LogoMaxAreaDescription = '';
                            if (prints.LogoPositionDescription !== undefined && prints.LogoPositionDescription !== null &&
                              prints.LogoPositionDescription !== '') {
                              LogoPositionDescription = prints.LogoPositionDescription + '-';
                            }
                            if (prints.Description !== undefined && prints.Description !== null &&
                              prints.Description !== '') {
                              LogoDescription = prints.Description;
                            }
                            if (prints.LogoMaxAreaDescription !== undefined && prints.LogoMaxAreaDescription !== null &&
                              prints.LogoMaxAreaDescription !== '') {
                              LogoMaxAreaDescription = prints.LogoMaxAreaDescription;
                            }
                            const labelValue = LogoPositionDescription + LogoDescription + LogoMaxAreaDescription +
                              ' (' + prints.Price + ' ' + this.order.CurrencyCode + ')';

                            let extraCostLabel = '';
                            if (Number(prints.ScreenChargesCost) > 0 || Number(prints.SetupCost) > 0 ||
                              Number(prints.HandlingCost) > 0 || Number(prints.ToolCost) > 0) {
                              let screenCostlabel = '';
                              let handlingCostlabel = '';
                              let toolCostlabel = '';
                              let setupCostlabel = '';
                              this.translate.get('EDITORDERSITEMS.SCREENCOSTLABEL').subscribe((res: string) => {
                                screenCostlabel = res;
                              });
                              this.translate.get('EDITORDERSITEMS.HANDLINGCOSTLABEL').subscribe((res: string) => {
                                handlingCostlabel = res;
                              });
                              this.translate.get('EDITORDERSITEMS.TOOLCOSTLABEL').subscribe((res: string) => {
                                toolCostlabel = res;
                              });
                              this.translate.get('EDITORDERSITEMS.SETUPCOSTLABEL').subscribe((res: string) => {
                                setupCostlabel = res;
                              });
                              extraCostLabel += `
                              (`;
                              if (Number(prints.ScreenChargesCost) > 0) {
                                extraCostLabel += screenCostlabel + ' :' + prints.ScreenChargesCost.toFixed(2) + ', ';
                              }
                              if (Number(prints.HandlingCost) > 0) {
                                extraCostLabel += handlingCostlabel + ' :' + prints.HandlingCost.toFixed(2) + ', ';
                              }
                              if (Number(prints.ToolCost) > 0) {
                                extraCostLabel += toolCostlabel + ' :' + prints.ToolCost.toFixed(2) + ', ';
                              }
                              if (Number(prints.SetupCost) > 0) {
                                extraCostLabel += setupCostlabel + ' :' + prints.SetupCost.toFixed(2);
                              }
                              extraCostLabel += ')';
                            }
                            this.ProductPrintPrice.push({ label: labelValue + extraCostLabel, value: prints.ProductionLinePrintPriceID });
                          });
                        }
                      });

                      obj.OrderLogos.forEach((OrderLogo, j) => {
                        if (this.order['OrderItems'][index].productionLine !== undefined) {
                          this.order['OrderItems'][index].productionLine.ProductionLinePrices.forEach((ProductPrice, i) => {
                            // tslint:disable-next-line:max-line-length
                            if (Number(ProductPrice.MinimumQuantity) <= Number(obj.OrderedQuantity) && Number(obj.OrderedQuantity) <= Number(ProductPrice.MaximumQuantity)) {
                              console.log("ProductPrice.ProductionLinePrintPrices value : ", ProductPrice.ProductionLinePrintPrices);
                              ProductPrice.ProductionLinePrintPrices.forEach((prints, k) => {
                                this.logooption(j, prints, OrderLogo);
                              });
                            }
                          });
                        }
                      });
                    }
                    /** For Logo Section prefill for Print Price  - End Line */

                    if (productionLineResult.ProductionLinePrices[0].ProductionLinePrintPrices.length > 0) {
                      if (productionLineResult.ProductionLinePrices[0].ProductionLinePrintPrices[0].LogoPositionID !== undefined) {
                        this.disableLogoPositions = true;
                      }
                      if (productionLineResult.ProductionLinePrices[0].ProductionLinePrintPrices[0].LogoMaxAreaID !== undefined) {
                        this.disableLogoMaxAreas = true;
                      }
                    }
                  }
                });
              }
            }
          });
          console.log("Response Data : ", data);
        }

        setTimeout(() => {
          document.getElementById('editOrderForm').click();
        }, 3000);
      }
    }, error => {
      this.showLoader = false;
    });
  }

  validateAdjustedAmount(adjustedAmount) {
    console.log("adjustedAmount : ", adjustedAmount);
    if (adjustedAmount !== null && adjustedAmount !== '' && adjustedAmount !== undefined) {
      const RE = /^-{0,1}\d*\.{0,1}\d+$/;
      if (RE.test(adjustedAmount)) {
        // this.order.AdjustedAmount = this.adjustedAmount;
        if (Number(adjustedAmount) > Number(this.grandTotalAmount)) {
          this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTAMOUNTGREATERTHANGRANDAMOUNT_WARNINGMESSAGE'), '',
            { closeButton: true, enableHtml: true });
        } else {
          this.grandTotalAmount = parseFloat(this.order.OrderCurrentAmountWithVAT) + parseFloat(adjustedAmount);
        }
      }
      // else {
      //   this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTAMOUNT_NOTVALID'), '',
      //     { closeButton: true, enableHtml: true });
      //   this.order.AdjustedAmount = this.adjustedAmount;
      // }
    } else {
      this.order.AdjustedAmount = '';
    }
  }

  validatePriceAdjustedAmount(adjustedAmount) {
    console.log("adjustedAmount : ", adjustedAmount);
    if (adjustedAmount !== null && adjustedAmount !== '') {
      if (Number(adjustedAmount) <= 0) {
        console.log("Inside if");
      }
    }
  }

  getColorArray(color) {
    color.ColorCodeArr = color.ColorCode.split(",");
  }

  checkSelectedOptions = function (ProductOptionID, ProductPropertyID, ProductProperties, k) {
    ProductProperties.forEach((productData, i) => {
      if (Number(productData.ProductPropertyID) === Number(ProductPropertyID)) {
        productData.ProductOptions.forEach((optionData, j) => {
          if (Number(productData.ProductPropertyID) === Number(ProductPropertyID)) {
            if (this.SelectProductProperties[k] === undefined || this.SelectProductProperties[k] === null) {
              this.SelectProductProperties[k] = {};
            }
            this.SelectProductProperties[k].ProductPropertyID = ProductPropertyID;
            this.SelectProductProperties[k].SelectedProductOptionID = optionData.ProductOptionID;
          }
        });
      }
    });
  }

  addOrUpdateProperties(k, suborderIndex?) {
    if (this.isSuborder) {
      this.SelectProductProperties.forEach((selectedData, i) => {
        const nomatch = false;
        let match;
        this.order.Suborders[suborderIndex].OrderItems[k].ProductProperties.forEach((orderData, i) => {
          if (orderData.ProductPropertyID == selectedData.ProductPropertyID) {
            if (orderData.ProductOptions[0].ProductOption === undefined) {
              orderData.ProductOptions = [];
            }
            orderData.ProductOptions[0] = {};
            orderData.ProductOptions[0].ProductOptionID = selectedData.SelectedProductOptionID;
            orderData.ProductOptions[0].Action = 'Update';
            match = true;
          }
        });
        if (match === false) {
          const data = {
            ProductPropertyID: selectedData.ProductPropertyID,
            ProductOptions: [{
              ProductOptionID: selectedData.SelectedProductOptionID,
              Action: 'Insert'
            }]
          };
          this.order.Suborders[suborderIndex].OrderItems[k].ProductProperties.push(data);
        }
      });
    } else {
      this.SelectProductProperties.forEach((selectedData, i) => {
        let match = false;
        this.order.OrderItems[k].ProductProperties.forEach((orderData, i) => {
          if (Number(orderData.ProductPropertyID) === Number(selectedData.ProductPropertyID)) {
            if (orderData.ProductOptions[0].ProductOption === undefined || orderData.ProductOptions[0].ProductOption === null) {
              orderData.ProductOptions = [];
            }
            orderData.ProductOptions[0] = {};
            orderData.ProductOptions[0].ProductOptionID = selectedData.SelectedProductOptionID;
            orderData.ProductOptions[0].Action = 'Update';
            match = true;
          }
        });
        if (match === false) {
          const data = {
            Action: 'Insert',
            ProductPropertyID: selectedData.ProductPropertyID,
            ProductOptions: [{
              ProductOptionID: selectedData.SelectedProductOptionID,
              Action: 'Insert'
            }]
          };
          this.order.OrderItems[k].ProductProperties.push(data);
        }
      });
    }
  }

  /*** Check Items To Show ***/
  checkItems(orderItemID) {
    if (this.orderItemID > 0 && orderItemID !== this.orderItemID) {
      return false;
    }
    return true;
  }

  /*** Check Suborder Items ***/
  checkSuborderItems(SuborderID) {
    if (this.SuborderID > 0 && SuborderID !== this.SuborderID) {
      return false;
    }
    return true;
  }

  /*** Get Country Items ***/
  getCountryList() {
    this.getCountryListStatus = false;
    // this.showLoader = true;
    this.orderHttpService.getCountryList().subscribe((data: any) => {
      //  this.showLoader = false;
      if (data) {
        let countryList = [];
        data.forEach(option => {
          countryList.push({ label: option.Name, value: option.Alpha2Code })
        });
        this.countries = countryList;
        this.countryList = data;
        /** To validate VAT field based on Country */
        setTimeout(() => {
          this.setVATValidation(null);
        }, 300);

      }
    }, error => {
      this.showLoader = false;
    });
  }

  deleteLogoDetails(i, j, suborderIndex?) {
    this.deleteLogoId['iValue'] = i;
    this.deleteLogoId['jValue'] = j;
    this.deleteLogoId['suborderIndex'] = suborderIndex;
    this.deleteLogoModal.show();
  }

  delete_order_logo(status) {
    if (status && this.deleteLogoId['iValue'] !== null && this.deleteLogoId['iValue'] !== undefined) {
      const i = this.deleteLogoId['iValue'];
      const j = this.deleteLogoId['jValue'];
      const suborderIndex = this.deleteLogoId['suborderIndex'];
      if (this.isSuborder) {
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action = 'Delete';
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors.forEach((colors, i) => {
          colors.Action = 'Delete';
        });
      } else {
        this.order.OrderItems[i].OrderLogos[j].Action = 'Delete';
        this.order.OrderItems[i].OrderLogos[j].OrderLogoColors.forEach((colors, j) => {
          colors.Action = 'Delete';
        });
      }
      this.deleteLogoId = [];
    }
  }

  change_action(i, suborderIndex?, changeAction?) {
    if (this.isSuborder) {
      this.order.Suborders[suborderIndex].OrderItems[i].Action = 'Update';
      if (Number(changeAction) === 1) {
        this.order.Suborders[suborderIndex].Action = 'Update';
      } else {
        this.order.Suborders[suborderIndex].Action = 'NoChange';
      }
    } else {
      this.order.OrderItems[i].Action = 'Update';
    }
  }

  change_transport_cost(i, suborderIndex?, changeAction?) {
    if (this.isSuborder) {
      this.order.Suborders[suborderIndex].OrderItems[i].isTransportationCalculated = true;
      this.order.Suborders[suborderIndex].OrderItems[i].Action = 'Update';
      if (Number(changeAction) === 1) {
        this.order.Suborders[suborderIndex].Action = 'Update';
      } else {
        this.order.Suborders[suborderIndex].Action = 'NoChange';
      }
    } else {
      this.order.OrderItems[i].isTransportationCalculated = true;
      this.order.OrderItems[i].Action = 'Update';
    }
  }

  getShipingFeeStatus(i, field, suborderIndex?) {
    // if (field === "SHIPPING FEE") {
    //   if (this.isSuborder) {
    //     this.order.Suborders[suborderIndex].OrderItems[i].updateShippingFeeBy = 'updatedfee';
    //   }
    //   else {
    //     this.order.OrderItems[i].updateShippingFeeBy = 'updatedfee';
    //   }
    // }
    // else {
    //   if (this.isSuborder) {
    //     this.order.Suborders[suborderIndex].OrderItems[i].updateShippingFeeBy = 'calculatedfee';
    //   }
    //   else {
    //     this.order.OrderItems[i].updateShippingFeeBy = 'calculatedfee';
    //   }
    // }
  }

  // tslint:disable-next-line:variable-name
  logo_change_action = function (i, j, suborderIndex?) {
    if (this.isSuborder) {
      if (this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action === undefined &&
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action !== 'Insert') {
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action = 'Update';
      }
    } else {
      debugger;
      if (this.order.OrderItems[i].OrderLogos[j].Action === undefined &&
        this.order.OrderItems[i].OrderLogos[j].Action !== 'Insert') {
        this.order.OrderItems[i].OrderLogos[j].Action = 'Update';
      }
    }
  }

  deleteLogoColor(i, j?, k?, suborderIndex?) {
    this.deleteLogoColorDetails['iValue'] = i;
    this.deleteLogoColorDetails['jValue'] = j;
    this.deleteLogoColorDetails['kValue'] = k;
    this.deleteLogoColorDetails['suborderIndex'] = suborderIndex;
    this.deleteLogoColorModal.show();
  }

  delete_order_logo_color(status) {
    if (status && this.deleteLogoColorDetails['iValue'] !== undefined && this.deleteLogoColorDetails['iValue'] !== null
      && this.deleteLogoColorDetails['iValue'] !== '') {
      const i = this.deleteLogoColorDetails['iValue'];
      const j = this.deleteLogoColorDetails['jValue'];
      const k = this.deleteLogoColorDetails['kValue'];
      const suborderIndex = this.deleteLogoColorDetails['suborderIndex'];
      if (this.isSuborder) {
        if (this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors[k].Action !== undefined
          && this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors[k].Action === 'Insert') {
          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors.splice(k, 1);
        } else {
          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors[k].Action = 'Delete';
        }
        // tslint:disable-next-line:max-line-length
        const colorFilter = this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors.filter(data1 => (data1.Action !== 'Delete'));
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].pantoneColorLength = colorFilter.length;
      } else {
        if (this.order.OrderItems[i].OrderLogos[j].OrderLogoColors[k].Action !== undefined &&
          this.order.OrderItems[i].OrderLogos[j].OrderLogoColors[k].Action === 'Insert') {
          this.order.OrderItems[i].OrderLogos[j].OrderLogoColors.splice(k, 1);
        } else {
          this.order.OrderItems[i].OrderLogos[j].OrderLogoColors[k].Action = 'Delete';
        }

        // tslint:disable-next-line:max-line-length
        const colorFilter = this.order.OrderItems[i].OrderLogos[j].OrderLogoColors.filter(data1 => (data1.Action !== 'Delete'));
        this.order.OrderItems[i].OrderLogos[j].pantoneColorLength = colorFilter.length;
      }
      this.deleteLogoColorDetails = [];
    }
  }

  openLogoBank(ev, i, j) {
    this.getUserLogos(this.order.UserID);
    // $mdDialog.show({
    //   parent: angular.element(document.body),
    //   templateUrl: 'orders/logobank.html',
    //   scope: $scope,
    //   controller: 'editOrderCtrl',
    //   clickOutsideToClose: true
    // });
  };

  change_order_logo(i, j, name, suborderIndex?) {
    if (this.isSuborder) {
      this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].FileName = '';
      this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].FileName = name;
      if (this.order.Suborders[suborderIndex].OrderItems[i].Action === undefined &&
        this.order.Suborders[suborderIndex].OrderItems[i].Action !== 'Insert') {
        this.order.Suborders[suborderIndex].OrderItems[i].Action = 'Update';
      }
    } else {
      this.order.OrderItems[i].OrderLogos[j].FileName = '';
      this.order.OrderItems[i].OrderLogos[j].FileName = name;
      if (this.order.OrderItems[i].OrderLogos[j].Action === undefined &&
        this.order.OrderItems[i].OrderLogos[j].Action !== 'Insert') {
        this.order.OrderItems[i].OrderLogos[j].Action = 'Update';
      }
    }
    this.logo_change_action(i, j, suborderIndex);
  }

  cancel() {
    //  $mdDialog.cancel();
  }

  // 	this.pantone_codes = pantone_codes;
  // let new_pantone_codes = loadAll();

  loadAll() {
    this.pantone_codes = '';
    let allStates = "'" + this.pantone_codes.join() + "'";
    return allStates.split(/,+/g).map(function (state) {
      return {
        value: state.toLowerCase(),
        display: state
      };
    });
  }

  createFilterFor(query) {
    const lowercaseQuery = query.toLowerCase();
    let pantone_codes = this.pantoneCodes;
    return function filterFn(pantone_codes) {
      return (pantone_codes.value.indexOf(lowercaseQuery) === 0 || pantone_codes.value.indexOf(lowercaseQuery) >= 0);
    };
  }

  /*** Logo Section Section => Check upload file Extension ***/
  checkFileExtn(values) {
    let ext;
    return ext = values.split('.')[values.split('.').length - 1].toLowerCase();
  }

  /*** Color Section => To add Pantone Color ***/
  AddPantoneColor(i, j, suborderIndex?) {
    if (this.isSuborder) {
      /** Check Selected color is already added for that particular Logo Section and add that color if not already added */
      // tslint:disable-next-line:max-line-length
      const colorDuplicateFilter = this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors.filter(data1 => (data1.Action !== 'Delete' && data1.Color === this.panthomeColor[j].color));
      if (colorDuplicateFilter.length > 0) {
        // tslint:disable-next-line:max-line-length
        this.toast.error(this.translate.instant('EDITORDERSITEMS.SELECT_COLOR') + ' ' + this.panthomeColor[j].color + ' ' + this.translate.instant('EDITORDERSITEMS.SELECT_COLOR_WARNING_MESSAGE'), '',
          { closeButton: true, enableHtml: true });
      } else {
        // tslint:disable-next-line:max-line-length
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors.push({ Color: this.panthomeColor[j].color, Action: 'Insert' });
      }
      /** Check Color Length for Particular Logo Section based on NumberOfColors in API to restrict color Add */
      // tslint:disable-next-line:max-line-length
      const colorFilter = this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].OrderLogoColors.filter(data1 => (data1.Action !== 'Delete'));
      this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].pantoneColorLength = colorFilter.length;
    } else {
      /** Check Selected color is already added for that particular Logo Section and add that color if not already added */
      // tslint:disable-next-line:max-line-length
      const colorDuplicateFilter = this.order.OrderItems[i].OrderLogos[j].OrderLogoColors.filter(data1 => (data1.Action !== 'Delete' && data1.Color === this.panthomeColor[j].color));
      if (colorDuplicateFilter.length > 0) {
        // tslint:disable-next-line:max-line-length
        this.toast.error(this.translate.instant('EDITORDERSITEMS.SELECT_COLOR') + ' ' + this.panthomeColor[j].color + ' ' + this.translate.instant('EDITORDERSITEMS.SELECT_COLOR_WARNING_MESSAGE'), '',
          { closeButton: true, enableHtml: true });
      } else {
        this.order.OrderItems[i].OrderLogos[j].OrderLogoColors.push({ Color: this.panthomeColor[j].color, Action: 'Insert' });
      }
      /** Check Color Length for Particular Logo Section based on NumberOfColors in API to restrict color Add */
      // tslint:disable-next-line:max-line-length
      const colorFilter = this.order.OrderItems[i].OrderLogos[j].OrderLogoColors.filter(data1 => (data1.Action !== 'Delete'));
      this.order.OrderItems[i].OrderLogos[j].pantoneColorLength = colorFilter.length;
    }
    this.panthomeColor[j] = '';
  }

  /*** To Update Order Edit Details ***/
  UpdateOrder() {
    let postalcodeErrorExample = '';
    let postalcodeErrorIs = '';
    let deliveryPostalcodeError = '';
    let invoicePostalcodeError = '';
    let postalcodeProceedText = '';

    this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
      postalcodeErrorExample = res;
    });
    this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
      postalcodeErrorIs = res;
    });
    this.translate.get('USER.DELIVERY_POSTALCODE_ERROR').subscribe((res: string) => {
      deliveryPostalcodeError = res;
    });
    this.translate.get('USER.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
      invoicePostalcodeError = res;
    });
    this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
      postalcodeProceedText = res;
    });
    this.showLoader = true;
    this.validatePostalCodeCheck(this.order.InvoiceCountry, this.order.InvoicePostalCode).subscribe((data) => {
      let proceedStatus = false;
      if (data['ValidPostalRegex']) {
        proceedStatus = true;
      } else {
        this.postalCodeErrorCount += 1;
        if (Number(this.postalCodeErrorCount) > 1) {
          proceedStatus = true;
        } else {
          proceedStatus = false;
          const countryResponse = this.countryList.find(item => item.Alpha2Code === this.order.InvoiceCountry);
          let displayText = '';
          if (data['VaildPostalcode'] !== '') {
            displayText = invoicePostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
              data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
              postalcodeProceedText;
            this.showLoader = false;
            this.toast.error('Error: ' + displayText, '', { closeButton: true, enableHtml: true });
          } else {
            proceedStatus = true;
          }
        }
      }


      let proceedStatus1 = false;
      this.validatePostalCodeCheck(this.order.DeliveryCountry, this.order.DeliveryPostalCode).subscribe((data1) => {
        if (data1['ValidPostalRegex']) {
          //  this.proceed(2);
          proceedStatus1 = true;
        } else {
          this.postalCodeDeliveryCountryErrorCount += 1;
          if (Number(this.postalCodeDeliveryCountryErrorCount) > 1) {
            // this.proceed(2);
            proceedStatus1 = true;
          } else {
            proceedStatus1 = false;
            const countryResponse = this.countryList.find(item => item.Alpha2Code === this.order.DeliveryCountry);
            let displayText = '';
            if (data1['VaildPostalcode'] !== '') {
              displayText = deliveryPostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data1['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data1['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;
              this.showLoader = false;
              this.toast.error('Error: ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus1 = true;
            }
          }
        }

        if (proceedStatus && proceedStatus1) {
          let errorCount = 0;
          /** To Validate Invoice/Delivery Address */
          if (this.order.InvoiceRecipient === '' || this.order.InvoiceAddress === '' || this.order.InvoiceCity === '' ||
            this.order.InvoicePostalCode === '' || this.order.orderinvoiceCountry === '' || this.order.PhoneNumber === '' ||
            this.order.Email === '' || this.order.DeliveryRecipient === '' || this.order.DeliveryAddress === '' ||
            this.order.DeliveryCity === '' || this.order.DeliveryPostalCode === '' || this.order.DeliveryCountry === '' ||
            this.order.DeliveryPhoneNumber === '' || this.order.DeliveryEmail === '') {
            errorCount += 1;
            this.toast.error(this.translate.instant('EDITORDERSITEMS.INVOICE_DELIVERY_MANDATORYFIELDS'));
          }

          /** To Validate Invoice Email Field */
          if (this.order.Email !== '') {
            const reg = /^([A-Za-z0-9_\&-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(this.order.Email) === false) {
              this.toast.error(this.translate.instant('EDITORDERSITEMS.INVALID_INVOICEEMAIL'));
              errorCount += 1;
            }
          }

          if (this.order.DeliveryEmail !== '') {
            const reg = /^([A-Za-z0-9_\&-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(this.order.DeliveryEmail) === false) {
              this.toast.error(this.translate.instant('EDITORDERSITEMS.INVALID_DELIVERYEMAIL'));
              errorCount += 1;
            }
          }

          /** To Validate VAT Field */
          if (this.vatMandatoryFieldStatus && this.order.VAT === '') {
            errorCount += 1;
            this.toast.error(this.translate.instant('EDITORDERSITEMS.VAT_VALIDATION'));
          }

          /** To Validate Buyer Info Email Field */
          if (this.order.UserEmail !== '') {
            const reg = /^([A-Za-z0-9_\&-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(this.order.UserEmail) === false) {
              this.toast.error(this.translate.instant('EDITORDERSITEMS.BUYERINFO_EMAILVALIDATION'));
              errorCount += 1;
            }
          }

          /** To Validate Adjusted Amount Field */
          if (this.order.AdjustedAmount !== null && this.order.AdjustedAmount !== '' && this.order.AdjustedAmount !== undefined) {
            const RE = /^-{0,2}\d*\.{0,2}\d+$/;
            if (RE.test(this.order.AdjustedAmount)) {
              // this.order.AdjustedAmount = this.adjustedAmount;
              if (Number(this.order.AdjustedAmount) > Number(this.grandTotalAmount)) {
                errorCount += 1;
                this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTAMOUNTGREATERTHANGRANDAMOUNT_WARNINGMESSAGE'), '',
                  { closeButton: true, enableHtml: true });
              } else {
                this.grandTotalAmount = parseFloat(this.order.OrderCurrentAmountWithVAT) + parseFloat(this.order.AdjustedAmount);
              }
            }
            // else {
            //   errorCount += 1;
            //   this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTAMOUNT_NOTVALID'), '',
            //     { closeButton: true, enableHtml: true });
            //   this.order.AdjustedAmount = this.adjustedAmount;
            // }
          } else {
            this.order.AdjustedAmount = '';
          }

          if (!this.isSuborder) {
            console.log("this.order : ", this.order)
            const orders = this.order.OrderItems.filter(dataorder => Number(dataorder.OrderItemID) === Number(this.orderItemID));
            if (orders.length > 0) {
              const orderitems = orders[0];
              console.log("Inside Order Section Order ietms After Subit  ", orderitems);
              if (orderitems.OrderItemID !== null && orderitems.OrderItemID !== '' && orderitems.OrderItemID !== undefined) {
                const orderUpdateData = orderitems;
                /** To Validate Order QTY */
                if (Number(orderUpdateData.OrderedQuantity) <= 0 || orderUpdateData.OrderedQuantity === '') {
                  errorCount += 1;
                  this.toast.error(this.translate.instant('EDITORDERSITEMS.QUANTITY_VALIDATION'));
                }

                /** To Validate Order Item TransportationCost */
                if (Number(orderUpdateData.OrderItemTransportationCost) < 0 || orderUpdateData.OrderItemTransportationCost === '') {
                  errorCount += 1;
                  this.toast.error(this.translate.instant('EDITORDERSITEMS.TRANSPORTATIONCOST_EMPTYVALIDATION'));
                }

                if (orderUpdateData.OrderItemTransportationCost !== '') {
                  if (String(orderUpdateData.OrderItemTransportationCost).match(/^\d*\.?\d{0,2}$/)) {
                  } else {
                    this.toast.error(this.translate.instant('EDITORDERSITEMS.TRANSPORTATIONCOST_VALIDATION'));
                    errorCount += 1;
                  }
                }

                /** To Validate Order Item Product Adjust price Field */
                if (orderUpdateData.AdjustedProductPrice !== undefined &&
                  orderUpdateData.AdjustedProductPrice !== null) {
                  if (Number(orderUpdateData.AdjustedProductPrice) > 0) {
                    if (String(orderUpdateData.AdjustedProductPrice).match(/^\d*\.?\d{0,2}$/)) {
                    } else {
                      this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTPRODUCTPRICE_VALIDATION'));
                      errorCount += 1;
                    }
                  }
                  if (isNaN(orderUpdateData.AdjustedProductPrice)) {
                    this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTPRODUCTPRICE_VALIDATION'));
                    errorCount += 1;
                  }
                }

                /** To Validate Order Item Discount Field */
                if (orderUpdateData.DiscountPercentage !== '' && orderUpdateData.DiscountPercentage !== undefined &&
                  orderUpdateData.DiscountPercentage !== null) {
                  if (String(orderUpdateData.DiscountPercentage).match(/^\d*\.?\d{0,2}$/)) {
                  } else {
                    this.toast.error(this.translate.instant('EDITORDERSITEMS.DISCOUNT_VALIDATION'));
                    errorCount += 1;
                  }
                }

                /** Check Order Logo Section Validation */
                /** To check Logo Section have Duplicate Print Price Label Name(if Logo Section have print Price Dropdown) */
                // tslint:disable-next-line:max-line-length
                if (orderUpdateData.OrderLogos.length > 0) {
                  // tslint:disable-next-line:max-line-length
                  if (orderUpdateData.OrderLogos[0].PrintPriceDescription !== null && orderUpdateData.OrderLogos[0].PrintPriceDescription !== undefined && orderUpdateData.productionLine !== null &&
                    orderUpdateData.productionLine !== undefined) {
                    const orderLogos = orderUpdateData.OrderLogos.filter(data1 => (data1.Action !== 'Delete'));
                    console.log("orderLogos Filter result After Delete : ", orderLogos);
                    if (orderLogos.length > 0) {
                      const valueArr = orderLogos.map(function (item) { return item.NumberOfColors });
                      const isDuplicate = valueArr.some(function (item, idx) {
                        return valueArr.indexOf(item) !== idx;
                      });
                      if (isDuplicate) {
                        this.toast.error(this.translate.instant('EDITORDERSITEMS.DUPLICATE_LOGO_PRINTPRICE'));
                        errorCount += 1;
                      }
                    }
                  }

                  orderUpdateData.OrderLogos.forEach((logo, index) => {
                    /** Check Order Logo Color with NumberofColor for Each Logo item */
                    const colorFilter = logo.OrderLogoColors.filter(data1 => (data1.Action === null || data1.Action === undefined
                      || data1.Action !== 'Delete'));
                    // if (colorFilter.length !== logo.NumberOfColors && (logo.Action !== 'Delete') &&
                    //   orderUpdateData.productionLine !== null && orderUpdateData.productionLine !== undefined) {
                    //   errorCount += 1;
                    //   // tslint:disable-next-line:max-line-length
                    //   this.toast.error(this.translate.instant('EDITORDERSITEMS.FILL') + ' ' + logo.NumberOfColors + this.translate.instant('EDITORDERSITEMS.COLOR_FOR') + ' ' + logo.NumberOfColors + ' ' + this.translate.instant('EDITORDERSITEMS.LOGO_ITEM'));
                    // }

                    /** Check Order Preferred Logo Position Section Validation */
                    if (logo.isChecked) {
                      if (logo.LogoPositionComment === '' || logo.LogoPositionComment === null || logo.LogoPositionComment === undefined) {
                        errorCount += 1;
                        this.toast.error(this.translate.instant('EDITORDERSITEMS.PREFERRED_LOGO_COMMENT_VALIDATION'));
                      }
                    }

                    /** Check Order Preferred Logo Position Section Validation when Preferred Logo Position is hide(orderUpdateData.product.ProductLogoPositions Length is 0) */
                    // tslint:disable-next-line:max-line-length
                    if (orderUpdateData.product.ProductLogoPositions.length <= 0 && !logo.isChecked) {
                      if (logo.LogoPositionComment === '' || logo.LogoPositionComment === null || logo.LogoPositionComment === undefined) {
                        errorCount += 1;
                        this.toast.error(this.translate.instant('EDITORDERSITEMS.PREFERRED_LOGO_COMMENT_VALIDATION'));
                      }
                    }

                    /** Check Order Print Price Empty Validation */
                    // tslint:disable-next-line:max-line-length
                    if (orderUpdateData.productionLine !== null && orderUpdateData.productionLine !== undefined) {
                      if (orderUpdateData.productionLine.ProductionLinePrices.length > 0) {
                        if ((logo.LogoTypeID === '' || logo.LogoTypeID === null || Number(logo.LogoTypeID) === 0
                          || logo.LogoTypeID === undefined) && (logo.Action !== 'Delete')) {
                          errorCount += 1;
                          this.toast.error(this.translate.instant('EDITORDERSITEMS.LOGO_PRINTPRICE_VALIDATION'));
                        }
                      }
                    }
                  });
                }
              }
            }
          } else {
            const subOrders = this.order.Suborders.filter(datanew => Number(datanew.OrderID) === Number(this.orderID));
            if (subOrders.length > 0) {
              let orderitems;
              orderitems = subOrders[0].OrderItems.filter(datanew1 => Number(datanew1.OrderItemID) === Number(this.orderItemID));
              console.log("Order ietms After Subit  ", orderitems);
              if (orderitems.length > 0) {
                const orderUpdateData = orderitems[0];
                /** To Validate Order QTY */
                if (Number(orderUpdateData.OrderedQuantity) <= 0 || orderUpdateData.OrderedQuantity === '') {
                  errorCount += 1;
                  this.toast.error(this.translate.instant('EDITORDERSITEMS.QUANTITY_VALIDATION'));
                }

                /** To Validate Order Item TransportationCost */
                if (Number(orderUpdateData.OrderItemTransportationCost) < 0 || orderUpdateData.OrderItemTransportationCost === '') {
                  errorCount += 1;
                  this.toast.error(this.translate.instant('EDITORDERSITEMS.TRANSPORTATIONCOST_EMPTYVALIDATION'));
                }

                if (orderUpdateData.OrderItemTransportationCost !== '') {
                  if (String(orderUpdateData.OrderItemTransportationCost).match(/^\d*\.?\d{0,2}$/)) {
                  } else {
                    this.toast.error(this.translate.instant('EDITORDERSITEMS.TRANSPORTATIONCOST_VALIDATION'));
                    errorCount += 1;
                  }
                }

                /** To Validate Order Item Product Adjust price Field */
                if (orderUpdateData.AdjustedProductPrice !== undefined &&
                  orderUpdateData.AdjustedProductPrice !== null) {
                  if (Number(orderUpdateData.AdjustedProductPrice) > 0) {
                    if (String(orderUpdateData.AdjustedProductPrice).match(/^\d*\.?\d{0,2}$/)) {
                    } else {
                      this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTPRODUCTPRICE_VALIDATION'));
                      errorCount += 1;
                    }
                  }
                  if (isNaN(orderUpdateData.AdjustedProductPrice)) {
                    this.toast.error(this.translate.instant('EDITORDERSITEMS.ADJUSTPRODUCTPRICE_VALIDATION'));
                    errorCount += 1;
                  }
                }

                /** To Validate Order Item Discount Field */
                if (orderUpdateData.DiscountPercentage !== '' && orderUpdateData.DiscountPercentage !== undefined &&
                  orderUpdateData.DiscountPercentage !== null) {
                  if (String(orderUpdateData.DiscountPercentage).match(/^\d*\.?\d{0,2}$/)) {
                  } else {
                    this.toast.error(this.translate.instant('EDITORDERSITEMS.DISCOUNT_VALIDATION'));
                    errorCount += 1;
                  }
                }

                /** Check Order Logo Section Validation */
                /** To check Logo Section have Duplicate Print Price Label Name(if Logo Section have print Price Dropdown) */
                // tslint:disable-next-line:max-line-length
                if (orderUpdateData.OrderLogos.length > 0) {
                  // tslint:disable-next-line:max-line-length
                  if (orderUpdateData.OrderLogos[0].PrintPriceDescription !== null && orderUpdateData.OrderLogos[0].PrintPriceDescription !== undefined && orderUpdateData.productionLine !== null &&
                    orderUpdateData.productionLine !== undefined) {
                    const orderLogos = orderUpdateData.OrderLogos.filter(data1 => (data1.Action !== 'Delete'));
                    if (orderLogos.length > 0) {
                      const valueArr = orderLogos.map(function (item) { return item.NumberOfColors });
                      const isDuplicate = valueArr.some(function (item, idx) {
                        return valueArr.indexOf(item) !== idx;
                      });
                      if (isDuplicate) {
                        this.toast.error(this.translate.instant('EDITORDERSITEMS.DUPLICATE_LOGO_PRINTPRICE'));
                        errorCount += 1;
                      }
                    }
                  }

                  orderUpdateData.OrderLogos.forEach((logo, index) => {
                    /** Check Order Logo Color with NumberofColor for Each Logo item */
                    const colorFilter = logo.OrderLogoColors.filter(data1 => (data1.Action === null || data1.Action === undefined
                      || data1.Action !== 'Delete'));
                    // if (colorFilter.length !== logo.NumberOfColors && (logo.Action !== 'Delete') &&
                    //   orderUpdateData.productionLine !== null && orderUpdateData.productionLine !== undefined) {
                    //   errorCount += 1;
                    //   // tslint:disable-next-line:max-line-length
                    //   this.toast.error(this.translate.instant('EDITORDERSITEMS.FILL') + ' ' + logo.NumberOfColors + this.translate.instant('EDITORDERSITEMS.COLOR_FOR') + ' ' + logo.NumberOfColors + ' ' + this.translate.instant('EDITORDERSITEMS.LOGO_ITEM'));
                    // }

                    /** Check Order Preferred Logo Position Section Validation */
                    if (logo.isChecked) {
                      if (logo.LogoPositionComment === '' || logo.LogoPositionComment === null || logo.LogoPositionComment === undefined) {
                        errorCount += 1;
                        this.toast.error(this.translate.instant('EDITORDERSITEMS.PREFERRED_LOGO_COMMENT_VALIDATION'));
                      }
                    }

                    /** Check Order Preferred Logo Position Section Validation when Preferred Logo Position is hide(orderUpdateData.product.ProductLogoPositions Length is 0) */
                    // tslint:disable-next-line:max-line-length
                    if (orderUpdateData.product.ProductLogoPositions.length <= 0 && !logo.isChecked) {
                      if (logo.LogoPositionComment === '' || logo.LogoPositionComment === null || logo.LogoPositionComment === undefined) {
                        errorCount += 1;
                        this.toast.error(this.translate.instant('EDITORDERSITEMS.PREFERRED_LOGO_COMMENT_VALIDATION'));
                      }
                    }

                    /** Check Order Print Price Empty Validation */
                    // tslint:disable-next-line:max-line-length
                    if (orderUpdateData.productionLine !== null && orderUpdateData.productionLine !== undefined) {
                      if (orderUpdateData.productionLine.ProductionLinePrices.length > 0) {
                        if ((logo.LogoTypeID === '' || logo.LogoTypeID === null || Number(logo.LogoTypeID) === 0
                          || logo.LogoTypeID === undefined) && (logo.Action !== 'Delete')) {
                          errorCount += 1;
                          this.toast.error(this.translate.instant('EDITORDERSITEMS.LOGO_PRINTPRICE_VALIDATION'));
                        }
                      }
                    }
                  });
                }
              }
            }
          }

          if (Number(errorCount) > 0) {
            this.showLoader = false;
            return false;
          } else {
            this.showLoader = true;
            if (this.order.AdjustedAmount !== null && this.order.AdjustedAmount !== '' && this.order.AdjustedAmount !== undefined) {
              this.order.AdjustedAmount = Number(this.order.AdjustedAmount);
            }
            if (this.isSuborder) {
              if (this.order.Suborders !== null && this.order.Suborders.length > 0) {
                const orderIndex = this.order.Suborders.findIndex(x => x.SuborderID === this.SuborderID);
                const itemIndex = this.order.Suborders[orderIndex].OrderItems.findIndex(x => x.OrderItemID === this.orderItemID);
                // tslint:disable-next-line:max-line-length
                this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderItemTransportationCost = Number(this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderItemTransportationCost);

                if (this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice !== null) {
                  // tslint:disable-next-line:max-line-length
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice = Number(this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice);
                }
                console.log('itemIndex..', itemIndex)
                if (this.order.Suborders[orderIndex].OrderItems[itemIndex].UpdatedTransportationCost && this.order.UpdatedTransportationCost ? this.order.Suborders[orderIndex].OrderItems[itemIndex].UpdatedTransportationCost === this.order.UpdatedTransportationCost : ''
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderedQuantity && this.order.OrderedQuantity ? this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderedQuantity === this.order.OrderedQuantity : ''
                    && this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice && this.order.AdjustedProductPrice ? this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice === this.order.AdjustedProductPrice : ''
                      && this.order.Suborders[orderIndex].OrderItems[itemIndex].UserDiscountPercentage && this.order.UserDiscountPercentage ? this.order.Suborders[orderIndex].OrderItems[itemIndex].UserDiscountPercentage === this.order.UserDiscountPercentage : ''
                        && this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderItemTransportationCost && this.order.OrderItemTransportationCost ? this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderItemTransportationCost === this.order.OrderItemTransportationCost : ''
                          && this.order.Suborders[orderIndex].OrderItems[itemIndex].AdditionalTransporationCost && this.order.AdditionalTransporationCost ? this.order.Suborders[orderIndex].OrderItems[itemIndex].AdditionalTransporationCost === this.order.AdditionalTransporationCost : ''
                            && this.order.Suborders[orderIndex].OrderItems[itemIndex].ShippingFee && this.order.ShippingFee ? this.order.Suborders[orderIndex].OrderItems[itemIndex].ShippingFee === this.order.ShippingFee : ''
                              && this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderitemCustomFee && this.order.OrderitemCustomFee ? this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderitemCustomFee === this.order.OrderitemCustomFee : ''
                ) {
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].Action = "Update"
                }
                else if (this.order.Suborders[orderIndex].OrderItems[itemIndex].UpdatedTransportationCost !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].UpdatedTransportationCost !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderedQuantity !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderedQuantity !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].AdjustedProductPrice !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].UserDiscountPercentage !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].UserDiscountPercentage !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderItemTransportationCost !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderItemTransportationCost !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].AdditionalTransporationCost !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].AdditionalTransporationCost !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].ShippingFee !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].ShippingFee !== null
                  && this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderitemCustomFee !== undefined &&
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].OrderitemCustomFee !== null) {
                  this.order.Suborders[orderIndex].OrderItems[itemIndex].Action = "Update"
                }
                //this.order.Suborders[orderIndex].OrderItems[itemIndex].Action = "Update"

              }
            } else {
              if (this.order.OrderItems !== null && this.order.OrderItems.length > 0) {
                debugger;
                const orderIndex = this.order.OrderItems.findIndex(x => x.OrderItemID === this.orderItemID);
                if (this.order.OrderItems[orderIndex].UpdatedTransportationCost && this.order.UpdatedTransportationCost ? this.order.OrderItems[orderIndex].UpdatedTransportationCost === this.order.UpdatedTransportationCost : ''
                  && this.order.OrderItems[orderIndex].OrderedQuantity && this.order.OrderedQuantity ? this.order.OrderItems[orderIndex].OrderedQuantity === this.order.OrderedQuantity : ''
                    && this.order.OrderItems[orderIndex].AdjustedProductPrice && this.order.AdjustedProductPrice ? this.order.OrderItems[orderIndex].AdjustedProductPrice === this.order.AdjustedProductPrice : ''
                      && this.order.OrderItems[orderIndex].UserDiscountPercentage && this.order.UserDiscountPercentage ? this.order.OrderItems[orderIndex].UserDiscountPercentage === this.order.UserDiscountPercentage : ''
                        && this.order.OrderItems[orderIndex].OrderItemTransportationCost && this.order.OrderItemTransportationCost ? this.order.OrderItems[orderIndex].OrderItemTransportationCost === this.order.OrderItemTransportationCost : ''
                          && this.order.OrderItems[orderIndex].AdditionalTransporationCost && this.order.AdditionalTransporationCost ? this.order.OrderItems[orderIndex].AdditionalTransporationCost === this.order.AdditionalTransporationCost : ''
                            && this.order.OrderItems[orderIndex].ShippingFee && this.order.ShippingFee ? this.order.OrderItems[orderIndex].ShippingFee === this.order.ShippingFee : ''
                              && this.order.OrderItems[orderIndex].OrderitemCustomFee && this.order.OrderitemCustomFee ? this.order.OrderItems[orderIndex].OrderitemCustomFee === this.order.OrderitemCustomFee : ''
                ) {
                  this.order.OrderItems[orderIndex].Action = "Update"
                }
                else if (this.order.OrderItems[orderIndex].UpdatedTransportationCost !== undefined &&
                  this.order.OrderItems[orderIndex].UpdatedTransportationCost !== null
                  && this.order.OrderItems[orderIndex].OrderedQuantity !== undefined &&
                  this.order.OrderItems[orderIndex].OrderedQuantity !== null
                  && this.order.OrderItems[orderIndex].AdjustedProductPrice !== undefined &&
                  this.order.OrderItems[orderIndex].AdjustedProductPrice !== null
                  && this.order.OrderItems[orderIndex].UserDiscountPercentage !== undefined &&
                  this.order.OrderItems[orderIndex].UserDiscountPercentage !== null
                  && this.order.OrderItems[orderIndex].OrderItemTransportationCost !== undefined &&
                  this.order.OrderItems[orderIndex].OrderItemTransportationCost !== null
                  && this.order.OrderItems[orderIndex].AdditionalTransporationCost !== undefined &&
                  this.order.OrderItems[orderIndex].AdditionalTransporationCost !== null
                  && this.order.OrderItems[orderIndex].ShippingFee !== undefined &&
                  this.order.OrderItems[orderIndex].ShippingFee !== null
                  && this.order.OrderItems[orderIndex].OrderitemCustomFee !== undefined &&
                  this.order.OrderItems[orderIndex].OrderitemCustomFee !== null) {
                  this.order.OrderItems[orderIndex].Action = "Update"
                }

                // tslint:disable-next-line:max-line-length
                this.order.OrderItems[orderIndex].OrderItemTransportationCost = Number(this.order.OrderItems[orderIndex].OrderItemTransportationCost);

                if (this.order.OrderItems[orderIndex].AdjustedProductPrice !== undefined &&
                  this.order.OrderItems[orderIndex].AdjustedProductPrice !== null) {
                  // tslint:disable-next-line:max-line-length
                  this.order.OrderItems[orderIndex].AdjustedProductPrice = Number(this.order.OrderItems[orderIndex].AdjustedProductPrice);

                }
                //this.order.OrderItems[orderIndex].Action = "Update"
                // console.log('this.order.OrderItems[orderIndex]..', this.order.OrderItems[orderIndex])
              }
            }
            console.log('this.orderrequest..', this.order)
            let reqData = { ...this.order };
            this.orderHttpService.updateOrder(reqData, this.order.OrderID).subscribe((result: any) => {
              this.showLoader = false;
              this.toast.success(this.translate.instant('EDITORDERSITEMS.ORDER_UPDATE_SUCCESS'));
              // this.getOrderDetails(this.orderID);
              this.orderStatusPage();
            }, error => {
              // this.toast.error(this.translate.instant('EDITORDERSITEMS.ORDER_UPDATE_FAILURE'));
              let errMsg = 'The request is invalid. ';
              for (var key in error.error.ModelState) {
                errMsg += error.error.ModelState[key].toString() + ' ';
              }
              this.toast.error(errMsg);
              this.showLoader = false;
            });
          }
        } else {
          this.showLoader = false;
          return false;
        }
      });
    });
  }

  orderStatusPage() {
    debugger
    const orderData = {
      'orderID': this.orderID,
      'orderItemID': this.orderItemID,
      'isSubOrder': this.isSuborder,
      'suborderID': history.state.data.suborderID
    }
    if (!this.viewDetails) {
      this.router.navigate(['/view-order'], { state: { data: orderData }, replaceUrl: false });
    } else {
      if (this.SuborderID !== '' && this.SuborderID > 0) {
        this.router.navigate([`admin-order-process/orderProcess/${this.orderID}/${this.SuborderID}/${1}`],
          { state: { subOrderItemData: this.subOrderItemData, isSubOrder: this.isSuborder }, replaceUrl: false });
      } else if (history.state.returnPage === 'unauthorised') {
        const customerID = history.state.customerID;
        this.router.navigate([`/admin-order-process/unauthorised/${this.orderID}`], {
          state: { customerID, subOrderItemData: this.subOrderItemData, isSubOrder: this.isSuborder }, replaceUrl: false
        });
      }
      else {
        this.router.navigate([`admin-order-process/orderProcess/${this.orderID}/${this.orderItemID}/${0}`], { state: { subOrderItemData: this.subOrderItemData, isSubOrder: this.isSuborder }, replaceUrl: false });
      }
    }
  }

  stateChanged(status, i, j, suborderIndex?) {
    let name = 'showCustomLogoPosition_' + i + '_' + j;
    let name1 = 'showLogoPos_' + i + '_' + j;
    if (status) {
      // if($scope.isSuborder) {
      // 	$scope.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoPositionID=0;
      // }
      // else{
      // 	$scope.order.OrderItems[i].OrderLogos[j].LogoPositionID=0;
      // }

      // $scope[name] = true;
      // $scope[name1] = true;
      this.showCustomLogoPosition[i][j] = true;
      this.showLogoPos[i][j] = true;
    } else {
      // $scope[name] = false;
      // $scope[name1] = false;
      this.showCustomLogoPosition[i][j] = false;
      this.showLogoPos[i][j] = false;

      if (this.isSuborder) {
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoPositionComment = '';
      } else {
        this.order.OrderItems[i].OrderLogos[j].LogoPositionComment = '';
      }
      this.logo_change_action(i, j, suborderIndex);
    }
  }

  onloadstateChanged(comment, i, j, suborderIndex = null) {
    let name = 'showCustomLogoPosition_' + i + '_' + j;
    let name1 = 'showLogoPos_' + i + '_' + j;
    if (comment !== undefined && comment !== '' && comment !== null) {
      if (this.isSuborder) {
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].isChecked = true;
        // $scope.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoPositionID=0;
        this.showCustomLogoPosition[i][j] = true;
        this.showLogoPos[i][j] = true;
        // $scope[name] = true;
        // $scope[name1] = true;
      } else {
        this.order.OrderItems[i].OrderLogos[j].isChecked = true;
        // $scope.order.OrderItems[i].OrderLogos[j].LogoPositionID=0;
        this.showCustomLogoPosition[i][j] = true;
        this.showLogoPos[i][j] = true;
        // $scope[name] = true;
        // $scope[name1] = true;
      }
    } else {
      if (this.isSuborder) {
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].isChecked = false;
      } else {
        this.order.OrderItems[i].OrderLogos[j].isChecked = false;
      }
      this.showCustomLogoPosition[i][j] = false;
      this.showLogoPos[i][j] = false;
    }
    console.log("this.showCustomLogoPosition : ", this.showCustomLogoPosition);
  }

  logoStateChanged(status, i, j, suborderIndex?) {
    let name = 'showCustomLogoPosition_' + i + '_' + j;
    if (this.isSuborder) {
      // $scope.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoPositionComment='';
      this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].isChecked = false;
      this.showCustomLogoPosition[i][j] = false;
      // $scope[name] = false;
    } else {
      // $scope.order.OrderItems[i].OrderLogos[j].LogoPositionComment='';	
      this.order.OrderItems[i].OrderLogos[j].isChecked = false;
      this.showCustomLogoPosition[i][j] = false;
      console.log("this.showCustomLogoPosition : ", this.showCustomLogoPosition);
      // $scope[name] = false;
    }
  }

  changeLogoPosition(i, j, suborderIndex?) {
    if (this.isSuborder) {
      if (this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action === undefined &&
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action !== 'Insert') {
        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action = 'Update';
      }
    } else {
      if (this.order.OrderItems[i].OrderLogos[j].Action === undefined &&
        this.order.OrderItems[i].OrderLogos[j].Action !== 'Insert') {
        this.order.OrderItems[i].OrderLogos[j].Action = 'Update';
      }
    }
  }

  AddLogoSection(i, suborderIndex?) {
    // tslint:disable-next-line:variable-name
    const order_logo = {
      Action: 'Insert',
      NumberOfColors: 0,
      LogoTypeID: 0,
      LogoTypeName: '',
      PrintPriceDescription: '',
      LogoPositionID: 0,
      LogoPositionDescription: '',
      LogoMaxAreaID: 0,
      LogoMaxAreaDescription: '',
      FileName: '',
      FileURL: '',
      ProductPrintPrice: 0,
      ScreenChargesCost: 0.0,
      SetupCost: 0.0,
      HandlingCost: 0.0,
      ToolCost: 0.0,
      OrderLogoColors: []
    };

    if (this.isSuborder) {
      /** To check Logo Section have Duplicate Print Price Label Name(if Logo Section have print Price Dropdown) */
      // tslint:disable-next-line:max-line-length
      if (this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[0] !== null && this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[0] !== undefined) {
        // tslint:disable-next-line:max-line-length
        if (this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[0].PrintPriceDescription !== null && this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[0].PrintPriceDescription !== undefined) {
          const orderLogos = this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos.filter(data1 => (data1.Action !== 'Delete'));
          if (orderLogos.length > 0) {
            const valueArr = orderLogos.map(function (item) { return item.NumberOfColors });
            const isDuplicate = valueArr.some(function (item, idx) {
              return valueArr.indexOf(item) !== idx;
            });
            if (isDuplicate) {
              this.toast.error(this.translate.instant('EDITORDERSITEMS.DUPLICATE_LOGO_PRINTPRICE'));
              return false;
            }
          }
        }
      }
      this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos.push(order_logo);
      const resetIndex = this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos.length - 1;
      this.logosectionId[resetIndex] = '';
    } else {
      /** To check Logo Section have Duplicate Print Price Label Name(if Logo Section have print Price Dropdown) */
      // tslint:disable-next-line:max-line-length
      if (this.order.OrderItems[i].OrderLogos[0] !== null && this.order.OrderItems[i].OrderLogos[0] !== undefined) {
        if (this.order.OrderItems[i].OrderLogos[0].PrintPriceDescription !== null &&
          this.order.OrderItems[i].OrderLogos[0].PrintPriceDescription !== undefined) {
          const orderLogos = this.order.OrderItems[i].OrderLogos.filter(data1 => (data1.Action !== 'Delete'));
          if (orderLogos.length > 0) {
            const valueArr = orderLogos.map(function (item) { return item.NumberOfColors });
            const isDuplicate = valueArr.some(function (item, idx) {
              return valueArr.indexOf(item) !== idx;
            });
            if (isDuplicate) {
              this.toast.error(this.translate.instant('EDITORDERSITEMS.DUPLICATE_LOGO_PRINTPRICE'));
              return false;
            }
          }
        }
      }
      this.order.OrderItems[i].OrderLogos.push(order_logo);
      const resetIndex = this.order.OrderItems[i].OrderLogos.length - 1;
      this.logosectionId[resetIndex] = '';
    }
  }

  changelogosection(i, j, logosections?, suborderIndex?) {
    debugger;
    let logosection: any;
    /** To get Changed print Price Value from Html Code => new code because of Select dropdown instead of RadioBox */

    if (this.isSuborder) {
      this.order.Suborders.forEach((obj1, index1) => {
        obj1.OrderItems.forEach((obj, index) => {
          if (obj.ProductionLineID !== '' && obj.ProductionLineID !== undefined && obj.ProductionLineID !== null &&
            Number(obj.ProductionLineID) > 0) {
            // tslint:disable-next-line:max-line-length
            if (obj.productionLine !== undefined && obj.productionLine !== null) {
              obj.OrderLogos.forEach((OrderLogo, q) => {
                obj.productionLine.ProductionLinePrices.forEach((ProductPrice, p) => {
                  // tslint:disable-next-line:max-line-length
                  if (Number(ProductPrice.MinimumQuantity) <= Number(obj.OrderedQuantity) && Number(obj.OrderedQuantity) <= Number(ProductPrice.MaximumQuantity)) {
                    ProductPrice.ProductionLinePrintPrices.forEach((prints, k) => {
                      // if (Number(prints.ProductionLinePrintPriceID) === Number(this.logosectionId)) {
                      // if (this.logosectionId.indexOf(prints.ProductionLinePrintPriceID) !== -1) {
                      if (Number(this.logosectionId[j]) === Number(prints.ProductionLinePrintPriceID)) {
                        console.log("logosectionId[j] : ", this.logosectionId);
                        console.log("Logo Section : ", logosection);
                        logosection = prints;
                        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].NumberOfColors = logosection.NumberOfColors;
                        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoTypeID = logosection.LogoTypeID;
                        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoPositionID = logosection.LogoPositionID;
                        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoMaxAreaID = logosection.LogoMaxAreaID;
                        if (logosection.HandlingCost !== undefined && logosection.HandlingCost !== null) {
                          // tslint:disable-next-line:max-line-length
                          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].HandlingCost = logosection.HandlingCost.toFixed(2);
                        }
                        if (logosection.ScreenChargesCost !== undefined && logosection.ScreenChargesCost !== null) {
                          // tslint:disable-next-line:max-line-length
                          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].ScreenChargesCost = logosection.ScreenChargesCost.toFixed(2);
                        }
                        if (logosection.SetupCost !== undefined && logosection.SetupCost !== null) {
                          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].SetupCost = logosection.SetupCost.toFixed(2);
                        }
                        if (logosection.ToolCost !== undefined && logosection.ToolCost !== null) {
                          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].ToolCost = logosection.ToolCost.toFixed(2);
                        }
                        /** Old Code */
                        if (this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action === undefined && this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action !== 'Insert'
                        ) {
                          this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].Action = 'Update';
                        }
                        /** Old Code End */
                      }
                      if (this.logosectionId[j] === '') {
                        this.order.Suborders[suborderIndex].OrderItems[i].OrderLogos[j].LogoTypeID = '';
                      }
                    });
                  }
                });
              });
            }
          }
        });
      });
    } else {
      this.order.OrderItems.forEach((obj, index) => {
        if (obj.ProductionLineID !== '' && obj.ProductionLineID !== undefined && obj.ProductionLineID !== null &&
          Number(obj.ProductionLineID) > 0) {
          // tslint:disable-next-line:max-line-length
          if (this.order['OrderItems'][index].productionLine !== undefined && this.order['OrderItems'][index].productionLine !== null) {
            obj.OrderLogos.forEach((OrderLogo, q) => {
              this.order['OrderItems'][index].productionLine.ProductionLinePrices.forEach((ProductPrice, p) => {
                // tslint:disable-next-line:max-line-length
                if (Number(ProductPrice.MinimumQuantity) <= Number(obj.OrderedQuantity) && Number(obj.OrderedQuantity) <= Number(ProductPrice.MaximumQuantity)) {
                  ProductPrice.ProductionLinePrintPrices.forEach((prints, k) => {
                    // if (Number(prints.ProductionLinePrintPriceID) === Number(this.logosectionId)) {
                    // if (this.logosectionId.indexOf(prints.ProductionLinePrintPriceID) !== -1) {
                    if (Number(this.logosectionId[j]) === Number(prints.ProductionLinePrintPriceID)) {
                      logosection = prints;

                      console.log("logosectionId[j] : ", this.logosectionId);
                      console.log("Logo Section : ", logosection);
                      this.order.OrderItems[i].OrderLogos[j].NumberOfColors = logosection.NumberOfColors;
                      this.order.OrderItems[i].OrderLogos[j].LogoTypeID = logosection.LogoTypeID;
                      this.order.OrderItems[i].OrderLogos[j].LogoPositionID = logosection.LogoPositionID;
                      this.order.OrderItems[i].OrderLogos[j].LogoMaxAreaID = logosection.LogoMaxAreaID;
                      if (logosection.HandlingCost !== undefined && logosection.HandlingCost !== null) {
                        this.order.OrderItems[i].OrderLogos[j].HandlingCost = logosection.HandlingCost.toFixed(2);
                      }
                      if (logosection.ScreenChargesCost !== undefined && logosection.ScreenChargesCost !== null) {
                        this.order.OrderItems[i].OrderLogos[j].ScreenChargesCost = logosection.ScreenChargesCost.toFixed(2);
                      }
                      if (logosection.SetupCost !== undefined && logosection.SetupCost !== null) {
                        this.order.OrderItems[i].OrderLogos[j].SetupCost = logosection.SetupCost.toFixed(2);
                      }
                      if (logosection.ToolCost !== undefined && logosection.ToolCost !== null) {
                        this.order.OrderItems[i].OrderLogos[j].ToolCost = logosection.ToolCost.toFixed(2);
                      }

                      /** Old Code */
                      if (this.order.OrderItems[i].OrderLogos[j].Action === undefined &&
                        this.order.OrderItems[i].OrderLogos[j].Action !== 'Insert') {
                        this.order.OrderItems[i].OrderLogos[j].Action = 'Update';
                      }
                      /** Old Code End */

                    }
                    if (this.logosectionId[j] === '') {
                      this.order.OrderItems[i].OrderLogos[j].LogoTypeID = '';
                    }
                  });
                }
              });
            });
          }
        }
      });
    }

  }

  logooption(j, prints, OrderLogo) {
    if (prints.LogoTypeID === OrderLogo.LogoTypeID &&
      prints.NumberOfColors === OrderLogo.NumberOfColors &&
      (prints.LogoPositionID === OrderLogo.LogoPositionID || prints.LogoPositionID === null || prints.LogoPositionID === undefined) &&
      (prints.LogoMaxAreaID === OrderLogo.LogoMaxAreaID || prints.LogoMaxAreaID === null || prints.LogoMaxAreaID === undefined)) {
      console.log("Finally Inside : ", prints);
      this.logosection[j] = prints;
      this.logosectionId[j] = prints.ProductionLinePrintPriceID;
    }
  }

  checkFileExt(newFile) {
    const allowedExt = ['eps', 'ai', 'pdf']
    const allowedSize = 0; //Use 0 if no size validation required or use size in MB
    const sFileName = newFile.name ? newFile.name : '';
    const sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
    const iConvert = (newFile.size / 10485760).toFixed(2);
    const result = allowedSize === 0 ? (allowedExt.indexOf(sFileExtension) > -1) : ((allowedExt.indexOf(sFileExtension) > -1)
      && (Number(iConvert) <= Number(allowedSize)));
    if (result) {
      return result;
    } else {
      this.toast.info(this.translate.instant('EDITORDERSITEMS.LOGO_FILE_FORMATS'));
      return result;
    }
  }

  fileUpload(files, event?, flow?, $index?) {
    // files.file.forEach((value) => {
    if (files.file !== undefined) {
      if (this.checkFileExt(files.file)) {
        if (files.file.size !== '') {
          const maximumMb = files.file.size / 1024 / 1024;
          if (maximumMb > 10) {
            this.toast.error(this.translate.instant('EDITORDERSITEMS.LOG_FILE_VALIDATION'));
            return false;
          }
        }
        let formdata = new FormData();
        formdata.append('file', files.file.nativeFile);
        formdata.append('filePath', 'UserLogos');
        this.showLoader = true;
        this.orderHttpService.uploadLogos(formdata).subscribe((result: any) => {
          this.showLoader = true;
          const userLogoData = [];
          userLogoData.push({
            UserID: this.order.UserID,
            FileName: result[1]
          });
          this.orderHttpService.uploadUserLogos(userLogoData).subscribe((result1: any) => {
            this.showLoader = false;
            if (result1 === true) {
              this.orderHttpService.getUserLogos(this.order.UserID).subscribe((userLogoResponse: any) => {
                this.showLoader = false;
                this.Userlogos = userLogoResponse;
                this.UploadedLogoUrl = this.Userlogos[0].FileURL;
              }, error => {
                this.toast.error(this.translate.instant('EDITORDERSITEMS.USERLOGO_UPDATE_FAILURE'));
                this.showLoader = false;
              });
            }
          }, error => {
            this.toast.error(this.translate.instant('EDITORDERSITEMS.USERLOGO_UPDATE_FAILURE'));
            this.showLoader = false;
          });
        }, error => {
          this.toast.error(this.translate.instant('EDITORDERSITEMS.ORDER_UPDATE_FAILURE'));
          this.showLoader = false;
        });
      }
    }
    //  });
  }

  /*** Get User Logos ***/
  getUserLogos(userId) {
    this.showLoader = true;
    this.orderHttpService.getUserLogos(userId).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.Userlogos = data;
      }
    }, error => {
      this.showLoader = false;
    });
  }

  deleteUserLogoDetails(index) {
    this.deleteUserLogoId = index;
    this.deleteUserLogoModal.show();
  }
  /**** User Logo Delete ***/
  delete_user_logo(status) {
    if (status && this.deleteUserLogoId !== '') {
      this.deleteUserLogoModal.hide();
      this.showLoader = true;
      this.orderHttpService.deleteLogo(this.deleteUserLogoId).subscribe((result: any) => {
        this.showLoader = false;
        if (result) {
          this.deleteUserLogoId = '';
          this.getUserLogos(this.order.UserID);
          if (result === true || result === 'true') {
            this.toast.success(this.translate.instant('EDITORDERSITEMS.LOGO_DELETE_SUCCESSMESSAGE'));
          }
        }
      }, error => {
        this.showLoader = false;
        this.toast.error(error);
      });
    }
  }

  /*** Get User Logos ***/
  getProductDetail(productId): Observable<any> {
    const productDetail = new Subject<any>();
    this.showLoader = true;
    this.orderHttpService.getProductDetail(productId).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        productDetail.next(data);
      }
    }, error => {
      this.showLoader = false;
      productDetail.next('');
    });
    return productDetail.asObservable();
  }

  /*** Get User Logos ***/
  getProductionLineDetail(productionLineId, CustomerId, CurrencyID): Observable<any> {
    const productLineData = new Subject<any>();
    //  this.showLoader = true;
    this.orderHttpService.getProductionLineDetail(productionLineId, CustomerId, CurrencyID).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        productLineData.next(data);
      }
    }, error => {
      this.showLoader = false;
      productLineData.next('');
    });
    return productLineData.asObservable();
  }

  getPaymentTypes() {
    // this.showLoader = true;
    const request = {
      userID: this.order.UserID,
      customerID: this.order.CustomerID
    }

    this.orderHttpService.newPayment(request).subscribe((result: any) => {
      //  this.showLoader = false;
      if (result) {
        let paymentTypes = result;
        paymentTypes.forEach(element => {
          element.label = element.Name;
          element.value = element.PaymentTypeID;
        });
        paymentTypes = paymentTypes.filter(data => data.Code !== 'CC' && data.Code !== 'PP');
        this.paymentTypes = paymentTypes;
        console.log('paymentTypes', this.paymentTypes);
        //  this.showLoader = false;
      }
    }, error => {
      this.showLoader = false;
    });
  }

  setVATValidation(event = null) {
    let data;
    if (event === null) {
      data = this.order.InvoiceCountry;
    } else {
      data = (event.value !== undefined && event.value !== null) ? event.value : event;
    }
    const vatMandatoryField = this.countryList.find(x => x.Alpha2Code === data);
    this.vatMandatoryFieldStatus = false;
    if (vatMandatoryField.length > 0) {
      if (vatMandatoryField.isVATNumberMandatory) {
        this.vatMandatoryFieldStatus = true;
      }
    }
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    let subject = new Subject<any>();
    this.orderHttpService.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  allowDecimal(event, i, subOrderIndex = null) {
    let returnValue;
    returnValue = true;
    const charCode = event.charCode;
    if (Number(charCode) === 44) {
      if (!this.isSuborder) {
        setTimeout(() => {
          const subOrderUpdatedTransportationCost = this.order.OrderItems[i].UpdatedTransportationCost;
          const subOrderAdditionalTransporationCost = this.order.OrderItems[i].AdditionalTransporationCost;
          const subOrderShippingFee = this.order.OrderItems[i].ShippingFee;
          const orderItemTransportationCost = this.order.OrderItems[i].OrderItemTransportationCost;
          const orderitemCustomFee = this.order.OrderItems[i].OrderitemCustomFee;

          this.order.OrderItems[i].AdjustedProductPrice = this.replaceCommaWithDot(this.order.OrderItems[i].AdjustedProductPrice);
          this.order.OrderItems[i].UpdatedTransportationCost = this.replaceCommaWithDot(subOrderUpdatedTransportationCost);
          this.order.OrderItems[i].AdditionalTransporationCost = this.replaceCommaWithDot(subOrderAdditionalTransporationCost);
          this.order.OrderItems[i].ShippingFee = this.replaceCommaWithDot(subOrderShippingFee);
          this.order.OrderItems[i].OrderItemTransportationCost = this.replaceCommaWithDot(orderItemTransportationCost);
          this.order.OrderItems[i].OrderitemCustomFee = this.replaceCommaWithDot(orderitemCustomFee);
        }, 10);
      } else {
        setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          const adjustedProductPrice = this.order.Suborders[subOrderIndex].OrderItems[i].AdjustedProductPrice;
          const updatedTransportationCost = this.order.Suborders[subOrderIndex].OrderItems[i].UpdatedTransportationCost;
          const additionalTransporationCost = this.order.Suborders[subOrderIndex].OrderItems[i].AdditionalTransporationCost;
          const shippingFee = this.order.Suborders[subOrderIndex].OrderItems[i].ShippingFee;
          const subOrderOrderItemTransportationCost = this.order.Suborders[subOrderIndex].OrderItems[i].OrderItemTransportationCost;
          const orderitemCustomFee = this.order.Suborders[subOrderIndex].OrderItems[i].OrderitemCustomFee;

          this.order.Suborders[subOrderIndex].OrderItems[i].AdjustedProductPrice = this.replaceCommaWithDot(adjustedProductPrice);
          this.order.Suborders[subOrderIndex].OrderItems[i].UpdatedTransportationCost = this.replaceCommaWithDot(updatedTransportationCost);
          this.order.Suborders[subOrderIndex].OrderItems[i].AdditionalTransporationCost = this.replaceCommaWithDot(additionalTransporationCost);
          this.order.Suborders[subOrderIndex].OrderItems[i].ShippingFee = this.replaceCommaWithDot(shippingFee);
          this.order.Suborders[subOrderIndex].OrderItems[i].OrderItemTransportationCost = this.replaceCommaWithDot(subOrderOrderItemTransportationCost);
          this.order.Suborders[subOrderIndex].OrderItems[i].OrderitemCustomFee = this.replaceCommaWithDot(orderitemCustomFee);
        }, 10);
      }
    }
    if (
      (charCode !== 44) &&      // “,” CHECK COMMA AND ONLY ONE.
      (charCode !== 46) &&      // “.” CHECK DOT AND ONLY ONE.
      (charCode < 48 || charCode > 57)) {
      return false;
    }
    return returnValue;
  }

  getSelectedPayment(event) {
    this.order.PaymentTypeID = event.PaymentTypeID;
    this.order.PaymentTypeCode = event.Code;
    this.order.PaymentTypeName = event.Name;
  }

  replaceCommaWithDot(value) {
    return typeof (value) === 'string' ? value.replace(',', '.') : value;
  }

  checkPLValue(value) {
    if (value != '') {
      this.checkPL = false;
    } else {
      this.checkPL = true;
    }
  }

  changePL(checkValue, subIndex, index) {
    if (subIndex !== null) {
      this.order.Suborders[subIndex].OrderItems[index]['ApplyCostPriceToPL'] = checkValue.checked;
    } else {
      this.order.OrderItems[index]['ApplyCostPriceToPL'] = checkValue.checked;
    }
  }
}
