import { Action } from '@ngrx/store';

export const LOAD_CUSTOMERS = '[Login] Load All Customers';
export const LOAD_CUSTOMERS_SUCCESS = '[Login] Load Customers Success';
export const LOAD_CUSTOMERS_FAIL = '[Login] Load Customers Fail';

export const LOGIN = '[Login] Login';
export const LOGIN_SUCCESS = '[Login] Login Success';
export const LOGIN_FAIL = '[Login] Login Fail';
export const LOGGED_IN = '[Login] Logged In';

export const SELECTED_CUSTOMER = '[Login] Selected Customer';

export const LANGUAGE = '[Login] Language';
export const LANGUAGE_SUCCESS = '[Login] Language Success';
export const LANGUAGE_FAIL = '[Login] Language Fail';

export const CUSTOMERNAME = '[Login] CustomerName';
export const CUSTOMERNAME_SUCCESS = '[Login] CustomerName Success';
export const CUSTOMERNAME_FAIL = '[Login] CustomerName Fail';

export const LOAD_SUBDOMAIN = '[Domain] Load Subdomain';
export const SET_SUBDOMAIN = '[Domain] Set Subdomain';

export const GETWEBSHOPLANGUAGE = '[Login] Webshop Language';
export const WEBSHOPLANGUAGE_SUCCESS = '[Login] Webshop Language Success';
export const WEBSHOPLANGUAGE_FAIL = '[Login] Webshop Language Fail';




export class LoadCustomers implements Action {
    readonly type = LOAD_CUSTOMERS;
    constructor() { }
}

export class LoadCustomersSuccess implements Action {
    readonly type = LOAD_CUSTOMERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCustomersFail implements Action {
    readonly type = LOAD_CUSTOMERS_FAIL;
    constructor(public payload: any) { }
}

export class Login implements Action {
    readonly type = LOGIN;
    constructor(public payload: any) {
    }
}

export class LoginSuccess implements Action {
    readonly type = LOGIN_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoginFail implements Action {
    readonly type = LOGIN_FAIL;
    constructor(public payload: any) { }
}

export class LoggedIn implements Action {
    readonly type = LOGGED_IN;
    constructor(public payload: any) { }
}

export class SelectedCustomer implements Action {
    readonly type = SELECTED_CUSTOMER;
    constructor(public payload: any) { }
}

export class WebshopLanguage implements Action {
    readonly type = GETWEBSHOPLANGUAGE;
    constructor(public payload: any) {
    }
}

export class WebshopLanguageSuccess implements Action {
    readonly type = WEBSHOPLANGUAGE_SUCCESS;
    constructor(public payload: any) {
    }
}

export class WebshopLanguageFail implements Action {
    readonly type = WEBSHOPLANGUAGE_FAIL;
    constructor(public payload: any) { }
}
export class Language implements Action {
    readonly type = LANGUAGE;
    constructor() {
    }
}

export class LanguageSuccess implements Action {
    readonly type = LANGUAGE_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LanguageFail implements Action {
    readonly type = LANGUAGE_FAIL;
    constructor(public payload: any) { }
}

export class LoadCustomerName implements Action {
    readonly type = CUSTOMERNAME;
    constructor(public payload: any) { }
}

export class LoadCustomerNameSuccess implements Action {
    readonly type = CUSTOMERNAME_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCustomerNameFail implements Action {
    readonly type = CUSTOMERNAME_FAIL;
    constructor(public payload: any) { }
}

export class LoadSubDomain implements Action {
    readonly type = LOAD_SUBDOMAIN;
    constructor(public payload: any) { }
}
export class SetSubDomain implements Action {
    readonly type = SET_SUBDOMAIN;
    constructor(public payload: any) { }
}



export type All =
    | LoadCustomers
    | LoadCustomersSuccess
    | LoadCustomersFail
    | Login
    | LoginSuccess
    | LoginFail
    | LoggedIn
    | SelectedCustomer
    | Language
    | LanguageSuccess
    | LanguageFail
    | WebshopLanguage
    | WebshopLanguageSuccess
    | WebshopLanguageFail
    | LoadCustomerName
    | LoadCustomerNameSuccess
    | LoadCustomerNameFail
    | LoadSubDomain
    | SetSubDomain;


