import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductGroupsComponent } from './product-groups.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomdirectiveModule } from 'src/app/product/product-edit/customdirective/customdirective.module';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';
import { RouterModule } from '@angular/router';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { FilterTagModule } from 'src/app/common/common-filter/filter-tags/filter-tags.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductGroupsEditComponent } from './product-groups-edit/product-groups-edit.component';
import { showListModule } from 'src/app/user/showlist/showlist.module';
import { userAdditionalDeliveryListModule } from 'src/app/user/useradditionaldeliverylist/useradditionaldeliverylist.module';
import { ProductManageGroupsComponent } from './product-manage-groups/product-manage-groups.component';

@NgModule({
  declarations: [ProductGroupsComponent, ProductGroupsEditComponent, ProductManageGroupsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonFormModule,
    ControlValidationModule,
    SharedModule,
    TranslateModule,
    NgSelectModule,
    CustomdirectiveModule,
    DropdownPopupModule,
    RouterModule,
    PageLoaderModule,
    CommonFormModule,
    FilterTagModule,
    CommonFilterModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    showListModule,
    userAdditionalDeliveryListModule,
  ]
})
export class ProductGroupsModule { }
