import { Component, OnInit, Input } from '@angular/core';
import { OrderHttpService } from '../state/services/order.http.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'rewardkart-admin-orders-edit',
  templateUrl: './orders-edit.component.html',
  styleUrls: ['./orders-edit.component.scss']
})
export class OrdersEditComponent implements OnInit {
  orderItems = null;
  OrderItem = [];
  subOrderId = '';
  orderId = '';

  countries = [];

  invoiceAddress = {
    CompanyName: 'UserCompanyName',
    Recipient: 'InvoiceRecipient',
    Address1: 'InvoiceAddress',
    Address2: 'InvoiceAddress2',
    Address3: 'InvoiceAddress3',
    PostalCode: 'InvoicePostalCode',
    City: 'InvoiceCity',
    Country: 'InvoiceCountry',
    Email: 'Email',
    PhoneNumber: 'PhoneNumber',
    VAT: 'VAT'
  };

  deliveryAddress = {
    CompanyName: 'DeliveryCompanyName',
    Recipient: 'DeliveryRecipient',
    Address1: 'DeliveryAddress',
    Address2: 'DeliveryAddress2',
    Address3: 'DeliveryAddress3',
    PostalCode: 'DeliveryPostalCode',
    City: 'DeliveryCity',
    Country: 'DeliveryCountry',
    Email: 'DeliveryEmail',
    PhoneNumber: 'DeliveryPhoneNumber',
    VAT: ''
  };
  constructor(private httpService: OrderHttpService, private route: ActivatedRoute, private router: Router) {
    this.orderId = this.getUrlParam('orderId');
    this.subOrderId = this.getUrlParam('subOrderId');
    this.getEditDetails(this.orderId);
  }

  ngOnInit() {
    this.loadCountries();
  }

  getUrlParam(paramName) {
    const paramValueFromUrl: string = this.route.snapshot.paramMap.get(paramName);
    return paramValueFromUrl;
  }
  loadCountries() {
    this.httpService.getCountries().subscribe((response: any) => {
      this.countries = [...response.map(country => (
        { label: country.Name, value: country.Alpha2Code }
      ))];

    });
  }
  getEditDetails(orderId) {
    this.httpService.getEditDetails(orderId).subscribe((response) => {
      this.orderItems = response;
    });
  }

  backToOrderDetails(orderId, subOrderId) {
    this.router.navigate([`/admin-order-process/${ orderId }/${ subOrderId }`], { replaceUrl: false });
  }
}
