import { Action } from '@ngrx/store';
export const LOAD_CAMPAIGNS = '[Campaign] Load all Campaigns';
export const LOAD_CAMPAIGNS_SUCCESS = '[Campaign] Load Campaigns Success';
export const LOAD_CAMPAIGNS_FAIL = '[Campaign] Load Campaigns Fail';
export const SAVE_CAMPAIGN = '[Campaign] Save Campaign';
export const SAVE_CAMPAIGN_SUCCESS = '[Campaign] Save Campaigns Success';
export const SAVE_CAMPAIGNS_FAIL = '[Campaign] Save Campaign Fail';
export const SELECTED_CAMPAIGN = '[Campaign] Selected Campaign';
export const FILE_UPLOAD = '[Campaign] Load Fileupload';
export const FILE_UPLOAD_SUCCESS = '[Campaign] Load Fileupload Fail';
export const FILE_UPLOAD_FAIL = '[Campaign] Load Fileupload Fail';

export class LoadFileUploadSuccess implements Action {
  readonly type = FILE_UPLOAD_SUCCESS;
  constructor(public payload: any) { }
}


export class LoadFileUploadFail implements Action {
  readonly type = FILE_UPLOAD_FAIL;
  constructor(public payload: any) { }
}

export class LoadFileUpload implements Action {
  readonly type = FILE_UPLOAD;
  constructor(public payload: any) { }
}

export class LoadCampaign implements Action {
  readonly type = LOAD_CAMPAIGNS;
  constructor(public payload: any) { }
}

export class LoadCampaignSuccess implements Action {
  readonly type = LOAD_CAMPAIGNS_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadCampaignFail implements Action {
  readonly type = LOAD_CAMPAIGNS_FAIL;
  constructor(public payload: any) { }
}

export class SaveCampaign implements Action {
  readonly type = SAVE_CAMPAIGN;
  constructor(public payload: any) { }
}

export class SaveCampaignSuccess implements Action {
  readonly type = SAVE_CAMPAIGN_SUCCESS;
  constructor(public payload: any) { }
}

export class SaveCampaignFail implements Action {
  readonly type = SAVE_CAMPAIGNS_FAIL;
  constructor(public payload: any) { }
}

export class SelectedCampaign implements Action {
  readonly type = SELECTED_CAMPAIGN;
  constructor(public payload: any) { }
}

export type All =
  | LoadFileUpload
  | LoadFileUploadSuccess
  | LoadFileUploadFail
  | LoadCampaign
  | LoadCampaignSuccess
  | LoadCampaignFail
  | SaveCampaign
  | SaveCampaignSuccess
  | SaveCampaignFail;
