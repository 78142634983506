import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ColumnSetting } from '../../shared/link-table-border/models/link-table-model';
import { ReportsHttpService } from '../services/reports-http.service';
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ToastService } from 'ng-uikit-pro-standard';
@Component({
    selector: 'rewardkart-report-user-management',
    templateUrl: './report-user-management.component.html',
    styleUrls: ['./report-user-management.component.scss']
})

export class ReportUserManagementComponent implements OnInit {
    fromDate: any;
    toDate: any;
    date = new Date();
    campaignID: any;
    domainName: any = '';
    activePage: number = 1;
    userName: string = '';
    customerName: string = '';
    isParentCustomer: boolean = true;
    webshop: any;
    datePickerOptions: IMyOptions = {};
    enddatePickerOptions: IMyOptions = {};
    childwebshop: any;
    childShop: any;
    showLoader: boolean = false;
    campaignList: any;
    userReport: any;
    showTable: boolean = false;
    noData: boolean = false;
    public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public fileExtension = '.xlsx';
    public exportData: any = [];
    totalBalancePoints: any = '0';
    columns: ColumnSetting[] = [];
    customerid: any;
    constructor(private reportHttpService: ReportsHttpService, private datePipe: DatePipe, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
            let user = JSON.parse(sessionStorage.getItem('userdetails'));
            this.customerid = user.CustomerID;

        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.getCampaigns(user.UserID);
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }
    }

    ngOnInit() {
        let dt = new Date();
        const disableDate = new Date(dt.setDate(dt.getDate() + 1));
        const startDate = new Date(dt.setDate(dt.getDate() - 90));
        this.fromDate = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
        this.toDate = this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear();
        this.datePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
        };
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
            disableUntil: {
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                day: startDate.getDate()
            },
        };
        this.getChildShops();
    }

    getReport() {
        let a = this.fromDate.split('/');
        let b = this.toDate.split('/');
        let fromDate = a[2] + '-' + a[1] + '-' + a[0];
        let toDate = b[2] + '-' + b[1] + '-' + b[0];
        let params = {
            'fromDate': fromDate,
            'toDate': toDate,
            'campaignID': this.campaignID,
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'userName': this.userName,
            'customerName': this.customerName,
            'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getUserManagementReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.userReport = '';
            this.showTable = false;
            if (data && data.IsSuccess) {
                this.totalBalancePoints = data.UserReportDetails[0].TotalBalancePoints;
                this.columns = [
                    {
                        primaryKey: 'UserId',
                        header: 'User ID',
                        footer: true,
                        footerKey: 'Total'
                    },
                    {
                        primaryKey: 'Username',
                        header: 'User Name'
                    },
                    {
                        primaryKey: 'Status',
                        header: 'Status'
                    },
                    {
                        primaryKey: 'OnboardingDate',
                        header: 'Onboarding Date',
                        format: 'date'
                    },
                    {
                        primaryKey: 'LastLogindate',
                        header: 'Last Login Date',
                        format: 'date'
                    },
                    {
                        primaryKey: 'NoOfOrders',
                        header: 'No Of Orders'
                    },
                    {
                        primaryKey: 'BalancePoints',
                        header: 'Balance Points',
                        format: 'number',
                        footer: true,
                        footerKey: this.totalBalancePoints
                    }
                ];
                this.userReport = data.UserReportDetails;
                this.userReport.forEach(e => e.BalancePoints = e.BalancePoints.toString())
                this.showTable = true;
                this.noData = false;
            } else {
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })

    }

    getChildShops() {
        const searchdata = {
            activePage: this.activePage,
            pageSize: 1000,
        };
        this.showLoader = true;
        this.webshophttpservice.getWebshopList(searchdata).subscribe((res: any) => {
            this.showLoader = false;
            this.getReport();
            if (res) {
                let data = res.ItemsCollection;
                // let childcustomer = data.ChildCustomers
                this.childwebshop = [];
                this.childwebshop.push({ label: 'All Child Shop', value: 'All Child Shop' });
                data.forEach(element => {
                    const langVal = {
                        value: element.CustomerID,
                        label: element.Name,
                    };
                    this.childwebshop.push(langVal);
                });
                this.childShop = this.childwebshop[0].value;
            }
        }, _err => {
            this.showLoader = false;
            this.getReport();
        });
    }

    getCampaigns(id) {
        let param = 'pageSize=100&pageIndex=1&userId=' + id;
        this.showLoader = true;
        this.campaignHttpService.getCampaignList(param).subscribe((res: any) => {
            this.showLoader = false;
            this.campaignList = [];
            this.campaignID = '';
            if (res && res.length > 0) {
                let data = res;
                this.campaignList.push({ label: 'All Campaign', value: 'All Campaign' });
                data.forEach(element => {
                    const c = {
                        value: element.CampaignId,
                        label: element.CampaignName,
                    };
                    this.campaignList.push(c);
                });
                this.campaignID = this.campaignList[0].value;
            }
        },
            _err => {
                this.showLoader = false;
            });
    }

    getCampaignSelectedValue(_event) {

    }

    dateChange(event) {
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableUntil: {
                year: event.date.year,
                month: event.date.month,
                day: event.date.day,
            },
        };
    }

    getSelectedValue(event) {
        this.childwebshop.forEach((element) => {
            if (element.value === event) {
                this.domainName = element.label;
            }
        })
        if (event === 'All Child Shop') {
            this.campaignID = '';
            if (this.isParentCustomer) {
                this.domainName = '';
            }
        } else {
            this.getCampaigns(event);
        }
    }

    download() {
        this.exportData = [];

        this.userReport.forEach(e => {
            this.exportData.push({
                'User ID': e.UserId,
                'User Name': e.Username,
                'Status': e.Status,
                'Onboarding Date': this.datePipe.transform(e.OnboardingDate, 'dd/MM/yyyy'),
                'Last Login Date': this.datePipe.transform(e.LastLogindate, 'dd/MM/yyyy'),
                'No Of Orders': e.NoOfOrders,
                'Balance Points': e.BalancePoints,
            })
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, this.domainName + '-user-management');
    }

    saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }
}