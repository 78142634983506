import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, ViewChild, ViewContainerRef, ComponentFactoryResolver, ViewChildren, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from '../state/services/category.service';
import { SBItemComponent } from 'ng-uikit-pro-standard';



@Component({
  selector: 'rewardkart-admin-category-list-tree',
  templateUrl: './category-list-tree.component.html',
  styleUrls: ['./category-list-tree.component.scss']
})
export class CategoryListTreeComponent implements OnInit, OnChanges {

  @Input() categories: any[];

  @Output() public onSelectedCategory: EventEmitter<any> = new EventEmitter<any>();

  @Output() public onSelectedSubCategory: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('viewContainer', { static: false }) viewContainer: ViewContainerRef;

  @ViewChildren('subCategory') subCategoryComp: QueryList<CategoryListTreeComponent>;
  @ViewChildren('accordianItem') accordianItemComp: QueryList<SBItemComponent>;
  @Output() sendCategory = new EventEmitter();
  constructor(private cfr: ComponentFactoryResolver, private translate: TranslateService, private categoryService: CategoryService) { }
  ngOnInit() {

  }

  ngOnChanges(changes) {
    if (this.categories !== undefined) {
      this.categories.forEach(element => {
        element.SubCategories.forEach(subelement => {
          subelement.showAddcategory = true;
        });
      });
    }
  }

  selectCategory(category: any, index: number): void {

    this.subCategoryComp.toArray()[index].categories = category.SubCategories;

    let isCollapsed = this.accordianItemComp.toArray()[index].collapsed;

    category.SubCategories.map(subCategory => {
      subCategory.parentCategory = category;
    })


    if (category.ParentID) {
      this.categoryService.onSelecteSubCategory.next(!isCollapsed ? category : category.parentCategory);
    }

    if (!category.ParentID) {
      isCollapsed ? this.categoryService.onLoadCategory.next(this.categories) : this.categoryService.onSelecteCategory.next(category);
    }

    if (category.showAddcategory) {
      sessionStorage.setItem('showbutton', 'show');
    }
    else {
      sessionStorage.setItem('showbutton', 'hide');
    }
    this.sendCategory.emit(category);
  }

}
