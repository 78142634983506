import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ProductService } from '../../state/services/product.service';
import { ProductHttpService } from '../../state/services/product.http.service';
import { UserService } from 'src/app/user/state/services/user.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { ProductEditComponent } from '../product-edit.component';

@Component({
  selector: 'app-product-governmentalcosts',
  templateUrl: './product-governmentalcosts.component.html',
  styleUrls: ['./product-governmentalcosts.component.scss']
})
export class ProductGovernmentalcostsComponent implements OnInit, OnChanges {
  @ViewChild('clickmodal', { static: true }) clickmodal: ElementRef<HTMLElement>;
  productGovernmentalCostsForm: any;
  items: FormArray;
  optionsSelectGovernmentalCosts: any = [];
  @Input() productDetails: any;
  @Input() loadApi: any;
  @Output() sendGovernmentalCosts = new EventEmitter();
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  optionsSelectCurrencies: any[];
  optionsSelectTypes: any[];
  resetGovernmentalCostModal: Boolean = false
  itemIndex: any;
  showManageGovernmentalCostModal: Boolean = false
  loadApiFlag: Boolean = false
  loadManageApi: any;
  searchKey: any;
  selfGovernmantal: any;
  resultMessage: any;
  showSearch: any;
  optionsSelectCountries: any[];
  constructor(public translate: TranslateService,
    public formBuilder: FormBuilder,
    public productHttpService: ProductHttpService,
    public userService: UserService,
    public toast: ToastService,
    public productService: ProductService) {
    this.loadManageApi = {
      governmentalCost: false
    }
  }

  ngOnInit() {
    // this.getProductGovernmentalCosts();
    // this.getCurrencies();
    // this.getAdditionalCostTypes();
    this.productGovernmentalCostsForm = this.formBuilder.group({
      governmentalCost: [''],
      price: [''],
      Country: [''],
      currency: [''],
      costType: [''],
      optional: [false],
      items: this.formBuilder.array([])
    });

    if (this.productDetails.ProductGovernmentalCosts.length > 0) {
      this.loadFormData();
    }

    this.productGovernmentalCostsForm.valueChanges.subscribe(() => {
      this.emitData();
    });
    this.emitData();
    if (this.loadApi) {
      this.getProductGovernmentalCosts();
      this.clearFormItems();
      this.loadFormData();
    }
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.getProductGovernmentalCosts();
      this.getCurrencies();
      this.getAdditionalCostTypes();
      this.getCountries();
    }
  }


  ngOnChanges() {
    if (this.loadApi) {
      this.getProductGovernmentalCosts();
      this.clearFormItems();
      this.loadFormData();
    }
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.getProductGovernmentalCosts();
      this.getCurrencies();
      this.getAdditionalCostTypes();
      this.getCountries();
    }
  }

  clearFormItems() {
    const control = <FormArray>this.productGovernmentalCostsForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  searchList() {
    this.items.value.filter((data, index) => {
      let value = (data.name).toLowerCase();
      let searchData = (this.searchKey).toLowerCase();
      if (((value).includes(searchData))) {
        data.search = 'show';
      }
      else {
        data.search = 'hide';
      }
    });
    const noresult = this.items.value.filter(get => get.search === 'show');
    this.resultMessage = noresult.length > 0 ? false : true;
    // this.hideSearch()
  }

  getCountries() {
    this.userService.getAllCountries().subscribe(
      data => {
        if (data) {
          const optionsSelectCountries = [{ label: 'Select country', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
              Alpha3Code: element.Alpha3Code
            };
            optionsSelectCountries.push(langVal);
          });
          this.optionsSelectCountries = optionsSelectCountries;
        }
      });
  }

  resetFilter() {
    this.searchKey = '';
    this.items.value.filter((data, index) => {
      data.search = 'show';
    });
    this.resultMessage = this.items.length === 0 ? true : false;
  }


  resetGovernmentalCost(event) {
    this.resetGovernmentalCostModal = true
    setTimeout(() => {
      this.resetGovernmentalCostModal = false
    }, 1000)
  }

  emitData() {
    const finalVal = [];
    // this.getProductGovernmentalCosts()
    if (this.items) {
      this.items.value.forEach(data => {
        const name = this.optionsSelectGovernmentalCosts.find(item => item.ProductGovernmentalCostID == data.governmentalCost)
        const value = {
          isOptionalForBuyer: data.optional !== null && data.optional !== undefined ? data.optional : false,
          ProductGovernmentalCostID: data.governmentalCost,

          Price: data.price !== undefined && data.price !== null && (data.price.toString()).includes(',') ? data.price.replace(',', '.') : data.price,
          // Country:data.Country,
          ProductAdditionalCostTypeID: data.costType,
          CurrencyID: data.currency,
          Name: data.name,
          isGovernmentalCost: false,
        }
        if (data.action) {
          value['Action'] = data.action;
        }
        //       costTypeCode: "PerOrder"
        // costTypeName: "per order"
        if (data.costTypeCode) {
          value['CostTypeCode'] = data.costTypeCode;
        }
        if (data.costTypeName) {
          value['CostTypeName'] = data.costTypeName;
        }
        if (data.countryAlpha2Code) {
          value['CountryAlpha2Code'] = data.countryAlpha2Code;
        }
        if (data.countryAlpha3Code) {
          value['CountryAlpha3Code'] = data.countryAlpha3Code
        }
        if (data.countryName) {
          value['CountryName'] = data.countryName;
        }
        finalVal.push(value);
      });
    }

    this.sendGovernmentalCosts.emit(finalVal);
    if (this.items) {
      this.items.value.forEach(element => {
        delete element['Action']
      });
    }
  }

  loadFormData() {
    this.items = this.productGovernmentalCostsForm.get('items') as FormArray;
    const tempArray = this.items;
    if (this.productDetails.ProductGovernmentalCosts !== undefined) {
      if (this.productDetails.ProductGovernmentalCosts.length > 0) {
        this.productDetails.ProductGovernmentalCosts.forEach(data => {

          let newValue = {
            governmentalCost: data.ProductGovernmentalCostID,
            price: data.Price,
            name: data.Name,
            Country: data.CountryAlpha2Code,
            costTypeCode: data.CostTypeCode,
            costTypeName: data.CostTypeName,
            currency: data.CurrencyID,
            costType: data.ProductAdditionalCostTypeID,
            optional: data.isOptionalForBuyer,
            countryName: data.CountryName,
            countryAlpha2Code: data.CountryAlpha2Code,
            countryAlpha3Code: data.CountryAlpha3Code,
            action: "Update",
            search: 'show'
          }

          tempArray.push(this.createItem(newValue));
        })
        this.items = tempArray;
        this.selfGovernmantal = tempArray;
        // this.hideSearch()
      }
    }
  }

  getCurrencies() {
    this.userService.getAllCurrencies().subscribe(
      data => {
        if (data) {
          this.optionsSelectCurrencies = [{ label: 'Select currency', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            this.optionsSelectCurrencies.push(langVal);
          });
        }
      });
  }


  getAdditionalCostTypes() {
    this.productService.getProductAdditionalCostTypes().subscribe(
      data => {
        if (data) {
          this.optionsSelectTypes = [{ label: 'Select cost type', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.ProductAdditionalCostTypeID,
              label: element.Name,
            };
            this.optionsSelectTypes.push(langVal);
          });
        }
      });
  }

  createItem(newValue): FormGroup {
    let group = {
      governmentalCost: newValue.governmentalCost,
      price: newValue.price,
      currency: newValue.currency,
      costType: newValue.costType,
      optional: newValue.optional,
      Country: newValue.Country,
      name: newValue.name,
      costTypeCode: newValue.costTypeCode,
      costTypeName: newValue.costTypeName,
      countryName: newValue.countryName,
      countryAlpha2Code: newValue.countryAlpha2Code,
      countryAlpha3Code: newValue.countryAlpha3Code,
      search: newValue.search
    }
    if (newValue.action) {
      group['action'] = newValue.action;
    }
    return this.formBuilder.group({ ...group });
  }

  // checkListValue() {

  //   let arr = this.productGovernmentalCostsForm.get('items')['controls'].length
  //   const val = this.productGovernmentalCostsForm.controls.items.value.find(item => item.action !== 'Delete')
  //   if (val && arr) {
  //     return true
  //   }
  //   return false
  // }



  checkAddGovernmentalCosts() {
    const governmentalCost = this.productGovernmentalCostsForm.get('governmentalCost').value;
    const price = this.productGovernmentalCostsForm.get('price').value;
    const Country = this.productGovernmentalCostsForm.get('Country').value
    const currency = this.productGovernmentalCostsForm.get('currency').value
    const costType = this.productGovernmentalCostsForm.get('costType').value
    if (!(governmentalCost && price && currency && costType && Country)) {
      return true
    }
    return false
  }

  addGovernmentalCosts() {
    const governmentalCost = this.productGovernmentalCostsForm.get('governmentalCost').value;
    const price = this.productGovernmentalCostsForm.get('price').value;
    const Country = this.productGovernmentalCostsForm.get('Country').value
    const currency = this.productGovernmentalCostsForm.get('currency').value
    const costType = this.productGovernmentalCostsForm.get('costType').value

    this.items = this.productGovernmentalCostsForm.get('items') as FormArray;
    const getName = this.optionsSelectGovernmentalCosts.filter(data => data.ProductGovernmentalCostID === governmentalCost)
    let newValue: any = {
      governmentalCost,
      price,
      Country,
      currency,
      costType,
      optional: this.productGovernmentalCostsForm.get('optional').value,
      action: 'Insert',
      search: 'show'
    }

    newValue.name = getName.length === 0 ? '' : getName[0].Name;
    this.items.push(this.createItem(newValue));
    this.selfGovernmantal = this.items;
    this.productGovernmentalCostsForm.patchValue({
      governmentalCost: '',
      price: '',
      Country: '',
      currency: '',
      costType: '',
      optional: false,
    })
    this.emitData();
    // this.hideSearch();
  }

  getProductGovernmentalCosts() {
    this.productHttpService.getProductGovernmentalCosts().subscribe((data: any) => {
      if (data) {
        const govCosObj = [{ label: 'Select governmental cost', value: '' }];
        data.forEach((element) => {
          const options = {
            value: element.ProductGovernmentalCostID,
            label: element.Name,
            ProductGovernmentalCostID: element.ProductGovernmentalCostID,
            SupplierID: element.SupplierID,
            Country: element.Country,
            DefaultPrice: element.DefaultPrice,
            DefaultCurrencyID: element.DefaultCurrencyID,
            DefaultProductAdditionalCostTypeID: element.DefaultProductAdditionalCostTypeID,
            DefaultIsOptionalForBuyer: element.DefaultIsOptionalForBuyer,
            Name: element.Name,
          }
          govCosObj.push(options)
        })
        this.optionsSelectGovernmentalCosts = govCosObj;
      }
    });
  }

  getGovernmentalCosts(selectedValue) {
    this.productGovernmentalCostsForm.get('price').setValue(selectedValue.DefaultPrice)
    this.productGovernmentalCostsForm.get('Country').setValue(selectedValue.Country)
    this.productGovernmentalCostsForm.get('currency').setValue(selectedValue.DefaultCurrencyID)
    this.productGovernmentalCostsForm.get('costType').setValue(selectedValue.DefaultProductAdditionalCostTypeID)
    this.productGovernmentalCostsForm.get('optional').setValue(selectedValue.DefaultIsOptionalForBuyer)
  }


  deleteItem(itemIndex) {
    this.itemIndex = itemIndex;
    this.deleteModal.show();
  }

  getDeleteStatus(status) {
    if (status) {
      if (this.items.value[this.itemIndex].action == "Insert") {
        this.items.removeAt(this.itemIndex);
      } else {
        this.items.value[this.itemIndex].action = "Delete";
      }
      this.emitData();
      this.deleteModal.hide();
      // this.hideSearch();
      this.toast.success('Product Governmental Cost deleted successfully')
    } else {
      this.deleteModal.hide();
    }
  }

  triggerFalseClick() {
    let el: HTMLElement = this.clickmodal.nativeElement;
    el.click();
  }
  // loadData(){
  //  this.loadfulldata.ngOnInit()
  //  this.loadfulldata.loadTranslate1()
  // }
  resetDropDown() {
    this.getProductGovernmentalCosts()
  }
  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Number only accepted here')
      return false
    }
    else {
      return true
    }
  }

  // hideSearch() {
  //   const getResult = this.items.value.filter(data => data.Action !== 'Delete' || data.search !== 'hide');
  //   this.showSearch = getResult.length > 0 ? true : false;
  // }

  getCountrySelect(selectedCountry, i) {
    this.items.value[i]['countryAlpha2Code'] = selectedCountry.value;
    this.items.value[i]['countryAlpha3Code'] = selectedCountry.Alpha3Code;
    this.items.value[i]['countryName'] = selectedCountry.label;
    this.emitData()
    // this.productGovernmentalCostsForm.patchValue({
    //   Country: selectedCountry.value
    // })
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
    // console.log(event.target.value);
  }

}
