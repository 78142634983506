import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ApiService } from '../../shared/common-services/api.service';
import { ProductService } from '../state/services/product.service';
import { Router } from '@angular/router';
import { TabsetComponent, ToastService, MDBDatePickerComponent, IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../state/services/product.http.service';
import { ProductEditService } from '../product-edit/product-edit.service';
import * as moment from 'moment';
import { LoginService } from 'src/app/login/state/services/login.service';
import { UserHttpService } from 'src/app/user/state/services/user.http.service';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
  @Input() editProduct: any;
  @Input() isEditScreen: boolean;
  @Input() clonestatus: any;
  @Input() siblingstatus: any;
  @Input() customerProduct: any;
  @Output() sendBasicInfodata = new EventEmitter<any>();
  @ViewChild('datepicker', { static: false }) datePicker: any;
  @ViewChild('datepicker1', { static: false }) datePicker1: any;
  @ViewChild('datepicker2', { static: false }) datePicker2: any;
  @ViewChild('datepicker3', { static: false }) datePicker3: any;
  @ViewChild('datepicker4', { static: false }) datePicker4: any;
  @ViewChild('lockdelivery', { static: false }) lockdelivery: ModalDirective;
  @ViewChild('removeDelivery', { static: false }) removeDelivery: ModalDirective;
  @ViewChild('customersDetail', { static: false }) customersDetail: ModalDirective;
  addProductForm: FormGroup;
  dynamicAddForm: FormGroup;
  customerlist: any = [];
  showManageUnit: boolean;
  categoryList: any;
  optionsSelectSubcategory: any = [];
  currencyList: any;
  brandList: any;
  productTypeList: any;
  translateForm: any = [];
  optionsSelectSubCategories: any = [];
  showSampleWithLogoDeliveryDays: boolean = false;
  showSampleWithOutLogoDeliveryDays: boolean = false;
  showallowsketchPrice: boolean = false;
  productUnitList: any[];
  categoryData: any;
  getSelectedCategoryObj: any;
  supplierList: any;
  showproductname: any = 'English';
  showmetaDescription: any = 'English';
  getCustomerId: any;
  getCategoryId: any = 0;
  optionsSelectCountries: any;
  selectSubCategory: any;
  productNameList: {}[];
  metaDescriptionList: {}[];
  h1NameList: {}[];
  productMaterialList: {}[];
  productDescriptionList: {}[];
  imageAlternativeTextList: {}[];
  productAdditionalDataList: {}[];
  productCardLabelList: {}[];
  activeLanguage: any = {};
  selectedLang: any = 'English'
  showh1Name: any = 'English';
  showproductMaterial: any = 'English';
  showproductDescription: any = 'English';
  showimageAlternativeText: any = 'English';
  showproductAdditionalData: any = 'English';
  showproductCardLabel: any = 'English';
  showManualValidation: boolean = false;
  languageOption: any = [];
  transportationOriginCountries: any = [];
  transportationOriginCountryName: any = '';
  translateDataArray: any = [];
  showLoader: boolean = false;
  isClone: boolean;
  minmumvaluecheck: boolean = false;
  optionsUnderSupplier: any[];
  date = new Date();
  ratingArray: any = [1, 2, 3, 4, 5];
  productionDate: any;
  deliveryDate: any;
  showManualerror: Boolean = false;
  showAlertPiecesPerUnit: boolean = false;
  showAlertMRP: boolean = false;
  showAlertShippingPrice: boolean = false;
  showAlertRewardPoints: boolean = false;
  @HostListener('keydown.esc')
  handleInput() {
    if (this.datePicker.isOpen) {
      this.datePicker.closeBtnClicked();
    }
    if (this.datePicker1.isOpen) {
      this.datePicker1.closeBtnClicked();
    }
    if (this.datePicker2.isOpen) {
      this.datePicker2.closeBtnClicked();
    }
    if (this.datePicker3.isOpen) {
      this.datePicker3.closeBtnClicked();
    }
    if (this.datePicker4.isOpen) {
      this.datePicker4.closeBtnClicked();
    }
  }
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  };
  showRemoveLockDate: boolean = false;
  manualIdList: any[];
  ispProductionProductManualIdList: any[];
  manualName: any = '';
  ispProductionProductmanualName: any = '';
  searchText: any = '';
  showSearchManualID: boolean = false;
  showSearchIspProductionProductManualID: boolean = false;
  groupCollection: any = [];
  groupName: any = '';
  warehouseList: any = [];
  hideChangeButton: boolean = true;
  hideChangeButtonForISP: boolean = true;
  ispSectionShowStatus: boolean = false;
  selectedSubcatId: any;
  valuesSet: any
  getCountryName: any;
  metaDescription: any;
  resetManageunitModal: Boolean = false;
  lockedDelivery: Boolean = false;
  showAlertPerCarton: Boolean = false;
  showAlertOnPallet: Boolean = false;
  webshopDetail: any;
  productCustID: any;
  @Input() productData: any;
  showUnderSupplier: Boolean = true;
  multiCustomerData: any = [];
  selectedDynamicField: any;
  selectedStaticField: any;
  isParentProduct: Boolean = false;
  noRecord: Boolean = false;
  productionDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() }
  };
  deliveryDatePickerOptions: IMyOptions;
  currencyLists:any;
  webshopOnlineProductCustomerList:any;
  webshopcurrencyList: any;
  countryLists:any;
  isParentshop: boolean = false;
  @ViewChild('bulkCreationModal', { static: true }) bulkCreationModal: ModalDirective;
  instructions: string = "";
  terms: string = "";
  storeLocator: string = "";
  howtoUse: string = "";
  filterLists: Array<any> = [];
  dateArr: Array<any> = [
    { label: 'Select', value: ''},
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' }
  ]
  reqDate: string = "Y";
  constructor(public translate: TranslateService,
    private formbuilder: FormBuilder,
    private apiService: ApiService,
    private productHttpService: ProductHttpService,
    private productService: ProductService,
    private loginService: LoginService,
    private productEditService: ProductEditService,
    private router: Router,
    private toast: ToastService , private userHttpService: UserHttpService) {
    this.isClone = this.productEditService.isProductCloneSelected;
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    if (this.webshopDetail && (this.webshopDetail.ParentCustomerDomainName === this.webshopDetail.DomainName)) {
      this.isParentshop = true;
    }

    const getCustID = sessionStorage.getItem('productCustomerID');
    if (getCustID !== undefined && getCustID !== null && getCustID !== '') {
      this.productCustID = getCustID;
    }
    else {
      this.productCustID = this.webshopDetail.CustomerID;
    }

    this.isParentProduct = !history.state.isClone && (this.webshopDetail.ParentCustomerID === null || this.webshopDetail.ParentCustomerID === undefined || this.webshopDetail.ParentCustomerID === '') ? true : false;

  }

  ngOnInit() {
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (this.productData !== undefined && getWebshopDetails !== null && getWebshopDetails !== undefined && getWebshopDetails !== '') {
      if ((getWebshopDetails.ParentCustomerID && getWebshopDetails.SupplierID !== this.productData.SupplierID) && !getWebshopDetails.IsOpenBook) {
        this.showUnderSupplier = false;
      }
      else {
        this.showUnderSupplier = true;
      }
    }
    this.getAllLanguages();
    // this.getSupplierList();
    this.getTransportationOriginCountries();
    this.getCurrenyList();
    this.getBrandList();
    // this.getCategoryList();
    this.sendEditValue();
    this.wareHouse();
    this.getChildChildCustomer();
    if(this.isParentshop){
      this.getFilterList();
    }

    /** For Display Production product linked with ISP products */
    if (this.editProduct !== undefined) {
      //console.log(this.editProduct)
      if (!this.editProduct.isInternalStockProduct) {
        this.deliveryDatePickerOptions = {
          dateFormat: 'dd/mm/yyyy',
          closeAfterSelect: true,
          markCurrentDay: true,
          disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() + (this.editProduct.DeliveryWeeksTo * 7) + 1 }
        };
      }
      if (this.editProduct.isInternalStockProduct) {
        this.ispSectionShowStatus = true;
      }
    }

    if (this.isEditScreen) {
      if(this.isParentshop){
        if((this.customerProduct && this.customerProduct.HowtoUse) || (this.editProduct && this.editProduct.HowtoUse)){
          this.howtoUse = this.editProduct.HowtoUse ? this.editProduct.HowtoUse : this.customerProduct.HowtoUse ? this.customerProduct.HowtoUse : "";
        }
        if((this.customerProduct && this.customerProduct.Instructions) || (this.editProduct && this.editProduct.Instructions)){
          this.instructions = this.editProduct.Instructions ? this.editProduct.Instructions : this.customerProduct.Instructions ? this.customerProduct.Instructions : "";
        }
        if((this.customerProduct && this.customerProduct.StoreLocator) || (this.editProduct && this.editProduct.StoreLocator)){
          this.storeLocator = this.editProduct.StoreLocator ? this.editProduct.StoreLocator : this.customerProduct.StoreLocator ? this.customerProduct.StoreLocator : "";
        }
        if((this.customerProduct && this.customerProduct.TandC) || (this.editProduct && this.editProduct.TandC)){
          this.terms = this.editProduct.TandC  ? this.editProduct.TandC : this.customerProduct.TandC ? this.customerProduct.TandC : "";
        }
        if((this.customerProduct && this.customerProduct.RequestDate) || (this.editProduct && this.editProduct.RequestDate)){
          this.reqDate = this.editProduct.RequestDate  ? this.editProduct.RequestDate : this.customerProduct.RequestDate ? this.customerProduct.RequestDate : "";
        }
      }
      this.getCustomerData();
      this.getEditData();
      this.getSupplierList(this.productCustID);
      if (this.editProduct.SupplierID !== '' && this.editProduct.SupplierID !== undefined) {
        this.getUnderSuppliers(this.productCustID, this.editProduct.SupplierID);
      }
      this.showRemoveLockDate = this.editProduct.isLockedDeliveryDate;
      if (this.editProduct.ProductionDate !== undefined) {
        this.productionDate = new Date(this.editProduct.ProductionDate);
      }
      if (this.editProduct.DeliveryDate !== undefined) {
        this.deliveryDate = new Date(this.editProduct.DeliveryDate);
      }

      this.addProductForm.patchValue({
        "StockParentProductID": this.editProduct.StockParentProductID,
        "StockParentProductIdManual": this.editProduct.StockParentProductIdManual,
      })
      if (this.editProduct.ProductGroupName !== undefined) {
        this.autoCompleteGroup(this.editProduct.ProductGroupName)
        this.searchText = this.editProduct.ProductGroupName;
      }
      this.manualName = this.editProduct.StockParentProductIdManual;
      this.ispProductionProductmanualName = this.editProduct.ProductionProductIdManual;
      // this.searchText = this.editProduct.ProductGroupID;
      // if (this.clonestatus !== undefined && this.clonestatus) {
      //   this.addProductForm.patchValue({
      //     underSupplierProductID: ''
      //   });
      // }
      // if (this.siblingstatus !== undefined && this.siblingstatus) {
      //   this.addProductForm.patchValue({
      //     underSupplierProductID: ''
      //   });
      // }


    }
    // const loginData = this.apiService.getLoginData();
    this.getCustomerId = this.webshopDetail.CustomerID;
    this.addProductForm.valueChanges.subscribe(() => {
    })
    // this.getUnderSuppliers();
    this.productTypeList = [{
      label: 'All',
      value: 1
    }, {
      label: 'Digital',
      value: 2
    }, {
      label: 'Physical',
      value: 3
    }]
    this.getCurrencyList();
    this.getcountry();
  }

  getCurrencyList(){
    this.userHttpService.getCurrency().subscribe((data:any) => {
      this.currencyLists = data;
      this.webshopOnlineProductCustomerList = data;
      const webshopData = [{ label: 'Select Currency', value: '' }];
      this.currencyLists.forEach(option => {
        webshopData.push({ label: option.CurrencyName, value: option.CurrencyID })
      });
      let concatArray = webshopData.concat(this.currencyLists);
      this.webshopOnlineProductCustomerList = concatArray;
    })
  }

  getcountry(){
    this.userHttpService.countryy().subscribe((data:any) => {
      this.countryLists = data;
      this.webshopcurrencyList = data;
      const webshopData = [{ label: 'Select Country', value: '' }];
      this.countryLists.forEach(option => {
        webshopData.push({ label: option.CountryName, value: option.CountryID })
      });
      let concatArray = webshopData.concat(this.countryLists);
      this.webshopcurrencyList = concatArray;
    })
  }
  wareHouse() {
    let productCustomerID;
    const loginData = this.apiService.getLoginData();
    // if (this.isEditScreen) {
    //   productCustomerID = this.editProduct.CreatedByCustomerID;
    // }
    // if (productCustomerID === undefined || productCustomerID === null || productCustomerID === '') {
    //   productCustomerID = this.editProduct.ProductCustomers.length > 0 ? this.editProduct.ProductCustomers[0]['CustomerID'] : loginData.CustomerID;
    // }

    this.productHttpService.wareHouse(this.productCustID).subscribe((data: any) => {
      if (data) {
        const selectwarehouse = [{ label: 'Select warehouse', value: '' }];
        let wareHouseArray = data.ItemsCollection;
        wareHouseArray.forEach((element, index) => {
          element.label = element.Name;
          element.value = element.WarehouseID;
        });

        let concatArray = selectwarehouse.concat(wareHouseArray);
        this.warehouseList = concatArray;
      }
    })
  }
  ngOnChanges() {

    if (this.isEditScreen) {
      if (this.isParentshop) {
        if(this.customerProduct && this.customerProduct.HowtoUse){
          this.howtoUse = this.customerProduct.HowtoUse;
        }
        if(this.customerProduct && this.customerProduct.Instructions){
          this.instructions = this.customerProduct.Instructions;
        }
        if(this.customerProduct && this.customerProduct.StoreLocator){
          this.storeLocator = this.customerProduct.StoreLocator;
        }
        if(this.customerProduct && this.customerProduct.TandC){
          this.terms = this.customerProduct.TandC;
        }
        if(this.customerProduct && this.customerProduct.RequestDate){
          this.reqDate = this.customerProduct.RequestDate;
        }
      }
      this.loadForm();
      this.getEditData();
      this.sendEditValue();
      if (!this.editProduct.isWebshopProduct) {
        this.addProductForm.patchValue({
          customerWithOutLogo: this.customerProduct.IsSampleWithoutLogoDisabled,
          customerWithLogo: this.customerProduct.IsSampleWithLogoDisabled,
          disableLogo: this.customerProduct.IsLogoDisabled,
          hscode: this.customerProduct.HSCode
        });
      }
    }

  }
  ngAfterViewInit() {
    if (history.state.isClone) {
      this.showManualValidation = true;
      this.showManualerror = true;
    }
  }

  getFilterList(){
    this.productHttpService.getFilterList().subscribe({
      next: (res:any) => {
        if(res){
          let supplierList = [{ label: 'Select', value: '' }];
          res.forEach(supplier => {
          let supplierListData = {
            label: supplier.Name,
            value: supplier.Name
          }
          supplierList.push(supplierListData)
        })
        this.filterLists = supplierList;
        }
      }
    })
  }

  moreDetails(){
    // if(sessionStorage.getItem("princeEdit") && sessionStorage.getItem("princeEdit") === "true"){
    //   this.princeDisable = true;
    //   return;
    // }else{
      this.bulkCreationModal.show()
    // }
  }

  close(){
    this.bulkCreationModal.hide()
  }
  saveMoreDetails(){

    if (this.isEditScreen) {
      if (this.isParentshop){
        this.addProductForm.value.Instructions = this.instructions ? this.instructions : "";
        this.addProductForm.value.StoreLocator = this.storeLocator ? this.storeLocator : "";
        this.addProductForm.value.TandC = this.terms ? this.terms : "";
        this.addProductForm.value.HowtoUse = this.howtoUse ? this.howtoUse : "";
        this.addProductForm.value.RequestDate = this.reqDate ? this.reqDate : "";
      }
      const data = {
        instruction: this.instructions,
        storeLoc: this.storeLocator,
        tandc: this.terms,
        howtouse: this.howtoUse,
        requestDate: this.reqDate
      }
      sessionStorage.setItem("princepipeEdit", JSON.stringify(data))
      //   this.addProductForm.valueChanges.subscribe((currentfield) => {
      //   this.translateFieldValue();
      //   this.sendBasicInfodata.emit({ formData: this.addProductForm.value, translationData: this.translateDataArray });
      // })
    }
    setTimeout(() => {
      this.close();
    }, 1000);
    
  }
  resetManageunit(event) {
    this.resetManageunitModal = true;
    setTimeout(() => {
      this.resetManageunitModal = false;
    }, 1000)
  }
  enableManualId() {
    if (this.editProduct && this.isClone) {
      return false;
    } else if (this.editProduct) {
      return true;
    }
  }

  minimumordercheck() {
    let minOrder = this.addProductForm.controls.minimumOrder.value;
    let minimumProductionOrder = this.addProductForm.controls.minimumProductionOrder.value
    if (minOrder !== "" && minOrder !== null && minOrder !== undefined && minimumProductionOrder !== "" && minimumProductionOrder !== null && minimumProductionOrder !== undefined) {
      if (Number(minimumProductionOrder) < Number(minOrder)) {
        return true;
      }

    }
    return false;
  }
  deliveryweeks() {
    let deliveryOrderFrom = this.addProductForm.controls.deliveryWeeksFrom.value;
    let deliveryOrderTo = this.addProductForm.controls.deliveryWeeksTo.value
    if (deliveryOrderFrom !== "" && deliveryOrderFrom !== null && deliveryOrderFrom !== undefined && deliveryOrderTo !== "" && deliveryOrderTo !== null && deliveryOrderTo !== undefined) {
      if (Number(deliveryOrderTo) < Number(deliveryOrderFrom)) {
        return true
      }
    }
    return false;
  }
  piecesPerUnit() {
    let minOrder = this.addProductForm.controls.minimumOrder.value;
    let minimumProductionOrder = this.addProductForm.controls.minimumProductionOrder.value
    let piecesPerUnit = this.addProductForm.controls.piecesPerUnit.value
    if (minOrder !== "" && minOrder !== null && minOrder !== undefined && piecesPerUnit !== "" && piecesPerUnit !== null && piecesPerUnit !== undefined && minimumProductionOrder !== "" && minimumProductionOrder !== null && minimumProductionOrder !== undefined) {
      if (Number(minOrder) < Number(piecesPerUnit)) {
        return true
      }
      if (Number(minimumProductionOrder) < Number(piecesPerUnit)) {
        return true
      }
    }
    return false;
  }
  sendEditValue() {
    if (this.isParentshop) {
      this.addProductForm.value.Instructions = this.instructions ? this.instructions : "";
      this.addProductForm.value.StoreLocator = this.storeLocator ? this.storeLocator : "";
      this.addProductForm.value.TandC = this.terms ? this.terms : "";
      this.addProductForm.value.HowtoUse = this.howtoUse ? this.howtoUse : "";
      this.addProductForm.value.RequestDate = this.reqDate ? this.reqDate : "";
    }
    this.addProductForm.valueChanges.subscribe((currentfield) => {
      this.translateFieldValue();
      this.sendBasicInfodata.emit({ formData: this.addProductForm.value, translationData: this.translateDataArray });
    })

  }

  getEditData() {
    this.showLoader = true;
    if (this.customerProduct && this.customerProduct.OriginalTranslations) {
      if (this.customerProduct.OriginalTranslations.length > 0) {
        this.translateForm.forEach(formData => {
          this.customerProduct.OriginalTranslations.forEach(editData => {
            if (formData.PropertyName === editData.PropertyName && formData.LanguageID === editData.LanguageID) {
              this.addProductForm.get(formData.formcontrolname).patchValue(editData.PropertyValue);
            }
          })
        })
      }
    }

    if (this.editProduct.Translations !== undefined) {
      this.translateForm.forEach(formData => {
        this.editProduct.Translations.forEach(editData => {
          if (formData.PropertyName === editData.PropertyName && formData.LanguageID === editData.LanguageID) {
            if (editData.PropertyValue !== undefined && editData.PropertyValue !== '' && editData.PropertyValue !== null) {
              this.addProductForm.get(formData.formcontrolname).patchValue(editData.PropertyValue);
            }
          }
        })
        this.showLoader = false

      })
    }


    this.addProductForm.patchValue({
      indexedBySearchEngine: this.editProduct.isIndexedBySearchEngines,
      isDeleted: this.editProduct.isDeleted,
      internalStockProduct: this.editProduct.isInternalStockProduct,
      enableExpressOrder: this.editProduct.isExpressOrderEnabled,
      manualId: this.editProduct.ProductIdManual,
      selectSupplier: this.editProduct.OpenbookBasedSupplierID,
      minimumOrder: this.editProduct.MinimumOrder,
      minimumProductionOrder: this.editProduct.MinimumProductionOrder,
      deliveryWeeksFrom: this.editProduct.DeliveryWeeksFrom,
      deliveryWeeksTo: this.editProduct.DeliveryWeeksTo,
      unitWeight: this.editProduct.UnitWeight,
      piecesPerUnit: this.editProduct.PiecesPerUnit,
      MRP: this.editProduct.MRP,
      ShippingPrice: this.editProduct.ShippingPrice,
      RewardPoints: this.editProduct.RewardPoints,
      fixedUnits: this.editProduct.FixedUnits,
      unitType: this.editProduct.ProductUnitID,
      currency: this.editProduct.CurrencyID,
      brand: this.editProduct.Brand,
      productType: this.editProduct.ProductsCategoryID,
      printPriceCurrency: this.editProduct.PrintPricesCurrencyID,
      transportationOriginCountries: this.editProduct.TransportationOriginCountryCode,
      underSupplierProductID: this.editProduct.UndersupplierProductID,
      underSupplier: this.editProduct.UndersupplierID,
      hscode: this.editProduct.HSCode,
      visibleonlinknetwork: this.editProduct.VisibleOnLinkNetwork,
      transportationcostfree: this.editProduct.isTransportationFree,
      transportationcostmanually: this.editProduct.DisableTransportationCostCalculation,
      handlingcostfree: this.editProduct.isTransportationHandlingCostFree,
      volumeitem: this.editProduct.isVolumeItem,
      linkToVideo: this.editProduct.LinkToVideo,
      unitHeight: this.editProduct.UnitHeight,
      unitWidth: this.editProduct.UnitWidth,
      unitDepth: this.editProduct.UnitDepth,
      unitsPerCarton: this.editProduct.UnitsPerCarton,
      cartonHeight: this.editProduct.CartonHeight,
      cartonWidth: this.editProduct.CartonWidth,
      cartonDepth: this.editProduct.CartonDepth,
      cartonWeight: this.editProduct.CartonWeight,
      unitsOnPallet: this.editProduct.UnitsOnPallet,
      discontinuedDate: this.editProduct.DiscontinuedDate !== undefined ? this.getDateFormat(this.editProduct.DiscontinuedDate) : '',
      updateReminderDate: this.editProduct.UpdateReminderDate !== undefined ? this.getDateFormat(this.editProduct.UpdateReminderDate) : '',
      productNewEndDate: this.editProduct.ProductNewEndDate !== undefined ? this.getDateFormat(this.editProduct.ProductNewEndDate) : '',
      productFeaturedEndDate: this.editProduct.ProductFeaturedEndDate !== undefined ? this.getDateFormat(this.editProduct.ProductFeaturedEndDate) : '',
      productDiscountPercentage: this.editProduct !== undefined ? this.editProduct.ProductDiscountPercentage : '',
      productDiscountEndDate: this.editProduct.ProductDiscountEndDate !== undefined ? this.getDateFormat(this.editProduct.ProductDiscountEndDate) : '',
      warehouse: this.editProduct.WarehouseID,
      setSearchManualId: this.editProduct.StockParentProductID,
      setSearchManualName: this.editProduct.StockParentProductIdManual,
      setSearchIspProductProductManualId: this.editProduct.ProductionProductID,
      setSearchIspProductProductManualName: this.editProduct.ProductionProductIdManual,
      groupId: this.editProduct.ProductGroupID,
      groupName: this.editProduct.ProductGroupName,
      disablePriceDropNotification: this.editProduct.DisablePriceDropNotification,
      currencyID: this.editProduct.NewCurrencyID,
      countryID: this.editProduct.NewCountryID
    });



    //  this.sendEditValue();
  }

  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }

  getAllLanguages() {
    // let customerDetail = JSON.parse(sessionStorage.getItem(this.webshopDetail));
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    //console.log('getlangtest....', getlang)
    let translationFieldJson = [
      {
        formcontrolname: 'productName',
        label: 'PRODUCTADD.PRODUCTNAME',
        PropertyName: "ProductName",
        showLabel: "Product Name",
        isRequired: true
      },

      {
        formcontrolname: 'h1Name',
        label: 'PRODUCTADD.H1NAME',
        PropertyName: "ProductH1Name",
        isRequired: true
      },
      {
        formcontrolname: 'productDescription',
        label: 'PRODUCTADD.PRODUCTDESCRIPTION',
        PropertyName: "ProductDescription",
        isRequired: true
      },
      {
        formcontrolname: 'origincountry',
        label: 'PRODUCTADD.ORIGINCOUNTRY',
        PropertyName: "OriginCountry",
        isRequired: false
      },
      {
        formcontrolname: 'metaDescription',
        label: 'PRODUCTADD.METADESCRIPTION',
        PropertyName: "ProductMetaDescription",
        isRequired: false
      },
      {
        formcontrolname: 'productMaterial',
        label: 'PRODUCTADD.PRODUCTMATERIAL',
        PropertyName: "Material",
        isRequired: false
      },
      {
        formcontrolname: 'productCardLabel',
        label: 'PRODUCTADD.PRODUCTCARDLABEL',
        PropertyName: "ProductCardLabel",
        isRequired: false
      },
      {
        formcontrolname: 'imageAlternativeText',
        label: 'PRODUCTADD.IMAGEALTERNATIVETEXT',
        PropertyName: "ImageAlternativeText",
        isRequired: false
      },
      {
        formcontrolname: 'productAdditionalData',
        label: 'PRODUCTADD.PRODUCTADDITIONALDATA',
        PropertyName: "AdditionalData",
        isRequired: false
      },


    ];
    let productNameArray = []
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.Code };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let requiredField = false;
        if ((lang.Code === 'en' && jsonField.formcontrolname === "productName") || (lang.Code === 'en' && jsonField.formcontrolname === "h1Name") || (lang.Code === 'en' && jsonField.formcontrolname === "productDescription")) {
          requiredField = true;
        }
        // if((lang.Code === 'en' && jsonField.formcontrolname !== "productDescription")){
        //   requiredField = false;
        // }



        let formJson = { formcontrolname: jsonField.formcontrolname + lang.Code, label: jsonField.label, class: lang.Name, name: jsonField.formcontrolname, required: requiredField, LanguageID: lang.LanguageID, LanguageCode: lang.Code, PropertyName: jsonField.PropertyName, isRequired: jsonField.isRequired }
        if (jsonField.formcontrolname === "productName") {
          productNameArray.push(formJson)
        }
        this.translateForm.push(formJson)
      });

    })

    this.loadForm()
    // this.getCurrentLang({ label: 'English', value: 'en' });
  }

  loadForm() {
    let group: any = {
      indexedBySearchEngine: new FormControl(false),
      isDeleted: new FormControl(false),
      transportationOriginCountries: new FormControl(''),
      internalStockProduct: new FormControl(''),
      enableExpressOrder: new FormControl(false),
      manualId: new FormControl('', Validators.required),
      selectSupplier: new FormControl('', Validators.required),
      minimumOrder: new FormControl('', Validators.required),
      minimumProductionOrder: new FormControl('', Validators.required),
      deliveryWeeksFrom: new FormControl('', Validators.required),
      deliveryWeeksTo: new FormControl('', Validators.required),
      unitWeight: new FormControl('', Validators.required),
      piecesPerUnit: new FormControl('', Validators.required),
      MRP: new FormControl('', Validators.required),
      ShippingPrice: new FormControl('', Validators.required),
      RewardPoints: new FormControl('', Validators.required),
      fixedUnits: new FormControl(''),
      unitType: new FormControl(''),
      currency: new FormControl('', Validators.required),
      brand: new FormControl(''),
      productType: new FormControl(1, Validators.required),
      printPriceCurrency: new FormControl('', Validators.required),
      allowOrderSketch: new FormControl(false),
      underSupplierProductID: new FormControl(''),
      underSupplier: new FormControl(''),
      hscode: new FormControl(''),
      visibleonlinknetwork: new FormControl(false),
      transportationcostfree: new FormControl(false),
      transportationcostmanually: new FormControl(false),
      handlingcostfree: new FormControl(false),
      volumeitem: new FormControl(false),
      searchManualId: new FormControl(''),
      setSearchManualId: new FormControl(''),
      setSearchManualName: new FormControl(''),
      searchIspProductProductManualId: new FormControl(''),
      setSearchIspProductProductManualId: new FormControl(''),
      setSearchIspProductProductManualName: new FormControl(''),
      groupName: new FormControl(''),
      groupId: new FormControl(''),
      warehouse: new FormControl(''),
      currencyID: new FormControl('', Validators.required),
      countryID: new FormControl('', Validators.required),

    }
    if (!this.isEditScreen) {
      group.selectCustomers = new FormControl('', Validators.required);
      group.selectCategories = new FormControl('', Validators.required);
      group.selectSubCategories = new FormControl('');
      group.allowOrderSamplewithoutLogo = new FormControl(false);
      group.allowOrderSamplewithLogo = new FormControl(false);
      group.sketchPrice = new FormControl('');
      group.sampleWithOutLogo = new FormControl('');
      group.sampleWithLogo = new FormControl('');
      group.sampleWithLogoDeliveryDays = new FormControl('');
      group.sampleWithOutLogoDeliveryDays = new FormControl('');
    }
    else {
      group.disablePriceDropNotification = new FormControl(false);
    }

    group.unitHeight = new FormControl('')
    group.unitWidth = new FormControl('')
    group.unitDepth = new FormControl('')
    group.unitsPerCarton = new FormControl('')
    group.cartonHeight = new FormControl('')
    group.cartonWidth = new FormControl('')
    group.cartonDepth = new FormControl('')
    group.cartonWeight = new FormControl('')
    group.unitsOnPallet = new FormControl('')
    group.discontinuedDate = new FormControl('')
    group.updateReminderDate = new FormControl('')
    group.productNewEndDate = new FormControl('')
    group.productFeaturedEndDate = new FormControl('')
    group.productDiscountPercentage = new FormControl('')
    group.productDiscountEndDate = new FormControl('')
    group.linkToVideo = new FormControl('')
    group.language = new FormControl('en');

    if (this.isEditScreen) {
      if (!this.editProduct.isWebshopProduct) {
        group.customerWithOutLogo = new FormControl(false);
        group.customerWithLogo = new FormControl(false);
        group.disableLogo = new FormControl(false);
      }
    }

    this.translateForm.forEach(element => {
      if (element.required) {
        group[element.formcontrolname] = new FormControl('', Validators.required);
      }
      else {
        group[element.formcontrolname] = new FormControl('');
      }
    })
    this.addProductForm = new FormGroup(group);
  }

  getCurrentLang(lang) {
    this.selectedLang = lang.label;
  }

  validateManualId(manualid) {
    if (manualid !== '') {
      const manualData = {
        "manualid": manualid,
        "productid": 0
      }
      this.productService.loadManualId(manualData);
      this.productService.validateManualId().subscribe((data) => {
        if (data !== null && data !== '') {
          // this.showManualValidation = false;
          this.showManualerror = false;
          if (Boolean(data) === false) {
            // this.showManualValidation = true;
            this.showManualerror = true;
          }

        }
      })
    }
  }

  getBrandList() {
    this.productHttpService.getBrandList().subscribe((data: any) => {
      this.brandList = [];
      if (data) {
        data.forEach(brand => {
          let brandData = {
            label: brand.BrandName,
            value: brand.BrandId
          }
          this.brandList.push(brandData);
          //console.log(brandData);
        });
      }
    })
  }

  getCurrenyList() {
    this.productService.loadProductCurrency();
    this.productService.getProductCurrency().subscribe((data) => {
      this.currencyList = [];
      if (data !== null && data) {
        data.forEach(currency => {
          let currencyData = {
            label: currency.Name,
            value: currency.CurrencyID
          }
          this.currencyList.push(currencyData);
        })
      }
    })
  }
  getUnderSuppliers(customerID, supplierid) {
    // this.productService.loadUnderSuppliers();
    // const loginData = this.apiService.getLoginData();
    const request = {
      customerID: customerID,
      supplierID: supplierid
    }
    this.productHttpService.getUnderSupplierList(request).subscribe((data: any) => {
      if (data) {
        this.optionsUnderSupplier = [{ label: 'Select under supplier', value: '' }];
        data.ItemsCollection.forEach(element => {
          const customerVal = {
            value: element.UndersupplierID,
            label: element.Name,
          };
          this.optionsUnderSupplier.push(customerVal);
        });
      }
    });
  }
  getSupplierList(customerID) {
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getSuppliersList(customerID, this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        let supplierList = [{ label: 'Select supplier', value: '' }];
        data.ItemsCollection.forEach(supplier => {
          let supplierListData = {
            label: supplier.Name,
            value: supplier.SupplierID
          }
          supplierList.push(supplierListData)
        })
        this.supplierList = supplierList;
        // if (this.webshopDetail.ParentCustomerID && !this.webshopDetail.IsOpenBook) {
        //   this.addProductForm.patchValue({
        //     selectSupplier: this.webshopDetail.ParentCustomerID,
        //   })
        // }
      }
    })
  }

  getTransportationOriginCountries() {
    this.productService.loadTransportationOriginCountries();
    this.productService.getTransportationOriginCountries().subscribe((data) => {
      if (data) {
        let transportationOriginCountry = [{ label: 'Select transportantion country', value: '' }];
        data.forEach(contries => {
          let transportationOriginCountries: any = {
            label: contries.Name,
            value: contries.Alpha2Code
          }
          transportationOriginCountry.push(transportationOriginCountries)
        });
        this.transportationOriginCountries = transportationOriginCountry;
      }
    })
  }

  // getCategoryList() {
  //   this.loginService.getAllCustomers().subscribe(allcustomer => {
  //     if (allcustomer) {
  //       const customerlist = [];
  //       allcustomer.forEach(cust => {
  //         let custData = {
  //           label: cust.Name,
  //           value: cust.CustomerID
  //         }
  //         customerlist.push(custData)
  //       })
  //       this.customerlist = customerlist;
  //     }
  //   });

  //   this.productService.loadUnits();
  //   this.productService.getUnits().subscribe((data) => {
  //     if (data !== null) {
  //       this.productUnitList = [];
  //       data.forEach(units => {
  //         let unitsData = {
  //           label: units.Name,
  //           value: units.ProductUnitID,
  //           selected: true
  //         }
  //         this.productUnitList.push(unitsData);
  //       })
  //     }
  //   })
  // }


  getSelectedCustomer(customer) {
    this.getCustomerId = customer.value;
    this.optionsUnderSupplier = [];
    this.getCategory(customer.value)
    this.getSupplierList(customer.value);
    // this.getUnderSuppliers(customer.value, '');
    this.addProductForm.patchValue({
      selectSupplier: '',
      underSupplier: ''
    })
  }

  getCategory(data) {
    const categoryData = {
      customerID: data
    }
    this.productService.loadProductCategory(categoryData);
    this.productService.getProductCategory().subscribe((data: any) => {
      if (data) {
        this.categoryData = data;
        this.categoryList = [{ label: 'Select category', value: '' }];
        data.forEach(element => {
          let categoryObject = {
            label: element.Name,
            value: element.CategoryID
          }
          this.categoryList.push(categoryObject);
        });
      }
    })
  }

  getSelectedCategory(event) {
    this.optionsSelectSubCategories = [{ label: 'Select Subcategory', value: '' }];
    const getSelectedCategoryObj = this.categoryData.filter(data => data.CategoryID === event.value);
    this.getSelectedCategoryObj = getSelectedCategoryObj[0];
    this.getCategoryId = getSelectedCategoryObj[0].CategoryID;
    this.getCustomerId = getSelectedCategoryObj[0].CustomerID;
    getSelectedCategoryObj[0].SubCategories.forEach(item => {
      const option = {
        value: item.CategoryID,
        label: item.Name
      }
      this.optionsSelectSubCategories.push(option);
    });
  }

  getSub(event) {
    this.selectedSubcatId = event.value;
  }

  setTransportationOriginCountries(country) {
    this.transportationOriginCountryName = country.label;
    this.getTransportationCountry();
  }

  translateFieldValue() {
    this.translateDataArray = [];
    this.translateForm.forEach(translate => {
      let translateData = {
        "LanguageID": translate.LanguageID,
        "LanguageCode": translate.LanguageCode,
        "ObjectType": "Products",
        "PropertyName": translate.PropertyName,
        "PropertyValue": this.addProductForm.value[translate.formcontrolname],
        "Action": "Insert"
      }
      this.translateDataArray.push(translateData)
    })
  }

  getTransportationCountry() {
    this.productService.getTransportationOriginCountries().subscribe((data) => {
      if (data && data.length > 0) {
        const getCountry = data.filter(cou => cou.Alpha2Code === this.addProductForm.value.transportationOriginCountries);
        if (getCountry.length > 0) {
          this.getCountryName = getCountry[0].Name;
        }
      }
    });
  }

  addProduct() {
    this.translateFieldValue();
    if (this.addProductForm.invalid) {
      this.addProductForm.markAllAsTouched();
      const invalid = [];
      const controls = this.addProductForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      return;
    }

    const loginData = this.apiService.getLoginData();
    let addProduct = {
      "ProductUnitID": this.addProductForm.value.unitType,
      "isIndexedBySearchEngines": this.addProductForm.value.indexedBySearchEngine,
      "isInternalStockProduct": this.addProductForm.value.internalStockProduct,
      "isExpressOrderEnabled": this.addProductForm.value.enableExpressOrder,
      "ProductIdManual": this.addProductForm.value.manualId,
      "SupplierID": this.addProductForm.value.selectSupplier,
      "MinimumOrder": this.addProductForm.value.minimumOrder,
      "MinimumProductionOrder": this.addProductForm.value.minimumProductionOrder,
      "DeliveryWeeksFrom": this.addProductForm.value.deliveryWeeksFrom,
      "DeliveryWeeksTo": this.addProductForm.value.deliveryWeeksTo,
      "UnitWeight": this.addProductForm.value.unitWeight,
      "PiecesPerUnit": this.addProductForm.value.piecesPerUnit,
      "MRP": this.addProductForm.value.MRP,
      "ShippingPrice": this.addProductForm.value.ShippingPrice,
      "RewardPoints": this.addProductForm.value.RewardPoints,
      "FixedUnits": this.addProductForm.value.fixedUnits,
      "CustomerID": this.addProductForm.value.selectCustomers,
      "SelectedCategory": this.getSelectedCategoryObj,
      "AllowOrderSketch": this.addProductForm.value.allowOrderSketch,
      "AllowOrderSampleWithoutLogo": this.addProductForm.value.allowOrderSamplewithoutLogo,
      "AllowOrderSampleWithLogo": this.addProductForm.value.allowOrderSamplewithLogo,
      "isDeleted": this.addProductForm.value.isDeleted,
      "CurrencyID": this.addProductForm.value.currency,
      "Brand": this.addProductForm.value.brand,
      "ProductsCategoryID": this.addProductForm.value.productType,
      "PrintPricesCurrencyID": this.addProductForm.value.printPriceCurrency,
      "SketchPrice": this.addProductForm.value.sketchPrice,
      "SampleWithoutLogoPrice": this.addProductForm.value.sampleWithOutLogo,
      "SampleWithLogoPrice": this.addProductForm.value.sampleWithLogo,
      "SampleWithLogoDeliveryDays": this.addProductForm.value.sampleWithLogoDeliveryDays,
      "SampleWithoutLogoDeliveryDays": this.addProductForm.value.sampleWithOutLogoDeliveryDays,
      "UndersupplierProductID": this.addProductForm.value.underSupplierProductID,
      "UndersupplierID": this.addProductForm.value.underSupplier,
      "ProductCategories": [
        {
          "CustomerID": this.getCustomerId,
          "Action": "Insert",
          "CategoryID": this.selectedSubcatId ? this.selectedSubcatId : this.getCategoryId
        }
      ],
      "ProductPrices": [
        {
          "MinimumQuantity": 0,
          "MaximumQuantity": 2147483647,
          "Price": 1,
          "isFixedPrice": false,
          "ProductPriceSupplierProfits": [],
          "ProductPriceLinkNetworkCommissions": [],
          "ProductPriceCustomerFees": [],
          "ProductPrintPrices": [],
          "Action": "Insert"
        }
      ],
      "ImageName": "No_Image_Available.png",
      "ProductCustomers": [
        {
          "CustomerID": this.getCustomerId,
          "Action": "Insert"
        }
      ],
      "Translations": this.translateDataArray,
      "ProductImages": [
        {
          "ImageName": "No_Image_Available.png",
          "Position": 1,
          "ImageAlternativeText": this.addProductForm.value.imageAlternativeTextEng,
          "Action": "Insert",
          "Translations": [
            {
              "LanguageID": 1,
              "LanguageCode": "en",
              "ObjectID": 0,
              "ObjectType": "ProductImages",
              "PropertyName": "ImageAlternativeText",
              "PropertyValue": this.addProductForm.value.imageAlternativeTextEng,
              "Action": 1
            }
          ]
        }
      ],
      "VisibleOnLinkNetwork": this.addProductForm.value.visibleonlinknetwork,
      "isVolumeItem": this.addProductForm.value.volumeitem,
      "isTransportationFree": this.addProductForm.value.transportationcostfree,
      "DisableTransportationCostCalculation": this.addProductForm.value.transportationcostmanually,
      "isTransportationHandlingCostFree": this.addProductForm.value.handlingcostfree,
      "DiscontinuedDate": this.addProductForm.value.discontinuedDate ? this.dateFormatchange(this.addProductForm.value.discontinuedDate) : '',
      "UpdateReminderDate": this.addProductForm.value.updateReminderDate ? this.dateFormatchange(this.addProductForm.value.updateReminderDate) : '',
      "ProductNewEndDate": this.addProductForm.value.productNewEndDate ? this.dateFormatchange(this.addProductForm.value.productNewEndDate) : '',
      "ProductFeaturedEndDate": this.addProductForm.value.productFeaturedEndDate ? this.dateFormatchange(this.addProductForm.value.productFeaturedEndDate) : '',
      "ProductDiscountEndDate": this.addProductForm.value.productDiscountEndDate ? this.dateFormatchange(this.addProductForm.value.productDiscountEndDate) : '',
      "ProductDiscountPercentage": this.addProductForm.value.productDiscountPercentage,
      "LinkToVideo": this.addProductForm.value.linkToVideo,
      "UnitHeight": this.addProductForm.value.unitHeight,
      "UnitWidth": this.addProductForm.value.unitWidth,
      "UnitDepth": this.addProductForm.value.unitDepth,
      "UnitsPerCarton": this.addProductForm.value.unitsPerCarton,
      "CartonHeight": this.addProductForm.value.cartonHeight,
      "CartonWidth": this.addProductForm.value.cartonWidth,
      "CartonDepth": this.addProductForm.value.cartonDepth,
      "CartonWeight": this.addProductForm.value.cartonWeight,
      "UnitsOnPallet": this.addProductForm.value.unitsOnPallet,
      "TransportationOriginCountryCode": this.addProductForm.value.transportationOriginCountries,
      "TransportationOriginCountryName": this.getCountryName,
      "ProductGroupID": this.addProductForm.value.groupId,
      "ProductGroupName": this.addProductForm.value.groupName,
      "HSCode": this.addProductForm.value.hscode,
      "Action": "Insert",
      "CreatedByUserID": loginData.UserID,
      "CreatedByCustomerID": this.addProductForm.value.selectCustomers,
      "NewCurrencyID": this.addProductForm.value.currencyID,
      "NewCountryID" : this.addProductForm.value.countryID
    }
    if (this.isParentshop) {
      addProduct["Instructions"] = this.instructions;
      addProduct["StoreLocator"] = this.storeLocator;
      addProduct["TandC"] = this.terms;
      addProduct["HowtoUse"] = this.howtoUse;
      addProduct["RequestDate"] = this.reqDate;
    }

    if (this.addProductForm.value.setSearchManualId !== null && this.addProductForm.value.setSearchManualId !== undefined && this.addProductForm.value.setSearchManualId !== '') {
      addProduct["StockParentProductID"] = this.addProductForm.value.setSearchManualId;
      addProduct["ProductFutureStock"] = [];
    }
    else {
      addProduct["StockParentProductID"] = '';
    }
    if (this.addProductForm.value.setSearchManualName !== null && this.addProductForm.value.setSearchManualName !== undefined) {
      addProduct["StockParentProductIdManual"] = this.addProductForm.value.setSearchManualName;
    }

    this.productHttpService.addProduct(addProduct).subscribe((data) => {
      if (data) {
        if (typeof (data) === "number") {
          this.toast.success('Product created successfully');
          this.pushProductList();
        }
      }
    },
      (error) => {
        let errMsg = '';
        for (var key in error.error.ModelState) {
          errMsg += error.error.ModelState[key] + ' ';
        }
        this.toast.error(errMsg);
      })
  }

  pushProductList() {
    window.scroll(0, 0);
    this.router.navigate(['/admin-product-list'], { replaceUrl: false });
  }

  sketchOrderShow() {
    const sketchPrice = this.addProductForm.get('sketchPrice');
    if (this.addProductForm.value.allowOrderSketch === true) {
      this.showallowsketchPrice = true;
      sketchPrice.updateValueAndValidity();
    }
    else {
      this.showallowsketchPrice = false;
      sketchPrice.clearValidators();
      sketchPrice.setValue('');
    }
  }

  logoCheckbox(data) {
    if (data === 'allowOrderSamplewithoutLogo') {
      this.addProductForm.patchValue({
        allowOrderSamplewithLogo: false
      })
    }
    if (data === 'allowOrderSamplewithLogo') {
      this.addProductForm.patchValue({
        allowOrderSamplewithoutLogo: false
      })
    }
    if (data === 'allowOrderSketch') {
      this.addProductForm.patchValue({
        allowOrderSketch: false
      })
    }
    const sampleWithLogoDeliveryDays = this.addProductForm.get('sampleWithLogoDeliveryDays');
    const sampleWithOutLogoDeliveryDays = this.addProductForm.get('sampleWithOutLogoDeliveryDays');
    const sampleWithOutLogo = this.addProductForm.get('sampleWithOutLogo');
    const sampleWithLogo = this.addProductForm.get('sampleWithLogo');
    if (this.addProductForm.value.allowOrderSamplewithLogo) {
      this.showSampleWithLogoDeliveryDays = true;
      sampleWithLogoDeliveryDays.setValidators(Validators.required);
      sampleWithLogo.setValidators(Validators.required);
      sampleWithLogo.updateValueAndValidity();
    } else {
      this.showSampleWithLogoDeliveryDays = false;
      sampleWithLogoDeliveryDays.clearValidators();
      sampleWithLogoDeliveryDays.setValue('');
      sampleWithLogo.clearValidators();
      sampleWithLogo.setValue('');
    }
    if (this.addProductForm.value.allowOrderSamplewithoutLogo) {
      this.showSampleWithOutLogoDeliveryDays = true;
      sampleWithOutLogoDeliveryDays.setValidators(Validators.required);
      sampleWithOutLogo.setValidators(Validators.required);
      sampleWithOutLogo.updateValueAndValidity();
      // this.addProductForm.controls["sampleWithOutLogo"].setValidators([Validators.required]);
    } else {
      this.showSampleWithOutLogoDeliveryDays = false;
      sampleWithOutLogoDeliveryDays.clearValidators();
      sampleWithOutLogoDeliveryDays.setValue('');
      sampleWithOutLogo.clearValidators();
      sampleWithOutLogo.setValue('');
    }
    sampleWithLogoDeliveryDays.updateValueAndValidity();
  }



  showManageUnitFun(value) {
    this.showManageUnit = value;
    this.getChildChildCustomer();
  }

  numberOnly(event) {
    // var x = event.which || event.keyCode;
    // if(x == 45){
    //   this.toast.error('Negative values are not accepted here')
    //   return false
    // }
    // else{
    //   return true
    // }
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.toast.error('Numbers only accepted here')
      return false;
    }
    return true;
  }

  dateFormatchange(getDate) {
    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    return myDate;
  }

  lockDate() {
    if (this.productionDate !== undefined || this.deliveryDate !== undefined) {
      const request = {
        "ProductID": this.editProduct.ProductID,
        "ProductionDate": this.productionDate ? this.dateFormatchange(this.productionDate) : '',
        "DeliveryDate": this.deliveryDate ? this.dateFormatchange(this.deliveryDate) : ''
      }
      this.productHttpService.lockDeliveryDate(request).subscribe((data: any) => {
        if (data) {

          if (data.Message === "Locked delivery date successfully set for this product.") {
            this.toast.success(data.Message)
            this.showRemoveLockDate = true;
            this.lockdelivery.hide();
            this.lockedDelivery = false;
          }
          else {
            this.toast.error(data.Message)
            this.showRemoveLockDate = false;
            this.lockdelivery.hide();
          }
        }
      })
    }
    else {
      if (this.productionDate === undefined) {
        this.toast.error('Please select Production Date');
        return false;
      }
      if (this.deliveryDate === undefined) {
        this.toast.error('Please select Delivery Date');
      }
    }

  }

  removelockDate(id) {
    this.productHttpService.removeDevliveryDate(id).subscribe((data: any) => {
      if (data) {
        this.datePicker.clearDate()
        this.productionDate = '';
        this.deliveryDate = '';
        this.showRemoveLockDate = false;
        this.removeDelivery.hide();
        this.lockedDelivery = false;
        this.toast.success('Locked date deleted successfully');
      }
    })
  }

  searchProductManualId() {
    const request = {
      "ProductIdManualExact": this.addProductForm.value.searchManualId
    }
    this.productHttpService.searchManualId(request).subscribe((data: any) => {
      if (data) {
        this.manualIdList = data.ItemsCollection
      }
    })
  }

  setManualId(data) {

    this.addProductForm.patchValue({
      setSearchManualId: data.ProductID,
      setSearchManualName: data.ProductIdManual
    })

    this.manualName = data.ProductIdManual
    this.showSearchManualID = false;
    this.hideChangeButton = true;
  }

  changeManualName() {
    this.showSearchManualID = true;
    this.hideChangeButton = false
    this.addProductForm.patchValue({
      searchManualId: ''
    });
    this.manualIdList = undefined;
  }

  removeManualName() {
    this.showSearchManualID = true;
    this.manualName = '';
    this.addProductForm.patchValue({
      "searchManualId": '',
      "setSearchManualId": '',
      "setSearchManualName": '',
    });
    this.manualIdList = undefined;
  }


  searchIspProductionProductManualId() {
    const request = {
      "ProductIdManualExact": this.addProductForm.value.searchIspProductProductManualId,
      "OnlyProductionProducts": Boolean('true')
    }
    this.productHttpService.searchIspProductProductManualId(request).subscribe((data: any) => {
      if (data) {
        this.ispProductionProductManualIdList = data.ItemsCollection
      }
    })
  }
  setIspProductionProductManualId(data) {
    this.addProductForm.patchValue({
      setSearchIspProductProductManualId: data.ProductID,
      setSearchIspProductProductManualName: data.ProductIdManual
    })

    this.ispProductionProductmanualName = data.ProductIdManual;
    if (this.editProduct !== undefined) {
      this.editProduct.ProductionProductIdManual = this.ispProductionProductmanualName;
    }
    this.showSearchIspProductionProductManualID = false;
    this.hideChangeButtonForISP = true;
  }

  changeIspProductionProductManualName() {
    this.showSearchIspProductionProductManualID = true;
    this.hideChangeButtonForISP = false
    this.addProductForm.patchValue({
      searchIspProductProductManualId: ''
    });
    this.ispProductionProductManualIdList = undefined;
  }

  removeIspProductionProductManualName() {
    this.showSearchIspProductionProductManualID = true;
    this.ispProductionProductmanualName = '';
    if (this.editProduct !== undefined) {
      this.editProduct.ProductionProductIdManual = '';
    }
    this.addProductForm.patchValue({
      "searchIspProductProductManualId": '',
      "setSearchIspProductProductManualId": '',
      "setSearchIspProductProductManualName": '',
    });
    this.ispProductionProductManualIdList = undefined;
  }

  autoCompleteGroup(data) {
    this.productHttpService.autoCompleteGroup(data).subscribe((res: any) => {
      if (res) {
        this.groupCollection = res.ItemsCollection;
      }
    })
  }

  selected(groupname) {

    this.groupName = groupname;
    this.addProductForm.patchValue({
      groupName: groupname.Name,
      groupId: groupname.ProductGroupID
    })
  }

  

  selectedWareHouse(val) { }
  dontAllowDecimal(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  notAllowDecimal(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  deleteGroup() {
    this.searchText = '';
    this.addProductForm.patchValue({
      groupName: ''
    })
    const request = {
      groupid: this.addProductForm.value.groupId,
      productid: this.editProduct.ProductID
    }
    this.productHttpService.deleteGroup(request).subscribe(data => {
      if (data) {
        this.toast.success('Group deleted successfully');
      }
    })
  }

  getChildChildCustomer() {
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getChildChildCustomer(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        const customerlist = [{ label: 'Select webshop', value: '' }];
        data.forEach(cust => {
          let custData = {
            label: cust.Name,
            value: cust.CustomerID
          }
          customerlist.push(custData)
        })
        this.customerlist = customerlist;
      }
    })

    // this.productService.loadUnits();
    this.productHttpService.getUnits().subscribe((data: any) => {
      if (data !== null) {
        this.productUnitList = [{ label: 'Select Unit type', value: '' }];
        data.forEach(units => {
          let unitsData = {
            label: units.Name,
            value: units.ProductUnitID,
            selected: true
          }
          this.productUnitList.push(unitsData);
        })
      }
    })

  }

  getSupplier(data) {
    let customerId = this.isEditScreen ? this.editProduct.CreatedByCustomerID : this.addProductForm.value.selectCustomers;
    let productCustomerID = customerId;
    if (productCustomerID === undefined || productCustomerID === null || productCustomerID === '') {
      productCustomerID = this.editProduct.ProductCustomers.length > 0 ? this.editProduct.ProductCustomers[0]['CustomerID'] : '';
    }
    this.getUnderSuppliers(productCustomerID, data.value);
    this.addProductForm.patchValue({
      underSupplier: ''
    })
  }

  hasDecimalPlace(event) {
    let t = event.target.value;
    event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 6)) : t;
  }



  showlockdelivery() {
    if (this.editProduct.ProductionDate !== undefined && this.productionDate) {
      this.productionDate = new Date(this.editProduct.ProductionDate);
    }
    if (this.editProduct.DeliveryDate !== undefined && this.deliveryDate) {
      this.deliveryDate = new Date(this.editProduct.DeliveryDate);
    }
    // // this.productionDate = '';
    // this.datePicker.clearDate()
    this.lockedDelivery = true;
    this.lockdelivery.show();
  }

  ifDecimal(data, type) {
    if (type === 'unitsPerCarton') {
      this.showAlertPerCarton = data.target.value.includes('.');
    }
    else if (type === 'unitsOnPallet') {
      this.showAlertOnPallet = data.target.value.includes('.');
    }
    else if (type === 'piecesPerUnit') {
      this.showAlertPiecesPerUnit = data.target.value.includes('.');
    }
    else if (type === 'MRP') {
      this.showAlertMRP = data.target.value.includes('.');
    }
    else if (type === 'ShippingPrice') {
      this.showAlertShippingPrice = data.target.value.includes('.');
    }
    else if (type === 'RewardPoints') {
      this.showAlertRewardPoints = data.target.value.includes('.');
    }
  }

  // restrictAlphabets(e) {
  //   console.log(e);
  //   var x = e.which || e.keycode;
  //   if ((x >= 48 && x <= 57)) {
  //     return true;
  //   }
  //   else
  //     return false;
  // }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
    // console.log(event.target.value);
  }

  getCustomerData() {
    this.productService.getCustomerProduct().subscribe((cust: any) => {
      if (cust) {
        this.multiCustomerData = cust;
      }
    })
  }

  viewCustomerData(data, fieldName?, label?) {
    this.noRecord = false;
    this.selectedStaticField = '';
    this.selectedDynamicField = '';
    if (this.multiCustomerData.length === 1 && this.editProduct.ProductCustomers.length === 1) {
      if (this.editProduct.ProductCustomers['CustomerName'] === this.multiCustomerData['CustomerName']) {
        this.noRecord = true;
        this.customersDetail.show();
        return false;
      }
    }

    if (fieldName) {
      this.selectedStaticField = {
        fieldName: fieldName,
        label: label
      };
      this.multiCustomerData.forEach(element => {
        this.noRecord = element[fieldName] ? false : true;
      });

    }
    else {
      this.noRecord = true;
      this.selectedDynamicField = data;
      this.selectedDynamicField.showCustLabel = data.PropertyName;
      if (data.PropertyName === "ProductDescription") {
        this.selectedDynamicField.showCustLabel = 'Product Description';
      }
      else if (data.PropertyName === "OriginCountry") {
        this.selectedDynamicField.showCustLabel = 'Origin Country';
      }

      else if (data.PropertyName === "ProductMetaDescription") {
        this.selectedDynamicField.showCustLabel = 'Product Meta Description';
      }
      else if (data.PropertyName === "Material") {
        this.selectedDynamicField.showCustLabel = 'Product Material';
      }
      else if (data.PropertyName === "ProductCardLabel") {
        this.selectedDynamicField.showCustLabel = 'Product Card Label';
      }
      else if (data.PropertyName === "ProductName") {
        this.selectedDynamicField.showCustLabel = 'Product Name';
      }
      else if (data.PropertyName === "ProductH1Name") {
        this.selectedDynamicField.showCustLabel = 'Product H1 Name';
      }
      this.multiCustomerData.forEach(cust => {
        cust.CustomerTranslations.forEach(element => {
          if (data.PropertyName === element.PropertyName && data.LanguageID && element.LanguageID) {
            // this.noRecord = element.PropertyValue ? false : true;
            if (element.PropertyValue) {
              this.noRecord = false;
            }
          }
        });
      });
    }
    this.customersDetail.show();
  }

}
