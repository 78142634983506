import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillboardService } from '../../billboard/state/services/billboard.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { BillboardHttpService } from '../../billboard/state/services/billboard.http.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-campaign-billboard-list',
  templateUrl: './billboard-list.component.html',
  styleUrls: ['./billboard-list.component.scss']
})
export class CampaignBillboardListComponent implements OnInit {
  isLoading = false;
  header = "Banner Enquiry List";
  billboardList: any = [];
  campaignID: any;
  actions: any = ['edit', 'delete'];
  submitted = false;
  tableEvent: any;
  tableName = 'billboardList';
  isParentCustomer: boolean = true;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'BillboardAdID',
      header: 'Billboard ID'
    },
    {
      primaryKey: 'ImageURL',
      header: 'Image',
      format: 'customImage'
    },
    {
      primaryKey: 'VisibleOnLinkNetwork',
      header: 'Visible On Webshop'
    },
    {
      primaryKey: 'Position',
      header: 'Position'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];
  campaignName: any;
  status: any;
  icon: any;

  constructor(
    private billboardService: BillboardService,
    private router: Router,
    private billboardHttpService: BillboardHttpService,
    private linkTableService: LinkTableService,
    private toaster: ToastService
  ) {
    this.campaignID = history.state.data.campaignid;
    this.campaignName = history.state.data.name;
    this.icon = history.state.data.icon;
    this.status = history.state.data.status;
    this.onLoadFunctions();
  }

  ngOnInit() {
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (getWebshopDetails.DomainName !== getWebshopDetails.ParentCustomerDomainName) {
      this.isParentCustomer = false;
    } else {
      this.isParentCustomer = true;
    }
    this.header = 'Billboard Details'; // Multilingual to be implemented
    this.getBillboardList();
  }

  onLoadFunctions() {
    this.billboardService.loadBillboardList();
  }

  getBillboardList() {
    this.isLoading = true;
    this.billboardHttpService.getCampaignBillboardList(this.campaignID).subscribe(data => {
      if (data) {
        this.billboardList = data;
        this.billboardList = this.billboardList.ItemsCollection;
        this.isLoading = false;
      }
    });
  }

  addBillboard(event: boolean) {
    this.submitted = event;
  }

  addNewBillboard() {
    let data = { campaignid: this.campaignID, name: this.campaignName, icon: this.icon, status: this.status }
    this.router.navigate(['/admin-campaign-billboard-edit'], { state: { data }, replaceUrl: false });
  }

  viewBillboard(event: boolean) {
    this.submitted = event;
  }

  editBillboard(event) {
    try {
      if (event) {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(selectedRow => {
          if (selectedRow) {
            let data = {
              id: selectedRow.BillboardAdID,
              campaignid: this.campaignID,
              name: this.campaignName,
              image: selectedRow.ImageURL,
              icon: this.icon,
              status: this.status
            }
            this.router.navigate(['/admin-campaign-billboard-edit'], {
              state: { data },
              replaceUrl: true
            });
          }
        });
      }
    } catch (error) { }
    this.submitted = event;
  }

  deleteBillboard(event) {
    this.submitted = event;
    this.billboardHttpService.deleteBillboard(event.BillboardAdID).subscribe((data: any) => {
      this.onLoadFunctions();
      this.getBillboardList();
    });
  }

  changePosition(event) {
    const dragEvent = { CurrentPosition: event.previousIndex + 1, DesiredPosition: event.currentIndex + 1 };
    this.billboardHttpService.changePositionOfBillBoard(dragEvent).subscribe((res: any) => {
      if (res) {
        this.toaster.success(res);
      }
    }, error => {
      this.toaster.error(error);
    });
  }

  goBack() {
    let id = this.campaignID;
    let icon = this.icon;
    let status = this.status;
    this.router.navigate(['/admin-campaign-edit'], { state: { id, icon, status }, replaceUrl: false });
  }
}
