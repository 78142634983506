import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockHttpService } from './state/services/block.http.service';
import { BlockService } from './state/services/block.service';
import { blockReducers } from './state/reducers/block.reducer';
import { BlockEffects } from './state/effects/block.effects';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { BlockEditModule } from './block-edit/block-edit.module';
import { BlockListComponent } from './block-list/block-list.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    BlockEditModule,
    EffectsModule.forFeature([BlockEffects]),
    StoreModule.forFeature('blockModule', blockReducers),
    ColorPickerModule
  ],
  declarations: [BlockListComponent],
  providers: [BlockHttpService, BlockService],
  exports: [BlockListComponent, BlockEditModule]
})

export class BlockModule { }
