import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { BannerEnquiryHttpService } from './state/services/bannerenquiry.http.service';
import { BannerEnquiryService } from './state/services/bannerenquiry.service';
import { bannerenquiryReducers } from './state/reducers/bannerenquiry.reducer';
import { BannerEnquiryListComponent } from '../bannerenquiry/bannerenquiry-list/bannerenquiry-list.component';
import { BannerEnquiryDetailModule } from '../bannerenquiry/bannerenquiry-detail/bannerenquiry-detail.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        BannerEnquiryDetailModule,
        StoreModule.forFeature('billboardModule', bannerenquiryReducers)
    ],
    declarations: [BannerEnquiryListComponent],
    providers: [BannerEnquiryHttpService, BannerEnquiryService],
    exports: [BannerEnquiryListComponent]
})
export class BannerEnquiryModule { }