import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from '@angular/router';
import { BrandService } from '../state/services/brand.service';
import { BrandHttpService } from '../state/services/brand.http.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';

@Component({
    selector: 'rewardkart-admin-brand-list',
    templateUrl: './brand-list.component.html',
    styleUrls: ['./brand-list.component.scss']
})

export class BrandListComponent implements OnInit, OnChanges {

    showLoader: Boolean = false;
    header = "Brand Details";
    passFilterresult: any = [];
    brandList: any = [];
    columns: ColumnSetting[] = [];
    searchCriteria: any = {};
    filterData: any = {};
    submitted = false;
    perPagedata: number = 10;
    pageIndex: number = 1;
    resetStatus: boolean = false;
    actions: any = ['view', 'edit'];
    showBrandEditpage = false;
    filterFields: any = [];
    tableName: string = 'brandlist';
    enablepagination: boolean = true;
    branddatacount: any;
    tableEvent: any;
    constructor(private router: Router, private brandHttpService: BrandHttpService, private brandService: BrandService, private linkTableService: LinkTableService) {
        this.columns = [
            {
                primaryKey: 'BrandName',
                header: 'Name'
            },
            {
                primaryKey: 'BrandDescription',
                header: 'Description'
            },
            {
                primaryKey: 'ImagePath',
                header: 'Image',
                format: 'icon'
            },
            {
                primaryKey: 'action',
                format: 'brandaction',
            }
        ]
    }
    ngOnInit(): void {
        const searchkey = sessionStorage.getItem('searchkey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.searchCriteria = JSON.parse(searchkey);
            //this.perPagedata = 10;
            this.searchCriteria.pageIndex = 1;
            this.searchCriteria.pageSize = 10;
            this.resetStatus = true;
        } else {
            this.pageIndex = 1;
            this.searchCriteria.pageIndex = 1;
            this.searchCriteria.pageSize = 10;
        }
        sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
        this.getBrands(this.searchCriteria);
    }

    ngOnChanges() {
        this.getBrands(this.searchCriteria);
    }

    addNewBrand() {
        this.router.navigate(['/admin-brand-add'], { replaceUrl: false });
    }

    getFilterResult(filterresult) {
        this.passFilterresult = filterresult;
    }

    getBrands(data) {
        this.showLoader = true;
        this.brandHttpService.getBrandList(data).subscribe(
            (res: any) => {
                this.showLoader = false;
                if (res) {
                    this.branddatacount = res[0].TotalCount;
                    this.brandList = res;
                }
            });
    }

    editBrand(brandData: boolean) {
        if (brandData) {
            const searchkey = sessionStorage.getItem('searchkey');
            if (searchkey !== null && searchkey !== '[object Object]') {
                this.searchCriteria = JSON.parse(searchkey);
                //this.perPagedata = 10;
                this.searchCriteria.pageIndex = 1;
                this.searchCriteria.pageSize = 10;
                this.resetStatus = true;
                sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
            }
            this.showBrandEditpage = true;
            try {
                this.brandService.selectedBrand(brandData);
                this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
                    if (data && data.BrandId) {
                        data.dashboardRedirectStatus = false;
                        this.router.navigate(['/admin-brand-edit'], { state: { data }, replaceUrl: false });
                    }
                });
            } catch (error) {
            }
        }
    }

    onLoadBrands() {
        const searchkey = sessionStorage.getItem('searchkey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.getBrands(JSON.parse(searchkey));
        } else {
            this.searchCriteria = {
                pageIndex: 1,
                pageSize: this.perPagedata,
            }
            this.filterData = this.searchCriteria;
            sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
            this.getBrands(this.searchCriteria);
        }
    }

    refreshTable(event: boolean) {
        this.submitted = event;
        if (this.submitted) {
            this.onLoadBrands();
        }
    }

    getSubmit(submit) {
        this.pageIndex = 1;
        this.searchCriteria.pageIndex = this.pageIndex;
        this.filterData = this.searchCriteria;
        this.getBrands(this.filterData);
        sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
        sessionStorage.setItem('searchresult', JSON.stringify(this.filterFields));
    }

    getPerPage(event) {
        this.perPagedata = event;
        this.searchCriteria.pageSize = event;
        this.getSubmit(true);
    }

    paginationNum(event) {
        this.pageIndex = event;

        const searchkey = sessionStorage.getItem('searchkey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.searchCriteria = JSON.parse(searchkey);
        }
        this.searchCriteria.pageIndex = event;
        sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
        this.perPagedata = this.searchCriteria.pageSize;
        // this.getSubmit(true);
        this.getBrands(this.searchCriteria);
    }

    ngOnDestroy() {
        if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
    }
}