import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-product-roles',
  templateUrl: './product-roles.component.html',
  styleUrls: ['./product-roles.component.scss']
})
export class ProductRolesComponent implements OnInit {
  @Output() enableRoleStatus = new EventEmitter<any>();
  @Input() webshopData: any;
  @ViewChild('deleteModal', { static: false }) deleteModal: ModalDirective;
  @ViewChild('productrolesadd', { static: false }) productrolesadd: ModalDirective;
  @ViewChild('productrolesaddproducts', { static: false }) productrolesaddproducts: ModalDirective;
  @ViewChild('productrolesadduser', { static: false }) productrolesadduser: ModalDirective;
  productRolesList: any;
  roleName: any = '';
  deleteData: any = {};
  showLoader: Boolean = false;
  editData: any;
  productRoleList: any = [];
  selectedRole: any;
  usersRoleList: any;
  selectedUserRole: any;
  productManualID: any = '';
  enableProductRoles: Boolean = false;
  webshopDetail: any;
  selectedRoleName: any = '';
  isOpenCreateRole: Boolean = false;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.getRoleList();
  }

  ngOnChanges() {
    if (this.webshopData) {
      this.enableProductRoles = this.webshopData.EnableProductRoles;
    }
  }

  getRoleList() {
    this.showLoader = true;
    const loginData = this.apiService.getLoginData();
    let request: any = {
      customerID: this.webshopDetail.CustomerID
    }
    request.productIdManual = this.productManualID;
    this.webshopHttpService.loadRoles(request).subscribe(data => {
      this.showLoader = false;
      this.productRolesList = data;
    },
      (error) => {
        this.showLoader = false;
      })
  }

  editRoles(role) {
    this.editData = role;
    this.productrolesadd.show();
  }

  deleteRoles(role) {
    this.deleteData = role;
    this.roleName = role.Name;
    this.deleteModal.show();
  }

  confimRoleDelete() {
    this.showLoader = true;
    this.webshopHttpService.deleteRole(this.deleteData.ProductRoleID).subscribe(data => {
      this.showLoader = false;
      if (data) {
        this.getRoleList()
        this.deleteModal.hide();
      }
    },
      (error) => {
        this.showLoader = false;
      })

  }

  roleAdded(event) {
    if (event) {
      this.productrolesadd.hide();
      this.getRoleList();
    }
  }

  createRole() {
    this.editData = '';
    this.productrolesadd.show();
  }

  viewProduct(role) {
    this.showLoader = true;
    this.selectedRole = role;
    this.webshopHttpService.viewProduct(role).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        if (data.Message) {
          this.toast.error(data.Message);
        }
        this.productRoleList = data.Products;
        this.productRoleList.forEach(element => {
          element.ProductRoleID = role.ProductRoleID;
        });
        this.productrolesaddproducts.show();
      }
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      })
  }

  viewUser(role) {
    this.selectedUserRole = role;
    this.webshopHttpService.productRole(role.ProductRoleID).subscribe((data: any) => {
      if (data) {
        this.usersRoleList = data;
        this.productrolesadduser.show();
      }
    })
  }

  getproductList(event) {
    this.getRoleList();
  }

  loadUserStatus(event) {
    this.getRoleList();
  }

  searchManual() {
    this.getRoleList();
  }

  clearList() {
    this.productManualID = '';
    this.getRoleList();
  }

  roleStatus(event) {
    this.enableRoleStatus.emit(event.checked);
  }

  openCreateRole(event) {
    this.isOpenCreateRole = true;
  }

  closeCreateRole(event) {
    this.isOpenCreateRole = false;
  }

  getloaderStatus(event) {
    this.showLoader = event;
  }

}
