import { Dashboard, Stock, OrderFlow, ProductionLine, ProductList, Message, OrderState, PendingUser } from '../models/index';
import { DashboardActions } from '../actions/index';

export interface DashboardState {
    orderswidget: Dashboard[];
    stockwidget: Stock[];
    orderflowwidget: OrderFlow[];
    productionlinewidget: ProductionLine[];
    productlistwidget: ProductList[];
    messagewidget: Message[];
    orderstatewidget: OrderState[];
    pendinguserwidget: PendingUser[]
}

export const initialState: DashboardState = {
    orderswidget: null,
    stockwidget: null,
    orderflowwidget: null,
    productionlinewidget: null,
    productlistwidget: null,
    messagewidget: null,
    orderstatewidget: null,
    pendinguserwidget: null
};

export function dashboardReducers(state: DashboardState = initialState, action: DashboardActions.All) {

    switch (action.type) {

        case DashboardActions.LOAD_STOCK_SUCCESS:
            return Object.assign({}, state, { stockwidget: action.payload });

        case DashboardActions.LOAD_DASHBOARD_ORDERLIST_SUCCESS:
            return Object.assign({}, state, { orderswidget: action.payload });

        case DashboardActions.LOAD_ORDERFLOW_SUCCESS:
            return Object.assign({}, state, { orderflowwidget: action.payload });

        case DashboardActions.LOAD_PRODUCTIONLINE_SUCCESS:
            return Object.assign({}, state, { productionlinewidget: action.payload });

        case DashboardActions.LOAD_PRODUCTLIST_SUCCESS:
            return Object.assign({}, state, { productlistwidget: action.payload });

        case DashboardActions.LOAD_MESSAGE_SUCCESS:
            return Object.assign({}, state, { messagewidget: action.payload });

        case DashboardActions.LOAD_ORDERSTATE_SUCCESS:
            return Object.assign({}, state, { orderstatewidget: action.payload });

        case DashboardActions.LOAD_PENDINGUSER_SUCCESS:
            return Object.assign({}, state, { pendinguserwidget: action.payload });

        default: {
            return state;
        }
    }
}
