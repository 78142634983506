import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderState } from '../reducers/order.reducer';
import { OrderActions } from '../actions/index';
import { getOrderListSuccess } from '../reducers/index';

@Injectable()
export class OrderService {
    constructor(private store: Store<OrderState>) { }

    loadOrders(searchCriteria: any) {
        this.store.dispatch(new OrderActions.LoadOrders(searchCriteria));
    }

    getOrderList() {
        return this.store.select(getOrderListSuccess);
    }

}
