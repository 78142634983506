import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-link-pagination',
  templateUrl: './link-pagination.component.html',
  styleUrls: ['./link-pagination.component.scss']
})
export class LinkPaginationComponent implements OnInit, OnChanges {
  @Input() datacount: any;
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Output() rowPerPageEvent = new EventEmitter<any>();
  @Output() perPageEvent = new EventEmitter<any>();

  pageList: any = [];
  optionsSelect: Array<any>;
  initialLimit: any;
  finalLimit: any;
  selectItems = false;;
  constructor() { }

  ngOnChanges() {
    this.loadPaginate();
  }

  loadPaginate() {
    this.initialLimit = this.currentPage * this.itemsPerPage - this.itemsPerPage + 1;
    this.finalLimit = (this.currentPage * this.itemsPerPage < this.datacount) ?
      this.currentPage * this.itemsPerPage : this.datacount;
    let getPageList = this.datacount / this.itemsPerPage;
    getPageList = Math.ceil(getPageList);
    this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
    const pageLisArray = [];
    for (let i = 1; i <= getPageList; i++) {
      pageLisArray.push({ label: i, value: i });
    }
    this.pageList = pageLisArray;
  }

  ngOnInit() {
    this.loadPerPage();
  }

  loadPerPage() {
    this.optionsSelect = [
      { value: 10, label: '10', selected: true },
      { value: 30, label: '30' },
      { value: 60, label: '60' },
      { value: 90, label: '90' },
      { value: 120, label: '120' }
    ];
  }

  onChangePage(event) {
    this.currentPage = event;
    const pageLimit = { pageIndex: this.currentPage, pageSize: this.itemsPerPage };
    this.selectItems = this.currentPage * this.itemsPerPage > this.datacount ? true : false;
    this.perPageEvent.emit(pageLimit);
  }

  onChangeRowPerPage(setPageSizeOptionsInput) {
    this.itemsPerPage = setPageSizeOptionsInput.value;
    const pageLimit = { pageIndex: this.currentPage, pageSize: this.itemsPerPage };
    const limit = this.currentPage * this.itemsPerPage;
    this.rowPerPageEvent.emit(pageLimit);
    if (limit < this.datacount) {
      this.loadPaginate();
    }
  }
}
