import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsHttpService } from '../services/settings.http.service';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { EnableopenbookSettingsComponent } from './enableopenbook-settings.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [EnableopenbookSettingsComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule
  ],
  exports: [EnableopenbookSettingsComponent],
  providers: [SettingsHttpService, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
})
export class EnableopenbookSettingsModule { }
