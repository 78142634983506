import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoginService } from '../login/state/services/login.service';
import { Subject } from 'rxjs/Subject';
import { BreadCrumb } from './breadcrumb';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UserHttpService } from '../user/state/services/user.http.service';
import { element } from 'protractor';
import { timeStamp } from 'console';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {

  @Output() getlogoutstatus = new EventEmitter<any>();
  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    distinctUntilChanged(),
    map(event => this.buildBreadCrumb(this.activatedRoute.root))
  );
  Roles: any = [];
  roleName: any;
  showDashboard: boolean = true;
  title: any;
  logoutstatus: boolean = false;
  loggedIn: any = true;
  loggedInUser: any;
  userBudgetTransactions: any = [''];
  BudgetAmount = null;
  userID: number;
  customerImage: any;
  selectedMenuItem: any = { itemName: '', subItemName: '' };
  totalBudget: any;
  navIsClosed: any = false;
  dashboard = 'DASHBOARD'; inbox = 'INBOX'; blocks = 'BLOCKS'; orders = 'ORDERS'; users = 'USERS';
  products = 'PRODUCTS'; webshops = 'WEBSHOPS'; category = 'CATEGORY';
  settings = 'SETTINGS'; reports = 'REPORTS';
  categoryDetails = 'Category List'; categoryCopy = 'Category Copy';
  deliveredProducts = 'Delivered Products'; profitCenter = 'Profit Center';
  shopDetails = 'Shop Details'; paymentDetails = 'Payment Details'; socialMedia = 'Social Media';
  webshopTheme = 'Webshop Theme'; billboardImages = 'Billboard Images'; publishSettings = 'Publish Settings';
  changePassword = 'Change Password'; suppliers = 'SUPPLIERS'; billboard = 'BILLBOARD';
  currentPath: any = '';
  activeDashboard = true;
  menuItems: any = [
    { itemName: this.dashboard, iconName: 'assets/icons/dashboard.svg' },
    { itemName: this.inbox, iconName: 'assets/icons/Inbox.svg' },
    {
      itemName: this.category, iconName: 'assets/icons/category.svg',
      subItems: [
        { subItemName: this.categoryDetails, iconName: '' },
        { subItemName: this.categoryCopy, iconName: '' }
      ]
    },
    { itemName: this.products, iconName: 'assets/icons/Products.svg' },
    { itemName: this.blocks, iconName: 'assets/icons/Blocks.svg' },
    { itemName: this.orders, iconName: 'assets/icons/Orders.svg' },
    { itemName: this.users, iconName: 'assets/icons/users.svg' },
    { itemName: this.webshops, iconName: 'assets/icons/Webshops.svg' },
    {
      itemName: this.settings, iconName: 'assets/icons/Settings.svg',
      subItems: [
        { subItemName: this.shopDetails },
        { subItemName: this.paymentDetails },
        { subItemName: this.socialMedia },
        { subItemName: this.webshopTheme },
        { subItemName: this.billboardImages },
        { subItemName: this.publishSettings },
        { subItemName: this.changePassword }
      ]
    },
    {
      itemName: this.reports, iconName: 'assets/icons/reports.svg',
      subItems: [
        { subItemName: this.deliveredProducts },
        { subItemName: this.profitCenter }
      ]
    }
  ];
  subscription: any;
  planName: any;
  validTill: any;
  webshopDetail: any;
  isSpringboard: boolean = false;
  isSmartworks: boolean;
  constructor(private toastr: ToastService, private router: Router, private loginService: LoginService, private activatedRoute: ActivatedRoute, private userHttpService: UserHttpService) {
    this.subscription = this.userHttpService.getBudget().subscribe(res => {
      this.totalBudget = res;
    })
    this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if(this.webshopDetail.DomainName === "91sb" || this.webshopDetail.DomainName === "91springbaord"){
      this.isSpringboard = true;
    }
    if(this.webshopDetail.DomainName === "sw" || this.webshopDetail.DomainName === "smartworks"){
      this.isSmartworks = true;
    }
  }

  ngOnInit() {
    // this.showDashboardLink();
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe(data => {
        //console.log("data",data)
        if (data) {
          this.title = data.UserName;
          this.loggedInUser = data.Name;
          this.userID = data.UserID;
          this.roleName = data.Roles[0].RoleName;
          this.planName = data.SubScriptionPlanName;
          this.validTill = data.SubscriptionEndDate;
        }
      });
    }
    else if (sessionStorage.getItem('userdetails') !== null) {
      const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
      this.title = loginuserdetails.UserName;
      this.userID = loginuserdetails.UserID;
      this.loggedInUser = loginuserdetails.Name;
      this.roleName = loginuserdetails.Roles[0].RoleName;
      this.planName = loginuserdetails.SubScriptionPlanName;
      this.validTill = loginuserdetails.SubscriptionEndDate;
    }

    if (this.validTill) {
      let date = new Date(this.validTill);
      let currentDate = new Date();
      let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
      if (days < 0) {
        this.toastr.error('Your subscription has expired, renew it at earliest.')
      }
      else if (days < 7) {
        this.toastr.warning('Your subscription is about to expire, renew it at earliest.')
      }
    }

    this.getUserTransactions();
    this.getUserbudgetList();

    // this.customerImage = this.getCustomerImage();

  }

  ngOnChanges() {
    //  this.showDashboardLink();
  }

  showDashboardLink() {
    if (this.router.url !== '/admin-dashboard') {
      this.showDashboard = false;
    }
    this.currentPath = this.router.url;
  }


  buildBreadCrumb(route: ActivatedRoute, url: string = '',
    breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {

    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : 'Dashboard';
    const path = route.routeConfig ? route.routeConfig.path : '';

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };
    let newBreadcrumbs;
    if (route.routeConfig && route.routeConfig.path !== 'admin-dashboard') {
      this.activeDashboard = false;
      newBreadcrumbs = [...breadcrumbs, breadcrumb];
    } else {
      this.activeDashboard = false;
    }
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }


  // ngAfterViewInit() {
  //   this.sidenav.show();
  // }

  getCustomerImage() {
    let imageURL = null;
    this.loginService.getSelectedCustomer().subscribe(data => {
      if (data) {
        imageURL = data.ImageURL;
      }
    });
    return imageURL;
  }
  logout() {
    this.logoutstatus = true;
    sessionStorage.clear();
    this.getlogoutstatus.emit(this.logoutstatus);
  }
  navigateTo(item: any) {
    if (item.subItemName === undefined) {
      this.navIsClosed = true;
      this.selectedMenuItem.itemName = item.itemName;
      this.selectedMenuItem.subItemName = '';
      if (item.itemName === this.users) {
        this.router.navigate(['/admin-user-list'], { replaceUrl: false });
      } else if (item.itemName === this.webshops) {
        this.router.navigate(['/admin-webshop-list'], { replaceUrl: false });
      } else if (item.itemName === this.blocks) {
        this.router.navigate(['/admin-block-list'], { replaceUrl: false });
      } else if (item.itemName === this.orders) {
        this.router.navigate(['/admin-order-list'], { replaceUrl: false });
      } else if (item.itemName === this.products) {
        this.router.navigate(['/admin-product-list'], { replaceUrl: false });
      } else if (item.itemName === this.suppliers) {
        this.router.navigate(['/admin-supplier-list'], { replaceUrl: false });
      } else if (item.itemName === this.billboard) {
        this.router.navigate(['/admin-billboard-list'], { replaceUrl: false });
      }
    } else {
      this.navIsClosed = true;
      this.selectedMenuItem.subItemName = item.subItemName;
      if (item.subItemName === this.categoryDetails) {
        this.router.navigate(['/admin-category-list'], { replaceUrl: false });
      } else if (item.subItemName === this.categoryCopy) {
        this.router.navigate(['/admin-category-copy'], { replaceUrl: false });
      }
    }
  }

  getUserTransactions() {
    this.userHttpService.getUserbudgetTransaction(this.userID).subscribe(
      data => {
        if (data) {
          this.userBudgetTransactions = data;
          this.userBudgetTransactions.forEach(element => {
            this.BudgetAmount += Number(element.AmountDebit);

          });
          //console.log(this.BudgetAmount)
        };
      });

  }

  getUserbudgetList() {
    this.userHttpService.getUsersBudgetList(this.userID).subscribe((data: any) => {
      if (data) {
        let len = data.length;
        if (data.length > 0) {
          this.totalBudget = data[len - 1].AmountLeft;
          /*data.forEach((element) => {
            this.totalBudget = this.totalBudget + element.AmountLeft;
          })*/
          this.userHttpService.setBudget(this.totalBudget);
        } else {
          this.userHttpService.setBudget(0);
        }
      }
    });
  }

  goToDashBoard() {
    const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
    if (loginuserdetails.IsSubScriptionValid) {
      this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
    }
  }

  inrFormat(val: number) {
    return Number(val).toLocaleString("en-IN");
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
} 
