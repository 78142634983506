import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
// import { SettingsHttpService } from '../services/settings.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { SettingsHttpService } from '../services/settings.http.service';

@Component({
  selector: 'rewardkart-admin-enableopenbook-settings',
  templateUrl: './enableopenbook-settings.component.html',
  styleUrls: ['./enableopenbook-settings.component.scss']
})
export class EnableopenbookSettingsComponent implements OnInit {

  settingsForm: FormGroup;
  showLoader: boolean;
  enabledisableopenbook: any;
  webshopDetail: any;
  constructor(private toast: ToastService, private settingsHttpService: SettingsHttpService,
    private formBuilder: FormBuilder, private router: Router, public apiService: ApiService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.createForms();
  }

  ngOnInit() {
  }

  createForms() {
    // tslint:disable-next-line:max-line-length
    const enableStatus = (this.webshopDetail.IsOpenBook !== undefined && this.webshopDetail.IsOpenBook !== null) ? this.webshopDetail.IsOpenBook : false;
    this.settingsForm = this.formBuilder.group({
      enabledisableopenbook: [enableStatus]
    });
  }

  updateSettings() {
    console.log("Update Settings : ", this.settingsForm.value.enabledisableopenbook);
    const currentdate = this.MMDDYYYY();
    const postData = {
      Status: this.settingsForm.value.enabledisableopenbook,
      CustomerID: this.webshopDetail.CustomerID
    };
    console.log(postData);
    this.settingsHttpService.updateOpenBookSettings(postData).subscribe(data => {
      if (data) {
        this.showLoader = false;
        let userdetails = JSON.parse(sessionStorage.getItem('userdetails'));
        userdetails.IsOpenBookEnableStatus = this.settingsForm.value.enabledisableopenbook;
        sessionStorage.setItem('userdetails', JSON.stringify(userdetails));

        let webShopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
        webShopDetails.IsOpenBook = this.settingsForm.value.enabledisableopenbook;
        sessionStorage.setItem('webshopDetail', JSON.stringify(webShopDetails));
        this.toast.success("Settings updated successfully");
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  MMDDYYYY = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

}
