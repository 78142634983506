import { Category } from '../models/index';
import { CategoryActions } from '../actions/index';

export interface CategoryState {
    categorys: Category[];
    updateCategorySuccess: any;
    selectedCategory: any;
    customerCategories: Category[];
}

export const initialState: CategoryState = {
    categorys: null,
    updateCategorySuccess: null,
    selectedCategory: null,
    customerCategories: null
};

export function categoryReducers(state: CategoryState = initialState, action: CategoryActions.All) {
    switch (action.type) {
        case CategoryActions.LOAD_CATEGORIES_SUCCESS:
            return Object.assign({}, state, { categorys: action.payload });

        case CategoryActions.SAVE_CATEGORY_SUCCESS:
            return Object.assign({}, state, { updateCategorySuccess: action.payload });

        case CategoryActions.SELECTED_CATEGORY:
            return Object.assign({}, state, { selectedCategory: action.payload });

        case CategoryActions.CUSTOMER_CATEGORY_LIST:
            return Object.assign({}, state, { customerCategories: action.payload });

        default: {
            return state;
        }
    }
}
