import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductState, productReducers, } from './product.reducer';

export interface State {
    productModule: ProductState;
}

export const reducers = productReducers;

export const getProductState = createFeatureSelector<ProductState>('productModule');

export const getProductListSuccess = createSelector(getProductState,
    (state: ProductState) => state.products);

export const getProductDetailsSuccess = createSelector(getProductState,
    (state: ProductState) => state.productDetailsSuccess);

export const getDeleteProductSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductSuccess);

export const getProductSaveSuccess = createSelector(getProductState,
    (state: ProductState) => state.saveProductSuccess);

export const getProductUpdateSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductSuccess);

export const getSupplierListSuccess = createSelector(getProductState,
    (state: ProductState) => state.supplierListSuccess);

export const getCustomerInfoSuccess = createSelector(getProductState,
    (state: ProductState) => state.customerInfoSuccess);

export const getUnderSupplierListSuccess = createSelector(getProductState,
    (state: ProductState) => state.undersupplierListSuccess);

export const getProductColorListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productColorListSuccess);

export const getAllProductColorListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productAllColorListSuccess);

export const getAllProductGovtCostsListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productAllGovtCostsListSuccess);

export const getAllProductAdditionalCostsListSuccess = createSelector(getProductState,
    (state: ProductState) => state.getAllProductAdditionalCostsListSuccess);

export const getProductSizesListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productAllSizeListSuccess);

export const getProductLogoListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productAllLogoListSuccess);

export const getEnProductSizesListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productEnSizeListSuccess);

export const getParentProductColorListSuccess = createSelector(getProductState,
    (state: ProductState) => state.productParentColorListSuccess);

export const addProductColorSuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductColorSuccess);

export const getProductGovernmentalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.getProductGovernmentalCostsSuccess);

export const addProductGovernmentalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductGovernmentalCostsSuccess);

export const addProductAdditionalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductAdditionalCostsSuccess);

export const addProductSizeSuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductSizeSuccess);

export const addProductLogoPositionSuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductLogoPositionSuccess);

export const addProductPropertySuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductPropertySuccess);

export const updateProductColorSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductColorSuccess);

export const updateProductGovernmentalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductGovernmentalCostsSuccess);

export const updateProductAdditionalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductAdditionalCostsSuccess);

export const updateAllProductAdditionalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateAllProductAdditionalCostsSuccess);

export const updateProductLogoPositionSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductColorSuccess);

export const updateProductSizeSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductSizeSuccess);

export const deleteProductColorSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductColorSuccess);

export const deleteProductGovernmentalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductGovernmentalCostsSuccess);

export const deleteProductAdditionalCostsSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductAdditionalCostsSuccess);

export const deleteProductSizeSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductSizeSuccess);

export const deleteProductLogoPositionsSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductLogoPositionsSuccess);

export const deleteProductUnitSuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductUnitSuccess);

export const deleteProductPropertySuccess = createSelector(getProductState,
    (state: ProductState) => state.deleteProductPropertySuccess);

export const getProductCategorySuccess = createSelector(getProductState,
    (state: ProductState) => state.productCategory);

export const getProductPropertiesSuccess = createSelector(getProductState,
    (state: ProductState) => state.productProperties);

export const addProductUnitSuccess = createSelector(getProductState,
    (state: ProductState) => state.addProductUnits);

export const updateProductUnitSuccess = createSelector(getProductState,
    (state: ProductState) => state.updateProductUnits);

export const getproductUnitsSuccess = createSelector(getProductState,
    (state: ProductState) => state.productUnits);

export const getAllproductUnitsSuccess = createSelector(getProductState,
    (state: ProductState) => state.allProductUnits);

export const getproductSupplierSuccess = createSelector(getProductState,
    (state: ProductState) => state.productSuppliers);

export const getTransportationOriginCountriesSuccess = createSelector(getProductState,
    (state: ProductState) => state.transportationOriginCountries);

export const getproductCurrencySuccess = createSelector(getProductState,
    (state: ProductState) => state.productCurrency);

export const getProductAdditionalCostTypes = createSelector(getProductState,
    (state: ProductState) => state.productAdditionalCostTypes);

export const getproductCreateSuccess = createSelector(getProductState,
    (state: ProductState) => state.createProduct);

export const getManualIdSuccess = createSelector(getProductState,
    (state: ProductState) => state.manualValidate);

export const checkProductIdSuccess = createSelector(getProductState,
    (state: ProductState) => state.checkProductId);

export const unitsSuccess = createSelector(getProductState,
    (state: ProductState) => state.units);

export const getlogoTypeSuccess = createSelector(getProductState,
    (state: ProductState) => state.logoType);

export const getlogoTypeAllSuccess = createSelector(getProductState,
    (state: ProductState) => state.logoTypeAll);

export const getlogoAreaSuccess = createSelector(getProductState,
    (state: ProductState) => state.logoArea);

export const getchangevisibility = createSelector(getProductState,
    (state: ProductState) => state.changevisibility);

export const addmaxLogoArea = createSelector(getProductState,
    (state: ProductState) => state.maxLogoArea);

export const delmaxLogoArea = createSelector(getProductState,
    (state: ProductState) => state.maxLogoAreadel);

export const updatemaxLogoArea = createSelector(getProductState,
    (state: ProductState) => state.maxLogoAreaupdate);

export const handlingCostsuccess = createSelector(getProductState,
    (state: ProductState) => state.handlingCost);

export const customerProductsuccess = createSelector(getProductState,
    (state: ProductState) => state.customerProduct);    