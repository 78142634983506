import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { OrderActions } from '../actions/index';
import { State } from '../reducers/index';
import { OrderHttpService } from '../services/order.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class OrderEffects {

    // @Effect()
    // getOrderList$: Observable<Action> = this.actions$.pipe(
    //     ofType(OrderActions.LOAD_ORDERS),
    //     withLatestFrom(this.store$),
    //     switchMap(([payload]: any) => {
    //         return this.orderHttpService.getOrderList(payload.payload).map((data) => {
    //             // data preparation done here... image to be made image tag, etc.
    //             return new OrderActions.LoadOrdersSuccess(data);
    //         });
    //     }),
    //     catchError((error: any) => {
    //             return Observable.of(new OrderActions.LoadOrdersFail(error));
    //         })
    //     );

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private orderHttpService: OrderHttpService) { }
}
