import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderHttpService } from '../state/services/order.http.service';
import { DatePipe } from '@angular/common';
import { WarehouseHttpService } from 'src/app/warehouse-management/state/services/warehouse.http.service';
import { MDBModalService, MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { UpdateSubOrder } from './update-suborder-cost/update-suborder-cost.component'

@Component({
  selector: 'rewardkart-admin-order-process-view-details',
  templateUrl: './order-process-view-details.component.html',
  styleUrls: ['./order-process-view-details.component.scss']
})
export class OrderProcessViewDetailsComponent implements OnInit {
  orderData = null;
  orderId = '';
  subOrderId = '';
  lineId = '';
  sourcePage = '';
  isSubOrder = false;
  hideButtons = false;
  isSampleOrder = false;
  returnPage = ''; // productLine // orderProcessDetail // status
  loading: boolean;
  subOrderItemData: any;
  customerID: any;
  webshopDetail: any;
  finishedPL: any;
  customerPickUpItem: boolean = false;
  showField: boolean = false;
  warehouselist: any = [];
  logisticsVendorList: any = [];

  modalRef: MDBModalRef;
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };
  constructor(private warehousehttpservice: WarehouseHttpService, private route: ActivatedRoute, private httpService: OrderHttpService, public datepipe: DatePipe, private modalService: MDBModalService, private toastService: ToastService,) {
    this.sourcePage = this.getUrlParam('sourcePage');
    this.orderId = this.getUrlParam('orderId');
    const routeSubOrderId = this.getUrlParam('subOrderId');
    this.lineId = this.getUrlParam('lineId');
    this.isSubOrder = this.getUrlParam('isSubOrder') == '1' ? true : false;
    this.hideButtons = this.getUrlParam('hideButtons') == '1' ? true : false;
    this.isSampleOrder = this.getUrlParam('isSampleOrder') == '1' ? true : false;
    const { subOrderItemData, customerID, finishedPL } = history.state;
    this.finishedPL = finishedPL;
    this.customerID = customerID;
    this.subOrderItemData = subOrderItemData;
    this.subOrderId = routeSubOrderId || this.lineId;
    this.lineId = this.lineId || history.state.lineId;
    if (this.lineId && (history.state.type === 'productionline' || history.state.type === 'orderOverviewFromProductionLine')) {
      this.returnPage = 'productionLine';
    } else if (this.lineId && history.state.type === 'orderFromProductionLine') {
      this.returnPage = 'orderFromProductionLine';
    } else if (routeSubOrderId) {
      this.returnPage = 'orderProcessDetail';
    }
    this.getAllWareHouse();
    // tslint:disable-next-line: triple-equals
    this.getOrderDetails(this.orderId, this.subOrderId, this.isSubOrder);

  }

  ngOnInit() {

  }
  getUrlParam(paramValue): string {
    return this.route.snapshot.paramMap.get(paramValue);

  }

  getAllWareHouse() {
    var webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));;
    var searchdata = {
      pageSize: '',
      pageIndex: '',
      customerId: webshop.CustomerId,
    }
    this.warehousehttpservice.getAllWarehouse(searchdata).subscribe((datas: any) => {
      if (datas) {
        this.warehouselist = [];
        this.warehouselist = datas.ItemsCollection;
      }
    })
    this.warehousehttpservice.getCustomerLogisticVendor(searchdata).subscribe((datas: any) => {
      if (datas) {
        this.logisticsVendorList = [];
        this.logisticsVendorList = datas.ItemsCollection;
      }
    })
  }


  addItem(newItem: boolean) {
    this.customerPickUpItem = newItem;
  }

  getOrderDetails(orderId, subOrderId, subOrderFlag) {
    
    this.loading = true;
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    this.httpService.getOrderDetails(orderId, subOrderFlag, this.webshopDetail.CustomerID).subscribe((response: any) => {

      if (response.Suborders) {
        if (response.domainName == 'wsa' || (Number(response.CustomerID) == 15) || (Number(response.CustomerID) == 3)) {
          this.showField = true;
        }
        response.Suborders = this.getMappedSubOrders(response.Suborders, response.CurrencyCode);
        for (var item of response.Suborders) {
          const orderDetailItem = item.OrderItems
            .filter(data => (Number(data.OrderItemID) === Number(this.subOrderId)) || (Number(data.SuborderID) === Number(this.subOrderId)));
          if (orderDetailItem.length != 0) {
            if (orderDetailItem[0].IsPickupatWarehouse == true) {
              this.customerPickUpItem = true;
            }
            else {
              this.customerPickUpItem = false;
            }
          }
        }
      } else {
        
        if (response.domainName == 'wsa' || (Number(response.CustomerID) == 15) || (Number(response.CustomerID) == 3)) {
          this.showField = true;
        }
        response.OrderItems = this.getMappedOrders(response, response.OrderItems, response.CurrencyCode);
        const orderDetailItem = response.OrderItems
          .filter(data => (Number(data.OrderItemID) === Number(this.subOrderId)) || (Number(data.SuborderID) === Number(this.subOrderId)));
        if (orderDetailItem.length != 0) {
          if (orderDetailItem[0].IsPickupatWarehouse == true) {
            this.customerPickUpItem = true;
          }
          else {
            this.customerPickUpItem = false;
          }
        }
      }


      this.orderData = response;
      this.loading = false;

    });

  }

  getMappedSubOrders(subOrders, currency) {
    

    return subOrders.map(subOrder => {

      subOrder.OrderItems = this.getMappedOrders(subOrder, subOrder.OrderItems, currency);

      return subOrder;
    });

  }

  getMappedOrders(subOrder, orderItems, currency) {
    
    return orderItems.map(order => {
      // else {
      order.priceList = [...this.getProductPrice(order, currency),
      // tslint:disable-next-line: max-line-length
      ...this.getLogoPrice(order.OrderLogos, currency, order.OrderedQuantity), ...this.getAdditionalCost(order.ProductAdditionalCosts, currency), ...this.getGovernmentalCost(order.ProductGovernmentalCosts, currency)

      ];
      // }

      if (order.OrderItemTransportationCost > 0) {
        order.priceList.push(this.getTransportationCost(order.OrderItemTransportationCost, currency, order))

      }
      if (order.ShippingFee > 0) {
        order.priceList.push(this.getshippingCost(order.ShippingFee, currency, order))

      }

      if (order.OrderItemTransportationHandlingCost > 0) {
        order.priceList.push(this.getTransportationHandlingCost(subOrder, order.OrderItemTransportationHandlingCost, currency, order));
        if (order.AdditionalTransporationCost > 0) {
          order.priceList.push(this.getAdditionalTransportationCost(order.AdditionalTransporationCost, currency, order));
        }
        if (order.UpdatedTransportationCost >= 0) {
          order.priceList.push(this.getUpdatedTransportationCost(order.UpdatedTransportationCost, currency, order))
        }
      }

      if (order.AdditionalTransporationCost > 0 && order.OrderItemTransportationHandlingCost <= 0) {
        order.priceList.push(this.getAdditionalTransportationCost(order.AdditionalTransporationCost, currency, order));
      }
      if (order.UpdatedTransportationCost >= 0 && order.OrderItemTransportationHandlingCost <= 0) {
        order.priceList.push(this.getUpdatedTransportationCost(order.UpdatedTransportationCost, currency, order))
      }
      if (order.OrderitemCustomFee > 0) {
        order.priceList.push(this.getCustomFee(order.OrderitemCustomFee, currency, order))

      }

      if (order.IsPickupatWarehouse) {
        order.priceList.push(this.getPickupDeatils(order))

        // transportationText = 'Pick-up at supplier';
        // if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
        //   transportationText = 'Pick-up at warehouse';
        // }
        // return { label: transportationText, type: 'primary' };
      }
      if (!order.isTransportationCalculated) {
        order.priceList.push(this.transportationNotCalculated())

      }
      return order;
    });
  }

  getProductPrice(order, currency) {
    let discount = '';
    const mappedPrice = [];
    if (order.DiscountPercentage) {
      discount = `( Discount : ${order.DiscountPercentage} %)`;
    }


    if (order.AdjustedProductPrice > 0) {
      mappedPrice.push({ label: `Product Price `, type: 'primary', unit: order.OriginalProductPrice, currency, total: order.OriginalProductPrice * order.OrderedQuantity, seperator: ':', strike: false, cPrice: order.CostProductPrice });

      const updatedDate = this.datepipe.transform(order.OrderitemUpdateDate, 'dd/MM/yyyy');
      mappedPrice.push({ label: `Adjusted Product Price (${updatedDate})`, type: 'primary', unit: order.AdjustedProductPrice, currency, total: order.AdjustedProductPrice * order.OrderedQuantity, seperator: ':', cPrice: order.CostProductPrice, aPrice: order.AdjustedCostPrice });
    } else {
      mappedPrice.push({ label: `Product Price ${discount}`, type: 'primary', unit: order.ProductPrice, currency, total: order.ProductPrice * order.OrderedQuantity, seperator: ':', strike: false, cPrice: order.CostProductPrice, aPrice: order.AdjustedCostPrice });
    }
    return mappedPrice;
  }

  getLogoPrice(logos = [], currency, qty) {
    const mappedLogos: any = logos.length ? [this.getDummyPriceList()] : [];
    console.log('logos', logos)
    logos.forEach(logo => {
      mappedLogos.push({ label: `Product Print Cost `, type: 'primary', unit: logo.ProductPrintPrice, currency, total: logo.ProductPrintPrice * qty, seperator: ':', cPrice: logo.CostProductPrintPrice });

      if (logo.ScreenChargesCost > 0) { mappedLogos.push({ label: `Screen Charges Cost  `, type: 'primary', unit: '', currency, total: logo.ScreenChargesCost, seperator: ':', cPrice: logo.CostScreenChargesCost }); }

      if (logo.SetupCost > 0) { mappedLogos.push({ label: `Setup Cost`, type: 'primary', unit: '', currency, total: logo.SetupCost, seperator: ':', cPrice: logo.CostSetupCost }); }

      if (logo.ToolCost > 0) { mappedLogos.push({ label: `Tool Cost`, type: 'primary', unit: '', currency, total: logo.ToolCost, seperator: ':', cPrice: logo.CostToolCost }); }

      if (logo.ScreenChargesCost > 0) { mappedLogos.push({ label: `Setup Cost`, type: 'primary', unit: '', currency, total: logo.SetupCost, seperator: ':', cPrice: logo.CostSetupCost }); }

      if (logo.TotalHandlingCost > 0) {
        let handlingCost = logo.CostHandlingCost;
        mappedLogos.push({ label: `Handling Cost `, type: 'primary', unit: '', currency, total: logo.TotalHandlingCost, seperator: ':', cPrice: handlingCost });
      }

    });
    return mappedLogos;
  }

  getAdditionalCost(additionalCost, currency) {
    const additionalCosts: any = additionalCost.length ? [this.getDummyPriceList(), this.getDummyPriceList('Additional Cost', '', 'primary')] : [];
    additionalCost.forEach(cost => {
      let subCostName = '';
      let unit = 0;
      if (!cost.Price && cost.hasOwnProperty('ProductAdditionalCostOptions')) {
        subCostName = cost.ProductAdditionalCostOptions[0].Name;
        unit = cost.ProductAdditionalCostOptions[0].Price;
      } else {
        unit = cost.Price;
      }
      additionalCosts.push({ label: `${cost.Name} ${subCostName}`, type: 'secondary', unit, currency, total: cost.CurrentOrdPrice, seperator: ':', key: '3' });
    });
    return additionalCosts;
  }

  getGovernmentalCost(governmentalCost, currency) {
    if (governmentalCost.length !== 0) {
      // const additionalCosts: any = governmentalCost.length !== 0 ? [this.getDummyPriceList(), this.getDummyPriceList('Governmental Cost', '', 'primary')] : [];
      var additionalCosts: any = [];
      governmentalCost.forEach(cost => {
        additionalCosts.push({ label: `${cost.Name} `, type: 'secondary', unit: cost.Price, currency, total: cost.CurrentOrdPrice, seperator: ':' });
      });
      return additionalCosts;
    }
    else {
      return [];
    }
  }

  getTransportationCost(OrderItemTransportationCost, currency, order) {
    // let transportationText = 'Estimated Transportation Cost';
    console.log('order..', order)
    let transportationText = this.webshopDetail.DomainName == 'wsa' || this.showField || (Number(this.webshopDetail.CustomerID) == 15) || (Number(this.webshopDetail.CustomerID) == 3) ? 'Estimated Transportation Cost' : 'Transportation Cost';

    // if (order.IsPickupatWarehouse) {
    //   transportationText = 'Pick-up at supplier';
    //   if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
    //     transportationText = 'Pick-up at warehouse';
    //   }
    //   return { label: transportationText, type: 'primary' };
    // } else {
    console.log('OrderItemTransportationCost', OrderItemTransportationCost);
    return { label: transportationText, type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 1, cPrice: OrderItemTransportationCost };
    // }
    // return { label: transportationText, type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 1 };
  }


  transportationNotCalculated() {
    let transportationText = this.webshopDetail.DomainName == 'wsa' || this.showField || (Number(this.webshopDetail.CustomerID) == 15) || (Number(this.webshopDetail.CustomerID) == 3) ? 'Estimated Transportation Cost' : 'Transportation Cost';
    return { label: transportationText, type: 'primary', transportation: 'false', seperator: ':', key: 1 };

  }

  getPickupDeatils(order) {
    let transportationText: any;
    if (order.IsPickupatWarehouse) {
      transportationText = 'Pick-up at supplier';
      if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
        transportationText = 'Pick-up at warehouse';
      }
      return { label: transportationText, type: 'primary' };
    }
  }



  getUpdatedTransportationCost(UpdatedTransportationCost, currency, order) {
    let updatedDate = this.datepipe.transform(order.OrderitemUpdateDate, 'dd/MM/yyyy')
    // console.log('updatedDate', updatedDate)
    let transportationText = 'Updated Transportation Cost' + ' ' + '(' + updatedDate + ') ';
    // if (order.IsPickupatWarehouse) {
    //   transportationText = 'Pick-up at supplier';
    //   if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
    //     transportationText = 'Pick-up at warehouse';
    //   }
    // }
    return { label: transportationText, type: 'primary', unit: UpdatedTransportationCost, currency, total: UpdatedTransportationCost, seperator: ':', key: 2 };
  }

  getAdditionalTransportationCost(AdditionalTransporationCost, currency, order) {
    let transportationText = 'Additional Transportation Cost';
    // if (order.IsPickupatWarehouse) {
    //   transportationText = 'Pick-up at supplier';
    //   if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
    //     transportationText = 'Pick-up at warehouse';
    //   }
    // }
    return { label: transportationText, type: 'primary', unit: AdditionalTransporationCost, currency, total: AdditionalTransporationCost, seperator: ':', key: 3 };
  }
  getTransportationHandlingCost(subOrder, OrderItemTransportationCost, currency, order) {
    
    if (this.warehouselist.length > 0) {
      for (var warehouse of this.warehouselist) {
        if (warehouse.WarehouseID === order.WarehouseID) {
          var logisticsVendorId = warehouse.LogisticsVendorID;
          if (warehouse.MinimumHandlingPricePerSuborder === undefined || warehouse.MinimumHandlingPricePerSuborder === null) {
            if (this.logisticsVendorList.length > 0) {
              for (var vendor of this.logisticsVendorList) {
                if (logisticsVendorId === vendor.LogisticsVendorID) {
                  if (vendor.MinimumHandlingPricePerSuborder === undefined || vendor.MinimumHandlingPricePerSuborder === null) {
                    return false;
                  }
                  else if (vendor.MinimumHandlingPricePerSuborder !== undefined || vendor.MinimumHandlingPricePerSuborder !== null) {
                    if (OrderItemTransportationCost > vendor.MinimumHandlingPricePerSuborder) {
                      return { label: 'Handling Cost', type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 4, strike: true };
                    }
                    else if (OrderItemTransportationCost < vendor.MinimumHandlingPricePerSuborder) {
                      return { label: 'Handling Cost', type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 4, strike: false };
                    }
                    else {
                      return false;
                    }
                  }
                }
                // else {
                //   return false;
                // }
              }
            }
          }
          else if (warehouse.MinimumHandlingPricePerSuborder !== undefined || warehouse.MinimumHandlingPricePerSuborder !== null) {
            if (OrderItemTransportationCost > warehouse.MinimumHandlingPricePerSuborder) {
              return { label: 'Handling Cost', type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 4, strike: true };
            }
            else if (OrderItemTransportationCost < warehouse.MinimumHandlingPricePerSuborder) {
              return { label: 'Handling Cost', type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 4, strike: false };
            }
            else {
              return false;
            }
          }
        }
      }
    }
  }

  getshippingCost(shippingFee, currency, ordeer) {
    return { label: 'Shipping Fee', type: 'primary', unit: shippingFee, currency, total: shippingFee, seperator: ':', key: 5 };

  }

  updateSubOrder(suborderNO, SubTransCost) {
    const data = {
      header: `Update Suborder Transportation Cost By Amount`,
      suborderno: suborderNO,
      subtranscost: SubTransCost,
    };

    this.modalRef = this.modalService.show(UpdateSubOrder, {
      ...this.modalOptions, data
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.updateTransportationCost(this.subOrderId, result.newsubtranscost);
    });
  }

  updateTransportationCost(suborderno, newsubtranscost) {
    const payload = { Amount: newsubtranscost }
    if (newsubtranscost > 0) {
      this.httpService.updateNewTransportationCost(suborderno, payload).subscribe((response) => {
        if (response) {
          this.toastService.success('New Suborder Transportation Cost  updated successfully');
          this.modalRef.hide();
          this.getOrderDetails(this.orderId, this.subOrderId, this.isSubOrder);
        } else {
          this.toastService.error('something went wrong.please try after sometimes');
        }
      }, (error) => {
        this.toastService.error('something went wrong.please try after sometimes');
      });
    }
    else {
      this.toastService.error('Please check your transportation cost');
    }
  }

  getCustomFee(UpdatedCustomFee, currency, order) {
    return { label: 'Calculated Custom Fee', type: 'primary', unit: UpdatedCustomFee, currency, total: UpdatedCustomFee, seperator: ':', key: 6 };

  }
  getTotal(order, currency) {
    return { label: 'Total', type: 'primary', unit: '', currency, total: '', seperator: ':' };
  }

  getDummyPriceList(label = '', seperator = '', type = 'blank') {
    
    return { label, type, unit: '', currency: '', total: '', seperator };
  }
}
