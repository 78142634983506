import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OrderHttpService } from '../../state/services/order.http.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WebshopService } from 'src/app/webshop/state/services/webshop.service';

@Component({
  selector: 'rewardkart-admin-orderemailnotification',
  templateUrl: './orderemailnotification.component.html',
  styleUrls: ['./orderemailnotification.component.scss']
})
export class OrderemailnotificationComponent implements OnInit {
  @Input() customerEmailId;
  @Input() orderId;
  @Input() emailNotificationComment;
  @Output() modalCloseStatus = new EventEmitter<any>();
  showLoader = false;
  emailOrderForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private orderHttpService: OrderHttpService,
    private toast: ToastService, private router: Router, private translate: TranslateService,
    public _httpWebshopService: WebshopService) { }

  ngOnInit() {
    this.loadForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.emailNotificationComment !== undefined && changes.emailNotificationComment !== null) {
      if (changes.emailNotificationComment.currentValue !== null) {
        if (changes.emailNotificationComment.currentValue || !changes.emailNotificationComment.currentValue) {
          this.loadForm();
        }
      }
    }
  }

  loadForm() {
    this.emailOrderForm = this.formBuilder.group({
      CustomerEmail: [this.customerEmailId, Validators.required],
      CustomerOrderComments: []
    });
  }

  submitOrderCommentForm() {
    const requestData = {
      OrderID: this.orderId,
      CustomerEmail: this.emailOrderForm.value.CustomerEmail,
      Comments: this.emailOrderForm.value.CustomerOrderComments,
      Updated: true
    };
    this.showLoader = true;
    this.orderHttpService.sendUpdateOrderEmail(requestData).subscribe((result: any) => {
      this.showLoader = false;
      this.modalCloseStatus.emit(true);
      this.cancelOrderCommentForm();
      this.toast.success(this.translate.instant('EDITORDERSITEMS.ORDER_EMAIL_SUCCESS'));
    }, error => {
      console.log(error);
      this.toast.error(error);
      this.showLoader = false;
    });
  }

  cancelOrderCommentForm() {
    this.emailOrderForm.patchValue({
      CustomerOrderComments: ''
    });
    this.modalCloseStatus.emit(true);
  }

}
