import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { MessageTrashComponent } from './message-trash.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessageTrashdetailsComponent } from './message-trashdetails/message-trashdetails.component';
import { MessagetrashdetailsModule } from './message-trashdetails/message-trashdetails.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    MessagetrashdetailsModule
  ],
  declarations: [MessageTrashComponent],
  exports: [MessageTrashComponent]
})

export class MessagetrashModule { }