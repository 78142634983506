import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CategoryService } from '../state/services/category.service';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-category-productgrid',
  templateUrl: './category-productgrid.component.html',
  styleUrls: ['./category-productgrid.component.scss']
})
export class CategoryProductgridComponent implements OnInit, OnChanges {
  @Input() advanceSearchData: any;
  @Input() webshopOnlineProductsSearchStatus: any;
  @Output() webshopOnlineProductsSearchStatusChange = new EventEmitter();
  @Input() advanceSearchDataWebshopOnlineproducts: any;
  @Input() webshopOnlineProductsSelectStatus: any;
  @Input() formResetStatus: any;
  @Output() webshopOnlineProductsSelectStatusChange = new EventEmitter();
  @Output() formResetStatusChange = new EventEmitter();
  optionsSelect: Array<any>;
  categoryList: any = [];
  selectedSubCategoryName: string;
  selectedCategoryName: string;
  showLoader: boolean;
  categoryExists = false;
  selectedCustomerID: any;
  subCategoryExists = false;
  productExists = false;
  selectedCategoryID: any;
  selectedSubCategoryID: any;
  productList: any = []; selectedProductList: any = [];
  productTotalCount = 0;
  electedCustomerID: any; selectedCustomer: any = ''; subCategoryList: any = [];
  configpaginate: any = { itemsPerPage: 20, currentPage: 1, totalItems: 0 };
  subSubCategoryExist: Boolean = false;
  subSubCategory: any = [];
  productSelected = false;
  checkEvent: boolean;
  showDeleteIcon: boolean;
  sendIds: any = [];
  public selectIds = []
  deleteRelated: Boolean = false;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('alertModal', { static: true }) alertModal: ModalDirective;
  requestData: any
  selectAll: boolean = false
  showMsg: any;
  eventName: any;
  productListOnly: any;
  setAllCheck: boolean = false
  checked: boolean
  checkboxEvent: any;
  showDelete: boolean;
  eventNameFalse: boolean;
  subcategoryCollected: any;
  subcategorycollecteditems = [];
  subcategIds: any;
  subcategorycollectedobj: any;
  setSubcategorisCheck: boolean
  addCategoryIdReq: boolean = false;
  selectAllCheck: boolean = false;
  productsOnlyDelete: boolean;
  unCheckedValues = []
  FilterUnChecked: boolean;
  needFilter = [];
  filterArray1: any[];
  subCategoryListDuplicate: any[];
  selectAllChecked: boolean;
  setCategoryCheck: boolean;
  categoryIdObjs: any;
  categorycollecteditems = []
  categoryOnlySelect: boolean;
  selectedperpage: any = 20;
  pageSize: number = 0;
  activepage: number = 1;
  pageSizeOptions: number[] = [10, 30, 60, 90];
  pageList: any = [];
  rowPerPage: any = [{ label: 20, value: 20 }, { label: 40, value: 40 }, { label: 60, value: 60 }, { label: 80, value: 80 }];
  selectedpage: any = 1;
  totalCount: any;
  request: any = {};
  getID: any;
  getSelectObj: any;
  deletesendIdspro: any = [];
  webshopdata: any;
  webshopDetail: any;
  constructor(public categoryServices: CategoryService,
    public categoryService: CategoryHttpService,
    private categoryHttpService: CategoryHttpService,
    public toast: ToastService, public apiService: ApiService, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {

    // this.populateCategoryList()
    this.loadPerPage();
    this.resetPagination();
    this.populateCategoryList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this happens when click "Search" button in Advanced Search of  Webshop Library Section
    if (this.webshopOnlineProductsSearchStatus) {
      this.activepage = 1;
      this.selectedperpage = 20;
      this.selectedCategoryID = null
      this.selectedSubCategoryID = null;
      this.advanceSearchDataWebshopOnlineproducts.pageIndex = 1;
      this.advanceSearchDataWebshopOnlineproducts.pageSize = 20;
      this.advanceSearchCategoryList(this.advanceSearchDataWebshopOnlineproducts);
    }
    if (this.webshopOnlineProductsSelectStatus) {
      this.activepage = 1;
      this.selectedperpage = 20;
      if (this.formResetStatus) {
        this.populateCategoryList();
        this.formResetStatusChange.emit(false);
      }
    }
  }


  loadPerPage() {
    this.optionsSelect = [
      { value: '10', label: '10' },
      { value: '30', label: '30', selected: true },
      { value: '60', label: '60' },
      { value: '90', label: '90' },
    ];
  }

  resetPagination() {
    // if (this.refreshdata) {
    this.selectedperpage = 20;
    // }
  }

  advanceSearchCategoryList(requestParam) {
    if (this.webshopOnlineProductsSearchStatus) {
      this.selectedCategoryName = '';
      this.selectedSubCategoryName = '';
    }
    this.showLoader = true
    // this.categoryServices.loadCategorys(this.selectedCustomerID);
    this.categoryService.advacedSearchWebshopLibraryCategoryList(requestParam).subscribe(data => {
      if (data) {
        this.configpaginate = {
          itemsPerPage: Number(this.selectedperpage),
          currentPage: Number(this.activepage),
          totalItems: Number(data['TotalItemsCount'])
        };
        this.categoryExists = false;
        this.subCategoryExists = false;
        this.productExists = true;
        this.productList = data['ItemsCollection'];
        this.changeStatus(this.productList, false);
        this.productTotalCount = data['TotalItemsCount'];
        this.totalCount = data['TotalItemsCount'];

        let getPageList = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      } else {
        this.categoryExists = true;
      }
      this.showLoader = false;
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  populateCategoryList() {
    // const loginData = this.apiService.getLoginData();
    this.selectedCustomerID = this.webshopDetail.CustomerID;
    this.selectedCategoryName = '';
    this.selectedSubCategoryName = '';
    this.showLoader = true
    // this.categoryServices.loadCategorys(this.selectedCustomerID);
    this.categoryService.populateOnlineCategoryList(this.selectedCustomerID).subscribe(data => {
      if (data) {
        if (!this.webshopOnlineProductsSearchStatus) {
          this.productList = [];
        }
        this.productTotalCount = 0;
        this.categoryList = data;
        this.webshopdata = data;
        this.categoryList.forEach(element => {
          element.status = false;
        });
        this.categoryExists = !this.webshopOnlineProductsSearchStatus ? true : false;
      } else {
        this.categoryExists = false;
      }
      this.showLoader = false
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
    // debugger
  }

  loadCategory() {
    for (var k = 0; k < this.categoryList.length; k++) {
      this.categoryIdObjs = this.categoryList[k]['CategoryID']
      this.categorycollecteditems.push(this.categoryIdObjs)
    }
    if (!this.categoryOnlySelect) {
      this.selectIds = this.categorycollecteditems
    }
  }

  setSelectedCategory(id: any, name: any, catego) {
    this.getID = id;
    this.getSelectObj = catego;
    this.webshopOnlineProductsSearchStatus = false; // make false for @Input Params based on search from Advanced Search Section
    this.webshopOnlineProductsSelectStatus = false; // make false for @Input Params based on select from Advanced Search Section
    this.webshopOnlineProductsSearchStatusChange.emit(false);
    this.webshopOnlineProductsSelectStatusChange.emit(false);
    this.subcategoryCollected = id;
    this.selectIds = [];
    this.sendIds = [];
    this.deletesendIdspro = [];
    this.subcategIds = catego.SubCategories
    for (var j = 0; j < this.subcategIds.length; j++) {
      this.subcategorycollectedobj = this.subcategIds[j].CategoryID
      this.subcategorycollecteditems.push(this.subcategorycollectedobj)
    }
    this.subCategoryListDuplicate = this.subcategorycollecteditems
    this.categoryExists = false; //Hide category list
    this.selectedCategoryID = id;
    this.selectedCategoryName = name;
    this.selectedSubCategoryID = null;
    this.resetSubCategoryDetailsValues();
    this.populateSubCategoryAndProductList(id);
  }

  resetSubCategoryDetailsValues() {
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
    this.productTotalCount = 0;
  }

  populateSubCategoryAndProductList(categoryID: any) {
    const category = this.categoryList.find(x => x.CategoryID === categoryID);
    if (category.SubCategories.length > 0) {
      this.subCategoryExists = true;
      this.subCategoryList = category.SubCategories;
      this.webshopdata = category.Webshops;
      this.subCategoryList.forEach(element => {
        element.status = false;
      });
    } else {
      this.subCategoryExists = false;
    }
    if (category.ProductsMainCount > 0) {
      this.productExists = true;
      this.populateProductList(true);
    } else {
      this.productExists = false;
    }
    this.productExists = false;
  }

  populateProductList(isCategory, request?: any) {
    this.getSearchData(isCategory, request)

  }

  getSearchData(isCategory: boolean, request?) {
    let searchData: any;

    if (this.webshopOnlineProductsSearchStatus) {
      searchData = this.advanceSearchDataWebshopOnlineproducts;
      searchData.isAdmin = true;
      searchData.CategoryID = Number(request.CategoryID);
      searchData.pageIndex = 1;
      searchData.pageSize = this.configpaginate.itemsPerPage;
    }
    if (this.webshopOnlineProductsSelectStatus) {
      searchData = this.advanceSearchDataWebshopOnlineproducts;
      searchData.isAdmin = true;
      if (request !== undefined && request !== null) {
        searchData.CategoryID = Number(request.CategoryID);
      }
      if (this.selectedCategoryID !== undefined && this.selectedCategoryID !== null && this.selectedCategoryID !== '') {
        searchData.CategoryID = this.selectedCategoryID;
      }
      if (this.selectedSubCategoryID !== undefined && this.selectedSubCategoryID !== null && this.selectedSubCategoryID !== '') {
        searchData.CategoryID = this.selectedSubCategoryID;
      }
      searchData.pageIndex = 1;
      searchData.pageSize = this.configpaginate.itemsPerPage;
      searchData.CustomerID = this.selectedCustomerID;
    }
    if (!this.webshopOnlineProductsSearchStatus && !this.webshopOnlineProductsSelectStatus) {
      if (this.selectedCustomerID === 0) {
        searchData = {
          CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true
        };
      } else {
        if (isCategory) {
          searchData = {
            CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true
          };
        } else {
          searchData = {
            CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true, request: request
          };
        }
      }
    }
    this.showLoader = true;
    this.categoryHttpService.searchData(searchData).subscribe(data => {
      this.showLoader = false;
      if (data) {
        this.productList = data['ItemsCollection'];
        this.changeStatus(this.productList, false);
        this.productTotalCount = data['TotalItemsCount'];
        this.productListOnly = this.productList;
        this.totalCount = data['TotalItemsCount'];
        this.configpaginate.totalItems = this.productTotalCount;
        this.productExists = true;
        let getPageList = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
        // if (!isCategory) {
        //  this.loadPaginate();
        // }
      } else {
        this.productExists = false;
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
    // return searchData;
  }

  setSelectedSubCategory(id: any, name: any, data?) {
    this.getSelectObj = data;
    this.getID = id;
    this.activepage = 1;
    this.selectedperpage = 20;
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
    this.categoryOnlySelect = true
    this.productsOnlyDelete = true
    this.subCategoryExists = false;
    this.selectedSubCategoryID = id;
    this.selectedSubCategoryName = name;
    this.selectIds = [];
    this.sendIds = [];
    this.deletesendIdspro = [];
    this.populateSubCategoryProductList(id);

    //if (data.SubCategories.length > 0) {
    this.subSubCategoryExist = true;
    this.subSubCategory = data.SubCategories;
    this.changeStatus(this.subSubCategory, false);
    this.populateSubSubCategoryProductList(id);
    // }
    // else {

    // }
  }

  populateSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subCategoryList.find(x => x.CategoryID === subCategoryID);
    const subSubCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    if ((subCategory !== undefined && subCategory.ProductsMainCount > 0) || (subSubCategory !== undefined && subSubCategory.ProductsMainCount > 0)) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
  }

  populateSubSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    this.populateProductList(false);
    // if (subCategory.ProductsMainCount > 0) {
    //   this.populateProductList(false);
    // } else {
    //   this.productExists = false;
    // }
  }

  setPageSizeOptions(setPageSizeOptionsInput) {
    this.configpaginate.itemsPerPage = Number(setPageSizeOptionsInput);
    this.configpaginate.currentPage = 1;
    this.activepage = 1;
    // this.perPageData.emit(setPageSizeOptionsInput.value);
    this.loadPaginate();
    // this.advanceSearchCategoryList();
    this.setRequestParam(1, this.configpaginate.itemsPerPage);
  }

  loadPaginate() {

    // const productListLength = this.productList.TotalItemsCount;
    // console.log(productListLength)
    // this.configpaginate = {
    //   itemsPerPage: this.productList.PageSize,
    //   currentPage: this.productList.PageIndex,
    //   totalItems: productListLength
    // };
    this.configpaginate.totalItems = Number(this.productTotalCount);
  }

  pageChanged(event) {
    this.configpaginate.currentPage = Number(event);
    this.activepage = event;
    // this.populateProductList(false, request);
    this.setRequestParam(event, this.configpaginate.itemsPerPage);
  }

  setRequestParam(pageIndex, pageSize) {
    if (this.webshopOnlineProductsSearchStatus) {
      this.advanceSearchDataWebshopOnlineproducts.pageIndex = pageIndex;
      this.advanceSearchDataWebshopOnlineproducts.pageSize = pageSize;
      this.advanceSearchDataWebshopOnlineproducts.isAdmin = true;
      if (this.selectedCategoryID !== undefined && this.selectedCategoryID !== null && this.selectedCategoryID !== '') {
        this.advanceSearchDataWebshopOnlineproducts.CategoryID = this.selectedCategoryID;
        this.advanceSearchDataWebshopOnlineproducts.categoryId = this.selectedCategoryID;
      }
      if (this.selectedSubCategoryID !== undefined && this.selectedSubCategoryID !== null && this.selectedSubCategoryID !== '') {
        this.advanceSearchDataWebshopOnlineproducts.CategoryID = this.selectedSubCategoryID;
        this.advanceSearchDataWebshopOnlineproducts.categoryId = this.selectedSubCategoryID;
      }
      this.advanceSearchCategoryList(this.advanceSearchDataWebshopOnlineproducts);
    } else {
      let params = {};
      params['pageIndex'] = pageIndex;
      params['pageSize'] = this.configpaginate.itemsPerPage;
      params['isAdmin'] = true;
      params['CustomerID'] = this.selectedCustomerID;
      if (this.selectedCategoryID !== undefined && this.selectedCategoryID !== null && this.selectedCategoryID !== '') {
        params['CategoryID'] = this.selectedCategoryID;
        params['categoryId'] = this.selectedCategoryID;
      }
      if (this.selectedSubCategoryID !== undefined && this.selectedSubCategoryID !== null && this.selectedSubCategoryID !== '') {
        params['CategoryID'] = this.selectedSubCategoryID;
        params['categoryId'] = this.selectedSubCategoryID;
      }
      this.advanceSearchCategoryList(params);
    }
  }

  setProductSelected(selectedProduct: any) {
    this.categoryOnlySelect = true
    // alert('product only')
    const product = this.selectedProductList.find(x => x.ProductID === selectedProduct.ProductID);
    if (product === undefined) {
      this.selectedProductList.push(selectedProduct);
    } else {
      this.selectedProductList.splice(product);
    }
    this.productSelected = !this.productSelected;
  }

  sendId(id, event?) {
    this.showDelete = true
    this.checkboxEvent = event;
    if (this.eventNameFalse) {
      this.eventName = false
      if (this.eventName === false) {
        // alert('tick True')
        this.sendIds = id
        this.selectIds.push(id)
        this.needFilter = this.selectIds
      }
      else {
        this.showDelete = false
        // alert('tick false')
        const product = this.selectIds.findIndex(x => x === id);
        this.selectIds.splice(product, 1);
      }
    }
    else {
      this.eventName = event.target.control.checked;
      if (this.eventName === false) {
        // alert('tick True')
        this.sendIds = id
        this.selectIds.push(this.sendIds);
      }
      else {
        this.showDelete = false
        // alert('tick false')
        const product = this.selectIds.findIndex(x => x === id);
        this.selectIds.splice(product, 1);
      }
    }
  }

  ShowDeleteIcon(event) {
    if (event.target.control.checked == false) {
      this.showDelete = true
      this.setDeleteAll()
    }
    else {
      this.showDelete = false
    }
  }

  changeStatus(type, status) {
    type.forEach(element => {
      element.status = status;
    });
  }

  changeEvent(event, action) {
    this.selectIds = [];
    this.deletesendIdspro = [];
    this.selectAllCheck = event.checked;
    if (event.checked) {
      if (this.categoryExists) {
        if (this.categoryList.length > 0) {
          this.changeStatus(this.categoryList, event.checked);
          this.categoryList.forEach(element => {
            this.selectIds.push(element.CategoryID);
          });
        }
      }
      if (this.subCategoryExists) {
        if (this.subCategoryList.length > 0) {
          this.changeStatus(this.subCategoryList, event.checked);
          this.subCategoryList.forEach(element => {
            this.selectIds.push(element.CategoryID);
          });
        }
      }
      if (this.subSubCategoryExist) {
        if (this.subSubCategory.length > 0) {
          this.changeStatus(this.subSubCategory, event.checked);
          this.subSubCategory.forEach(element => {
            this.selectIds.push(element.CategoryID);
          });
        }
      }
      if (this.productExists) {
        if (this.productList.length > 0) {
          this.changeStatus(this.productList, event.checked);
          this.productList.forEach(element => {
            this.deletesendIdspro.push(element.ProductID);
          });
        }
      }

    }
    else {
      this.changeStatus(this.categoryList, event.checked);
      this.changeStatus(this.subCategoryList, event.checked);
      this.changeStatus(this.subSubCategory, event.checked);
      this.changeStatus(this.productList, event.checked);
      this.selectIds = [];
      this.deletesendIdspro = [];
    }
  }

  checkUnchecked(event, id, type, index) {
    if (event.checked) {
      if (type === 'category' || type === 'subCategory' || type === 'subsubCategory') {
        this.selectIds.push(id);
      }
      else {
        this.deletesendIdspro.push(id);
      }

      this.showDelete = true;

    }
    else {
      if (type === 'category' || type === 'subCategory' || type === 'subsubCategory') {
        let getIndex = this.selectIds.indexOf(this.sendIds)
        this.selectIds.splice(getIndex, 1)
      }
      else {
        let getIndex = this.deletesendIdspro.indexOf(id)
        this.deletesendIdspro.splice(getIndex, 1)
      }
    }
    if (type === 'category') {
      this.dynamicCheckUncheck(this.categoryList, index, event)
    }
    else if (type === 'subCategory') {
      this.dynamicCheckUncheck(this.subCategoryList, index, event)
    }
    else if (type === 'subsubCategory') {
      this.dynamicCheckUncheck(this.subSubCategory, index, event)
    }
    else {
      this.dynamicCheckUncheck(this.productList, index, event)
    }
  }

  dynamicCheckUncheck(type, index, event) {
    type[index]['status'] = event.checked;
    if (type.length > 0) {
      const checkIfAllChecked = type.filter(data => data.status === false);
      if (checkIfAllChecked.length === 0) {
        this.selectAllCheck = true;
      }
      else {
        this.selectAllCheck = false;
      }
    }
  }

  setDeleteAll() {
    this.addCategoryIdReq = true
    this.eventNameFalse = true
    // if (this.productListOnly !== undefined) {
    //   for (var i = 0; i < this.productListOnly.length; i++) {
    //     if (this.productListOnly !== undefined) {
    //       this.sendId(this.productListOnly[i].ProductID, this.checkboxEvent)
    //       this.setAllCheck = true
    //       this.setSubcategorisCheck = true
    //       this.setCategoryCheck = true
    //     }
    //   }
    // }
    return false;


  }

  setDelete() {
    // const loginData = this.apiService.getLoginData();
    this.deleteRelated = false;
    this.deleteModal.show();
  }

  deleterelate(event) {
    this.deleteRelated = event.checked;
  }

  confirmDelete() {
    // const loginData = this.apiService.getLoginData();
    let requestData = {
      CustomerID: this.webshopDetail.CustomerID,
      RemoveBatchProducts: this.deleteRelated
    }
    //if(this.selectIds.length > 0){

    if (this.productExists) {
      requestData['Products'] = this.deletesendIdspro;
    }

    if (this.categoryExists || this.subCategoryExists || this.subSubCategoryExist) {
      requestData['Categories'] = this.selectIds;
    }
    //}
    if (this.getID !== undefined && this.getID !== null && this.getID !== '') {
      requestData['CategoryID'] = this.getID;
    }

    // this.showLoader = true;
    this.categoryService.deleteOnlineProductsBulk(requestData).subscribe((data: any) => {
      if (data.Status) {
        this.showMsg = data.Message;
        this.alertModal.show();
      }
      else {
        this.confirmDel();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.Message);
    });



    // this.deleteModal.hide();
    // this.alertModal.hide();
  }

  confirmDel() {
    // const loginData = this.apiService.getLoginData();
    let requestDatas = {
      CustomerID: this.webshopDetail.CustomerID,
      RemoveBatchProducts: this.deleteRelated
    }
    if (this.productExists) {
      requestDatas['Products'] = this.deletesendIdspro;
    }

    if (this.categoryExists || this.subCategoryExists || this.subSubCategoryExist) {
      requestDatas['Categories'] = this.selectIds;
    }
    if (this.getID !== undefined && this.getID !== null && this.getID !== '') {
      requestDatas['CategoryID'] = this.getID;
    }

    this.showLoader = true;
    this.categoryService.deleteOnlineProductsConfirm(requestDatas).subscribe((data: any) => {
      if (data !== null) {
        if (data.Status) {
          this.toast.success(data.Message);
        }
        else {
          this.toast.error(data.Message);
        }
        if (!this.categoryExists) {
          this.getSearchData(true);
        }
        this.selectIds.forEach(deleteId => {
          if (this.categoryExists) {
            const getSubDeleteId = this.categoryList.findIndex(findSub => findSub.CategoryID === deleteId);
            this.categoryList[getSubDeleteId]['isDeleted'] = true;
          }
          if (this.subCategoryExists) {
            const getSubDeleteId = this.subCategoryList.findIndex(findSub => findSub.CategoryID === deleteId);
            this.subCategoryList[getSubDeleteId]['isDeleted'] = true;
          }
          if (this.subSubCategoryExist && this.subSubCategory.length > 0) {
            const getSubDeleteId = this.subSubCategory.findIndex(findSub => findSub.CategoryID === deleteId);
            this.subSubCategory[getSubDeleteId]['isDeleted'] = true;
          }
        });
        this.selectIds = [];
        this.deletesendIdspro = [];
        if (this.categoryExists) {
          this.populateCategoryList();
        }

        this.deleteModal.hide();
      }
      this.alertModal.hide();
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.Message);
    });
  }



  showAllCategory() {
    this.getID = null;
    this.webshopOnlineProductsSearchStatus = false;
    this.webshopOnlineProductsSelectStatus = true;
    this.webshopOnlineProductsSearchStatusChange.emit(false);
    this.webshopOnlineProductsSelectStatusChange.emit(true);
    this.activepage = 1;
    this.selectedperpage = 20;
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
    this.categoryOnlySelect = false
    this.subCategoryExists = false;
    this.productExists = false;
    this.populateCategoryList();
    this.subCategoryList = []
    this.subSubCategory = []
    this.productList = []
    this.setAllCheck = false
    this.setSubcategorisCheck = false
    this.setCategoryCheck = false
    this.selectAllCheck = false
    this.showDelete = false;
    this.selectedSubCategoryID = null;
    // this.selectedCategoryName = '';
    // this.selectedSubCategoryName = '';
  }

  showSubCategory() {
    this.webshopOnlineProductsSearchStatus = false;
    this.webshopOnlineProductsSelectStatus = true;
    this.webshopOnlineProductsSearchStatusChange.emit(false);
    this.webshopOnlineProductsSelectStatusChange.emit(true);
    this.activepage = 1;
    this.selectedperpage = 20;
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
    this.selectedSubCategoryID = null;
    this.categoryOnlySelect = false
    this.addCategoryIdReq = true
    this.productsOnlyDelete = false
    // this.subcategorycollecteditems= []
    this.selectIds = []
    this.unCheckedValues = []
    this.populateSubCategoryAndProductList(this.selectedCategoryID);
    this.selectedSubCategoryName = '';
    this.productExists = false;
    this.setAllCheck = false
    this.setSubcategorisCheck = false
    this.setCategoryCheck = false
    this.selectAllCheck = false
    this.showDelete = false
  }

  showProducts() {
    this.webshopOnlineProductsSearchStatus = false;
    this.webshopOnlineProductsSelectStatus = true;
    this.webshopOnlineProductsSearchStatusChange.emit(false);
    this.webshopOnlineProductsSelectStatusChange.emit(true);
    this.activepage = 1;
    this.selectedperpage = 20;
    // this.categoryOnlySelect = false
    this.populateSubCategoryProductList(this.selectedSubCategoryID)
    this.setAllCheck = false
    this.setSubcategorisCheck = false
    this.setCategoryCheck = false
    this.selectAllCheck = false
    this.showDelete = false
  }

  clearArray() {
    this.selectIds = []
  }

  onDroppedData(data: any) {

    // const loginData = this.apiService.getLoginData();
    let returnList: any = {
      customerID: this.webshopDetail.CustomerID
    }
    if (data.dropData !== undefined) {
      returnList.categoryID = data.dropData.ParentID ? data.dropData.ParentID : data.dropData.CategoryID;
    }
    let request = {
      "CopyBatchProducts": data.typeData.leftCopyRelatedProducts,
      "DestinationCustomerid": this.webshopDetail.CustomerID,
      "ExcludeCategories": data.typeData.excludeCategory,
      "IncludeProducts": data.typeData.withProducts,
      "IncludeSubcategories": data.typeData.withSubCategory,
      "SourceCustomerID": data.typeData.SourceCustomerID ? data.typeData.SourceCustomerID : null
    }
    if (data.dropData !== undefined) {
      request['DestinationCategoryID'] = data.dropData.CategoryID;
    }
    if (data.typeData.type === 'category') {
      request['Categories'] = [data.dragData.CategoryID];
    }
    else if (data.typeData.type === 'product') {
      request['Products'] = data.dragData.productIds;
    }

    // return false;
    this.showLoader = true;
    this.categoryHttpService.dragDrop(request).subscribe((res: any) => {

      if (res.Status) {
        this.toast.success(res.Message);
        this.categoryHttpService.getSubCategory(returnList).subscribe((response: any) => {
          if (response) {
            // this.categoryList = [response];
            console.log(response)
            if (data.dropData !== undefined) {
              if (this.subCategoryExists) {
                this.subCategoryList = response.SubCategories;
              }
            }
            if (!this.categoryExists) {
              this.getSearchData(true, data.dropData);
            }
            else {
              // this.showLoader = false;
              this.populateCategoryList();
            }
            // if(this.categoryExists){
            //   this.populateCategoryList();
            // }
          }
        },
          (error) => {
            this.showLoader = false;
            this.toast.error('Method not allowed');
          })
      }
      else {
        this.showLoader = false;
        this.toast.error(res.Message);
      }

    }, error => {
      this.showLoader = false;
    });
  }

  hoverDiv(val?) {
    return val;
  }

}
