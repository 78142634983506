import { Component, OnInit } from '@angular/core';
import { UserHttpService } from '../../user/state/services/user.http.service'
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { LoginHttpService } from 'src/app/login/state/services/login.http.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-subscription-list',
    templateUrl: './subscription-list.component.html',
    styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit {
    planList: any;
    header: string = 'Choose a Plan';
    subscriptionPlanId: any;
    btnSelect: any;
    subscriptionAmount: any;
    domainName: any;
    contactEmail: any;
    contactNumber: any;
    customerID: any;
    isLoading: boolean = false;
    constructor(private cookieService: CookieService, private loginHttpService: LoginHttpService, private _http: UserHttpService, private router: Router, private toastr: ToastService) {
        if (history.state.SubscriptionPlanID) {
            this.subscriptionAmount = history.state.SubscriptionAmount;
            this.subscriptionPlanId = history.state.SubscriptionPlanID;
            this.domainName = history.state.DomainName;
            this.contactEmail = history.state.ContactEmail;
            this.contactNumber = history.state.ContactNumber;
        }
        const webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.customerID = webshop.CustomerID;
        }
    }
    ngOnInit(): void {
        this.getSubscription();
    }

    getSubscription() {
        this._http.getallSubScriptions().subscribe(data => {
            this.planList = data;
            if (this.subscriptionAmount) {
                this.planList = this.planList.filter(e => e.SubscriptionAmount >= this.subscriptionAmount);
            } else {
                this.planList = this.planList.filter(e => e.SubscriptionAmount > 0);
            }
            this.planList = this.planList.reverse();
        });
    }

    publish() {
        if (this.planList[this.btnSelect].SubscriptionAmount) {
            let url = window.location.origin;
            const queryString = 'OrderId=' + this.subscriptionPlanId + '&IsRewardKart=Y&Domain=' + url + '&Pre=SubScription&ReturnURL=' + url + '&Email=' + this.contactEmail + '&Mobile=' + this.contactNumber + '&customerID='+this.customerID;
            const paymentURL = 'https://dotnet.constient.com/RewardKartPayWeb/AtomPayment/AtomPaymentRequest?' + queryString;
            sessionStorage.setItem('subscriptionid', this.subscriptionPlanId);
            this.loginHttpService.getUser().subscribe(data => {
                this.cookieService.set('payment', data.password);
            });
            window.location.href = paymentURL;
        } else {
            this.isLoading = true;
            this._http.upgradeSubscription(this.customerID, this.subscriptionPlanId).subscribe((data) => {
                sessionStorage.removeItem('subscriptionid');
                this.isLoading = false;
                this.toastr.success('Plan Subscribed Successfully');
                setTimeout(() => {
                    location.reload();
                }, 4000);
            })
        }
    }

    plans(id, i) {
        this.subscriptionPlanId = id;
        this.btnSelect = i;
    }

    inrFormat(val: number) {
        return Number(val).toLocaleString("en-IN");
    }
}