import { Component, OnInit, ViewChild, OnChanges, DoCheck, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../state/services/product.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debug } from 'util';
import { ProductHttpService } from '../state/services/product.http.service';
import { idLocale, getFullYear, getMonth, getDay } from 'ngx-bootstrap';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { WebService } from '../../restful-service/web.service';
import { IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import * as moment from 'moment';
import { ProductEditService } from './product-edit.service';
import * as _ from 'lodash';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})

export class ProductEditComponent implements OnInit, AfterViewInit {

  @ViewChild('publishModal', { static: false }) publishModal: ModalDirective;
  @ViewChild('unpublishWarningModal', { static: false }) unpublishWarningModal: ModalDirective;
  @ViewChild('datePicker', { static: false }) datePicker: any;
  @ViewChild('visibledatepicker', { static: false }) visibledatepicker: any;
  @ViewChild('cloneProduct', { static: false }) cloneProduct: ModalDirective;
  @ViewChild('visibleDatePopup', { static: false }) visibleDatePopup: ModalDirective;
  @ViewChild('batchproduct', { static: false }) batchproduct: ModalDirective;
  @ViewChild('vieworiginaldataPopup', { static: false }) vieworiginaldataPopup: ModalDirective;
  @ViewChild('confirmProduct', { static: false }) confirmProduct: ModalDirective;
  @ViewChild('publishErrorModal', { static: false }) publishErrorModal: ModalDirective;
  customerProduct: boolean;

  @HostListener('keydown.esc')
  handleInput() {
    if (this.datePicker) {
      if (this.datePicker.isOpen) {
        this.datePicker.closeBtnClicked();
      }
    }
  }
  loadApi: any = {}
  loadImages: Boolean = false;


  publishForm: FormGroup;
  productedittab: any = [];
  basicinfo = 'BASICINFO';
  additionalinfo = 'ADDITIONALINFO';
  categoryinfo = 'CATEGORYINFO';
  productstock = 'PRODUCTSTOCK';
  productimages = 'PRODUCTIMAGES';
  productprices = 'PRODUCTPRICES';
  productproperties = 'PRODUCTPROPERTIES';
  colors = 'COLORS';
  sizes = 'SIZES';

  logopositionandareas = 'LOGOPOSITIONSANDAREAS';
  additionalcosts = 'ADDITIONALCOSTS';
  publish = 'PUBLISH';
  isNextBtnDisabaled = false;
  header: any;
  showProductEditpage = false;
  productList: any = [];
  submitted = false;
  additionalInfo: any = [];
  date = new Date()

  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  };
  loadBasicInfo: boolean = false;
  productData: any = {};
  // currentTab: any = 'basicInfo';
  productStockValues: any = {};
  isPublished: boolean;
  productDetails: any = {};
  productColorsValues: any;
  productCategoryValues: any;
  activePage: any = 'basicInfo';
  updateStatus: boolean = false;
  basicInfoValues: any;
  translationData: any;
  getCountryName: any;
  visibleDate: any;
  displayPublishDate: any;
  productEditTab: any = [
    'basicInfo', 'categoryInfo', 'productImages', 'productPrices', 'productStock',
    'productProperties', 'colorsizes',
    'productLogopositionarea', 'additionalgovernmental'
  ]

  currentTab: any = { itemName: 'basicinfo', Position: 0 };
  productEditMenuTab: any = [];
  productImageDetails: any;
  productPropertyDetails: any;
  catloadStatus: boolean = false;
  sizeData: any = [];
  showLoader: Boolean = false;
  isVisibleError: Boolean = false
  logoPositionArea: any;
  productGovernmentalCosts: any;
  productAdditionalCosts: any;
  showCloneBtn = false;
  showSiblingBtn = false;
  showUpdateBtn: Boolean = false;
  printpricedata: any = [];
  rightPosition = false;
  hideClonepublish: boolean;
  getproductPriceEdit: any;
  priceFormValue: any;
  defaultlogotype: any;
  autoUpate: boolean;
  showContent: boolean;
  hideConvertForClone: boolean;
  isSibling: any;
  historyData: any;
  customerProductData: any;
  fixedLogoData: any;
  originalData: any;
  webshopDetail: any;
  editableType: any = 'BOTH';
  isOriginalProduct: Boolean = true;
  getByePassStatus: any = false;
  disableAll: Boolean = false;
  productType: any;
  publishErrMsg: any = '';
  checkCustomerStatus: Boolean = true;
  isParentcustomer:boolean = false;
  constructor(private productService: ProductService,
    private linkTableService: LinkTableService,
    private productHttpService: ProductHttpService,
    private productEditService: ProductEditService,
    public toast: ToastService,
    private router: Router,
    private translate: TranslateService,
    private webService: WebService,
    private apiService: ApiService,
    public formBuilder: FormBuilder) {
    this.getByePassStatus = sessionStorage.getItem('isParentLoginAsChildShop');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    if ((this.webshopDetail && this.webshopDetail.ParentCustomerDomainName) === (this.webshopDetail && this.webshopDetail.DomainName)) {
      this.isParentcustomer = true;
    }
  }

  ngAfterViewInit() {
    this.historyData = history.state;
    if (history.state.isClone) {
      if (history.state.isClone) {
        this.productEditService.isProductCloneSelected = history.state.isClone;
        this.showCloneBtn = true;
        this.showSiblingBtn = false;
        this.hideConvertForClone = true
        this.showUpdateBtn = false;
      } else {
        this.showCloneBtn = false;
        this.showSiblingBtn = false;
        // if(!this.isPublished){
        //   this.showUpdateBtn = true;
        // }
      }
    }
    if (history.state.isSibling) {
      if (history.state.isSibling) {
        this.isSibling = history.state.isSibling;
        this.productEditService.isProductCloneSelected = history.state.isClone;
        this.showSiblingBtn = true;
        this.showCloneBtn = false;
        this.hideConvertForClone = true
        this.showUpdateBtn = false;
      } else {
        this.showSiblingBtn = false;
        this.showCloneBtn = true;
        // if(!this.isPublished){
        //   this.showUpdateBtn = true;
        // }
      }
    }
  }

  loadForm() {
    this.displayPublishDate = this.getDateFormat(new Date())
    this.publishForm = this.formBuilder.group({
      visibleDate: [this.displayPublishDate, Validators.required],
    })
  }

  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }


  MMDDYYYY = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('-');
  }


  YYYYMMDD = (val) => {
    let d = new Date(this.dateToMonth(val)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('/');
  }



  loadBatch() {
    this.productEditMenuTab.forEach(element => {
      if (element.id === 'producttobatch') {
        element.isActive = this.productDetails.EnableConvertToBatch;
      }
    });
  }


  loadTranslate() {
    this.translate.get('PRODUCTEDITTAB', {}).subscribe((res: any) => {
      this.productEditMenuTab = [
        { itemName: res.BASICINFO, id: 'basicInfo', isActive: true, rightPosition: false },
        { itemName: res.CATEGORYINFO, id: 'categoryInfo', isActive: false, rightPosition: false },
        { itemName: res.PRODUCTIMAGES, id: 'productImages', isActive: false, rightPosition: false },
        { itemName: res.PRODUCTPRICES, id: 'productPrices', isActive: false, rightPosition: false },
        { itemName: res.PRODUCTSTOCK, id: 'productStock', isActive: false, rightPosition: false },
        { itemName: res.PRODUCTPROPERTIES, id: 'productProperties', isActive: false, rightPosition: false },
        { itemName: res.COLORSSIZES, id: 'colorsizes', isActive: false, rightPosition: false },
        { itemName: res.LOGOPOSITIONSANDAREAS, id: 'productLogopositionarea', isActive: false, rightPosition: true },
        { itemName: res.ADDITIONALGOVERNMENALCOSTS, id: 'additionalgovernmental', isActive: false, rightPosition: true },
        // { itemName: res.CONVERTPRODUCTTOBATCH, id: 'producttobatch', isActive: false, rightPosition: true },
        // { itemName: this.isPublished ? "Unpublish" : res.PUBLISH, id: 'publish', isActive: false, rightPosition: true },
      ]
    })

  }
  loadHide() {
    // if (history.state.isClone) {
    //   if (this.productEditMenuTab[12].id == 'publish') {
    //     this.hideClonepublish = true
    //   }
    //   if (this.productEditMenuTab[11].id == 'governmentalcost') {
    //     this.isNextBtnDisabaled == true;
    //   }
    // }
  }
  // loadTranslate1() {
  //   this.translate.get('PRODUCTEDITTAB', {}).subscribe((res: any) => {
  //     this.productEditMenuTab = [
  //       { itemName: res.BASICINFO, id: 'basicInfo', isActive: false },
  //       { itemName: res.ADDITIONALINFO, id: 'additionalInfo', isActive: false },
  //       { itemName: res.CATEGORYINFO, id: 'categoryInfo', isActive: false },
  //       { itemName: res.PRODUCTSTOCK, id: 'productStock', isActive: false },
  //       { itemName: res.PRODUCTIMAGES, id: 'productImages', isActive: false },
  //       { itemName: res.PRODUCTPRICES, id: 'productPrices', isActive: false },
  //       { itemName: res.PRODUCTPROPERTIES, id: 'productProperties', isActive: false },
  //       { itemName: res.COLORS, id: 'productColors', isActive: false },
  //       { itemName: res.SIZES, id: 'productSizes', isActive: false },
  //       { itemName: res.LOGOPOSITIONSANDAREAS, id: 'productLogopositionarea', isActive: false },
  //       { itemName: res.ADDITIONALCOSTS, id: 'additionalcost', isActive: false },
  //       { itemName: res.GOVERNMENTALCOSTS, id: 'governmentalcost', isActive: true },
  //       { itemName: this.isPublished ? "Unpublish" : res.PUBLISH, id: 'publish', isActive: false },
  //     ]
  //   })
  // }

  ngOnInit() {

    this.getProductDetails('initial');
    this.loadBasicInfo = false;
    this.loadForm();
    this.loadTranslate();
    this.loadBatch();
    // this.updateProductInfo()
    this.publishForm.valueChanges.subscribe(() => {
      const d = this.publishForm.value.visibleDate
      const date = new Date(this.dateToMonth(d))
      const currentDate = new Date(this.MMDDYYYY())

      if (!(date >= currentDate)) {
        this.isVisibleError = true
      } else {
        this.isVisibleError = false
      }
      this.displayPublishDate = this.publishForm.value.visibleDate
    });
    this.loadHide();
  }
  getProductDetails(type?) {
    console.log(history.state.data)
    const historyData = history.state.data;
    this.isOriginalProduct = true;
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.showLoader = true;
    if (history.state.data !== undefined) {
      this.productData = history.state.data;
      if (this.productData.IsMultipleCustomer && this.productData.EditableType === 'OP' && this.productData.isImported) {
        this.disableAll = true;
      }
      const getFilterData = JSON.parse(sessionStorage.getItem('presearch'));
      let getFilterCustID;
      if (getFilterData !== null && getFilterData !== undefined) {
        getFilterCustID = getFilterData.CustomerID ? getFilterData.CustomerID : '';
      }
      let request = {
        productID: this.productData.ProductID,
        customerID: this.webshopDetail.CustomerID,
        productCustID: this.productData.CustomerID ? this.productData.CustomerID : getFilterCustID,
        isCustomerSelected: history.state.data.IsCustomerSelected
      }
      if (history.state.type) {
        if (history.state.type.type == 'stock') {
          if (this.webshopDetail.ParentCustomerID) {
            request['productCustID'] = this.webshopDetail.CustomerID;
            request['isCustomerSelected'] = false;
          }
          else {
            request['productCustID'] = history.state.type.customer == 'All' ? this.webshopDetail.CustomerID : history.state.type.customer;
            request['isCustomerSelected'] = history.state.type.customer == 'All' ? false : true;
          }
        }
      }
      this.productHttpService.checkProductType(request).subscribe((checkPro: any) => {
        if (checkPro) {
          if (this.productData.IsMultipleCustomer) {
            checkPro.EditableType = this.productData.EditableType
          }
          if (this.historyData.type == 'stock' && !this.webshopDetail.ParentCustomerID) {
            checkPro.EditableType = 'OP';
          }
          this.productType = checkPro.EditableType;
          if (checkPro.EditableType === 'OP' && this.productData.isImported) {
            this.disableAll = true;
          }
          if (checkPro.EditableType === 'CP') {
            this.isOriginalProduct = false;
          }
          else {
            this.isOriginalProduct = true;
          }
          if (this.productDetails !== undefined) {
            this.productDetails.ProductProperties = [];
            this.productDetails.ProductPrices = [];
          }
          // const loginData = this.apiService.getLoginData();
          this.productData.customerID = this.webshopDetail.CustomerID;
          let checkOpenBook = true;
          // if (!this.productData.IsWebshopProduct) {
          if (checkPro.EditableType === 'CP') {
            checkOpenBook = getWebshopDetails.IsOpenBook;
            if (!getWebshopDetails.IsOpenBook) {
              if (getWebshopDetails.ParentCustomerID === null || getWebshopDetails.ParentCustomerID === undefined) {
                checkOpenBook = true;
              }
            }
            const getBypePassStatus = sessionStorage.getItem('isParentLoginAsChildShop');
            if (getBypePassStatus) {
              checkOpenBook = true;
            }
          }

          this.webService.productEditdata(this.productData, checkOpenBook, getWebshopDetails).subscribe((editdata) => {
            if (editdata) {
              const request = {
                customerID: this.productData.CustomerID ? this.productData.CustomerID : this.webshopDetail.CustomerID,
                productID: this.productData.ProductID
              }
              this.productService.loadCustomerProduct(request);
              this.productService.getCustomerProduct().subscribe((data: any) => {

              });
              this.originalData = editdata;
              let productDetails: any = editdata;

              if (checkPro.EditableType === 'CP') {
                productDetails.isWebshopProduct = false;
              }
              else {
                productDetails.isWebshopProduct = true;
              }
              let productCustomerID = this.webshopDetail.CustomerID;
              if (this.productData) {
                if (this.productData.CustomerName) {
                  if (productDetails.ProductCustomers.length > 0) {
                    if (productDetails.ProductCustomers.length === 1) {
                      productCustomerID = productDetails.ProductCustomers[0]['CustomerID'];
                    }
                    else {
                      const getCustID = productDetails.ProductCustomers.filter(get => get.CustomerName === this.productData.CustomerName);
                      productCustomerID = getCustID[0]['CustomerID'];
                    }
                  }
                  else {
                    productCustomerID = this.webshopDetail.CustomerID;
                  }
                }
                else {
                  if (productDetails.ProductCustomers.length === 1) {
                    productCustomerID = productDetails.ProductCustomers[0]['CustomerID'];
                  }
                  else {
                    productCustomerID = this.webshopDetail.CustomerID;
                  }
                }
              }
              if (productCustomerID !== undefined && productCustomerID !== null && productCustomerID !== '') {
                sessionStorage.setItem('productCustomerID', productCustomerID);
              }
              if (getWebshopDetails !== null && getWebshopDetails !== undefined) {
                productDetails.IsOpenBook = getWebshopDetails.IsOpenBook;
              }
              if (!checkOpenBook) {
                productDetails.ProductSizes = [];
              }

              if (this.historyData.isClone) {
                productDetails.isWebshopProduct = true;
              }

              if (editdata) {
                // let getID = this.productData.CustomerID ? this.productData.CustomerID : getFilterCustID;
                const getCust = this.originalData.ProductCustomers.filter(getCustID => getCustID.CustomerID == productCustomerID);
                if (this.productData.IsUnpublishedByCust == 2) {
                  this.checkCustomerStatus = false;
                }
                if (getCust.length > 0) {
                  this.productData.IsOriginalProductPublished = getCust[0]['isOriginalProductPublished'];
                  // this.isOriginalProduct = this.productData.isOriginalProductPublished;
                  if (!this.isOriginalProduct) {
                    if (getCust[0]['isApproved']) {
                      this.productData.IsUnpublishedByCust = 0;
                    }
                    else if (!getCust[0]['isApproved']) {
                      this.productData.IsUnpublishedByCust = 1;
                    }
                    if (!getCust[0]['IsCPDataAvailable']) {
                      // this.checkCustomerStatus = false;
                      this.productData.IsUnpublishedByCust = 2;
                    }
                  }

                }
              }


              // if (!this.productData.IsWebshopProduct) {
              if (checkPro.EditableType === 'CP') {
                // const loginData = this.apiService.getLoginData();
                // productDetails.isWebshopProduct = false;
                const request = {
                  customerID: this.productData.CustomerID ? this.productData.CustomerID : this.webshopDetail.CustomerID,
                  productID: this.productData.ProductID
                }
                // this.productService.loadCustomerProduct(request);
                this.productHttpService.getCustomerProductData(request).subscribe((cust: any) => {

                  if (cust) {
                    // const getCurrentData = cust.filter(get => get.CustomerID === this.webshopDetail.CustomerID)

                    this.customerProductData = cust;
                    if (editdata['ProductPrices'] && this.customerProductData.ProductPrices != undefined) {
                      if (this.customerProductData.ProductPrices.length > 0) {
                        editdata['ProductPrices'].forEach(orgCustFees => {
                          this.customerProductData.ProductPrices.forEach(customerFee => {
                            if (orgCustFees.ProductPriceID === customerFee.ProductPriceID && (orgCustFees.ProductPriceCustomerFees === undefined || orgCustFees.ProductPriceCustomerFees.length === 0)) {
                              orgCustFees.ProductPriceCustomerFees = customerFee.ProductPriceCustomerFees;
                            }
                          });
                        });
                      }
                    }
                    productDetails.ProductDiscountPercentage = this.customerProductData.ProductDiscountPercentage;
                    productDetails.ProductDiscountEndDate = this.customerProductData.ProductDiscountEndDate;
                    productDetails.UnitHeight = this.customerProductData.UnitHeight;
                    productDetails.UnitWidth = this.customerProductData.UnitWidth;
                    productDetails.UnitDepth = this.customerProductData.UnitDepth;
                    productDetails.ProductLogoMaxAreas = this.customerProductData.ProductLogoMaxAreas;
                    productDetails.ProductLogoPositions = this.customerProductData.ProductLogoPositions;
                    if (this.customerProductData.CustomerImageURL) {
                      productDetails.Translations = this.customerProductData.CustomerTranslations;
                      productDetails.ImageURL = this.customerProductData.CustomerImageURL;
                    }
                    else {
                      productDetails.Translations = this.customerProductData.OriginalTranslations;
                      productDetails.ImageURL = this.customerProductData.OriginalImageURL;
                      productDetails.CustomerImageName = this.customerProductData.OriginalImageName;
                    }
                    if (this.customerProductData.CustomerImageName) {
                      productDetails.ImageName = this.customerProductData.CustomerImageName;
                    }
                    else {
                      productDetails.ImageName = this.customerProductData.OriginalImageName;
                    }
                    productDetails.ProductImages = this.customerProductData.CustomerProductImages.length > 0 ? this.customerProductData.CustomerProductImages : this.customerProductData.OriginalProductImages;
                    if (!checkOpenBook) {
                      productDetails.ProductSizes = [];
                    }
                    this.productDetails = productDetails;
                    this.checkPublishedStatus();
                    this.loadBasicInfo = true;
                    this.showLoader = false;
                    if (this.historyData.isClone && !productDetails.IsOpenBook) {
                      productDetails.InspectionFee = 0;
                      if (productDetails.ProductPrices.length > 0) {
                        productDetails.ProductPrices.forEach(element => {
                          element.Price = 0;
                          if (element.ProductPriceCustomerFees && element.ProductPriceCustomerFees.length > 0) {
                            element.ProductPriceCustomerFees.forEach(custPrice => {
                              custPrice.CustomerFeePercentage = 0;
                            });
                          }
                          element.ProductPrintPrices.forEach(productPrintPrice => {
                            productPrintPrice.HandlingCost = 0;
                            productPrintPrice.Price = 0;
                            productPrintPrice.ScreenChargesCost = 0;
                            productPrintPrice.SetupCost = 0;
                            productPrintPrice.ToolCost = 0;
                          });


                        });
                      }

                      if (productDetails.ProductAdditionalCosts.length > 0) {
                        productDetails.ProductAdditionalCosts.forEach(element => {
                          if (element.Price) {
                            element.Price = 0;
                          }
                          if (element.Prices.length > 0) {
                            element.Prices.forEach(price => {
                              price.Price = 0;
                            });
                          }
                        });
                      }

                      if (productDetails.ProductGovernmentalCosts.length > 0) {
                        productDetails.ProductGovernmentalCosts.forEach(element => {
                          if (element.Price) {
                            element.Price = 0;
                          }
                        });
                      }

                    }
                  }
                },
                  (error) => {
                    this.showLoader = false;
                  })
              }
              else {
                this.productDetails = productDetails;
                this.checkPublishedStatus();
                this.loadBasicInfo = true;
                this.showLoader = false;
                if (checkPro.EditableType === 'BOTH' && type === 'initial') {
                  this.confirmProduct.show();
                }
              }


            }
          },
            (error) => {
              this.toast.error(error.error.Message);
              this.showLoader = false;
            })
        }
      },
        (error) => {

        });


    }
  }
  getPro(){
    if (this.isParentcustomer) {
      sessionStorage.setItem("princeEdit", "false")
    }
  }

  openCustomerProduct() {
    if (this.isParentcustomer) {
      sessionStorage.setItem("princeEdit", "true")
    }
    this.showLoader = true;
    if (!history.state.isClone) {
      this.isOriginalProduct = false;
      this.customerProduct = true;
    }
    let checkOpenBook = true;
    // if (!this.productData.IsWebshopProduct) {
    const getWebshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    checkOpenBook = getWebshopDetails.IsOpenBook;
    if (!getWebshopDetails.IsOpenBook) {
      if (getWebshopDetails.ParentCustomerID === null || getWebshopDetails.ParentCustomerID === undefined) {
        checkOpenBook = true;
      }
    }
    // const loginData = this.apiService.getLoginData();
    const request = {
      customerID: this.productData.CustomerID ? this.productData.CustomerID : this.webshopDetail.CustomerID,
      productID: this.productData.ProductID
    }
    // this.productService.loadCustomerProduct(request);

    let productDetails: any = this.productDetails;
    // this.productDetails = {};
    this.productHttpService.getCustomerProductData(request).subscribe((cust: any) => {
      this.showLoader = false;
      if (cust) {
        // const getCurrentData = cust.filter(get => get.CustomerID === this.webshopDetail.CustomerID)
        this.confirmProduct.hide();

        this.customerProductData = cust;
        productDetails.ProductDiscountPercentage = this.customerProductData.ProductDiscountPercentage ? this.customerProductData.ProductDiscountPercentage : "";
        productDetails.ProductDiscountEndDate = this.customerProductData.ProductDiscountEndDate ? moment(this.customerProductData.ProductDiscountEndDate).format("YYYY/MM/DD") : productDetails.ProductDiscountEndDate;
        productDetails.UnitHeight = this.customerProductData.UnitHeight;
        productDetails.UnitWidth = this.customerProductData.UnitWidth;
        productDetails.UnitDepth = this.customerProductData.UnitDepth;
        productDetails.ProductLogoMaxAreas = this.customerProductData.ProductLogoMaxAreas;
        productDetails.ProductLogoPositions = this.customerProductData.ProductLogoPositions;
        if (this.customerProductData.CustomerImageURL) {
          productDetails.Translations = this.customerProductData.CustomerTranslations;
          productDetails.ImageURL = this.customerProductData.CustomerImageURL;
        }
        else {
          productDetails.Translations = this.customerProductData.OriginalTranslations;
          productDetails.ImageURL = this.customerProductData.OriginalImageURL;
          productDetails.CustomerImageName = this.customerProductData.OriginalImageName;
        }
        productDetails.ProductImages = this.customerProductData.CustomerProductImages.length > 0 ? this.customerProductData.CustomerProductImages : this.customerProductData.OriginalProductImages;
        if (!checkOpenBook) {
          productDetails.ProductSizes = [];
        }
        productDetails.isWebshopProduct = history.state.isClone ? true : false;
        this.productDetails = productDetails;
        this.checkPublishedStatus();
        this.loadBasicInfo = true;
        this.showLoader = false;
        // if (this.historyData.isClone && !productDetails.isWebshopProduct && !getWebshopDetails.IsOpenBook) {
        if (this.historyData.isClone && !productDetails.IsOpenBook) {
          productDetails.InspectionFee = 0;
          if (productDetails.ProductPrices.length > 0) {
            productDetails.ProductPrices.forEach(element => {
              if (element.Price) {
                element.Price = 0;
                if (element.ProductPriceCustomerFees.length > 0) {
                  element.ProductPriceCustomerFees.forEach(custPrice => {
                    custPrice.CustomerFeePercentage = 0;
                  });
                }
                element.ProductPrintPrices.forEach(productPrintPrice => {
                  productPrintPrice.HandlingCost = 0;
                  productPrintPrice.Price = 0;
                  productPrintPrice.ScreenChargesCost = 0;
                  productPrintPrice.SetupCost = 0;
                  productPrintPrice.ToolCost = 0;
                });
              }

            });
          }

          if (productDetails.ProductAdditionalCosts.length > 0) {
            productDetails.ProductAdditionalCosts.forEach(element => {
              if (element.Price) {
                element.Price = 0;
              }
              if (element.Prices.length > 0) {
                element.Prices.forEach(price => {
                  price.Price = 0;
                });
              }
            });
          }

          if (productDetails.ProductGovernmentalCosts.length > 0) {
            productDetails.ProductGovernmentalCosts.forEach(element => {
              if (element.Price) {
                element.Price = 0;
              }
            });
          }

        }
      }
    },
      (error) => {
        this.showLoader = false;
      })
  }

  checkPublishedStatus() {
    if (this.productDetails["VisibleDate"] !== undefined) {
      const data = this.productEditMenuTab.map(tab => {
        if (tab.id === "publish") {
          return { itemName: "Unpublish", id: 'publish', isActive: false }
        }
        return tab
      })
      this.productEditMenuTab = data
      this.isPublished = true
      this.showUpdateBtn = false;
    } else {
      const data = this.productEditMenuTab.map(tab => {
        if (tab.id === "publish") {
          return { itemName: "Publish", id: 'publish', isActive: false }
        }
        return tab
      })
      this.productEditMenuTab = data
      this.isPublished = false
      if (this.isPublished === false) {
        this.showContent = true;
        this.showUpdateBtn = true;
        if (this.customerProductData) {
          this.showContent = this.productData.IsUnpublishedByCust ? true : false;
        }
      }
    }
  }


  utcDateformat = (dt) => {
    const date = this.dateToMonth(dt)
    return new Date(date + " UTC").toISOString()
  }


  goBack() {
  }

  goNext() {
  }

  enableDateField() {
    this.productData.IsVisible = this.productData.IsVisible = true ? false : true;
  }

  doSave() {
    this.productService.saveProduct(this.productData);
    // this.showLoader = true;
    this.productService.getProductUpdateSuccess().subscribe(data => {
      if (data) {
        const searchData = { CurrencyID: 2, CustomerID: data.CustomerID, isAdmin: true };
        this.productService.loadProducts(searchData);
        this.router.navigate(['/admin-product'], { replaceUrl: false });
        // this.showLoader = false;
      }
    });
  }

  getAdditionInfo(additionalInfo) {
    this.additionalInfo = additionalInfo;
  }

  getProductStock(productStockValues) {
    // this.stockBalance
    this.productStockValues = productStockValues;
  }

  getProductColors(productColorsValues) {
    this.productColorsValues = productColorsValues;
  }

  getCategoryInfo(productCategoryValues) {
    this.productCategoryValues = productCategoryValues;
  }

  getProductImageDetails(productImageDetails) {
    this.productImageDetails = productImageDetails;
    // this.productImageDetails.Translations.forEach(element => {
    //   this.productDetails.Translations.forEach(ele=> {
    //     if(element.PropertyName === ele.PropertyName && element.LanguageCode === ele.LanguageCode){
    //       ele.PropertyValue = element.PropertyValue;
    //       ele.Action = 'Update';
    //     }
    //   });
    // });
  }

  getProductPropertiesDetails(productPropertyDetails) {
    this.productPropertyDetails = productPropertyDetails;
  }

  getBasicInfo(data) {
  }

  getBasicInfoData(basicInfoValues) {
    this.basicInfoValues = basicInfoValues.formData;
    this.translationData = basicInfoValues.translationData;
    this.loadBasicInfo = true;
    this.productService.getTransportationOriginCountries().subscribe((data) => {
      if (data && data.length > 0) {
        const getCountry = data.filter(cou => cou.Alpha2Code === this.basicInfoValues.transportationOriginCountries);
        if (getCountry.length > 0) {
          this.getCountryName = getCountry[0].Name;
        }
      }
    });
    // this.basicInfoValues.transportationOriginCountries
  }

  getproductPriceEditData(data) {
    const priceLoop = data;

    priceLoop.forEach(element => {
      if (element.ProductPriceCustomerFees !== undefined && element.ProductPriceCustomerFees.length > 0) {
        element.ProductPriceCustomerFees.forEach(cust => {
          if (cust.ChildCustomerFeePercentage || 'ChildCustomerFeePercentage' in cust) {
            cust.CustomerFeePercentage = cust.ChildCustomerFeePercentage;
            // delete cust.ChildCustomerFeePercentage;
          }
          if (cust.CustomerFeePercentage === undefined || cust.CustomerFeePercentage === '' || cust.CustomerFeePercentage === null) {
            cust.Action === 'Delete';
          }
          if (cust.Action === 'Delete') {
            cust.CustomerFeePercentage = 0;
          }
        });
      }
      if (element.Action === 'Insert') {
        delete element.linknetworkcost;
        delete element.supplierprofit;
        delete element.customerCost;
        if (element.ProductPriceCustomerFees !== undefined) {
          const customerFeefilter = element.ProductPriceCustomerFees.filter(custFee => custFee.Action === 'Insert' && (custFee.CustomerFeePercentage === undefined || custFee.CustomerFeePercentage === ''));

          if (customerFeefilter.length > 0 || element.ProductPriceCustomerFees.length === 0) {
            delete element.ProductPriceCustomerFees;
          }
        }

        if (element.ProductPriceSupplierProfits !== undefined) {
          const supplierFeeFilter = element.ProductPriceSupplierProfits.filter(suppFee => suppFee.Action === 'Insert' && (suppFee.SupplierProfitPercentage === undefined || suppFee.SupplierProfitPercentage === ''));
          if (supplierFeeFilter.length > 0 || element.ProductPriceSupplierProfits.length === 0) {
            delete element.ProductPriceSupplierProfits;
          }
        }

        if (element.ProductPriceLinkNetworkCommissions !== undefined) {
          const linkFeeFilter = element.ProductPriceLinkNetworkCommissions.filter(linkFee => linkFee.Action === 'Insert' && (linkFee.LinkNetworkCommissionPercentage === undefined || linkFee.LinkNetworkCommissionPercentage === ''));
          if (linkFeeFilter.length > 0 || element.ProductPriceLinkNetworkCommissions.length === 0) {
            delete element.ProductPriceLinkNetworkCommissions;
          }
        }

      }

      if (element.Action === 'Delete' && element.MaximumQuantity === 0) {
        element.MaximumQuantity = 1;
      }
    });
    this.getproductPriceEdit = data;
    // }
  }

  priceFormData(data) {
    this.priceFormValue = data;
  }

  dateFormatchange(getDate) {

    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    return myDate;
  }

  // getProductSizes(getsize) {
  //   this.sizeData = getsize;
  // }

  getLogoPosAreaData(posarea) {
    this.logoPositionArea = posarea;
  }
  getProductGovernmentalCosts(values) {
    this.productGovernmentalCosts = values;
  }

  getProductAdditionalCosts(values) {
    this.productAdditionalCosts = values;
  }

  getSizes(sizes) {
    this.sizeData = sizes;
  }

  cloneProductroductInfo() {
    this.cloneProduct.show();
  }

  publishProduct() {
    // this.cloneProduct.hide();  
    this.publishModal.show();
  }


  updateToInsert = (object) => {
    if (history.state.isClone) {
      const objectValue = { ...object }
      let update = true

      if (Object.keys(objectValue).length > 0) {
        for (let item in objectValue) {
          if (isNaN(parseInt(item))) {
            if (objectValue[item] instanceof Array && objectValue[item].length > 0) {
              objectValue[item] = this.insertForCloneObject(objectValue[item])
            }
            if (objectValue[item] instanceof Object) {
              objectValue[item] = this.updateToInsert(objectValue[item])
            }
          } else {
            update = false
          }
        }
        if (update) {
          return { ...objectValue, Action: 'Insert' }
        }
      }


    }
    return object
  }

  insertForCloneObject = (array) => {
    if (array instanceof Array && array.length > 0) {
      return array.map(this.updateToInsert)
    }
    return array
  }

  getdefaultLogotype(defaultlogotype) {
    this.defaultlogotype = defaultlogotype;
  }

  setDate() {
    this.displayPublishDate = this.getDateFormat(new Date())
    this.publishForm.setValue({
      visibleDate: this.displayPublishDate
    })
  }

  updateProductInfo(IsVisible?: any, toastStatus?) {

    //Category Validation
    if (this.productCategoryValues !== undefined) {
      const checkCategory = this.productCategoryValues.filter(cat => cat.Action !== "Delete")
      if (this.productCategoryValues.length === 0 || checkCategory.length === 0) {
        this.toast.error('Please select atleast one category');
        return false;
      }
    }

    this.showLoader = true;
    if (this.basicInfoValues !== undefined && this.basicInfoValues.groupId !== undefined) {
      const groupReqest = {
        "groupid": this.basicInfoValues.groupId,
        "productid": this.productData.ProductID,
      }
      this.productHttpService.addGroup(groupReqest).subscribe(res => {
        if (res) {

        }
      })
    }

    const action = history.state.isClone ? 'Insert' : 'Update';
    if (history.state.isClone) {
      this.productImageDetails.ProductImages.forEach(element => {
        element.Action = 'Insert'
      });
    }
    if (this.productDetails.ProductImages != undefined && this.productDetails.ProductImages.length > 0) {
      for (var productDetail of this.productDetails.ProductImages) {
        for (var productImg of this.productImageDetails.ProductImages) {
          if (productImg.Action == "Insert") {
            if (productDetail.ImageName === productImg.ImageName) {
              if (productDetail.Translations[0].TranslationID !== "" || productDetail.Translations[0].TranslationID !== undefined) {
                productImg.Action = "Update";
              }
            }
          }
        }
      }
    }

    if (this.translationData !== undefined) {
      this.translationData.forEach(translate => {
        this.productDetails.Translations.forEach((element, index) => {
          if (translate.LanguageID === element.LanguageID && translate.PropertyName === element.PropertyName) {
            if (this.productDetails.Translations[index]['PropertyValue'] !== translate.PropertyValue) {
              this.productDetails.Translations[index]['Action'] = 'Update';
            }
            this.productDetails.Translations[index]['PropertyValue'] = translate.PropertyValue;
          }
        });
      });
    }



    if (this.productImageDetails !== undefined) {
      this.productImageDetails.Translations.forEach(element => {
        this.productDetails.Translations.forEach(ele => {
          if (element.PropertyName === ele.PropertyName && element.LanguageCode === ele.LanguageCode) {
            ele.PropertyValue = element.PropertyValue;
            ele.Action = 'Update';
          }
        });
      });
    }



    let onlyInA = this.translationData.filter(this.comparer(this.productDetails.Translations));
    let onlyInB = this.productDetails.Translations.filter(this.comparer(this.translationData));
    const result = onlyInA.concat(onlyInB);
    let getProTrans = _.concat(result, this.productDetails.Translations);
    this.productDetails.Translations = getProTrans;
    this.productDetails.Translations = getProTrans.filter((value, index, self) => self.findIndex(v2 => (v2.TranslationID === value.TranslationID)) === index);
    const loginData = this.apiService.getLoginData();
    this.updateStatus = true;
    const updateRequest = {
      "ProductID": this.productData ? this.productData.ProductID : '',
      "SupplierID": this.basicInfoValues ? this.basicInfoValues.selectSupplier : '',
      "SupplierName": this.productData ? this.productData.SupplierName : '',
      "SupplierQualityLevel": this.productDetails.SupplierQualityLevel,
      "ProductIdManual": this.basicInfoValues ? this.basicInfoValues.manualId : '',
      "IsStockParentProductDiscontinued": this.productDetails.IsStockParentProductDiscontinued,
      "VisibleOnLinkNetwork": this.basicInfoValues ? this.basicInfoValues.visibleonlinknetwork : '',
      "MinimumOrder": this.basicInfoValues ? this.basicInfoValues.minimumOrder : '',
      "MinimumProductionOrder": this.basicInfoValues ? this.basicInfoValues.minimumProductionOrder : '',
      "DeliveryWeeksFrom": this.basicInfoValues ? this.basicInfoValues.deliveryWeeksFrom : '',
      "DeliveryWeeksTo": this.basicInfoValues ? this.basicInfoValues.deliveryWeeksTo : '',
      "ImageName": this.productImageDetails ? this.productImageDetails.ImageName : this.productDetails.ImageName,
      "ImageURL": this.productImageDetails ? this.productImageDetails.ImageURL : this.productDetails.ImageURL,
      "PiecesPerUnit": this.basicInfoValues ? this.basicInfoValues.piecesPerUnit : '',
      "MRP": this.basicInfoValues ? this.basicInfoValues.MRP : '',
      "ShippingPrice": this.basicInfoValues ? this.basicInfoValues.ShippingPrice : '',
      "RewardPoints": this.basicInfoValues ? this.basicInfoValues.RewardPoints : '',
      "ProductsCategoryID": this.basicInfoValues ? this.basicInfoValues.ProductsCategoryID : '',
      "FixedUnits": this.basicInfoValues ? this.basicInfoValues.fixedUnits : '',
      "UnitWeight": this.basicInfoValues ? this.basicInfoValues.unitWeight : '',
      "CurrencyID": this.priceFormValue ? this.priceFormValue.currency : '',
      "Brand": this.basicInfoValues ? this.basicInfoValues.brand : '',
      "PrintPricesCurrencyID": this.priceFormValue ? this.priceFormValue.pricecurrency : '',
      "CurrencyCode": this.productDetails.CurrencyCode,
      "CurrencySymbol": this.productDetails.CurrencySymbol,
      "AllowOrderSketch": this.priceFormValue ? this.priceFormValue.allowordersketch : '',
      "IsLogoDisabled": this.productDetails.IsLogoDisabled,
      "IsSampleWithoutLogoDisabled": this.productDetails.IsSampleWithoutLogoDisabled,
      "IsSampleWithLogoDisabled": this.productDetails.IsSampleWithLogoDisabled,
      "SketchPrice": this.priceFormValue ? this.priceFormValue.sketchPrice : '',
      "SampleWithLogoPrice": this.priceFormValue ? this.priceFormValue.samplewithlogoprice : '',
      "SampleWithoutLogoPrice": this.priceFormValue ? this.priceFormValue.samplewithoutlogoprice : '',
      "AllowOrderSampleWithoutLogo": this.priceFormValue ? this.priceFormValue.samplewithoutlogo : false,
      "AllowOrderSampleWithLogo": this.priceFormValue ? this.priceFormValue.samplewithlogo : false,
      "isInternalStockProduct": this.basicInfoValues ? this.basicInfoValues.internalStockProduct : '',
      "VATPercentageToInclude": this.productDetails.VATPercentageToInclude,
      "StockBalance": this.productStockValues ? this.productStockValues.stockBalance : "",
      "StockBalanceAlert": this.productStockValues ? this.productStockValues.stockBalanceAlert : "",
      "isIndexedBySearchEngines": this.basicInfoValues ? this.basicInfoValues.indexedBySearchEngine : '',
      "ProductUnitID": this.basicInfoValues ? this.basicInfoValues.unitType : null,
      "ProductUnitName": this.basicInfoValues ? this.basicInfoValues.unitType : null,
      "CreationDate": this.productDetails.CreationDate,
      "CreatedByUserID": this.productDetails.CreatedByUserID,
      "CreatedByUserName": this.productDetails.CreatedByUserName,
      "CreatedByCustomerID": this.productDetails.CreatedByCustomerID,
      "LastUpdateDate": new Date().toISOString(),
      "LastUpdatedByUserID": loginData.UserID,
      "LastUpdatedByUserName": loginData.UserName,
      "TotalLikedQuantity": this.productDetails.TotalLikedQuantity,
      "TotalNumberOfLikes": this.productDetails.TotalNumberOfLikes,
      "DisablePrices": this.productDetails.DisablePrices,
      "DisableStockData": false,
      "isProductImported": false,
      "isExpressOrderEnabled": this.basicInfoValues ? this.basicInfoValues.enableExpressOrder : '',
      "isTransportationFree": this.basicInfoValues ? this.basicInfoValues.transportationcostfree : '',
      "DisableTransportationCostCalculation": this.basicInfoValues ? this.basicInfoValues.transportationcostmanually : '',
      "isTransportationHandlingCostFree": this.basicInfoValues ? this.basicInfoValues.handlingcostfree : '',
      "TransportationOriginCountryCode": this.basicInfoValues ? this.basicInfoValues.transportationOriginCountries : '',
      "TransportationOriginCountryName": this.getCountryName,
      "isVolumeItem": this.basicInfoValues ? this.basicInfoValues.volumeitem : '',
      "EnableConvertToBatch": this.productDetails.EnableConvertToBatch,
      "isLockedDeliveryDate": this.productDetails.isLockedDeliveryDate,
      "isMarketingProduct": this.productDetails.isMarketingProduct,
      "ProductPrices": this.insertForCloneObject(this.getproductPriceEdit),
      "ProductImages": this.productImageDetails ? this.productImageDetails.ProductImages : '',
      "ProductSizes": this.insertForCloneObject(this.sizeData),
      "ProductColors": this.insertForCloneObject(this.productColorsValues),
      "ProductProperties": this.productPropertyDetails ? this.insertForCloneObject(this.productPropertyDetails) : [],
      "ProductBatchID": this.productDetails.ProductBatchID,
      "ProductCustomers": this.productDetails.ProductCustomers,
      "ProductCategories": this.productCategoryValues ? this.insertForCloneObject(this.productCategoryValues) : [],
      "ProductAdditionalCosts": this.productAdditionalCosts ? this.insertForCloneObject(this.productAdditionalCosts.additionalCostValue) : [],
      "ProductGovernmentalCosts": this.productGovernmentalCosts ? this.insertForCloneObject(this.productGovernmentalCosts) : [],
      "ProductLogoPositions": this.logoPositionArea ? this.insertForCloneObject(this.logoPositionArea.position) : [],
      "ProductLogoMaxAreas": this.logoPositionArea ? this.insertForCloneObject(this.logoPositionArea.area) : [],
      "ProductLogoTypes": [],
      "ProductRelatedProducts": [],
      "ProductFutureStock": this.productStockValues ? this.insertForCloneObject(this.productStockValues.futurestock) : [],
      "ProductRoles": [],
      "ProductTags": [],
      "Translations": this.productDetails ? this.insertForCloneObject(this.productDetails.Translations) : [],
      "isDeleted": this.basicInfoValues ? this.basicInfoValues.isDeleted : '',
      "SelectedCategory": 1168,
      // "OnStockFromDate": this.productStockValues.onStockFromDate ? this.dateFormatchange(this.productStockValues.onStockFromDate) : "",
      // "NextShipmentDate": this.productStockValues.nextShipmentDate ? this.dateFormatchange(this.productStockValues.nextShipmentDate) : "",
      "ProductDiscountPercentage": this.basicInfoValues ? this.basicInfoValues.productDiscountPercentage : "",
      "LinkToVideo": this.basicInfoValues ? this.basicInfoValues.linkToVideo : "",
      "UnitHeight": this.basicInfoValues ? this.basicInfoValues.unitHeight : "",
      "UnitWidth": this.basicInfoValues ? this.basicInfoValues.unitWidth : "",
      "UnitDepth": this.basicInfoValues ? this.basicInfoValues.unitDepth : "",
      "UnitsPerCarton": this.basicInfoValues ? this.basicInfoValues.unitsPerCarton : "",
      "CartonHeight": this.basicInfoValues ? this.basicInfoValues.cartonHeight : "",
      "CartonWidth": this.basicInfoValues ? this.basicInfoValues.cartonWidth : "",
      "CartonDepth": this.basicInfoValues ? this.basicInfoValues.cartonDepth : "",
      "CartonWeight": this.basicInfoValues ? this.basicInfoValues.cartonWeight : "",
      "UnitsOnPallet": this.basicInfoValues ? this.basicInfoValues.unitsOnPallet : "",
      "UndersupplierProductID": this.basicInfoValues ? this.basicInfoValues.underSupplierProductID : "",
      "UndersupplierID": this.basicInfoValues ? this.basicInfoValues.underSupplier : "",
      'InspectionFee': this.productAdditionalCosts ? this.productAdditionalCosts.InspectionFee : '',
      "Action": action,
      "DefaultLogoPositionID": this.productDetails ? this.productDetails.DefaultLogoPositionID : '',
      "DefaultLogoMaxAreaID": this.productDetails ? this.productDetails.DefaultLogoMaxAreaID : '',
      "HSCode": this.basicInfoValues ? this.basicInfoValues.hscode : '',
      // "ProductGroupID": this.basicInfoValues.groupId,
      "ProductGroupName": this.basicInfoValues ? this.basicInfoValues.groupName : '',
      "WarehouseID": this.basicInfoValues ? this.basicInfoValues.warehouse : '',
      "DefaultLogoTypeID": this.defaultlogotype,
      "DisablePriceDropNotification": this.basicInfoValues ? this.basicInfoValues.disablePriceDropNotification : false,
      "NewCurrencyID": this.basicInfoValues.currencyID,
      "NewCountryID": this.basicInfoValues.countryID
    }
    if (this.isParentcustomer) {
      const data = JSON.parse(sessionStorage.getItem("princepipeEdit"))
      let values;
      if(data){
        values = data;
      }
      updateRequest["Instructions"] = values && values.instruction ? values.instruction : "";
      updateRequest["StoreLocator"] = values && values.storeLoc ? values.storeLoc : "";
      updateRequest["TandC"] = values && values.tandc ? values.tandc : "";
      updateRequest["HowtoUse"] = values && values.howtouse ? values.howtouse : "";
      updateRequest["RequestDate"] = values && values.requestDate ? values.requestDate : "";
    }

    if (history.state.isClone) {
      if (this.productDetails.ProductCustomers !== undefined) {
        if (this.productDetails.ProductCustomers.length > 0) {
          this.productDetails.ProductCustomers.forEach(cust => {
            cust.isApproved = false;
            if (IsVisible == 'publish') {
              cust['isOriginalProductPublished'] = true;
            }
            else if (IsVisible == 'unpublish') {
              cust['isOriginalProductPublished'] = false;
            }
          });
        }
      }
    }


    if (this.productDetails.ParentProductID) {
      updateRequest['ParentProductID'] = this.productDetails.ProductID;
    }

    if (history.state.isClone) {
      if (updateRequest.ProductCustomers) {
        if (updateRequest.ProductCustomers.length > 0) {
          updateRequest.ProductCustomers.forEach(custClone => {
            custClone.Action = 'Insert';
          });
        }
      }
      updateRequest['ClonedFromProductIdManual'] = this.productDetails.ProductIdManual;
      updateRequest['ClonedFromSupplierID'] = this.productDetails.SupplierID;
      if (history.state.isSibling) {
        updateRequest['ParentProductID'] = this.productDetails.ProductID;
      }

      // if (!history.state.isSibling) {
      //   updateRequest['ClonedFromProductIdManual'] = this.productDetails.ProductIdManual;
      //   updateRequest['ClonedFromSupplierID'] = this.productDetails.SupplierID;
      // }
      // else {
      //   updateRequest['ParentProductID'] = this.productDetails.ProductID;
      // }
    }

    // if(history.state.isSibling !== undefined && history.state.isSibling){
    //   updateRequest['ParentProductID'] = ''
    // }

    if (this.productImageDetails) {
      if (this.productImageDetails.ImageURL !== '') {
        updateRequest["ImageURL"] = this.productImageDetails ? this.productImageDetails.ImageURL : '';
      }
    }

    if (this.basicInfoValues) {
      if (this.basicInfoValues.discontinuedDate) {
        updateRequest["DiscontinuedDate"] = this.basicInfoValues ? this.dateFormatchange(this.basicInfoValues.discontinuedDate) : "";
      }
      if (this.basicInfoValues.updateReminderDate) {
        updateRequest["UpdateReminderDate"] = this.basicInfoValues ? this.dateFormatchange(this.basicInfoValues.updateReminderDate) : "";
      }
      if (this.basicInfoValues.productNewEndDate) {
        updateRequest["ProductNewEndDate"] = this.basicInfoValues ? this.dateFormatchange(this.basicInfoValues.productNewEndDate) : "";
      }
      if (this.basicInfoValues.productFeaturedEndDate) {
        updateRequest["ProductFeaturedEndDate"] = this.basicInfoValues ? this.dateFormatchange(this.basicInfoValues.productFeaturedEndDate) : "";
      }
      if (this.basicInfoValues.productDiscountEndDate) {
        updateRequest["ProductDiscountEndDate"] = this.basicInfoValues ? this.dateFormatchange(this.basicInfoValues.productDiscountEndDate) : "";
      }

      if (this.basicInfoValues.setSearchManualId !== null && this.basicInfoValues.setSearchManualId !== undefined && this.basicInfoValues.setSearchManualId !== '') {
        updateRequest["StockParentProductID"] = this.basicInfoValues.setSearchManualId;
        updateRequest["ProductFutureStock"] = [];
      }
      else {
        updateRequest["StockParentProductID"] = '';
      }
      if (this.basicInfoValues.setSearchManualName !== null && this.basicInfoValues.setSearchManualName !== undefined) {
        updateRequest["StockParentProductIdManual"] = this.basicInfoValues.setSearchManualName;
      }

      if (this.basicInfoValues.setSearchIspProductProductManualId !== null && this.basicInfoValues.setSearchIspProductProductManualId !== undefined && this.basicInfoValues.setSearchIspProductProductManualId !== '') {
        updateRequest["ProductionProductIdManual"] = this.basicInfoValues.setSearchIspProductProductManualName;
        updateRequest["ProductionProductID"] = this.basicInfoValues.setSearchIspProductProductManualId;
      }
    }

    if (this.priceFormValue && this.priceFormValue.withLogoDeliveryDays !== null && this.priceFormValue.samplewithlogo) {
      updateRequest["SampleWithLogoDeliveryDays"] = this.priceFormValue.withLogoDeliveryDays;
    }

    if (this.priceFormValue && this.priceFormValue.withoutLogoDeliveryDays !== null && this.priceFormValue.samplewithoutlogo) {
      updateRequest["SampleWithoutLogoDeliveryDays"] = this.priceFormValue.withoutLogoDeliveryDays;
    }

    if (IsVisible === "publishForm") {
      updateRequest["VisibleDate"] = this.YYYYMMDD(this.publishForm.value.visibleDate)
      updateRequest["NewVisibleDate"] = this.utcDateformat(this.publishForm.value.visibleDate)
      this.productDetails["VisibleDate"] = this.YYYYMMDD(this.publishForm.value.visibleDate)
    }
    if (this.basicInfoValues.internalStockProduct !== null && this.basicInfoValues.internalStockProduct !== undefined && this.basicInfoValues.internalStockProduct === false) {
      updateRequest["WarehouseID"] = null;
    }

    // if (this.productImageDetails && this.productImageDetails.Translations.length > 0) {
    //   updateRequest.Translations.push(...this.productImageDetails.Translations);
    // }
    // this.productService.updateProduct(updateRequest);
    // this.showLoader = true;
    // this.productService.getProductUpdateSuccess().subscribe((data) => {
    //   this.showLoader = false
    //   if (data !== null) {
    //     if (data === true) {
    //       this.toast.success('Product updated successfully');
    //     }
    //   }
    // })
    updateRequest.ProductID = history.state.isClone ? '' : updateRequest.ProductID;

    // if(history.state.isSibling){
    //   updateRequest["ProductFutureStock"] = [];
    // }



    if (history.state.isClone && !this.isOriginalProduct) {
      const getProID = sessionStorage.getItem('productCustomerID');
      if (updateRequest.ProductCustomers.length > 0) {
        updateRequest.CreatedByCustomerID = Number(getProID);
        updateRequest.ProductCustomers = updateRequest.ProductCustomers.filter(getdata => getdata.CustomerID === Number(getProID));
        if (updateRequest.ProductCustomers.length > 0) {
          updateRequest.ProductCustomers[0]['Action'] = 'Insert';
        }
      }
      if (updateRequest.ProductCategories.length > 0) {
        updateRequest.ProductCategories = updateRequest.ProductCategories.filter(getdata => getdata.CustomerID === Number(getProID));
      }
    }

    this.productHttpService.updateProduct(updateRequest).subscribe((data) => {
      if (this.showCloneBtn) {
        if (history.state.isClone === true) {
          this.toast.success('Copy product created successfully');
          this.router.navigate(['/admin-product-list'], { replaceUrl: true });
          this.showUpdateBtn = true;
        }
      }
      if (this.showSiblingBtn) {
        if (history.state.isSibling === true) {
          this.toast.success('Sibling product created successfully');
          this.router.navigate(['/admin-product-list'], { replaceUrl: true });
          this.showUpdateBtn = true;
        }
      }
      if (data) {
        if (toastStatus !== 'hideToast') {
          this.toast.success('Product updated successfully');
        }
        this.publishModal.hide();
        this.unpublishWarningModal.hide()
        this.checkPublishedStatus();
        this.getProductDetails();
        this.loadBatch();
        this.loadImages = true;
        this.loadApi.productImages = true;
        this.loadApi.additionalgovernmental = true;
        this.loadApi.colors = true;
        this.loadApi.sizes = true;
        this.loadApi.productStock = true;
        this.showUpdateBtn = true;
        if (IsVisible && IsVisible !== 'unpublish' || IsVisible === 'exit') {
          this.router.navigate(['/admin-product-list'], { replaceUrl: true });
        }

        localStorage.removeItem('dataSource');
        this.showLoader = false;

      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false
        let errMsg = 'The request is invalid. ';
        for (var key in error.error.ModelState) {
          errMsg += error.error.ModelState[key].toString() + ' ';
        }
        this.toast.error(errMsg);
        this.unpublishWarningModal.hide();
        this.cloneProduct.hide();
        this.publishModal.hide();
      })

  }

  unpublishWarning(data) {
    let className = data.srcElement.className != undefined ? data.srcElement.className : '';
    let outerText = data.path != undefined ? data.path[0]['outerText'] : '';
    if (data.srcElement.tagName != "DIV" && data.srcElement.tagName != "A" && !this.historyData.isClone && !className.includes('fa fa-eye') && !className.includes('modal fade') && outerText != "×") {
      // if (this.customerProductData) {
      //   return false;
      // }
      if (this.productData.IsOriginalProductPublished && !this.customerProductData) {
        this.unpublishWarningModal.show()
      }
    }
  }


  changeVisibility = (push: any) => {
    const userID = this.apiService.getLoginData().UserID
    this.productService.loadChangeVisibility({ ...this.productDetails, userID });
    this.productService.getChangeVisibility().subscribe((data) => {
      if (data) {
        this.toast.success('Product updated successfully');
        if (push) {
          this.pushProductList()
          this.unpublishWarningModal.hide()
        } else {
          this.unpublishWarningModal.hide()
          this.getProductDetails();
          // this.pushProductList()
        }
      }
    })
  }

  // getProductPricedata(data) {
  //   this.printpricedata = data;
  // }

  setActive(val) {
    if (val.id === "producttobatch") {
      this.batchproduct.show();
      return false;
    }
    if (val.id === 'categoryInfo') {
      this.catloadStatus = true;
    }
    if (val.id === 'publish') {
      if (this.isPublished) {
        this.changeVisibility(true)
      } else {
        this.isVisibleError = false
        this.displayPublishDate = this.getDateFormat(new Date())
        this.publishForm.setValue({
          visibleDate: this.displayPublishDate
        })
        this.publishModal.show()
      }
    }

    if (!(val.id === 'publish')) {
      this.loadApi[val.id] = true
      this.productEditMenuTab.forEach(element => {
        element.isActive = false;
        this.isNextBtnDisabaled = false;
      });
      val.isActive = true;
      this.activePage = val.id;
      let currentPageIndex = this.productEditTab.findIndex((x: any) => x === val.id);
      this.currentTab = { itemName: val.id, Position: currentPageIndex };
    }
  }

  nextTab() {
    this.currentTab.Position = this.currentTab.Position + 1;
    let nextIndex = this.currentTab.Position;
    if (nextIndex === this.productEditTab.length) {
      this.isNextBtnDisabaled = true;
    }
    else {
      this.isNextBtnDisabaled = false;
      this.activePage = this.productEditTab[nextIndex];
      this.currentTab = { itemName: 'basicinfo', Position: nextIndex };
      this.productEditMenuTab.forEach(element => {
        if (element.id == this.activePage) {
          if ((this.activePage === 'productImages') && (this.historyData.isClone || this.historyData.isClone)) {
            element.isActive = false;
            this.activePage = 'productPrices';
            this.loadApi['productPrices'] = true
          }
          else {
            this.loadApi[element.id] = true
            element.isActive = true;
          }
        } else {
          element.isActive = false;
        }
      });
    }
  }

  previousTab() {
    this.currentTab.Position = this.currentTab.Position - 1;
    let prevIndex = this.currentTab.Position;
    if (prevIndex === 0) {
      this.activePage = this.productEditTab[prevIndex];
      this.productEditMenuTab.forEach(element => {
        if (element.id == this.activePage) {
          this.loadApi[element.id] = true
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      });
    }
    else {
      this.isNextBtnDisabaled = false;
      this.activePage = this.productEditTab[prevIndex];
      this.currentTab = { itemName: 'basicinfo', Position: prevIndex };
      this.productEditMenuTab.forEach(element => {
        if (element.id == this.activePage) {
          if ((this.activePage === 'productImages') && (this.historyData.isClone || this.historyData.isClone)) {
            element.isActive = false;
            this.activePage = 'categoryInfo';
            this.loadApi['categoryInfo'] = true
          }
          else {
            this.loadApi[element.id] = true
            element.isActive = true;
          }
        } else {
          element.isActive = false;
        }
      });
    }
  }

  // nextTab() {
  //   if(this.activePage === "basicinfo"){
  //     this.currentTab.Position = 0;
  //   }
  //   let nextIndex = this.currentTab.Position + 1;
  //   if (nextIndex === this.productEditTab.length) {
  //     this.isNextBtnDisabaled = true;
  //   }
  //   else {
  //     this.isNextBtnDisabaled = false;
  //     this.activePage = this.productEditTab[nextIndex];
  //     this.currentTab = { itemName: 'basicinfo', Position: nextIndex };
  //     this.productEditMenuTab.forEach(element => {
  //       if (element.id == this.activePage) {
  //         this.loadApi[element.id] = true
  //         element.isActive = true;
  //       } else {
  //         element.isActive = false;
  //       }
  //     });
  //   }
  // }
  // previousTab() {
  //   let prevIndex = this.currentTab.Position - 1;
  //   if (prevIndex === 0) {
  //     this.activePage = this.productEditTab[prevIndex];
  //     this.productEditMenuTab.forEach(element => {
  //       if (element.id == this.activePage) {
  //         this.loadApi[element.id] = true
  //         element.isActive = true;
  //       } else {
  //         element.isActive = false;
  //       }
  //     });
  //   }
  //   else {
  //     this.isNextBtnDisabaled = false;
  //     this.activePage = this.productEditTab[prevIndex];
  //     this.currentTab = { itemName: 'basicinfo', Position: prevIndex };
  //     this.productEditMenuTab.forEach(element => {
  //       if (element.id == this.activePage) {
  //         this.loadApi[element.id] = true
  //         element.isActive = true;
  //       } else {
  //         element.isActive = false;
  //       }
  //     });
  //   }
  // }


  pushProductList() {
    window.scroll(0, 0);
    if (history.state.data !== undefined) {
      if (history.state.data.dashboardRedirectStatus) {
        this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
      } else {
        this.router.navigate(['/admin-product-list'], { replaceUrl: false });
      }
    } else {
      this.router.navigate(['/admin-product-list'], { replaceUrl: false });
    }
  }

  convertToBatchProduct() {
    const request = this.productDetails.ProductID;
    this.productHttpService.convertToBatchProduct(request).subscribe((data) => {
      if (data) {
        this.toast.success('Product converted to batch');
        this.pushProductList();
      }
    })
  }
  setUpdate() {
    this.autoUpate = true
    this.updateProductInfo()
  }

  getFixedLogoData(event) {
    this.fixedLogoData = event;
  }

  dateToMonth(date: String) {
    if (date !== undefined && date !== null) {
      const val = date.split('/');
      return [val[1], val[0], val[2]].join('-')
    }
  }

  updateCustomerProduct(status?, toastStatus?) {

    this.showLoader = true;
    this.translationData.forEach(element => {
      element.Action = 'Update';
    });
    if (this.basicInfoValues.productDiscountEndDate != undefined && this.basicInfoValues.productDiscountEndDate != "") {
      let getDate = this.dateToMonth(this.basicInfoValues.productDiscountEndDate);
      let d = new Date(getDate);
      let productDiscountDate: any = d.setDate(d.getDate() + 1);
      this.basicInfoValues.productDiscountEndDate = new Date(productDiscountDate).toISOString();
    }

    if (this.getByePassStatus && this.webshopDetail.ParentCustomerID) {
      this.getproductPriceEdit.forEach(element => {
        if (element.ProductPriceCustomerFees !== undefined && element.ProductPriceCustomerFees.length > 0) {
          element.ProductPriceCustomerFees.forEach(cust => {
            if (cust.ChildCustomerFeePercentage || 'ChildCustomerFeePercentage' in cust) {
              cust.CustomerFeePercentage = cust.ChildCustomerFeePercentage;
              // delete cust.ChildCustomerFeePercentage;
            }
            if (cust.CustomerFeePercentage === undefined || cust.CustomerFeePercentage === '' || cust.CustomerFeePercentage === null) {
              cust.Action === 'Delete';
            }
            if (cust.Action === 'Delete') {
              cust.CustomerFeePercentage = 0;
            }
          });
        }
      });
    }


    if (this.productDetails.ProductImages != undefined && this.productDetails.ProductImages.length > 0) {
      for (var productDetail of this.productDetails.ProductImages) {
        for (var productImg of this.productImageDetails.ProductImages) {
          if (productImg.Action == "Insert") {
            if (productDetail.ImageName === productImg.ImageName) {
              if (productDetail.Translations[0].TranslationID !== "" || productDetail.Translations[0].TranslationID !== undefined) {
                productImg.Action = "Update";
              }
            }
          }
        }
      }
    }


    const updateRequest = {
      "CustomerID": this.customerProductData.CustomerID,
      "ProductID": this.customerProductData.ProductID,
      "SupplierID": this.customerProductData.SupplierID,
      "ProductIdManual": this.customerProductData.ProductIdManual,
      "CustomerImageName": this.productImageDetails ? this.productImageDetails.ImageName : this.productDetails.ImageName,
      "CustomerImageURL": this.productImageDetails ? this.productImageDetails.ImageURL : this.productDetails.ImageURL,
      "OriginalImageName": this.customerProductData.OriginalImageName,
      "OriginalImageURL": this.customerProductData.OriginalImageURL,
      "IsLogoDisabled": this.basicInfoValues.disableLogo ? this.basicInfoValues.disableLogo : false,
      "IsSampleWithoutLogoDisabled": this.basicInfoValues.customerWithOutLogo ? this.basicInfoValues.customerWithOutLogo : false,
      "IsSampleWithLogoDisabled": this.basicInfoValues.customerWithLogo ? this.basicInfoValues.customerWithLogo : false,
      "CustomerProductFixedLogos": this.fixedLogoData,
      "CustomerProductImages": this.productImageDetails ? this.productImageDetails.ProductImages : '',
      "OriginalProductImages": this.customerProductData.OriginalProductImages,
      "CustomerTranslations": this.translationData,
      "OriginalTranslations": this.customerProductData.OriginalTranslations,
      "ProductPrintPrices": this.customerProductData.ProductPrintPrices,
      "ProductLogoPositions": this.customerProductData.ProductLogoPositions,
      "ProductLogoMaxAreas": this.customerProductData.ProductLogoMaxAreas,
      "ProductLogoTypes": this.customerProductData.ProductLogoTypes,
      "ImageURL": this.productImageDetails ? this.productImageDetails.ImageURL : this.productDetails.ImageURL,
      "Translations": this.customerProductData.Translations,
      "HSCode": this.basicInfoValues.hscode,
      "UnitDepth": this.basicInfoValues.unitDepth,
      "UnitHeight": this.basicInfoValues.unitHeight,
      "UnitWidth": this.basicInfoValues.unitWidth,
      "ProductPrices": this.insertForCloneObject(this.getproductPriceEdit),
      "ProductDiscountPercentage": this.basicInfoValues.productDiscountPercentage,
      "ProductDiscountEndDate": this.basicInfoValues.productDiscountEndDate,
    };
    if (this.isParentcustomer) {
      const data = JSON.parse(sessionStorage.getItem("princepipeEdit"))
      let values;
      if(data){
        values = data;
      }
      updateRequest["Instructions"] = values && values.instruction ? values.instruction : "";
      updateRequest["StoreLocator"] = values && values.storeLoc ? values.storeLoc : "";
      updateRequest["TandC"] = values && values.tandc ? values.tandc : "";
      updateRequest["HowtoUse"] = values && values.howtouse ? values.howtouse : "";
      updateRequest["RequestDate"] = values && values.requestDate ? values.requestDate : "";
    }


    this.productHttpService.customerProduct(updateRequest).subscribe(data => {
      this.showLoader = false;
      if (data) {
        if (!this.checkCustomerStatus) {
          this.productData.IsUnpublishedByCust = 1;
          this.checkCustomerStatus = true;
        }
        this.openCustomerProduct();
        if (toastStatus !== 'hideToast') {
          this.toast.success('Customer product updated succesfully');
        }
        if (status === 'exit') {
          this.router.navigate(['/admin-product-list'], { replaceUrl: true });
        }
      }
    },
      (error) => {
        this.showLoader = false;
      })
  }

  viewOriginalPopup() {
    this.vieworiginaldataPopup.show();
  }

  customerPublish(status) {
    this.showLoader = true;
    const url = {
      CustomerID: this.customerProductData.CustomerID,
      productID: this.customerProductData.ProductID
    }
    const request = {
      isUnpublishedByCustomer: status
    }

    this.productHttpService.customerPublish(url, request).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        // alert(data)
        // this.getProductDetails();
        this.isOriginalProduct = false;
        this.productData.IsUnpublishedByCust = status;
        if (status) {
          this.toast.success('Product unpublish successfully');
        }
        else {
          this.router.navigate(['/admin-product-list'], { replaceUrl: true });
          this.toast.success('Product publish successfully');
        }
        // this.router.navigate(['/admin-product-list'], { replaceUrl: true });
      }
    },
      (error) => {
        this.showLoader = false;
      })
  }

  publishOriginalProduct() {
    this.setDate();
    this.publishModal.show();
  }

  returnPage() {
    if (this.historyData.orderDetailData) {
      let orderData: any = this.historyData.orderDetailData;
      let lineId = '';
      let subOrderID = orderData.subOrderID;
      if (orderData && orderData.returnPage &&
        orderData.returnPage === 'deletedOrders') {
        this.router.navigate([`/admin-order-process/deletedOrders/${orderData.orderID}`],
          { replaceUrl: false });
      } else {
        this.router.navigate([`/admin-order-process/orderProcess/${orderData.orderID}/${orderData.subOrderID}/${0}`], { state: { lineId, subOrderID }, replaceUrl: false });
      }
    }
    else if (this.historyData.dashboardPage) {
      this.router.navigate(['/admin-dashboard']);
    }
    else if (this.historyData.productionLine) {
      let customerID = this.historyData.productionLine.customerID;
      this.router.navigate([`/admin-order-production-line-details/${this.historyData.data.ProductID}/false`], { state: { customerID }, replaceUrl: false });
    }
    else {
      this.router.navigate(['/admin-product-list'], { replaceUrl: false });
    }

  }

  comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.PropertyName == current.PropertyName && other.LanguageID == current.LanguageID
      }).length == 0;
    }
  }

  publishUnpublishOPCP(type?) {
    const getCustID = sessionStorage.getItem('productCustomerID');
    const request = {
      customerID: getCustID ? getCustID : this.productData.customerID,
      productID: this.productData.ProductID,
      isOriginalPro: this.isOriginalProduct
    }


    this.productHttpService.publishUnpublishOPCP(request).subscribe(data => {
      if (data) {
        if (this.isOriginalProduct) {
          this.productData.IsOriginalProductPublished = !this.productData.IsOriginalProductPublished;
          let showMsg = this.productData.IsOriginalProductPublished ? 'Product published successfully' : 'Product unpublished successfully';
          if (this.productData.IsOriginalProductPublished) {
            this.updateProductInfo('', 'hideToast');
            this.goToList();
          }
          this.toast.success(showMsg);
        }
        else {
          if (this.productData.IsUnpublishedByCust === 1) {
            this.productData.IsUnpublishedByCust = 0;
          }
          else if (this.productData.IsUnpublishedByCust === 0) {
            this.productData.IsUnpublishedByCust = 1;
          }
          let showMsg = '';
          if (this.productData.IsUnpublishedByCust === 0) {
            this.updateCustomerProduct('', 'hideToast');
            this.goToList();
            showMsg = 'Product published successfully';
          }
          if (this.productData.IsUnpublishedByCust === 1) {
            showMsg = 'Product unpublished successfully';
          }
          this.toast.success(showMsg);
        }

      }
    },
      (error) => {
        let errMsg = '';
        for (var key in error.error.ModelState) {
          errMsg += error.error.ModelState[key].toString() + ' ';
        }

        if (errMsg == 'Unpublish original product to publish customer product ' || 'Unpublish customer product to publish original product ') {
          this.publishErrMsg = errMsg;
          this.publishErrorModal.show();
        }
        else {
          this.toast.error(errMsg);
        }
      })
  }

  proceedPubUnpub(publishErrMsg) {
    const getCustID = sessionStorage.getItem('productCustomerID');
    const request = {
      customerID: getCustID ? getCustID : this.productData.customerID,
      productID: this.productData.ProductID,
      isOriginalPro: !this.isOriginalProduct
    }
    if (publishErrMsg == 'Unpublish original product to publish customer product ' || publishErrMsg === 'Unpublish customer product to publish original product ') {
      this.productHttpService.publishUnpublishOPCP(request).subscribe(data => {
        if (data) {
          this.publishErrorModal.hide();
          this.publishUnpublishOPCP();
        }
      });
    }
  }

  goToList() {
    this.router.navigate(['/admin-product-list'], { replaceUrl: true });
  }

  pubUnpubPro(type) {
    this.updateProductInfo(type)
  }

}
