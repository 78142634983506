import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';


@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit, OnChanges {
  @Input() emitReset = true;
  @Input() autoSearch = true;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input() filterFormField: any[];
  @Input() isBulkEditSelectedForDelete: any[];
  @Output() getSelectedData = new EventEmitter<any>();
  @Output() formSubmit = new EventEmitter<any>();
  @Output() formReset = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() importantAction = new EventEmitter<any>();
  @Output() filterresult = new EventEmitter<any>();
  public filterFormFieldData: any[];
  @Output() selectType = new EventEmitter<any>();
  @Input() removedFilter: any;
  columnSplit: any;
  dynamicClass: boolean = true;
  submitFilter: boolean = false;
  resetFilter: boolean = false;
  // checkboxStatus: boolean = false;
  selectedData: any = {};
  deleteaction: any = {};
  getdeletedata: any = {};
  getFilterData: any;
  formArray: any = [];
  isButtonDisabled = true;
  isShowWebshopDropdown = true;
  webshopDetail: any;
  constructor(private apiService: ApiService, public toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {

    // this.columnSplit = ' col-md-' + 12 / this.filterFormField.length;
    this.formArray = this.filterFormField;
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }
    // tslint:disable-next-line:max-line-length
    this.isShowWebshopDropdown = (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) ? this.webshopDetail.IsWebshopDropdown : true;
    if (!isParentCustomer) {
      this.isShowWebshopDropdown = false;
    }
  }

  ngOnChanges() {
    this.formArray = this.filterFormField;
    this.formArray.forEach(element => {

      if (element.selected === '' && this.selectedData[element.filterkey] !== undefined) {
        this.selectedData[element.filterkey].value = element.selected;
      }
      if (element.required && (element.selected === '')) {
        this.isButtonDisabled = false;
      }
    });
    if (this.autoSearch) {
      this.getSelectedValueInTheBeginning();
    }
  }

  removeFilterData() {
    if (this.removedFilter !== undefined) {
      this.filterFormField.forEach((element, index) => {
        if (element.filterkey === this.removedFilter.key) {
          element.selected = '';
        }
      });
      this.selectedData[this.removedFilter.key] = {}
    }
  }

  getSelectedValue(data, selectName, array?) {
    const getData = this.selectedData;
    getData[selectName] = { value: data, selectMethod: selectName, data: array, type: selectName };
    this.validateRequiredField();
    this.getSelectedData.emit(getData);
    this.selectType.emit({ key: selectName, value: data });
  }

  validateRequiredField() {
    this.filterFormField.forEach(field => {
      if (field.required && (field.selected == undefined || field.selected == "")) {
        this.isButtonDisabled = false;
      } else if (field.required && field.selected != undefined) {
        this.isButtonDisabled = true;
      }
    });
  }

  getSelectedValueInTheBeginning() {
    const getData = this.selectedData;
    if (this.formArray.length > 0) {
      const filterData = [];
      this.filterFormField.forEach(data => {
        getData[data.key] = { value: data.selected ? data.selected : '', selectMethod: data.key, data: data.data }
        if (data.type === 'select') {

          const selectData = data.data.filter(array => array.value === data.selected);
          if (selectData[0] !== undefined) {
            selectData[0].key = data.filterkey;
            filterData.push(selectData[0])
          }
        }
        else {
          // if (data.type !== "checkbox") {
          //   filterData.push({ label: data.selected, value: data.selected, key: data.filterkey })
          // }
          filterData.push({ label: data.selected, value: data.selected, key: data.filterkey })
        }
      })
      const getfilterData = filterData.filter(ele => ele.label !== undefined && ele.label !== '');
      this.getFilterData = getfilterData;
      //this.getSelectedData.emit(getData);
      this.filterresult.emit(this.getFilterData);
    }
  }

  getDeleteStatus(data, status) {
    this.getdeletedata.action = status;
    let deleteaction = this.deleteaction;
    deleteaction[data.type] = status;
    this.deleteAction.emit({ 'deleteAction': deleteaction })
    this.deleteModal.hide();
  }

  //Message bulkaction delete
  messagedelete(type) {
    if (this.isBulkEditSelectedForDelete) {
      if (this.isBulkEditSelectedForDelete["data"].length > 0) {
        this.deleteModal.show();
        this.getdeletedata = {
          type: type
        }
      } else {
        this.toast.warning('Please select message to delete');
      }
    } else {
      this.deleteModal.show();
      this.getdeletedata = {
        type: type
      }
    }
  }

  messageimportant(type) {
    if (this.isBulkEditSelectedForDelete) {
      if (this.isBulkEditSelectedForDelete["data"].length > 0) {
        this.importantAction.emit(type);
      } else {
        this.toast.warning('Please select message to set important');
      }
    }
  }

  submitForm(data, selectName) {
    this.submitFilter = data;
    const submitData = { value: data, selectMethod: selectName }
    this.formSubmit.emit(submitData)
    this.getSelectedValueInTheBeginning()
  }

  // resetForm(data, selectName) {
  //   this.selectedData = {};
  //   this.resetFilter = data;
  //   const resetData = { value: data, selectMethod: selectName }
  //   this.formReset.emit(resetData)
  // }

  resetForm(data, selectName) {
    this.resetFilter = data;
    if (this.emitReset) {
      const resetData = { value: data, selectMethod: selectName };
      this.formReset.emit(resetData);
    } else {
      this.filterFormField = [...this.filterFormField].map(field => {
        const fieldCopy = { ...field };
        if (fieldCopy.alwaysEnable) {
          return fieldCopy;
        }
        if (fieldCopy.selected) {
          fieldCopy.selected = '';
        }
        return fieldCopy;
      });
      this.formReset.emit(this.selectedData);
      if (!this.autoSearch)
        this.isButtonDisabled = false;
    }
  }

}
