import { NgModule } from "@angular/core";
import { SignUpComponent } from "./signup.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AdminRouterModule } from '../router/router.module';
import { PageLoaderModule } from "../common/pageloader/pageloader.module";
import { SignupEffects } from "../signup/state/effects";
import { SignupService } from "../signup/state/services/signup.service";
import { signupReducers} from "../signup/state/reducers/signup.reducer";
import { SignupHttpService } from "../signup/state/services/signup.http.service";
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports:[
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AdminRouterModule,
        PageLoaderModule,
        ControlValidationModule,
        SharedModule,
        EffectsModule.forFeature([SignupEffects]),
        StoreModule.forFeature('signupModule', signupReducers),
        TranslateModule
    ],
    declarations: [SignUpComponent],
    exports: [SignUpComponent],
    providers: [SignupEffects, SignupHttpService, SignupService]
})
export class SignUpModule { }