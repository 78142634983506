import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';

@Component({
  selector: 'rewardkart-admin-link-csv-table',
  templateUrl: './link-csv-table.component.html',
  styleUrls: ['./link-csv-table.component.scss']
})
export class LinkCsvTableComponent implements OnInit {
  @Input()
  set reportURL(url) {
    if (url && typeof url === 'string' && url !== this.noProductFoundText) {
      this.getCSVData(url);
      this.showLoader = true;
    } else {
      this.reports = [];
      this.columns = [];
      this.showLoader = false;
    }
  }

  @Input() noProductFoundText = 'No orders found.';
  @Input() splitString = '	';
  reports = [];
  showLoader = false;
  header = ' Profit center';
  columns: ColumnSetting[] = [];
  productdatacount: any;
  enablepagination: any;
  activePage: any;
  perPagedata: any;
  webshopSupplierID: any;

  constructor(private apiService: ApiService, private httpClient: HttpClient) { }

  ngOnInit() { }
  getCSVData(url: string) {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'text';
      withCredentials?: boolean;
    } = {
      responseType: 'text'
    };
    this.httpClient.get(url, options).subscribe(
      (data: any) => this.extractData(data),
      err => this.handleError(err)
    );
  }

  private extractData(res: any) {
    const csvData = res['_body'] || res;
    const allTextLines = csvData.split(/\r\n|\n/);
    const headers = allTextLines[0].split(this.splitString);
    const lines = [];

    allTextLines.length > 1 ? this.buildColumn(headers) : undefined;
    for (let i = 1; i < allTextLines.length - 1; i++) {
      const data = allTextLines[i].split(this.splitString);
      const tarr = {};
      for (let j = 0; j < headers.length; j++) {
        if (headers[j]) {
          tarr[headers[j]] = data[j];
        }
      }
      lines.push(tarr);
    }

    this.reports = lines;
    this.showLoader = false;
  }

  addProduct(data) { }

  viewProduct(data) { }
  editProduct(data) { }
  deleteProduct(data) { }
  refreshProductList(data) { }
  paginationNum(data) { }
  getPerPage(data) { }
  siblingProduct(data) { }
  cloneProduct(data) { }
  buildColumn(headers) {
    this.columns = headers.reduce((formatedHeaders, header: string) => {
      if (header) {
        return [
          ...formatedHeaders,
          {
            primaryKey: header,
            header
          }
        ];
      } else {
        return formatedHeaders;
      }
    }, []);
    // this.columns = headers.map(header => ({
    //   primaryKey: header,
    //   header
    // }));
  }
  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    console.error(errMsg); // log to console instead
    return errMsg;
  }
}
