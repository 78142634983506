import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryProductgridComponent } from './category-productgrid.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageControlModule } from 'src/app/shared/page-control/page-control.module';
import { FormsModule } from '@angular/forms';
import { DragdropModule } from '../directives/dragdrop.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [CategoryProductgridComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgxPaginationModule,
    PageControlModule,
    FormsModule,
    DragdropModule,
    TranslateModule
  ],
  exports: [CategoryProductgridComponent]
})
export class CategoryProductgridModule { }