import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../state/services/dashboard.service';
import { ApiService } from '../../shared/common-services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-message',
  templateUrl: './dashboard-message.component.html',
  styleUrls: ['./dashboard-message.component.scss']
})
export class DashboardMessageComponent implements OnInit {
  public messageList: any = [];
  messageQueryParams: any = {};
  webshopDetail: any;
  constructor(public dashboardservice: DashboardService,
    public apiService: ApiService,
    public router: Router, private translate: TranslateService
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.getDashboardProductList();
  }

  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.messageQueryParams.customerID = this.webshopDetail.CustomerID;
    this.messageQueryParams.UserID = loginData.UserID;
    this.dashboardservice.loadMessage(this.messageQueryParams);
  }

  getDashboardProductList() {
    this.dashboardservice.getMessage().subscribe((data: any) => {
      if (data) {
        this.messageList = data.ItemsCollection;
      }
    },
      err => {
        console.log(err);
      });
  }

  navigateToMailDetails(userData) {
    this.router.navigate(['/app-message-inboxdetails'], { state: { data: userData }, replaceUrl: false });
  }
}
