import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, SimpleChanges, SimpleChange, OnChanges, EventEmitter } from '@angular/core';
import { SupplierHttpService } from '../state/services/supplier.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ControlValidationService } from '../../shared/control-validation/index';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/common-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'rewardkart-admin-supplier-add',
  templateUrl: './supplier-add.component.html',
  styleUrls: ['./supplier-add.component.scss']
})
export class SupplierAddComponent implements OnInit {
  countriesList: any = [];
  optionsSelectCountries: any = [];
  optionsSelectCurrencies: Array<any>;
  supplierDetails: FormGroup;
  vatMandatoryFieldStatus: Boolean = false;
  showLoader: Boolean = false;
  editData: any;
  editPage: Boolean = false;
  viewPage: Boolean = false;
  public postalCodeErrorCount = 0;
  inputName: string;
  itemId: number;
  rating: number;
  public customerList: any = [];
  webshopDetail: any;
  isParentCustomer: Boolean = false;
  showWebshopDropdown: Boolean = false;
  nameFieldDisable: Boolean = false;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(private supplierHttpService: SupplierHttpService, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router, private renderer: Renderer2, private translate: TranslateService) {
    this.loadForm();
  }

  ngOnInit() {
    this.getCountryList();
    /** to get Currencies list from Redux State */
    this.getCurrencies();
    this.inputName = this.itemId + '_rating';

    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = false;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID === null) {
        this.isParentCustomer = true;
        this.getAllCustomerList();
      }
    }
  }
  onClick(rating: number): void {
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }
  resetRating() {
    this.rating = 0;
  }
  loadForm() {
    const editData = history.state.data;
    console.log("Load Form Edit Data : ", editData);
    this.editData = history.state.data;
    if (editData !== undefined && editData.CurrencyID !== undefined) {
      editData.CurrencyID = (editData.CurrencyID !== '') ? editData.CurrencyID : '';
    } else {
      if (editData !== undefined) {
        editData.CurrencyID = '';
      }
    }
    if (editData !== undefined) {
      this.editPage = true;
      if (!editData.IsEditable) {
        this.viewPage = true;
      }
      if (!editData.IsDeletable) {
        this.nameFieldDisable = true;
      }
    }
    if (editData !== undefined && editData.QualityLevel !== undefined) {
      console.log("Inside QTY LEVEL : ", editData.QualityLevel);
      this.rating = Number(editData.QualityLevel);
    }
    this.supplierDetails = this.formBuilder.group({
      Name: [editData !== undefined ? editData.Name : '', Validators.required],
      ContactName: [editData !== undefined ? editData.ContactName : ''],
      Email: [editData !== undefined ? editData.Email : '', [Validators.required, ControlValidationService.emailValidator]],
      CurrencyID: [editData !== undefined ? editData.CurrencyID : ''],
      PhoneNumber: [editData !== undefined ? editData.PhoneNumber : ''],
      // tslint:disable-next-line:max-line-length
      MobileNumber: [editData !== undefined ? editData.MobileNumber : ''],
      Address: [editData !== undefined ? editData.Address : ''],
      City: [editData !== undefined ? editData.City : ''],
      PostalCode: [editData !== undefined ? editData.PostalCode : ''],
      Country: [editData !== undefined ? editData.Country : '', Validators.required],
      WebsiteURL: [editData !== undefined ? editData.WebsiteURL : ''],
      thirdpartysupplier: [],
      webshop: ['']
    });
  }

  isPhoneNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode < 40 || charCode > 57) || (charCode === 42) || (charCode === 44)) {
      return false;
    }
    return true;
  }

  /*** Get Country Items ***/
  getCountryList() {
    this.supplierHttpService.getAllCountries().subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCountries = [];
          this.optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          this.countriesList = [];
          this.countriesList = data;
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });
        }
      });
  }

  getCurrencies() {
    this.supplierHttpService.getAllCurrencies().subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCurrencies = [];
          this.optionsSelectCurrencies.push({ label: this.translate.instant('USERBUDGETEDIT.SELECT_CURRENCY'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            this.optionsSelectCurrencies.push(langVal);
          });
        }
      });
  }

  validateFields() {
    let validateStatus = true;
    // tslint:disable-next-line:max-line-length
    if ((this.supplierDetails.controls.Name.value === '') || (this.supplierDetails.controls.Email.value === '') || (this.supplierDetails.controls.Country.value === '')) {
      this.supplierDetails.controls['Name'].markAllAsTouched();
      this.supplierDetails.controls['Email'].markAllAsTouched();
      this.supplierDetails.controls['Country'].markAllAsTouched();
      validateStatus = false;
    }
    return validateStatus;
  }

  addSupplier() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      const addSupplierData = {
        Name: this.supplierDetails.value.Name,
        ContactName: this.supplierDetails.value.ContactName,
        Email: this.supplierDetails.value.Email,
        PhoneNumber: this.supplierDetails.value.PhoneNumber,
        MobileNumber: this.supplierDetails.value.MobileNumber,
        Address: this.supplierDetails.value.Address,
        PostalCode: this.supplierDetails.value.PostalCode,
        Country: this.supplierDetails.value.Country,
        CurrencyID: this.supplierDetails.value.CurrencyID,
        City: this.supplierDetails.value.City,
        QualityLevel: (this.rating !== null && this.rating !== undefined) ? Number(this.rating) : null,
        WebsiteURL: this.supplierDetails.value.WebsiteURL,
        //  CustomerID: loginData.CustomerID
      };

      /** Validation Based on Third Part Supplier Checkbox => If checkbox select then Supplier concept going to create for childshop */
      if (this.supplierDetails.value.thirdpartysupplier) {
        if (this.supplierDetails.value.webshop === '') {
          this.toast.error('Please select webshop');
          return false;
        } else {
          addSupplierData['CustomerID'] = this.supplierDetails.value.webshop;
          addSupplierData['CreatedByCustomerID'] = this.supplierDetails.value.webshop;
          addSupplierData['ParentSupplierID'] = null;
        }
      } else {
        addSupplierData['CustomerID'] = this.webshopDetail.CustomerID;
        addSupplierData['CreatedByCustomerID'] = this.webshopDetail.CustomerID;
        addSupplierData['ParentSupplierID'] = null;
        if (this.isParentCustomer) {
          addSupplierData['ParentSupplierID'] = 1;
        }
      }
      // console.log("addSupplierData : ", addSupplierData); return false;
      if ((this.supplierDetails.value.CurrencyID).toString() !== '') {
        addSupplierData['CurrencyID'] = (this.supplierDetails.value.CurrencyID).toString();
      }

      let country = '';
      let postalCode = '';
      if (this.supplierDetails.controls.Country.value !== undefined) {
        country = this.supplierDetails.controls.Country.value.trim();
      }
      if (this.supplierDetails.controls.PostalCode.value !== undefined) {
        postalCode = this.supplierDetails.controls.PostalCode.value.trim()
      }

      let postalcodeErrorExample = '';
      let postalcodeErrorIs = '';
      let postalcodeError = '';
      let postalcodeProceedText = '';

      this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
        postalcodeErrorExample = res;
      });
      this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
        postalcodeErrorIs = res;
      });
      this.translate.get('USER.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
        postalcodeError = res;
      });
      this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
        postalcodeProceedText = res;
      });

      this.validatePostalCodeCheck(country, postalCode).subscribe((data) => {
        let proceedStatus = false;
        if (data['ValidPostalRegex']) {
          proceedStatus = true;
        } else {
          this.postalCodeErrorCount += 1;
          if (Number(this.postalCodeErrorCount) > 1) {
            proceedStatus = true;
          } else {
            proceedStatus = false;
            const countryResponse = this.countriesList.find(item => item.Alpha2Code === country);
            if (data['VaildPostalcode'] !== '' && data['VaildPostalcode'] !== undefined) {
              const displayText = postalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;
              // tslint:disable-next-line:max-line-length
              this.toast.error(this.translate.instant('USERADDEDIT.ERROR') + ': ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus = true;
            }
          }
        }

        console.log("addSupplierData : ", addSupplierData);
        if (proceedStatus) {
          console.log("Inside");
          addSupplierData['SupplierAction'] = 'insert';
          if (this.editData !== undefined) {
            addSupplierData['supplierId'] = this.editData.SupplierID;
            addSupplierData['SupplierAction'] = 'update';
            if (this.isParentCustomer) {
              /** Pass CustomerID Parameter based on webshop dropdown selection value in parnet login */
              const searchResult = sessionStorage.getItem('searchSupplierResult')
              if (searchResult !== null) {
                const searchSupplierResult = JSON.parse(sessionStorage.getItem('searchSupplierResult'))
                const getIndex1 = searchSupplierResult.findIndex(ind => ind.key === 'selectwebshop');
                if (searchSupplierResult[getIndex1].selected !== '' && Number(searchSupplierResult[getIndex1].selected) !== 0) {
                  addSupplierData['CustomerID'] = Number(searchSupplierResult[getIndex1].selected);
                }
              }
            }
          }
          this.showLoader = true;
          this.supplierHttpService.addSupplier(addSupplierData).subscribe((data: any) => {
            this.showLoader = false;
            if (data) {
              if (this.editData !== undefined) {
                this.toast.success('Supplier updated successfully');
                sessionStorage.setItem('supplierEditRedirect', JSON.stringify(true));
              } else {
                this.toast.success('Supplier added successfully');
              }
              this.router.navigate(['/admin-supplier-list'], { replaceUrl: false });
            }
          },
            err => {
              let errMsg = '';
              this.showLoader = false;
              if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
                for (var key in err.error.ModelState) {
                  errMsg += err.error.ModelState[key] + ' ';
                }
              } else {
                errMsg += err.error;
              }
              this.toast.error(errMsg);
            });
        }
      });
    }
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    var subject = new Subject<any>();
    this.supplierHttpService.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  redirectToSupplierList() {
    sessionStorage.setItem('supplierEditRedirect', JSON.stringify(true));
    this.router.navigate(['/admin-supplier-list'], { replaceUrl: false });
  }

  websiteURL() {
    if (this.supplierDetails.value.WebsiteURL !== '') {
      if (this.validURL(this.supplierDetails.value.WebsiteURL)) {
        window.open(this.supplierDetails.value.WebsiteURL, "_blank");
      } else {
        this.toast.error('Enter Valid URL(incl http:// or https://)');
      }
    } else {
      this.toast.error('Enter Valid URL(incl http:// or https://)');
    }
  }

  validURL(str) {
    // const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    //   '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    // return !!pattern.test(str);

    const RegExp1 = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (RegExp1.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  addSupplierDetails() { }

  thirdPartySupplier() {
    this.showWebshopDropdown = false;
    if (this.supplierDetails.value.thirdpartysupplier) {
      this.showWebshopDropdown = true;
    }
  }

  getAllCustomerList() {
    this.showLoader = true;
    this.supplierHttpService.getParentCustomersList(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.showLoader = false;
        const webshopData = [{ label: this.translate.instant('DASHBOARD.SELECTWEBSHOP'), value: '' }];
        data.forEach(option => {
          webshopData.push({ label: option.Name, value: option.CustomerID });
        })
        this.customerList = webshopData;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

}
