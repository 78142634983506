import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';

@Injectable()
export class WebshopHttpService {
    webshopDetail: any;
    constructor(private apiService: ApiService, private loginService: LoginService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            //const loginData = this.apiService.getLoginData();
            const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getWebshopList(searchData: any) {
        const webshop = sessionStorage.getItem('webshopDetail');
        //const loginData = this.apiService.getLoginData();
        const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {

            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        let path;
        let searchCriteria: any = {
            activePage: searchData.activePage !== undefined ? searchData.activePage : '',
            pageSize: searchData.pageSize !== undefined ? searchData.pageSize : '',
        }

        // tslint:disable-next-line:max-line-length
        const childShopId = (searchData.childShopId !== null && searchData.childShopId !== undefined && searchData.childShopId !== '') ? searchData.childShopId : '';
        if (childShopId !== '') {
            path = 'en/customers/?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&ParentCustomerID=' + this.webshopDetail.CustomerID + '&customerID=' + childShopId + '&RoleCode=' + loginData.Roles[0].Code + '&IsNeedParentShop=false';
        } else {
            // tslint:disable-next-line:max-line-length
            path = 'en/customers?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&ParentCustomerID=' + this.webshopDetail.CustomerID + '&RoleCode=' + loginData.Roles[0].Code + '&IsNeedParentShop=false';
        }
        // this.loginService.loginSuccess().subscribe(data => {
        //     const parentCustomerID = data.CustomerID;
        //     path = 'en/customers/?ParentCustomerID=' + parentCustomerID;
        // });
        if (loginData.Roles[0].RoleName !== 'Super Admin') {
            path = path + '&CompanyName=' + loginData.CompanyName;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomerDet(){
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        let id = this.webshopDetail ? this.webshopDetail.CustomerID : 0;
        let path = `en/customer/GetCustFrReport?CustomerID=${id}`
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getchildWebshopList(data, isDeleted = null) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            //const loginData = this.apiService.getLoginData();
            const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        let path;
        const deletedStatus = (isDeleted !== null) ? "?deleted=" + isDeleted : '';
        if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
            this.loginService.loginSuccess().subscribe(data => {
                const CustomerID = this.webshopDetail.CustomerID;
                path = 'en/customers/' + CustomerID + deletedStatus;
            });
        } else {
            if (sessionStorage.getItem('userdetails') !== null) {
                const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
                const CustomerID = this.webshopDetail.CustomerID;
                path = 'en/customers/' + CustomerID + deletedStatus;
            }
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getUserToken(requestdata: any) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            //const loginData = this.apiService.getLoginData();
            const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        const data = {
            UserID: requestdata.UserID,
            ParentCustomerID: this.webshopDetail.CustomerID,
            ChildCustomerID: requestdata.ChildCustomerID
        }
        const path = 'en/users/Token';
        return this.apiService.post(path, data);
    }

    verifyToken(postData) {
        const path = 'en/users/verifyToken';
        return this.apiService.post(path, postData);
    }

    addWebshop(data: any, isSaas:boolean) {
        // let path =  isSaas ? 'en/CreateSaasCustomer' : 'en/customers';
        let path = 'en/customers';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    checkdomainname(domainName: any) {
        const path = 'en/customers?domain=' + domainName;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    /** Upload User Logos */
    uploadwebshopimage(webshopLogo) {
        const path = 'en/uploadfile';
        return this.apiService.postFileUpload(path, webshopLogo, { responseType: 'json' });
    }

    webshopEdit(customerID) {
        const path = 'en/customers/' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    webshopcostprofit(customerID) {
        const path = 'en/customerfee/' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    updatewebshopcostprofit(profiltDetailData) {
        const path = 'en/customerfee/' + profiltDetailData.CustomerID;
        return this.apiService.post(path, profiltDetailData).map((res) => {
            return res;
        });
    }
    parentwebshopcostprofit(data) {
        const path = 'en/customerfee/' + data.ParentCustomerID + '/' + data.customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    updateParentwebshopcostprofit(profiltDetailData) {
        const path = 'en/customerfee/';
        return this.apiService.post(path, profiltDetailData).map((res) => {
            return res;
        });
    }
    paymentType() {
        const path = 'en/paymenttypes/';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    linkcommission(customerID) {
        const path = 'en/linknetworkcommission?customerID=' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    updateLinkcommission(profiltDetailData) {
        const path = 'en/linknetworkcommission';
        return this.apiService.post(path, profiltDetailData).map((res) => {
            return res;
        });
    }

    updateWebshop(request, customerID) {
        debugger
        const path = 'en/customers/' + customerID;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    checkDomain(request) {
        const path = `en/customers?domain=${request.domain}&customerID=${request.customerID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCountries() {
        const path = 'en/countries';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    webshopUpdateUser(request, userID) {
        const path = `en/users/${userID}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    loadRoles(request) {
        const path = `en/productroles?customerID=${request.customerID}&productIdManual=${request.productIdManual}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteRole(roleID) {
        const path = `en/productroles/${roleID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getTransportationData(customerID) {
        const path = 'en/TransportationHubCountries?customerID=' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    updateTransportationData(transportationData, customerID) {
        const path = 'en/TransportationHubCountries?customerID=' + customerID;
        return this.apiService.post(path, transportationData).map((res) => {
            return res;
        });
    }

    getAllCountries() {
        const path = 'en/countries';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    /**
    * Service to Check Postal Code for Selected Country
    */

    checkPostalCode(countryCode, postalCode) {
        // const userSettings = this.globalService.getUserSettings();
        const path = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addRole(request) {
        const path = `en/productroles`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    updateRole(request) {
        const path = `en/productroles/${request.ProductRoleID}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    viewProduct(request) {
        const path = `en/productroles/${request.ProductRoleID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteProduct(request) {
        const path = `en/productroles/product/${request.ProductRoleID}/${request.ProductID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }


    getCustomerCurrencies() {
        const path = 'en/currencies';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    loadHandlingCharges(webshopId) {
        const path = 'en/transportationhandlingcostprices?customerID=' + webshopId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    searchManualID(request) {
        const path = 'en/productsadminsearch';
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    addProductRole(request) {
        const path = `en/productroles/product/${request.ProductRoleID}/${request.ProductID}?addBatchProducts=${request.addBatchProduct}`;
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    productRole(productRoleID) {
        const path = `en/productroles/${productRoleID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    searchUser(request) {
        const path = `en/users/list/${request.customerID}?roleCode=${request.roleCode}&pageIndex=1&pageSize=10&username=${request.username}&name=${request.name}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addUserRole(request) {
        const path = `en/productroles/user/${request.ProductRoleID}/${request.UserID}`;
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }
    updateHandlingCharges(handlingChargeData) {
        const path = 'en/transportationhandlingcostprices?customerID=' + handlingChargeData.CustomerID;
        return this.apiService.post(path, handlingChargeData).map((res) => {
            return res;
        });
    }

    deleteUser(request) {
        console.log(request)
        const path = `en/productroles/user/${request.ProductRoleID}/${request.UserID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getCurrenciesData(customerID) {
        const path = 'en/currencies/customer/' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    updateCurrenciesData(currenciesData, customerID) {
        const path = 'en/currencies/customer/' + customerID;
        return this.apiService.post(path, currenciesData).map((res) => {
            return res;
        });
    }

    addTag(request) {
        const path = `en/producttagsgroups`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    addProductTag(request) {
        const path = `en/producttags`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    getLanguages(custID?) {
        let path = 'en/languages';
        if (custID) {
            path = 'en/languages?customerId=' + custID;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    loadTagList(request) {
        let path = '';
        if (request.manualID) {
            path = `all/producttagsgroups?customerID=${request.CustomerID}&productIdManual=${request.manualID}`;
        }
        else {
            path = `all/producttagsgroups?customerID=${request.CustomerID}`;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    getCategories(customerID) {
        const path = 'en/categories?customerID=' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getSocialMediaMasterData() {
        const path = 'en/SocialMedias/SocialMedias';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    updateProductTagGroup(request, groupID) {
        const path = `en/producttagsgroups/${groupID}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    updateProductTag(request, groupID) {
        const path = `en/producttags/${groupID}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    deleteTagGroup(groupID) {
        const path = `en/producttagsgroups/${groupID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteTag(groupID) {
        const path = `en/producttags/${groupID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProTag(request) {
        const path = `en/producttags/product/${request.ProductTagID}/${request.ProductID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    tagList(tagID) {
        const path = `en/producttagsgroups/${tagID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addProductTags(request) {
        const path = `en/producttags/product/${request.ProductTagID}/${request.ProductID}?addBatchProducts=${request.addBatchProduct}`;
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    unpublishwebshopedit(data) {
        const path = `en/UpdateWebshopPublish`;
        return this.apiService.put(path, data);
    }

    getActAsSupplier(name) {
        const path = `${undefined}/suppliers?name=${name}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    setMaintenanceScreen(name, status){
        const path = "en/customer/MaintenanceScreen?DomainName="+name+"&Status="+status;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    customerSupplier(data) {
        const path = `${undefined}/customer_supplier`;
        return this.apiService.put(path, data).map((res) => {
            return res;
        });
    }

    deleteWebshop(webshopID) {
        const path = `en/customers/${webshopID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }


    webshopPublishListMenu(request) {
        const path = `en/customers/GetWebshopSideMenuDetails?CustomerID=${request.customerID}&UserID=${request.UserID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getBankDetails(custID) {
        const path = `en/customers/GetParentBankDetails/${custID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    imgUpld(params){
        let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        const path = "en/UploadSignUpBanner?ImageName="+params+"&CustomerId="+webshop.CustomerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCurrencies(params){
        let path = `en/currencies/CurrenciesMaster?PageSize=${params.pageSize}&PageIndex=${params.activePage}`
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCountry(params){
        let path = `en/countries/CountryMaster?PageSize=${params.pageSize}&PageIndex=${params.activePage}`
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addCurrency(params: any) {
        let path = 'en/currencies/UpdateCurrenciesDetails';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    addCountry(params: any) {
        let path = 'en/countries/UpdateCountryDetails';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

}
