import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessageClaimsdetailsComponent } from './message-claimsdetails.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    ControlValidationModule
  ],
  declarations: [MessageClaimsdetailsComponent],
  exports: [MessageClaimsdetailsComponent]
})

export class MessageclaimsdetailsModule { }
