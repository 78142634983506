import { Action } from '@ngrx/store';
export const LOAD_BILLBOARD_LIST = '[Webshop] Load all Billboard List';
export const LOAD_BILLBOARD_LIST_SUCCESS = '[Webshop] Load Billboard List Success';
export const LOAD_BILLBOARD_LIST_FAIL = '[Webshop] Load Billboard List Fail';

export class LoadBillboardList implements Action {
    readonly type = LOAD_BILLBOARD_LIST;
}

export class LoadBillboardListSuccess implements Action {
    readonly type = LOAD_BILLBOARD_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadBillboardListFail implements Action {
    readonly type = LOAD_BILLBOARD_LIST_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | LoadBillboardList
    | LoadBillboardListSuccess
    | LoadBillboardListFail;
