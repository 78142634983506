import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-warehouse-management',
  templateUrl: './warehouse-management.component.html',
  styleUrls: ['./warehouse-management.component.scss']
})
export class WarehouseManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
