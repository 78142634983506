import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalnumbersDirective } from './decimalnumbers.directive';
import { OnlynumberDirective } from './numberonly.directive';
// import { ListnerDirective } from './listner.directive';



@NgModule({
  declarations: [DecimalnumbersDirective, OnlynumberDirective],
  imports: [
    CommonModule
  ], 
  exports:[DecimalnumbersDirective,OnlynumberDirective]
})
export class CustomdirectiveModule { } 
