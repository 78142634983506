import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common'
import { ReportsHttpService } from "../services/reports-http.service";
import { ToastService } from "ng-uikit-pro-standard";
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { ColumnSetting } from '../../shared/link-table-border/models/link-table-model';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
    selector: 'rewardkart-admin-report-payment',
    templateUrl: './report-payment.component.html',
    styleUrls: ['./report-payment.component.scss']
})

export class ReportPaymentComponent implements OnInit{
    columns: ColumnSetting[] = [];
    fromDate: any;
    toDate: any;
    date = new Date();
    datePickerOptions: IMyOptions = {};
    enddatePickerOptions: IMyOptions = {};
    showLoader: boolean = false;
    showTable: boolean = false;
    userName: any = '';
    userMobile: any = '';
    userEmail: any = '';
    orderID: any = '';
    paymentReport: any;
    domainName: any = '';
    webshop: any;
    isParentCustomer: boolean;
    public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public fileExtension = '.xlsx';
    public exportData: any = [];
    noData: boolean;

    constructor(private reportHttpService: ReportsHttpService, private toastr: ToastService, private datePipe: DatePipe){
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }
    }

    ngOnInit() {
        let dt = new Date();
        const disableDate = new Date(dt.setDate(dt.getDate() + 1));
        const startDate = new Date(dt.setDate(dt.getDate() - 7));
        this.fromDate = startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear();
        this.toDate = this.date.getDate() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getFullYear();
        this.datePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd-mm-yyyy',
            disableSince: {
              year: disableDate.getFullYear(),
              month: disableDate.getMonth() + 1,
              day: disableDate.getDate()
            },
          };
          this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd-mm-yyyy',
            disableSince: {
              year: disableDate.getFullYear(),
              month: disableDate.getMonth() + 1,
              day: disableDate.getDate()
            },
            disableUntil: {
              year: startDate.getFullYear(),
              month: startDate.getMonth() + 1,
              day: startDate.getDate()
            },
          };
        this.getReport();
    }

    dateChange(event) {
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd-mm-yyyy',
            disableUntil: {
                year: event.date.year,
                month: event.date.month,
                day: event.date.day,
            },
        };
    }

    getReport(){
        let params = {
            'FromDate': this.fromDate,
            'ToDate': this.toDate,
            'UserName': this.userName,
            'Email': this.userEmail,
            'Mobile': this.userMobile,
            'OrderID': this.orderID
        }
        this.showLoader = true;
        this.reportHttpService.getPaymentReport(params).subscribe((data: any) => {
            this.showLoader = false;
            if(data){
                this.showTable = true;
                this.noData = false;
                this.paymentReport = data.UserReportDetails;
                this.columns = [
                    {
                        primaryKey: 'Username',
                        header: 'User Name'
                    },
                    {
                        primaryKey: 'PaymentID',
                        header: 'Payment ID'
                    },
                    {
                        primaryKey: 'Source',
                        header: 'Source'
                    },
                    {
                        primaryKey: 'TransactionDate',
                        header: 'Date',
                        format: 'date'
                    },
                    {
                        primaryKey: 'TotalAmount',
                        header: 'Amount',
                        format: 'number'
                    },
                    {
                        primaryKey: 'Status',
                        header: 'Status'
                    }
                ]
            }else{
                this.noData = true;
                this.showTable = false;
            }
        });
    }

    download() {
        this.exportData = [];

        this.paymentReport.forEach(e => {
            this.exportData.push({
                'User name': e.Username,
                'Payment ID': e.PaymentID,
                'Source': e.Source,
                'Date': this.datePipe.transform(e.TransactionDate, 'dd/MM/yyyy'),
                'Amount': e.TotalAmount,
                'Status': e.Status
            })
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, this.domainName + '-payment-report');
    }

    saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }
}