import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { RangeModule } from 'ng-uikit-pro-standard'
import { CategoryGridDetailsModule } from '../category-griddetails/category-griddetails.module';
import { CategoryListDetailsModule } from '../category-listdetails/category-listdetails.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AdvanceSearchWebshopOnlineProductsComponent } from './advance-search-webshop-online-products.component';
import { CategoryProductgridModule } from '../category-productgrid/category-productgrid.module';
import { ManageSupplierModule } from '../manage-supplier/manage-supplier.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    RangeModule,
    CategoryGridDetailsModule,
    CategoryListDetailsModule,
    CategoryProductgridModule,
    AutocompleteLibModule,
    ManageSupplierModule,
    TranslateModule
  ],
  declarations: [AdvanceSearchWebshopOnlineProductsComponent],
  exports: [AdvanceSearchWebshopOnlineProductsComponent]
})
export class AdvanceSearchWebshopOnlineProductsModule { }