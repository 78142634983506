import { Action } from '@ngrx/store';
export const LOAD_USERS = '[Users] Load all users';
export const LOAD_USERS_SUCCESS = '[Users] Load Users Success';
export const LOAD_USERS_FAIL = '[Users] Load Users Fail';
export const LOAD_COUNTRIES = '[Users] Load all countries';
export const LOAD_COUNTRIES_SUCCESS = '[Users] Load Countries Success';
export const LOAD_COUNTRIES_FAIL = '[Users] Load Countries Fail';

export const LOAD_CUSTOMERS = '[Users] Load All Customers';
export const LOAD_CUSTOMERS_SUCCESS = '[Users] Load Customers Success';
export const LOAD_CUSTOMERS_FAIL = '[Users] Load Customers Fail';

export const LOAD_ROLES = '[Users] Load All Roles';
export const LOAD_ROLES_SUCCESS = '[Users] Load Roles Success';
export const LOAD_ROLES_FAIL = '[Users] Load Roles Fail';

export const PREPARE_FORM = '[Users] Prepare dynamic form';
export const PREPARE_FORM_SUCCESS = '[Users] Prepare dynamic form Success';
export const PREPARE_FORM_FAIL = '[Users] Prepare dynamic form fail';

export const SELECTED_USER = '[Users] User Selected ';

export const LOAD_LANGUAGES = '[Users] Load All languages';
export const LOAD_LANGUAGES_SUCCESS = '[Users] Load languages Success';
export const LOAD_LANGUAGES_FAIL = '[Users] Load languages Fail';

export const LOAD_CURRENCIES = '[Users] Load All Currencies';
export const LOAD_CURRENCIES_SUCCESS = '[Users] Load Currencies Success';
export const LOAD_CURRENCIES_FAIL = '[Users] Load Currencies Fail';

export const LOAD_DELETE = '[Users] Load All Delete';
export const LOAD_DELETE_SUCCESS = '[Users] Load Delete Success';
export const LOAD_DELETE_FAIL = '[Users] Load Delete Fail';

export const ADD_USER = '[Users] Load AddUser';
export const ADD_USER_SUCCESS = '[Users] Load AddUser Success';
export const ADD_USER_FAIL = '[Users] Load AddUser Fail';

export const FILE_UPLOAD = '[Users] Load Fileupload';
export const FILE_UPLOAD_SUCCESS = '[Users] Load Fileupload Success';
export const FILE_UPLOAD_FAIL = '[Users] Load Fileupload Fail';

export const UPDATE_USER = '[Users] Load UpdateUser';
export const UPDATE_USER_SUCCESS = '[Users] Load UpdateUser Success';
export const UPDATE_USER_FAIL = '[Users] Load UpdateUser Fail';

export const VALIDATE_USER = '[Users] Load ValidateUser';
export const VALIDATE_USER_SUCCESS = '[Users] Load ValidateUser Success';
export const VALIDATE_USER_FAIL = '[Users] Load ValidateUser Fail';




// export const SUBMIT_SEARCH_INFO = '[Users] Submit Search Info';
export class LoadCurrencies implements Action {
    readonly type = LOAD_CURRENCIES;
    constructor() { }
}

export class LoadCurrenciesSuccess implements Action {
    readonly type = LOAD_CURRENCIES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCurrenciesFail implements Action {
    readonly type = LOAD_CURRENCIES_FAIL;
    constructor(public payload: any) { }
}

export class LoadLanguages implements Action {
    readonly type = LOAD_LANGUAGES;
    constructor() { }
}

export class LoadLanguagesSuccess implements Action {
    readonly type = LOAD_LANGUAGES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadLanguagesFail implements Action {
    readonly type = LOAD_LANGUAGES_FAIL;
    constructor(public payload: any) { }
}

export class LoadUsers implements Action {
    readonly type = LOAD_USERS;
    constructor(public payload: any) { }
}

export class LoadUsersSuccess implements Action {
    readonly type = LOAD_USERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadUsersFail implements Action {
    readonly type = LOAD_USERS_FAIL;
    constructor(public payload: any) { }
}

export class LoadCountries implements Action {
    readonly type = LOAD_COUNTRIES;
    constructor() { }
}

export class LoadCountriesSuccess implements Action {
    readonly type = LOAD_COUNTRIES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCountriesFail implements Action {
    readonly type = LOAD_COUNTRIES_FAIL;
    constructor(public payload: any) { }
}

export class LoadCustomers implements Action {
    readonly type = LOAD_CUSTOMERS;
    constructor() { }
}

export class LoadCustomersSuccess implements Action {
    readonly type = LOAD_CUSTOMERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCustomersFail implements Action {
    readonly type = LOAD_CUSTOMERS_FAIL;
    constructor(public payload: any) { }
}

export class LoadRoles implements Action {
    readonly type = LOAD_ROLES;
    constructor() { }
}

export class LoadRolesSuccess implements Action {
    readonly type = LOAD_ROLES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadRolesFail implements Action {
    readonly type = LOAD_ROLES_FAIL;
    constructor(public payload: any) { }
}

export class PrepareDynamicForm implements Action {
    readonly type = PREPARE_FORM;
}

export class PrepareDynamicFormSuccess implements Action {
    readonly type = PREPARE_FORM_SUCCESS;
    constructor(public payload: any) { }
}

export class PrepareDynamicFormFail implements Action {
    readonly type = PREPARE_FORM_FAIL;
    constructor(public payload: any) { }
}

export class SelectedUser implements Action {
    readonly type = SELECTED_USER;
    constructor(public payload: any) { }
}



export class LoadDelete implements Action {
    readonly type = LOAD_DELETE;
    constructor(public payload: any) { }
}
export class LoadDeleteSuccess implements Action {
    readonly type = LOAD_DELETE_SUCCESS;
    constructor(public payload: any) { }
}
export class LoadDeleteFail implements Action {
    readonly type = LOAD_DELETE_FAIL;
    constructor(public payload: any) { }
}

export class AddUser implements Action {
    readonly type = ADD_USER;
    constructor(public payload: any) { }
}

export class AddUserSuccess implements Action {
    readonly type = ADD_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class AddUserFail implements Action {
    readonly type = ADD_USER_FAIL;
    constructor(public payload: any) { }
}

// export class SubmitSearchInfo implements Action {
//     readonly type = SUBMIT_SEARCH_INFO;
//     constructor(public payload: any) {}
// }

export class LoadFileUpload implements Action {
    readonly type = FILE_UPLOAD;
    constructor(public payload: any) { }
}

export class LoadFileUploadSuccess implements Action {
    readonly type = FILE_UPLOAD_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadFileUploadFail implements Action {
    readonly type = FILE_UPLOAD_FAIL;
    constructor(public payload: any) { }
}

export class UpdateUser implements Action {
    readonly type = UPDATE_USER;
    constructor(public payload: any) { }
}

export class UpdateUserSuccess implements Action {
    readonly type = UPDATE_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateUserFail implements Action {
    readonly type = UPDATE_USER_FAIL;
    constructor(public payload: any) { }
}

export class LoadValidateUser implements Action {
    readonly type = VALIDATE_USER;
    constructor(public payload: any) { }
}

export class LoadValidateUserSuccess implements Action {
    readonly type = VALIDATE_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadValidateUserFail implements Action {
    readonly type = VALIDATE_USER_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | LoadUsers
    | LoadUsersSuccess
    | LoadUsersFail
    | LoadCountries
    | LoadCountriesSuccess
    | LoadCountriesFail
    | LoadCustomers
    | LoadCustomersSuccess
    | LoadCustomersFail
    | LoadRoles
    | LoadRolesSuccess
    | LoadRolesFail
    | PrepareDynamicForm
    | PrepareDynamicFormSuccess
    | PrepareDynamicFormFail
    | SelectedUser
    | LoadLanguages
    | LoadLanguagesSuccess
    | LoadLanguagesFail
    | LoadCurrencies
    | LoadCurrenciesSuccess
    | LoadCurrenciesFail
    | LoadDelete
    | LoadDeleteSuccess
    | LoadDeleteFail
    | AddUser
    | AddUserSuccess
    | AddUserFail
    | LoadFileUpload
    | LoadFileUploadSuccess
    | LoadFileUploadFail
    | UpdateUser
    | UpdateUserSuccess
    | UpdateUserFail
    | LoadValidateUser
    | LoadValidateUserSuccess
    | LoadValidateUserFail;