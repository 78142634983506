import { Component, OnInit } from '@angular/core';
import { ReportsHttpService } from '../services/reports-http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ReportAbstract } from '../services/report-abstract';
@Component({
  selector: 'rewardkart-admin-report-profit-center',
  templateUrl: './report-profit-center.component.html',
  styleUrls: ['./report-profit-center.component.scss']
})
export class ReportProfitCenterComponent extends ReportAbstract
  implements OnInit {
  header = 'Profit Center';
  excludeKeyInUrlParam = [];
  noProductFoundText = 'No orders found.';
  downloadReport = 'Download Report';
  orderPlacedFromDate = '';
  orderPlacedToDate = '';
  customerList = [];
  customerLoader = false;
  selectedCustomerID = '';
  filterFields: any = [];

  commonRemoveFilter: any;
  reportData: any;
  webshopDetail: any;
  constructor(public reportHttpService: ReportsHttpService, private toaster: ToastService) {
    super();
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers() {
    this.customerLoader = true;
    this.reportHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
      (customersRes: any) => {
        if (customersRes) {
          this.selectedCustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
          const customerlist = [{ label: 'Select All', value: this.webshopDetail.CustomerID }];
          customersRes.forEach(cust => {
            let custData = {
              label: cust.Name,
              value: cust.CustomerID
            }
            customerlist.push(custData);
          });
          this.customerList = customerlist;
          this.formFields();
        }
        this.customerLoader = false;
      },
      error => {
        this.customerLoader = false;
        this.toaster.error('unable to get webshop customers');
      }
    );
  }

  formFields() {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12 pl-3 pt-4',
        key: 'filterHeading',
        filterkey: ''
      }];
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.filterFields.push(
          {
            label: 'Select Webshop',
            type: 'select',
            data: this.customerList,
            class: 'col-md-12 no-icon',
            key: 'CustomerID',
            filterkey: 'CustomerID',
            selected: this.selectedCustomerID
          }
        );
      }
    }
    this.filterFields.push(
      {
        label: 'Order Placed From Date',
        type: 'date',
        class: 'col-md-12',
        key: 'orderPlacedFromDate',
        default: '',
        selected: '',
        required: true,
        format: {
          dateFormat: 'dd/mm/yyyy',
          closeAfterSelect: true,
        },
        filterkey: 'orderPlacedFromDate'
      },
      {
        label: 'Order Placed To Date',
        type: 'date',
        class: 'col-md-12',
        key: 'orderPlacedToDate',
        default: '',
        selected: '',
        required: true,
        format: {
          dateFormat: 'dd/mm/yyyy',
          closeAfterSelect: true,
        },
        filterkey: 'orderPlacedToDate'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    );
  }

  buildReportURL(parameter) {
    return `en/ordersprofit?adminCustomerID=${this.webshopDetail.CustomerID}&${this.selectedCustomerID}
    ${this.orderPlacedFromDate}${this.orderPlacedToDate}`;
  }

  getSelectData(filterResult) {
    const { orderPlacedFromDate, orderPlacedToDate, CustomerID } = filterResult;
    if (orderPlacedFromDate) {
      const formatedDate = this.reportHttpService.dateFormatchange(orderPlacedFromDate.value);
      this.orderPlacedFromDate = `&${orderPlacedFromDate.selectMethod}=${formatedDate}`;
    }
    if (orderPlacedToDate) {
      const formatedDate = this.reportHttpService.dateFormatchange(orderPlacedToDate.value);
      this.orderPlacedToDate = `&${orderPlacedToDate.selectMethod}=${formatedDate}`;
    }
    if (CustomerID && CustomerID.value !== '') {
      this.selectedCustomerID = `${CustomerID.selectMethod}=${CustomerID.value}`;
    }
  }

  handleResetForm(event) {
    this.filterFields.forEach(element => {
      event[element.key].value = '';
    });
    this.orderPlacedFromDate = '';
    this.orderPlacedToDate = '';
    if (this.reportData && this.reportData != null) {
      this.reportData = null;
    }
  }
}
