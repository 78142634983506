import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { OrderHttpService } from './state/services/order.http.service';
import { OrderService } from './state/services/order.service';
import { orderReducers } from './state/reducers/order.reducer';
import { OrderEffects } from './state/effects/order.effects';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { ProductionLineListModule } from './production-line-list/production-line-list.module';
import { ProductionLineModule } from './production-line/production-line.module';
import { OrderProcessModule } from './order-process/order-process.module';
import { OrdersEditModule } from './orders-edit/orders-edit.module';
import { OrderItemModule } from './order-item/order-item.module';
import { OrderProcessViewDetailsModule } from './order-process-view-details/order-process-view-details.module';
import { editOrderItemsModule } from './order-process/edit-orderitems/edit-orderitems.module';
import { viewOrderstatusModule } from './order-process/view-orderstatus/view-orderstatus.module';
import { viewOrderitemsModalModule } from './order-process/view-orderstatus/view-orderitems-modal/view-orderitems-modal.module';
import { orderEmailnotificationModule } from './order-process/orderemailnotification/orderemailnotification.module';
import { orderAuditLogModule } from './order-process/order-audit-log/order-audit-log.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    CommonFilterModule,
    ProductionLineListModule,
    ProductionLineModule,
    OrderProcessModule,
    OrderItemModule,
    OrderProcessViewDetailsModule,
    OrdersEditModule,
    editOrderItemsModule,
    viewOrderstatusModule,
    viewOrderitemsModalModule,
    orderEmailnotificationModule,
    orderAuditLogModule,
    EffectsModule.forFeature([OrderEffects]),
    StoreModule.forFeature('orderModule', orderReducers)
  ],
  providers: [OrderHttpService, OrderService],
  exports: [ProductionLineListModule]
})

export class OrderModule { }
