import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-webshop-publish',
  templateUrl: './webshop-publish.component.html',
  styleUrls: ['./webshop-publish.component.scss']
})
export class WebshopPublishComponent implements OnInit {
  is_completed: any;
  @Input() webshopData: any;
  @Output() getPublishListData = new EventEmitter<any>();
  @Output() activeStatus = new EventEmitter<any>();
  publishTabDatas: any = []
  isWebShopActive: any
  showLoader: Boolean = false;
  getLink: any = '';
  publishBtn: boolean = false;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private toast: ToastService) { }

  ngOnInit() {


    // console.log(this.webshopData);

    // if (this.webshopData.isWebShopActive) {
    //   this.isWebShopActive = true
    // }
    // else {
    //   this.isWebShopActive = false
    // }
    // this.webshopPublishListMenu()
  }
  ngOnChanges() {
    if (this.webshopData) {
      let getLink = window.location.origin;

      this.getLink = getLink.split(':')[0] + '://' + this.webshopData.DomainName + '.' + getLink.substring(getLink.indexOf('.') + 1);
      if (getLink.includes('linkpartneradmin')) {
        this.getLink = this.getLink.replace("linkpartneradmin", "linkwebshop");
      }
      else {
        this.getLink = this.getLink.replace("linkpartner", "linknetwork");
      }
    }
    if (this.webshopData.isWebShopActive) {
      this.isWebShopActive = true
    }
    else {
      this.isWebShopActive = false
    }
    this.webshopPublishListMenu()
  }
  unpublishedwebshop(type) {
    if (type === 'unpublish') {
      let data = {
        "CustomerID": this.webshopData.CustomerID,
        "Status": false
      }

      // this.webshopHttpService.unpublishwebshopedit(data).subscribe((result: any) => {
      //   if (result) {
      //     console.log(result)
      //     this.toast.success("Your web shop has been unpublished. Edit the necessary information")
      //     if (!this.webshopData.isWebShopActive) {
      //       this.isWebShopActive = false
      //     }
      //   }
      // })
      this.activeStatus.emit(false);
    }
    else {
      this.activeStatus.emit(true);
    }

  }
  webshopPublishListMenu() {
    debugger
    this.showLoader = true;
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    const loginData = this.apiService.getLoginData();
    if (webshopDetail.ParentCustomerID == null || this.webshopData.WebshopManager.length == 0) {
      var req = {
        customerID: this.webshopData ? this.webshopData.CustomerID : '',
        UserID: loginData.UserID
      }
    }

    else if (webshopDetail.ParentCustomerID != null && this.webshopData.WebshopManager.length != 0) {
      var req = {
        customerID: this.webshopData ? this.webshopData.CustomerID : '',
        UserID: this.webshopData.WebshopManager[0].UserID
      }
    }

    this.webshopHttpService.webshopPublishListMenu(req).subscribe((data: any) => {
      this.showLoader = false;
      this.publishTabDatas = data;
      for (var publishData of this.publishTabDatas) {
        if (publishData.IsCompleted == 1 || publishData.IsCompleted == 2) {
          this.publishBtn = false;
        }
        else {
          this.publishBtn = true;
          break;
        }
      }
      this.getPublishListData.emit(this.publishTabDatas)
    },
      (error) => {
        this.showLoader = false;
      })
  }
}
