
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { AdvancedSearchComponent } from './advanced-search.component';
import { RangeModule } from 'ng-uikit-pro-standard'
import { CategoryGridDetailsModule } from '../category-griddetails/category-griddetails.module';
import { CategoryListDetailsModule } from '../category-listdetails/category-listdetails.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    RangeModule,
    CategoryGridDetailsModule,
    CategoryListDetailsModule,
    AutocompleteLibModule,
    TranslateModule
  ],
  declarations: [AdvancedSearchComponent],
  exports: [AdvancedSearchComponent]

})

export class AdvancedSearchModule { }