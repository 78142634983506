import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductManageSizeComponent } from './product-manage-sizes.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchFieldsPipe } from 'src/app/shared/link-table/pipes/search-fields.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule ,
    CommonFormModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [ProductManageSizeComponent ,SearchFieldsPipe],
  exports: [ProductManageSizeComponent]
})

export class ProductManageSizeModule { }
 