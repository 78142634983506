import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { DashboardService } from '../state/services/dashboard.service';

@Component({
  selector: 'app-dashboard-order-notification-alarm',
  templateUrl: './dashboard-order-notification-alarm.component.html',
  styleUrls: ['./dashboard-order-notification-alarm.component.scss']
})
export class DashboardOrderNotificationAlarmComponent implements OnInit {
  @Input() customerList: any = [];
  activePage: number = 1;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  public orderNotificationList: any = [];
  public orderNotificationListMasterData: any = [];
  public orderNotificationListCustomize: any = [];
  public titles: any;
  public totalCountStock: any;
  public totalPagingCountStock: any;
  stockQueryParams: any = {};
  showLoader: boolean = false;
  public notificationalarmcolumns: ColumnSetting[] = [];
  tableName: any = 'dashboardordernotificationalarm';

  public filterFields: any = [];
  public stockAlarmListQuery: any = {};
  resetPaginationNumber: boolean = false;
  loginuserdetails: any;
  webshopDetail: any;
  isParentCustomer: any;
  actions: any = ['redirectToOrderProcessPage']
  constructor(public dashboardservice: DashboardService, public dashboardhttpservice: DashboardHttpService, public apiService: ApiService, private router: Router,
    private linkTableService: LinkTableService, private translate: TranslateService) {
    this.titles = this.translate.instant('DASHBOARD.STOCKALARMS');
    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.notificationalarmcolumns = [
      {
        primaryKey: 'SuborderNumber',
        header: this.translate.instant('DASHBOARD.SUBORDER')
      },
      {
        primaryKey: 'CompanyName',
        header: this.translate.instant('DASHBOARD.COMPANYNAME')
      },
      {
        primaryKey: 'DeliveryCountryName',
        header: this.translate.instant('DASHBOARD.DELIVERYCOUNTRY')
      },
      {
        primaryKey: 'InvoiceCountryName',
        header: this.translate.instant('DASHBOARD.INVOICECOUNTRY')
      },
      {
        primaryKey: 'CustomerName',
        header: this.translate.instant('DASHBOARD.WEBSHOP'),
      },
      {
        primaryKey: 'DaysToNotificationAlarm',
        header: this.translate.instant('DASHBOARD.DAYSTONOTIFICATIONALARM'),
      },
      {
        primaryKey: 'IsNotificationSent',
        header: this.translate.instant('DASHBOARD.SENTNOTIFICATION'),
      }
    ];
    this.onLoadFunctions();
  }

  ngOnInit() {
    let webshopEnableStatus = true;
    if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
      webshopEnableStatus = false;
      if (this.webshopDetail.IsWebshopDropdown) {
        webshopEnableStatus = true;
      }
    }
    this.filterFields = [
      {
        label: this.translate.instant('MENU.WEBSHOPS'),
        type: 'select',
        key: 'selectwebshop',
        data: [],
        placeholder: this.translate.instant('CATEGORIES.SELECT_ALL'),
        class: (webshopEnableStatus && this.isParentCustomer) ? 'col-6' : ''
      },
    ];
    this.stockAlarmListQuery.webshopID = 'All';
    this.getDashboardNotificationAlarmList();
    this.getAllCustomerList();
  }

  onLoadFunctions() {
    this.stockQueryParams.customerID = this.webshopDetail.CustomerID;
    // this.dashboardservice.loadStock(this.stockQueryParams);
  }

  getSelectData(alarmName) {
    if (alarmName.selectwebshop !== undefined && alarmName.selectwebshop.selectMethod === 'selectwebshop') {
      if (alarmName.selectwebshop.value !== '') {
        this.stockAlarmListQuery.webshopID = alarmName.selectwebshop.value;
      } else {
        this.stockAlarmListQuery.webshopID = 'All';
      }
      this.activePage = 1;
      this.perPagedata = 10;
    }
    this.stockAlarmListQuery.pageIndex = 1;
    this.stockAlarmListQuery.pageSize = 10;
    this.getDashboardNotificationAlarmList();
  }

  paginationNum(event) {
    //console.log("paginationNum : ", event);
    this.activePage = (this.resetPaginationNumber) ? 1 : event;
    this.stockAlarmListQuery.pageIndex = (this.resetPaginationNumber) ? 1 : event;
    this.resetPaginationNumber = false;
    this.getDashboardNotificationAlarmList();
  }

  getPerPage(event) {
    //console.log(event)
    this.perPagedata = event;
    this.stockAlarmListQuery.pageSize = event;
    this.resetPaginationNumber = true;
  }

  redirectToOrderProcessPage(orderData: any) {
    if (orderData.OrderID !== undefined && orderData.OrderID !== null) {
      try {
        const id = orderData.OrderID;
        const dashboardData = {
          dashboardRedirectStatus: true
        }
        sessionStorage.setItem('dashboardRedirectStatus', JSON.stringify(true));
        this.router.navigate([`/admin-order-item/${id}`], {
          state: { id, dashboardData },
          replaceUrl: false
        });
      } catch (error) {
      }
    }
  }

  getDashboardNotificationAlarmList() {
    this.showLoader = true;
    const loginData = this.apiService.getLoginData();
    let searchData = {
      'customerID': loginData.CustomerID,
      // 'webshopID': (this.stockAlarmListQuery.webshopID !== undefined && this.stockAlarmListQuery.webshopID !== null) ?
      //  this.stockAlarmListQuery.webshopID : '',
      'pageIndex': (this.stockAlarmListQuery.pageIndex !== undefined && this.stockAlarmListQuery.pageIndex !== null) ?
        this.stockAlarmListQuery.pageIndex : this.activePage,
      'pageSize': (this.stockAlarmListQuery.pageSize !== undefined && this.stockAlarmListQuery.pageSize !== null) ?
        this.stockAlarmListQuery.pageSize : this.rowPagepage
    }
    if (this.webshopDetail.ParentCustomerID === null) {
      searchData['webshopID'] = (this.stockAlarmListQuery.webshopID !== undefined && this.stockAlarmListQuery.webshopID !== null) ?
        this.stockAlarmListQuery.webshopID : '';
      if (searchData['webshopID'] === 'All') {
        searchData['webshopID'] = this.webshopDetail.CustomerID;
      }
    } else {
      searchData['webshopID'] = this.webshopDetail.CustomerID;
    }
    //console.log("this.stockAlarmListQuery : ", this.stockAlarmListQuery);
    this.dashboardhttpservice.getOrderNotificationAlarmList(searchData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        data.ItemsCollection.forEach(element => {

          // tslint:disable-next-line:max-line-length

          if (element.IsNotificationSent == false) {
            // element.StockBalanceAlertTypeName = '<p class="text-success">Expires in ' + element.ExpiresInDays + ' days</p>';
            // tslint:disable-next-line:max-line-length
            element.IsNotificationSent = "No";
          } else {
            // element.IsNotificationSent = '<p class="text-danger">Delayed</p>';
            element.IsNotificationSent = "Yes";
          }
        });
        this.orderNotificationList = data.ItemsCollection;
        this.totalPagingCountStock = data.TotalItemsCount;
        this.enablepagination = true;
        if (data.ItemsCollection.length === 0) {
          this.enablepagination = false;
        }
        this.orderNotificationListMasterData = data;
      }
      else {
        this.showLoader = false;
      }
    },
      _err => {
        //console.log(err);
        this.showLoader = false;
      });
  }

  getAllCustomerList() {

    const webshopData = [{ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' }];
    this.customerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    })
    this.filterFields[0].data = webshopData;
  }



}
