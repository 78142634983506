import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PageloaderComponent } from './pageloader.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  declarations: [PageloaderComponent],
  exports: [PageloaderComponent],
})

export class PageLoaderModule { }
