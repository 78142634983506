import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ControlValidationService } from '../shared/control-validation/index';
import { SignupService } from "./state/services/signup.service";
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
import { DomSanitizer } from '@angular/platform-browser';
import { SignupHttpService } from "./state/services/signup.http.service";

@Component({
    selector: 'rewardkart-admin-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})

export class SignUpComponent implements OnInit {
    userPersonalDetails: FormGroup;
    showUserValidation: boolean;
    isUserExists: boolean;
    userName: any;
    customerID: any;
    webshopDetail: any;
  loaderShow: boolean;
  otpSection: boolean;
  emailotpSection: boolean;
  mobileotpSection: boolean;
  emailOTP: any;
  mobileOTP: any;
  mobileOTPError: boolean;
  emailOTPError: boolean;
  emailOTPSuccess: boolean;
  mobileOTPSuccess: boolean;
  @ViewChild('userDataPolicyModal', { static: true }) userDataPolicyModal: ModalDirective;
  userTerms: boolean;
  path: any;
  domainName: any;
    constructor( private sanitizer: DomSanitizer, private signuphttpService: SignupHttpService, private formBuilder: FormBuilder, private signupService: SignupService, private toastr: ToastService){
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));  
      this.customerID = this.webshopDetail.CustomerID;
      this.loadForm();
    }
    ngOnInit(){
      this.path = 'https://fs.rewardkartapi.in:8050' +'/UserDocuments/';
      this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(this.path+'terms_and_conditions.pdf');
    }

    loadForm(){
        this.userPersonalDetails = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            companyName: ['', [Validators.required, Validators.maxLength(50)]],
            confirmPassword: ['', [Validators.required]],
            Password: ['', [Validators.required, ControlValidationService.passwordValidator]],
            userName: ['', Validators.required],
            phoneNumber: ['', [Validators.required, ControlValidationService.phoneNumberValidator]],
            userEmail: ['', [Validators.required, ControlValidationService.emailValidator]],
            checked: ['', Validators.required]
        })
    }

    usernameValidate(username) {
        if (username) {
          const validateUserData = {
            username: username,
            customerID: (this.customerID !== null && this.customerID !== undefined &&
              this.customerID !== '') ? this.customerID : this.webshopDetail.CustomerID
          }
    
         // this.signupService.loadvalidateUser(validateUserData);
    
          try {
            if (username === '' || username === null) {
            } else {
              this.signuphttpService.validateUser(validateUserData).subscribe((data: any) => {
                if (data) {
                  this.showUserValidation = false;
                }else{
                  this.showUserValidation = true;
                }
    
              })
    
            }
          } catch (error) {
            this.toastr.error(error);
          }
        }
        else {
          this.showUserValidation = false;
        }
      }
      genOTP(){
        const fullname = this.userPersonalDetails.value.firstname + ' '+this.userPersonalDetails.value.lastname;
        const data = {
          mobile: this.userPersonalDetails.value.phoneNumber.trim(),
          fullname: fullname,
          userName: this.userPersonalDetails.value.userName.trim(),
          email: this.userPersonalDetails.value.userEmail.trim(),
        };
        this.loaderShow = true;

       // this.signupService.loadMobileOTP(data);

        try{
          if(fullname && data.mobile){
            this.signuphttpService.triggerMobileOTP(data).subscribe((res)=>{
              if(res){
                this.loaderShow = false;
                this.otpSection = true;
                this.mobileotpSection = true;
              }else{
              this.loaderShow = false;
              this.toastr.error('Error in generating Mobile OTP');
              }
            },_error=>{
              this.loaderShow = false;
              this.toastr.error('Error in generating Mobile OTP');
              this.loaderShow = false;
            });
          }

          if(fullname && data.email){
            this.signuphttpService.triggerEmailOTP(data).subscribe((res)=>{
              if(res){
                this.loaderShow = false;
                this.otpSection = true;
                this.emailotpSection = true;
              }else{
                this.loaderShow = false;
                this.toastr.error('Error in generating email OTP');
              }
            },_error=>{
              this.loaderShow = false;
              this.toastr.error('Error in generating email OTP');
              this.loaderShow = false;
            });
          }
        } catch (error) {
            this.toastr.error(error);
          }
      }

      validateOTP(){
        const fullname = this.userPersonalDetails.value.firstname + ' '+this.userPersonalDetails.value.lastname;
        const data = {
          mobile: this.userPersonalDetails.value.phoneNumber.trim(),
          fullname: fullname,
          userName: this.userPersonalDetails.value.userName.trim(),
          email: this.userPersonalDetails.value.userEmail.trim(),
          emailOTP: this.emailOTP,
          mobileOTP: this.mobileOTP,
        };
        this.loaderShow = true;

       // this.signupService.loadMobileOTP(data);

        try{
          if(fullname && data.mobile){
            
            this.signuphttpService.validateMobileOTP(data).subscribe((res)=>{
              if(res){
                this.loaderShow = false;
                this.mobileOTPSuccess =true;
                this.checkOTP();
              }else{
                this.loaderShow = false;
                this.mobileOTPError = true;
              }
            },_error=>{
              this.loaderShow = false;
              this.toastr.error('Invalid Mobile OTP');
              this.loaderShow = false;
            });
          }

          if(fullname && data.email){
            this.loaderShow = true;
            this.signuphttpService.validateEmailOTP(data).subscribe((res)=>{
              if(res){
                this.emailOTPSuccess =true;
                this.checkOTP();
              }else{
                this.loaderShow = false;
                this.emailOTPError = true;
              }
            },_error=>{
              this.loaderShow = false;
              this.toastr.error('Invalid Email OTP');
              this.loaderShow = false;
            });
          }
        } catch (error) {
            this.toastr.error(error);
          }
      }

      ispasteFunc(_event: ClipboardEvent, formControlName) {
      }
    
      isPhoneNumber(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode < 40 || charCode > 57) || (charCode === 42) || (charCode === 44)) {
          return false;
        }
        return true;
      }

      
      checkOTP(){
        if(this.emailOTPSuccess && this.mobileOTPSuccess){

          this.loaderShow = false;
          const params = {
            "Salutation":"",
            "Name": this.userPersonalDetails.value.firstname+' '+this.userPersonalDetails.value.lastname,
            "firstname": this.userPersonalDetails.value.firstname,
            "lastname": this.userPersonalDetails.value.lastname,
            "username": this.userPersonalDetails.value.userName,
            "jobposition":"",
            "password": this.userPersonalDetails.value.Password,
            "companyname": this.userPersonalDetails.value.companyName,
            "customerid": this.webshopDetail.CustomerID,
            "languageid":1,
            "currencyid":20,
            "phonenumber":  this.userPersonalDetails.value.phoneNumber,
            "email": this.userPersonalDetails.value.userEmail,
            "emailforinvoices": '', 
            'Address': 'Address',
            'Address2': '',
            'Address3': '',
            'City': 'city',
            'PostalCode': '123456',
            "DeliveryContactPerson":"",
            "DeliveryInstructions":"",
            "DeliveryAddress":"DeliveryAddress",
            "DeliveryAddress2":"DeliveryAddress",
            "DeliveryAddress3":"",
            "DeliveryCity":"city",
            "DeliveryPostalCode":"123456",
            "DeliveryCountry":"IN",
            "SameAsCompany":true,
            "Department":"",
            "Comment":"",
            "EANNumber":"",
            "ImageName":null,
            "PanImageName":null,
            "GSTImageName":null,
            "ROCImageName":null,
            "RoleCode":"Webshop_Manager",
            "UserType":"U",
            "FirstLogin":true,
            "VAT":"",
            "PaymentByInvoice":false,
            "PaymentByCard":true,
            "isDeleted":false,
            "Country": "India",
            "CountryName":"India",
            "isSameAsInvoiceAddress":true,
            "isTermsAndPolicyAccepted":true,
            "KYCStatus":false,
            "IsParentShop":true,
            "IsVoucher":false
          }
          
          this.loaderShow = true;
          this.signuphttpService.registerUser(params).subscribe(
            (data: any) => {
              this.loaderShow = false;
              if (data['UserID']) {
                this.toastr.success('Your account has been created. Please login with your username and password.');
              }else{
                this.toastr.success('Please Try Again Later!');
              }
              setTimeout(()=>{
                location.reload();
              },5000)
            }, error => {
              this.loaderShow = false;
              this.toastr.error(error);
            });
        }
        
      }

      termsModal(){
        this.userTerms = !this.userTerms;
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(this.path+'91sb_terms_and_conditions.pdf');
        this.userDataPolicyModal.show();
      }
}