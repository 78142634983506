import { Action } from '@ngrx/store';
export const LOAD_CATEGORIES = '[Category] Load all Categorys';
export const LOAD_CATEGORIES_SUCCESS = '[Category] Load Categorys Success';
export const LOAD_CATEGORIES_FAIL = '[Category] Load Categorys Fail';
export const SAVE_CATEGORY = '[Category] Save Category';
export const SAVE_CATEGORY_SUCCESS = '[Category] Save Categorys Success';
export const SAVE_CATEGORIES_FAIL = '[Category] Save Category Fail';
export const SELECTED_CATEGORY = '[Category] Selected Category';
export const CUSTOMER_CATEGORY_LIST = '[Category] Load all Customer Categories ';

export class LoadCategory implements Action {
    readonly type = LOAD_CATEGORIES;
    constructor(public payload: any) { }
}

export class LoadCategorySuccess implements Action {
    readonly type = LOAD_CATEGORIES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCategoryFail implements Action {
    readonly type = LOAD_CATEGORIES_FAIL;
    constructor(public payload: any) { }
}

export class SaveCategory implements Action {
    readonly type = SAVE_CATEGORY;
    constructor(public payload: any) { }
}

export class SaveCategorySuccess implements Action {
    readonly type = SAVE_CATEGORY_SUCCESS;
    constructor(public payload: any) { }
}

export class SaveCategoryFail implements Action {
    readonly type = SAVE_CATEGORIES_FAIL;
    constructor(public payload: any) { }
}

export class SelectedCategory implements Action {
    readonly type = SELECTED_CATEGORY;
    constructor(public payload: any) { }
}

export class CustomerCategoryList implements Action {
    readonly type = CUSTOMER_CATEGORY_LIST;
    constructor(public payload: any) { }
}

export type All =
    | LoadCategory
    | LoadCategorySuccess
    | LoadCategoryFail
    | SaveCategory
    | SaveCategorySuccess
    | SaveCategoryFail
    | SelectedCategory
    | CustomerCategoryList;

