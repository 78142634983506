import { UserListHttpService } from './state/services/user.http.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { UserSearchModule } from './user-search/user-search.module';
import { UserPointsEditModule } from './user-edit/user-edit.module';
import { PointsSummaryModule } from './points-summary/points-summary.module';
import { UserPointsComponent } from './user-list/user-list.component';
import { SharedModule } from '../shared/shared.module';
import { UserEffects } from './state/effects/user.effects';
import { userReducers } from './state/reducers/user.reducer';
import { UserService } from './state/services/user.service';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { CommonFormModule } from '../common/common-form/common-form.module';
import { FilterTagModule } from '../common/common-filter/filter-tags/filter-tags.module';
import { userAdditionalDeliveryListModule } from './useradditionaldeliverylist/useradditionaldeliverylist.module';


@NgModule({
  imports: [
    UserPointsEditModule,
    PointsSummaryModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    UserSearchModule,
    CommonFilterModule,
    CommonFormModule,
    FilterTagModule,
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('userModule', userReducers),
    userAdditionalDeliveryListModule
  ],
  declarations: [UserPointsComponent],
  providers: [UserListHttpService, UserService],
  exports: [UserPointsComponent, UserPointsEditModule, PointsSummaryModule]
})

export class UserPointsModule { }
