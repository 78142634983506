import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormsModule, Validators } from '@angular/forms';
import { MessageboxService } from '../state/services/messagebox.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { ControlValidationService } from '../../shared/control-validation/index';
import { Location } from '@angular/common';

@Component({
  selector: 'app-message-compose',
  templateUrl: './message-compose.component.html',
  styleUrls: ['./message-compose.component.scss']
})
export class MessageComposeComponent implements OnInit {
  // @Input() loadApiStatus:any;
  composeMessageForm: FormGroup;
  // @Output() public closeComposeMailModal = new EventEmitter<any>();
  myControl = new FormControl();
  keyword = '';
  userMailAddress;
  searchText = '';
  htmlContent: any;
  composeTo: any;
  isSearchTextError = false;
  isSubjectTextError = false;
  textErrorMsg = 'Please fill out this field';
  webshopDetail: any;
  constructor(public apiService: ApiService,
    public messageboxService: MessageboxService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toast: ToastService,
    private location: Location,
    private router: Router) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.createForms();
    this.loadForm();
  }

  loadForm() {
    this.composeMessageForm = this.formBuilder.group({
      composesubject: ['', [Validators.required]],
      htmlContent: ['', Validators.required]
    });
  }

  getRequestBody(val) {
    const loginData = this.apiService.getLoginData();
    const requestBody = {
      "SenderID": loginData.UserID,
      "ReceiverID": this.composeTo.UserID,
      "SenderType": "SU",
      "ReceiverType": "g",
      "Subject": this.composeMessageForm.value.composesubject,
      "Body": this.composeMessageForm.value.htmlContent,
      "Status": "NEW",
      "EmailType": "Email",
      "isDraft": val == 'send' ? false : true,
      "CustomerID": this.webshopDetail.CustomerID,
    };
    // if(this.composeTo.UserID == undefined && this.composeTo.UserID == ''){
    //   requestBody["ReceiverID"] = '';
    // }
    return requestBody;
  }

  sendMessage() {
    if (this.composeMessageForm.controls.composesubject.value == '' || this.searchText == '') {
      if (this.composeMessageForm.controls.composesubject.value == '') {
        this.isSubjectTextError = true;
      }
      if (this.searchText == '') {
        this.isSearchTextError = true;
      }
    } else {
      if (this.composeTo == undefined) {
        this.toast.error('Please choose receiver\'s address from auto suggest box');
      } else {
        this.messageboxService.sendMessage(this.getRequestBody('send'));
        this.messageboxService.getSendMessageSuccess().subscribe(data => {
          if (data == null) {
            setTimeout(() => {
              this.toast.success('Email sent successfully');
              this.pageredirect();
            }, 300)
          }
        });
      }

    }
  }


  createForms() {
    this.composeMessageForm = this.formBuilder.group({
      composeto: [''],
      composesubject: [''],
      htmlContent: [''],
    });
  }

  draftMessage() {

    if (this.composeMessageForm.controls.composesubject.value == '') {
      this.toast.warning('Subject should not be empty');
    } else {
      if (this.composeTo == undefined) {
        this.toast.error('To is required field');
      }
      this.messageboxService.sendMessage(this.getRequestBody('draft'));
      this.messageboxService.getSendMessageSuccess().subscribe(data => {
        if (data == null) {
          this.toast.success('Message saved in draft folder');
          this.pageredirect();
        }

      });
    }
  }


  deleteMessage() {
    this.pageredirect();
  }

  onChangeSearch(val: string) {
    this.isSearchTextError = false;
    if (val.length > 3) {
      const loginData = this.apiService.getLoginData();
      const request = {
        val: val,
        custID: this.webshopDetail.CustomerID
      }
      this.messageboxService.getUserMailAddress(request);
      this.messageboxService.getUserMailAddressSuccess().subscribe((data: any) => {
        if (data !== null) {
          this.userMailAddress = data;
        }
      });
    }
  }

  onChangeSubject(val) {
    this.isSubjectTextError = false;
  }

  selected(val) {
    this.composeTo = val;
  }

  pageredirect() {
    this.router.navigate(['/app-message-inbox'], { replaceUrl: true });
  }
}
