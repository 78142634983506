import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CompanyOrderSummaryComponent } from './company-order-summary.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [CompanyOrderSummaryComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule
    ],
    exports: [CompanyOrderSummaryComponent]
})
export class CompanyOrderSummaryModule { }