import { Component, Input, OnChanges } from '@angular/core';
import {
  ColumnSetting,
  ColumnMap,
} from '../link-table/models/link-table-model';

@Component({
  selector: 'rewardkart-admin-link-editable-table',
  templateUrl: './link-editable-table.component.html',
  styleUrls: ['./link-editable-table.component.scss'],
})
export class LinkEditableTableComponent implements OnChanges {
  @Input() rows: any[];
  @Input() columns: ColumnSetting[];
  columnMaps: ColumnMap[];
  constructor() {
    console.log('tabe', this.rows, this.columns);
  }

  ngOnChanges() {
    if (this.columns) {
      this.columnMaps = this.columns.map((col) => new ColumnMap(col));
    } else {
      this.columnMaps = Object.keys(this.rows[0]).map((key) => {
        return new ColumnMap({ primaryKey: key });
      });
    }
  }

  updateList(index: number, property: string, event: any) {
    event.preventDefault();
    const editField = event.target.value;
    this.rows[index][property] = editField.trim();
  }

  changeValue(id: number, property: string, event: any) {
    // console.log(event.target.textContent);
  }
}
