import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessageInboxService {
    updateReadUnreadCount = new Subject();
    setMenuActive = new Subject()
}
 