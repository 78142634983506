import { Component, OnInit } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { WarehouseHttpService } from '../state/services/warehouse.http.service';

@Component({
  selector: 'rewardkart-admin-logistic-vendor',
  templateUrl: './logistic-vendor.component.html',
  styleUrls: ['./logistic-vendor.component.scss']
})
export class LogisticVendorComponent implements OnInit {

  header: any;
  tableName: string = 'logisticvendorlist';
  filterFields: any = [];
  logisticvendorlist: any = [];
  logisticvendordatacount: any;
  // actions: any = ['editsupplier', 'delete', 'viewsupplier'];
  actions: any = ['delete', 'viewsupplier'];
  submitted = false;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  activePage: number = 1;
  rowactivePage: number = 1;
  resetStatus: boolean = false;
  searchCriteria: any = {};
  filterData: any = {};
  passFilterresult: any = [];
  commonRemoveFilter: any;
  showLoader: Boolean = false;
  isParentCustomer: Boolean = true;
  webshopDetail: any;
  childwebshop: any = [];
  CustomerID: any = "";
  columns: ColumnSetting[] = [
    {
      primaryKey: 'Name',
      header: 'Name'
    },
    {
      primaryKey: 'Address',
      header: 'Address',
    },
    {
      primaryKey: 'ContactPerson',
      header: 'Contact Person',
    },
    {
      primaryKey: 'Email',
      header: 'Email',
    },
    {
      primaryKey: 'PhoneNumber',
      header: 'Phone Number',
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];
  constructor(private apiService: ApiService, private router: Router,
    private linkTableService: LinkTableService, private toast: ToastService, private warehousehttpservice: WarehouseHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    } else {
      this.webshopDetail.CustomerID = this.webshopDetail.CustomerID;
    }
    this.CustomerID = this.webshopDetail.CustomerID;
    this.getChildWebshop();
  }

  ngOnInit() {
    this.header = 'LogisticVendor Details'; // Multilingual to be implemented
    const searchkey = sessionStorage.getItem('searchLogisticVendorKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      // tslint:disable-next-line:max-line-length
      if (sessionStorage.getItem('logisticvendorEditRedirect') !== null && sessionStorage.getItem('logisticvendorEditRedirect') !== undefined) {
        this.activePage = this.searchCriteria.activePage;
        sessionStorage.removeItem('logisticvendorEditRedirect');
      } else {
        this.activePage = 1;
        this.searchCriteria.activePage = 1;
      }
      sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
    }
    this.onLoadLogisticVendor();
  }

  getChildWebshop() {
    this.warehousehttpservice.getCustomersListInclParent(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.childwebshop = [];
        this.childwebshop.push({ label: 'Select All', value: '0' });
        data.forEach(element => {
          const langVal = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.childwebshop.push(langVal);
        });
        // we have only one filter(webshop dropdown).so,based on parter login we will show filter section(show only for parent shop)
        if (this.isParentCustomer) {
          this.setfilterData();
        }
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  onLoadLogisticVendor() {
    const searchkey = sessionStorage.getItem('searchLogisticVendorKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.getlogisticvendorList(JSON.parse(searchkey));
    } else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        pageSize: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        pageIndex: 10
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
      this.getlogisticvendorList(this.searchCriteria);
    }
  }

  getlogisticvendorList(searchCriteria?) {
    console.log("searchCriteria : ", searchCriteria);
    if (!this.webshopDetail.IsWebshopDropdown) {
      searchCriteria.customerId = this.webshopDetail.CustomerID;
    }
    if (searchCriteria.customerId === '' || Number(searchCriteria.customerId) === 0) {
      searchCriteria.customerId = '';
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (this.webshopDetail.ParentCustomerID !== null) {
          searchCriteria.customerId = this.webshopDetail.CustomerID;
        }
      }
    }
    console.log("searchCriteria.customerId : ", searchCriteria.customerId);
    const searchdata = {
      activePage: this.activePage,
      pageSize: (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10,
      customerId: (searchCriteria.customerId !== null && searchCriteria.customerId !== undefined) ? searchCriteria.customerId : '',
    };
    console.log("searchdata : ", searchdata);
    this.showLoader = true;
    this.warehousehttpservice.getCustomerLogisticVendor(searchdata).subscribe((datas: any) => {
      this.showLoader = false;
      if (datas) {
        this.logisticvendorlist = [];
        this.logisticvendorlist = datas.ItemsCollection;
        this.logisticvendordatacount = datas.TotalItemsCount;
        const webshop = sessionStorage.getItem('webshopDetail');

        // tslint:disable-next-line:max-line-length
        /** If login as child customer then we need to restrict Edit/Delete Supplier based on condition (CreatedByCustomerID = loggedincustomerId) */
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
          //  const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
          this.logisticvendorlist.forEach(element => {
            element.EditActionEnabled = true;
            element.DeleteActionEnabled = true;
            // if (webshopDetail.ParentCustomerID !== null) {
            if (!element.IsEditable && this.webshopDetail.ParentCustomerID !== null) {
              element.EditActionEnabled = false;
            }
            if (!element.IsDeletable && this.webshopDetail.ParentCustomerID !== null) {
              element.DeleteActionEnabled = false;
            }
          });
        }
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  /** Paginaion code Section Functions */
  getPerPage(event) {
    console.log("Get Per Page : ", event);
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(this.searchCriteria);
  }

  paginationNum(event) {
    console.log("paginationNum : ", event);
    this.activePage = event;
    const searchkey = sessionStorage.getItem('searchLogisticVendorKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    this.searchCriteria.activePage = event;
    sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
    this.perPagedata = this.searchCriteria.pageIndex;
    this.getlogisticvendorList(this.searchCriteria);
  }


  /** Filter code Section Functions */
  setfilterData() {
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Select Webshop',
        type: 'select',
        class: 'col-md-12',
        key: 'selectwebshop',
        data: this.childwebshop,
        selected: (this.webshopDetail.IsWebshopDropdown && isParentCustomer) ? this.CustomerID : '',
        required: false,
        filterkey: 'selectwebshop'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      },
    ];

    const searchResult = sessionStorage.getItem('searchLogisticVendorResult')
    if (searchResult !== null) {
      const searchLogisticVendorResult = JSON.parse(sessionStorage.getItem('searchLogisticVendorResult'));

      /** To Prefill Webshop dropdown value(based on Login) */
      // tslint:disable-next-line:max-line-length
      if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
        const getIndex1 = searchLogisticVendorResult.findIndex(ind => ind.key === 'selectwebshop');
        if (this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
          this.filterFields[getIndex1].selected = searchLogisticVendorResult[getIndex1].selected;
        } else {
          this.filterFields[getIndex1].selected = '';
        }
      }
    }
  }

  getSelectData(data) {
    console.log("getSelectData : ", data);
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        customerId: data.selectwebshop !== undefined && data.selectwebshop.selectMethod === 'selectwebshop' ? data.selectwebshop.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    } else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }

    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'selectwebshop' && this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
        element.selected = this.searchCriteria.customerId;
      }
    });
  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    this.getlogisticvendorList(this.filterData);
    sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchLogisticVendorResult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.CustomerID = this.webshopDetail.CustomerID;
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    // this.filterFields.forEach(element => {
    //   if (element.filterkey === 'supplier') {
    //     element.selected = '';
    //   }
    //   if (element.filterkey === 'selectwebshop') {
    //     element.selected = '';
    //   }
    // });
    this.resetStatus = true;
    // this.getPerPage(10);
    this.getSelectData(undefined);
    sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchLogisticVendorResult', JSON.stringify(this.filterFields));
    this.setfilterData();
    this.onLoadLogisticVendor();
  }


  /** Remove Filter Tags Section Code */
  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchkey = sessionStorage.getItem('searchLogisticVendorKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    if (val !== undefined) {
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = this.webshopDetail.CustomerID;
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
      });
      sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchLogisticVendorResult', JSON.stringify(this.filterFields));
      this.getlogisticvendorList(this.filterData);
    }
  }

  getFilterResult(filterresult) {
    this.passFilterresult = filterresult;
  }

  /** Table Actions(Edit, Delete, View, Add) Functions */
  addLogisticVendor(event: boolean) {
    this.submitted = event;
  }

  viewLogisticVendor(event: boolean) {
    this.submitted = event;
  }


  editLogisticVendor(data: any) {
    console.log("Edit Dt : ", data);
    if (data !== '') {
      const searchkey = sessionStorage.getItem('searchLogisticVendorKey');
      if (searchkey !== null && searchkey !== '[object Object]') {
        this.searchCriteria = JSON.parse(searchkey);
        this.perPagedata = 10;
        this.searchCriteria.pageIndex = 10;
        this.resetStatus = true;
        sessionStorage.setItem('searchLogisticVendorKey', JSON.stringify(this.searchCriteria));
      }
      //  this.showUserEditpage = true;
      data.logisticVendorEditPage = true;
      data.isParentCustomer = (this.webshopDetail.ParentCustomerID === null) ? true : false;
      this.router.navigate(['/logisticvendor-edit'], { state: { data }, replaceUrl: false });
    }
  }

  deleteLogisticVendor(deletedData: any) {
    this.showLoader = true;
    this.warehousehttpservice.deleteLogisticVendor(deletedData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.toast.success('Logistic vendor(' + deletedData.Name + ') deleted successfully');
        this.onLoadLogisticVendor();
      } else {
        this.toast.error('Logistic vendor(' + deletedData.Name + ') cannot be deleted');
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);
      });
  }

  /** Page Reedirect Functions */
  pageredirect() {
    this.router.navigate(['/logisticvendor-add'], { replaceUrl: true });
  }

}
