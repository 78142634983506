import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MessageboxHttpService {
    private messageDragData = new BehaviorSubject([]);
    private getDragData = new BehaviorSubject([]);
    constructor(private apiService: ApiService) { }
    // message inbox
    getMessageLineData(searchCriteria) {
        let path = '';
        if (searchCriteria.showAll) {
            path = 'en/emails?role_code=' + searchCriteria.roleCode + '&customerId=' + searchCriteria.customerID + '&inbox=true&includeChildCustomers=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize;
        }
        else {
            path = 'en/emails?user_id=' + searchCriteria.UserID + '&inbox=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID;
        }

        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message draft
    getMessageInboxdetailsData(searchCriteria) {
        const path = 'en/emails?email_id=' + searchCriteria.emailID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message draft
    getMessageImportantdetailsData(searchCriteria) {
        const path = 'en/emails?email_id=' + searchCriteria.emailID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message draft
    getMessageClaimsdetailsData(searchCriteria) {
        const path = 'en/claims?claim_id=' + searchCriteria.ClaimID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message draft
    getMessageInquiriesdetailsData(searchCriteria) {
        const path = 'en/inquiries/' + searchCriteria.InquiryID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message draft
    getMessageDraftData(searchCriteria) {
        let path = '';
        if (searchCriteria.showall) {
            path = 'en/emails?role_code=ANY&customerId=' + searchCriteria.customerID + '&includeChildCustomers=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&emailType=drafts'
        }
        else {
            path = 'en/emails?user_id=' + searchCriteria.UserID + '&drafts=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message important
    getMessageImportantData(searchCriteria) {
        let path = '';
        if (searchCriteria.showall) {
            path = 'en/emails?role_code=ANY&customerId=' + searchCriteria.customerID + '&includeChildCustomers=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&emailType=important'
        }
        else {
            path = 'en/emails?user_id=' + searchCriteria.UserID + '&important=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message important
    getMessageInquiriesData(searchCriteria) {
        const path = 'en/inquiries?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID;

        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message important
    getMessageTrashData(searchCriteria) {
        let path = '';
        if (searchCriteria.showAll) {
            path = 'en/emails?role_code=ANY&customerId=' + searchCriteria.customerID + '&includeChildCustomers=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&emailType=trash'
        }
        else {
            path = 'en/emails?user_id=' + searchCriteria.UserID + '&trash=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message important
    getMessageClaimsData(searchCriteria) {
        const path = 'en/claims?user_id=' + searchCriteria.UserID + '&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID + '&inbox=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // message important
    getMessageSentData(searchCriteria) {
        let path = '';
        if (searchCriteria.showAll) {
            path = `en/emails?role_code=ANY&customerId=${searchCriteria.customerID}&sent=true&includeChildCustomers=true&pageIndex=${searchCriteria.activePage}&pageSize=${searchCriteria.pageSize}`;
        }
        else {
            path = 'en/emails?user_id=' + searchCriteria.UserID + '&sent=true&pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&CustomerID=' + searchCriteria.customerID;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomers(searchCriteria) {
        const path = '/en/customers/?ParentCustomerID=' + searchCriteria.customerID + '&returnParentCustomer=' + true;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    // for read and unread messages


    bulkAction(searchCriteria, UserID) {
        const path = 'en/emails/bulkactions?user_id=' + UserID;
        return this.apiService.post(path, searchCriteria).map((res) => {
            return res;
        });

    }

    sendMessage(data: any) {
        const path = 'en/emails';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    sendDraftMessage(data: any) {
        const path = 'en/emails';
        return this.apiService.put(path, data).map((res) => {
            return res;
        });
    }

    deleteMessage(data: any, UserID) {
        const path = 'en/emails/bulkactions?userID=' + UserID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getUserMailAddress(req: any) {
        const path = 'en/users?name=' + req.val + '&customerID=' + req.custID;

        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteMail(data: any) {
        const path = 'en/emails';
        return this.apiService.put(path, data).map((res) => {
            return res;
        });
    }



    unreadMessage(searchCriteria) {
        const path = 'en/emails_setasread/' + searchCriteria.emailid + '?CustomerID=' + searchCriteria.customerid;
        return this.apiService.put(path).map((res) => {
            return res;
        });
    }

    inquriesunreadMessage(searchCriteria) {
        const path = 'en/inquiries_setasread/' + searchCriteria.InquiryID + '?CustomerID=' + searchCriteria.customerid;
        return this.apiService.put(path).map((res) => {
            return res;
        });
    }

    claimsunreadMessage(searchCriteria) {
        const path = 'en/claims_setasread/' + searchCriteria.ClaimID + '?CustomerID=' + searchCriteria.customerid;
        return this.apiService.put(path).map((res) => {
            return res;
        });
    }

    dragAndDropMessage(data) {
        const path = 'en/emails/bulkactions';
        return this.apiService.post(path, data);
    }

    moveEmails(data) {
        const path = 'en/emails/MoveEmail';
        return this.apiService.post(path, data);
    }

    emailSetAsRead(setData) {
        const path = 'en/emails_setasread/' + setData.EmailID + '?CustomerID=' + setData.CustomerID + '&isUnread=true';
        return this.apiService.put(path).map((res) => {
            return res;
        });
    }

    getEmailList(postParams) {
        const path = 'en/Users/EmailTrackingDetails'
        return this.apiService.post2(path, postParams)
    }

    updateStatus(id, role) {
        const path = 'en/Users/EmailUpdate?MailId=' + id + '&RoleName=' + role;
        return this.apiService.get2(path);
    }


}
