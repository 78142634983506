import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable()
export class UnderSupplierHttpService {
    webshopDetail: any;
    constructor(private apiService: ApiService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getUnderSupplierList(searchData: any) {
        const searchCriteria = {
            activePage: searchData.activePage !== undefined ? searchData.activePage : '',
            pageSize: searchData.pageSize !== undefined ? searchData.pageSize : ''
        }
        let path = '';
        let queryParam = '';
        if (searchData.undersupplierId !== '') {
            queryParam += '&SupplierID=' + searchData.undersupplierId;
        }
        if (searchData.name !== '') {
            queryParam += '&undersupplierName=' + searchData.name;
        }
        path = 'en/undersuppliers?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize +
            '&customerID=' + searchData.customerID + queryParam;
        return this.apiService.get(path).map((res) => {
            return res;
        }, error => {
            return error;
        });
    }


    getCustomerSupplier(customerId = null) {
        const path = 'en/suppliers/CustomerSupplier?isOrderbyname=true&CustomerId=' + customerId;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    getAllSuppliers() {
        const path = 'en/suppliers';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCountries() {
        const path = 'en/countries';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    getcustomerlist() {
        const path = 'en/customers/list';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    getCustomerUnderSupplier(customerId = null) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        // tslint:disable-next-line:max-line-length
        const path = 'en/suppliers?returnOnlyCustomerSupplier=true&CustomerId=' + customerId + '&LoggedInCustomerID=' + this.webshopDetail.CustomerID;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    addUnderSupplier(data: any) {
        let path = '';
        if (data.UndersupplierID !== null && data.UndersupplierID !== undefined && data.UndersupplierID !== '') {
            path = 'en/undersuppliers/' + data.UndersupplierID;
        } else {
            path = 'en/undersuppliers';
        }
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    deleteUnderSupplier(data: any) {
        const path = 'en/undersuppliers/' + data.UndersupplierID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    /**
    * Service to Check Postal Code for Selected Country
    */

    checkPostalCode(countryCode, postalCode) {
        // const userSettings = this.globalService.getUserSettings();
        const path = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCurrencies() {
        const path = 'en/currencies?isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    undersupplierRatingUpdata(data, supplierId) {
        const path = 'en/suppliers/updatequalitylevel/' + supplierId;
        return this.apiService.put(path, data).map((res) => {
            return res;
        });
    }

}
