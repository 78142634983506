
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryHttpService } from '../state/services/category.http.service';
import { CategoryService } from '../category-services/category.service';
import { CategoryGriddetailsComponent, SafePipe } from './category-griddetails.component';
// import { CategoryGriddetailsComponent } from './category-griddetails.component';
import { PageControlModule } from 'src/app/shared/page-control/page-control.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { DragdropDirective, DragItemImage } from '../directives/dragdrop.directive';
import { CategoryAddeditComponent } from '../category-addedit/category-addedit.component';
import { DragdropModule } from '../directives/dragdrop.module';
import { CategoryAddEditModule } from '../category-addedit/category-addedit.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    PageControlModule,
    NgxPaginationModule,
    DragdropModule,
    CategoryAddEditModule,
    TranslateModule
  ],
  declarations: [CategoryGriddetailsComponent],
  providers: [CategoryHttpService, CategoryService],
  exports: [CategoryGriddetailsComponent]
})

export class CategoryGridDetailsModule { }