import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderSearchModule } from '../order-search/order-search.module';
import { OrderProcessComponent } from './order-process.component';
import { OrderListModule } from '../order-list/order-list.module';

@NgModule({
    declarations: [
        OrderProcessComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        OrderListModule,
        OrderSearchModule
    ],
    entryComponents: [OrderProcessComponent]
})

export class OrderProcessModule { }