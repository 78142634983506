import {
  Component, OnInit, Input, Output, Pipe, PipeTransform, ViewChild, EventEmitter,
  ElementRef, HostListener, SimpleChanges, OnChanges
} from '@angular/core';
import { CategoryService } from '../state/services/category.service';
import { ProductService } from '../../product/state/services/product.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { CategoryHttpService } from '../state/services/category.http.service';
import { WebService } from 'src/app/restful-service/web.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
// import { ViewChild } from '@angular/core';
@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }
  // import { CategoryHttpService } from '../state/services/category.http.service';

  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
@Component({
  selector: 'app-category-griddetails',
  templateUrl: './category-griddetails.component.html',
  styleUrls: ['./category-griddetails.component.scss']
})
export class CategoryGriddetailsComponent implements OnInit, OnChanges {
  public previewFileUrl;
  optionsSelect: Array<any>;
  optionsSelect1: Array<any>;
  optionsSelect2: Array<any>;
  optionsSelect3: Array<any>;
  languageOption: any = [];
  translateForm: any = [];
  translateFormDesc: any = []
  translateFormMetaDesc: any = []
  wholeTranslateArray: any = [];
  wholeobj: {};
  checkAll: any;
  imgControl: any;
  @Input() header: any;
  @Input() optionsSelectCustomers: Array<any>;
  @Input() webshopLibrarySearchStatus: any;
  @Output() webshopLibrarySearchStatusChange = new EventEmitter();
  @Input() advanceSearchDataWebshopLibrary: any;
  @Input() webshopLibrarySelectStatus: any;
  @Output() webshopLibrarySelectStatusChange = new EventEmitter();
  @Input() advanceSearchSelectDataWebshopLibrary: any;
  selectedCustomerID: any; selectedCustomer: any = '';
  selectedCategoryID: any; selectedCategoryName: any;
  selectedSubCategoryID: any; selectedSubCategoryName: any;
  selectedProductID: any; selectedProductName: any;
  selectedImg: any;
  productTotalCount = 0;
  categoryList: any = []; subCategoryList: any = []; productList: any = []; selectedProductList: any = [];
  categoryExists = false; subCategoryExists = false; productExists = false; productSelected = false;
  selectedLang: any = 'en';
  name = 'Angular';
  fileToUpload: any;
  imageUrl: any;
  imgResponseIcon: any;
  previewFileUrlIcon: string;
  imgControlIcon: any;
  showPreIcon: boolean;
  showPreImg: boolean;

  imgName: boolean;
  imgNameicon: boolean;
  @ViewChild('categoryModel', { static: true }) categoryModel: ModalDirective;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  getData: any;
  editName: boolean;
  getId: any;

  totaldata: any;
  catNameReq: boolean;
  configpaginate: any = { itemsPerPage: 20, currentPage: 1, totalItems: 0 };
  showLoader: boolean;
  subSubCategory: any = [];
  subSubCategoryExist: Boolean = false;
  imgType: any;
  iconType: any;
  sendIds: any;
  selectIds: any = [];
  checkEvent = false
  showDeleteIcon: boolean;
  categoryDeleteID: any;
  selectedperpage: any = 20;
  pageSize: number = 0;
  activepage: number = 1;
  pageSizeOptions: number[] = [10, 30, 60, 90];
  pageList: any = [];
  rowPerPage: any = [{ label: 20, value: 20 }, { label: 40, value: 40 }, { label: 60, value: 60 }, { label: 80, value: 80 }];
  selectedpage: any = 1;
  totalCount: any;
  request: any = {};
  leftCopyRelatedProducts: Boolean = false;
  withselectAll: Boolean = false;
  excludeCategory: Boolean = false;
  withSubCategory: Boolean = false;
  withProducts: Boolean = false;
  typeList: any = {};
  resetModal: Boolean = false;
  getParentID: any = '';
  categoryName: any;
  showAddCategory: Boolean = true;
  relatedProduct: Array<any> = [];
  relatedProductcount: number = 0;
  relatedProductBatchID: Array<any> = []

  selectedperpageForCategory: any = 40;
  pageSizeForCategory: number = 0;
  activepageForCategory: number = 1;
  rowPerPageForCategory: any = [{ label: 20, value: 20 }, { label: 40, value: 40 }, { label: 60, value: 60 }, { label: 80, value: 80 }];
  selectedpageForCategory: any = 1;
  totalCountForCategory: any;
  pageListForCategory: any = [];
  searchCriteriaForCategory: any = {};
  checkedProduct: any = [];
  getDragData: any = [];
  getDragEvent: any;
  dragDataLength: any;
  getDragDatas: any = [];
  lastEvent: any = [];
  showValue: any = '';
  showData: any = {};
  getType: any = 'product';
  showPopup: Boolean = false;
  allowAction: Boolean = false;
  selectedCategory: any = [];
  isParentWebshop: Boolean = false;
  webshopDetail: any;
  constructor(public apiService: ApiService,
    public formBuilder: FormBuilder,
    public categoryService: CategoryHttpService,
    public categoryServices: CategoryService,
    public webService: WebService,
    private sanitizer: DomSanitizer,
    public toast: ToastService,
    private productService: ProductService,
    private categoryHttpService: CategoryHttpService,
    private translate: TranslateService
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  onLoadFunctions() {
    // this.selectedCategoryName = 'selectedCategory';
    // this.selectedSubCategoryName = 'selectedSubCategory';

  }

  ngOnInit() {
    // this.setFormControlName()
    // this.populateCategoryList();

    this.loadPerPage();
    this.resetPagination();
    this.changeType();
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this happens when click "Search" button in Advanced Search of  Webshop Library Section

    if (sessionStorage.getItem('isParentLoginAsChildShop') != undefined) {
      this.isParentWebshop = sessionStorage.getItem('isParentLoginAsChildShop') == "true" ? true : false;
    }
    if (this.webshopDetail.CustomerID === this.advanceSearchSelectDataWebshopLibrary.CustomerID || this.isParentWebshop) {
      this.allowAction = true;
    }
    else {
      this.allowAction = false;
    }
    // const webshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    // if (webshopDetails !== null && webshopDetails !== undefined) {
    //   if (webshopDetails.ParentCustomerID) {
    //     if (this.advanceSearchSelectDataWebshopLibrary.CustomerID === webshopDetails.ParentCustomerID) {
    //       this.advanceSearchSelectDataWebshopLibrary.childCustomerID = webshopDetails.CustomerID;
    //     }
    //     else {
    //       this.advanceSearchSelectDataWebshopLibrary.childCustomerID = null;
    //     }
    //   }
    // }

    if (this.webshopLibrarySearchStatus) {
      this.activepage = 1;
      this.selectedperpage = 20;
      this.advanceSearchDataWebshopLibrary.pageIndex = this.activepage;
      this.advanceSearchDataWebshopLibrary.pageSize = this.selectedperpage;
      this.advanceSearchCategoryList();
      this.changeType();
    }

    if (this.webshopLibrarySelectStatus) {
      this.activepage = 1;
      this.selectedperpage = 20;
      this.populateCategoryList();
      this.changeType();
    }
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
      this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    }
    else {
      this.showAddCategory = true;
    }
  }

  resetCategory(event) {
    this.resetModal = true
    setTimeout(() => {
      this.resetModal = false
    }, 1000)
  }

  loadPerPage() {
    this.optionsSelect = [
      { value: '10', label: '10' },
      { value: '30', label: '30', selected: true },
      { value: '60', label: '60' },
      { value: '90', label: '90' },
    ];
  }

  resetPagination() {
    // if (this.refreshdata) {
    this.selectedperpage = 20;
    // }
  }

  advanceSearchCategoryList() {
    this.selectedCategoryName = '';
    this.selectedSubCategoryName = '';
    this.selectedCategory = [];
    this.showLoader = true
    // this.categoryServices.loadCategorys(this.selectedCustomerID);
    this.categoryService.advacedSearchWebshopLibraryCategoryList(this.advanceSearchDataWebshopLibrary).subscribe(data => {
      if (data) {
        this.configpaginate = {
          itemsPerPage: Number(this.selectedperpage),
          currentPage: Number(this.activepage),
          totalItems: Number(data['TotalItemsCount'])
        };
        this.categoryExists = false;
        this.subCategoryExists = false;
        this.productExists = true;
        this.productList = data['ItemsCollection'];
        this.productList.forEach(element => {
          element.status = false;
        });
        this.productTotalCount = data['TotalItemsCount'];
        this.totalCount = data['TotalItemsCount'];

        let getPageList = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;

      } else {
        this.categoryExists = true;
      }
      this.showLoader = false;
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  onCustomerChange(event: any) {
    this.selectedCustomerID = event;
    this.resetCategoryDetailsValues();
    this.populateCategoryList();
  }

  resetCategoryDetailsValues() {
    this.selectedCategoryID = null;
    this.selectedCategoryName = null;
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.selectedCategory = [];
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
    this.productTotalCount = 0;
  }

  onSelectFile(data) {

  }


  populateCategoryList() {
    this.getParentID = '';
    this.selectedCategoryName = '';
    this.selectedSubCategoryName = '';
    this.selectedCategory = [];
    this.showLoader = true;
    this.subCategoryExists = false;
    this.categoryName = '';
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID) {
      this.subCategoryList = [];
    }



    this.categoryService.getCustomerCategoryList(this.selectedCustomerID, this.advanceSearchSelectDataWebshopLibrary).subscribe((data: any) => {
      if (data) {
        this.productList = [];
        this.productTotalCount = 0;
        this.categoryList = data;
        this.categoryExists = true;



      } else {
        this.categoryExists = false;
      }
      this.showLoader = false
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  setSelectedCategory(id: any, name: any) {
    this.getDragData = [];
    this.checkedProduct = [];
    this.selectIds = [];
    this.getParentID = id;
    this.categoryExists = false; //Hide category list
    this.selectedCategoryID = id;
    this.selectedCategoryName = name;
    this.categoryName = name;
    this.activepage = 1;
    this.selectedperpage = 20;
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
      this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    }
    else {
      this.showAddCategory = true;
    }
    this.resetSubCategoryDetailsValues();
    this.populateSubCategoryAndProductList(id);
    // this.searchProduct();
  }


  resetSubCategoryDetailsValues() {
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.selectedCategory = [];
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
    this.productTotalCount = 0;
  }

  populateSubCategoryAndProductList(categoryID: any) {
    const category = this.categoryList.find(x => x.CategoryID === categoryID);
    this.advanceSearchSelectDataWebshopLibrary.CategoryID = category.CategoryID;
    this.advanceSearchSelectDataWebshopLibrary.categoryId = category.CategoryID;
    if (category.SubCategories.length > 0) {
      this.subCategoryExists = true;
      this.subCategoryList = category.SubCategories;
    } else {
      this.subCategoryExists = false;
    }
    if (category.ProductsMainCount > 0) {
      this.productExists = true;
      this.populateProductList(true);
    } else {
      this.productExists = false;
    }
    this.productExists = false;
  }


  setSelectedSubCategory(id: any, name: any, data?) {
    this.getDragData = [];
    this.checkedProduct = [];
    this.selectIds = [];
    this.showAddCategory = false;
    this.activepage = 1;
    this.selectedperpage = 20;
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
    this.getParentID = id;
    this.subCategoryExists = false;
    this.selectedSubCategoryID = id;
    this.selectedSubCategoryName = name;
    if (!this.selectedCategory.some(x => x.ParentID == data.ParentID)) {
      this.selectedCategory.push({ "CategoryID": id, "ParentID": data.ParentID, "category": name, "click": this.setSelectedSubCategory.bind(this, id, name, data) });
    } else {
      let currentIndex = this.selectedCategory.findIndex(x => x.CategoryID == data.CategoryID)
      if (currentIndex !== -1) {
        this.selectedCategory = this.selectedCategory.slice(0, currentIndex + 1)
      }
    }
    this.categoryName = name;
    //  this.populateSubCategoryProductList(id);
    this.request = {
      "CurrencyID": this.advanceSearchSelectDataWebshopLibrary.CurrencyID,
      "CustomerID": this.advanceSearchSelectDataWebshopLibrary.CustomerID,
      "categoryId": id,
      "isAdmin": true,
      "childCustomerID": this.advanceSearchSelectDataWebshopLibrary.childCustomerID,
      "OnlyInCategoriesOfSupplierID": data.SupplierID,
      "activepage": this.activepage,
      "selectedperpage": this.selectedperpage
    }
    this.advanceSearchSelectDataWebshopLibrary.categoryId = id;
    this.advanceSearchSelectDataWebshopLibrary.isAdmin = true;
    this.advanceSearchSelectDataWebshopLibrary.pageIndex = 1;
    this.advanceSearchSelectDataWebshopLibrary.pageSize = 20;

    this.searchProduct();

    if (data.SubCategories.length > 0) {
      // this.subSubCategoryExist = true;
      // this.subSubCategory = data.SubCategories;
      this.subCategoryExists = true;
      this.productExists = false;
      this.subCategoryList = data.SubCategories;
      // this.populateSubCategoryProductList(id);
    }
    else {
      this.populateProductList(false, data);
      this.productExists = true;
    }
  }


  // setSelectedSubSubCategory(id: any, name: any, data?){
  // }

  // populateSubSubCategoryProductList(subCategoryID: any) {
  //   this.productSelected = null;
  //   const subCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
  //   if (subCategory.ProductsMainCount > 0) {
  //     this.populateProductList(false);
  //   } else {
  //     this.productExists = false;
  //   }
  // }


  populateSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subCategoryList.find(x => x.CategoryID === subCategoryID);
    const subSubCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    // if(subCategory !== undefined){
    if ((subCategory !== undefined && subCategory.ProductsMainCount > 0) || (subSubCategory !== undefined && subSubCategory.ProductsMainCount > 0)) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
    // }
    // else{
    //   if (subSubCategory.ProductsMainCount > 0) {
    //     this.populateProductList(false);
    //   } else {
    //     this.productExists = false;
    //   }
    // }
  }

  getSearchData(isCategory: boolean, request?) {
    let searchData: any;
    // if (this.selectedCustomerID === 0) {
    //   searchData = {
    //     CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true
    //   };
    // } else {
    //   if (isCategory) {
    //     searchData = {
    //       CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true
    //     };
    //   } else {
    //     searchData = {
    //       CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true, request: request
    //     };
    //   }
    // }
    if (this.webshopLibrarySearchStatus) {
      searchData = this.advanceSearchDataWebshopLibrary;
      searchData.isAdmin = true;
      // tslint:disable-next-line:max-line-length
      searchData.CategoryID = (request !== undefined) ? Number(request.CategoryID) : this.advanceSearchDataWebshopLibrary.CategoryID;
      searchData.pageIndex = 1;
      searchData.pageSize = this.configpaginate.itemsPerPage;
    }
    if (this.webshopLibrarySelectStatus) {
      searchData = this.advanceSearchSelectDataWebshopLibrary;
      searchData.isAdmin = true;
      // tslint:disable-next-line:max-line-length
      searchData.CategoryID = (request !== undefined) ? Number(request.CategoryID) : this.advanceSearchSelectDataWebshopLibrary.CategoryID;
      searchData.pageIndex = 1;
      searchData.pageSize = this.configpaginate.itemsPerPage;
    }
    if (!this.webshopLibrarySearchStatus && !this.webshopLibrarySelectStatus) {
      if (this.selectedCustomerID === 0) {
        searchData = {
          CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true
        };
      } else {
        if (isCategory) {
          searchData = {
            CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true
          };
        } else {
          searchData = {
            CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true, request: request
          };
        }
      }
    }
    this.showLoader = true;
    this.categoryHttpService.searchData(searchData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.productExists = true;
        this.productList = data['ItemsCollection'];
        this.productList.forEach(element => {
          element.status = false;
        });
        this.totalCount = data['TotalItemsCount'];
        this.productTotalCount = data['TotalItemsCount'];
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
        if (!isCategory) {
          this.loadPaginate();
        }
      } else {
        this.productExists = false;
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
    // return searchData;
  }

  populateProductList(isCategory, request?: any) {
    this.getSearchData(isCategory, request)

  }

  setProductSelected(selectedProduct: any) {
    const product = this.selectedProductList.find(x => x.ProductID === selectedProduct.ProductID);
    if (product === undefined) {
      this.selectedProductList.push(selectedProduct);
    } else {
      this.selectedProductList.splice(product);
    }
    this.productSelected = !this.productSelected;
  }

  ShowAddModel() {
    // this.setImgIcon = false;
    // this.setImg = false;
    // this.categoryForm.reset();
    this.previewFileUrl = '';
    this.previewFileUrlIcon = '';
    this.editName = false
    this.getData = '';
    this.categoryName = '';
    this.getParentID = this.getParentID;
    // setTimeout(() => {
    //   this.getData = '';
    // }, 500)
    this.categoryModel.show();
  }

  handleFileInput(file: FileList, event) {
    this.fileToUpload = file.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    formData.append('filePath', 'CategoryImages');
    // this.webService.uploadImg(formData).subscribe((data: any) => {
    // })
  }


  getCurrentLang(lang) {
    this.selectedLang = lang.value;
  }
  // createItem(sizeVal?): FormGroup {
  //   let getlang = this.apiService.getLanguages();
  //   this.translateFormItems = [];
  //   getlang.forEach(lang => {
  //     sizeVal.Translations.forEach(item => {
  //       if (lang.Code == item.LanguageCode) {
  //         let formJson = {
  //           formcontrolname: 'ProductSizeName' + lang.Code,
  //           translationID: 'TranslationID' + lang.Code,
  //           objectID: 'ObjectID' + lang.Code,
  //           class: lang.Name,
  //           name: item.PropertyName,
  //           LanguageID: lang.LanguageID,
  //           LanguageCode: lang.Code,
  //           PropertyName: item.PropertyName,
  //           PropertyValue: item.PropertyValue,
  //           TranslationID: item.TranslationID,
  //           ObjectID: item.ObjectID
  //         }
  //         this.translateFormItems.push(formJson);
  //       }
  //     });
  //   })
  //   let group: any = {
  //     productSizeID: sizeVal.ProductSizeID,
  //   }
  //   this.translateFormItems.forEach(element => {
  //     group[element.formcontrolname] = element.PropertyValue;
  //     group[element.translationID] = element.TranslationID;
  //     group[element.objectID] = element.ObjectID;
  //   })

  //   return this.formBuilder.group({ ...group });
  // } 
  setIdBased(id) {
    this.getId = id;
    this.editName = true;
    this.showLoader = true;
    this.getParentID = id;
    this.categoryService.getCategoryById(id).subscribe(data => {
      if (data) {
        this.getData = data
        this.showLoader = false
        this.categoryModel.show();
      }
    });
  }
  sendId(id, event) {
    this.showPopup = false;
    this.sendIds = id.ProductID;
    if (event.checked) {
      this.selectIds.push(this.sendIds)
      this.checkedProduct.push(id);
      if (this.sendIds)
        this.changeType()
    }
    else {
      let getIndex = this.selectIds.indexOf(this.sendIds)
      this.selectIds.splice(getIndex, 1);
      const getCheckedIndex = this.checkedProduct.findIndex(data => data.ProductID === this.sendIds);
      this.checkedProduct.splice(getCheckedIndex, 1)
      if (this.leftCopyRelatedProducts) {
        // this.relatedProductcount = this.relatedProductcount - this.relatedProduct[getCheckedIndex].Count
        if (this.relatedProduct.filter(x => x.ProductBatchID == this.relatedProduct[getCheckedIndex].ProductBatchID).length == 1)
          this.relatedProductcount -= this.relatedProduct[getCheckedIndex].Count
        this.relatedProduct.splice(getCheckedIndex, 1)
      }
      else {
        this.relatedProductcount = this.checkedProduct.length;
        this.relatedProduct.splice(getCheckedIndex, 1)
      }

    }
  }
  setDelete() {
    const requestData = {
      CustomerID: 1,
      Categories: this.selectIds,
      RemoveBatchProducts: false
      // target.control.checked
    }
    // this.categoryService.deleteOnlineProducts(requestData).subscribe((data) => {
    //   if (data !== null) {
    //     this.toast.success('Category Added Successfully')
    //   }
    // },
    //   error => {
    //     this.toast.error('Please fill mandatory  fields')
    //   }
    // );
  }

  getDeleteStatus() {
    const CategoryId = this.selectedCategoryID;
    this.showLoader = true;
    this.categoryService.deleteCategorylist(CategoryId).subscribe((data: any) => {
      this.showLoader = false;
      if (data === "Category cannot be deleted because it contains sub-categories.") {
        this.translate.get('CATEGORIES.CATEGORY_NOT_DELETED').subscribe((res: string) => {
          this.toast.error(res);
        });
        this.deleteModal.hide();
      }
      else {
        this.translate.get('CATEGORIES.CATEGORY_DELETED').subscribe((res: string) => {
          this.toast.success(res);
        });
        this.deleteModal.hide();
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }
  toggleModal() {
    this.categoryModel.hide();
  }


  setPageSizeOptions(setPageSizeOptionsInput) {
    this.selectedperpage = Number(setPageSizeOptionsInput);
    this.configpaginate.itemsPerPage = Number(setPageSizeOptionsInput);
    this.configpaginate.currentPage = 1;
    this.activepage = 1;
    this.request.activepage = this.activepage;
    this.request.selectedperpage = this.selectedperpage;
    this.searchProduct()
    // this.perPageData.emit(setPageSizeOptionsInput.value);
    // this.loadPaginate();
    // this.advanceSearchCategoryList();
  }

  loadPaginate() {
    this.configpaginate.totalItems = Number(this.productTotalCount);
  }

  pageChanged(event) {
    this.configpaginate.currentPage = Number(event);
    this.activepage = event;
    this.request.activepage = this.activepage;
    this.request.selectedperpage = this.selectedperpage;
    this.searchProduct()
    // this.populateProductList(false, request);
    // this.advanceSearchCategoryList();
  }

  showSubCategory() {
    this.relatedProduct = [];
    this.relatedProductcount = 0;
    this.relatedProductBatchID = [];
    this.getParentID = this.selectedCategoryID;
    this.webshopLibrarySearchStatus = false;
    this.webshopLibrarySelectStatus = true;
    this.webshopLibrarySearchStatusChange.emit(false);
    this.webshopLibrarySelectStatusChange.emit(true);
    this.activepage = 1;
    this.selectedperpage = 20;
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
      this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    }
    else {
      this.showAddCategory = true;
    }
    this.checkedProduct = [];
    this.populateSubCategoryAndProductList(this.selectedCategoryID);
    this.categoryName = this.selectedCategoryName;
    this.selectedSubCategoryName = '';
    this.selectedCategory = [];
    this.productExists = false;
  }

  showProducts() {
    this.relatedProduct = [];
    this.relatedProductcount = 0;
    this.relatedProductBatchID = [];
    this.webshopLibrarySearchStatus = false;
    this.webshopLibrarySelectStatus = true;
    this.webshopLibrarySearchStatusChange.emit(false);
    this.webshopLibrarySelectStatusChange.emit(true);
    this.activepage = 1;
    this.selectedperpage = 20;
    this.populateSubCategoryProductList(this.selectedSubCategoryID)
  }

  showAllCategory() {
    this.relatedProduct = [];
    this.relatedProductcount = 0;
    this.relatedProductBatchID = [];
    this.getParentID = '';
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
      this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    }
    else {
      this.showAddCategory = true;
    }
    this.webshopLibrarySearchStatus = false;
    this.webshopLibrarySelectStatus = true;
    this.webshopLibrarySearchStatusChange.emit(false);
    this.webshopLibrarySelectStatusChange.emit(true);
    this.activepage = 1;
    this.selectedperpage = 20;
    this.configpaginate.itemsPerPage = 20;
    this.configpaginate.currentPage = 1;
    this.subCategoryExists = false;
    this.productExists = false;
    this.categoryName = '';
    this.checkedProduct = [];
    this.populateCategoryList();
    // this.selectedCategoryName = '';
    // this.selectedSubCategoryName = '';
  }
  @HostListener('document:keyup', ['$event']) handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.categoryModel.onEsc()
      this.categoryModel.hide()
      // this.categoryForm.reset()
      this.previewFileUrl = ''
      this.previewFileUrlIcon = ''
      // this.setImgIcon = false
      // this.setImg = false
    }
  }

  deleteCategory(category, type) {
    this.categoryDeleteID = { id: category, type: type };
  }

  confimDelete(status) {
    if (status) {
      this.showLoader = true;
      this.categoryService.deleteCategory(this.categoryDeleteID.id).subscribe((data: any) => {
        this.showLoader = false;
        if (data.Status) {
          if (data.ResponseMessage === "Category cannot be deleted because it contains sub-categories.") {
            this.toast.warning(data.ResponseMessage);
          }
          else {
            this.toast.success(data.ResponseMessage);
          }
          if ((this.categoryDeleteID.type === 'category' || this.categoryDeleteID.type === 'subcategory') && data.ResponseMessage !== "Category cannot be deleted because it contains sub-categories.") {
            if (this.categoryDeleteID.type === "category") {
              if (this.categoryList.length > 0) {
                const getDelId = this.categoryList.findIndex(ind => ind.CategoryID === this.categoryDeleteID.id);
                this.categoryList[getDelId]['isDeleted'] = true;
              }
            }

            if (this.categoryDeleteID.type === "subcategory") {
              if (this.subCategoryList.length > 0) {
                const getDelId = this.subCategoryList.findIndex(ind => ind.CategoryID === this.categoryDeleteID.id);
                this.subCategoryList[getDelId]['isDeleted'] = true;
              }

              if (this.subSubCategory.length > 0) {
                const getSubDelId = this.subSubCategory.findIndex(ind => ind.CategoryID === this.categoryDeleteID.id);
                this.subSubCategory[getSubDelId]['isDeleted'] = true;
              }
            }
            // this.populateCategoryList();
          }
          else {

          }
          this.deleteModal.hide();
        }
        else {
          this.toast.error(data.ResponseMessage);
        }
      }, error => {
        this.showLoader = false;
        this.deleteModal.hide();
        this.toast.error(error.error.Message);
      });
    }
    else {
      this.deleteModal.hide();
    }
  }

  onDragStart(data) {
    this.getType = sessionStorage.getItem('dragtype');
    this.showPopup = true;
    // this.getDragData = JSON.parse(sessionStorage.getItem('dragdata'));

    // if (this.getDragData.length > 1) {
    //   if (this.getDragData.length === 2) {
    //     this.showData.firstName = this.getDragData[0]['Name'];
    //     this.showData.firstImg = this.getDragData[0]['ImageURL'];
    //     this.showData.secondName = this.getDragData[1]['Name'];
    //     this.showData.secondImg = this.getDragData[1]['ImageURL'];
    //   }

    //   if (this.getDragData.length > 3 || this.getDragData.length === 3) {
    //     if (this.getDragData.length > 3) {
    //       let lastThreeData = this.getDragData.slice(-3 * 1);
    //       this.getDragData = lastThreeData;
    //     }
    //     this.showData.firstName = this.getDragData[0]['Name'];
    //     this.showData.firstImg = this.getDragData[0]['ImageURL'];
    //     this.showData.secondName = this.getDragData[1]['Name'];
    //     this.showData.secondImg = this.getDragData[1]['ImageURL'];
    //     this.showData.threeName = this.getDragData[2]['Name'];
    //     this.showData.threeImg = this.getDragData[2]['ImageURL'];
    //     console.log(this.showData);
    //   }
    //   else {
    //     this.getDragData = this.getDragData;
    //   }
    //   this.dragDataLength = JSON.parse(sessionStorage.getItem('dragdata')).length;
    // }
  }


  onDragMove(event: PointerEvent) {
  }

  onDragEnd(event: PointerEvent) {
    this.getDragData = [];
    this.selectIds = [];
    this.checkedProduct = [];
    this.relatedProductcount = 0;
    this.relatedProduct = [];
    this.relatedProductBatchID = [];
    this.productList.map(x => x.status = false);
  }

  searchProduct() {
    let requestParams;
    if (this.webshopLibrarySearchStatus) {
      requestParams = this.advanceSearchDataWebshopLibrary;
    } else {
      requestParams = this.advanceSearchSelectDataWebshopLibrary;
    }
    requestParams.activepage = this.request.activepage;
    requestParams.selectedperpage = this.request.selectedperpage;
    //  this.categoryHttpService.searchCategory(this.request).subscribe((res:any)=>{
    this.showLoader = true;
    this.categoryHttpService.searchCategory(requestParams).subscribe((res: any) => {
      this.showLoader = false;
      if (res) {
        if (res.TotalItemsCount > 0) {
          this.productExists = true;
        }
        let getPageList = res.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
        this.totalCount = res['TotalItemsCount'];
        this.productList = res.ItemsCollection;

        for (var list of this.productList) {
          for (var id of this.selectIds) {
            if (id === list.ProductID) {
              list.status = true;
            }
          }
        }
        // this.productList.forEach(element => {
        //   element.status = false;
        // });
        this.productTotalCount = res['TotalItemsCount'];
      }
    }, error => {
      this.showLoader = false;
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  changeType() {

    this.typeList = {
      leftCopyRelatedProducts: this.leftCopyRelatedProducts,
      withselectAll: this.withselectAll,
      excludeCategory: this.excludeCategory,
      withSubCategory: this.withSubCategory,
      withProducts: this.withProducts,
      SourceCustomerID: this.advanceSearchSelectDataWebshopLibrary.CustomerID
    }
    const selectedId = [];
    if (this.leftCopyRelatedProducts) {
      this.relatedProductBatchID = [];
      this.checkedProduct.forEach(id => {
        if (this.relatedProduct.filter(x => x.ProductId == id.ProductID).length == 0)
          selectedId.push(id.ProductID);
      });
      if (selectedId.length > 0) {
        this.showLoader = true;
        this.categoryHttpService.getRelatedList(selectedId).subscribe((data: any) => {
          // setTimeout(() => {
          this.relatedProduct = this.relatedProduct.concat(data);
          this.relatedProductcount = 0;
          this.relatedProduct.forEach(id => {
            if (this.relatedProductBatchID.filter(x => x == id.ProductBatchID).length == 0) {
              this.relatedProductcount += id.Count;
              this.relatedProductBatchID.push(id.ProductBatchID)
            }
          })
          this.showLoader = false;
          // }, 500)
        });
      }
      else {
        this.relatedProductcount = 0;
        this.relatedProduct.forEach(id => {
          if (this.relatedProductBatchID.filter(x => x == id.ProductBatchID).length == 0) {
            this.relatedProductcount += id.Count;
            this.relatedProductBatchID.push(id.ProductBatchID)
          }
        });
      }
    }
    else {
      this.relatedProductBatchID = []
      this.relatedProductcount = this.checkedProduct.length;
    }
  }

  getModalStatus(event) {
    if (event) {
      this.categoryModel.hide();
      this.populateCategoryList()
    }
  }

  selectAll() {
    this.typeList.withselectAll = this.withselectAll;
    if (this.withselectAll) {
      const getProductId = [];
      const getProduct = [];
      this.productList.forEach(element => {
        element.status = this.withselectAll;
        getProductId.push(element.ProductID)
        getProduct.push(element)
      });
      this.selectIds = getProductId;
      this.checkedProduct = getProduct;
      this.changeType();
    }
    else {
      this.productList.forEach(element => {
        element.status = this.withselectAll;
      });
      this.selectIds = [];
      this.checkedProduct = [];
      this.relatedProduct = [];
      this.relatedProductcount = 0;
      this.relatedProductBatchID = [];
    }
  }

  isChecked(event, index) {
    this.productList[index]['status'] = event.checked;
    if (!event.checked) {
      this.withselectAll = event.checked;
    }
    if (this.productList.length > 0) {
      const checkIfAllChecked = this.productList.filter(data => data.status === false);
      if (checkIfAllChecked.length === 0) {
        this.withselectAll = true;
      }
      else {
        this.withselectAll = false;
      }
    }
  }

  selectNone() {
    this.checkedProduct = [];
    this.selectIds = [];
    this.withselectAll = false;
    this.productList.forEach(element => {
      element.status = false;
    });
  }


  pageChangedForCategory(page) {
    this.activepageForCategory = page;
    this.populateCategoryList();
  }

  setPageSizeOptionsForCategory(perpage) {
    this.activepageForCategory = 1;
    this.selectedperpageForCategory = perpage;
    this.pageListForCategory = [];
    this.populateCategoryList();
  }

  getMultiProduct(event) {
  }

  getselectedPro(event) {
    if (event.array.length > 0) {
      if (event.array.length > 3) {
        let lastThreeData = event.array.slice(-3 * 1);
        this.getDragData = lastThreeData;
      }
      else {
        this.getDragData = event.array;
      }
      this.dragDataLength = event.array.length;
    }
    else {
      // this.getDragData = JSON.parse(sessionStorage.getItem('dragdata'));
      // this.getDragData = event.drag;
    }
  }

  getDragEvents(event) {
    // this.getDragEvent = event.event;
    // console.log(event.event)
  }

  @HostListener('document:dragover', ['$event']) onPointerMove(event): void {
    if (this.getDragEvent === undefined) {
      this.getDragEvent = event;
    }

    if (this.getDragEvent.x !== event.x || this.getDragEvent.y !== event.y) {
      // this.lastEvent.push(event)
      // const getLast = this.lastEvent.length - 1;
      // console.log(this.lastEvent[this.lastEvent.length - 1]);
      // this.getDragEvent = this.lastEvent[this.lastEvent.length - 1];
      this.getDragEvent = event;
    }
    // this.getDragEvent = this.lastEvent[this.lastEvent.length - 1];
  }

  @HostListener('document:drop', ['$event']) onDrop(event): void {
    sessionStorage.setItem('dragtype', 'product');
    this.getType = 'product';
    // this.getDragData = [];
    this.showPopup = false;
    this.withselectAll = false;
    this.checkedProduct = [];
    this.selectIds = [];
    this.relatedProductcount = 0;
    this.relatedProduct = [];
    this.relatedProductBatchID = [];
    this.productList.map(x => x.status = false);
  }
}
