import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from '../../../shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-webshop-currencies',
  templateUrl: './webshop-currencies.component.html',
  styleUrls: ['./webshop-currencies.component.scss']
})
export class WebshopCurrenciesComponent implements OnInit {
  @Input() webshopData: any;
  @Input() currenciesData: any;
  // currenciesDetails: FormGroup;
  @Output() getCurrenciesData = new EventEmitter<any>();
  customerCurrencies: any = []
  currencyList: any = []
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private toast: ToastService) { }

  ngOnInit() {
    this.customerCurrencies = this.currenciesData;
    for (var item of this.currenciesData) {
      if (item.isAllowedOnWebshop && item.isAllowedOnWebshop == true) {
        this.currencyList.push(item);
      }
    }
  }

  ngOnChanges() {
    // alert("pass- change")
  }


  changeCurrencies(event, value) {
    if (event.target.checked) {
      this.currencyList.push(value);
    }
    else {
      const index = this.currencyList.findIndex((ch) => ch.CurrencyName === value.CurrencyName);
      this.currencyList.splice(index, 1);
    }
  }

  // saveWebshop() {
  //   this.getCurrenciesData.emit(this.currenciesDetails.value);
  // }

  updateCurrencies() {
    let updateCurrenciesData = {};
    updateCurrenciesData['customerCurrencies'] = this.customerCurrencies
    this.getCurrenciesData.emit(this.customerCurrencies)
  }
}
