import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { LoginService } from 'src/app/login/state/services/login.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from '../../state/services/webshop.http.service';

@Component({
  selector: 'rewardkart-admin-webshop-maintenance',
  templateUrl: './webshop-maintenance.component.html',
  styleUrls: ['./webshop-maintenance.component.scss']
})
export class WebshopMaintenanceComponent implements OnInit {
  @Input() webshopData: any;
  @Output() sendMaintenance = new EventEmitter<any>();
  maintenanceStatus: boolean = false;
  webshopDetail: any;
  constructor(private webshopHttpService: WebshopHttpService,
    private loginService: LoginService,
    private apiService: ApiService,
    private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      } else {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }

    }
  }

  ngOnInit() {
    this.maintenanceStatus = this.webshopDetail.MaintenanceScreen
  }

  getSelectedMaintenance(event){
    this.maintenanceStatus = event.checked;
  }

  updateMaintenance() {
    this.sendMaintenance.emit(this.maintenanceStatus)
  }


}

