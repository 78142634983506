import { Component, OnInit, Input, Output, SimpleChanges, ViewChild, EventEmitter, HostListener } from '@angular/core';
import { CategoryService } from '../state/services/category.service';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-category-listdetails',
  templateUrl: './category-listdetails.component.html',
  styleUrls: ['./category-listdetails.component.scss']
})
export class CategoryListdetailsComponent implements OnInit {
  @Input() sendCategory: any;
  @Input() webshopLibrarySearchStatus: any;
  @Output() webshopLibrarySearchStatusChange = new EventEmitter();
  @Input() advanceSearchDataWebshopLibrary: any;
  @Input() webshopLibrarySelectStatus: any;
  @Output() webshopLibrarySelectStatusChange = new EventEmitter();
  @Input() advanceSearchSelectDataWebshopLibrary: any;
  @Input() searchStatus: any;
  @ViewChild('categoryModel', { static: true }) categoryModel: ModalDirective;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  selectedCategoryName: string;
  selectedCustomerID: any;
  selectedSubCategoryName: any;
  showLoader: boolean;
  categoryList: any
  categoryExists = false;
  selectedCategoryID: any;
  selectedSubCategoryID: any;
  subCategoryExists = false;
  productExists = false;
  subCategoryList: any = []; productList: any = []; selectedProductList: any = [];
  productListOnly: any;
  subSubCategory: any = [];
  productSelected = false;
  configpaginate: { itemsPerPage: any; currentPage: any; totalItems: any; };
  subSubCategoryExist: Boolean = false;
  checkEvent = false
  showDeleteIcon: boolean;
  sendIds: any;
  productSelectall: boolean = false
  selectIds = [];
  config: any;
  selectedperpage: any = 20;
  pageSize: number = 0;
  activepage: number = 1;
  rowPerPage: any = [{ label: 20, value: 20 }, { label: 40, value: 40 }, { label: 60, value: 60 }, { label: 80, value: 80 }];
  selectedpage: any = 1;
  totalCount: any;
  pageList: any = [];
  leftCopyRelatedProducts: Boolean = false;
  withselectAll: Boolean = false;
  excludeCategory: Boolean = false;
  withSubCategory: Boolean = false;
  withProducts: Boolean = false;
  typeList: any = {};
  productTotalCount = 0;
  subCategoryListResult: any = [];
  request: any = {};
  subSubCategoryResult: any = [];
  showName: any = '';
  categoryDeleteID: any;
  editName: boolean;
  getId: any;
  getParentID: any = '';
  getData: any;
  resetModal: Boolean = false;
  categoryName: any;
  showAddCategory: Boolean = true;
  listStatus: Boolean = true;


  selectedperpageForCategory: any = 40;
  pageSizeForCategory: number = 0;
  activepageForCategory: number = 1;
  rowPerPageForCategory: any = [{ label: 20, value: 20 }, { label: 40, value: 40 }, { label: 60, value: 60 }, { label: 80, value: 80 }];
  selectedpageForCategory: any = 1;
  totalCountForCategory: any;
  pageListForCategory: any = [];
  searchCriteriaForCategory: any = {};

  parentCategoryId: number;
  parentCategory: any;
  loadSearch: Boolean = false;
  getDragData: any = [];
  getDragEvent: any;
  checkedProduct: any = [];
  dragDataLength: any;
  showData: any = {};
  getType: any = 'product';
  showPopup: Boolean = false;
  allowAction: Boolean = false;
  constructor(public categoryServices: CategoryService, private categoryHttpService: CategoryHttpService,
    public categoryService: CategoryHttpService, public toast: ToastService, private translate: TranslateService, public apiService: ApiService) { }

  ngOnInit() {
    // this.populateCategoryList();
    // this.configpaginate.itemsPerPage = 20;
    // this.configpaginate.currentPage = 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // const webshopDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    // if (webshopDetails !== null && webshopDetails !== undefined) {
    //   if (webshopDetails.ParentCustomerID) {
    //     if (this.advanceSearchSelectDataWebshopLibrary.CustomerID === webshopDetails.ParentCustomerID) {
    //       this.advanceSearchSelectDataWebshopLibrary.childCustomerID = webshopDetails.CustomerID;
    //     }
    //     else {
    //       this.advanceSearchSelectDataWebshopLibrary.childCustomerID = null;
    //     }
    //   }
    // }
    const loginData = this.apiService.getLoginData();
    if (loginData.CustomerID === this.advanceSearchSelectDataWebshopLibrary.CustomerID) {
      this.allowAction = true;
    }
    else {
      this.allowAction = false;
    }
    this.parentCategory = '';
    this.categoryServices.onSelecteCategory.subscribe(category => {
      this.parentCategory = category;
      this.setSelectedCategory(category.CategoryID, category.Name, category);

      this.parentCategoryId = category.CategoryID;
      this.activepage = 1;
      this.selectedperpage = 20;
    })
    this.categoryServices.onSelecteSubCategory.subscribe(category => {
      this.parentCategory = category;
      setTimeout(() => {
        this.setSelectedSubCategory(category.CategoryID, category.Name, category, 'subcategory')
      }, 100);

      this.parentCategoryId = category.CategoryID;
      this.activepage = 1;
      this.selectedperpage = 20;
      //
    })
    this.categoryServices.onLoadCategory.subscribe(data => {
      this.productList = [];
      this.productTotalCount = 0;
      this.categoryList = data;
      this.categoryExists = true;
      this.productExists = false;
    })
    // if (this.searchStatus) {
    this.webshopLibrarySearchStatus = this.searchStatus ? true : false;
    this.webshopLibrarySelectStatus = this.searchStatus ? false : true;
    // }
    // this happens when click "Search" button in Advanced Search of  Webshop Library Section
    if (this.webshopLibrarySearchStatus) {
      this.activepage = 1;
      this.selectedperpage = 20;
      this.advanceSearchSelectDataWebshopLibrary.pageIndex = 1;
      this.advanceSearchSelectDataWebshopLibrary.pageSize = 20;
      this.loadSearch = true;
      this.populateCategoryList('search');
      this.subCategoryListResult = [];
      this.advanceSearchCategoryList();
      this.changeType();
      // this.populateCategoryList();
    }

    if (this.webshopLibrarySelectStatus) {
      this.loadSearch = false;
      this.populateCategoryList();
      this.changeType();
    }
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
      this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    }
    else {
      this.showAddCategory = true;
    }
  }

  advanceSearchCategoryList() {
    // debugger
    this.selectedCategoryName = '';
    this.selectedSubCategoryName = '';
    this.showLoader = true
    // this.categoryServices.loadCategorys(this.selectedCustomerID);
    this.categoryService.advacedSearchWebshopLibraryCategoryList(this.advanceSearchDataWebshopLibrary).subscribe(data => {
      if (data) {
        this.configpaginate = {
          itemsPerPage: Number(this.selectedperpage),
          currentPage: Number(this.activepage),
          totalItems: Number(data['TotalItemsCount'])
        };
        this.categoryExists = false;
        this.subCategoryExists = false;
        this.productExists = true;
        this.productList = data['ItemsCollection'];
        this.productList.forEach(element => {
          element.status = false;
        });
        this.productTotalCount = data['TotalItemsCount'];
        this.totalCount = data['TotalItemsCount'];

        let getPageList = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;

      } else {
        this.categoryExists = true;
      }
      this.showLoader = false;
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  resetCategoryDetailsValues() {
    this.selectedCategoryID = null;
    this.selectedCategoryName = null;
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
    this.productTotalCount = 0;
  }

  populateCategoryList(type?) {
    this.getParentID = '';
    this.selectedCategoryName = '';
    this.selectedSubCategoryName = '';
    this.showName = '';
    this.showLoader = true;
    this.checkedProduct = [];
    this.subCategoryExists = false;
    this.categoryName = '';
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID) {
      this.subCategoryList = [];
    }



    this.categoryService.getCustomerCategoryList(this.selectedCustomerID, this.advanceSearchSelectDataWebshopLibrary).subscribe((data: any) => {
      if (data) {

        this.categoryList = data;
        this.categoryList.forEach(element => {
          element.SubCategories.forEach(subelement => {
            subelement.showAddcategory = true;
          });
        });
        if (type === undefined) {
          this.productList = [];
          this.productTotalCount = 0;
          this.categoryExists = true;
        }
      } else {
        this.categoryExists = false;
      }
      this.showLoader = false
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }
  setSelectedCategory(id: any, name: any, category?) {
    this.getDragData = [];
    this.checkedProduct = [];
    this.selectIds = [];
    this.getParentID = id;
    if (category) {
      this.subCategoryListResult = category.SubCategories;
    }
    this.categoryExists = false; //Hide category list
    this.subSubCategoryExist = true;
    this.selectedCategoryID = id;
    this.selectedCategoryName = name;
    this.categoryName = name;
    this.showName = name;
    this.showAddCategory = true;
    this.activepage = 1;
    if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
      this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    }
    if (this.configpaginate !== undefined) {
      this.configpaginate.itemsPerPage = 20;
    }
    this.webshopLibrarySelectStatus = true;
    this.webshopLibrarySearchStatus = false;
    this.resetSubCategoryDetailsValues();
    this.populateSubCategoryAndProductList(id);
  }

  resetSubCategoryDetailsValues() {
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
  }
  populateSubCategoryAndProductList(categoryID: any) {
    const category = this.categoryList.find(x => x.CategoryID === categoryID);
    if (category.SubCategories.length > 0) {
      this.subCategoryExists = true;
      this.subCategoryList = category.SubCategories;
      this.subCategoryListResult = category.SubCategories;
    } else {
      this.subCategoryExists = false;
    }
    if (category.ProductsMainCount > 0) {
      this.productExists = true;
      this.populateProductList(true, { CategoryID: categoryID });
    } else {
      this.productExists = false;
    }
    this.productExists = false;
  }

  populateSubSubCategoryAndProductList(categoryID: any) {
    const category = this.subCategoryList.find(x => x.CategoryID === categoryID);
    if (category.SubCategories.length > 0) {
      // this.subCategoryExists = true;
      this.subSubCategory = category.SubCategories;
      this.subCategoryListResult = category.SubCategories;
    } else {
      this.subCategoryExists = false;
    }
    if (category.ProductsMainCount > 0) {
      this.productExists = true;
      this.populateProductList(true);
    } else {
      this.productExists = false;
    }
    this.productExists = false;
  }

  populateProductList(isCategory, request?: any) {
    this.getSearchData(isCategory, request)

  }

  getSearchData(isCategory: boolean, request?) {
    let searchData: any;
    // if (this.selectedCustomerID === 0) {
    //   searchData = {
    //     CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true
    //   };
    // } else {
    //   if (isCategory) {
    //     searchData = {
    //       CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true
    //     };
    //   } else {
    //     searchData = {
    //       CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true, request: request
    //     };
    //   }
    // }
    if (this.webshopLibrarySearchStatus) {
      searchData = this.advanceSearchDataWebshopLibrary;
      searchData.isAdmin = true;
      searchData.CategoryID = request ? Number(request.CategoryID) : null;
      searchData.pageIndex = this.activepage;
      searchData.pageSize = this.configpaginate.itemsPerPage;
    }
    if (this.webshopLibrarySelectStatus) {
      searchData = this.advanceSearchSelectDataWebshopLibrary;
      searchData.isAdmin = true;
      searchData.CategoryID = request ? Number(request.CategoryID) : null;
      searchData.pageIndex = this.activepage;
      searchData.pageSize = this.configpaginate ? this.configpaginate.itemsPerPage : 20;
    }
    if (!this.webshopLibrarySearchStatus && !this.webshopLibrarySelectStatus) {
      if (this.selectedCustomerID === 0) {
        searchData = {
          CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true
        };
      } else {
        if (isCategory) {
          searchData = {
            CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true
          };
        } else {
          searchData = {
            CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true, request: request
          };
        }
      }
    }
    this.showLoader = true;
    this.categoryHttpService.searchData(searchData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.productExists = true;
        this.productList = data['ItemsCollection'];
        this.productList.forEach(element => {
          element.status = false;
        });
        this.totalCount = data['TotalItemsCount'];
        this.productTotalCount = data['TotalItemsCount'];
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
        if (!isCategory) {
          this.loadPaginate();
        }
      } else {
        this.productExists = false;
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
    // return searchData;
  }

  // setSelectedSubCategory(id: any, name: any, data?) {
  //   console.log(id)
  //   console.log('datalists', data)
  //   this.subCategoryExists = false;
  //   this.selectedSubCategoryID = id;
  //   this.selectedSubCategoryName = name;
  //   this.subCategoryListResult = data.SubCategories;
  //   this.populateSubCategoryProductList(id);
  //   if (data.SubCategories.length > 0) {
  //     this.subSubCategoryExist = true;
  //     this.subSubCategory = data.SubCategories;
  //     console.log(this.subSubCategory)
  //     this.populateSubSubCategoryProductList(id);
  //   }
  //   else {
  //   }
  // }

  setSelectedSubCategory(id: any, name: any, data?, type?) {

    // console.log(this.advanceSearchSelectDataWebshopLibrary)
    // const getbtnShow = sessionStorage.getItem('showbutton');
    // if (getbtnShow === 'show') {
    //   this.showAddCategory = true;
    // }
    // else {
    //   this.showAddCategory = false;
    // }
    // if (this.advanceSearchSelectDataWebshopLibrary.CustomerID !== null && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== undefined && this.advanceSearchSelectDataWebshopLibrary.CustomerID !== '') {
    //   this.showAddCategory = this.advanceSearchSelectDataWebshopLibrary.CustomerCreated ? true : false;
    // }
    this.showAddCategory = false;
    this.getDragData = [];
    this.checkedProduct = [];
    this.selectIds = [];
    this.subCategoryExists = false;
    this.selectedSubCategoryID = id;
    this.selectedSubCategoryName = name;
    this.categoryName = name;
    this.showName = name;
    this.getParentID = id;
    this.request = {
      "CurrencyID": this.advanceSearchSelectDataWebshopLibrary.CurrencyID,
      "CustomerID": this.advanceSearchSelectDataWebshopLibrary.CustomerID,
      "categoryId": id,
      "isAdmin": true,
      "childCustomerID": this.advanceSearchSelectDataWebshopLibrary.childCustomerID,
      "OnlyInCategoriesOfSupplierID": data.SupplierID,
      "activepage": this.activepage,
      "selectedperpage": this.selectedperpage
    }
    // this.searchProduct();
    if (data.SubCategories.length > 0) {
      this.subSubCategoryExist = true;
      // this.subCategoryExists = true;
      this.productExists = false;
      if (type === 'subcategory') {
        this.subSubCategory = data.SubCategories;
        this.subCategoryListResult = data.SubCategories;
        // this.subCategoryExists = false;
      }
      if (type === 'subsubcategory') {
        this.subSubCategoryResult = data.SubCategories;
        this.subCategoryListResult = data.SubCategories;
      }
    }
    else {
      this.subCategoryListResult = [];
      this.subSubCategory = [];
      this.productExists = true;
      this.populateProductList(false, data);
    }
    // if(type === 'subcategory'){
    //   this.showAddCategory = true;
    // }
    // if(type === 'subsubcategory'){
    //   this.showAddCategory = false;
    // }
    this.searchProduct();


  }


  populateSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subCategoryList.find(x => x.CategoryID === subCategoryID);
    const subSubCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    this.subCategoryListResult = subCategory ? subCategory.SubCategories : [];
    // if(subCategory !== undefined){
    if ((subCategory !== undefined && subCategory.ProductsMainCount > 0) || (subSubCategory !== undefined && subSubCategory.ProductsMainCount > 0)) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
  }
  populateSubSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    if (subCategory !== undefined && subCategory.ProductsMainCount > 0) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
  }
  loadPaginate() {
    if (this.configpaginate) {
      this.configpaginate.totalItems = Number(this.productTotalCount);
    }
  }
  setProductSelected(selectedProduct: any) {
    const product = this.selectedProductList.find(x => x.ProductID === selectedProduct.ProductID);
    if (product === undefined) {
      this.selectedProductList.push(selectedProduct);
    } else {
      this.selectedProductList.splice(product);
    }
    this.productSelected = !this.productSelected;
  }
  pageChanged(event) {
    // this.configpaginate.currentPage = event;
    // this.paginationdata.emit(event);
    const request = {
      pageindex: event,
      pagesize: this.selectedperpage
    }

    this.request.activepage = event;
    this.activepage = event;
    this.advanceSearchDataWebshopLibrary.pageIndex = event;
    // this.request.pagesize = event;
    // this.request.pageindex = this.selectedperpage;
    // this.request.CategoryID = this.selectedCategoryID;

    // this.searchProduct();category.CategoryID, category.Name, category, 'subcategory'
    // this.setSelectedSubCategory(this.parentCategory.CategoryID, this.parentCategory.Name, this.parentCategory, 'subcategory')

    // if(this.parentCategory === '' || this.parentCategory === undefined){
    //   this.parentCategory.CategoryID 
    // }

    if (this.parentCategory.CategoryID !== undefined) {
      this.setSelectedSubCategory(this.parentCategory.CategoryID, this.parentCategory.Name, this.parentCategory, 'subcategory')
    }
    else if (this.webshopLibrarySearchStatus && this.advanceSearchDataWebshopLibrary.CategoryID !== '' && this.advanceSearchDataWebshopLibrary.CategoryID !== null && this.advanceSearchDataWebshopLibrary.CategoryID !== undefined) {
      this.advanceSearchCategoryList();
    }
    else {
      this.populateProductList(true, request);
    }
  }
  sendId(id, event) {
    this.showPopup = false;
    this.sendIds = id.ProductID;
    if (event.checked) {
      this.selectIds.push(this.sendIds);
      this.checkedProduct.push(id);
    }
    else {
      let getIndex = this.selectIds.indexOf(this.sendIds);
      this.selectIds.splice(getIndex, 1);
      const getCheckedIndex = this.checkedProduct.findIndex(data => data.ProductID === this.sendIds);
      this.checkedProduct.splice(getCheckedIndex, 1);
    }

  }
  multiSelect() {
    this.productSelectall = true
  }

  changeType() {
    this.typeList = {
      leftCopyRelatedProducts: this.leftCopyRelatedProducts,
      withselectAll: this.withselectAll,
      excludeCategory: this.excludeCategory,
      withSubCategory: this.withSubCategory,
      withProducts: this.withProducts,
      SourceCustomerID: this.advanceSearchSelectDataWebshopLibrary.CustomerID
    }
  }

  searchProduct() {
    this.showLoader = true;
    this.categoryHttpService.searchCategory(this.request).subscribe((res: any) => {
      this.showLoader = false;
      if (res) {
        if (res.TotalItemsCount > 0) {
          this.productExists = true;
        }
        let getPageList = res.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
        this.totalCount = res['TotalItemsCount'];
        this.productList = res.ItemsCollection;
        this.productTotalCount = res['TotalItemsCount'];
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  setPageSizeOptions(setPageSizeOptionsInput) {
    this.activepage = 1;
    this.selectedperpage = setPageSizeOptionsInput;
    this.advanceSearchDataWebshopLibrary.pageSize = Number(setPageSizeOptionsInput);
    this.advanceSearchDataWebshopLibrary.pageIndex = Number(this.activepage);
    this.request.activepage = this.activepage;
    this.request.selectedperpage = this.selectedperpage;
    //this.searchProduct()
    // if (this.webshopLibrarySearchStatus) {
    //   this.configpaginate.itemsPerPage = this.selectedperpage;
    //   const request = {
    //     pageindex: this.activepage,
    //     pagesize: this.selectedperpage
    //   }
    //   this.populateProductList(true, request);
    // }
    // else {
    //   this.setSelectedSubCategory(this.parentCategory.CategoryID, this.parentCategory.Name, this.parentCategory, 'subcategory');
    // }
    if (this.parentCategory.CategoryID !== undefined) {
      this.setSelectedSubCategory(this.parentCategory.CategoryID, this.parentCategory.Name, this.parentCategory, 'subcategory')
    }
    else if (this.webshopLibrarySearchStatus && this.advanceSearchDataWebshopLibrary.CategoryID !== '' && this.advanceSearchDataWebshopLibrary.CategoryID !== null && this.advanceSearchDataWebshopLibrary.CategoryID !== undefined) {
      this.advanceSearchCategoryList();
    }
    else {
      this.configpaginate.itemsPerPage = this.selectedperpage;
      const request = {
        pageindex: this.activepage,
        pagesize: this.selectedperpage
      }
      this.populateProductList(true, request);
    }
  }

  onDragStart(data) {
    this.getType = sessionStorage.getItem('dragtype');
    this.showPopup = true;
  }

  onDragMove(event: PointerEvent) {
  }

  onDragEnd(event: PointerEvent) {
    this.getDragData = [];
  }

  selectAll() {
    this.showPopup = false;
    this.typeList.withselectAll = this.withselectAll;
    if (this.withselectAll) {
      const getProductId = [];
      const getProduct = [];
      this.productList.forEach(element => {
        element.status = this.withselectAll;
        getProductId.push(element.ProductID);
        getProduct.push(element)
      });
      this.selectIds = getProductId;
      this.checkedProduct = getProduct;
    }
    else {
      this.productList.forEach(element => {
        element.status = this.withselectAll;
      });
      this.selectIds = [];
      this.checkedProduct = [];
    }
  }

  isChecked(event, index) {
    this.productList[index]['status'] = event.checked;
    if (!event.checked) {
      this.withselectAll = event.checked;
    }
    if (this.productList.length > 0) {
      const checkIfAllChecked = this.productList.filter(data => data.status === false);
      if (checkIfAllChecked.length === 0) {
        this.withselectAll = true;
      }
    }
  }

  deleteCategory(category, type) {
    this.categoryDeleteID = { id: category, type: type };
  }

  confimDelete(status) {
    if (status) {
      this.showLoader = true;
      this.categoryService.deleteCategory(this.categoryDeleteID.id).subscribe((data: any) => {
        this.showLoader = false;
        if (data.Status) {
          // this.toast.success(data);
          if (data.ResponseMessage === "Category cannot be deleted because it contains sub-categories.") {
            this.toast.warning(data.ResponseMessage);
          }
          else {
            this.toast.success(data.ResponseMessage);
          }
          if (this.categoryDeleteID.type === 'category' || this.categoryDeleteID.type === 'subcategory') {
            this.populateCategoryList();
            this.showName = '';
          }
          else {

          }
          this.deleteModal.hide();
        }
        else {
          this.toast.error(data.ResponseMessage);
        }
      }, error => {
        this.showLoader = false;
        this.deleteModal.hide();
        this.toast.error(error.error.Message);
      });
    }
    else {
      this.deleteModal.hide();
    }
  }

  setIdBased(id) {
    this.getId = id;
    this.editName = true;
    this.showLoader = true;
    this.getParentID = id;
    this.categoryService.getCategoryById(id).subscribe(data => {
      if (data) {
        this.getData = data
        this.showLoader = false
        this.categoryModel.show();
      }
    });
  }

  resetCategory(event) {
    this.resetModal = true
    setTimeout(() => {
      this.resetModal = false
    }, 1000)
  }

  toggleModal() {
    this.categoryModel.hide();
  }

  ShowAddModel() {
    // this.setImgIcon = false;
    // this.setImg = false;
    // this.categoryForm.reset();
    // this.previewFileUrl = '';
    // this.previewFileUrlIcon = '';
    this.editName = false
    this.getData = '';
    this.categoryName = '';
    this.getParentID = this.getParentID;
    // setTimeout(() => {
    //   this.getData = '';
    // }, 500)
    this.categoryModel.show();
  }
  getModalStatus(event) {
    if (event) {
      this.categoryModel.hide();
      this.populateCategoryList()
    }
  }


  pageChangedForCategory(page) {
    this.activepageForCategory = page;
    this.populateCategoryList();
  }

  setPageSizeOptionsForCategory(perpage) {
    this.activepageForCategory = 1;
    this.selectedperpageForCategory = perpage;
    this.pageListForCategory = [];
    this.populateCategoryList();
  }



  setHeight() {
    let getWindowHeight = window.innerHeight;
    getWindowHeight = getWindowHeight - 60;
    return getWindowHeight;
  }

  getselectedPro(event) {
    if (event.array.length > 0) {
      if (event.array.length > 3) {
        let lastThreeData = event.array.slice(-3 * 1);
        this.getDragData = lastThreeData;
      }
      else {
        this.getDragData = event.array;
      }
      this.dragDataLength = event.array.length;
    }
    // else {
    //   this.getDragData = JSON.parse(sessionStorage.getItem('dragdata'));
    // }
  }

  getDragEvents(event) {
    // this.getDragEvent = event.event;
    // console.log(event.event)
  }

  @HostListener('document:dragover', ['$event']) onPointerMove(event): void {
    if (this.getDragEvent === undefined) {
      this.getDragEvent = event;
    }

    if (this.getDragEvent.x !== event.x || this.getDragEvent.y !== event.y) {
      // this.lastEvent.push(event)
      // const getLast = this.lastEvent.length - 1;
      // console.log(this.lastEvent[this.lastEvent.length - 1]);
      // this.getDragEvent = this.lastEvent[this.lastEvent.length - 1];
      this.getDragEvent = event;
    }
    // this.getDragEvent = this.lastEvent[this.lastEvent.length - 1];
  }

  @HostListener('document:drop', ['$event']) onDrop(event): void {
    this.getDragData = [];
    sessionStorage.setItem('dragtype', 'product');
    this.getType = 'product';
    this.getDragData = [];
    this.showPopup = false;
    // this.selectIds = [];
  }

  getsendCategory(event) {
    // console.log(event);
    // if (event.ParentID === undefined || event.showAddcategory) {
    //   this.showAddCategory = true;
    // }
  }

}
