
import { Component, OnInit, Input, EventEmitter, OnChanges, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ProductService } from '../../state/services/product.service';
import { ProductHttpService } from '../../state/services/product.http.service';
import { UserService } from 'src/app/user/state/services/user.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-product-additional-costs',
  templateUrl: './product-additional-costs.component.html',
  styleUrls: ['./product-additional-costs.component.scss']
})
export class ProductAdditionalCostsComponent implements OnInit, OnChanges {
  productAdditionalCostsForm: any;
  items: FormArray;
  optionsSelectAdditionalCosts: any = [];
  @Input() productDetails: any
  @Input() loadApi: any;
  @Output() sendAdditionalCosts = new EventEmitter();
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('deleteModal1', { static: true }) deleteModal1: ModalDirective;
  @ViewChild('additionalcostsoptions', { static: true }) additionalcostsoptions: ModalDirective;
  optionsSelectCurrencies: any[];
  optionsSelectOptions: any[];
  optionsSelectTypes: any[];
  resetAdditionalCostModal: Boolean = false
  itemIndex: any;
  optionIndexNumber: number
  loadManageApi: any;
  loadApiFlag: Boolean = false
  options: any;
  price: any;
  optionList: any = [];
  selectedAdditionalCost: any;
  showAdditionalCostModal: Boolean = false;
  optionsadditional: any;
  updatestatus: Boolean = false;
  searchKey: any;
  resultMessage: any;
  selfAdditionalCost: any = [];
  showSearch: Boolean;
  selectlabel: any = 'Select Additional Cost';
  popupList: any;
  dropdownData: any = {};
  constructor(public translate: TranslateService,
    public formBuilder: FormBuilder,
    public productHttpService: ProductHttpService,
    public userService: UserService,
    public toast: ToastService,
    public productService: ProductService) {
    this.loadManageApi = {
      additionalCost: false
    }

  }


  ngOnChanges() {
    if (this.loadApi) {
      this.clearFormItems();
      this.loadFormData();
    }
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.userService.loadCountries();
      this.userService.loadCurrencies();
      this.productService.loadProductAdditionalCostTypes();
      this.getProductAdditionalCosts();
      this.getCurrencies();
      this.getAdditionalCostTypes();
    }
  }

  resetAdditionalCost(event) {
    this.resetAdditionalCostModal = true
    setTimeout(() => {
      this.resetAdditionalCostModal = false
    }, 1000)
  }


  ngOnInit() {

    this.productAdditionalCostsForm = this.formBuilder.group({
      inspectionFee: [''],
      additionalCost: [''],
      price: [''],
      currency: [''],
      costType: [''],
      optional: [false],
      items: this.formBuilder.array([])
    });

    this.productAdditionalCostsForm.get('inspectionFee').setValue(this.productDetails ? this.productDetails.InspectionFee : '');
    this.getProductAdditionalCosts();
    if (this.productDetails.ProductAdditionalCosts.length > 0) {
      this.loadFormData();

    }
    this.productAdditionalCostsForm.valueChanges.subscribe(() => {
      this.emitData();
    });
    this.emitData();


    this.popupList = {
      apiurl: 'all/ProductAdditionalCostsWithPagination',
      key: 'ProductAdditionalCostID',
      label: 'Additional Cost',
      autoComplete: {
        apiUrl: 'AutoCompleteAdditionCosts',
        searchKey: 'name'
      }
    }
  }

  clearFormItems() {
    const control = <FormArray>this.productAdditionalCostsForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  addPrintPrice() {

  }

  emitData() {
    const finalVal = [];
    if (this.items && this.items.length > 0) {
      this.items.value.forEach(data => {
        if (data.productAdditionalCostOptions !== null && data.productAdditionalCostOptions !== undefined) {
          data.productAdditionalCostOptions.forEach(element => {
            element.Price = element.Price !== undefined && element.Price !== '' && element.Price !== null && (element.Price.toString()).includes(',') ? element.Price.replace(',', '.') : element.Price;
          });
        }

        const name = this.optionsSelectAdditionalCosts.find(item => item.ProductAdditionalCostID == data.additionalCost)
        let getprice = data.price !== undefined && data.price !== null && (data.price.toString()).includes(',') ? data.price.replace(',', '.') : data.price;
        if (data.productAdditionalCostOptions !== null && data.productAdditionalCostOptions.length > 0) {
          getprice = null;
        }
        const value = {
          isOptionalForBuyer: data.optional !== null ? data.optional : false,
          ProductAdditionalCostID: data.additionalCost,
          Price: getprice,
          ProductAdditionalCostTypeID: data.costType,
          CurrencyID: data.currency,
          Name: data.name,
        }
        if (data.action) {
          value['Action'] = data.action;
        }
        if (data.costTypeCode) {
          value['CostTypeCode'] = data.costTypeCode;
        }
        if (data.costTypeName) {
          value['CostTypeName'] = data.costTypeName;
        }
        if (data.prices) {
          value['Prices'] = [{ MinimumQuantity: data.prices.MinimumQuantity, MaximumQuantity: data.prices.MaximumQuantity, Price: data.prices.Price }];
        }
        if (data.productAdditionalCostOptions) {
          value['ProductAdditionalCostOptions'] = data.productAdditionalCostOptions;
        }
        finalVal.push(value);
      });
      this.sendAdditionalCosts.emit({ InspectionFee: this.productAdditionalCostsForm.get('inspectionFee').value, additionalCostValue: finalVal });
    }


  }

  getCurrencies() {
    this.userService.getAllCurrencies().subscribe(
      data => {
        if (data) {
          this.optionsSelectCurrencies = [{ label: 'Select currency', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            this.optionsSelectCurrencies.push(langVal);
          });
        }
      });
  }


  getAdditionalCostTypes() {
    this.productService.getProductAdditionalCostTypes().subscribe(
      data => {
        if (data) {
          this.optionsSelectTypes = [{ label: 'Select cost type', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.ProductAdditionalCostTypeID,
              label: element.Name,
            };
            this.optionsSelectTypes.push(langVal);
          });
        }
      });
  }

  createItem(newValue): FormGroup {
    let group = {
      additionalCost: newValue.additionalCost,
      price: newValue.price,
      currency: newValue.currency,
      costType: newValue.costType,
      optional: newValue.optional,
      productAdditionalCostOptions: [newValue.productAdditionalCostOptions],
      name: newValue.name,
      costTypeCode: newValue.costTypeCode,
      costTypeName: newValue.costTypeName,
      prices: newValue.prices,
      search: newValue.search
    }
    if (newValue.action) {
      group['action'] = newValue.action;
    }
    return this.formBuilder.group({ ...group });
  }


  loadFormData() {
    this.items = this.productAdditionalCostsForm.get('items') as FormArray;
    if (this.productDetails.ProductAdditionalCosts !== undefined) {
      if (this.productDetails.ProductAdditionalCosts.length > 0) {
        this.productDetails.ProductAdditionalCosts.forEach(data => {
          if (data.ProductAdditionalCostOptions !== undefined) {
            if (data.ProductAdditionalCostOptions.length > 0) {
              data.ProductAdditionalCostOptions.forEach(element => {
                element.Action = 'Update'
              });
            }
          }
          let newValue = {
            additionalCost: data.ProductAdditionalCostID,
            price: data.Price,
            name: data.Name,
            costTypeCode: data.CostTypeCode,
            costTypeName: data.CostTypeName,
            currency: data.CurrencyID,
            costType: data.ProductAdditionalCostTypeID,
            optional: data.isOptionalForBuyer,
            prices: data.Prices,
            productAdditionalCostOptions: data.ProductAdditionalCostOptions,
            action: "Update",
            search: 'show'
          }
          this.items.push(this.createItem(newValue));
        })
        this.selfAdditionalCost = this.productDetails.ProductAdditionalCosts;
        // this.hideSearch()
      }
    }
  }


  disabledbutton() {
    const additionalCost = this.productAdditionalCostsForm.get('additionalCost').value;
    const price = this.productAdditionalCostsForm.get('price').value;
    const currency = this.productAdditionalCostsForm.get('currency').value
    const costType = this.productAdditionalCostsForm.get('costType').value
    const optional = this.productAdditionalCostsForm.get('optional').value

    if (!(additionalCost && currency && costType)) {
      return true;
    }
    return false;
  }

  addAdditionalCosts() {
    if (this.productAdditionalCostsForm.get('additionalCost').value === "") {
      this.toast.error('Please fill the mandatory fields')
    } else {
      const additionalCost = this.productAdditionalCostsForm.get('additionalCost').value;
      const price = this.productAdditionalCostsForm.get('price').value && (this.productAdditionalCostsForm.get('price').value.toString()).includes(',') ? (this.productAdditionalCostsForm.get('price').value).replace(',', '.') : this.productAdditionalCostsForm.get('price').value;
      const currency = this.productAdditionalCostsForm.get('currency').value
      const costType = this.productAdditionalCostsForm.get('costType').value
      const optional = this.productAdditionalCostsForm.get('optional').value
      const getName = this.optionsSelectAdditionalCosts.filter(data => data.ProductAdditionalCostID === additionalCost)
      if (!(additionalCost || currency || costType)) {
        if (!additionalCost) {
          this.toast.error("Please select Additional Cost")
        } else if (!currency) {
          this.toast.error("Please select Currency")
        } else if (!costType) {
          this.toast.error("Please select Cost Type")
        }
      } else {
        this.items = this.productAdditionalCostsForm.get('items') as FormArray;
        let newValue: any = {
          additionalCost,
          price,
          currency,
          costType,
          optional,
          action: 'Insert',
          search: 'show'
        }
        newValue.name = getName.length === 0 ? '' : getName[0].Name;
        this.items.push(this.createItem(newValue));
        this.dropdownData = {};
        this.productAdditionalCostsForm.patchValue({
          additionalCost: '',
          price: '',
          currency: '',
          costType: '',
          optional: false,
        })
        this.toast.success("Additional Cost Added Successfully")
        this.emitData();
      }
      // this.hideSearch()
    }
  }


  updateNewOptions(event) {
    if (event) {
      this.getProductAdditionalCosts()
    }

  }

  getUpdateStatus(event) {
    // if(event){
    this.getProductAdditionalCosts();
    // }
    this.updatestatus = !event;
  }



  getProductAdditionalCosts() {
    this.productHttpService.getProductAdditionalCosts().subscribe((data: any) => {
      if (data) {
        const addCosObj = [];
        data.forEach((element) => {
          // element.ProductAdditionalCostOptions.Action = 'Update';
          const options = {
            value: element.ProductAdditionalCostID,
            label: element.Name,
            ProductAdditionalCostID: element.ProductAdditionalCostID,
            SupplierID: element.SupplierID,
            Price: element.Price,
            DefaultCurrencyID: element.DefaultCurrencyID,
            DefaultProductAdditionalCostTypeID: element.DefaultProductAdditionalCostTypeID,
            DefaultIsOptionalForBuyer: element.DefaultIsOptionalForBuyer,
            Name: element.Name,
            ProductAdditionalCostOptions: element.ProductAdditionalCostOptions
          }
          addCosObj.push(options)
        })
        this.optionsSelectAdditionalCosts = addCosObj;
        this.emitData();
      }
    });
  }

  getAdditionalCosts(selectedValue) {
    this.dropdownData = selectedValue;
    this.productAdditionalCostsForm.get('additionalCost').setValue(selectedValue.ProductAdditionalCostID)
    this.productAdditionalCostsForm.get('price').setValue(selectedValue.Price)
    this.productAdditionalCostsForm.get('currency').setValue(selectedValue.DefaultCurrencyID)
    this.productAdditionalCostsForm.get('costType').setValue(selectedValue.DefaultProductAdditionalCostTypeID)
    this.productAdditionalCostsForm.get('optional').setValue(selectedValue.DefaultIsOptionalForBuyer)
  }

  deleteItem(itemIndex) {
    this.itemIndex = itemIndex;
    this.deleteModal.show();
  }

  checkListItems() {
    let arr = this.productAdditionalCostsForm.get('items')['controls'].length
    const val = this.productAdditionalCostsForm.controls.items.value.find(item => item.action !== 'Delete')
    if (val && arr) {
      return true
    }
    return false
  }

  deleteAdditionalCostOption(optionIndexNumber) {
    this.optionIndexNumber = optionIndexNumber;
    this.deleteModal1.show();
  }

  getDeleteOptionStatus(optionIndexNumber?) {
    if (this.optionList[this.optionIndexNumber].Action == "Insert") {
      this.optionList.splice(this.optionIndexNumber, 1);
    } else {
      this.optionList[this.optionIndexNumber]['Action'] = "Delete";
    }
    this.toast.success("Product Additional Cost Options deleted successfully")
    this.deleteModal1.hide();
  }

  getDeleteStatus(status) {
    if (status) {
      if (this.items.value[this.itemIndex].action == "Insert") {
        this.items.removeAt(this.itemIndex);
      } else {
        this.items.value[this.itemIndex].action = "Delete";
      }
      this.toast.success('Product Additional Cost deleted successfully')
      this.emitData();
      // this.hideSearch();
      this.deleteModal.hide();
    } else {
      this.deleteModal.hide();
    }
  }

  addOption() {
    if (!(this.optionsadditional || this.price)) {
      if (!this.optionsadditional) {
        this.toast.error("Please Select options")
      } else if (!this.price) {
        this.toast.error("Please Select Price")
      }

    } else if (this.optionsadditional && this.price) {
      this.optionList = this.optionList || [];

      this.optionList.push(
        {
          ProductAdditionalCostOptionID: this.optionsadditional,
          Price: this.price.includes(',') ? Number(this.price.replace(',', '.')) : Number(this.price),
          Action: "Insert"
        }
      );
      this.optionsadditional = '';
      this.price = ''
      // this.emitData();
    }
    else {
      this.toast.error("Please Fill the Mandatory Fields")
    }
  }

  deleteOption(index) {
    if (this.optionList[index].Action == "Insert") {
      this.optionList.splice(index, 1);
    } else {
      this.optionList[index]['Action'] = "Delete";
    }
  }

  saveOption() {
    this.selectedAdditionalCost.ProductAdditionalCostOptions = this.optionList;
    this.items.value.forEach(data => {
      if (data.additionalCost == this.selectedAdditionalCost.value.additionalCost) {
        data.productAdditionalCostOptions = this.optionList;
      }
    });
    this.additionalcostsoptions.hide();
    this.emitData();
  }

  setOptions(item) {
    this.additionalcostsoptions.show();
    this.optionsSelectOptions = [];
    const optionList = this.optionsSelectAdditionalCosts.find(data => data.ProductAdditionalCostID == item.value.additionalCost)
    optionList.ProductAdditionalCostOptions.forEach(element => {
      const option = {
        value: element.ProductAdditionalCostOptionID,
        label: element.Name,
        price: element.Price,
        Action: "Update"
      }
      this.optionsSelectOptions.push(option);
    })
    this.optionList = item.value.productAdditionalCostOptions;
    this.selectedAdditionalCost = item;
  }

  getPriceValue(val) {
    this.price = val.price;
  }
  resetDropDown() {
    this.emitData()
    this.getProductAdditionalCosts()
  }

  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Number only accepted here')
      return false
    }
    else {
      return true
    }
  }

  searchList() {
    this.items.value.filter((data, index) => {
      let value = (data.name).toLowerCase();
      let searchData = (this.searchKey).toLowerCase();
      if (((value).includes(searchData))) {
        data.search = 'show';
      }
      else {
        data.search = 'hide';
      }
    });
    const noresult = this.items.value.filter(get => get.search === 'show');
    this.resultMessage = noresult.length === 0 ? true : false;
    // this.hideSearch();
  }


  resetFilter() {
    this.searchKey = '';
    this.items.value.filter((data, index) => {
      data.search = 'show';
    });
    this.resultMessage = this.items.length === 0 ? true : false;
  }

  // hideSearch() {
  //   const getResult = this.items.value.filter(data => data.Action !== 'Delete' || data.search !== 'hide');
  //   this.showSearch = getResult.length > 0 ? true : false;
  // }

  resetAdditonalCostDropdownPopup() {
    this.productAdditionalCostsForm.patchValue({
      additionalCost: '',
      price: '',
      currency: '',
      costType: '',
      optional: false,
    })
    this.dropdownData = {};
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
  }

}
