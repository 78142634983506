import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  schemas: [NO_ERRORS_SCHEMA]
})

export class HeaderModule {

customerImage: any;

  gotHome() {

  }
}
