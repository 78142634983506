export class ColumnSetting {
    primaryKey: string;
    primaryKeyItem?: string;
    header?: string;
    format?: string;
    key?: string;
    showSort?: boolean;
    style?: object;
    default?: string;
    array?: any;
    width?: any;
    footer?: boolean;
    footerKey?: string;
}

export class ColumnMap {
    primaryKey: string;
    showSort: boolean;
    style?: object;
    array?: any;
    private columnHeader: string;
    private columnFormat: string;
    private columnKey: string;
    private columnWidth: string;
    private columnFooter: boolean;
    private columnFooterKey: string;
    primaryKeyItem?: string;
    constructor(settings) {
        this.primaryKey = settings.primaryKey;
        this.primaryKeyItem = settings.primaryKeyItem;
        this.header = settings.header;
        this.format = settings.format;
        this.key = settings.key;
        this.showSort = settings.showSort;
        this.style = settings.style;
        this.array = settings.array;
        this.width = settings.width;
        this.footer = settings.footer;
        this.footerKey = settings.footerKey;
    }
    set header(setting: string) {
        this.columnHeader = setting ?
            setting :
            this.primaryKey.slice(0, 1).toUpperCase() +
            this.primaryKey.replace(/_/g, ' ').slice(1);
    }
    get header() {
        return this.columnHeader;
    }
    set format(setting: string) {
        this.columnFormat = setting ? setting : 'default';
    }
    get format() {
        return this.columnFormat;
    }

    set width(setting: string) {
        this.columnWidth = setting ? setting : 'default';
    }
    get width() {
        return this.columnWidth;
    }

    set footer(setting: boolean) {
        this.columnFooter = setting ? setting : false;
    }
    get footer() {
        return this.columnFooter;
    }

    set footerKey(setting: string) {
        this.columnFooterKey = setting ? setting : '0';
    }
    get footerKey() {
        return this.columnFooterKey;
    }

    set key(setting: string) {
        this.columnKey = setting ? setting : 'default';
    }
    get key() {
        return this.columnKey;
    }

    // tslint:disable-next-line:space-before-function-paren
    access = function (object: any) {
        if (object[this.primaryKey]) {
            return this.primaryKey;
        } else if (object[this.primaryKey] === false) {
            return this.primaryKey;
        }
    };

    // tslint:disable-next-line:space-before-function-paren
    accessArray = function (row: any) {
        for (const rowItem of row[this.primaryKey]) {
            return rowItem[this.primaryKeyItem];
        }
    };
}

