import { Component, OnInit } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { WarehouseHttpService } from '../state/services/warehouse.http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rewardkart-admin-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
  header: any;
  tableName: string = 'warehouselist';
  filterFields: any = [];
  warehouselist: any = [];
  warehousedatacount: any;
  // actions: any = ['editsupplier', 'delete', 'viewsupplier'];
  actions: any = ['delete', 'viewsupplier'];
  submitted = false;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  activePage: number = 1;
  rowactivePage: number = 1;
  resetStatus: boolean = false;
  searchCriteria: any = {};
  filterData: any = {};
  passFilterresult: any = [];
  commonRemoveFilter: any;
  showLoader: Boolean = false;
  webshopDetail: any;
  logisticsID: any = "";
  childwebshop: any = [];
  optionsSelectLogisticVendors: any = [];
  CustomerID: any = "";
  columns: ColumnSetting[] = [
    // {
    //   primaryKey: 'SupplierID',
    //   header: 'Supplier ID'
    // },
    {
      primaryKey: 'Name',
      header: 'Name'
    },
    {
      primaryKey: 'LogisticsVendorName',
      header: 'Logistics vendor'
    },
    {
      primaryKey: 'Address',
      header: 'Address',
    },
    {
      primaryKey: 'ContactPerson',
      header: 'Contact Person',
    },
    {
      primaryKey: 'Email',
      header: 'Email',
    },
    {
      primaryKey: 'PhoneNumber',
      header: 'Phone Number',
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];
  constructor(private apiService: ApiService, private router: Router,
    private linkTableService: LinkTableService, private toast: ToastService, private translate: TranslateService,
    private warehousehttpservice: WarehouseHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      this.webshopDetail.CustomerID = this.webshopDetail.CustomerID;
    }
    this.CustomerID = this.webshopDetail.CustomerID;

    this.getChildWebshop();
  }

  ngOnInit() {
    this.header = 'Warehouse Details'; // Multilingual to be implemented
    const searchkey = sessionStorage.getItem('searchWarehouseKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      // tslint:disable-next-line:max-line-length
      if (sessionStorage.getItem('warehouseEditRedirect') !== null && sessionStorage.getItem('warehouseEditRedirect') !== undefined) {
        this.activePage = this.searchCriteria.activePage;
        sessionStorage.removeItem('warehouseEditRedirect');
      } else {
        this.activePage = 1;
        this.searchCriteria.activePage = 1;
      }
      sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
    }
    this.onLoadWarehouse();
  }

  getLogisticVendorList() {
    let customerId = this.searchCriteria.customerId;
    if (customerId === '' || Number(customerId) === 0) {
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        //  if (this.webshopDetail.ParentCustomerID !== null) {
        customerId = this.webshopDetail.CustomerID;
        //  }
      }
    }
    const searchdata = {
      customerId: (customerId !== null && customerId !== undefined) ? customerId : '',
    };
    this.warehousehttpservice.getAllLogisticVendor(searchdata).subscribe(
      (vendordata: any) => {
        if (vendordata) {
          this.optionsSelectLogisticVendors = [];
          this.optionsSelectLogisticVendors.push({ label: this.translate.instant('WAREHOUSEMANAGEMENT.SELECTLOGISTICVENDOR'), value: '' });
          vendordata.ItemsCollection.forEach(element => {
            const vendorData = {
              value: element.LogisticsVendorID,
              label: element.Name,
            };
            this.optionsSelectLogisticVendors.push(vendorData);
          });
          this.setfilterData();
        }
      });
  }

  getChildWebshop() {
    this.warehousehttpservice.getCustomersListInclParent(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.childwebshop = [];
        this.childwebshop.push({ label: 'Select All', value: '0' });
        data.forEach(element => {
          const langVal = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.childwebshop.push(langVal);
        });
      }
      this.getLogisticVendorList();
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  onLoadWarehouse() {
    console.log("onLoadWarehouse called");
    const searchkey = sessionStorage.getItem('searchWarehouseKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.getwarehouseList(JSON.parse(searchkey));
    } else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        logisticsID: '',
        pageSize: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        pageIndex: 10
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
      this.getwarehouseList(this.searchCriteria);
    }
  }

  getwarehouseList(searchCriteria?) {
    console.log("searchCriteria : ", searchCriteria);
    if (!this.webshopDetail.IsWebshopDropdown) {
      searchCriteria.customerId = this.webshopDetail.CustomerID;
    }
    if (searchCriteria.customerId === '' || Number(searchCriteria.customerId) === 0) {
      searchCriteria.customerId = '';
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (this.webshopDetail.ParentCustomerID !== null) {
          searchCriteria.customerId = this.webshopDetail.CustomerID;
        }
      }
    }
    const searchdata = {
      activePage: this.activePage,
      pageSize: (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10,
      customerId: (searchCriteria.customerId !== null && searchCriteria.customerId !== undefined) ? searchCriteria.customerId : '',
      logisticId: (searchCriteria.logisticsID !== null && searchCriteria.logisticsID !== undefined) ? searchCriteria.logisticsID : '',
    };
    console.log("searchdata : ", searchdata);
    this.showLoader = true;
    this.warehousehttpservice.getAllWarehouse(searchdata).subscribe((datas: any) => {
      this.showLoader = false;
      if (datas) {
        this.warehouselist = [];
        this.warehouselist = datas.ItemsCollection;
        this.warehousedatacount = datas.TotalItemsCount;
        const webshop = sessionStorage.getItem('webshopDetail');

        // tslint:disable-next-line:max-line-length
        /** If login as child customer then we need to restrict Edit/Delete Supplier based on condition (CreatedByCustomerID = loggedincustomerId) */
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
          //  const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
          this.warehouselist.forEach(element => {
            element.EditActionEnabled = true;
            element.DeleteActionEnabled = true;
            // if (webshopDetail.ParentCustomerID !== null) {
            if (!element.IsEditable && this.webshopDetail.ParentCustomerID !== null) {
              element.EditActionEnabled = false;
            }
            if (!element.IsDeletable && this.webshopDetail.ParentCustomerID !== null) {
              element.DeleteActionEnabled = false;
            }
          });
        }
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  /** Paginaion code Section Functions */
  getPerPage(event) {
    console.log("Get Per Page : ", event);
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(this.searchCriteria);
  }

  paginationNum(event) {
    console.log("paginationNum : ", event);
    this.activePage = event;
    const searchkey = sessionStorage.getItem('searchWarehouseKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    this.searchCriteria.activePage = event;
    sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
    this.perPagedata = this.searchCriteria.pageIndex;
    this.getwarehouseList(this.searchCriteria);
  }


  /** Filter code Section Functions */
  setfilterData() {
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Select Webshop',
        type: 'select',
        class: 'col-md-12',
        key: 'selectwebshop',
        data: this.childwebshop,
        selected: (this.webshopDetail.IsWebshopDropdown && isParentCustomer) ? this.CustomerID : '',
        required: false,
        filterkey: 'selectwebshop'
      },
      {
        label: 'Select Logisticvendor',
        type: 'select',
        class: 'col-md-12',
        key: 'logisticsvendor',
        data: this.optionsSelectLogisticVendors,
        selected: this.logisticsID,
        required: false,
        filterkey: 'logisticsvendor'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      },
    ];

    const searchResult = sessionStorage.getItem('searchWarehouseResult')
    if (searchResult !== null) {
      const searchWarehouseResult = JSON.parse(sessionStorage.getItem('searchWarehouseResult'));
      /** To Prefill Logistic vendor dropdown value */
      const getIndex = searchWarehouseResult.findIndex(ind => ind.key === 'logisticsvendor');
      this.filterFields[getIndex].selected = searchWarehouseResult[getIndex].selected;

      /** To Prefill Webshop dropdown value(based on Login) */
      // tslint:disable-next-line:max-line-length
      if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
        const getIndex1 = searchWarehouseResult.findIndex(ind => ind.key === 'selectwebshop');
        if (this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
          this.filterFields[getIndex1].selected = searchWarehouseResult[getIndex1].selected;
        } else {
          this.filterFields[getIndex1].selected = '';
        }
      }
    }
  }

  getSelectData(data) {
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        customerId: data.selectwebshop !== undefined && data.selectwebshop.selectMethod === 'selectwebshop' ? data.selectwebshop.value : '',
        // tslint:disable-next-line:max-line-length
        logisticsID: data.logisticsvendor !== undefined && data.logisticsvendor.selectMethod === 'logisticsvendor' ? data.logisticsvendor.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    } else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        logisticsID: '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }

    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'selectwebshop' && this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
        element.selected = this.searchCriteria.customerId;
      } else if (element.filterkey === 'logisticsvendor') {
        element.selected = this.searchCriteria.logisticsID;
      }
    });

    /** Code to prefill Logistic vendor dropdown value based on Webshop dropdown change */
    this.filterData = this.searchCriteria;
    sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchWarehouseResult', JSON.stringify(this.filterFields));
    this.getLogisticVendorList();
  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    this.getwarehouseList(this.filterData);
    sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchWarehouseResult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.CustomerID = this.webshopDetail.CustomerID;
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    // this.filterFields.forEach(element => {
    //   if (element.filterkey === 'supplier') {
    //     element.selected = '';
    //   }
    //   if (element.filterkey === 'selectwebshop') {
    //     element.selected = '';
    //   }
    // });
    this.resetStatus = true;
    // this.getPerPage(10);
    this.getSelectData(undefined);
    sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchWarehouseResult', JSON.stringify(this.filterFields));
    this.setfilterData();
    this.onLoadWarehouse();
  }


  /** Remove Filter Tags Section Code */
  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchkey = sessionStorage.getItem('searchWarehouseKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    if (val !== undefined) {
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = this.webshopDetail.CustomerID;
      } else if (val.key === "logisticsvendor") {
        this.searchCriteria.logisticsID = '';
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
      });
      sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchWarehouseResult', JSON.stringify(this.filterFields));
      this.getwarehouseList(this.filterData);
    }
  }

  getFilterResult(filterresult) {
    this.passFilterresult = filterresult;
  }

  /** Table Actions(Edit, Delete, View, Add) Functions */
  addWarehouse(event: boolean) {
    this.submitted = event;
  }

  viewWarehouse(event: boolean) {
    this.submitted = event;
  }

  editWarehouse(data: any) {
    console.log("Edit Dt : ", data);
    if (data !== '') {
      const searchkey = sessionStorage.getItem('searchWarehouseKey');
      if (searchkey !== null && searchkey !== '[object Object]') {
        this.searchCriteria = JSON.parse(searchkey);
        this.perPagedata = 10;
        this.searchCriteria.pageIndex = 10;
        this.resetStatus = true;
        sessionStorage.setItem('searchWarehouseKey', JSON.stringify(this.searchCriteria));
      }
      //  this.showUserEditpage = true;
      data.warehouseEditPage = true;
      data.isParentCustomer = (this.webshopDetail.ParentCustomerID === null) ? true : false;
      this.router.navigate(['/warehouse-edit'], { state: { data }, replaceUrl: false });
    }
  }

  deleteWarehouse(deletedData: any) {
    this.showLoader = true;
    this.warehousehttpservice.deleteWarehouse(deletedData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.toast.success('Warehouse(' + deletedData.Name + ') deleted successfully');
        this.onLoadWarehouse();
      } else {
        this.toast.error('Warehouse(' + deletedData.Name + ') cannot be deleted');
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);
      });
  }

  /** Page Reedirect Functions */
  pageredirect() {
    this.router.navigate(['/warehouse-add'], { replaceUrl: true });
  }
}
