import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ControlValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      'required': 'REQUIRED',
      'invalidCreditCard': 'INVALIDCREDITCARD',
      'invalidEmailAddress': 'INVALIDEMAILADDRESS',
      'invalidPhoneNumber': 'INVALIDPHONENUMBER',
      'invalidPassword': 'INVALIDPASSWORD',
      'validpostalcode': 'POSTALCODE',
      'invalidQuantity': 'INVALIDQUANTITY',
      'invalidPostalCode': 'INVALIDPOSTALCODE',
      'notvalid': 'NOTVALID',
      'subjectvalidation': 'INVALIDSUBJECT',
      'invalidDecimal': 'INVALIDDECIMAL',
      'invalidDomainName': 'INVALIDDOMAIN',
      "invalidURL": "INVALIDURL"

    };
    return config[validatorName];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*.]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
  static URLPattern(control) {
    if (control.value != null) {
      if (control.value.startsWith('https') || control.value.startsWith('http')) {
        return null;
      } else {
        return { 'invalidURL': true };
      }
    }
  }




  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('Password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('Confirmpassword').setErrors({ MatchPassword: true })
    } else {
      return null
    }
  }

  static phoneNumberValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match('.{0,25}')) {
      return null;
    }
    else {
      return true;
    }
  }

  static subjectValidate(control) {
    if (control.value == '' && control.value == null && control.value == undefined) {
      return { 'subjectvalidation': true }
    }
  }



  static isNumberCheck(control) {
    // RFC 2822 compliant regex
    if (control.value !== '' && control.value !== null && control.value !== undefined) {
      if (control.value.match('.{5,10}')) {
        return null;
      } else {
        return true;
      }
    }
  }

  static isDecimalCheck(control) {
    if (control.value !== '' && control.value !== null && control.value !== undefined) {
      if (String(control.value).match(/^\d*\.?\d{0,2}$/)) {
        return null;
      } else {
        return { 'invalidDecimal': true };
      }
    }
  }


  static postalCodeValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.length <= 25 && control.value.length >= 4) {
      return null;
    }
    else {
      return true;
    }
  }

  static domainNameValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^[a-z0-9-]*$/)) {
      return null;
    } else {
      return { 'invalidDomainName': true };
    }
  }


}