import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { WebshopHttpService } from './state/services/webshop.http.service';
import { WebshopService } from './state/services/webshop.service';
import { webshopReducers } from './state/reducers/webshop.reducer';
import { WebshopEffects } from './state/effects/webshop.effects';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { WebshopListModule } from './webshop-list/webshop-list.module';
import { WebshopAddModule } from './webshop-add/webshop-add.module';
import { WebshopEditComponent } from './webshop-edit/webshop-edit.component';
import { ShopDetailsComponent } from './webshop-edit/shop-details/shop-details.component';
import { LandingPageComponent } from './webshop-edit/landing-page/landing-page.component';
import { PaymentDetailsComponent } from './webshop-edit/payment-details/payment-details.component';
import { UserDetailsComponent } from './webshop-edit/user-details/user-details.component';
// import { TransportationHubComponent } from './webshop-edit/transportation-hub/transportation-hub.component';
import { ProductRolesComponent } from './webshop-edit/product-roles/product-roles.component';
import { ProductRolesAddComponent } from './webshop-edit/product-roles/product-roles-add/product-roles-add.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ProductRolesUserComponent } from './webshop-edit/product-roles/product-roles-user/product-roles-user.component';
import { ProductRolesProductsComponent } from './webshop-edit/product-roles/product-roles-products/product-roles-products.component';
import { ProductTagsComponent } from './webshop-edit/product-tags/product-tags.component';
import { ProductTagsAddComponent } from './webshop-edit/product-tags/product-tags-add/product-tags-add.component';
import { ProductTagsProductsComponent } from './webshop-edit/product-tags/product-tags-products/product-tags-products.component';
import { HandlingChargesComponent } from './webshop-edit/handling-charges/handling-charges.component';
import { TransportationHubComponent } from './webshop-edit/transportation-hub/transportation-hub.component';
import { WebshopSettingsComponent } from './webshop-edit/webshop-settings/webshop-settings.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { WebshopCurrenciesComponent } from './webshop-edit/webshop-currencies/webshop-currencies.component';
import { ProductTagsGroupAddComponent } from './webshop-edit/product-tags/product-tags-group-add/product-tags-group-add.component';
import { WebshopPublishComponent } from './webshop-edit/webshop-publish/webshop-publish.component';
import { RouterModule } from '@angular/router';
import { ControlValidationModule } from '../shared/control-validation/control-validation.module';
import { WebshopLanguageComponent } from './webshop-edit/webshop-language/webshop-language.component';
import { WebshopMaintenanceComponent } from './webshop-edit/webshop-maintenance/webshop-maintenance.component';
import { CurrrencyComponent } from './webshop-edit/currrency/currrency.component';
import { CurrencyaddComponent } from './webshop-edit/currencyadd/currencyadd.component';
import { HyperCountryComponent } from './webshop-edit/hyper-country/hyper-country.component';
import { AddCountryComponent } from './webshop-edit/add-country/add-country.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    CommonFilterModule,
    WebshopListModule,
    WebshopAddModule,
    PerfectScrollbarModule,
    ColorPickerModule,
    RouterModule,
    ControlValidationModule,
    EffectsModule.forFeature([WebshopEffects]),
    StoreModule.forFeature('webshopModule', webshopReducers)
  ],
  declarations: [
    WebshopEditComponent,
    ShopDetailsComponent,
    LandingPageComponent,
    PaymentDetailsComponent,
    UserDetailsComponent,
    ProductRolesComponent,
    TransportationHubComponent,
    ProductRolesAddComponent,
    ProductRolesUserComponent,
    ProductRolesProductsComponent,
    ProductTagsComponent,ProductTagsAddComponent,
    ProductTagsProductsComponent,
    HandlingChargesComponent,
    WebshopSettingsComponent,
    WebshopCurrenciesComponent,
    ProductTagsGroupAddComponent,
    WebshopPublishComponent,
    WebshopLanguageComponent,
    WebshopMaintenanceComponent,
    CurrrencyComponent,
    CurrencyaddComponent,
    HyperCountryComponent,
    AddCountryComponent
  ],
  providers: [WebshopHttpService, WebshopService, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  exports: []
})

export class WebshopModule { }
