import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserModule } from '../user/user.module';
import { UserPointsModule } from '../user-points/user.module';
import { BrandModule } from '../brand/brand.module';
import { WebshopModule } from '../webshop/webshop.module';
import { BlockModule } from '../block/block.module';
import { OrderModule } from '../order/order.module';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main.component';
import { CategoryModule } from '../category/category.module';
import { SupplierModule } from '../supplier/supplier.module';
import { BillboardModule } from '../billboard/billboard.module';
import { CategoryDetailsModule } from '../category/category-details/category-details.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { CommonFormModule } from '../common/common-form/common-form.module';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    BrandModule,
    UserModule,
    UserPointsModule,
    WebshopModule,
    BlockModule,
    OrderModule,
    CategoryDetailsModule,
    CategoryModule,
    SupplierModule,
    BillboardModule,
    SharedModule,
    DashboardModule,
    CommonFilterModule,
    CommonFormModule,
    RouterModule
  ],
  declarations: [MainComponent],
  exports: [MainComponent],
  schemas: [NO_ERRORS_SCHEMA]
})

export class MainModule { }