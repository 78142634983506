import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from 'src/app/login/state/services/login.service';

@Injectable()
export class CategoryHttpService {
  getId: any;
  constructor(private apiService: ApiService, private loginService: LoginService) { }

  getCategoryList(customerID: any) {
    const path = 'en/categories?isOrderbyname=true&deleted=true&pageSize=200&CustomerID=' + customerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getRelatedList(selectedID: any) {
    const path = 'en/ProductBatchCount';
    return this.apiService.post(path, selectedID).map((res) => {
      return res;
    });
  }

  getCustomerCategoryList(customerID: any, queryParams?) {
    let params = '';
    queryParams = queryParams !== undefined ? queryParams : null;
    if (queryParams !== null) {
      if (queryParams.childCustomerID !== null && queryParams.childCustomerID !== undefined &&
        queryParams.childCustomerID !== '' && queryParams.childCustomerID !== 0) {
        params += '&childCustomerID=' + queryParams.childCustomerID;
      }
      if ((queryParams.customerID !== null && queryParams.customerID !== undefined &&
        queryParams.customerID !== '') || (queryParams.CustomerID !== null && queryParams.CustomerID !== undefined &&
          queryParams.CustomerID !== '')) {
        customerID = queryParams.CustomerID;
      }
      if (queryParams.OnlyWithoutWebshop !== null && queryParams.OnlyWithoutWebshop !== undefined &&
        queryParams.OnlyWithoutWebshop) {
        params += '&productsWithoutWebshop=true';
      }
      if (queryParams.OnlyInCategoriesOfSupplierID !== null && queryParams.OnlyInCategoriesOfSupplierID !== undefined &&
        queryParams.OnlyInCategoriesOfSupplierID !== '') {
        params += '&supplierID=' + Number(queryParams.OnlyInCategoriesOfSupplierID);
      }
      if (queryParams.OnlyWithoutWebshopCustomerID !== null && queryParams.OnlyWithoutWebshopCustomerID !== undefined &&
        queryParams.OnlyWithoutWebshopCustomerID !== '') {
        params += '&productsWithoutWebshopCustomerID=' + queryParams.OnlyWithoutWebshopCustomerID;
      }
      if (queryParams.CustomerCreated !== null && queryParams.CustomerCreated !== undefined &&
        queryParams.CustomerCreated) {
        params += '&customerCreatedCategories=true';
      }
    }

    customerID = customerID ? customerID : null;
    const path = 'en/categories?deleted=true&pageSize=200&CustomerID=' + customerID + params;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  advacedSearchWebshopLibraryCategoryList(data: any) {
    let pageSize = 20;
    let pageIndex = 1;
    let productStatusList = '';
    let productDeletedStatusList = '';
    if (data.pageSize !== undefined && data.pageSize !== null && data.pageSize !== '') {
      pageSize = data.pageSize;
    }
    if (data.pageIndex !== undefined && data.pageIndex !== null && data.pageIndex !== '') {
      pageIndex = data.pageIndex;
    }
    if (data.productStatusList !== undefined && data.productStatusList !== null && data.productStatusList !== '') {
      if (Number(data.productStatusList) === 1) {
        productStatusList = '&visible=true';
      }
      if (Number(data.productStatusList) === 0) {
        productStatusList = '&invisible=true';
      }
      if (Number(data.productStatusList) === 2) {
        productStatusList = '&visible=true&invisible=true';
      }
    }
    if (data.productDeletedStatusList !== undefined && data.productDeletedStatusList !== null && data.productDeletedStatusList !== '') {
      if (Number(data.productDeletedStatusList) === 1) {
        productDeletedStatusList = '&deleted=true';
      }
      if (Number(data.productDeletedStatusList) === 0) {
        productDeletedStatusList = '&notdeleted=true';
      }
      if (Number(data.productDeletedStatusList) === 2) {
        productDeletedStatusList = '&deleted=true&notdeleted=true';
      }
    }
    const path = 'en/search?pageIndex=' + pageIndex + '&pageSize=' + pageSize + productStatusList + productDeletedStatusList;
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  getParentColorList() {
    const path = 'en/productcolors?onlyParentColors=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  saveCategory(data: any) {
    const path = 'en/productcategorys/' + data.ProductCategoryID;
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  getCategoryById(data: any) {
    this.getId = data
    // const path = 'en/search?name='+data+'&customerID=0';
    const path = 'all/categories/' + data + '?translations=true&deleted=true'
    return this.apiService.get(path).map((res) => {
      return res;
    })
  }
  addCategory(data) {
    const path = 'en/categories';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  deleteCategorylist(CategoryId) {
    const path = 'en/categories/' + CategoryId;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }
  updateCategory(data) {
    const path = 'en/categories/' + this.getId
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  searchProduct(data: any, custId?) {
    const getCustomerID = custId ? custId : 0;
    const path = 'en/search?name=' + data + '&customerID=' + getCustomerID;
    return this.apiService.get(path).map((res) => {
      return res;
    })
  }

  getParentCustomersList(customerId) {
    const path = 'en/customers/list?ParentCustomerID=' + customerId + '&returnParentCustomer=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getCustomersList() {
    const path = 'en/customers/list';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getParentWebshopDetail(customerId) {
    const path = 'en/customers/' + customerId;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getSuppliersWithCategories() {
    const path = 'en/supplierswithcategories?isOrderbyname=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getSupplierSizes(request = null) {
    let queryparams = '';
    if (request.supplierId !== undefined && request.supplierId !== null && request.supplierId !== '') {
      queryparams += '&SupplierID=' + request.supplierId;
    }
    const path = 'en/productsizes?translations=true' + queryparams;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }



  searchData(searchData: any) {
    let path;
    // tslint:disable-next-line:max-line-length
    let pageSize = (searchData.pageSize !== undefined && searchData.pageSize !== null && searchData.pageSize !== '') ? searchData.pageSize : 20;
    // tslint:disable-next-line:max-line-length
    let pageIndex = (searchData.pageIndex !== undefined && searchData.pageIndex !== null && searchData.pageIndex !== '') ? searchData.pageIndex : 1;
    if (searchData !== undefined) {
      path = 'en/search?pageIndex=' + pageIndex + '&pageSize=' + pageSize;
    }
    else {
      path = 'en/search?pageIndex=' + null + '&pageSize=' + null;
    }

    return this.apiService.post(path, searchData).map((res) => {
      return res;
    });
  }
  populateOnlineCategoryList(customerID: any) {
    const path = 'en/categories?isOrderbyname=true&deleted=true&pageSize=200&CustomerID=' + customerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }
  // deleteOnlineProducts(){

  // }
  deleteOnlineProductsBulk(data) {
    const path = 'en/CategoriesProductsBulkRemoveCheckProducts'
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  deleteOnlineProductsConfirm(data) {
    const path = 'en/CategoriesProductsBulkRemove'
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  deleteCategory(catid) {
    const path = 'en/categories/' + catid;
    return this.apiService.delete(path).map((res) => {
      return res;
    })
  }

  dragDrop(request) {
    const path = 'en/CategoriesProductsBulkCopy?roleCode=LinkNetwork_SuperAdmin';
    return this.apiService.post(path, request).map((res) => {
      return res;
    })
  }

  searchCategory(request) {
    const path = 'en/search?pageIndex=' + request.activepage + '&pageSize=' + request.selectedperpage;
    return this.apiService.post(path, request).map((res) => {
      return res;
    })
  }


  getSubCategory(request) {
    let isCatID = ''
    if (request.categoryID !== undefined) {
      isCatID = '/' + request.categoryID;
    }
    const path = `en/categories${isCatID}?deleted=true&CustomerID=${request.customerID}`;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }


  supplierAutoComplete(request) {
    const path = `en/suppliers?name=${request.name}`;
    return this.apiService.get(path);
  }
}
