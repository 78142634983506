import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { DashboardService } from '../state/services/dashboard.service';
import { ApiService } from '../../shared/common-services/api.service';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-productionline',
  templateUrl: './dashboard-productionline.component.html',
  styleUrls: ['./dashboard-productionline.component.scss']
})
export class DashboardProductionlineComponent implements OnInit {
  @Input() customerList: any = [];
  public titles: any;
  tableName: any = 'productionlinelist';
  public productionLine: any = [];
  activePage: number = 1;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  productionLineQueryParams: any = {};
  public productlinecolumns: ColumnSetting[] = [];
  public filterFields: any = [];
  showLoader: boolean = false;
  public productionLineListQuery: any = {};
  totalCountProduct: any;
  totalPagingCountProduct: any;
  resetPaginationNumber: boolean = false;
  loginuserdetails: any;
  webshopDetail: any;
  isParentCustomer: any;

  constructor(public dashboardservice: DashboardService, public dashboardhttpservice: DashboardHttpService,
    public apiService: ApiService, private router: Router, private translate: TranslateService) {
    this.titles = this.translate.instant('DASHBOARD.PRODUCTIONLINEALERTS');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.productlinecolumns = [
      {
        primaryKey: 'ProductionLineID',
        header: this.translate.instant('DASHBOARD.LINKORDER')
      },
      {
        primaryKey: 'ProductName',
        header: this.translate.instant('DASHBOARD.PRODUCTNAME')
      },
      {
        primaryKey: 'NumberOfOrders',
        header: this.translate.instant('DASHBOARD.NOOFORDERS')
      },
      {
        primaryKey: 'TotalOrderedQuantity',
        header: this.translate.instant('DASHBOARD.TOTALQTY')
      },
      {
        primaryKey: 'DeliveryDate',
        header: this.translate.instant('DASHBOARD.DELIVERYDATE'),
        format: 'date'
      },
      {
        primaryKey: 'ProductionLineStatus',
        header: this.translate.instant('DASHBOARD.STATUS')
        //  format: 'status'
      },
    ];
  }

  ngOnInit() {
    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    }

    let webshopEnableStatus = true;
    if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
      webshopEnableStatus = false;
      if (this.webshopDetail.IsWebshopDropdown) {
        webshopEnableStatus = true;
      }
    }
    this.productionLineQueryParams.customerID = (this.isParentCustomer) ? null : this.webshopDetail.CustomerID;
    this.filterFields = [
      {
        label: this.translate.instant('MENU.WEBSHOPS'),
        type: 'select',
        key: 'selectwebshop',
        data: [],
        placeholder: this.translate.instant('CATEGORIES.SELECT_ALL'),
        class: (webshopEnableStatus && this.isParentCustomer) ? 'col-6' : ''
      },
      {
        label: this.translate.instant('DASHBOARD.STATUS'),
        type: 'select',
        selected: 'All',
        key: 'ProductionLine',
        class: 'col-6',
        data: [{
          label: 'All',
          value: 'All',
          selected: true
        },
        {
          label: this.translate.instant('DASHBOARD.MINQTYNOTREACHED'),
          value: 1
        },
        {
          label: this.translate.instant('DASHBOARD.PRODUCTIONNOTSTARTED'),
          value: 2
        },
        {
          label: this.translate.instant('DASHBOARD.PRODUCTIONNOTCOMPLETED'),
          value: 3
        },
        {
          label: this.translate.instant('DASHBOARD.PRODUCTIONNOTDISPATCHED'),
          value: 4
        }]
      }
    ];
    this.productionLineListQuery.webshopID = (this.isParentCustomer) ? null : this.webshopDetail.CustomerID;
    this.getAllCustomerList();
    this.getDashboardOrderFlowList();
  }

  getSelectData(productionLineName) {
    //console.log("productionLineName : ", productionLineName);
    if (productionLineName.ProductionLine !== undefined && productionLineName.ProductionLine.selectMethod === 'ProductionLine') {
      if (productionLineName.ProductionLine.value !== '') {
        this.productionLineListQuery.productionLineStatusID = productionLineName.ProductionLine.value;
      } else {
        this.productionLineListQuery.productionLineStatusID = '';
      }
    }
    if (productionLineName.selectwebshop !== undefined) {
      if (productionLineName.selectwebshop.value !== '') {
        this.productionLineListQuery.webshopID = productionLineName.selectwebshop.value;
      } else {
        this.productionLineListQuery.webshopID = (this.isParentCustomer) ? null : this.webshopDetail.CustomerID;
      }
      this.activePage = 1;
      this.perPagedata = 10;
    }
    this.productionLineListQuery.pageIndex = 1;
    this.productionLineListQuery.pageSize = 10;
    this.getDashboardOrderFlowList();
  }

  onLoadFunctions() {

    // this.dashboardservice.loadProductionLine(this.productionLineQueryParams);
  }

  paginationNum(event) {
    //console.log("paginationNum : ", event);
    this.activePage = (this.resetPaginationNumber) ? 1 : event;
    this.productionLineListQuery.pageIndex = (this.resetPaginationNumber) ? 1 : event;
    this.resetPaginationNumber = false;
    this.getDashboardOrderFlowList();
  }

  getPerPage(event) {
    //console.log(event)
    this.perPagedata = event;
    this.productionLineListQuery.pageSize = event;
    this.resetPaginationNumber = true;
  }

  getDashboardOrderFlowList() {
    this.showLoader = true;
    //console.log("this.productionLineListQuery : ", this.productionLineListQuery);
    let searchData = {
      'webshopID': (this.productionLineListQuery.webshopID !== undefined && this.productionLineListQuery.webshopID !== null) ?
        this.productionLineListQuery.webshopID : '',
      'productionLineStatusID': (this.productionLineListQuery.productionLineStatusID !== undefined && this.productionLineListQuery.productionLineStatusID !== null) ?
        this.productionLineListQuery.productionLineStatusID : '',
      'pageIndex': (this.productionLineListQuery.pageIndex !== undefined && this.productionLineListQuery.pageIndex !== null) ?
        this.productionLineListQuery.pageIndex : this.activePage,
      'pageSize': (this.productionLineListQuery.pageSize !== undefined && this.productionLineListQuery.pageSize !== null) ?
        this.productionLineListQuery.pageSize : this.rowPagepage
    }
    this.dashboardhttpservice.getProductionLine(searchData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.productionLine = data.ItemsCollection;
        this.totalCountProduct = data.TotalItemsCountAllStatuses;
        this.totalPagingCountProduct = data.TotalItemsCount;
        this.enablepagination = true;
        if (data.ItemsCollection.length === 0) {
          this.enablepagination = false;
        }
        this.titles = this.translate.instant('DASHBOARD.PRODUCTIONLINEALERTS') + ' (' + data.TotalItemsCountAllStatuses + ')';
        this.filterFields[1].data = [{
          label: 'All',
          value: 'All',
          selected: true
        },
        {
          label: 'Min. Qty. not Reached (' + data.Status1Count + ')',
          value: 1
        },
        {
          label: 'Production not Started (' + data.Status2Count + ')',
          value: 2
        },
        {
          label: 'Production not Completed (' + data.Status3Count + ')',
          value: 3
        },
        {
          label: 'Products not Dispatched (' + data.Status4Count + ')',
          value: 4
        }];
      }
    },
      _err => {
        //console.log(err);
        this.showLoader = false;
      });
  }

  getAllCustomerList() {
    const webshopData = [{ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' }];
    this.customerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    })
    //console.log("Form Fields productline : ", this.filterFields);
    this.filterFields[0].data = webshopData;
  }

  redirectToProductionLine(productionLineData: any) {
    if (productionLineData.ProductID !== undefined && productionLineData.ProductID !== null) {
      try {
        const dashboardData = {
          dashboardRedirectStatus: true
        }
        sessionStorage.setItem('dashboardRedirectStatus', JSON.stringify(true));
        this.router.navigate([`/admin-order-production-line-details/${productionLineData.ProductID}/false`], {
          state: { dashboardData },
          replaceUrl: false
        });
      } catch (error) {
      }
    }
  }

}
