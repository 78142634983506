import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { LoginService } from 'src/app/login/state/services/login.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from '../../state/services/webshop.http.service';

@Component({
  selector: 'rewardkart-admin-webshop-language',
  templateUrl: './webshop-language.component.html',
  styleUrls: ['./webshop-language.component.scss']
})
export class WebshopLanguageComponent implements OnInit {
  @Input() webshopData: any;
  @Output() sendLanguage = new EventEmitter<any>();
  languageList: any;
  disableSave: Boolean = false;
  webshopDetail: any;
  constructor(private webshopHttpService: WebshopHttpService,
    private loginService: LoginService,
    private apiService: ApiService,
    private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      } else {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }

    }
  }

  ngOnInit() {
    // console.log('this.webshopData', this.webshopData);
    // this.getLanguageList();
  }

  ngOnChanges() {
    this.getLanguageList()
  }

  getLanguageList() {
    this.webshopHttpService.getLanguages().subscribe(data => {
      this.languageList = data;
      const getDefaultLang = this.webshopData.Languages.filter(res => res.IsDefault);
      this.webshopData.Languages.forEach(element => {
        this.languageList.forEach(lang => {
          lang.IsDefault = false;
          if (element.LanguageID === lang.LanguageID) {
            lang.status = true;
          }
        });
      });
      let getDefaultIndex;
      if (getDefaultLang.length > 0) {
        getDefaultIndex = this.languageList.findIndex(response => response.LanguageID === getDefaultLang[0]['LanguageID']);
        this.languageList[getDefaultIndex]['IsDefault'] = true;
      }
    })
  }

  getSelectedLang(event, index) {

    let languageList = this.languageList;
    if (languageList.length > 0) {
      languageList[index]['statusdup'] = event.checked;
    }
    languageList = languageList.filter(data => data.statusdup || data.status)
    if (languageList.length === 0) {
      this.toast.error('One language is required');
      this.disableSave = true;
    }
    else {
      this.disableSave = false;
    }
    let getSelectedLang = this.languageList[index];
    if (getSelectedLang.status === undefined && event.checked) {
      this.languageList[index]['Action'] = 'Insert';
    }
    else if (getSelectedLang.status && !event.checked) {
      this.languageList[index]['Action'] = 'Delete';
    }
    else if (!getSelectedLang.status && !event.checked || getSelectedLang.status && event.checked) {
      delete this.languageList[index]['Action'];
    }
  }

  updateLanguage() {
    const filterLang = this.languageList.filter(data => data.Action)
    this.sendLanguage.emit(filterLang);
  }


}

