import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { WidgetModalComponent } from './widget-modal.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  declarations: [WidgetModalComponent],
  exports: [WidgetModalComponent],
})

export class WidgetModalModule { }
