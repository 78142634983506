
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Injectable()
@Pipe({ name: 'formatCell' })
export class FormatCellPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe) { }

    transform(value: any, format: string) {
        if (value === undefined) {
            return '';
        }

        if (value === true || value === 'true') {
            return 'Yes';
        }
        if (value === false || value === 'false') {
            return 'No';
        }
        if (format === 'default') {
            if (typeof value === 'object') {
                return value.name;
            }
        } else if (format === 'color') {
            if (typeof value === 'object') {
                return value.name;
            }
        } else if (format === 'currency') {
            const value2 = this.currencyPipe.transform(value, 'USD', 'symbol');
            const firstDigit = value2.match(/\d/);
            const symbol = value2.slice(0, firstDigit.index);
            const amount = value2.slice(firstDigit.index);
            return symbol + ' ' + amount;
        } else if (format === 'date') {
            return this.datePipe.transform(value, 'dd/MM/yyyy');
        }
        return value;
    }
}
