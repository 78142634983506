import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessageTrashdetailsComponent } from './message-trashdetails.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule
  ],
  declarations: [MessageTrashdetailsComponent],
  exports: [MessageTrashdetailsComponent]
})

export class MessagetrashdetailsModule { }
