import { Component, OnInit } from '@angular/core';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

interface ModalActionType {
  type: string;
}

@Component({
  selector: 'rewardkart-admin-unauthorised-modal',
  templateUrl: './unauthorised-modal.component.html',
  styleUrls: ['./unauthorised-modal.component.scss']
})
export class UnauthorisedModalComponent implements OnInit {
  header: any;
  content: any;
  type: string;
  buttonlabel: any;
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  btnLabel: any = 'OK';
  constructor(public modalRef: MDBModalRef, public toastService: ToastService) { }

  ngOnInit() {
    console.log(this.buttonlabel)
    this.btnLabel = this.buttonlabel ? this.buttonlabel : 'OK';
  }

  modalConfirmed() {
    this.modalAction.next({ type: this.type });
  }

  modalCanceled() {
    this.modalRef.hide();
  }

}
