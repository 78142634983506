import { Component, OnInit } from '@angular/core';
import { WebService } from '../../restful-service/web.service';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit {

  public childCustomersList: any = [];
  selectedCustomer :any;


  constructor(public webservice: WebService) { }

  ngOnInit() {
    this.ChildCustomersData();
  }

  ChildCustomersData() {
    this.webservice.getChildCustomers().subscribe((data: any) => {
      var objAry=[]
      for(var i=0;i<data.ChildCustomers.length;i++){
        var obj ={
            value:data.ChildCustomers[i].CustomerID, 
            label:data.ChildCustomers[i].Name
          }
          objAry.push(obj)
      }
      this.selectedCustomer=objAry
    })
  }

}
