import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProductAddComponent } from './product-add.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { ProductManageUnitModule } from './product-manage-unit/product-manage-unit.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CustomdirectiveModule } from '../product-edit/customdirective/customdirective.module';
import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    ProductManageUnitModule,
    ControlValidationModule,
    TooltipModule,
    CustomdirectiveModule,
    NgxEditorModule
  ],
  declarations: [ProductAddComponent],
  exports: [ProductAddComponent]
})

export class ProductAddModule { }
