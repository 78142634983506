import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OnInitEffects } from "@ngrx/effects";
import { ColumnSetting } from "src/app/shared/link-table/models/link-table-model";
import { CompanyOrderHttpService } from "../state/services/company-order.http.services";

@Component({
    selector: 'app-company-order-summary',
    templateUrl: './company-order-summary.component.html',
    styleUrls: ['./company-order-summary.component.scss']
})

export class CompanyOrderSummaryComponent implements OnInit {
    showLoader: boolean = false;
    orderList: any;
    actions: any = ['orderview'];
    @Input() startDate: any;
    @Input() endDate: any;
    @Input() customerID: any;
    columns: ColumnSetting[] = [
        {
            primaryKey: 'OrderID',
            header: 'Order ID'
        },
        {
            primaryKey: 'TotalItemsCount',
            header: 'Total Items',
            width: '30px'
        },
        {
            primaryKey: 'OrderCreationDate',
            format: 'date',
            header: 'Order Date',
        },
        {
            primaryKey: 'UserName',
            header: 'User Name'
        },
        {
            primaryKey: 'UserEmail',
            header: 'User Email'
        },
        {
            primaryKey: 'UserPhoneNumber',
            header: 'Phone Number'
        },
        {
            primaryKey: 'action',
            format: 'action',
            width: '30px'
        }
    ]
    header: any;
    constructor(private router: Router, private _http: CompanyOrderHttpService) {

        if (history.state.data && history.state.data.id) {
            this.customerID = history.state.data.id;
            this.startDate = history.state.data.startDate;
            this.endDate = history.state.data.endDate
            this.getOrderDetail();
        } else {
            this.router.navigate(['/admin-dashboard'])
        }
        this.header = "Company Orders";
    }

    ngOnInit(): void {
        if (this.customerID) {
            this.getOrderDetail();
        }
    }

    getOrderDetail() {
        this.showLoader = true;
        this._http.ordersummary(this.customerID, this.startDate, this.endDate).subscribe((data: any) => {
            this.showLoader = false;
            this.orderList = data;
        }, (_error) => {
            this.showLoader = false;
        })
    }

    viewSummary(event) {
        console.log(event)
        let data = {
            orderSummary: event,
            customerID: this.customerID,
            startDate: this.startDate,
            endDate: this.endDate
        }
        this.router.navigate(['/admin-company-order-detail'], { state: { data }, replaceUrl: false });
    }
}