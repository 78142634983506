import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { UserEditComponent } from './user-edit.component';
import { UserHttpService } from '../state/services/user.http.service';
import { UserService } from '../state/services/user.service';

import { userReducers } from '../state/reducers/user.reducer';
import { UserEffects } from '../state/effects/user.effects';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { showListModule } from '../showlist/showlist.module';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { userAdditionalDeliveryListModule } from '../useradditionaldeliverylist/useradditionaldeliverylist.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommonFormModule,
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('userModule', userReducers),
    ControlValidationModule,
    showListModule,
    userAdditionalDeliveryListModule,
    PageLoaderModule,
    TranslateModule
  ],
  declarations: [UserEditComponent],
  providers: [UserHttpService, UserService],
  exports: [UserEditComponent]
})

export class UserEditModule { }