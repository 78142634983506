import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
interface ModalActionType {
  data: any[];
  filterState: any;
}

@Component({
  selector: 'rewardkart-admin-order-advance-search-modal',
  templateUrl: './order-advance-search-modal.component.html',
  styleUrls: ['./order-advance-search-modal.component.scss']
})
export class OrderAdvanceSearchModalComponent {
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  orderItems: any = [];

  constructor(public modalRef: MDBModalRef) { }

  advanceSearch() {
    const selectedOrderItems = [];
    const filterState = {};
    this.orderItems.forEach((status) => {
      if (status.checked) {
        const { OrderItemStateOptionID, value, Options } = status;
        filterState[value] = { OrderItemStateOptionID, value };
        status.OrderItemStateOptionID && Number.isInteger(OrderItemStateOptionID) ?
          selectedOrderItems.push({ OrderItemStateOptionID }) :
          selectedOrderItems.push({ OrderItemStateID: this.getOrderItemStateOptionID(Options, OrderItemStateOptionID, value) });
      }
    });
    this.modalAction.next({ data: selectedOrderItems, filterState });
    this.modalRef.hide();
  }

  getOrderItemStateOptionID(options, OrderItemStateOptionID, value) {
    if (options.length > 0 && OrderItemStateOptionID) {
      return options.filter(optionItem => (optionItem.OrderItemStateOptionID === OrderItemStateOptionID))[0].OrderItemStateID;
    } else {
      return value;
    }
  }

  statusChanges(event, statusIndex) {
    if (this.orderItems[statusIndex].checked) {
      this.orderItems[statusIndex].OrderItemStateOptionID = '';
    }
  }

  subStatusChanges(event, statusIndex) {
    if (this.orderItems[statusIndex].checked == false) {
      this.orderItems[statusIndex].checked = true;
    }
  }

  clearForm() {
    this.orderItems.forEach(item => {
      item.checked = false;
      item.OrderItemStateOptionID = '';
    });
  }

  ngOnDestroy() {
    this.modalAction.unsubscribe();
  }

}
