import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { BannerEnquiryHttpService } from '../state/services/bannerenquiry.http.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { BannerEnquiryService } from '../state/services/bannerenquiry.service';

@Component({
    selector: 'rewardkart-admin-bannerenquiry-list',
    templateUrl: './bannerenquiry-list.component.html',
    styleUrls: ['./bannerenquiry-list.component.scss']
})

export class BannerEnquiryListComponent implements OnInit {
    columns: ColumnSetting[] = [];
    enquiryList: any = [];
    actions: any = ['view'];
    submitted = false;
    isLoading: Boolean = false;
    header: any;
	tableEvent:any;
    customerID:any;
    constructor(private bannerenquiryService: BannerEnquiryService, private router: Router, private _httpServerice: BannerEnquiryHttpService, private linkTableService: LinkTableService,) {
        this.columns = [
            {
                primaryKey: 'FirstName',
                header: 'First Name'
            },
            {
                primaryKey: 'LastName',
                header: 'Last Name'
            },
            {
                primaryKey: 'CompanyName',
                header: 'Company Name'
            },
            {
                primaryKey: 'Mobile',
                header: 'Mobile Number'
            }
            ,
            {
                primaryKey: 'CompanyEmail',
                header: 'Email Address'
            },
            {
                primaryKey: 'Description',
                header: 'Description'
            },
            {
                primaryKey: 'action',
                format: 'bannerenquiryaction',
            }
        ]
        const customerDetails = JSON.parse(sessionStorage.getItem("customerDetails"));
        this.customerID = customerDetails.CustomerID;

    }
    ngOnInit(): void {
        this.header = 'Banner Enquiry List';
        this.isLoading = true;
        this._httpServerice.getBannerEnquiryList(this.customerID).subscribe(
            data => {
                this.isLoading = false;
                this.enquiryList = data;
            },_error=>{
                this.isLoading = false;
            }
        )
    }
    viewSummary(userData: boolean) {
       
        if (userData) {
            try {
                this.bannerenquiryService.selectedEnquiry(userData);
                this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
                    if (data && data.BannerInfoId) {
                        this.router.navigate(['/admin-banner-enquiry-detail'], { state: { data }, replaceUrl: false });
                    }
                });
            }
            catch (error) {

            }
        }
    }
	
 ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }
}