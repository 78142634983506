import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SupplierState } from '../reducers/supplier.reducer';
import { SupplierActions } from '../actions/index';
import { getSupplierListSuccess, getAllSuppliers } from '../reducers/index';

@Injectable()
export class UnderSupplierService {
    constructor(private store: Store<SupplierState>) { }

    loadSupplierList(searchCriteria: any) {
        this.store.dispatch(new SupplierActions.LoadSupplierList(searchCriteria));
    }

    getSupplierList() {
        return this.store.select(getSupplierListSuccess);
    }

    loadAllSuppliers() {
        this.store.dispatch(new SupplierActions.LoadSuppliers());
    }

    getAllSuppliers() {
        return this.store.select(getAllSuppliers);
    }

}
