import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserService } from 'src/app/user/state/services/user.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  @Input() ImageUrl: string;
  @Input() allowMultipleSelection = false;
  @Input() bucketName: string;
  @Input() alt = '';
  @Input() uploadButtonText = 'Picture Upload';
  @Input() note = 'Image Size W * H : 1410 * 300';
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUploaded = new EventEmitter();
  filesToUpload = [];
  isLoading: boolean = false;
  constructor(private userService: UserService, private toastr: ToastService) {
   
   }

  ngOnInit() {
    if(this.bucketName === 'CustomerImages'){
      this.note = '';
    }
   }
  onSelectFile(event) {
    const filesCount: number = event.target.files.length || 0;

    if (filesCount > 0) {
      for (let i = 0; i < filesCount; i++) {
        const reader = new FileReader();
        reader.onload = (loadedEvent: any) => {
          this.ImageUrl = loadedEvent.target.result;
          this.filesToUpload.push(loadedEvent.target.result);
        };
        console.log(event.target.files[i]);
        if (event.target.files[i].size > 2000000) {
          this.toastr.error('File size must be less than 2mb')
        } else {
          reader.readAsDataURL(event.target.files[i]);
          this.uploadSelectedImage(event.target.files[i]);
        }
        event.target.value = '';
      }
    }
  }

  emitUploadedData(data, error = null) {
    this.onUploaded.emit({ data, error });
  }

  uploadSelectedImage(file) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', null);
    httpHeaders.set('Accept', 'multipart/form-data');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filePath', this.bucketName);
    this.isLoading = true;
    this.userService.loaduploadFile(formData);
    this.userService.uploadFile().subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data) {
          this.emitUploadedData(data);
        }
      },
      error => {
        this.isLoading = false;
        this.emitUploadedData(null, error);
      }
    );
  }
}
