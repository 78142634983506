import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from '../state/services/dashboard.service';
import { ApiService } from '../../shared/common-services/api.service';
import { Router } from '@angular/router';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';

@Component({
  selector: 'app-dashboard-product',
  templateUrl: './dashboard-product.component.html',
  styleUrls: ['./dashboard-product.component.scss']
})
export class DashboardProductComponent implements OnInit {
  public productList = [];
  productListQueryParams: any = {};
  showLoader: boolean = false;
  webshopDetail: any;
  constructor(public translate: TranslateService,
    public dashboardservice: DashboardService,
    public apiService: ApiService, public dashboardhttpservice: DashboardHttpService,
    public router: Router, private linkTableService: LinkTableService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.getDashboardProductList();
  }


  onLoadFunctions() {
    // this.dashboardservice.loadProductList(this.productListQueryParams);
  }

  getDashboardProductList() {
    this.showLoader = true;
    this.productListQueryParams.customerID = this.webshopDetail.CustomerID;
    this.dashboardhttpservice.getProductList(this.productListQueryParams).subscribe((data: any) => {
      if (data) {
        this.showLoader = false;
        this.productList = data.ItemsCollection;
      }
      else {
        this.showLoader = false;
      }
    },
      _err => {
        //console.log(err);
        this.showLoader = false;
      });
  }

  NavigateToProductList() {
    this.router.navigate(['/admin-product-list'], { replaceUrl: false });
  }
  productdetails(productData: any) {
    if (productData.ProductID !== undefined && productData.ProductID !== null) {
      productData.dashboardRedirectStatus = true;
      const data = productData;
      let dashboardPage = 'dashboardPage'
      if (productData.isMarketingProduct) {
        this.router.navigate(['/product-market-edit'], { state: { data, dashboardPage }, replaceUrl: false });
      } else {
        this.router.navigate(['/admin-product-edit'], { state: { data, dashboardPage }, replaceUrl: false });
      }
    }
  }
}
