import { Injectable } from '@angular/core';
import { RequestOptions, Http, HttpModule, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService } from '../shared/common-services/api.service';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  constructor(private http: Http, public httpClient: HttpClient, public apiService: ApiService) { }

  getOrderData() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/orders?suborders=true&pageIndex=0&pageSize=5&unfinishedOrders=true')
  }

  getProductLineData() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/productionlineswidget?customerID=1&pageIndex=1&pageSize=5')
  }

  // getMessageLineData(){
  //   return this.httpClient.get(this.apiService.baseUrl+'en/emails?user_id=3817&inbox=true&pageIndex=1&pageSize=10&CustomerID=1')
  // }

  // getMessageSentMessage(){
  //   return this.httpClient.get(this.apiService.baseUrl+'en/emails?user_id=3817&sent=true&pageIndex=1&pageSize=10&CustomerID=1')
  // }
  // getMessageImportant(){
  //   return this.httpClient.get(this.apiService.baseUrl+'en/emails?user_id=3817&important=true&pageIndex=1&pageSize=10&CustomerID=1')
  // }

  // getMessageDraft(){
  //   return this.httpClient.get(this.apiService.baseUrl+'en/emails?user_id=3817&drafts=true&pageIndex=1&pageSize=10&CustomerID=1')
  // }

  getMessagetrash() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/emails?user_id=3817&trash=true&pageIndex=1&pageSize=10&CustomerID=1')
  }
  getMessageinquiries() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/inquiries?pageIndex=1&pageSize=10&CustomerID=1')
  }
  getMessageclaims() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/claims?user_id=3817&pageIndex=1&pageSize=10&CustomerID=1&inbox=true')
  }
  getUnreadMessageData(customerID, childcustomer) {
    return this.httpClient.get(this.apiService.baseUrl + 'en/unreadmessages/?CustomerID=' + customerID + '&includeChildCustomers=' + childcustomer)
  }

  getStateList() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/orderitemstates?roleCode=Webshop_Admin&altaPayStates=true&customerID=1')
  }

  getChildCustomers() {
    return this.httpClient.get(this.apiService.baseUrl + 'en/customers/1')
  }

  deleteInquiry(data) {
    return this.httpClient.delete(this.apiService.baseUrl + 'en/inquiries/' + data.InquiryID)
  }

  deleteClaim(data) {
    return this.httpClient.delete(this.apiService.baseUrl + 'en/claims/' + data.claimid)
  }

  productEditdata(data, openbook?, getWebshopDetails?) {
    if (openbook) {
      return this.httpClient.get(this.apiService.baseUrl + 'all/products/' + data.ProductID + '?translations=true&admin=true&OrderbyName=true&LoggedInCustomerID=' + getWebshopDetails.CustomerID);
    }
    else {
      return this.httpClient.get(this.apiService.baseUrl + 'all/products/' + data.ProductID + '?translations=true&customerid=' + data.customerID + '&salesPrices=true&LoggedInCustomerID=' + getWebshopDetails.CustomerID);
    }
  }

  uploadImg(data) {
    const path = 'en/uploadfile';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  addLogoType(data) {
    const path = '/all/logotypes';
    return this.apiService.post(path, data).map((res) => {
      return res;
    })
  }

  deleteLogotype(data) {
    const path = '/all/logotypes/' + data.LogoTypeID;
    return this.apiService.delete(path).map((res) => {
      return res;
    })
  }

  updateLogoType(data) {
    const path = '/all/logotypes/' + data.LogoTypeID;
    return this.apiService.post(path, data).map((res) => {
      return res;
    })
  }

  //   updateCalendar(data){
  //     return this.httpClient.post(this.apiService.baseUrl+'Calendar/InsertCalendarDetails', data);
  //   }

}
