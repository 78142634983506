import { Component, OnInit } from '@angular/core';
import { BannerEnquiryHttpService } from '../state/services/bannerenquiry.http.service';

@Component({
    selector: 'rewardkart-admin-bannerenquiry-detail',
    templateUrl: './bannerenquiry-detail.component.html',
    styleUrls: ['./bannerenquiry-detail.component.scss']
})

export class BannerEnquiryDetailComponent implements OnInit {
    enquiryDetails: any;
    constructor(private _httpServerice: BannerEnquiryHttpService) {

    }
    ngOnInit(): void {
        let id = history.state.data.BannerInfoId;
        this._httpServerice.getBannerEnquiryDetails(id).subscribe(
            data => {
                this.enquiryDetails = data[0];
            }
        )
    }
}