import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ProductAddMarketingComponent } from './product-add-marketing.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    ControlValidationModule,
    TooltipModule,
    FormsModule,
    NgSelectModule,


  ],
  declarations: [ProductAddMarketingComponent],
  exports: [ProductAddMarketingComponent]
})

export class ProductAddMarketingModule { }
