import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../../shared/link-table/models/link-table-model';
import { ApiService } from '../../../shared/common-services/api.service';
import { MessageboxService } from '../../state/services/messagebox.service';
import { LinkTableService } from '../../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Location } from '@angular/common';
import { MessageboxHttpService } from '../../state/services/messagebox.http.service';

@Component({
  selector: 'app-message-importantdetails',
  templateUrl: './message-importantdetails.component.html',
  styleUrls: ['./message-importantdetails.component.scss']
})
export class MessageImportantdetailsComponent implements OnInit {
  customerList: any = [];
  showMessageDetailpage = true;
  customerId: any;
  messageinboxQueryParams: any = {};
  importantdetailsData: any;
  mailInfo: any
  emailid: any;
  filterFields: any;
  webshopDetail: any;
  constructor(public messageboxservice: MessageboxService,
    private toast: ToastService,
    public apiService: ApiService,
    private linkTableService: LinkTableService,
    private location: Location,
    private router: Router, private messageboxhttpservice: MessageboxHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.filterFields = [{
      label: 'Delete',
      type: 'button',
      class: 'deleteico',
      key: 'messagedelete',
      model: 'deleteclaims'
    }]
    this.mailInfo = window.history.state;
    this.emailid = this.mailInfo.data.EmailID;
    this.MessageBoxLineData();
  }
  ngOnChanges() {
  }
  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });
      }
    })
  }
  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.customerId = this.webshopDetail.CustomerID;
    this.messageinboxQueryParams.customerID = this.customerId;
    this.messageinboxQueryParams.UserID = loginData.UserID;


    this.messageboxservice.loadCustomers(this.messageinboxQueryParams)
    // this.filterParams = {

    // }
  }
  MessageBoxLineData() {
    this.messageinboxQueryParams.emailID = this.emailid;
    this.messageboxservice.loadMessageboximportantState(this.messageinboxQueryParams);
    this.messageboxservice.getMessageimportantdetailsData().subscribe((data: any) => {
      // if (data) {

      //   console.log(this.importantdetailsData)
      //   // this.messageinbox = data.ItemsCollection;
      // }
      // const loginData = this.apiService.getLoginData();
      this.importantdetailsData = data;
      if (this.importantdetailsData !== null) {
        let unreadData = {
          emailid: this.importantdetailsData.EmailID,
          customerid: this.webshopDetail.CustomerID
        }
        this.messageboxservice.loadUnread(unreadData);
        this.messageboxservice.unreadMessage().subscribe(data => {
        })
      }
    })
  }

  pageredirect() {
    this.router.navigate(['/app-message-important'], { replaceUrl: false });
  }


  deleteMail(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deleteclaims) {
      const loginData = this.apiService.getLoginData();
      // const requestData = {
      //   "EmailID": this.importantdetailsData.EmailID,
      //   "SenderID": this.importantdetailsData.SenderID,
      //   "ReceiverID": this.importantdetailsData.ReceiverID,
      //   "CustomerID": this.importantdetailsData.CustomerID,
      //   "EmailTypeID": this.importantdetailsData.EmailTypeID,
      //   "Subject": this.importantdetailsData.Subject,
      //   "Body": this.importantdetailsData.Body,
      //   "SenderType": this.importantdetailsData.SenderType,
      //   "ReceiverType": this.importantdetailsData.ReceiverType,
      //   "SendingTime": this.importantdetailsData.SendingTime,
      //   "Status": this.importantdetailsData.Status,
      //   "SenderName": this.importantdetailsData.SenderName,
      //   "SenderEmail": this.importantdetailsData.SenderEmail,
      //   "SenderImageName": this.importantdetailsData.SenderImageName,
      //   "SenderImageURL": this.importantdetailsData.SenderImageURL,
      //   "ReceiverName": this.importantdetailsData.ReceiverName,
      //   "ReceiverEmail": this.importantdetailsData.ReceiverEmail,
      //   "ReceiverImageName": this.importantdetailsData.ReceiverImageName,
      //   "ReceiverImageURL": this.importantdetailsData.ReceiverImageURL,
      //   "senderName": this.importantdetailsData.SenderName,
      //   "senderEmail": this.importantdetailsData.SenderEmail,
      //   "recieverName": this.importantdetailsData.RecieverName,
      //   "recieverEmail": this.importantdetailsData.RecieverEmail,
      //   "isTrash": true,
      //   "isDeleted": true,
      //   "UserID": loginData.UserID,
      //   "EmailType": "Email"
      // }

      // this.messageboxservice.deleteMail(requestData);
      // this.messageboxservice.getSendMessageSuccess().subscribe(data => {
      //   if (data == null) {
      //     setTimeout(() => {
      //       console.log('data', data);
      //       this.toast.success('Message Successfully Deleted');
      //       this.pageredirect();
      //     }, 300);
      //   }
      // });


      const deleteData = {
        "ActionType": "TRASH",
        "Value": true,
        "EmailIDs": [this.importantdetailsData.EmailID]
      }

      const initialRequest = {
        "ActionType": "IMPORTANT",
        "Value": false,
        "EmailIDs": [this.importantdetailsData.EmailID]
      }

      this.messageboxhttpservice.bulkAction(initialRequest, loginData.UserID).subscribe((response: any) => {
        if (response) {
          this.messageboxhttpservice.bulkAction(deleteData, loginData.UserID).subscribe((data: any) => {
            if (data != null) {
              if (data) {
                setTimeout(() => {
                  // this.messageboxservice.loadMessageimportantState(this.messageimportantQueryParams);
                  this.toast.success('Message Successfully Deleted');
                  this.pageredirect();
                }, 300)
              }
            }
          })
        }
      });


    }
  }
}
