import { Action } from '@ngrx/store';
export const LANGUAGE = '[Login] Language';
export const VALIDATE_USER = '[Users] Load ValidateUser';
export const VALIDATE_USER_SUCCESS = '[Users] Load ValidateUser Success';
export const VALIDATE_USER_FAIL = '[Users] Load ValidateUser Fail';
export const GENERATE_MOBILE_OTP = '[Signup] Load Generate Mobile OTP';
export const GENERATE_MOBILE_OTP_SUCCESS = '[Signup] Load Generate Mobile Success';
export const GENERATE_MOBILE_OTP_FAIL = '[Signup] Load Generate Mobile Fail';
export const GENERATE_EMAIL_OTP = '[Signup] Load Generate Email OTP';

export class LoadValidateUser implements Action {
    readonly type = VALIDATE_USER;
    constructor(public payload: any) { }
}

export class LoadValidateUserSuccess implements Action {
    readonly type = VALIDATE_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadValidateUserFail implements Action {
    readonly type = VALIDATE_USER_FAIL;
    constructor(public payload: any) { }
}

export class LoadGenerateMobileOTP implements Action {
    readonly type = GENERATE_MOBILE_OTP;
    constructor(public payload: any) { }
}

export class LoadGenerateMobileOTPSuccess implements Action{
    readonly type = GENERATE_MOBILE_OTP_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadGenerateMobileOTPFail implements Action{
    readonly type = GENERATE_MOBILE_OTP_FAIL;
    constructor(public payload: any) { }
}


export type All =   | LoadValidateUser
                    | LoadValidateUserSuccess
                    | LoadValidateUserFail
                    | LoadGenerateMobileOTP
                    | LoadGenerateMobileOTPSuccess
                    | LoadGenerateMobileOTPFail;