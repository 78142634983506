import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginHttpService } from 'src/app/login/state/services/login.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { UserHttpService } from 'src/app/user/state/services/user.http.service';
import { UserService } from 'src/app/user/state/services/user.service';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { DashboardService } from '../state/services/dashboard.service';

@Component({
  selector: 'rewardkart-admin-dashboard-inactive-users',
  templateUrl: './dashboard-inactive-users.component.html',
  styleUrls: ['./dashboard-inactive-users.component.scss']
})
export class DashboardInactiveUsersComponent implements OnInit {
  @Input() customerList: any = [];
  activePage: number = 1;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  public orderNotificationList: any = [];
  public orderNotificationListMasterData: any = [];
  public orderNotificationListCustomize: any = [];
  public titles: any;
  public totalCountStock: any;
  public totalPagingCountStock: any;
  stockQueryParams: any = {};
  showLoader: boolean = false;
  public notificationalarmcolumns: ColumnSetting[] = [];
  tableName: any = 'dashboardordernotificationalarm';

  public filterFields: any = [];
  public stockAlarmListQuery: any = {};
  resetPaginationNumber: boolean = false;
  loginuserdetails: any;
  webshopDetail: any;
  isParentCustomer: any;
  actions: any = ['redirectToOrderProcessPage']
  CustomerID: any;
  constructor(public dashboardservice: DashboardService, public dashboardhttpservice: DashboardHttpService, public apiService: ApiService, private router: Router,
    private userHttpService: UserHttpService, private translate: TranslateService, private userService: UserService, private loginHttpService: LoginHttpService) {
    this.titles = this.translate.instant('DASHBOARD.STOCKALARMS');
    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.notificationalarmcolumns = [
      {
        primaryKey: 'UserName',
        header: this.translate.instant('DASHBOARD.USERNAME')
      },
      {
        primaryKey: 'Name',
        header: this.translate.instant('DASHBOARD.NAME')
      },
      {
        primaryKey: 'CompanyName',
        header: this.translate.instant('DASHBOARD.COMPANYNAME')
      },
      {
        primaryKey: 'Email',
        header: this.translate.instant('DASHBOARD.EMAIL')
      },
      {
        primaryKey: 'Country',
        header: this.translate.instant('DASHBOARD.COUNTRY'),
      },
      {
        primaryKey: 'UserCreatedDate',
        header: this.translate.instant('DASHBOARD.USERCREATED'),
      },
      {
        primaryKey: 'LastActivityDate',
        header: this.translate.instant('DASHBOARD.LASTLOGIN'),
      },
      {
        primaryKey: 'InactiveDays',
        header: this.translate.instant('DASHBOARD.INACTIVEDAYS'),
      },
      {
        primaryKey: 'InactiveMonths',
        header: this.translate.instant('DASHBOARD.INACTIVEMONTHS'),
      },
    ];
    this.onLoadFunctions();
  }

  ngOnInit() {
    const hostname = window.location.hostname; // for deploy 
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    if (urlParts !== null) {
      const getCust = hostname.replace(urlParts[0], '').slice(0, -1);
      this.loginHttpService.getCustomer(getCust).subscribe(
        (data1: any) => {
          //console.log("CustomerID" + data1.CustomerID);
          this.webshopDetail = data1;
          let webshopEnableStatus = true;
          if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
            webshopEnableStatus = false;
            if (this.webshopDetail.IsWebshopDropdown) {
              webshopEnableStatus = true;
            }
          }
          if (this.webshopDetail !== null && this.webshopDetail !== undefined && this.webshopDetail !== '' && this.webshopDetail !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            if (this.webshopDetail.ParentCustomerID !== null && this.webshopDetail.ParentCustomerID !== undefined) {
              this.isParentCustomer = false;
            }
          } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
              this.webshopDetail.CustomerID = loginData.CustomerID;
            }
          }
          //console.log("isParentCustomer" + this.isParentCustomer);
          this.filterFields = [
            {
              label: this.translate.instant('MENU.WEBSHOPS'),
              type: 'select',
              key: 'selectwebshop',
              data: [],
              placeholder: this.translate.instant('CATEGORIES.SELECT_ALL'),
              class: (webshopEnableStatus && this.isParentCustomer) ? 'col-6' : ''
            },
            {
              type: 'radio',
              radioArray: [{ key: 'selectactiveusers', label: this.translate.instant('MENU.ACTIVEUSERS') }, { key: 'selectdeletedusers', label: this.translate.instant('MENU.DELETEDUSERS') }],
              class: (webshopEnableStatus && this.isParentCustomer) ? 'col-6' : 'col-6',
              selected: 'selectactiveusers'
            }

          ];
          this.stockAlarmListQuery.showDeletedUsers = false;
          this.stockAlarmListQuery.webshopID = 'All';
          this.getDashboardInActiveDaysList();
          this.getAllCustomerList();
        })
    }
  }

  onLoadFunctions() {
    this.stockQueryParams.customerID = this.webshopDetail.CustomerID;
    // this.dashboardservice.loadStock(this.stockQueryParams);
  }

  getSelectData(alarmName) {

    if (alarmName.selectactiveusers !== undefined && alarmName.selectactiveusers.selectMethod === 'selectactiveusers') {
      if (alarmName.selectactiveusers.data.selected === 'selectactiveusers') {
        this.stockAlarmListQuery.showDeletedUsers = false;
      } else {
        this.stockAlarmListQuery.showDeletedUsers = true;
      }
      this.activePage = 1;
      this.perPagedata = 10;
    }
    else if (alarmName.selectdeletedusers !== undefined && alarmName.selectdeletedusers.selectMethod === 'selectdeletedusers') {
      this.stockAlarmListQuery.showDeletedUsers = true;
    }
    if (alarmName.selectwebshop !== undefined && alarmName.selectwebshop.selectMethod === 'selectwebshop') {
      if (alarmName.selectwebshop.value !== '') {
        this.stockAlarmListQuery.webshopID = alarmName.selectwebshop.value;
      } else {
        this.stockAlarmListQuery.webshopID = 'All';
      }
    }
    this.stockAlarmListQuery.pageIndex = 1;
    this.stockAlarmListQuery.pageSize = 10;
    this.getDashboardInActiveDaysList();
  }

  paginationNum(event) {
    //console.log("paginationNum : ", event);
    this.activePage = (this.resetPaginationNumber) ? 1 : event;
    this.stockAlarmListQuery.pageIndex = (this.resetPaginationNumber) ? 1 : event;
    this.resetPaginationNumber = false;
    this.getDashboardInActiveDaysList();
  }

  getPerPage(event) {
    //console.log(event)
    this.perPagedata = event;
    this.stockAlarmListQuery.pageSize = event;
    this.resetPaginationNumber = true;
  }

  redirectToOrderProcessPage(userData) {
    if (userData) {
      // this.showUserEditpage = true;
      try {
        sessionStorage.setItem('dashboardRedirectStatus', JSON.stringify(true));
        this.userHttpService.getUserData(userData.UserID).subscribe(data => {
          if (data) {
            data['dashboardRedirectStatus'] = true;
            this.router.navigate(['/admin-user-edit'], { state: { data }, replaceUrl: false });
          }
        },
          _err => {
            //console.log(err);
          });
      } catch (error) {
      }
    }
  }

  getDashboardInActiveDaysList() {
    this.showLoader = true;
    const loginData = this.apiService.getLoginData();
    let includChildShopUsers = false;
    if (loginData.CustomerID === '') {
      includChildShopUsers = true;
    }
    let searchData = {
      'customerID': this.CustomerID,
      // 'webshopID': (this.stockAlarmListQuery.webshopID !== undefined && this.stockAlarmListQuery.webshopID !== null) ?
      //  this.stockAlarmListQuery.webshopID : '',

      "includChildShopUsers": includChildShopUsers,
      "showDeletedUsers": this.stockAlarmListQuery.showDeletedUsers,
      'pageIndex': (this.stockAlarmListQuery.pageIndex !== undefined && this.stockAlarmListQuery.pageIndex !== null) ?
        this.stockAlarmListQuery.pageIndex : this.activePage,
      'pageSize': (this.stockAlarmListQuery.pageSize !== undefined && this.stockAlarmListQuery.pageSize !== null) ?
        this.stockAlarmListQuery.pageSize : this.rowPagepage
    }
    if (this.webshopDetail.ParentCustomerID === null || this.webshopDetail.ParentCustomerID === undefined) {
      searchData['webshopID'] = (this.stockAlarmListQuery.webshopID !== undefined && this.stockAlarmListQuery.webshopID !== null) ?
        this.stockAlarmListQuery.webshopID : '';
      if (searchData['webshopID'] === 'All') {
        searchData['webshopID'] = this.webshopDetail.CustomerID;
      }
    } else {
      searchData['webshopID'] = this.webshopDetail.CustomerID;
    }
    //console.log("this.stockAlarmListQuery : ", this.stockAlarmListQuery);
    this.dashboardhttpservice.getInActiveUsersList(searchData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        data.ItemsCollection.forEach(element => {

          // tslint:disable-next-line:max-line-length

          if (element.IsNotificationSent == false) {
            // element.StockBalanceAlertTypeName = '<p class="text-success">Expires in ' + element.ExpiresInDays + ' days</p>';
            // tslint:disable-next-line:max-line-length
            element.IsNotificationSent = "No";
          } else {
            // element.IsNotificationSent = '<p class="text-danger">Delayed</p>';
            element.IsNotificationSent = "Yes";
          }
        });
        this.orderNotificationList = data.ItemsCollection;
        this.totalPagingCountStock = data.TotalItemsCount;
        this.enablepagination = true;
        if (data.ItemsCollection.length === 0) {
          this.enablepagination = false;
        }
        this.orderNotificationListMasterData = data;
      }
      else {
        this.showLoader = false;
      }
    },

      _err => {
        //console.log(err);
        this.showLoader = false;
      });
  }

  getAllCustomerList() {

    const webshopData = [{ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' }];
    this.customerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    })
    this.filterFields[0].data = webshopData;
  }



}
