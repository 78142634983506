import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ProdctionLineStatusModalComponent } from './production-line-status-modal/production-line-status-modal.component';
import { OrderHttpService } from '../../state/services/order.http.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { InternalNoteComponent } from '../../order-process-view-details/order-process-info-container/internal-note/internal-note.component';

@Component({
  selector: 'rewardkart-admin-order-item-list',
  templateUrl: './production-line-item.component.html',
  styleUrls: ['./production-line-item.component.scss']
})
export class ProductionLineItemComponent implements OnInit {
  @Input() Products = [];
  @Input() OrderId = '';
  @Input() customerID;
  @Input() loading = true;
  @Input() finishedPL = false;
  modalRef: MDBModalRef;
  itemToExpand = {};
  selectedProduct: any = { DeliveryDate: '' };

  timeline = {};

  actions: any = ['Status', 'Order Overview'];
  tableName = 'orderViewDetails';

  columns: ColumnSetting[] = [
    {
      primaryKey: 'OrderIdManual',
      header: 'Order Number',
    },
    {
      primaryKey: 'UserName',
      header: 'User Name',
      format: 'default'
    },
    {
      primaryKey: 'CompanyName',
      header: 'Company Name',
      format: 'default'
    },
    {
      primaryKey: 'DeliveryCountry',
      header: 'Delivery Country',
      format: 'default'
    },
    {
      primaryKey: 'InvoiceCountry',
      header: 'Invoice Country',
      format: 'default'
    },
    {
      primaryKey: 'WebShop',
      header: 'Web Shop',
      format: 'default'
    },
    {
      primaryKey: 'TotalQuantity',
      header: 'Quantity',
      format: 'default'
    }, {
      primaryKey: 'PaymentMode',
      header: 'Payment Type',
      format: 'default'
    },
    {
      primaryKey: 'isPaymentReceived',
      header: 'Payment Received',
      format: 'default'
    },
    {
      primaryKey: 'isDispatched',
      header: 'Dispatched',
      format: 'default'
    },
    {
      primaryKey: 'isDelivered',
      header: 'Delivered',
      format: 'default'
    },
    {
      primaryKey: 'hasBrandingOption',
      header: 'Branding Option',
      format: 'default'
    },
    {
      primaryKey: 'isDeliveryNoteGenerated',
      header: 'Delivery Note Generated',
      format: 'Quantity'
    },
    {
      primaryKey: 'action',
      format: 'controlledLinkAction',
      showSort: false
    }
  ];

  productInformation = [{
    label: 'ProductionLineID',
    key: 'ProductionLineID'
  },
  {
    label: 'Ordered Qty',
    key: 'TotalOrderedQuantity'
  },
  {
    label: 'Supplier',
    key: 'SupplierName'
  },
  ];

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top modal-lg', // for modal dialog
    animated: true
  };
  prodDetail: any;
  header: any;
  webshopDetail: any;
  deliveryHistory: any = [];
  constructor(private router: Router, private modalService: MDBModalService,
    private orderService: OrderHttpService, private toasterServcie: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    debugger
    console.log(this.customerID);
    if (sessionStorage.getItem('productionlineRedirectStatus') !== undefined &&
      sessionStorage.getItem('productionlineRedirectStatus') !== null) {
      sessionStorage.removeItem('productionlineRedirectStatus');
    }
  }

  deliveryDatePopup(product) {
    debugger
    this.orderService.getDeliveryNotificationMsgHistory(product.ProductionLineID).subscribe(response => {
      this.loading = false;
      if (response) {
        this.deliveryHistory = response;
      }
    })
    setTimeout(() => {
      const modalData = {
        type: 'deliveryEdit',
        header: `Production Line Delivery Date - #${product.ProductionLineID} `,
        deliveryDateLabel: `${product.DeliveryDate}`,
        dateModel: moment(product.DeliveryDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        order: product,
        DeliveryNotificationMessage: "",
        DonotAllowJoinOrders: `${product.DonotAllowJoinOrders}`,
        DeliveryHistory: this.deliveryHistory
      };

      this.modalRef = this.modalService.show(ProdctionLineStatusModalComponent, {
        ...this.modalOptions, data: modalData
      });


      this.modalRef.content.modalAction.subscribe((result: any) => {
        const { data } = result;
        this.loading = true;
        const { ProductionLineID } = product;
        const { IsProductionLineEmail, dateModel, discountPercentage, DeliveryNotificationMessage, DonotAllowJoinOrders } = data;
        const deliveryDate = moment(dateModel, 'DD/MM/YYYY').format('YYYY-MM-DD');
        // if (this.customerID === '') {
        //   this.customerID = this.webshopDetail.CustomerID;
        // }
        let getCustomerID = this.customerID;
        if (getCustomerID === undefined || getCustomerID === null || getCustomerID === '') {
          if (product.Orders !== undefined && product.Orders !== null && product.Orders.length > 0) {
            if (product.Orders[0].hasOwnProperty(['CustomerID'])) {
              getCustomerID = product.Orders[0]['CustomerID'];
            }
            else {
              getCustomerID = this.webshopDetail.CustomerID;
            }
          }

        }

        this.orderService.updateProductionLineDate(ProductionLineID, deliveryDate,
          IsProductionLineEmail, discountPercentage, DeliveryNotificationMessage, DonotAllowJoinOrders, getCustomerID).subscribe(response => {
            this.loading = false;
            if (response) {
              this.modalRef.hide();
              this.handleDeliveryDateResponse(product, response);
            } else {
              this.toasterServcie.error('unable to update the production delivery date');
            }
          }, error => {
            this.loading = false;
            this.toasterServcie.error('unable to update the production delivery date');
          });
      });
    }, 700)
  }

  handleDeliveryDateResponse(product, response) {
    const modalData = {
      type: 'message',
      header: `Production Line Delivery Date - #${product.ProductionLineID} `,
      deliveryDateLabel: ``,
      dateModel: product.DeliveryDate,
      order: product,
      message: response
    };
    this.modalRef = this.modalService.show(ProdctionLineStatusModalComponent, {
      ...this.modalOptions, class: 'modal-md modal-center', data: modalData
    });

    this.loadOrderDetail();
  }

  updateProductionDate() {
  }

  clearSelectedProduct() {
    this.selectedProduct = null;
  }

  handleActions(event, product) {
    const { data, type } = event;
    const { OrderID, ProductionLineID, ProductID } = data;
    const customerID = this.customerID;
    const finishedPL = this.finishedPL;
    if (type === 'View Details' && OrderID !== undefined) {
      const orderData = {
        'OrderID': data.OrderID,
        'OrderItemID': data.ProductionLineID,
        'isSubOrder': false
      };
      this.router.navigate([`admin-order-process/productionlineoverview/${OrderID}/${ProductionLineID}/${1}`],
        { state: { subOrderItemData: orderData, customerID, type: 'productionline', finishedPL }, replaceUrl: false });
    } else if (type === 'Status' || type === 'status') {
      const orderData = {
        orderID: OrderID,
        isSubOrder: true,
        productID: history.state.orderId ? history.state.orderId : this.OrderId,
        orderItemID: 'All',
        suborderID: 'All'
      };
      this.router.navigate(['/view-order'], { state: { data: orderData, type: 'productionline', customerID, finishedPL }, replaceUrl: false });
    } else if (type === 'Order Overview') {
      sessionStorage.setItem('productionlineRedirectStatus', JSON.stringify(true));
      this.router.navigate([`/admin-order-item/productionlineoverview/${OrderID}/${product.ProductID}`],
        { state: { customerID, type: 'productionline', finishedPL }, replaceUrl: false });
    }
  }

  loadOrderDetail() {
    debugger
    this.loading = true;
    this.orderService.getOrderProductionLineDetail(this.OrderId, this.finishedPL,
      this.customerID === 0 ? null : this.customerID,
      this.webshopDetail.CustomerID).subscribe((res: any) => {
        this.Products = res.map(order => {
          const totalOrder = {
            CompanyName: ' ',
            InvoiceCountry: '   ',
            OrderIdManual: 'Total',
            PaymentMode: '  ',
            TotalQuantity: order.OrderedQuantity,
            UserName: ' ',
            WebShop: '  ',
            isDispatched: '  ',
            isDelivered: '  '
          };
          order.Orders.push(totalOrder);
          return order;
        });

        this.loading = false;
      }, error => {
        this.loading = false;
        this.toasterServcie.error('something went wrong.please try after sometime');
      });
  }

  handleTimelineAction(event) {
    this.selectedProduct = { ...event.data };
    switch (event.action) {
      case 'productionStarted':
        this.productionStartedPopup(event);
        break;
      case 'productionCompleted':
        this.productionCompletedPopup(event);
        break;
      case 'dispatched':
        this.dispatchedPopup(event);
        break;
    }
  }

  productionStartedPopup(event) {
    const modalData = {
      type: event.action,
      header: `Production Started status - #${event.data.ProductionLineID} `,
      order: { ...event.data }
    };
    this.modalRef = this.modalService.show(ProdctionLineStatusModalComponent, {
      ...this.modalOptions, data: modalData
    });
    this.modalRef.content.modalAction.subscribe((result: any) => {
      const { data } = result;
      const field = 'productionlines_isproductionstarted';
      const startedDate = moment(data.dateModel, 'DD/MM/YYYY').format('YYYY-MM-DD');

      const formData = { Date: startedDate, status: data.status, Note: data.Note };
      this.loading = true;
      const { ProductionLineID } = this.selectedProduct;
      this.orderService.updateProductionLineProcessOrderStatus(ProductionLineID, field, formData).subscribe(response => {
        this.loading = false;
        if (response) {
          this.modalRef.hide();
          this.loadOrderDetail();
          this.toasterServcie.success('Updated the production started status successfully');
        } else {
          this.toasterServcie.error('unable to update the production started status');
        }
      }, error => {
        this.loading = false;
        this.toasterServcie.error('unable to update the production started status');
      });
    });
  }

  productionCompletedPopup(event) {
    const modalData = {
      type: event.action,
      header: `Production Completed Status - #${event.data.ProductionLineID} `,
      deliveryDateLabel: `${event.data.DeliveryDate}`,
      order: { ...event.data }
    };
    this.modalRef = this.modalService.show(ProdctionLineStatusModalComponent, {
      ...this.modalOptions, data: modalData
    });
    this.modalRef.content.modalAction.subscribe((result: any) => {
      const { data } = result;
      const field = 'productionlines_isproductioncompleted';
      const completedDate = moment(data.dateModel, 'DD/MM/YYYY').format('YYYY-MM-DD');

      const formData = { Date: completedDate, status: data.status, Note: data.Note };
      this.loading = true;
      const { ProductionLineID } = this.selectedProduct;
      this.orderService.updateProductionLineProcessOrderStatus(ProductionLineID, field, formData).subscribe(response => {
        this.loading = false;
        if (response) {
          this.modalRef.hide();
          this.loadOrderDetail();
          this.toasterServcie.success('Updated the production completed status successfully');
        } else {
          this.toasterServcie.error('unable to update the production completed status');
        }
      }, error => {
        this.loading = false;
        this.toasterServcie.error('unable to update the production completed status');
      });
    });
  }

  dispatchedPopup(event) {
    const data = {
      type: event.action,
      header: `Production Dispatch Status - #${event.data.ProductionLineID} `,
      deliveryDateLabel: `${event.data.DeliveryDate}`,
      order: event.data
    };
    this.showPopup(data);
  }

  showPopup(data) {
    this.modalRef = this.modalService.show(ProdctionLineStatusModalComponent, {
      ...this.modalOptions, data
    });
    this.modalRef.content.modalAction.subscribe((result: any) => { console.log('dispatched', result, this.selectedProduct); });
  }

  toggleBody(id, expand) {
    this.itemToExpand[id] = expand;
  }

  toggleEditNotes(ProductionLineID, InternalNote = '') {
    const data = {
      header: `Edit Internal Production Line Note`,
      note: `${InternalNote}`
    };
    this.modalRef = this.modalService.show(InternalNoteComponent, {
      ...this.modalOptions, data
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.submitInternalLineNote(ProductionLineID, result.note);
    });
  }

  submitInternalLineNote(ProductionLineID, InternalNote) {
    const formData = { InternalNote: InternalNote };
    this.orderService.updateInternalProductionLine(ProductionLineID, formData).subscribe(response => {
      this.loading = false;
      if (response) {
        this.loadOrderDetail();
        this.toasterServcie.success('Internal production line note updated successfully');
        this.modalRef.hide();
      } else {
        this.toasterServcie.error('unable to update the internal production line');
      }
    }, error => {
      this.loading = false;
      this.toasterServcie.error('unable to update the internal production line');
    });
  }

  downloadProductionLine(productionLineID) {
    this.orderService.downloadProductionLine(
      productionLineID, this.webshopDetail.CustomerID)
      .subscribe((response) => {
        console.log(response);
        window.location.href = response;
      }, error => {
        this.toasterServcie.error('Download Failed.');
      });
    this.toasterServcie.success('Please wait for download to start');
  }
}
