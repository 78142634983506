import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rewardkart-admin-order-old-profoma-invoice',
  templateUrl: './order-old-profoma-invoice.component.html',
  styleUrls: ['./order-old-profoma-invoice.component.scss']
})
export class OrderOldProfomaInvoiceComponent implements OnInit {

  @Input() header = '';
  modalBodyText: any;
  closeButtonLabel: any;

  constructor(public modalRef: MDBModalRef, private translate: TranslateService) { }

  modalCanceled() {
    this.modalRef.hide();
  }
  ngOnInit() {
    this.modalBodyText = this.translate.instant('ORDERDETAILS.OLD_ORDERINFO_MESSAGE');
    this.closeButtonLabel = this.translate.instant('ORDERDETAILS.CLOSE');
  }
}
