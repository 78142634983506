import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductCardModule } from 'src/app/product/product-card/product-card.module';
import { EditOrderitemsComponent } from './edit-orderitems.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { orderEmailnotificationModule } from '../orderemailnotification/orderemailnotification.module';
import { orderAuditLogModule } from '../order-audit-log/order-audit-log.module';

@NgModule({
    declarations: [
        EditOrderitemsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        CommonFilterModule,
        ProductCardModule,
        TranslateModule,
        NgSelectModule,
        orderEmailnotificationModule,
        orderAuditLogModule
    ],
    providers: [],
    exports: [EditOrderitemsComponent]
})

export class editOrderItemsModule { }

