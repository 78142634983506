import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderHttpService } from '../state/services/order.http.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
  orderItem: any = {};
  orderItemDetails: any = [];
  orderItemSuborders: any = [];
  orderItemDetailsLoader: boolean = false;
  orderId = '';
  lineId = '';
  returnPage = ''; // productLine // orderProcessList
  customerData: any;
  webshopDetail: any;
  constructor(private route: ActivatedRoute, private router: Router,
    private orderHttpService: OrderHttpService, private toaster: ToastService) { }

  ngOnInit() {
    const idFromRoute = this.getUrlParam('id');
    const lineIdFromRoute = this.getUrlParam('lineId');
    const { id, lineId, customerID, finishedPL } = history.state;
    this.orderId = id || idFromRoute;
    this.lineId = lineId || lineIdFromRoute;
    if (this.lineId) {
      this.returnPage = 'productionLine';
    } else {
      this.returnPage = 'orderProcess';
    }
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.orderItemList(this.orderId, this.webshopDetail.CustomerID);
    }
  }

  getUrlParam(paramValue): string {
    return this.route.snapshot.paramMap.get(paramValue);
  }

  orderItemList(id, customerID) {
    this.orderItemDetailsLoader = true;
    this.orderHttpService.getOrderItemDetails(id, customerID).subscribe((orderItemData: any) => {
      if (orderItemData) {
        this.orderItemDetailsLoader = false;
        this.orderItem = orderItemData;
        this.orderItemDetails = this.orderItem.OrderItems;
        this.orderItemSuborders = this.orderItem.Suborders;
        this.loadCustomer(orderItemData.CustomerID)
      }
    }, error => {
      this.orderItemDetailsLoader = false;
      this.toaster.error('Unable to get the order item details');
    });
  }

  loadCustomer(id) {
    this.orderHttpService.orderCustomer(id).subscribe((data: any) => {
      if (data) {
        this.customerData = data;
        sessionStorage.setItem('OrderCustomerData', JSON.stringify(data));
      }
    })
  }

  loadOrderItemList(event) {
    if (event === true) {
      this.orderItemDetailsLoader = true
    } else {
      this.orderItemList(event, this.webshopDetail.CustomerID);
    }
  }

  goBack() {
    const id = this.orderId;
    const customerID = history.state.customerID;
    const finishedPL = history.state.finishedPL;
    if (history.state.dashboardData !== undefined || (sessionStorage.getItem('dashboardRedirectStatus') !== undefined &&
      sessionStorage.getItem('dashboardRedirectStatus') !== null && (sessionStorage.getItem('productionlineRedirectStatus')
        === undefined || sessionStorage.getItem('productionlineRedirectStatus') === null))) {
      this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
    } else {
      if (this.returnPage === 'productionLine') {
        this.router.navigate([`/admin-order-production-line-details/${this.lineId}/${finishedPL}`], {
          state: { id, customerID },
          replaceUrl: false
        });
      } else {
        sessionStorage.setItem("backcliked", "true");
        this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
      }
    }
  }

  getDispatch(data) {
    console.log(data);
  }

}
