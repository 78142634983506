import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { DashboardService } from '../state/services/dashboard.service';
import { UserService } from '../../user/state/services/user.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-likedproduct',
  templateUrl: './dashboard-likedproduct.component.html',
  styleUrls: ['./dashboard-likedproduct.component.scss']
})
export class DashboardLikedproductComponent implements OnInit {
  @Input() customerList: any = [];
  userdatacount: any;
  activePage: number = 1;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  tableEvent: any;
  enablepagination: boolean = true;
  likedProductCount: any;
  public likedProduct: any = [];
  tableName: any = 'likedProductlist';
  showUserEditpage: boolean = true;
  showLoader: boolean = false;
  resetPaginationNumber: boolean = false;
  public likedProductlist: ColumnSetting[] = [];
  likedproductcustomerID: any;
  public likedProductListQuery: any = {};
  public filterFields: any = [];
  productIdManual: any = "";
  loginuserdetails: any;
  webshopDetail: any;
  isParentCustomer: any;
  constructor(public dashboardhttpservice: DashboardHttpService, public dashboardservice: DashboardService,
    public apiService: ApiService, private userService: UserService, private linkTableService: LinkTableService,
    private router: Router, private translate: TranslateService) {

    this.likedProductlist = [{
      primaryKey: 'ProductName',
      header: this.translate.instant('DASHBOARD.PRODUCTNAME')
    },
    {
      primaryKey: 'ImageURL',
      header: this.translate.instant('DASHBOARD.PRODUCTIMAGES'),
      format: 'likedicon',
    },
    {
      primaryKey: 'ProductIdManual',
      header: this.translate.instant('DASHBOARD.ITEMNO'),
    },
    {
      primaryKey: 'Quantity',
      header: this.translate.instant('DASHBOARD.TOTALLIKEQTY'),
    }
      // {
      //   primaryKey: '',
      //   header: 'View Product with Link',
      //   format: 'viewItem'
      // }]
    ];
  }


  ngOnInit() {
    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  onLoadFunctions() {
    let webshopEnableStatus = true;
    if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
      webshopEnableStatus = false;
      if (this.webshopDetail.IsWebshopDropdown) {
        webshopEnableStatus = true;
      }
    }
    this.likedProductListQuery.CustomerId = (this.isParentCustomer) ? null : this.webshopDetail.CustomerID;
    //console.log("Onload function : ", this.productIdManual);
    this.filterFields = [
      {
        label: this.translate.instant('MENU.WEBSHOPS'),
        type: 'select',
        key: 'selectwebshop',
        data: [],
        placeholder: this.translate.instant('CATEGORIES.SELECT_ALL'),
        class: (webshopEnableStatus && this.isParentCustomer) ? 'col-6' : ''
      },
      {
        label: this.translate.instant('DASHBOARD.ITEMNUMBER'),
        type: 'text',
        class: 'col-6',
        key: 'productManualID',
        selected: this.productIdManual,
        filterkey: 'ProductIdManual'
      },
      {
        label: this.translate.instant('DASHBOARD.RESET'),
        type: 'button',
        class: 'ml-auto',
        key: 'reset'
      },
      {
        label: this.translate.instant('DASHBOARD.SEARCH'),
        type: 'button',
        class: 'ml-2',
        key: 'button'
      }
    ];
    this.getAllCustomerList();
    this.getDashboardLikedProduct();
  }

  viewlikedProduct(likeProductData: any) {
    if (likeProductData) {
      this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
        if (data) {

          // this.router.navigate([data.ProductUrl], { state: { likeProductData, data }, replaceUrl: false });

          // this.router.navigate([data.ProductUrl]).then(likeProductData => {  window.open(data.ProductUrl, '_blank'); });
        }
      });
    }
  }

  paginationNum(event) {
    //console.log("paginationNum : ", event);
    this.activePage = (this.resetPaginationNumber) ? 1 : event;
    this.likedProductListQuery.pageIndex = (this.resetPaginationNumber) ? 1 : event;
    this.resetPaginationNumber = false;
    this.getDashboardLikedProduct();
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.likedProductListQuery.pageSize = event;
    this.resetPaginationNumber = true;
  }

  getSelectData(selectedData) {
    //console.log('Liked product Widget Selected data : ', selectedData);
    this.productIdManual = selectedData.productManualID ? selectedData.productManualID.value : "";
    this.likedProductListQuery.ProductIdManual = this.productIdManual;

    // tslint:disable-next-line:max-line-length
    if (selectedData.selectwebshop !== undefined && selectedData.selectwebshop.selectMethod === 'selectwebshop'
      && selectedData.selectwebshop.value !== '') {
      this.likedProductListQuery.CustomerId = selectedData.selectwebshop.value;
    } else {
      this.likedProductListQuery.CustomerId = (this.isParentCustomer) ? null : this.webshopDetail.CustomerID;
    }

    this.filterFields.forEach(element => {
      if (element.key === "productManualID") {
        element.selected = this.productIdManual
      }
    });
  }

  getSubmit(val) {
    //console.log("val : ", val);
    this.filterFields.forEach(_element => {
      //console.log("Element : ", element);
    });
    this.perPagedata = 10;
    this.activePage = 1;
    this.likedProductListQuery.pageIndex = 1;
    this.likedProductListQuery.pageSize = 10;
    this.getDashboardLikedProduct();
  }

  getDashboardLikedProduct() {
    const searchData = {
      'CustomerId': this.likedProductListQuery.CustomerId,
      'ProductIdManual': (this.likedProductListQuery.ProductIdManual !== undefined && this.likedProductListQuery.ProductIdManual !== null) ?
        this.likedProductListQuery.ProductIdManual : '',
      'PageIndex': (this.likedProductListQuery.pageIndex !== undefined && this.likedProductListQuery.pageIndex !== null) ?
        this.likedProductListQuery.pageIndex : this.activePage,
      'PageSize': (this.likedProductListQuery.pageSize !== undefined && this.likedProductListQuery.pageSize !== null) ?
        this.likedProductListQuery.pageSize : this.rowPagepage
    }

    this.showLoader = true;
    this.dashboardhttpservice.getLikedProducts(searchData).subscribe((data: any) => {
      if (data) {
        this.showLoader = false;
        this.likedProduct = data.ItemsCollection;
        this.likedProductCount = data.TotalItemsCount;
        this.enablepagination = true;
        if (data.ItemsCollection.length === 0) {
          this.enablepagination = false;
        }
      }
    },
      _err => {
        //console.log(err);
        this.showLoader = false;
      });
  }

  getReset(_val) {
    this.likedProductListQuery = {};
    this.likedProductListQuery.CustomerId = (this.isParentCustomer) ? null : this.webshopDetail.CustomerID;
    this.productIdManual = '';
    this.onLoadFunctions();
  }

  getAllCustomerList() {
    const webshopData = [{ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' }];
    this.customerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    })
    //console.log("Form Fields productline : ", this.filterFields);
    this.filterFields[0].data = webshopData;
  }

  editUser(data) { }

}
