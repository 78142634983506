import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { BlockService } from '../state/services/block.service';
import { LoginService } from '../../login/state/services/login.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { BlockHttpService } from './../state/services/block.http.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss']
})

export class BlockListComponent implements OnInit {

  header: any;
  blockList: any = [];
  submitted = false;
  actions: any = ['edit', 'delete'];
  isLoading = false;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'ProductBlockID',
      header: 'ID'
    },
    {
      primaryKey: 'Translations',
      header: 'Name',
      primaryKeyItem: 'PropertyValue',
      format: 'array'
    },
    {
      primaryKey: 'IsVisible',
      header: 'Visible Always'
    },
    {
      primaryKey: 'VisibleFrom',
      header: 'Visible From',
      format: 'date'
    },
    {
      primaryKey: 'VisibleTo',
      header: 'Visible To',
      format: 'date'
    },
    {
      primaryKey: 'NumberOfProducts',
      header: 'No Of Products'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];
  tableName = 'blockList';
  webshopDetail: any;
  tableEvent:any;

  constructor(private blockService: BlockService,
    private loginService: LoginService,
    private router: Router,
    private linkTableService: LinkTableService,
    private blockHttpService: BlockHttpService,
    private toaster: ToastService) {
    this.onLoadFunctions();
  }

  onLoadFunctions() {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    this.isLoading = true;
    console.log('this.webshopDetail.CustomerID : ', this.webshopDetail.CustomerID);
    this.blockService.loadBlocks(this.webshopDetail.CustomerID);
  }

  ngOnInit() {
    this.isLoading = true;

    this.header = 'Block Details'; // Multilingual to be implemented
    this.getBlockList();
  }

  getBlockList() {
    this.isLoading = true;

    this.blockService.getBlockList().subscribe(data => {
      if (data) {
        this.isLoading = false;
        this.blockList = data.map((block: any) => {
          if (block.IsVisible) {
            block.VisibleFrom = '';
            block.VisibleTo = '';
          } else {
            block.IsVisible = false;
          }
          return block;
        });
      }
    });
  }

  refreshTable(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.getBlockList();
    }
  }

  addNewBlock() {
    this.router.navigate(['/admin-block-add'], { replaceUrl: false });
  }

  addBlock(event: boolean) {
    this.submitted = event;
  }

  viewBlock(blockData: any) {
  }

  editBlock(event) {
    try {
      if (event) {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(selectedRow => {
          if (selectedRow && selectedRow.ProductBlockID) {
            const id = selectedRow.ProductBlockID;
            this.router.navigate(['/admin-block-edit'], {
              state: { id },
              replaceUrl: true
            });
          }
        });
      }
    } catch (error) { }
    this.submitted = event;
  }

  deleteBlock(event) {
    if (event) {
      this.blockHttpService
        .deleteBlock(event.ProductBlockID)
        .subscribe((data: any) => {
          if (data !== null) {
            this.toaster.success('Block Deleted Successfully');
            this.onLoadFunctions();
          }
        }, error => {
          this.toaster.error('Unable to Delete Block');
        });
    }
  }

  changeOrderBlock(event) {
    const dragEvent = { CurrentSortOrder: event.previousIndex + 1, DesiredSortOrder: event.currentIndex + 1 };
    this.blockHttpService.changeSortOrderOfBlock(dragEvent).subscribe((res: any) => {
      if (res) {
        this.toaster.success(res);
      }
    }, error => {
      this.toaster.error(error);
    });
  }
  
    ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }

}
