import { LinkTableActions } from '../actions/index';

export interface LinkTableState {
    selectedRow: any;
}

export const initialState: LinkTableState = {
    selectedRow: null
};

export function linkTableReducers(state: LinkTableState = initialState, action: LinkTableActions.All) {
    switch (action.type) {

        case LinkTableActions.SELECTED_ROW:
            return Object.assign({}, state, { selectedRow: action.payload });

        default: {
            return state;
        }
    }
}
