import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from '../../shared/shared.module';
import { CampaignEditComponent } from './campaign-edit.component';
import { CampaignHttpService } from '../state/services/campaign.http.service';
import { CampaignService } from '../state/services/campaign.service';
import { ProductSelectModule } from 'src/app/product/product-select/product-select.module';
import { ToastModule } from 'ng-uikit-pro-standard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    SharedModule,
    ProductSelectModule,
    ToastModule,
  ],
  declarations: [CampaignEditComponent],
  providers: [CampaignHttpService, CampaignService],
  exports: [CampaignEditComponent],
})
export class CampaignEditModule { }
