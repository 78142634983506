import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rewardkart-admin-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})

export class UserSearchComponent implements OnInit {

  header: any;
  submitted = false;

  optionsSelectCustomers: Array<any>;
  optionsSelectRoles: Array<any>;
  optionsSelectCountries: Array<any>;

  userSearchForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService, private translate: TranslateService) {
    this.onLoadFunctions();
    this.createForms();
  }

  onLoadFunctions() {
    this.userService.loadCountries();
    this.userService.loadCustomers();
    this.userService.loadRoles();
    this.userService.loadSearchFormFields();
  }

  ngOnInit() {
    this.header = this.translate.instant('USERSEARCH.USERSEARCH'); // Multilingual to be implemented
    this.getCustomers();
    this.getRoles();
    this.getCountries();
  }

  getCustomers() {
    this.userService.getAllCustomers().subscribe(
      data => {
        if (data) {
          this.optionsSelectCustomers = [];
          data.forEach(element => {
            const customerVal = {
              value: element.CustomerID,
              label: element.Name,
            };
            this.optionsSelectCustomers.push(customerVal);
          });
        }
      });
  }

  getRoles() {
    this.userService.getAllRoles().subscribe(
      data => {
        if (data) {
          this.optionsSelectRoles = [];
          data.forEach(element => {
            const countryVal = {
              value: element.RoleID,
              label: element.RoleName,
            };
            this.optionsSelectRoles.push(countryVal);
          });
        }
      });
  }

  getCountries() {
    this.userService.getAllCountries().subscribe(
      data => {
        if (data) {
          this.optionsSelectCountries = [];
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });
        }
      });
  }

  createForms() {
    this.userSearchForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      roleId: [''],
      name: [''],
      email: [''],
      companyName: [''],
      country: [''],
    });
  }

  onSubmit() {
    const userSearch = {
      customerId: this.userSearchForm.value.customerId !== undefined ? this.userSearchForm.value.customerId : '',
      roleId: this.userSearchForm.value.roleId !== undefined ? this.userSearchForm.value.roleId : '',
      name: '',
      email: '',
      companyName: '',
      country: this.userSearchForm.value.country !== undefined ? this.userSearchForm.value.country : ''
    };
    this.userService.loadUsers(userSearch);
  }

  resetForm() {
    this.userSearchForm.reset();
  }

  goBack(event: boolean) {
  }

  goNext(event: boolean) {
  }

}
