import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { LoginActions } from '../actions/index';
import { State } from '../reducers/index';
import { LoginHttpService } from '../services/login.http.service';

@Injectable()
export class LoginEffects {

    @Effect()
    getAllCustomers$: Observable<Action> = this.actions$.pipe(
        ofType(LoginActions.LOAD_CUSTOMERS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.loginHttpService.getAllCustomers().map((data) => {
                return new LoginActions.LoadCustomersSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new LoginActions.LoadCustomersFail(error));
        })
    );


    @Effect()
    doLogin$: Observable<Action> = this.actions$.pipe(
        ofType(LoginActions.LOGIN),
        withLatestFrom(this.store$),
        mergeMap(([payload]: any) =>
            this.loginHttpService.doLogin(payload.payload).pipe(
                switchMap(data => [
                    new LoginActions.LoginSuccess(data)
                ]),
                catchError((error: any) => of(new LoginActions.LoginFail(LoginActions.LOGIN_FAIL)))
            )
        ),
    );

    @Effect()
    loadSubDomain$: Observable<Action> = this.actions$.pipe(
        ofType(LoginActions.LOAD_SUBDOMAIN),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.loginHttpService.getSubDomain(payload.payload).map((resp: any) => {
                return new LoginActions.SetSubDomain(resp.Subdomain);
            });
        }),
    );

    @Effect()
    getLanguage$: Observable<Action> = this.actions$.pipe(
        ofType(LoginActions.LANGUAGE),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.loginHttpService.getLanguage().map((data) => {
                return new LoginActions.LanguageSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new LoginActions.LanguageFail(error));
        })
    );



    @Effect()
    getLanguages$: Observable<Action> = this.actions$.pipe(
        ofType(LoginActions.GETWEBSHOPLANGUAGE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.loginHttpService.getCustomerLanguage(payload.payload).map((data) => {
                //console.log('effectdata...', data)
                return new LoginActions.WebshopLanguageSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new LoginActions.WebshopLanguageFail(error));
        })
    );










    @Effect()
    getCustomer$: Observable<Action> = this.actions$.pipe(
        ofType(LoginActions.CUSTOMERNAME),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.loginHttpService.getCustomer(payload.payload).map((data) => {
                return new LoginActions.LoadCustomerNameSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new LoginActions.LoadCustomerNameFail(error));
        })
    );


    constructor(private actions$: Actions,
        private store$: Store<State>,
        private loginHttpService: LoginHttpService) { }
}
