import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdvancedProductSearchComponent } from './product-adv-search.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [AdvancedProductSearchComponent],
  imports: [CommonModule, ReactiveFormsModule,
    FormsModule, SharedModule],
  exports: [AdvancedProductSearchComponent]
})
export class AdvancedSearchModule { }
