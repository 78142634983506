import { Component, OnInit } from '@angular/core';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { LinkTableService } from '../../../shared/link-table/state/services/link-table.service';
import { ColumnSetting } from '../../../shared/link-table/models/link-table-model';
@Component({
  selector: 'rewardkart-admin-order-audit-log',
  templateUrl: './order-audit-log.component.html',
  styleUrls: ['./order-audit-log.component.scss']
})
export class OrderAuditLogComponent implements OnInit {
  date = new Date();
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  };
  header: any;
  columns: ColumnSetting[] = [
    {
      primaryKey: '',
      header: 'Select',
      format: 'bulkaction',
      key: 'productcheckbox'
    },
    {
      primaryKey: '',
      header: 'Name'
    },
    {
      primaryKey: '',
      header: 'Item number'
    },

    {
      primaryKey: '',
      header: 'Type',
      format: 'producttype'
    },
    {
      primaryKey: '',
      header: 'Action',
      format: 'productListAction'
    }
  ];
  constructor(private linkTableService: LinkTableService) { }

  ngOnInit() {
  }

}
