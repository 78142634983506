import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ProductService } from '../state/services/product.service';
import { ProductHttpService } from '../state/services/product.http.service';
import { LoginService } from '../../login/state/services/login.service';
import { ToastService, ModalDirective, IMyOptions } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'rewardkart-admin-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})

export class ProductListComponent implements OnInit {
  @ViewChild('bulkCreationModal', { static: true }) bulkCreationModal: ModalDirective;
  @ViewChild('publishModal', { static: false }) publishModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) deleteModal: ModalDirective;
  @ViewChild('visibledatepicker', { static: false }) visibledatepicker: any;
  @ViewChild('mappedModal', { static: false }) mappedModal: ModalDirective;
  tableName: string = 'productlist';
  perPagedata: number = 10;
  activePage: number = 1;
  productdatacount: any;
  enablepagination: boolean = true;
  header: any;
  productList: any = [];
  excelproductList: any = [];
  showLoader: Boolean = false;
  showInnerLoader: Boolean = false;
  submitted = false;
  actions: any = ['view', 'edit', 'delete'];
  customerID: any = '';
  loadSupplier: boolean = false;
  loadUnderSupplier: boolean = false;
  showImageError: boolean = false;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'ProductID',
      header: 'Select',
      format: 'bulkaction',
      key: 'productcheckbox'
    },
    {
      primaryKey: 'Name',
      header: 'Name'
    },
    {
      primaryKey: 'ProductIdManual',
      header: 'Item Number'
    },
    {
      primaryKey: 'ImageURL',
      header: 'Product Image',
      format: 'icon',
      showSort: false
    },
    {
      primaryKey: 'CustomerName',
      header: 'Customer Name',
    },
    {
      primaryKey: 'SupplierName',
      header: 'Supplier Name',
    },
    {
      primaryKey: 'StockBalance',
      header: 'Stock Balance',
      format: 'showNumberValue'
    },
    // {
    //   primaryKey: 'RewardPoints',
    //   header: 'Reward Points',
    //   format: 'showNumberValue'
    // },
    {
      primaryKey: 'isDeleted',
      header: 'Is Deleted',
      format: 'delete'
    },
    {
      primaryKey: 'VisibleDate',
      header: 'Original product status',
      format: 'opstatus'
    },
    {
      primaryKey: 'IsUnpublishedByCust',
      header: 'Customer product status',
      format: 'condition'
    },
    {
      primaryKey: 'ProductBatchID',
      header: 'Type',
      format: 'producttype'
    },
    {
      primaryKey: 'action',
      header: 'Action',
      format: 'productListAction'
    }

  ];

  suppliers: any = [];
  undersuppliers: any = [];
  filterFields: any = [];
  productIdManual: any = "";
  productName: any = "";
  supplierID: any = "";
  undersupplierID: any = "";
  status: any = [];
  getStatusVal: any;
  webshopSupplierID: any;
  statusValue: any = 'All';
  passFilterresult: any = [];
  searchCriteria: any = {};
  commonRemoveFilter: any;
  productListFilter: any = {};
  showTags: any = true;
  DontShowDeleted: Boolean = true;
  undersupplierProductID: any = '';
  bulkProductIds: any = [];
  publishForm: FormGroup;
  displayPublishDate: any;
  isVisibleError: Boolean = false;
  date = new Date();
  statusError: Boolean = false;
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'yyyy-mm-dd',
    closeAfterSelect: true,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  };
  updateStatus: any = '';
  loadTag: Boolean = true;
  customerlist: any = [];
  webshopDetail: any;
  InternalStockProducts: Boolean = false;
  mappedList: any = [];
  mappedProductColumns: any = [];
  mappedPagination: Boolean = false;
  productListPopup: any = 'productListPopup';
  deleteWarningMessage: any = 'Are you sure to delete original & customer product?';
  deleteCPWarningMessage: any = 'Are you sure to delete customer product?';
  bothProductDeleteMessage: any = 'If you delete original product then the customer and original product both will be deleted.';
  mappedRowData: any;
  getTypeOfPublish: any;
  bulkData: any = [];
  IsCustomerSelected: any;
  tableEvent: any;
  isParentcustomer: boolean = false;
  constructor(private productService: ProductService,
    private loginService: LoginService,
    private apiService: ApiService,
    private toast: ToastService,
    private router: Router, private linkTableService: LinkTableService, public formBuilder: FormBuilder, private productHttpService: ProductHttpService) {
    sessionStorage.removeItem('productCustomerID');
    const column = this.columns;
    this.mappedProductColumns = column;
    // // this.mappedProductColumns = this.mappedProductColumns.shift();

    this.onLoadFunctions();
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    if ((this.webshopDetail && this.webshopDetail.ParentCustomerDomainName) === (this.webshopDetail && this.webshopDetail.DomainName)) {
      this.isParentcustomer = true;
  }
  }

  ngOnInit() {
    // const loginData = this.apiService.getLoginData();
    // this.customerID = loginData.CustomerID;
    this.header = 'Product Details'; // Multilingual to be implemented
    const ifHaveFilter = JSON.parse(sessionStorage.getItem('presearch'));
    if (ifHaveFilter !== null && ifHaveFilter !== undefined && ifHaveFilter !== '') {
      sessionStorage.setItem('productListFilter', JSON.stringify(ifHaveFilter));
      this.setfilterData();
      this.loadProducts();
      if (ifHaveFilter.CustomerID !== undefined && ifHaveFilter.CustomerID !== '') {
        this.getSuppliers();
      }
    }
    else {
      sessionStorage.removeItem('productListFilter');
    }
    this.getCustomerInfo();
    this.setfilterData();
    this.getChildChildCustomer();
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID) {
        this.customerID = getCustomerDetails.CustomerID;
        this.getSuppliers();
      }
    }
    this.loadForm();
    this.publishForm.valueChanges.subscribe(() => {
      const d = this.publishForm.value.visibleDate
      const date = new Date(this.dateToMonth(d))
      const currentDate = new Date(this.MMDDYYYY())

      if (!(date >= currentDate)) {
        this.isVisibleError = true
      } else {
        this.isVisibleError = false
      }
      this.displayPublishDate = this.publishForm.value.visibleDate
    });
  }
  fileName = 'Products.xlsx';
  //@ViewChild('TABLE') table: ElementRef;
  exportexcel(event) {
    this.showInnerLoader = true;
    if (this.customerID === '' || this.customerID === undefined || this.customerID === null || this.customerID === 0) {
      this.customerID = this.webshopDetail.CustomerID;
    }
    const customerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (customerDetails !== null && customerDetails !== undefined) {
      if (customerDetails.ParentCustomerID) {
        this.searchCriteria.data.IncludeChildCustomers = false;
      }
    }
    this.IsCustomerSelected = !this.searchCriteria.data.IncludeChildCustomers;
    // if (this.searchCriteria.data.ProductName || this.searchCriteria.data.ProductIdManual) {
    //   this.searchCriteria.data.IncludeChildCustomers = false;
    // }

    this.searchCriteria.data.LoggedInCustomerID = this.webshopDetail.CustomerID;

    if (this.searchCriteria.data.All && this.searchCriteria.data.InternalStockProducts) {
      this.searchCriteria.data.All = false;
    }
    let filterInfo = JSON.parse(sessionStorage.getItem('productListFilter'));
    if (filterInfo) {
      this.productIdManual = filterInfo.productIdManual;
      this.productName = filterInfo.productName;
      this.supplierID = filterInfo.supplierID;
      this.undersupplierID = filterInfo.undersupplierID;
      this.getStatusVal = filterInfo.getStatusVal;
      this.statusValue = filterInfo.statusValue;
      this.DontShowDeleted = filterInfo.DontShowDeleted;
      this.undersupplierProductID = filterInfo.UndersupplierProductID;

      this.InternalStockProducts = filterInfo.InternalStockProducts;
    } else {
      this.statusValue = 'All'
      this.productIdManual = "";
      this.productName = "";
      this.supplierID = "";
      this.undersupplierID = "";
      this.undersupplierProductID = "";
      this.getStatusVal = false;
      this.DontShowDeleted = true;
      this.activePage = 1;
      this.perPagedata = 10;
      this.InternalStockProducts = false;
    }
    this.searchCriteria = {
      data: {
        CustomerID: this.customerID,
        ProductIdManual: this.productIdManual,
        ProductName: this.productName,
        SupplierID: this.supplierID !== 'nil' ? this.supplierID : '',
        UndersupplierID: this.undersupplierID !== 'nil' ? this.undersupplierID : '',
        DontShowDeleted: this.DontShowDeleted,
        InternalStockProducts: this.InternalStockProducts,
        ...this.getStatusVal
      },
      pageIndex: this.activePage,
      pageSize: this.productdatacount
    };
    if (this.undersupplierProductID !== undefined && this.undersupplierProductID !== '') {
      this.searchCriteria.data.UndersupplierProductID = this.undersupplierProductID;
    }

    this.productHttpService.getProductList1(this.searchCriteria).subscribe(data => {
      if (data !== null) {
        if (data) {
          this.showInnerLoader = false
          let excelproductList = data['ItemsCollection'];
          this.excelproductList = [];
          excelproductList.forEach((e)=>{
            this.excelproductList.push({
              ProductIdManual: e.ProductIdManual,
              CreationDate: e.CreationDate,
              BrandName: e.BrandName,
              MRP: e.mrp,
              ProductCardLabel: e.ProductCardLabel,
              DeliveryWeeksFrom: e.DeliveryWeeksFrom,
              DeliveryWeeksTo: e.DeliveryWeeksTo,
              IsOriginalProductPublished: e.IsOriginalProductPublished,
              LandingPrice: e.LandingPrice,
              ProductCreationDate: e.ProductCreationDate,
              CategoryName: e.CategoryName,
              ImageURL: e.ImageURL,
              ProductName: e.ProductName,
              Description: e.ProductDesc,
              SubCategory: e.SubCategory,
              PriceSlab: e.SlapPrice,
              MarginPrice: e.MarginPrice

            })
          })
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.excelproductList);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, "Products-" + this.customerID + ".xlsx");
        }
        else {
          this.showInnerLoader = false
        }
      }
    })


  }
  getCustomerInfo() {
    // const loginData = this.apiService.getLoginData();
    this.productService.loadCustomerInfo(this.webshopDetail.CustomerID);
    this.productService.getCustomerInfo().subscribe(data => {
      if (data) {
        this.webshopSupplierID = data.SupplierID;
      }
    });
  }


  onLoadFunctions() {
    this.getStatus();
    // this.getSuppliers();
    // this.getUnderSuppliers();
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe(data => {
        if (data) {
          this.customerID = data.CustomerID;
          //  this.loadProducts();
        }
      });
    } else {
      if (sessionStorage.getItem('userdetails') !== null) {
        const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
        this.customerID = loginuserdetails.CustomerID;
      }
    }
  }


  getStatus() {
    this.status = [
      { value: 'All', label: 'All' },
      { value: 'OnlyNotDeleted', label: 'Published' },
      { value: 'OnlyDeleted', label: 'UnPublished' },
      { value: 'OnlyWithoutWebshop', label: 'Not on web shop' },
      { value: 'PendingUpdate', label: 'Pending update' }
    ]
  }

  getSuppliers(type?) {
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getSuppliersList(this.customerID, this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        const suppliers = [{ label: 'Select Supplier', value: 'nil' }];
        data['ItemsCollection'].forEach(element => {
          const customerVal = {
            value: element.SupplierID,
            label: element.Name,
          };
          suppliers.push(customerVal);
        });
        this.suppliers = suppliers;
        this.loadSupplier = true;
        if (type !== 'changewebshop') {
          let filterInfo = JSON.parse(sessionStorage.getItem('productListFilter'));
          if (filterInfo) {
            if (filterInfo.supplierID !== '' && filterInfo.supplierID !== undefined) {
              // this.getUnderSuppliers();
            }
          }
        }
        this.setfilterData();
      }
    });
  }
  // getUnderSuppliers(type?) {
  //   const request = {
  //     customerID: this.webshopDetail.CustomerID,
  //     supplierID: this.supplierID !== 'nil' ? this.supplierID : ''
  //   }
  //   if (this.supplierID !== 'nil') {
  //     this.productHttpService.getUnderSupplierList(request).subscribe((data: any) => {
  //       if (data) {
  //         const undersuppliers = [{ label: 'Select under supplier', value: 'nil' }];
  //         data.ItemsCollection.forEach(element => {
  //           const customerVal = {
  //             value: element.UndersupplierID,
  //             label: element.Name,
  //           };
  //           undersuppliers.push(customerVal);
  //         });
  //         this.undersuppliers = undersuppliers;
  //         this.loadUnderSupplier = true;
  //         this.setfilterData();
  //       }
  //     });
  //   }

  // }

  setfilterData() {
    let filterInfo = JSON.parse(sessionStorage.getItem('productListFilter'));
    if (filterInfo) {
      this.productIdManual = filterInfo.productIdManual;
      this.productName = filterInfo.productName;
      this.supplierID = filterInfo.supplierID;
      this.undersupplierID = filterInfo.undersupplierID;
      this.getStatusVal = filterInfo.getStatusVal;
      this.statusValue = filterInfo.statusValue;
      this.DontShowDeleted = filterInfo.DontShowDeleted;
      this.undersupplierProductID = filterInfo.UndersupplierProductID;
      this.customerID = filterInfo.CustomerID;
      this.activePage = filterInfo.pageIndex;
      this.perPagedata = filterInfo.pageSize;
      this.InternalStockProducts = filterInfo.InternalStockProducts;
    } else {
      this.statusValue = 'All'
      this.productIdManual = "";
      this.productName = "";
      this.supplierID = "";
      this.undersupplierID = "";
      this.undersupplierProductID = "";
      this.getStatusVal = false;
      this.DontShowDeleted = true;
      this.customerID = '';
      this.activePage = 1;
      this.perPagedata = 10;
      this.InternalStockProducts = false;
    }


    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      }
    ];
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.filterFields.push({
          label: 'Select Webshop',
          type: 'select',
          class: 'col-md-12',
          key: 'webshop',
          data: this.customerlist,
          selected: this.customerID,
          required: false,
          filterkey: 'customerID'
        });
      }
    }
    this.filterFields.push({
      label: 'Supplier',
      type: 'select',
      class: 'col-md-12',
      key: 'supplier',
      data: this.suppliers,
      selected: this.supplierID,
      required: false,
      filterkey: 'SupplierID'
    },
      {
        label: 'Status',
        type: 'select',
        class: 'col-md-12 status-items',
        key: 'status',
        data: this.status,
        selected: this.statusValue,
        required: false,
        filterkey: 'status'
      },
      {
        label: 'Product Name',
        type: 'text',
        class: 'col-md-12',
        key: 'productName',
        selected: this.productName,
        required: false,
        filterkey: 'ProductName'
      },
      {
        label: 'Product Item Number',
        type: 'text',
        class: 'col-md-12',
        key: 'productManualID',
        selected: this.productIdManual,
        required: false,
        filterkey: 'ProductIdManual'
      },
      {
        label: 'Supplier item no',
        type: 'text',
        class: 'col-md-12',
        key: 'UndersupplierProductID',
        selected: this.undersupplierProductID,
        required: false,
        filterkey: 'UndersupplierProductID'
      },
      {
        label: "Don't show deleted & discontinued products",
        type: 'checkbox',
        class: 'col-md-12 d-flex justify-content-start',
        key: 'dontShowDeleted',
        data: this.DontShowDeleted,
        required: false,
        filterkey: 'DontShowDeleted',
        selected: this.DontShowDeleted
      },
      {
        label: "Internal stock Products",
        type: 'checkbox',
        class: 'col-md-12 d-flex justify-content-start',
        key: 'InternalstockProducts',
        data: this.InternalStockProducts,
        required: false,
        filterkey: 'DontShowDeleted',
        selected: this.InternalStockProducts
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      });
  }

  loadProducts() {
    this.searchCriteria = {
      data: {
        CustomerID: this.customerID,
        ProductIdManual: this.productIdManual,
        ProductName: this.productName,
        SupplierID: this.supplierID !== 'nil' ? this.supplierID : '',
        UndersupplierID: this.undersupplierID !== 'nil' ? this.undersupplierID : '',
        DontShowDeleted: this.DontShowDeleted,
        InternalStockProducts: this.InternalStockProducts,
        ...this.getStatusVal
      },
      pageIndex: this.activePage,
      pageSize: this.perPagedata
    };
    if (this.undersupplierProductID !== undefined && this.undersupplierProductID !== '') {
      this.searchCriteria.data.UndersupplierProductID = this.undersupplierProductID;
    }

    this.getProductList();
  }

  getRemovedFilter(val) {
    this.activePage = 1;
    this.perPagedata = 10;
    this.commonRemoveFilter = val;
    if (val !== undefined) {
      this.commonRemoveFilter = val;


      if (this.searchCriteria.data !== undefined) {
        this.searchCriteria.data[val.key] = '';
      }

      if (val.key === "status") {
        this.statusValue = 'All';
        delete this.searchCriteria.data[val.value];
        this.searchCriteria.data.All = true;
      }

      if (val.key === "customerID") {
        this.customerID = '';
        // this.searchCriteria.data.CustomerID = this.webshopDetail.CustomerID;
        this.supplierID = "";
        this.suppliers = [];
        // this.undersupplierID = "";
        // this.undersuppliers = [];
      }
      // if (val.key === "UndersupplierID") {
      //   this.undersupplierID = "";
      // }
      else if (val.key === "SupplierID") {
        this.supplierID = "";
        // this.undersupplierID = "";
        // this.undersuppliers = [];
      }
      else if (val.key === "ProductIdManual") {
        this.productIdManual = "";
      }
      else if (val.key === "ProductName") {
        this.productName = "";
      }
      else if (val.key === "checkbox" || val.key === "dontShowDeleted") {
        this.DontShowDeleted = true;
      }
      else if (val.key === 'UndersupplierProductID') {
        this.undersupplierProductID = "";
      }
      else if (val.key === 'InternalstockProducts') {
        this.InternalStockProducts = false;
      }

      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
      });
      this.productListFilter = {
        productIdManual: this.productIdManual,
        productName: this.productName,
        supplierID: this.supplierID,
        getStatusVal: this.getStatusVal,
        statusValue: this.statusValue,
        DontShowDeleted: this.DontShowDeleted,
        CustomerID: this.customerID,
        InternalStockProducts: this.InternalStockProducts
      }
      if (this.undersupplierProductID !== undefined && this.undersupplierProductID !== '') {
        this.productListFilter.UndersupplierProductID = this.undersupplierProductID;
      }
      this.searchCriteria.pageIndex = 1;
      this.searchCriteria.pageSize = 10;
      sessionStorage.setItem('productListFilter', JSON.stringify(this.productListFilter));
      this.setfilterData();
      this.searchCriteria.data.SupplierID = this.supplierID;
      this.searchCriteria.data.UndersupplierID = this.undersupplierID;
      this.searchCriteria.data.customerID = this.customerID
      let filterInfo = JSON.parse(sessionStorage.getItem('productListFilter'));
      if (filterInfo) {
        this.getProductList();
      }
    }
  }

  getSelectData(val) {
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.customerID = val.webshop.value ? val.webshop.value : 0;
      }
    }
    this.productIdManual = val.productManualID ? val.productManualID.value : "";
    this.productName = val.productName ? val.productName.value : "";
    this.supplierID = val.supplier ? val.supplier.value : "";
    this.DontShowDeleted = val.dontShowDeleted ? val.dontShowDeleted.value : false;
    this.undersupplierProductID = val.UndersupplierProductID ? val.UndersupplierProductID.value : "";
    this.InternalStockProducts = val.InternalstockProducts ? val.InternalstockProducts.value : false;

    if (val.status) {
      this.statusValue = val.status.value;
      let statusKey = val.status.value;
      this.getStatusVal = { [statusKey]: true };
    }
    this.productListFilter = {
      productIdManual: this.productIdManual,
      productName: this.productName,
      supplierID: this.supplierID,
      getStatusVal: this.getStatusVal,
      statusValue: this.statusValue,
      DontShowDeleted: this.DontShowDeleted,
      CustomerID: this.customerID,
      pageIndex: this.activePage,
      pageSize: this.perPagedata,
      InternalStockProducts: this.InternalStockProducts,
    }

    if (this.undersupplierProductID !== undefined && this.undersupplierProductID !== '') {
      this.productListFilter.UndersupplierProductID = this.undersupplierProductID;
    }
    this.filterFields.forEach(element => {
      if (element.key === 'productName') {
        element.selected = this.productName
      }
      else if (element.key === "productManualID") {
        element.selected = this.productIdManual
      }
    });
    // this.setfilterData();
    sessionStorage.setItem('productListFilter', JSON.stringify(this.productListFilter));
  }

  getReset(val) {
    this.activePage = 1;
    const filterResult = this.passFilterresult.filter(data => data.label === 'All')
    this.passFilterresult = filterResult;
    sessionStorage.removeItem('productListFilter');
    sessionStorage.removeItem('presearch');
    this.productIdManual = "";
    this.productName = "";
    this.supplierID = "";
    this.undersupplierProductID = "";
    this.getStatusVal = false;
    this.DontShowDeleted = true;
    this.productList = [];
    this.InternalStockProducts = false;
    this.tableName = 'productlist';
    this.setfilterData();
    // this.getUnderSuppliers();
    // this.loadProducts();
  }

  getSubmit(val) {
    this.perPagedata = 10;
    this.activePage = 1;
    let filterInfo: any = JSON.parse(sessionStorage.getItem('productListFilter'));
    if (filterInfo !== null && filterInfo !== undefined) {
      filterInfo.pageIndex = this.activePage;
      filterInfo.pageSize = this.perPagedata;
      sessionStorage.setItem('productListFilter', JSON.stringify(filterInfo));
      sessionStorage.setItem('presearch', JSON.stringify(filterInfo));
    }
    else {
      const sendReq = {
        "productIdManual": "",
        "productName": "",
        "supplierID": "",
        "getStatusVal": { "All": true },
        "statusValue": "All",
        "DontShowDeleted": true,
        "InternalStockProducts": false,
        "CustomerID": '',
        "pageIndex": 1,
        "pageSize": 10
      }
      sessionStorage.setItem('presearch', JSON.stringify(sendReq));
      sessionStorage.setItem('productListFilter', JSON.stringify(sendReq));
    }
    this.loadTag = true;
    this.setfilterData()
    this.loadProducts();
  }

  getProductList() {
    this.showInnerLoader = true;
    this.searchCriteria.data.IncludeChildCustomers = false;
    delete this.searchCriteria.data['customerID'];
    // const loginData = this.apiService.getLoginData();
    if (this.customerID === '' || this.customerID === undefined || this.customerID === null || this.customerID === 0) {
      this.searchCriteria.data.CustomerID = this.webshopDetail.CustomerID;
      this.searchCriteria.data.IncludeChildCustomers = true;
    }
    const customerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (customerDetails !== null && customerDetails !== undefined) {
      if (customerDetails.ParentCustomerID) {
        this.searchCriteria.data.IncludeChildCustomers = false;
      }
    }
    this.IsCustomerSelected = !this.searchCriteria.data.IncludeChildCustomers;
    // if (this.searchCriteria.data.ProductName || this.searchCriteria.data.ProductIdManual) {
    //   this.searchCriteria.data.IncludeChildCustomers = false;
    // }

    this.searchCriteria.data.LoggedInCustomerID = this.webshopDetail.CustomerID;

    if (this.searchCriteria.data.All && this.searchCriteria.data.InternalStockProducts) {
      this.searchCriteria.data.All = false;
    }

    // if (this.searchCriteria.data.ProductIdManualExact === undefined || this.searchCriteria.data.ProductIdManualExact === '' || this.searchCriteria.data.ProductIdManualExact === null) {
    //   delete this.searchCriteria.data.ProductIdManualExact;
    // }

    this.productHttpService.getProductList(this.searchCriteria).subscribe(data => {
      if (data !== null) {
        if (data) {
          this.productList = data;
          this.productList = this.productList.ItemsCollection;
          this.productdatacount = data['TotalItemsCount'];
          this.showInnerLoader = false
          if (Number(data['TotalItemsCount']) === 0) {
            this.tableName = 'productlistpage';
          }
        }
        else {
          this.showInnerLoader = false
        }
      }
    },
      err => {
        let errMsg = '';
        this.showInnerLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);

      });
  }

  getFilterResult(filterresult) {
    if (filterresult.length === 2) {
      this.passFilterresult = filterresult;
    }
    const ifHaveFilter = JSON.parse(sessionStorage.getItem('presearch'));
    if (ifHaveFilter !== null && ifHaveFilter !== undefined && ifHaveFilter !== '') {
      if (this.loadTag) {
        this.passFilterresult = filterresult;
        this.showTags = this.passFilterresult.length > 0 ? true : false;
      }
    }
  }


  refreshProductList(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.getProductList();
    }
  }

  addProduct(event: boolean) {
    this.submitted = event;
  }

  viewProduct(event: boolean) {
    this.submitted = event;
  }

  editProduct(productData: any) {
    // this.router.navigate(['/admin-product-edit'], { state: { productData }, replaceUrl: false });
    if (productData) {
      this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
        if (data) {
          data.dashboardRedirectStatus = false;
          data.IsCustomerSelected = this.IsCustomerSelected
          if (data.isMarketingProduct) {
            this.router.navigate(['/product-market-edit'], { state: { productData, data }, replaceUrl: false });
          }
          else {
            this.router.navigate(['/admin-product-edit'], { state: { productData, data }, replaceUrl: false });
          }
        }
      });
    }
  }


  deleteProduct(event: boolean) {
    this.submitted = event;
    console.log('delete product')
    console.log(event);
    const request = {
      customerID: event['CustomerID'],
      productID: event['ProductID']
    }
    if (!event['IsMultipleCustomer'] && !event['isImported']) {
      if (event['proType'] === 'OP') {
        this.originalProductDelete('all');
      } else {
        this.customerProductDelete(request);
      }
    }



    if (event['EditableType'] === 'OP' && event['IsMultipleCustomer']) {
      this.originalProductDelete('all');
    }
    else if (event['EditableType'] === 'CP' && event['IsMultipleCustomer']) {
      this.productHttpService.customerProductDelete(request).subscribe(cusdata => {
        console.log('cusdata')
        if (cusdata) {
          this.toast.success('Product deleted successfully');
          this.getMappedData(this.mappedRowData, 'delete');
        }
      });
    }
    else if (!event['IsMultipleCustomer'] && event['isImported']) {
      this.customerProductDelete(request)
    }

  }

  customerProductDelete(request) {
    this.productHttpService.customerProductDelete(request).subscribe(cusdata => {
      if (cusdata) {
        this.toast.success('Product deleted successfully');
        this.loadProducts();
      }
    });
  }

  originalProductDelete(type?) {
    const request = {
      ProductID: this.submitted['ProductID'],
      deleteCP: type == 'all' ? true : false
    }
    this.productHttpService.deleteProduct(request).subscribe(data => {
      if (data !== null) {
        if (data) {
          this.mappedModal.hide();
          this.toast.success('Product deleted successfully');
          setTimeout(() => {
            this.loadProducts();
          }, 300)
        }
      }
    });
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.activePage = 1;
    let ifHaveFilter = JSON.parse(sessionStorage.getItem('presearch'));
    ifHaveFilter.pageIndex = this.activePage;
    ifHaveFilter.pageSize = this.perPagedata;
    sessionStorage.setItem('presearch', JSON.stringify(ifHaveFilter));
    ifHaveFilter = JSON.parse(sessionStorage.getItem('presearch'));
    if (ifHaveFilter !== null && ifHaveFilter !== undefined && ifHaveFilter !== '') {
      sessionStorage.setItem('productListFilter', JSON.stringify(ifHaveFilter));
      this.setfilterData();
      this.loadProducts();
    }
    else {
      this.setfilterData();
      this.loadProducts();
    }
  }
  paginationNum(event) {
    this.perPagedata = this.searchCriteria.pageSize;
    this.activePage = event;
    let ifHaveFilter = JSON.parse(sessionStorage.getItem('presearch'));
    ifHaveFilter.pageIndex = this.activePage;
    ifHaveFilter.pageSize = this.perPagedata;
    ifHaveFilter.CustomerID = this.customerID;
    ifHaveFilter.productName = this.productName;
    ifHaveFilter.productIdManual = this.productIdManual;
    ifHaveFilter.supplierID = this.supplierID;
    ifHaveFilter.UndersupplierProductID = this.undersupplierProductID;
    sessionStorage.setItem('presearch', JSON.stringify(ifHaveFilter));
    ifHaveFilter = JSON.parse(sessionStorage.getItem('presearch'));
    if (ifHaveFilter !== null && ifHaveFilter !== undefined && ifHaveFilter !== '') {
      sessionStorage.setItem('productListFilter', JSON.stringify(ifHaveFilter));
      this.setfilterData();
      this.loadProducts();
    }
    else {
      this.setfilterData();
      this.loadProducts();
    }
  }
  editpage() {
    this.router.navigate(['/app-product-tab'], { replaceUrl: false });
  }

  cloneProduct(productData) {
    this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
      if (data) {
        data.dashboardRedirectStatus = false;
        data.IsCustomerSelected = this.IsCustomerSelected;
        this.router.navigate(['/admin-product-edit'], { state: { productData, data, isClone: true, isSibling: false }, replaceUrl: false });
      }
    });
  }

  siblingProduct(siblingdata) {
    this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
      if (data) {
        data.dashboardRedirectStatus = false;
        data.IsCustomerSelected = this.IsCustomerSelected
        this.router.navigate(['/admin-product-edit'], { state: { siblingdata, data, isClone: true, isSibling: true }, replaceUrl: false })
      }
    });
  }

  getBulkAction(event) {
    console.log(event);
    console.log(this.getTypeOfPublish);
    if (event.bulkaction.productcheckbox !== undefined) {
      let getaction = event.bulkaction.productcheckbox;
      this.bulkProductIds = [];
      this.bulkData = [];
      getaction.forEach((element, index) => {
        this.bulkProductIds.push(element.ProductID)
        const productRequest = {
          "ProductID": element.ProductID,
          "CustomerID": element.CustomerID
        }
        this.bulkData.push(productRequest)
      });
      console.log('testing bulk', this.bulkProductIds)
      const getErrorMessage = getaction.filter(data => data.VisibleDate);
      this.statusError = getErrorMessage.length === 0 || getErrorMessage.length === getaction.length ? false : true;
      this.updateStatus = getErrorMessage.length === 0 ? 'unpublish' : 'publish';
    }
  }



  MMDDYYYY = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('-');
  }

  dateToMonth(date: String) {
    const val = date.split('/');
    return [val[1], val[0], val[2]].join('-')
  }

  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }

  loadForm() {
    this.displayPublishDate = this.getDateFormat(new Date())
    this.publishForm = this.formBuilder.group({
      visibleDate: [this.displayPublishDate, Validators.required],
    })
  }

  getDateHypenFormat(data) {
    let getDate = new Date(data);
    let dateFormat = getDate.getFullYear() + '-' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '-' + this.getTwoDigitDateFormat(getDate.getDate());
    return dateFormat;
  }

  setDate() {
    if (this.bulkProductIds.length === 0) {
      this.toast.error('Please select the product');
      return false;
    }
    if (this.statusError) {
      this.toast.error("Please select publish or unpublish product. Both can't be selected.");
      return false;
    }
    this.displayPublishDate = this.getDateHypenFormat(new Date())
    this.publishForm.setValue({
      visibleDate: this.displayPublishDate
    })
    this.publishModal.show();
  }

  setStatus() {
    const loginData = this.apiService.getLoginData();
    let request: any = {
      userid: loginData.UserID,
      json: {
        "ProductIDs": this.bulkProductIds
      }
    }
    if (this.updateStatus === 'unpublish') {
      request.json.VisibleDate = this.publishForm.value.visibleDate;
    }
    this.productHttpService.bulkAction(request).subscribe(data => {
      if (data) {
        this.toast.success('Product status updated');
        this.publishModal.hide();
        this.loadProducts();
      }
    },
      (error) => {
        this.toast.error(error.error.Message);
        this.publishModal.hide();
      })
  }

  deleteRow() {
    if (this.bulkProductIds.length === 0) {
      this.toast.error('Please select the product');
      return false;
    }
    this.deleteModal.show();
  }

  getDeleteStatus(status) {
    if (status) {
      const request = this.bulkProductIds;
      this.productHttpService.bulkDelete(request).subscribe(data => {
        if (data) {
          this.toast.success('Product deleted successfully');
          this.deleteModal.hide();
          this.loadProducts();
        }
      },
        (error) => {
          this.toast.error(error.error.Message);
          this.deleteModal.hide();
        })

    }
    else {
      this.deleteModal.hide();
    }
  }

  getselectType(get) {
    if (get.key === 'webshop') {
      this.customerID = get.value;
      this.loadTag = false;
      this.undersupplierID = "";
      this.undersuppliers = [];
      this.supplierID = '';
      this.suppliers = [];
      const getSupplierIndex = this.filterFields.findIndex(data => data.filterkey === 'SupplierID');
      this.filterFields[getSupplierIndex]['data'] = [];

      // const getFormIndex = this.filterFields.findIndex(data => data.filterkey === 'UndersupplierID');
      // this.filterFields[getFormIndex]['data'] = [];
      let filterInfo = JSON.parse(sessionStorage.getItem('productListFilter'));
      filterInfo.supplierID = '';
      filterInfo.undersupplierID = '';
      sessionStorage.setItem('productListFilter', JSON.stringify(filterInfo));
      this.getSuppliers('changewebshop');
    }
    else if (get.key === "supplier") {
      this.loadTag = false;
      // this.undersupplierID = "";
      // this.undersuppliers = [];
      // const getFormIndex = this.filterFields.findIndex(data => data.filterkey === 'UndersupplierID');
      // this.filterFields[getFormIndex]['data'] = [];
      // let filterInfo = JSON.parse(sessionStorage.getItem('productListFilter'));
      // filterInfo.undersupplierID = '';
      // sessionStorage.setItem('productListFilter', JSON.stringify(filterInfo));
      // this.getUnderSuppliers('change');
    }
    else {
      this.loadTag = true;
    }
  }

  getChildChildCustomer() {
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getChildChildCustomer(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        const customerlist = [{ label: 'Select All', value: 0 }];
        data.forEach(cust => {
          let custData = {
            label: cust.Name,
            value: cust.CustomerID
          }
          customerlist.push(custData)
        })
        this.customerlist = customerlist;
        this.setfilterData();
      }
    },
      (error) => {

      })
  }

  // this.getSuppliers();

  getMappedData(event, type?) {
    this.mappedRowData = event;
    this.showLoader = true;
    const request: any = {
      "data": {
        "CustomerID": this.webshopDetail.CustomerID,
        "ProductIdManualExact": event.ProductIdManual,
        "ProductName": "",
        "SupplierID": "",
        "UndersupplierID": "",
        "DontShowDeleted": this.DontShowDeleted,
        "All": true,
        "IncludeChildCustomers": true,
        "LoggedInCustomerID": this.webshopDetail.CustomerID,
        "ShowMappedCustomers": true,
      },
      "pageIndex": 1,
      "pageSize": 20
    }
    this.productHttpService.getProductList(request).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.mappedList = data.ItemsCollection;
        this.mappedModal.show();
        console.log(this.mappedList);
        if (type == 'delete' && this.mappedList.length === 2) {
          this.mappedModal.hide();
          this.loadProducts();
        }
      }
    },
      (error) => {
        this.showLoader = false;
      });
  }

  typeOfPublish(event) {
    console.log(event);
    this.getTypeOfPublish = event;
  }

  bulkPublishUnpublish(type, mappedList?) {
    if (this.bulkProductIds.length === 0) {
      this.toast.error('Please select the product');
      return false;
    }
    console.log(type);
    console.log(this.getTypeOfPublish);
    const request = {
      customerID: this.webshopDetail.CustomerID,
      isOriginalPro: this.getTypeOfPublish.originalPro,
      type: type == 'publish' ? true : false
    }
    console.log(request)
    const jsonReq = this.bulkData;
    console.log(jsonReq);
    console.log('this.bulkData', this.bulkData)

    this.productHttpService.bulkPublishUnpublish(request, jsonReq).subscribe(data => {
      if (data) {
        let showMsg = type == 'publish' ? 'Product published successfully' : 'Product unpublished successfully';
        this.bulkProductIds = [];
        this.bulkData = [];
        this.toast.success(showMsg);
        if (mappedList) {
          this.getMappedData(this.mappedRowData);
        }
        else {
          this.loadProducts();
        }
      }
    },
      (error) => {
        let errMsg = 'The request is invalid. ';
        for (var key in error.error.ModelState) {
          errMsg += error.error.ModelState[key].toString() + ' ';
        }
        this.toast.error(errMsg);
      })

    // if (type === 'publish') {
    //   if (this.getTypeOfPublish.originalPro) {
    //     alert('OP')
    //     this.productHttpService.bulkPublishUnpublish(request, jsonReq).subscribe(data => {
    //       console.log(data);
    //     },
    //       (error) => {
    //         let errMsg = 'The request is invalid. ';
    //         for (var key in error.error.ModelState) {
    //           errMsg += error.error.ModelState[key].toString() + ' ';
    //         }
    //         this.toast.error(errMsg);
    //       })
    //   }
    //   else if (this.getTypeOfPublish.customerPro) {
    //     alert('CP')
    //     this.productHttpService.bulkPublishUnpublish(request, jsonReq).subscribe(data => {
    //       console.log(data);
    //     },
    //       (error) => {
    //         let errMsg = 'The request is invalid. ';
    //         for (var key in error.error.ModelState) {
    //           errMsg += error.error.ModelState[key].toString() + ' ';
    //         }
    //         this.toast.error(errMsg);
    //       })
    //   }
    // }
    // else {
    //   if (this.getTypeOfPublish.originalPro) {
    //     alert('OP')
    //     this.productHttpService.bulkPublishUnpublish(request, jsonReq).subscribe(data => {
    //       console.log(data);
    //     },
    //       (error) => {
    //         let errMsg = 'The request is invalid. ';
    //         for (var key in error.error.ModelState) {
    //           errMsg += error.error.ModelState[key].toString() + ' ';
    //         }
    //         this.toast.error(errMsg);
    //       })
    //   }
    //   else if (this.getTypeOfPublish.customerPro) {
    //     alert('CP')
    //     this.productHttpService.bulkPublishUnpublish(request, jsonReq).subscribe(data => {
    //       console.log(data);
    //     },
    //       (error) => {
    //         let errMsg = 'The request is invalid. ';
    //         for (var key in error.error.ModelState) {
    //           errMsg += error.error.ModelState[key].toString() + ' ';
    //         }
    //         this.toast.error(errMsg);
    //       })
    //   }
    // }
  }

  addBulkProduct() {
    this.bulkCreationModal.show();
  }

  onSelectFile(event) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if (getImg.toLowerCase() !== '.zip') {
      this.showImageError = true;
    } else {
      this.showImageError = false;
      if (event.target.files && event.target.files[0]) {
        let httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', null);
        httpHeaders.set('Accept', "multipart/form-data");
        const formData = new FormData();
        formData.append('CustomerId', this.webshopDetail.CustomerID);
        formData.append('filePath', event.target.files[0]);
        event.target.value = '';
        this.productHttpService.bulkProduct(formData).subscribe((data: any) => {
          this.bulkCreationModal.hide();
          this.toast.success('File imported successfully')
        }, _error => {
          this.toast.error('Please try again');
        });

      }
    }
  }

}
