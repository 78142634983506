import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from '../user/user-list/user-list.component';
import { UserPointsComponent } from '../user-points/user-list/user-list.component';
import { LoginComponent } from '../login/login.component';
import { WebshopListComponent } from '../webshop/webshop-list/webshop-list.component';
import { BlockEditComponent } from '../block/block-edit/block-edit.component';
import { BlockListComponent } from '../block/block-list/block-list.component';
import { CategoryListComponent } from '../category/category-list/category-list.component';
import { ProductListComponent } from '../product/product-list/product-list.component';
import { CategoryEditComponent } from '../category/category-edit/category-edit.component';
import { CategoryCopyComponent } from '../category/category-copy/category-copy.component';
import { UserEditComponent } from '../user/user-edit/user-edit.component';
import { BrandEditComponent } from '../brand/brand-edit/brand-edit.component';
import { UserPointsEditComponent } from '../user-points/user-edit/user-edit.component';
import { SupplierListComponent } from '../supplier/supplier-list/supplier-list.component';
import { BillboardListComponent } from '../billboard/billboard-list/billboard-list.component';
import { MainComponent } from '../main/main.component';
import { UnderConstructionComponent } from '../under-construction/under-construction.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { WidgetModalComponent } from '../widget-modal/widget-modal.component';
import { AdvancedSearchComponent } from '../category/advanced-search/advanced-search.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MessageInboxdetailsComponent } from '../message-box/message-inbox/message-inboxdetails/message-inboxdetails.component';
import { MessageTrashComponent } from '../message-box/message-trash/message-trash.component';
import { ProductTabComponent } from '../product/product-tab/product-tab.component';
import { ProductEditComponent } from '../product/product-edit/product-edit.component';
import { ProductAddComponent } from '../product/product-add/product-add.component';
import { ProductAddMarketingComponent } from '../product/product-add-marketing/product-add-marketing.component';
import { ProductMarketEditComponent } from '../product/product-market-edit/product-market-edit.component';
import { SubcategoriesComponent } from '../category/subcategories/subcategories.component';
import { CategoriesComponent } from '../category/categories/categories.component';
import { BillboardEditComponent } from '../billboard/billboard-edit/billboard-edit.component';
import { BannerEnquiryListComponent } from '../bannerenquiry/bannerenquiry-list/bannerenquiry-list.component';
import { BannerEnquiryDetailComponent } from '../bannerenquiry/bannerenquiry-detail/bannerenquiry-detail.component';
import { ReportProfitCenterComponent } from '../reports/report-profit-center/report-profit-center.component';
import { ReportDispatchedProductsComponent } from '../reports/report-dispatched-products/report-dispatched-products.component';
import { ReportOrdersComponent } from '../reports/report-orders/report-orders.component';
import { ReportProductsSearchComponent } from '../reports/report-products-search/report-products-search.component';
import { ReportInternalStockProductsComponent } from '../reports/report-internal-stock-products/report-internal-stock-products.component';
import { UserBudgetslistComponent } from '../user/user-budgetslist/user-budgetslist.component';
import { UserBudgetseditComponent } from '../user/user-budgetsedit/user-budgetsedit.component';
// import { ColorssettingComponent } from '../colorssetting/colorssetting.component';
import { ProductionLineComponent } from '../order/production-line/production-line.component';
import { ProductionLineListComponent } from '../order/production-line-list/production-line-list.component';
import { ColorssettingComponent } from '../colorssetting/colorssetting.component';
import { ManageColorSettingsComponent } from '../settings/manage-color-settings/manage-color-settings.component';
import { BatchManagementComponent } from '../settings/batch-management/batch-management.component';
import { AdvanceSearchWebshopOnlineProductsComponent } from '../category/advance-search-webshop-online-products/advance-search-webshop-online-products.component';
import { OrderProcessComponent } from '../order/order-process/order-process.component';
import { OrdersEditComponent } from '../order/orders-edit/orders-edit.component';
import { OrderItemComponent } from '../order/order-item/order-item.component';
import { OrderProcessViewDetailsComponent } from '../order/order-process-view-details/order-process-view-details.component';
import { EditOrderitemsComponent } from '../order/order-process/edit-orderitems/edit-orderitems.component';
import { ViewOrderstatusComponent } from '../order/order-process/view-orderstatus/view-orderstatus.component';
import { EnabledisablewebshopSettingsComponent } from '../settings/enabledisablewebshop-settings/enabledisablewebshop-settings.component';
import { SupplierAddComponent } from '../supplier/supplier-add/supplier-add.component';
import { UndersupplierAddComponent } from '../undersupplier/undersupplier-add/undersupplier-add.component';
import { UndersupplierListComponent } from '../undersupplier/undersupplier-list/undersupplier-list.component';
import { ManageSupplierSettingsComponent } from '../supplier/manage-supplier-settings/manage-supplier-settings.component';
import { EnableopenbookSettingsComponent } from '../settings/enableopenbook-settings/enableopenbook-settings.component';

import { OrderProcessUnauthorisedDetailsComponent } from '../order/order-process-view-details/order-process-unauthorised-details/order-process-unauthorised-details.component';
import { WebshopAddComponent } from '../webshop/webshop-add/webshop-add.component';
import { WebshopEditComponent } from '../webshop/webshop-edit/webshop-edit.component';
import { PaymentDetailsComponent } from '../webshop/webshop-edit/payment-details/payment-details.component';
import { WebshopCurrenciesComponent } from '../webshop/webshop-edit/webshop-currencies/webshop-currencies.component';
import { WarehouseComponent } from '../warehouse-management/warehouse/warehouse.component';
import { WarehouseAddComponent } from '../warehouse-management/warehouse/warehouse-add/warehouse-add.component';
import { LogisticVendorComponent } from '../warehouse-management/logistic-vendor/logistic-vendor.component';
import { LogisticVendorAddComponent } from '../warehouse-management/logistic-vendor/logistic-vendor-add/logistic-vendor-add.component';
import { WebshopLanguageComponent } from '../webshop/webshop-edit/webshop-language/webshop-language.component';
import { FooterLinkSettingsComponent } from '../settings/footer-link-settings/footer-link-settings.component';
import { OrderProcessDeletedOrdersComponent } from '../order/order-process-view-details/order-process-deleted-orders/order-process-deleted-orders.component';
import { ProductGroupsComponent } from '../settings/product-groups/product-groups.component';
import { ProductGroupsEditComponent } from '../settings/product-groups/product-groups-edit/product-groups-edit.component';
import { PointsSummaryComponent } from '../user-points/points-summary/points-summary.component';
import { BrandListComponent } from '../brand/brand-list/brand-list.component';
import { CampaignListComponent } from '../campaign/campaign-list/campaign-list.component';
import { CampaignEditComponent } from '../campaign/campaign-edit/campaign-edit.component';
import { ChildshopListComponent } from '../childshop/childshop-list/childshop-list.component';
import { ParentshopListComponent } from '../parentshop/parentshop-list/parentshop-list.component';
import { CompanyOrderDetailComponent } from '../company-order/company-order-detail/company-order-detail.component';
import { CompanyOrderDetailViewComponent } from '../company-order/company-order-detail-view/company-order-detail-view.component';
import { CompanyOrderSummaryComponent } from '../company-order/company-order-summary/company-order-summary.component';
import { CampaignBillboardEditComponent } from '../campaign/billboard-edit/billboard-edit.component';
import { CampaignBillboardListComponent } from '../campaign/billboard-list/billboard-list.component';
import { ReportDeliveryStatusComponent } from '../reports/report-delivery-status/report-delivery-status.component';
import { ReportUsermisComponent } from '../reports/report-user-mis/report-usermis.component';
import { ReportUseractivityComponent } from '../reports/report-user-activity/report-useractivity.component';
import { ReportBudgetComponent } from '../reports/report-budget/report-budget.component';
import { ReportCampaignAllocationComponent } from '../reports/report-campaign-allocation/report-campaign-allocation.component';
import { ReportCampaignUsageComponent } from '../reports/report-campaign-usage/report-campaign-usage.component';
import { ReportOrderFulfillmentComponent } from '../reports/report-order-fulfillment/report-order-fulfillment.component';
import { ReportUserManagementComponent } from '../reports/report-user-management/report-user-management.component';
import { ReportPaymentComponent } from '../reports/report-payment/report-payment.component'; 
import { SubscriptionComponent } from '../subscription/subscription.component';
import { SubscriptionListComponent } from '../subscription/subscription-list/subscription-list.component';
import { TrackOrderComponent } from '../order/order-item/track-order/track-order.component';
import { BulkUserImportComponent } from '../user/user-import/user-import.component';
import { SignUpComponent } from '../signup/signup.component';
import { UserPaymentComponent } from '../user/user-payment/user-payment.component';
import { UserPaymentReportComponent } from '../user/user-paymentreport/user-paymentreport.component';
import { EmailconfigComponent } from '../user/emailconfig/emailconfig.component';
import { CurrrencyComponent } from '../webshop/webshop-edit/currrency/currrency.component';
import { CurrencyaddComponent } from '../webshop/webshop-edit/currencyadd/currencyadd.component';
import { AddCountryComponent } from '../webshop/webshop-edit/add-country/add-country.component';
import { HyperCountryComponent } from '../webshop/webshop-edit/hyper-country/hyper-country.component';
const routes: Routes = [
  { path: 'admin-banner-enquiry', component: BannerEnquiryListComponent, data: { breadcrumb: 'Banner Enquiry' } },
  { path: 'admin-banner-enquiry-detail', component: BannerEnquiryDetailComponent, data: { breadcrumb: 'Banner Enquiry Details' } },
  { path: 'admin-billboard-add', component: BillboardEditComponent, data: { breadcrumb: 'Billboard add' } },
  { path: 'admin-billboard-edit', component: BillboardEditComponent, data: { breadcrumb: 'Billboard edit' } },
  { path: 'admin-budget-report', component: ReportBudgetComponent, data: { breadcrumb: 'Budget Report' } },
  { path: 'admin-campaign-billboard-edit', component: CampaignBillboardEditComponent, data: { breadcrumb: 'Campaign Billboard' } },
  { path: 'admin-campaign-billboard-list', component: CampaignBillboardListComponent, data: { breadcrumb: 'Campaign Billboard' } },
  { path: 'admin-campaign-allocation-report', component: ReportCampaignAllocationComponent, data: { breadcrumb: 'Campaign Allocation Report' } },
  { path: 'admin-campaign-usage-report', component: ReportCampaignUsageComponent, data: { breadcrumb: 'Campaign Usage Report' } },
  { path: 'admin-company-order-detail', component: CompanyOrderDetailComponent, data: { breadcrumb: 'Company Order Detail' } },
  { path: 'admin-company-order-detail-view', component: CompanyOrderDetailViewComponent, data: { breadcrumb: 'Company Order Detail' } },
  { path: 'admin-company-order-summary', component: CompanyOrderSummaryComponent, data: { breadcrumb: 'Company Order Summary' } },
  { path: '', pathMatch: 'full', redirectTo: 'admin-login', data: { breadcrumb1: 'Dashboard' } },
  { path: 'admin-login', component: LoginComponent, pathMatch: 'full', data: { breadcrumb: 'Login' } },
  { path: 'admin-signup', component: SignUpComponent, pathMatch: 'full', data: {breadcrumb: 'Signup'}},
  { path: 'admin-main', component: MainComponent, pathMatch: 'full', data: { breadcrumb: 'Main' } },
  { path: 'admin-user-list', component: UserListComponent, pathMatch: 'full', data: { breadcrumb: 'User List' } },
  { path: 'admin-user-points', component: UserPointsComponent, pathMatch: 'full', data: { breadcrumb: 'User Reward Points' } },
  { path: 'admin-payment-report', component: ReportPaymentComponent, data: { breadcrumb: 'Budget Report' } },
  { path: 'admin-user-budgetlist/:userID', component: UserBudgetslistComponent, pathMatch: 'full', data: { breadcrumb: 'User Budget list' }},
  { path: 'admin-userbudget-add/:userID', component: UserBudgetseditComponent, pathMatch: 'full', data: { breadcrumb: 'Add User Budget ' } },
  // { path: 'admin-userbudget-edit', component: UserBudgetseditComponent, pathMatch: 'full', data: { breadcrumb: 'Edit User Budget' } },
  { path: 'admin-user-add', component: UserEditComponent, pathMatch: 'full', data: { breadcrumb: 'Add User' } }, { path: 'admin-brand-add', component: BrandEditComponent, pathMatch: 'full', data: { breadcrumb: 'Add Brand' } },
  { path: 'admin-user-edit', component: UserEditComponent, data: { breadcrumb: 'Edit User' } },
  { path: 'admin-bulk-user-import', component: BulkUserImportComponent, pathMatch: 'full', data: {breadcrumb: 'Bulk User Import'}},
  { path: 'admin-brand-edit', component: BrandEditComponent, data: { breadcrumb: 'Edit Brand' } },
  { path: 'admin-user-points-edit', component: UserPointsEditComponent, data: { breadcrumb: 'Edit User Points' } },
  { path: 'admin-points-summary', component: PointsSummaryComponent, data: { breadcrumb: 'Points Summary' } },
  { path: 'admin-webshop-list', component: WebshopListComponent, data: { breadcrumb: 'Webshop List' } },
  { path: 'admin-webshop-add', component: WebshopAddComponent, data: { breadcrumb: 'Webshop Add' } },
  { path: 'admin-block-list', component: BlockListComponent, data: { breadcrumb: 'Block list' } },
  { path: 'admin-block-add', component: BlockEditComponent, data: { breadcrumb: 'Block add' } },
  { path: 'admin-block-edit', component: BlockEditComponent, pathMatch: 'full', data: { breadcrumb: 'Block edit' } },
  { path: 'admin-order-process/:resetSearch', component: OrderProcessComponent, data: { breadcrumb: 'Order Process' } },
  { path: 'admin-order-item/:id', component: OrderItemComponent, data: { breadcrumb: 'Order Item' }},
  { path: 'admin-track-order', component: TrackOrderComponent, data: { breadcrumb: 'Track Order' }},
  { path: 'admin-order-item/productionlineoverview/:id/:lineId', component: OrderItemComponent, data: { breadcrumb: 'Order Item' }},
  { path: 'admin-order-production-line-details/:id/:finishedPLs', component: ProductionLineComponent, data: { breadcrumb: 'Order Production Line Details' }},
  { path: 'edit-orderitems', component: EditOrderitemsComponent, data: { breadcrumb: 'Edit Order Item' }},
  { path: 'view-order', component: ViewOrderstatusComponent, data: { breadcrumb: 'View Order Status' }},
  { path: 'admin-order-production-line-list/:resetSearch', component: ProductionLineListComponent, data: { breadcrumb: 'Production Line' }},
  { path: 'admin-category-list', component: CategoryListComponent, data: { breadcrumb: 'Category list' } },
  { path: 'admin-category-edit', component: CategoryEditComponent, data: { breadcrumb: 'Category edit' } },
  { path: 'admin-category-copy', component: CategoryCopyComponent, data: { breadcrumb: 'Category copy' } },
  { path: 'admin-product-list', component: ProductListComponent, data: { breadcrumb: 'Product list' } },
  { path: 'admin-brand-list', component: BrandListComponent, data: { breadcrumb: 'Brand List' } },
  { path: 'admin-campaign-list', component: CampaignListComponent, data: { breadcrumb: 'Campaign list' } },
  { path: 'admin-campaign-add', component: CampaignEditComponent, pathMatch: 'full', data: { breadcrumb: 'Add Campaign' } },
  { path: 'admin-campaign-edit', component: CampaignEditComponent, pathMatch: 'full', data: { breadcrumb: 'Edit Campaign' } },
  { path: 'admin-product-edit', component: ProductEditComponent, data: { breadcrumb: 'Product edit' } },
  { path: 'admin-supplier-list', component: SupplierListComponent, data: { breadcrumb: 'Supplier list' } },
  { path: 'admin-billboard-list', component: BillboardListComponent, data: { breadcrumb: 'Billboard list' } },
  { path: 'admin-under-construction', component: UnderConstructionComponent, data: { breadcrumb: 'Under construction' } },
  { path: 'admin-dashboard', component: DashboardComponent, data: { breadcrumb: 'Dashboard' } },
  { path: 'app-widget-modal', component: WidgetModalComponent, data: { breadcrumb: 'Widget modal' } },
  { path: 'app-product-tab', component: ProductTabComponent, data: { breadcrumb: 'Edit Product' } },
  { path: 'admin-messagebox', component: MessageBoxComponent, data: { breadcrumb: 'Message box' } },
  // { path: 'admin-messagebox', loadChildren: () => import('../message-box/messagebox.module').then(m => m.MessageboxModule), data: { breadcrumb: 'Message box' } },
  { path: 'admin-order-process/unauthorised/:orderId', component: OrderProcessUnauthorisedDetailsComponent, data: { breadcrumb: 'Order Process' }},
  { path: 'admin-order-process/deletedOrders/:orderId', component: OrderProcessDeletedOrdersComponent, data: { breadcrumb: 'Order Process' }},
  { path: 'admin-order-process/orderProcess/:orderId/:subOrderId/:isSubOrder', component: OrderProcessViewDetailsComponent, data: { breadcrumb: 'Order Process' } },
  { path: 'admin-order-process/productionlineoverview/:orderId/:lineId/:isSubOrder', component: OrderProcessViewDetailsComponent, data: { breadcrumb: 'Order Process' } },
  { path: 'admin-order-process/:orderId/:subOrderId/edit', component: OrdersEditComponent, data: { breadcrumb: 'Order Edit' } },
  { path: 'admin-order-fulfillment-report', component: ReportOrderFulfillmentComponent, data: { breadcrumb: 'Order Fulfillment Report' } },
  { path: 'admin-user-management-report', component: ReportUserManagementComponent, data: { breadcrumb: 'User Management Report' } },
  { path: 'admin-trash', component: MessageTrashComponent, data: { breadcrumb: 'Trash' } },
  { path: 'admin-product-add', component: ProductAddComponent, data: { breadcrumb: 'Product Add' } },
  { path: 'add-marketing-product', component: ProductAddMarketingComponent, data: { breadcrumb: 'Add Marketing Product' } },
  { path: 'product-market-edit', component: ProductMarketEditComponent, data: { breadcrumb: 'Edit Marketing Product' } },
  { path: 'app-advanced-search', component: AdvancedSearchComponent },
  { path: 'app-subcategories', component: SubcategoriesComponent },
  { path: 'admin-report-dispatched-products', component: ReportDispatchedProductsComponent, data: { breadcrumb: 'Report - Dispatched Products' }},
  { path: 'admin-report-profit-center', component: ReportProfitCenterComponent, data: { breadcrumb: 'Report - Profit Center' }},
  { path: 'report-internal-stock-products',component: ReportInternalStockProductsComponent, data: { breadcrumb: 'Report - Internal Stock Products' }},
  { path: 'admin-report-products-search', component: ReportProductsSearchComponent, data: { breadcrumb: 'Report - Products Search' } },
  { path: 'admin-report-orders', component: ReportOrdersComponent, data: { breadcrumb: 'Report - Order Reports' } },
  { path: 'admin-delivery-status', component: ReportDeliveryStatusComponent, data: { breadcrumb: 'Report - Order Delivery Status' } },
  { path: 'admin-report-user-mis', component: ReportUsermisComponent, data: { breadcrumb: 'User - MIS Report' } },
  { path: 'admin-report-user-activity', component: ReportUseractivityComponent, data: { breadcrumb: 'User - Actiivity Report' } },
  /*{ path: 'admin-subscription', component: SubscriptionComponent, data: { breadcrumb: 'Subscription Details' } },
  { path: 'admin-subscription-list', component: SubscriptionListComponent, data: { breadcrumb: 'Subscription List' } },*/
  { path: 'admin-category', component: CategoriesComponent, data: { breadcrumb: 'Categories' } },
  { path: 'manage-color', component: ManageColorSettingsComponent, data: { breadcrumb: 'Colors Setting', supplierEnableStatus: true } },
  { path: 'footer-link', component: FooterLinkSettingsComponent, data: { breadcrumb: 'FooterLink Setting' } },
  { path: 'product-groups', component: ProductGroupsComponent, data: { breadcrumb: 'Product Groups' } },
  { path: 'product-groups-add', component: ProductGroupsEditComponent, pathMatch: 'full', data: { breadcrumb: 'Add Product Group' } },
  { path: 'product-groups-edit', component: ProductGroupsEditComponent, data: { breadcrumb: 'Edit Product Group' } },
  { path: 'batch-management', component: BatchManagementComponent, data: { breadcrumb: 'Batch Management' } },
  { path: 'app-advanced-search-online-products', component: AdvanceSearchWebshopOnlineProductsComponent },
  { path: 'settings-webshop', component: EnabledisablewebshopSettingsComponent, data: { breadcrumb: 'Admin Settings' } },
  { path: 'manage-supplier', component: ManageSupplierSettingsComponent, data: { breadcrumb: 'Manage Supplier ' } },
  { path: 'settings-openbook', component: EnableopenbookSettingsComponent, data: { breadcrumb: 'Enable Open-book ' } },
  { path: 'admin-supplier-add', component: SupplierAddComponent, data: { breadcrumb: 'Add Supplier' } },
  { path: 'admin-supplier-edit', component: SupplierAddComponent, data: { breadcrumb: 'Edit Supplier' }},
  { path: 'admin-undersupplier-add', component: UndersupplierAddComponent, data: { breadcrumb: 'Add Under Supplier' }},
  { path: 'admin-undersupplier-edit', component: UndersupplierAddComponent, data: { breadcrumb: 'Edit Under Supplier' }},
  { path: 'admin-undersupplier-list', component: UndersupplierListComponent, data: { breadcrumb: 'Under Supplier List' }},
  { path: 'webshop-edit', component: WebshopEditComponent, data: { breadcrumb: 'Webshop edit' }},
  { path: 'shop-details', component: WebshopEditComponent, data: { breadcrumb: 'Shop details' }},
  { path: 'webshop-landing-page', component: WebshopEditComponent, data: { breadcrumb: 'Landing Page' }},
  { path: 'webshop-payment', component: WebshopEditComponent, data: { breadcrumb: 'Payment details' }},
  { path: 'webshop-currencies', component: WebshopEditComponent, data: { breadcrumb: 'Currencies' }},
  { path: 'product-roles', component: WebshopEditComponent, data: { breadcrumb: 'Product roles' }},
  { path: 'product-tags', component: WebshopEditComponent, data: { breadcrumb: 'Product tags' }},
  { path: 'user-details', component: WebshopEditComponent, data: { breadcrumb: 'User details' }},
  { path: 'handling-charge', component: WebshopEditComponent, data: { breadcrumb: 'Handling Charges' }},
  { path: 'transportation-hub', component: WebshopEditComponent, data: { breadcrumb: 'Transportation Hub' }},
  { path: 'webshop-settings', component: WebshopEditComponent, data: { breadcrumb: 'Webshop Settings' }},
  { path: 'webshop-color', component: WebshopEditComponent, data: { breadcrumb: 'Webshop Color ' }},
  { path: 'webshop-logo', component: WebshopEditComponent, data: { breadcrumb: 'Webshop Logo ' }},
  { path: 'homepage-category', component: WebshopEditComponent, data: { breadcrumb: 'Homepage Category' }},
  { path: 'webshop-features', component: WebshopEditComponent, data: { breadcrumb: 'Webshop features' }},
  { path: 'social-media', component: WebshopEditComponent, data: { breadcrumb: 'Social Media' }},
  { path: 'warehouse', component: WarehouseComponent, data: { breadcrumb: 'Warehouse' }},
  { path: 'warehouse-add', component: WarehouseAddComponent, data: { breadcrumb: 'Create Warehouse ' }},
  { path: 'warehouse-edit', component: WarehouseAddComponent, data: { breadcrumb: 'Edit Warehouse' } },
  { path: 'logisticvendor', component: LogisticVendorComponent, data: { breadcrumb: 'Logistic Vendor' } },
  { path: 'logisticvendor-add', component: LogisticVendorAddComponent, data: { breadcrumb: 'Create Logistic Vendor' } },
  { path: 'logisticvendor-edit', component: LogisticVendorAddComponent, data: { breadcrumb: 'Edit Logistic Vendor' } },
  { path: 'publish', component: WebshopEditComponent, data: { breadcrumb: 'Publish' } },
  { path: 'webshop-language', component: WebshopEditComponent, data: { breadcrumb: 'Webshop Language' } },
  { path: 'webshop-maintenance', component: WebshopEditComponent, data: { breadcrumb: 'Webshop Maintenance' } },
  { path: 'admin-childshop-list', component: ChildshopListComponent, data: { breadcrumb: 'Childshop List' } },
  { path: 'admin-parentshop-list', component: ParentshopListComponent, data: { breadcrumb: 'Parentshop List' } },
  { path: 'admin-user-payment',component: UserPaymentComponent,data : { breadcrumb:'Payment'}},
  { path: 'admin-user-payment-report',component: UserPaymentReportComponent, data : { breadcrumb:'Payment Report'}},
  { path: 'email-config',component: EmailconfigComponent, data : { breadcrumb:'Email Config'}},
  { path: 'currency',component: CurrrencyComponent, data : { breadcrumb:'Currency'}},
  { path: 'addcurrency',component: CurrencyaddComponent, data : { breadcrumb:'Currency'}},
  { path: 'addcountry',component: AddCountryComponent, data : { breadcrumb:'Country'}},
  { path: 'country',component: HyperCountryComponent, data : { breadcrumb:'Country'}},



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AdminRouterModule { }
