import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WarehouseHttpService } from 'src/app/warehouse-management/state/services/warehouse.http.service';

@Component({
  selector: 'rewardkart-admin-order-price-table',
  templateUrl: './order-price-table.component.html',
  styleUrls: ['./order-price-table.component.scss']
})
export class OrderPriceTableComponent implements OnInit {
  @Input() orderItem: any = [];
  @Input() order: any = {};
  @Input() currency: any = {};
  @Input() orderId: any = '';
  @Input() profit: any = {};
  @Input() showField: any;
  @Input() unAuthorised: any;
  priceList = [];
  mappedOrders = [];
  webshopDetail: any;
  handlingCost: boolean;
  handlingActualCost: boolean;
  warehouselist: any[];
  subOrderIndex: any;
  constructor(public datepipe: DatePipe, private warehousehttpservice: WarehouseHttpService, private route: ActivatedRoute) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    this.handlingActualCost = false;
    const idFromRoute = this.getUrlParam('subOrderId');
    if (idFromRoute !== null && idFromRoute !== undefined && this.order.Suborders !== undefined) {
      this.subOrderIndex = this.order.Suborders.findIndex(x => x.SuborderID === parseInt(idFromRoute));
    }
    else if (this.order.Suborders !== undefined) {
      for (var item of this.order.Suborders) {
        const index = item.OrderItems.findIndex(x => x.OrderIdManual === this.orderItem.OrderIdManual);
        this.subOrderIndex = index;
      }
    }
    if (this.orderItem.priceList && this.orderItem.priceList.length !== 0) {
      this.orderItem.priceList = this.orderItem.priceList.filter(values => values !== undefined);
    }
    var searchdata = {
      pageSize: '',
      pageIndex: '',
      customerId: this.webshopDetail.CustomerID,
    }
    this.warehousehttpservice.getAllWarehouse(searchdata).subscribe((datas: any) => {
      if (datas) {
        this.warehouselist = [];
        this.warehouselist = datas.ItemsCollection;
      }
    })

    if (this.orderItem.priceList !== undefined) {
      if (this.orderItem.priceList.length > 0) {
        this.checkOrderItem(this.orderItem.priceList)
      }

      for (var list of this.orderItem.priceList) {
        if (list.key == 4 && list.strike == true) {
          this.handlingCost = true;
          break;
        }
        else if (list.key == 4 && list.strike == false) {
          this.handlingCost = false;
          break;
        }
        else if (list == false) {
          this.handlingActualCost = true;
        }
      }
    }
    if (!this.orderItem.priceList && this.unAuthorised) {
      //  this.currency = this.orderItem.CurrencyCode;
      this.orderItem.priceList = [...this.getProductPrice(this.orderItem, this.currency),
      ...this.getLogoPrice(this.orderItem.OrderLogos, this.currency, this.orderItem.OrderedQuantity), ...this.getAdditionalCost(this.orderItem.ProductAdditionalCosts, this.currency), ...this.getGovernmentalCost(this.orderItem.ProductGovernmentalCosts, this.currency)

      ];


      if (this.orderItem.OrderItemTransportationCost > 0) {
        this.orderItem.priceList.push(this.getTransportationCost(this.orderItem.OrderItemTransportationCost, this.currency, this.orderItem))

      }
      if (this.orderItem.ShippingFee > 0) {
        this.orderItem.priceList.push(this.getshippingCost(this.orderItem.ShippingFee, this.currency, this.orderItem))

      }

      if (this.orderItem.OrderItemTransportationHandlingCost > 0) {
        this.orderItem.priceList.push(this.getTransportationHandlingCost(this.orderItem.OrderItemTransportationHandlingCost, this.currency));
        if (this.orderItem.AdditionalTransporationCost > 0) {
          this.orderItem.priceList.push(this.getAdditionalTransportationCost(this.orderItem.AdditionalTransporationCost, this.currency, this.orderItem));
        }
        if (this.orderItem.UpdatedTransportationCost >= 0) {
          this.orderItem.priceList.push(this.getUpdatedTransportationCost(this.orderItem.UpdatedTransportationCost, this.currency, this.orderItem))
        }
      }

      if (this.orderItem.AdditionalTransporationCost > 0 && this.orderItem.OrderItemTransportationHandlingCost <= 0) {
        this.orderItem.priceList.push(this.getAdditionalTransportationCost(this.orderItem.AdditionalTransporationCost, this.currency, this.orderItem));
      }
      if (this.orderItem.UpdatedTransportationCost >= 0 && this.orderItem.OrderItemTransportationHandlingCost <= 0) {
        this.orderItem.priceList.push(this.getUpdatedTransportationCost(this.orderItem.UpdatedTransportationCost, this.currency, this.orderItem))
      }

      if (this.orderItem.OrderitemCustomFee > 0) {
        this.orderItem.priceList.push(this.getCustomFee(this.orderItem.OrderitemCustomFee, this.currency, this.orderItem))

      }
      if (this.orderItem.IsPickupatWarehouse) {
        this.orderItem.priceList.push(this.getPickupDeatils(this.orderItem))
      }

      if (!this.orderItem.isTransportationCalculated) {
        this.orderItem.priceList.push(this.transportationNotCalculated())

      }
      this.checkOrderItem(this.orderItem.priceList)
    }
  }

  getUrlParam(paramValue): string {
    return this.route.snapshot.paramMap.get(paramValue);
  }

  checkOrderItem(priceList) {
    let keyData = priceList.filter(x => x.key)
    const selfName = 1;
    const sorter = (a, b) => {
      if (a.key === selfName) {
        return -1;
      };
      if (b.key === selfName) {
        return 1;
      };
      return a.key < b.key ? -1 : 1;
    };
    keyData.sort(sorter);
    priceList = priceList.filter(x => {
      if (x) {
        return !x.key;
      }
    });
    this.orderItem.priceList = priceList.concat(keyData)
  }

  getFunc(priceList) {
    return priceList.some(x => x.key == 2)
  }

  getHandlingCostFunc(priceList) {
    return priceList.some(x => x.key == 4 && x.strike == true)
  }

  getProductPrice(order, currency) {
    let discount = '';
    const mappedPrice = [];
    if (order.DiscountPercentage) {
      discount = `( Discount : ${order.DiscountPercentage} %)`;
    }


    if (order.AdjustedProductPrice > 0) {

      mappedPrice.push({ label: `Product Price `, type: 'primary', unit: order.OriginalProductPrice, currency, total: order.OriginalProductPrice * order.OrderedQuantity, seperator: ':', strike: false });

      const updatedDate = this.datepipe.transform(order.OrderitemUpdateDate, 'dd/MM/yyyy');
      mappedPrice.push({ label: `Adjusted Product Price (${updatedDate})`, type: 'primary', unit: order.AdjustedProductPrice, currency, total: order.AdjustedProductPrice * order.OrderedQuantity, seperator: ':' });
    } else {
      mappedPrice.push({ label: `Product Price ${discount}`, type: 'primary', unit: order.ProductPrice, currency, total: order.ProductPrice * order.OrderedQuantity, seperator: ':', strike: false });
    }
    return mappedPrice;
  }

  getLogoPrice(logos = [], currency, qty) {
    const mappedLogos: any = logos.length ? [this.getDummyPriceList()] : [];
    logos.forEach(logo => {
      mappedLogos.push({ label: `Product Print Cost `, type: 'primary', unit: logo.ProductPrintPrice, currency, total: logo.ProductPrintPrice * qty, seperator: ':' });

      if (logo.ScreenChargesCost > 0) { mappedLogos.push({ label: `Screen Charges Cost  `, type: 'primary', unit: '', currency, total: logo.ScreenChargesCost, seperator: ':' }); }

      if (logo.SetupCost > 0) { mappedLogos.push({ label: `Setup Cost`, type: 'primary', unit: '', currency, total: logo.SetupCost, seperator: ':' }); }

      if (logo.ToolCost > 0) { mappedLogos.push({ label: `Tool Cost`, type: 'primary', unit: '', currency, total: logo.ToolCost, seperator: ':' }); }

      if (logo.ScreenChargesCost > 0) { mappedLogos.push({ label: `Setup Cost`, type: 'primary', unit: '', currency, total: logo.SetupCost, seperator: ':' }); }

      if (logo.TotalHandlingCost > 0) {
        mappedLogos.push({ label: `Handling Cost `, type: 'primary', unit: '', currency, total: logo.TotalHandlingCost, seperator: ':' });
      }

    });
    return mappedLogos;
  }
  getAdditionalCost(additionalCost, currency) {
    const additionalCosts: any = additionalCost.length ? [this.getDummyPriceList(), this.getDummyPriceList('Additional Cost', '', 'primary')] : [];
    additionalCost.forEach(cost => {
      let subCostName = '';
      let unit = 0;
      if (!cost.Price && cost.hasOwnProperty('ProductAdditionalCostOptions')) {
        subCostName = cost.ProductAdditionalCostOptions[0].Name;
        unit = cost.ProductAdditionalCostOptions[0].Price;
      } else {
        unit = cost.Price;
      }
      additionalCosts.push({ label: `${cost.Name} ${subCostName}`, type: 'secondary', unit, currency, total: cost.CurrentOrdPrice, seperator: ':', key: '3' });
    });
    return additionalCosts;
  }
  getGovernmentalCost(governmentalCost, currency) {
    debugger
    if (governmentalCost.length !== 0) {
      // const additionalCosts: any = governmentalCost.length !== 0 ? [this.getDummyPriceList(), this.getDummyPriceList('Governmental Cost', '', 'primary')] : [];
      var additionalCosts: any = [];
      governmentalCost.forEach(cost => {
        additionalCosts.push({ label: `${cost.Name} `, type: 'secondary', unit: cost.Price, currency, total: cost.CurrentOrdPrice, seperator: ':' });
      });
      return additionalCosts;
    } else {
      return [];
    }
  }
  getTransportationCost(OrderItemTransportationCost, currency, order) {
    let transportationText = this.webshopDetail.DomainName == 'wsa' || this.showField || (Number(this.webshopDetail.CustomerID) == 15) || (Number(this.webshopDetail.CustomerID) == 3) ? 'Estimated Transportation Cost' : 'Transportation Cost';
    return { label: transportationText, type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 1 };

  }
  transportationNotCalculated() {
    let transportationText = this.webshopDetail.DomainName == 'wsa' || this.showField || (Number(this.webshopDetail.CustomerID) == 15) || (Number(this.webshopDetail.CustomerID) == 3) ? 'Estimated Transportation Cost' : 'Transportation Cost';
    return { label: transportationText, type: 'primary', transportation: 'false', seperator: ':', key: 1 };

  }


  getPickupDeatils(order) {
    let transportationText: any;
    if (order.IsPickupatWarehouse) {
      transportationText = 'Pick-up at supplier';
      if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
        transportationText = 'Pick-up at warehouse';
      }
      return { label: transportationText, type: 'primary' };
    }
  }
  getUpdatedTransportationCost(UpdatedTransportationCost, currency, order) {
    let updatedDate = this.datepipe.transform(order.OrderitemUpdateDate, 'dd/MM/yyyy')
    let transportationText = 'Updated Transportation Cost' + ' ' + '(' + updatedDate + ') ';
    // if (order.IsPickupatWarehouse) {
    //   transportationText = 'Pick-up at supplier';
    //   if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
    //     transportationText = 'Pick-up at warehouse';
    //   }
    // }
    return { label: transportationText, type: 'primary', unit: UpdatedTransportationCost, currency, total: UpdatedTransportationCost, seperator: ':', key: 2 };
  }


  getAdditionalTransportationCost(AdditionalTransporationCost, currency, order) {
    let transportationText = 'Additional Transportation Cost';
    // if (order.IsPickupatWarehouse) {
    //   transportationText = 'Pick-up at supplier';
    //   if (order.isInternalStockProduct !== undefined && order.isInternalStockProduct !== null) {
    //     transportationText = 'Pick-up at warehouse';
    //   }
    // }
    return { label: transportationText, type: 'primary', unit: AdditionalTransporationCost, currency, total: AdditionalTransporationCost, seperator: ':', key: 3 };
  }
  getTransportationHandlingCost(OrderItemTransportationCost, currency) {
    return { label: 'Handling Cost', type: 'primary', unit: OrderItemTransportationCost, currency, total: OrderItemTransportationCost, seperator: ':', key: 4, strike: false };
  }

  getshippingCost(shippingFee, currency, ordeer) {
    return { label: 'Shipping Fee', type: 'primary', unit: shippingFee, currency, total: shippingFee, seperator: ':', key: 5 };

  }
  getCustomFee(UpdatedCustomFee, currency, order) {
    return { label: 'Calculated Custom Fee', type: 'primary', unit: UpdatedCustomFee, currency, total: UpdatedCustomFee, seperator: ':', key: 6 };

  }
  getTotal(order, currency) {
    return { label: 'Total', type: 'primary', unit: '', currency, total: '', seperator: ':' };
  }

  getDummyPriceList(label = '', seperator = '', type = 'blank') {
    return { label, type, unit: '', currency: '', total: '', seperator };
  }

}



