import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AngularSplitModule } from 'angular-split';
import { WarehouseModule } from './warehouse/warehouse.module';
import { LogisticVendorModule } from './logistic-vendor/logistic-vendor.module';
import { WarehouseHttpService } from './state/services/warehouse.http.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    WarehouseModule,
    LogisticVendorModule,
    AngularSplitModule.forRoot(),
  ],
  providers: [WarehouseHttpService]
})
export class WarehouseManagementModule { }