import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { UserHttpService } from '../state/services/user.http.service';
import { UserService } from '../state/services/user.service';

import {userReducers} from '../state/reducers/user.reducer';
import { UserEffects } from '../state/effects/user.effects';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { HttpHeaders } from '@angular/common/http';
import { ShowlistComponent } from './showlist.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommonFormModule,
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('userModule', userReducers),
    ControlValidationModule,
  ],
  declarations: [ShowlistComponent ],
  providers: [UserHttpService, UserService],
  exports: [ShowlistComponent]
})

export class showListModule { }
