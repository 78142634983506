import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss']
})

export class HeaderContentComponent implements OnInit {

  @Input() header: any;

  @Output() getBackEvent = new EventEmitter<any>();
  @Output() getNextEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  goBack() {
    this.getBackEvent.emit(true);
  }

  goNext() {
    this.getNextEvent.emit(true);
  }

}
