import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageColorSettingsModule } from './manage-color-settings/manage-color-settings.module';
import { BatchManagementModule } from './batch-management/batch-management.module';
import { EnabledisablewebshopSettingsModule } from './enabledisablewebshop-settings/enabledisablewebshop-settings.module';
// import { SettingsHttpService } from './services/settings.http.service';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AngularSplitModule } from 'angular-split';
import { SettingsHttpService } from './services/settings.http.service';
import { EnableopenbookSettingsModule } from './enableopenbook-settings/enableopenbook-settings.module';
import { FooterLinksModule } from './footer-link-settings/footer-link-settings.module';
import { ProductGroupsModule } from './product-groups/product-groups.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ManageColorSettingsModule,
    BatchManagementModule,
    EnabledisablewebshopSettingsModule,
    EnableopenbookSettingsModule,
    FooterLinksModule,
    ProductGroupsModule,
    AngularSplitModule.forRoot(),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [SettingsHttpService]
})
export class SettingsModule { }