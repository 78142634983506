import { Component, OnInit } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { DashboardService } from '../state/services/dashboard.service';
import { debug } from 'util';
import { UserService } from '../../user/state/services/user.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-pendinguser',
  templateUrl: './dashboard-pendinguser.component.html',
  styleUrls: ['./dashboard-pendinguser.component.scss']
})
export class DashboardPendinguserComponent implements OnInit {
  public pendingUser: any = [];
  pendingUseristeQueryParams: any = {};
  tableName: any = 'pendinguserlist';
  showUserEditpage: boolean = true;
  webshopDetail: any;
  tableEvent: any;
  public pendingUserlist: ColumnSetting[] = [];
  constructor(public dashboardservice: DashboardService, public apiService: ApiService, private userService: UserService, private linkTableService: LinkTableService, private router: Router, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

    this.pendingUserlist = [
      {
        primaryKey: 'Name',
        header: this.translate.instant('DASHBOARD.NAME')
      },
      {
        primaryKey: 'UserName',
        header: this.translate.instant('DASHBOARD.USERNAME'),
      },
      {
        primaryKey: 'Roles',
        primaryKeyItem: 'RoleName',
        header: this.translate.instant('DASHBOARD.ROLE'),
        format: 'array'
      },
      {
        primaryKey: 'ProductRoleName',
        header: this.translate.instant('DASHBOARD.PRODUCTROLES')
      },
      {
        primaryKey: 'Email',
        header: this.translate.instant('DASHBOARD.EMAIL')
      },
      {
        primaryKey: 'PhoneNumber',
        header: this.translate.instant('DASHBOARD.PHONENUMBER')
      },
      {
        primaryKey: 'UserCreatedDate',
        header: this.translate.instant('DASHBOARD.SIGNUPDATE'),
        format: 'date'

      },
    ];
    this.onLoadFunctions();
  }


  ngOnInit() {
    this.getDashboardPendingUser();
  }

  onLoadFunctions() {
    this.pendingUseristeQueryParams.customerID = this.webshopDetail.CustomerID;
    this.dashboardservice.loadPendingUser(this.pendingUseristeQueryParams);
  }

  getDashboardPendingUser() {
    this.dashboardservice.getPendingUser().subscribe((data: any) => {
      if (data) {
        data.ItemsCollection.forEach(element => {
          if (element.ProductRoles !== undefined && element.ProductRoles !== null) {
            element.ProductRoleName = ' ';
            if (element.ProductRoles.length > 0) {
              element.ProductRoleName = element.ProductRoles[0].ProductRoleName;
            }
            if (element.VAT === '') {
              element.VAT = ' ';
            }
          }
        });
        this.pendingUser = data.ItemsCollection;
      }
    })
  }

  editUser(userData: boolean) {

    if (userData) {
      this.showUserEditpage = true;
      try {
        this.userService.selectedUser(userData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            data.dashboardRedirectStatus = true;
            this.router.navigate(['/admin-user-edit'], { state: { data }, replaceUrl: false });
          }
        },
          err => {
            console.log(err);
          });
      } catch (error) {
      }
    }

  }

}
