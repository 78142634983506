import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-product-manage-logo-areas',
  templateUrl: './product-manage-logo-areas.component.html',
  styleUrls: ['./product-manage-logo-areas.component.scss']
})
export class ProductManageLogoAreasComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input() loadApi: Boolean
  logoAreaList: FormGroup;
  // parentColor: any;
  code: any;
  name: any;
  optionsSelectParentSize: any = [{
    value: '',
    label: '',
  }];
  allColors: any = [];
  activeLanguage: any;
  showcolorname: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English';
  updatelogoareadesc: any;
  updatewidth: any;
  updateheight: any;
  updatediameter: any;
  updatesurface: any;
  addlogoareadesc: any = "";
  addwidth: any = "";
  addheight: any = "";
  adddiameter: any = "";
  addsurface: any = "";
  loadApiFlag: Boolean = false
  @Input('resetForm') resetForm: Boolean
  @Input('productLogoArea') ParentproductLogoArea: any
  @Input() productDetails: any
  @Output() showManageSize = new EventEmitter<any>();
  @Output() loadlogoAreaDropdown = new EventEmitter<any>();
  productLogoArea: any;
  areaDeleteData: void;
  selfLogoArea: any = [];
  searchArea: string;
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  selectedMaxArea: any = {
    LogoMaxAreaID: '',
    SupplierID: ''
  };
  productLogoPositionList: Array<any> = new Array<any>();
  showLoader: Boolean = false;
  // subscribeMethod: boolean = false;
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public apiService: ApiService,
    public toast: ToastService,
    public translate: TranslateService,
    public productHttpService: ProductHttpService,
  ) {
    translate.setDefaultLang('en');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFilter();
      this.filterReset();
    }

    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.loadAreas();
    }
  }


  ngOnInit() {
    this.loadAreas();
    this.loadForm();
  }


  searchAreaList() {
    // let getResult = [];
    // const getData = this.selfLogoArea;
    // getData.filter((data, index) => {
    //   if (((data.Description).toLowerCase()).includes(this.searchArea.toLowerCase())) {
    //     getResult.push(data)
    //   }
    // });

    // this.productLogoArea = _.uniq(getResult, 'LogoMaxAreaID');
    this.setPageSizeOptions(this.selectedperpage);
  }

  resetFilter() {
    this.searchArea = '';
    this.productLogoArea = this.selfLogoArea;
  }

  resetFormData() {
    this.addlogoareadesc = "";
    this.addwidth = "";
    this.addheight = "";
    this.adddiameter = "";
    this.addsurface = "";
  }

  loadForm() {
    let group: any = {};

    this.productLogoArea.forEach((element, index) => {
      let LogoMaxAreaID = 'LogoMaxAreaID' + index;
      let SupplierID = 'SupplierID' + index;
      let Width = 'Width' + index;
      let Height = 'Height' + index;
      let Diameter = 'Diameter' + index;
      let Surface = 'Surface' + index;
      let Description = 'Description' + index;
      group[SupplierID] = new FormControl(element.SupplierID);
      group[Width] = new FormControl(element.Width);
      group[Height] = new FormControl(element.Height);
      group[Diameter] = new FormControl(element.Diameter);
      group[Surface] = new FormControl(element.Surface);
      group[Description] = new FormControl(element.Description);

    });

    this.logoAreaList = new FormGroup(group);
    this.productLogoArea.forEach(element => {
      this.logoAreaList.get(element.datas.SupplierID).patchValue(element.SupplierID);
      this.logoAreaList.get(element.datas.Width).patchValue(element.Width);
      this.logoAreaList.get(element.datas.Height).patchValue(element.Height);
      this.logoAreaList.get(element.datas.Diameter).patchValue(element.Diameter);
      this.logoAreaList.get(element.datas.Surface).patchValue(element.Surface);
      this.logoAreaList.get(element.datas.Description).patchValue(element.Description);
    });
  }

  loadAreas() {

    this.clearFormItems();
    // const supplierData = {
    //   supplierID: this.productDetails.SupplierID
    // }

    this.searchCriteria = {
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      LogoMaxAreaID: this.selectedMaxArea.LogoMaxAreaID,
      SupplierID: this.productDetails.SupplierID
    };

    // this.productService.loadProductLogoArea(supplierData);
    //this.productHttpService.getProductLogoArea(supplierData).subscribe((data: any) => {
    this.productHttpService.getLogoMaxAreasPagination(this.searchCriteria).subscribe((data: any) => {
      if (data) {
        const formlogoAreaData = [];
        data.ItemsCollection.forEach((element, index) => {
          let group: any = {};
          group.LogoMaxAreaID = 'LogoMaxAreaID' + index;
          group.SupplierID = 'SupplierID' + index;
          group.Width = 'Width' + index;
          group.Height = 'Height' + index;
          group.Diameter = 'Diameter' + index;
          group.Surface = 'Surface' + index;
          group.Description = 'Description' + index;
          formlogoAreaData.push(group)
          data['ItemsCollection'][index]['datas'] = group
        });
        this.selfLogoArea = data.ItemsCollection;
        this.productLogoArea = data.ItemsCollection;
        this.loadForm();


        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      }
    })
  }

  getText(data, type) {
    if (type === 'addlogoareadesc') {
      this.addlogoareadesc = data.target.value;
    } else if (type === 'addwidth') {
      this.addwidth = data.target.value;
    }
    else if (type === 'addheight') {
      this.addheight = data.target.value;
    }
    else if (type === 'adddiameter') {
      this.adddiameter = data.target.value;
    }
    else if (type === 'addsurface') {
      this.addsurface = data.target.value;
    }

  }


  checkAddLogoArea = () => {
    if (this.addlogoareadesc !== "") {
      return false
    }
    return true
  }

  clearFormItems() {
    if (this.logoAreaList !== undefined) {
      const control = <FormArray>this.logoAreaList.controls['items'];
      this.productLogoArea = [];
      // for (let i = control.length - 1; i >= 0; i--) {
      //   control.removeAt(i)
      // }
    }
  }

  addLogoArea() {

    const max = 9999999

    if (!(this.addsurface < max) || !(this.adddiameter < max) || !(this.addwidth < max) || !(this.addheight < max)) {
      if (!(this.addsurface < max)) {
        this.toast.error("Surface Should not be greater than " + max)
      } else if (!(this.adddiameter < max)) {
        this.toast.error("Diameter Should not be greater than " + max)
      } else if (!(this.addwidth < max)) {
        this.toast.error("Width Should not be greater than " + max)
      }
      else if (!(this.addheight < max)) {
        this.toast.error("Height Should not be greater than " + max)
      }
      return null
    }

    if (this.addlogoareadesc !== "") {
      const addLogoAreaData = {
        "SupplierID": this.productDetails.SupplierID,
        "Width": Number(this.addwidth),
        "Height": Number(this.addheight),
        "Diameter": Number(this.adddiameter),
        "Surface": Number(this.addsurface),
        "Description": this.addlogoareadesc
      }
      // this.productService.loadMaxLogoArea(addLogoAreaData);

      this.logoAreaDropDown()
      this.showLoader = true;
      this.productHttpService.addLogoMax(addLogoAreaData).subscribe((data: any) => {
        this.showLoader = false;
        if (data) {
          this.loadAreas();
          this.addlogoareadesc = '';
          this.addwidth = '';
          this.addheight = '';
          this.adddiameter = '';
          this.addsurface = '';


          this.toast.success('Logo area added successfully')
          // this.loadAreas();
          // this.loadForm();
          // this.resetFormData();
        }
        this.logoAreaDropDown()
      },
        err => {
          this.showLoader = false;
          if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
            if (err.error.ModelState.LogoMaxArea !== null && err.error.ModelState.LogoMaxArea !== undefined) {
              this.toast.error(err.error.ModelState.LogoMaxArea);
            }
          }
        })
      this.logoAreaDropDown()
    } else {
      if (this.addlogoareadesc === "") {
        this.toast.error("Please fill Description")
      }
    }

  }

  updateLogoArea(area, index) {
    const updateLogoAreaData = {
      "SupplierID": this.logoAreaList.value[area.datas.SupplierID],
      "Width": this.logoAreaList.value[area.datas.Width],
      "Height": this.logoAreaList.value[area.datas.Height],
      "Diameter": this.logoAreaList.value[area.datas.Diameter],
      "Surface": this.logoAreaList.value[area.datas.Surface],
      "Description": this.logoAreaList.value[area.datas.Description],
      "LogoMaxAreaID": area.LogoMaxAreaID
    }
    // this.productService.loadupdateMaxLogoArea(updateLogoAreaData);
    this.showLoader = true;
    this.productHttpService.updateLogoArea(updateLogoAreaData).subscribe((data: any) => {
      this.showLoader = false;
      if (data !== null && data) {
        this.toast.success('Logo area updated successfully')
        this.logoAreaDropDown()
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.LogoMaxArea !== null && err.error.ModelState.LogoMaxArea !== undefined) {
            this.toast.error(err.error.ModelState.LogoMaxArea);
          }
        }
      })
  }

  deleteLogoArea(data, index) {
    let isToBeDeleted = true


    if (this.ParentproductLogoArea instanceof Array) {
      isToBeDeleted = !(this.ParentproductLogoArea.some(maxArea => {
        if (maxArea.Action === "Delete") return false
        return maxArea.LogoMaxAreaID === data.LogoMaxAreaID
      }))
    }
    if (isToBeDeleted) {
      this.deleteModal.show();
      this.areaDeleteData = data.LogoMaxAreaID;
    } else {
      this.toast.error("This Option already Mapped. Cannot Be Deleted")
    }
  }

  getDeleteStatus(status) {
    if (status) {
      let subscription;
      // this.productService.loaddelMaxLogoArea()
      // if (!this.subscribeMethod) {
      this.showLoader = true;
      this.productHttpService.deleteLogoArea(this.areaDeleteData).subscribe(data => {
        this.showLoader = false;
        if (data && data !== null) {
          this.deleteModal.hide();
          this.toast.success('Logo Area deleted successfully')
          this.logoAreaDropDown()
          this.loadAreas();
          // this.subscribeMethod = true;
        }
      },
        err => {
          this.showLoader = false;
          if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
            if (err.error.ModelState.LogoMaxArea !== null && err.error.ModelState.LogoMaxArea !== undefined) {
              this.toast.error(err.error.ModelState.LogoMaxArea);
            }
          }
        })
      // }
      // if (this.subscribeMethod) {
      //   subscription.unsubscribe();
      // }
    }
    else {
      this.deleteModal.hide();
    }
  }
  logoAreaDropDown() {
    this.loadlogoAreaDropdown.emit()
  }
  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Negative values are not accepted here')
      return false
    }
    else {
      return true
    }
  }


  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Description'];
  }

  autoCompleteLogoAreas(data) {

    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      SupplierID: this.productDetails.SupplierID,
      translations: true
    }

    this.groupCollection = []
    this.productHttpService.logoMaxAreasAutoComplete(request).subscribe((res: any) => {

      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if (res) {
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedMaxArea = selected;
    this.setPageSizeOptions(10);
  }

  onOptionSelected(event) {
    this.selectedMaxArea = event.text
  }

  filterReset() {
    this.searchText = '';
    this.selectedMaxArea.LogoMaxAreaID = '';
    this.selectedMaxArea.SupplierID = '';
    this.textNoResults = '';
    this.groupCollection = [];
    this.loadAreas();
  }

  pageChanged(page) {
    this.selectedpage = page;
    this.loadAreas();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.groupCollection = [];
    this.loadAreas();
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
