import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../../shared/link-table/models/link-table-model';
import { ApiService } from '../../../shared/common-services/api.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MessageboxService } from '../../state/services/messagebox.service';
import { LinkTableService } from '../../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { WebService } from 'src/app/restful-service/web.service';
import { Location } from '@angular/common';
import { MessageInboxService } from '../../message-inbox.service';

@Component({
  selector: 'app-message-claimsdetails',
  templateUrl: './message-claimsdetails.component.html',
  styleUrls: ['./message-claimsdetails.component.scss']
})
export class MessageClaimsdetailsComponent implements OnInit {
  [x: string]: any;
  claimReplyMessageForm = new FormGroup({
    replyMessage: new FormControl()
  });
  customerList: any = [];
  showMessageDetailpage = true;
  customerId: any;
  messageinboxQueryParams: any = {};
  importantdetailsData: any;
  claimdetailsimage: any;

  isReplyTextError = false;
  @Input() emailid: any;
  ClaimID: any;
  claimsdetailData: any;
  mailInfo: any;
  filterFields: any = [];
  webshopDetail: any;
  constructor(public messageboxService: MessageboxService,
    public apiService: ApiService,
    public messageInboxService: MessageInboxService,
    public webService: WebService,
    private linkTableService: LinkTableService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.mailInfo = window.history.state;
    this.ClaimID = this.mailInfo.data.ClaimID;
    this.MessageBoxLineData();
    this.filterFields = [{
      label: 'Delete',
      type: 'button',
      class: 'deleteico',
      key: 'messagedelete',
      model: 'deleteclaims'
    }];
    // alert('pass')
    this.messageInboxService.updateReadUnreadCount.next(true);
  }
  ngOnChanges() {

  }
  loadCustomer() {

    this.messageboxService.getCustomers().subscribe((data: any) => {
      if (data) {
        console.log(data)
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });
      }
    })
  }
  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.customerId = this.webshopDetail.CustomerID;
    this.messageinboxQueryParams.customerID = this.customerId;
    this.messageinboxQueryParams.UserID = loginData.UserID;


    this.messageboxService.loadCustomers(this.messageinboxQueryParams)
    // this.filterParams = {

    // }
  }
  MessageBoxLineData() {
    this.messageinboxQueryParams.ClaimID = this.ClaimID;
    this.messageboxService.loadMessageboxclaimsState(this.messageinboxQueryParams);
    this.messageboxService.getMessageclaimsdetailsData().subscribe((data: any) => {
      if (data) {
        this.claimsdetailData = data[0];
        this.claimdetailsimage = data[0].ClaimFiles[0];
        // const loginData = this.apiService.getLoginData();
        let unreadData = {
          ClaimID: this.claimsdetailData.ClaimID,
          customerid: this.webshopDetail.CustomerID
        }
        this.messageboxService.loadUnreadclaims(unreadData);
        this.messageboxService.claimsunreadMessage().subscribe(data => {
          if (data) {
            this.messageInboxService.updateReadUnreadCount.next(true);
          }
        })

      }

    })
  }
  pageredirect() {
    this.router.navigate(['/app-message-claims'], { replaceUrl: true });
  }

  getRequestBody(val) {
    // const loginData = this.apiService.getLoginData();
    const requestBody = {
      "SenderID": this.mailInfo.data.SenderID,
      "ReceiverID": this.mailInfo.data.ReceiverID,
      "SenderType": "SU",
      "ReceiverType": "g",
      "Subject": this.mailInfo.data.OrderNo,
      "Body": this.claimReplyMessageForm.value.replyMessage,
      "Status": "NEW",
      "EmailType": "Email",
      "Drafts": false,
      "CustomerID": this.mailInfo.data.CustomerID,
    };
    return requestBody;
  }

  sendMessage() {
    if (this.claimReplyMessageForm.controls.replyMessage.value == '' || this.claimReplyMessageForm.controls.replyMessage.value == null) {
      this.isReplyTextError = true;
    } else {
      this.messageboxService.sendMessage(this.getRequestBody('send'));
      this.messageboxService.getSendMessageSuccess().subscribe(data => {
        if (data == null) {
          console.log('data', data);
          this.toast.success('Message successfully sent');
          this.pageredirect();
        }
      });
    }
  }

  onChangeSubject(val) {
    this.isReplyTextError = false;
  }

  deleteClaim(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deleteclaims) {
      console.log(this.ClaimID)
      const data = { claimid: this.ClaimID }
      this.webService.deleteClaim(data).subscribe((data: any) => {
        if (data != null) {
          this.toast.success('Claims deleted successfully');
          this.pageredirect();
        }
      });
    }
  }

}
