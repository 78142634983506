import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/login/state/services/login.service';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Injectable()
export class SettingsHttpService {
  getId: any;
  webshopDetail: any;
  constructor(private apiService: ApiService) { }

  updateWebshopSettings(data: any) {
    const path = 'en/UpdateWebshopdropdownStatus';
    return this.apiService.put(path, data).map((res) => {
      return res;
    });
  }

  updateOpenBookSettings(data: any) {
    const path = 'en/UpdateOpenbookStatus';
    return this.apiService.put(path, data).map((res) => {
      return res;
    });
  }


  getFooterLinksList(data: any) {
    let queryString: any = '';
    queryString = '?customerID=' + data.CustomerID;
    const path = 'all/FooterLinks' + queryString + '&translations=true&PageSize=' + data.PageSize + '&PageIndex=' + data.PageIndex;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  addFooterLinks(data: any) {
    let path: any;
    path = 'all/FooterLinks';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  updateFooterLink(data: any) {
    const path = 'all/FooterLinks';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  deleteFooterLink(data: any) {
    let path: any;
    path = 'all/FooterLink' + '/' + data.id;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }
  chaneOrderFooterLink(data: any) {
    let path: any;
    path = 'all/FooterLink/changePosition';
    return this.apiService.put(path, data).map((res) => {
      return res;
    });
  }
  getSuppliersList(customerID, loggedID) {
    const path = 'en/suppliers?returnOnlyCustomerSupplier=true&CustomerId=' + customerID + '&LoggedInCustomerID=' + loggedID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getWebshopCustomers(customerID) {
    const path = 'en/customers/list?ParentCustomerID=' + customerID + '&returnParentCustomer=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }


  getProductGroupsList(searchCriteria: any) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    let includChildShopGroups = '';
    if (searchCriteria.customerId === '') {
      includChildShopGroups = '&includChildShopGroups=true';
    }
    let request = {
      activePage: searchCriteria.activePage !== undefined ? searchCriteria.activePage : '',

      // tslint:disable-next-line:max-line-length
      customerId: (searchCriteria.customerId !== undefined && searchCriteria.customerId !== '') ? searchCriteria.customerId : this.webshopDetail.CustomerID,
      productName: searchCriteria.productName !== undefined ? searchCriteria.productName : '',
      pageIndex: searchCriteria.pageIndex !== undefined ? searchCriteria.pageIndex : '',
      productManualId: searchCriteria.productManualId !== undefined ? searchCriteria.productManualId : '',
      supplierId: searchCriteria.supplierId !== undefined ? searchCriteria.supplierId : '',
      productGroupName: searchCriteria.groupName !== undefined ? searchCriteria.groupName : ''
    }
    // const path = 'en/productgroups/list/' + request.customerId + '?' +
    //     'pageIndex=' + request.activePage + '&pageSize=' + searchCriteria.pageIndex + '&roleID=' + request.roleId + '&name=' +
    //     request.name + '&username=' + request.username + '&email=' + request.email + '&companyName=' + request.companyName + '&country=' + request.country + '&roleCode=Webshop_Admin&IsRequireUsersApproved=' + request.pendinguserlist + includChildShopUsers;
    const path = 'en/productgroups' + '?' +
      'pageIndex=' + request.activePage + '&pageSize=' + searchCriteria.pageIndex + '&customerID=' + request.customerId + '&supplierID=' + request.supplierId + '&productName=' +
      request.productName + '&productIdManual=' + request.productManualId + '&productGroupName=' + request.productGroupName + includChildShopGroups;
    return this.apiService.get(path).map((res) => {
      return res;
    }, error => {
      return error;
    });
  }

  addProductGroups(data) {
    const path = 'en/productgroups';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  deleteProductGroups(productGroupId: any) {
    const path = 'en/productgroups' + '/' + productGroupId;;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }

  viewProductGroups(productGroupId) {
    const path = `en/productgroups` + '?' + 'ProductGroupID=' + productGroupId
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  searchManualID(request) {
    const path = 'en/productsadminsearch';
    return this.apiService.post(path, request).map((res) => {
      return res;
    });
  }

  addProductGroup(request) {
    const path = `en/productgroups/${request.ProductGroupID}/${request.ProductID}`;
    return this.apiService.post(path).map((res) => {
      return res;
    });
  }

  deleteProduct(request) {
    const path = `en/productgroups/${request.ProductGroupID}/${request.ProductID}`;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }

  productGroup(productRoleID) {
    const path = `en/productgroups/${productRoleID}`;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  updateProductGroup(data) {
    const path = `en/productgroups/${data.ProductGroupID}`;
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }



}
