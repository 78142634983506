import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MDBBootstrapModulePro, MDBBootstrapModule, WavesModule, ButtonsModule } from 'ng-uikit-pro-standard';
import { TranslateModule } from '@ngx-translate/core';
import { ManageLogoTypeComponent } from './manage-logo-type.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    MDBBootstrapModulePro, MDBBootstrapModule, ButtonsModule, WavesModule
  ],
  declarations: [ManageLogoTypeComponent],
  exports: [ManageLogoTypeComponent]
})
export class ManageLogoTypeModule { }
