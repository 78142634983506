import { Action } from '@ngrx/store';
import { cleanSession } from 'selenium-webdriver/safari';
export const LOAD_DASHBOARD_ORDERLIST = '[Dashboard] Load all OrderList';
export const LOAD_DASHBOARD_ORDERLIST_SUCCESS = '[Dashboard] Load OrderList Success';
export const LOAD_DASHBOARD_ORDERLIST_FAIL = '[Dashboard] Load OrderList Fail';

//Message widget Actions
export const LOAD_ORDERSTATE = '[Dashboard] Load all OrderState';
export const LOAD_ORDERSTATE_SUCCESS = '[Dashboard] Load OrderState Success';
export const LOAD_ORDERSTATE_FAIL = '[Dashboard] Load OrderState Fail';

//Stock widget Actions
export const LOAD_STOCK = '[Dashboard] Load all Stock';
export const LOAD_STOCK_SUCCESS = '[Dashboard] Load Stock Success';
export const LOAD_STOCK_FAIL = '[Dashboard] Load Stock Fail';

//OrderFlow widget Actions
export const LOAD_ORDERFLOW = '[Dashboard] Load all Orderflow';
export const LOAD_ORDERFLOW_SUCCESS = '[Dashboard] Load Orderflow Success';
export const LOAD_ORDERFLOW_FAIL = '[Dashboard] Load Orderflow Fail';

//Productionline widget Actions
export const LOAD_PRODUCTIONLINE = '[Dashboard] Load all Productionline';
export const LOAD_PRODUCTIONLINE_SUCCESS = '[Dashboard] Load Productionline Success';
export const LOAD_PRODUCTIONLINE_FAIL = '[Dashboard] Load Productionline Fail';

//Productionline widget Actions
export const LOAD_PRODUCTLIST = '[Dashboard] Load all ProductList';
export const LOAD_PRODUCTLIST_SUCCESS = '[Dashboard] Load ProductList Success';
export const LOAD_PRODUCTLIST_FAIL = '[Dashboard] Load ProductList Fail';

//Message widget Actions
export const LOAD_MESSAGE = '[Dashboard] Load all Message';
export const LOAD_MESSAGE_SUCCESS = '[Dashboard] Load Message Success';
export const LOAD_MESSAGE_FAIL = '[Dashboard] Load Message Fail';

//Message widget Actions
export const LOAD_PENDINGUSER = '[Dashboard] Load all penginuser';
export const LOAD_PENDINGUSER_SUCCESS = '[Dashboard] Load penginuser Success';
export const LOAD_PENDINGUSER_FAIL = '[Dashboard] Load penginuser Fail';

export class LoadDashboardOrderList implements Action {
    readonly type = LOAD_DASHBOARD_ORDERLIST;
    constructor(public payload: any) { }
}

export class LoadDashboardOrderListSuccess implements Action {
    readonly type = LOAD_DASHBOARD_ORDERLIST_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadDashboardOrderListFail implements Action {
    readonly type = LOAD_DASHBOARD_ORDERLIST_FAIL;
    constructor(public payload: any) { }
}

//Order State
//Message
export class LoadOrderState implements Action{
    readonly type = LOAD_ORDERSTATE;
    constructor(public payload: any) { }
}

export class LoadOrderStateSuccess implements Action{
   readonly type = LOAD_ORDERSTATE_SUCCESS;
   constructor(public payload: any) { } 
}

export class LoadOrderStateFail implements Action{
    readonly type = LOAD_ORDERSTATE_FAIL;
    constructor(public payload: any) { }
}

//Stock Alarm
export class LoadStock implements Action{
    readonly type = LOAD_STOCK;
    constructor(public payload: any) { }
}

export class LoadStockSuccess implements Action{
   readonly type = LOAD_STOCK_SUCCESS;
   constructor(public payload: any) { } 
}

export class LoadStockFail implements Action{
    readonly type = LOAD_STOCK_FAIL;
    constructor(public payload: any) { }
}

//Order Flow
export class LoadOrderFlow implements Action{
    readonly type = LOAD_ORDERFLOW;
    constructor(public payload: any) { }
}

export class LoadOrderFlowSuccess implements Action{
   readonly type = LOAD_ORDERFLOW_SUCCESS;
   constructor(public payload: any) { } 
}

export class LoadOrderFlowFail implements Action{
    readonly type = LOAD_ORDERFLOW_FAIL;
    constructor(public payload: any) { }
}

//Production Line
export class LoadProductionLine implements Action{
    readonly type = LOAD_PRODUCTIONLINE;
    constructor(public payload: any) { }
}

export class LoadProductionLineSuccess implements Action{
   readonly type = LOAD_PRODUCTIONLINE_SUCCESS;
   constructor(public payload: any) { } 
}

export class LoadProductionLineFail implements Action{
    readonly type = LOAD_PRODUCTIONLINE_FAIL;
    constructor(public payload: any) { }
}

//Product List
export class LoadProductList implements Action{
    readonly type = LOAD_PRODUCTLIST;
    constructor(public payload: any) { }
}

export class LoadProductListSuccess implements Action{
   readonly type = LOAD_PRODUCTLIST_SUCCESS;
   constructor(public payload: any) { } 
}

export class LoadProductListFail implements Action{
    readonly type = LOAD_PRODUCTLIST_FAIL;
    constructor(public payload: any) { }
}

//Message
export class LoadMessage implements Action{
    readonly type = LOAD_MESSAGE;
    constructor(public payload: any) { }
}

export class LoadMessageSuccess implements Action{
   readonly type = LOAD_MESSAGE_SUCCESS;
   constructor(public payload: any) { } 
}

export class LoadMessageFail implements Action{
    readonly type = LOAD_MESSAGE_FAIL;
    constructor(public payload: any) { }
}

//Pending user list
export class LoadPendingUserList implements Action {
    readonly type = LOAD_PENDINGUSER;
    constructor(public payload: any) { }
}

export class LoadPendingUserSuccess implements Action {
    readonly type = LOAD_PENDINGUSER_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadPendingUserFail implements Action {
    readonly type = LOAD_PENDINGUSER_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | LoadDashboardOrderList
    | LoadDashboardOrderListSuccess
    | LoadDashboardOrderListFail
    | LoadStock
    | LoadStockSuccess
    | LoadStockFail
    | LoadOrderFlow
    | LoadOrderFlowSuccess
    | LoadOrderFlowFail
    | LoadProductionLine
    | LoadProductionLineSuccess
    | LoadProductionLineFail
    | LoadProductList
    | LoadProductListSuccess
    | LoadProductListFail
    | LoadMessage
    | LoadMessageSuccess
    | LoadMessageFail
    | LoadOrderState
    | LoadOrderStateSuccess
    | LoadOrderStateFail
    | LoadPendingUserList 
    | LoadPendingUserSuccess 
    | LoadPendingUserFail;

