import { Component, OnInit } from '@angular/core';
import { MDBModalService, ToastService, MDBModalRef } from 'ng-uikit-pro-standard';
import { OrderHttpService } from 'src/app/order/state/services/order.http.service';
import { OrderModalHttpService } from 'src/app/order/state/services/order.modal.http.service';
import { Subject } from 'rxjs';

interface ModalActionType {
  data: any;
  action: string;
  type: string;
  sendEmail: boolean;
  handlingCost: boolean;
}

@Component({
  selector: 'rewardkart-admin-performa-invoice',
  templateUrl: './performa-invoice.component.html',
  styleUrls: ['./performa-invoice.component.scss']
})
export class PerformaInvoiceComponent implements OnInit {
  type = '';
  method: any;
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  deliveryNoteItems: any[] = [];
  deliveryNotes: any[] = [];
  proformaInvoices: any[] = [];
  captureAmountItems: any[] = [];
  deliveryNoteOrderItemIds = [];
  selectedDeliveryNoteOrderItems = [];
  deliveryNoteOrderItemQuantities = [];
  header: any;
  dispatch: any = [];
  checkedDispatch: any = [];
  handlingtransportCost = false;
  dispatchMail = true;
  createinvoice: any = 'createinvoice';
  dispatchData: any = [];
  invoiceRecipientEmail: any;

  //
  selectedItemCount = 0;
  selectedItems = {};
  // subOrderItems = [];
  incoterms = "";
  awb_no = "";
  constructor(private modalService: MDBModalService, private orderHttpService: OrderHttpService,
    private orderModalHttpService: OrderModalHttpService,
    private toastServcie: ToastService, public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  modalConfirmed() {
    debugger
    switch (this.type) {
      case 'newProformaInvoices':
        let selectedOrderItems = [];
        Object.keys(this.selectedItems).forEach((SuborderID) => {
          // const AWBNo: any = this.selectedItems[SuborderID].AWBNo;
          // delete this.selectedItems[SuborderID]["AWBNo"];
          const orderItemKeys: any = Object.keys(this.selectedItems[SuborderID]);
          let OrderItems = [];
          orderItemKeys.forEach((OrderItemID) => {
            OrderItems.push({ OrderItemID });
          });
          if (orderItemKeys.length > 0) {
            selectedOrderItems.push({ SuborderID, OrderItems });
          }
          for (var items of selectedOrderItems) {
            if (selectedOrderItems.indexOf(items) == 0) {
              items.Incoterms = this.incoterms;
              items.AWBNo = this.awb_no;
            }
          }
        });
        this.modalAction.next({ data: selectedOrderItems, action: 'update', type: this.type, sendEmail: this.dispatchMail, handlingCost: this.handlingtransportCost });
        break;

    }
  }

  handlingTransCost(checkedValues) {
    this.handlingtransportCost = checkedValues.checked;
  }

  checkboxChanged(event, suborderId, orderItemId) {

    if (event) {
      this.selectedItemCount = this.selectedItemCount + 1;
      if (typeof this.selectedItems[suborderId] == 'undefined') {
        this.selectedItems[suborderId] = {};
      }
      this.selectedItems[suborderId][orderItemId] = true;
    } else {
      this.selectedItemCount = this.selectedItemCount - 1;
      delete this.selectedItems[suborderId][orderItemId];
    }

  }

  modalCanceled() {
    this.modalRef.hide();
  }

  // getValueAwbNo(suborderId, awb_no) {
  //   debugger
  //   if (typeof this.selectedItems[suborderId] == 'undefined') {
  //     this.selectedItems[suborderId] = {};
  //   }
  //   this.selectedItems[suborderId]["AWBNo"] = awb_no;
  // }
}
