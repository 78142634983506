import { OrderHttpService } from '../../../state/services/order.http.service';
import { OrderItemModalComponent } from '../../order-item-modal/order-item-modal.component';
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { DeliveryNoteModalComponent } from '../../delivery-note-modal/delivery-note-modal.component';
const FileSaver = require('file-saver');

@Component({
  selector: 'rewardkart-admin-suborder-item-summary',
  templateUrl: './suborder-item-summary.component.html',
  styleUrls: ['./suborder-item-summary.component.scss']
})
export class SuborderItemSummaryComponent implements OnChanges {
  @Output() getOrderList = new EventEmitter<any>();
  @Input() subOrderItem: any;
  @Input() summaryData: any;
  @Input() lineId: any;
  @Input() currency = '';
  showDispatched: Boolean = true;
  showDeliveryNote: boolean = false;
  showDispatchDetailsButton = false;
  showNewNotification: boolean = false;
  showNotification: boolean = false;
  showDispatchDetailsButtonForNonISP = false;
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };

  fileUrl;
  modalRef: MDBModalRef;
  isDialogOpened = false;
  ispCheck = false;
  constructor(
    private router: Router,
    private modalService: MDBModalService,
    private orderHttpService: OrderHttpService,
    private toasterServcie: ToastService) { }


  ngOnChanges() {
    if (this.subOrderItem) {
      this.showDispatched = false;
      this.showDispatchDetailsButton = false;
      this.showDispatchDetailsButtonForNonISP = false;
      this.subOrderItem.OrderItems.map(item => {
        if (item.isInternalStockProduct === true) {
          this.ispCheck = true
        }
        if (item.OrderedQuantity > item.DeliveryNoteQuantity) {
          this.showDeliveryNote = true;
        }
        if (item.isDispatchEnabled) {
          this.showDispatched = true;
        }
        if (item.isDispatchEnabled != undefined && item.isDispatchEnabled == false) {
          this.showNewNotification = true;
        }

        // tslint:disable-next-line:max-line-length
        /** For Task LIN-5923 => SO Button (To hide dispatch button & display Dispatch Detail, Dispatch Detail All button when customer is GN with ISP products) */
        /** Start Line */
        // if (item.isInternalStockProduct && Number(this.summaryData.CustomerID) === 15) {
        //   this.showDispatched = false;
        // }
        // if (item.isInternalStockProduct && Number(this.summaryData.CustomerID) === 15 &&
        //   (item.isDispatchEnabled !== undefined && !item.isDispatchEnabled)) {
        //   this.showDispatchDetailsButton = true;
        // }
        /** End Here */

        // tslint:disable-next-line:max-line-length
        /** For Task LIN-5964 => IS Button (To hide dispatch button & display Dispatch Detail, Dispatch Detail All button when customer is GN with Non-ISP products) */
        /** Start Line */
        // if (!item.isInternalStockProduct && Number(this.summaryData.CustomerID) === 15 && item.isDispatchEnabled) {
        //   this.showDispatched = true;
        // }
        if (!item.isInternalStockProduct && Number(this.summaryData.CustomerID) === 15 &&
          (item.isDispatchEnabled !== undefined && !item.isDispatchEnabled)) {
          // this.showDispatchDetailsButtonForNonISP = true;
        }
        /** End Here */
      });
    }
    if (this.subOrderItem.IsNotificationSent) {
      this.showNotification = true;
    }
  }

  navigateToStatus(subOrderItem: any) {
    const lineId = this.lineId;
    const orderData = {
      'orderID': subOrderItem.OrderID,
      'orderItemID': subOrderItem.OrderItems[0].OrderItemID,
      'isSubOrder': true,
      'suborderID': subOrderItem.SuborderID
    };
    this.router.navigate(['/view-order'],
      {
        state:
        {
          data: orderData,
          customerID: history.state.customerID,
          lineId
        },
        replaceUrl: false
      });
  }

  captureAmountPopup(SuborderId: any) {
    let showProductDetails = false;
    let isSampleOrderItem = false;
    if (this.subOrderItem && this.subOrderItem.OrderItems && this.subOrderItem.OrderItems.length > 0) {
      isSampleOrderItem = this.subOrderItem.OrderItems[0].isSampleOrderItem;
      this.subOrderItem.OrderItems[0].OrderItemStates.forEach((item, i) => {
        if (item.StateCode === 'USER_PAID' && item.Properties.StateOptionCode === 'NOT_PAID') {
          showProductDetails = true;
        }
      });
    }
    const OrderID = this.subOrderItem.OrderID;
    const data = { OrderID, issampleOrder: isSampleOrderItem };
    this.orderHttpService.getCaptureSuborderAmount(SuborderId, data).subscribe((captureamount: any) => {
      const modalData = {
        type: 'captureAmount',
        header: `Capture Amount`,
        captureAmountItems: { ...captureamount, CurrencyCode: this.currency },
        showProductDetails,
      }; OrderItemModalComponent
      this.modalRef = this.modalService.show(OrderItemModalComponent, {
        ...this.modalOptions, data: modalData
      });

      this.modalRef.content.modalAction.subscribe((result: any) => {
        const senddata = {
          OrderID,
          TXNID: this.summaryData.ePayTxnID,
          Amount: captureamount.SuborderAmountWithTransportationCostAndVAT,
          currencyCode: this.currency,
          Email: this.summaryData.Email,
        };
        this.orderHttpService.captureSuborderAmount(SuborderId, senddata).subscribe((response: any) => {
          if (response) {
            this.modalRef.hide();
            this.toasterServcie.success(response);
          } else {
            this.toasterServcie.error('unable to capture the amount');
          }
        }, (error: any) => {
          if (error.error) {
            this.toasterServcie.error(error.error);
          } else {
            this.toasterServcie.error('unable to capture the amount');
          }
          this.modalRef.hide();
        });
      });
    }, (apiError: any) => {
      this.toasterServcie.error(apiError.error);
    });
  }

  handleOrderAction(event, subOrderItemId = null) {
    // this.selectedOrderItem = { ...event.data };
    switch (event) {
      case 'dispatch':
        this.dispatchPopup(event);
        break;
      case 'dispatchAll':
        this.dispatchAllPopup(event);
        break;
      /** For Task LIN-5923 => SO Button (Implement Dispatch Detail, Dispatch Detail All button when customer is GN with ISP products) */
      /** For Task LIN-5964 => IS Button (Implement Dispatch Detail, Dispatch Detail All button when customer is GN with ISP products) */
      case 'dispatchDetail':
        this.dispatchPopup(event, 'Dispatch Detail');
        break;
      case 'dispatchAllDetail':
        this.dispatchAllPopup(event, 'Dispatch All Detail');
        break;
      case 'deliveryNote':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.newDeliveryNotePopup(event, subOrderItemId);
        }
      case 'newNotification':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.newNotification(event, subOrderItemId);
        }
      case 'deliveryDate':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.subDeliveryDate(event, 'SUBORDER DELIVERY DATE')
          break;
        }
    }
  }

  subDeliveryDate(type, title) {
    const modalData = {
      type: type,
      header: title,
      dispatch: this.subOrderItem,
      method: 'deliveryDate'
    }
    const orderListItem = this.subOrderItem.OrderItems.reduce((previous, current) => {
      previous.push(current.OrderItemID);
      return previous
    }, [])
    const payload = {
      ListOfID: orderListItem
    }
    this.modalRef = this.modalService.show(OrderItemModalComponent, {
      ...this.modalOptions, data: modalData
    })
    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.modalRef.hide();
      this.getOrderList.emit(true);
      this.orderHttpService.deliveryDateUpdateISO(result.data, payload).subscribe((response) => {
        if (response === true) {
          this.toasterServcie.success('Delivery Date Updated Successfully')
          this.getOrderList.emit(this.subOrderItem.OrderID);
          this.isDialogOpened = false;
        } else {
          this.toasterServcie.error('Delivery Date not Updated')
        }
      })
    });

    this.modalService.closed.subscribe(() => {
      this.isDialogOpened = false;
    });

  }

  dispatchPopup(type, title = null) {
    // this.getOrderList.emit(this.subOrderItem.OrderID);
    const modalData = {
      type: (type === 'dispatch' || type === 'dispatchDetail') ? 'dispatch' : 'dispatchAll',
      header: (title !== null) ? title : 'Dispatch Orders',
      dispatch: this.subOrderItem,
      method: 'dispatchItems',
      invoiceRecipientEmail: this.summaryData.EmailForInvoices === this.summaryData.Email ? this.summaryData.EmailForInvoices : this.summaryData.Email,
      generalEmail: this.summaryData.DeliveryEmail,
      // tslint:disable-next-line:max-line-length
      dispatchDetailStatus: (title !== null) ? true : false, /** For Task LIN-5923 => SO Button (Submit button in Dispatch Popup screen when customer is GN with ISP products & order is dispatched by automatic(thorugh warehouse)) */
      // tslint:disable-next-line:max-line-length
      /** For Task LIN-5964 => IS Button (Submit button in Dispatch Popup screen when customer is GN with Non-ISP products & order is dispatched by automatic(thorugh warehouse)) */
    };
    this.modalRef = this.modalService.show(OrderItemModalComponent, {
      ...this.modalOptions, data: modalData
    });
    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.getOrderList.emit(result.data);
    });
    this.modalService.closed.subscribe(result => {
      this.isDialogOpened = false;
      this.getOrderList.emit(this.subOrderItem.OrderID);
      return false;
    });
  }

  dispatchAllPopup(type, title = null) {
    this.dispatchPopup(type, title);
  }



  downloadShipmentImage(image, subordernum) {
    FileSaver.saveAs(image, subordernum);
  }

  newDeliveryNotePopup(data, subOrderItemId = null) {
    this.orderHttpService.getDeliveryNoteOrderItems(this.summaryData.OrderID, subOrderItemId)
      .subscribe((deliveryNoteData: any) => {
        if (deliveryNoteData) {
          let newDeliveryNoteOrderItemIds = [];
          let newDeliveryNoteOrderItemQuantities = [];
          let selectedDeliveryNoteOrderItems = [];
          deliveryNoteData.forEach(item => {
            newDeliveryNoteOrderItemIds[item.OrderItemID] = true;
            newDeliveryNoteOrderItemQuantities[item.OrderItemID] = item.OrderedQuantity;
          });
          const modalData = {
            type: 'newDeliveryNote',
            header: `New Delivery Note `,
            newDeliveryNoteItems: deliveryNoteData,
            newDeliveryNoteOrderItemIds: newDeliveryNoteOrderItemIds,
            newDeliveryNoteOrderItemQuantities: newDeliveryNoteOrderItemQuantities,
            newSelectedDeliveryNoteOrderItems: selectedDeliveryNoteOrderItems,
            isSubOrderItem: true
          };
          this.modalRef = this.modalService.show(DeliveryNoteModalComponent, {
            ...this.modalOptions, data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
          });

          this.modalRef.content.modalAction.subscribe((result: any) => {
            const { data } = result;
            this.orderHttpService.createDeliveryNote(data).subscribe(response => {
              if (response) {
                this.orderHttpService.downloadDeliveryNote(response);
                this.modalRef.hide();
                this.isDialogOpened = false;
                this.getOrderList.emit(this.summaryData.OrderID);
              } else {
                this.toasterServcie.error('Error while creating delivery note.');
              }
            }, error => {
              this.toasterServcie.error('Error while creating delivery note.');
            });
          });
        }
      }, error => {
        this.toasterServcie.error('Error while getting delivery note order items');
        this.isDialogOpened = false;
      });
  }

  // ** New code to send delivery notification and get notifications

  newNotification(event, SuborderId: any) {
    const modalData = {
      type: 'newNotification',
      header: `New Notification`,
    };
    this.modalRef = this.modalService.show(OrderItemModalComponent, {
      ...this.modalOptions, data: modalData
    });

    this.modalService.closed.subscribe(result => {
      this.isDialogOpened = false;
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      const data = {
        'SuborderId': SuborderId,
        'NotificationMessage': result.data
      };
      if (result.data == "" || result.data == null) {
        this.toasterServcie.error('Please enter notification message');
        return;
      }
      this.orderHttpService.postnewNotification(data).subscribe((response: any) => {
        if (response) {
          this.getOrderList.emit(this.subOrderItem.OrderID);
          this.modalRef.hide();
          this.isDialogOpened = false;
          this.toasterServcie.success("Notification sent successfully");
        } else {
          this.isDialogOpened = false;
          this.toasterServcie.error('Error while sending notification');
        }
      }, (error: any) => {
        if (error.error) {
          this.isDialogOpened = false;
          this.toasterServcie.error(error.error);
        } else {
          this.isDialogOpened = false;
          this.toasterServcie.error('Error while sending notification');
        }
        this.modalRef.hide();
      });
    });
  }

  notifications(SuborderId: any) {
    this.orderHttpService.getNotifications(SuborderId).subscribe((newNotification: any) => {
      const modalData = {
        type: 'Notification',
        header: `Notifications`,
        notificationItems: [...newNotification],
      };
      this.modalRef = this.modalService.show(OrderItemModalComponent, {
        ...this.modalOptions, data: modalData
      });
    }, (apiError: any) => {
      this.toasterServcie.error(apiError.error);
    });
  }

}
