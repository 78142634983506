import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/state/services/user.service';
import { BillboardHttpService } from '../../billboard/state/services/billboard.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
@Component({
  selector: 'rewardkart-admin-billboard-edit',
  templateUrl: './billboard-edit.component.html',
  styleUrls: ['./billboard-edit.component.scss']
})
export class CampaignBillboardEditComponent implements OnInit {
  billBoardFormGroup: FormGroup;
  formSubmitted = false;
  campaignID: any;
  billBoardDefaultValue = {
    ImageName: '',
    LanguageID: 0,
    AdURL: 'rewardkartshop.in',
    VisibleOnLinkNetwork: true,
    newsletter: false
  };

  billBoardImageUrl = '';
  defaultLanguage = { label: 'Billboard visible on all Languages', value: 0 };
  saveButtonLabel = 'Save';
  backButtonLabel = "Cancel";
  languages = [];
  isProductSearchVisible = false;

  productRoles = [];

  billboardId: number = null;

  searchResult = [];
  newsLetterURL: any;
  billboardImageURL: any;
  campaignName: any;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public toaster: ToastService,
    private billboardHttpService: BillboardHttpService
  ) {

    /** Static URL for Newsletter with Dynamic domain set based on Dev, Qatest, Staging, Prod Server */
    // let hostname = 'conxion.linkpartner-staging.dk';  //for local
    const hostname = window.location.hostname; // for deploy 
    let baseURL = '';
    if (String(hostname) !== '') {
      if (hostname.search('dev') !== -1 || hostname.search('qatest') !== -1 || hostname.search('staging') !== -1) {
        baseURL = hostname.replace('linkpartner', 'linknetwork');
      } else {
        const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        baseURL = webshopDetail.Name.toLowerCase() + '.linkwebshop.com';
      }
      this.newsLetterURL = window.location.protocol + '//' + baseURL + '/#newsletter';
    }
  }

  ngOnInit() {
    this.createForm();
    this.getLanguages();
    const id = history.state.data.id;
    this.campaignID = history.state.data.campaignid;
    this.campaignName = history.state.data.name;
    if (id) {
      this.saveButtonLabel = 'Update';
      this.backButtonLabel = "Back";
      this.billboardId = id;
      this.billboardImageURL = history.state.data.image
      this.getBillboard(id);
    } else {
    }
  }

  getBillboard(id) {
    this.billboardHttpService.getBillboard(id).subscribe((billboardItemRes: any) => {
      if (billboardItemRes) {
        this.productRoles = billboardItemRes.ProductRoles;
        this.createForm(billboardItemRes);

        this.billboardImageURL = billboardItemRes.ImageURL;
      }
    });
  }

  createForm(billBoardItem = this.billBoardDefaultValue) {
    this.billBoardFormGroup = this.formBuilder.group({
      ImageName: [billBoardItem.ImageName, Validators.required],
      AdURL: [billBoardItem.AdURL || 'rewardkartshop.in'],
      LanguageID: [billBoardItem.LanguageID || 0, Validators.required],
      VisibleOnLinkNetwork: [billBoardItem.VisibleOnLinkNetwork],
      newsletter: [(billBoardItem.AdURL === this.newsLetterURL) ? true : false]
    });
  }

  goBack() {
    let data = { campaignid: this.campaignID, name: this.campaignName, icon: history.state.data.icon, status: history.state.data.status }
    let icon = history.state.data.icon;
    let status = history.state.data.status;
    if (this.backButtonLabel === 'Back') {
      this.router.navigate(['/admin-campaign-billboard-list'], { state: { data }, replaceUrl: false });
    } else {
      let id = this.campaignID;
      this.router.navigate(['/admin-campaign-edit'], { state: { id, icon, status }, replaceUrl: false });
    }
  }

  getLanguages() {
    this.userService.loadLanguages();

    this.userService.getAllLanguages().subscribe(
      languageResponse => {
        if (languageResponse) {
          this.languages = [
            this.defaultLanguage,
            ...languageResponse.map(language => ({
              value: language.LanguageID,
              label: language.Name
            }))
          ];
        }
      },
      error => {
        console.log('unable to get language');
      }
    );
  }

  toggleProductSearchVisibility() {
    this.isProductSearchVisible = !this.isProductSearchVisible;
  }
  visibilityChange(event) {
    console.log(event, 'changeevent');
  }

  validateForm() {
    if (this.billBoardFormGroup.valid) {
      const formValue = this.billBoardFormGroup.value;
      if (formValue.LanguageID === 0) {
        delete formValue.LanguageID;
      }
      this.submitForm(formValue);
    } else {
      this.formSubmitted = true;
    }
  }

  submitForm(formValue) {
    if (this.billboardId) {
      this.billboardHttpService
        .updateBillboard(this.billboardId, formValue)
        .subscribe(
          editResponse => {
            this.goBack();
          },
          error => {
            console.log('unable to edit billboard', error);
          }
        );
    } else {
      this.billboardHttpService.addBillboardCampaign([formValue], this.campaignID).subscribe(
        addResponse => {
          this.goBack();
        },
        error => {
          this.toaster.error(error.error.Message);
        }
      );
    }
  }

  handleUploadedImage(event) {
    const { data, error } = event;
    if (error) {
      console.log('unable to upload image', event.error);
    } else {
      this.billBoardFormGroup.controls.ImageName.setValue(data[1] || '');
    }
  }

  handleProductSelection(product) {
    const hostname = window.location.origin; // for deploy
    if (product) {
      const { ProductID, Name } = product;
      const linkUrl = `${hostname}/product${ProductID}/${Name}`;
      this.billBoardFormGroup.controls.AdURL.setValue(linkUrl);
    }
  }

  setAdURL() {
    if (this.billBoardFormGroup.value.newsletter) {
      this.billBoardFormGroup.controls.AdURL.setValue(this.newsLetterURL);
    } else {
      if (this.billboardImageURL === this.billBoardFormGroup.value.AdURL) {
        this.billBoardFormGroup.controls.AdURL.setValue(this.billBoardDefaultValue.AdURL);
      } else {
        this.billBoardFormGroup.controls.AdURL.setValue(this.billboardImageURL);
      }
    }
  }
}
