import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';
import { SupplierAddComponent } from './supplier-add.component';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierHttpService } from '../state/services/supplier.http.service';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';



@NgModule({

  declarations: [SupplierAddComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ControlValidationModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [SupplierHttpService],
})
export class SupplierAddModule { }
