import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductSizeComponent } from './product-sizes.component';
import { ProductManageSizeModule } from './product-manage-sizes/product-manage-sizes.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomdirectiveModule } from '../customdirective/customdirective.module';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    ProductManageSizeModule,
    SharedModule,
    TranslateModule,
    CustomdirectiveModule,
    DropdownPopupModule
  ],
  declarations: [ProductSizeComponent],
  exports: [ProductSizeComponent]
})
export class ProductSizesModule { }

