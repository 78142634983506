import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import {NgxPrintModule} from 'ngx-print';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { MessageInquiriesComponent } from './message-inquiries.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessageenquiriesdetailsModule } from './message-inquiredetails/message-inquiriesdetails.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    MessageenquiriesdetailsModule,
    NgxPrintModule
  ],
  declarations: [MessageInquiriesComponent],
  exports: [MessageInquiriesComponent]
})

export class MessageenquiriesModule { }
