import { Response } from '@angular/http';
import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild, SimpleChanges } from '@angular/core';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import * as _ from 'lodash';
import { debug } from 'util';

@Component({
  selector: 'app-product-manage-properties',
  templateUrl: './product-manage-properties.component.html',
  styleUrls: ['./product-manage-properties.component.scss']
})
export class ProductManagePropertiesComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('deleteModal1', { static: true }) deleteModal1: ModalDirective;
  @Input() productDetails: any;
  subscribeMethod: boolean = false;
  propertyOptionEn: any = '';
  propertyOptionDk: any = '';
  propertyOptionRm: any = '';
  manageProperties = '';
  getLang: any;
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  optionsSelectLanguage: any = [];
  optionToBeDeleted: any = [];
  selfManagepropertiesData: any;
  languageOption: any = [];
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  @Output() showManageProperties = new EventEmitter<any>();
  @Input() productProperties: any;
  @Input() loadApi: Boolean
  propertyList: any;
  @Input('resetForm') resetForm: Boolean
  @ViewChild('editproperty', { static: true }) editproperty: ModalDirective;
  propertyOptionNameList: any = [];
  activeLanguage: any = 'english';
  selectedPropertyForEdit: any = {
    ProductOptions: [],
    Translations: [{ PropertyValue: '' }, { PropertyValue: '' }, { PropertyValue: '' }]
  };
  selectedPropertyForDelete: any;
  showEditProperty: boolean;
  selectedLang = 1;
  newOption: any;
  test: any;
  deletedid: any;
  loadApiFlag: Boolean = false;
  hideToater: boolean;
  deleteindex: any;
  searchProperty: any;
  @Output() loadPropertyDropdown = new EventEmitter<any>();
  selfPropertyList: any = [];
  searchCriteria: any = {};
  selectedProperty: any = {
    ProductPropertyID: '',
    SupplierID: ''
  };
  showLoader: Boolean = false;
  webshopDetail: any;
  selectedLanguageId: number = 1;

  constructor(
    public toast: ToastService,
    public productService: ProductService,
    public apiService: ApiService,
    public translate: TranslateService,
    public productHttpService: ProductHttpService
  ) {

    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

    translate.setDefaultLang('en');
    this.getLang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.languageOption = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      // this.resetFilter()
      this.filterReset()
    }

    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.getProductProperties()
    }
  }

  ngOnInit() {

    this.loadForm();
  }



  resetFilter() {
    this.searchProperty = '';
    this.propertyList = this.selfPropertyList;
  }

  loadForm() {
    this.optionsSelectLanguage = [];
    this.propertyOptionNameList = [];
    this.getLang.forEach((data) => {
      const translation = {
        formControlName: 'propertyOption' + data.Code,
        inputName: data.Name,
        languageID: data.LanguageID,
        code: data.Code,
        lang: data.Lang,
        translationID: 'translationID' + data.Code,
        objectID: 'objectID' + data.Code
      }
      const options = { value: data.LanguageID, label: data.Name, name: data.Name }

      this.optionsSelectLanguage.push(options);
      this.propertyOptionNameList.push(translation)
    });
    let data = {};
    this.propertyOptionNameList.forEach((item) => {
      data[item.formControlName] = ''
    })

    this.newOption = data;
  }

  loadParentDropDown() {
    this.loadPropertyDropdown.emit()
  }

  toggleDynamicField(activeclass) {
    this.selectedLang = activeclass.value
    this.selectedLanguageId = activeclass.value;

  }

  addProperty() {
    this.showEditProperty = true;
    this.selectedPropertyForEdit = {
      SupplierID: this.productDetails.SupplierID,
      Action: "Insert",
      ProductOptions: [],
      Translations: []
    }
    const translation = {}
    this.propertyOptionNameList.forEach(val => {
      translation[val.formControlName] = "";
      translation[val.translationID] = "";
      translation[val.objectID] = "";
      this.newOption[val.formControlName] = "";
    });
    this.selectedPropertyForEdit.Translations.push(translation);
    this.loadParentDropDown();
  }
  addProductOption() {
    if (this.newOption.propertyOptionen !== '') {
      const data = {
        Action: "Insert",
      }
      this.propertyOptionNameList.forEach(list => {
        data[list.formControlName] = this.newOption[list.formControlName];
        data[list.translationID] = "";
        data[list.objectID] = "";
        this.newOption[list.formControlName] = '';
      })

      if (data['propertyOptionen'] !== '' && data['propertyOptionen'] !== undefined) {
        let filter = this.selectedPropertyForEdit.ProductOptions.filter((element) => element.propertyOptionen === data['propertyOptionen']);
        if (filter.length > 0) {
          this.toast.error('Option should not be duplicate');
          return false;
        }
        this.selectedPropertyForEdit.ProductOptions.push(data);
      }
      else {
        this.toast.error('Please fill the option for english');
      }

    }
    else {
      this.toast.error('Please fill the option for english');
    }
  }

  editProperty(productProperty) {

    this.showEditProperty = true;
    let productOptions = [];
    productProperty.ProductOptions.forEach(data => {
      const item = {
        productOptionID: data.ProductOptionID,
      }
      this.propertyOptionNameList.forEach(val => {
        const isDataFound = data.Translations.find(list => list.LanguageID == val.languageID)
        if (isDataFound) {
          item[val.formControlName] = isDataFound.PropertyValue,
            item[val.translationID] = isDataFound.TranslationID,
            item[val.objectID] = isDataFound.ObjectID
        }
      })

      productOptions.push(item);
    })

    let translations = [];
    let list = {};
    this.propertyOptionNameList.forEach(val => {
      const isDataFound = productProperty.Translations.find(list => list.LanguageID == val.languageID)
      if (isDataFound) {
        list[val.formControlName] = isDataFound.PropertyValue,
          list[val.translationID] = isDataFound.TranslationID,
          list[val.objectID] = isDataFound.ObjectID
      }
    });
    translations.push(list);

    this.selectedPropertyForEdit = {
      ProductPropertyID: productProperty.ProductPropertyID,
      SupplierID: productProperty.SupplierID,
      ProductOptions: productOptions,
      Translations: translations,
      Action: "Update"
    };



  }
  deleteProperty(id, index) {
    this.deleteModal.show();
    this.deletedid = id
  }
  deletePropertyOption(id, index) {
    this.deleteindex = index
    // if (confirm("Are you want to delete this option ?")) {
    //   this.selectedPropertyForEdit.ProductOptions.splice(index, 1);
    // } else {
    //   return null
    // }
    this.deleteModal1.show();
  }
  getDeleteStatuss(status) {
    if (status) {
      if (this.selectedPropertyForEdit.ProductOptions[this.deleteindex]['Action'] === 'Insert') {
        this.selectedPropertyForEdit.ProductOptions.splice(this.deleteindex, 1);
        this.deleteModal1.hide();
      }
      else {
        this.selectedPropertyForEdit.ProductOptions[this.deleteindex]['Action'] = 'Delete';
        this.getProductProperties();
        this.toast.success(`Deleted Successfully`);
        this.deleteModal1.hide();
        this.loadParentDropDown();
      }
    } else {
      this.deleteModal1.hide();
    }
  }

  getDeleteStatus(status) {
    if (status) {
      // this.subscribeMethod = false;
      // this.productService.deleteProductProperties(this.deletedid);

      this.productHttpService.deleteProductProperty(this.deletedid).subscribe((data) => {
        if (data && data !== null) {
          this.getProductProperties();
          this.toast.success(`Deleted Successfully`);
          this.deleteModal.hide();
          // this.subscribeMethod = true;
          this.loadParentDropDown();
        }
      });
    } else {
      this.deleteModal.hide();
    }
  }

  getProductProperties() {
    // this.productService.loadProductProperties();
    this.searchCriteria = {
      translations: true,
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      ProductPropertyID: this.selectedProperty.ProductPropertyID,
      SupplierID: this.productDetails.SupplierID
    };
    this.productHttpService.getmanagePropertiesPagination(this.searchCriteria).subscribe((data: any) => {
      this.loadForm();
      this.propertyList = data.ItemsCollection;
      this.totalCount = data.TotalItemsCount;
      this.selfPropertyList = data.ItemsCollection;
      this.selfManagepropertiesData = data.ItemsCollection;
      // data.ItemsCollection.forEach(element => {
      //   this.propertyList.push(element)
      // });
      let getPageList = data.TotalItemsCount / this.selectedperpage;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = []
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;
      this.loadParentDropDown();
    });
  }



  pageChanged(page) {
    this.selectedpage = page;
    // this.loadPagination();
    this.getProductProperties();
    // this.pageList = page;
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    // this.loadPagination();
    this.getProductProperties();
    // this.rowPerPage=perpage;
  }
  loadPagination() {
    if (this.selfManagepropertiesData !== undefined) {
      let getPageList = this.selfManagepropertiesData.ItemsCollection.length / this.selectedperpage;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = []
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;

      const getArray = [];
      const startArray = this.selectedpage * this.selectedperpage - this.selectedperpage;
      const endArray = this.selectedpage * this.selectedperpage;
      this.selfManagepropertiesData.slice([startArray], [endArray]).map((item, i) => {
        getArray.push(item)
      });
      this.languageOption = getArray;

    }
  }


  async savePropertyOption() {
    this.hideToater = true;
    const getPropertyName = this.selectedPropertyForEdit.Translations.filter(element => element.propertyOptionen === '');
    if (getPropertyName.length > 0) {
      this.toast.error("Property Name is required for English");
      return null
    }

    if (this.selectedPropertyForEdit.ProductOptions.length > 0) {
      const getOptions = this.selectedPropertyForEdit.ProductOptions.filter(ele => ele.propertyOptionen === '');
      if (getOptions.length > 0) {
        this.toast.error(`Please add option for English`);
        return null
      }
    }
    else {
      this.toast.error(`At least one option is required.`);
      return null
    }



    let requestData = {
      ProductOptions: [],
      Translations: []
    };


    this.selectedPropertyForEdit.ProductOptions.forEach(item => {
      let productOptions = {
        Translations: []
      };
      productOptions['ProductOptionID'] = item.productOptionID;

      this.propertyOptionNameList.forEach(val => {
        if (item.Action) {
          productOptions['Action'] = item.Action;
        }
        const list = {
          PropertyValue: item[val.formControlName],
          LanguageID: val.languageID,
          LanguageCode: val.code,
          ObjectType: "ProductOptions",
          PropertyName: "OptionName",
          Action: item.Action ? item.Action : "Update",
          TranslationID: item[val.translationID] || null,
          ObjectID: item[val.objectID] || null
        }
        // if (this.selectedPropertyForEdit.Action) {
        //   list['Action'] = this.selectedPropertyForEdit.Action;
        // }
        // if (item[val.translationID]) {
        //   list['TranslationID'] = item[val.translationID];
        // }
        // if (item[val.objectID]) {
        //   list['ObjectID'] = item[val.objectID];
        // }
        productOptions.Translations.push(list);
      });
      requestData.ProductOptions.push(productOptions);
    })

    this.selectedPropertyForEdit.Translations.forEach(item => {
      let translations = []
      this.propertyOptionNameList.forEach(val => {
        let list = {
          PropertyValue: item[val.formControlName],
          LanguageID: val.languageID,
          LanguageCode: val.code,
          ObjectType: "ProductProperties",
          PropertyName: "PropertyName",
          Action: "Update",
        };
        // if(list.PropertyValue === ''){
        //   this.toast.error("The PropertyName field is required for English language");
        // }
        if (this.selectedPropertyForEdit.Action) {
          list['Action'] = this.selectedPropertyForEdit.Action;
        }
        if (item[val.translationID]) {
          list['TranslationID'] = item[val.translationID];
        }
        if (item[val.objectID]) {
          list['ObjectID'] = item[val.objectID];
        }
        translations.push(list);
      });
      requestData.Translations.push(...translations);
    })
    if (this.selectedPropertyForEdit.ProductPropertyID) {
      requestData['ProductPropertyID'] = this.selectedPropertyForEdit.ProductPropertyID;
    }
    requestData['SupplierID'] = this.selectedPropertyForEdit.SupplierID;
    // alert(this.selectedPropertyForEdit.Action)
    if (this.selectedPropertyForEdit.Action) {
      requestData['Action'] = this.selectedPropertyForEdit.Action;
    }
    // this.productService.addProductProperties(requestData);
    this.showLoader = true;
    this.productHttpService.addProductProperty(requestData).subscribe((data: any) => {
      this.showLoader = false;
      if (data != null) {
        this.getProductProperties();
        this.loadParentDropDown();
        this.editproperty.hide()
        // if()
        // if (this.selectedPropertyForEdit.Translations[0].propertyOptionen === '' && this.selectedPropertyForEdit.Translations[0].propertyOptionen === undefined) {
        //   this.toast.error("The PropertyName field is required for English language");
        // }
        if (this.hideToater) {
          this.toast.success(data);
          this.hideToater = false
        }
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.productProperty !== null && err.error.ModelState.productProperty !== undefined) {
            this.toast.error(err.error.ModelState.productProperty);
          }
        }
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.ProductOptions !== null && err.error.ModelState.ProductOptions !== undefined) {
            this.toast.error(err.error.ModelState.ProductOptions);
          }
        }
      });

  }

  closeModal() {
    this.showManageProperties.emit(false);
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }


  autoCompletelogo(data) {
    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      translations: true,
      name: data,
      SupplierID: this.productDetails.SupplierID
    }

    this.groupCollection = [];
    this.productHttpService.autoCompleteManageproperties(request).subscribe((res: any) => {
      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if (res) {
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedProperty = selected;
    this.setPageSizeOptions(10);
  }

  onOptionSelected(event) {
    this.selectedProperty = event.text
  }

  searchProperties() {
    this.setPageSizeOptions(this.selectedperpage);
  }





  filterReset() {
    this.searchText = '';
    this.selectedProperty.ProductPropertyID = '';
    this.selectedProperty.SupplierID = '';
    this.textNoResults = '';
    this.groupCollection = [];
    this.getProductProperties();
  }

}

