import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ProductService } from '../../state/services/product.service';
import { LoginService } from 'src/app/login/state/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, MDBDatePickerComponent, ToastService } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../../state/services/product.http.service';
import { element } from 'protractor';
import { DropdownpopupComponent } from 'src/app/common/dropdownpopup/dropdownpopup.component';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-category-info',
  templateUrl: './product-category-info.component.html',
  styleUrls: ['./product-category-info.component.scss']
})
export class ProductCategoryInfoComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  productCategoryInfo: FormGroup;
  optionsSelectCategory: any = [];
  model: any;
  productColors: any = [];
  selectedColor: any;
  @Input() productDetails: any;
  @Input() catloadStatus: any;
  @Input() loadApi: any;
  @Output() sendProductCategoryDetails = new EventEmitter<any>();
  showManageColor: boolean;
  items: FormArray;
  selectedData: any;
  categoryData: any;
  customerID: any;
  customerInfo: any;
  categoryInfo: any;
  optionsSelectCostomer: any[];
  optionsSelectSubcategories: any[];
  deleteData: any = {};
  categoryDataList: any;
  Roles: any;
  Tags: any;
  showSeparator: boolean;
  setSeparate: boolean;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selfCategoryData: any;
  categoryDataListTotal: any;
  Newlists: any;
  selectedCategory: any;
  popupList: any;
  dropdownData: any = {};
  selectlabel: any;
  webshopDetail: any;
  productCustID: any;
  custID: any;
  btnDisabled: boolean = true;
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public translate: TranslateService,
    public loginService: LoginService,
    private toast: ToastService,
    public productHttpService: ProductHttpService,
    private apiService: ApiService,
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

    const getCustID = sessionStorage.getItem('productCustomerID');
    if (getCustID !== undefined && getCustID !== null && getCustID !== '') {
      this.productCustID = getCustID;
    }
    else {
      this.productCustID = this.webshopDetail.CustomerID;
    }
    this.custID = this.productCustID;
    this.getCustomerID();
  }




  ngOnInit() {
    this.customerInfo = undefined;
    this.loadForm();
    this.getCostomer()

    this.setProductCategories();
    this.getCatName();
    // this.getProductColors();
    // this.getAllProductColors();
    this.loadCustomerData();
  }

  ngOnChanges() {
    if (this.loadApi === true) {
      this.clearFormItems();
      this.getCategory();
      this.setProductCategories()
    }
    if (this.catloadStatus) {
      this.getCategory();
    }
  }

  loadCustomerData() {
    setTimeout(() => {
      this.popupList = {
        apiurl: 'en/categorieswithpagination',
        key: 'CategoryID',
        label: 'Category',
        supplierid: this.productDetails.SupplierID,
        customerID: this.custID,
        autoComplete: {
          apiUrl: 'AutoCompleteCategories',
          searchKey: 'name',
          supplierid: undefined
        }
      }
    }, 500);
  }

  clearFormItems() {
    const control = <FormArray>this.productCategoryInfo.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  getCatName() {
    const catLength = this.optionsSelectCategory.length;
    this.productCategoryInfo.value.items.forEach((element, index) => {
      for (let i = 0; i < catLength; i++) {
        let subCatLength = this.optionsSelectCategory[i].subCategories.length;
        for (let j = 0; j < subCatLength; j++) {
          if (this.optionsSelectCategory[i].subCategories[j].CategoryID === element.data.CategoryID && element.subCategory === this.optionsSelectCategory[i].subCategories[j].Name) {
            this.productCategoryInfo.value.items[index]['categoryName'] = this.optionsSelectCategory[i].label;
          }
        }
      }
    });
    // let filterCatArray = this.productCategoryInfo.value.items.filter((thing, index, self) =>
    //   index === self.findIndex((t) => (
    //     t.categoryName === thing.categoryName && t.subCategory === thing.subCategory
    //   ))
    // )
    this.categoryDataList = this.productCategoryInfo.value.items;
    this.categoryDataListTotal = this.categoryDataList.length
    this.categoryDataList.forEach((data, index) => {
      const rolesList = [];
      if (this.Roles !== undefined) {
        this.Roles.forEach(editData => {
          // if (editData.CustomerName === data.customer) {
          rolesList.push(editData.ProductRoleName)
          // }
        })
        this.categoryDataList[index]['ProductRoleName'] = rolesList;
      }


      const tagsName = [];
      const tagsList = [];
      if (this.Tags !== undefined) {
        this.Tags.forEach(editData => {
          // if (editData.CustomerName === data.customer) {
          // tagsName.push(editData.ProductTagName);
          tagsList.push(editData.ProductTagName + '/' + editData.ProductTagsGroupName);
          // this.categoryDataList[index].ProductTagName = editData.ProductTagName
          // this.categoryDataList[index].ProductTagsGroupName = editData.ProductTagsGroupName + '/'
          // }
        })
        this.categoryDataList[index].ProductTagsGroupName = tagsList;
      }
      this.selfCategoryData = this.categoryDataList;
    }
    )
    this.productCategoryInfo.valueChanges.subscribe(() => {
      this.getValue();
    });
    this.getValue();
    this.loadPagination();
  }



  getCustomerID() {
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe(data => {
        if (data) {
          this.customerID = data.CustomerID;
        }
      });
    } else {
      if (sessionStorage.getItem('webshopDetail') !== null) {
        const loginuserdetails = JSON.parse(sessionStorage.getItem('webshopDetail'));
        this.customerID = loginuserdetails.CustomerID;
      }
    }
  }

  loadForm() {
    const loginData = this.apiService.getLoginData();
    this.productCategoryInfo = this.formBuilder.group({
      customer: ['', Validators.required],
      customerName: ['', Validators.required],
      category: ['', Validators.required],
      categoryName: ['', Validators.required],
      subCategory: ['', Validators.required],
      items: this.formBuilder.array([])
    });
    this.items = this.productCategoryInfo.get('items') as FormArray;
  }

  createItem(categoryVal?): FormGroup {
    return this.formBuilder.group({
      customer: [categoryVal.CustomerName || ''],
      categoryName: categoryVal ? categoryVal.categoryName : '',
      subCategory: categoryVal ? categoryVal.Name : '',
      data: categoryVal.data ? categoryVal.data : '',
      status: categoryVal.status ? categoryVal.status : '',
    });
  }

  getValue() {
    const categories = [];
    // let getPushdata = this.productCategoryInfo.value.items.filter((thing, index, self) =>
    //   index === self.findIndex((t) => (
    //     t.categoryName === thing.categoryName && t.subCategory === thing.subCategory
    //   ))
    // )
    // getPushdata.forEach((item) => {
    //   categories.push(item.data);
    // });
    if (this.selfCategoryData !== undefined) {
      this.selfCategoryData.forEach(element => {
        categories.push(element.data);
      });
    }
    this.sendProductCategoryDetails.emit(categories);
    this.Roles = this.productDetails.ProductRoles;
    this.Tags = this.productDetails.ProductTags;
  }


  setProductCategories() {
    // let filterCatArray = this.productDetails.ProductCategories.filter((thing, index, self) =>
    //   index === self.findIndex((t) => (
    //     t.CategoryID === thing.CategoryID
    //   ))
    // )
    this.productDetails.ProductCategories.forEach(item => {

      const categoryVal = {
        CustomerName: item.CustomerName,
        Name: item.Name,
        categoryName: item.categoryName,
        data: item,
        status: 'saved'
      };
      this.items.push(this.createItem(categoryVal));
    });
  }

  getCategory() {
    // const loginData = this.apiService.getLoginData();
    const categoryData = {
      customerID: this.custID
    }
    this.productService.loadProductCategory(categoryData);
    this.productService.getProductCategory().subscribe((data: any) => {
      if (data) {
        this.categoryData = data;
        this.optionsSelectCategory = [];
        data.forEach(element => {
          let categoryObject = {
            label: element.Name,
            value: element.CategoryID,
            subCategories: element.SubCategories
          }
          this.optionsSelectCategory.push(categoryObject);
        });
        this.getCatName();
      }
    })
  }

  setSubcategory(value) {
    this.selectedCategory = value;
    this.optionsSelectSubcategories = [];
    value.subCategories.forEach(item => {
      let subcategories = {
        label: item.Name,
        value: item.CategoryID
      }
      this.optionsSelectSubcategories.push(subcategories)

    })


  }

  getCostomer() {
    const loginData = this.apiService.getLoginData();
    const getCustID = this.webshopDetail.ParentCustomerID ? this.productCustID : this.webshopDetail.CustomerID;
    this.productHttpService.getChildChildCustomer(getCustID).subscribe((allcustomer: any) => {
      if (allcustomer) {

        const optionsSelectCostomer = [];
        allcustomer.forEach(cust => {
          let custData = {
            label: cust.Name,
            value: cust.CustomerID
          }
          if (cust.CustomerID == this.customerID) {
            this.customerInfo = {
              value: cust.CustomerID,
              name: cust.Name,
            }
          }
          optionsSelectCostomer.push(custData)
        })
        this.optionsSelectCostomer = optionsSelectCostomer;

      }
    });
    this.customerInfo = undefined;
  }

  resetForm() {
    this.productCategoryInfo.patchValue({
      category: '',
      subCategory: ''
    })
  }

  addCategory() {
    if (this.productCategoryInfo.controls.category.value !== '') {
      if (this.productCategoryInfo.controls.category.value === '') {
      } else {
        if (this.customerInfo == undefined || this.productCategoryInfo.controls.customer.valid == false) {
          this.toast.error('Please fill all the fields')
          return false;
        }
        const requestData = {
          productID: this.productDetails.ProductID,
          customerID: this.productCategoryInfo.controls.customer.value,
          categoryID: this.productCategoryInfo.controls.subCategory.value ? this.productCategoryInfo.controls.subCategory.value : this.productCategoryInfo.controls.category.value,
        }
        const checkCategory = this.productCategoryInfo.value.items.findIndex(check => check.data.CategoryID === requestData.categoryID && requestData.customerID === check.data.CustomerID);
        if (checkCategory > -1) {
          delete this.productCategoryInfo.value.items[checkCategory]['data']['Action'];
          this.categoryDataList = this.selfCategoryData;
          this.resetForm();
          this.selectedCategory = {};
          // this.customerInfo = {};
          this.popUpDropDown.filterReset()
          this.categoryInfo = {};
          this.optionsSelectSubcategories = [];
          // alert('one')
          // this.toast.error("Can't add duplicate category");

          // return false;
        }
        const getDeletedCategory = this.productCategoryInfo.value.items.filter(data => data.data.Action === "Delete" && data.data.CategoryID === requestData.categoryID && requestData.customerID === data.data.CustomerID)
        if (getDeletedCategory.length === 0) {
          let getCategory = this.optionsSelectCategory.filter(Cat => Cat.value === requestData.categoryID)
          let getSubCategory = this.optionsSelectSubcategories.filter(subCat => subCat.value === requestData.categoryID)
          if (getCategory.length > 0) {
            getCategory = getCategory[0].label;
          }
          if (getSubCategory.length > 0) {
            getSubCategory = getSubCategory[0].label;
          }
          // this.productCategoryInfo.value.items.forEach(element => {
          //   if(requestData)
          // });
          // if(requestData.categoryID !== null){
          let getPushData = this.productCategoryInfo.value.items.filter(data => (data.data.SubCategoryName === getSubCategory && getSubCategory.length > 0 || data.data.Name === getCategory && getCategory.length > 0) && requestData.categoryID === data.data.CategoryID && requestData.customerID === data.data.CustomerID);
          // let getPushData = this.productCategoryInfo.value.items.filter(x => x.data.CategoryID != this.selectedCategory.value)
          // if(this.productCategoryInfo.value.category !==null ||this.productCategoryInfo.value.category !==''  ){
          // this.getCatName();

          if (getPushData.length === 0 || getPushData.length > 0) {
            this.productHttpService.categoryProductCustomer(requestData).subscribe((cust) => {
              if (cust) {
              }
            })

            // this.productService.checkIsProductCategoryAvailable(requestData);
            this.productHttpService.checkProductCategory(requestData).subscribe((data) => {
              if (data !== null && data == true) {
                // if (data == true) {
                const productCategory = {
                  CustomerName: this.customerInfo.name,
                  Name: this.selectedCategory.label,
                  data: {
                    CategoryID: this.categoryInfo !== undefined && this.categoryInfo.value ? this.categoryInfo.value : this.selectedCategory.value,
                    Name: this.selectedCategory.label,
                    CustomerID: this.customerInfo.value,
                    CustomerName: this.customerInfo.name,
                    SubCategoryName: this.categoryInfo !== undefined ? this.categoryInfo.name : ''
                    // Action: 'Insert'
                  },
                  status: 'added'
                };
                const loginData = this.apiService.getLoginData();
                this.customerID = this.productCustID;
                this.productCategoryInfo.patchValue({
                  customer: this.customerID
                })
                this.items.push(this.createItem(productCategory));
                this.categoryDataList = this.selfCategoryData;
                this.resetForm();
                this.getCatName();
                this.loadCustomerData();
                this.selectedCategory = {};
                // this.customerInfo = {};
                this.popUpDropDown.filterReset()
                this.categoryInfo = {};
                this.getValue();

                this.toast.success('Category added Successfully')
                this.customerInfo = undefined;
                this.optionsSelectSubcategories = [];


              } else {
                this.toast.error("Can't add duplicate category");
                this.optionsSelectSubcategories = [];
                this.optionsSelectCategory = [];
                this.selectedCategory.label = '';
                this.getCategory()
                this.resetForm();
              }

            });
            // this.getValue();

            // this.toast.success('Category added Successfully')
            // this.optionsSelectSubcategories = [];
          }
          else {
            this.toast.error("Can't add duplicate category");
            this.optionsSelectSubcategories = [];
            this.optionsSelectCategory = [];
            this.selectedCategory.label = '';
            this.getCategory()
            this.resetForm();
          }
        }
        else {
          this.productCategoryInfo.value.items.forEach(element => {
            if (element.status === "added" && element.data.Action === "Delete" && element.data.CategoryID === requestData.categoryID) {
              delete element.data['Action']
              this.resetForm();
              this.toast.success('Added Successfully')
              this.customerInfo = undefined;
            }
            if (element.status === "saved" && element.data.Action === "Delete" && element.data.CategoryID === requestData.categoryID) {
              delete element.data['Action']
              this.resetForm();
              this.toast.success('Added Successfully')
              this.customerInfo = undefined;
            }
          })
        }
        // }
      }
    }
    else {
      this.toast.error('Please fill all the fields')
    }
  }

  getSelectedCustomer(value) {
    this.custID = value.value;
    this.loadCustomerData()
    this.customerID = value.value;
    this.selectedCategory = {};
    this.categoryInfo = {};
    this.productCategoryInfo.patchValue({
      subCategory: '',
      category: ''
    });
    this.loadCustomerData();
    this.customerInfo = {
      value: value.value,
      name: value.label
    }
  }

  getSelectedSubcategory(value) {
    this.categoryInfo = {
      value: value.value,
      name: value.label
    }
  }

  deleteCategory(productCategory, index) {
    this.deleteData = {
      productCategory: productCategory,
      index: index
    }
    this.deleteModal.show();
    // if (productCategory.items.value[index].status == 'saved') {
    //   productCategory.items.controls[index].controls.status.value = 'deleted';
    //   productCategory.items.value[index].data.Action = "Delete";
    // } else if (productCategory.items.value[index].status == 'added') {
    //   productCategory.items.removeAt(index);
    // }
  }

  getDeleteStatus(status) {

    if (status) {
      // if (this.deleteData.productCategory.status == 'saved') {
      //   this.deleteData.productCategory.status = 'deleted';
      //   this.deleteData.productCategory.data.Action = "Delete";
      // } else if (this.deleteData.productCategory.status == 'added') {
      //   this.deleteData.productCategory.data.Action = "Delete";
      //   this.deleteData.productCategory.status = 'deleted';
      //   this.getCatName();
      // }
      const getDelete = this.selfCategoryData.findIndex(res => res.data.CategoryID === this.deleteData.productCategory.data.CategoryID);
      this.selfCategoryData[getDelete].status = 'deleted';
      this.selfCategoryData[getDelete].data.Action = 'Delete'
      this.getValue()
      this.toast.success('Deleted Successfully')
      // this.loadPagination()
      let currentpage;
      if (this.selectedpage === this.pageList.length) {
        currentpage = this.pageList.length;
      }
      else {
        currentpage = this.selectedpage
      }
      this.loadPagination();
      if (this.pageList[currentpage] === undefined) {
        this.selectedpage = this.pageList.length;
        this.loadPagination();
      }
      this.getCatName()
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }

  checkHeaderRequired() {
    let value = false;
    this.productCategoryInfo.get('items')['controls'].forEach((data) => {
      if (data.controls.status.value != 'deleted') {
        value = true;
      }
    });
    return value;
  }

  showManageColorFun(val) {
    this.showManageColor = val;
  }

  pageChanged(page) {
    this.selectedpage = page;
    this.loadPagination();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.loadPagination();
  }

  loadPagination() {
    if (this.selfCategoryData) {
      const newArrayLists = this.selfCategoryData.filter(data => data.data.Action !== "Delete")
      // this.selfCategoryData = newArrayLists;

      this.categoryDataListTotal = newArrayLists.length
      // this.rowPerPageFun()
      if (newArrayLists !== undefined) {
        if (newArrayLists.length <= 10) {
          this.selectedpage = 1;
        }
        let getPageList = newArrayLists.length / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;

        const getArray = [];
        const startArray = this.selectedpage * this.selectedperpage - this.selectedperpage;
        const endArray = this.selectedpage * this.selectedperpage;
        newArrayLists.slice([startArray], [endArray]).map((item, i) => {
          getArray.push(item)
        });
        this.categoryDataList = getArray;
      }
    }

  }



  @ViewChild('popUpDropDown', { static: false }) popUpDropDown: DropdownpopupComponent;

  getSelectedDropdown(dropdown) {

    this.selectedCategory = {
      label: dropdown.Name,
      value: dropdown.CategoryID
    };
    this.btnDisabled = false;
    this.dropdownData = dropdown;
    this.optionsSelectSubcategories = [{ label: 'Select Subcategory', value: '' }];
    dropdown.SubCategories.forEach(item => {
      let subcategories = {
        label: item.Name,
        value: item.CategoryID
      }
      this.optionsSelectSubcategories.push(subcategories)

    })

    this.productCategoryInfo.get('category').patchValue(dropdown.CategoryID)
  }

  resetPopupDropdown() {
    this.selectedCategory = {};
    this.productCategoryInfo.controls.category.setValue('');
    this.productCategoryInfo.controls.subCategory.setValue('');
    this.btnDisabled = true;
  }

}

