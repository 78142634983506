import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { UserListHttpService } from '../state/services/user.http.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/common-services/api.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-useradditionaldeliverylist',
  templateUrl: './useradditionaldeliverylist.component.html',
  styleUrls: ['./useradditionaldeliverylist.component.scss']
})
export class UseradditionaldeliverylistComponent implements OnInit {
  actions: any = ['edit', 'delete'];
  @ViewChild('showlist', { static: false }) showlist: ModalDirective;
  @ViewChild('editdeliveryAddress', { static: false }) editdeliveryAddress: ModalDirective;
  optionsSelectCustomers: Array<any>;
  usershowAddressList: any = [];
  @Input() userId;
  @Output() editSelectedDeliveryRowEvent = new EventEmitter();
  @Input() deliveryAddressList: any = [];
  editData: any;
  showUserEditAddresspage = false;
  columns: ColumnSetting[] = [];
  activePage: any;
  tableName: any;
  tableEvent: any;
  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private userListHttpService: UserListHttpService, private activatedRoute: ActivatedRoute, private toast: ToastService, private translate: TranslateService) {
    this.columns = [
      {
        primaryKey: 'DeliveryContactPerson',
        header: this.translate.instant('USERADDEDIT.CONTACT_PERSON')
      },
      {
        primaryKey: 'DeliveryCompanyName',
        header: this.translate.instant('USERADDEDIT.COMPANY_NAME')
      },
      {
        primaryKey: 'DeliveryPhoneNumber',
        header: this.translate.instant('USERADDEDIT.PHONE_NUMBER')
      },
      {
        primaryKey: 'DeliveryEmail',
        header: this.translate.instant('USERADDEDIT.EMAIL')
      },
      {
        primaryKey: 'DeliveryAddress',
        header: this.translate.instant('USERADDEDIT.ADDRESS')
      },
      {
        primaryKey: 'DeliveryCity',
        header: this.translate.instant('USERADDEDIT.CITY')
      },
      {
        primaryKey: 'DeliveryInstructions',
        header: this.translate.instant('USERADDEDIT.DELIVERY_INSTRUCTION')
      },
      {
        primaryKey: 'DeliveryPostalCode',
        header: this.translate.instant('USERADDEDIT.POSTAL_CODE')
      },
      {
        primaryKey: 'DeliveryCountryName',
        header: this.translate.instant('USERADDEDIT.COUNTRY')
      },
      {
        primaryKey: 'isDefaultAddress',
        header: this.translate.instant('USERDELIVERYADDRESS.DEFAULT_ADDRESS'),
        format: 'showAddresscheckbox'
      },
      {
        primaryKey: 'action',
        header: 'Action',
        format: 'action',
      }
    ];
  }

  ngOnInit() {
  }
  editShowAddress(event) {
    if (event) {
      try {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.editSelectedDeliveryRowEvent.emit(data);
          }
        });
      } catch (error) {
      }
    }
  }

  deleteDeliveryAddress(event) {
    this.userListHttpService.deleteUserAdditionalDeliveryAddress(event.UsersDeliveryAddressID).subscribe((data: any) => {
      if (data !== null) {
        this.deliveryAddressList = data.DeliveryAddresses;
        this.toast.success(this.translate.instant('USERDELIVERYADDRESS.DELIVRYADDRESS_DELETESUCCESS'));
      }
    });
  }

  ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }
}