import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BrandState, brandReducers } from './brand.reducer';

export interface State {
    userModule: BrandState;
}

export const reducers = brandReducers;

export const getBrandState = createFeatureSelector<BrandState>('userModule');

export const FileUploadSuccess = createSelector(getBrandState,
    (state: BrandState) => state.fileUpload)