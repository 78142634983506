import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductState } from '../reducers/product.reducer';
import { ProductActions } from '../actions/index';
import {
    getProductListSuccess, getProductSaveSuccess, getProductUpdateSuccess,
    getSupplierListSuccess, getUnderSupplierListSuccess, getDeleteProductSuccess,
    getProductColorListSuccess, getAllProductColorListSuccess, updateProductColorSuccess,
    deleteProductColorSuccess, getParentProductColorListSuccess, addProductColorSuccess,
    getProductDetailsSuccess, getProductCategorySuccess, getproductUnitsSuccess,
    getproductSupplierSuccess, getproductCurrencySuccess, getproductCreateSuccess,
    getManualIdSuccess, getProductSizesListSuccess, getEnProductSizesListSuccess,
    addProductSizeSuccess, updateProductSizeSuccess, deleteProductSizeSuccess,
    checkProductIdSuccess, unitsSuccess, getAllproductUnitsSuccess, addProductUnitSuccess,
    updateProductUnitSuccess, deleteProductUnitSuccess, getCustomerInfoSuccess,
    getProductPropertiesSuccess, deleteProductPropertySuccess, getlogoTypeSuccess,
    getlogoAreaSuccess, getlogoTypeAllSuccess, getTransportationOriginCountriesSuccess, getProductLogoListSuccess,
    addProductLogoPositionSuccess, updateProductLogoPositionSuccess, deleteProductLogoPositionsSuccess,
    getAllProductGovtCostsListSuccess, getProductAdditionalCostTypes, addProductGovernmentalCostsSuccess,
    updateProductGovernmentalCostsSuccess, getchangevisibility, deleteProductGovernmentalCostsSuccess,
    getProductGovernmentalCostsSuccess, addmaxLogoArea, delmaxLogoArea, addProductPropertySuccess,
    updatemaxLogoArea, updateProductAdditionalCostsSuccess, getAllProductAdditionalCostsListSuccess,
    deleteProductAdditionalCostsSuccess, addProductAdditionalCostsSuccess, handlingCostsuccess, updateAllProductAdditionalCostsSuccess, customerProductsuccess
} from '../reducers/index';
import { ProductHttpService } from './product.http.service';
import { SearchLimit } from '../models';

@Injectable()
export class ProductService {
    constructor(private store: Store<ProductState>, private productHttpService: ProductHttpService) { }

    loadProducts(searchCriteria: any) {
        this.store.dispatch(new ProductActions.LoadProducts(searchCriteria));
    }

    getProductList() {
        return this.store.select(getProductListSuccess);
    }

    loadProductDetail(productID: any) {
        this.store.dispatch(new ProductActions.LoadProductDetails(productID));
    }

    getProductDetailsSuccess() {
        return this.store.select(getProductDetailsSuccess);
    }

    saveProduct(data) {
        this.store.dispatch(new ProductActions.SaveProduct(data));
    }

    getProductSaveSuccess() {
        return this.store.select(getProductSaveSuccess);
    }

    updateProduct(data) {
        this.store.dispatch(new ProductActions.UpdateProduct(data));
    }

    getProductUpdateSuccess() {
        return this.store.select(getProductUpdateSuccess);
    }

    deleteProduct(data) {
        this.store.dispatch(new ProductActions.DeleteProduct(data));
    }

    getDeleteProductSuccess() {
        return this.store.select(getDeleteProductSuccess);
    }

    getProductListBySearchCriteria(searchCriteria: any, limit?: SearchLimit) {
        return this.productHttpService.getProductListBySearchCriteria(searchCriteria, limit);
    }

    loadSuppliers() {
        this.store.dispatch(new ProductActions.LoadSuppliers());
    }

    getSuppliersList() {
        return this.store.select(getSupplierListSuccess);
    }

    loadCustomerInfo(data) {
        this.store.dispatch(new ProductActions.LoadCustomerInfo(data));
    }

    getCustomerInfo() {
        return this.store.select(getCustomerInfoSuccess);
    }

    loadProductColors(data) {
        this.store.dispatch(new ProductActions.LoadProductColors(data));
    }

    getProductColorsList() {
        return this.store.select(getProductColorListSuccess);
    }

    loadProductSizes(data) {
        this.store.dispatch(new ProductActions.LoadProductSizes(data));
    }

    getProductSizesList() {
        return this.store.select(getProductSizesListSuccess);
    }

    loadProductLogoPosition(data) {
        this.store.dispatch(new ProductActions.LoadProductLogoPosition(data));
    }

    getProductLogoPositionList() {
        return this.store.select(getProductLogoListSuccess);
    }

    loadProductLogoArea(data) {
        this.store.dispatch(new ProductActions.LoadLogoArea(data));
    }

    getProductLogoArea() {
        return this.store.select(getlogoAreaSuccess);
    }

    loadEnProductSizes(data) {
        this.store.dispatch(new ProductActions.LoadEnProductSizes(data));
    }

    getEnProductSizesList() {
        return this.store.select(getEnProductSizesListSuccess);
    }

    checkIsProductCategoryAvailable(data) {
        this.store.dispatch(new ProductActions.CheckProductCategory(data));
    }

    checkIsProductCategoryAvailableSuccess() {
        return this.store.select(checkProductIdSuccess);
    }

    loadAllProductColors(data) {
        this.store.dispatch(new ProductActions.LoadAllProductColors(data));
    }

    getAllProductColorsList() {
        return this.store.select(getAllProductColorListSuccess);
    }

    loadAllProductGovtCosts(data) {
        this.store.dispatch(new ProductActions.LoadAllProductGovtCosts(data));
    }

    getAllProductGovtCostsList() {
        return this.store.select(getAllProductGovtCostsListSuccess);
    }

    loadAllProductAdditionalCosts(data) {
        this.store.dispatch(new ProductActions.LoadAllProductAdditionalCosts(data));
    }

    getAllProductAdditionalCostsList() {
        return this.store.select(getAllProductAdditionalCostsListSuccess);
    }

    loadParentProductColors(data) {
        this.store.dispatch(new ProductActions.LoadParentProductColors(data));
    }

    getParentProductColorsList() {
        return this.store.select(getParentProductColorListSuccess);
    }

    loadUnderSuppliers() {
        this.store.dispatch(new ProductActions.LoadUnderSuppliers());
    }

    getUnderSuppliersList() {
        return this.store.select(getUnderSupplierListSuccess);
    }


    addProductColor(data) {
        this.store.dispatch(new ProductActions.AddProductColor(data));
    }

    addProductColorSuccess() {
        return this.store.select(addProductColorSuccess);
    }

    addProductGovernmentalCosts(data) {
        this.store.dispatch(new ProductActions.AddProductGovernmentalCosts(data));
    }

    addProductGovernmentalCostsSuccess() {
        return this.store.select(addProductGovernmentalCostsSuccess);
    }

    addProductAdditionalCosts(data) {
        this.store.dispatch(new ProductActions.AddProductAdditionalCosts(data));
    }

    addProductAdditionalCostsSuccess() {
        return this.store.select(addProductAdditionalCostsSuccess);
    }

    getProductGovernmentalCosts() {
        this.store.dispatch(new ProductActions.LoadProductGovernmentalCosts());
    }

    getProductGovernmentalCostsSuccess() {
        return this.store.select(getProductGovernmentalCostsSuccess);
    }

    addProductSize(data) {
        this.store.dispatch(new ProductActions.AddProductSize(data));
    }

    addProductSizeSuccess() {
        return this.store.select(addProductSizeSuccess);
    }

    addProductLogoPosition(data) {
        this.store.dispatch(new ProductActions.AddProductLogoPosition(data));
    }

    addProductLogoPositionSuccess() {
        return this.store.select(addProductLogoPositionSuccess);
    }

    addProductProperties(data) {
        this.store.dispatch(new ProductActions.AddProductProperties(data));
    }

    addProductPropertiesSuccess() {
        return this.store.select(addProductPropertySuccess);
    }

    updateProductColor(data) {
        this.store.dispatch(new ProductActions.UpdateProductColor(data));
    }

    updateProductColorSuccess() {
        return this.store.select(updateProductColorSuccess);
    }

    updateProductGovernmentalCosts(data) {
        this.store.dispatch(new ProductActions.UpdateProductGovernmentalCosts(data));
    }

    updateProductGovernmentalCostsSuccess() {
        return this.store.select(updateProductGovernmentalCostsSuccess);
    }

    updateProductAdditionalCosts(data) {
        this.store.dispatch(new ProductActions.UpdateProductAdditionalCosts(data));
    }

    updateProductAdditionalCostsSuccess() {
        return this.store.select(updateProductAdditionalCostsSuccess);
    }

    updateAllProductAdditionalCosts(data) {
        this.store.dispatch(new ProductActions.UpdateAllProductAdditionalCosts(data));
    }

    updateAllProductAdditionalCostsSuccess() {
        return this.store.select(updateAllProductAdditionalCostsSuccess);
    }

    updateProductSize(data) {
        this.store.dispatch(new ProductActions.UpdateProductSize(data));
    }

    updateProductSizeSuccess() {
        return this.store.select(updateProductSizeSuccess);
    }

    updateProductLogoPosition(data) {
        this.store.dispatch(new ProductActions.UpdateProductLogoPosition(data));
    }

    updateProductLogoPositionSuccess() {
        return this.store.select(updateProductLogoPositionSuccess);
    }

    deleteProductColor(data) {
        this.store.dispatch(new ProductActions.DeleteProductColor(data));
    }

    deleteProductColorSuccess() {
        return this.store.select(deleteProductColorSuccess);
    }

    deleteProductGovernmentalCosts(data) {
        this.store.dispatch(new ProductActions.DeleteProductGovernmentalCosts(data));
    }

    deleteProductGovernmentalCostsSuccess() {
        return this.store.select(deleteProductGovernmentalCostsSuccess);
    }

    deleteProductAdditionalCosts(data) {
        this.store.dispatch(new ProductActions.DeleteProductAdditionalCosts(data));
    }

    deleteProductAdditionalCostsSuccess() {
        return this.store.select(deleteProductAdditionalCostsSuccess);
    }

    deleteProductSize(data) {
        this.store.dispatch(new ProductActions.DeleteProductSize(data));
    }

    deleteProductSizeSuccess() {
        return this.store.select(deleteProductSizeSuccess);
    }

    deleteProductLogoPosition(data) {
        this.store.dispatch(new ProductActions.DeleteProductLogoPosition(data));
    }

    deleteProductLogoPositionSuccess() {
        return this.store.select(deleteProductLogoPositionsSuccess);
    }

    deleteProductUnit(data) {
        this.store.dispatch(new ProductActions.DeleteProductUnit(data));
    }

    deleteProductUnitSuccess() {
        return this.store.select(deleteProductUnitSuccess);
    }
    deleteProductProperties(data) {
        this.store.dispatch(new ProductActions.DeleteProductProperties(data));
    }

    deleteProductPropertiesSuccess() {
        return this.store.select(deleteProductPropertySuccess);
    }

    loadProductCategory(data: any) {
        this.store.dispatch(new ProductActions.LoadProductsCategory(data));
    }

    getProductCategory() {
        return this.store.select(getProductCategorySuccess);
    }

    loadProductProperties() {
        this.store.dispatch(new ProductActions.LoadProductProperties());
    }

    getProductProperties() {
        return this.store.select(getProductPropertiesSuccess);
    }

    loadProductUnits() {
        this.store.dispatch(new ProductActions.LoadProductUnits());
    }

    getProductUnits() {
        return this.store.select(getproductUnitsSuccess);
    }

    addProductUnits(data: any) {
        this.store.dispatch(new ProductActions.AddProductUnits(data));
    }

    addProductUnitsSuccess() {
        return this.store.select(addProductUnitSuccess);
    }

    updateProductUnits(data: any) {
        this.store.dispatch(new ProductActions.UpdateProductUnits(data));
    }

    updateProductUnitsSuccess() {
        return this.store.select(updateProductUnitSuccess);
    }

    loadAllProductUnits() {
        this.store.dispatch(new ProductActions.LoadAllProductUnits());
    }

    getAllProductUnits() {
        return this.store.select(getAllproductUnitsSuccess);
    }

    loadProductSupplier() {
        this.store.dispatch(new ProductActions.LoadProductSuppliers());
    }

    getProductSuppliers() {
        return this.store.select(getproductSupplierSuccess);
    }

    loadTransportationOriginCountries() {
        this.store.dispatch(new ProductActions.LoadTransportationOriginCountries());
    }

    getTransportationOriginCountries() {
        return this.store.select(getTransportationOriginCountriesSuccess);
    }

    loadProductCurrency() {
        this.store.dispatch(new ProductActions.LoadProductCurrency());
    }

    getProductCurrency() {
        return this.store.select(getproductCurrencySuccess);
    }

    loadProductAdditionalCostTypes() {
        this.store.dispatch(new ProductActions.LoadProductAdditionalCostTypes());
    }

    getProductAdditionalCostTypes() {
        return this.store.select(getProductAdditionalCostTypes);
    }

    loadProductCreate(data) {
        this.store.dispatch(new ProductActions.LoadProductCreate(data));
    }

    addProduct() {
        return this.store.select(getproductCreateSuccess);
    }

    loadManualId(data) {
        this.store.dispatch(new ProductActions.LoadManualID(data));
    }

    validateManualId() {
        return this.store.select(getManualIdSuccess);
    }

    loadUnits() {
        this.store.dispatch(new ProductActions.LoadUnits());
    }

    getUnits() {
        return this.store.select(unitsSuccess);
    }

    loadLogoType() {
        this.store.dispatch(new ProductActions.LoadLogoType());
    }

    getLogoType() {
        return this.store.select(getlogoTypeSuccess);
    }

    loadLogoTypeAll() {
        this.store.dispatch(new ProductActions.LoadTypeAll());
    }

    getLogoTypeAll() {
        return this.store.select(getlogoTypeAllSuccess);
    }

    loadChangeVisibility(data) {
        this.store.dispatch(new ProductActions.LoadChangeVisibility(data));
    }

    getChangeVisibility() {
        return this.store.select(getchangevisibility);
    }

    loadMaxLogoArea(data) {
        this.store.dispatch(new ProductActions.LoadMaxArea(data));
    }

    addLogoMax() {
        return this.store.select(addmaxLogoArea);
    }

    loaddelMaxLogoArea(data) {
        this.store.dispatch(new ProductActions.LoadAreaDel(data));
    }

    deleteLogoArea() {
        return this.store.select(delmaxLogoArea);
    }

    loadupdateMaxLogoArea(data) {
        this.store.dispatch(new ProductActions.LoadAreaUpdate(data));
    }

    updateLogoArea() {
        return this.store.select(updatemaxLogoArea);
    }

    loadHandlingCost() {
        this.store.dispatch(new ProductActions.LoadHandlingCost());
    }

    getHandlingCost() {
        return this.store.select(handlingCostsuccess);
    }

    loadCustomerProduct(data) {
        this.store.dispatch(new ProductActions.LoadCustomerProducts(data));
    }

    getCustomerProduct() {
        return this.store.select(customerProductsuccess);
    }

}