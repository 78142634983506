import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortdate'
})
export class ShortdatePipe implements PipeTransform {
  mNames = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
  shortDateValue = '';
  transform(value: any, ...args: any[]): any {
    const date = value.substring(0, 10);
    const day = date.substring(8, 10);
    // tslint:disable-next-line:radix
    const month = parseInt(date.substring(5, 7));
    const year = date.substring(2, 4);
    return this.shortDateValue = this.mNames[month - 1] + ' ' + day + ' ' + year;
  }

}
