import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { UserHttpService } from 'src/app/user/state/services/user.http.service';
@Component({
  selector: 'rewardkart-admin-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss']
})
export class ShopDetailsComponent implements OnInit {
  @Input() webshopData: any;
  @Input() getCountryList: any;
  @Output() getShopData = new EventEmitter<any>();
  @Input() languages: any;
  @Input() currencies: any;
  webshopEdit: FormGroup;
  isActAsSupplier: Boolean = false;
  invoiceList: any = [];
  paymentTerm: any = [];
  showLoader: Boolean = false;
  showEmailError: Boolean = false;
  showLinkEmailError: Boolean = false;
  showSupportEmailError: Boolean = false;
  showSalesEmailError: Boolean = false;
  webshopDetail: any;
  currenciesData = [];
  orderNotificationTime = [{ 'label': "Select Order Notification Time", 'value': null }, { 'label': "1 week before dispatch date", 'value': 1 }, { 'label': "2 weeks before dispatch date", 'value': 2 }, { 'label': "3 weeks before dispatch date", 'value': 3 },
  { 'label': "4 weeks before dispatch date", 'value': 4 }, { 'label': "5 weeks before dispatch date", 'value': 5 },
  { 'label': "6 weeks before dispatch date", 'value': 6 },
  { 'label': "7 weeks before dispatch date", 'value': 7 },
  { 'label': "8 weeks before dispatch date", 'value': 8 }

  ]
  languageData = [];
  getLanguages: any;
  constructor(private webshopHttpService: WebshopHttpService, private formBuilder: FormBuilder, private translate: TranslateService, private toast: ToastService, private apiService: ApiService, private userHttpService: UserHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    debugger
    // if (this.getLanguages.length > 0) {
    //   this.getLanguages.forEach(lang => {
    //     const langData = { label: lang.Name, value: lang.LanguageID };
    //     this.languageData.push(langData)
    //   });
    // }

    if (this.currencies.length > 0) {
      this.currencies.forEach(res => {
        const cdata = { label: res.Name, value: res.CurrencyID };
        this.currenciesData.push(cdata);
      });
    }
    this.webshopForm();
    this.loadInvoicePaymentTerm();
    this.prefillData();
    this.getWebshopLanguages();

  }

  ngOnChanges() {

  }


  prefillData() {
    if (this.webshopData) {
      this.isActAsSupplier = this.webshopData.SupplierID > 0 ? true : false;
      this.webshopEdit.patchValue({
        webshopName: this.webshopData.Name,
        companyName: this.webshopData.CompanyName,
        address: this.webshopData.Address,
        textField: this.webshopData.ShopDetailsName,
        city: this.webshopData.City,
        country: this.webshopData.Country,
        postalCode: this.webshopData.ZipCode,
        contactPerson: this.webshopData.ContactPerson,
        contactNumber: this.webshopData.ContactNumber,
        email: this.webshopData.ContactEmail,
        salesEmail: this.webshopData.SalesEmail,
        supportEmail: this.webshopData.SupportEmail,
        salesLinkEmail: this.webshopData.SalesLinkEmail,
        website: this.webshopData.Website,
        domainName: this.webshopData.DomainName,
        registerNumber: this.webshopData.RegistrationNumber,
        vatNumber: this.webshopData.VAT,
        defaultInvoiceType: this.webshopData.DefaultInvoiceType,
        invoicePaymentTerms: this.webshopData.PaymentTermsNumberOfDays,
        actAsSupplier: this.isActAsSupplier,
        DefaultLanguageID: (this.webshopData.DefaultLanguageID !== undefined) ? this.webshopData.DefaultLanguageID : '',
        DefaultCurrencyID: (this.webshopData.DefaultCurrencyID !== undefined) ? this.webshopData.DefaultCurrencyID : '',
        OrderNotificationAlarmWeeks: (this.webshopData.OrderNotificationAlarmWeeks !== undefined) ? this.webshopData.OrderNotificationAlarmWeeks : null
      });
    }
  }

  webshopForm() {
    this.webshopEdit = this.formBuilder.group({
      webshopName: ['', Validators.required],
      companyName: ['', Validators.required],
      address: ['', Validators.required],
      textField: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      postalCode: ['', Validators.required],
      contactPerson: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', Validators.required],
      salesEmail: ['', Validators.required],
      supportEmail: ['', Validators.required],
      salesLinkEmail: [''],
      website: ['', Validators.required],
      domainName: ['', Validators.required],
      registerNumber: ['', Validators.required],
      vatNumber: ['', Validators.required],
      defaultInvoiceType: ['', Validators.required],
      invoicePaymentTerms: ['', Validators.required],
      actAsSupplier: [false],
      DefaultLanguageID: ['', Validators.required],
      DefaultCurrencyID: ['', Validators.required],
      OrderNotificationAlarmWeeks: ['']
    })
  }

  loadInvoicePaymentTerm() {
    this.invoiceList = [
      { label: 'Invoice', value: 1 },
      { label: 'Shipment Invoice', value: 2 }
    ];
    this.paymentTerm = [
      { label: '14 days net', value: 14 },
      { label: '30 days net', value: 30 },
      { label: '60 days net', value: 60 },
    ];
  }

  saveWebshop() {
    console.log(this.webshopEdit.value);
    this.webshopEdit.patchValue({
      salesLinkEmail: null
    })
    this.getShopData.emit(this.webshopEdit.value);
  }

  checkDomain(key) {
    const request = {
      domain: key,
      customerID: this.webshopDetail.CustomerID
    }
    this.webshopHttpService.checkDomain(request).subscribe(data => {
      if (!data) {
        this.toast.error('Domain already exists');
      }
    })
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    //old code
    // var regex = /^[a-z0-9-]*$/;
    var regex = /^[a-z0-9-]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  validateEmail(evt, fieldName) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(evt.target.value)) {
      if (fieldName == "email") {
        this.showEmailError = false;
      }
      else if (fieldName == "salesEmail") {
        this.showSalesEmailError = false;
      }
      else if (fieldName == "supportEmail") {
        this.showSupportEmailError = false;
      }
      else if (fieldName == "salesLinkEmail") {
        this.showLinkEmailError = false;
      }
    }
    else {
      if (fieldName == "email") {
        this.showEmailError = true;
      }
      else if (fieldName == "salesEmail") {
        this.showSalesEmailError = true;
      }
      else if (fieldName == "supportEmail") {
        this.showSupportEmailError = true;
      }
      else if (fieldName == "salesLinkEmail") {
        this.showLinkEmailError = true;
      }
    }
  }


  onlyAlphabet(event) {
    let k = event.charCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123)) {
      return false;
    }
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 43 || k == 45 || k == 40 || k == 41 || k == 47 || (k >= 48 && k <= 57));
  }

  getWebshopLanguages() {
    this.webshopHttpService.getLanguages(this.webshopDetail.CustomerID).subscribe(
      (data: any) => {
        if (data) {

          let languageData = [];
          data.forEach(lang => {
            const langData = { label: lang.Name, value: lang.LanguageID };
            languageData.push(langData)
          });
          this.getLanguages = languageData;
        }
      });
  }

}