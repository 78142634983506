import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportProfitCenterComponent } from './report-profit-center/report-profit-center.component';
import { ReportDispatchedProductsComponent } from './report-dispatched-products/report-dispatched-products.component';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { ReportInternalStockProductsComponent } from './report-internal-stock-products/report-internal-stock-products.component';
import { SharedModule } from '../shared/shared.module';
import { ReportOrdersComponent } from './report-orders/report-orders.component';
import { ReportProductsSearchComponent } from './report-products-search/report-products-search.component';
import { ReportDeliveryStatusComponent } from './report-delivery-status/report-delivery-status.component';
import { ReportUsermisComponent } from './report-user-mis/report-usermis.component';
import { ReportUseractivityComponent } from './report-user-activity/report-useractivity.component';
import { ReportBudgetComponent } from './report-budget/report-budget.component';
import { ReportCampaignAllocationComponent } from './report-campaign-allocation/report-campaign-allocation.component';
import { ReportCampaignUsageComponent } from './report-campaign-usage/report-campaign-usage.component';
import { ReportOrderFulfillmentComponent } from './report-order-fulfillment/report-order-fulfillment.component';
import { ReportUserManagementComponent } from './report-user-management/report-user-management.component';
import { ReportPaymentComponent } from './report-payment/report-payment.component'

@NgModule({
  declarations: [
    ReportProfitCenterComponent,
    ReportDispatchedProductsComponent,
    ReportInternalStockProductsComponent,
    ReportProductsSearchComponent,
    ReportOrdersComponent,
    ReportDeliveryStatusComponent,
    ReportUsermisComponent,
    ReportBudgetComponent,
    ReportUseractivityComponent,
    ReportCampaignAllocationComponent,
    ReportCampaignUsageComponent,
    ReportOrderFulfillmentComponent,
    ReportUserManagementComponent,
    ReportPaymentComponent
  ],
  imports: [
    CommonModule,
    CommonFilterModule,
    SharedModule
  ],
  exports: [
    ReportProfitCenterComponent,
    ReportDispatchedProductsComponent,
    ReportInternalStockProductsComponent,
    ReportProductsSearchComponent,
    ReportOrdersComponent,
    ReportUsermisComponent,
    ReportBudgetComponent,
    ReportUseractivityComponent,
    ReportCampaignAllocationComponent,
    ReportCampaignUsageComponent,
    ReportOrderFulfillmentComponent,
    ReportUserManagementComponent
  ]
})
export class ReportsModule { }
