import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { UserService } from 'src/app/user/state/services/user.service';
import { SettingsHttpService } from '../../services/settings.http.service';

@Component({
  selector: 'rewardkart-admin-product-groups-edit',
  templateUrl: './product-groups-edit.component.html',
  styleUrls: ['./product-groups-edit.component.scss']
})
export class ProductGroupsEditComponent implements OnInit {

  header: any;
  formFields: any = '';
  buttonActions: any = ['back', 'next', 'submit', 'popup', 'delete'];
  optionSelectSupplier: Array<any>;
  productRoles: any;
  selectProductRoles: any;
  personalFormCompleteStatus: Boolean = false;
  formSubmitDatas: any = {};
  userPersonalDetails: FormGroup;
  isEdit: boolean = false;
  addUserButton: any;
  selectedImg: any;
  currentImg: any;
  userName: any;
  isEnabledNextBtn = false;
  EnableNextbtnTab = false;
  isUserExists = false;
  showEanNumber: boolean;
  postalError: Boolean;
  deliverypostalError: Boolean;
  showImageError: Boolean = false;
  editResponse: any;
  deleteRequest: any = {};
  showLoader: Boolean = false;
  getuserstatus = [{ label: 'Approve', value: 1 }, { label: 'Reject', value: 0 }];
  inputElement: ElementRef;
  webshopDetail: any;
  productList: any = [];
  selectedRole: any;
  @ViewChild('productmanage', { static: false }) productmanage: ModalDirective;
  constructor(private userService: UserService, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router,
    private translate: TranslateService, private settingsHttpService: SettingsHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.loadForm();
    this.onLoadFunctions();
  }
  showUserValidation: boolean = false;
  personalDetails: FormGroup;
  userAdditinalDeliveryAddressDetails: FormGroup;
  vatImageForm: FormGroup;
  PreviousRoleCode: any;
  editData: any;
  customerId: any;
  ngOnInit() {
    const searchproductgroupsresult = sessionStorage.getItem('searchproductgroupsresult')
    if (searchproductgroupsresult !== null) {
      const filterFields = JSON.parse(sessionStorage.getItem('searchproductgroupsresult'))
      /** To Prefill Webshop dropdown value(based on Webshop) */
      // tslint:disable-next-line:max-line-length
      if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
        const getIndex = filterFields.findIndex(ind => ind.key === 'selectwebshop');
        if (getIndex !== null && getIndex !== undefined) {
          this.customerId = filterFields[getIndex].selected;
        }
      }
      if (history.state.data !== undefined) {
        console.log("Edit data : ", history.state.data);
        this.customerId = history.state.data.CustomerID;
      }
    }
  }

  listPage() {
    if (history.state.data !== undefined) {
      if (history.state.data.dashboardRedirectStatus) {
        this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
      } else {
        sessionStorage.setItem('productGroupEditRedirect', JSON.stringify(true));
        this.router.navigate(['/product-groups'], { replaceUrl: false });
      }
    } else {
      sessionStorage.setItem('productGroupEditRedirect', JSON.stringify(true));
      this.router.navigate(['/product-groups'], { replaceUrl: false });
    }
  }

  loadForm() {
    this.userPersonalDetails = this.formBuilder.group({
      selectSupplier: ['', Validators.required],
      name: ['', Validators.required],
    })
  }


  onLoadFunctions() {
    this.userService.loadCountries();
    this.userService.loadCustomers();
    this.userService.loadRoles();
    this.userService.loadSearchFormFields();
    this.userService.loadLanguages();
    this.userService.loadCurrencies();


    /** to get Roles list from Redux State */
    this.getSupplierList();

  }

  getSupplierList() {
    let customerId = this.webshopDetail.CustomerID;
    this.settingsHttpService.getSuppliersList(customerId, this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.optionSelectSupplier = [];
        this.optionSelectSupplier.push({ label: this.translate.instant('PRODUCTGROUPS.SELECT_SUPPLIER'), value: '' });
        data.ItemsCollection.forEach(element => {
          const countryVal = {
            value: element.SupplierID,
            label: element.Name,
          };
          this.optionSelectSupplier.push(countryVal);
        });

        if (history.state.data !== undefined && this.optionSelectSupplier !== undefined) {
          this.isEdit = true;
          this.editAddUserForm();
          // this.getUserData();
          // this.getShowAddressList();
          this.addUserButton = this.translate.instant('PRODUCTGROUPS.UPDATE_PRODUCTGROUPS');
          this.header = this.translate.instant('PRODUCTGROUPS.EDIT_PRODUCTGROUPS');
        } else {
          this.isEdit = false;
          this.addUserButton = this.translate.instant('PRODUCTGROUPS.SUBMIT');
          this.header = this.translate.instant('PRODUCTGROUPS.ADD_PRODUCTGROUPS');
        }
      }
    });
  }

  validateFields() {
    let validateStatus = true;
    if ((this.userPersonalDetails.controls.name.value == '') || (this.userPersonalDetails.controls.selectSupplier.value == '')) {
      this.userPersonalDetails.controls['name'].markAllAsTouched();
      this.userPersonalDetails.controls['selectSupplier'].markAllAsTouched();
      validateStatus = false;
    }
    return validateStatus;
  }

  addProductGroups() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      const addUserData = {
        "Name": this.userPersonalDetails.value.name,
        // "CustomerID": (this.customerId !== null && this.customerId !== undefined &&
        //   this.customerId !== '') ? this.customerId : this.webshopDetail.CustomerID,
        "SupplierID": (this.userPersonalDetails.value.selectSupplier).toString(),

      }
      // this.userService.loadAddUser(addUserData);
      this.showLoader = true;
      this.settingsHttpService.addProductGroups(addUserData).subscribe((data: any) => {
        this.showLoader = false;
        sessionStorage.setItem('productGroupEditRedirect', JSON.stringify(true));
        this.router.navigate(['/product-groups'], { replaceUrl: false });
        this.toast.success('Product group created successfully');
      },
        err => {
          let errMsg = '';
          this.showLoader = false;
          if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
            for (var key in err.error.ModelState) {
              errMsg += err.error.ModelState[key] + ' ';
            }
          } else {
            errMsg += err.error;
          }
          this.toast.error(errMsg);

        });
    }
  }

  editAddUserForm() {
    const editData = history.state.data;
    this.editData = history.state.data;
    this.userPersonalDetails = this.formBuilder.group({
      name: [editData !== undefined ? editData.Name : '', Validators.required],
      selectSupplier: [editData !== undefined ? editData.SupplierID : '', Validators.required],
    })
  }

  viewProductGroups() {
    this.showLoader = true;
    const editData = history.state.data;
    this.editData = history.state.data;
    this.selectedRole = this.editData;
    this.settingsHttpService.viewProductGroups(editData.ProductGroupID).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        if (data.Message) {
          this.toast.error(data.Message);
        }
        this.productList = data.ItemsCollection[0].Products;
        // this.productList.forEach(element => {
        //   element.ProductRoleID = role.ProductRoleID;
        // });
        this.productmanage.show();
      }
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      })
  }


  getloaderStatus(event) {
    this.showLoader = event;
  }

  updateProductGroups() {
    let editData = history.state.data;
    editData.Name = this.userPersonalDetails.value.name
    this.settingsHttpService.updateProductGroup(editData).subscribe((data: any) => {
      if (data) {
        this.toast.success('Product group updated successfully');
        sessionStorage.setItem('productGroupEditRedirect', JSON.stringify(true));
        this.router.navigate(['/product-groups'], { replaceUrl: false })
      }
    },
      (error) => {
        if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
          let errorMessage = '';
          const objResponse = Object.values(error.error.ModelState);
          objResponse.forEach(errmessage => {
            errorMessage += errmessage;
          });
          this.toast.error(errorMessage);
        }
      })
  }

}
