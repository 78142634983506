import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, TabHeadingDirective, ToastService } from 'ng-uikit-pro-standard';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';

@Component({
  selector: 'app-product-options',
  templateUrl: './product-options.component.html',
  styleUrls: ['./product-options.component.scss']
})
export class ProductOptionsComponent implements OnInit, OnChanges {

  productOptionsForm: FormGroup;
  optionsSelectOptions: any = [];
  items: FormArray;

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input() optionsProductProperty: any;
  @Input() productPropertyInfo: any;
  @Output() showProductOption = new EventEmitter<any>();
  @Output() optionsList = new EventEmitter<any>();
  productPropertyDetails: any;
  propertyOptions: any = [];
  sendOptions: any = [];
  selectedOption: any;
  deleteData: any;
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public translate: TranslateService,
    public toast: ToastService,
    public productHttpService: ProductHttpService
  ) {
    translate.setDefaultLang('en');
    this.buildForm();
  }

  ngOnInit() {
  }
  ngOnChanges() {
    this.productPropertyDetails = [];
    this.setValues()
  }

  clearFormItems() {
    const control = <FormArray>this.productOptionsForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  buildForm() {
    this.productOptionsForm = this.formBuilder.group({
      options: ['', Validators.required],
      moq: ['', Validators.required],
      optionalForBuyer: [false, Validators.required],
      items: this.formBuilder.array([])
    });
  }

  loadForm() {

  }

  setOptions() {
    this.optionsSelectOptions = [];
  }

  createItem(optionsVal?): FormGroup {
    return this.formBuilder.group({
      options: optionsVal ? optionsVal.options : '',
      moq: optionsVal ? optionsVal.moq : '',
      optionalForBuyer: optionsVal ? optionsVal.optionalForBuyer : false,
      status: optionsVal ? optionsVal.status : '',
      data: optionsVal ? optionsVal.data : ''
    });
  }



  setValues() {
    this.clearFormItems()
    this.items = this.productOptionsForm.get('items') as FormArray;

    this.productPropertyDetails = this.productPropertyInfo.data;
    this.propertyOptions = [{ label: 'Select option', value: '' }];
    this.optionsProductProperty.forEach(element => {
      if (element.value == this.productPropertyDetails.ProductPropertyID) {
        element.data.ProductOptions.forEach(item => {
          const check = item.Translations.filter(data => data.LanguageCode === 'en');
          const option = {
            value: item.ProductOptionID,
            label: check[0].PropertyValue,
            data: item
          }
          this.propertyOptions.push(option);
        })
      }
    });

    this.productPropertyInfo.data.ProductOptions.forEach(item => {
      const optionsVal = {
        options: item.Name,
        moq: item.MOQ,
        optionalForBuyer: item.isDefault,
        data: item,
        status: 'saved',
      };
      this.items.push(this.createItem(optionsVal));
    })
  }

  setOptionalForBuyer(value) {
    this.productOptionsForm.controls['optionalForBuyer'].setValue(value.checked);
  }

  onSelectedOption(value) {
    this.selectedOption = value
  }



  addOptions() {
    const values = {
      options: this.selectedOption.label,
      moq: this.productOptionsForm.value.moq,
      optionalForBuyer: this.productOptionsForm.value.optionalForBuyer,
      status: 'added',
      data: {
        ProductOptionID: this.productOptionsForm.value.options,
        MOQ: this.productOptionsForm.value.moq,
        isDefault: this.productOptionsForm.value.optionalForBuyer,
        Name: this.selectedOption.label,
        Action: "Insert"
      },
    }

    this.items.push(this.createItem(values));
    this.productOptionsForm.controls['options'].setValue('');
    this.productOptionsForm.controls['moq'].setValue('');
    this.productOptionsForm.controls['optionalForBuyer'].setValue(false);

  }

  // deleteOptions(productProperties, index) {
  //   if (productProperties.items.value[index].status == 'saved') {
  //     productProperties.items.controls[index].controls['status'].setValue('deleted');
  //     productProperties.items.controls[index].controls.data.value['Action'] = 'Delete';
  //   } else if (productProperties.items.value[index].status == 'added') {
  //     productProperties.items.removeAt(index);
  //   }
  // }


  deleteOptions(productProperties, index) {
    this.deleteData = {
      productProperty: productProperties,
      index: index
    }
    this.deleteModal.show();
  }

  getDeleteStatus(status) {

    if (status) {
      if (this.deleteData.productProperty.items.value[this.deleteData.index].status == 'saved') {
        this.deleteData.productProperty.items.controls[this.deleteData.index].controls['status'].setValue('deleted');
        this.deleteData.productProperty.items.controls[this.deleteData.index].controls.data.value['Action'] = 'Delete';
      } else if (this.deleteData.productProperty.items.value[this.deleteData.index].status == 'added') {
        this.deleteData.productProperty.items.removeAt(this.deleteData.index);
      }
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }



  closeModal() {
    this.showProductOption.emit(false);
  }

  savePropertyOption() {
    this.sendOptions = [];
    this.productOptionsForm.value.items.forEach(item => {
      this.sendOptions.push(item.data);
    });
    this.optionsList.emit(this.sendOptions);
  }

  getOptional(event, i) {
    this.items.value[i].optionalForBuyer = event.checked;
    this.items.value[i].data.isDefault = event.checked;
    if (this.productPropertyInfo.data.Action === "Insert") {
      this.items.value[i].data.Action = 'Insert';
    }
    else {
      this.items.value[i].data.Action = 'Update';
    }
    // this.productOptionsForm.value
  }

  getMoq(event, i) {
    this.items.value[i].data.MOQ = event.target.value;
    if (this.productPropertyInfo.data.Action === "Insert") {
      this.items.value[i].data.Action = 'Insert';
    }
    else {
      this.items.value[i].data.Action = 'Update';
    }
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
