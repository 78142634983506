import { Component, OnInit } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { DashboardService } from '../state/services/dashboard.service';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-order',
  templateUrl: './dashboard-order.component.html',
  styleUrls: ['./dashboard-order.component.scss']
})
export class DashboardOrderComponent implements OnInit {
  public dashboardList: any;
  tableName: any = 'orderlist';
  public orderStateList: any = [];
  public orderStateOptionList: any = [];
  public filterFields: any = [];
  public titles: any;
  public allStateList: any = [];
  public loadStateoption: boolean = false;
  public orderListQuery: any = {};
  showLoader: boolean = false;
  public ordercolumns: ColumnSetting[] = [];
  submitted = false;
  loadOrderData: boolean = false;
  webshopDetail: any;
  constructor(public dashboardservice: DashboardService, public apiService: ApiService,
    public dashboardhttpservice: DashboardHttpService, private router: Router, private translate: TranslateService) {
    this.titles = this.translate.instant('DASHBOARD.RECENTORDERS');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.ordercolumns = [
      {
        primaryKey: 'OrderIdManual',
        header: this.translate.instant('DASHBOARD.ORDERNUMBER')
      },
      {
        primaryKey: 'ProductNames',
        header: this.translate.instant('DASHBOARD.PRODUCTNAME'),
        // primaryKeyItem: 'ProductName',
        // format: 'array'
      },
      {
        primaryKey: 'UserName',
        header: this.translate.instant('DASHBOARD.USERNAME'),
      },
      {
        primaryKey: 'CustomerName',
        header: this.translate.instant('MENU.WEBSHOPS')
      },
      {
        primaryKey: 'orderItemCount',
        header: this.translate.instant('DASHBOARD.TOTALITEMS')
      },
      {
        primaryKey: 'Suborders',
        header: this.translate.instant('DASHBOARD.TOTALSUBITEMS'),
        format: 'length'
      }
    ];
    this.onLoadFunctions();
  }

  ngOnInit() {
    // this.loadState();
    this.loadStateList();
  }

  getSelectData(stateId) {
    console.log('stateId : ', stateId);
    let sampleorder = false;
    let orderItemStateId = '';
    let orderItemStateOptionID = '';
    this.orderListQuery.customerID = this.webshopDetail.CustomerID;
    if (stateId.orders !== undefined && stateId.orders.selectMethod === 'orders' && stateId.orders.value === 'Sample Orders') {
      sampleorder = true;
      this.orderListQuery.sampleorder = sampleorder;
      //   this.dashboardservice.loadDashboard(this.orderListQuery);
    }
    else {
      sampleorder = false;
      this.orderListQuery.sampleorder = sampleorder;
      //  this.dashboardservice.loadDashboard(this.orderListQuery);
    }
    if (stateId.state !== undefined && stateId.state.selectMethod === 'state') {
      if (stateId.state.value !== this.orderListQuery.orderItemStateId) {
        stateId.selectoption.value = '';
      }
      orderItemStateId = stateId.state.value;
      this.orderListQuery.orderItemStateId = orderItemStateId;
      //  this.dashboardservice.loadDashboard(this.orderListQuery);
      let getOrderStateOption = this.allStateList;
      getOrderStateOption.filter(data => {
        if (data.value === stateId.state.value) {
          let stateOptionData = [{ label: this.translate.instant('DASHBOARD.SELECTSTATEOPTION'), value: '', orderItemStateOptionID: '' }];
          if (data.options !== undefined) {
            data.options.forEach(option => {
              // tslint:disable-next-line:max-line-length
              if (option.OrderItemStateOptionID !== undefined && option.OrderItemStateOptionID !== null) {
                stateOptionData.push({ label: option.StateOptionName, value: option.OrderItemStateOptionID, orderItemStateOptionID: option.OrderItemStateOptionID });
              } else {
                stateOptionData.push({ label: option.StateOptionName, value: option.OrderItemStateID, orderItemStateOptionID: option.OrderItemStateID });
              }
            });
          } else {
            orderItemStateOptionID = '';
            this.orderListQuery.orderItemStateOptionID = '';
          }
          this.filterFields[1].data = stateOptionData;
        }
      });
      this.loadOrderData = true;
    }

    if (stateId.selectoption !== undefined && stateId.selectoption.selectMethod === 'selectoption') {
      orderItemStateOptionID = stateId.selectoption.value;
      this.orderListQuery.orderItemStateOptionID = orderItemStateOptionID;
      //  this.dashboardservice.loadDashboard(this.orderListQuery);
      this.loadOrderData = true;
    }
    if (this.loadOrderData) {
      //  this.dashboardservice.loadDashboard(this.orderListQuery);
      this.getDashboardOrderList();
    }
  }


  onLoadFunctions() {
    this.orderListQuery.customerID = this.webshopDetail.CustomerID;
    // this.dashboardservice.loadDashboard(this.orderListQuery);
    this.getDashboardOrderList();
    this.dashboardservice.loadOrderState(this.orderListQuery);
    const cust = { customerID: this.webshopDetail.CustomerID }
    this.dashboardservice.loadPendingUser(cust);
  }

  loadStateList() {
    this.showLoader = true;
    const cust = { customerID: this.webshopDetail.CustomerID }
    this.dashboardhttpservice.getOrderState(cust).subscribe((data: any) => {
      if (data) {
        this.showLoader = false;
        let stateName = data;
        this.orderStateList = [{ label: this.translate.instant('DASHBOARD.SELECTSTATE'), value: '', options: '' }]
        stateName.forEach(element => {
          let stateObject = { label: element.StateName, value: element.OrderItemStateID, options: element.Options }
          this.orderStateList.push(stateObject);
          this.allStateList.push(stateObject);
        });

        this.filterFields = [
          {
            label: this.translate.instant('DASHBOARD.STATELABEL'),
            type: 'select',
            key: 'state',
            data: this.orderStateList,
            placeholder: this.translate.instant('DASHBOARD.SELECTSTATE'),
            class: 'col-4'
          },
          {
            label: this.translate.instant('DASHBOARD.SELECTOPTIONLABEL'),
            type: 'select',
            key: 'selectoption',
            data: [],
            placeholder: this.translate.instant('DASHBOARD.SELECTOPTIONLABEL'),
            class: 'col-5 p-0'
          },
          {
            label: this.translate.instant('MENU.ORDERS'),
            type: 'select',
            selected: 'All Orders',
            key: 'orders',
            placeholder: this.translate.instant('MENU.ORDERS'),
            class: 'col-3',
            data: [{
              label: 'All Orders',
              value: 'All Orders',
              selected: true
            },
            {
              label: 'Sample Orders',
              value: 'Sample Orders'
            }]
          },
          {
            label: this.translate.instant('DASHBOARD.RESET'),
            type: 'button',
            class: 'ml-auto',
            key: 'reset',
          },
        ];
      }
      else {
        this.showLoader = false;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });

    this.dashboardservice.getPendingUser().subscribe((user: any) => {
      if (user) {
        // console.log(user)
      }
    })
  }

  getDashboardOrderList() {
    this.showLoader = true;
    let searchData = {
      'customerID': this.orderListQuery.customerID,
      'orderItemStateId': this.orderListQuery.orderItemStateId,
      'sampleorder': this.orderListQuery.sampleorder,
      'orderItemStateOptionID': this.orderListQuery.orderItemStateOptionID
    }
    this.dashboardhttpservice.getDashboardOrderList(searchData).subscribe((data: any) => {
      if (data) {
        data.ItemsCollection.forEach(element => {
          let orderItemCount = 0;
          orderItemCount += element.OrderItems.length;
          let ProductNames = [];
          element.Suborders.forEach(element1 => {
            orderItemCount += element1.OrderItems.length;
            element1.OrderItems.forEach(element2 => {
              ProductNames.push(element2.ProductName);
            });
          });

          element.OrderItems.forEach(element3 => {
            ProductNames.push(element3.ProductName);
          });

          element.ProductNames = ProductNames.join(", ");
          element.orderItemCount = orderItemCount;
        });
        this.loadOrderData = false;
        this.showLoader = false;
        this.dashboardList = data.ItemsCollection;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  getReset(val) {
    this.orderListQuery = {};
    this.formreset();
    this.onLoadFunctions();
  }

  formreset() {
    this.filterFields = [
      {
        label: this.translate.instant('DASHBOARD.STATELABEL'),
        type: 'select',
        key: 'state',
        data: this.orderStateList,
        placeholder: this.translate.instant('DASHBOARD.SELECTSTATE'),
        class: 'col-4'
      },
      {
        label: this.translate.instant('DASHBOARD.SELECTOPTIONLABEL'),
        type: 'select',
        key: 'selectoption',
        data: [],
        placeholder: this.translate.instant('DASHBOARD.SELECTOPTIONLABEL'),
        class: 'col-5 p-0'
      },
      {
        label: this.translate.instant('MENU.ORDERS'),
        type: 'select',
        selected: 'All Orders',
        key: 'orders',
        placeholder: this.translate.instant('MENU.ORDERS'),
        class: 'col-3',
        data: [{
          label: 'All Orders',
          value: 'All Orders',
          selected: true
        },
        {
          label: 'Sample Orders',
          value: 'Sample Orders'
        }]
      },
      {
        label: this.translate.instant('DASHBOARD.RESET'),
        type: 'button',
        class: 'ml-auto',
        key: 'reset',
      },
    ];
  }

  redirectToOrderPage(orderData: any) {
    if (orderData.OrderID !== undefined && orderData.OrderID !== null) {
      try {
        const id = orderData.OrderID;
        const dashboardData = {
          dashboardRedirectStatus: true
        }
        sessionStorage.setItem('dashboardRedirectStatus', JSON.stringify(true));
        this.router.navigate([`/admin-order-item/${id}`], {
          state: { id, dashboardData },
          replaceUrl: false
        });
      } catch (error) {
      }
    }
  }

  orderListPage() {
    this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
  }
}
