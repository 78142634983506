import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DashboardState } from '../reducers/dashboard.reducer';
import { DashboardActions } from '../actions/index';
import { getDashboardListSuccess, getDashboardStockListSuccess, getDashboardOrderFlowSuccess, getDashboardProductListSuccess, getDashboardProductionLineSuccess, getMessageSuccess, getOrderStateSuccess, getPendingUserSuccess } from '../reducers/index';

@Injectable()
export class DashboardService {
    constructor(private store: Store<DashboardState>) { }

    loadDashboard(searchCriteria: any) {
        this.store.dispatch(new DashboardActions.LoadDashboardOrderList(searchCriteria));
    }

    getDashboardOrderList() {
        return this.store.select(getDashboardListSuccess);
    }

    loadStock(searchCriteria: any) {
        this.store.dispatch(new DashboardActions.LoadStock(searchCriteria));
    }

    getStockList(){
        return this.store.select(getDashboardStockListSuccess);
    }

    loadOrderFlow(searchCriteria: any){
        this.store.dispatch(new DashboardActions.LoadOrderFlow(searchCriteria));
    }

    getOrderFlow(){
        return this.store.select(getDashboardOrderFlowSuccess);
    }

    loadProductionLine(searchCriteria: any){
        this.store.dispatch(new DashboardActions.LoadProductionLine(searchCriteria));
    }

    getProductionLine(){
        return this.store.select(getDashboardProductionLineSuccess);
    }

    loadProductList(searchCriteria: any){
        this.store.dispatch(new DashboardActions.LoadProductList(searchCriteria));
    }

    getProductList(){
        return this.store.select(getDashboardProductListSuccess);
    }

    loadMessage(searchCriteria: any){
        this.store.dispatch(new DashboardActions.LoadMessage(searchCriteria));  
    }

    getMessage(){
        return this.store.select(getMessageSuccess);
    }

    loadOrderState(searchCriteria: any){
        this.store.dispatch(new DashboardActions.LoadOrderState(searchCriteria));  
    }

    getOrderState(){
        return this.store.select(getOrderStateSuccess);
    }

    
    loadPendingUser(searchCriteria: any){
        this.store.dispatch(new DashboardActions.LoadPendingUserList(searchCriteria));  
    }


    getPendingUser(){
        return this.store.select(getPendingUserSuccess);
    }

}
