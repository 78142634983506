import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';
import { SettingsHttpService } from '../../services/settings.http.service';

@Component({
  selector: 'rewardkart-admin-product-manage-groups',
  templateUrl: './product-manage-groups.component.html',
  styleUrls: ['./product-manage-groups.component.scss']
})
export class ProductManageGroupsComponent implements OnInit {
  @Input() getProductRolesList: any;
  @Input() selectedRole: any;
  @Output() loadProductList = new EventEmitter<any>();
  @Output() loaderStatus = new EventEmitter<any>();
  actions: any = ['delete'];
  tableName: string = 'productrolesproducts';
  productList: any = [];
  manualID: any;
  manualList: any;
  addRelatedProducts: Boolean = false;
  columns: ColumnSetting[] = [

    {
      primaryKey: 'Name',
      header: 'Product Name'
    },
    {
      primaryKey: 'ProductIdManual',
      header: 'Item Number'
    },
    {
      primaryKey: 'action',
      header: 'Action',
      format: 'action'
    }
  ];
  webshopDetail: any;
  showLoader: Boolean = false;
  constructor(private settingsHttpService: SettingsHttpService, private apiService: ApiService, public translate: TranslateService, public toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.manualList = null;
    this.manualID = '';
    this.productList = this.getProductRolesList;
  }

  deleteProduct(request) {
    debugger
    this.loaderStatus.emit(true);
    const req = {
      ProductGroupID: this.selectedRole.ProductGroupID,
      ProductID: request.ProductID
    }
    this.settingsHttpService.deleteProduct(req).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success("" + data);
        this.loadProductList.emit(true);
        this.loadPopupRole();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  searchManualID() {
    this.loaderStatus.emit(true);
    const loginData = this.apiService.getLoginData();
    let request = {
      "ProductIdManualExact": this.manualID,
      "CustomerID": this.webshopDetail.CustomerID,
      "DontShowDeleted": true
    }
    if (this.webshopDetail.ParentCustomerID === null || this.webshopDetail.ParentCustomerID === undefined || this.webshopDetail.ParentCustomerID === '') {
      request['IncludeChildCustomers'] = true;
    }
    this.settingsHttpService.searchManualID(request).subscribe((data: any) => {
      this.addRelatedProducts = false;
      this.loaderStatus.emit(false);
      if (data) {
        this.manualList = data.ItemsCollection;
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  addProductGroup(data) {
    debugger
    this.loaderStatus.emit(true);
    const request = {
      ProductGroupID: this.selectedRole.ProductGroupID,
      ProductID: data.ProductID,
      // addBatchProduct: this.addRelatedProducts
    }
    this.settingsHttpService.addProductGroup(request).subscribe((data: any) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success('Product(s) added to group');
        this.manualList = undefined;
        this.manualID = '';
        // this.addRelatedProducts = false;
        this.loadProductList.emit(true);
        this.loadPopupRole();
      }
      else {
        this.toast.error('Product cannot be added to Group. Check if product already has this role.');
      }
    },
      (error) => {
        if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
          let errorMessage = '';
          const objResponse = Object.values(error.error.ModelState);
          objResponse.forEach(errmessage => {
            errorMessage += errmessage;
          });
          this.toast.error(errorMessage);
        }
        this.loaderStatus.emit(false);
      })
  }

  loadPopupRole() {
    this.settingsHttpService.viewProductGroups(this.selectedRole.ProductGroupID).subscribe((group: any) => {
      if (group) {
        this.productList = group.ItemsCollection[0].Products;
      }
    },
      (error) => {

      })
  }
}
