import { NgModule } from '@angular/core';
import { FormatCellPipe } from './format-cell.pipe';

@NgModule({
    declarations: [FormatCellPipe],
    exports: [
        FormatCellPipe
    ]
})
export class LinkTablePipeModule { }
