import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, SimpleChanges, SimpleChange, OnChanges, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ControlValidationService } from '../../shared/control-validation/index';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/common-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { UnderSupplierHttpService } from '../state/services/undersupplier.http.service';

@Component({
  selector: 'rewardkart-admin-undersupplier-add',
  templateUrl: './undersupplier-add.component.html',
  styleUrls: ['./undersupplier-add.component.scss']
})
export class UndersupplierAddComponent implements OnInit {

  countriesList: any = [];
  optionsSelectCountries: any = [];
  optionsSelectCustomer: any = [];
  optionsSelectSupplier: any = [];
  underSupplierDetails: FormGroup;
  vatMandatoryFieldStatus: Boolean = false;
  showLoader: Boolean = false;
  public postalCodeErrorCount = 0;
  QualityLevel: string;
  itemId: number;
  rating: number;
  editData: any;
  webshopDetail: any;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(private undersupplierHttpService: UnderSupplierHttpService, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router, private renderer: Renderer2, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.loadForm();
  }

  ngOnInit() {
    this.getCountryList();
    /** to get Currencies list from Redux State */
    this.getCustomerlist()
    this.getSupplierlist()
    this.QualityLevel = this.itemId + '_rating';
  }
  onClick(rating: number): void {
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }
  resetRating() {
    this.rating = 0;
  }
  loadForm() {
    const editData = history.state.data;
    console.log("Load Form Edit Data : ", editData);
    this.editData = history.state.data;
    if (editData !== undefined && editData.QualityLevel !== undefined) {
      console.log("Inside QTY LEVEL : ", editData.QualityLevel);
      this.rating = Number(editData.QualityLevel);
    }
    this.underSupplierDetails = this.formBuilder.group({
      Name: [editData !== undefined ? editData.Name : '', Validators.required],
      ContactName: [editData !== undefined ? editData.ContactName : ''],
      Email: [editData !== undefined ? editData.Email : '', [Validators.required, ControlValidationService.emailValidator]],
      SupplierID: [editData !== undefined ? editData.SupplierID : '', Validators.required],
      PhoneNumber: [editData !== undefined ? editData.PhoneNumber : ''],
      MobileNumber: [editData !== undefined ? editData.MobileNumber : ''],
      Address: [editData !== undefined ? editData.Address : ''],
      City: [editData !== undefined ? editData.City : ''],
      PostalCode: [editData !== undefined ? editData.PostalCode : ''],
      Country: [editData !== undefined ? editData.Country : '', Validators.required],
      WebsiteURL: [editData !== undefined ? editData.WebsiteURL : '']
    });
  }

  isPhoneNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode < 40 || charCode > 57) || (charCode === 42) || (charCode === 44)) {
      return false;
    }
    return true;
  }

  /*** Get Country Items ***/
  getCountryList() {
    this.undersupplierHttpService.getAllCountries().subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCountries = [];
          this.optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          this.countriesList = [];
          this.countriesList = data;
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });
        }
      });
  }

  getCustomerlist() {
    this.undersupplierHttpService.getcustomerlist().subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCustomer = [];
          this.optionsSelectCustomer.push({ label: this.translate.instant('USERBUDGETEDIT.SELECTCUSTOMER'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.CustomerID,
              label: element.Name,
            };
            this.optionsSelectCustomer.push(langVal);
          });
        }
      });
  }

  getSupplierlist() {
    this.undersupplierHttpService.getCustomerSupplier(this.webshopDetail.CustomerID).subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectSupplier = [];
          this.optionsSelectSupplier.push({ label: this.translate.instant('USERBUDGETEDIT.SELECTSUPPLIER'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.SupplierID,
              label: element.SuppliersName,
            };
            this.optionsSelectSupplier.push(langVal);
          });
        }
      });
  }

  validateFields() {
    let validateStatus = true;
    // tslint:disable-next-line:max-line-length
    if ((this.underSupplierDetails.controls.Name.value === '') || (this.underSupplierDetails.controls.Email.value === '') || (this.underSupplierDetails.controls.Country.value === '')) {
      this.underSupplierDetails.controls['Name'].markAllAsTouched();
      this.underSupplierDetails.controls['Email'].markAllAsTouched();
      this.underSupplierDetails.controls['Country'].markAllAsTouched();
      validateStatus = false;
    }
    return validateStatus;
  }

  addUnderSupplierSubmit() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      const addUnderSupplierData = {
        Name: this.underSupplierDetails.value.Name,
        ContactName: this.underSupplierDetails.value.ContactName,
        Email: this.underSupplierDetails.value.Email,
        PhoneNumber: this.underSupplierDetails.value.PhoneNumber,
        MobileNumber: this.underSupplierDetails.value.MobileNumber,
        Address: this.underSupplierDetails.value.Address,
        PostalCode: this.underSupplierDetails.value.PostalCode,
        Country: this.underSupplierDetails.value.Country,
        CustomerID: this.webshopDetail.CustomerID,
        SupplierID: this.underSupplierDetails.value.SupplierID,
        City: this.underSupplierDetails.value.City,
        WebsiteURL: this.underSupplierDetails.value.WebsiteURL,
        QualityLevel: (this.rating !== null && this.rating !== undefined) ? Number(this.rating) : null
      };

      let country = '';
      let postalCode = '';
      if (this.underSupplierDetails.controls.Country.value !== undefined) {
        country = this.underSupplierDetails.controls.Country.value.trim();
      }
      if (this.underSupplierDetails.controls.PostalCode.value !== undefined) {
        postalCode = this.underSupplierDetails.controls.PostalCode.value.trim()
      }

      let postalcodeErrorExample = '';
      let postalcodeErrorIs = '';
      let postalcodeError = '';
      let postalcodeProceedText = '';

      this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
        postalcodeErrorExample = res;
      });
      this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
        postalcodeErrorIs = res;
      });
      this.translate.get('USER.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
        postalcodeError = res;
      });
      this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
        postalcodeProceedText = res;
      });

      this.validatePostalCodeCheck(country, postalCode).subscribe((data) => {
        let proceedStatus = false;
        if (data['ValidPostalRegex']) {
          proceedStatus = true;
        } else {
          this.postalCodeErrorCount += 1;
          if (Number(this.postalCodeErrorCount) > 1) {
            proceedStatus = true;
          } else {
            proceedStatus = false;
            const countryResponse = this.countriesList.find(item => item.Alpha2Code === country);
            if (data['VaildPostalcode'] !== '' && data['VaildPostalcode'] !== undefined) {
              const displayText = postalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;
              // tslint:disable-next-line:max-line-length
              this.toast.error(this.translate.instant('USERADDEDIT.ERROR') + ': ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus = true;
            }
          }
        }

        console.log("addUnderSupplierData : ", addUnderSupplierData);
        if (proceedStatus) {
          if (this.editData !== undefined) {
            addUnderSupplierData['UndersupplierID'] = this.editData.UndersupplierID;
          }
          this.showLoader = true;
          this.undersupplierHttpService.addUnderSupplier(addUnderSupplierData).subscribe((data: any) => {
            this.showLoader = false;
            if (data) {
              if (this.editData !== undefined) {
                this.toast.success('Under Supplier updated successfully');
              } else {
                this.toast.success('Under Supplier added successfully');
              }
              this.router.navigate(['/admin-undersupplier-list'], { replaceUrl: false });
            }
          },
            err => {
              let errMsg = '';
              this.showLoader = false;
              if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
                for (var key in err.error.ModelState) {
                  errMsg += err.error.ModelState[key] + ' ';
                }
              } else {
                errMsg += err.error;
              }
              this.toast.error(errMsg);
            });
        }
      });
    }
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    var subject = new Subject<any>();
    this.undersupplierHttpService.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  redirectToUnderSupplierList() {
    this.router.navigate(['/admin-undersupplier-list'], { replaceUrl: false });
  }

  websiteURL() {
    if (this.underSupplierDetails.value.WebsiteURL !== '') {
      if (this.validURL(this.underSupplierDetails.value.WebsiteURL)) {
        window.open(this.underSupplierDetails.value.WebsiteURL, "_blank");
      } else {
        this.toast.error('Enter Valid URL(incl http:// or https://)');
      }
    } else {
      this.toast.error('Enter Valid URL(incl http:// or https://)');
    }
  }

  validURL(str) {
    // const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    //   '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    // return !!pattern.test(str);
    const RegExp1 = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (RegExp1.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  addSupplierDetails() { }

}
