import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/common-services/api.service';
import { LoginService } from '../../login/state/services/login.service';

@Injectable()
export class CategoryService {
    webshopDetail: any;
    constructor(private apiService: ApiService, private loginService: LoginService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getCategoryList() {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        let path;
        if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
            this.loginService.loginSuccess().subscribe(data => {
                if (data) {
                    const customerID = this.webshopDetail.CustomerID;
                    path = 'en/categories?isOrderbyname=true&deleted=true&pageSize=200&CustomerID=' + customerID;
                }
            });
        } else {
            if (sessionStorage.getItem('userdetails') !== null) {
                const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
                const customerID = this.webshopDetail.CustomerID;
                path = 'en/categories?isOrderbyname=true&deleted=true&pageSize=200&CustomerID=' + customerID;
            }
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomerCategoryList(customerID: any) {
        const path = 'en/categories?isOrderbyname=true&deleted=true&pageSize=200&CustomerID=' + customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    saveCategory(data: any) {
        const path = 'en/productcategorys/' + data.ProductCategoryID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    categoryDropdown() {
        const path = 'en/categories?isOrderbyname=true&deleted=true&customerID=null';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    supplierDropdown(customerId = null) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        let queryparam = '';
        if (customerId !== null && customerId !== '') {
            if (Number(customerId) === 0) {
                customerId = null;
            }
            // tslint:disable-next-line:max-line-length
            queryparam += '?returnOnlyCustomerSupplier=true&CustomerId=' + customerId + '&LoggedInCustomerID=' + this.webshopDetail.CustomerID;
        }
        const path = 'en/suppliers' + queryparam;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    getCustomerSupplier(customerId = null) {
        const path = 'en/suppliers/CustomerSupplier?isOrderbyname=true&CustomerId=' + customerId;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    setCustomerSupplier(data: any) {
        const path = 'en/suppliers/CustomerSupplier';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }


    supplierDropdownWithPagination(search, customerId = null) {
        let queryparam = `?pageIndex=${search.PageIndex}&pageSize=${search.PageSize}&Name=${search.SupplierID ? search.SupplierID : ''}`;
        if (customerId !== null && customerId !== '') {
            if (Number(customerId) === 0) {
                customerId = null;
            }
            queryparam += '&returnOnlyCustomerSupplier=true&CustomerId=' + customerId;
        }

        const path = 'en/suppliers' + queryparam;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }



}
