import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerEnquiryDetailComponent } from './bannerenquiry-detail.component';

@NgModule({
    declarations: [BannerEnquiryDetailComponent],
    imports: [
        CommonModule
    ]
})

export class BannerEnquiryDetailModule { }