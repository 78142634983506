import { Component, OnInit } from '@angular/core';
import { UserHttpService } from '../state/services/user.http.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'link-admin-emailconfig',
  templateUrl: './emailconfig.component.html',
  styleUrls: ['./emailconfig.component.scss']
})
export class EmailconfigComponent implements OnInit {


  CustomerID: any;
  customerList: any;
  webshopOnlineProductCustomerList = [];
  webshopDetail: any;
  searchForm: FormGroup;
  autoGeneratedEmails: any;
  userID:any;


  constructor(private _http:UserHttpService, public apiService: ApiService, private toastr: ToastService) { 
    const customerUserID = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.userID = customerUserID.CustomerID;
    console.log("customer user id",this.userID);
   }

  ngOnInit() {
    this.searchForm = new FormGroup({
      CustomerID: new FormControl(''),
      Inquiry:new FormControl(''),
      placeOrderParent:new FormControl(''),
      placeOrder:new FormControl(''),
      UserParentshop:new FormControl(''),
      UserChildshop:new FormControl(''),
      Rewardpoints:new FormControl(''),
      ProductInquiry:new FormControl(''),
    })
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.getCustomerList();
    this.getAutoGeneratedEmails();
  }

  getCustomerList(){
    const isParentWebshop = JSON.parse(sessionStorage.getItem('isParentWebshop'));
    this._http.getCustomerList(this.userID).subscribe((data:any) => {
      this.customerList = data;
      this.webshopOnlineProductCustomerList = data;
      const webshopData = [];
      this.webshopOnlineProductCustomerList.forEach(option => {
        webshopData.push({ label: option.Name, value: option.CustomerID })
      });
      this.webshopOnlineProductCustomerList = webshopData;
      this.searchForm.patchValue({
        CustomerID: this.webshopDetail.CustomerID,
        relatedProducts: true
      });
    })
  }

  getAutoGeneratedEmails(){
    this._http.getAutoGeneratedEmails(this.userID).subscribe((data:any) => {
      if(data.IsSuccess){
        this.autoGeneratedEmails = data;
        this.searchForm.patchValue({
          CustomerId:this.autoGeneratedEmails.CustomerId,
          Inquiry:this.autoGeneratedEmails.CSEnquiry,
          placeOrderParent:this.autoGeneratedEmails.OrderParent,
          placeOrder:this.autoGeneratedEmails.OrderChild,
          UserParentshop:this.autoGeneratedEmails.UserParent,
          UserChildshop:this.autoGeneratedEmails.UserChild,
          Rewardpoints:this.autoGeneratedEmails.Rewardpoints,
          ProductInquiry:this.autoGeneratedEmails.ProductEnquiry
        })
      }
    })
  }

  updateCustomer(){
    let params = {
      "CustomerId":this.searchForm.value.CustomerID,
      "Rewardpoints":this.searchForm.value.Rewardpoints,
      "CSEnquiry":this.searchForm.value.Inquiry,
      "OrderParent":this.searchForm.value.placeOrderParent,
      "OrderChild":this.searchForm.value.placeOrder,
      "UserParent":this.searchForm.value.UserParentshop,
      "UserChild":this.searchForm.value.UserChildshop,
      "ProductEnquiry":this.searchForm.value.ProductInquiry,
  }
  if(!this.searchForm.value.CustomerID){
    this.toastr.error("Please Select the Customer Name");
    return;
  }
  this._http.postCustomerList(params).subscribe((data:any) => {
    if(data){
      this.toastr.success("Success");
      // this.searchForm.reset();
      this.getAutoGeneratedEmails();
    }else{
      this.toastr.error("Something Went Wrong")
    }
  })
  }

}
