import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFields',
  // pure: false
 })
 export class SearchFieldsPipe implements PipeTransform {
  // storedValue = []
 
  transform(value: any[], myvalue:any): any {
    if(myvalue==undefined || myvalue=='' || value.length==0)
    {
      return value;
    }
    var tempArr = [];
 
    for(var i = 0; i<value.length; i++)
    {
      // debugger
      if(value[i].PropertyValue.includes(myvalue))
      {
        tempArr.push(value[i]);
      }
    }

    return tempArr;
  }
  
 
 }
