import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { UserService } from 'src/app/user/state/services/user.service';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import { ProductEditComponent } from '../../product-edit.component';
import { ProductGovernmentalcostsComponent } from '../product-governmentalcosts.component';
import * as _ from 'lodash';
import { debug } from 'util';
import { ApiService } from 'src/app/shared/common-services/api.service';
@Component({
  selector: 'app-product-manage-governmentalcosts',
  templateUrl: './product-manage-governmentalcosts.component.html',
  styleUrls: ['./product-manage-governmentalcosts.component.scss']
})

export class ProductManageGovernmentalcostsComponent implements OnInit, OnChanges {
  colorPickerChange: any;
  productManageGovernmentalCosts: FormGroup;
  code: any;
  name: any;
  optionsSelectParentColor: any = [{
    value: '',
    label: '',
  }];
  allColors: any = [];
  color: any;
  activeLanguage: any;
  showcolorname: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English'
  @Input('resetForm') resetForm: Boolean
  @Input('productGovernmentalCostsForm') productGovernmentalCostsForm: any
  @Input() productDetails: any
  @Output() showManageColor = new EventEmitter<any>();
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Output() loadSizeDropdown = new EventEmitter<any>();
  @Input() loadApi: Boolean
  subscribeMethod: boolean = false;
  optionsSelectCurrencies: any[];
  optionsSelectCountries: any[];
  optionsSelectTypes: any[];
  productGovtCosts: any;
  loadApiFlag: Boolean = false
  toaster: boolean;
  deletetoaster: boolean;
  updatetoaster: boolean;
  toasteradd: boolean;
  showLoader: Boolean = false;
  // toasteradd: any;
  getGovenmentalCostDatats: any = [];
  searchGovenmentalCostName: any;
  govenmentalCostList: any;
  webshopDetail: any;
  constructor(public formBuilder: FormBuilder,
    private totalData: ProductEditComponent,
    private showmodal: ProductGovernmentalcostsComponent,
    public productService: ProductService,
    public productHttpService: ProductHttpService,
    public apiService: ApiService,
    public userService: UserService,

    public toast: ToastService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFilter()
    }
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.userService.loadCountries();
      this.userService.loadCurrencies();
      this.productService.loadProductAdditionalCostTypes();
      this.getCurrencies();
      this.getCountries();
      this.getAdditionalCostTypes();
    }
  }




  ngOnInit() {
    this.buildForm();
    this.getAllProductGvtCosts();
    this.userService.loadCountries();
    this.userService.loadCurrencies();
    this.productService.loadProductAdditionalCostTypes();
    this.getCurrencies();
    this.getCountries();
    this.getAdditionalCostTypes();
    // this.getAllProductColors();
  }

  getCurrentLang(lang) {
    this.selectedLang = lang.label;
  }


  getCurrencies() {
    this.userService.getAllCurrencies().subscribe(
      data => {
        if (data) {
          this.optionsSelectCurrencies = [{ label: 'Select currency', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            this.optionsSelectCurrencies.push(langVal);
          });
        }
      });
  }

  getAdditionalCostTypes() {
    this.productService.getProductAdditionalCostTypes().subscribe(
      data => {
        if (data) {
          this.optionsSelectTypes = [{ label: 'Select type', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.ProductAdditionalCostTypeID,
              label: element.Name,
            };
            this.optionsSelectTypes.push(langVal);
          });
        }
      });
  }

  getCountries() {
    this.userService.getAllCountries().subscribe(
      data => {
        if (data) {
          this.optionsSelectCountries = [{ label: 'Select country', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });
        }
      });
  }


  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let translationFieldJson = [
      {
        formcontrolname: 'GovernmentalCostName',
        label: 'Name',
        PropertyName: "GovernmentalCostName",
      }
    ];

    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: 'GovernmentalCostName' + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })

    let group: any = {
      ProductGovernmentalCostID: new FormControl(),
      SupplierID: new FormControl(),
      Country: new FormControl(),
      DefaultPrice: new FormControl(),
      DefaultCurrencyID: new FormControl(),
      DefaultProductAdditionalCostTypeID: new FormControl(),
      DefaultIsOptionalForBuyer: new FormControl(),
      Name: new FormControl(),
      language: new FormControl(1),
      items: this.formBuilder.array([])
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.productManageGovernmentalCosts = new FormGroup(group);
  }

  createItem(govtCostVal?): FormGroup {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    this.translateFormItems = [];
    const tempArray = []
    getlang.forEach(lang => {
      let isFound = govtCostVal.Translations.find(data => data.LanguageCode == lang.Code);
      let formJson;
      if (isFound) {
        formJson = {
          formcontrolname: 'GovernmentalCostName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: isFound.PropertyName,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: isFound.PropertyName,
          PropertyValue: isFound.PropertyValue,
          TranslationID: isFound.TranslationID,
          ObjectID: isFound.ObjectID
        }
      } else {
        formJson = {
          formcontrolname: 'GovernmentalCostName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: '',
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: '',
          PropertyValue: '',
          TranslationID: '',
          ObjectID: ''
        }
      }
      tempArray.push(formJson);
    });
    this.translateFormItems = tempArray

    let group: any = {
      ProductGovernmentalCostID: govtCostVal.ProductGovernmentalCostID,
      SupplierID: govtCostVal.SupplierID,
      Country: govtCostVal.Country,
      DefaultPrice: govtCostVal.DefaultPrice,
      DefaultCurrencyID: govtCostVal.DefaultCurrencyID,
      DefaultProductAdditionalCostTypeID: govtCostVal.DefaultProductAdditionalCostTypeID,
      DefaultIsOptionalForBuyer: govtCostVal.DefaultIsOptionalForBuyer,
      Name: govtCostVal.Name,
    }
    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })
    return this.formBuilder.group({ ...group });
  }


  getAllProductGvtCosts() {
    this.showLoader = true;
    this.subscribeMethod = false;
    this.items = this.productManageGovernmentalCosts.get('items') as FormArray;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    let subscription;
    // this.productService.loadAllProductGovtCosts(requestData);
    if (!this.subscribeMethod) {
      // subscription = this.productService.getAllProductGovtCostsList().subscribe((data) => {
      this.productHttpService.getAllProductGovtCosts(requestData).subscribe((data: any) => {
        if (data !== null) {
          this.govenmentalCostList = data
          this.getGovenmentalCostDatats = data
          this.subscribeMethod = true;
          this.showLoader = false;
          data.forEach(element => {
            this.items.push(this.createItem(element));
          });
        }
      });
    }
    if (this.subscribeMethod) {
      subscription.unsubscribe();
    }
  }
  searchGovenmenetalCost() {
    let getResult = [];
    const getData = this.getGovenmentalCostDatats;
    getData.filter((data, index) => {
      data.Translations.filter((ele) => {
        let value = (ele.PropertyValue).toLowerCase();
        let searchData = (this.searchGovenmentalCostName).toLowerCase();
        if ((value).includes(searchData)) {
          getResult.push(data)
        }
      })
    });
    const filterResult = _.uniq(getResult, 'AdditionalCost');
    this.clearFormItems();
    filterResult.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.govenmentalCostList = filterResult;
  }
  resetFilter() {
    this.searchGovenmentalCostName = '';
    this.clearFormItems();
    this.getGovenmentalCostDatats.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.govenmentalCostList = this.getGovenmentalCostDatats;
  }
  clearFormItems() {
    if (this.productManageGovernmentalCosts) {
      const control = <FormArray>this.productManageGovernmentalCosts.controls['items'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i)
      }
    }
  }

  addGovtCosts() {
    this.toasteradd = true
    let translations = [];

    let flag = true


    this.translateFormItems.forEach(item => {
      if (item.LanguageCode === 'en') {
        if (!this.productManageGovernmentalCosts.value[item.formcontrolname] && flag) {
          flag = false
        }
      }
      const data = {
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectType: "ProductGovernmentalCosts",
        PropertyName: item.name,
        PropertyValue: this.productManageGovernmentalCosts.value[item.formcontrolname],
        Action: "Insert"
      }
      translations.push(data);
    });

    const { Country, DefaultPrice, DefaultCurrencyID, DefaultProductAdditionalCostTypeID } = this.productManageGovernmentalCosts.value


    if (!(flag && Country && DefaultCurrencyID && DefaultProductAdditionalCostTypeID)) {
      this.toast.error("Please fill the Required fields")
      // if (!flag) {
      //   this.toast.error("Govermental Cost Name is Required") 
      // } else if (!DefaultPrice) {
      //   this.toast.error("Default Price is Required")
      // } else if (!Country) {
      //   this.toast.error("Country is Required")
      // } else if (!DefaultCurrencyID) {
      //   this.toast.error("Currency is Required")
      // } else if (!DefaultProductAdditionalCostTypeID) {
      //   this.toast.error("Type is Required")
      // }

      return null
    }

    let requestData = {
      SupplierID: this.productDetails.SupplierID,
      Country: this.productManageGovernmentalCosts.value.Country,
      DefaultPrice: this.productManageGovernmentalCosts.value.DefaultPrice !== undefined && this.productManageGovernmentalCosts.value.DefaultPrice !== null && (this.productManageGovernmentalCosts.value.DefaultPrice.toString()).includes(',') ? this.productManageGovernmentalCosts.value.DefaultPrice.replace(',', '.') : this.productManageGovernmentalCosts.value.DefaultPrice,
      DefaultCurrencyID: this.productManageGovernmentalCosts.value.DefaultCurrencyID,
      DefaultProductAdditionalCostTypeID: this.productManageGovernmentalCosts.value.DefaultProductAdditionalCostTypeID,
      DefaultIsOptionalForBuyer: this.productManageGovernmentalCosts.value.DefaultIsOptionalForBuyer,
      Translations: translations
    }

    // this.productService.addProductGovernmentalCosts(requestData);
    this.showLoader = true;
    this.productHttpService.addProductGovernmentalCosts(requestData).subscribe((data) => {
      this.showLoader = false;
      if (data !== null) {
        this.clearFormItems();
        this.getAllProductGvtCosts();
        this.clearValue();
        this.subscribeMethod = false;
      }
      this.loadParentDropDown()
      if (this.toasteradd) {
        this.toast.success('Product governmental cost added successfully');
        this.toasteradd = false
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.ProductGovernmentalCosts !== null && err.error.ModelState.ProductGovernmentalCosts !== undefined) {
            this.toast.error(err.error.ModelState.ProductGovernmentalCosts);
          }
        }
      });

    //  this.totalData.ngOnInit()
    //  this.totalData.loadTranslate1()
    //  this.showmodal.triggerFalseClick()
    // this.showmodal.showmodals()
    this.loadapi()
  }

  clearValue() {
    this.translateFormItems.forEach(data => {
      this.productManageGovernmentalCosts.get(data.formcontrolname).patchValue('');
    });
    this.productManageGovernmentalCosts.patchValue({
      Country: '',
      DefaultPrice: '',
      DefaultCurrencyID: '',
      DefaultProductAdditionalCostTypeID: '',
      DefaultIsOptionalForBuyer: false
    })
  }

  updateGovtCosts(productGovtCosts) {
    this.updatetoaster = true
    let translations = [];
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: productGovtCosts.value[item.translationID],
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: productGovtCosts.value[item.objectID],
        ObjectType: "ProductGovernmentalCosts",
        PropertyName: item.name,
        PropertyValue: productGovtCosts.value[item.formcontrolname],
        Action: "Update",
      }
      translations.push(data);
    })
    const requestData = {
      parentId: productGovtCosts.value.ProductGovernmentalCostID,
      data: {
        SupplierID: this.productDetails.SupplierID,
        Country: productGovtCosts.value.Country,
        DefaultPrice: productGovtCosts.value.DefaultPrice !== undefined && productGovtCosts.value.DefaultPrice !== null && (productGovtCosts.value.DefaultPrice.toString()).includes(',') ? productGovtCosts.value.DefaultPrice.replace(',', '.') : productGovtCosts.value.DefaultPrice,
        DefaultCurrencyID: productGovtCosts.value.DefaultCurrencyID,
        DefaultProductAdditionalCostTypeID: productGovtCosts.value.DefaultProductAdditionalCostTypeID,
        DefaultIsOptionalForBuyer: productGovtCosts.value.DefaultIsOptionalForBuyer,
        ColorCode: productGovtCosts.value.colorCode,
        Translations: translations
      }
    }
    // this.productService.updateProductGovernmentalCosts(requestData);
    this.showLoader = true;
    this.productHttpService.updateProductGovernmentalCosts(requestData).subscribe((data) => {
      this.showLoader = false;
      if (data !== null) {
        this.clearFormItems();
        this.getAllProductGvtCosts();
      }
      this.loadParentDropDown()
      if (this.updatetoaster) {
        this.toast.success('Product governmental cost Updated successfully');
        this.updatetoaster = false
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);

      });

  }

  deleteGovernmentalCosts(productGovtCosts) {
    // ProductGovernmentalCostID
    //productGovernmentalCostsForm

    let isToBeDeleted = true

    if (this.productGovernmentalCostsForm instanceof Array) {
      isToBeDeleted = !(this.productGovernmentalCostsForm.some(cost => {
        return cost.value.governmentalCost === productGovtCosts.value.ProductGovernmentalCostID
      }))
    }
    if (isToBeDeleted) {
      this.productGovtCosts = productGovtCosts;
      this.deleteModal.show();
    } else {
      this.toast.error("This Option already Mapped. Cannot be Deleted")
    }





  }


  getDeleteStatus(status) {
    this.deletetoaster = true
    if (status) {
      const ProductGovernmentalCostID = this.productGovtCosts.value.ProductGovernmentalCostID;
      // this.productService.deleteProductGovernmentalCosts(ProductGovernmentalCostID);
      this.productHttpService.deleteProductGovernmentalCosts(ProductGovernmentalCostID).subscribe((data) => {
        if (data !== null) {
          this.clearFormItems();
          this.getAllProductGvtCosts();
        }
        this.loadParentDropDown()
      });

      if (this.deletetoaster) {
        this.toast.success('Product governmental cost deleted successfully');
        this.deletetoaster = false
      }
      this.deleteModal.hide();
    } else {
      this.deleteModal.hide();
    }
  }
  loadapi() {
    this.productHttpService.getProductGovernmentalCosts().subscribe((data: any) => {
    });
  }
  // for default price lock
  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Negative values are not accepted here')
      return false
    }
    else {
      return true
    }
  }
  loadParentDropDown() {
    this.loadSizeDropdown.emit()
  }

  setmaxlength(price, controlName, index?) {
    const priceLength = price.target.value.length;
    let priceValue = price.target.value;
    if (Number(priceLength) > Number(12)) {
      price.target.value = priceValue.slice(0, -1);
      if (index !== undefined) {
        this.items.controls[index]['value']['DefaultPrice'] = priceValue.slice(0, -1);
      }
    }
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }


}
