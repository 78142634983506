import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductPricesComponent } from './product-prices.component';
import { MDBBootstrapModulePro, MDBBootstrapModule, WavesModule, ButtonsModule } from 'ng-uikit-pro-standard';
import { TranslateModule } from '@ngx-translate/core';
import { ManagePrintPriceModule } from './manage-print-price/manage-print-price.module';
import { ManageLogoTypeComponent } from './manage-logo-type/manage-logo-type.component';
import { ManageLogoTypeModule } from './manage-logo-type/manage-logo-type.module';
import { LinkTableModule } from 'src/app/shared/link-table/link-table.module';
import { CustomdirectiveModule } from '../customdirective/customdirective.module';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    ManagePrintPriceModule,
    ManageLogoTypeModule,
    MDBBootstrapModulePro, MDBBootstrapModule, ButtonsModule, WavesModule,
    LinkTableModule,
    CustomdirectiveModule,
    DropdownPopupModule
  ],
  declarations: [ProductPricesComponent],
  exports: [ProductPricesComponent]
})
export class ProductPricesModule { }