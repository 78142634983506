import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { CampaignActions } from '../actions/index';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import { State } from '../reducers/index';
import { CampaignHttpService } from '../services/campaign.http.service';

@Injectable()
export class CampaignEffects {
  /* @Effect()
   getCampaignList$: Observable<Action> = this.actions$.pipe(
     ofType(CampaignActions.LOAD_CAMPAIGNS),
     withLatestFrom(this.store$),
     switchMap(([payload]: any) => {
       return this.campaignHttpService.getCampaignList().map((data) => {
         // data preparation done here... image to be made image tag, etc.
         return new CampaignActions.LoadCampaignSuccess(data);
       });
     }),
     catchError((error: any) => {
       return Observable.of(new CampaignActions.LoadCampaignFail(error));
     })
   );*/

  @Effect()
  saveCampaign$: Observable<Action> = this.actions$.pipe(
    ofType(CampaignActions.SAVE_CAMPAIGN),
    withLatestFrom(this.store$),
    switchMap(([payload]: any) => {
      return this.campaignHttpService
        .saveCampaign(payload.payload)
        .map((data) => {
          return new CampaignActions.SaveCampaignSuccess(data);
        });
    }),
    catchError((error: any) => {
      return Observable.of(new CampaignActions.SaveCampaignFail(error));
    })
  );

  @Effect()
  uploadFile$: Observable<Action> = this.actions$.pipe(
    ofType(CampaignActions.FILE_UPLOAD),
    withLatestFrom(this.store$),
    switchMap(([payload]: any) => {
      return this.campaignHttpService.uploadFile(payload.payload).map((data) => {
        return new CampaignActions.LoadFileUploadSuccess(data);
      });
    }),
    catchError((error: any) => {
      return Observable.of(new CampaignActions.LoadFileUploadFail(error));
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private campaignHttpService: CampaignHttpService
  ) { }
}
