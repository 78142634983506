import { Component, OnInit } from '@angular/core';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { Router } from '@angular/router';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';

@Component({
  selector: 'link-admin-currrency',
  templateUrl: './currrency.component.html',
  styleUrls: ['./currrency.component.scss']
})
export class CurrrencyComponent implements OnInit {
  isLoading: boolean = false;
  searchCriteria: any = {};
  rowPagepage: number = 10;
  activePage: number = 1;
  filterData: any = {};
  columns: ColumnSetting[] = [];
  webshopdatacount: any;
  usageReport:any;
  header:string = "Currency Table";
  perPagedata:number = 10;
  enablepagination:boolean = true;
  filterFields: any = [];
  tableEvent: any;
  submitted = false;
  actions: any = ['edit'];
  constructor(private http:WebshopHttpService, private toastr:ToastService, private router: Router,private linkTableService: LinkTableService,) { }

  ngOnInit() {
    this.onLoadParentshopList();
  }

  getCurrency(creteria) {
    this.isLoading = true;
    let params = {
      "activePage": this.activePage,
      "pageSize": (creteria && creteria.pageIndex !== null && creteria.pageIndex !== undefined) ? creteria.pageIndex : 10
    }
    this.http.getAllCurrencies(params).subscribe({
      next: (data:any) => {
        this.isLoading = false;
        if(data){
          this.usageReport = data;
          this.columns = [
            {
                primaryKey: "Name",
                header: 'Name',
            },
            {
                primaryKey: "Code",
                header: 'Code'
            },
            {
                primaryKey: "Symbol",
                header: 'Symbol'
            },
            {
                primaryKey: "active",
                header: 'Active'
            },
            {
              primaryKey: 'action',
              format: 'action',
            }
        ]
        this.webshopdatacount = data[0].TotalCount;
        }else{
          this.usageReport = "";
        }
      }, error : (e:any) => {
        this.toastr.error(e.error.Message);
        this.isLoading = false;
      }
     })
  }

  onLoadParentshopList() {
    const searchkey = sessionStorage.getItem('searchWebshopListKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
        this.getCurrency(JSON.parse(searchkey));
    } else {
        this.searchCriteria = {
            childShopId: '',
            pageSize: 10,
            rowpageIndex: this.rowPagepage,
            activePage: this.activePage,
            pageIndex: 10
        };
        this.filterData = this.searchCriteria;
        sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
        // this.userService.loadUsers(this.searchCriteria);
        this.getCurrency(this.searchCriteria);
    }
}

getPerPage(event) {
  console.log("Get Per Page : ", event);
  this.perPagedata = event;
  this.searchCriteria.pageIndex = event;
  this.getSubmit();
}
getSubmit() {
  this.activePage = 1;
  this.searchCriteria.activePage = this.activePage;
  this.filterData = this.searchCriteria;
  this.getCurrency(this.filterData);
  sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
  sessionStorage.setItem('searchWebshopListResult', JSON.stringify(this.filterFields));
}

paginationNum(event) {
  console.log("paginationNum : ", event);
  this.activePage = event;
  const searchkey = sessionStorage.getItem('searchWebshopListKey');
  if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
  }
  this.searchCriteria.activePage = event;
  this.perPagedata = this.searchCriteria.pageIndex;
  this.getCurrency(this.searchCriteria);
}

editCampaign(event) {
  try {
    if (event) {
      this.tableEvent = this.linkTableService.getSelectedRow().subscribe((selectedRow) => {
        if (selectedRow) {
          this.router.navigate(['/addcurrency'], {
            state: {editCurrency : selectedRow},
            replaceUrl: true,
          });
        }
      });
    }
  } catch (error) { }
  this.submitted = event;
}

addCurrency(){
  this.router.navigate(['/addcurrency'], {replaceUrl: true});
}

}
