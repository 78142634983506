import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderProcessViewDetailsComponent } from './order-process-view-details.component';
import { OrderProcessInfoContainerComponent } from './order-process-info-container/order-process-info-container.component';
import { OrderItemListComponent } from './order-item-list/order-item-list.component';
import { OrderItemsOverviewComponent } from '../order-items-overview/order-items-overview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'ng-uikit-pro-standard';
import { OrderLogosComponent } from './order-item-list/order-logos/order-logos.component';
import { OrderPriceTableComponent } from './order-item-list/order-price-table/order-price-table.component';
import { OrderProfitComponent } from './order-process-info-container/order-profit/order-profit.component';
import { OrderItemSummaryComponent } from './order-item-list/order-item-summary/order-item-summary.component';
import { CaptureAmountComponent } from './order-item-list/capture-amount/capture-amount.component';
import { OrderCancelComponent } from './order-item-list/order-cancel/order-cancel.component';
import { OrderOldProfomaInvoiceComponent } from './order-old-profoma-invoice/order-old-profoma-invoice.component';
import { InternalNoteComponent } from './order-process-info-container/internal-note/internal-note.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderProcessUnauthorisedDetailsComponent } from './order-process-unauthorised-details/order-process-unauthorised-details.component';
import { UnauthorisedModalComponent } from './order-process-unauthorised-details/unauthorised-modal/unauthorised-modal.component';
import { OrderProcessDeletedOrdersComponent } from './order-process-deleted-orders/order-process-deleted-orders.component';
import { UpdateSubOrder } from './update-suborder-cost/update-suborder-cost.component'

@NgModule({


  declarations: [
    OrderProcessViewDetailsComponent,
    OrderItemListComponent,
    OrderPriceTableComponent,
    OrderItemsOverviewComponent,
    OrderProcessInfoContainerComponent,
    OrderLogosComponent,
    OrderProfitComponent,
    OrderItemSummaryComponent,
    CaptureAmountComponent,
    OrderCancelComponent,
    OrderOldProfomaInvoiceComponent,
    InternalNoteComponent,
    OrderProcessUnauthorisedDetailsComponent,
    UnauthorisedModalComponent,
    OrderProcessDeletedOrdersComponent,
    UpdateSubOrder,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MDBBootstrapModule.forRoot()
  ],
  entryComponents: [OrderProfitComponent, OrderCancelComponent, CaptureAmountComponent,
    InternalNoteComponent, OrderOldProfomaInvoiceComponent, UnauthorisedModalComponent, UpdateSubOrder]
})
export class OrderProcessViewDetailsModule { }
