import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PageControlComponent } from './page-control.component';

@NgModule({
  imports: [
    CommonModule,
    // BrowserModule
  ],
  declarations: [PageControlComponent],
  exports: [
    CommonModule,
    PageControlComponent
  ]
})

export class PageControlModule {
}
