import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as _ from 'lodash';
@Component({
  selector: 'link-app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss']
})
export class CommonFormComponent implements OnInit {
  @Input() formFields: any;
  @Input() actions: any;
  @Input() formTypeName: any;
  @Output() userRegisterFormData = new EventEmitter<any>();
  columnSplit: any;

  userRegisterData: any;
  userRegister: FormGroup;
  invoiceDeliveryAddress: any;
  invoiceDeliveryAddress1: any;
  vatImageForm: any;
  formName: FormGroup;
  formTemplate: any = '';
  formDisplayStatus: Boolean = false;
  isChecked: Boolean = false;
  // @Input() formCounts: any;
  // loadOption: any = [{ label: 'Arun', valuesa: 'arung' }]
  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    // this.columnSplit = this.formFields.columnDesign;

    this.formName = this.formTypeName;
    /** Form Control Assign to Form Group */
    if (this.formTypeName === 'userRegister') {
      this.userRegister = this.createGroup();
    }

    this.formDisplayStatus = true;
  }


  createGroup() {
    const group = this.formBuilder.group({});
    this.formFields.forEach(control =>
      group.addControl(control.fieldName, this.formBuilder.control('', [this.requireMatch(control).bind(this)]))
    );
    return group;
  }

  requireMatch = (controlName) => {
    return (control: FormControl) => {
      const validators = [];
      // tslint:disable-next-line:max-line-length
      if (controlName.isMandatory && controlName.fieldName !== 'next' && controlName.fieldName !== 'prev' && controlName.fieldName !== 'submit') {
        // validators.push(Validators.required);
      }
      /** validation for email Field */
      if (controlName.isEmailField !== undefined && controlName.isEmailField !== null && controlName.isMandatory) {
        // validators.push(Validators.minLength(5));
      }
      return validators.length > 0 ? { validators } : null;
    }
  }

  nextButtonClick(formName, event) {
    event.preventDefault();
    if (formName === 'userRegister') {
      const data = {
        buttonName: 'next',
        formvalue: this.userRegister.value
      }
      this.userRegisterFormData.emit(data);
    }
  }

  prevButtonClick(formName, event) {
    event.preventDefault();
    if (formName === 'userRegister') {
      // console.log(this.userRegister);
    }
  }

  formSubmit(formName, event) {
    event.preventDefault();
    if (formName === 'userRegister') {
      // console.log(this.userRegister);
    }
  }

  checkboxDataValidate(fieldName) {
    this.isChecked = !this.isChecked;
    if (fieldName === 'isSameAsInvoiceAddress') {
      this.userRegister.patchValue({
        DeliveryCompanyName: this.userRegister.value['CompanyName']
      });
    }
  }

}
