import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OrderProcessService {
    defaultSearch = {
        CustomerID: 0,
        SupplierID: '',
        UndersupplierID: '',
        invoiceCountryCode: '',
        deliveryCountryCode: '',
        orderIdManual: '',
        documentNumber: '',
        carrier: '',
        trackingNumber: '',
        userName: '',
        orderRecipient: '',
        productIdManual: '',
        productName: '',
        orderPlacedFromDate: '',
        orderPlacedToDate: '',
        deliveryDate: '',
        paymentTypeID: '',
        sampleorder: false,
        finishedOrders: false,
        unfinishedOrders: false,
        onlyUnauthorisedOrders: false,
        onlyDeletedOrders: false,
        onlyOrdersWithoutTransportation: false,
        onlyInternalStockOrders: false,
        onlyExpressOrders: false,
        onlyInternalOrders: false
    };
    defaultPageLimit = {
        pageSize: 30,
        pageIndex: 1
    };
    updatedSearch: any = {};
    updatedPageLimit: any = {};
    defaultAdvanceSearchPayload: any = [];
    updateAdvanceSearchPayload: any = [];
    includChildShopOrders = false;
    constructor() {
        this.updatedSearch = { ...this.defaultSearch };
        this.updatedPageLimit = { ...this.defaultPageLimit };
        this.updateAdvanceSearchPayload = this.defaultAdvanceSearchPayload;
    }

    updateSearchCriteria(searchData) {
        this.updatedSearch = searchData;
    }

    updateAdvanceSearchPayloadValue(advanceSearchPayload) {
        this.updateAdvanceSearchPayload = advanceSearchPayload;
    }
}
