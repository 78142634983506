import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductCategoryInfoComponent } from './product-category-info.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CommonFormModule,
    SharedModule,
    TranslateModule,
    DropdownPopupModule
  ],
  declarations: [ProductCategoryInfoComponent],
  exports: [ProductCategoryInfoComponent]
})

export class ProductCategoryInfoModule { }

