import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ReportsHttpService } from '../../reports/services/reports-http.service';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';

@Component({
    selector: 'app-dashboard-user-management-report',
    templateUrl: './dashboard-user-management-report.component.html',
    styleUrls: ['./dashboard-user-management-report.component.scss']
})

export class DashboardUserManagementComponent implements OnInit {
    webshop: any;
    isParentCustomer: boolean = true;
    domainName: any = '';
    campaignList: any;
    campaignID: any = '';
    fromDate: any;
    toDate: any;
    date = new Date();
    datePickerOptions: IMyOptions = {};
    enddatePickerOptions: IMyOptions = {};
    activePage: number = 1;
    childShop: any;
    childwebshop: any;
    showLoader: boolean = false;
    userName: any = '';
    customerName: any = '';
    userReport: any;
    pieChartData: any;
    customerid: any;
    noData: boolean = false;
    constructor(private reportHttpService: ReportsHttpService, private datePipe: DatePipe, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        let user = JSON.parse(sessionStorage.getItem('userdetails'));
        this.customerid = user.CustomerID;

        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.getCampaigns(user.UserID);
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }

    }
    ngOnInit() {
        let dt = new Date();
        const disableDate = new Date(dt.setDate(dt.getDate() + 1));
        const startDate = new Date(dt.setDate(dt.getDate() - 90));
        this.fromDate = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
        this.toDate = this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear();
        this.datePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
        };
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
            disableUntil: {
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                day: startDate.getDate()
            },
        };
        this.getChildShops();
        this.getReport();
    }

    getSelectedValue(event) {
        this.childwebshop.forEach((element) => {
            if (element.value === event) {
                this.domainName = element.label;
            }
        })
        if (event === 'All Child Shop') {
            this.campaignID = '';
            if (this.isParentCustomer) {
                this.domainName = '';
            }
        } else {
            this.getCampaigns(event);
        }
    }

    getCampaigns(id) {
        let param = 'pageSize=100&pageIndex=1&userId=' + id;
        this.campaignHttpService.getCampaignList(param).subscribe((res: any) => {
            this.campaignList = [];
            this.campaignID = '';
            if (res && res.length > 0) {
                let data = res;
                this.campaignList.push({ label: 'All Campaign', value: 'All Campaign' });
                data.forEach(element => {
                    const c = {
                        value: element.CampaignId,
                        label: element.CampaignName,
                    };
                    this.campaignList.push(c);
                });
                this.campaignID = this.campaignList[0].value;
            }
        });
    }

    dateChange(event) {
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableUntil: {
                year: event.date.year,
                month: event.date.month,
                day: event.date.day,
            },
        };
    }

    getChildShops() {
        const searchdata = {
            activePage: this.activePage,
            pageSize: 1000,
        };
        this.webshophttpservice.getWebshopList(searchdata).subscribe((res: any) => {
            if (res) {
                let data = res.ItemsCollection;
                // let childcustomer = data.ChildCustomers
                this.childwebshop = [];
                this.childwebshop.push({ label: 'All Child Shop', value: 'All Child Shop' });
                data.forEach(element => {
                    const langVal = {
                        value: element.CustomerID,
                        label: element.Name,
                    };
                    this.childwebshop.push(langVal);
                });
                this.childShop = this.childwebshop[0].value;
            }
        },
            err => {
                this.showLoader = false;
            });
    }

    getReport() {

        let a = this.fromDate.split('/');
        let b = this.toDate.split('/');
        let fromDate = a[2] + '-' + a[1] + '-' + a[0];
        let toDate = b[2] + '-' + b[1] + '-' + b[0];
        let params = {
            'fromDate': fromDate,
            'toDate': toDate,
            'campaignID': this.campaignID,
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'userName': this.userName,
            'customerName': this.customerName,
            'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getUserManagementReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.userReport = '';
            this.pieChartData = [];
            if (data && data.IsSuccess) {
                this.userReport = data.UserReportDetails;
                this.pieChartData.push({ name: 'Active Users', value: this.userReport[0].ActiveCount }, { name: 'In Active Users', value: this.userReport[0].InActiveCount })
                this.noData = false;
            } else {
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })

    }

    getChildShopReport() {

    }

}