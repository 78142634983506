import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { CategoryService } from '../category-services/category.service';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-supplier',
  templateUrl: './manage-supplier.component.html',
  styleUrls: ['./manage-supplier.component.scss']
})
export class ManageSupplierComponent implements OnInit, OnChanges {
  @Input() supplier;
  @Input() customerList;
  @Output() modalCloseStatus = new EventEmitter<any>();
  @Output() supplierUpdateStatus = new EventEmitter<any>();
  @Input() setCustomerSupplierCallStatus;
  // @Input() manageSupplier: any = [];
  manageSupplier: any = [];
  webshopOnlineProductCustomerList = [];
  searchForm: FormGroup;
  showLoader: boolean = false;

  perPagedata: number = 20;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 20;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  selectedSupplier: any = {
    SupplierID: ''
  };
  selectSupplierForSubmit: Array<any> = new Array<any>();
  customerSupplier: any = [];
  webshopDetail: any;
  constructor(private categoryHttpService: CategoryHttpService, private categoryService: CategoryService,
    public apiService: ApiService, public toast: ToastService, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.loadForm();
    this.setWebshopLibraryCustomerList();
    this.supplierDropdown();
    // this.setCustomerSupplier();
  }
  loadForm() {
    this.searchForm = new FormGroup({
      CustomerID: new FormControl('')
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setCustomerSupplierCallStatus !== undefined && changes.setCustomerSupplierCallStatus !== null) {
      this.setCustomerSupplier();
    }
    if (changes.customerList) this.setWebshopLibraryCustomerList();
  }
  // supplierDropdown() {
  //   const loginData = this.apiService.getLoginData();
  //   this.categoryService.supplierDropdown(loginData.CustomerID).subscribe((data: any) => {
  //     if (data) {
  //       data.ItemsCollection.forEach(element => {
  //         element.label = element.Name;
  //         element.value = element.SupplierID;
  //       });
  //       this.manageSupplier = data.ItemsCollection;
  //       this.setCustomerSupplier();
  //     }
  //   },
  //   error => {
  //     this.toast.error(error)
  //   });
  // }

  setCustomerSupplier() {
    this.categoryService.getCustomerSupplier(this.webshopDetail.CustomerID).subscribe((data: any) => {

      if (!this.customerSupplier.length) {
        this.customerSupplier = data;
        this.customerSupplier.forEach(row => {
          let idx = this.selectSupplierForSubmit.findIndex((val: any) => val.SupplierID == row.SupplierID)
          if (idx < 0) this.selectSupplierForSubmit.push(row)
        });
      }

      if (this.selectSupplierForSubmit) {
        this.manageSupplier.forEach(option => {
          option.isSelected = false;
          if (option.IsCustomerSupplier !== undefined && option.IsCustomerSupplier !== null) {
            delete option.IsCustomerSupplier;
          }
        });
        if (this.selectSupplierForSubmit.length > 0) {
          this.selectSupplierForSubmit.forEach(value => {
            const test = this.manageSupplier.filter((data1) => data1.SupplierID === value.SupplierID);
            if (test.length > 0) {
              const index = this.manageSupplier.findIndex(record => record.SupplierID === test[0].SupplierID);
              this.manageSupplier[index].isSelected = true;
              if (value.IsCustomerSupplier !== undefined && value.IsCustomerSupplier !== null) {
                if (value.IsCustomerSupplier) {
                  this.manageSupplier[index].IsCustomerSupplier = true;
                }
              }
            }
          });
        } else {
          this.translate.get('CATEGORIES.SUPPLIER_NOT_FOUND').subscribe((res: string) => {
            this.toast.info(res);
          });
        }
      }
    },
      error => {
        this.toast.error(error)
      });
  }

  supplierSelect(event, item, index) {
    //Push select supplier in array
    let sIdx = this.selectSupplierForSubmit.findIndex((s: any) => s.SupplierID == item.SupplierID)
    if (sIdx < 0 && event.checked) {
      this.selectSupplierForSubmit = [...this.selectSupplierForSubmit, item];
    } else {
      this.selectSupplierForSubmit.splice(sIdx, 1);
    }

    this.manageSupplier[index].isSelected = event.checked;
  }

  selectAll(event) {
    if (event.checked) {
      this.manageSupplier.forEach(option => {
        option.isSelected = true;
      });
    } else {
      this.manageSupplier.forEach(option => {
        if (option.IsCustomerSupplier !== undefined && option.IsCustomerSupplier !== null) {
          if (option.IsCustomerSupplier) {
            option.isSelected = true;
          }
        } else {
          option.isSelected = false;
        }
      });
    }
  }

  updateCustomerSupplier() {
    let SupplierIDs = this.selectSupplierForSubmit.map(val => val.SupplierID);

    const requestData = {
      CustomerID: this.webshopDetail.CustomerID,
      SupplierIDs
    }
    this.categoryService.setCustomerSupplier(requestData).subscribe((data: any) => {
      if (data) {
        this.modalCloseStatus.emit(true);
        this.supplierUpdateStatus.emit(true);
        setTimeout(() => {
          this.setCustomerSupplier();
        }, 300);
        this.translate.get('CATEGORIES.SUPPLIER_ASSIGN_TO_CUSTOMER').subscribe((res: string) => {
          this.toast.success(res);
        });
      }
    },
      error => {
        console.log(error);
        this.toast.error(error.error)
      });
  }

  supplierDropdown() {



    // this.categoryService.supplierDropdown().subscribe((data: any) => {
    //   if (data) {
    //     console.log(data)
    //     data.ItemsCollection.forEach(element => {
    //       element.label = element.Name;
    //       element.value = element.SupplierID;
    //     });
    //     this.manageSupplier = data.ItemsCollection;
    //     this.setCustomerSupplier();
    //   }
    // })

    this.searchCriteria = {
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      SupplierID: this.selectedSupplier.Name
    };


    this.categoryService.supplierDropdownWithPagination(this.searchCriteria).subscribe((data: any) => {
      if (data) {
        data.ItemsCollection.forEach(element => {
          element.label = element.Name;
          element.value = element.SupplierID;
        });
        this.manageSupplier = data.ItemsCollection;
        this.setCustomerSupplier();

        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      }
    })
  }

  setWebshopLibraryCustomerList() {
    /** Webshop Libraray Section (Left Side section) => To load Webshop List Based on Login Shop(Ex., Conxion, Ambu) */
    const isParentWebshop = JSON.parse(sessionStorage.getItem('isParentWebshop'));
    let webshopOnlineProductCustomerList = [];
    // check parent or child webshop
    if (isParentWebshop) {
      // tslint:disable-next-line:max-line-length
      webshopOnlineProductCustomerList = this.customerList.filter(x => (x.ParentCustomerID === this.webshopDetail.CustomerID || x.CustomerID === this.webshopDetail.CustomerID));
    } else {
      webshopOnlineProductCustomerList = this.customerList.filter(x => x.value === this.webshopDetail.CustomerID);
    }
    const webshopData = [];
    webshopOnlineProductCustomerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    });
    this.webshopOnlineProductCustomerList = webshopData;

    if (this.searchForm) {
      this.searchForm.patchValue({
        CustomerID: this.webshopDetail.CustomerID
      });
    }

  }

  cancelFunc() {
    this.setCustomerSupplier();
    this.modalCloseStatus.emit(true);
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }

  autoCompleteProductSize(data) {

    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      translations: true,
      SupplierID: 1,
      selectedLangCode: 'en'
    }

    this.groupCollection = [];

    this.categoryHttpService.supplierAutoComplete(request).subscribe((res: any) => {

      if (!res.ItemsCollection.length) this.textNoResults = 'No Results found';

      if (res) {
        this.groupCollection = res.ItemsCollection;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedSupplier = selected;
    this.setPageSizeOptions(20);
  }

  onOptionSelected(event) {
    this.selectedSupplier = event.text
  }

  filterReset(options: any = {}) {
    this.searchText = '';
    this.selectedSupplier.SupplierID = '';
    this.selectedSupplier.Name = '';
    this.textNoResults = '';
    this.groupCollection = [];
    if (options.paginationReset) {
      this.customerSupplier = []
      this.serarchSupplier();
      this.selectSupplierForSubmit = []
      return;
    }
    if (this.manageSupplier) {
      this.supplierDropdown();
    }
  }

  pageChanged(page) {
    this.selectedpage = page;
    this.supplierDropdown();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    if (this.searchText == '') {
      this.groupCollection = [];
    }
    this.supplierDropdown();
  }

  serarchSupplier() {
    this.setPageSizeOptions(20);
  }
}
