import { Component, OnInit, Input } from '@angular/core';
import { CategoryService } from '../state/services/category.service';
import { ProductService } from '../../product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rewardkart-admin-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})

export class CategoryDetailsComponent implements OnInit {

  @Input() header: any;
  @Input() optionsSelectCustomers: Array<any>;
  selectedCustomerID: any; selectedCustomer: any = '';
  selectedCategoryID: any; selectedCategoryName: any;
  selectedSubCategoryID: any; selectedSubCategoryName: any;
  selectedProductID: any; selectedProductName: any;
  categoryList: any = []; subCategoryList: any = []; productList: any = []; selectedProductList: any = [];
  categoryExists = false; subCategoryExists = false; productExists = false; productSelected = false;


  constructor(private categoryService: CategoryService, private productService: ProductService, private translate: TranslateService) {
    this.onLoadFunctions();
  }

  onLoadFunctions() {
    this.selectedCategoryName = 'selectedCategory';
    this.selectedSubCategoryName = 'selectedSubCategory';
  }

  ngOnInit() {
    this.populateCategoryList();
  }

  onCustomerChange(event: any) {
    this.selectedCustomerID = event;
    this.resetCategoryDetailsValues();
    this.populateCategoryList();
  }

  resetCategoryDetailsValues() {
    this.selectedCategoryID = null;
    this.selectedCategoryName = null;
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
  }

  populateCategoryList() {
    this.categoryService.loadCategorys(this.selectedCustomerID);
    this.categoryService.getCustomerCategoryList(this.selectedCustomerID).subscribe(data => {
      if (data) {
        this.categoryList = data;
        this.categoryExists = true;
      } else {
        this.categoryExists = false;
      }
    });
  }

  setSelectedCategory(id: any, name: any) {
    this.selectedCategoryID = id;
    this.selectedCategoryName = name;
    this.resetSubCategoryDetailsValues();
    this.populateSubCategoryAndProductList(id);
  }

  resetSubCategoryDetailsValues() {
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
  }

  populateSubCategoryAndProductList(categoryID: any) {
    const category = this.categoryList.find(x => x.CategoryID === categoryID);
    if (category.SubCategories.length > 0) {
      this.subCategoryExists = true;
      this.subCategoryList = category.SubCategories;
    } else {
      this.subCategoryExists = false;
    }
    if (category.ProductsMainCount > 0) {
      this.productExists = true;
      this.populateProductList(true);
    } else {
      this.productExists = false;
    }
  }

  setSelectedSubCategory(id: any, name: any) {
    this.selectedSubCategoryID = id;
    this.selectedSubCategoryName = name;
    this.populateSubCategoryProductList(id);
  }

  populateSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subCategoryList.find(x => x.CategoryID === subCategoryID);
    if (subCategory.ProductsMainCount > 0) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
  }

  getSearchData(isCategory: boolean) {
    let searchData: any;
    if (this.selectedCustomerID === 0) {
      searchData = {
        CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true
      };
    } else {
      if (isCategory) {
        searchData = {
          CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true
        };
      } else {
        searchData = {
          CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true
        };
      }
    }
    return searchData;
  }

  populateProductList(isCategory) {
    this.productService.getProductListBySearchCriteria(this.getSearchData(isCategory)).subscribe(data => {
      if (data) {
        this.productExists = true;
        this.productList = data;
        this.productList = this.productList.ItemsCollection;
      } else {
        this.productExists = false;
      }
    });
  }

  setProductSelected(selectedProduct: any) {
    const product = this.selectedProductList.find(x => x.ProductID === selectedProduct.ProductID);
    if (product === undefined) {
      this.selectedProductList.push(selectedProduct);
    } else {
      this.selectedProductList.splice(product);
    }
    this.productSelected = !this.productSelected;
  }

}
