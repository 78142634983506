import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductCardModule } from 'src/app/product/product-card/product-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { ViewOrderstatusComponent } from './view-orderstatus.component';
import { viewOrderitemsModalModule } from './view-orderitems-modal/view-orderitems-modal.module';
import { orderEmailnotificationModule } from '../orderemailnotification/orderemailnotification.module';

@NgModule({
    declarations: [
        ViewOrderstatusComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        CommonFilterModule,
        ProductCardModule,
        TranslateModule,
        viewOrderitemsModalModule,
        orderEmailnotificationModule
    ],
    providers: [],
    exports: [ViewOrderstatusComponent]
})

export class viewOrderstatusModule { }
