import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { ProductEffects } from '../product/state/effects';
import { productReducers } from '../product/state/reducers/product.reducer';
import { ProductHttpService } from '../product/state/services/product.http.service';
import { ProductService } from './state/services/product.service';
import { ProductEditModule } from './product-edit/product-edit.module';
import { ProductSearchModule } from './product-search/product-search.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductlistModule } from './product-list/product-list.module';
import { ProductAddModule } from './product-add/product-add.module';
import { ProductAddMarketingModule } from './product-add-marketing/product-add-marketing.module';
import { ProductMarketEditComponent } from './product-market-edit/product-market-edit.component';
import { ProductMarketEditModule } from './product-market-edit/product-market-edit.module';

@NgModule({
  imports: [
    ProductEditModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    ProductSearchModule,
    CommonFilterModule,
    ProductlistModule,
    ProductAddModule,
    ProductAddMarketingModule,
    ProductMarketEditModule,
    EffectsModule.forFeature([ProductEffects]),
    StoreModule.forFeature('productModule', productReducers)
  ],
  providers: [ProductService, ProductHttpService],
  exports: [ProductEditModule]
})
export class ProductModule { }
