import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-tab',
  templateUrl: './product-tab.component.html',
  styleUrls: ['./product-tab.component.scss']
})
export class ProductTabComponent implements OnInit {
  productedittab: any = [];
  basicinfo ='BASICINFO';
  additionalinfo='ADDITIONALINFO';
  categoryinfo ='CATEGORYINFO';
  productstock ='PRODUCTSTOCK';
  productimages='PRODUCTIMAGES';
  productprices='PRODUCTPRICES';
  productproperties='PRODUCTPROPERTIES';
  colors='COLORS';
  sizes='SIZES';
  logopositionandareas='LOGOPOSITIONSANDAREAS';
  additionalcosts='ADDITIONALCOSTS';
  publish='PUBLISH';
 

  constructor(private translate: TranslateService,) {
    translate.get('PRODUCTEDITTAB', {}).subscribe((res: any) => {
        this.productedittab = [ 
          { itemName: res.BASICINFO ,id:'basicinfo' },
          { itemName: res.ADDITIONALINFO ,id:'additionalinfo' },
          { itemName: res.CATEGORYINFO ,id:'categoryinfo' },
          { itemName: res.PRODUCTSTOCK ,id:'productstock' },
          { itemName: res.PRODUCTIMAGES ,id:'productimages' },
          { itemName: res.PRODUCTPRICES ,id:'productprices' },
          { itemName: res.PRODUCTPROPERTIES ,id:'productproperties' },
          { itemName: res.COLORS ,id:'colors' },
          { itemName: res.SIZES ,id:'sizes' },
          { itemName: res.LOGOPOSITIONSANDAREAS ,id:'logopositionandareas' },
          { itemName: res.ADDITIONALCOSTS ,id:'additionalcosts' },
          { itemName: res.PUBLISH ,id:'publish' },
        ]
    })
  }

  ngOnInit() {}
}
