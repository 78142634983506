import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderContentComponent } from './header-content.component';

@NgModule({
  imports: [
    CommonModule,
    // BrowserModule
  ],
  declarations: [HeaderContentComponent],
  exports: [
    CommonModule,
    HeaderContentComponent
  ]
})

export class HeaderContentModule {
}
