import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/state/services/user.service';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';



@Component({
  selector: 'app-message-important',
  templateUrl: './message-important.component.html',
  styleUrls: ['./message-important.component.scss']
})
export class MessageImportantComponent implements OnInit {
  // @Input() loadApiStatus:any;
  //public titles: any = 'Compose Message';
  enablepagination: boolean = true;
  messagesearch: boolean = true;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  paginationData: any = {};
  rowactivePage: number = 1;
  activePage: number = 1;
  importantCount: number;
  titles: any;
  tableName: string = 'message';
  showMessageDetailpage: boolean = false;
  showMessageInbox: boolean = true;
  EmailID: any = 0;
  public messageimportant: any = [];
  messageimportantQueryParams: any = {};
  customerList: any = [];
  filterFields: any = [];
  customerId: any = 0;
  isBulkEditSelectedForDelete = {
    data: []
  }
  public inboxImportantData: ColumnSetting[] = [
    {
      primaryKey: 'ProductionLineID',
      header: 'Select',
      format: 'bulkaction',
      key: 'importantcheckbox'
    },
    {
      primaryKey: 'SenderName',
      header: 'Name',
    },
    {
      primaryKey: 'SenderEmail',
      header: 'Email',
    },
    {
      primaryKey: 'Subject',
      header: 'Subject',
    },
    {
      primaryKey: 'SendingTime',
      header: 'Date',
      format: 'time'
    }
  ];
  bulkEmailIds: any;
  showLoader: Boolean = false;
  webshopDetail: any;
  tableEvent: any;

  constructor(public messageboxservice: MessageboxService, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private toast: ToastService, private messageboxhttpservice: MessageboxHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();

  }

  ngOnInit() {


    this.loadCustomer();
    // this.Messageboximportant();
    this.onLoadUsers();

  }

  // Messageboximportant() {
  //   // this.webservice.getMessageImportant().subscribe((data: any) => {
  //   //   this.messageimportant = data.ItemsCollection;
  //   //   console.log(this.messageimportant)
  //   // })
  // }

  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.messageimportantQueryParams.customerID = 0;
    this.EmailID = '';
    this.messageimportantQueryParams.EmailID = this.EmailID;
    this.messageimportantQueryParams.UserID = loginData.UserID;
    this.messageimportantQueryParams.activePage = this.activePage;
    this.messageimportantQueryParams.pageSize = this.perPagedata;
    sessionStorage.setItem('importantfilter', JSON.stringify(this.messageimportantQueryParams))
    // this.messageboxservice.loadMessageimportantState(this.messageimportantQueryParams);
    this.Messageboximportant(this.messageimportantQueryParams);
    this.messageboxservice.loadCustomers(this.messageimportantQueryParams)
  }

  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        this.customerList = [{ label: 'Select All', value: 0 }];
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });

        this.filterFields = [];
        if (this.customerList.length > 0) {
          this.filterFields.push({
            label: 'Customer',
            type: 'select',
            data: this.customerList,
            class: 'col-md-3',
            key: 'inboxcustomer',
            required: false
          },
            {
              label: 'Search',
              type: 'button',
              class: '',
              key: 'button',
            },
            {
              label: 'Refresh',
              type: 'button',
              class: '',
              key: 'reset',
            })
        }
        this.filterFields.push(
          {
            label: 'Delete',
            type: 'button',
            class: 'ml-auto mt-2 pt-1',
            key: 'messagedelete',
            model: 'deleteimportant'
          });
      }
    })
  }

  Messageboximportant(request) {
    if (this.customerId === 0) {
      request.showall = true;
      // const loginData = this.apiService.getLoginData();
      request.customerID = this.webshopDetail.CustomerID;
    }
    else {
      request.showall = false;
    }
    request.showall = true;
    this.showLoader = true;
    this.messageboxhttpservice.getMessageImportantData(request).subscribe((data: any) => {
      if (data) {
        this.messageimportant = data.ItemsCollection;
        this.importantCount = data.TotalItemsCount;
        this.showMessageDetailpage = false;
        this.showMessageInbox = true;
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      })
  }

  onLoadUsers() {
    // const loginData = this.apiService.getLoginData();
    const searchCriteria = {
      customerId: this.webshopDetail.CustomerID,
      roleId: '',
      name: '',
      email: '',
      companyName: '',
      country: '',
      pendinguserlist: false,
      username: '',
      pageIndex: this.perPagedata,
      rowpageIndex: this.rowPagepage,
      activePage: this.activePage,
    };
    this.userService.loadUsers(searchCriteria);
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.messageimportantQueryParams.pageSize = event;
    this.messageimportantQueryParams.pageIndex = event;
    let getFilter = JSON.parse(sessionStorage.getItem('importantfilter'));
    getFilter.pageSize = event;
    getFilter.pageIndex = event;
    sessionStorage.setItem('importantfilter', JSON.stringify(getFilter));
    if (this.customerId > 0) {
      sessionStorage.setItem('importantfilter', JSON.stringify(this.messageimportantQueryParams))
    }
    this.getSubmit('pagechange');
  }
  paginationNum(event) {
    this.activePage = event;
    this.messageimportantQueryParams.activePage = event;
    let getFilter = JSON.parse(sessionStorage.getItem('importantfilter'));
    getFilter.activePage = event;
    sessionStorage.setItem('importantfilter', JSON.stringify(getFilter));
    console.log(this.customerId);
    console.log(getFilter);
    console.log(this.messageimportantQueryParams);
    if (this.customerId > 0) {
      sessionStorage.setItem('importantfilter', JSON.stringify(this.messageimportantQueryParams))
    }
    this.getSubmit('pagechange');
  }

  selectedTableRow(event: boolean) {
    if (event) {
    }
  }
  importantdetails(messageData: boolean) {
    if (messageData) {
      this.showMessageDetailpage = true;
      try {
        this.messageboxservice.selectedMessage(messageData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.showMessageDetailpage = true;
            this.showMessageInbox = false;
            this.EmailID = data.EmailID;
            this.router.navigate(['/app-message-importantdetails'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }
  }

  getBulkAction(event) {
    if (event.bulkaction.importantcheckbox !== undefined) {
      let getaction = event.bulkaction.importantcheckbox;
      this.bulkEmailIds = [];
      getaction.forEach(element => {
        this.bulkEmailIds.push(element.EmailID)
      });
    }
    this.isBulkEditSelectedForDelete.data = this.bulkEmailIds;
  }

  deleteRow(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deleteimportant) {
      const loginData = this.apiService.getLoginData();
      const deleteData = {
        "ActionType": "TRASH",
        "Value": true,
        "EmailIDs": this.bulkEmailIds
      }

      const initialRequest = {
        "ActionType": "IMPORTANT",
        "Value": false,
        "EmailIDs": this.bulkEmailIds
      }

      this.messageboxhttpservice.bulkAction(initialRequest, loginData.UserID).subscribe((response: any) => {
        if (response) {
          this.messageboxhttpservice.bulkAction(deleteData, loginData.UserID).subscribe((data: any) => {
            if (data != null) {
              if (data) {
                setTimeout(() => {
                  // this.messageboxservice.loadMessageimportantState(this.messageimportantQueryParams);
                  this.Messageboximportant(this.messageimportantQueryParams);
                  this.toast.success('Email deleted successfully');
                  this.bulkEmailIds = [];
                  this.isBulkEditSelectedForDelete.data = [];
                }, 300)
              }
            }
          })
        }
      });

    }
  }

  getSubmit(type) {
    let getFilter = this.messageimportantQueryParams;
    if (type === 'pagechange') {
      getFilter = JSON.parse(sessionStorage.getItem('importantfilter'));
    }
    else {
      this.activePage = 1;
      getFilter.activePage = this.activePage;
    }
    this.Messageboximportant(getFilter);
  }

  getSelectData(event) {
    if (event.inboxcustomer !== undefined && event.inboxcustomer.selectMethod === 'inboxcustomer') {
      this.customerId = event.inboxcustomer.value;
      this.messageimportantQueryParams.customerID = this.customerId;
    }
  }

  getReset(reset) {
    if (reset.selectMethod === 'reset' && reset.value) {
      // const loginData = this.apiService.getLoginData();
      this.customerId = 0;
      this.messageimportantQueryParams.customerID = this.customerId;
      this.activePage = 1;
      this.messageimportantQueryParams.activePage = this.activePage;
      // this.messageboxservice.loadMessageimportantState(this.messageimportantQueryParams);
      this.Messageboximportant(this.messageimportantQueryParams);
      this.loadCustomer();
    }
  }

}
