import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CompanyOrderHttpService } from "../state/services/company-order.http.services";

@Component({
    selector: 'app-company-order-detail',
    templateUrl: './company-order-detail-view.component.html',
    styleUrls: ['./company-order-detail-view.component.scss']
})

export class CompanyOrderDetailViewComponent {
    customerID: any;
    orderID: any;
    orderSummary: any = '';
    orderDetail: any;
    showLoader: boolean = false;
    startDate: any;
    endDate: any;
    constructor(private router: Router, private _http: CompanyOrderHttpService) {
        if (history.state.data) {
            this.customerID = history.state.data.customerID;
            this.orderID = history.state.data.OrderID;
            this.orderDetail = history.state.data.orderDetail;
            this.startDate = history.state.data.startDate;
            this.endDate = history.state.data.endDate;
            this.orderSummary = history.state.data.orderSummary;
        } else {
            this.router.navigate(['/admin-dashboard']);
        }

    }



    goBack() {
        let data = {
            customerID: this.customerID,
            startDate: history.state.data.startDate,
            endDate: history.state.data.endDate,
            orderSummary: this.orderSummary
        }
        this.router.navigate(['/admin-company-order-detail'], { state: { data }, replaceUrl: false });
    }
}