import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxEditorModule } from 'ngx-editor';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { MessageInboxComponent } from './message-inbox.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessageinboxdetailsModule } from './message-inboxdetails/message-inboxdetails.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    NgxEditorModule,
    MessageinboxdetailsModule,
    ControlValidationModule
  ],
  declarations: [MessageInboxComponent],
  exports: [MessageInboxComponent]
})

export class MessageinboxModule { }
