import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WavesModule, ButtonsModule, TableModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule } from '@angular/forms';
import { LinkDraganddropTableComponent } from './link-draganddrop-table.component';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ng2-tooltip-directive';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LinkTableDirectiveModule } from '../link-table/directives/link-table-directive.module';
import { LinkTablePipeModule } from '../link-table/pipes/link-table-pipe.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [LinkDraganddropTableComponent],
    imports: [
        BrowserModule,
        DragDropModule,
        BrowserAnimationsModule,
        LinkTableDirectiveModule,
        LinkTablePipeModule,
        CommonModule,
        FormsModule,
        WavesModule,
        ButtonsModule,
        TooltipModule,
        TableModule,
        MDBBootstrapModulesPro.forRoot()
    ],
    providers: [{
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
    exports: [
        CommonModule,
        LinkDraganddropTableComponent
    ]
})
export class LinkDragAndDropTableModule { }