import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable()
export class SignupHttpService {
    constructor(@Inject(DOCUMENT) private _document: HTMLDocument, private apiService: ApiService) { 

    }

    validateUser(data) {
        const path = 'en/users?UserName=' + data.username + '&customerID=' + data.customerID + '&UserID=0';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    triggerMobileOTP(data){
        const path = 'en/SignUpMobileOTP?Mobile='+ data.mobile + '&CustomerName='+data.fullname+'&UserName='+ data.userName + '&language_id=1&CustomerTheme=#f23d49';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    triggerEmailOTP(data){
        let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'))
        const path = 'en/SignUpEmailOTP?Email='+ data.email + '&CustomerName='+data.fullname+'&UserName='+ data.userName + '&DomainName='+webshop.DomainName+'&language_id=1&CustomerTheme=#f23d49';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    validateMobileOTP(data){
        const path = 'en/SignUpValidateMobileOTP?Mobile='+ data.mobile + '&UserName='+ data.userName + '&OTP=' + data.mobileOTP;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    validateEmailOTP(data){
        const path = 'en/SignUpValidateOTP?Email='+ data.email + '&UserName='+ data.userName + '&OTP=' + data.emailOTP;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    registerUser(params) {
        const path = 'en/users/CreateWebShopManager';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }
}