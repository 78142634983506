import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductService } from '../state/services/product.service';
import { UserService } from '../../user/state/services/user.service';
import { SupplierService } from '../../supplier/state/services/supplier.service';

@Component({
  selector: 'rewardkart-admin-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})

export class ProductSearchComponent implements OnInit {

  header: any;
  submitted = false;

  optionsSelectSuppliers: Array<any>;
  optionsSelectStatus: any = [];
  optionsSelectCustomers: Array<any>;
  productSearchForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private productService: ProductService,
              private userService: UserService, private supplierService: SupplierService) {
    this.onLoadFunctions();
    this.createForms();
  }

  onLoadFunctions() {
    this.supplierService.loadAllSuppliers();
    this.userService.loadCustomers();
  }

  ngOnInit() {
    this.header = 'Product Search'; // Multilingual to be implemented
    this.getAllSuppliers();
    this.getStatusList();
    this.getCustomerList();
  }

  getAllSuppliers() {
    this.supplierService.getAllSuppliers().subscribe(
      data => {
        if (data) {
          this.optionsSelectSuppliers = [];
          data.ItemsCollection.forEach(element => {
            const supplierVal = {
              value: element.SupplierID,
              label: element.Name,
            };
            this.optionsSelectSuppliers.push(supplierVal);
          });
        }
      });
  }

  getStatusList() {
    const status = {
      value: 'Active',
      label: 'Active',
    };
    this.optionsSelectStatus.push(status);
  }

  getCustomerList() {
    this.userService.getAllCustomers().subscribe(
      data => {
        if (data) {
          this.optionsSelectCustomers = [];
          data.forEach(element => {
            const customerVal = {
              value: element.CustomerID,
              label: element.Name,
            };
            this.optionsSelectCustomers.push(customerVal);
          });
        }
      });
  }

  createForms() {
    this.productSearchForm = this.formBuilder.group({
      supplierId: ['', Validators.required],
      status: [''],
      customerId: ['', Validators.required],
      name: [''],
      manualId: [''],
      excludeDeleted: [true]
    });
  }

  onSubmit() {
    const productSearch = {
      supplierId: this.productSearchForm.value.supplierId !== undefined ? this.productSearchForm.value.supplierId : '',
      status: this.productSearchForm.value.status !== undefined ? this.productSearchForm.value.status : '',
      customerId: this.productSearchForm.value.customerId !== undefined ? this.productSearchForm.value.customerId : '',
      name: this.productSearchForm.value.name !== undefined ? this.productSearchForm.value.name : '',
      manualId: this.productSearchForm.value.manualId !== undefined ? this.productSearchForm.value.manualId : '',
      excludeDeleted: this.productSearchForm.value.excludeDeleted !== undefined ? this.productSearchForm.value.excludeDeleted : ''
    };
    this.productService.loadProducts(productSearch);
  }

  resetForm() {
    this.productSearchForm.reset();
  }

}
