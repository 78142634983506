import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductListComponent } from './product-list.component';
import { ProducttabModule } from '../product-tab/product-tab.module';
import { RouterModule } from '@angular/router';
import { FilterTagModule } from 'src/app/common/common-filter/filter-tags/filter-tags.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    ProducttabModule,
    RouterModule,
    FilterTagModule
  ],
  declarations: [ProductListComponent],
  exports: [ProductListComponent]
})
export class ProductlistModule { }