import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductManageUnitComponent } from './product-manage-unit.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CommonFormModule,
    SharedModule
  ],
  declarations: [ProductManageUnitComponent],
  exports: [ProductManageUnitComponent]
})

export class ProductManageUnitModule { }
 