import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'rewardkart-admin-track-order-details',
    templateUrl: './track-order.component.html',
    styleUrls: ['./track-order.component.scss']
})

export class TrackOrderComponent implements OnInit{
    item: any;
    requested: boolean;
    dispatchCompleted: boolean;
    inTransistCompleted: boolean;
    deliveryCompleted: boolean;
    constructor(){
        if(history.state.data){
            this.item = history.state.data
        }
    }
    ngOnInit() {
        this.itemDetail();
    }

    itemDetail(){
     
        if(this.item.DEL_DATE){
          this.requested = true;
          this.dispatchCompleted = true;
          this.inTransistCompleted = true;
          this.deliveryCompleted = true;
        }else if(this.item.Cour_Date){
          this.requested = true;
          this.dispatchCompleted = true;
          this.inTransistCompleted = true;
          this.deliveryCompleted = false;
        }else if(this.item.Dispatch_Date){
          this.requested = true;
          this.dispatchCompleted = true;
          this.inTransistCompleted = false;
          this.deliveryCompleted = false;
        }else if(this.item.F_Date){
          this.requested = true;
          this.dispatchCompleted = false;
          this.inTransistCompleted = false;
          this.deliveryCompleted = false;
        }
        //this.orderDetails(id);
      }
}