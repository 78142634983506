import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ReportsHttpService } from '../../reports/services/reports-http.service';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { timeStamp } from 'console';

@Component({
    selector: 'app-dashboard-budget-report',
    templateUrl: './dashboard-budget-report.component.html',
    styleUrls: ['./dashboard-budget-report.component.scss']
})

export class DashboardBudgetReportComponent implements OnInit {
    fromDate: any;
    toDate: any;
    public trackCount1: any;
    public trackKey1: any;
    public trackCount2: any;
    public trackKey2: any;
    public trackCount3: any;
    public trackKey3: any;
    date = new Date();
    userName: string = '';
    customerName: string = '';
    campaignID: any = '';
    domainName: any = '';
    activePage: number = 1;
    isParentCustomer: boolean = true;
    webshop: any;
    childwebshop: any;
    childShop: any;
    showLoader: boolean = false;
    campaignList: any;
    showFirstTable: boolean = false;
    showSecondTable: boolean = false;
    showThirdTable: boolean = false;
    budgetReport: any;
    childShopReport: any;
    noData: boolean = false;

    public chartType: string = 'line';

    public chartDatasets: any;

    public chartLabels: Array<any> = [];

    public chartColors: Array<any> = [
        {
            backgroundColor: 'rgba(0, 137, 132, .2)',
            borderColor: 'rgba(0, 10, 130, .7)',
            borderWidth: 2,
        }
    ];

    public chartOptions: any = {
        responsive: true
    };
    public orderFlowList: any = [];
    childShopData: any;
    totalCount: any;
    public chartClicked(e: any): void { }
    public chartHovered(e: any): void { }
    public colorScheme: any = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#1DA1F2']
    };
    public pieChartData: any = [];
    customerid: any;
    constructor(private reportHttpService: ReportsHttpService, private datePipe: DatePipe, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
            let user = JSON.parse(sessionStorage.getItem('userdetails'));
            this.customerid = user.CustomerID;

        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.getCampaigns(user.UserID);
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }
    }
    ngOnInit() {
        this.getChildShops();
        if (this.isParentCustomer) {
            this.getReport();
        } else {
            this.getChildShopReport();
        }

    }

    onSelect1(event) {
        this.domainName = event.name;
        let c = this.childwebshop.filter((i: any) => {
            return event.name === i.label;
        });
        this.childShop = c[0].value;
        this.getChildShopReport();
        this.getCampaigns(this.childShop);
    }

    onSelect2(event) {
        let c = this.childShopReport.filter((i: any) => {
            return event.name === i.CampaignName;
        });
        this.campaignID = c[0].CampaignID;
        this.getCampaignReport();
    }

    getChildShops() {
        const searchdata = {
            activePage: this.activePage,
            pageSize: 1000,
        };
        this.webshophttpservice.getWebshopList(searchdata).subscribe((res: any) => {
            if (res) {
                let data = res.ItemsCollection;
                // let childcustomer = data.ChildCustomers
                this.childwebshop = [];
                this.childwebshop.push({ label: 'All Child Shop', value: 'All Child Shop' });
                data.forEach(element => {
                    const langVal = {
                        value: element.CustomerID,
                        label: element.Name,
                    };
                    this.childwebshop.push(langVal);
                });
                this.childShop = this.childwebshop[0].value;
            }
        }, _err => {
            this.showLoader = false;
        });
    }

    getCampaigns(id) {
        let param = 'pageSize=100&pageIndex=1&userId=' + id;
        this.showLoader = true;
        this.campaignHttpService.getCampaignList(param).subscribe((res: any) => {
            this.showLoader = false;
            this.campaignList = [];
            this.campaignID = '';
            if (res && res.length > 0) {
                let data = res;
                this.campaignList.push({ label: 'All Campaign', value: 'All Campaign' });
                data.forEach(element => {
                    const c = {
                        value: element.CampaignId,
                        label: element.CampaignName,
                    };
                    this.campaignList.push(c);
                });
                this.campaignID = this.campaignList[0].value;
            }
        }, _err => {
            this.showLoader = false;
        });
    }

    getSelectedValue(event) {
        this.childwebshop.forEach((element) => {
            if (element.value === event) {
                this.domainName = element.label;
            }
        })
        if (event === 'All Child Shop') {
            this.campaignID = '';
            if (this.isParentCustomer) {
                this.domainName = '';
            }
        } else {
            this.getCampaigns(event);
        }
    }

    getReport() {
        let params = {
            'campaignID': (this.campaignID && this.campaignID !== 'All Campaign') ? this.campaignID : '',
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getBudgetReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.budgetReport = '';
            if (data && data.IsSuccess) {

                this.showFirstTable = true;
                this.showSecondTable = false;
                this.showThirdTable = false;
                this.budgetReport = data.UserReportDetails;
                if (this.budgetReport) {
                    this.childShopReport = [];
                    let count1: Array<any> = [];
                    let count2: Array<any> = [];
                    let count3: Array<any> = [];
                    this.budgetReport.forEach((i: any) => {
                        count1[i['Childhop']] = (count1[i['Childhop']] || 0) + i['CreditedPoint'];
                        count2[i['Childhop']] = (count2[i['Childhop']] || 0) + i['RewardPointsUsed'];
                        count3[i['Childhop']] = (count3[i['Childhop']] || 0) + i['BalancePoint'];
                    });
                    this.trackCount1 = Object.values(count1);
                    this.trackKey1 = Object.keys(count1);
                    this.trackCount2 = Object.values(count2);
                    this.trackKey2 = Object.keys(count2);
                    this.trackCount3 = Object.values(count3);
                    this.trackKey3 = Object.keys(count3);
                    let count: Array<any> = [];
                    this.budgetReport.forEach((i: any, key: any) => {
                        if (key !== 0 && ((key + 1) <= this.budgetReport.length)) {

                            if (i.Childhop === this.budgetReport[key - 1].Childhop) {

                            } else {
                                if (count.length !== 0) {
                                    if (count.indexOf(i.Childhop) === -1) {
                                        count.push(i.Childhop);
                                        this.childShopReport.push(i);
                                    }
                                }
                            }
                        } else {
                            count.push(this.budgetReport[key].Childhop);
                            this.childShopReport.push(this.budgetReport[key]);
                        }

                    });

                }
                this.pieChartData = [];
                this.childShopReport.forEach((key: any) => {
                    let a = this.trackKey1.findIndex((i: any) => {
                        return key.Childhop === i;
                    });
                    key.CreditedPoint = this.trackCount1[a];
                    let b = this.trackKey2.findIndex((i: any) => {
                        return key.Childhop === i;
                    });
                    key.RewardPointsUsed = this.trackCount2[b];
                    let c = this.trackKey3.findIndex((i: any) => {
                        return key.Childhop === i;
                    });
                    key.BalancePoint = this.trackCount3[c];
                    this.pieChartData.push({ name: key.Childhop, value: key.BalancePoint })
                });
                this.noData = false;
            } else {

                this.showFirstTable = false;
                this.showSecondTable = false;
                this.showThirdTable = false;
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })
    }

    getChildShopReport() {
        let params = {
            'campaignID': (this.campaignID && this.campaignID !== 'All Campaign') ? this.campaignID : '',
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getBudgetReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.budgetReport = '';
            if (data && data.IsSuccess) {
                this.showFirstTable = false;
                this.showSecondTable = true;
                this.showThirdTable = false;
                this.budgetReport = data.UserReportDetails;
                if (this.budgetReport) {
                    this.childShopReport = [];
                    let count1: Array<any> = [];
                    let count2: Array<any> = [];
                    let count3: Array<any> = [];
                    this.budgetReport.forEach((i: any) => {
                        count1[i['CampaignName']] = (count1[i['CampaignName']] || 0) + i['CreditedPoint'];
                        count2[i['CampaignName']] = (count2[i['CampaignName']] || 0) + i['RewardPointsUsed'];
                        count3[i['CampaignName']] = (count3[i['CampaignName']] || 0) + i['BalancePoint'];
                    });
                    this.trackCount1 = Object.values(count1);
                    this.trackKey1 = Object.keys(count1);
                    this.trackCount2 = Object.values(count2);
                    this.trackKey2 = Object.keys(count2);
                    this.trackCount3 = Object.values(count3);
                    this.trackKey3 = Object.keys(count3);
                    let count: Array<any> = [];
                    this.budgetReport.forEach((i: any, key: any) => {
                        if (key !== 0 && ((key + 1) <= this.budgetReport.length)) {

                            if (i.CampaignName === this.budgetReport[key - 1].CampaignName) {

                            } else {
                                if (count.length !== 0) {
                                    if (count.indexOf(i.CampaignName) === -1) {
                                        count.push(i.CampaignName);
                                        this.childShopReport.push(i);
                                    }
                                }
                            }
                        } else {
                            count.push(this.budgetReport[key].CampaignName);
                            this.childShopReport.push(this.budgetReport[key]);
                        }

                    });

                }
                this.pieChartData = [];
                this.childShopReport.forEach((key: any) => {
                    let a = this.trackKey1.findIndex((i: any) => {
                        return key.CampaignName === i;
                    });
                    key.CreditedPoint = this.trackCount1[a];
                    let b = this.trackKey2.findIndex((i: any) => {
                        return key.CampaignName === i;
                    });
                    key.RewardPointsUsed = this.trackCount2[b];
                    let c = this.trackKey3.findIndex((i: any) => {
                        return key.CampaignName === i;
                    });
                    key.BalancePoint = this.trackCount3[c];
                    this.pieChartData.push({ name: key.CampaignName, value: key.CreditedPoint })
                })
                this.noData = false;
            } else {

                this.showFirstTable = false;
                this.showSecondTable = false;
                this.showThirdTable = false;
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })
    }

    getCampaignReport() {
        let params = {
            'campaignID': (this.campaignID && this.campaignID !== 'All Campaign') ? this.campaignID : '',
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getBudgetReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.budgetReport = '';
            if (data && data.IsSuccess) {
                this.showFirstTable = false;
                this.showSecondTable = false;
                this.showThirdTable = true;
                this.budgetReport = data.UserReportDetails;
                if (this.budgetReport) {
                    this.childShopReport = [];
                    let count1: Array<any> = [];
                    let count2: Array<any> = [];
                    let count3: Array<any> = [];
                    this.budgetReport.forEach((i: any) => {
                        count1[i['UserName']] = (count1[i['UserName']] || 0) + i['CreditedPoint'];
                        count2[i['UserName']] = (count2[i['UserName']] || 0) + i['RewardPointsUsed'];
                        count3[i['UserName']] = (count3[i['UserName']] || 0) + i['BalancePoint'];
                    });
                    this.trackCount1 = Object.values(count1);
                    this.trackKey1 = Object.keys(count1);
                    this.trackCount2 = Object.values(count2);
                    this.trackKey2 = Object.keys(count2);
                    this.trackCount3 = Object.values(count3);
                    this.trackKey3 = Object.keys(count3);
                    let count: Array<any> = [];
                    this.budgetReport.forEach((i: any, key: any) => {
                        if (key !== 0 && ((key + 1) <= this.budgetReport.length)) {

                            if (i.UserName === this.budgetReport[key - 1].UserName) {

                            } else {
                                if (count.length !== 0) {
                                    if (count.indexOf(i.UserName) === -1) {
                                        count.push(i.UserName);
                                        this.childShopReport.push(i);
                                    }
                                }
                            }
                        } else {
                            count.push(this.budgetReport[key].UserName);
                            this.childShopReport.push(this.budgetReport[key]);
                        }

                    });

                }
                this.pieChartData = [];
                this.childShopReport.forEach((key: any) => {
                    let a = this.trackKey1.findIndex((i: any) => {
                        return key.UserName === i;
                    });
                    key.CreditedPoint = this.trackCount1[a];
                    let b = this.trackKey2.findIndex((i: any) => {
                        return key.UserName === i;
                    });
                    key.RewardPointsUsed = this.trackCount2[b];
                    let c = this.trackKey3.findIndex((i: any) => {
                        return key.UserName === i;
                    });
                    key.BalancePoint = this.trackCount3[c];
                    this.pieChartData.push({ name: key.UserName, value: key.CreditedPoint })
                })
                this.noData = false;
            } else {
                this.showFirstTable = false;
                this.showSecondTable = false;
                this.showThirdTable = false;
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })
    }
}