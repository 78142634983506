import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { CategoryService } from '../category-services/category.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-advance-search-webshop-online-products',
  templateUrl: './advance-search-webshop-online-products.component.html',
  styleUrls: ['./advance-search-webshop-online-products.component.scss']
})
export class AdvanceSearchWebshopOnlineProductsComponent implements OnInit, OnChanges {
  @ViewChild('autoComp', { static: true }) autoComp;
  ProductName = ''
  keyword = 'ProductName';
  displayMode: number = 1;
  searchproduct: any = [];
  searchText: any = '';
  selectedColorID = '';
  searchForm: FormGroup;
  customerList = [];
  webshopLibraryCustomerList = [];
  webshopOnlineProductCustomerList = [];
  suppliersCategoriesList = [];
  supplier: any = [];
  sizesList = [];
  category = [];
  productName: any = '';
  @Input() colorList;
  @Output() searchData = new EventEmitter<any>();
  @Output() selectSearchData = new EventEmitter<any>();
  @Output() supplierChangeStatus = new EventEmitter<any>();
  @Output() supplierRecallStatus = new EventEmitter<any>();
  @Output() searchStatus = new EventEmitter<any>();
  showLoader: boolean = false;
  webshopDetail: any;
  setCustomerSupplierCallStatus: boolean = true;
  constructor(private categoryHttpService: CategoryHttpService, public apiService: ApiService, public toast: ToastService, private categoryService: CategoryService, private translate: TranslateService) { }

  ngOnInit() {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.loadForm();
    this.getAllCustomerList();
    this.populateCategoryList();
    this.getSupplierSizes();
    this.supplierDropdown();
  }

  ngOnChanges() {
    console.log(this.category)
    console.log(this.supplier)
  }


  loadForm() {
    this.searchForm = new FormGroup({
      CustomerID: new FormControl(''),
      productCategory: new FormControl(''),
      supplier: new FormControl(''),
      quantity: new FormControl(''),
      priceFrom: new FormControl(''),
      priceTo: new FormControl(''),
      childwebshop: new FormControl(''),
      maxDeliveryWeeks: new FormControl(''),
      SizeID: new FormControl(''),
      relatedProducts: new FormControl('')
    })
  }

  /** GET All customer list based on Login CustomerId */
  getAllCustomerList() {
    if (sessionStorage.getItem('customerList') !== undefined && sessionStorage.getItem('customerList') !== null
      && sessionStorage.getItem('customerList') !== '') {
      this.customerList = JSON.parse(sessionStorage.getItem('customerList'));
      this.setWebshopLibraryCustomerList();
    } else {
      this.showLoader = true;
      this.categoryHttpService.getCustomersList().subscribe((data: any) => {
        if (data) {
          this.showLoader = false;
          this.customerList = data;
          sessionStorage.setItem('customerList', JSON.stringify(data));
          this.setWebshopLibraryCustomerList();
        }
      },
        err => {
          console.log(err);
          this.showLoader = false;
        });
    }
  }

  supplierDropdown(supplierRecallStatus = null) {
    let selectSupplierLabel = '';
    this.translate.get('CATEGORIES.SELECTSUPPLIER').subscribe((res: string) => {
      selectSupplierLabel = res;
    });
    const customerId = this.searchForm.value.CustomerID;
    this.categoryService.supplierDropdown(customerId).subscribe((data: any) => {
      if (data) {
        console.log(data);
        const supplierData = [{ label: selectSupplierLabel, value: '' }];
        data.ItemsCollection.forEach(option => {
          supplierData.push({ label: option.Name, value: option.SupplierID })
        });
        this.supplier = supplierData;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
    if (supplierRecallStatus) {
      this.supplierRecallStatus.emit(true);
    }
  }

  setWebshopLibraryCustomerList() {
    /** Webshop Libraray Section (Left Side section) => To load Webshop List Based on Login Shop(Ex., Conxion, Ambu) */
    const isParentWebshop = JSON.parse(sessionStorage.getItem('isParentWebshop'));
    let webshopOnlineProductCustomerList = [];
    // check parent or child webshop
    if (isParentWebshop) {
      webshopOnlineProductCustomerList = this.customerList.filter(x => (x.ParentCustomerID === this.webshopDetail.CustomerID || x.CustomerID === this.webshopDetail.CustomerID));
    } else {
      webshopOnlineProductCustomerList = this.customerList.filter(x => x.CustomerID === this.webshopDetail.CustomerID);
    }
    const webshopData = [];
    webshopOnlineProductCustomerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    });
    this.webshopOnlineProductCustomerList = webshopData;
    this.searchForm.patchValue({
      CustomerID: this.webshopDetail.CustomerID,
      relatedProducts: true
    });
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  /** Check Login Webshop is Parent or Child webshop */
  getSupplierSizes() {
    let selectSizeLabel = '';
    this.translate.get('CATEGORIES.SELECT_SIZE').subscribe((res: string) => {
      selectSizeLabel = res;
    });
    let request = {
      supplierId: null
    };
    if (this.searchForm.value.supplier !== '' && this.searchForm.value.supplier !== null && this.searchForm.value.supplier !== undefined) {
      request.supplierId = this.searchForm.value.supplier;
    }
    this.categoryHttpService.getSupplierSizes(request).subscribe((data: any) => {
      if (data) {
        this.searchForm.value.SizeID = '';
        this.searchForm.patchValue({
          SizeID: '',
        });
        this.showLoader = false;
        const sizeData = [{ label: selectSizeLabel, value: '' }];
        data.forEach(option => {
          sizeData.push({ label: option.Name, value: option.ProductSizeID })
        })
        this.sizesList = sizeData;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }


  search() {
    if (Number(this.searchForm.value.priceFrom) > 0 || Number(this.searchForm.value.priceTo) > 0) {
      if (this.searchForm.value.quantity === '' || Number(this.searchForm.value.quantity) === 0) {
        this.translate.get('CATEGORIES.QTY_VALIDATION').subscribe((res: string) => {
          this.toast.error(res);
        });
        return false;
      }
    }
    console.log(this.searchForm.value);
    const searchData = {
      productName: this.productName,
      Quantity: Number(this.searchForm.value.quantity) ? this.searchForm.value.quantity : '',
      CategoryID: this.searchForm.value.productCategory,
      SupplierID: this.searchForm.value.supplier,
      MaxDeliveryWeeks: this.searchForm.value.maxDeliveryWeeks,
      SizeID: this.searchForm.value.SizeID,
      PriceFrom: Number(this.searchForm.value.priceFrom > 0) ? this.searchForm.value.priceFrom : '',
      PriceTo: Number(this.searchForm.value.priceTo) ? this.searchForm.value.priceTo : '',
      ReturnBatchProducts: this.searchForm.value.relatedProducts,
      CurrencyID: 2,
      CustomerID: this.searchForm.value.CustomerID,
      isAdmin: false,
      childCustomerID: this.searchForm.value.childwebshop,
      customerID: this.webshopDetail.CustomerID,
      ColorID: (this.selectedColorID !== '') ? Number(this.selectedColorID) : ''
    }
    this.searchData.emit(searchData)
    this.searchStatus.emit(true);
    // productsWithoutWebshop=true&productsWithoutWebshopCustomerID=undefined&childCustomerID=1
  }

  resetForm() {
    this.searchForm.patchValue({
      relatedProducts: true,
      quantity: '',
      productCategory: '',
      supplier: '',
      SizeID: '',
      maxDeliveryWeeks: '',
      priceFrom: '',
      priceTo: ''
    });
    this.selectedColorID = '';
    this.productName = '';
    this.searchText = '';
    this.searchproduct = [];
    this.autoComp.clear();
    this.autoComp.close();
    this.selectSearchData.emit(1);
    this.searchStatus.emit(false);
    this.getSupplierSizes();
  }

  populateCategoryList() {
    let selectCategoryLabel = '';
    this.translate.get('CATEGORIES.SELECT_PRODUCT_CATEGORY').subscribe((res: string) => {
      selectCategoryLabel = res;
    });
    // debugger
    this.categoryHttpService.getCustomerCategoryList(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        console.log("Populate category response list : ", data);
        const categoryData = [{ label: selectCategoryLabel, value: '' }];
        data.forEach(option => {
          categoryData.push({ label: option.Name, value: option.CategoryID })
        });
        this.category = categoryData;
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  autocomplete(value) {
    this.productName = value;
    if (value !== '') {
      this.categoryHttpService.searchProduct(value, this.webshopDetail.CustomerID).subscribe((data: any) => {
        if (data) {
          this.searchproduct = data;
          console.log(this.searchproduct)
        }
      },
        err => {
          console.log(err);
          this.showLoader = false;
        });
    }
  }

  selected(option) {
    this.productName = option.ProductName;
  }

  selectedSearch(key?) {
    if (key !== undefined) {
      this.productName = key.target.value;
      console.log(key)
      console.log(this.productName)
    }
    console.log(key)
    console.log(this.productName)
    this.search();
  }

  clearProductName() {
    this.productName = '';
  }
}
