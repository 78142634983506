import { Component, OnInit, ViewChild, Output, EventEmitter, OnChanges, Input, AfterViewInit } from '@angular/core';
import { OrderHttpService } from '../state/services/order.http.service';
import { ToastService, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { OrderProcessService } from '../state/services/order.process.service';
import { CommonFilterComponent } from 'src/app/common/common-filter/common-filter.component';
import { OrderAdvanceSearchModalComponent } from './order-advance-search-modal/order-advance-search-modal.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rewardkart-admin-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})
export class OrderSearchComponent {
  @ViewChild('commonFilter', { static: true }) commonFilterComponent: CommonFilterComponent;
  @Input() resetSearchFlag: any;
  @Output() orderListPayload = new EventEmitter<any>();
  filterFields: any = [];
  customerList: any = [];
  suppliers: any = [];
  companyName: any = [];
  underSuppliers: any = [];
  optionsSelectCountries: any = [];
  payments: any = [];
  orderItemStatusList: any = [];
  paymentLoader: boolean = false;
  customerLoader: boolean = false;
  supplierLoader: boolean = false;
  underSupplierLoader: boolean = false;
  orderStatusDetailsLoader: boolean = false;
  pageLimit: any = {};
  modalRef: MDBModalRef;
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };
  payload: any;
  searchParameter = '';
  searchAdvanceQParam = [];
  filterState = [];
  webshopDetail: any;
  searchCriteriaObject: any;

  constructor(private orderHttpService: OrderHttpService,
    private toaster: ToastService, private modalService: MDBModalService,
    private orderProcessService: OrderProcessService, private route: ActivatedRoute,
    private translate: TranslateService) {
    const resetSearch = this.route.snapshot.paramMap.get('resetSearch');
    const { resetSearchValue } = history.state;
    this.resetSearchFlag = resetSearch || resetSearchValue;
    this.onLoadFunction();
  }

  onLoadFunction() {
    this.searchCriteriaObject = this.resetSearchFlag === 'true' ? this.orderProcessService.defaultSearch : this.orderProcessService.updatedSearch;
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID) {
        this.getSuppliers(this.webshopDetail.CustomerID);
      } else {
        this.getCustomers();
      }
    }
    if(this.webshopDetail.DomainName === "sw" || this.webshopDetail.DomainName === "smartworks"){
      this.getCompanyList();
    }
    this.getCountryList();
    this.getPayments();
    this.getListOrderStatusDetails();
  }

  onLoad(resetSearchFlag) {
    if (resetSearchFlag === 'true') {
      this.searchAdvanceQParam = this.orderProcessService.defaultAdvanceSearchPayload;
      this.prepareSearchFields(this.orderProcessService.defaultSearch);
    } else {
      this.searchAdvanceQParam = this.orderProcessService.updateAdvanceSearchPayload;
      this.prepareSearchFields(this.orderProcessService.updatedSearch);
    }
  }

  getCompanyList(){
    this.orderHttpService.getCompanyList().subscribe((data:any)=>{
      if (data) {
        data.forEach(e => {
            const a = {
            value: e.companyname,
            label: e.companyname
            }
            this.companyName.push(a)
          });
        this.updateSearchFormFields('CompanyNmae', this.companyName);
      }
    })
  }

  getCountryList() {
    this.orderHttpService.getCountryList().subscribe(
      (countryList: any) => {
        if (countryList) {
          this.optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          countryList.forEach(country => {
            const countryOption = {
              value: country.Alpha2Code,
              label: country.Name,
            };
            this.optionsSelectCountries.push(countryOption);
          });
          this.updateSearchFormFields('invoiceCountryCode', this.optionsSelectCountries);
          this.updateSearchFormFields('deliveryCountryCode', this.optionsSelectCountries);
        }
      }, error => {
        this.toaster.error('unable to get country code');
      });
  }

  getPayments() {
    this.paymentLoader = true;
    this.orderHttpService.getPaymentsData().subscribe(
      (paymentResponse: any) => {
        if (paymentResponse) {
          this.payments = paymentResponse.map(payment => ({
            value: payment.PaymentTypeID,
            label: payment.Name
          }));
          this.updateSearchFormFields('paymentTypeID', this.payments.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label)));
        }
        this.paymentLoader = false;
      },
      error => {
        this.paymentLoader = false;
        this.toaster.error('unable to get payments');
      }
    );
  }

  getCustomers() {
    this.customerLoader = true;
    this.orderHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
      (customersRes: any) => {
        if (customersRes) {
          const customerlist = [{ label: 'Select All', value: 0 }];
          customersRes.forEach(cust => {
            let custData = {
              label: cust.Name,
              value: cust.CustomerID
            }
            customerlist.push(custData);
          });
          this.customerList = customerlist;
          this.updateSearchFormFields('CustomerID', this.customerList);
          const customerId = this.resetSearchFlag === 'true' ?
            this.webshopDetail.CustomerID : this.searchCriteriaObject['CustomerID'];
          this.getSuppliers(customerId);
        }
        this.customerLoader = false;
      },
      error => {
        this.customerLoader = false;
        this.toaster.error('unable to get webshop customers');
      }
    );
  }

  getSuppliers(customerId: any) {
    this.supplierLoader = true;
    if (customerId === 0) {
      customerId = this.webshopDetail.CustomerID;
    }
    this.orderHttpService.getSuppliers(customerId, this.webshopDetail.CustomerID).subscribe(
      (supplierRes: any) => {
        if (supplierRes) {
          this.suppliers = supplierRes.ItemsCollection.map(supplier => ({
            value: supplier.SupplierID,
            label: supplier.Name
          }));
          const selectSupplier = { value: '', label: 'Select Supplier' };
          this.suppliers = this.suppliers.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label));
          this.suppliers = [selectSupplier, ...this.suppliers];
          this.updateSearchFormFields('SupplierID', this.suppliers);
        }
        this.supplierLoader = false;
      },
      error => {
        this.supplierLoader = false;
        this.toaster.error('unable to get suppliers');
      }
    );
  }

  getUnderSuppliers(customerId, supplierID) {
    this.underSupplierLoader = true;
    this.orderHttpService.getUnderSuppliers(customerId, supplierID, this.webshopDetail.CustomerID).subscribe(
      (underSupplierRes: any) => {
        if (underSupplierRes) {
          this.underSuppliers = underSupplierRes.ItemsCollection.map(underSupplier => ({
            value: underSupplier.UndersupplierID,
            label: underSupplier.Name,
          }));
          const selectUnderSupplier = { value: '', label: 'Select UnderSupplier' };
          this.underSuppliers = this.underSuppliers.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label));
          this.underSuppliers = [selectUnderSupplier, ...this.underSuppliers];
          this.updateSearchFormFields('UndersupplierID', this.underSuppliers);
        }
        this.underSupplierLoader = false;
      },
      error => {
        this.underSupplierLoader = false;
        this.toaster.error('unable to get under suppliers');
      }
    );
  }

  updateSearchFormFields(key: any, value: any) {
    if (this.commonFilterComponent && this.commonFilterComponent.filterFormField !== undefined) {
      this.commonFilterComponent.filterFormField.forEach((field) => {
        if (field.key === key) {
          field.data = value;
          field.selected = (this.resetSearchFlag === 'true') ? this.orderProcessService.defaultSearch[key] :
            this.orderProcessService.updatedSearch[key];
        }
      });
    }
  }

  getListOrderStatusDetails() {
    this.orderStatusDetailsLoader = true;
    this.orderHttpService.getListOrderStatusDetails().subscribe(
      (orderItemStatus: any) => {
        if (orderItemStatus) {
          this.orderItemStatusList = orderItemStatus.map(orderItemState => ({
            value: orderItemState.OrderItemStateID,
            label: orderItemState.StateName,
            Options: orderItemState.Options === undefined ? [] : this.prepareOptions(orderItemState.Options)
          }));
        }
        this.orderStatusDetailsLoader = false;
        this.onLoad(this.resetSearchFlag);
        this.handleFilterResult({});
      },
      error => {
        this.orderStatusDetailsLoader = false;
        this.toaster.error('unable to get order item states');
      }
    );
  }

  prepareOptions(options) {
    return options.map((optionItem: any) => {
      return {
        ...optionItem, OrderItemStateOptionID: optionItem.OrderItemStateOptionID ? optionItem.OrderItemStateOptionID :
          optionItem.OrderItemStateID + '-1'
      };
    })
  }

  prepareSearchFields(searchCriteriaValue) {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12 pl-3 pt-4',
        key: 'filterHeading',
        filterkey: ''
      },

    ];
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.filterFields.push(
          {
            label: 'Select Webshop',
            type: 'select',
            data: this.customerList,
            class: 'col-md-12 no-icon',
            key: 'CustomerID',
            filterkey: 'CustomerID',
            selected: searchCriteriaValue.CustomerID
          }
        );
      }
    }
    this.filterFields.push(   
      {
        label: 'Order Number',
        type: 'text',
        class: 'col-md-12',
        key: 'orderIdManual',
        filterkey: 'orderIdManual',
        name: 'orderIdManual',
        selected: searchCriteriaValue.orderIdManual
      },
      {
        label: 'User Name',
        type: 'text',
        class: 'col-md-12',
        key: 'userName',
        filterkey: 'userName',
        name: 'userName',
        selected: searchCriteriaValue.userName
      }
     /* {
        label: 'Select Supplier',
        type: 'select',
        data: this.suppliers,
        class: 'col-md-12 no-icon',
        key: 'SupplierID',
        selected: searchCriteriaValue.SupplierID,
        filterkey: 'SupplierID'
      },
      // {
      //   label: 'Select Under Supplier',
      //   type: 'select',
      //   data: this.underSuppliers,
      //   class: 'col-md-12 no-icon',
      //   key: 'UndersupplierID',
      //   selected: searchCriteriaValue.UndersupplierID,
      //   filterkey: 'UndersupplierID'
      // },
      {
        label: 'Select Invoice Country',
        type: 'select',
        data: this.optionsSelectCountries,
        class: 'col-md-12 no-icon',
        key: 'invoiceCountryCode',
        selected: searchCriteriaValue.invoiceCountryCode,
        filterkey: 'invoiceCountryCode'
      },
      {
        label: 'Select Delivery Country',
        type: 'select',
        data: this.optionsSelectCountries,
        class: 'col-md-12 no-icon',
        key: 'deliveryCountryCode',
        selected: searchCriteriaValue.deliveryCountryCode,
        filterkey: 'deliveryCountryCode'
      },
      {
        label: 'Document Number',
        type: 'text',
        class: 'col-md-12',
        key: 'documentNumber',
        filterkey: 'documentNumber',
        name: 'documentNumber',
        tooltip: 'Invoice Number, Credit Note Number, Delivery Note Number',
        selected: searchCriteriaValue.documentNumber
      },
      {
        label: 'Carrier',
        type: 'text',
        class: 'col-md-12',
        key: 'carrier',
        filterkey: 'carrier',
        name: 'carrier',
        selected: searchCriteriaValue.carrier
      },
      {
        label: 'Tracking Number',
        type: 'text',
        class: 'col-md-12',
        key: 'trackingNumber',
        filterkey: 'trackingNumber',
        name: 'trackingNumber',
        selected: searchCriteriaValue.trackingNumber
      },
      {
        label: 'Recipient Name',
        type: 'text',
        class: 'col-md-12',
        key: 'orderRecipient',
        filterkey: 'orderRecipient',
        name: 'orderRecipient',
        selected: searchCriteriaValue.orderRecipient
      },
      {
        label: 'Item Number',
        type: 'text',
        class: 'col-md-12',
        key: 'productIdManual',
        filterkey: 'productIdManual',
        name: 'productIdManual',
        selected: searchCriteriaValue.productIdManual
      },
      {
        label: 'Product Name',
        type: 'text',
        class: 'col-md-12',
        key: 'productName',
        filterkey: 'productName',
        name: 'productName',
        selected: searchCriteriaValue.productName
      },
      {
        label: 'Placed From ',
        type: 'date',
        class: 'col-md-12',
        key: 'orderPlacedFromDate',
        default: '',
        selected: searchCriteriaValue.orderPlacedFromDate,
        format: {
          closeAfterSelect: true,
          dateFormat: 'dd/mm/yyyy',
        },
        filterkey: 'orderPlacedFromDate'
      },
      {
        label: 'Placed To ',
        type: 'date',
        class: 'col-md-12',
        key: 'orderPlacedToDate',
        default: '',
        selected: searchCriteriaValue.orderPlacedToDate,
        format: {
          closeAfterSelect: true,
          dateFormat: 'dd/mm/yyyy',
        },
        filterkey: 'orderPlacedToDate'
      },
      {
        label: 'Delivery Date ',
        type: 'date',
        class: 'col-md-12',
        key: 'deliveryDate',
        default: '',
        selected: searchCriteriaValue.deliveryDate,
        format: {
          closeAfterSelect: true,
          dateFormat: 'dd/mm/yyyy',
        },
        filterkey: 'deliveryDate'
      },
      {
        label: 'Select Payments',
        type: 'select',
        data: this.payments,
        class: 'col-md-12 no-icon',
        key: 'paymentTypeID',
        selected: searchCriteriaValue.paymentTypeID,
        filterkey: 'paymentTypeID'
      },
      {
        label: 'Sample Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'sampleorder',
        filterkey: 'sampleorder',
        selected: searchCriteriaValue.sampleorder
      },
      {
        label: 'Order History',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'finishedOrders',
        filterkey: 'finishedOrders',
        selected: searchCriteriaValue.finishedOrders
      },
      {
        label: 'Unauthorised Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyUnauthorisedOrders',
        filterkey: 'onlyUnauthorisedOrders',
        selected: searchCriteriaValue.onlyUnauthorisedOrders
      },
      {
        label: 'Deleted Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyDeletedOrders',
        filterkey: 'onlyDeletedOrders',
        selected: searchCriteriaValue.onlyDeletedOrders
      },
      {
        label: 'Orders Without Transportation',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyOrdersWithoutTransportation',
        filterkey: 'onlyOrdersWithoutTransportation',
        selected: searchCriteriaValue.onlyOrdersWithoutTransportation
      },
      {
        label: 'Internal Stock Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyInternalStockOrders',
        filterkey: 'onlyInternalStockOrders',
        selected: searchCriteriaValue.onlyInternalStockOrders
      },
      {
        label: 'Express Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyExpressOrders',
        filterkey: 'onlyExpressOrders',
        selected: searchCriteriaValue.onlyExpressOrders
      },
      {
        label: 'Internal Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyInternalOrders',
        filterkey: 'onlyInternalOrders',
        selected: searchCriteriaValue.onlyInternalOrders
      },
      {
        label: 'Advanced Search',
        type: 'anchor',
        class: 'col-md-12',
        key: 'advanceSearch',
      },*/
      ,{
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    );
    if(this.webshopDetail.DomainName === "sw" || this.webshopDetail.DomainName === "smartworks"){
      let a = {
        label: 'Select Company Name',
        type: 'select',
        data: this.companyName,
        class: 'col-md-12 no-icon',
        key: 'CompanyName',
        selected: searchCriteriaValue.CompanyName,
        filterkey: 'CompanyName'
      }
      this.filterFields.splice(2,1,a)
    }
  }

  getSelectData(selectedFieldValue: any) {
    this.commonFilterComponent.filterFormField.forEach((field) => {
      if (selectedFieldValue[field.key] && selectedFieldValue[field.key].selectMethod &&
        field.key == selectedFieldValue[field.key].selectMethod) {
        if (!this.excludeFields(selectedFieldValue[field.key].selectMethod)) {
          if (field.key === 'CustomerID' && selectedFieldValue[field.key].value === '') {
            field.selected = selectedFieldValue[field.key].value === '' ? 0 : selectedFieldValue[field.key].value;
            this.searchCriteriaObject[field.key] = selectedFieldValue[field.key].value === '' ? 0 : selectedFieldValue[field.key].value;
          } else {
            field.selected = selectedFieldValue[field.key].value;
            this.searchCriteriaObject[field.key] = selectedFieldValue[field.key].value;
          }
        }
      }
    });
  }

  excludeFields(selectMethod) {
    return (selectMethod === 'advanceSearch' || selectMethod === 'button' || selectMethod === 'clear' || selectMethod === 'filterHeading');
  }

  handleSubmit(formData: any) { }

  handleResetForm(event: any) {
    this.commonFilterComponent.filterFormField.forEach((field) => {
      if (event && event[field.key] && event[field.key].value) {
        event[field.key].value = event[field.key].value !== '' ? '' : false;
      }
    });
    this.orderProcessService.updatedPageLimit.pageSize = 30;
    this.orderProcessService.updatedPageLimit.pageIndex = 1;
    this.orderProcessService.defaultSearch = {
      CustomerID: 0,
      SupplierID: '',
      UndersupplierID: '',
      invoiceCountryCode: '',
      deliveryCountryCode: '',
      orderIdManual: '',
      documentNumber: '',
      carrier: '',
      trackingNumber: '',
      userName: '',
      orderRecipient: '',
      productIdManual: '',
      productName: '',
      orderPlacedFromDate: '',
      orderPlacedToDate: '',
      deliveryDate: '',
      paymentTypeID: '',
      sampleorder: false,
      finishedOrders: false,
      unfinishedOrders: false,
      onlyUnauthorisedOrders: false,
      onlyDeletedOrders: false,
      onlyOrdersWithoutTransportation: false,
      onlyInternalStockOrders: false,
      onlyExpressOrders: false,
      onlyInternalOrders: false
    };
    this.searchCriteriaObject = this.orderProcessService.defaultSearch;
    this.orderProcessService.updateSearchCriteria(this.orderProcessService.defaultSearch);
    this.orderProcessService.updateAdvanceSearchPayloadValue(this.orderProcessService.defaultAdvanceSearchPayload);
    this.searchAdvanceQParam = this.orderProcessService.defaultAdvanceSearchPayload;
    this.onLoadFunction();
  }

  handleFilterResult(searchFieldsData: any) {
    this.searchParameter = '';
    for (const property in this.searchCriteriaObject) {
      if (this.searchCriteriaObject[property] && this.searchCriteriaObject[property] !== '') {
        if (property === 'orderPlacedToDate' && this.searchCriteriaObject['orderPlacedToDate'] !== '') {
          const placedTo = this.orderHttpService.dateFormatchange(this.searchCriteriaObject['orderPlacedToDate']);
          const formatedParam = `${property}=${placedTo}`;
          this.searchParameter = this.searchParameter ? `${this.searchParameter}&${formatedParam}` : formatedParam;
        } else if (property === 'orderPlacedFromDate' && this.searchCriteriaObject['orderPlacedFromDate'] !== '') {
          const placedFrom = this.orderHttpService.dateFormatchange(this.searchCriteriaObject['orderPlacedFromDate']);
          const formatedParam = `${property}=${placedFrom}`;
          this.searchParameter = this.searchParameter ? `${this.searchParameter}&${formatedParam}` : formatedParam;
        }
        else if (property === 'deliveryDate' && this.searchCriteriaObject['deliveryDate'] !== '') {
          const datedelivery = this.orderHttpService.dateFormatchange(this.searchCriteriaObject['deliveryDate']);
          const formatedParam = `${property}=${datedelivery}`;
          this.searchParameter = this.searchParameter ? `${this.searchParameter}&${formatedParam}` : formatedParam;
        }
        else {
          const formatedParam = `${property}=${this.searchCriteriaObject[property]}`;
          this.searchParameter = this.searchParameter ? `${this.searchParameter}&${formatedParam}` : formatedParam;
        }
      }
    }
    if (this.searchCriteriaObject['CustomerID'] === 0 && this.webshopDetail.ParentCustomerID === null) {
      this.orderProcessService.includChildShopOrders = true;
    } else {
      this.orderProcessService.includChildShopOrders = false;
    }
    this.payload = {
      customerId: this.searchCriteriaObject.CustomerID,
      includChildShopOrders: this.orderProcessService.includChildShopOrders,
      searchQueryParam: this.searchCriteriaObject['CustomerID'] === 0 ?
        this.searchParameter + '&CustomerID=' + this.webshopDetail.CustomerID : this.searchParameter,
      advanceSearchPayload: this.searchAdvanceQParam
    };
    this.orderProcessService.updateSearchCriteria(this.searchCriteriaObject);
    this.orderListPayload.emit(this.payload);
  }

  advanceSearchEvent(eventData) {
    if (eventData.key === 'advanceSearch') {
      const modalData = {
        orderItems: this.orderItemStatusList.map(statusItem => {
          statusItem.checked = false;
          statusItem.OrderItemStateOptionID = '';
          if (this.filterState[statusItem.value]) {
            statusItem.OrderItemStateOptionID = this.filterState[statusItem.value].OrderItemStateOptionID;
            statusItem.checked = true;
          }
          return { ...statusItem };
        })
      };
      this.modalRef = this.modalService.show(OrderAdvanceSearchModalComponent, {
        ...this.modalOptions, data: modalData
      });

      this.modalRef.content.modalAction.subscribe((result: any) => {
        this.filterState = result.filterState;
        if (result.data.length > 0) {
          this.searchAdvanceQParam = result.data;
          this.orderProcessService.updateAdvanceSearchPayloadValue(this.searchAdvanceQParam);
          this.payload = {
            customerId: this.searchCriteriaObject.CustomerID,
            includChildShopOrders: this.orderProcessService.includChildShopOrders,
            searchQueryParam: this.searchCriteriaObject['CustomerID'] === 0 ?
              this.searchParameter + '&CustomerID=' + this.webshopDetail.CustomerID : this.searchParameter,
            advanceSearchPayload: this.searchAdvanceQParam
          };
          this.orderListPayload.emit(this.payload);
        }
      });
    } else if (eventData.key === 'CustomerID') {
      this.getSuppliers(eventData.value);
    } else if (eventData.key === 'SupplierID') {
      // this.getUnderSuppliers(this.searchCriteriaObject.CustomerID, eventData.value);
    }
  }
}
