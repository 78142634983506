import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/state/services/user.service';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';


@Component({
  selector: 'app-message-draft',
  templateUrl: './message-draft.component.html',
  styleUrls: ['./message-draft.component.scss']
})
export class MessageDraftComponent implements OnInit {
  //public titles: any = 'Compose Message';
  // @Input() loadApiStatus:any;
  enablepagination: boolean = true;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  rowactivePage: number = 1;
  activePage: number = 1;
  paginationData: any = {};
  customerList: any = [];
  filterFields: any = [];
  tableName: string = 'message';
  public messagedraft: any = [];
  showMessageDetailpage: boolean = false;
  showMessageInbox: boolean = true;
  EmailID: any = 0;
  messagedraftQueryParams: any = {};
  customerId: any = 0;
  draftdatacount: any;
  showLoader: Boolean = false;
  public inboxDraftData: ColumnSetting[] = [
    {
      primaryKey: 'ProductionLineID',
      header: 'Select',
      format: 'bulkaction',
      key: 'draftcheckbox'
    },
    {
      primaryKey: 'SenderName',
      header: 'Name',
    },
    {
      primaryKey: 'SenderEmail',
      header: 'Email',
    },
    {
      primaryKey: 'Subject',
      header: 'Subject',
    },
    {
      primaryKey: 'SendingTime',
      header: 'Date',
      format: 'time'
    }
  ];
  titles: any;
  bulkEmailIds: any;
  tableEvent: any;
  isBulkEditSelectedForDelete = {
    data: []
  }
  webshopDetail: any;


  constructor(public messageboxservice: MessageboxService, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private toast: ToastService, private messageboxhttpservice: MessageboxHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    // this.MessageBoxLineData();
    this.loadCustomer();
  }

  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.EmailID = '';
    this.messagedraftQueryParams.EmailID = this.EmailID;
    this.messagedraftQueryParams.customerID = 0;
    this.messagedraftQueryParams.UserID = loginData.UserID;
    this.messagedraftQueryParams.pageSize = this.perPagedata;
    this.messagedraftQueryParams.activePage = this.activePage;
    sessionStorage.setItem('draftfilter', JSON.stringify(this.messagedraftQueryParams))
    this.MessageBoxLineData(this.messagedraftQueryParams);
    this.messageboxservice.loadCustomers(this.messagedraftQueryParams)
  }

  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        this.customerList = [{ label: 'Select All', value: 0 }];
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });

        this.filterFields = [];
        if (this.customerList.length > 0) {
          this.filterFields.push(
            {
              label: 'Customer',
              type: 'select',
              data: this.customerList,
              class: 'col-md-3',
              key: 'inboxcustomer',
              required: false
            },
            {
              label: 'Search',
              type: 'button',
              class: '',
              key: 'button',
            },
            {
              label: 'Refresh',
              type: 'button',
              class: '',
              key: 'reset',
            })
        }
        this.filterFields.push({
          label: 'Delete',
          type: 'button',
          class: 'ml-auto mt-2 pt-1',
          key: 'messagedelete',
          model: 'deletedraft'
        })
      }
    })
  }

  MessageBoxLineData(request) {
    if (this.customerId === 0) {
      request.showall = true;
      // const loginData = this.apiService.getLoginData();
      request.customerID = this.webshopDetail.CustomerID;
    }
    else {
      request.showall = false;
    }
    request.showall = true;
    this.showLoader = true;
    this.messageboxhttpservice.getMessageDraftData(request).subscribe((data: any) => {
      if (data) {
        this.messagedraft = data.ItemsCollection;
        this.draftdatacount = data.TotalItemsCount;
        this.showMessageDetailpage = false;
        this.showMessageInbox = true;
      }
      this.showLoader = false;
    },
      (error) => {
        this.toast.error(error.error.Message);
        this.showLoader = false;
      })
  }


  getPerPage(event) {
    this.perPagedata = event;
    this.messagedraftQueryParams.pageSize = event;
    this.messagedraftQueryParams.pageIndex = event;
    let getFilter = JSON.parse(sessionStorage.getItem('draftfilter'));
    getFilter.pageSize = event;
    getFilter.pageIndex = event;
    sessionStorage.setItem('draftfilter', JSON.stringify(getFilter));
    if (this.customerId > 0) {
      sessionStorage.setItem('draftfilter', JSON.stringify(this.messagedraftQueryParams));
    }
    this.getSubmit('pagechange');
  }
  paginationNum(event) {
    this.activePage = event;
    this.messagedraftQueryParams.activePage = event;
    let getFilter = JSON.parse(sessionStorage.getItem('draftfilter'));
    getFilter.activePage = event;
    sessionStorage.setItem('draftfilter', JSON.stringify(getFilter));
    console.log(this.customerId);
    if (this.customerId > 0) {
      sessionStorage.setItem('draftfilter', JSON.stringify(this.messagedraftQueryParams));
    }
    this.getSubmit('pagechange');
  }

  trashdetails(messageData: boolean) {
    if (messageData) {
      this.showMessageDetailpage = true;
      try {
        this.messageboxservice.selectedMessage(messageData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.showMessageDetailpage = true;
            this.showMessageInbox = false;
            this.EmailID = data.EmailID;
            this.router.navigate(['/app-message-draftdetails'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }
  }

  getBulkAction(event) {
    if (event.bulkaction.draftcheckbox !== undefined) {
      let getaction = event.bulkaction.draftcheckbox;
      this.bulkEmailIds = [];
      getaction.forEach(element => {
        this.bulkEmailIds.push(element.EmailID)
      });
    }
    this.isBulkEditSelectedForDelete.data = this.bulkEmailIds;
  }

  deleteRow(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deletedraft) {
      const loginData = this.apiService.getLoginData();
      const deleteData = {
        "ActionType": "TRASH",
        "Value": true,
        "EmailIDs": this.bulkEmailIds
      }

      const initialDeleteData = {
        "ActionType": "DRAFT",
        "Value": false,
        "EmailIDs": this.bulkEmailIds
      }
      this.messageboxhttpservice.bulkAction(initialDeleteData, loginData.UserID).subscribe((data: any) => {
        if (data) {
          this.messageboxhttpservice.bulkAction(deleteData, loginData.UserID).subscribe((data: any) => {
            if (data != null) {
              if (data) {
                setTimeout(() => {
                  this.toast.success('Email deleted successfully');
                  this.MessageBoxLineData(this.messagedraftQueryParams);
                  this.bulkEmailIds = [];
                  this.isBulkEditSelectedForDelete.data = [];
                }, 300)
              }
            }
          })
        }
      });


    }
  }


  getSubmit(type) {
    let getFilter = this.messagedraftQueryParams;
    if (type === 'pagechange') {
      getFilter = JSON.parse(sessionStorage.getItem('draftfilter'));
    }
    else {
      this.activePage = 1;
      getFilter.activePage = this.activePage;
    }
    this.MessageBoxLineData(getFilter);
  }

  getSelectData(event) {
    if (event.inboxcustomer !== undefined && event.inboxcustomer.selectMethod === 'inboxcustomer') {
      this.customerId = event.inboxcustomer.value;
      this.messagedraftQueryParams.customerID = this.customerId;
    }
  }

  getReset(reset) {
    if (reset.selectMethod === 'reset' && reset.value) {
      // const loginData = this.apiService.getLoginData();
      this.customerId = 0;
      this.messagedraftQueryParams.customerID = this.customerId;
      this.activePage = 1;
      this.messagedraftQueryParams.activePage = this.activePage;
      this.MessageBoxLineData(this.messagedraftQueryParams);
      this.loadCustomer();
    }
  }

}
