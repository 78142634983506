import { Action } from '@ngrx/store';
import { cleanSession } from 'selenium-webdriver/safari';


export const SELECTED_MESSAGE = '[message] Message Selected ';

//Message widget Actions
export const LOAD_MESSAGEINBOXSTATE = '[Mesagebox] Load all OrderState';
export const LOAD_MESSAGEINBOX_SUCCESS = '[Mesagebox] Load OrderState Success';
export const LOAD_MESSAGEINBOX_FAIL = '[Mesagebox] Load OrderState Fail';


//Message draft Actions
export const LOAD_MESSAGEDRAFTSTATE = '[Mesagedraft] Load all MessageDraft';
export const LOAD_MESSAGEDRAFT_SUCCESS = '[Mesagedraft] Load MessageDraft Success';
export const LOAD_MESSAGEDRAFT_FAIL = '[Mesagedraft] Load MessageDraft Fail';

//Message draft Actions
export const LOAD_MESSAGEIMPORTANTSTATE = '[Mesageimportant] Load all MessageImportant';
export const LOAD_MESSAGEIMPORTANT_SUCCESS = '[Mesageimportant] Load MessageImportant Success';
export const LOAD_MESSAGEIMPORTANT_FAIL = '[Mesageimportant] Load MessageImportant Fail';

//Message draft Actions
export const LOAD_MESSAGEINQUIRIESSTATE = '[Mesageinquiries] Load all MessageInquiries';
export const LOAD_MESSAGEINQUIRIES_SUCCESS = '[Mesageinquiries] Load MessageInquiries Success';
export const LOAD_MESSAGEINQUIRIES_FAIL = '[Mesageinquiries] Load MessageInquiries Fail';

//Message draft Actions
export const LOAD_MESSAGETRASHSTATE = '[Mesagetrash] Load all MessageTrash';
export const LOAD_MESSAGETRASH_SUCCESS = '[Mesageinquiries] Load MessageTrash Success';
export const LOAD_MESSAGETRASH_FAIL = '[Mesageinquiries] Load MessageTrash Fail';

//Message draft Actions
export const LOAD_MESSAGECLAIMSSTATE = '[Mesageclaims] Load all MessageClaims';
export const LOAD_MESSAGECLAIMS_SUCCESS = '[Mesageclaims] Load MessageClaims Success';
export const LOAD_MESSAGECLAIMS_FAIL = '[Mesageclaims] Load MessageClaims Fail';

//Message draft Actions
export const LOAD_MESSAGESENTSTATE = '[Mesagesent] Load all MessageSent';
export const LOAD_MESSAGESENT_SUCCESS = '[Mesagesent] Load MessageSent Success';
export const LOAD_MESSAGESENT_FAIL = '[Mesagesent] Load MessageSent Fail';

//Message Customer Actions
export const LOAD_CUSTOMERS = '[Message] Load all customer';
export const LOAD_CUSTOMERS_SUCCESS = '[Message] Load customer Success';
export const LOAD_CUSTOMERS_FAIL = '[Message] Load customer Fail';

//Message maessagae details
export const LOAD_MESSAGEINBOXDETAILSSTATE = '[Message] Load all Messagedetails';
export const LOAD_MESSAGEINBOXDETAILS_SUCCESS = '[Message] Load customer Messagedetails';
export const LOAD_MESSAGEINBOXDETAILS_FAIL = '[Message] Load customer Messagedetails';
//Message important messages
export const LOAD_MESSAGEINPORTANTDETAILSSTATE = '[Message] Load all messageimportantdetailsstate';
export const LOAD_MESSAGEINPORTANTDETAILS_SUCCESS = '[Message] Load customer messageimportantdetailsstate';
export const LOAD_MESSAGEINPORTANTDETAILS_FAIL = '[Message] Load customer messageimportantdetailsstate';
//Message Bulk Actions
export const LOAD_BULKACTION = '[Message] Load all BulkAction';
export const LOAD_BULKACTION_SUCCESS = '[Message] Load BulkAction Success';
export const LOAD_BULKACTION_FAIL = '[Message] Load BulkAction Fail';
//Message Send Message
export const SEND_MESSAGE = '[Messagebox] Send Message';
export const SEND_MESSAGE_SUCCESS = '[Messagebox] Send Message Success';
export const SEND_MESSAGE_FAIL = '[Messagebox] Send Message Fail';
//Message Send Message
export const SEND_DRAFT_MESSAGE = '[Messagebox] Send Draft Message';
export const SEND_DRAFT_MESSAGE_SUCCESS = '[Messagebox] Send Draft Message Success';
export const SEND_DRAFT_MESSAGE_FAIL = '[Messagebox] Send Draft Message Fail';
//Message Delete Message
export const DELETE_MESSAGE = '[Messagebox] Delete Message';
export const DELETE_MESSAGE_SUCCESS = '[Messagebox] Delete Message Success';
export const DELETE_MESSAGE_FAIL = '[Messagebox] Delete Message Fail';
//Message Delete Message
export const DELETE_MAIL = '[Messagebox] Delete Mail';
export const DELETE_MAIL_SUCCESS = '[Messagebox] Delete Mail Success';
export const DELETE_MAIL_FAIL = '[Messagebox] Delete Mail Fail';
//Message Load User Mail Address
export const LOAD_USERMAILADDRESS = '[Messagebox] Load User Mail Address ';
export const LOAD_USERMAILADDRESS_SUCCESS = '[Messagebox] Load User Mail Address Success';
export const LOAD_USERMAILADDRESS_FAIL = '[Messagebox] Load User Mail Address Fail';

//Message Load User Mail Address
export const LOAD_MESSAGECLAIMSDETAILSSTATE = '[Messageboxclaims] Load messageclaimsdetailsstate ';
export const LOAD_MESSAGECLAIMSDETAILS_SUCCESS = '[Messageboxclaims] Load messageclaimsdetails Success';
export const LOAD_MESSAGECLAIMSDETAILS_FAIL = '[Messageboxclaims] Load essageclaimsdetails Fail';

//Message Load User Mail Address
export const LOAD_MESSAGEINQUIRIESDETAILSSTATE = '[Messageboxclaims] Load messageinquiriesdetailsstate ';
export const LOAD_MESSAGEINQUIRIESDETAILS_SUCCESS = '[Messageboxclaims] Load messageinquiriesdetails Success';
export const LOAD_MESSAGEINQUIRIESDETAILS_FAIL = '[Messageboxclaims] Load essageinquiriesdetails Fail';

//Message Load User Mail Address
export const LOAD_MESSAGEREADSTATE = '[Messageboxclaims] Load messagereadstate ';
export const LOAD_MESSAGEREAD_SUCCESS = '[Messageboxclaims] Load messageread Success';
export const LOAD_MESSAGEREAD_FAIL = '[Messageboxclaims] Load messageread Fail';

export const LOAD_EMAILUNREAD = '[Messageboxclaims] Load Email Unread ';
export const LOAD_EMAILUNREAD_SUCCESS = '[Messageboxclaims] Load Email Unread Success';
export const LOAD_EMAILUNREAD_FAIL = '[Messageboxclaims] Load Email Unread Fail';

export const LOAD_CLAIMSUNREAD = '[Messageboxclaims] Load claims Unread ';
export const LOAD_CLAIMSUNREAD_SUCCESS = '[Messageboxclaims] Load claims Unread Success';
export const LOAD_CLAIMSUNREAD_FAIL = '[Messageboxclaims] Load claims Unread Fail';

export const LOAD_INQURIESUNREAD = '[Messageboxclaims] Load inquries Unread ';
export const LOAD_INQURIESUNREAD_SUCCESS = '[Messageboxclaims] Load inquries Unread Success';
export const LOAD_INQURIESUNREAD_FAIL = '[Messageboxclaims] Load inquries Unread Fail';




export class SelectedMessage implements Action {
    readonly type = SELECTED_MESSAGE;
    constructor(public payload: any) { }
}

//Message
export class LoadMessageinboxState implements Action {
    readonly type = LOAD_MESSAGEINBOXSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageinboxSuccess implements Action {
    readonly type = LOAD_MESSAGEINBOX_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMessageinboxFail implements Action {
    readonly type = LOAD_MESSAGEINBOX_FAIL;
    constructor(public payload: any) { }
}

//Message claims detailspage
export class LoadMessageinquiriesdetailsState implements Action {
    readonly type = LOAD_MESSAGEINQUIRIESDETAILSSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageinquiriesdetailsSuccess implements Action {
    readonly type = LOAD_MESSAGEINQUIRIESDETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMessageinquiriesdetailsFail implements Action {
    readonly type = LOAD_MESSAGEINQUIRIESDETAILS_FAIL;
    constructor(public payload: any) { }
}


//Message claims detailspage
export class LoadMessageclaimsdetailsState implements Action {
    readonly type = LOAD_MESSAGECLAIMSDETAILSSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageclaimsdetailsSuccess implements Action {
    readonly type = LOAD_MESSAGECLAIMSDETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMessageclaimsdetailsFail implements Action {
    readonly type = LOAD_MESSAGECLAIMSDETAILS_FAIL;
    constructor(public payload: any) { }
}


//Message inbox detailspage
export class LoadMessageinboxdetailsState implements Action {
    readonly type = LOAD_MESSAGEINBOXDETAILSSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageinboxdetailsSuccess implements Action {
    readonly type = LOAD_MESSAGEINBOXDETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMessageinboxdetailsFail implements Action {
    readonly type = LOAD_MESSAGEINBOXDETAILS_FAIL;
    constructor(public payload: any) { }
}

//Message inbox detailspage
export class LoadMessageimportantdetailsState implements Action {
    readonly type = LOAD_MESSAGEINPORTANTDETAILSSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageimportantdetailsSuccess implements Action {
    readonly type = LOAD_MESSAGEINPORTANTDETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMessageimportantdetailsFail implements Action {
    readonly type = LOAD_MESSAGEINPORTANTDETAILS_FAIL;
    constructor(public payload: any) { }
}

//Message draft
export class LoadMessageDraftState implements Action {
    readonly type = LOAD_MESSAGEDRAFTSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessagedraftSuccess implements Action {
    readonly type = LOAD_MESSAGEDRAFT_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadMessagedraftFail implements Action {
    readonly type = LOAD_MESSAGEDRAFT_FAIL;
    constructor(public payload: any) { }
}

//Message important
export class LoadMessageImportantState implements Action {
    readonly type = LOAD_MESSAGEIMPORTANTSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageImportantSuccess implements Action {
    readonly type = LOAD_MESSAGEIMPORTANT_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadMessageImportantFail implements Action {
    readonly type = LOAD_MESSAGEIMPORTANT_FAIL;
    constructor(public payload: any) { }
}

//Message inquiries
export class LoadMessageInquiriesState implements Action {
    readonly type = LOAD_MESSAGEINQUIRIESSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageInquiriesSuccess implements Action {
    readonly type = LOAD_MESSAGEINQUIRIES_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadMessageInquiriesFail implements Action {
    readonly type = LOAD_MESSAGEINQUIRIES_FAIL;
    constructor(public payload: any) { }
}

//Message Trash
export class LoadMessageTrashState implements Action {
    readonly type = LOAD_MESSAGETRASHSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageTrashSuccess implements Action {
    readonly type = LOAD_MESSAGETRASH_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadMessageTrashFail implements Action {
    readonly type = LOAD_MESSAGETRASH_FAIL;
    constructor(public payload: any) { }
}

//Message claims
export class LoadMessageClaimsState implements Action {
    readonly type = LOAD_MESSAGECLAIMSSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageClaimsSuccess implements Action {
    readonly type = LOAD_MESSAGECLAIMS_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadMessageClaimsFail implements Action {
    readonly type = LOAD_MESSAGECLAIMS_FAIL;
    constructor(public payload: any) { }
}


//Message sent message
export class LoadMessageSentState implements Action {
    readonly type = LOAD_MESSAGESENTSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessageSentSuccess implements Action {
    readonly type = LOAD_MESSAGESENT_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadMessageSentFail implements Action {
    readonly type = LOAD_MESSAGESENT_FAIL;
    constructor(public payload: any) { }
}

//Customer
export class LoadCustomer implements Action {
    readonly type = LOAD_CUSTOMERS;
    constructor(public payload: any) {
    }
}

export class LoadCustomerSuccess implements Action {
    readonly type = LOAD_CUSTOMERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCustomerFail implements Action {
    readonly type = LOAD_CUSTOMERS_FAIL;
    constructor(public payload: any) { }
}

export class LoadBulkAction implements Action {
    readonly type = LOAD_BULKACTION;
    constructor(public payload: any) {
    }
}

export class LoadBulkActionSuccess implements Action {
    readonly type = LOAD_BULKACTION_SUCCESS;
    constructor(public payload: any) {
    }
}

export class LoadBulkActionFail implements Action {
    readonly type = LOAD_BULKACTION_FAIL;
    constructor(public payload: any) { }
}



export class SendMessage implements Action {
    readonly type = SEND_MESSAGE;
    constructor(public payload: any) { }
}

export class SendMessageSuccess implements Action {
    readonly type = SEND_MESSAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class SendMessageFail implements Action {
    readonly type = SEND_MESSAGE_FAIL;
    constructor(public payload: any) { }
}

export class SendDraftMessage implements Action {
    readonly type = SEND_DRAFT_MESSAGE;
    constructor(public payload: any) { }
}

export class SendDraftMessageSuccess implements Action {
    readonly type = SEND_DRAFT_MESSAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class SendDraftMessageFail implements Action {
    readonly type = SEND_DRAFT_MESSAGE_FAIL;
    constructor(public payload: any) { }
}

export class DeleteMessage implements Action {
    readonly type = DELETE_MESSAGE;
    constructor(public payload: any) { }
}

export class DeleteMessageSuccess implements Action {
    readonly type = DELETE_MESSAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteMessageFail implements Action {
    readonly type = DELETE_MESSAGE_FAIL;
    constructor(public payload: any) { }
}

export class DeleteMail implements Action {
    readonly type = DELETE_MAIL;
    constructor(public payload: any) { }
}

export class DeleteMailSuccess implements Action {
    readonly type = DELETE_MAIL_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteMailFail implements Action {
    readonly type = DELETE_MAIL_FAIL;
    constructor(public payload: any) { }
}

export class LoadUserMailAddress implements Action {
    readonly type = LOAD_USERMAILADDRESS;
    constructor(public payload: any) { }
}

export class LoadUserMailAddressSuccess implements Action {
    readonly type = LOAD_USERMAILADDRESS_SUCCESS;
    constructor(public payload: any) { }
}
export class LoadUserMailAddressFail implements Action {
    readonly type = LOAD_USERMAILADDRESS_FAIL;
    constructor(public payload: any) { }
}


//Message read unread
export class LoadMessagereadState implements Action {
    readonly type = LOAD_MESSAGEREADSTATE;
    constructor(public payload: any) {
    }
}

export class LoadMessagereadSuccess implements Action {
    readonly type = LOAD_MESSAGEREAD_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMessagereadFail implements Action {
    readonly type = LOAD_MESSAGEREAD_FAIL;
    constructor(public payload: any) { }
}

export class LoadEmailUnread implements Action {
    readonly type = LOAD_EMAILUNREAD;
    constructor(public payload: any) {
    }
}

export class LoadEmailUnreadSuccess implements Action {
    readonly type = LOAD_EMAILUNREAD_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadEmailUnreadFail implements Action {
    readonly type = LOAD_EMAILUNREAD_FAIL;
    constructor(public payload: any) { }
}

export class LoadClaimsUnread implements Action {
    readonly type = LOAD_CLAIMSUNREAD;
    constructor(public payload: any) {
    }
}

export class LoadEmailClaimsSuccess implements Action {
    readonly type = LOAD_CLAIMSUNREAD_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadClaimsUnreadFail implements Action {
    readonly type = LOAD_CLAIMSUNREAD_FAIL;
    constructor(public payload: any) { }
}

export class LoadInquriesUnread implements Action {
    readonly type = LOAD_INQURIESUNREAD;
    constructor(public payload: any) {
    }
}

export class LoadEmailInquriesSuccess implements Action {
    readonly type = LOAD_INQURIESUNREAD_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadInquriesUnreadFail implements Action {
    readonly type = LOAD_INQURIESUNREAD_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | SelectedMessage
    | LoadMessageinboxState
    | LoadMessageinboxSuccess
    | LoadMessageinboxFail
    | LoadMessageDraftState
    | LoadMessagedraftSuccess
    | LoadMessagedraftFail
    | LoadMessageImportantState
    | LoadMessageImportantSuccess
    | LoadMessageImportantFail
    | LoadMessageInquiriesState
    | LoadMessageInquiriesSuccess
    | LoadMessageInquiriesFail
    | LoadMessageTrashState
    | LoadMessageTrashSuccess
    | LoadMessageTrashFail
    | LoadMessageClaimsState
    | LoadMessageClaimsSuccess
    | LoadMessageClaimsFail
    | LoadMessageSentState
    | LoadMessageSentSuccess
    | LoadMessageSentFail
    | LoadCustomer
    | LoadCustomerSuccess
    | LoadCustomerFail
    | LoadMessageinboxdetailsState
    | LoadMessageinboxdetailsSuccess
    | LoadMessageinboxdetailsFail
    | LoadMessageimportantdetailsState
    | LoadMessageimportantdetailsSuccess
    | LoadMessageimportantdetailsFail
    | LoadBulkAction
    | LoadBulkActionSuccess
    | LoadBulkActionFail
    | SendMessage
    | SendMessageSuccess
    | SendMessageFail
    | SendDraftMessage
    | SendDraftMessageSuccess
    | SendDraftMessageFail
    | DeleteMessage
    | DeleteMessageSuccess
    | DeleteMessageFail
    | DeleteMail
    | DeleteMailSuccess
    | DeleteMailFail
    | LoadUserMailAddress
    | LoadUserMailAddressSuccess
    | LoadUserMailAddressFail
    | LoadMessageclaimsdetailsState
    | LoadMessageclaimsdetailsSuccess
    | LoadMessageclaimsdetailsFail
    | LoadMessageinquiriesdetailsState
    | LoadMessageinquiriesdetailsSuccess
    | LoadMessageinquiriesdetailsFail
    | LoadMessagereadState
    | LoadMessagereadSuccess
    | LoadMessagereadFail
    | LoadEmailUnread
    | LoadEmailUnreadSuccess
    | LoadEmailUnreadFail
    | LoadClaimsUnread
    | LoadEmailClaimsSuccess
    | LoadClaimsUnreadFail
    | LoadInquriesUnread
    | LoadEmailInquriesSuccess
    | LoadInquriesUnreadFail