import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderHttpService } from '../state/services/order.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { CommonFilterComponent } from 'src/app/common/common-filter/common-filter.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductionLineService } from './../state/services/production.line.service';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-order-production-line-list',
  templateUrl: './production-line-list.component.html',
  styleUrls: ['./production-line-list.component.scss']
})
export class ProductionLineListComponent implements OnInit {
  @ViewChild('commonFilter', { static: true }) commonFilterComponent: CommonFilterComponent;
  header = 'Production Line';
  noDataFoundText: any;
  customerList: any = [];
  suppliers = [];
  states = [];
  orderByData = [{ value: 0, label: 'Production Start Date' }, { value: 1, label: 'Delivery Date' }];
  filterFields: any = [];
  selectedSupplier = '';
  selectedState = '';
  selectedOrderBy = 0;
  commonRemoveFilter: any;
  searchLoader = false;
  finishedPLs = false;
  customerLoader = false;
  productionStateLoader = false;
  supplierLoader = false;
  productionLineData = null;
  enablepagination = true;
  productionLineCount: any;
  currentPage = 1;
  itemsPerPage = 10;
  pageLimit: any = {};
  resetSearchFlag: string;
  webshopDetail: any;
  searchCriteriaObject: any;
  constructor(private route: ActivatedRoute,
    private orderHttpService: OrderHttpService,
    private toaster: ToastService, private router: Router,
    private productionLineService: ProductionLineService, private apiService: ApiService) {
    const resetSearch = this.route.snapshot.paramMap.get('resetSearch');
    const { resetSearchValue } = history.state;
    this.resetSearchFlag = resetSearch || resetSearchValue;
    this.onLoadFunction();
  }

  onLoadFunction() {
    this.searchCriteriaObject = this.resetSearchFlag === 'true' ? this.productionLineService.defaultSearch : this.productionLineService.updatedSearch;
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID) {
        this.getSuppliers(this.webshopDetail.CustomerID);
        this.onLoad(this.resetSearchFlag);
        this.handleFilterResult({});
      } else {
        this.getCustomers();
      }
    }
    this.getProductionLineStates();
  }

  ngOnInit() {
    if (sessionStorage.getItem('dashboardRedirectStatus') !== undefined && sessionStorage.getItem('dashboardRedirectStatus') !== null) {
      sessionStorage.removeItem('dashboardRedirectStatus');
    }
  }

  onLoad(resetSearchFlag) {
    if (resetSearchFlag === 'true') {
      this.pageLimit = this.productionLineService.defaultPageLimit;
      this.itemsPerPage = this.productionLineService.defaultPageLimit.pageSize;
      this.currentPage = this.productionLineService.defaultPageLimit.pageIndex;
      this.prepareSearchFields(this.productionLineService.defaultSearch);
    } else {
      this.pageLimit = this.productionLineService.updatedPageLimit;
      this.itemsPerPage = this.productionLineService.updatedPageLimit.pageSize;
      this.currentPage = this.productionLineService.updatedPageLimit.pageIndex;
      this.prepareSearchFields(this.productionLineService.updatedSearch);
    }
  }

  doSearch() {
    this.searchLoader = true;
    if (this.searchCriteriaObject['CustomerID'] === 0 && this.webshopDetail.ParentCustomerID === null) {
      this.productionLineService.includChildShopOrders = true;
    } else {
      this.productionLineService.includChildShopOrders = false;
    }
    this.productionLineService.updateSearchCriteria(this.searchCriteriaObject);
    this.orderHttpService.getProductionLinesOverview(this.pageLimit,
      this.webshopDetail.CustomerID, this.searchCriteriaObject,
      this.productionLineService.includChildShopOrders)
      .subscribe((res: any) => {
        this.searchLoader = false;
        if (res && res.ItemsCollection.length > 0) {
          this.productionLineData = res.ItemsCollection;
          this.productionLineCount = res.TotalItemsCount;
          this.selectedOrderBy = this.searchCriteriaObject.SortBy;
          this.noDataFoundText = '';
        } else {
          this.productionLineData = [];
          this.productionLineCount = 0;
          this.noDataFoundText = 'No Orders Found.';
          this.toaster.error('No Orders Found');
        }
      }, error => {
        this.searchLoader = false;
        this.toaster.error('unable to get Production Line Data');
      });
  }

  getCustomers() {
    this.customerLoader = true;
    this.orderHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
      (customersRes: any) => {
        if (customersRes) {
          const customerlist = [{ label: 'Select All', value: 0 }];
          customersRes.forEach(cust => {
            let custData = {
              label: cust.Name,
              value: cust.CustomerID
            }
            customerlist.push(custData);
          });
          this.customerList = customerlist;
          this.updateSearchFormFields('CustomerID', this.customerList);
          const customerId = this.resetSearchFlag === 'true' ?
            this.webshopDetail.CustomerID : this.searchCriteriaObject['CustomerID'];
          this.getSuppliers(customerId);
          this.onLoad(this.resetSearchFlag);
          // this.handleFilterResult({});
        }
        this.customerLoader = false;
      },
      error => {
        this.customerLoader = false;
        this.toaster.error('unable to get webshop customers');
      }
    );
  }

  getSuppliers(customerId: any) {
    this.supplierLoader = true;
    if (customerId === 0) {
      customerId = this.webshopDetail.CustomerID;
    }
    this.orderHttpService.getSuppliers(customerId, this.webshopDetail.CustomerID).subscribe(
      (supplierRes: any) => {
        if (supplierRes) {
          this.suppliers = supplierRes.ItemsCollection.map(supplier => ({
            value: supplier.SupplierID,
            label: supplier.Name
          }));
          const selectSupplier = { value: '', label: 'Select Supplier' };
          this.suppliers = this.suppliers.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label));
          this.suppliers = [selectSupplier, ...this.suppliers];
          this.updateSearchFormFields('SupplierID', this.suppliers);
        }
        this.supplierLoader = false;
      },
      error => {
        this.toaster.error('unable to get suppliers');
      }
    );
  }

  getProductionLineStates(query = true) {
    this.productionStateLoader = true;
    this.orderHttpService.getProductionLineStates(query, this.webshopDetail.CustomerID).subscribe(
      (states: any) => {
        if (states) {
          this.states = states.map(state => ({
            value: state.StateCode,
            label: state.StateName
          }));
          this.updateSearchFormFields('ProductionLineStateCode', this.states);
        }
        this.productionStateLoader = false;
      },
      error => {
        this.toaster.error('unable to get production Line states');
      }
    );
  }

  updateSearchFormFields(key: any, value: any) {
    if (this.commonFilterComponent && this.commonFilterComponent.filterFormField !== undefined) {
      this.commonFilterComponent.filterFormField.forEach((field) => {
        if (field.key === key) {
          field.data = value;
          field.selected = (this.resetSearchFlag === 'true') ? this.productionLineService.defaultSearch[key] :
            this.productionLineService.updatedSearch[key];
        }
      });
    }
  }

  prepareSearchFields(searchCriteria) {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12 pl-3 pt-4',
        key: 'filterHeading',
        filterkey: ''
      },
    ];
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.filterFields.push(
          {
            label: 'Select Webshop',
            type: 'select',
            data: this.customerList,
            class: 'col-md-12 no-icon',
            key: 'CustomerID',
            filterkey: 'CustomerID',
            selected: searchCriteria.CustomerID
          }
        );
      }
    }
    this.filterFields.push(
      {
        label: 'Select Supplier',
        type: 'select',
        data: this.suppliers,
        class: 'col-md-12 no-icon',
        key: 'SupplierID',
        selected: searchCriteria.SupplierID,
        filterkey: 'SupplierID'
      },
      {
        label: 'Product Name',
        type: 'text',
        class: 'col-md-12',
        key: 'ProductName',
        filterkey: 'ProductName',
        name: 'ProductName',
        selected: searchCriteria.ProductName
      },
      {
        label: 'Item Number',
        type: 'text',
        class: 'col-md-12',
        key: 'ProductIdManual',
        filterkey: 'ProductIdManual',
        name: 'ProductIdManual',
        selected: searchCriteria.ProductIdManual
      },
      {
        label: 'Production Line ID',
        type: 'text',
        class: 'col-md-12',
        key: 'ProductionLineID',
        filterkey: 'ProductionLineID',
        name: 'ProductionLineID',
        selected: searchCriteria.ProductionLineID
      },
      {
        label: 'Select State',
        type: 'select',
        data: this.states,
        class: 'col-md-12 no-icon',
        key: 'ProductionLineStateCode',
        selected: searchCriteria.ProductionLineStateCode,
        filterkey: 'ProductionLineStateCode'
      },
      {
        label: 'Select Order By',
        type: 'select',
        data: this.orderByData,
        class: 'col-md-12 no-icon',
        key: 'SortBy',
        selected: searchCriteria.SortBy,
        filterkey: 'SortBy'
      },
      {
        label: 'Order History',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'finishedPLs',
        filterkey: 'finishedPLs',
        selected: searchCriteria.finishedPLs
      },
      {
        label: 'Internal Orders',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'InternalOrders',
        filterkey: 'InternalOrders',
        selected: searchCriteria.InternalOrders
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    );
  }

  getSelectData(selectedFieldValue) {
    const { ProductionLineStateCode } = selectedFieldValue;
    this.commonFilterComponent.filterFormField.forEach((field) => {
      if (selectedFieldValue[field.key] &&
        selectedFieldValue[field.key].selectMethod &&
        (field.key === selectedFieldValue[field.key].selectMethod)) {
        if (!this.excludeFields(selectedFieldValue[field.key].selectMethod)) {
          if (field.key === 'finishedPLs' || field.key === 'InternalOrders') {
            field.selected = selectedFieldValue[field.key].value === '' ? false : selectedFieldValue[field.key].value;
            this.searchCriteriaObject[field.key] = selectedFieldValue[field.key].value === '' ? false : selectedFieldValue[field.key].value;
          } else if (field.key === 'SortBy' || field.key === 'CustomerID') {
            field.selected = selectedFieldValue[field.key].value === '' ? 0 : selectedFieldValue[field.key].value;
            this.searchCriteriaObject[field.key] = selectedFieldValue[field.key].value === '' ? 0 : selectedFieldValue[field.key].value;
          } else if (field.key === 'ProductionLineStateCode' && selectedFieldValue[field.key].value === '') {
            field.selected = '';
            delete this.searchCriteriaObject[ProductionLineStateCode];
          } else {
            field.selected = selectedFieldValue[field.key].value;
            this.searchCriteriaObject[field.key] = selectedFieldValue[field.key].value;
          }
        }
      }
    });

    this.finishedPLs = this.searchCriteriaObject.finishedPLs || false;
    if (ProductionLineStateCode && ProductionLineStateCode.value !== '') {
      this.searchCriteriaObject = Object.assign(this.searchCriteriaObject, { ProductionLineStateCode: ProductionLineStateCode.value });
    } else {
      delete this.searchCriteriaObject[ProductionLineStateCode];
    }
  }

  excludeFields(selectMethod) {
    return (selectMethod === 'button' || selectMethod === 'clear' || selectMethod === 'filterHeading');
  }

  handleSubmit(formData: any) { }

  stateChangeEvent(eventData) {
    if (eventData.key === 'finishedPLs') {
      this.getProductionLineStates(!eventData.value);
    } else if (eventData.key === 'CustomerID') {
      this.getSuppliers(eventData.value);
      this.getProductionLineStates(eventData.value);
      this.doSearch();
    } else if (eventData.key === 'SortBy') {
      this.selectedOrderBy = eventData.value;
    }
  }

  handleResetForm(event: any) {
    this.commonFilterComponent.filterFormField.forEach((field) => {
      if (event[field.key] && event[field.key].value) {
        event[field.key].value = false;
      } else if (event[field.key] && event[field.key].value !== '') {
        event[field.key].value = '';
      }
    });
    this.productionLineService.defaultPageLimit.pageSize = 10;
    this.productionLineService.defaultPageLimit.pageIndex = 1;
    this.productionLineService.updatedPageLimit.pageSize = 10;
    this.productionLineService.updatedPageLimit.pageIndex = 1;
    this.productionLineService.defaultSearch = {
      CustomerID: 0,
      SupplierID: '',
      ProductName: '',
      ProductIdManual: '',
      ProductionLineID: '',
      SortBy: 0,
      finishedPLs: false,
      InternalOrders: false
    };
    this.searchCriteriaObject = this.productionLineService.defaultSearch;
    this.productionLineService.updateSearchCriteria(this.productionLineService.defaultSearch);
    this.onLoadFunction();
  }

  handleFilterResult(searchFieldsData: any) {
    this.itemsPerPage = this.itemsPerPage;
    this.pageLimit.pageIndex = 1;
    this.pageLimit.pageSize = this.itemsPerPage;
    this.productionLineService.updatedPageLimit.pageSize = this.itemsPerPage;
    this.productionLineService.updatedPageLimit.pageIndex = 1;
    this.currentPage = 1;
    this.doSearch();

  }

  onChangeRowPerPage(event: any) {
    this.itemsPerPage = event.pageSize;
    this.pageLimit.pageIndex = event.pageIndex;
    this.pageLimit.pageSize = event.pageSize;
    this.productionLineService.updatedPageLimit.pageSize = event.pageSize;
    this.productionLineService.updatedPageLimit.pageIndex = event.pageIndex;
    if (this.productionLineCount >= this.itemsPerPage) {
      this.doSearch();
    }

  }

  onChangeCurrentPage(event: any) {
    this.currentPage = event.pageIndex;
    this.pageLimit.pageIndex = event.pageIndex;
    this.pageLimit.pageSize = event.pageSize;
    this.productionLineService.updatedPageLimit.pageSize = event.pageSize;
    this.productionLineService.updatedPageLimit.pageIndex = event.pageIndex;
    this.doSearch();

  }

  gotoDetail(orderId) {
    let customerID = null;
    if (this.webshopDetail.ParentCustomerID) {
      customerID = this.webshopDetail.CustomerID;
    } else {
      customerID = this.searchCriteriaObject.CustomerID === 0 ? null : this.searchCriteriaObject.CustomerID;
    }
    this.finishedPLs = this.searchCriteriaObject.finishedPLs ? this.searchCriteriaObject.finishedPLs : false;
    this.router.navigate([`/admin-order-production-line-details/${orderId}/${this.finishedPLs}`], { state: { orderId, customerID }, replaceUrl: false });
  }
}
