import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';
import { MessageInboxService } from '../message-inbox.service';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-message-inbox',
  templateUrl: './message-inbox.component.html',
  styleUrls: ['./message-inbox.component.scss']
})
export class MessageInboxComponent implements OnInit {
  isBulkEditSelectedForDelete = {
    data: []
  }
  enablepagination: boolean = true;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  rowactivePage: number = 1;
  activePage: number = 1;
  tableEvent: any;
  filterFields: any = [];
  filterParams: any;
  public messageinbox: any;
  messageinboxQueryParams: any = {};
  customerList: any = [];
  roleCode: any = 'ANY';
  showMessageDetailpage: boolean = false;
  showMessageInbox: boolean = true;
  showAll: boolean = true;
  customerId: any;
  titles: any;
  tableName: string = 'message';
  EmailID: any = 0;
  inboxdatacount: any;
  bulkEmailIds: any = [];
  currentCustomerID: any;
  showLoader: Boolean = false;
  public MessageboxLine: ColumnSetting[] = [
    /* {
       primaryKey: 'ProductionLineID',
       header: 'Select',
       format: 'bulkaction',
       key: 'inboxcheckbox',
       width: '40px'
     },
     {
       primaryKey: 'SenderName',
       header: 'Name',
     },*/
    {
      primaryKey: 'FromEmail',
      header: 'From',
      width: '240px',
    },
    {
      primaryKey: 'ToEmail',
      header: 'To'
    },
    {
      primaryKey: 'Subject',
      header: 'Subject',
      width: '240px',
    },
    {
      primaryKey: 'SendingTime',
      header: 'Date',
      format: 'time'
    }
  ];
  webshopList: any = [];
  roleCodeSearch: Boolean = false;
  webshopDetail: any;
  userDetails: any;
  inboxCount: any;
  constructor(public messageboxservice: MessageboxService,
    private router: Router,
    public apiService: ApiService,
    private messageInboxService: MessageInboxService,
    private linkTableService: LinkTableService,
    private toast: ToastService,
    private messageboxhttpservice: MessageboxHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    this.userDetails = JSON.parse(sessionStorage.getItem('userdetails'));
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    const loginData = this.apiService.getLoginData();
    this.currentCustomerID = this.webshopDetail.CustomerID;
    this.customerId = this.webshopDetail.CustomerID;
    this.EmailID = '';
    this.messageinboxQueryParams.customerID = this.customerId;
    this.messageinboxQueryParams.EmailID = this.EmailID;
    this.messageinboxQueryParams.UserID = loginData.UserID;
    this.messageinboxQueryParams.showAll = this.showAll;
    this.messageinboxQueryParams.roleCode = this.roleCode;
    this.messageinboxQueryParams.pageSize = this.perPagedata;
    this.messageinboxQueryParams.activePage = this.activePage;
    const getFilterData = this.messageinboxQueryParams;
    sessionStorage.setItem('inboxfilter', JSON.stringify(this.messageinboxQueryParams))
    const request = {
      type: 'ANY',
      customerID: this.customerId
    }
    sessionStorage.setItem('inboxrolecode', JSON.stringify(request));
    this.MessageBoxLineData(this.messageinboxQueryParams);
    this.messageboxservice.loadCustomers(this.messageinboxQueryParams)
    this.loadCustomer();
    this.messageInboxService.setMenuActive.next(true);
  }

  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        const customers = [{ label: 'Select All', value: 0 }]
        data.ItemsCollection.forEach(element => {
          customers.push({
            value: element.CustomerID,
            label: element.Name,
          });
        });
        this.customerList = customers;
        this.webshopList = [{ label: 'Webshop', value: 'Webshop' }];
        this.filterFields = [];
        if (this.customerList.length > 1) {
          this.filterFields.push(
            {
              label: 'Customer',
              type: 'select',
              data: this.customerList,
              class: 'col-md-2',
              key: 'inboxcustomer',
              required: false
            },
            // {
            //   label: 'Role',
            //   type: 'select',
            //   data: [{ label: 'Select role', value: '' }, { label: 'Webshop', value: 'Webshop' }],
            //   class: 'col-md-2',
            //   key: 'inboxrole',
            //   required: false
            // },
            {
              label: 'Search',
              type: 'messagesearch',
              class: '',
              key: 'button',
              model: 'messagesearch'
            },
            {
              label: 'Refresh',
              type: 'button',
              class: '',
              key: 'reset',
              model: 'messagereset'
            },
            {
              label: 'Show all',
              type: 'checkbox',
              class: 'ml-auto mr-3',
              key: 'showall',
              required: false,
              checked: true,
              selected: true
            })
        }
        this.filterFields.push({
          label: 'Important',
          type: 'button',
          class: 'importantico position-absolute mt-2 pt-1',
          key: 'messageimportant',
          model: 'importantinbox'
        },
          {
            label: 'Delete',
            type: 'button',
            class: 'ml-auto mt-2 pt-1',
            key: 'messagedelete',
            model: 'deleteinbox'
          })
      }
    })
  }

  getFilterType(event) {
    if (event.key === 'inboxcustomer') {
      this.customerId = event.value;
      this.messageinboxQueryParams.customerID = this.customerId;
      const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
      this.showAll = false;
      this.filterFields[showStatus]['selected'] = this.showAll;
      this.filterFields[showStatus]['checked'] = this.showAll;
      this.messageinboxQueryParams.showAll = this.showAll;
    }
    else if (event.key === 'inboxrole') {
      this.roleCode = event.value;
      this.messageinboxQueryParams.roleCode = this.roleCode;
      const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
      this.showAll = false;
      this.filterFields[showStatus]['selected'] = this.showAll;
      this.filterFields[showStatus]['checked'] = this.showAll;
      this.messageinboxQueryParams.showAll = this.showAll;
    }
    else if (event.key === 'checkbox' || event.key === "showall" || event.key === 'inboxcustomer' && event.value === 0) {
      // const loginData = this.apiService.getLoginData();
      this.messageinboxQueryParams.customerID = this.webshopDetail.CustomerID;
      this.messageinboxQueryParams.roleCode = 'ANY';
      this.roleCode = 'ANY';
      this.customerId = this.webshopDetail.CustomerID;
      const inboxcustomer = this.filterFields.findIndex(data => data.key === 'inboxcustomer');
      this.filterFields[inboxcustomer]['selected'] = '';
      const inboxrole = this.filterFields.findIndex(data => data.key === 'inboxrole');
      if (this.filterFields[inboxrole]) {
        this.filterFields[inboxrole]['selected'] = '';
      }
      this.showAll = event.value;
      const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
      this.filterFields[showStatus]['selected'] = this.showAll;
      this.filterFields[showStatus]['checked'] = this.showAll;
      this.messageinboxQueryParams.showAll = this.showAll;
      if (!(event.key === 'inboxcustomer' && event.value === 0)) {
        this.getSubmit();
      }
      else {
        this.roleCodeSearch = true;
      }
    }
  }

  getSelectData(event) {
  }

  showStatus() {
    this.showAll = false;
    const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
    this.messageinboxQueryParams.showAll = false;
    this.filterFields[showStatus]['selected'] = false;
    this.filterFields[showStatus]['checked'] = false;
  }

  getSubmit(type?) {
    // const loginData = this.apiService.getLoginData();
    if (this.messageinboxQueryParams.customerID === 0) {
      this.messageinboxQueryParams.showAll = true;
      this.messageinboxQueryParams.roleCode = 'ANY';
      this.showAll = true;
      const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
      this.filterFields[showStatus]['selected'] = true;
      this.filterFields[showStatus]['checked'] = true;
    }
    if (this.messageinboxQueryParams.customerID === undefined || this.messageinboxQueryParams.customerID === '' || this.messageinboxQueryParams.customerID === 0) {
      this.customerId = this.webshopDetail.CustomerID;
      this.messageinboxQueryParams.customerID = this.webshopDetail.CustomerID;
    }
    const request = {
      type: this.messageinboxQueryParams.roleCode,
      customerID: this.customerId
    }
    sessionStorage.setItem('inboxrolecode', JSON.stringify(request));
    let getFilter = this.messageinboxQueryParams;
    if (type === 'pagechange') {
      getFilter = JSON.parse(sessionStorage.getItem('inboxfilter'));
    }
    else {
      this.activePage = 1;
      getFilter.activePage = this.activePage;
    }
    this.MessageBoxLineData(getFilter);
  }

  getReset(reset) {
    if (reset.selectMethod === 'reset' && reset.value) {
      // const loginData = this.apiService.getLoginData();
      this.customerId = this.webshopDetail.CustomerID;
      this.messageinboxQueryParams.showAll = true;
      this.messageinboxQueryParams.customerID = this.customerId;
      this.messageinboxQueryParams.roleCode = 'ANY';
      const request = {
        type: this.messageinboxQueryParams.roleCode,
        customerID: this.customerId
      }
      sessionStorage.setItem('inboxrolecode', JSON.stringify(request));
      this.roleCode = 'ANY';
      this.showAll = true;
      this.activePage = 1;
      this.MessageBoxLineData(this.messageinboxQueryParams);
      this.loadCustomer();
    }
  }


  MessageBoxLineData(request) {
    // request.showAll = true;
    // request.roleCode = 'ANY';
    this.showLoader = true;
    let params = {
      "UserID": this.userDetails.UserID,
      "UserRole": this.userDetails.Roles[0].RoleName,
      "pageNumber": this.activePage,
      "pageSize": this.perPagedata
    }
    //this.messageboxhttpservice.getMessageLineData(request).subscribe((data: any) => {
    this.messageboxhttpservice.getEmailList(params).subscribe((data: any) => {
      this.showLoader = false;
      if (data !== null && data) {
        this.inboxdatacount = data[0].total;
        this.messageinbox = data;
        this.inboxCount = this.messageinbox.length > 0 ? this.messageinbox[0]['AdminUnReadCount'] : 0;
        this.showMessageDetailpage = false;
        this.showMessageInbox = true;
        this.messageInboxService.updateReadUnreadCount.next(true);
      }
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      })

  }

  getPerPage(event) {
    this.perPagedata = event;
    this.messageinboxQueryParams.pageSize = event;
    this.messageinboxQueryParams.pageIndex = event;
    let getFilter = JSON.parse(sessionStorage.getItem('inboxfilter'));
    getFilter.pageSize = event;
    getFilter.pageIndex = event;
    sessionStorage.setItem('inboxfilter', JSON.stringify(getFilter))
    this.getSubmit('pagechange');
  }
  paginationNum(event) {
    this.activePage = event;
    this.messageinboxQueryParams.activePage = event;
    let getFilter = JSON.parse(sessionStorage.getItem('inboxfilter'));
    getFilter.activePage = event;
    sessionStorage.setItem('inboxfilter', JSON.stringify(getFilter))
    this.getSubmit('pagechange');
  }

  getBulkAction(event) {
    if (event.bulkaction.inboxcheckbox !== undefined) {
      let getaction = event.bulkaction.inboxcheckbox;
      this.bulkEmailIds = [];
      getaction.forEach(element => {
        this.bulkEmailIds.push(element.EmailID)
      });
    }
    this.isBulkEditSelectedForDelete.data = this.bulkEmailIds;
  }
  inboxdetails(messageData) {
    if (messageData) {
      this.showMessageDetailpage = true;
      try {
        this.messageboxservice.selectedMessage(messageData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.showMessageDetailpage = true;
            this.showMessageInbox = false;
            this.EmailID = data.emailid;
            this.router.navigate(['/app-message-inboxdetails'], { state: { data: data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }
  }
  deleteRow(event) {
    const loginData: any = this.apiService.getLoginData();
    const deleteData = {
      "ActionType": "TRASH",
      "Value": true,
      "EmailIDs": this.bulkEmailIds
    }
    if (event.deleteAction !== undefined && event.deleteAction.deleteinbox) {
      this.messageboxhttpservice.bulkAction(deleteData, loginData.UserID).subscribe((bulkdata: any) => {
        if (bulkdata != null) {
          if (bulkdata) {
            this.toast.success('Email deleted successfully');
            setTimeout(() => {
              this.MessageBoxLineData(this.messageinboxQueryParams);
              this.bulkEmailIds = [];
              this.isBulkEditSelectedForDelete.data = [];
            }, 300)
          }
          else {
            this.MessageBoxLineData(this.messageinboxQueryParams);
          }
        }
      })

    }
  }

  importantMail(event) {
    if (event == 'importantinbox') {
      const loginData = this.apiService.getLoginData();
      const importantData = {
        "ActionType": "IMPORTANT",
        "Value": true,
        "EmailIDs": this.bulkEmailIds
      }
      this.messageboxhttpservice.bulkAction(importantData, loginData.UserID).subscribe((data: any) => {
        if (data != null) {
          setTimeout(() => {
            this.toast.success('Mail moved to important')
            this.MessageBoxLineData(this.messageinboxQueryParams);
          }, 300);
        }
      })
    }
  }

}