import {CategoryHttpService} from '../state/services/category.http.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryCopyComponent } from './category-copy.component';
import { CategoryDetailsModule } from '../category-details/category-details.module';
import { CategoryService } from '../state/services/category.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CategoryDetailsModule,
    FormsModule
  ],
  declarations: [CategoryCopyComponent],
  providers: [CategoryHttpService, CategoryService],
  exports: [CategoryCopyComponent]
})

export class CategoryCopyModule { }
