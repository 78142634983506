import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable()
export class WarehouseHttpService {
    webshopDetail: any;
    constructor(private apiService: ApiService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    addLogisticVendor(data: any) {
        let path = '';
        if (data.LogisticsVendorID !== null && data.LogisticsVendorID !== undefined && data.LogisticsVendorID !== '') {
            path = 'en/logisticsvendors/' + data.LogisticsVendorID;
        } else {
            path = 'en/logisticsvendors';
        }
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    addWarehouse(data: any) {
        let path = '';
        if (data.WarehouseID !== null && data.WarehouseID !== undefined && data.WarehouseID !== '') {
            path = 'en/warehouses/' + data.WarehouseID;
        } else {
            path = 'en/warehouses';
        }
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }


    getAllCountries() {
        const path = 'en/countries';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCurrencies() {
        const path = 'en/currencies?isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllWarehouse(searchData: any) {
        let loginCustomerId;
        let logisticVendorId = '';
        /** For CustomerID Query Parameter **/
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = this.webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }

        if (searchData.logisticId !== undefined && searchData.logisticId !== null && searchData.logisticId !== '') {
            logisticVendorId = '&logisticsVendorID=' + searchData.logisticId;
        }
        // tslint:disable-next-line:max-line-length
        const searchCriteria = {
            activePage: searchData.activePage !== undefined ? searchData.activePage : '',
            pageSize: searchData.pageSize !== undefined ? searchData.pageSize : '',
            // tslint:disable-next-line:max-line-length
            customerId: (searchData.customerId !== undefined && searchData.customerId !== '' && searchData.customerId !== null) ? searchData.customerId : this.webshopDetail.CustomerID,
        }

        let queryCustomerId = '';
        if (searchData.customerId !== undefined && searchData.customerId !== '') {
            queryCustomerId = '&customerID=' + searchCriteria.customerId;
        }

        const path = 'en/warehouses?' +
            'pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + queryCustomerId + logisticVendorId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }


    getCustomerLogisticVendor(searchData: any) {
        console.log("searchData : ", searchData);
        let loginCustomerId;
        /** For CustomerID Query Parameter **/
        const webshopExists = sessionStorage.getItem('webshopDetail');
        if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
            loginCustomerId = this.webshopDetail.CustomerID;
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                loginCustomerId = loginData.CustomerID;
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }

        const searchCriteria = {
            activePage: searchData.activePage !== undefined ? searchData.activePage : '',
            pageSize: searchData.pageSize !== undefined ? searchData.pageSize : '',
            // tslint:disable-next-line:max-line-length
            customerId: (searchData.customerId !== undefined && searchData.customerId !== '') ? searchData.customerId : this.webshopDetail.CustomerID,
        }

        let queryCustomerId = '';
        if (searchData.customerId !== undefined && searchData.customerId !== '') {
            queryCustomerId = '&customerID=' + searchCriteria.customerId;
        }

        // tslint:disable-next-line:max-line-length
        const path = 'en/logisticsvendors' + '?' +
            'pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + queryCustomerId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllLogisticVendor(searchData = null) {
        let queryCustomerId = '';
        if (searchData !== null) {
            if (searchData.customerId !== undefined && searchData.customerId !== '') {
                queryCustomerId = '?customerID=' + searchData.customerId;
            }
        }
        const path = 'en/logisticsvendors' + queryCustomerId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    checkPostalCode(countryCode, postalCode) {
        // const userSettings = this.globalService.getUserSettings();
        const path = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteLogisticVendor(data: any) {
        const path = 'en/logisticsvendors/' + data.LogisticsVendorID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteWarehouse(data: any) {
        const path = 'en/warehouses/' + data.WarehouseID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getCustomersListInclParent(customerId) {
        const path = 'en/customers/list?ParentCustomerID=' + customerId + '&returnParentCustomer=true&isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }


}
