import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../state/services/product.http.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss']
})
export class ProductGridComponent {

  public selectAllToggle: boolean = false;
  public selectedAllToggle: boolean = false;
  public isParentCustomer: boolean = true;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;

  @Input() selectedProducts = [];
  @Input() dragEnabled = true;
  @Input() intersectResult = false;
  @Input() imageProperty = 'ImageURL';

  pageIndex: any = 1;
  pageSize: any = 20;
  @Output() productRemovedEvent = new EventEmitter();
  @Output() productSelected = new EventEmitter();
  @Output() proudctsChange = new EventEmitter();
  @Input() campaignID: any;
  @Input() max: any;
  @Input() min: any;
  productList = [];
  productArray = [];
  optionsSelect = [
    { value: '20', label: '20', selected: true },
    { value: '40', label: '40' },
    { value: '60', label: '60' },
    { value: '80', label: '80' }
  ];
  showPagination = false;
  isLoading = false;

  paginateConfig = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  message = '';
  messageType = '';
  showMessage = false;
  productToDelete = null;
  porductIndexToDelete = null;
  mode = 'Add';
  modalConfirmButtonLabel = 'Add';
  selectedperpage: any;
  userdetails: any;
  config: any;
  constructor(private productHttpService: ProductHttpService, private toaster: ToastService) {
    const userdetails = sessionStorage.getItem('userdetails');
    if (userdetails) {
      this.userdetails = JSON.parse(userdetails);
    }

    let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshop) {
      if (webshop.ParentCustomerDomainName === webshop.DomainName) {
        this.isParentCustomer = true;
      } else {
        this.isParentCustomer = false;
      }
    }

  }


  emitselectedProduct(product) {
    this.productSelected.emit(product);
  }

  setPageSizeOptions(event) {
    this.paginateConfig.itemsPerPage = event.value;
    this.pageIndex = this.paginateConfig.currentPage;
    this.isLoading = true;
    this.pageSize = event.value;
  }

  pageChanged(pageIndex) {
    this.paginateConfig.currentPage = pageIndex;
    this.isLoading = true;
    this.selectAllToggle = false;
    this.pageIndex = pageIndex;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.mode = 'Add';
      this.modalConfirmButtonLabel = 'Add';

      this.getConfirmation(this.selectedProducts[event.currentIndex], event.currentIndex, 'Add');
    }
  }



  getConfirmation(product, index, label = 'Delete') {
    this.productToDelete = product;
    console.log("product", product);
    this.porductIndexToDelete = index;
    this.mode = label;
    this.modalConfirmButtonLabel = label;
    if (label === 'Add') {
      this.modalConfirmAction();
    } else {
      this.deleteModal.show();
    }
  }

  modalConfirmAction() {
    if (this.mode === 'Delete') {
      this.productRemovedEvent.emit({ product: this.productToDelete, index: this.porductIndexToDelete, operation: this.mode });

    } else {
      this.productSelected.emit({ product: this.productToDelete, index: this.porductIndexToDelete, operation: this.mode });

    }
    this.hideModal();
  }

  modalCancelAction() {
    this.hideModal();
  }

  hideModal() {
    this.deleteModal.hide();
    this.clearSelectedProduct();
  }

  clearSelectedProduct() {
    this.productToDelete = null;
    this.porductIndexToDelete = null;
  }

  addProducts() {
    let params = {
      UserId: this.userdetails.UserID,
      CampaignId: this.campaignID,
      ProductId: this.productArray
    }
    this.isLoading = true;
    this.productHttpService.addProductAll(params).subscribe(
      data => {
        this.isLoading = false;
        if (data) {
          this.proudctsChange.emit(true);
        }
      }, error => {
        this.isLoading = false;
        this.toaster.error(error.error.Message)
      }
    );
  }

  removeProducts() {
    let params = {
      UserId: this.userdetails.UserID,
      CampaignId: this.campaignID,
      ProductId: this.productArray
    }
    this.productHttpService.removeProductALL(params).subscribe(
      data => {
        this.proudctsChange.emit(true);
      }
    );
  }

  selectAll() {
    let l = this.productList.length
    for (let i = 0; i < l; i++) {
      if (this.selectAllToggle) {
        this.productList[i].status = true;
      } else {
        this.productList[i].status = false;
      }
    }
  }

  selectedAll() {
    let l = this.selectedProducts.length
    for (let i = 0; i < l; i++) {
      if (this.selectedAllToggle) {
        this.selectedProducts[i].status = true;
      } else {
        this.selectedProducts[i].status = false;
      }
    }
  }

  isChecked(event, index) {
    this.productList[index]['status'] = event.checked;
    if (!event.checked) {
      this.selectAllToggle = false;
    }

    /*if (this.productList.length > 0) {
      const checkIfAllChecked = this.productList.filter(data => data.status === false);
      if (checkIfAllChecked.length === 0) {
        this.selectAllToggle = true;
      }
      else {
        this.selectAllToggle = false;
      }
    }*/
  }

  isCheckedCampaign(event, index) {
    this.selectedProducts[index]['status'] = event.checked;
    if (!event.checked) {
      this.selectedAllToggle = false;
    }

    /*if (this.productList.length > 0) {
      const checkIfAllChecked = this.productList.filter(data => data.status === false);
      if (checkIfAllChecked.length === 0) {
        this.selectAllToggle = true;
      }
      else {
        this.selectAllToggle = false;
      }
    }*/
  }

  addExcludedProducts() {
    let l = this.productList.length;
    this.productArray = [];
    for (let i = 0; i < l; i++) {
      if (!this.productList[i].status) {
        this.productArray.push(this.productList[i].ProductID);
      }
    }
    this.addProducts();
  }

  addSelectedProducts() {
    let l = this.productList.length;
    this.productArray = [];
    for (let i = 0; i < l; i++) {
      if (this.productList[i].status) {
        this.productArray.push(this.productList[i].ProductID);
      }
    }
    this.addProducts();
  }

  deleteSelectedProducts() {
    let l = this.selectedProducts.length;
    this.productArray = [];
    for (let i = 0; i < l; i++) {
      if (this.selectedProducts[i].status) {
        this.productArray.push(this.selectedProducts[i].ProductID);
      }
    }
    this.removeProducts();
  }

  deleteExcludedProducts() {
    let l = this.selectedProducts.length;
    this.productArray = [];
    for (let i = 0; i < l; i++) {
      if (!this.selectedProducts[i].status) {
        this.productArray.push(this.selectedProducts[i].ProductID);
      }
    }
    this.removeProducts();
  }

  updatedSearchResult(data) {
    this.isLoading = false;
    if (data.productSearch === 'product') {
      this.productList = data.ItemsCollection;
      this.paginateConfig.totalItems = data.TotalItemsCount || 0;
      this.showPagination = data.TotalItemsCount ? true : false;
    } else {
      this.selectedProducts = data.ItemsCollection;
    }
  }

  setHeight() {
    let getWindowHeight = window.innerHeight;
    getWindowHeight = getWindowHeight - 120;
    return getWindowHeight;
  }
}
