import { Order } from '../models/index';
import { OrderActions } from '../actions/index';

export interface OrderState {
    orders: Order[];
}

export const initialState: OrderState = {
    orders: null
};

export function orderReducers(state: OrderState = initialState, action: OrderActions.All) {

    switch (action.type) {

        case OrderActions.LOAD_ORDERS_SUCCESS:
            return Object.assign({}, state, { orders: action.payload });

        default: {
            return state;
        }
    }
}
