import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { CategoryService } from '../state/services/category.service';
import { LoginService } from '../../login/state/services/login.service';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})

export class CategoryListComponent implements OnInit {

  header: any;
  categoryList: any = [];
  submitted = false;
  actions: any = ['view', 'edit', 'delete'];
  webshopDetail: any;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'CategoryID',
      header: 'ID'
    },
    {
      primaryKey: 'Name',
      header: 'Name'
    },
    // {
    //   primaryKey: 'Description',
    //   header: 'Description'
    // },
    {
      primaryKey: 'IconURL',
      header: 'Icon',
      format: 'icon'
    },
    {
      primaryKey: 'ImageURL',
      header: 'Image',
      format: 'icon'
    },
    {
      primaryKey: 'Position',
      header: 'Position'
    },
    {
      primaryKey: 'ProductsMainCount',
      header: 'Product Count'
    },
    {
      primaryKey: 'ProductsCount',
      header: 'Products Count (Sub)'
    },
    {
      primaryKey: 'isDeleted',
      header: 'Deleted'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];

  constructor(private categoryService: CategoryService, private apiService: ApiService, private loginService: LoginService, private router: Router) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  onLoadFunctions() {
    let customerID = 0;
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe(data => {
        if (data) {
          customerID = this.webshopDetail.CustomerID;
        }
      });
    } else {
      if (sessionStorage.getItem('userdetails') !== null) {
        const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
        customerID = this.webshopDetail.CustomerID;
      }
    }

    const searchCriteria = {
      customerId: customerID
    };
    this.categoryService.loadCategorys(searchCriteria);
  }

  ngOnInit() {
    this.header = 'Category Details'; // Multilingual to be implemented
    this.getCategoryList();
  }

  getCategoryList() {
    this.categoryService.getCategoryList().subscribe(data => {
      if (data) {
        console.log('whole list', data)
        this.categoryList = data;
      }
    });
  }

  refreshCategoryList(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.getCategoryList();
    }
  }

  addCategory(event: boolean) {
    this.submitted = event;
  }

  viewCategory(event: boolean) {
    this.submitted = event;
  }

  editCategory(categoryData: any) {
    try {
      this.router.navigate(['/admin-category-edit'], { replaceUrl: false });
    } catch (error) {
    }
  }

  deleteCategory(event: boolean) {
    this.submitted = event;
  }

  advancesearch() {
    this.router.navigate(['/app-advanced-search'], { replaceUrl: false });
  }
  subcategories() {
    this.router.navigate(['/app-subcategories'], { replaceUrl: false });
  }
}
