import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { BlockActions } from '../actions/index';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import { State } from '../reducers/index';
import { BlockHttpService } from '../services/block.http.service';


@Injectable()
export class BlockEffects {

    @Effect()
    getBlockList$: Observable<Action> = this.actions$.pipe(
        ofType(BlockActions.LOAD_BLOCKS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.blockHttpService.getBlockList(payload).map((data) => {
                // data preparation done here... image to be made image tag, etc.
                return new BlockActions.LoadBlockSuccess(data);
            });
        }),
        catchError((error: any) => {
                return Observable.of(new BlockActions.LoadBlockFail(error));
            })
        );

    @Effect()
    saveBlock$: Observable<Action> = this.actions$.pipe(
        ofType(BlockActions.SAVE_BLOCK),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.blockHttpService.saveBlock(payload.payload).map((data) => {
                return new BlockActions.SaveBlockSuccess(data);
            });
        }),
        catchError((error: any) => {
                return Observable.of(new BlockActions.SaveBlockFail(error));
            })
        );

    constructor(private actions$: Actions,
                private store$: Store<State>,
                private blockHttpService: BlockHttpService) { }
}
