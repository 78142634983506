import { User } from '../models/index';
import { UserActions } from '../actions/index';

export interface UserState {
    users: User[];
    countries: any;
    customers: any;
    roles: any;
    dynamicFormFields: any;
    language: any;
    currencies: any;
    userID: any;
    addUser: any;
    fileUpload: any;
    updateUser: any;
    validateuser:any;
}

export const initialState: UserState = {
    users: null,
    countries: null,
    customers: null,
    roles: null,
    dynamicFormFields: null,
    language: null,
    currencies: null,
    userID: null,
    addUser: null,
    fileUpload: null,
    updateUser: null,
    validateuser:null
};

export function userReducers(state: UserState = initialState, action: UserActions.All) {
    switch (action.type) {

        case UserActions.LOAD_USERS_SUCCESS:
            return Object.assign({}, state, { users: action.payload });

        case UserActions.LOAD_COUNTRIES_SUCCESS:
            return Object.assign({}, state, { countries: action.payload });

        case UserActions.LOAD_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, { customers: action.payload });

        case UserActions.LOAD_ROLES_SUCCESS:
            return Object.assign({}, state, { roles: action.payload });

        case UserActions.LOAD_LANGUAGES_SUCCESS:
            return Object.assign({}, state, { language: action.payload });

        case UserActions.LOAD_CURRENCIES_SUCCESS:
            return Object.assign({}, state, { currencies: action.payload });

        case UserActions.PREPARE_FORM_SUCCESS:
            return Object.assign({}, state, { dynamicFormFields: action.payload });

        case UserActions.LOAD_DELETE_SUCCESS:
            return Object.assign({}, state, { userID: action.payload });

        case UserActions.ADD_USER_SUCCESS:
            return Object.assign({}, state, { addUser: action.payload });

        case UserActions.FILE_UPLOAD_SUCCESS:
            return Object.assign({}, state, { fileUpload: action.payload });

        case UserActions.UPDATE_USER_SUCCESS:
            return Object.assign({}, state, { updateUser: action.payload });

        case UserActions.VALIDATE_USER_SUCCESS:
            return Object.assign({}, state, { validateuser: action.payload });

        default: {
            return state;
        }
    }
}
