import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MDBBootstrapModulePro, MDBBootstrapModule, WavesModule, ButtonsModule } from 'ng-uikit-pro-standard';
import { TranslateModule } from '@ngx-translate/core';
import { ManagePrintPriceComponent } from './manage-print-price.component';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { CustomdirectiveModule } from '../../customdirective/customdirective.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    ControlValidationModule,
    MDBBootstrapModulePro,MDBBootstrapModule,ButtonsModule, WavesModule,CustomdirectiveModule
  ],
  declarations: [ManagePrintPriceComponent],
  exports: [ManagePrintPriceComponent]
})
export class ManagePrintPriceModule { }
