import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable({
    providedIn: 'root'
})
export class OrderModalHttpService {
    constructor(private apiService: ApiService) {
    }

    downloadDeliveryNote(invoiceID) {
        window.location.href = this.apiService.baseUrl + `en/deliverynote/pdf/${invoiceID}`;
    }

    deleteDeliveryNote(invoiceID) {
        const path = `en/deliverynote/${invoiceID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    createCreditNote(data) {
        const path = `en/creditnote?sendEmail=${data.sendEmail}`;
        return this.apiService.post(path, data.payload).map((res) => {
            return res;
        });
    }

    sendCreditNoteMail(invoiceID) {
        const path = `en/sendCreditNoteToEmail/pdf/${invoiceID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    downloadCreditNote(invoiceID) {
        window.location.href = this.apiService.baseUrl + `en/creditnote/pdf/${invoiceID}`;
    }

    deleteCreditNote(invoiceID) {
        const path = `en/creditnote/${invoiceID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getSoForIspProduct(data) {
        // const url = 'en/transportexchanger/salesorders/getdeliverynotestatus';
        const url = 'en/transportexchanger/salesorders/v2/getdeliverynotestatus';
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    }

    /** For create SO-SalesOrder => ISP products */
    createSoForIspProduct(data) {
        const url = `en/transportexchanger/salesorders/senddeliverynotetowarehouse`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };

    getSoForNonIspProduct(data) {
        const url = 'en/transportexchanger/purchaseorders/getdeliverynotestatus';
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    }

    /** For create IS-SalesOrder => Non-ISP products */
    createSoForNonIspProduct(data) {
        const url = `en/transportexchanger/purchaseorders/senddeliverynotetowarehouse`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };
}
