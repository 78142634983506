import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderHttpService } from '../state/services/order.http.service';

@Component({
  selector: 'rewardkart-admin-order-items-overview',
  templateUrl: './order-items-overview.component.html',
  styleUrls: ['./order-items-overview.component.scss']
})
export class OrderItemsOverviewComponent implements OnInit {
  orderItem = null;
  webshopDetail: any;
  infoList = [
    {
      label: 'Item Number',
      key: 'ProductIdManual',

    },
    {
      label: 'Product Name',
      key: 'ProductName',

    },
    {
      label: 'Ordered Quantity',
      key: 'OrderedQuantity',
    },
    {
      label: 'Express Order',
      key: 'isExpressOrder',
      format: 'boolean'
    },
    {
      label: 'Sample Order',
      key: 'isSampleOrderItem',
      format: 'boolean'
    },
    {
      label: 'Delivery Date',
      key: 'DeliveryDate',
      format: 'date'
    },
  ];
  constructor(private route: ActivatedRoute, private httpService: OrderHttpService, private router: Router) {
    const idNo = this.getUrlParam("id");
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.getOrderOverView(idNo, this.webshopDetail.CustomerID);
    }
  }

  ngOnInit() {
  }
  getUrlParam(paramValue) {
    const idFromUrl: string = this.route.snapshot.paramMap.get(paramValue);
    return idFromUrl;
  }

  getOrderOverView(id, customerId) {
    this.httpService.getOrderOverView(id, customerId).subscribe((response) => {
      this.orderItem = response;
    });
  }
  viewDetail(orderId, subOrderId) {
    this.router.navigate([`/admin-order-process/${orderId}/${subOrderId}`], { replaceUrl: false });
  }
  backToOrderList(orderId) {
    const id = orderId;
    this.router.navigate([`/admin-order-item/${id}`], {
      state: { id },
      replaceUrl: false
    });
  }
}
