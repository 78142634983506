import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderHttpService } from '../state/services/order.http.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-order-process-online-details',
  templateUrl: './production-line.component.html',
  styleUrls: ['./production-line.component.scss']
})
export class ProductionLineComponent implements OnInit {
  orderDetail = [];
  loading = false;
  OrderId;
  finishedPL = false;
  customerID: any;
  webshopDetail: any;
  constructor(private route: ActivatedRoute, private toastService: ToastService,
    private orderService: OrderHttpService, private router: Router) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    const idFromRoute = this.route.snapshot.paramMap.get('id');
    const finishedPLsFromRoute = this.route.snapshot.paramMap.get('finishedPLs');

    const { id, finishedPLs, customerID } = history.state;
    const productId = idFromRoute || id;
    this.finishedPL = finishedPLsFromRoute || finishedPLs;
    this.OrderId = productId;
    this.customerID = customerID;
    this.loadOrderDetail(productId, this.finishedPL, customerID);
  }

  loadOrderDetail(id, finishedPL, customerID) {
    this.loading = true;
    this.orderService.getOrderProductionLineDetail(id, finishedPL, customerID,
      this.webshopDetail.CustomerID).subscribe((res: any) => {
        this.orderDetail = res.map(order => {
          order.Orders = order.Orders.map(orderItem => {
            orderItem.ProductionLineID = order.ProductionLineID;
            return orderItem;
          });
          const totalOrder = {
            CompanyName: ' ',
            InvoiceCountry: '   ',
            OrderIdManual: 'Total',
            PaymentMode: '  ',
            TotalQuantity: order.OrderedQuantity,
            UserName: ' ',
            WebShop: '  ',
            isDispatched: '  ',
            isDelivered: '  '
          };
          order.Orders.push(totalOrder);

          return order;
        });
        this.loading = false;
      }, error => {
        this.loading = false;
        this.toastService.error('something went wrong.please try after sometime');
      });
  }

  goBack() {
    if (history.state.urlData !== undefined) {
      this.router.navigate(['/view-order'], { state: { data: history.state.urlData }, replaceUrl: false });
    } else if (history.state.dashboardData !== undefined || (sessionStorage.getItem('dashboardRedirectStatus') !== undefined &&
      sessionStorage.getItem('dashboardRedirectStatus') !== null)) {
      this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
    } else {
      this.router.navigate([`/admin-order-production-line-list/false`], { replaceUrl: false });
    }
  }
}
