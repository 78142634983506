import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ControlValidationComponent } from './control-validation.component';
import { ControlValidationService } from './control-validation.service';


@NgModule({
  imports: [
    CommonModule,
    // BrowserModule
  ],
  declarations: [ControlValidationComponent],
  exports: [
    ControlValidationComponent,
    CommonModule
  ],
  providers: [
    ControlValidationService
  ]
})

export class ControlValidationModule {
}
