import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { LinkTableComponent } from './link-table.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormatCellPipe } from './pipes/format-cell.pipe';
import { LinkTableService } from './state/services/link-table.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LinkTableEffects } from './state/effects/link-table.effects';
import { linkTableReducers } from './state/reducers/link-table.reducer';
import { WavesModule, ButtonsModule, TableModule, DropdownModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { PageControlModule } from '../page-control/page-control.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LinkTableDirectiveModule } from './directives/link-table-directive.module';
import { LinkTablePipeModule } from './pipes/link-table-pipe.module';
import { DragItemImage } from './directives/con-drag-drop-directive';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
// import { SearchFieldsPipe } from './pipes/search-fields.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  imports: [
    CommonModule,
    // BrowserModule,
    WavesModule,
    ButtonsModule,
    TooltipModule,
    TableModule,
    FormsModule,
    PageControlModule,
    EffectsModule.forFeature([LinkTableEffects]),
    StoreModule.forFeature('linkTableModule', linkTableReducers),
    PerfectScrollbarModule,
    NgxPaginationModule,
    LinkTableDirectiveModule,
    LinkTablePipeModule,
    MDBBootstrapModulesPro.forRoot(),
    DropdownModule.forRoot(),
  ],
  declarations: [LinkTableComponent, DragItemImage],
  exports: [
    CommonModule,
    LinkTableComponent,
    LinkTableDirectiveModule,
    LinkTablePipeModule
  ],
  providers: [CurrencyPipe, DatePipe, LinkTableService, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }]
})
export class LinkTableModule { }

