import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { UserHttpService } from '../state/services/user.http.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ControlValidationService } from '../../shared/control-validation/index';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/common-services/api.service';
import { stat } from 'fs';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginHttpService } from 'src/app/login/state/services/login.http.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'rewardkart-admin-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})

export class UserEditComponent implements OnInit {
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('rejectModal', { static: true }) rejectModal: ModalDirective;
  @ViewChild('userExceedModal', { static: true }) userExceedModal: ModalDirective;
  @ViewChild('showlist', { static: true }) showlist: ModalDirective;
  @ViewChild('editdeliveryAddress', { static: true }) editdeliveryAddress: ModalDirective;
  header: any;
  formFields: any = '';
  buttonActions: any = ['back', 'next', 'submit', 'popup', 'delete'];
  optionsSelectCustomers: Array<any>;
  optionsSelectRoles: Array<any>;
  optionsSelectSalutation: Array<any>;
  optionsSelectCountries: Array<any>;
  countriesList: Array<any>;
  optionsSelectLanguages: Array<any>;
  optionsSelectCurrencies: Array<any>;
  productRoles: any;
  selectProductRoles: any;
  public formTypeName: any = 'personalDetails';
  personalFormCompleteStatus: Boolean = false;
  formSubmitDatas: any = {};
  uploadImage: any;
  uploadPanImage: any;
  uploadGstImage: any;
  uploadRocImage: any;
  userPersonalDetails: FormGroup;
  isEdit: boolean = false;
  addUserButton: any;
  selectedImg: any;
  selectedpanImg: any;
  selectedgstImg: any;
  selectedrocImg: any;
  currentImg: any;
  panImg: any;
  gstImg: any;
  rocImg: any;
  userName: any;
  isEnabledNextBtn = false;
  EnableNextbtnTab = false;
  isUserExists = false;
  showEanNumber: boolean;
  postalError: Boolean;
  deliverypostalError: Boolean;
  showImageError: Boolean = false;
  isParentCustomer: Boolean = false;
  editResponse: any;
  deleteRequest: any = {};
  showLoader: Boolean = false;
  usershowAddressList: any = [];
  editUserDeliveryAddressId: any = '';
  getuserstatus = [{ label: 'Approve', value: 1 }, { label: 'Reject', value: 0 }];
  inputElement: ElementRef;
  public postalCodeErrorCount = 0;
  public postalCodeDeliveryCountryErrorCount = 0;
  public postalCodeDeliveryCountryErrorCount1 = 0;
  webshopDetail: any;
  public kycStatus: Boolean = false;
  public panpdfView: Boolean = false;
  public gstpdfView: Boolean = false;
  public rocpdfView: Boolean = false;

  public ppdfView: Boolean = false;
  public gpdfView: Boolean = false;
  public rpdfView: Boolean = false;
  oldpassword: any;
  adduserData: any;
  customerID: any;
  planID: any;
  constructor(private cookieService: CookieService, private loginHttpService: LoginHttpService, private userService: UserService, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router, private userHttpService: UserHttpService, private renderer: Renderer2,
    private translate: TranslateService, private sanitizer: DomSanitizer) {
    const webshop = sessionStorage.getItem('webshopDetail');

    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {

      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.customerID = this.webshopDetail.CustomerID;
      // if (this.webshopDetail.ParentCustomerID !== null) {
      //   this.isParentCustomer = false;
      // }
      if (this.webshopDetail.ParentCustomerDomainName === this.webshopDetail.DomainName) {
        this.isParentCustomer = true;
      }
    }
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.loadForm();
    this.onLoadFunctions();
    if (history.state.payment && history.state.payment === 'success') {

      this.updateAdditionalUser();
    }
  }
  showUserValidation: boolean = false;
  personalDetails: FormGroup;
  userAdditinalDeliveryAddressDetails: FormGroup;
  vatImageForm: FormGroup;
  PreviousRoleCode: any;
  editData: any;
  customerId: any;
  ngOnInit() {
    const searchResult = sessionStorage.getItem('searchresult')
    if (searchResult !== null) {
      const filterFields = JSON.parse(sessionStorage.getItem('searchresult'))
      /** To Prefill Webshop dropdown value(based on Webshop) */
      // tslint:disable-next-line:max-line-length
      if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
        const getIndex = filterFields.findIndex(ind => ind.key === 'selectwebshop');
        if (getIndex !== null && getIndex !== undefined && getIndex >= 0) {
          this.customerId = filterFields[getIndex].selected;
        }
      }
      if (history.state.data !== undefined) {
        this.customerId = history.state.data.CustomerID;
      }
    }
    this.validatePostal();
    if (history.state.data === undefined) {
      this.getproductRoles();
    } else {
      this.getproductRoles(history.state.data.CustomerID);
    }

  }

  listPage() {
    if (history.state.data !== undefined) {
      if (history.state.data.dashboardRedirectStatus) {
        this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
      } else {
        sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
        this.router.navigate(['/admin-user-list'], { replaceUrl: false });
      }
    } else {
      sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
      this.router.navigate(['/admin-user-list'], { replaceUrl: false });
    }
  }

  loadForm() {
    this.userPersonalDetails = this.formBuilder.group({
      selectCurrency: ['', Validators.required],
      selectLanguage: ['', Validators.required],
      invoiceCompanyName: ['', [Validators.required, Validators.maxLength(50)]],
      invoicePhoneNumber: ['', [Validators.required, ControlValidationService.phoneNumberValidator]],
      invoiceEmail: ['', [Validators.required, ControlValidationService.emailValidator]],
      emailForInvoice: ['', [ControlValidationService.emailValidator]],
      invoiceAddressLine1: ['', Validators.required],
      invoiceAddressLine2: ['', Validators.required],
      invoiceAddressLine3: ['', Validators.required],
      invoiceCity: ['', Validators.required],
      invoicePostalCode: ['', Validators.required],
      invoiceCountry: ['', Validators.required],
      sameInvoiceAddress: [false, Validators.required],
      deliveryCompanyName: ['', [Validators.maxLength(50)]],
      deliveryPhoneNumber: ['', Validators.required],
      deliveryEmail: ['', [Validators.required, ControlValidationService.emailValidator]],
      deliveryAddressLine1: ['', Validators.required],
      deliveryAddressLine2: ['', Validators.required],
      deliveryAddressLine3: ['', Validators.required],
      deliveryCity: ['', Validators.required],
      deliveryPostalCode: ['', Validators.required],
      // selectCountryList: ['', Validators.required],
      deliveryContactPerson: ['', Validators.required],
      deliveryInstruction: [''],
      selectProductRoles: ['', Validators.required],
      vatIdentificationNumber: [''],
      eanNumber: [''],
      discountPercentage: ['', Validators.required],
      comment: ['', Validators.required],
      paymentInvoice: [false, Validators.required],
      draganddrop: ['', Validators.required],
      draganddroppan: ['', Validators.required],
      draganddropgst: ['', Validators.required],
      draganddroproc: ['', Validators.required],
      salutation: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      department: ['', Validators.required],
      jobPosition: ['', Validators.required],
      confirmPassword: ['', [Validators.required]],
      Password: ['', [Validators.required, ControlValidationService.passwordValidator]],
      userName: ['', Validators.required],
      roles: ['', Validators.required],
      deliveryCountry: ['', Validators.required],
      userstatus: ['']
    })
    this.optionsSelectSalutation = [
      {
        'value': 'CAPT',
        'label': 'CAPT'
      },
      {
        'value': 'DR',
        'label': 'DR'
      },
      {
        'value': 'MR',
        'label': 'MR'
      },
      {
        'value': 'MRS',
        'label': 'MRS'
      },
      {
        'value': 'MISS',
        'label': 'MISS'
      },
      {
        'value': 'MS',
        'label': 'MS'
      }
    ]
    this.resetMoreDeliveryAddressForm();
    // this.userAdditinalDeliveryAddressDetails = this.formBuilder.group({
    //   deliveryCompanyName1: ['', Validators.required],
    //   deliveryPhoneNumber1: ['', Validators.required],
    //   deliveryEmail1: ['', [Validators.required, ControlValidationService.emailValidator]],
    //   deliveryAddressLine11: ['', Validators.required],
    //   deliveryAddressLine21: [''],
    //   deliveryAddressLine31: [''],
    //   deliveryCity1: ['', Validators.required],
    //   deliveryPostalCode1: [''],
    //   deliveryCountry1: ['', Validators.required],
    //   deliveryContactPerson1: ['', Validators.required],
    //   setAsDefaultAddress: ['']
    // })
  }

  // setVATValidation(event) {
  //   const data = (event.value !== undefined && event.value !== null) ? event.value : event;
  //   const vatMandatoryField = this.countriesList.find(x => x.Alpha2Code === data);
  //   this.vatMandatoryFieldStatus = false;
  //   if (vatMandatoryField.isVATNumberMandatory) {
  //     this.vatMandatoryFieldStatus = true;
  //     this.userPersonalDetails.get('vatIdentificationNumber').setValidators([Validators.required]);
  //   } else {
  //     this.userPersonalDetails.get('vatIdentificationNumber').setValidators(null);
  //   }
  // }

  resetMoreDeliveryAddressForm() {
    this.userAdditinalDeliveryAddressDetails = this.formBuilder.group({
      deliveryCompanyName1: ['', [Validators.maxLength(50)]],
      deliveryPhoneNumber1: ['', Validators.required],
      deliveryEmail1: ['', [Validators.required, ControlValidationService.emailValidator]],
      deliveryAddressLine11: ['', Validators.required],
      deliveryAddressLine21: [''],
      deliveryAddressLine31: [''],
      deliveryCity1: ['', Validators.required],
      deliveryPostalCode1: ['', Validators.required],
      deliveryCountry1: ['', Validators.required],
      deliveryContactPerson1: ['', Validators.required],
      setAsDefaultAddress: [''],
      deliveryInstruction: ['']
    })
  }

  editUserDeliveryData(data) {
    if (data !== '' && data !== undefined && data !== null) {
      this.showlist.hide();
      this.editdeliveryAddress.show();
      this.editUserDeliveryAddressId = data.UsersDeliveryAddressID;
      const getCountry = this.optionsSelectCountries.filter(data1 => data1.value === data.DeliveryCountry);
      this.userAdditinalDeliveryAddressDetails = this.formBuilder.group({
        deliveryCompanyName1: [data !== undefined ? data.DeliveryCompanyName.substring(0, 50) : ''],
        deliveryPhoneNumber1: [data !== undefined ? data.DeliveryPhoneNumber : '', Validators.required],
        deliveryEmail1: [data !== undefined ? data.DeliveryEmail : '', [Validators.required, ControlValidationService.emailValidator]],
        deliveryAddressLine11: [data !== undefined ? data.DeliveryAddress : '', Validators.required],
        deliveryAddressLine21: [data !== undefined ? data.DeliveryAddress2 : ''],
        deliveryAddressLine31: [data !== undefined ? data.DeliveryAddress3 : ''],
        deliveryCity1: [data !== undefined ? data.DeliveryCity : '', Validators.required],
        deliveryPostalCode1: [data !== undefined ? data.DeliveryPostalCode : '', Validators.required],
        deliveryContactPerson1: [data !== undefined ? data.DeliveryContactPerson : '', Validators.required],
        deliveryCountry1: [data !== undefined && getCountry[0] !== undefined ? getCountry[0].value : '', Validators.required],
        setAsDefaultAddress: [data !== undefined ? data.isDefaultAddress : false],
        deliveryInstruction: [data !== undefined ? data.DeliveryInstructions : false]
      });
    }
  }

  updateUserAdditinalDeliveryAddress() {
    let updateUserBudgetsData = {
      DeliveryAddress: this.userAdditinalDeliveryAddressDetails.value.deliveryAddressLine11,
      DeliveryAddress2: this.userAdditinalDeliveryAddressDetails.value.deliveryAddressLine21,
      DeliveryAddress3: this.userAdditinalDeliveryAddressDetails.value.deliveryAddressLine31,
      DeliveryCity: this.userAdditinalDeliveryAddressDetails.value.deliveryCity1,
      DeliveryCompanyName: this.userAdditinalDeliveryAddressDetails.value.deliveryCompanyName1.substring(0, 50),
      DeliveryContactPerson: this.userAdditinalDeliveryAddressDetails.value.deliveryContactPerson1,
      DeliveryCountry: this.userAdditinalDeliveryAddressDetails.value.deliveryCountry1,
      DeliveryEmail: this.userAdditinalDeliveryAddressDetails.value.deliveryEmail1,
      DeliveryPhoneNumber: this.userAdditinalDeliveryAddressDetails.value.deliveryPhoneNumber1,
      DeliveryPostalCode: this.userAdditinalDeliveryAddressDetails.value.deliveryPostalCode1,
      UserID: history.state.data.UserID,
      isDefaultAddress: (this.userAdditinalDeliveryAddressDetails.value.setAsDefaultAddress !== '') ? this.userAdditinalDeliveryAddressDetails.value.setAsDefaultAddress : false,
      DeliveryInstructions: this.userAdditinalDeliveryAddressDetails.value.deliveryInstruction,
    }
    let postalcodeErrorExample = '';
    let postalcodeErrorIs = '';
    let deliveryPostalcodeError = '';
    let postalcodeProceedText = '';
    this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
      postalcodeErrorExample = res;
    });
    this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
      postalcodeErrorIs = res;
    });
    this.translate.get('USER.DELIVERY_POSTALCODE_ERROR').subscribe((res: string) => {
      deliveryPostalcodeError = res;
    });
    this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
      postalcodeProceedText = res;
    });
    let country = '';
    let postalCode = '';
    if (this.userAdditinalDeliveryAddressDetails.controls.deliveryCountry1.value !== undefined) {
      country = this.userAdditinalDeliveryAddressDetails.controls.deliveryCountry1.value.trim();
    }
    if (this.userAdditinalDeliveryAddressDetails.controls.deliveryPostalCode1.value !== undefined) {
      postalCode = this.userAdditinalDeliveryAddressDetails.controls.deliveryPostalCode1.value.trim()
    }
    this.userHttpService.checkPostalCode(country, postalCode).subscribe(
      data => {
        let returnStatus;
        if (data['ValidPostalRegex']) {
          returnStatus = true;
        } else {
          this.postalCodeDeliveryCountryErrorCount1 += 1;
          if (Number(this.postalCodeDeliveryCountryErrorCount1) > 1) {
            returnStatus = true;
          } else {
            const countryResponse = this.countriesList.find(item => item.Alpha2Code === country);
            if (data['VaildPostalcode'] !== '') {
              const displayText = deliveryPostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;;
              // tslint:disable-next-line:max-line-length
              this.toast.error(this.translate.instant('USERADDEDIT.ERROR') + ': ' + displayText, '', { closeButton: true, enableHtml: true });
              returnStatus = false;
            } else {
              returnStatus = true;
            }
          }
        }
        if (returnStatus) {
          if (this.editUserDeliveryAddressId !== '') {
            updateUserBudgetsData['UsersDeliveryAddressID'] = this.editUserDeliveryAddressId;

            this.showLoader = true;
            this.userHttpService.updateUserAdditionalDeliveryAddress(updateUserBudgetsData).subscribe((data: any) => {
              this.showLoader = false;
              if (data) {
                this.editUserDeliveryAddressId = '';
                this.editData.DeliveryAddresses = data.DeliveryAddresses;
                this.toast.success(this.translate.instant('USERADDEDIT.DELIVERY_ADDRESS_UPDATE_SUCCESS'));
                this.showlist.show();
                this.editdeliveryAddress.hide();
              } else {
                this.toast.error(data.message);
              }
            },
              err => {
                this.showLoader = false;
                this.toast.error(err.error.Message);
              })
          } else {
            this.showLoader = true;
            this.userHttpService.createUserAdditionalDeliveryAddress(updateUserBudgetsData).subscribe((data: any) => {
              this.showLoader = false;
              if (data) {
                this.editUserDeliveryAddressId = '';
                this.editData.DeliveryAddresses = data.DeliveryAddresses;
                this.toast.success(this.translate.instant('USERADDEDIT.DELIVERY_ADDRESS_CREATE_SUCCESS'));
                this.showlist.show();
                this.editdeliveryAddress.hide();
              } else {
                this.toast.error(data.message);
              }
            },
              err => {
                this.showLoader = false;
                this.toast.error(err.error.Message);
              })
          }
        }
      });
  }

  editAddUserForm() {
    const editData = history.state.data;
    this.editData = history.state.data;
    if (this.editData !== undefined && this.editData.ImageURL !== undefined && this.editData.ImageURL !== '') {
      let getImg = this.editData.ImageURL.split('/');
      getImg = getImg[getImg.length - 1];
      this.uploadImage = getImg !== '' && getImg !== undefined ? getImg : '';
    }

    if (this.editData !== undefined && this.editData.PanImageUrl !== undefined && this.editData.PanImageUrl !== '') {
      let getImg = this.editData.PanImageUrl.split('/');
      getImg = getImg[getImg.length - 1];
      this.uploadPanImage = getImg !== '' && getImg !== undefined ? getImg : '';
    }

    if (this.editData !== undefined && this.editData.GSTImageUrl !== undefined && this.editData.GSTImageUrl !== '') {
      let getImg = this.editData.GSTImageUrl.split('/');
      getImg = getImg[getImg.length - 1];
      this.uploadGstImage = getImg !== '' && getImg !== undefined ? getImg : '';
    }

    if (this.editData !== undefined && this.editData.ROCImageUrl !== undefined && this.editData.ROCImageUrl !== '') {
      let getImg = this.editData.ROCImageUrl.split('/');
      getImg = getImg[getImg.length - 1];
      this.uploadRocImage = getImg !== '' && getImg !== undefined ? getImg : '';
    }

    if (editData !== undefined && editData.EANNumber !== undefined) {
      editData.EANNumber = (editData.EANNumber !== '-') ? editData.EANNumber : '';
    }

    this.userName = editData !== undefined ? editData.UserName : '';
    this.PreviousRoleCode = editData.Roles[0].Code;
    if (this.optionsSelectCountries !== null && this.optionsSelectCountries !== undefined) {
      let getCountry = this.optionsSelectCountries.filter(data => data.value === editData.DeliveryCountry)
      this.userPersonalDetails = this.formBuilder.group({
        selectCurrency: [editData !== undefined ? editData.CurrencyID : '', Validators.required],
        selectLanguage: [editData !== undefined ? editData.LanguageID : '', Validators.required],
        invoiceCompanyName: [editData !== undefined ? editData.CompanyName.substring(0, 50) : '', Validators.required],
        invoicePhoneNumber: [editData !== undefined ? editData.PhoneNumber : '', [Validators.required, ControlValidationService.phoneNumberValidator]],
        invoiceEmail: [editData !== undefined ? editData.Email : '', [Validators.required, ControlValidationService.emailValidator]],
        emailForInvoice: [editData !== undefined ? editData.EmailForInvoices : '', [ControlValidationService.emailValidator]],
        invoiceAddressLine1: [editData !== undefined ? editData.Address : '', Validators.required],
        invoiceAddressLine2: [editData !== undefined ? editData.Address2 : '', Validators.required],
        invoiceAddressLine3: [editData !== undefined ? editData.Address3 : '', Validators.required],
        invoiceCity: [editData !== undefined ? editData.City : '', Validators.required],
        invoicePostalCode: [editData !== undefined ? editData.PostalCode : '', Validators.required],
        invoiceCountry: [editData !== undefined ? editData.Country : '', Validators.required],
        sameInvoiceAddress: [editData !== undefined ? editData.isSameAsInvoiceAddress : '', Validators.required],
        deliveryCompanyName: [editData !== undefined ? editData.DeliveryCompanyName.substring(0, 50) : ''],
        deliveryPhoneNumber: [editData !== undefined ? editData.DeliveryPhoneNumber : '', Validators.required],
        deliveryEmail: [editData !== undefined ? editData.DeliveryEmail : '', [Validators.required, ControlValidationService.emailValidator]],
        deliveryAddressLine1: [editData !== undefined ? editData.DeliveryAddress : '', Validators.required],
        deliveryAddressLine2: [editData !== undefined ? editData.DeliveryAddress2 : '', Validators.required],
        deliveryAddressLine3: [editData !== undefined ? editData.DeliveryAddress3 : '', Validators.required],
        deliveryCity: [editData !== undefined ? editData.DeliveryCity : '', Validators.required],
        deliveryPostalCode: [editData !== undefined ? editData.DeliveryPostalCode : '', Validators.required],
        // selectCountryList: [editData !== undefined ? editData.DeliveryCountry : '', Validators.required],
        deliveryContactPerson: [editData !== undefined ? editData.DeliveryContactPerson : '', Validators.required],
        deliveryInstruction: [editData !== undefined ? editData.DeliveryInstructions : '',],
        selectProductRoles: ['', Validators.required],
        vatIdentificationNumber: [editData !== undefined ? editData.VAT : ''],
        eanNumber: [(editData !== undefined && editData.EANNumber !== undefined) ? editData.EANNumber : ''],
        discountPercentage: [(editData !== undefined && editData.DiscountPercentage !== undefined) ? editData.DiscountPercentage : ''],
        comment: [editData !== undefined ? editData.Comment : '', Validators.required],
        paymentInvoice: [editData !== undefined ? editData.PaymentByInvoice : '', Validators.required],
        draganddrop: ['', Validators.required],
        draganddroppan: ['', Validators.required],
        draganddropgst: ['', Validators.required],
        draganddroproc: ['', Validators.required],
        salutation: [editData !== undefined ? editData.Salutation : '', Validators.required],
        firstname: [editData !== undefined ? editData.FirstName : '', Validators.required],
        lastname: [editData !== undefined ? editData.LastName : '', Validators.required],
        department: [editData !== undefined ? editData.Department : '', Validators.required],
        jobPosition: [editData !== undefined ? editData.JobPosition : '', Validators.required],
        confirmPassword: ['', [Validators.required]],
        Password: ['', [Validators.required, ControlValidationService.passwordValidator]],
        userName: [editData !== undefined ? editData.UserName : '', Validators.required],
        roles: [editData !== undefined ? editData.Roles[0].Code : '', Validators.required],
        deliveryCountry: [editData !== undefined && getCountry[0] !== undefined ? getCountry[0].value : '', Validators.required],
        userstatus: ['']
      });
      this.oldpassword = editData.Password;
      // this.setVATValidation(editData.Country);
      this.currentImg = editData.ImageName !== '' && editData.ImageName !== null && editData.ImageName !== undefined ? editData.ImageURL : '';
      this.panImg = editData.PanImageName !== '' && editData.PanImageName !== null && editData.PanImageName !== undefined ? editData.PanImageUrl : '';
      this.gstImg = editData.GSTImageName !== '' && editData.GSTImageName !== null && editData.GSTImageName !== undefined ? editData.GSTImageUrl : '';
      this.rocImg = editData.ROCImageName !== '' && editData.ROCImageName !== null && editData.ROCImageName !== undefined ? editData.ROCImageUrl : '';
      this.kycStatus = editData.KYCStatus;

      if (this.panImg) {
        fetch(this.panImg, { method: "HEAD" })
          .then(response => response.headers.get("Content-Type"))
          .then(type => {
            let fileType = `.${type.replace(/.+\/|;.+/g, "")}`;
            if (fileType === '.pdf') {
              this.panpdfView = true;
            }
          });
      }

      if (this.gstImg) {
        fetch(this.gstImg, { method: "HEAD" })
          .then(response => response.headers.get("Content-Type"))
          .then(type => {
            let fileType = `.${type.replace(/.+\/|;.+/g, "")}`;
            if (fileType === '.pdf') {
              this.gstpdfView = true;
            }
          });
      }

      if (this.rocImg) {
        fetch(this.rocImg, { method: "HEAD" })
          .then(response => response.headers.get("Content-Type"))
          .then(type => {
            let fileType = `.${type.replace(/.+\/|;.+/g, "")}`;
            if (fileType === '.pdf') {
              this.rocpdfView = true;
            }
          });
      }
    }
  }
  // File Upload Preview
  urls = [];
  onSelectFile(event, imgName: any) {

    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.pdf' && getImg.toLowerCase() !== '.jfif' && getImg.toLowerCase() !== '.jpg' && getImg.toLowerCase() !== '.png' && getImg.toLowerCase() !== '.gif')) {
      this.showImageError = true;
      return;
    }
    else {
      if (getImg.toLowerCase() === '.pdf') {
        if (imgName == 'photo') {
          this.showImageError = true;
          return;
        } else {
          if (imgName == 'pan') {
            this.ppdfView = true;
          }

          if (imgName == 'gst') {
            this.gpdfView = true;
          }

          if (imgName == 'roc') {
            this.rpdfView = true;
          }
        }
      } else {
        if (imgName == 'pan') {
          this.ppdfView = false;
        }
        if (imgName == 'gst') {
          this.gpdfView = false;
        }
        if (imgName == 'roc') {
          this.rpdfView = false;
        }
      }
      this.showImageError = false;
    }

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          const imgurl = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
          if (imgName == 'photo') {
            this.selectedImg = event.target.result;
          }
          else if (imgName == 'pan') {
            this.selectedpanImg = imgurl;
          }
          else if (imgName == 'gst') {
            this.selectedgstImg = imgurl;
          }
          else if (imgName == 'roc') {
            this.selectedrocImg = imgurl;
          }
          this.urls.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
        let httpHeaders = new HttpHeaders();
        let httpParams = new HttpParams();
        httpHeaders.set('Content-Type', null);
        httpHeaders.set('Accept', "multipart/form-data");
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        if (imgName == 'photo') {
          formData.append('filePath', 'UserImages');
        } else {
          formData.append('filePath', 'UserDocuments');
        }
        //        this.userService.loaduploadFile(event.target.files[0].name, httpParams, httpHeaders);
        this.userService.loaduploadFile(formData);
        this.userService.uploadFile().subscribe((data: any) => {
          if (data) {
            if (imgName == 'photo') {
              this.uploadImage = data[1];
            }
            else if (imgName == 'pan') {
              this.uploadPanImage = data[1];
            }
            else if (imgName == 'gst') {
              this.uploadGstImage = data[1];
            }
            else if (imgName == 'roc') {
              this.uploadRocImage = data[1];
            }
          }
        })
      }
    }
  }

  validatePostal(type?) {

    if ((type === 'invoice' || type === 'all') && !(this.userPersonalDetails.controls.invoicePostalCode.value.length <= 25)) {
      this.postalError = true;
    }
    else {
      this.postalError = false;
    }

    if ((type === 'delivery' || type === 'all') && !(this.userPersonalDetails.controls.deliveryPostalCode.value.length <= 25)) {
      this.deliverypostalError = true;
    }
    else {
      this.deliverypostalError = false;
    }
  }

  changeActiveTab(tab) {
    if (!this.isUserExists) {
      this.isEnabledNextBtn = true;
    }
    if (tab === 1) {
      this.staticTabs.setActiveTab(tab);
    }
    // if (tab === 2 && this.isEnabledNextBtn) {
    if (tab === 2) {
      let pushPage = false;
      if ((this.userPersonalDetails.controls.Password.value == '' && !this.isEdit) || (this.userPersonalDetails.controls.confirmPassword.value == '' && !this.isEdit) || (this.userPersonalDetails.controls.Password.value !== this.userPersonalDetails.controls.confirmPassword.value && !this.isEdit)) {
        this.userPersonalDetails.controls['Password'].markAllAsTouched();
        this.userPersonalDetails.controls['confirmPassword'].markAllAsTouched();
        pushPage = false;
      }
      else {
        pushPage = true;
      }

      if (((this.userPersonalDetails.controls.salutation.value == '') || (this.userPersonalDetails.controls.userName.value == '') || (this.userPersonalDetails.controls.firstname.value == '') || (this.userPersonalDetails.controls.lastname.value == '') || (this.userPersonalDetails.controls.roles.value == '') || (this.userPersonalDetails.controls.selectCurrency.value == '') || (this.userPersonalDetails.controls.selectLanguage.value == '')) || !pushPage && !this.isEdit) {
        this.userPersonalDetails.controls['userName'].markAllAsTouched();
        this.userPersonalDetails.controls['roles'].markAllAsTouched();
        this.userPersonalDetails.controls['selectCurrency'].markAllAsTouched();
        this.userPersonalDetails.controls['selectLanguage'].markAllAsTouched();
        this.userPersonalDetails.controls['firstname'].markAllAsTouched();
        this.userPersonalDetails.controls['lastname'].markAllAsTouched();
        this.userPersonalDetails.controls['salutation'].markAllAsTouched();
        this.staticTabs.tabs[1].disabled = true;
      }
      else {
        // if (!this.isEdit) {
        this.staticTabs.tabs[1].disabled = false;

        // }
        this.staticTabs.setActiveTab(tab);
      }
    }

    if (tab === 3) {
      // if (this.userPersonalDetails.controls.deliveryCountry.value === 'DK') {
      //   this.showEanNumber = true;
      // }else{
      //   this.showEanNumber = false;
      // }
      if ((this.userPersonalDetails.controls.invoicePhoneNumber.value === '') || (this.userPersonalDetails.controls.invoiceEmail.value === '') || (this.userPersonalDetails.controls.invoiceAddressLine1.value === '') || (this.userPersonalDetails.controls.invoiceCity.value === '') || (this.userPersonalDetails.controls.invoiceCountry.value === '') || (this.userPersonalDetails.controls.deliveryPhoneNumber.value === '') || (this.userPersonalDetails.controls.deliveryEmail.value === '') || (this.userPersonalDetails.controls.deliveryAddressLine1.value === '') || (this.userPersonalDetails.controls.deliveryCity.value === '') || (this.userPersonalDetails.controls.deliveryCountry.value === '') || (this.userPersonalDetails.controls.deliveryContactPerson.value === '') || (this.userPersonalDetails.controls.deliveryCountry.value === '') || (this.userPersonalDetails.controls.invoicePostalCode.value === '') || (this.userPersonalDetails.controls.deliveryPostalCode.value === '') || !(this.userPersonalDetails.controls.invoicePostalCode.value.length <= 24) || !(this.userPersonalDetails.controls.deliveryPostalCode.value.length <= 24)) {
        this.userPersonalDetails.controls['invoicePhoneNumber'].markAllAsTouched();
        this.userPersonalDetails.controls['invoiceEmail'].markAllAsTouched();
        // this.userPersonalDetails.controls['emailForInvoice'].markAllAsTouched();
        this.userPersonalDetails.controls['invoiceAddressLine1'].markAllAsTouched();
        this.userPersonalDetails.controls['invoiceCity'].markAllAsTouched();
        this.userPersonalDetails.controls['invoiceCountry'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryPhoneNumber'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryEmail'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryAddressLine1'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryCity'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryContactPerson'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryCountry'].markAllAsTouched();
        this.userPersonalDetails.controls['invoicePostalCode'].markAllAsTouched();
        this.userPersonalDetails.controls['deliveryPostalCode'].markAllAsTouched();
        this.validatePostal('all');
        this.staticTabs.tabs[2].disabled = true;
      }
      else {
        // this.staticTabs.tabs[2].disabled = false;
        // this.staticTabs.setActiveTab(tab);
        this.validatePostalCode().subscribe((data) => {
          if (data) {
            this.staticTabs.tabs[2].disabled = false;
            this.staticTabs.setActiveTab(tab);
          }
        });
      }
    }

    if (tab === 4) {
      this.staticTabs.setActiveTab(tab);
    }

    // this.staticTabs.setActiveTab(tab);
  }

  validatePostalCode(): Observable<any> {
    let checkStatus = new Subject<any>();
    var country = '';
    var postalCode = '';
    if (this.userPersonalDetails.controls.invoiceCountry.value !== undefined) {
      country = this.userPersonalDetails.controls.invoiceCountry.value.trim();
    }
    if (this.userPersonalDetails.controls.invoicePostalCode.value !== undefined) {
      postalCode = this.userPersonalDetails.controls.invoicePostalCode.value.trim()
    }

    var deliveryPostalCode = '';
    var deliveryCountry = '';
    if (this.userPersonalDetails.controls.deliveryCountry.value !== undefined) {
      deliveryCountry = this.userPersonalDetails.controls.deliveryCountry.value.trim();
    }
    if (this.userPersonalDetails.controls.deliveryPostalCode.value !== undefined) {
      deliveryPostalCode = this.userPersonalDetails.controls.deliveryPostalCode.value.trim()
    }

    let postalcodeErrorExample = '';
    let postalcodeErrorIs = '';
    let deliveryPostalcodeError = '';
    let invoicePostalcodeError = '';
    let postalcodeProceedText = '';

    this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
      postalcodeErrorExample = res;
    });
    this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
      postalcodeErrorIs = res;
    });
    this.translate.get('USER.DELIVERY_POSTALCODE_ERROR').subscribe((res: string) => {
      deliveryPostalcodeError = res;
    });
    this.translate.get('USER.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
      invoicePostalcodeError = res;
    });
    this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
      postalcodeProceedText = res;
    });

    this.validatePostalCodeCheck(country, postalCode).subscribe((data) => {
      let proceedStatus = false;
      if (data['ValidPostalRegex']) {
        proceedStatus = true;
      } else {
        this.postalCodeErrorCount += 1;
        if (Number(this.postalCodeErrorCount) > 1) {
          proceedStatus = true;
        } else {
          proceedStatus = false;
          const countryResponse = this.countriesList.find(item => item.Alpha2Code === country);
          if (data['VaildPostalcode'] !== '') {
            const displayText = invoicePostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
              data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
              postalcodeProceedText;
            this.toast.error(this.translate.instant('USERADDEDIT.ERROR') + ': ' + displayText, '', { closeButton: true, enableHtml: true });
          } else {
            proceedStatus = true;
          }
        }
      }


      let proceedStatus1 = false;
      this.validatePostalCodeCheck(deliveryCountry, deliveryPostalCode).subscribe((data1) => {
        if (data1['ValidPostalRegex']) {
          proceedStatus1 = true;
        } else {
          this.postalCodeDeliveryCountryErrorCount += 1;
          if (Number(this.postalCodeDeliveryCountryErrorCount) > 1) {
            proceedStatus1 = true;
          } else {
            proceedStatus1 = false;
            const countryResponse = this.countriesList.find(item => item.Alpha2Code === deliveryCountry);
            if (data1['VaildPostalcode'] !== '') {
              const displayText = deliveryPostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data1['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data1['VaildPostalcode'] + '\n  <br/>'
                + postalcodeProceedText;
              this.toast.error('Error: ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus1 = true;
            }
          }
        }

        if (proceedStatus && proceedStatus1) {
          checkStatus.next(true);
        } else {
          checkStatus.next(false);
        }
      });
    });
    return checkStatus.asObservable();
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    var subject = new Subject<any>();
    this.userHttpService.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  validateFields() {
    let validateStatus = true;
    if ((this.userPersonalDetails.controls.salutation.value == '') || (this.userPersonalDetails.controls.userName.value == '') || (this.userPersonalDetails.controls.firstname.value == '') || (this.userPersonalDetails.controls.lastname.value == '') || (this.userPersonalDetails.controls.roles.value == '') || (this.userPersonalDetails.controls.selectCurrency.value == '') || (this.userPersonalDetails.controls.selectLanguage.value == '')) {
      this.userPersonalDetails.controls['userName'].markAllAsTouched();
      this.userPersonalDetails.controls['roles'].markAllAsTouched();
      this.userPersonalDetails.controls['selectCurrency'].markAllAsTouched();
      this.userPersonalDetails.controls['selectLanguage'].markAllAsTouched();
      this.userPersonalDetails.controls['firstname'].markAllAsTouched();
      this.userPersonalDetails.controls['lastname'].markAllAsTouched();
      this.userPersonalDetails.controls['salutation'].markAllAsTouched();
      validateStatus = false;
    }
    if ((this.userPersonalDetails.controls.invoicePhoneNumber.value === '') || (this.userPersonalDetails.controls.invoiceEmail.value === '') || (this.userPersonalDetails.controls.invoiceAddressLine1.value === '') || (this.userPersonalDetails.controls.invoiceCity.value === '') || (this.userPersonalDetails.controls.invoiceCountry.value === '') || (this.userPersonalDetails.controls.deliveryPhoneNumber.value === '') || (this.userPersonalDetails.controls.deliveryEmail.value === '') || (this.userPersonalDetails.controls.deliveryAddressLine1.value === '') || (this.userPersonalDetails.controls.deliveryCity.value === '') || (this.userPersonalDetails.controls.deliveryCountry.value === '') || (this.userPersonalDetails.controls.deliveryContactPerson.value === '') || (this.userPersonalDetails.controls.deliveryCountry.value === '') || (this.userPersonalDetails.controls.invoicePostalCode.value === '') || (this.userPersonalDetails.controls.deliveryPostalCode.value === '') || !(this.userPersonalDetails.controls.invoicePostalCode.value.length <= 24) || !(this.userPersonalDetails.controls.deliveryPostalCode.value.length <= 24)) {
      this.userPersonalDetails.controls['invoicePhoneNumber'].markAllAsTouched();
      this.userPersonalDetails.controls['invoiceEmail'].markAllAsTouched();
      // this.userPersonalDetails.controls['emailForInvoice'].markAllAsTouched();
      this.userPersonalDetails.controls['invoiceAddressLine1'].markAllAsTouched();
      this.userPersonalDetails.controls['invoiceCity'].markAllAsTouched();
      this.userPersonalDetails.controls['invoiceCountry'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryPhoneNumber'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryEmail'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryAddressLine1'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryCity'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryContactPerson'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryCountry'].markAllAsTouched();
      this.userPersonalDetails.controls['invoicePostalCode'].markAllAsTouched();
      this.userPersonalDetails.controls['deliveryPostalCode'].markAllAsTouched();
      validateStatus = false;
    }

    // if (this.userPersonalDetails.controls.vatIdentificationNumber.value === '' && this.vatMandatoryFieldStatus) {
    //   this.userPersonalDetails.controls['vatIdentificationNumber'].markAllAsTouched();
    //   validateStatus = false;
    // }
    return validateStatus;
  }

  onLoadFunctions() {
    this.userService.loadCountries();
    this.userService.loadCustomers();
    this.userService.loadRoles();
    this.userService.loadSearchFormFields();
    this.userService.loadLanguages();
    this.userService.loadCurrencies();

    /** to get country list from Redux State */
    this.getCountries();

    /** to get Roles list from Redux State */
    this.getRoles();

    /** to get Languages list from Redux State */
    this.getLanguages();

    /** to get Currencies list from Redux State */
    this.getCurrencies();
  }

  getRoles() {
    this.userService.getAllRoles().subscribe(
      data => {
        if (data) {
          this.optionsSelectRoles = [];
          this.optionsSelectRoles.push({ label: this.translate.instant('USERLIST.SELECT_ROLE'), value: '' });
          data.forEach(element => {
            const countryVal = {
              value: element.Code,
              label: element.RoleName,
            };
            this.optionsSelectRoles.push(countryVal);
          });

          if (history.state.data !== undefined && this.optionsSelectRoles !== undefined) {
            this.isEdit = true;
            this.editAddUserForm();
            this.getUserData();
            this.getShowAddressList();
            this.addUserButton = this.translate.instant('USERADDEDIT.UPDATE_USER');
            this.header = this.translate.instant('USERADDEDIT.EDIT_USER');
          } else {
            this.isEdit = false;
            this.addUserButton = this.translate.instant('USERADDEDIT.SUBMIT');
            this.header = this.translate.instant('USERADDEDIT.ADD_USER');
          }
        }
      });
  }

  getCountries() {
    this.userService.getAllCountries().subscribe(
      data => {
        if (data) {
          let optionsSelectCountries = [];
          optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          this.countriesList = [];
          this.countriesList = data;
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            optionsSelectCountries.push(langVal);
          });
          this.optionsSelectCountries = optionsSelectCountries;
        }
      });
  }

  getLanguages() {
    this.userHttpService.getAllLanguages(this.webshopDetail.CustomerID).subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectLanguages = [];
          this.optionsSelectLanguages.push({ label: this.translate.instant('USERADDEDIT.SELECT_LANGUAGE'), value: '' });
          data.forEach(element => {
            const countryVal = {
              value: element.LanguageID,
              label: element.Name,
            };
            this.optionsSelectLanguages.push(countryVal);
          });
          // const indexValue = _.findIndex(this.formFields.personalDetails, function (o) { return o.fieldName === 'LanguageID'; });
          // this.formFields.personalDetails[indexValue]['data'] = this.optionsSelectLanguages;
        }
      });
  }

  getCurrencies() {
    this.userHttpService.getCurrenciesById(this.webshopDetail.CustomerID).subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCurrencies = [];
          this.optionsSelectCurrencies.push({ label: this.translate.instant('USERBUDGETEDIT.SELECT_CURRENCY'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            this.optionsSelectCurrencies.push(langVal);
          });
        }
      });
  }


  usernameValidate(username) {
    if (username !== '' && username !== null) {
      const validateUserData = {
        username: username,
        customerID: (this.customerId !== null && this.customerId !== undefined &&
          this.customerId !== '') ? this.customerId : this.webshopDetail.CustomerID
      }

      this.userService.loadvalidateUser(validateUserData);

      try {
        if (username === '' || username === null) {
        } else {
          this.userService.validateUser().subscribe((data: any) => {
            if (data !== null && data !== '') {
              this.showUserValidation = false;
              if (Boolean(data) === false) {
                this.showUserValidation = true;

                if (this.isEdit && this.userName === username) {
                  this.isUserExists = false;
                } else {
                  this.isEnabledNextBtn = false;
                  this.isUserExists = true;
                }
              } else {
                this.isEnabledNextBtn = true;
                this.showUserValidation = false;
              }
            }

          })

        }
      } catch (error) {
        this.toast.error(error);
      }
    }
    else {
      this.showUserValidation = false;
    }


  }


  addUserSubmit() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      let imgCount = 0;
      if (this.uploadImage) {
        imgCount++;
      }
      if (this.uploadPanImage) {
        imgCount++;
      }
      if (this.uploadGstImage) {
        imgCount++;
      }
      if (this.uploadRocImage) {
        imgCount++;
      }
      if (imgCount === 4) {
        this.kycStatus = true;
      }
      const addUserData = {
        "Salutation": this.userPersonalDetails.value.salutation,
        "Name": this.userPersonalDetails.value.firstname + ' ' + this.userPersonalDetails.value.lastname,
        "FirstName": this.userPersonalDetails.value.firstname,
        "LastName": this.userPersonalDetails.value.lastname,
        "UserName": this.userPersonalDetails.value.userName,
        "JobPosition": this.userPersonalDetails.value.jobPosition,
        "Password": this.userPersonalDetails.value.Password,
        "CompanyName": this.userPersonalDetails.value.invoiceCompanyName.substring(0, 50),
        "CustomerID": (this.customerId !== null && this.customerId !== undefined &&
          this.customerId !== '') ? this.customerId : this.webshopDetail.CustomerID,
        "RoleCode": (this.userPersonalDetails.value.roles).toString(),
        "LanguageID": (this.userPersonalDetails.value.selectLanguage).toString(),
        "CurrencyID": this.userPersonalDetails.value.selectCurrency,
        "PhoneNumber": this.userPersonalDetails.value.invoicePhoneNumber,
        "Email": this.userPersonalDetails.value.invoiceEmail,
        "EmailForInvoices": this.userPersonalDetails.value.emailForInvoice,
        "Address": this.userPersonalDetails.value.invoiceAddressLine1,
        "Address2": this.userPersonalDetails.value.invoiceAddressLine2,
        "Address3": this.userPersonalDetails.value.invoiceAddressLine3,
        "City": this.userPersonalDetails.value.invoiceCity,
        //  "PostalCode": (this.userPersonalDetails.value.invoicePostalCode).toString(),
        "Country": this.userPersonalDetails.value.invoiceCountry,
        "DeliveryCompanyName": this.userPersonalDetails.value.deliveryCompanyName.substring(0, 50),
        "DeliveryPhoneNumber": this.userPersonalDetails.value.deliveryPhoneNumber,
        "DeliveryAddress": this.userPersonalDetails.value.deliveryAddressLine1,
        "DeliveryAddress2": this.userPersonalDetails.value.deliveryAddressLine2,
        "DeliveryAddress3": this.userPersonalDetails.value.deliveryAddressLine3,
        "DeliveryCity": this.userPersonalDetails.value.deliveryCity,
        //  "DeliveryPostalCode": (this.userPersonalDetails.value.deliveryPostalCode).toString(),
        "DeliveryCountry": this.userPersonalDetails.value.deliveryCountry,
        "DeliveryEmail": this.userPersonalDetails.value.deliveryEmail,
        "DeliveryContactPerson": this.userPersonalDetails.value.deliveryContactPerson,
        "DeliveryInstructions": this.userPersonalDetails.value.deliveryInstruction,
        "Department": this.userPersonalDetails.value.department,
        "isSameAsInvoiceAddress": this.userPersonalDetails.value.sameInvoiceAddress,
        "Comment": this.userPersonalDetails.value.comment,
        "ImageName": this.uploadImage,
        "PanImageName": this.uploadPanImage,
        "GSTImageName": this.uploadGstImage,
        "ROCImageName": this.uploadRocImage,
        "UserType": "U",
        "FirstLogin": true,
        "VAT": this.userPersonalDetails.value.vatIdentificationNumber,
        "EANNumber": (this.userPersonalDetails.value.invoiceCountry === 'DK') ? this.userPersonalDetails.value.eanNumber : '',
        "DiscountPercentage": (this.userPersonalDetails.value.discountPercentage !== '') ? parseFloat(this.userPersonalDetails.value.discountPercentage) : 0,
        "PaymentByInvoice": this.userPersonalDetails.value.paymentInvoice,
        "PaymentByCard": true,
        "isDeleted": false,
        "KYCStatus": this.kycStatus,
        "IsParentShop": this.isParentCustomer,
        "IsUserAdd": false
      }
      if ((this.userPersonalDetails.value.invoicePostalCode).toString() !== '') {
        addUserData['PostalCode'] = (this.userPersonalDetails.value.invoicePostalCode).toString();
      }
      if ((this.userPersonalDetails.value.deliveryPostalCode).toString() !== '') {
        addUserData['DeliveryPostalCode'] = (this.userPersonalDetails.value.deliveryPostalCode).toString();
      }
      // this.userService.loadAddUser(addUserData);
      this.showLoader = true;
      this.userHttpService.addUser(addUserData).subscribe((data: any) => {
        this.showLoader = false;

        if (data) {
          if (data.IsSuccess === 0) {
            this.loadForm();
            this.staticTabs.setActiveTab(1);
            this.toast.success(data.Messgae);
            sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
            this.router.navigate(['/admin-user-list'], { replaceUrl: false });
          } else if (data.IsSuccess === 1) {
            this.loadForm();
            this.staticTabs.setActiveTab(1);
            this.toast.success(data.Messgae);
            sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
            this.router.navigate(['/admin-user-list'], { replaceUrl: false });
          }
          else {
            //this.toast.error(data);
            if (data === 'User Registration Count exceed with this Customer. Please Upgrade your Plan or add additional fee for per User Registration') {
              this.userExceedModal.show();
            }
          }
        }

      },
        err => {
          let errMsg = '';
          this.showLoader = false;
          if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
            for (var key in err.error.ModelState) {
              errMsg += err.error.ModelState[key] + ' ';
            }
          } else {
            errMsg += err.error;
          }

          if (errMsg === 'User Registration Count exceed with this Customer. Please Upgrade your Plan or add additional fee for per User Registration') {
            this.userExceedModal.show();
            this.adduserData = addUserData;
            this.userHttpService.getSubcriptionValidityDetails(this.customerID).subscribe((data) => {
              this.planID = data[0].SubscriptionPlanID;
            })
          } else {
            this.toast.error(errMsg);
          }

        });
    }
  }

  updateAdditionalUser() {
    this.showLoader = true;
    const addUserData = JSON.parse(sessionStorage.getItem('adduser'));
    addUserData.IsUserAdd = true;
    this.userHttpService.addUser(addUserData).subscribe((data: any) => {
      this.showLoader = false;

      sessionStorage.removeItem('adduser');
      if (data) {
        if (data.IsSuccess === 0) {
          this.loadForm();
          this.staticTabs.setActiveTab(1);
          this.toast.success(data.Messgae);
          sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
          this.router.navigate(['/admin-user-list'], { replaceUrl: false });
        } else if (data.IsSuccess === 1) {
          this.loadForm();
          this.staticTabs.setActiveTab(1);
          this.toast.success(data.Messgae);
          sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
          this.router.navigate(['/admin-user-list'], { replaceUrl: false });
        }
        else {
          this.toast.error(data);
        }
      }

    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        sessionStorage.removeItem('adduser');
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }

        this.toast.error(errMsg);

      });
  }

  payAdditionalUser() {

    let url = window.location.origin;
    const paymentURL = "https://dotnet.constient.com/RewardKartPayWeb/AtomPayment/AtomPaymentRequest?OrderId=" + this.planID + "&IsRewardKart=Y&Domain=" + url + "&Pre=AddUser&ReturnURL=" + url + "&Email=" + this.userPersonalDetails.value.invoiceEmail + "&Mobile=" + this.userPersonalDetails.value.invoicePhoneNumber+'&customerID='+this.customerID;
    sessionStorage.setItem('adduser', JSON.stringify(this.adduserData));
    this.loginHttpService.getUser().subscribe(data => {
      this.cookieService.set('payment', data.password);
    });
    window.location.href = paymentURL;
  }

  checkUserStatus() {
    if (this.userPersonalDetails.value.userstatus !== undefined && this.userPersonalDetails.value.userstatus !== null && this.userPersonalDetails.value.userstatus !== '' && !this.userPersonalDetails.value.userstatus) {
      this.rejectModal.show();
    } else {
      this.updateAddUser();
    }
  }

  updateAddUser() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      const editData = history.state.data;
      const updateUserData = {
        "Salutation": this.userPersonalDetails.value.salutation,
        "Name": this.userPersonalDetails.value.firstname + ' ' + this.userPersonalDetails.value.lastname,
        "FirstName": this.userPersonalDetails.value.firstname,
        "LastName": this.userPersonalDetails.value.lastname,
        "UserName": this.userPersonalDetails.value.userName,
        "JobPosition": this.userPersonalDetails.value.jobPosition,
        "Password": this.userPersonalDetails.value.Password,
        "CompanyName": this.userPersonalDetails.value.invoiceCompanyName.substring(0, 50),
        "CustomerID": editData.CustomerID ? editData.CustomerID : this.webshopDetail.CustomerID,
        "RoleCode": this.userPersonalDetails.value.roles,
        "LanguageID": this.userPersonalDetails.value.selectLanguage,
        "CurrencyID": this.userPersonalDetails.value.selectCurrency,
        "PhoneNumber": this.userPersonalDetails.value.invoicePhoneNumber,
        "Email": this.userPersonalDetails.value.invoiceEmail,
        "EmailForInvoices": this.userPersonalDetails.value.emailForInvoice,
        "Address": this.userPersonalDetails.value.invoiceAddressLine1,
        "Address2": this.userPersonalDetails.value.invoiceAddressLine2,
        "Address3": this.userPersonalDetails.value.invoiceAddressLine3,
        "City": this.userPersonalDetails.value.invoiceCity,
        "PostalCode": this.userPersonalDetails.value.invoicePostalCode,
        "Country": this.userPersonalDetails.value.invoiceCountry,
        "DeliveryAddress": this.userPersonalDetails.value.deliveryAddressLine1,
        "DeliveryAddress2": this.userPersonalDetails.value.deliveryAddressLine2,
        "DeliveryAddress3": this.userPersonalDetails.value.deliveryAddressLine3,
        "DeliveryCity": this.userPersonalDetails.value.deliveryCity,
        "DeliveryPostalCode": this.userPersonalDetails.value.deliveryPostalCode,
        "DeliveryCountry": this.userPersonalDetails.value.deliveryCountry,
        "DeliveryEmail": this.userPersonalDetails.value.deliveryEmail,
        "Department": this.userPersonalDetails.value.department,
        "DeliveryContactPerson": this.userPersonalDetails.value.deliveryContactPerson,
        "DeliveryInstructions": this.userPersonalDetails.value.deliveryInstruction,
        "DeliveryCompanyName": this.userPersonalDetails.value.deliveryCompanyName.substring(0, 50),
        "isSameAsInvoiceAddress": this.userPersonalDetails.value.sameInvoiceAddress,
        "Comment": this.userPersonalDetails.value.comment,
        "ImageName": this.uploadImage ? this.uploadImage : "",
        "PanImageName": this.uploadPanImage ? this.uploadPanImage : "",
        "GSTImageName": this.uploadGstImage ? this.uploadGstImage : "",
        "ROCImageName": this.uploadRocImage ? this.uploadRocImage : "",
        "PreviousRoleCode": this.PreviousRoleCode,
        "IsRequireUsersApproved": this.editResponse !== undefined && this.userPersonalDetails.value.userstatus === 1 ? true : false,
        "UserType": "U",
        "FirstLogin": true,
        "VAT": this.userPersonalDetails.value.vatIdentificationNumber,
        "EANNumber": (this.userPersonalDetails.value.invoiceCountry === 'DK') ? this.userPersonalDetails.value.eanNumber : '',
        "DiscountPercentage": (this.userPersonalDetails.value.discountPercentage !== '') ? parseFloat(this.userPersonalDetails.value.discountPercentage) : 0,
        "PaymentByInvoice": this.userPersonalDetails.value.paymentInvoice,
        "PaymentByCard": true,
        "isDeleted": (this.userPersonalDetails.value.userstatus !== undefined && this.userPersonalDetails.value.userstatus !== null && this.userPersonalDetails.value.userstatus !== '' && !this.userPersonalDetails.value.userstatus) ? true : false,
        "DeliveryPhoneNumber": this.userPersonalDetails.value.deliveryPhoneNumber,
        "KYCStatus": this.kycStatus,
        "IsParentShop": this.isParentCustomer
      }
      const updateData = {
        userId: editData.UserID,
        updateData: updateUserData
      }

      let showToast = true;
      this.userHttpService.updateUser(updateData).subscribe((data: any) => {
        if (data) {
          if (showToast) {
            this.toast.success(this.translate.instant('USERADDEDIT.USER_UPDATE_SUCCESS'));
          }
          showToast = false;
          sessionStorage.setItem('userEditRedirect', JSON.stringify(true));
          this.router.navigate(['/admin-user-list'], { replaceUrl: false });
        }
      },
        err => {
          this.showLoader = false;
          let errMsg = '';
          this.showLoader = false;
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
          this.toast.error(errMsg);
        });
    }
  }

  sameInvoice() {
    this.postalCodeDeliveryCountryErrorCount = 0 // code to reset count for validate delivery postal code
    const sameInvoice = this.userPersonalDetails.value.sameInvoiceAddress;
    let getCountry = this.optionsSelectCountries.filter(data => data.value === this.userPersonalDetails.value.invoiceCountry)

    this.userPersonalDetails = this.formBuilder.group({
      selectCurrency: [this.userPersonalDetails.value.selectCurrency, Validators.required],
      selectLanguage: [this.userPersonalDetails.value.selectLanguage, Validators.required],
      invoiceCompanyName: [this.userPersonalDetails.value.invoiceCompanyName.substring(0, 50), Validators.required],
      invoicePhoneNumber: [this.userPersonalDetails.value.invoicePhoneNumber, [Validators.required, ControlValidationService.phoneNumberValidator]],
      invoiceEmail: [this.userPersonalDetails.value.invoiceEmail, [Validators.required, ControlValidationService.emailValidator]],
      emailForInvoice: [this.userPersonalDetails.value.emailForInvoice, [ControlValidationService.emailValidator]],
      invoiceAddressLine1: [this.userPersonalDetails.value.invoiceAddressLine1, Validators.required],
      invoiceAddressLine2: [this.userPersonalDetails.value.invoiceAddressLine2, Validators.required],
      invoiceAddressLine3: [this.userPersonalDetails.value.invoiceAddressLine3, Validators.required],
      invoiceCity: [this.userPersonalDetails.value.invoiceCity, Validators.required],
      invoicePostalCode: [this.userPersonalDetails.value.invoicePostalCode, Validators.required],
      invoiceCountry: [this.userPersonalDetails.value.invoiceCountry, Validators.required],
      sameInvoiceAddress: [this.userPersonalDetails.value.sameInvoiceAddress, Validators.required],
      deliveryCompanyName: [sameInvoice ? this.userPersonalDetails.value.invoiceCompanyName.substring(0, 50) : ''],
      deliveryPhoneNumber: [sameInvoice ? this.userPersonalDetails.value.invoicePhoneNumber : '', [Validators.required, ControlValidationService.phoneNumberValidator]],
      deliveryEmail: [sameInvoice ? this.userPersonalDetails.value.invoiceEmail : '', [Validators.required, ControlValidationService.emailValidator]],
      deliveryAddressLine1: [sameInvoice ? this.userPersonalDetails.value.invoiceAddressLine1 : '', Validators.required],
      deliveryAddressLine2: [sameInvoice ? this.userPersonalDetails.value.invoiceAddressLine2 : '', Validators.required],
      deliveryAddressLine3: [sameInvoice ? this.userPersonalDetails.value.invoiceAddressLine3 : '', Validators.required],
      deliveryCity: [sameInvoice ? this.userPersonalDetails.value.invoiceCity : '', Validators.required],
      deliveryPostalCode: [sameInvoice ? this.userPersonalDetails.value.invoicePostalCode : '', Validators.required],
      // selectCountryList: [sameInvoice ? this.userPersonalDetails.value.invoiceCountry : '', Validators.required],
      deliveryContactPerson: [sameInvoice ? this.userPersonalDetails.value.name : '', Validators.required],
      selectProductRoles: [this.userPersonalDetails.value.selectProductRoles, Validators.required],
      vatIdentificationNumber: [this.userPersonalDetails.value.vatIdentificationNumber],
      eanNumber: [this.userPersonalDetails.value.eanNumber],
      discountPercentage: [this.userPersonalDetails.value.discountPercentage, ControlValidationService.isDecimalCheck],
      comment: [this.userPersonalDetails.value.comment, Validators.required],
      paymentInvoice: [this.userPersonalDetails.value.paymentInvoice, Validators.required],
      draganddrop: [this.userPersonalDetails.value.draganddrop, Validators.required],
      draganddroppan: [this.userPersonalDetails.value.draganddroppan, Validators.required],
      draganddropgst: [this.userPersonalDetails.value.draganddropgst, Validators.required],
      draganddroproc: [this.userPersonalDetails.value.draganddroproc, Validators.required],
      salutation: [this.userPersonalDetails.value.salutation, Validators.required],
      firstname: [this.userPersonalDetails.value.firstname, Validators.required],
      lastname: [this.userPersonalDetails.value.lastname, Validators.required],
      department: [this.userPersonalDetails.value.department, Validators.required],
      jobPosition: [this.userPersonalDetails.value.jobPosition, Validators.required],
      confirmPassword: [this.userPersonalDetails.value.confirmPassword, [Validators.required]],
      Password: [this.userPersonalDetails.value.Password, [Validators.required, ControlValidationService.passwordValidator]],
      userName: [this.userPersonalDetails.value.userName, Validators.required],
      roles: [this.userPersonalDetails.value.roles, Validators.required],
      deliveryCountry: [sameInvoice ? this.userPersonalDetails.value.invoiceCountry : '', Validators.required],
      DeliveryPhoneNumber: [sameInvoice ? this.userPersonalDetails.value.invoicePhoneNumber : '', Validators.required],
      userstatus: [''],
      //deliveryInstruction: [sameInvoice ? this.userPersonalDetails.value.deliveryInstruction : '', Validators.required],
      deliveryInstruction: [sameInvoice ? this.userPersonalDetails.value.deliveryInstruction : this.editResponse.DeliveryInstructions],
    })
    this.validatePostal('all');
    // this.userPersonalDetails.controls['deliveryCompanyName'].disable();
    // this.userPersonalDetails.controls['deliveryAddressLine1'].disable();
    // this.userPersonalDetails.controls['deliveryAddressLine2'].disable();
    // this.userPersonalDetails.controls['deliveryAddressLine3'].disable();
    // this.userPersonalDetails.controls['deliveryCity'].disable();
    // this.userPersonalDetails.controls['deliveryPostalCode'].disable();
    // this.userPersonalDetails.controls['selectCountryList'].disable();
    // this.userPersonalDetails.controls['deliveryEmail'].disable();
    // this.userPersonalDetails.controls['deliveryPhoneNumber'].disable();
  }

  ngAfterViewInit(): void {
    if (this.inputElement != null) {
      this.renderer.listen(this.inputElement.nativeElement, 'paste', (event) => {
        // handle the event
      });
    }
  }

  ispasteFunc(_event: ClipboardEvent, formControlName) {
  }

  isPhoneNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode < 40 || charCode > 57) || (charCode === 42) || (charCode === 44)) {
      return false;
    }
    return true;
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode < 48 || charCode > 58)) {
      return false;
    }
    return true;
  }

  isDecimalNumber(evt) {
    const e = event || evt; // for trans-browser compatibility
    const unicode = e.which || e.keyCode;
    if (unicode < 46 || unicode > 57) {
      return false;
    }
    return true;
  }

  checkDiscountPercentageLength() {
    const fieldVal = this.userPersonalDetails.value.discountPercentage;
    if (Number(fieldVal) < 100) {
      return true;
    } else {
      let str = this.userPersonalDetails.value.discountPercentage;
      str = str.substring(0, 2);
      this.userPersonalDetails.patchValue({
        discountPercentage: str
      });
    }
  }

  deleteImg() {
    this.selectedImg = '';
    if (this.uploadImage !== '' && this.uploadImage !== null && this.editData.ImageURL !== undefined && this.editData.ImageURL !== '') {
      let getImg = this.editData.ImageURL.split('/');
      getImg = getImg[getImg.length - 1];
      this.uploadImage = getImg !== '' && getImg !== undefined ? getImg : '';
    }
  }

  getproductRoles(editCustomerId = null) {
    let customerID = '';
    if (editCustomerId !== null) {
      customerID = editCustomerId;
    } else {
      customerID = (this.customerId !== null && this.customerId !== undefined &&
        this.customerId !== '') ? this.customerId : this.webshopDetail.CustomerID;
    }
    try {
      this.userHttpService.getproductRoles(customerID).subscribe(res => {
        if (res) {
          const roleSelect = { label: this.translate.instant('USERLIST.SELECT_PRODUCT_ROLE'), value: '' };
          this.productRoles = res;
          this.productRoles.forEach(element => {
            element.label = element.Name;
            element.value = element.ProductRoleID
          });
          this.productRoles.unshift(roleSelect);
        }
      });
    } catch (error) {
      this.toast.error(error);
    }
  }

  addProductRoles() {
    const request = {
      roleid: this.userPersonalDetails.value.selectProductRoles,
      userId: this.editData.UserID
    }
    this.userHttpService.addProductRoles(request).subscribe((res: any) => {
      if (res) {
        this.toast.success(this.translate.instant('USERADDEDIT.ROLEADD_USER'));
        // this.getUserData();
        this.userHttpService.getUserData(this.editData.UserID).subscribe(res => {
          if (res) {
            this.editResponse.ProductRoles = res['ProductRoles'];
            // this.editData.DeliveryAddresses = this.editResponse.DeliveryAddresses;
            // this.editAddUserForm();
          }
        })
      }
      else {
        this.toast.error(this.translate.instant('USERADDEDIT.ROLEADD_ERROR'));
      }
    })
  }

  getUserData() {

    this.userHttpService.getUserData(this.editData.UserID).subscribe(res => {
      if (res) {
        this.editResponse = res;
        this.editData.DeliveryAddresses = this.editResponse.DeliveryAddresses;
        this.editAddUserForm();
      }
    })
  }

  deleteProductRole(roleid) {
    this.deleteRequest = {
      roleid: roleid.ProductRoleID,
      userId: this.editData.UserID
    }
    this.deleteModal.show();
  }

  getDeleteStatus(status) {
    if (status) {
      this.userHttpService.deleteProductRole(this.deleteRequest).subscribe(res => {
        if (res) {
          this.toast.success(this.translate.instant('USERADDEDIT.ROLE_DELETE_SUCCESS'));
          this.userHttpService.getUserData(this.editData.UserID).subscribe(res => {
            if (res) {
              this.editResponse.ProductRoles = res['ProductRoles'];
              // this.editData.DeliveryAddresses = this.editResponse.DeliveryAddresses;
              // this.editAddUserForm();
            }
          })
        }
      })
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }

  getStatus(status) {
    this.userPersonalDetails.patchValue({
      userstatus: status.value
    })
  }

  ValidateEmail($event) {
    var email = this.userPersonalDetails.value.emailForInvoice;
    var lblError = document.getElementById("lblError");
    lblError.innerHTML = "";
    var expr = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,2}$/;
    if (!expr.test(email)) {
      lblError.innerHTML = '(' + this.translate.instant('USERADDEDIT.INVALID_EMAIL') + ')';
    }
  }

  getShowAddressList() {
    this.userHttpService.showAddressList(this.editData.UserID).subscribe((data: any) => {
      if (data) {
        this.usershowAddressList = data.DeliveryAddresses;
      }
    });
  }

  createNewPassword() {
    try {
      const postParameters = {
        'autopass': this.oldpassword,
        'newpass': this.userPersonalDetails.value.Password,
        'confpass': this.userPersonalDetails.value.confirmPassword,
        'username': this.userPersonalDetails.value.userName,
        'CustomerID': this.customerId
      }
      var subject = new Subject<any>();
      this.userHttpService.newPassword(postParameters).subscribe(
        (data) => {
          if (data) {
            this.userPersonalDetails = this.formBuilder.group({
              'Password': ['', [Validators.required, ControlValidationService.passwordValidator]],
              'confirmPassword': ['', [Validators.required]],
            });
            subject.next("Password updated successfully.");
          }
        }, error => {
          if (error.status === 404) {
            subject.next('Old password incorrect');
          } else {
            subject.next(error);
          }
        });
    } catch (error) {
      if (error.status === 404) {
        subject.next('Old password incorrect');
      } else {
        subject.next(error);
      }

    }
  }
}
