import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SettingsHttpService } from '../services/settings.http.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { resolve } from 'dns';
import { ControlValidationService } from 'src/app/shared/control-validation/control-validation.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-footer-link-settings',
  templateUrl: './footer-link-settings.component.html',
  styleUrls: ['./footer-link-settings.component.scss']
})
export class FooterLinkSettingsComponent implements OnInit {
  languageOption: any = [];
  selectedLanguageId: number = 1;
  tempLanguageID: number = 1;
  selectedLang: any = 'English';
  selectedLangCode = 'en';
  footerLinkSetting: FormGroup;
  translateForm: any = [];
  items: FormArray;
  noDataFound: boolean = false;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  totalCount: any;
  showLoader: boolean = false
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  footerDataID: number;
  footerDataList: any;
  webshopDetail: any;
  footerUpdated: boolean;
  formarray: any;
  multiLanguageData: any = [];
  multiLangData: any;
  childFooter: boolean;
  translateItems: any = [];
  footerData: any;
  currentFooterData: any;
  multiLanguageFooterData: any = [];
  translateRequest: any = [];
  multiRequestData: any;
  statusError: any;
  errorMsg: string;
  showValidation: boolean = true;
  showMsg: boolean;

  constructor(private apiService: ApiService,
    private settingsHttpService: SettingsHttpService,
    public toast: ToastService,
    public formBuilder: FormBuilder,
    public translate: TranslateService) {
    translate.setDefaultLang('en');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      } else {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }

    }
  }

  ngOnInit() {
    this.removeFooterSession()
    this.getAllLanguages()
    this.setDefaultLang();
  }

  getAllLanguages() {
    let getlang: any = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    console.log("getLang" + getlang);
    getlang.forEach(lang => {
      let langData: any = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
    })
  }
  setDefaultLang() {
    this.translate.setDefaultLang('en');
    this.selectedLangCode = 'en';
    this.selectedLang = 'English';
    this.selectedLanguageId = 1;
    this.tempLanguageID = 1;
    this.loadForm();
  }

  loadForm() {
    this.footerLinkSetting = this.formBuilder.group({
      footerLinkDescription: ['', Validators.required],
      footerLinkURL: ['', Validators.compose([Validators.required, ControlValidationService.URLPattern])],
      items: this.formBuilder.array([])
    });
    this.getFooterLinkList()
    this.getTranslation()
  }
  getTranslation() {
    let getlang: any = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.translateForm = []
    this.translateItems = [];
    this.translateRequest = [];
    let translationFieldJson: any = [
      {
        formcontrolname: 'footerLinkDescription',
        label: 'Footer Description',
        PropertyName: 'FooterLinkDescription'
      },
      {
        formcontrolname: 'footerLinkURL',
        label: 'Footer Link',
        PropertyName: 'FooterLinkURL'
      }
    ];
    getlang.forEach(lang => {
      translationFieldJson.forEach(jsonField => {
        let formJson: any = {
          formcontrolname: jsonField.formcontrolname,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName,
        }
        this.translateForm.push(formJson)
      });

      translationFieldJson.forEach(jsonField => {
        let formJson: any = {
          Action: 'Insert',
          PropertyValue: '',
          ObjectType: 'FooterLinks',
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName,
        }
        this.translateRequest.push(formJson)
      });

      translationFieldJson.forEach(jsonField => {
        let formJson: any = {
          Action: 'Insert',
          PropertyValue: '',
          ObjectType: "ChildCustomerFooterLinks",
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName,
        }
        this.translateItems.push(formJson)
      });


    })

  }

  getFooterLinkList() {
    this.clearFormItems();
    this.items = this.footerLinkSetting.get('items') as FormArray;
    this.showLoader = true;
    let queryRequest: any = {
      translations: true,
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      CustomerID: this.webshopDetail.CustomerID,
      langCode: this.selectedLangCode
    };
    this.settingsHttpService.getFooterLinksList(queryRequest).subscribe(data => {
      if (data && data['ItemsCollection']) {
        this.footerDataList = data['ItemsCollection'];
        this.showLoader = false;
        this.noDataFound = data['ItemsCollection'].length == 0 ? true : false;
        let translationData: any;
        data['ItemsCollection'].forEach(element => {
          this.items.push(this.createItem(element, element.FooterLinkID, this.selectedLanguageId));
          translationData = element.Translations.filter(x => x.LanguageID == this.selectedLanguageId)
        });
        // For get list to add multiple language footer data 
        this.currentFooterData = {
          id: this.selectedLanguageId,
          description: this.footerLinkSetting.value.footerLinkDescription,
          link: this.footerLinkSetting.value.footerLinkURL,
        }
        let isAlreadyData: any = []
        isAlreadyData = this.multiLanguageFooterData.filter(x => x.id == this.selectedLanguageId)
        if (isAlreadyData.length > 0) {
          this.footerLinkSetting.controls['footerLinkDescription'].patchValue(isAlreadyData[0].description)
          this.footerLinkSetting.controls['footerLinkURL'].patchValue(isAlreadyData[0].link)
        } else {
          this.multiLanguageFooterData.push(this.currentFooterData)
        }
        // For get list to update multiple language footer data 
        this.formarray = this.footerLinkSetting.controls['items'].value;
        this.multiLangData = {
          id: this.selectedLanguageId,
          langCode: this.translateRequest.filter(x => x.LanguageID == this.selectedLanguageId),
          items: this.formarray
        }
        let isAlready: any = []
        isAlready = this.multiLanguageData.filter(x => x.id == this.selectedLanguageId)
        if (isAlready.length > 0) {
          this.footerLinkSetting.controls['items'].patchValue(isAlready[0].items)
        } else {
          this.multiLanguageData.push(this.multiLangData)
        }
        //For pagination
        this.totalCount = data['TotalItemsCount'];
        let getPageList: any = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      } else {
        this.showLoader = false;
        this.noDataFound = true;
      }
    }, err => {
      this.showLoader = false;
      if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
        if (err.error.ModelState.ProductColor !== null && err.error.ModelState.ProductColor !== undefined) {
          this.toast.error(err.error.ModelState.ProductColor);
        }
      }
    });
  }


  createItem(element, id?, lang?): FormGroup {
    let getSelectedLangData: any = [];
    let descriptionValue: any;
    let urlValue: any;
    let descTranID: any;
    let urlTransID: any
    let descPropertyName: any;
    let urlPropertyName: any;
    let langcode: any;
    let langID: any;
    getSelectedLangData = element.Translations.filter(res => res.LanguageID == lang);
    getSelectedLangData.forEach(res => {
      if (res.PropertyName == 'FooterLinkDescription') {
        descriptionValue = res.PropertyValue;
        descTranID = res.TranslationID;
        descPropertyName = 'FooterLinkDescription'
        langcode = res.LanguageCode
        langID = res.LanguageID
      } else {
        urlValue = res.PropertyValue;
        urlTransID = res.TranslationID;
        urlPropertyName = 'FooterLinkURL'
      }
    })
    return this.formBuilder.group({
      itemDescription: [descriptionValue || ''],
      itemLinkURL: [urlValue || ''],
      id: [id || ''],
      descTranID: [descTranID || ''],
      urlTransID: [urlTransID || ''],
      descPropertyName: [descPropertyName || ''],
      langcode: [langcode || ''],
      langID: [langID || ''],
      urlPropertyName: [urlPropertyName || ''],
      footer: [element || '']
    });
  }
  clearFormItems() {
    if (this.footerLinkSetting) {
      const control = <FormArray>this.footerLinkSetting.controls['items'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i)
      }
    }
  }

  createFooterLink() {
    let getRequestTranslations: any = [];
    let request: any;
    let data: any;
    let requestData: any;
    let control: any;
    control = {
      value: this.footerLinkSetting.controls['footerLinkURL'].value
    }
    let validate: any = ControlValidationService.URLPattern(control);
    if (this.footerLinkSetting.invalid && validate == null || control.value == '') {
      this.showLoader = false
      this.toast.error("Please fill all the fields")
    } else if (validate != null && validate.invalidURL == true) {
      this.showLoader = false
      this.toast.error("Please enter valid url")
    } else {
      const { value } = this.footerLinkSetting;
      this.multiLanguageFooterData.forEach(r => {
        if (r.description) {
          data = {
            LanguageID: r.id,
            PropertyName: 'FooterLinkDescription',
            PropertyValue: r.description,
          }
          getRequestTranslations.push(data);
        }
        if (r.link) {
          data = {
            LanguageID: r.id,
            PropertyName: 'FooterLinkURL',
            PropertyValue: r.link,
          }
          getRequestTranslations.push(data);
        }
      })
      this.multiRequestData = getRequestTranslations;
      let op = this.multiRequestData.map((e, i) => {
        let temp = this.translateRequest.find(element => element.LanguageID == e.LanguageID && element.PropertyName == e.PropertyName)
        if (temp.PropertyName == 'FooterLinkDescription') {
          temp.PropertyValue = e.PropertyValue;
        } else if (temp.PropertyName == 'FooterLinkURL') {
          temp.PropertyValue = e.PropertyValue;
        }
      })
      request = this.translateRequest;
      requestData = {
        "Action": "Insert",
        "CustomerID": this.webshopDetail.CustomerID,
        "Translations": request
      }
      this.showLoader = true
      this.settingsHttpService.addFooterLinks(requestData).subscribe(data => {
        this.footerLinkSetting.reset();
        this.toast.success('Footer link added successfully');
        this.multiLanguageData = [];
        this.multiLanguageFooterData = [];
        sessionStorage.removeItem('unsavedData')
        this.getCurrentLang(1)
      }, err => {
        this.showLoader = false;
        if (err.error.ModelState) {
          if (err.error.ModelState['footerLink.Error']) {
            this.statusError = err.error.ModelState['footerLink.Error'][0];
            this.toast.error(this.statusError);
          } else if (err.error.ModelState['footerLink.Translations.FooterLinkDescription']) {
            this.statusError = err.error.ModelState['footerLink.Translations.FooterLinkDescription'][0];
            this.toast.error(this.statusError);
          }
        }
      })
    }
  }

  updateFooterLink(currentFooter) {
    console.log("FooterLink Test 1");
    let control: any;
    console.log("FooterLink Test 2");
    control = {
      value: currentFooter.value.itemLinkURL
    }
    console.log("FooterLink Test 3");
    let validate: any = ControlValidationService.URLPattern(control);
    // if (validate != null && validate.invalidURL == true) {
    // this.showLoader = false
    // this.toast.error("Please enter valid url")
    // } else {
    console.log("FooterLink Test 4");
    let requestupdateData: any = {};
    console.log("FooterLink Test 5");
    let getCurrentFooterData: any;
    console.log("FooterLink Test 6");
    getCurrentFooterData = currentFooter.value.footer;
    console.log("FooterLink Test 7");
    let requestTranslations: any = [];
    console.log("FooterLink Test 8");
    let data: any;
    console.log("FooterLink Test 9");
    let multipleData: any = [];
    console.log("FooterLink Test 10");
    this.multiLanguageData.forEach(x => {
      console.log("FooterLink Test 11");
      x.items.forEach(res => {
        console.log("FooterLink Test 12");
        if (res.id == getCurrentFooterData.FooterLinkID && res.descTranID != '' && res.urlTransID != '') {
          console.log("FooterLink Test 13");
          data = {
            LanguageID: res.langID,
            LanguageCode: res.langcode,
            ObjectType: "FooterLinks",
            PropertyName: res.descPropertyName,
            TranslationID: res.descTranID,
            PropertyValue: res.itemDescription,
            Action: "Update"
          }
          console.log("FooterLink Test 14");
          requestTranslations.push(data)
          console.log("FooterLink Test 15");
          data = {
            LanguageID: res.langID,
            LanguageCode: res.langcode,
            ObjectType: "FooterLinks",
            PropertyName: res.urlPropertyName,
            TranslationID: res.urlTransID,
            PropertyValue: res.itemLinkURL,
            Action: "Update"
          }
          console.log("FooterLink Test 16");
          requestTranslations.push(data)
          console.log("FooterLink Test 17");
        } else if (res.id == getCurrentFooterData.FooterLinkID && res.descTranID == '' && res.urlTransID == '') {
          console.log("FooterLink Test 18");
          data = {
            LanguageID: x.id,
            LanguageCode: x.langCode[0].LanguageCode,
            ObjectType: "FooterLinks",
            PropertyName: 'FooterLinkDescription',
            PropertyValue: res.itemDescription,
            Action: "Insert"
          }
          console.log("FooterLink Test 19");
          requestTranslations.push(data)
          console.log("FooterLink Test 20");
          data = {
            LanguageID: x.id,
            LanguageCode: x.langCode[0].LanguageCode,
            ObjectType: "FooterLinks",
            PropertyName: 'FooterLinkURL',
            PropertyValue: res.itemLinkURL,
            Action: "Insert"
          }
          console.log("FooterLink Test 21");
          requestTranslations.push(data)
          console.log("FooterLink Test 22");
        }
      })
    })
    console.log("FooterLink Test 23");
    multipleData.concat(requestTranslations)
    console.log("FooterLink Test 24");
    requestupdateData = {
      "Action": "Update",
      "FooterLinkID": currentFooter.value.id,
      "CustomerID": this.webshopDetail.CustomerID,
      "Translations": multipleData.concat(requestTranslations)
    }
    console.log("FooterLink Test 25");
    this.footerUpdated = true;
    console.log("FooterLink Test 26");
    this.settingsHttpService.addFooterLinks(requestupdateData).subscribe((data) => {
      console.log("FooterLink Test 27");
      if (data !== null) {
        this.toast.success('Footer link updated successfully');
        sessionStorage.removeItem('currentData')
        this.multiLanguageData = [];
        this.getCurrentLang(1);
        this.footerUpdated = false;
      }
    }, err => {
      this.showLoader = false;
      this.footerUpdated = false;
      if (err.error.ModelState) {
        if (err.error.ModelState['footerLink.Error']) {
          this.statusError = err.error.ModelState['footerLink.Error'][0];
          this.toast.error(this.statusError);
        } else if (err.error.ModelState['footerLink.Translations.FooterLinkDescription']) {
          this.statusError = err.error.ModelState['footerLink.Translations.FooterLinkDescription'][0];
          this.toast.error(this.statusError);
        } else {
          this.statusError = err.error.ModelState['footerLink.Translations.FooterLinkURL'][0];
          this.toast.error(this.statusError);
        }
      }
    });
  }


  changeValue(event, i, value) {
    let current: any = [];
    current = this.multiLanguageData.filter(x => x.id == this.selectedLanguageId)
    if (current && value == 'name') {
      current[0].items[i].itemDescription = event.target.value;
    } else if (current && value == 'url') {
      current[0].items[i].itemLinkURL = event.target.value;
    }
  }

  changeFooter(event, data) {
    let current: any = [];
    current = this.multiLanguageFooterData.filter(x => x.id == this.selectedLanguageId)
    if (data == 'description') {
      current[0].description = event.target.value;
    } else if (data == 'link') {
      current[0].link = event.target.value;
    }
  }
  showValidate(value) {
    if (value != '' && this.footerLinkSetting.controls['footerLinkURL'].invalid && (this.footerLinkSetting.controls['footerLinkURL'].dirty || this.footerLinkSetting.controls['footerLinkURL'].touched)) {
      this.showValidation = true;
      this.showMsg = true;
    } else {
      this.showValidation = false;
      this.showMsg = false;
    }
  }
  focusValidate(value) {
    if (value == '' || value == undefined) {
      this.showValidation = false;
      this.showMsg = false;
    }
  }
  getCurrentLang(langid) {
    this.clearFormItems()
    this.selectedLanguageId = langid;
    this.tempLanguageID = langid;
    this.selectedLang = langid;
    this.showMsg = false;
    this.loadForm();
    // this.getFooterLinkList()
    // this.getTranslation()
  }


  deleteConfirmation(footerLinkData) {
    this.footerDataID = footerLinkData.value.footer.FooterLinkID;
    this.deleteModal.show();
  }

  deleteFooterLink() {
    let requestData = {
      id: this.footerDataID
    };
    this.settingsHttpService.deleteFooterLink(requestData).subscribe((data) => {
      this.deleteModal.hide();
      this.toast.success('Footer link deleted successfully');
      this.multiLanguageData = [];
      this.getCurrentLang(1);
    });
  }

  selected(selected) {
    this.setPageSizeOptions(10);
  }
  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.getFooterLinkList();
  }
  pageChanged(page) {
    this.selectedpage = page;
    this.multiLanguageData = []
    this.getFooterLinkList();
  }

  removeFooterSession() {
    sessionStorage.removeItem('form')
    sessionStorage.removeItem('updateData')
    sessionStorage.removeItem('selectedLanguageData');
    sessionStorage.removeItem('currentData')
    sessionStorage.removeItem('unsavedData');

  }
  ngOnDestroy() {
    sessionStorage.removeItem('unsavedData');
    sessionStorage.removeItem('selectedLanguageData');
    sessionStorage.removeItem('updateData')
    sessionStorage.removeItem('currentData')

  }
  onDrop(event: CdkDragDrop<string[]>) {
    const dragEvent = { CurrentPosition: event.previousIndex + 1, DesiredPosition: event.currentIndex + 1 };

    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.footerLinkSetting.get('items')['controls'], event.previousIndex, event.currentIndex);
      let requestData = {
        CurrentPosition: event.previousIndex + 1,
        DesiredPosition: event.currentIndex + 1,
        CustomerID: this.webshopDetail.CustomerID
      };
      this.settingsHttpService.chaneOrderFooterLink(requestData).subscribe((data: any) => {
        if (data) {
          this.toast.success(data);
          this.multiLanguageData = [];
          this.getCurrentLang(this.selectedLanguageId);
        }
      });
    }
  }
}
