import { Product } from '../models/index';
import { ProductActions } from '../actions/index';

export interface ProductState {
    products: Product[];
    productDetailsSuccess: any;
    saveProductSuccess: any;
    updateProductSuccess: any;
    deleteProductSuccess: any;
    supplierListSuccess: any;
    customerInfoSuccess: any;
    undersupplierListSuccess: any;
    productColorListSuccess: any;
    productAllColorListSuccess: any;
    productParentColorListSuccess: any;
    productAllGovtCostsListSuccess: any;
    getAllProductAdditionalCostsListSuccess: any;
    updateProductColorSuccess: any;
    updateProductGovernmentalCostsSuccess: any;
    updateProductAdditionalCostsSuccess: any;
    updateAllProductAdditionalCostsSuccess: any;
    updateProductLogoPositionSuccess: any;
    updateProductSizeSuccess: any;
    addProductColorSuccess: any;
    addProductGovernmentalCostsSuccess: any;
    addProductAdditionalCostsSuccess: any;
    getProductGovernmentalCostsSuccess: any;
    addProductSizeSuccess: any;
    addProductLogoPositionSuccess: any;
    addProductPropertySuccess: any;
    deleteProductColorSuccess: any;
    deleteProductGovernmentalCostsSuccess: any;
    deleteProductAdditionalCostsSuccess: any;
    deleteProductSizeSuccess: any;
    deleteProductLogoPositionsSuccess: any;
    deleteProductUnitSuccess: any;
    deleteProductPropertySuccess: any;
    productCategory: any;
    productProperties: any;
    addProductUnits: any;
    updateProductUnits: any;
    productUnits: any;
    allProductUnits: any;
    productSuppliers: any;
    transportationOriginCountries: any;
    productCurrency: any;
    productAdditionalCostTypes: any;
    createProduct: any;
    manualValidate: any;
    checkProductId: any;
    productAllSizeListSuccess: any;
    productAllLogoListSuccess: any;
    productEnSizeListSuccess: any;
    units: any;
    logoType: any;
    logoTypeAll: any;
    logoArea: any;
    changevisibility: any,
    maxLogoArea: any,
    maxLogoAreadel: any,
    maxLogoAreaupdate: any,
    handlingCost: any;
    customerProduct: any;
}

export const initialState: ProductState = {
    products: null,
    productDetailsSuccess: null,
    saveProductSuccess: null,
    updateProductSuccess: null,
    deleteProductSuccess: null,
    supplierListSuccess: null,
    customerInfoSuccess: null,
    undersupplierListSuccess: null,
    productColorListSuccess: null,
    productAllColorListSuccess: null,
    productParentColorListSuccess: null,
    productAllGovtCostsListSuccess: null,
    getAllProductAdditionalCostsListSuccess: null,
    addProductColorSuccess: null,
    addProductGovernmentalCostsSuccess: null,
    addProductAdditionalCostsSuccess: null,
    getProductGovernmentalCostsSuccess: null,
    addProductSizeSuccess: null,
    addProductLogoPositionSuccess: null,
    addProductPropertySuccess: null,
    updateProductColorSuccess: null,
    updateProductGovernmentalCostsSuccess: null,
    updateProductAdditionalCostsSuccess: null,
    updateAllProductAdditionalCostsSuccess: null,
    updateProductLogoPositionSuccess: null,
    updateProductSizeSuccess: null,
    deleteProductColorSuccess: null,
    deleteProductGovernmentalCostsSuccess: null,
    deleteProductAdditionalCostsSuccess: null,
    deleteProductSizeSuccess: null,
    deleteProductLogoPositionsSuccess: null,
    deleteProductUnitSuccess: null,
    deleteProductPropertySuccess: null,
    productCategory: null,
    productProperties: null,
    addProductUnits: null,
    updateProductUnits: null,
    productUnits: null,
    allProductUnits: null,
    productSuppliers: null,
    transportationOriginCountries: null,
    productCurrency: null,
    productAdditionalCostTypes: null,
    createProduct: null,
    manualValidate: null,
    checkProductId: null,
    productAllSizeListSuccess: null,
    productAllLogoListSuccess: null,
    productEnSizeListSuccess: null,
    units: null,
    logoType: null,
    logoTypeAll: null,
    logoArea: null,
    changevisibility: null,
    maxLogoArea: null,
    maxLogoAreadel: null,
    maxLogoAreaupdate: null,
    handlingCost: null,
    customerProduct: null
};

export function productReducers(state: ProductState = initialState, action: ProductActions.All) {
    switch (action.type) {

        case ProductActions.LOAD_PRODUCTS_SUCCESS:
            return Object.assign({}, state, { products: action.payload });

        case ProductActions.SAVE_PRODUCT_SUCCESS:
            return Object.assign({}, state, { saveProductSuccess: action.payload });
        case ProductActions.UPDATE_PRODUCT_SUCCESS:
            return Object.assign({}, state, { updateProductSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_SUCCESS:
            return Object.assign({}, state, { deleteProductSuccess: action.payload });

        case ProductActions.LOAD_SUPPLIERS_SUCCESS:
            return Object.assign({}, state, { supplierListSuccess: action.payload });
        case ProductActions.LOAD_CUSTOMER_INFO_SUCCESS:
            return Object.assign({}, state, { customerInfoSuccess: action.payload });
        case ProductActions.LOAD_UNDER_SUPPLIERS_SUCCESS:
            return Object.assign({}, state, { undersupplierListSuccess: action.payload });

        case ProductActions.LOAD_PRODUCT_COLORS_SUCCESS:
            return Object.assign({}, state, { productColorListSuccess: action.payload });

        case ProductActions.LOAD_ALL_PRODUCT_COLORS_SUCCESS:
            return Object.assign({}, state, { productAllColorListSuccess: action.payload });

        case ProductActions.LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS:
            return Object.assign({}, state, { productAllGovtCostsListSuccess: action.payload });

        case ProductActions.LOAD_ALL_PRODUCT_ADDITIONAL_COSTS_SUCCESS:
            return Object.assign({}, state, { getAllProductAdditionalCostsListSuccess: action.payload });

        case ProductActions.LOAD_PARENT_PRODUCT_COLORS_SUCCESS:
            return Object.assign({}, state, { productParentColorListSuccess: action.payload });

        case ProductActions.ADD_PRODUCT_COLOR_SUCCESS:
            return Object.assign({}, state, { addProductColorSuccess: action.payload });

        case ProductActions.ADD_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS:
            return Object.assign({}, state, { addProductGovernmentalCostsSuccess: action.payload });

        case ProductActions.ADD_PRODUCT_ADDITIONAL_COSTS_SUCCESS:
            return Object.assign({}, state, { addProductAdditionalCostsSuccess: action.payload });

        case ProductActions.LOAD_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS:
            return Object.assign({}, state, { getProductGovernmentalCostsSuccess: action.payload });

        case ProductActions.ADD_PRODUCT_SIZE_SUCCESS:
            return Object.assign({}, state, { addProductSizeSuccess: action.payload });

        case ProductActions.ADD_PRODUCT_LOGO_POSITION_SUCCESS:
            return Object.assign({}, state, { addProductLogoPositionSuccess: action.payload });

        case ProductActions.ADD_PRODUCT_PROPERTY_SUCCESS:
            return Object.assign({}, state, { addProductPropertySuccess: action.payload });

        case ProductActions.UPDATE_PRODUCT_COLOR_SUCCESS:
            return Object.assign({}, state, { updateProductColorSuccess: action.payload });

        case ProductActions.UPDATE_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS:
            return Object.assign({}, state, { updateProductGovernmentalCostsSuccess: action.payload });

        case ProductActions.UPDATE_PRODUCT_ADDITIONAL_COSTS_SUCCESS:
            return Object.assign({}, state, { updateProductAdditionalCostsSuccess: action.payload });

        case ProductActions.UPDATE_PRODUCT_SIZE_SUCCESS:
            return Object.assign({}, state, { updateProductSizeSuccess: action.payload });

        case ProductActions.UPDATE_PRODUCT_LOGO_POSITION_SUCCESS:
            return Object.assign({}, state, { updateProductLogoPositionSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_COLOR_SUCCESS:
            return Object.assign({}, state, { deleteProductColorSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS:
            return Object.assign({}, state, { deleteProductGovernmentalCostsSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_ADDITIONAL_COSTS_SUCCESS:
            return Object.assign({}, state, { deleteProductAdditionalCostsSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_SIZE_SUCCESS:
            return Object.assign({}, state, { deleteProductSizeSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_LOGO_POSITION_SUCCESS:
            return Object.assign({}, state, { deleteProductLogoPositionsSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_UNIT_SUCCESS:
            return Object.assign({}, state, { deleteProductUnitSuccess: action.payload });

        case ProductActions.DELETE_PRODUCT_PROPERTY_SUCCESS:
            return Object.assign({}, state, { deleteProductPropertySuccess: action.payload });

        case ProductActions.LOAD_PRODUCT_DETAILS_SUCCESS:
            return Object.assign({}, state, { productDetailsSuccess: action.payload });

        case ProductActions.LOAD_PRODUCTS_CATEGORY_SUCCESS:
            return Object.assign({}, state, { productCategory: action.payload });

        case ProductActions.LOAD_PRODUCTS_PROPERTIES_SUCCESS:
            return Object.assign({}, state, { productProperties: action.payload });

        case ProductActions.ADD_PRODUCT_UNITS_SUCCESS:
            return Object.assign({}, state, { addProductUnits: action.payload });

        case ProductActions.UPDATE_PRODUCT_UNITS_SUCCESS:
            return Object.assign({}, state, { updateProductUnits: action.payload });

        case ProductActions.LOAD_PRODUCT_UNITS_SUCCESS:
            return Object.assign({}, state, { productUnits: action.payload });

        case ProductActions.LOAD_ALL_PRODUCT_UNITS_SUCCESS:
            return Object.assign({}, state, { allProductUnits: action.payload });

        case ProductActions.LOAD_PRODUCT_SUPPLIERS_SUCCESS:
            return Object.assign({}, state, { productSuppliers: action.payload });

        case ProductActions.LOAD_PRODUCT_TOC_SUCCESS:
            return Object.assign({}, state, { transportationOriginCountries: action.payload });

        case ProductActions.LOAD_PRODUCT_CURRENCY_SUCCESS:
            return Object.assign({}, state, { productCurrency: action.payload });

        case ProductActions.LOAD_PRODUCT_ADDITIONAL_COST_TYPES_SUCCESS:
            return Object.assign({}, state, { productAdditionalCostTypes: action.payload });

        case ProductActions.LOAD_PRODUCT_CREATE_SUCCESS:
            return Object.assign({}, state, { createProduct: action.payload });

        case ProductActions.LOAD_MANUALID_VALIDATION_SUCCESS:
            return Object.assign({}, state, { manualValidate: action.payload });

        case ProductActions.LOAD_PRODUCT_SIZES_SUCCESS:
            return Object.assign({}, state, { productAllSizeListSuccess: action.payload });

        case ProductActions.LOAD_PRODUCT_LOGO_POSITION_SUCCESS:
            return Object.assign({}, state, { productAllLogoListSuccess: action.payload });

        case ProductActions.LOAD_EN_PRODUCT_SIZES_SUCCESS:
            return Object.assign({}, state, { productEnSizeListSuccess: action.payload });

        case ProductActions.CHECK_PRODUCT_CATEGORY_SUCCESS:
            return Object.assign({}, state, { checkProductId: action.payload });

        case ProductActions.LOAD_UNITS_SUCCESS:
            return Object.assign({}, state, { units: action.payload });

        case ProductActions.LOAD_LOGOTYPES_SUCCESS:
            return Object.assign({}, state, { logoType: action.payload });

        case ProductActions.LOAD_LOGOTYPESAll_SUCCESS:
            return Object.assign({}, state, { logoTypeAll: action.payload });

        case ProductActions.LOAD_LOGOAREA_SUCCESS:
            return Object.assign({}, state, { logoArea: action.payload });

        case ProductActions.LOAD_CHANGEVISIBILITY_SUCCESS:
            return Object.assign({}, state, { changevisibility: action.payload });

        case ProductActions.LOAD_LOGOMAXAREA_SUCCESS:
            return Object.assign({}, state, { maxLogoArea: action.payload });

        case ProductActions.LOAD_LOGOMAXAREADEL_SUCCESS:
            return Object.assign({}, state, { maxLogoAreadel: action.payload });

        case ProductActions.LOAD_LOGOMAXAREAUPDATE_SUCCESS:
            return Object.assign({}, state, { maxLogoAreaupdate: action.payload });

        case ProductActions.LOAD_HANDLINGCOST_SUCCESS:
            return Object.assign({}, state, { handlingCost: action.payload });

        case ProductActions.LOAD_CUSTOMERPRODUCT_SUCCESS:
            return Object.assign({}, state, { customerProduct: action.payload });
        default: {
            return state;
        }
    }
}
