import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unpublishmodal',
  templateUrl: './unpublishmodal.component.html',
  styleUrls: ['./unpublishmodal.component.scss']
})
export class UnpublishmodalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
