import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LinkPaginationComponent } from './link-pagination.component';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';

@NgModule({
    declarations: [LinkPaginationComponent],
    imports: [CommonModule, FormsModule, MDBBootstrapModulesPro.forRoot()],
    exports: [LinkPaginationComponent]
})
export class LinkPaginationModule { }
