import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../../shared/link-table/models/link-table-model';
import { ApiService } from '../../../shared/common-services/api.service';
import { MessageboxService } from '../../state/services/messagebox.service';
import { LinkTableService } from '../../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sentmessagedetails',
  templateUrl: './sentmessagedetails.component.html',
  styleUrls: ['./sentmessagedetails.component.scss']
})
export class SentmessagedetailsComponent implements OnInit {
  customerList: any = [];
  showMessageDetailpage = true;
  customerId: any;
  messageinboxQueryParams: any = {};
  inboxdetailsData: any;
  emailid: any;
  filterFields: any;
  mailInfo: any;
  webshopDetail: any;
  constructor(public messageboxservice: MessageboxService,
    public apiService: ApiService,
    private linkTableService: LinkTableService,
    private toast: ToastService,
    private location: Location,
    private router: Router) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {

    console.log(this.emailid)
    this.mailInfo = window.history.state;
    this.emailid = this.mailInfo.data.EmailID;
    this.MessageBoxLineData();
    this.filterFields = [{
      label: 'Delete',
      type: 'button',
      class: 'deleteico',
      key: 'messagedelete',
      model: 'deleteclaims'
    }]
  }
  ngOnChanges() {
  }
  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        console.log(data)
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });
      }
    })
  }
  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.customerId = this.webshopDetail.CustomerID;
    this.messageinboxQueryParams.customerID = this.customerId;
    this.messageinboxQueryParams.UserID = loginData.UserID;


    this.messageboxservice.loadCustomers(this.messageinboxQueryParams)
    // this.filterParams = {

    // }
  }
  MessageBoxLineData() {
    this.messageinboxQueryParams.emailID = this.emailid;
    this.messageboxservice.loadMessageboxdetailState(this.messageinboxQueryParams);
    this.messageboxservice.getMessageInboxdetailsData().subscribe((data: any) => {
      if (data) {

        // const loginData = this.apiService.getLoginData();
        this.inboxdetailsData = data;
        let unreadData = {
          emailid: this.inboxdetailsData.EmailID,
          customerid: this.webshopDetail.CustomerID
        }
        this.messageboxservice.loadUnread(unreadData);
        this.messageboxservice.unreadMessage().subscribe(data => {
        })
        // this.messageinbox = data.ItemsCollection;
      }
    })
  }

  pageredirect() {
    this.router.navigate(['/app-message-sentmessage'], { replaceUrl: false });
  }

  deleteMail(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deleteclaims) {
      const loginData = this.apiService.getLoginData();
      const requestData = {
        "EmailID": this.inboxdetailsData.EmailID,
        "SenderID": this.inboxdetailsData.SenderID,
        "ReceiverID": this.inboxdetailsData.ReceiverID,
        "CustomerID": this.inboxdetailsData.CustomerID,
        "EmailTypeID": this.inboxdetailsData.EmailTypeID,
        "Subject": this.inboxdetailsData.Subject,
        "Body": this.inboxdetailsData.Body,
        "SenderType": this.inboxdetailsData.SenderType,
        "ReceiverType": this.inboxdetailsData.ReceiverType,
        "SendingTime": this.inboxdetailsData.SendingTime,
        "Status": this.inboxdetailsData.Status,
        "SenderName": this.inboxdetailsData.SenderName,
        "SenderEmail": this.inboxdetailsData.SenderEmail,
        "SenderImageName": this.inboxdetailsData.SenderImageName,
        "SenderImageURL": this.inboxdetailsData.SenderImageURL,
        "ReceiverName": this.inboxdetailsData.ReceiverName,
        "ReceiverEmail": this.inboxdetailsData.ReceiverEmail,
        "ReceiverImageName": this.inboxdetailsData.ReceiverImageName,
        "ReceiverImageURL": this.inboxdetailsData.ReceiverImageURL,
        "senderName": this.inboxdetailsData.SenderName,
        "senderEmail": this.inboxdetailsData.SenderEmail,
        "recieverName": this.inboxdetailsData.RecieverName,
        "recieverEmail": this.inboxdetailsData.RecieverEmail,
        "isTrash": true,
        "isDeleted": false,
        "UserID": loginData.UserID,
        "EmailType": "Email"
      }

      this.messageboxservice.deleteMail(requestData);
      this.messageboxservice.getSendMessageSuccess().subscribe(data => {
        if (data == null) {
          setTimeout(() => {
            this.toast.success('Message Successfully Deleted');
            this.pageredirect();
          }, 300);
        }
      });
    }
  }
}
