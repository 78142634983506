import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BannerenquiryState } from '../reducers/bannerenquiry.reducer';
import { BannerenquiryActions } from '../actions/index';
@Injectable()
export class BannerEnquiryService {
    constructor(private store: Store<BannerenquiryState>) { }

    selectedEnquiry(bannerid) {
        this.store.dispatch(new BannerenquiryActions.SelectedEnquiry(bannerid));
    }
}
