import { Component, OnInit } from '@angular/core';
import { WebshopHttpService } from '../state/services/webshop.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/common-services/api.service';
import { LoginService } from 'src/app/login/state/services/login.service';
import { UserHttpService } from 'src/app/user/state/services/user.http.service';

@Component({
  selector: 'rewardkart-admin-webshop-edit',
  templateUrl: './webshop-edit.component.html',
  styleUrls: ['./webshop-edit.component.scss']
})
export class WebshopEditComponent implements OnInit {
  showLoader: Boolean = false;
  webshopData: any;
  getShopData: any;
  getPublishListData: any;
  getShopPaymentData: any;
  ShopPaymentDetailData: any;
  getuserData: any;
  getTransportationData: any;
  getSettingsData: any;
  getCurrenciesData: any;
  countriesList = [];
  countryList: any = [];
  customerCurrencies: any = [];
  getHandlingCharges: any;
  roleStatus: Boolean = false;
  getLanguages: any = [];
  getCategories: any = [];
  getCategoryStatus: Boolean = false;
  activePage: any = '';
  tagStatus: Boolean = false;
  webshopDetail: any;
  webshopColorPage: Boolean = false;
  webshopLogoPage: Boolean = false;
  webshopLandingPage: Boolean = false;
  webshopHomepagecategoryPage: Boolean = false;
  webshopFeaturesPage: Boolean = false;
  webshopSocialmediaPage: Boolean = false;
  publishStatus: Boolean;
  loadAgain: Boolean = false;
  languageList: any;
  bankDetails: any;
  getCurrencies: any;
  constructor(private webshopHttpService: WebshopHttpService, private loginService: LoginService,
    private formBuilder: FormBuilder, private translate: TranslateService, private userHttpService: UserHttpService, private toast: ToastService, private router: Router, private apiService: ApiService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.loadWebshop();
    this.activePage = window.location.pathname;
    if (this.activePage !== '/homepage-category' && this.activePage !== '/social-media' && this.activePage !== '/webshop-color' &&
      this.activePage !== '/webshop-features' && this.activePage !== '/webshop-logo') {
      this.getCountryList();
      if (this.activePage !== '/transportation-hub') {
        this.loadCountries();
      }
    }
    this.webshopColorPage = false;
    this.webshopLogoPage = false;
    this.webshopLandingPage = false;
    this.webshopHomepagecategoryPage = false;
    this.webshopFeaturesPage = false;
    this.webshopSocialmediaPage = false;
    if (this.activePage === '/webshop-color') {
      this.webshopColorPage = true;
    }
    if (this.activePage === '/webshop-logo') {
      this.webshopLogoPage = true;
    }
    if(this.activePage === '/webshop-landing-page'){
      this.webshopLandingPage = true;
    }
    if (this.activePage === '/homepage-category') {
      this.webshopHomepagecategoryPage = true;
    }
    if (this.activePage === '/webshop-features') {
      this.webshopFeaturesPage = true;
    }
    if (this.activePage === '/social-media') {
      this.webshopSocialmediaPage = true;
    }
    this.userHttpService.getCurrenciesById(this.webshopDetail.CustomerID).subscribe(
      (data: any) => {
        if (data) {
          this.getCurrencies = data;
        }
      })
  }

  ngOnChanges() {

  }


  loadWebshop() {
    this.showLoader = true;
    const loginData = this.apiService.getLoginData();
    this.webshopHttpService.webshopEdit(this.webshopDetail.CustomerID).subscribe((webshop: any) => {
      let custID = webshop.ParentCustomerID ? webshop.ParentCustomerID : webshop.CustomerID;
      this.getBankDetails(custID);
      this.webshopData = webshop;
      this.publishStatus = webshop.isWebShopActive;
      this.roleStatus = this.webshopData.EnableProductRoles;
      this.tagStatus = this.webshopData.EnableProductTags;
      this.showLoader = false;

      /** For Transportation hub section */
      if (this.activePage === '/transportation-hub') {
        this.loadTransportationData(this.webshopData.CustomerID);
      }
      /** For Handling charge section */
      if (this.activePage === '/handling-charge') {
        this.loadHandlingCharges(this.webshopData.CustomerID);
      }
      /** For Settings section */
      this.getSettingsData = this.webshopData;
      // tslint:disable-next-line:max-line-length
      if (this.activePage !== '/social-media' && this.activePage !== '/webshop-color' && this.activePage !== '/webshop-features' && this.activePage !== '/webshop-logo' && this.activePage !== '/transportation-hub') {
        this.getWebshopCategories(this.webshopData.CustomerID);
      }
      if (this.activePage !== '/homepage-category' && this.activePage !== '/social-media' && this.activePage !== '/webshop-color'
        && this.activePage !== '/webshop-features' && this.activePage !== '/webshop-logo' && this.activePage !== '/transportation-hub') {
        this.getWebshopLanguages();
        this.loadCurrenciesData(this.webshopData.CustomerID);
        this.getCustomerCurrencies();
      }
      if (webshop) {
      }
    },
      (error) => {
        this.showLoader = false;
      })
  }

  loadTransportationData(webshopId) {
    this.showLoader = true;
    this.webshopHttpService.getTransportationData(webshopId).subscribe((responseData: any) => {
      this.getTransportationData = responseData;
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
      })
  }

  loadCurrenciesData(webshopId) {
    this.showLoader = true;
    this.webshopHttpService.getCurrenciesData(webshopId).subscribe((data: any) => {
      this.getCurrenciesData = data;
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
      })
  }

  shopData(data) {
    this.getShopData = data;
    this.saveWebshop('shopdetails');
  }

  shopPaymentTypeData(data) {
    this.ShopPaymentDetailData = data;
    this.saveWebshop('paymentType');
  }
  shopPaymentData(data) {
    this.getShopPaymentData = data;
    // this.saveWebshop();
  }

  userData(data) {
    this.getuserData = data;
    this.updateUsers();
  }

  transportationData(data) {
    this.getTransportationData = data;
    this.updateTransportationData();
  }
  publishListData(data) {
    this.getPublishListData = data
  }
  settingsData(data, type) {
    this.getSettingsData = data;
    this.saveWebshop(type);
  }

  currenciesData(data) {
    this.getCurrenciesData = data;
    this.updateCurrenciesData();
  }

  handlingChargeData(data) {
    this.getHandlingCharges = data;
    this.updateHandlingCharges();
  }

  savePublishData() {
    if (this.webshopData.isPrivate === true) {
      var isPrivate = 1;
    }
    else {
      var isPrivate = 0;
    }
    const publishDetailData = {
      'isPrivate': isPrivate,
      'isWebShopActive': true,//$scope.webshop.isWebShopActive
      'DomainName': this.webshopData.DomainName,
      'ImageName': this.webshopData.FileURL,
      'BackgroundImage': this.webshopData.BackgroundFileURL,
      'action': 'update',
    }

  }

  getActiveStatus(event) {
    this.publishStatus = event;
    let type = this.publishStatus ? 'publish' : 'unpublish';
    this.saveWebshop(type)
  }

  saveWebshop(tabName?) {
    console.log(this.webshopData, this.settingsData, 'testinga')
    debugger
    let isPrivate = this.getSettingsData.passwordProtected;
    if (isPrivate || this.getSettingsData.isPrivate == 'True') {
      isPrivate = 1;
    }
    else {
      isPrivate = false;
    }
    this.webshopHttpService.getActAsSupplier(this.webshopData.Name).subscribe((result: any) => {
      if (result) {
        this.showLoader = true;
        let request = {
          "Name": this.getShopData && this.getShopData.webshopName ? this.getShopData.webshopName : this.webshopData.Name,
          "Address": this.getShopData && this.getShopData.address ? this.getShopData.address : this.webshopData.Address,
          "City": this.getShopData && this.getShopData.city ? this.getShopData.city : this.webshopData.City,
          "Country": this.getShopData && this.getShopData.country ? this.getShopData.country : this.webshopData.Country,
          "ZipCode": this.getShopData && this.getShopData.postalCode ? this.getShopData.postalCode : this.webshopData.ZipCode,
          "ContactPerson": this.getShopData && this.getShopData.contactPerson ? this.getShopData.contactPerson : this.webshopData.ContactPerson,
          "ContactEmail": this.getShopData && this.getShopData.email ? this.getShopData.email : this.webshopData.ContactEmail,
          "SalesEmail": this.getShopData && this.getShopData.salesEmail ? this.getShopData.salesEmail : this.webshopData.SalesEmail,
          "OrderNotificationAlarmWeeks": this.getShopData && this.getShopData.OrderNotificationAlarmWeeks ? this.getShopData.OrderNotificationAlarmWeeks : this.webshopData.OrderNotificationAlarmWeeks,
          "SupportEmail": this.getShopData && this.getShopData.supportEmail ? this.getShopData.supportEmail : this.webshopData.SupportEmail,
          "Website": this.getShopData && this.getShopData.website ? this.getShopData.website : this.webshopData.Website,
          "SalesLinkEmail": this.getShopData && this.getShopData.salesLinkEmail ? this.getShopData.salesLinkEmail : null,
          "ContactNumber": this.getShopData && this.getShopData.contactNumber ? this.getShopData.contactNumber : this.webshopData.ContactNumber,
          "RegistrationNumber": this.getShopData && this.getShopData.registerNumber ? this.getShopData.registerNumber : this.webshopData.RegistrationNumber,
          "DomainName": this.getShopData && this.getShopData.domainName ? this.getShopData.domainName : this.webshopData.DomainName,
          "Action": "update",
          "Translations": this.webshopData.Translations,
          // tslint:disable-next-line:max-line-length
          "FirstSubcategoryRule": this.getSettingsData && this.getSettingsData.FirstSubcategoryRule !== undefined ? this.getSettingsData.FirstSubcategoryRule : this.webshopData.FirstSubcategoryRule,
          "ShopDetailsName": this.getShopData && this.getShopData.textField ? this.getShopData.textField : this.webshopData.ShopDetailsName,
          "CustomerID": this.webshopData.CustomerID,
          "CompanyName": this.getShopData && this.getShopData.companyName ? this.getShopData.companyName : this.webshopData.CompanyName,
          "ParentCustomerDomainName": this.webshopData.ParentCustomerDomainName,
          "ParentCustomerName": this.webshopData.ParentCustomerName,
          "ParentCustomerAddress": this.webshopData.ParentCustomerAddress,
          "ParentCustomerCity": this.webshopData.ParentCustomerCity,
          "ParentCustomerPostalCode": this.webshopData.ParentCustomerPostalCode,
          "ParentCustomerCountryName": this.webshopData.ParentCustomerCountryName,
          "ParentCustomerContactNumber": this.webshopData.ParentCustomerContactNumber,
          "ParentCustomerContactEmail": this.webshopData.ParentCustomerContactEmail,
          "ParentCustomerVAT": this.webshopData.ParentCustomerVAT,
          "ParentCustomerRegistrationNumber": this.webshopData.ParentCustomerRegistrationNumber,
          "ParentCustomerBankName": this.getShopPaymentData ? this.getShopPaymentData.bankName : '',
          "ParentCustomerAccountNumber": this.getShopPaymentData ? this.getShopPaymentData.AccountNumber : this.webshopData.ParentCustomerAccountNumber,
          "ParentCustomerIbanNumber": this.getShopPaymentData ? this.getShopPaymentData.IbanNumber : this.webshopData.ParentCustomerIbanNumber,
          "ParentCustomerSwiftNumber": this.getShopPaymentData ? this.getShopPaymentData.SwiftNumber : this.webshopData.ParentCustomerSwiftNumber,
          "SupplierID": this.webshopData.SupplierID,
          "SupplierName": this.webshopData.SupplierName,
          "CountryName": this.webshopData.CountryName,
          "VAT": this.getShopData && this.getShopData.vatNumber ? this.getShopData.vatNumber : this.webshopData.VAT,
          "ImageName": this.getSettingsData && this.getSettingsData.ImageName !== undefined ? this.getSettingsData.ImageName : this.webshopData.ImageName,
          "ImageURL": this.getSettingsData && this.getSettingsData.ImageURL !== undefined ? this.getSettingsData.ImageURL : this.webshopData.ImageURL,
          "SapID": this.webshopData.SapID,
          // tslint:disable-next-line:max-line-length
          "LinkFacebook": this.getSettingsData && this.getSettingsData.LinkFacebook !== undefined ? this.getSettingsData.LinkFacebook : this.webshopData.LinkFacebook,
          // tslint:disable-next-line:max-line-length
          "LinkTwitter": this.getSettingsData && this.getSettingsData.LinkTwitter !== undefined ? this.getSettingsData.LinkTwitter : this.webshopData.LinkTwitter,
          // tslint:disable-next-line:max-line-length
          "LinkLinkedin": this.getSettingsData && this.getSettingsData.LinkLinkedin !== undefined ? this.getSettingsData.LinkLinkedin : this.webshopData.LinkLinkedin,
          // tslint:disable-next-line:max-line-length
          "LinkInstagram": this.getSettingsData && this.getSettingsData.LinkInstagram !== undefined ? this.getSettingsData.LinkInstagram : this.webshopData.LinkInstagram,
          // tslint:disable-next-line:max-line-length
          "LinkGoogleplus": this.getSettingsData && this.getSettingsData.LinkGoogleplus !== undefined ? this.getSettingsData.LinkGoogleplus : this.webshopData.LinkGoogleplus,
          "BankName": this.getShopPaymentData && this.getShopPaymentData.BankName ? this.getShopPaymentData.BankName : this.bankDetails.BankName,
          "AccountNumber": this.getShopPaymentData && this.getShopPaymentData.AccountNumber ? this.getShopPaymentData.AccountNumber : this.bankDetails.AccountNumber,
          "SwiftNumber": this.getShopPaymentData && this.getShopPaymentData.SwiftNumber ? this.getShopPaymentData.SwiftNumber : this.bankDetails.SwiftNumber,
          "IbanNumber": this.getShopPaymentData && this.getShopPaymentData.IbanNumber ? this.getShopPaymentData.IbanNumber : this.bankDetails.IbanNumber,
          "isPrivate": isPrivate,
          "ShowFixedPrices": this.webshopData.ShowFixedPrices,
          "ShowProductUnits": this.webshopData.ShowProductUnits,
          "PasswordProtected": this.webshopData.PasswordProtected,
          "CustomersConditionText": this.webshopData.CustomersConditionText,
          "CustomersFrontPageText": this.webshopData.CustomersFrontPageText,
          "CustomersNewsletter": this.webshopData.CustomersNewsletter,
          "CustomersWelcomeEmailTemplateSubject": this.webshopData.CustomersWelcomeEmailTemplateSubject,
          "CustomersWelcomeEmailTemplateBody": this.webshopData.CustomersWelcomeEmailTemplateBody,
          "isWebShopActive": this.publishStatus,
          // tslint:disable-next-line:max-line-length
          "DefaultLanguageID": this.getShopData && this.getShopData.DefaultLanguageID !== undefined ? this.getShopData.DefaultLanguageID : this.webshopData.DefaultLanguageID,
          // tslint:disable-next-line:max-line-length
          "DefaultLanguageCode": this.getShopData && this.getShopData.DefaultLanguageCode !== undefined ? this.getShopData.DefaultLanguageCode : this.webshopData.DefaultLanguageCode,
          // tslint:disable-next-line:max-line-length
          "DefaultCurrencyID": this.getShopData && this.getShopData.DefaultCurrencyID !== undefined ? this.getShopData.DefaultCurrencyID : this.webshopData.DefaultCurrencyID,
          // tslint:disable-next-line:max-line-length
          "DefaultCurrencyCode": this.getShopData && this.getShopData.DefaultCurrencyCode !== undefined ? this.getShopData.DefaultCurrencyCode : this.webshopData.DefaultCurrencyCode,
          // tslint:disable-next-line:max-line-length
          "isSocialMediaSharingEnabled": this.getSettingsData && this.getSettingsData.isSocialMediaSharingEnabled !== undefined ? this.getSettingsData.isSocialMediaSharingEnabled : this.webshopData.isSocialMediaSharingEnabled,
          // tslint:disable-next-line:max-line-length
          "ShowPricesIncludingVAT": this.getSettingsData && this.getSettingsData.ShowPricesIncludingVAT !== undefined ? this.getSettingsData.ShowPricesIncludingVAT : this.webshopData.ShowPricesIncludingVAT,
          // tslint:disable-next-line:max-line-length
          "ShowSupplierRating": this.getSettingsData && this.getSettingsData.ShowSupplierRating !== undefined ? this.getSettingsData.ShowSupplierRating : this.webshopData.ShowSupplierRating,
          // tslint:disable-next-line:max-line-length
          "ShowAdvancedSearchBranding": this.getSettingsData && this.getSettingsData.ShowAdvancedSearchBranding !== undefined ? this.getSettingsData.ShowAdvancedSearchBranding : this.webshopData.ShowAdvancedSearchBranding,
          // tslint:disable-next-line:max-line-length
          "ShowAdvancedSearchColors": this.getSettingsData && this.getSettingsData.ShowAdvancedSearchColors !== undefined ? this.getSettingsData.ShowAdvancedSearchColors : this.webshopData.ShowAdvancedSearchColors,
          // tslint:disable-next-line:max-line-length
          "ShowAdvancedSearchPrices": this.getSettingsData && this.getSettingsData.ShowAdvancedSearchPrices !== undefined ? this.getSettingsData.ShowAdvancedSearchPrices : this.webshopData.ShowAdvancedSearchPrices,
          // tslint:disable-next-line:max-line-length
          "ShowSortByFilter": this.getSettingsData && this.getSettingsData.ShowSortByFilter !== undefined ? this.getSettingsData.ShowSortByFilter : this.webshopData.ShowSortByFilter,
          // tslint:disable-next-line:max-line-length
          "ShowFiltersOnHomePage": this.getSettingsData && this.getSettingsData.ShowFiltersOnHomePage !== undefined ? this.getSettingsData.ShowFiltersOnHomePage : this.webshopData.ShowFiltersOnHomePage,
          // tslint:disable-next-line:max-line-length
          "RequireUsersApproval": this.getSettingsData && this.getSettingsData.RequireUsersApproval !== undefined ? this.getSettingsData.RequireUsersApproval : this.webshopData.RequireUsersApproval,
          "PaymentTermsNumberOfDays": this.getShopData && this.getShopData.invoicePaymentTerms ? this.getShopData.invoicePaymentTerms : this.webshopData.PaymentTermsNumberOfDays,
          "DefaultInvoiceType": this.getShopData && this.getShopData.defaultInvoiceType ? this.getShopData.defaultInvoiceType : this.webshopData.DefaultInvoiceType,
          "PDPNoBrandingConfirmation": false,
          // tslint:disable-next-line:max-line-length
          "OrderConfirmationOnlyForISPs": this.getSettingsData && this.getSettingsData.OrderConfirmationOnlyForISPs !== undefined ? this.getSettingsData.OrderConfirmationOnlyForISPs : this.webshopData.OrderConfirmationOnlyForISPs,
          "EnableProductRoles": this.roleStatus,
          // tslint:disable-next-line:max-line-length
          "UseChildCurrencyWhenPossible": this.getSettingsData && (this.getSettingsData.UseChildCurrencyWhenPossible !== undefined) ? this.getSettingsData.UseChildCurrencyWhenPossible : this.webshopData.UseChildCurrencyWhenPossible,
          // tslint:disable-next-line:max-line-length
          "ShowJoinOrdersOnHomePage": this.getSettingsData && this.getSettingsData.ShowJoinOrdersOnHomePage !== undefined ? this.getSettingsData.ShowJoinOrdersOnHomePage : this.webshopData.ShowJoinOrdersOnHomePage,
          // tslint:disable-next-line:max-line-length
          "UseManualIdWhenSortingByNewestOldest": this.getSettingsData && this.getSettingsData.UseManualIdWhenSortingByNewestOldest !== undefined ? this.getSettingsData.UseManualIdWhenSortingByNewestOldest : this.webshopData.UseManualIdWhenSortingByNewestOldest,
          // tslint:disable-next-line:max-line-length
          "ShowAllProductsInSortByFilter": this.getSettingsData && this.getSettingsData.ShowAllProductsInSortByFilter !== undefined ? this.getSettingsData.ShowAllProductsInSortByFilter : this.webshopData.ShowAllProductsInSortByFilter,
          "EnableProductTags": this.tagStatus,
          // tslint:disable-next-line:max-line-length
          "ShowCategoriesOnHomePage": this.getSettingsData && this.getSettingsData.ShowCategoriesOnHomePage !== undefined ? this.getSettingsData.ShowCategoriesOnHomePage : this.webshopData.ShowCategoriesOnHomePage,
          // tslint:disable-next-line:max-line-length
          "EnableCookiePolicy": this.getSettingsData && this.getSettingsData.EnableCookiePolicy !== undefined ? this.getSettingsData.EnableCookiePolicy : this.webshopData.EnableCookiePolicy,
          // tslint:disable-next-line:max-line-length
          "ChangeDeliveryDateText": this.getSettingsData && this.getSettingsData.ChangeDeliveryDateText !== undefined ? this.getSettingsData.ChangeDeliveryDateText : this.webshopData.ChangeDeliveryDateText,
          // tslint:disable-next-line:max-line-length
          "TreatDeliveryWeeksAsDaysForISPs": this.getSettingsData && this.getSettingsData.TreatDeliveryWeeksAsDaysForISPs !== undefined ? this.getSettingsData.TreatDeliveryWeeksAsDaysForISPs : this.webshopData.TreatDeliveryWeeksAsDaysForISPs,
          // tslint:disable-next-line:max-line-length
          "EnableNewsletterSignupLink": this.getSettingsData && (this.getSettingsData.EnableNewsletterSignupLink !== undefined) ? this.getSettingsData.EnableNewsletterSignupLink : 0,
          // tslint:disable-next-line:max-line-length
          "IsSendEmailCheckedByDefault": this.getSettingsData && this.getSettingsData.IsSendEmailCheckedByDefault !== undefined ? this.getSettingsData.IsSendEmailCheckedByDefault : this.webshopData.IsSendEmailCheckedByDefault,
          "Languages": this.languageList ? this.languageList : this.webshopData.Languages,
          "Currencies": this.webshopData.Currencies,
          // "PaymentTypes": this.webshopData.PaymentTypes,
          "PaymentTypes": this.ShopPaymentDetailData && this.ShopPaymentDetailData ? this.ShopPaymentDetailData : this.webshopData.PaymentTypes,
          "Themes": [
            {
              "ThemeId": this.webshopData.Themes[0].ThemeId,
              // tslint:disable-next-line:max-line-length
              "Name": this.getSettingsData && this.getSettingsData.Themes ? this.getSettingsData.Themes[0].Name : this.webshopData.Themes[0].Name,
              // tslint:disable-next-line:max-line-length
              "Main": this.getSettingsData && this.getSettingsData.Themes ? this.getSettingsData.Themes[0].Main : this.webshopData.Themes[0].Main,
              // tslint:disable-next-line:max-line-length
              "Lable": this.getSettingsData && this.getSettingsData.Themes ? this.getSettingsData.Themes[0].Lable : this.webshopData.Themes[0].Lable,
              // tslint:disable-next-line:max-line-length
              "Alert": this.getSettingsData && this.getSettingsData.Themes ? this.getSettingsData.Themes[0].Alert : this.webshopData.Themes[0].Alert,
            }
          ],
          "ChildCustomers": this.webshopData.ChildCustomers,
          "WebshopManager": this.webshopData.WebshopManager,
          "ProductTagsGroups": this.webshopData.ProductTagsGroups,
          // tslint:disable-next-line:max-line-length
          "SocialMedias": this.getSettingsData && this.getSettingsData.socialMedias ? this.getSettingsData.socialMedias : this.webshopData.SocialMedias,
          // tslint:disable-next-line:max-line-length
          "HomePageCategoryID": this.getSettingsData ? this.getSettingsData.HomePageCategoryID : this.webshopData.HomePageCategoryID,
          // tslint:disable-next-line:max-line-length
          "HomePageCategoryName": this.getSettingsData && this.getSettingsData.HomePageCategoryName ? this.getSettingsData.HomePageCategoryName : this.webshopData.HomePageCategoryName,
          "IsWebshopDropdown": this.webshopData.IsWebshopDropdown,
          "CustomerUpdateDate": "2020-09-22T08:25:32.537",
          "IsOpenBook": this.webshopData.IsOpenBook
        }
        if (this.getShopData != undefined && this.getShopData.OrderNotificationAlarmWeeks == null) {
          request.OrderNotificationAlarmWeeks = null;
        }
        if (this.webshopData && this.webshopData.ParentCustomerID) {
          const parentCustomerID = { "ParentCustomerID": this.webshopData.ParentCustomerID };
          request = { ...request, ...parentCustomerID };
        }

        const loginData = this.apiService.getLoginData();
        this.webshopHttpService.updateWebshop(request, this.webshopData.CustomerID).subscribe(data => {
          this.showLoader = false;
          if (data) {
            // this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
            this.loginService.loadwebshopLanguage(this.webshopDetail.CustomerID);

            if (tabName === 'paymentType') {
              this.toast.success('Payment details updated successfully');
            }
            else if (tabName === 'publish') {
              this.toast.success('Shop published successfully');
            }
            else if (tabName === 'unpublish') {
              this.toast.success('Shop unpublished successfully');
            }
            else if (tabName === 'languages') {
              this.toast.success('Languages updated successfully');
            }
            else if (tabName === 'shopdetails') {

              this.toast.success('Shop details updated successfully');
              let updatedata: any = {};
              if (this.getShopData && this.getShopData.actAsSupplier) {
                updatedata.SupplierID = result.ItemsCollection[0].SupplierID;
              } else {
                updatedata.SupplierID = null;
              }
              updatedata.CustomerID = this.webshopData.CustomerID;
              this.loadAgain = true;
              this.webshopHttpService.customerSupplier(updatedata).subscribe((res) => {
                this.loadWebshop();
              });
            }
            else if (tabName === 'unpublish') {
              this.toast.success('Shop unpublished successfully');
            } else if (tabName === 'color') {
              this.toast.success('Webshop theme color details updated successfully');
            } else if (tabName === 'logo') {
              this.toast.success('Webshop logo uploaded successfully');
            } else if (tabName === 'homepagecategory') {
              this.toast.success('Webshop homepage category updated successfully');
            } else if (tabName === 'features') {
              this.toast.success('Webshop features updated successfully');
            } else if (tabName === 'socialmedia') {
              this.toast.success('Webshop socialmedia updated successfully');
            } else {
              this.toast.success('Webshop detail updated successfully');
            }

            this.loadWebshop();
          }
        },
          (error) => {
            this.showLoader = false;
          })

      }
    });



  }

  updateUsers() {
    this.showLoader = true;
    let request = {
      "Name": this.getuserData.name,
      "UserName": this.getuserData.userName,
      "JobPosition": this.getuserData.jobPosition,
      "CompanyName": this.getuserData.companyName,
      "LanguageID": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['LanguageID'] : 1,
      "CurrencyID": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['CurrencyID'] : 1,
      "PhoneNumber": this.getuserData.phoneNumber,
      "Email": this.getuserData.userEmail,
      "Address": this.getuserData.address1,
      "Address2": this.getuserData.address2,
      "Address3": this.getuserData.address3,
      "DeliveryAddress": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['DeliveryAddress'] : this.getuserData.address1,
      "DeliveryAddress2": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['DeliveryAddress2'] : this.getuserData.address2,
      "DeliveryAddress3": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['DeliveryAddress3'] : this.getuserData.address3,
      "City": this.getuserData.city,
      "PostalCode": this.getuserData.postalCode,
      "Country": this.getuserData.country,
      "DeliveryCity": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['DeliveryCity'] : this.getuserData.city,
      "DeliveryPostalCode": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['DeliveryPostalCode'] : this.getuserData.postalCode,
      "DeliveryCountry": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['DeliveryCountry'] : this.getuserData.country,
      "SameAsCompany": true,
      "Department": this.getuserData.department,
      "UserType": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['UserType'] : '',
      "FirstLogin": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['FirstLogin'] : true,
      "VAT": this.getuserData.vatNumber,
      "PaymentByInvoice": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['PaymentByInvoice'] : true,
      "PaymentByCard": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['PaymentByCard'] : true,
      "CustomerID": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['CustomerID'] : this.webshopDetail.CustomerID,
      "isDeleted": this.webshopData && this.webshopData.WebshopManager.length > 0 ? this.webshopData.WebshopManager[0]['isDeleted'] : false,
      "ParentCustomerBankName": this.getShopPaymentData ? this.getShopPaymentData.bankName : '',
      "ParentCustomerAccountNumber": this.getShopPaymentData ? this.getShopPaymentData.AccountNumber : '',
      "ParentCustomerIbanNumber": this.getShopPaymentData ? this.getShopPaymentData.IbanNumber : '',
      "ParentCustomerSwiftNumber": this.getShopPaymentData ? this.getShopPaymentData.SwiftNumber : ''
    }

    if (this.webshopData && this.webshopData.WebshopManager.length === 0) {
      request['RoleCode'] = "Webshop_Manager";
      request['Password'] = this.getuserData.Password;
    }

    const userID = this.webshopData && this.webshopData.WebshopManager.length > 0 && this.webshopData.WebshopManager[0] ? this.webshopData.WebshopManager[0]['UserID'] : '';
    this.webshopHttpService.webshopUpdateUser(request, userID).subscribe((data) => {
      this.showLoader = false;
      if (data) {
        this.toast.success('User details updated successfully');
      }
    }, (error) => {
      this.showLoader = false;
    })
  }

  loadCountries() {
    this.webshopHttpService.getCountries().subscribe((data: any) => {
      if (data) {
        const getCountry = [];
        data.forEach(element => {
          getCountry.push({ label: element.Name, value: element.Alpha2Code })
        });
        this.countryList = getCountry;
      }
    })
  }

  updateTransportationData() {
    this.showLoader = true;
    // tslint:disable-next-line:max-line-length
    this.webshopHttpService.updateTransportationData(this.getTransportationData, this.getTransportationData.customerID).subscribe((result) => {
      this.showLoader = false;
      if (result) {
        this.toast.success('Transportation countries data updated successfully');
      } else {
        this.toast.error('Select Hub Country');
      }
    }, (error) => {
      this.showLoader = false;
    });
  }

  updateCurrenciesData() {
    this.showLoader = true;
    // tslint:disable-next-line:max-line-length
    this.webshopHttpService.updateCurrenciesData(this.getCurrenciesData, this.webshopData.CustomerID).subscribe((result) => {
      this.showLoader = false;
      if (result) {
        this.toast.success('Customer Currencies data updated successfully');
      } else {
        this.toast.error('Select any one Customer Currency');
      }
    }, (error) => {
      this.showLoader = false;
    });
  }

  /*** Get Country Items ***/
  getCountryList() {
    this.webshopHttpService.getAllCountries().subscribe(
      (data: any) => {
        if (data) {
          this.countriesList = [];
          this.countriesList.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.countriesList.push(langVal);
          });
        }
      });
  }

  /*** Get getCustomerCurrencies Items ***/
  getCustomerCurrencies() {
    this.webshopHttpService.getCustomerCurrencies().subscribe(
      (data: any) => {
        if (data) {
          this.customerCurrencies = [];
          // this.countriesList.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          data.forEach(element => {
            const currencyVal = {
              value: element.CurrencyID,
              label: element.Code,
            };
            this.customerCurrencies.push(currencyVal);
          });
        }
      });
  }

  loadHandlingCharges(webshopId) {
    this.webshopHttpService.loadHandlingCharges(webshopId).subscribe(
      (data: any) => {
        if (data) {
          this.getHandlingCharges = data[0];
        }
      });
  }

  updateHandlingCharges() {
    this.showLoader = true;
    // tslint:disable-next-line:max-line-length
    this.webshopHttpService.updateHandlingCharges(this.getHandlingCharges).subscribe((result) => {
      this.showLoader = false;
      if (result) {
        this.toast.success('Handling Charges added successfully');
      } else {
        this.toast.error('Handling Charges updated successfully');
      }
    }, (error) => {
      this.showLoader = false;
    });
  }

  getWebshopLanguages() {
    this.webshopHttpService.getLanguages().subscribe(
      (data: any) => {
        if (data) {
          this.getLanguages = data;
        }
      });
  }

  getWebshopCategories(webshopId) {
    this.webshopHttpService.getCategories(webshopId).subscribe(
      (data: any) => {
        this.getCategoryStatus = true;
        if (data) {
          this.getCategories = data;
        }
      });
  }

  getEnableRoleStatus(event) {
    this.roleStatus = event;
    this.saveWebshop();
  }

  getEnableTagStatus(event) {
    this.tagStatus = event;
    this.saveWebshop();
  }

  getSendLanguage(event) {
    this.languageList = event;
    this.saveWebshop('languages');
  }

  getSendMaintenance(event){
    this.webshopHttpService.setMaintenanceScreen(this.webshopData.DomainName, event).subscribe((data)=>{
      this.toast.success('Status updated successfully');
    });
  }

  getBankDetails(custID) {
    this.webshopHttpService.getBankDetails(custID).subscribe((data) => {
      this.bankDetails = data;
    })
  }

}