import { Component, OnInit, OnChanges, Output, EventEmitter, Input, ViewChild } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { } from 'events';
import { ProductService } from '../../state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../../state/services/product.http.service';
@Component({
  selector: 'app-product-sizes',
  templateUrl: './product-sizes.component.html',
  styleUrls: ['./product-sizes.component.scss']
})
export class ProductSizeComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  isMoqTextError: any;
  productSizeForm: FormGroup;
  optionsSelectSize: any = [];
  model: any;
  productSizes: any = [];
  selectedSize: any;
  @Input() productDetails: any;
  @Input() loadApiSize: any;
  @Output() sendProductSizeDetails = new EventEmitter<any>();
  resetSizeModal: Boolean = false
  showManageSize: boolean;
  items: FormArray;
  selectedData: any;
  deleteSizeData: any;
  loadManageApi: any;
  loadApiFlag: Boolean = false;
  showManageSizeModal: Boolean = false
  sizesArray: any[];
  sizesObj: { ProductID: any; ProductSizeID: any; MOQ: any; Name: any; Action: string; };
  addSizeArray: boolean;
  deleteSet: boolean;
  searchKey: any = '';
  resultMessage: any;
  showSearch: boolean;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selfSizes: any;
  selectlabel: any = 'Select Size';
  popupList: any;
  dropdownData: any = {};
  sizelength: any;

  constructor(public formBuilder: FormBuilder,
    public productService: ProductService, public translate: TranslateService,
    public toast: ToastService,
    public productHttpService: ProductHttpService) {
    this.loadManageApi = {
      manageSize: false
    }
  }



  resetSize(event) {
    this.resetSizeModal = true;
    setTimeout(() => {
      this.resetSizeModal = false;
    }, 1000)
  }



  ngOnChanges() {
    if (this.loadApiSize === true) {
      this.clearFormItems();
      this.setProductSizes();
      this.loadPagination()
    }

    if (this.loadApiSize === true && this.loadApiFlag !== this.loadApiSize) {
      this.loadApiFlag = true
      this.getProductSizes();
    }
  }

  ngOnInit() {
    // this.setProductSizes();
    this.loadForm();
    this.getProductSizes()


    this.productSizeForm.valueChanges.subscribe(() => {
      this.getValue();
    });
    this.getValue();

    this.loadSizes()
    this.loadPagination()


    this.popupList = {
      apiurl: 'all/ProductSizesWithPagination',
      supplierid: this.productDetails.SupplierID,
      key: 'ProductSizeID',
      label: 'Sizes',
      autoComplete: {
        apiUrl: 'AutoCompleteSizes',
        searchKey: 'name',
        supplierid: this.productDetails.SupplierID
      }
    }

  }



  clearFormItems() {
    const control = <FormArray>this.productSizeForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }
  loadSizes() {
    const selfColor = [];
    this.productDetails.ProductSizes.forEach(element => {
      const sizesVal = {
        size: element.ProductSizeID,
        moq: element.MOQ,
        data: element,
        status: 'saved'
      };
      this.items.push(this.createItem(sizesVal));
      selfColor.push(sizesVal)
      this.loadPagination();
    });
    this.selfSizes = selfColor;
  }
  searchList() {
    this.items.value.filter((data, index) => {
      let value = (data.data.Name).toLowerCase();
      let searchData = (this.searchKey).toLowerCase();
      if (((value).includes(searchData))) {
        data.search = 'show';
      }
      else {
        data.search = 'hide';
      }
    });
    const noresult = this.items.value.filter(get => get.search === 'show');
    this.resultMessage = noresult.length > 0 ? false : true;
    // this.hideSearch();
  }


  resetFilter() {
    this.searchKey = '';
    this.items.value.filter((data, index) => {
      data.search = 'show';
    });
    this.resultMessage = this.items.length === 0 ? true : false;
  }

  loadForm() {
    this.productSizeForm = this.formBuilder.group({
      size: ['', Validators.required],
      moq: ['', Validators.required],
      items: this.formBuilder.array([])
    });
    this.items = this.productSizeForm.get('items') as FormArray;
  }

  createItem(sizeVal?): FormGroup {
    return this.formBuilder.group({
      size: sizeVal ? sizeVal.size : '',
      moq: sizeVal ? sizeVal.moq : '',
      data: sizeVal ? sizeVal.data : '',
      status: sizeVal ? sizeVal.status : ''
    });
  }

  getValue() {
    const sizes = [];
    this.sizesArray = [];
    if (this.selfSizes !== undefined) {

      this.selfSizes.forEach(element => {
        sizes.push(element.data);
      });

      this.sendProductSizeDetails.emit(sizes);
      // this.selfSizes.forEach((item) => {
      //   if (this.addSizeArray || this.deleteSet) {
      //     sizes.push(item.data);
      //     this.sendProductSizeDetails.emit(sizes);
      //   }
      //   else {
      //     this.sizesObj = {
      //       ProductID: item.data.ProductID,
      //       ProductSizeID: item.data.ProductSizeID,
      //       MOQ: item.moq,
      //       Name: item.data.Name,
      //       Action: "Update"
      //     }
      //     this.sizesArray.push(this.sizesObj)
      //     this.sendProductSizeDetails.emit(this.sizesArray);
      //   }
      // })
    }


  }


  setProductSizes() {
    const selfColor = [];
    if (this.productDetails.ProductSizes !== undefined) {
      if (this.productDetails.ProductSizes.length > 0) {
        this.productDetails.ProductSizes.forEach(item => {
          const sizesVal = {
            size: item.ProductSizeID,
            moq: item.MOQ,
            data: item,
            status: 'saved'
          };
          // this.items.push(this.createItem(sizesVal));
          selfColor.push(sizesVal)
          // this.loadPagination();
        })
        this.selfSizes = selfColor;
        // this.hideSearch();
        this.loadPagination()
      }
    }
  }
  getProductSizes() {
    const requestData = { 'supplierID': 1, 'translations': true };
    // this.productService.loadEnProductSizes(requestData);
    this.productHttpService.getEnProductSizeList(requestData).subscribe((data: any) => {
      if (data !== null) {
        this.optionsSelectSize = [];
        data.forEach(element => {
          const sizeVal = {
            value: element.ProductSizeID,
            label: element.Translations[0].PropertyValue,
            data: element,
          };
          this.optionsSelectSize.push(sizeVal);
        });
      }
    });
  }

  resetDropDown() {
    this.getProductSizes()
  }



  addSize() {
    this.addSizeArray = true
    const { size, moq } = this.productSizeForm.controls
    const sizedata = this.dropdownData;
    const getDeletedSizes = this.productSizeForm.value.items.filter(data => {
      data.data.Action === "Delete" && data.data.ProductSizeID === sizedata.ProductSizeID
    })
    if (getDeletedSizes.length === 0) {
      let getSizeName = this.optionsSelectSize.filter(sizeName => sizeName.value === sizedata.ProductSizeID)
      if (getSizeName.length > 0) {
        getSizeName = getSizeName[0].label
      }
      let getPushData = this.productSizeForm.value.items.filter(data => data.data.Name === getSizeName && getSizeName.length > 0 && sizedata.ProductSizeID === data.data.ProductSizeID)
      if (getPushData.length === 0) {
        if (!(size.value === '' || moq.value === '' || moq.value === null || moq.value < 0 || moq.value > 9999999)) {
          this.selectedData.Action = "Insert";
          this.selectedData.MOQ = this.productSizeForm.controls.moq.value;
          const productSize = {
            size: this.productSizeForm.controls.size.value,
            moq: this.productSizeForm.controls.moq.value,
            data: this.selectedData,
            status: 'added'
          };
          this.items.push(this.createItem(productSize));
          this.selfSizes.push(productSize);
          this.toast.success("Sizes added successfully")
          this.productSizeForm.controls.size.setValue('');
          this.productSizeForm.controls.moq.setValue('');
          this.dropdownData = {};
          this.getValue();

        } else {

          if (size.value === "") {
            this.toast.error("Please select size")
          } else if (moq.value === "" || moq.value === null) {
            this.toast.error("Please enter moq")
          } else if (moq.value < 0) {
            this.toast.error("Moq should not be negative")
          } else if (moq.value > 9999999) {
            this.toast.error("Moq should not be greater than 9999999")
          }
        }
      }
      else {
        this.toast.error("Can't Add duplicate Sizes")
        this.productSizeForm.controls.size.setValue('');
        this.productSizeForm.controls.moq.setValue('');
        this.dropdownData = {};
        this.getValue();
      }
    }
    // this.hideSearch();
    this.loadPagination()
  }

  getSelectedSize(value) {
    this.selectedData = {
      Action: "",
      MOQ: "",
      ProductSizeID: value.data.ProductSizeID,
      Name: value.data.Name
    };
    const { size, moq } = this.productSizeForm.controls

  }

  getDeleteStatus(status) {
    const productSize = this.deleteSizeData.productSize;
    const index = this.deleteSizeData.index;
    const getDelete = this.selfSizes.findIndex(data => data.size === this.deleteSizeData.productSize.value.size);
    if (status) {
      if (productSize.value.status == 'saved') {
        // productColor.items.value[index].status = 'deleted';

        this.selfSizes[getDelete].status = 'deleted';
        // productSize.controls[index].controls.status.value = 'deleted';
        const deleteData = {
          ProductID: this.productDetails.ProductID,
          ProductSizeID: productSize.value.data.ProductSizeID,
          MOQ: productSize.value.data.MOQ,
          Name: productSize.value.data.Name,
          SupplierID: this.productDetails.SupplierID,
          SupplierName: this.productDetails.SupplierName,
          Action: "Delete",
          status: 'deleted'
        }
        // productSize.items.value[index].data = deleteData;
        this.selfSizes[getDelete].data = deleteData;
        this.selfSizes.forEach(element => {
          if (element.size === productSize.value.data.ProductSizeID) {
            element.Action = 'Delete';
          }
        });
      } else if (productSize.items.value.status == 'added') {
        // this.selfSizes[getDelete].data = deleteData;
        // productSize.items.removeAt(index);
      }
      // this.getValue();
      this.deleteModal.hide();
      // this.hideSearch();
      this.toast.success('Size deleted successfully')
      // this.loadPagination();
      let currentpage;
      if (this.selectedpage === this.pageList.length) {
        currentpage = this.pageList.length;
      }
      else {
        currentpage = this.selectedpage
      }
      this.loadPagination();
      if (this.pageList[currentpage] === undefined) {
        this.selectedpage = this.pageList.length;
        this.loadPagination();
      }
    }
    else {
      this.deleteModal.hide();
    }
  }

  deleteSize(productSize, index) {
    this.deleteSet = true
    this.deleteSizeData = { productSize: productSize, index: index }
    this.deleteModal.show();
  }

  showManageSizeFun(val) {
    this.showManageSize = val;
  }

  hideSearch() {
    const getResult = this.items.value.filter(data => data.Action !== 'Delete' || data.search !== 'hide');
    this.showSearch = getResult.length > 0 ? true : false;
  }
  pageChanged(page) {
    this.selectedpage = page;
    this.loadPagination();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.loadPagination();
  }

  loadPagination() {
    this.clearFormItems()
    // this.items.push(this.createItem([]));
    if (this.selfSizes !== undefined) {
      const selfSizes = this.selfSizes.filter(res => res.Action !== 'Delete');
      if (selfSizes.length <= 10) {
        this.selectedpage = 1;
      }
      let getPageList = selfSizes.length / this.selectedperpage;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = []
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;
      const getArray = [];
      const startArray = this.selectedpage * this.selectedperpage - this.selectedperpage;
      const endArray = this.selectedpage * this.selectedperpage;
      selfSizes.slice([startArray], [endArray]).map((item, i) => {
        const sizesValue = {
          size: item.data.ProductSizeID,
          moq: item.data.MOQ,
          data: item.data,
          status: 'saved'
        };
        this.items.push(this.createItem(sizesValue));
      });
      this.sizelength = selfSizes.length;
    }
  }

  getSelectedDropdown(dropdown) {
    this.selectedData = {
      Action: "",
      MOQ: "",
      ProductSizeID: dropdown.ProductSizeID,
      Name: dropdown.Name,
    };
    this.dropdownData = dropdown;
    this.productSizeForm.controls.size.setValue(dropdown.ProductSizeID);
  }

  resetPopupDropdown() {
    this.selectedData = {};
    this.dropdownData = {};
    this.productSizeForm.controls.size.setValue('');
    this.productSizeForm.controls.moq.setValue('');
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  changeData(data, i) {
    if (this.selfSizes.length > 0) {
      this.selfSizes[i]['moq'] = Number(data.target.value);
      this.selfSizes[i]['Action'] = 'Update';
      this.selfSizes[i]['data']['MOQ'] = Number(data.target.value);
      this.selfSizes[i]['data']['Action'] = 'Update';
    }
    this.getValue();
  }

}

