import { Action } from '@ngrx/store';
export const LOAD_SUPPLIER_LIST = '[Webshop] Load all Supplier List';
export const LOAD_SUPPLIER_LIST_SUCCESS = '[Webshop] Load Supplier List Success';
export const LOAD_SUPPLIER_LIST_FAIL = '[Webshop] Load Supplier List Fail';

export const LOAD_SUPPLIERS = '[Webshop] Load all Suppliers';
export const LOAD_SUPPLIERS_SUCCESS = '[Webshop] Load Suppliers Success';
export const LOAD_SUPPLIERS_FAIL = '[Webshop] Load Suppliers Fail';

export class LoadSupplierList implements Action {
    readonly type = LOAD_SUPPLIER_LIST;
    constructor(public payload: any) { }
}

export class LoadSupplierListSuccess implements Action {
    readonly type = LOAD_SUPPLIER_LIST_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadSupplierListFail implements Action {
    readonly type = LOAD_SUPPLIER_LIST_FAIL;
    constructor(public payload: any) { }
}

export class LoadSuppliers implements Action {
    readonly type = LOAD_SUPPLIERS;
}

export class LoadSuppliersSuccess implements Action {
    readonly type = LOAD_SUPPLIERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadSuppliersFail implements Action {
    readonly type = LOAD_SUPPLIERS_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | LoadSupplierList
    | LoadSupplierListSuccess
    | LoadSupplierListFail
    | LoadSuppliers
    | LoadSuppliersSuccess
    | LoadSuppliersFail;

