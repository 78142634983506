import { Component, OnInit, Input } from '@angular/core';
import { OrderProcessOverviewHttpService } from '../../state/services/order-process-overview-http.service';
import { MDBModalService, MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { OrderProfitComponent } from './order-profit/order-profit.component';
import { InternalNoteComponent } from './internal-note/internal-note.component';
import { Router } from '@angular/router';
import { OrderHttpService } from '../../state/services/order.http.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'rewardkart-admin-order-process-info-container',
  templateUrl: './order-process-info-container.component.html',
  styleUrls: ['./order-process-info-container.component.scss']
})
export class OrderProcessInfoContainerComponent implements OnInit {
  @Input() orderItem: any = null;
  @Input() subOrderItemData: any;
  @Input() profit: any = {};
  @Input() orderId: any = '';
  @Input() currency: any = '';
  @Input() lineId: any;
  @Input() customerID: any;
  @Input() unAuthorised: any;
  editNotes: any = {};
  orderItems: any;
  @Input() customerPickUpValue: any;

  modalRef: MDBModalRef;
  item: any;
  isLoading: boolean = false;
  searchData: any;
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };
  itemIndex: any;
  constructor(private httpService: OrderProcessOverviewHttpService,
    private modalService: MDBModalService, private toastService: ToastService,
    private router: Router, private orderHttpService: OrderHttpService, private datePipe: DatePipe) { }

  ngOnInit() {
    let url = window.location.href.split('/');
    this.itemIndex = url[url.length - 1];
  }

  ngOnChanges() {
    if (this.orderItem !== null) {
      this.orderItem.InternalNote = this.orderItem.InternalNote.replace(/\\n/g, '<br /><br />');
    }
  }

  trackStatus(){
    const params = {
      "SBINumber": "",
      "AWb_no": "",
      "MemberName": "",
      "Trn_Id": "",
      "Rawb_no":"",
      "B_Awb_No":"",
      "ContactNo": "",
      "QAWb_No":"",
      "BatchName": this.orderItem.OrderID,
      "CompanyName":""
  }
  this.isLoading = true;
  this.orderHttpService.getTrackOrderSearch(params).subscribe((data:any)=>{
      this.isLoading = false;
      if(data && data.IsSuccess){
        this.searchData = data;
        this.searchData.TrackOrder_List.forEach((element:any)=>{
          element.F_Date = this.datePipe.transform(new Date(element.F_Date), "dd/MM/yyyy");
          element.Cour_Date = this.datePipe.transform(new Date(element.Cour_Date), "dd/MM/yyyy");
          element.qrCodeImage = 'data:image/jpeg;base64,'+ element.qrCodeImage;
        });
        this.item = this.searchData.TrackOrder_List[this.itemIndex];
      }else{
        this.item = [];
      }
      this.router.navigate(['/admin-track-order'], { state: { data: this.item }, replaceUrl: false });
  });
  }

  toggleEditNotes(orderItemId, note) {

    this.showEditNote(orderItemId, note);
  }

  showEditNote(orderItemId, note) {
    const data = {
      header: `Edit Internal Note`,
      note: ''
    };
    this.modalRef = this.modalService.show(InternalNoteComponent, {
      ...this.modalOptions, data
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.updateInternalNote(orderItemId, result.note);
    });
  }

  hideEditNoteInputField(orderItemId, note) {
    if (this.editNotes[orderItemId]) {
      delete this.editNotes[orderItemId];
    }
  }

  updateInternalNote(orderItemId, note) {
    this.httpService.updateOrderInternalNote(this.orderId, note).subscribe((response) => {
      if (response) {
        this.toastService.success('Internal note updated successfully');
        this.orderItem.InternalNote = response.data.replace(/\\n/g, '<br /><br />');
        this.modalRef.hide();
      } else {
        this.toastService.error('something went wrong.please try after sometimes');

      }
    }, (error) => {
      this.toastService.error('something went wrong.please try after sometimes');

    });
  }

  showOrderProfit(profit) {
    const data = {
      header: `Order Profit`,
      profit
    };
    this.showPopup(data);
  }

  showPopup(data) {
    this.modalRef = this.modalService.show(OrderProfitComponent, {
      ...this.modalOptions, data
    });
  }

  setPaymentReceived() {
    this.httpService.updatePaymentAsReceived(this.orderId).subscribe((response: any) => {
      if (response) {
        this.orderItem.isPaymentReceived = true;
        this.toastService.success('Payment was set as received');
      }
    });
  }

  navigateToStatus() {
    const lineId = this.lineId;
    const customerID = this.customerID;
    const finishedPL = history.state.finishedPL;
    if (history.state.type === 'orderFromProductionLine') {
      const orderData = {
        'orderID': this.subOrderItemData.OrderID,
        'orderItemID': this.subOrderItemData.OrderItemID,
        'isSubOrder': this.subOrderItemData.isSubOrder ? this.subOrderItemData.isSubOrder : false,
        'suborderID': this.subOrderItemData.SuborderID ? this.subOrderItemData.SuborderID : ''
      };
      this.router.navigate(['/view-order'], {
        state: {
          data: orderData, lineId, customerID, finishedPL,
          subOrderItemData: this.subOrderItemData, type: 'orderFromProductionLine'
        }, replaceUrl: false
      });
    } else if (history.state.type === 'productionline') {
      const orderData = {
        'orderID': this.subOrderItemData.OrderID,
        'orderItemID': this.subOrderItemData.OrderItemID,
        'isSubOrder': this.subOrderItemData.isSubOrder ? this.subOrderItemData.isSubOrder : false,
        'suborderID': this.subOrderItemData.SuborderID ? this.subOrderItemData.SuborderID : ''
      };
      this.router.navigate(['/view-order'], {
        state: {
          data: orderData, lineId, customerID, finishedPL, type: 'orderOverviewFromProductionLine'
        },
        replaceUrl: false
      });
    } else {
      const orderData = {
        'orderID': this.subOrderItemData.OrderID,
        'orderItemID': this.subOrderItemData.OrderItems[0].OrderItemID,
        'isSubOrder': history.state.isSubOrder ? true : false,
        'suborderID': this.subOrderItemData.SuborderID ? this.subOrderItemData.SuborderID : ''
      };
      const lineId = this.lineId;
      const customerID = this.customerID;
      const subOrderItemData = this.subOrderItemData;
      this.router.navigate(['/view-order'], { state: { data: orderData, lineId, customerID, finishedPL, type: 'orderOverview', subOrderItemData }, replaceUrl: false });
    }
  }
}
