import { Injectable } from '@angular/core';
import { Response, ResponseContentType } from '@angular/http';
// import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/Rx';
// tslint:disable-next-line:import-blacklist
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WebshopService {
  public pantoneCodes = [
    { 'color': 'pantone-white-hvid' },
    { 'color': 'pantone-Black-sort' },
    { 'color': 'pantone-Yellow' },
    { 'color': 'pantone-Yellow-012' },
    { 'color': 'pantone-Orange-021' },
    { 'color': 'pantone-Warm-Red' },
    { 'color': 'pantone-Red-032' },
    { 'color': 'pantone-Rubine-Red' },
    { 'color': 'pantone-Rhodamine-Red' },
    { 'color': 'pantone-Purple' },
    { 'color': 'pantone-Violet' },
    { 'color': 'pantone-Blue-072' },
    { 'color': 'pantone-Reflex-Blue' },
    { 'color': 'pantone-Process-Blue' },
    { 'color': 'pantone-Green' },
    { 'color': 'pantone-Yellow-0131' },
    { 'color': 'pantone-Red-0331' },
    { 'color': 'pantone-Magenta-0521' },
    { 'color': 'pantone-Violet-0631' },
    { 'color': 'pantone-Blue-0821' },
    { 'color': 'pantone-Green-0921' },
    { 'color': 'pantone-Black-0961' },
    { 'color': 'pantone-801' },
    { 'color': 'pantone-802' },
    { 'color': 'pantone-803' },
    { 'color': 'pantone-804' },
    { 'color': 'pantone-805' },
    { 'color': 'pantone-806' },
    { 'color': 'pantone-807' },
    { 'color': 'pantone-871' },
    { 'color': 'pantone-872' },
    { 'color': 'pantone-873' },
    { 'color': 'pantone-874' },
    { 'color': 'pantone-875' },
    { 'color': 'pantone-876' },
    { 'color': 'pantone-877' },
    { 'color': 'pantone-Medium-Yellow' },
    { 'color': 'pantone-Bright-Orange' },
    { 'color': 'pantone-Bright-Red' },
    { 'color': 'pantone-Strong-Red' },
    { 'color': 'pantone-Pink' },
    { 'color': 'pantone-Medium-Purple' },
    { 'color': 'pantone-Dark-Blue' },
    { 'color': 'pantone-Medium-Blue' },
    { 'color': 'pantone-Bright-Green' },
    { 'color': 'pantone-Neutral-Black' },
    { 'color': 'pantone-100' },
    { 'color': 'pantone-101' },
    { 'color': 'pantone-102' },
    { 'color': 'pantone-103' },
    { 'color': 'pantone-104' },
    { 'color': 'pantone-105' },
    { 'color': 'pantone-7401' },
    { 'color': 'pantone-7402' },
    { 'color': 'pantone-7403' },
    { 'color': 'pantone-7404' },
    { 'color': 'pantone-7405' },
    { 'color': 'pantone-7406' },
    { 'color': 'pantone-7407' },
    { 'color': 'pantone-106' },
    { 'color': 'pantone-107' },
    { 'color': 'pantone-108' },
    { 'color': 'pantone-109' },
    { 'color': 'pantone-110' },
    { 'color': 'pantone-111' },
    { 'color': 'pantone-112' },
    { 'color': 'pantone-113' },
    { 'color': 'pantone-114' },
    { 'color': 'pantone-115' },
    { 'color': 'pantone-116' },
    { 'color': 'pantone-117' },
    { 'color': 'pantone-118' },
    { 'color': 'pantone-119' },
    { 'color': 'pantone-127' },
    { 'color': 'pantone-128' },
    { 'color': 'pantone-129' },
    { 'color': 'pantone-130' },
    { 'color': 'pantone-131' },
    { 'color': 'pantone-132' },
    { 'color': 'pantone-133' },
    { 'color': 'pantone-1205' },
    { 'color': 'pantone-1215' },
    { 'color': 'pantone-1225' },
    { 'color': 'pantone-1235' },
    { 'color': 'pantone-1245' },
    { 'color': 'pantone-1255' },
    { 'color': 'pantone-1265' },
    { 'color': 'pantone-120' },
    { 'color': 'pantone-121' },
    { 'color': 'pantone-122' },
    { 'color': 'pantone-123' },
    { 'color': 'pantone-124' },
    { 'color': 'pantone-125' },
    { 'color': 'pantone-126' },
    { 'color': 'pantone-7548' },
    { 'color': 'pantone-7549' },
    { 'color': 'pantone-7550' },
    { 'color': 'pantone-7551' },
    { 'color': 'pantone-7552' },
    { 'color': 'pantone-7553' },
    { 'color': 'pantone-7554' },
    { 'color': 'pantone-7555' },
    { 'color': 'pantone-7556' },
    { 'color': 'pantone-7557' },
    { 'color': 'pantone-7558' },
    { 'color': 'pantone-7559' },
    { 'color': 'pantone-7560' },
    { 'color': 'pantone-7561' },
    { 'color': 'pantone-134' },
    { 'color': 'pantone-135' },
    { 'color': 'pantone-136' },
    { 'color': 'pantone-137' },
    { 'color': 'pantone-138' },
    { 'color': 'pantone-139' },
    { 'color': 'pantone-140' },
    { 'color': 'pantone-1345' },
    { 'color': 'pantone-1355' },
    { 'color': 'pantone-1365' },
    { 'color': 'pantone-1375' },
    { 'color': 'pantone-1385' },
    { 'color': 'pantone-1395' },
    { 'color': 'pantone-1405' },
    { 'color': 'pantone-141' },
    { 'color': 'pantone-142' },
    { 'color': 'pantone-143' },
    { 'color': 'pantone-144' },
    { 'color': 'pantone-145' },
    { 'color': 'pantone-146' },
    { 'color': 'pantone-147' },
    { 'color': 'pantone-7408' },
    { 'color': 'pantone-7409' },
    { 'color': 'pantone-7410' },
    { 'color': 'pantone-7411' },
    { 'color': 'pantone-7412' },
    { 'color': 'pantone-7413' },
    { 'color': 'pantone-7414' },
    { 'color': 'pantone-7562' },
    { 'color': 'pantone-7563' },
    { 'color': 'pantone-7564' },
    { 'color': 'pantone-7565' },
    { 'color': 'pantone-7566' },
    { 'color': 'pantone-7567' },
    { 'color': 'pantone-7568' },
    { 'color': 'pantone-7569' },
    { 'color': 'pantone-7570' },
    { 'color': 'pantone-7571' },
    { 'color': 'pantone-7572' },
    { 'color': 'pantone-7573' },
    { 'color': 'pantone-7574' },
    { 'color': 'pantone-7575' },
    { 'color': 'pantone-712' },
    { 'color': 'pantone-713' },
    { 'color': 'pantone-714' },
    { 'color': 'pantone-715' },
    { 'color': 'pantone-716' },
    { 'color': 'pantone-717' },
    { 'color': 'pantone-718' },
    { 'color': 'pantone-148' },
    { 'color': 'pantone-149' },
    { 'color': 'pantone-150' },
    { 'color': 'pantone-151' },
    { 'color': 'pantone-152' },
    { 'color': 'pantone-153' },
    { 'color': 'pantone-154' },
    { 'color': 'pantone-155' },
    { 'color': 'pantone-156' },
    { 'color': 'pantone-157' },
    { 'color': 'pantone-158' },
    { 'color': 'pantone-159' },
    { 'color': 'pantone-160' },
    { 'color': 'pantone-161' },
    { 'color': 'pantone-1485' },
    { 'color': 'pantone-1495' },
    { 'color': 'pantone-1505' },
    { 'color': 'pantone-1525' },
    { 'color': 'pantone-1535' },
    { 'color': 'pantone-1545' },
    { 'color': 'pantone-1555' },
    { 'color': 'pantone-1565' },
    { 'color': 'pantone-1575' },
    { 'color': 'pantone-1585' },
    { 'color': 'pantone-1595' },
    { 'color': 'pantone-1605' },
    { 'color': 'pantone-1615' },
    { 'color': 'pantone-162' },
    { 'color': 'pantone-163' },
    { 'color': 'pantone-164' },
    { 'color': 'pantone-165' },
    { 'color': 'pantone-166' },
    { 'color': 'pantone-167' },
    { 'color': 'pantone-168' },
    { 'color': 'pantone-7576' },
    { 'color': 'pantone-7577' },
    { 'color': 'pantone-7578' },
    { 'color': 'pantone-7579' },
    { 'color': 'pantone-7580' },
    { 'color': 'pantone-7581' },
    { 'color': 'pantone-7582' },
    { 'color': 'pantone-1625' },
    { 'color': 'pantone-1635' },
    { 'color': 'pantone-1645' },
    { 'color': 'pantone-1655' },
    { 'color': 'pantone-1665' },
    { 'color': 'pantone-1675' },
    { 'color': 'pantone-1685' },
    { 'color': 'pantone-169' },
    { 'color': 'pantone-170' },
    { 'color': 'pantone-171' },
    { 'color': 'pantone-172' },
    { 'color': 'pantone-173' },
    { 'color': 'pantone-174' },
    { 'color': 'pantone-175' },
    { 'color': 'pantone-7583' },
    { 'color': 'pantone-7584' },
    { 'color': 'pantone-7585' },
    { 'color': 'pantone-7586' },
    { 'color': 'pantone-7587' },
    { 'color': 'pantone-7588' },
    { 'color': 'pantone-7589' },
    { 'color': 'pantone-7590' },
    { 'color': 'pantone-7591' },
    { 'color': 'pantone-7592' },
    { 'color': 'pantone-7593' },
    { 'color': 'pantone-7594' },
    { 'color': 'pantone-7595' },
    { 'color': 'pantone-7596' },
    { 'color': 'pantone-7597' },
    { 'color': 'pantone-7598' },
    { 'color': 'pantone-7599' },
    { 'color': 'pantone-7600' },
    { 'color': 'pantone-7601' },
    { 'color': 'pantone-7602' },
    { 'color': 'pantone-7603' },
    { 'color': 'pantone-7604' },
    { 'color': 'pantone-7605' },
    { 'color': 'pantone-7606' },
    { 'color': 'pantone-7607' },
    { 'color': 'pantone-7608' },
    { 'color': 'pantone-7609' },
    { 'color': 'pantone-7610' },
    { 'color': 'pantone-7611' },
    { 'color': 'pantone-7612' },
    { 'color': 'pantone-7613' },
    { 'color': 'pantone-7614' },
    { 'color': 'pantone-7615' },
    { 'color': 'pantone-7616' },
    { 'color': 'pantone-7617' },
    { 'color': 'pantone-7520' },
    { 'color': 'pantone-7521' },
    { 'color': 'pantone-7522' },
    { 'color': 'pantone-7523' },
    { 'color': 'pantone-7524' },
    { 'color': 'pantone-7525' },
    { 'color': 'pantone-7526' },
    { 'color': 'pantone-489' },
    { 'color': 'pantone-488' },
    { 'color': 'pantone-487' },
    { 'color': 'pantone-486' },
    { 'color': 'pantone-485' },
    { 'color': 'pantone-484' },
    { 'color': 'pantone-483' },
    { 'color': 'pantone-176' },
    { 'color': 'pantone-177' },
    { 'color': 'pantone-178' },
    { 'color': 'pantone-179' },
    { 'color': 'pantone-180' },
    { 'color': 'pantone-181' },
    { 'color': 'pantone-1765' },
    { 'color': 'pantone-1775' },
    { 'color': 'pantone-1785' },
    { 'color': 'pantone-1788' },
    { 'color': 'pantone-1795' },
    { 'color': 'pantone-1805' },
    { 'color': 'pantone-1815' },
    { 'color': 'pantone-1767' },
    { 'color': 'pantone-1777' },
    { 'color': 'pantone-1787' },
    { 'color': 'pantone-1797' },
    { 'color': 'pantone-1807' },
    { 'color': 'pantone-1817' },
    { 'color': 'pantone-7618' },
    { 'color': 'pantone-7619' },
    { 'color': 'pantone-7620' },
    { 'color': 'pantone-7621' },
    { 'color': 'pantone-7622' },
    { 'color': 'pantone-7623' },
    { 'color': 'pantone-7624' },
    { 'color': 'pantone-7625' },
    { 'color': 'pantone-7626' },
    { 'color': 'pantone-7627' },
    { 'color': 'pantone-7628' },
    { 'color': 'pantone-7629' },
    { 'color': 'pantone-7630' },
    { 'color': 'pantone-7631' },
    { 'color': 'pantone-7415' },
    { 'color': 'pantone-7416' },
    { 'color': 'pantone-7417' },
    { 'color': 'pantone-7418' },
    { 'color': 'pantone-7419' },
    { 'color': 'pantone-7420' },
    { 'color': 'pantone-7421' },
    { 'color': 'pantone-182' },
    { 'color': 'pantone-183' },
    { 'color': 'pantone-184' },
    { 'color': 'pantone-185' },
    { 'color': 'pantone-186' },
    { 'color': 'pantone-187' },
    { 'color': 'pantone-188' },
    { 'color': 'pantone-196' },
    { 'color': 'pantone-197' },
    { 'color': 'pantone-198' },
    { 'color': 'pantone-199' },
    { 'color': 'pantone-200' },
    { 'color': 'pantone-201' },
    { 'color': 'pantone-202' },
    { 'color': 'pantone-189' },
    { 'color': 'pantone-190' },
    { 'color': 'pantone-191' },
    { 'color': 'pantone-192' },
    { 'color': 'pantone-193' },
    { 'color': 'pantone-194' },
    { 'color': 'pantone-195' },
    { 'color': 'pantone-1895' },
    { 'color': 'pantone-1905' },
    { 'color': 'pantone-1915' },
    { 'color': 'pantone-1925' },
    { 'color': 'pantone-1935' },
    { 'color': 'pantone-1945' },
    { 'color': 'pantone-1955' },
    { 'color': 'pantone-705' },
    { 'color': 'pantone-706' },
    { 'color': 'pantone-707' },
    { 'color': 'pantone-708' },
    { 'color': 'pantone-709' },
    { 'color': 'pantone-710' },
    { 'color': 'pantone-711' },
    { 'color': 'pantone-698' },
    { 'color': 'pantone-699' },
    { 'color': 'pantone-700' },
    { 'color': 'pantone-701' },
    { 'color': 'pantone-702' },
    { 'color': 'pantone-703' },
    { 'color': 'pantone-704' },
    { 'color': 'pantone-203' },
    { 'color': 'pantone-204' },
    { 'color': 'pantone-205' },
    { 'color': 'pantone-206' },
    { 'color': 'pantone-207' },
    { 'color': 'pantone-208' },
    { 'color': 'pantone-209' },
    { 'color': 'pantone-210' },
    { 'color': 'pantone-211' },
    { 'color': 'pantone-212' },
    { 'color': 'pantone-213' },
    { 'color': 'pantone-214' },
    { 'color': 'pantone-215' },
    { 'color': 'pantone-216' },
    { 'color': 'pantone-7422' },
    { 'color': 'pantone-7423' },
    { 'color': 'pantone-7424' },
    { 'color': 'pantone-7425' },
    { 'color': 'pantone-7426' },
    { 'color': 'pantone-7427' },
    { 'color': 'pantone-7428' },
    { 'color': 'pantone-7632' },
    { 'color': 'pantone-7633' },
    { 'color': 'pantone-7634' },
    { 'color': 'pantone-7635' },
    { 'color': 'pantone-7636' },
    { 'color': 'pantone-7637' },
    { 'color': 'pantone-7638' },
    { 'color': 'pantone-217' },
    { 'color': 'pantone-218' },
    { 'color': 'pantone-219' },
    { 'color': 'pantone-220' },
    { 'color': 'pantone-221' },
    { 'color': 'pantone-222' },
    { 'color': 'pantone-7639' },
    { 'color': 'pantone-7640' },
    { 'color': 'pantone-7641' },
    { 'color': 'pantone-7642' },
    { 'color': 'pantone-7643' },
    { 'color': 'pantone-7644' },
    { 'color': 'pantone-7645' },
    { 'color': 'pantone-223' },
    { 'color': 'pantone-224' },
    { 'color': 'pantone-225' },
    { 'color': 'pantone-226' },
    { 'color': 'pantone-227' },
    { 'color': 'pantone-228' },
    { 'color': 'pantone-229' },
    { 'color': 'pantone-230' },
    { 'color': 'pantone-231' },
    { 'color': 'pantone-232' },
    { 'color': 'pantone-233' },
    { 'color': 'pantone-234' },
    { 'color': 'pantone-235' },
    { 'color': 'pantone-670' },
    { 'color': 'pantone-671' },
    { 'color': 'pantone-672' },
    { 'color': 'pantone-673' },
    { 'color': 'pantone-674' },
    { 'color': 'pantone-675' },
    { 'color': 'pantone-676' },
    { 'color': 'pantone-677' },
    { 'color': 'pantone-678' },
    { 'color': 'pantone-679' },
    { 'color': 'pantone-680' },
    { 'color': 'pantone-681' },
    { 'color': 'pantone-682' },
    { 'color': 'pantone-683' },
    { 'color': 'pantone-684' },
    { 'color': 'pantone-685' },
    { 'color': 'pantone-686' },
    { 'color': 'pantone-687' },
    { 'color': 'pantone-688' },
    { 'color': 'pantone-689' },
    { 'color': 'pantone-690' },
    { 'color': 'pantone-510' },
    { 'color': 'pantone-509' },
    { 'color': 'pantone-508' },
    { 'color': 'pantone-507' },
    { 'color': 'pantone-506' },
    { 'color': 'pantone-505' },
    { 'color': 'pantone-504' },
    { 'color': 'pantone-7429' },
    { 'color': 'pantone-7430' },
    { 'color': 'pantone-7431' },
    { 'color': 'pantone-7432' },
    { 'color': 'pantone-7433' },
    { 'color': 'pantone-7434' },
    { 'color': 'pantone-7435' },
    { 'color': 'pantone-691' },
    { 'color': 'pantone-692' },
    { 'color': 'pantone-693' },
    { 'color': 'pantone-694' },
    { 'color': 'pantone-695' },
    { 'color': 'pantone-696' },
    { 'color': 'pantone-697' },
    { 'color': 'pantone-496' },
    { 'color': 'pantone-495' },
    { 'color': 'pantone-494' },
    { 'color': 'pantone-493' },
    { 'color': 'pantone-492' },
    { 'color': 'pantone-491' },
    { 'color': 'pantone-490' },
    { 'color': 'pantone-503' },
    { 'color': 'pantone-502' },
    { 'color': 'pantone-501' },
    { 'color': 'pantone-500' },
    { 'color': 'pantone-499' },
    { 'color': 'pantone-498' },
    { 'color': 'pantone-497' },
    { 'color': 'pantone-5035' },
    { 'color': 'pantone-5025' },
    { 'color': 'pantone-5015' },
    { 'color': 'pantone-5005' },
    { 'color': 'pantone-4995' },
    { 'color': 'pantone-4985' },
    { 'color': 'pantone-4975' },
    { 'color': 'pantone-236' },
    { 'color': 'pantone-237' },
    { 'color': 'pantone-238' },
    { 'color': 'pantone-239' },
    { 'color': 'pantone-240' },
    { 'color': 'pantone-241' },
    { 'color': 'pantone-242' },
    { 'color': 'pantone-2365' },
    { 'color': 'pantone-2375' },
    { 'color': 'pantone-2385' },
    { 'color': 'pantone-2395' },
    { 'color': 'pantone-2405' },
    { 'color': 'pantone-2415' },
    { 'color': 'pantone-2425' },
    { 'color': 'pantone-243' },
    { 'color': 'pantone-244' },
    { 'color': 'pantone-245' },
    { 'color': 'pantone-246' },
    { 'color': 'pantone-247' },
    { 'color': 'pantone-248' },
    { 'color': 'pantone-249' },
    { 'color': 'pantone-7646' },
    { 'color': 'pantone-7647' },
    { 'color': 'pantone-7648' },
    { 'color': 'pantone-7649' },
    { 'color': 'pantone-7650' },
    { 'color': 'pantone-7651' },
    { 'color': 'pantone-7652' },
    { 'color': 'pantone-250' },
    { 'color': 'pantone-251' },
    { 'color': 'pantone-252' },
    { 'color': 'pantone-253' },
    { 'color': 'pantone-254' },
    { 'color': 'pantone-255' },
    { 'color': 'pantone-517' },
    { 'color': 'pantone-516' },
    { 'color': 'pantone-515' },
    { 'color': 'pantone-514' },
    { 'color': 'pantone-513' },
    { 'color': 'pantone-512' },
    { 'color': 'pantone-511' },
    { 'color': 'pantone-7436' },
    { 'color': 'pantone-7437' },
    { 'color': 'pantone-7438' },
    { 'color': 'pantone-7439' },
    { 'color': 'pantone-7440' },
    { 'color': 'pantone-7441' },
    { 'color': 'pantone-7442' },
    { 'color': 'pantone-2562' },
    { 'color': 'pantone-2572' },
    { 'color': 'pantone-2582' },
    { 'color': 'pantone-2592' },
    { 'color': 'pantone-2602' },
    { 'color': 'pantone-2612' },
    { 'color': 'pantone-2622' },
    { 'color': 'pantone-7653' },
    { 'color': 'pantone-7654' },
    { 'color': 'pantone-7655' },
    { 'color': 'pantone-7656' },
    { 'color': 'pantone-7657' },
    { 'color': 'pantone-7658' },
    { 'color': 'pantone-7659' },
    { 'color': 'pantone-524' },
    { 'color': 'pantone-523' },
    { 'color': 'pantone-522' },
    { 'color': 'pantone-521' },
    { 'color': 'pantone-520' },
    { 'color': 'pantone-519' },
    { 'color': 'pantone-518' },
    { 'color': 'pantone-5245' },
    { 'color': 'pantone-5235' },
    { 'color': 'pantone-5225' },
    { 'color': 'pantone-5215' },
    { 'color': 'pantone-5205' },
    { 'color': 'pantone-5195' },
    { 'color': 'pantone-5185' },
    { 'color': 'pantone-5175' },
    { 'color': 'pantone-5165' },
    { 'color': 'pantone-5155' },
    { 'color': 'pantone-5145' },
    { 'color': 'pantone-5135' },
    { 'color': 'pantone-5125' },
    { 'color': 'pantone-5115' },
    { 'color': 'pantone-531' },
    { 'color': 'pantone-530' },
    { 'color': 'pantone-529' },
    { 'color': 'pantone-528' },
    { 'color': 'pantone-527' },
    { 'color': 'pantone-526' },
    { 'color': 'pantone-525' },
    { 'color': 'pantone-256' },
    { 'color': 'pantone-257' },
    { 'color': 'pantone-258' },
    { 'color': 'pantone-259' },
    { 'color': 'pantone-260' },
    { 'color': 'pantone-261' },
    { 'color': 'pantone-262' },
    { 'color': 'pantone-2563' },
    { 'color': 'pantone-2573' },
    { 'color': 'pantone-2583' },
    { 'color': 'pantone-2593' },
    { 'color': 'pantone-2603' },
    { 'color': 'pantone-2613' },
    { 'color': 'pantone-2623' },
    { 'color': 'pantone-7660' },
    { 'color': 'pantone-7661' },
    { 'color': 'pantone-7662' },
    { 'color': 'pantone-7663' },
    { 'color': 'pantone-7664' },
    { 'color': 'pantone-7665' },
    { 'color': 'pantone-7666' },
    { 'color': 'pantone-2567' },
    { 'color': 'pantone-2577' },
    { 'color': 'pantone-2587' },
    { 'color': 'pantone-2597' },
    { 'color': 'pantone-2607' },
    { 'color': 'pantone-2617' },
    { 'color': 'pantone-2627' },
    { 'color': 'pantone-263' },
    { 'color': 'pantone-264' },
    { 'color': 'pantone-265' },
    { 'color': 'pantone-266' },
    { 'color': 'pantone-267' },
    { 'color': 'pantone-268' },
    { 'color': 'pantone-269' },
    { 'color': 'pantone-2635' },
    { 'color': 'pantone-2645' },
    { 'color': 'pantone-2655' },
    { 'color': 'pantone-2665' },
    { 'color': 'pantone-2685' },
    { 'color': 'pantone-2695' },
    { 'color': 'pantone-270' },
    { 'color': 'pantone-271' },
    { 'color': 'pantone-272' },
    { 'color': 'pantone-273' },
    { 'color': 'pantone-274' },
    { 'color': 'pantone-275' },
    { 'color': 'pantone-276' },
    { 'color': 'pantone-2705' },
    { 'color': 'pantone-2715' },
    { 'color': 'pantone-2725' },
    { 'color': 'pantone-2735' },
    { 'color': 'pantone-2745' },
    { 'color': 'pantone-2755' },
    { 'color': 'pantone-2765' },
    { 'color': 'pantone-7667' },
    { 'color': 'pantone-7668' },
    { 'color': 'pantone-7669' },
    { 'color': 'pantone-7670' },
    { 'color': 'pantone-7671' },
    { 'color': 'pantone-7672' },
    { 'color': 'pantone-7673' },
    { 'color': 'pantone-7443' },
    { 'color': 'pantone-7444' },
    { 'color': 'pantone-7445' },
    { 'color': 'pantone-7446' },
    { 'color': 'pantone-7447' },
    { 'color': 'pantone-7448' },
    { 'color': 'pantone-7449' },
    { 'color': 'pantone-7674' },
    { 'color': 'pantone-7675' },
    { 'color': 'pantone-7676' },
    { 'color': 'pantone-7677' },
    { 'color': 'pantone-7678' },
    { 'color': 'pantone-7679' },
    { 'color': 'pantone-7680' },
    { 'color': 'pantone-663' },
    { 'color': 'pantone-664' },
    { 'color': 'pantone-665' },
    { 'color': 'pantone-666' },
    { 'color': 'pantone-667' },
    { 'color': 'pantone-668' },
    { 'color': 'pantone-669' },
    { 'color': 'pantone-5315' },
    { 'color': 'pantone-5305' },
    { 'color': 'pantone-5295' },
    { 'color': 'pantone-5285' },
    { 'color': 'pantone-5275' },
    { 'color': 'pantone-5265' },
    { 'color': 'pantone-5255' },
    { 'color': 'pantone-538' },
    { 'color': 'pantone-537' },
    { 'color': 'pantone-536' },
    { 'color': 'pantone-535' },
    { 'color': 'pantone-534' },
    { 'color': 'pantone-533' },
    { 'color': 'pantone-532' },
    { 'color': 'pantone-7541' },
    { 'color': 'pantone-7542' },
    { 'color': 'pantone-7543' },
    { 'color': 'pantone-7544' },
    { 'color': 'pantone-7545' },
    { 'color': 'pantone-7546' },
    { 'color': 'pantone-7547' },
    { 'color': 'pantone-552' },
    { 'color': 'pantone-551' },
    { 'color': 'pantone-550' },
    { 'color': 'pantone-549' },
    { 'color': 'pantone-548' },
    { 'color': 'pantone-547' },
    { 'color': 'pantone-546' },
    { 'color': 'pantone-5455' },
    { 'color': 'pantone-5445' },
    { 'color': 'pantone-5435' },
    { 'color': 'pantone-5425' },
    { 'color': 'pantone-5415' },
    { 'color': 'pantone-5405' },
    { 'color': 'pantone-5395' },
    { 'color': 'pantone-642' },
    { 'color': 'pantone-643' },
    { 'color': 'pantone-644' },
    { 'color': 'pantone-645' },
    { 'color': 'pantone-646' },
    { 'color': 'pantone-647' },
    { 'color': 'pantone-648' },
    { 'color': 'pantone-649' },
    { 'color': 'pantone-650' },
    { 'color': 'pantone-651' },
    { 'color': 'pantone-652' },
    { 'color': 'pantone-653' },
    { 'color': 'pantone-654' },
    { 'color': 'pantone-655' },
    { 'color': 'pantone-656' },
    { 'color': 'pantone-657' },
    { 'color': 'pantone-658' },
    { 'color': 'pantone-659' },
    { 'color': 'pantone-660' },
    { 'color': 'pantone-661' },
    { 'color': 'pantone-662' },
    { 'color': 'pantone-7450' },
    { 'color': 'pantone-7451' },
    { 'color': 'pantone-7452' },
    { 'color': 'pantone-7453' },
    { 'color': 'pantone-7454' },
    { 'color': 'pantone-7455' },
    { 'color': 'pantone-7456' },
    { 'color': 'pantone-2706' },
    { 'color': 'pantone-2716' },
    { 'color': 'pantone-2726' },
    { 'color': 'pantone-2736' },
    { 'color': 'pantone-2746' },
    { 'color': 'pantone-2756' },
    { 'color': 'pantone-2766' },
    { 'color': 'pantone-2708' },
    { 'color': 'pantone-2718' },
    { 'color': 'pantone-2728' },
    { 'color': 'pantone-2738' },
    { 'color': 'pantone-2748' },
    { 'color': 'pantone-2758' },
    { 'color': 'pantone-2768' },
    { 'color': 'pantone-2707' },
    { 'color': 'pantone-2717' },
    { 'color': 'pantone-2727' },
    { 'color': 'pantone-2747' },
    { 'color': 'pantone-2757' },
    { 'color': 'pantone-2767' },
    { 'color': 'pantone-277' },
    { 'color': 'pantone-278' },
    { 'color': 'pantone-279' },
    { 'color': 'pantone-280' },
    { 'color': 'pantone-281' },
    { 'color': 'pantone-282' },
    { 'color': 'pantone-283' },
    { 'color': 'pantone-284' },
    { 'color': 'pantone-285' },
    { 'color': 'pantone-286' },
    { 'color': 'pantone-287' },
    { 'color': 'pantone-288' },
    { 'color': 'pantone-289' },
    { 'color': 'pantone-7681' },
    { 'color': 'pantone-7682' },
    { 'color': 'pantone-7683' },
    { 'color': 'pantone-7684' },
    { 'color': 'pantone-7685' },
    { 'color': 'pantone-7686' },
    { 'color': 'pantone-7687' },
    { 'color': 'pantone-545' },
    { 'color': 'pantone-544' },
    { 'color': 'pantone-543' },
    { 'color': 'pantone-542' },
    { 'color': 'pantone-541' },
    { 'color': 'pantone-540' },
    { 'color': 'pantone-539' },
    { 'color': 'pantone-290' },
    { 'color': 'pantone-291' },
    { 'color': 'pantone-292' },
    { 'color': 'pantone-293' },
    { 'color': 'pantone-294' },
    { 'color': 'pantone-295' },
    { 'color': 'pantone-296' },
    { 'color': 'pantone-2905' },
    { 'color': 'pantone-2915' },
    { 'color': 'pantone-2925' },
    { 'color': 'pantone-2935' },
    { 'color': 'pantone-2945' },
    { 'color': 'pantone-2955' },
    { 'color': 'pantone-2965' },
    { 'color': 'pantone-297' },
    { 'color': 'pantone-298' },
    { 'color': 'pantone-299' },
    { 'color': 'pantone-300' },
    { 'color': 'pantone-301' },
    { 'color': 'pantone-302' },
    { 'color': 'pantone-303' },
    { 'color': 'pantone-7688' },
    { 'color': 'pantone-7689' },
    { 'color': 'pantone-7690' },
    { 'color': 'pantone-7691' },
    { 'color': 'pantone-7692' },
    { 'color': 'pantone-7693' },
    { 'color': 'pantone-7694' },
    { 'color': 'pantone-2975' },
    { 'color': 'pantone-2985' },
    { 'color': 'pantone-2995' },
    { 'color': 'pantone-3005' },
    { 'color': 'pantone-3015' },
    { 'color': 'pantone-3025' },
    { 'color': 'pantone-3035' },
    { 'color': 'pantone-7695' },
    { 'color': 'pantone-7696' },
    { 'color': 'pantone-7697' },
    { 'color': 'pantone-7698' },
    { 'color': 'pantone-7699' },
    { 'color': 'pantone-7700' },
    { 'color': 'pantone-7701' },
    { 'color': 'pantone-7457' },
    { 'color': 'pantone-7458' },
    { 'color': 'pantone-7459' },
    { 'color': 'pantone-7460' },
    { 'color': 'pantone-7461' },
    { 'color': 'pantone-7462' },
    { 'color': 'pantone-7463' },
    { 'color': 'pantone-304' },
    { 'color': 'pantone-305' },
    { 'color': 'pantone-306' },
    { 'color': 'pantone-307' },
    { 'color': 'pantone-308' },
    { 'color': 'pantone-309' },
    { 'color': 'pantone-635' },
    { 'color': 'pantone-636' },
    { 'color': 'pantone-637' },
    { 'color': 'pantone-638' },
    { 'color': 'pantone-639' },
    { 'color': 'pantone-640' },
    { 'color': 'pantone-641' },
    { 'color': 'pantone-7702' },
    { 'color': 'pantone-7703' },
    { 'color': 'pantone-7704' },
    { 'color': 'pantone-7705' },
    { 'color': 'pantone-7706' },
    { 'color': 'pantone-7707' },
    { 'color': 'pantone-7708' },
    { 'color': 'pantone-628' },
    { 'color': 'pantone-629' },
    { 'color': 'pantone-630' },
    { 'color': 'pantone-631' },
    { 'color': 'pantone-632' },
    { 'color': 'pantone-633' },
    { 'color': 'pantone-634' },
    { 'color': 'pantone-310' },
    { 'color': 'pantone-311' },
    { 'color': 'pantone-312' },
    { 'color': 'pantone-313' },
    { 'color': 'pantone-314' },
    { 'color': 'pantone-315' },
    { 'color': 'pantone-316' },
    { 'color': 'pantone-3105' },
    { 'color': 'pantone-3115' },
    { 'color': 'pantone-3125' },
    { 'color': 'pantone-3135' },
    { 'color': 'pantone-3145' },
    { 'color': 'pantone-3155' },
    { 'color': 'pantone-3165' },
    { 'color': 'pantone-7709' },
    { 'color': 'pantone-7710' },
    { 'color': 'pantone-7711' },
    { 'color': 'pantone-7712' },
    { 'color': 'pantone-7713' },
    { 'color': 'pantone-7714' },
    { 'color': 'pantone-7715' },
    { 'color': 'pantone-317' },
    { 'color': 'pantone-318' },
    { 'color': 'pantone-319' },
    { 'color': 'pantone-320' },
    { 'color': 'pantone-321' },
    { 'color': 'pantone-322' },
    { 'color': 'pantone-323' },
    { 'color': 'pantone-7464' },
    { 'color': 'pantone-7465' },
    { 'color': 'pantone-7466' },
    { 'color': 'pantone-7467' },
    { 'color': 'pantone-7468' },
    { 'color': 'pantone-7469' },
    { 'color': 'pantone-7470' },
    { 'color': 'pantone-7471' },
    { 'color': 'pantone-7472' },
    { 'color': 'pantone-7473' },
    { 'color': 'pantone-7474' },
    { 'color': 'pantone-7475' },
    { 'color': 'pantone-7476' },
    { 'color': 'pantone-7477' },
    { 'color': 'pantone-5523' },
    { 'color': 'pantone-5513' },
    { 'color': 'pantone-5503' },
    { 'color': 'pantone-5493' },
    { 'color': 'pantone-5483' },
    { 'color': 'pantone-5473' },
    { 'color': 'pantone-5463' },
    { 'color': 'pantone-7716' },
    { 'color': 'pantone-7717' },
    { 'color': 'pantone-7718' },
    { 'color': 'pantone-7719' },
    { 'color': 'pantone-7720' },
    { 'color': 'pantone-7721' },
    { 'color': 'pantone-7722' },
    { 'color': 'pantone-324' },
    { 'color': 'pantone-325' },
    { 'color': 'pantone-326' },
    { 'color': 'pantone-327' },
    { 'color': 'pantone-328' },
    { 'color': 'pantone-329' },
    { 'color': 'pantone-330' },
    { 'color': 'pantone-3242' },
    { 'color': 'pantone-3252' },
    { 'color': 'pantone-3262' },
    { 'color': 'pantone-3272' },
    { 'color': 'pantone-3282' },
    { 'color': 'pantone-3292' },
    { 'color': 'pantone-3302' },
    { 'color': 'pantone-3245' },
    { 'color': 'pantone-3255' },
    { 'color': 'pantone-3265' },
    { 'color': 'pantone-3275' },
    { 'color': 'pantone-3285' },
    { 'color': 'pantone-3295' },
    { 'color': 'pantone-3305' },
    { 'color': 'pantone-3248' },
    { 'color': 'pantone-3258' },
    { 'color': 'pantone-3268' },
    { 'color': 'pantone-3278' },
    { 'color': 'pantone-3288' },
    { 'color': 'pantone-3298' },
    { 'color': 'pantone-3308' },
    { 'color': 'pantone-566' },
    { 'color': 'pantone-565' },
    { 'color': 'pantone-564' },
    { 'color': 'pantone-563' },
    { 'color': 'pantone-562' },
    { 'color': 'pantone-561' },
    { 'color': 'pantone-560' },
    { 'color': 'pantone-573' },
    { 'color': 'pantone-572' },
    { 'color': 'pantone-571' },
    { 'color': 'pantone-570' },
    { 'color': 'pantone-569' },
    { 'color': 'pantone-568' },
    { 'color': 'pantone-567' },
    { 'color': 'pantone-559' },
    { 'color': 'pantone-558' },
    { 'color': 'pantone-557' },
    { 'color': 'pantone-556' },
    { 'color': 'pantone-555' },
    { 'color': 'pantone-554' },
    { 'color': 'pantone-553' },
    { 'color': 'pantone-5595' },
    { 'color': 'pantone-5585' },
    { 'color': 'pantone-5575' },
    { 'color': 'pantone-5565' },
    { 'color': 'pantone-5555' },
    { 'color': 'pantone-5545' },
    { 'color': 'pantone-5535' },
    { 'color': 'pantone-5665' },
    { 'color': 'pantone-5655' },
    { 'color': 'pantone-5645' },
    { 'color': 'pantone-5635' },
    { 'color': 'pantone-5625' },
    { 'color': 'pantone-5615' },
    { 'color': 'pantone-5605' },
    { 'color': 'pantone-5527' },
    { 'color': 'pantone-5517' },
    { 'color': 'pantone-5507' },
    { 'color': 'pantone-5497' },
    { 'color': 'pantone-5487' },
    { 'color': 'pantone-5477' },
    { 'color': 'pantone-5467' },
    { 'color': 'pantone-621' },
    { 'color': 'pantone-622' },
    { 'color': 'pantone-623' },
    { 'color': 'pantone-624' },
    { 'color': 'pantone-625' },
    { 'color': 'pantone-626' },
    { 'color': 'pantone-627' },
    { 'color': 'pantone-331' },
    { 'color': 'pantone-332' },
    { 'color': 'pantone-333' },
    { 'color': 'pantone-334' },
    { 'color': 'pantone-335' },
    { 'color': 'pantone-336' },
    { 'color': 'pantone-337' },
    { 'color': 'pantone-338' },
    { 'color': 'pantone-339' },
    { 'color': 'pantone-340' },
    { 'color': 'pantone-341' },
    { 'color': 'pantone-342' },
    { 'color': 'pantone-343' },
    { 'color': 'pantone-7723' },
    { 'color': 'pantone-7724' },
    { 'color': 'pantone-7725' },
    { 'color': 'pantone-7726' },
    { 'color': 'pantone-7727' },
    { 'color': 'pantone-7728' },
    { 'color': 'pantone-7729' },
    { 'color': 'pantone-3375' },
    { 'color': 'pantone-3385' },
    { 'color': 'pantone-3395' },
    { 'color': 'pantone-3405' },
    { 'color': 'pantone-3415' },
    { 'color': 'pantone-3425' },
    { 'color': 'pantone-3435' },
    { 'color': 'pantone-344' },
    { 'color': 'pantone-345' },
    { 'color': 'pantone-346' },
    { 'color': 'pantone-347' },
    { 'color': 'pantone-348' },
    { 'color': 'pantone-349' },
    { 'color': 'pantone-350' },
    { 'color': 'pantone-351' },
    { 'color': 'pantone-352' },
    { 'color': 'pantone-353' },
    { 'color': 'pantone-354' },
    { 'color': 'pantone-355' },
    { 'color': 'pantone-356' },
    { 'color': 'pantone-357' },
    { 'color': 'pantone-7478' },
    { 'color': 'pantone-7479' },
    { 'color': 'pantone-7480' },
    { 'color': 'pantone-7481' },
    { 'color': 'pantone-7482' },
    { 'color': 'pantone-7483' },
    { 'color': 'pantone-7484' },
    { 'color': 'pantone-7730' },
    { 'color': 'pantone-7731' },
    { 'color': 'pantone-7732' },
    { 'color': 'pantone-7733' },
    { 'color': 'pantone-7734' },
    { 'color': 'pantone-7735' },
    { 'color': 'pantone-7736' },
    { 'color': 'pantone-7737' },
    { 'color': 'pantone-7738' },
    { 'color': 'pantone-7739' },
    { 'color': 'pantone-7740' },
    { 'color': 'pantone-7741' },
    { 'color': 'pantone-7742' },
    { 'color': 'pantone-7743' },
    { 'color': 'pantone-358' },
    { 'color': 'pantone-359' },
    { 'color': 'pantone-360' },
    { 'color': 'pantone-361' },
    { 'color': 'pantone-362' },
    { 'color': 'pantone-363' },
    { 'color': 'pantone-364' },
    { 'color': 'pantone-7485' },
    { 'color': 'pantone-7486' },
    { 'color': 'pantone-7487' },
    { 'color': 'pantone-7488' },
    { 'color': 'pantone-7489' },
    { 'color': 'pantone-7490' },
    { 'color': 'pantone-7491' },
    { 'color': 'pantone-365' },
    { 'color': 'pantone-366' },
    { 'color': 'pantone-367' },
    { 'color': 'pantone-368' },
    { 'color': 'pantone-369' },
    { 'color': 'pantone-370' },
    { 'color': 'pantone-371' },
    { 'color': 'pantone-372' },
    { 'color': 'pantone-373' },
    { 'color': 'pantone-374' },
    { 'color': 'pantone-375' },
    { 'color': 'pantone-376' },
    { 'color': 'pantone-377' },
    { 'color': 'pantone-378' },
    { 'color': 'pantone-580' },
    { 'color': 'pantone-579' },
    { 'color': 'pantone-578' },
    { 'color': 'pantone-577' },
    { 'color': 'pantone-576' },
    { 'color': 'pantone-575' },
    { 'color': 'pantone-574' },
    { 'color': 'pantone-5807' },
    { 'color': 'pantone-5797' },
    { 'color': 'pantone-5787' },
    { 'color': 'pantone-5777' },
    { 'color': 'pantone-5767' },
    { 'color': 'pantone-5757' },
    { 'color': 'pantone-5747' },
    { 'color': 'pantone-5875' },
    { 'color': 'pantone-5865' },
    { 'color': 'pantone-5855' },
    { 'color': 'pantone-5845' },
    { 'color': 'pantone-5835' },
    { 'color': 'pantone-5825' },
    { 'color': 'pantone-5815' },
    { 'color': 'pantone-5803' },
    { 'color': 'pantone-5793' },
    { 'color': 'pantone-5783' },
    { 'color': 'pantone-5773' },
    { 'color': 'pantone-5763' },
    { 'color': 'pantone-5753' },
    { 'color': 'pantone-5743' },
    { 'color': 'pantone-7492' },
    { 'color': 'pantone-7493' },
    { 'color': 'pantone-7494' },
    { 'color': 'pantone-7495' },
    { 'color': 'pantone-7496' },
    { 'color': 'pantone-7497' },
    { 'color': 'pantone-7498' },
    { 'color': 'pantone-7744' },
    { 'color': 'pantone-7745' },
    { 'color': 'pantone-7746' },
    { 'color': 'pantone-7747' },
    { 'color': 'pantone-7748' },
    { 'color': 'pantone-7749' },
    { 'color': 'pantone-7750' },
    { 'color': 'pantone-379' },
    { 'color': 'pantone-380' },
    { 'color': 'pantone-381' },
    { 'color': 'pantone-382' },
    { 'color': 'pantone-383' },
    { 'color': 'pantone-384' },
    { 'color': 'pantone-385' },
    { 'color': 'pantone-386' },
    { 'color': 'pantone-387' },
    { 'color': 'pantone-388' },
    { 'color': 'pantone-389' },
    { 'color': 'pantone-390' },
    { 'color': 'pantone-391' },
    { 'color': 'pantone-392' },
    { 'color': 'pantone-587' },
    { 'color': 'pantone-586' },
    { 'color': 'pantone-585' },
    { 'color': 'pantone-584' },
    { 'color': 'pantone-583' },
    { 'color': 'pantone-582' },
    { 'color': 'pantone-581' },
    { 'color': 'pantone-393' },
    { 'color': 'pantone-394' },
    { 'color': 'pantone-395' },
    { 'color': 'pantone-396' },
    { 'color': 'pantone-397' },
    { 'color': 'pantone-398' },
    { 'color': 'pantone-399' },
    { 'color': 'pantone-3935' },
    { 'color': 'pantone-3945' },
    { 'color': 'pantone-3955' },
    { 'color': 'pantone-3965' },
    { 'color': 'pantone-3975' },
    { 'color': 'pantone-3985' },
    { 'color': 'pantone-3995' },
    { 'color': 'pantone-600' },
    { 'color': 'pantone-601' },
    { 'color': 'pantone-602' },
    { 'color': 'pantone-603' },
    { 'color': 'pantone-604' },
    { 'color': 'pantone-605' },
    { 'color': 'pantone-606' },
    { 'color': 'pantone-607' },
    { 'color': 'pantone-608' },
    { 'color': 'pantone-609' },
    { 'color': 'pantone-610' },
    { 'color': 'pantone-611' },
    { 'color': 'pantone-612' },
    { 'color': 'pantone-613' },
    { 'color': 'pantone-461' },
    { 'color': 'pantone-460' },
    { 'color': 'pantone-459' },
    { 'color': 'pantone-458' },
    { 'color': 'pantone-457' },
    { 'color': 'pantone-456' },
    { 'color': 'pantone-455' },
    { 'color': 'pantone-614' },
    { 'color': 'pantone-615' },
    { 'color': 'pantone-616' },
    { 'color': 'pantone-617' },
    { 'color': 'pantone-618' },
    { 'color': 'pantone-619' },
    { 'color': 'pantone-620' },
    { 'color': 'pantone-7751' },
    { 'color': 'pantone-7752' },
    { 'color': 'pantone-7753' },
    { 'color': 'pantone-7754' },
    { 'color': 'pantone-7755' },
    { 'color': 'pantone-7756' },
    { 'color': 'pantone-7757' },
    { 'color': 'pantone-7758' },
    { 'color': 'pantone-7759' },
    { 'color': 'pantone-7760' },
    { 'color': 'pantone-7761' },
    { 'color': 'pantone-7762' },
    { 'color': 'pantone-7763' },
    { 'color': 'pantone-7764' },
    { 'color': 'pantone-7765' },
    { 'color': 'pantone-7766' },
    { 'color': 'pantone-7767' },
    { 'color': 'pantone-7768' },
    { 'color': 'pantone-7769' },
    { 'color': 'pantone-7770' },
    { 'color': 'pantone-7771' },
    { 'color': 'pantone-4545' },
    { 'color': 'pantone-4535' },
    { 'color': 'pantone-4525' },
    { 'color': 'pantone-4515' },
    { 'color': 'pantone-4505' },
    { 'color': 'pantone-4495' },
    { 'color': 'pantone-4485' },
    { 'color': 'pantone-454' },
    { 'color': 'pantone-453' },
    { 'color': 'pantone-452' },
    { 'color': 'pantone-451' },
    { 'color': 'pantone-450' },
    { 'color': 'pantone-449' },
    { 'color': 'pantone-448' },
    { 'color': 'pantone-7499' },
    { 'color': 'pantone-7500' },
    { 'color': 'pantone-7501' },
    { 'color': 'pantone-7502' },
    { 'color': 'pantone-7503' },
    { 'color': 'pantone-7504' },
    { 'color': 'pantone-7505' },
    { 'color': 'pantone-468' },
    { 'color': 'pantone-467' },
    { 'color': 'pantone-466' },
    { 'color': 'pantone-465' },
    { 'color': 'pantone-464' },
    { 'color': 'pantone-463' },
    { 'color': 'pantone-462' },
    { 'color': 'pantone-7506' },
    { 'color': 'pantone-7507' },
    { 'color': 'pantone-7508' },
    { 'color': 'pantone-7509' },
    { 'color': 'pantone-7510' },
    { 'color': 'pantone-7511' },
    { 'color': 'pantone-7512' },
    { 'color': 'pantone-719' },
    { 'color': 'pantone-720' },
    { 'color': 'pantone-721' },
    { 'color': 'pantone-722' },
    { 'color': 'pantone-723' },
    { 'color': 'pantone-724' },
    { 'color': 'pantone-725' },
    { 'color': 'pantone-475' },
    { 'color': 'pantone-474' },
    { 'color': 'pantone-473' },
    { 'color': 'pantone-472' },
    { 'color': 'pantone-471' },
    { 'color': 'pantone-470' },
    { 'color': 'pantone-469' },
    { 'color': 'pantone-726' },
    { 'color': 'pantone-727' },
    { 'color': 'pantone-728' },
    { 'color': 'pantone-729' },
    { 'color': 'pantone-730' },
    { 'color': 'pantone-731' },
    { 'color': 'pantone-732' },
    { 'color': 'pantone-4685' },
    { 'color': 'pantone-4675' },
    { 'color': 'pantone-4665' },
    { 'color': 'pantone-4655' },
    { 'color': 'pantone-4645' },
    { 'color': 'pantone-4635' },
    { 'color': 'pantone-4625' },
    { 'color': 'pantone-7513' },
    { 'color': 'pantone-7514' },
    { 'color': 'pantone-7515' },
    { 'color': 'pantone-7516' },
    { 'color': 'pantone-7517' },
    { 'color': 'pantone-7518' },
    { 'color': 'pantone-7519' },
    { 'color': 'pantone-4755' },
    { 'color': 'pantone-4745' },
    { 'color': 'pantone-4735' },
    { 'color': 'pantone-4725' },
    { 'color': 'pantone-4715' },
    { 'color': 'pantone-4705' },
    { 'color': 'pantone-4695' },
    { 'color': 'pantone-482' },
    { 'color': 'pantone-481' },
    { 'color': 'pantone-480' },
    { 'color': 'pantone-479' },
    { 'color': 'pantone-478' },
    { 'color': 'pantone-477' },
    { 'color': 'pantone-476' },
    { 'color': 'pantone-7527' },
    { 'color': 'pantone-7528' },
    { 'color': 'pantone-7529' },
    { 'color': 'pantone-7530' },
    { 'color': 'pantone-7531' },
    { 'color': 'pantone-7532' },
    { 'color': 'pantone-7533' },
    { 'color': 'pantone-7534' },
    { 'color': 'pantone-7535' },
    { 'color': 'pantone-7536' },
    { 'color': 'pantone-7537' },
    { 'color': 'pantone-7538' },
    { 'color': 'pantone-7539' },
    { 'color': 'pantone-7540' },
    { 'color': 'pantone-427' },
    { 'color': 'pantone-428' },
    { 'color': 'pantone-429' },
    { 'color': 'pantone-430' },
    { 'color': 'pantone-431' },
    { 'color': 'pantone-432' },
    { 'color': 'pantone-433' },
    { 'color': 'pantone-420' },
    { 'color': 'pantone-421' },
    { 'color': 'pantone-422' },
    { 'color': 'pantone-423' },
    { 'color': 'pantone-424' },
    { 'color': 'pantone-425' },
    { 'color': 'pantone-426' },
    { 'color': 'pantone-441' },
    { 'color': 'pantone-442' },
    { 'color': 'pantone-443' },
    { 'color': 'pantone-444' },
    { 'color': 'pantone-445' },
    { 'color': 'pantone-446' },
    { 'color': 'pantone-447' },
    { 'color': 'pantone-413' },
    { 'color': 'pantone-414' },
    { 'color': 'pantone-415' },
    { 'color': 'pantone-416' },
    { 'color': 'pantone-417' },
    { 'color': 'pantone-418' },
    { 'color': 'pantone-419' },
    { 'color': 'pantone-400' },
    { 'color': 'pantone-401' },
    { 'color': 'pantone-402' },
    { 'color': 'pantone-403' },
    { 'color': 'pantone-404' },
    { 'color': 'pantone-405' },
    { 'color': 'pantone-406' },
    { 'color': 'pantone-407' },
    { 'color': 'pantone-408' },
    { 'color': 'pantone-409' },
    { 'color': 'pantone-410' },
    { 'color': 'pantone-411' },
    { 'color': 'pantone-412' },
    { 'color': 'pantone-434' },
    { 'color': 'pantone-435' },
    { 'color': 'pantone-436' },
    { 'color': 'pantone-437' },
    { 'color': 'pantone-438' },
    { 'color': 'pantone-439' },
    { 'color': 'pantone-440' },
    { 'color': 'pantone-Warm-Gray-1' },
    { 'color': 'pantone-Warm-Gray-2' },
    { 'color': 'pantone-Warm-Gray-3' },
    { 'color': 'pantone-Warm-Gray-4' },
    { 'color': 'pantone-Warm-Gray-5' },
    { 'color': 'pantone-Warm-Gray-6' },
    { 'color': 'pantone-Warm-Gray-7' },
    { 'color': 'pantone-Warm-Gray-8' },
    { 'color': 'pantone-Warm-Gray-9' },
    { 'color': 'pantone-Warm-Gray-10' },
    { 'color': 'pantone-Warm-Gray-11' },
    { 'color': 'pantoneool-Gray-1' },
    { 'color': 'pantoneool-Gray-2' },
    { 'color': 'pantoneool-Gray-3' },
    { 'color': 'pantoneool-Gray-4' },
    { 'color': 'pantoneool-Gray-5' },
    { 'color': 'pantoneool-Gray-6' },
    { 'color': 'pantoneool-Gray-7' },
    { 'color': 'pantoneool-Gray-8' },
    { 'color': 'pantoneool-Gray-9' },
    { 'color': 'pantoneool-Gray-10' },
    { 'color': 'pantoneool-Gray-11' },
    { 'color': 'pantone-Black-2' },
    { 'color': 'pantone-Black-3' },
    { 'color': 'pantone-Black-4' },
    { 'color': 'pantone-Black-5' },
    { 'color': 'pantone-Black-6' },
    { 'color': 'pantone-Black-7' }
  ];

}