
import { Directive, ElementRef, Input, Renderer, OnInit, HostBinding, HostListener, Injectable, Output, Renderer2, Component, ViewContainerRef, ComponentFactoryResolver, EventEmitter } from '@angular/core';
import { MdbBtnDirective } from 'ng-uikit-pro-standard';
import $ from 'jquery';

@Component({
    selector: 'drag-item-image',
    template: `
                <div id="drag-image-item" class="drag-image-item" style="">
                    <div class="drag-image-item-center"><i class="fa fa-envelope"></i><span class="item-cnt-text">{{ text }}</span></div>
                </div>
            `,
    styles:[
        `
            .drag-image-item { 
                position:relative;
                width:200px;
                height:50px;
                background-color:#333333a1;
                color:white;
                border-radius:4px;
                box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
            }
            .drag-image-item-center {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 45%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        `
    ]
})
export class DragItemImage {

    text:string = ' Move 1 item';
    constructor(private el: ElementRef) { }

    getEl() {
        return document.getElementById('drag-image-item');
    }
}


@Directive({
    selector: '[conDraggable]'
})
export class ConDraggableDirective {


    private options: any = {};

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    _isDrag: boolean = false;

    @Input()
    set isDrag(status) {
        if (status) {
            this.renderer.setAttribute(this.el.nativeElement, 'draggable', 'true');
        }
        this._isDrag = status;
    }

    get isDrag() {
        return this._isDrag;
    }



    @Input()
    set conDraggable(options: any) {
        if (options) {
            this.options = options;
            return;
        }
    }

    @Input() dragImage: DragItemImage;

    @Input() dragFrom: any;

    @HostListener('dragstart', ['$event'])
    onDragStart(event) {
        
        if (!this.isDrag) {
            return false;
        }
     
        let dataTransfer:DataTransfer = event.dataTransfer;
        dataTransfer.effectAllowed = "move";
        
        let elem = document.createElement("div");
        elem.id = "drag-item-image";
        elem.style.position = "absolute";
        elem.style.top = "-100000px";
        let dragImageEl = $(this.dragImage.getEl())
        dragImageEl.find('.item-cnt-text').text(this.options.length > 1 ? ` Move ${this.options.length} Items` : this.dragImage.text)
        elem.appendChild(dragImageEl[0]);
        document.body.appendChild(elem);
        dataTransfer.setDragImage(elem, 0, 0);
        dataTransfer.setData('message-drag-data', JSON.stringify(this.options));
    }

    // @HostListener('drag', ['$event'])
    // onDragging(event) {
    //     console.log("On Dragging End", event)
    // }

    @HostListener('dragend', ['$event'])
    onDragEnd(event) {

    }
}

@Directive({
    selector: '[conDropPoint]'
})
export class ConDropPointDirective {

    private dropPoint: string;

    constructor(private el: ElementRef) { }

    @Input()
    set conDropPoint(dropPoint: any) {
        if (dropPoint) {
            this.dropPoint = dropPoint;
            return;
        }
    }

    @Output('droppedData') drop = new EventEmitter();

    @HostListener('dragenter', ['$event'])
    onDragEnter(event: DragEvent) {
        let idx = event.dataTransfer.types.indexOf('message-drag-data')
        this.el.nativeElement.style.backgroundColor = idx > -1 ? '#cae6f275' : '';
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent) {
        this.el.nativeElement.style.backgroundColor = '';
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
      
        if (!event.dataTransfer.getData("message-drag-data")) {
            return;
        }
        
        this.el.nativeElement.style.backgroundColor = '';
        const data = JSON.parse(event.dataTransfer.getData('message-drag-data'));
        this.drop.next(data);
    }

}