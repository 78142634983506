import { Campaign } from '../models/index';
import { CampaignActions } from '../actions/index';

export interface CampaignState {
  campaigns: Campaign[];
  updateCampaignSuccess: any;
  fileUpload: any;
}

export const initialState: CampaignState = {
  campaigns: null,
  updateCampaignSuccess: null,
  fileUpload: null
};

export function campaignReducers(
  state: CampaignState = initialState,
  action: CampaignActions.All
) {
  switch (action.type) {
    case CampaignActions.LOAD_CAMPAIGNS_SUCCESS:
      return Object.assign({}, state, { campaigns: action.payload });

    case CampaignActions.SAVE_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, {
        updateCampaignSuccess: action.payload,
      });

    case CampaignActions.FILE_UPLOAD_SUCCESS:
      return Object.assign({}, state, { fileUpload: action.payload });

    default: {
      return state;
    }
  }
}
