import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignState, campaignReducers } from './campaign.reducer';

export interface State {
  campaignModule: CampaignState;
}

export const reducers = campaignReducers;

export const getCampaignState =
  createFeatureSelector<CampaignState>('campaignModule');

export const getCampaignListSuccess = createSelector(
  getCampaignState,
  (state: CampaignState) => state.campaigns
);

export const getCampaignUpdateSuccess = createSelector(
  getCampaignState,
  (state: CampaignState) => state.updateCampaignSuccess
);

export const FileUploadSuccess = createSelector(
  getCampaignState,
  (state: CampaignState) => state.fileUpload)
