import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { analyzeFile } from '@angular/compiler';

@Component({
  selector: 'rewardkart-admin-product-tags-add',
  templateUrl: './product-tags-add.component.html',
  styleUrls: ['./product-tags-add.component.scss']
})
export class ProductTagsAddComponent implements OnInit {
  @Output() loaderStatus = new EventEmitter<any>();
  @Output() loadTag = new EventEmitter<any>();
  editTagGroup: any;
  @Input() sendTag: any;
  @Input() isOpenTagAdd: any;
  addProductTag: FormGroup;
  languageList: Array<any>;
  language: any = 1;
  tagName: any = '';
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder, private toast: ToastService) { }

  ngOnInit() {
    debugger
    this.loadLanguage();
  }

  ngOnChanges() {
    if (this.isOpenTagAdd) {
      this.language = 1;
      this.addProductTag.reset();
    }
    if (this.sendTag !== undefined && this.sendTag.type === "edit") {
      this.editTagGroup = this.sendTag.Translations;
      // this.editTagGroup.forEach(element => {
      //   console.log(element)
      //   if (element.PropertyValue) {
      //     element.Action = 'Update';
      //   }
      // });
    }
    else {

      this.editTagGroup = [];
      this.language = 1;
      if (this.addProductTag) {
        this.addProductTag.reset();
      }
    }
    this.prefillData();
  }

  prefillData() {
    if (this.editTagGroup) {
      this.editTagGroup.forEach(element => {
        this.addProductTag.get('tag' + element.LanguageID).patchValue(element.PropertyValue);
      });
    }
    else {
      if (this.addProductTag) {
        this.language = 1;
        this.addProductTag.reset();
      }
    }
  }

  loadTagForm() {
    // this.addProductTag = this.formBuilder.group({
    //   tagName: new FormControl('')
    // });
  }

  loadLanguage() {
    let group = {}
    // let getlang = this.apiService.getLanguages();
    let customerDetail = JSON.parse(sessionStorage.getItem('customerDetails'));
    let getlang = this.apiService.getWebshopLanguages(customerDetail.CustomerID);
    getlang.forEach((element: any) => {
      if (element.LanguageID === 1) {
        group['tag' + element.LanguageID] = new FormControl('', Validators.required);
      }
      else {
        group['tag' + element.LanguageID] = new FormControl('')
      }
      element.label = element.Name;
      element.value = element.LanguageID;
    });
    this.languageList = getlang;
    this.addProductTag = new FormGroup(group);
  }

  createTag() {
    debugger
    this.loaderStatus.emit(true);
    let getlang = this.apiService.getLanguages();
    const loginData = this.apiService.getLoginData();
    const tagName = [];
    this.languageList.forEach(element => {
      const name = {
        "LanguageID": element.LanguageID,
        "LanguageCode": element.Code,
        "ObjectType": 'ProductTags',
        "PropertyName": 'ProductTagName',
        "PropertyValue": this.addProductTag.value['tag' + element.LanguageID]
      }
      tagName.push(name);
    });
    const request = {
      "ProductTagsGroupID": this.sendTag ? this.sendTag.ProductTagsGroupID : '',
      "Translations": tagName
    }
    this.webshopHttpService.addProductTag(request).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success('Product tag created successfully');
        this.loadTag.emit();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
        this.toast.error(error.error.Message);
      })
  }

  updateTag() {
    this.loaderStatus.emit(true);
    const tagGroup = [];
    let groupID: any;
    this.editTagGroup.forEach(element => {
      groupID = element.ObjectID;
      let actionType = element.Action;
      if (!actionType && element.PropertyValue) {
        actionType = 'Update';
      }
      tagGroup.push({
        "TranslationID": element.TranslationID,
        "LanguageID": element.LanguageID,
        "LanguageCode": element.LanguageCode,
        "ObjectID": element.ObjectID,
        "ObjectType": element.ObjectType,
        "PropertyName": element.PropertyName,
        "PropertyValue": this.addProductTag.value['tag' + element.LanguageID],
        "Action": actionType
      })
    });
    const request = {
      Translations: tagGroup
    }
    this.webshopHttpService.updateProductTag(request, groupID).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success('Product tag updated successfully');
        this.loadTag.emit();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
        this.toast.error(error.error.Message);
      })
  }

}
