import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { DashboardPendinguserComponent } from './dashboard-pendinguser.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule
  ],
  declarations: [DashboardPendinguserComponent],
  exports: [DashboardPendinguserComponent]
})

export class DashboardPendingUserModule { }
