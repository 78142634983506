import { Signup } from '../models/index';
import { SignupActions } from '../actions/index';

export interface SignupState {
    validateuser: any;
    generatemobileotp: any;
}


export const initialState: SignupState = {
    validateuser: null,
    generatemobileotp: null
}

export function signupReducers(state: SignupState = initialState, action: SignupActions.All) {
    switch (action.type) {
        case SignupActions.VALIDATE_USER_SUCCESS:
            return Object.assign({}, state, { validateuser: action.payload });
        
        case SignupActions.GENERATE_MOBILE_OTP_SUCCESS:
            return Object.assign({}, state, { generatemobileotp: action.payload });

        default: {
            return state;
        }
    }
}