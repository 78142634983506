import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardOrderNotificationAlarmComponent } from './dashboard-order-notification-alarm.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [DashboardOrderNotificationAlarmComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommonFilterModule,
    PageLoaderModule,
    TranslateModule
  ],
  exports: [DashboardOrderNotificationAlarmComponent]
})
export class DashboardOrderNotificationAlarmModule { }
