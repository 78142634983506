import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { Router } from '@angular/router';
import { OrderHttpService } from '../../state/services/order.http.service';

@Component({
  selector: 'rewardkart-admin-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss']
})
export class OrderItemDetailsComponent implements OnChanges {
  @Input() orderItem: any;
  isLoading: boolean = false;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'ProductImageURL',
      header: 'Product',
      format: 'icon',
      showSort: false
    },
    {
      primaryKey: 'ProductIdManual',
      header: 'Item Number'
    },
    {
      primaryKey: 'ProductName',
      header: 'Product Name'
    },
    {
      primaryKey: 'OrderedQuantity',
      header: 'Ordered Quantity'
    },
    {
      primaryKey: 'isExpressOrder',
      header: 'Express Order'
    },
    {
      primaryKey: 'isSampleOrderItem',
      header: 'Sample Order'
    },
    {
      primaryKey: 'DeliveryDate',
      header: 'Delivery Date',
      format: 'date'
    },
    {
      primaryKey: 'action',
      format: 'linkAction',
      showSort: false
    }
  ];
  actions: any = ['Track Status'];
  orderID: any;
  orderItemDetails: any[] = [];
  tableName = 'orderViewDetails';
  searchData: any;
  item: any;
  header:any = '';
  constructor(private router: Router, private orderHttpService: OrderHttpService, private datePipe: DatePipe) {
  }

  ngOnChanges() {
    this.orderItemDetails = this.orderItem.OrderItems;
  }

  handleActions(event) {
    const { data, type } = event;
    const { OrderItemID } = data;
    if (type === 'Track Status') {
      const orderData = {
        'orderID': this.orderItem.OrderID,
        'orderItemID': data.OrderItemID,
        'isSubOrder': false
      }
      //this.router.navigate(['/view-order'], { state: { data: orderData }, replaceUrl: false });
     //this.orderItem.OrderID = 20539;
      const params = {
        "SBINumber": "",
        "AWb_no": "",
        "MemberName": "",
        "Trn_Id": "",
        "Rawb_no":"",
        "B_Awb_No":"",
        "ContactNo": "",
        "QAWb_No":"",
        "BatchName": this.orderItem.OrderID,
        "CompanyName":""
    }
    this.isLoading = true;
    this.orderHttpService.getTrackOrderSearch(params).subscribe((data:any)=>{
        this.isLoading = false;
        if(data && data.IsSuccess){
          this.searchData = data;
          this.searchData.TrackOrder_List.forEach((element:any)=>{
            element.F_Date = this.datePipe.transform(new Date(element.F_Date), "dd/MM/yyyy");
            element.Cour_Date = this.datePipe.transform(new Date(element.Cour_Date), "dd/MM/yyyy");
            element.qrCodeImage = 'data:image/jpeg;base64,'+ element.qrCodeImage;
          });
          this.item = this.searchData.TrackOrder_List[event.index];
        }else{
          this.item = [];
        }
        this.router.navigate(['/admin-track-order'], { state: { data: this.item }, replaceUrl: false });
    });
    }
    else if (type === 'View Details' && OrderItemID !== undefined && !this.isLoading) {
      const subOrderItemData = this.orderItem;
      const isSubOrder = false;
      this.router.navigate([`admin-order-process/orderProcess/${this.orderItem.OrderID}/${data.OrderItemID}/${event.index}`],
        { state: { subOrderItemData, isSubOrder }, replaceUrl: false })
    }

  }

}
