
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryHttpService } from '../state/services/category.http.service';
import { CategoryService } from '../category-services/category.service';
import { CategoryListdetailsComponent } from './category-listdetails.component';
import { PageControlModule } from 'src/app/shared/page-control/page-control.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DragItemImage, DragdropDirective } from '../directives/dragdrop.directive';
import { DragdropModule } from '../directives/dragdrop.module';
import { CategoryAddEditModule } from '../category-addedit/category-addedit.module';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryListTreeComponent } from '../category-list-tree/category-list-tree.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    PageControlModule,
    NgxPaginationModule,
    PerfectScrollbarModule,
    DragdropModule,
    CategoryAddEditModule,
    TranslateModule
  ],
  declarations: [CategoryListdetailsComponent, CategoryListTreeComponent],
  providers: [CategoryHttpService, CategoryService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
  exports: [CategoryListdetailsComponent]
})

export class CategoryListDetailsModule { }