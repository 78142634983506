import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignupState, signupReducers } from './signup.reducer';

export interface State {
    singupModule: SignupState;
}

export const reducers = signupReducers;

export const getSignupState = createFeatureSelector<SignupState>('singupModule');

export const ValidateUserSuccess = createSelector(getSignupState,
        (state: SignupState) => state.validateuser)

export const GenerateMobileOTPSuccess = createSelector(getSignupState,
    (state: SignupState)=> state.generatemobileotp)