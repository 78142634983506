import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { UserHttpService } from '../state/services/user.http.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/common-services/api.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-showlist',
  templateUrl: './showlist.component.html',
  styleUrls: ['./showlist.component.scss']
})
export class ShowlistComponent implements OnInit {
  actions: any = ['edit', 'delete'];
  @ViewChild('showlist', { static: false }) showlist: ModalDirective;
  @ViewChild('editdeliveryAddress', { static: false }) editdeliveryAddress: ModalDirective;
  optionsSelectCustomers: Array<any>;
  usershowAddressList: any = [];
  @Input() userId;
  @Output() editSelectedDeliveryRowEvent = new EventEmitter();
  @Input() deliveryAddressList: any = [];
  editData: any;
  showUserEditAddresspage = false;
  activePage: any;
  tableName: any = '';
  tableEvent: any;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'DeliveryContactPerson',
      header: 'Contact person'
    },
    {
      primaryKey: 'DeliveryCompanyName',
      header: 'Company Name'
    },
    {
      primaryKey: 'DeliveryPhoneNumber',
      header: 'Phone number',
    },
    {
      primaryKey: 'DeliveryEmail',
      header: 'Email'
    },
    {
      primaryKey: 'DeliveryAddress',
      header: 'Address'
    },
    {
      primaryKey: 'DeliveryCity',
      header: 'City'
    },
    {
      primaryKey: 'DeliveryPostalCode',
      header: 'Postal code'
    },
    {
      primaryKey: 'DeliveryCountry',
      header: 'Country'
    },
    {
      primaryKey: 'isDefaultAddress',
      header: 'Default Address',
      format: 'showAddresscheckbox'
    },
    {
      primaryKey: 'action',
      header: 'Action',
      format: 'action',
    }
  ];

  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private userHttpService: UserHttpService, private activatedRoute: ActivatedRoute, private toast: ToastService) {

  }

  ngOnInit() {
  }
  editShowAddress(event) {
    if (event) {
      try {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.editSelectedDeliveryRowEvent.emit(data);
          }
        });
      } catch (error) {
      }
    }
  }

  deleteDeliveryAddress(event) {
    this.userHttpService.deleteUserAdditionalDeliveryAddress(event.UsersDeliveryAddressID).subscribe((data: any) => {
      if (data !== null) {
        this.deliveryAddressList = data.DeliveryAddresses;
        this.toast.success('Delivery Address Deleted Successfully');
      }
    });
  }
}
