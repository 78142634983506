import { NgModule } from '@angular/core';
import { StyleCellDirective } from './style-cell-directive';

@NgModule({
    declarations: [StyleCellDirective],
    exports: [
        StyleCellDirective
    ]
})
export class LinkTableDirectiveModule { }
