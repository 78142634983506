import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockService } from '../state/services/block.service';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BlockHttpService } from './../state/services/block.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService } from 'ng-uikit-pro-standard';
import * as moment from 'moment';
@Component({
  selector: 'rewardkart-admin-block-edit',
  templateUrl: './block-edit.component.html',
  styleUrls: ['./block-edit.component.scss'],
})
export class BlockEditComponent implements OnInit {
  webshopDetail: any;
  blockFormGroup: FormGroup;
  isVisible: any;
  blockDefaultValue = {
    CustomerID: '',
    Translations: [],
    IsVisible: false,
    VisibleFrom: '',
    VisibleTo: '',
    BlockColor: '',
    TextColor: '',
  };
  date = new Date();
  public datePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
    disableUntil: {
      year: this.date.getFullYear(),
      month: this.date.getMonth() + 1,
      day: this.date.getDate() - 1,
    },
  };
  selectedProducts = [];
  blockData: any = { TextColor: '', BlockColor: '' };
  translations = [];
  searchResult = [];
  columns: ColumnSetting[] = [
    {
      primaryKey: 'languageName',
      header: 'Language',
      primaryKeyItem: 'languageName',
      style: { colborder: 'col-border' },
    },
    {
      primaryKey: 'PropertyValue',
      header: 'Block Name*',
      primaryKeyItem: 'PropertyValue',
      format: 'access',
      style: { colborder: 'col-border' },
    },
  ];
  languageList = {};
  saveButtonLabel = 'Save';
  blockID: number = null;
  blockNameEmpty: any;

  constructor(
    public formBuilder: FormBuilder,
    public blockService: BlockService,
    public blockHttpService: BlockHttpService,
    public apiService: ApiService,
    public toaster: ToastService,
    public router: Router
  ) {}

  ngOnInit() {
    this.createForm(this.blockDefaultValue);
    this.getLanguages();
    const { id } = history.state;
    console.log(id);
    if (id) {
      this.blockID = id;
      this.saveButtonLabel = 'Update';
      this.getBlockData(id);
    }
  }

  getLanguages() {
    this.blockHttpService.getLanguages().subscribe(
      (languageResponse: any) => {
        if (languageResponse) {
          let translations = [];
          let getData = {};
          languageResponse.forEach((language, index) => {
            let langcode = language.Code;
            getData[langcode] = { name: language.Name, index: index };
            translations.push({
              LanguageID: language.LanguageID,
              LanguageCode: language.Code,
              ObjectType: 'ProductBlocks',
              languageName: language.Name,
              PropertyName: 'ProductBlockName',
              PropertyValue: '',
              Action: 'Insert',
            });
          });
          this.languageList = getData;
          this.translations = translations;
        }
      },
      (error) => {
        console.log('unable to get language');
      }
    );
  }

  getBlockData(id) {
    const getWebshopDetails = JSON.parse(
      sessionStorage.getItem('webshopDetail')
    );
    const request = {
      CustomerID:
        getWebshopDetails !== null && getWebshopDetails !== undefined
          ? getWebshopDetails.CustomerID
          : '',
      id: id,
    };
    this.blockHttpService.getBlockDataByBlockId(request).subscribe((data) => {
      if (data) {
        this.blockData = data;
        this.blockData.Translations.forEach((item: any) => {
          const languageToMap = this.languageList[item.LanguageCode];
          this.translations[languageToMap.index] = {
            ...this.translations[languageToMap.index],
            ...item,
            Action: 'Update',
            languageName: languageToMap.name,
          };
        });
        this.blockData.VisibleFrom = this.blockData.VisibleFrom
          ? this.customDateFormat(this.blockData.VisibleFrom)
          : '';
        this.blockData.VisibleTo = this.blockData.VisibleTo
          ? this.customDateFormat(this.blockData.VisibleTo)
          : '';
        this.createForm(this.blockData);
      }
    });
  }

  getTwoDigitDateFormat(monthOrDate) {
    return monthOrDate < 10 ? '0' + monthOrDate : '' + monthOrDate;
  }

  customDateFormat(data) {
    const getDate = new Date(data);
    const dateFormat =
      this.getTwoDigitDateFormat(getDate.getDate()) +
      '/' +
      this.getTwoDigitDateFormat(getDate.getMonth() + 1) +
      '/' +
      getDate.getFullYear();
    return dateFormat;
  }

  createForm(blockItem) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (
      webshop !== null &&
      webshop !== undefined &&
      webshop !== '' &&
      webshop !== 'undefined'
    ) {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    const { IsVisible, VisibleFrom, VisibleTo } = blockItem;
    this.blockFormGroup = this.formBuilder.group({
      CustomerID: [this.webshopDetail.CustomerID, Validators.required],
      IsVisible: [IsVisible || false],
      VisibleFrom: [VisibleFrom || ''],
      VisibleTo: [VisibleTo || ''],
    });
  }

  validateBlockName(prop, data) {
    let result = -1;
    data.forEach((obj, index) => {
      if (prop in obj && obj[prop] !== '') {
        result = index;
        return false;
      }
    });
    return result;
  }

  validateForm() {
    if (
      this.blockFormGroup.valid &&
      this.translations.length > 0 &&
      !(this.validateBlockName('PropertyValue', this.translations) === -1)
    ) {
      const blocks = {
        BlockColor: '#ffffff',
        CustomerID: this.blockFormGroup.value.CustomerID,
        IsVisible: this.blockFormGroup.value.IsVisible,
        VisibleFrom:
          this.blockFormGroup.value.VisibleFrom === undefined
            ? this.dateFormatchange(new Date())
            : this.dateFormatchange(this.blockFormGroup.value.VisibleFrom),
        VisibleTo:
          this.blockFormGroup.value.VisibleTo === undefined
            ? this.dateFormatchange(new Date())
            : this.dateFormatchange(this.blockFormGroup.value.VisibleTo),
        TextColor: '#000000',
      };

      if (blocks.IsVisible) {
        blocks.VisibleFrom = '';
        blocks.VisibleTo = '';
      }

      this.submitForm({ ...blocks, Translations: this.translations });
    } else {
      this.toaster.error('Please Enter Block Name');
    }
  }

  dateFormatchange(value) {
    if (value !== '') {
      const myDate = moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD');
      return myDate;
    } else {
      return value;
    }
  }

  submitForm(formValue) {
    if (typeof formValue.IsVisible === 'undefined') {
      formValue.IsVisible = false;
    }
    if (this.blockID) {
      this.blockHttpService.updateBlock(this.blockID, formValue).subscribe(
        (editResponse) => {
          this.toaster.success('Block Updated Successfully');
        },
        (error) => {
          this.toaster.error('Unable to Update Block');
        }
      );
    } else {
      this.blockHttpService.addBlock(formValue).subscribe(
        (addResponse: any) => {
          this.blockID = addResponse;
          this.saveButtonLabel = 'Update';
          this.blockData.Products = [];
          this.toaster.success('Block Added Successfully');
          this.getBlockData(addResponse);
        },
        (error) => {
          this.toaster.error('Unable to Add Block');
        }
      );
    }
  }

  goBack() {
    this.router.navigate(['/admin-block-list'], { replaceUrl: false });
  }

  handleProductSelection(event) {
    if (event.operation === 'Add') {
      this.addProductToBlock(event);
    }
  }

  addProductToBlock(event) {
    const {
      product: { ProductID },
      index,
    } = event;
    this.blockService.addProductToBlock(this.blockID, ProductID).subscribe(
      (res) => {
        if (res) {
          this.toaster.success('Product added to block successfully');
          this.getBlockData(this.blockID);
        } else {
          this.toaster.error('Unable to add product');
          this.blockData.Products.splice(index, 1);
        }
      },
      (error) => {
        this.blockData.Products.splice(index, 1);
        this.toaster.error('Unable to add product');
      }
    );
  }

  deleteProductFromBlock(event) {
    const {
      product: { ProductID },
      index,
    } = event;
    this.blockService.removeProductFromBlock(this.blockID, ProductID).subscribe(
      (res) => {
        if (res) {
          this.blockData.Products.splice(index, 1);
          this.toaster.success('Product deleted from block successfully');
          this.getBlockData(this.blockID);
        } else {
          this.toaster.error('Unable to delete product');
        }
      },
      (error) => {
        this.toaster.error('Unable to delete product');
      }
    );
  }
}
