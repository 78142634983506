import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillboardService } from '../state/services/billboard.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { BillboardHttpService } from '../state/services/billboard.http.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-billboard-list',
  templateUrl: './billboard-list.component.html',
  styleUrls: ['./billboard-list.component.scss']
})
export class BillboardListComponent implements OnInit {
  isLoading = false;
  header = "Banner Enquiry List";
  tableEvent: any;
  billboardList: any = [];
  actions: any = ['edit', 'delete'];
  submitted = false;
  tableName = 'billboardList';
  columns: ColumnSetting[] = [
    {
      primaryKey: 'BillboardAdID',
      header: 'Billboard ID'
    },
    {
      primaryKey: 'ImageURL',
      header: 'Image',
      format: 'customImage'
    },
    {
      primaryKey: 'VisibleOnLinkNetwork',
      header: 'Visible On Webshop'
    },
    {
      primaryKey: 'Position',
      header: 'Position'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];

  constructor(
    private billboardService: BillboardService,
    private router: Router,
    private billboardHttpService: BillboardHttpService,
    private linkTableService: LinkTableService,
    private toaster: ToastService
  ) {
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.header = 'Billboard Details'; // Multilingual to be implemented
    this.getBillboardList();
  }

  onLoadFunctions() {
    this.billboardService.loadBillboardList();
  }

  getBillboardList() {
    this.isLoading = true;
    this.billboardHttpService.getBillboardList().subscribe(data => {
      if (data) {
        this.billboardList = data;
        this.billboardList = this.billboardList.ItemsCollection;
        this.isLoading = false;
      }
    });
  }

  addBillboard(event: boolean) {
    this.submitted = event;
  }

  addNewBillboard() {
    this.router.navigate(['/admin-billboard-add'], { replaceUrl: false });
  }

  viewBillboard(event: boolean) {
    this.submitted = event;
  }

  editBillboard(event) {
    try {
      if (event) {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(selectedRow => {
          if (selectedRow && selectedRow.BillboardAdID) {
            const id = selectedRow.BillboardAdID;
            this.router.navigate(['/admin-billboard-edit'], {
              state: { id },
              replaceUrl: true
            });
          }
        });
      }
    } catch (error) { }
    this.submitted = event;
  }

  deleteBillboard(event) {
    this.submitted = event;
    this.billboardHttpService.deleteBillboard(event.BillboardAdID).subscribe((data: any) => {
      if (data !== null) {
        this.onLoadFunctions();
        this.getBillboardList();
      }
    });
  }

  changePosition(event) {
    const dragEvent = { CurrentPosition: event.previousIndex + 1, DesiredPosition: event.currentIndex + 1 };
    this.billboardHttpService.changePositionOfBillBoard(dragEvent).subscribe((res: any) => {
      if (res) {
        this.toaster.success(res);
      }
    }, error => {
      this.toaster.error(error);
    });
  }

  ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }
}
