import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { ControlValidationService } from '../../../shared/control-validation/index';

@Component({
  selector: 'rewardkart-admin-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  userDetails: FormGroup;
  @Input() webshopData: any;
  @Input() getCountryList: any;
  @Output() getUserData = new EventEmitter<any>();
  userDetailsData: any;
  showLoader: boolean = false;
  showEmailError: Boolean = false;
  constructor(private webshopHttpService: WebshopHttpService, private formBuilder: FormBuilder, private translate: TranslateService, private toast: ToastService) { }

  ngOnInit() {
    debugger
    this.userDetailsData = this.webshopData.WebshopManager[0];
    this.loadForm();
  }

  loadForm() {
    this.userDetails = this.formBuilder.group({
      name: ['', Validators.required],
      jobPosition: [''],
      userEmail: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      userName: ['', Validators.required],
      department: [''],
      companyName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required],
      vatNumber: ['', Validators.required],
      confirmPassword: ['', this.webshopData.WebshopManager.length == 0 ? [Validators.required] : []],
      Password: ['', this.webshopData.WebshopManager == 0 ? [Validators.required, ControlValidationService.passwordValidator] : []]
    });
    console.log('this.userDetailsData', this.userDetailsData);
    if (this.userDetailsData) {
      this.userDetails.patchValue({
        name: this.userDetailsData.Name,
        jobPosition: this.userDetailsData.JobPosition,
        userEmail: this.userDetailsData.Email,
        phoneNumber: this.userDetailsData.PhoneNumber,
        userName: this.userDetailsData.UserName,
        department: this.userDetailsData.Department,
        companyName: this.userDetailsData.CompanyName,
        address1: this.userDetailsData.Address,
        address2: this.userDetailsData.Address2,
        address3: this.userDetailsData.Address3,
        city: this.userDetailsData.City,
        postalCode: this.userDetailsData.PostalCode,
        country: this.userDetailsData.Country,
        vatNumber: this.userDetailsData.VAT
      })
    }


  }

  saveWebshop() {
    this.getUserData.emit(this.userDetails.value);
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 43 || (k >= 48 && k <= 57));
  }

  validateEmail(evt) {

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(evt.target.value)) {
      this.showEmailError = false;
    }
    else {
      this.showEmailError = true;
    }
  }

  onlyAlphabet(event) {
    let k = event.charCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123)) {
      return false;
    }
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 43 || k == 45 || k == 40 || k == 41 || k == 47 || (k >= 48 && k <= 57));
  }

}
