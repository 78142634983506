import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductBrandComponent } from './product-brand.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';

@NgModule({
  declarations: [ProductBrandComponent],
  imports: [CommonModule, CommonFormModule, SharedModule],
  exports: [ProductBrandComponent]
})
export class ProductBrandModule {}
