import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { DashboardLikedproductComponent } from './dashboard-likedproduct.component';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    PageLoaderModule,
    CommonFilterModule,
    TranslateModule
  ],
  declarations: [DashboardLikedproductComponent],
  exports: [DashboardLikedproductComponent]
})

export class DashboardLikedproductsModule { }
