import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { Params } from '../currencyModal';
import { HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CampaignHttpService } from 'src/app/campaign/state/services/campaign.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';


@Component({
  selector: 'link-admin-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit {

  currencyForm: FormGroup;
  action: string = "ADD";
  edit: boolean = false;
  editedValues: any = [];
  currencyId: string | number;
  isLoading: boolean = false;
  showImageError: boolean = false;
  selectedImg: any;
  urls: any = [];
  uploadImage: any;
  Image:string = "";
  userID: string;
  constructor(private formBuilder: FormBuilder,private http:WebshopHttpService, private toastr:ToastService, private router: Router,
    public sanitizer: DomSanitizer,public campaignHttpService: CampaignHttpService,public apiService: ApiService,
  ) { }

  ngOnInit() {

    this.currencyForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: [''],
      // symbol: [''],
      isActive: [true],
      draganddrop: [],
  })
    let loginData = this.apiService.getLoginData();
    if(loginData){
      this.userID = loginData.UserID;
    }

    if(history.state.editCurrency){
      this.editedValues = history.state.editCurrency;
      this.currencyId = this.editedValues.CountryID;
      this.action = "UPDATE";
      this.edit = true;
      this.currencyForm.patchValue({
        name:this.editedValues.CountryName,
        code:this.editedValues.Code,
        // symbol:this.editedValues.Symbol,
        isActive: this.editedValues.IsActive,
      })
      this.Image = this.editedValues.ImageUrl;
      this.uploadImage = this.editedValues.Image;

    }
  }

  onSelectFile(event) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.jpeg' && getImg.toLowerCase() !== '.jpg' && getImg.toLowerCase() !== '.png' && getImg.toLowerCase() !== '.gif')) {
      this.showImageError = true;
    } else {
      this.showImageError = false;
      if (event.target.files && event.target.files[0]) {
        this.isLoading = true;
        var filesAmount = event.target.files.length;

        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();

          reader.onload = (event: any) => {
            const imgurl = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);

            this.selectedImg = event.target.result;

            this.urls.push(event.target.result);
          }

          reader.readAsDataURL(event.target.files[i]);
          let httpHeaders = new HttpHeaders();
          httpHeaders.set('Content-Type', null);
          httpHeaders.set('Accept', "multipart/form-data");
          const formData = new FormData();
          formData.append('file', event.target.files[0]);
          formData.append('filePath', 'CountryFlag');
          event.target.value = '';
          this.campaignHttpService.uploadFile(formData).subscribe((data: any) => {
            if (data) {
              this.uploadImage = data[1];
              this.Image = data[2];
            }
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
            this.toastr.error(error.error);
          })
        }
      }
    }
  }

  addCurency() {
    // if(this.currencyForm.valid){
      if(!this.currencyForm.value.name){
        this.toastr.error("Please Enter Country Name");
        return;
      }
      if(!this.Image){
        this.toastr.error("Please Upload Image");
        return;
      }
      this.isLoading = true;
      let params:Params = {
        "Name": this.currencyForm.value.name,
        "Code": this.currencyForm.value.code,
        "active": this.currencyForm.value.isActive ? 1 : 0,
        "Image" : this.uploadImage ? this.uploadImage : ""
      }
      if(this.edit){
        params.CountryID = this.currencyId;
      }


      this.http.addCountry(params).subscribe({
        next : (data:any) => {
          this.isLoading = false;
          if(data){
            this.router.navigate(['/country'],{replaceUrl : true})
          }
        },error : (e:any) => {
          this.isLoading = false;
        }
      })

  }

  currencyActive(event) {
    this.currencyForm.value.isActive = event;
  }

  back() {
    this.router.navigate(['/country'],{replaceUrl : true})
  }


}
