import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { MessageBoxListComponent } from './message-box-list.component';
import { ConDropPointDirective } from 'src/app/shared/link-table/directives/con-drag-drop-directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule
  ],
  declarations: [MessageBoxListComponent, ConDropPointDirective],
  exports: [MessageBoxListComponent]
})

export class MessageboxlistModule { }
