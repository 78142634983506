import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { SearchLimit } from '../models/product.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProductHttpService {
    private logoPositionlist = new BehaviorSubject([])
    logoPositionlistArray = this.logoPositionlist.asObservable()

    private logoArealist = new BehaviorSubject([])
    logoArealistArray = this.logoArealist.asObservable()
    constructor(private apiService: ApiService) { }

    // logopsotion
    setLogoPositions(logoPosition: any) {
        this.logoPositionlist.next(logoPosition)
    }
    getLogoPositions(): Observable<any> {
        return this.logoPositionlist.asObservable()
    }

    //logoareas
    setLogoArea(logoArea: any) {
        this.logoArealist.next(logoArea)
    }
    getLogoAreas(): Observable<any> {
        return this.logoArealist.asObservable()
    }


    getProductList(searchData: any) {
        const path = 'en/productsadminsearch?pageIndex=' + searchData.pageIndex + '&pageSize=' + searchData.pageSize;
        return this.apiService.post(path, searchData.data).map((res) => {
            return res;
        });
    }

    getProductList1(searchData: any) {
        const path = 'en/productsadminsearchV1?pageIndex=' + searchData.pageIndex + '&pageSize=' + searchData.pageSize;
        return this.apiService.post(path, searchData.data).map((res) => {
            return res;
        });
    }

    getProductDetails(productID: any) {
        const path = 'all/products/' + productID + '?translations=true&admin=true&OrderbyName=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    saveProduct(data: any) {
        const path = 'en/productproducts/' + data.ProductProductID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateProduct(data: any) {
        const path = 'en/products' + ((data.ProductID) ? '/' + data.ProductID : '');
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    deleteProduct(data: any) {
        let path = 'en/products/' + data.ProductID;
        if (data.deleteCP) {
            path = 'en/products/' + data.ProductID + '?DeleteCustomerFromProduct=' + data.deleteCP;
        }
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getProductListBySearchCriteria(searchData: any, limit?: SearchLimit) {
        const { pageIndex, pageSize } = limit;
        const path = 'en/search?pageIndex=' + pageIndex + '&pageSize=' + pageSize;
        return this.apiService.post(path, searchData).map((res) => {
            return res;
        });
    }

    getCampaignProductListBySearchCriteria(searchData: any, limit?: SearchLimit) {
        const { pageIndex, pageSize } = limit;
        const path = 'en/SearchV1?pageIndex=' + pageIndex + '&pageSize=' + pageSize;
        return this.apiService.post(path, searchData).map((res) => {
            return res;
        });
    }

    getSuppliersList(customerID, loggedID) {
        const path = 'en/suppliers?returnOnlyCustomerSupplier=true&CustomerId=' + customerID + '&LoggedInCustomerID=' + loggedID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addProductAll(params) {
        const path = 'en/CampaignProductAll';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    removeProductALL(params) {
        const path = 'en/DeleteProductALL';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }
    // getUnderSuppliersList() {
    //     const path = 'en/undersuppliers?pagesize=1000&customerID=1';
    //     return this.apiService.get(path).map((res) => {
    //         return res;
    //     });
    // }

    getUnderSuppliers(customerid) {
        const path = 'en/undersuppliers?pagesize=1000&customerID=' + customerid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomerInfo(costomerId) {
        const path = 'en/customers/' + costomerId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductColorList(data) {
        const path = 'en/productcolors?customerID=' + data.customerID + '&translations=' +
            data.translations + '&onlyParentColors=' + data.onlyParentColors;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductCategory(data) {
        const path = 'en/categories?customerID=' + data.customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductSizeList(data) {
        const path = 'all/productsizes?translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductLogoList(data) {
        const path = 'all/logopositions?supplierID=' + data.supplierID + '&translations=' + data.translations;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductLogoArea(data) {
        const path = 'all/logomaxareas?supplierID=' + data.supplierID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    updateLogoArea(data) {
        const path = 'en/logomaxareas/' + data.LogoMaxAreaID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    deleteLogoArea(data) {
        const path = 'en/logomaxareas/' + data;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getEnProductSizeList(data) {
        const path = 'en/productsizes?translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllProductColorList(data) {
        const path = 'all/productcolors?supplierID=' + data.supplierID + '&translations=' + data.translations;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllProductGovtCosts(data) {
        const path = 'all/productgovernmentalcosts?translations=' + data.translations;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllProductAdditionalCosts(data) {
        const path = 'all/productadditionalcosts?translations=' + data.translations;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    checkProductCategory(data) {
        const path = 'en/productcategory/' + data.productID + '/' + data.categoryID + '/' + data.customerID;
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }
    getParentProductColorList(data) {
        const path = 'all/productcolors?onlyParentColors=true&translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addProductColor(data) {
        const path = 'en/productcolors';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getProductGovernmentalCosts() {
        const path = 'en/productgovernmentalcosts';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductAdditionalCosts() {
        const path = 'en/productadditionalcosts';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addProductGovernmentalCosts(data) {
        const path = 'en/productgovernmentalcosts';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    addProductAdditionalCosts(data) {
        const path = 'en/productadditionalcosts';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    addProductSize(data) {
        const path = 'en/productsizes?translations=true';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    addProductLogoPosition(data) {
        const path = 'en/logopositions';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    addProductProperty(data) {
        let path = 'all/productproperties';
        if (data.ProductPropertyID) {
            path = 'all/productproperties/' + data.ProductPropertyID;
        }
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateProductColor(data) {
        const path = 'en/productcolors/' + data.parentId;
        return this.apiService.post(path, data.data).map((res) => {
            return res;
        });
    }

    updateProductGovernmentalCosts(data) {
        const path = 'en/productgovernmentalcosts/' + data.parentId;
        return this.apiService.post(path, data.data).map((res) => {
            return res;
        });
    }

    updateProductAdditionalCosts(data) {
        const path = 'en/productadditionalcosts/' + data.parentId;
        return this.apiService.post(path, data.data).map((res) => {
            return res;
        });
    }

    updateAllProductAdditionalCosts(data) {
        const path = '/all/productadditionalcosts/' + data.ProductAdditionalCostID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateProductSize(data) {
        const path = 'en/productsizes/' + data.ProductSizeID;
        return this.apiService.post(path, data.data).map((res) => {
            return res;
        });
    }

    updateProductLogoPosition(data) {
        const path = 'en/logopositions/' + data.LogoPositionID;
        return this.apiService.post(path, data.data).map((res) => {
            return res;
        });
    }

    deleteProductColor(productID) {
        const path = 'en/productcolors/' + productID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProductGovernmentalCosts(productID) {
        const path = 'en/productgovernmentalcosts/' + productID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProductAdditionalCosts(productID) {
        const path = 'en/productadditionalcosts/' + productID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProductSize(ProductSizeID) {
        const path = 'en/productsizes/' + ProductSizeID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProductLogoPosition(LogoPositionID) {
        const path = 'en/logopositions/' + LogoPositionID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProductUnit(ProductUnitID) {
        const path = 'en/productunits/' + ProductUnitID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    deleteProductProperty(ProductPropertyID) {
        const path = 'all/productproperties/' + ProductPropertyID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getProductUnits() {
        const path = 'en/productunits';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addProductUnits(data) {
        const path = 'en/productunits';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateProductUnits(data) {
        const path = 'en/productunits/' + data.ProductUnitID;
        return this.apiService.post(path, data.data).map((res) => {
            return res;
        });
    }

    getAllProductUnits() {
        const path = 'all/productunits?translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getUnits() {
        const path = 'en/productunits';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductProperties() {
        const path = 'all/productproperties?translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }


    getProductSuppliers() {
        const path = 'en/suppliers';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getTransportationOriginCountries() {
        const path = 'en/countries?transportationOriginCountries=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductCurrency() {
        const path = 'en/currencies';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductAdditionalCostTypes() {
        const path = 'en/productadditionalcosttypes';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getlogoTypePagination(searchdata: any) {
        const path = 'all/LogoTypesWithPagination?translations=true&' + 'PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.logotypeid;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }


    getmanagePropertiesPagination(searchdata: any) {
        const path = 'all/ProductPropertiesWIthPagination?translations=true&PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.ProductPropertyID;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    addProduct(data) {
        const path = 'en/products';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    validateManualId(data) {
        const path = 'en/products?manualID=' + data.manualid + '&productId=' + data.productid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getLogoType() {
        const path = 'en/logotypes';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getLogoTypeAll() {
        const path = '/all/logotypes?translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getChangeVisibility(data: any) {
        const path = 'en/products/changevisibility/' + data.ProductID + '?userID=' + data.userID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addLogoMax(data) {
        const path = 'en/logomaxareas';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getHandlingCost() {
        const path = 'en/productadditionalcosttypes?noKg=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    convertToBatchProduct(productID) {
        const path = 'en/convertproducttobatch/' + productID;
        return this.apiService.post(path, []).map((res) => {
            return res;
        })
    }

    lockDeliveryDate(request) {
        const path = 'en/createlockedproductionline';
        return this.apiService.post(path, request).map((res) => {
            return res;
        })
    }

    removeDevliveryDate(id) {
        const path = 'en/deletelockedproductionlines/' + id;
        return this.apiService.delete(path).map((res) => {
            return res;
        })
    }

    searchManualId(request) {
        const path = 'en/productsadminsearch';
        return this.apiService.post(path, request).map((res) => {
            return res;
        })
    }

    searchIspProductProductManualId(request) {
        const path = 'en/productsadminsearch';
        return this.apiService.post(path, request).map((res) => {
            return res;
        })
    }

    autoCompleteGroup(data) {
        const path = 'en/productgroups?productGroupName=' + data;
        return this.apiService.get(path).map((res) => {
            return res;
        })

    }

    wareHouse(customerID) {
        const path = `en/warehouses?customerID=${customerID}&returnAllWarehouses=true`;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    categoryProductCustomer(request) {
        const path = 'en/productcustomer/' + request.productID + '/' + request.customerID;
        return this.apiService.post(path).map((res) => {
            return res;
        })
    }

    addGroup(request) {
        const path = 'en/productgroups/' + request.groupid + '/' + request.productid;
        return this.apiService.post(path).map((res) => {
            return res;
        })
    }

    deleteGroup(request) {
        const path = 'en/productgroups/' + request.groupid + '/' + request.productid;
        return this.apiService.delete(path).map((res) => {
            return res;
        })
    }

    autoCompleteLogoType(request) {
        const path = request.selectedLangCode + '/AutoCompleteLogotypes?name=' + request.name + '&translations=true';
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    autoCompleteManageproperties(data) {
        const path = 'en/AutoCompleteProductProperties?translations=' + data.translations + '&name=' + data.name;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }
    popupAutocomplete(data) {
        let path;
        if (data.customerID) {
            path = `${data.selectedLangCode}/AutoCompleteColors?supplierID=` + data.SupplierID + '&translations=' + data.translations + '&name=' + data.name + '&CustomerID=' + data.customerID;
        }
        else {
            path = `${data.selectedLangCode}/AutoCompleteColors?supplierID=` + data.SupplierID + '&translations=' + data.translations + '&name=' + data.name;
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getPopupProductColors(data) {
        const path = 'en/ProductColorsWithPagination?supplierID=' + data.SupplierID + '&translations=' + data.translations + '&pageIndex=1&pageSize=10';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getPopupList(request) {
        let idParam = request.customerID ? '&customerID=' + request.customerID : '';
        const path = request.apiurl + '?supplierID=' + request.supplierID + '&translations=' + request.translations + '&pageIndex=' + request.pageIndex + '&pageSize=' + request.pageSize + '&ID=' + request.id + idParam + '&Name=' + request.name;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductColorsPagination(searchdata: any) {

        const path = 'all/ProductColorsWithPagination?translations=true&supplierID=' + searchdata.SupplierID + '&PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.ProductColorID + '&CustomerID=' + searchdata.CustomerID + '&showallcolors=' + searchdata.showAll;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    productSizeAutoComplete(data: any) {
        const path = `${data.selectedLangCode}/AutoCompleteSizes?supplierID=` + data.SupplierID + '&translations=' + data.translations + '&name=' + data.name;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductSizesPagination(searchdata: any) {
        const path = 'all/ProductSizesWithPagination?translations=true&supplierID=' + searchdata.SupplierID + '&PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.ProductSizeID;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    productLogoPositionsAutoComplete(data: any) {
        const path = `${data.selectedLangCode}/AutoCompleteLogoPositions?supplierID=` + data.SupplierID + '&translations=' + data.translations + '&description=' + data.name;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getLogoPositionsPagination(searchdata: any) {
        const path = 'all/LogoPositionsWithPagination?translations=true&supplierID=' + searchdata.SupplierID + '&PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.LogoPositionID;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    logoMaxAreasAutoComplete(data: any) {
        const path = 'en/AutoCompleteLogoMaxAreas?supplierID=' + data.SupplierID + '&translations=' + data.translations + '&description=' + data.name;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getLogoMaxAreasPagination(searchdata: any) {
        const path = 'all/LogoMaxAreasWithPagination?translations=true&supplierID=' + searchdata.SupplierID + '&PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.LogoMaxAreaID;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    productAdditionalCostAutoComplete(data: any) {
        const path = `${data.selectedLangCode}/AutoCompleteAdditionCosts?&translations=` + data.translations + '&name=' + data.name;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductAdditionalCostPagination(searchdata: any) {
        const path = 'all/ProductAdditionalCostsWithPagination?translations=true&PageIndex=' + searchdata.PageIndex + '&PageSize=' + searchdata.PageSize + '&ID=' + searchdata.ProductAdditionalCostID;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    autoCompleteDropDownPopup(request) {
        const queryString = new HttpParams({ fromObject: request.queryParams }).toString();
        const endPoint = request.apiUrl;
        return this.apiService.get(endPoint + '?' + queryString).map((res) => {
            return res;
        })
    }

    getChildChildCustomer(custId) {
        const path = 'en/customers/list?ParentCustomerID=' + custId + '&returnParentCustomer=' + true;
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    bulkAction(request) {
        const path = 'en/products/changevisibility?UserID=' + request.userid;
        return this.apiService.post(path, request.json).map((res) => {
            return res;
        })
    }

    bulkDelete(request) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                "ProductIDs": request
            }
        }
        const path = 'en/products';

        return this.apiService.deleteWithJson(path, options).map((res) => {
            return res;
        })
    }

    getBrandList() {
        const path = 'en/ProductBrand';
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }

    getUnderSupplierList(request) {
        const path = 'en/undersuppliers?customerID=' + request.customerID + '&SupplierID=' + request.supplierID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomerProductData(request) {
        // const path = `en/CustomerProducts/${request.customerID}/${request.productID}`;
        const path = `en/CustomerProducts/${request.customerID}/${request.productID}`;
        // const path = `en/CustomerProducts/1/62149/true`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomerProduct(request) {
        // const path = `en/CustomerProducts/${request.customerID}/${request.productID}`;
        const path = `en/CustomerProducts/${request.customerID}/${request.productID}/true`;
        // const path = `en/CustomerProducts/1/62149/true`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    customerProduct(request) {
        const path = 'en/CustomerProducts';
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    customerPublish(url, request) {
        const path = `en/changepublishstatusforproduct/${url.CustomerID}/${url.productID}`;
        return this.apiService.put(path, request).map((res) => {
            return res;
        });
    }

    checkProductType(request) {
        const path = `en/products/EditableType?LoggedInCustomerID=${request.customerID}&ProductID=${request.productID}&CustomerID=${request.productCustID}&IsCustomerSelected=${request.isCustomerSelected}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    customerProductDelete(request) {
        const path = `en/CustomerFromProducts/${request.customerID}/${request.productID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    updateCustomerProduct(data) {
        const path = 'en/CustomersProductInfo';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    bulkPublishUnpublish(request, jsonReq) {
        const path = `en/products/BulkChangePublishStatus/${request.isOriginalPro}/${request.type}`;
        return this.apiService.put(path, jsonReq).map((res) => {
            return res;
        });
    }

    publishUnpublishOPCP(request) {
        const path = `en/changepublishstatusforproduct/${request.customerID}/${request.productID}?ChangeOriginalProductStatus=${request.isOriginalPro}`;
        return this.apiService.put(path).map((res) => {
            return res;
        });
    }

    bulkProduct(data) {
        const path = 'en/BulkProduct';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    getFilterList() {
        const path = 'en/products/HowtoUseMst';
        return this.apiService.get(path).map((res) => {
            return res;
        })
    }
}
