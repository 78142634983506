import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from '@angular/common'
import { ColumnSetting } from '../../shared/link-table-border/models/link-table-model';
import { ReportsHttpService } from '../services/reports-http.service';
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ToastService } from 'ng-uikit-pro-standard';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
    selector: 'rewardkart-admin-report-useractivity',
    templateUrl: './report-useractivity.component.html',
    styleUrls: ['./report-useractivity.component.scss']
})

export class ReportUseractivityComponent implements OnInit {
    @ViewChild('orderDetailModal', { static: true }) orderDetailModal: ModalDirective;
    fromDate: any;
    toDate: any;
    date = new Date();
    campaignID: any;
    domainName: any = '';
    userName: string = '';
    customerName: string = '';
    isParentCustomer: boolean = true;
    webshop: any;
    datePickerOptions: IMyOptions = {};
    enddatePickerOptions: IMyOptions = {};
    childwebshop: any;
    childShop: any;
    showLoader: boolean = false;
    campaignList: any;
    activityReport: any;
    showTable: boolean = false;
    noData: boolean = false;
    tableEvent: any;
    public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public fileExtension = '.xlsx';
    public exportData: any = [];
    userActivity: any;
    orderDetails: any;
    columns: ColumnSetting[] = [
        {
            primaryKey: 'OrderCreationDate',
            header: 'Order Date',
            format: 'date'
        },
        {
            primaryKey: 'OrderId',
            header: 'Order Id'
        },
        {
            primaryKey: 'OrderItemID',
            header: 'Order Item Id'
        },
        {
            primaryKey: 'CategoryName',
            header: 'Category'
        },
        {
            primaryKey: 'ProductName',
            header: 'Product Name'
        },
        {
            primaryKey: 'Quantity',
            header: 'Quantity',
        },
        {
            primaryKey: 'OrderPrice',
            header: 'Price',
            format: 'number'
        },
        {
            primaryKey: 'Status',
            header: 'Status'
        },
        {
            primaryKey: 'action',
            header: 'Details',
            format: 'useraction',

        }
    ];
	userID: any;
    constructor(private linkTableService: LinkTableService, private reportHttpService: ReportsHttpService, private datePipe: DatePipe, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
            let user = JSON.parse(sessionStorage.getItem('userdetails'));
			this.userID = user.UserID;

        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.getCampaigns(user.UserID);
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }
    }
    ngOnInit() {
        let dt = new Date();
        const disableDate = new Date(dt.setDate(dt.getDate() + 1));
        const startDate = new Date(dt.setDate(dt.getDate() - 90));
        this.fromDate = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
        this.toDate = this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear();
        this.datePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
        };
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
            disableUntil: {
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                day: startDate.getDate()
            },
        };
        this.getChildShops();
    }

    getReport() {

        let a = this.fromDate.split('/');
        let b = this.toDate.split('/');
        let fromDate = a[2] + '-' + a[1] + '-' + a[0];
        let toDate = b[2] + '-' + b[1] + '-' + b[0];
        let params = {
            'fromDate': fromDate,
            'toDate': toDate,
            'campaignID': this.campaignID,
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'userName': this.userName,
            'customerName': this.customerName,
			'userID': this.userID
        }
        this.showLoader = true;
        this.reportHttpService.getUseractivityReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.showTable = false;
            if (data && data.IsSuccess) {
                this.noData = false;
                this.activityReport = data.UserReportDetails;
                this.userActivity = [];
                this.showTable = true;
                if (this.activityReport) {
                    let count: Array<any> = [];
                    this.activityReport.forEach((i: any, key: any) => {
                        if (key !== 0 && ((key + 1) <= this.activityReport.length)) {

                            if (i.UserID === this.activityReport[key - 1].UserID) {

                            } else {
                                if (count.length !== 0) {
                                    if (count.indexOf(i.UserID) === -1) {
                                        count.push(i.UserID);
                                        this.userActivity.push(i);
                                    }
                                }
                            }
                        } else {
                            count.push(this.activityReport[key].UserID);
                            this.userActivity.push(this.activityReport[key]);
                        }

                    });

                    this.userActivity.forEach((element1: any) => {
                        element1.activity = [];
                        this.activityReport.forEach((element2) => {
                            if (element1.UserID === element2.UserID) {
                                element1.activity.push(element2)
                            }
                        })
                    })

                }
            } else {
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })
    }

    getChildShops() {
        this.showLoader = true;
        this.webshophttpservice.getchildWebshopList(this.webshop.CustomerID, false).subscribe((res: any) => {
            this.showLoader = false;
            this.getReport();
            if (res) {
                let data = res;
                // let childcustomer = data.ChildCustomers
                this.childwebshop = [];
                this.childwebshop.push({ label: 'All Child Shop', value: 'All Child Shop' });
                data.ChildCustomers.forEach(element => {
                    const langVal = {
                        value: element.CustomerID,
                        label: element.Name,
                    };
                    this.childwebshop.push(langVal);
                });
                this.childShop = this.childwebshop[0].value;
            }
        },
            err => {
                this.showLoader = false;
                this.getReport();
            });
    }

    getCampaigns(id) {
        let param = 'pageSize=100&pageIndex=1&userId=' + id;
        this.showLoader = true;
        this.campaignHttpService.getCampaignList(param).subscribe((res: any) => {
            this.showLoader = false;
            this.campaignList = [];
            this.campaignID = '';
            if (res && res.length > 0) {
                let data = res;
                this.campaignList.push({ label: 'All Campaign', value: 'All Campaign' });
                data.forEach(element => {
                    const c = {
                        value: element.CampaignId,
                        label: element.CampaignName,
                    };
                    this.campaignList.push(c);
                });
                this.campaignID = this.campaignList[0].value;
            }
        }, _e => {
            this.showLoader = false;
        });
    }


    dateChange(event) {
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableUntil: {
                year: event.date.year,
                month: event.date.month,
                day: event.date.day,
            },
        };
    }

    getCampaignSelectedValue(_event) {

    }

    getSelectedValue(event) {
        this.childwebshop.forEach((element) => {
            if (element.value === event) {
                this.domainName = element.label;
            }
        })
        if (event === 'All Child Shop') {
            this.campaignID = '';
            if (this.isParentCustomer) {
                this.domainName = '';
            }
        } else {
            this.getCampaigns(event);
        }
    }

    download() {
        this.exportData = [];

        this.activityReport.forEach(e => {
            this.exportData.push({
                'User Id': e.UserID,
                'Name': e.Name,
                'Mobile Number': e.PhoneNumber,
                'Email': e.Email,
                'Order Date': this.datePipe.transform(e.OrderCreationDate, 'dd/MM/yyyy'),
                'Order Id': e.OrderId,
                'Order Item Id': e.OrderItemID,
                'Category': e.CategoryName,
                'Product Name': e.ProductName,
                'Quantity': e.Quantity,
                'Price': e.OrderPrice,
                'Status': e.Status,
                'Campaign Name': e.CampaignName,
                'Parent Name': e.ParentShop,
                'Child Name': e.Childhop
            })
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, 'user-activity-report');
    }

    saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }

    orderDetail(_event) {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
            if (data) {
                const orderId = data.OrderId;
                this.reportHttpService.getOrderDetails(orderId).subscribe((data: any) => {
                    if (data && data.UserReportDetails.length !== 0) {
                        this.orderDetails = data.UserReportDetails[0];
                        this.orderDetailModal.show();
                    }
                })
            }
        });
    }

    close() {
        this.orderDetailModal.hide();
    }

    ngOnDestroy() {
        if (this.tableEvent) {
            this.tableEvent.unsubscribe();
        }
    }
}