import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnSetting } from "src/app/shared/link-table/models/link-table-model";
import { CompanyOrderHttpService } from "../state/services/company-order.http.services";

@Component({
    selector: 'app-company-order-detail',
    templateUrl: './company-order-detail.component.html',
    styleUrls: ['./company-order-detail.component.scss']
})

export class CompanyOrderDetailComponent implements OnInit {
    customerID: any;
    orderID: any;
    orderDetail: any;
    orderSummary: any;
    showLoader: boolean = false;
    startDate: any;
    endDate: any;
    actions: any = ['orderview'];
    columns: ColumnSetting[] = [
        {
            primaryKey: 'OrderItemID',
            header: 'Item ID'
        },
        {
            primaryKey: 'ProductName',
            header: 'Product Name'
        },
        {
            primaryKey: 'Quantity',
            header: 'Quantity'
        },
        {
            primaryKey: 'ProductPrice',
            header: 'Price'
        },
        {
            primaryKey: 'ProductUnitName',
            header: 'Units'
        },
        {
            primaryKey: 'ProductID',
            header: 'Product ID'
        },
        {
            primaryKey: 'DeliveryDate',
            format: 'date',
            header: 'Delivery Date'
        },
        {
            primaryKey: 'action',
            format: 'action',
            width: '30px'
        }
    ]
    header: any;
    constructor(private router: Router, private _http: CompanyOrderHttpService) {

        if (history.state.data) {
            this.customerID = history.state.data.customerID;
            this.orderID = history.state.data.orderSummary.OrderID;
            this.startDate = history.state.data.startDate;
            this.endDate = history.state.data.endDate;
            this.orderSummary = history.state.data.orderSummary;
        } else {
            this.router.navigate(['/admin-dashboard']);
        }
    }

    ngOnInit() {
        this.getOrderSummary()
    }

    getOrderSummary() {
        this.showLoader = true;
        this._http.orderdetails(this.customerID, this.startDate, this.endDate, this.orderID).subscribe((data) => {
            this.showLoader = false;
            this.orderDetail = data;
        }, (_error) => {
            this.showLoader = false;
        })
    }

    viewSummary(event) {
        console.log(event)
        let data = {
            customerID: this.customerID,
            startDate: this.startDate,
            endDate: this.endDate,
            orderDetail: event,
            orderSummary: this.orderSummary
        }

        this.router.navigate(['/admin-company-order-detail-view'], { state: { data }, replaceUrl: false });
    }

    goBack() {
        let data = {
            id: this.customerID,
            startDate: history.state.data.startDate,
            endDate: history.state.data.endDate,
        }
        this.router.navigate(['/admin-company-order-summary'], { state: { data }, replaceUrl: false });
    }
}