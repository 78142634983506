import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LinkTableState } from '../reducers/link-table.reducer';
import { LinkTableActions } from '../actions/index';
import { getSelectedRow } from '../reducers/index';

@Injectable()
export class LinkTableService {
    constructor(private store: Store<LinkTableState>) { }

    setSelectedRow(data) {
        this.store.dispatch(new LinkTableActions.SelectedRow(data));
    }

    getSelectedRow() {
        return this.store.select(getSelectedRow);
    }
}
