import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, dashboardReducers } from './dashboard.reducer';

export interface State {
    dashboardModule: DashboardState;
}

export const reducers = dashboardReducers;

export const getDashboardState = createFeatureSelector<DashboardState>('dashboardModule');

export const getDashboardListSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.orderswidget);

export const getDashboardStockListSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.stockwidget);

export const getDashboardOrderFlowSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.orderflowwidget);

export const getDashboardProductionLineSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.productionlinewidget);

export const getDashboardProductListSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.productlistwidget);

export const getMessageSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.messagewidget);

export const getOrderStateSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.orderstatewidget);

export const getPendingUserSuccess = createSelector(getDashboardState,
    (state: DashboardState) => state.pendinguserwidget);