import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkDownloadIconComponent } from './link-download-icon.component';

@NgModule({
    declarations: [LinkDownloadIconComponent],
    imports: [CommonModule],
    exports: [LinkDownloadIconComponent]
})
export class LinkDownloadIconModule { }
