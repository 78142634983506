import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { BrandEditComponent } from './brand-edit.component';
import { BrandHttpService } from '../state/services/brand.http.service';
import { BrandService } from '../state/services/brand.service';

import { brandReducers } from '../state/reducers/brand.reducer';
import { BrandEffects } from '../state/effects/brand.effects';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        CommonFormModule,
        EffectsModule.forFeature([BrandEffects]),
        StoreModule.forFeature('brandModule', brandReducers),
        ControlValidationModule,
        PageLoaderModule,
        TranslateModule
    ],
    declarations: [BrandEditComponent],
    providers: [BrandHttpService, BrandService],
    exports: [BrandEditComponent]
})

export class BrandEditModule { }