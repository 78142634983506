import { Component, OnInit, Output, OnChanges, EventEmitter, Input } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { } from 'events';
import { ProductService } from '../../state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../../state/services/product.http.service';

@Component({
  selector: 'app-product-colors',
  templateUrl: './product-colors.component.html',
  styleUrls: ['./product-colors.component.scss']
})
export class ProductColorsComponent implements OnInit, OnChanges {
  isMoqTextError: any;
  productColorForm: FormGroup;
  optionsSelectColor: any = [];
  model: any;
  productColors: any = [];
  selectedColor: any;
  @Input() productDetails: any;
  @Input() loadApiColor: any;
  @Output() sendProductColorDetails = new EventEmitter<any>();
  showManageColor: boolean;
  resetColorModal: Boolean = false
  items: FormArray;
  selectedData: any;
  showManageColorModal: Boolean = false;
  popupSelectColor: any = [];
  searchSelectColorResult: any = [];
  SearchSelectColor: any;
  toBeDeleted: any
  UpdateColor: any[];
  SetColorItems: { ProductColorID: any; ColorCode: any; MOQ: any; Name: any; ColorCodes: any; ColorCodeArr: any; Action: string; };
  setvalue: boolean;
  seleteSet: boolean;
  deleteSet: boolean;
  searchKey: any = '';
  searchKeyCode: any = '';
  resultMessage: any;
  showSearch: boolean;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selfColors: any = [];
  showPopUp: boolean = false;
  selectlabel: any = 'Select Color';
  popupList: any;
  dropdownData: any = {};
  deleteModal: any;
  colorlength: any;
  constructor(public formBuilder: FormBuilder,
    public translate: TranslateService,
    public toast: ToastService,
    public productService: ProductService, public Producthttpservice: ProductHttpService) { }


  ngOnChanges() {

    if (this.loadApiColor === true) {
      this.clearFormItems();
      // this.getProductColors();
      this.setProductColors();
      this.loadPagination()
      //this.getAllProductColors();
    }

  }



  ngOnInit() {
    this.loadForm();
    this.getProductColors();
    this.setProductColors()
    this.productColorForm.valueChanges.subscribe(() => {
      this.getValue();
    });
    this.getValue();
    // this.getPopupProductColors();
    this.loadPagination();
    this.popupList = {
      apiurl: 'en/ProductColorsWithPagination',
      supplierid: this.productDetails.SupplierID,
      key: 'ProductColorID',
      label: 'Colors',
      autoComplete: {
        apiUrl: 'AutoCompleteColors',
        searchKey: 'name',
        supplierid: this.productDetails.SupplierID
      }
    }
  }

  clearFormItems() {
    const control = <FormArray>this.productColorForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  searchList() {
    this.items.value.filter((data, index) => {
      let value = (data.data.Name).toLowerCase();
      let searchData = (this.searchKey).toLowerCase();
      let colorcode = (data.data.ColorCode).toLowerCase();
      let searchColorKey = (this.searchKeyCode).toLowerCase();
      if (((value).includes(searchData)) && ((colorcode).includes(searchColorKey))) {
        data.search = 'show';
      }
      else {
        data.search = 'hide';
      }
      // if (((colorcode).includes(searchColorKey))) {
      //   data.search = 'show';
      // } 
      // else{
      //   data.search = 'hide';
      // }
      // this.hideSearch();
    });
    const noresult = this.items.value.filter(get => get.search === 'show');
    this.resultMessage = noresult.length > 0 ? false : true;
  }


  resetFilter() {
    this.searchKey = '';
    this.searchKeyCode = '';
    this.items.value.filter((data, index) => {
      data.search = 'show';
    });
    this.resultMessage = this.items.length === 0 ? true : false;
  }

  loadForm() {
    this.productColorForm = this.formBuilder.group({
      color: [null, Validators.required],
      moq: ['', Validators.required],
      items: this.formBuilder.array([]),
      // selfColors: this.formBuilder.array([])
    });
    this.items = this.productColorForm.get('items') as FormArray;
    // this.selfColors = this.productColorForm.get('items') as FormArray;
  }

  createItem(colorVal?): FormGroup {
    return this.formBuilder.group({
      color: colorVal ? colorVal.color : '',
      moq: colorVal ? colorVal.moq : '',
      data: colorVal ? colorVal.data : '',
      status: colorVal ? colorVal.status : ''
    });

  }

  getValue() {
    const colors = [];
    this.UpdateColor = []
    if (this.selfColors !== undefined) {
      this.selfColors.forEach((item) => {
        // colors.push(item.data);
        if (this.setvalue || this.deleteSet) {
          colors.push(item.data);
          this.sendProductColorDetails.emit(colors);
        }
        else {
          this.SetColorItems = {
            ProductColorID: item.color,
            ColorCode: item.data.ColorCode,
            MOQ: item.moq,
            Name: item.data.Name,
            ColorCodes: item.data.ColorCodes,
            ColorCodeArr: item.data.ColorCodes,
            Action: "Update"
          }

          this.UpdateColor.push(this.SetColorItems)
          this.sendProductColorDetails.emit(this.UpdateColor);
        }
      });
    }

  }


  setProductColors() {
    const selfColor = [];
    if (this.productDetails.ProductColors !== undefined) {
      if (this.productDetails.ProductColors.length > 0) {
        this.productDetails.ProductColors.forEach(item => {
          const colorVal = {
            color: item.ProductColorID,
            moq: item.MOQ,
            data: item,
            status: 'saved'
          };
          // this.items.push(this.createItem(colorVal));
          // this.selfColors.push(this.createItem(colorVal))
          selfColor.push(colorVal)
        })
        this.selfColors = selfColor;
        this.loadPagination();
      }
    }
  }
  getProductColors() {
    const requestData = { 'supplierID': 1, 'translations': true };
    this.productService.loadProductColors(requestData);
    this.productService.getProductColorsList().subscribe((data) => {
      if (data !== null) {
        this.optionsSelectColor = [];
        data.forEach(element => {
          if (element.Name) {
            const colorVal = {
              value: element.ProductColorID,
              label: element.Name,
              data: element,
              colorHash: '#' + element.ColorCode
            };
            this.optionsSelectColor.push(colorVal);
          }
        });
      }
    });
  }




  resetColor(evnt) {
    this.resetColorModal = true;
    setTimeout(() => {
      this.resetColorModal = false;
    }, 1000)
  }


  getPopupProductColors() {
    const requestData = { 'supplierID': 1, 'translations': true };
    // this.productService.loadProductColors(requestData);
    this.Producthttpservice.getPopupProductColors(requestData).subscribe((data: any) => {
      if (data !== null) {
        this.popupSelectColor = data.ItemsCollection;
        // data.forEach(element => {
        //   if (element.Name) {
        //     const colorVal = {
        //       value: element.ProductColorID,
        //       label: element.Name,
        //       data: element,
        //       colorHash:'#' + element.ColorCode
        //     };
        //     this.optionsSelectColor.push(colorVal);
        //   }
        // });
      }
    });
  }

  // getSelectProductColorsSearch(event) {
  //   const requestData = {
  //     'supplierID': 1,
  //     'translations': true,
  //     'name': this.SearchSelectColor,
  //   };
  //   this.Producthttpservice.getSelectProductColorsSearch(requestData).subscribe((data) => {
  //     if (data !== null) {
  //       this.searchSelectColorResult = data;
  //     }
  //   });
  // }



  // getAllProductColors() {
  //   const requestData = { 'supplierID': 1, 'translations': true };
  //   this.productService.loadAllProductColors(requestData);
  //   this.productService.getAllProductColorsList().subscribe((data) => {
  //     if (data !== null) {
  //       data.forEach(element => {
  //         const colorVal = {
  //           value: element.ProductColorID,
  //           label: element.Name
  //         };
  //       });
  //     }
  //   });
  // }

  addColorOld() {
    this.setvalue = true;
    // this.selfColors.forEach(item => {
    //   const colorVal = {
    //     color: item.data.ProductColorID,
    //     moq: item.MOQ,
    //     data: item,
    //     status: 'saved'
    //   };
    //   this.items.push(this.createItem(colorVal));
    // })
    const { color, moq } = this.productColorForm.controls;

    if (!(color.value === '' || color.value === undefined || color.value === null || moq.value === '' || moq.value === null || moq.value < 0 || moq.value > 9999999)) {
      this.selectedData.Action = "Insert";
      this.selectedData.MOQ = moq.value;
      const productColor: any = {
        color: color.value,
        moq: moq.value,
        data: this.selectedData,
        status: 'added'
      };
      this.items.push(this.createItem(productColor));

      // this.selfColors.push(this.createItem(productColor))
      this.selfColors.push(productColor);
      color.setValue(null);
      moq.setValue('');
      this.toast.success("Color Added Successfully")
      this.getValue();

    } else {
      if ((color.value === '' || color.value === undefined || color.value === null) && (moq.value === '' || moq.value === null)) {
        this.toast.error("Please fill the Fields ")
      }
      else if (color.value === '' || color.value === undefined || color.value === null) {
        this.toast.error("Please select color")
      } else if (moq.value === '' || moq.value === null) {
        this.toast.error("Please Enter MOQ")
      } else if (moq.value < 0) {
        this.toast.error("MOQ should not be negative")
      } else if (moq.value > 9999999) {
        this.toast.error("MOQ should not be greater than 9999999")
      }
    }
    // this.hideSearch();
    this.loadPagination()
  }

  addColor() {
    this.setvalue = true;
    // this.selfColors.forEach(item => {
    //   const colorVal = {
    //     color: item.data.ProductColorID,
    //     moq: item.MOQ,
    //     data: item,
    //     status: 'saved'
    //   };
    //   this.items.push(this.createItem(colorVal));
    // })


    const { moq } = this.productColorForm.controls;
    const color = this.dropdownData;

    const getDeletedColors = this.productColorForm.value.items.filter(data => {
      data.data.Action === "Delete" && data.data.ProductColorID === color.ProductColorID
    })
    if (getDeletedColors.length === 0) {
      let getColorName = this.optionsSelectColor.filter(colorName => colorName.value === color.ProductColorID)
      if (getColorName.length > 0) {
        getColorName = getColorName[0].label;
      }
      let getPushData = this.productColorForm.value.items.filter(data => data.data.Name === getColorName && getColorName.length > 0 && color.ProductColorID === data.data.ProductColorID)
      if (getPushData.length === 0) {
        if (!(color.ProductColorID === '' || color.ProductColorID === undefined || color.ProductColorID === null || moq.value === '' || moq.value === null || moq.value < 0 || moq.value > 9999999)) {
          this.selectedData.Action = "Insert";
          this.selectedData.MOQ = moq.value;
          const productColor: any = {
            color: color.ProductColorID,
            moq: moq.value,
            data: this.selectedData,
            status: 'added',
            Action: "Insert"
          };
          this.items.push(this.createItem(productColor));
          // this.selfColors.push(this.createItem(productColor))
          this.selfColors.push(productColor);
          // color.setValue(null);
          // moq.setValue('');
          this.toast.success("Color Added Successfully")
          this.selectedData = {};
          this.dropdownData = {};
          this.productColorForm.reset();
          this.getValue();

        } else {
          if ((color.ProductColorID === '' || color.ProductColorID === undefined || color.ProductColorID === null) && (moq.value === '' || moq.value === null)) {
            this.toast.error("Please fill the Fields ")
          }
          else if (color.ProductColorID === '' || color.ProductColorID === undefined || color.ProductColorID === null) {
            this.toast.error("Please select color")
          } else if (moq.value === '' || moq.value === null) {
            this.toast.error("Please Enter MOQ")
          } else if (moq.value < 0) {
            this.toast.error("MOQ should not be negative")
          } else if (moq.value > 9999999) {
            this.toast.error("MOQ should not be greater than 9999999")
          }
        }
      }
      else {
        this.toast.error("Can't Add duplicate Colors")
        this.selectedData = {};
        this.dropdownData = {};
        this.productColorForm.reset();
        this.getValue();
      }
    }
    // this.hideSearch();
    this.loadPagination()
  }

  getSelectedColor(value) {
    this.selectedData = {
      Action: "",
      MOQ: "",
      ProductColorID: value.data.ProductColorID,
      Name: value.data.Name,
      ColorCode: value.data.ColorCode,
      ColorCodeArr: [value.data.ColorCode],
    };
  }


  resetDropDown() {
    this.getProductColors();
  }

  deleteConfirmation(productColor, index) {
    this.deleteSet = true
    this.toBeDeleted = { productColor, index }
  }

  deleteColor() {
    const { productColor, index } = this.toBeDeleted
    const getDelete = this.selfColors.findIndex(data => data.color === productColor.value.color);
    if (this.selfColors[getDelete].status == 'saved' || productColor.value.status == 'saved') {
      // productColor.items.controls[index].controls.status.value = 'deleted';
      this.selfColors[getDelete].status = 'deleted';
      const deleteData = {
        ProductID: this.productDetails.ProductID,
        ProductColorID: productColor.value.data.ProductColorID,
        ColorCode: productColor.value.data.ColorCode,
        MOQ: productColor.value.data.MOQ,
        Name: productColor.value.data.Name,
        SupplierID: this.productDetails.SupplierID,
        SupplierName: this.productDetails.SupplierName,
        ColorCodes: [productColor.value.data.ColorCode],
        ColorCodeArr: [productColor.value.data.ColorCode],
        Action: "Delete"
      }
      // productColor.items.value[index].data = deleteData;
      this.selfColors[getDelete].data = deleteData;
      this.selfColors.forEach(element => {
        if (element.color === productColor.value.data.ProductColorID) {
          element.Action = 'Delete';
        }
      });
    } else if (productColor.items.value.status == 'added') {
      productColor.items.removeAt(index);
    }
    // this.hideSearch();
    this.toast.success("Color Deleted Successfully")
    this.getValue();

    this.getProductColors();
    let currentpage;
    if (this.selectedpage === this.pageList.length) {
      currentpage = this.pageList.length;
    }
    else {
      currentpage = this.selectedpage
    }
    this.loadPagination();
    if (this.pageList[currentpage] === undefined) {
      this.selectedpage = this.pageList.length;
      this.loadPagination();
    }
  }



  showManageColorFun(val) {
    this.showManageColor = val;
  }

  // hideSearch(){
  //   const getResult = this.items.value.filter(data=>data.Action !== 'Delete' || data.search !== 'hide');
  //   this.showSearch = getResult.length > 0 ? true : false;
  // }

  pageChanged(page) {
    this.selectedpage = page;
    this.loadPagination();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.loadPagination();
  }

  loadPagination() {
    this.clearFormItems()
    // this.items.push(this.createItem([]));
    if (this.selfColors !== undefined) {
      const selfColors = this.selfColors.filter(res => res.Action !== 'Delete');
      if (selfColors.length <= 10) {
        this.selectedpage = 1;
      }
      let getPageList = selfColors.length / this.selectedperpage;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = []
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;

      const getArray = [];
      const startArray = this.selectedpage * this.selectedperpage - this.selectedperpage;
      const endArray = this.selectedpage * this.selectedperpage;
      selfColors.slice([startArray], [endArray]).map((item, i) => {
        const colorVal = {
          color: item.color,
          moq: item.moq,
          data: item.data,
          status: 'saved'
        };
        this.items.push(this.createItem(colorVal));
        // getArray.push(item)
      });
      this.colorlength = selfColors.length;
      // this.productColorForm.get('items').value = getArray;

    }
  }

  dropdownPop() {
    this.showPopUp = true;
  }

  getSelectedDropdown(dropdown) {
    this.selectedData = {
      Action: "",
      MOQ: "",
      ProductColorID: dropdown.ProductColorID,
      Name: dropdown.Name,
      ColorCode: dropdown.ColorCode,
      ColorCodeArr: dropdown.ColorCodes,
    };
    this.dropdownData = this.selectedData;
  }

  resetPopupDropdown() {
    this.selectedData = {};
    this.dropdownData = {};
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  changeData(data, i) {
    if (this.selfColors.length > 0) {
      this.selfColors[i]['moq'] = Number(data.target.value);
      this.selfColors[i]['data']['MOQ'] = Number(data.target.value);
    }
    this.getValue();
  }

}


