import { Component, OnInit } from '@angular/core';
import { OrderHttpService } from '../../state/services/order.http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalService, ToastService } from 'ng-uikit-pro-standard';

@Component({
    selector: 'rewardkart-admin-order-process-deleted-orders',
    templateUrl: './order-process-deleted-orders.component.html',
    styleUrls: ['./order-process-deleted-orders.component.scss']
})
export class OrderProcessDeletedOrdersComponent implements OnInit {
    orderId = '';
    customerID = '';
    orderData = null;
    loading: boolean = false;
    returnPage: any;
    webshopDetail: any;
    itemToExpand = {};
    subOrderID: any;
    constructor(public modalService: MDBModalService,
        private orderHttpService: OrderHttpService,
        private route: ActivatedRoute,
        private toasterServcie: ToastService,
        private router: Router) {
        this.orderId = this.getUrlParam('orderId');
        this.returnPage = 'deletedOrders';
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
    }

    ngOnInit() {
        debugger
        this.loading = true;
        this.orderHttpService.getDeletedOrders(this.orderId, this.webshopDetail.CustomerID).subscribe(res => {
            this.orderData = res;
            this.loading = false;
        });
    }

    getUrlParam(paramValue): string {
        return this.route.snapshot.paramMap.get(paramValue);
    }

    toggleBody(id, expand) {
        this.itemToExpand[id] = expand;
    }

    redirectProductPage(productId) {
        const webshop = sessionStorage.getItem('webshopDetail'); 0
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
        const orderDetailData = {
            orderID: this.orderId,
            subOrderID: '',
            customerID: this.webshopDetail.CustomerID,
            returnPage: 'deletedOrders'
        }
        const productData = {
            ProductID: productId,
            dashboardRedirectStatus: false
        };
        this.router.navigate(['/admin-product-edit'], { state: { data: productData, orderDetailData }, replaceUrl: false });
    }

    goBack() {
        this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
    }
}