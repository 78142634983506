import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ColumnSetting } from '../../shared/link-table-border/models/link-table-model';
import { ReportsHttpService } from '../services/reports-http.service';
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
    selector: 'rewardkart-report-order-fulfillment',
    templateUrl: './report-order-fulfillment.component.html',
    styleUrls: ['./report-order-fulfillment.component.scss']
})

export class ReportOrderFulfillmentComponent implements OnInit {
    fromDate: any;
    toDate: any;
    date = new Date();
    datePickerOptions: IMyOptions = {};
    enddatePickerOptions: IMyOptions = {};
    statusList: any = [
        { label: 'Pending', value: 'pending' }, { label: 'InTransit', value: 'InTransit' }, { label: 'Return', value: 'Return' }, { label: 'Delivered', value: 'delivered' }, { label: 'Cancel', value: 'Cancel' }
    ]
    status: any = 'pending';
    orderReport: any;
    showLoader: boolean = false;
    showTable: boolean = false;
    noData: boolean = false;
    public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public fileExtension = '.xlsx';
    public exportData: any = [];
    columns: ColumnSetting[] = [
        {
            primaryKey: 'OrderID',
            header: 'Order ID'
        },
        {
            primaryKey: 'UserName',
            header: 'User Name'
        },
        {
            primaryKey: 'ProductName',
            header: 'Product Name'
        },
        {
            primaryKey: 'Quantity',
            header: 'Quantity'
        },
        {
            primaryKey: 'OrderAmount',
            header: 'Order Amount',
            format: 'number'
        },
        {
            primaryKey: 'OrderCreationDate',
            header: 'Order Date',
            format: 'date'
        },
        {
            primaryKey: 'OrderPendingDays',
            header: 'Pending Days'
        },
        {
            primaryKey: 'Delivery_Date',
            header: 'Delivery Date',
            format: 'date'
        },
        {
            primaryKey: 'AirWayBillNo',
            header: 'AirWay Bill No'
        },
        {
            primaryKey: 'Courier',
            header: 'Courier'
        },
        {
            primaryKey: 'Status',
            header: 'Status'
        }
    ];
    customerid: any;
    constructor(private reportHttpService: ReportsHttpService, private datePipe: DatePipe, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
			let user = JSON.parse(sessionStorage.getItem('userdetails'));
            this.customerid = user.CustomerID;
    }

    ngOnInit() {
        let dt = new Date();
        const disableDate = new Date(dt.setDate(dt.getDate() + 1));
        const startDate = new Date(dt.setDate(dt.getDate() - 90));
        this.fromDate = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
        this.toDate = this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear();
        this.datePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
        };
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableSince: {
                year: disableDate.getFullYear(),
                month: disableDate.getMonth() + 1,
                day: disableDate.getDate()
            },
            disableUntil: {
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                day: startDate.getDate()
            },
        };
        this.getReport();
    }

    getReport() {
        let a = this.fromDate.split('/');
        let b = this.toDate.split('/');
        let fromDate = a[2] + '-' + a[1] + '-' + a[0];
        let toDate = b[2] + '-' + b[1] + '-' + b[0];
        let params = {
            'fromDate': fromDate,
            'toDate': toDate,
            'deliveryStatus': this.status,
			'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getOrderFulfilmentReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.orderReport = '';
            this.showTable = false;
            if (data && data.IsSuccess) {
                this.orderReport = data.UserReportDetails;
                this.showTable = true;
                this.noData = false;
            } else {
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })
    }

    dateChange(event) {
        this.enddatePickerOptions = {
            closeAfterSelect: true,
            dateFormat: 'dd/mm/yyyy',
            disableUntil: {
                year: event.date.year,
                month: event.date.month,
                day: event.date.day,
            },
        };
    }

    download() {
        this.exportData = [];
        this.orderReport.forEach(e => {
            this.exportData.push({
                'Order ID': e.OrderID,
                'User Name': e.UserName,
                'Product Name': e.ProductName,
                'Quantity': e.Quantity,
                'Order Amount': e.OrderAmount,
                'Order Date': this.datePipe.transform(e.OrderCreationDate, 'dd/MM/yyyy'),
                'Pending Days': e.OrderPendingDays,
                'Delivery Date': this.datePipe.transform(e.Delivery_Date, 'dd/MM/yyyy'),
                'AirWay Bill No': e.AirWayBillNo,
                'Courier': e.Courier,
                'Status': e.Status
            })
        })
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, 'order-fulfillment-report');
    }

    saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }

}