import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { CategoryActions } from '../actions/index';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import { State } from '../reducers/index';
import { CategoryHttpService } from '../services/category.http.service';

@Injectable()
export class CategoryEffects {

    @Effect()
    getCategoryList$: Observable<Action> = this.actions$.pipe(
        ofType(CategoryActions.LOAD_CATEGORIES),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            const customerID = payload.payload;
            return this.categoryHttpService.getCategoryList(customerID).map((data) => {
                return new CategoryActions.LoadCategorySuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new CategoryActions.LoadCategoryFail(error));
        })
    );

    @Effect()
    saveCategory$: Observable<Action> = this.actions$.pipe(
        ofType(CategoryActions.SAVE_CATEGORY),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.categoryHttpService.saveCategory(payload.payload).map((data) => {
                return new CategoryActions.SaveCategorySuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new CategoryActions.SaveCategoryFail(error));
        })
    );

    constructor(private actions$: Actions, private store$: Store<State>, private categoryHttpService: CategoryHttpService) { }
}
