import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-order-logos',
  templateUrl: './order-logos.component.html',
  styleUrls: ['./order-logos.component.scss']
})
export class OrderLogosComponent implements OnInit {
  @Input() logos = [];
  @Input() currency = '';
  visible = true;
  constructor() { }

  ngOnInit() {
    if (this.logos.length === 1) {
      const { FileURL, LogoPositionComment, LogoMaxAreaDescription, PrintPriceDescription, OrderLogoColors } = this.logos[0];
      if (!FileURL && !LogoPositionComment && !LogoMaxAreaDescription && !PrintPriceDescription && OrderLogoColors.length == 0) {
        this.visible = false;
      }
    }
  }
  checkFileExtNew(fileName) {
    return (fileName && fileName.substring(fileName.length - 3)) || '';
  }
}
