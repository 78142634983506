import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';

interface ModalActionType {
  data: any;
  action: string;
  type: string;
}

@Component({
  selector: 'rewardkart-admin-credit-note-modal',
  templateUrl: './credit-note-modal.component.html',
  styleUrls: ['./credit-note-modal.component.scss']
})
export class CreditNoteModalComponent implements OnInit {

  header: any;
  type = '';
  newCreditNoteItems: any[] = [];
  newCreditNoteOrderItemIds = [];
  newCreditNoteOrderItemQuantities = [];
  newSelectedCreditNoteOrderItems = [];
  newCreditNoteOrderItemTransportation = [];
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  creditNotesList: any;
  invoiceRecipientEmail: any;
  sendMail: Boolean = true;
  constructor(public modalRef: MDBModalRef, public toastService: ToastService) { }

  ngOnInit() {
  }

  modalConfirmed(data) {
    switch (this.type) {
      case 'newCreditNote':
        let numOfOIonShInv = 0;
        let numOfOIonInv = 0;
        if (this.newSelectedCreditNoteOrderItems.length === 0) {
          this.toastService.error('Please select atleast one order');
          return;
        } else {
          this.newSelectedCreditNoteOrderItems.forEach(item => {
            if (this.newCreditNoteOrderItemQuantities[item.OrderItemID] === undefined) {
              this.toastService.error('You entered invalid quantity. Please correct quantities.');
              return;
            } else if (this.newCreditNoteOrderItemQuantities[item.OrderItemID] < 1) {
              this.toastService.error('You entered 0 for quantity. Minimal quantity is 1. Please correct quantities.');
              return;
            }
            this.newCreditNoteItems.forEach(orderItem => {
              if (orderItem.OrderItemID === item.OrderItemID) {
                if (this.newCreditNoteOrderItemQuantities[item.OrderItemID] > (orderItem.DispatchedQuantity - orderItem.CreditNoteQuantity)) {
                  this.toastService.error('You entered invalid quantity (' +
                    this.newCreditNoteOrderItemQuantities[item.OrderItemID] + '). Maximal quantity is ' +
                    (orderItem.DispatchedQuantity - orderItem.CreditNoteQuantity) + '. Please correct quantities.');
                  if (orderItem.isOnShipmentInvoice) numOfOIonShInv++;
                  else numOfOIonInv++;
                  return;
                }
              }
            });
            if (numOfOIonShInv > 0 && numOfOIonInv > 0) {
              this.toastService.error('You cannot put on same credit note items from invoice and shipment invoice. Please create credit notes separately.');
              return;
            }
          });
        }
        this.modalAction.next({
          data: {
            payload: this.newSelectedCreditNoteOrderItems,
            sendEmail: this.sendMail
          }, action: 'update', type: this.type
        });
        break;
    }
  }

  orderItemQuantityChange(orderItemID) {
    this.newSelectedCreditNoteOrderItems.map(item => {
      if (item.OrderItemID == orderItemID) {
        item.Quantity = this.newCreditNoteOrderItemQuantities[orderItemID];
      }
    });
  }

  orderItemTransportationChangeCN(orderItemID) {
    this.newSelectedCreditNoteOrderItems.map(item => {
      if (item.OrderItemID == orderItemID) {
        item.ReturnTransportation = !this.newCreditNoteOrderItemTransportation[orderItemID];
      }
    });
  }

  onCheckboxClick(eventHandler, orderItemID) {
    if (!eventHandler) {
      this.newSelectedCreditNoteOrderItems.push({
        "OrderItemID": orderItemID,
        "Quantity": this.newCreditNoteOrderItemQuantities[orderItemID],
        "ReturnTransportation": this.newCreditNoteOrderItemTransportation[orderItemID]
      });
    } else {
      this.newSelectedCreditNoteOrderItems.map((item, index) => {
        if (item.OrderItemID === orderItemID) {
          this.newSelectedCreditNoteOrderItems.splice(index, 1);
        }
      });
    }
  }

  sendCreditNoteMail(invoiceID, orderID) {
    this.modalAction.next({ data: { invoiceID: invoiceID, orderID: orderID }, action: 'sendCreditNoteMail', type: this.type });
  }

  downloadCreditNote(invoiceID, orderID) {
    this.modalAction.next({ data: { invoiceID: invoiceID, orderID: orderID }, action: 'download', type: this.type });
  }

  deleteCreditNote(invoiceID, orderID) {
    if (confirm('Are you sure you want to delete this credit note?'))
      this.modalAction.next({ data: { invoiceID: invoiceID, orderID: orderID }, action: 'delete', type: this.type });
  }

  modalCanceled() {
    this.modalRef.hide();
  }

  ngOnDestroy() {
    this.modalAction.unsubscribe();
  }

}
