import { Component, OnInit, OnChanges, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { UserService } from 'src/app/user/state/services/user.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { WebService } from '../../../restful-service/web.service';
import { debug } from 'util';
import { ProductService } from '../../state/services/product.service';


@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('deleteModalProfile', { static: true }) deleteModalProfile: ModalDirective;
  @Input() productDetails: any;
  @Input() loadApi: any;
  @Output() sendProductImages = new EventEmitter<any>();
  productImagesForm: FormGroup
  selectedImg: any;
  currentImg: any;
  productImages: any = []
  imageTextTranslations: any = [];
  optionsSelectLanguage: any = [
    { value: 1, label: 'ENGLISH', name: 'english' },
    { value: 2, label: 'DANISH', name: 'danish' },
    { value: 3, label: 'ROMANIAN', name: 'romanian' }
  ]
  activeLanguage: any = 'english';
  public imagePath;
  imgURL: any;
  public message: string;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English';
  selectedProdLang: any = 'English';
  items: FormArray;
  deleteData: any;
  subscribeMethod: boolean = false;
  productImgMessage: Boolean = false;
  optionsColorsName: any[];
  optionsPosition: any[];
  productImageSend: any;
  showLoader: boolean = false;
  lang: any = 1;
  defaultprolang: any = 1;
  myFiles: any = [];
  webshopDetail: any;

  @ViewChild('file', { static: true }) file;
  @ViewChild('prductDetailFile', { static: true }) prductDetailFile;
  showInnerLoader: boolean = false;
  constructor(private userService: UserService,
    public apiService: ApiService,
    public formBuilder: FormBuilder, public webService: WebService,
    public productService: ProductService
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

  }


  ngOnChanges() {
    // if (this.loadApi === true) {
    //   this.getProductColors();
    //   this.getLogoPosition();
    //    this.translateForm = []
    //    this.loadForm();
    //   this.getValue();
    // }

    this.translateForm = []
    this.getProductColors();
    this.getLogoPosition();
    this.loadForm();
    this.getValue();
    this.productImagesForm.valueChanges.subscribe(() => {
      this.getValue();
    });

    this.getValue();
    if (this.productDetails) {
      this.imgURL = this.productDetails.ImageURL;
    }
  }



  ngOnInit() {
    this.showInnerLoader = false;
    // this.getProductColors();
    // this.getLogoPosition();
    // this.loadForm();
    // this.getValue();
    // this.productImagesForm.valueChanges.subscribe(() => {
    //   this.getValue();
    // })
    // this.selectedProdLang.value = 1;

  }

  clearFormItems() {
    const control = <FormArray>this.productImagesForm.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  getCheckbox(i) {
    this.productImages[i]['ShowInProductDetails'] = this.productImagesForm.value.items[i]['ShowInProductDetails'];
    this.getValue();
  }

  getValue() {
    let productImage = {
      ImageName: this.productImagesForm.value.fileName1,
      ImageURL: this.productImagesForm.value.filePath,
      Translations: [],
      ProductImages: []
    };
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let translations = [];
    getlang.forEach(data => {
      const translationData = {
        TranslationID: this.productImagesForm.get('TranslationID' + data.Code) !== null ? this.productImagesForm.get('TranslationID' + data.Code).value : '',
        LanguageID: data.LanguageID,
        LanguageCode: data.Code,
        ObjectID: (this.productImagesForm.get('ObjectID' + data.Code) && this.productImagesForm.get('ObjectID' + data.Code).value) || "",
        ObjectType: "Products",
        PropertyName: "ImageAlternativeText",
        PropertyValue: this.productImagesForm.get('ImageAlternativeText' + data.Code) && this.productImagesForm.get('ImageAlternativeText' + data.Code).value || "",
      }
      translations.push(translationData);
    });
    productImage.Translations = translations;

    this.productImagesForm.value.items.forEach(data => {
      const imagesData = {
        ImageName: data.ImageName,
        ImageURL: data.ImageURL,
        Position: data.Position,
        ProductImageID: data.ProductImageID,
        ProductID: data.ProductID,
        isCustomerImage: data.isCustomerImage,
        ProductColorID: data.ProductColorID === '--Not Set--' ? '' : data.ProductColorID,
        LogoPositionID: data.LogoPositionID === '--Not Set--' ? '' : data.LogoPositionID,
        ShowInProductDetails: data.ShowInProductDetails,
        Action: data.Action === 'Saved' ? 'Update' : data.Action,
        Translations: []
      }
      getlang.forEach(lang => {
        const translations = {
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          ObjectType: "ProductImages",
          PropertyName: "ImageAlternativeText",
          ObjectID: data['ObjectID' + lang.Code],
          TranslationID: data['TranslationID' + lang.Code],
          PropertyValue: data['ImageAlternativeText' + lang.Code],
        }
        if (data['Action' + lang.Code] != "") {
          translations['Action'] = data['Action' + lang.Code];
        }

        if (data['TranslationID' + lang.Code] === undefined || data['TranslationID' + lang.Code] === '') {
          translations['Action'] = 'Insert';
        }
        else {
          translations['Action'] = 'Update';
        }

        // if (translations['Action'] = "") {
        //    = 'Insert';
        // }

        if (translations['Action'] === undefined) {
          translations['Action'] === 'Update'
        }

        imagesData.Translations.push(translations);
      });
      productImage.ProductImages.push(imagesData);
    });


    this.productImageSend = productImage;
    this.sendProductImages.emit(this.productImageSend);
  }

  loadForm() {
    this.imageTextTranslations = [];
    if (this.productDetails.Translations !== undefined) {
      if (this.productDetails.Translations.length > 0) {
        this.productDetails.Translations.forEach(element => {
          if (element.PropertyName == "ImageAlternativeText") {
            this.imageTextTranslations.push(element);
          }
        });
        this.productImages = this.productDetails.ProductImages;

        this.buildForm();
        this.createItem();
      }
    }
  }
  deleteImg() {
    this.showLoader = true;
    this.file.nativeElement.value = '';
    this.deleteModalProfile.show();
    this.showLoader = false;
  }
  clearImg() {
    this.imgURL = '';
    this.translateForm.forEach(element => {
      this.productImagesForm.get(element.formcontrolname).patchValue('');
    });
    this.productImageSend.ImageURL = '';
    this.productImageSend.ImageName = 'No_Image_Available.png';
    this.sendProductImages.emit(this.productImageSend);
    this.deleteModalProfile.hide();
  }
  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let translationFieldJson = [
      {
        formcontrolname: 'ImageAlternativeText',
        label: 'Name',
        PropertyName: "ImageAlternativeText",
      }
    ];

    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          translationIDname: 'TranslationID' + lang.Code,
          objectIDame: 'ObjectID' + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })

    let group: any = {
      language: new FormControl(this.lang),
      defaultlanguage: new FormControl(this.defaultprolang),
      fileName: new FormControl(''),
      fileName1: new FormControl(this.productDetails.ImageName),
      filePath: new FormControl(this.productDetails.ImageURL),
      items: this.formBuilder.array([])
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })

    this.translateForm.forEach(formData => {
      this.imageTextTranslations.forEach(item => {

        if (formData.LanguageID === item.LanguageID) {
          group[formData.formcontrolname] = new FormControl(item.PropertyValue);
          group[formData.translationIDname] = new FormControl(item.TranslationID);
          group[formData.objectIDame] = new FormControl(item.ObjectID);
        }
      })
    })

    this.productImagesForm = new FormGroup(group);
  }

  createItem() {
    this.items = this.productImagesForm.get('items') as FormArray;
    let group: any;
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.productImages.forEach(list => {
      group = {
        ProductImageID: list.ProductImageID,
        ProductID: list.ProductID,
        ImageName: list.ImageName,
        Position: list.Position,
        ImageURL: list.ImageURL,
        isCustomerImage: list.isCustomerImage,
        ShowInProductDetails: list.ShowInProductDetails,
        ProductColorID: list.ProductColorID ? list.ProductColorID : '--Not Set--',
        LogoPositionID: list.LogoPositionID ? list.LogoPositionID : '--Not Set--',
        Action: 'Saved'
      }
      this.translateFormItems = [];
      getlang.forEach(lang => {
        const isData = list.Translations.filter(data => data.LanguageCode == lang.Code);
        if (isData.length > 0) {
          let formJson = {
            formcontrolname: 'ImageAlternativeText' + lang.Code,
            translationID: 'TranslationID' + lang.Code,
            objectID: 'ObjectID' + lang.Code,
            Action: 'Action' + lang.Code,
            class: lang.Name,
            name: isData[0].PropertyName ? isData[0].PropertyName : '',
            LanguageID: lang.LanguageID,
            LanguageCode: lang.Code,
            PropertyName: isData[0].PropertyName ? isData[0].PropertyName : '',
            PropertyValue: isData[0].PropertyValue ? isData[0].PropertyValue : '',
            TranslationID: isData[0].TranslationID ? isData[0].TranslationID : '',
            ObjectID: isData[0].ObjectID ? isData[0].ObjectID : ''
          }
          this.translateFormItems.push(formJson);
          group[formJson.formcontrolname] = formJson.PropertyValue;
          group[formJson.translationID] = formJson.TranslationID;
          group[formJson.objectID] = formJson.ObjectID;
          group[formJson.Action] = '';
        } else {
          let formJson = {
            formcontrolname: 'ImageAlternativeText' + lang.Code,
            translationID: 'TranslationID' + lang.Code,
            objectID: 'ObjectID' + lang.Code,
            Action: 'Action' + lang.Code,
            class: lang.Name,
            name: '',
            LanguageID: lang.LanguageID,
            LanguageCode: lang.Code,
            PropertyName: '',
            PropertyValue: '',
            TranslationID: '',
            ObjectID: ''
          }
          this.translateFormItems.push(formJson);
          group[formJson.formcontrolname] = formJson.PropertyValue;
          group[formJson.translationID] = formJson.TranslationID;
          group[formJson.objectID] = formJson.ObjectID;
          group[formJson.Action] = '';
        }
      });

      this.items.push(this.formBuilder.group({ ...group }));
    });
  }

  scrollToBottom() {
    let scrollingElement = (document.scrollingElement || document.body)
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }


  preview(files) {
    debugger
    this.showLoader = true;
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      this.showLoader = false;
      return;
    }
    this.imagePath = files;
    this.onSelectFile(files, 'isDefaultImage')
    this.message = '';
    this.showLoader = false;
  }

  urls = [];
  onSelectFile(event, imageType, files?) {
    debugger
    this.showLoader = true;
    let file;
    const formData = new FormData();
    if (imageType == 'isDefaultImage') {
      this.showInnerLoader = true;
      file = event[0];
      formData.append('file', file);
    } else if (imageType == 'isProductImage') {
      if (event.target.files[0].type.match(/image\/*/) == null) {
        this.productImgMessage = true;
        this.showLoader = false;
        return;
      }
      // file = event.target.files[0];
      // formData.append('filePath', 'ProductImages');
      // formData.append('supplierID', this.productDetails.SupplierID);
      // formData.append('productIdManual', this.productDetails.ProductIdManual);
      // this.showLoader = true;
      this.showInnerLoader = true;
      for (var i = 0; i < event.target.files.length; i++) {
        this.myFiles.push(event.target.files[i]);
        // formData.append('file', event.target.files[i]);
      }
    }
    for (let i = 0; i < this.myFiles.length; i++) {
      // this.myFiles.push(event.target.files[i]);
      formData.append('file', this.myFiles[i]);
    }
    // formData.append('file', this.myFiles[1]);
    formData.append('filePath', 'ProductImages');
    formData.append('supplierID', this.productDetails.SupplierID);
    formData.append('productIdManual', this.productDetails.ProductIdManual);
    // this.showLoader = true;

    const productImg = event.srcElement !== undefined ? event.srcElement.value.substring(event.srcElement.value.indexOf('.')) : '';
    // if ((imageType == 'isProductImage') && (productImg !== '.jfif' && productImg !== '.jpeg' && productImg !== '.jpg' && productImg !== '.png' && productImg !== '.gif') && ((productImg !== '.JFIF' && productImg !== '.JPG' && productImg !== '.JPEG' && productImg !== '.PNG' && productImg !== '.GIF'))
    //   && (productImg !== '.jfif.jfif' && productImg !== '.jpeg.jpeg' && productImg !== '.jpg.jpg' && productImg !== '.png.png' && productImg !== '.gif.gif') && (productImg !== '.JFIF.JFIF' && productImg !== '.JPEG.JPEG' && productImg !== '.JPG.JPG' && productImg !== '.PNG.PNG' && productImg !== '.GIF.GIF')) {
    if ((imageType == 'isProductImage') && (!productImg.indexOf('.jfif') && !productImg.indexOf('.jpeg') && !productImg.indexOf('.jpg') && !productImg.indexOf('.PNG') && !productImg.indexOf('.GIF')) && (!productImg.indexOf('.JFIF') && !productImg.indexOf('.JPEG') && !productImg.indexOf('.JPG') && !productImg.indexOf('.PNG') && !productImg.indexOf('.GIF'))) {
      this.productImgMessage = true;
      this.showInnerLoader = true;
      this.showLoader = false;
      if (productImg === "") {
        this.productImgMessage = false;
      }
      // if(imageType == 'isProductImage'){
      //   this.productImgMessage = false;[
      //   debugger]
      // }

      return;

    }
    this.productImgMessage = false;
    this.webService.uploadImg(formData).subscribe((data: any) => {
      if (data) {
        this.subscribeMethod = true;
        this.showLoader = false;
        this.myFiles = [];
        if (imageType == 'isProductImage') {
          this.showLoader = false;
          const imgLen = this.items.length;
          let index = 1;
          for (let i = 0; i < imgLen; i++) {
            if (this.productImagesForm.controls.items.value[i]['Action'] !== "Delete") {
              index += 1;
            }
          }
          for (let i = 1; i < data.length; i += 3) {

            let newData = {
              ImageName: data[i],
              ImageURL: data[i + 1],
              Position: index,
              ProductImageID: 0,
              Action: "Insert",
              ProductID: '',
              isCustomerImage: false,
              ShowInProductDetails: true,
              ProductColorID: '--Not Set--',
              LogoPositionID: '--Not Set--'
            }
            let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

            getlang.forEach(item => {
              newData['ImageAlternativeText' + item.Code] = '';
              newData['TranslationID' + item.Code] = '';
              newData['ObjectID' + item.Code] = '';
              newData['Action' + item.Code] = 'Insert';
            })
            this.items.push(this.formBuilder.group({ ...newData }));
            index += 1;
          }
          // setTimeout(() => {
          //   this.scrollToBottom();
          // }, 100)
        }

        else if (imageType == 'isDefaultImage') {
          this.showLoader = false;
          this.imgURL = data[2];
          this.productImagesForm.controls.fileName.setValue(data[0]);
          this.productImagesForm.controls.fileName1.setValue(data[1]);
          this.productImagesForm.controls.filePath.setValue(data[2]);

        }

      }
      else {
        this.showLoader = false;
        this.showInnerLoader = false;
      }
      this.showInnerLoader = false;
    })
    this.showLoader = false;
  }

  setAction(productCategory) {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    const selectedLang = getlang.find(data => data.Name == this.selectedLang);
    if (productCategory['TranslationID' + selectedLang.Code] != "") {
      productCategory.controls['Action' + selectedLang.Code].setValue('Update');
    } else {
      productCategory.controls['Action' + selectedLang.Code].setValue('Insert');
    }
    this.getValue();
  }


  deleteImage(productCategory, index) {
    debugger
    this.deleteData = {
      productCategory: productCategory,
      index: index
    }
    this.prductDetailFile.nativeElement.value = '';
    this.deleteModal.show();
  }

  getDeleteStatus(status) {
    if (status) {
      if (this.productImagesForm.controls.items.value[this.deleteData.index].ProductImageID == 0) {
        this.items.removeAt(this.deleteData.index);

      } else {
        this.productImagesForm.controls.items.value[this.deleteData.index]['Action'] = "Delete";
      }
      this.getValue();
      const imgLen = this.items.length;
      let index = 0;
      // let position = Number(JSON.stringify(this.productImagesForm.controls.items.value[this.deleteData.index].Position));
      for (let i = 0; i < imgLen; i++) {
        if (this.productImagesForm.controls.items.value[i]['Action'] !== 'Delete') {
          index = index + 1;
          if (i >= this.deleteData.index) {
            this.productImagesForm.controls.items['controls'][i].controls['Position'].setValue(index);
            if (this.productImagesForm.controls.items.value[i]['ProductImageID'] == 0) {
              this.productImagesForm.controls.items['controls'][i].controls['Position'].setValue(index);
            }
          }
          else {

          }
        }

      }
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }

  getCurrentLang(lang) {
    this.selectedLang = lang.label;
    this.defaultprolang = lang.value;
  }


  getCurrentProductLang(lang) {
    this.selectedProdLang = lang.label;
    this.lang = lang.value;
  }
  getProductColors() {
    const optionsColorsName = [{ label: "--Not Set--", value: "--Not Set--" }];
    if (this.productDetails.ProductColors !== undefined) {
      if (this.productDetails.ProductColors.length > 0) {
        this.productDetails.ProductColors.forEach(element => {
          const value = {
            value: element.ProductColorID,
            label: element.Name,
          };
          optionsColorsName.push(value);
        });
        this.optionsColorsName = optionsColorsName;
      }
    }
  }

  getLogoPosition() {
    const logoPosition: any = [{ label: "--Not Set--", value: "--Not Set--" }];
    if (this.productDetails.ProductLogoPositions !== undefined) {
      if (this.productDetails.ProductLogoPositions.length > 0) {
        this.productDetails.ProductLogoPositions.forEach(element => {
          const value = {
            value: element.LogoPositionID,
            label: element.Description,
          };
          logoPosition.push(value);
        })
        this.optionsPosition = logoPosition;
        //  this.productImagesForm.patchValue({
        //   LogoPositionID: ''
        //  })
      }
    }
  }

  getImageText(data, index) {

  }


}
