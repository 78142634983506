import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, HostListener, Directive, Injectable, HostBinding } from '@angular/core';
import { ColumnSetting } from './models/link-table-model';
import { ColumnMap } from './models/link-table-model';
import { LinkTableService } from './state/services/link-table.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ProductService } from '../../product/state/services/product.service';
import { MessageboxHttpService } from '../../message-box/state/services/messagebox.http.service';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
const FileSaver = require('file-saver');

interface emitItem {
  data: any;
  action: string;
}

@Component({
  selector: 'rewardkart-admin-link-table-border',
  templateUrl: './link-table-border.component.html',
  styleUrls: ['./link-table-border.component.scss']
})

export class LinkTableBorderComponent implements OnInit, OnChanges {

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input() rows: any[];
  @Input() columns: ColumnSetting[];
  @Input() header: any;
  @Input() actions: any;
  @Input() titles: any;
  @Input() datacount: any;
  @Input() className: boolean;
  @Input() displaypagination: boolean;
  @Input() activepage: number;
  @Input() tablename: any;
  @Output() messageRowEvent = new EventEmitter<boolean>();
  @Input() perpagerow: number;
  @Input() refreshdata: boolean;
  @Input() webshopSupplierID: any;
  @Input() tableType = '';
  @Input() draggable: boolean = false;
  @Input() dragFrom: any;
  @Input() typeOfList: any;
  @Input() IsCustomerSelected: any;
  @Output() createNewRowEvent = new EventEmitter<boolean>();
  @Output() viewSelectedRowEvent = new EventEmitter<boolean>();
  @Output() editSelectedRowEvent = new EventEmitter<boolean>();
  @Output() summarySelectedRowEvent = new EventEmitter<boolean>();
  @Output() ratingRowEvent = new EventEmitter<boolean>();
  @Output() editDatasSelectedRow = new EventEmitter<any>();
  @Output() deleteSelectedRowEvent = new EventEmitter<boolean>();
  @Output() refreshTableEvent = new EventEmitter<boolean>();
  @Output() rowClickEvent = new EventEmitter<any>();
  @Output() editSelectedBudgetRowEvent = new EventEmitter<boolean>();
  @Output() approveUser = new EventEmitter<boolean>();
  @Output() emitItemEvent = new EventEmitter<any>();
  @Output() typeOfPublish = new EventEmitter<any>();
  @Output() paginationdata = new EventEmitter<any>();
  @Output() rowsperpageData = new EventEmitter<any>();
  @Output() perPageData = new EventEmitter<any>();
  @Output() bulkActionData = new EventEmitter<any>();
  @Output() messageread = new EventEmitter<any>();
  @Output() copyProductDetail = new EventEmitter<any>();
  @Output() siblingProduct = new EventEmitter<any>();
  @Input() noRecordFoundText: any;
  @Output() mappedData = new EventEmitter<any>();
  @Input() deleteMessage: any;
  @Input() deleteCPMessage: any;
  @Input() bothProductDeleteMessage: any;
  keys: string[];
  columnMaps: ColumnMap[];
  isDesc = false;
  column = 'Name';
  loginData: any = [];
  config: any;
  configpaginate: any;
  perPage: any = [];
  Roles = [];
  selectedData: any = {};
  collection: any;
  optionsSelect: Array<any>;
  pageSize = 0;
  pageSizeOptions: number[] = [10, 30, 60, 90];
  selectedperpage: any = 10;
  getTableName = '';
  deleteData: any;
  bulkaction: any = {};
  bulkactionselect: any = [];
  actiontype: any = '';
  pageList: any = [];
  roleName: any;
  selectedpage: number = 1;
  isParentCustomer: boolean = true;
  inputName: string;
  itemId: number;
  rating: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();
  public ratingRange = [1, 2, 3, 4, 5];
  webshopDetail: any;
  deleteWarning: any = 'Are you sure you want to delete?';
  showTwoBtn: Boolean = false;
  originalPro: Boolean = true;
  customerPro: Boolean = false;
  showLoader: Boolean = false;
  constructor(private linkTableService: LinkTableService, private productService: ProductService, private messageboxhttpservice: MessageboxHttpService, private productHttpService: ProductHttpService, public apiService: ApiService,) {
    this.collection = { count: 0, data: [], currentPage: this.activepage };
  }
  ngOnChanges() {

    /** Check Webshop is Parent/child => To display Webshop column name in table list */
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.webshopDetail = webshopDetail;
      if (webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    }

    if (this.noRecordFoundText === undefined) {
      this.noRecordFoundText = 'No Records Found';
    }
    this.getTableName = this.tablename !== undefined ? this.tablename : '';
    this.pageSize = this.perpagerow;
    this.selectedperpage = this.pageSize
    this.selectedpage = this.activepage;
    this.bulkactionselect = [];
    if (this.columns) {
      this.columnMaps = this.columns.map(col => new ColumnMap(col));
    } else {
      this.columnMaps = Object.keys(this.rows[0])
        .map(key => {
          return new ColumnMap({ primaryKey: key });
        });
    }
    /** Display Webshop Name Column Based on Parent/Child Shop */
    const findWebshopIndex = this.columnMaps.findIndex(data => data['columnFormat'] === 'webshopname');
    if (findWebshopIndex >= 0 && !this.isParentCustomer) {
      this.columnMaps.splice(findWebshopIndex, 1);
    }

    this.loadPaginate();

    if (this.refreshdata) {
      this.resetPagination();
    }
  }

  perPageCount() {

  }

  selectRow(rowdata, type, event) {
    const sendType = {
      originalPro: this.originalPro,
      customerPro: this.customerPro
    };
    this.typeOfPublish.emit(sendType)
    let checkboxAction = {};
    checkboxAction = this.bulkaction;
    if (event.checked) {
      this.bulkactionselect.push(rowdata)
    }
    else {
      let getIndex = this.bulkactionselect.indexOf(rowdata)
      this.bulkactionselect.splice(getIndex, 1)
    }
    checkboxAction[type] = this.bulkactionselect;
    this.bulkActionData.emit({ bulkaction: checkboxAction })
  }

  setPageSizeOptions(setPageSizeOptionsInput) {
    this.selectedpage = 1;
    this.pageSize = setPageSizeOptionsInput.value;
    this.configpaginate.itemsPerPage = setPageSizeOptionsInput.value;
    this.configpaginate.currentPage = 1;
    this.perPageData.emit(setPageSizeOptionsInput.value);
    this.paginationdata.emit(this.selectedpage);
    this.loadPaginate();
  }

  loadPaginate() {
    //Create dummy data
    if (this.getTableName !== 'orderList') {
      this.pageSize = this.pageSize ? this.pageSize : 10;
      for (var i = 0; i < this.datacount; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            value: "items number " + (i + 1)
          }
        );
      }
      this.configpaginate = {
        itemsPerPage: this.pageSize,
        currentPage: this.activepage,
      };

      if (this.datacount) {
        this.configpaginate.totalItems = this.datacount;
      }

      let getPageList = this.datacount / this.pageSize;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = [];
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;
      this.selectedpage = this.selectedpage ? this.selectedpage : 1;
      this.selectedperpage = this.selectedperpage ? this.selectedperpage : this.pageSize;
    }
  }

  pageChanged(event) {
    this.selectedpage = event;
    this.configpaginate.currentPage = event;
    this.paginationdata.emit(event);
  }

  ngOnInit() {
    this.loginData = this.apiService.getLoginData();
    if (this.loginData !== undefined && this.loginData !== null && this.loginData !== '') {
      // this.loginUserId = this.loginData.UserID;
      this.Roles = this.loginData.Roles;
      this.roleName = this.Roles[0].RoleName


    }

    this.getTableName = this.tablename !== undefined ? this.tablename : '';
    if (this.column) {
      this.sort(this.column);
    }
    this.loadPerPage();
    this.resetPagination();
    sessionStorage.removeItem('dragData');
    this.inputName = this.itemId + '_rating';
  }
  onClick(row: any, rating: number): void {
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
    row.QualityLevel = rating;
    this.ratingRowEvent.emit(row);
  }
  resetRating(row) {
    this.rating = 0;
    row.QualityLevel = 0;
    this.ratingRowEvent.emit(row);
  }
  goToPendingUserEdit(data) {
  }

  loadPerPage() {
    this.optionsSelect = [
      { value: '10', label: '10', selected: true },
      { value: '30', label: '30' },
      { value: '60', label: '60' },
      { value: '90', label: '90' },
    ];
  }

  resetPagination() {
    if (this.refreshdata) {
      this.selectedperpage = 10;
    }
  }

  sort(property) {
    this.isDesc = !this.isDesc;
    this.column = property;
    const direction = this.isDesc ? 1 : -1;
    if (this.rows) {
      // tslint:disable-next-line:only-arrow-functions
      this.rows.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        } else if (a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  createNewRow() {
    this.createNewRowEvent.emit(true);
  }

  viewSelectedRow(row: any, type?) {
    this.actiontype = 'viewwebshop';
    this.viewSelectedRowEvent.emit(row);
  }

  approveSelectedRow(row: any, type) {
    this.approveUser.emit(row);
  }

  editBudgetList(row: any, type?) {
    this.actiontype = 'viewbudget';
    this.editSelectedBudgetRowEvent.emit(row);
  }


  viewSummary(row: any) {
    this.linkTableService.setSelectedRow(row);
    this.editSelectedRowEvent.emit(true);
  }

  editDataSelectedRow(row: any, type?) {
    if (type === 'delete' || type === 'approve') {
      return false;
    }
    if (type === 'viewwebshop') {
      setTimeout(() => {
        this.actiontype = '';
      }, 200);
      return false;
    }
    if ((type !== 'delete' && type !== 'viewwebshop')) {
      // this.linkTableService.setSelectedRow(row);
      this.editDatasSelectedRow.emit(row);
    }
  }

  messageSelectedRow(row: any) {
    this.linkTableService.setSelectedRow(row);
    this.messageRowEvent.emit(row);
  }

  deleteSelectedRow(row: any, type?) {
    this.deleteData = row;
    this.actiontype = 'delete';
    this.showTwoBtn = false;

    if (this.tablename == 'productlist') {
      if (row.IsMultipleCustomer && row.EditableType === 'OP') {
        this.deleteWarning = this.deleteMessage
      }
      else if (row.IsMultipleCustomer && row.EditableType === 'CP') {
        this.deleteWarning = this.deleteCPMessage;
      }
      if (!row.IsMultipleCustomer && row.isImported) {
        this.deleteWarning = this.deleteCPMessage;
        this.deleteModal.show();
      }
      if (!row.IsMultipleCustomer && !row.isImported) {
        const getFilterData = JSON.parse(sessionStorage.getItem('presearch'));
        let getFilterCustID;
        if (getFilterData !== null && getFilterData !== undefined) {
          getFilterCustID = getFilterData.CustomerID ? getFilterData.CustomerID : '';
        }
        const request = {
          productID: row.ProductID,
          customerID: this.webshopDetail.CustomerID,
          productCustID: row.CustomerID ? row.CustomerID : getFilterCustID,
          isCustomerSelected: this.IsCustomerSelected
        }
        this.showLoader = true;
        this.productHttpService.checkProductType(request).subscribe((checkPro: any) => {
          this.showLoader = false;
          if (checkPro) {
            if (checkPro.EditableType == 'BOTH') {
              this.showTwoBtn = true;
              this.deleteWarning = this.bothProductDeleteMessage;
            }
            else if (checkPro.EditableType == 'OP') {
              this.deleteWarning = 'Are you sure you want to delete?';
            }
            else if (checkPro.EditableType == 'CP') {
              this.deleteWarning = this.deleteCPMessage;
            }
          }
          this.deleteModal.show();
        },
          (error) => {
            this.showLoader = false;
          });
      }
      else {
        this.deleteModal.show();
      }
    }
    else {
      this.deleteModal.show();
    }
  }

  getDeleteStatus(deleteData, action, type?) {
    if (type == 'OP') {
      deleteData.proType = 'OP';
      this.deleteSelectedRowEvent.emit(deleteData);
    }
    else if (type == 'CP') {
      deleteData.proType = 'CP';
      this.deleteSelectedRowEvent.emit(deleteData);
    }
    if (action) {
      this.deleteSelectedRowEvent.emit(deleteData);
      this.deleteModal.hide();
      this.actiontype = '';
    }
    else {
      this.deleteModal.hide();
      this.actiontype = '';
    }
  }

  refreshTable() {
    //    this.refreshTableEvent.emit(true);
    if (this.rows.length > 0) {
    }
  }

  // siblingProducts(data) { 

  // }

  copyProduct(data, type) {
    this.linkTableService.setSelectedRow(data);
    if (type === 'copy') {
      this.copyProductDetail.next(data);
    }
    else {
      this.siblingProduct.emit(data)
    }
  }

  editProduct(data) { }
  /** Redirect to Frontend Webshop based on Product URL */
  redirecttoFrontendWebshop(rowData) {
    window.open(rowData.ProductUrl, "_blank");
  }

  emitItemToClient(data, type) {
    const linkActionEvent = { data: data, type: type };
    this.emitItemEvent.emit(linkActionEvent);
  }

  downloadPDF(image, subordernum) {
    FileSaver.saveAs(image, subordernum);
  }

  mappedCustomer(data) {
    this.actiontype = 'mapped';
    this.mappedData.emit(data)
  }

  changeType(event, type) {
    if (type === 'op') {
      this.originalPro = event.checked;
      this.customerPro = !event.checked;
    }
    else if (type === 'cp') {
      this.customerPro = event.checked;
      this.originalPro = !event.checked;
    }

    const sendType = {
      originalPro: this.originalPro,
      customerPro: this.customerPro
    };
    this.typeOfPublish.emit(sendType)
  }

}




