import {
  MDBSpinningPreloader,
  WavesModule,
  ButtonsModule,
  AccordionModule,
  IconsModule
} from 'ng-uikit-pro-standard';
import {
  MDBBootstrapModulePro,
  MDBBootstrapModule
} from 'ng-uikit-pro-standard';
import { ToastModule } from 'ng-uikit-pro-standard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { AdminRouterModule } from './router/router.module';
import {
  HttpClientModule,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { NgxPrintModule } from 'ngx-print';
import { EffectsModule } from '@ngrx/effects';
import { ApiService } from './shared/common-services/api.service';
import { LoginModule } from './login/login.module';
import { SignUpModule } from './signup/signup.module';
import { WebshopModule } from './webshop/webshop.module';
import { CategoryModule } from './category/category.module';
import { BlockModule } from './block/block.module';
import { OrderModule } from './order/order.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { BlockEditModule } from './block/block-edit/block-edit.module';
import { HeaderModule } from './header/header.module';
import { CategoryEditModule } from './category/category-edit/category-edit.module';
import { ProductModule } from './product/product.module';
import { BrandModule } from './brand/brand.module';
import { CampaignModule } from './campaign/campaign.module';
import { HeaderContentModule } from './shared/header-content/header-content.module';
import { CategoryCopyModule } from './category/category-copy/category-copy.module';
import { CategoryDetailsModule } from './category/category-details/category-details.module';
import { MainModule } from './main/main.module';
import { SupplierModule } from './supplier/supplier.module';
import { BillboardModule } from './billboard/billboard.module';
import { BannerEnquiryModule } from './bannerenquiry/bannerenquiry.module';
import { UserModule } from './user/user.module';
import { UserPointsModule } from './user-points/user.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeftSidbarComponent } from './left-sidbar/left-sidbar.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionListComponent } from './subscription/subscription-list/subscription-list.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpModule } from '@angular/http';
import { NgxEditorModule } from 'ngx-editor';
import { FilterSearchComponent } from './message-box/filter-search/filter-search.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PageLoaderModule } from './common/pageloader/pageloader.module';
import { ControlValidationModule } from './shared/control-validation/control-validation.module';
import { MessageboxModule } from './message-box/messagebox.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TabScrollModule } from 'ngx-tab-scroll';
import { ProductAddModule } from './product/product-add/product-add.module';
import { MomentModule } from 'ngx-moment';
import { ReportsModule } from './reports/reports.module';
import { ColorssettingComponent } from './colorssetting/colorssetting.component';
import { ColorsSettingModule } from './colorssetting/colorsetting.module';
import { SettingsModule } from './settings/settings.module';
import { DragdropModule } from './category/directives/dragdrop.module';
import { WebshopService } from './restful-service/webshop-services/webshop.service';
import { CookieService } from 'ngx-cookie-service';
import { UnderSupplierModule } from './undersupplier/undersupplier.module';
import { WarehouseManagementComponent } from './warehouse-management/warehouse-management.component';
import { WarehouseManagementModule } from './warehouse-management/warehouse-management.module';
import { ChildshopModule } from './childshop/childshop.module';
import { ParentshopModule } from './parentshop/parentshop.module';
import { CompanyModule } from './company-order/company.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LeftSidbarComponent,
    SubscriptionComponent,
    SubscriptionListComponent,
    UnderConstructionComponent,
    WarehouseManagementComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    BlockModule,
    HttpClientModule,
    HttpModule,
    NgxPrintModule,
    ReactiveFormsModule,
    OrderModule,
    BlockEditModule,
    ColorPickerModule,
    TooltipModule,
    CompanyModule,
    ToastModule.forRoot({
      opacity: 1
    }),
    MDBBootstrapModule.forRoot(),
    MDBBootstrapModulePro.forRoot(),
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'Your_api_key'
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}),
    WavesModule,
    ButtonsModule,
    BrandModule,
    CampaignModule,
    ChildshopModule,
    ParentshopModule,
    AccordionModule,
    IconsModule,
    AdminRouterModule,
    LoginModule,
    SignUpModule,
    CategoryModule,
    UserModule,
    UserPointsModule,
    WebshopModule,
    SharedModule,
    MainModule,
    HeaderModule,
    HeaderContentModule,
    CategoryModule,
    CategoryEditModule,
    CategoryCopyModule,
    CategoryDetailsModule,
    ProductModule,
    SupplierModule,
    BillboardModule,
    BannerEnquiryModule,
    DashboardModule,
    ReportsModule,
    WarehouseManagementModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument(),
    NgxEditorModule,
    PerfectScrollbarModule,
    PageLoaderModule,
    ControlValidationModule,
    MessageboxModule,
    TabScrollModule.forRoot({
      autoRecalculate: true,
      showDropDown: true,
      showTooltips: false,
      tooltipLeftPlacement: 'top',
      tooltipRightPlacement: 'top',
      scrollBy: 50,
      leftScrollAddition: 0
    }),
    ProductAddModule,
    MomentModule,
    ColorsSettingModule,
    SettingsModule,
    DragdropModule,
    UnderSupplierModule
  ],
  providers: [
    MDBSpinningPreloader,
    WebshopService,
    CookieService,
    ApiService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
