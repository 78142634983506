import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { UndersupplierListComponent } from './undersupplier-list.component';
import { UnderSupplierHttpService } from '../state/services/undersupplier.http.service';
import { FilterTagModule } from 'src/app/common/common-filter/filter-tags/filter-tags.module';



@NgModule({

  declarations: [UndersupplierListComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ControlValidationModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    FilterTagModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [UnderSupplierHttpService],
})
export class UnderSupplierListModule { }
