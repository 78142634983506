import { Directive, HostBinding, HostListener, Output, EventEmitter, ElementRef, Input, Component, ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'drag-item-image',
  template: `
  <div id="drag-image" class="col mt-4 drag-image p-2">
    <div class="CDC-list-image-container mb-2 position-relative bg-white">
      <img src="{{ imgSrc }}" onerror="this.src='../../../assets/images/No_Image_Available.png';"  style="margin:auto;object-fit:contain;">
    </div>
  <div class="CDC-list-item-name text-center item-cnt-text bg-white mx-auto" placement="top">{{name}} </div>
</div>
          `,
  styles: [
    `
       .drag-image{
         background-color: #fff;
         padding: 10px 0px;
         /*border:2px solid #333333;*/
         border-radius:4px; 
         box-shadow: 0px 3px 6px #00000029;
       }  
       .CDC-list-item-name {
         width:70px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #333 !important;
        text-transform: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .drag-image .CDC-list-image-container img{
        width:100px;
        height:100px;
      }
      .listviewdrag .drag-image .CDC-list-image-container {
          width: 30px;
          display: flex;
          height: 30px;
          margin-bottom: 0px !important;
          align-items: center;
      }
      .listviewdrag .drag-image .CDC-list-image-container img{
        width: 25px !important;
        height: 25px !important;
      }
      .listviewdrag .drag-image {
          box-shadow: 0px 3px 6px #00000029;
          border: none;
          display: flex;
          align-items: center;
          background-color: red !important;
      }
      `
  ]
})

export class DragItemImage {

  name: string = ' Move 1 item';
  imgurl: any = '';
  @Input() imgSrc: string = '';
  constructor(private el: ElementRef, private ref: ChangeDetectorRef) { }

  getEl() {
    this.ref.detectChanges();
    return $(this.el.nativeElement).find('.drag-image')[0];
  }
}

@Directive({
  selector: '[appDragdrop]'
})
export class DragdropDirective {

  // constructor() { }
  @HostBinding('class.draggable') draggable = false;
  @Output() dragStart = new EventEmitter<PointerEvent>();
  @Output() dragMove = new EventEmitter<PointerEvent>();
  @Output() dragEnd = new EventEmitter<PointerEvent>();
  private dragging = false;
  private dragObj: any;
  private typeData: any;
  private getType: any;
  private productIdArray: any;
  dragImageEl: any;
  _dragImage: DragItemImage;
  listStatus: Boolean;
  selectedProducts: any;
  totalCount: Number = 0;
  constructor(private viewContainerRef: ViewContainerRef, private cfr: ComponentFactoryResolver) { }
  @Input()
  set dragData(data: any) {
    if (data) {
      this.dragObj = data;
    }
  }

  @Input()
  set orderCount(data: any) {
    if (data) {
      this.totalCount = data;
    }
  }

  @Input()
  set typeList(data: any) {
    if (data) {
      this.typeData = data;
    }
  }

  @Input()
  set getlistStatus(data: any) {
    if (data) {
      this.listStatus = data;
    }
  }

  @Input()
  set type(data: any) {
    if (data) {
      this.getType = data;
    }
  }

  @Input()
  set productIds(data: any) {
    if (data) {
      this.productIdArray = data;
    }
  }

  @Input()
  set checkedProducts(data: any) {
    if (data) {
      this.selectedProducts = data;
      // if (this.selectedProducts.length > 0) {
      //   const componentFactory = this.cfr.resolveComponentFactory(DragItemImage);
      //   const componentRef = this.viewContainerRef.createComponent(componentFactory);
      //   componentRef.instance.imgSrc = this.getType === 'category' ? this.dragObj.IconURL : this.dragObj.ImageURL;
      // }
    }
  }

  //  @Input() dragImage: DragItemImage;
  @Input()
  set dragImage(dragimg) {
    const componentFactory = this.cfr.resolveComponentFactory(DragItemImage);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.imgSrc = this.getType === 'category' ? '../../../assets/images/defaultcategory.svg' : this.dragObj.ImageURL;
    componentRef.instance.name = this.dragObj.Name;
    this.dragImageEl = componentRef.instance.getEl();
    this.viewContainerRef.detach();
    $("#drag-item-image").detach();
    this._dragImage = dragimg;
  }

  get dragImage() {
    return this._dragImage;
  }

  @HostListener('dragstart', ['$event']) onDragStart(event): void {
    if (this.getType === 'product') {
      $("#drag-item-image #drag-image").addClass("d-none");
    }
    else {
      $("#drag-item-image #drag-image").removeClass("d-none");
    }

    if (this.selectedProducts !== undefined) {
      if (this.selectedProducts.length === 0) {
        $("#drag-item-image").detach();
      }
    }
    sessionStorage.setItem('dragtype', this.getType);
    this.dragging = true;
    let dataTransfer: DataTransfer = event.dataTransfer;
    let productID = [];
    if (this.productIdArray !== undefined) {
      productID = this.productIdArray.length > 0 ? this.productIdArray : [this.dragObj.ProductID];
    }
    this.dragObj.productIds = productID;
    delete this.dragObj["parentCategory"];
    dataTransfer.setData('dragData', JSON.stringify(this.dragObj));
    let getTypeData: any = this.typeData;
    getTypeData.type = this.getType;
    dataTransfer.setData('typeData', JSON.stringify(getTypeData));
    dataTransfer.effectAllowed = "move";
    let elem = document.createElement("div");
    if (this.listStatus) {
      elem.className = 'listviewdrag'
    }
    elem.id = "drag-item-image";
    elem.style.position = "absolute";
    elem.style.top = "-100000px";
    let dragImageEl = this.dragImageEl;

    let newEle;
    if (this.selectedProducts.length >= 1) {
      newEle = `<div id="popupDrag" class="position-relative"><span class="dragvaluecount">` + (this.totalCount != 0 ? this.totalCount : this.selectedProducts.length) + `</span><div class="d-flex align-items-center">`;
      for (var data of this.selectedProducts.slice(0, 1)) {

        newEle += `<div class="w-100"><span class="layertwo"></span><span class="layerone"></span><div class="p-2 dataimagedrop"><img src="` + data.ImageURL + `" width="70" height="70" /></div><p class="mb-0 droptext text-center">` + data.Name + `</p></div>`;
      }
      newEle += `</div></div></div>`;
    }
    if (this.getType === 'product') {
      if (this.selectedProducts !== undefined) {
        dragImageEl = this.selectedProducts.length >= 1 ? newEle : this.dragImageEl;
      }
      else {
        dragImageEl = this.dragImageEl;
      }
    }
    else {
      dragImageEl = this.dragImageEl;
    }

    if (this.listStatus) {
      if (this.selectedProducts !== undefined) {
        dragImageEl = this.selectedProducts.length > 0 ? $("#listpopupDrag").get(0) : this.dragImageEl;
      }
    }
    if (this.selectedProducts.length >= 1) {
      elem.innerHTML = dragImageEl;
    } else {
      elem.appendChild(dragImageEl);
    }
    document.body.appendChild(elem);
    dataTransfer.setDragImage(elem, 0, 0);
    this.dragStart.emit();
  }

  // @HostListener('document:pointermove', ['$event']) onPointerMove(event): void {
  //   if(!this.dragging){
  //     return;
  //   }
  //   this.dragMove.emit();
  // }

  @HostListener('drop', ['$event']) onDrop(event): void {
    if (!this.dragging) {
      return;
    }
    this.dragging = false;
    this.dragEnd.emit();
  }


}


@Directive({
  selector: '[conDropPoint]'
})
export class ConDropPointDirective {
  private dropPoint: any;
  constructor(private el: ElementRef, public renderer: Renderer2) { }

  @Input()
  set conDropPoint(dropPoint: any) {
    if (dropPoint) {
      this.dropPoint = dropPoint;
      return;
    }
  }

  @Output('droppedData') drop = new EventEmitter();
  // onDrop(event: DragEvent) {
  //     console.log(event);
  //     this.drop.next(event);
  // }
  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    let idx = event.dataTransfer.types.indexOf('dragData');
    this.renderer.addClass(this.el.nativeElement, 'activeDropDiv');
  }
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    this.renderer.removeClass(this.el.nativeElement, 'activeDropDiv');
  }
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    this.renderer.addClass(this.el.nativeElement, 'activeDropDiv');
  }
  @HostListener('drop', ['$event']) onDrop(event): void {
    const data = {
      dragData: JSON.parse(event.dataTransfer.getData('dragData')),
      dropData: this.dropPoint,
      typeData: JSON.parse(event.dataTransfer.getData('typeData'))
    }
    this.drop.next(data);
    this.renderer.removeClass(this.el.nativeElement, 'activeDropDiv');
  }
}