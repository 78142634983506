import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild, HostListener } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { } from 'events';
import { ProductService } from '../../state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { IMyOptions, MDBDatePickerComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import * as moment from 'moment';
import { modalConfigDefaults } from 'ng-uikit-pro-standard/lib/free/modals/modal.options';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-stock',
  templateUrl: './product-stock.component.html',
  styleUrls: ['./product-stock.component.scss']
})
export class ProductStockComponent implements OnInit, OnChanges {
  @Input() isSibling: any;
  @ViewChild('datePicker', { static: false }) datePicker: MDBDatePickerComponent;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  additionalcosts: any;
  setDateValue: any;
  date = new Date();
  inherit: boolean;
  inheritStock: any;
  webshopDetail: any;
  @HostListener('keydown.esc')
  handleInput() {
    if (this.datePicker.isOpen) {
      this.datePicker.closeBtnClicked();
    }
  }
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
    inputAutoFill: false,
    markCurrentDay: true,
    editableDateField: false,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  }
  items: FormArray
  productStock: FormGroup;
  optionsUnderSupplier: any;
  model: any;
  showAdditionalCostModal: any;
  @Input() productDetails: any;
  @Input() loadApi: any;
  @Output() sendProductStock = new EventEmitter<any>();
  setBorder: boolean;
  //formArray  
  selectedLang: any = 'en'
  languageOption: any = [];
  translateForm: any = [];
  StockForms: FormGroup;
  translateFormItems: any = [];
  requestData: any = []
  newArray: any = [];
  request: any = {}
  deleteIndex: any;
  group: any;
  showError: boolean = false;
  hideLabel: boolean;
  translateFormItemsDefault: any[];
  constructor(public formBuilder: FormBuilder,
    public formBuilders: FormBuilder,
    public apiService: ApiService,
    public productService: ProductService,
    public translate: TranslateService,
    public toast: ToastService) {
    translate.setDefaultLang('en'); const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

  }

  ngOnChanges() {
    if (this.loadApi) {
      // if(this.productDetails.DisablePrices || this.productDetails.DisableStockData){
      //   this.StockForms.value.items = []
      //   const arr = <FormArray>this.StockForms.controls.items;
      //   arr.controls = [];
      // }
      this.request.futurestock = this.productDetails.ProductFutureStock;
      this.inheritStock = this.productDetails;
      // if (this.inherit) {
      this.inheritStock.ProductFutureStock.forEach(res => {
        if (res && !res.Translations) {
          this.inherit = true;
          this.productStock = this.formBuilder.group({
            stockBalance: [this.productDetails.StockBalance],
            stockBalanceAlert: this.productStock.value.stockBalanceAlert
          })
        }
      })
      // }

    }


    // this.clearValue();
    // this.setFormControlName()
    // this.loadForm();
    // this.request = {
    //   stockBalance: this.productStock.value.stockBalance,
    //   futurestock: this.productDetails.ProductFutureStock,
    //   stockBalanceAlert: this.productStock.value.stockBalanceAlert
    // }
    // this.StockForms.valueChanges.subscribe(() => {
    //   this.sendProductStock.emit(this.request);
    // });
    // this.productStock.valueChanges.subscribe(() => {
    //   this.sendProductStock.emit(this.request);
    // })
    // this.sendProductStock.emit(this.request);
    // this.getAllProductAdditionalCosts()
    // this.loadFormData();
    // this.StockForms.patchValue({
    //   language: 'en'
    // })
  }

  ngOnInit() {
    this.setFormControlName()
    this.buildForm();
    this.loadForm();

    this.request = {
      stockBalance: this.productStock.value.stockBalance,
      futurestock: this.productDetails.ProductFutureStock,
      stockBalanceAlert: this.productStock.value.stockBalanceAlert
    }
    this.StockForms.valueChanges.subscribe(() => {
      this.sendProductStock.emit(this.request);
    });
    this.productStock.valueChanges.subscribe(() => {
      this.sendProductStock.emit(this.request);
    })
    this.sendProductStock.emit(this.request);
    this.getAllProductAdditionalCosts()
    this.loadFormData();
    // this.StockForms.patchValue({
    //   language: 'en'
    // })
    // this.getCurrentLang({label:'English', value: 'en'});

    // if(this.isSibling !== undefined && this.isSibling){
    //   this.clearFormItems();     
    // }
  }

  clearFormItems() {
    const control = <FormArray>this.StockForms.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }


  sendRequest() {
    this.request = {
      stockBalance: this.productStock.value.stockBalance,
      futurestock: this.productDetails.ProductFutureStock,
      stockBalanceAlert: this.productStock.value.stockBalanceAlert
    }
    this.sendProductStock.emit(this.request);
  }

  getCurrentLang(lang) {
    this.selectedLang = lang.value;
    // this.productStock.patchValue({
    //   language: this.selectedLang
    // })
  }

  dateFormatchange(getDate) {
    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    return myDate;
  }

  changeField(i, value, name?, lang?) {
    this.productDetails.ProductFutureStock.forEach((element, index) => {
      element.Date = this.dateFormatchange(new Date(element.Date));
      if (index === i) {
        if (lang !== undefined) {
          element.Translations.forEach(ele => {
            if (ele.LanguageCode === lang && ele.PropertyName === name) {
              ele.PropertyValue = value;
              ele.Action = 'Update';
            }
          });
        }

        if (name === 'value') {
          element.Value = value;
        }

        if (name === 'date') {
          element.Date = this.dateFormatchange(value.actualDateFormatted);
        }
        element.Action = 'Update';
      }
    });
    this.sendProductStock.emit(this.request);
  }

  deleteUrl(i) {
    this.deleteIndex = i;
    this.deleteModal.show();
  }

  getDeleteStatus(status) {
    if (status) {
      this.productDetails.ProductFutureStock[this.deleteIndex].Action = 'Delete';
      this.items.value[this.deleteIndex].Action = 'Delete';
      this.request.futurestock = this.productDetails.ProductFutureStock;
      this.sendProductStock.emit(this.request);
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }

  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Negative values are not accepted here')
      return false
    }
    else {
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;
      return true
    }
  }


  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }
  onInputFocusBlur(event) {
  }
  loadForm() {
    this.productStock = this.formBuilder.group({
      stockBalance: [this.productDetails !== undefined ? this.productDetails.StockBalance : '', Validators.required],
      stockBalanceAlert: [this.productDetails !== undefined ? this.productDetails.StockBalanceAlert : '', Validators.required],
      onStockFromDate: [this.productDetails.OnStockFromDate !== undefined ? this.getDateFormat(this.productDetails.OnStockFromDate) : '', Validators.required],
      nextShipmentDate: [this.productDetails.NextShipmentDate !== undefined ? this.getDateFormat(this.productDetails.NextShipmentDate) : '', Validators.required],
      language: [this.selectedLang]
    })
  }

  getAllProductAdditionalCosts() {
    this.items = this.StockForms.get('items') as FormArray;
  }
  loadFormData() {
    this.items = this.StockForms.get('items') as FormArray;
    this.productDetails.ProductFutureStock.forEach(data => {
      this.items.push(this.createItem(data));
    })
  }
  buildForm() {

    // let getlang = this.apiService.getLanguages();
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    let translationFieldJson = [
      {
        formcontrolname: 'futureStockName',
        label: 'Name',
        PropertyName: "futureStockName",
      }
    ];

    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.Code };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: 'futureStockName' + lang.Code,
          label: jsonField.label,
          class: lang.Code,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: 'futureStock'
        }
        this.translateForm.push(formJson)
      });
    })

    this.group = {
      ProductAdditionalCostID: new FormControl(),
      SupplierID: new FormControl(),
      Value: new FormControl(),
      nextShipmentDates: new FormControl(''),
      Name: new FormControl(),
      language: new FormControl(1),
      items: this.formBuilders.array([]),
      FutureStockID: new FormControl(),
      ProductID: new FormControl(),
    }
    this.translateForm.forEach(element => {
      if (element.LanguageCode === 'en') {
        this.group[element.formcontrolname] = new FormControl('Incoming Stock');
      }
      else if (element.LanguageCode === 'dk') {
        this.group[element.formcontrolname] = new FormControl('NÃ¦ste forsendelse');
      }
      else {
        this.group[element.formcontrolname] = new FormControl('');
      }
    })
    this.StockForms = new FormGroup(this.group);
    this.StockForms.patchValue({
      futureStockNameen: 'Incoming Stock',
      futureStockNamedk: 'NÃ¦ste forsendelse'
    });
    // this.items = this.StockForms.get('items') as FormArray;
  }
  clearValue() {
    this.translateFormItems.forEach(data => {
      if (data.LanguageCode !== 'en') {
        this.StockForms.get(data.formcontrolname).patchValue('');
      }
    });
    this.StockForms.patchValue({
      Value: '',
      nextShipmentDates: '',
    })
    // this.StockForms.setValue({
    //   nextShipmentDates: ''
    // })
  }
  setFormControlName() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.translateFormItems = [];
    getlang.forEach((lang: any) => {
      // let isFound = stockVal.Translations.find(data => data.LanguageCode == lang.Code);
      let formJson;
      formJson = {
        formcontrolname: 'futureStockName' + lang.Code,
        translationID: 'TranslationID' + lang.Code,
        objectID: 'ObjectID' + lang.Code,
        class: lang.Code,
        name: '',
        LanguageID: lang.LanguageID,
        LanguageCode: lang.Code,
        PropertyName: 'futureStock',
        PropertyValue: lang.LanguageID === 'en' ? 'Incoming Stock' : '',
        TranslationID: '',
        ObjectID: ''
      }
      this.translateFormItems.push(formJson);
    });
  }
  setFormControlNameDefault() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.translateFormItemsDefault = [];
    getlang.forEach(lang => {
      // let isFound = stockVal.Translations.find(data => data.LanguageCode == lang.Code);
      let formJson;
      formJson = {
        formcontrolname: 'futureStockName' + lang.Code,
        translationID: 'TranslationID' + lang.Code,
        objectID: 'ObjectID' + lang.Code,
        class: lang.Code,
        name: '',
        LanguageID: lang.LanguageID,
        LanguageCode: lang.Code,
        PropertyName: 'futureStock',
        PropertyValue: 'Incoming Stock',
        TranslationID: '',
        ObjectID: ''
      }
      this.translateFormItemsDefault.push(formJson);
    });
  }
  createItem(stockVal?): FormGroup {
    // debugger
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    this.translateFormItems = [];
    getlang.forEach(lang => {
      let formJson;
      if (stockVal.Translations !== undefined) {
        let isFound = stockVal.Translations.find(data => data.LanguageCode == lang.Code);
        if (isFound) {
          this.inherit = false;
          formJson = {
            formcontrolname: 'futureStockName' + lang.Code,
            translationID: 'TranslationID' + lang.Code,
            objectID: 'ObjectID' + lang.Code,
            class: lang.Code,
            name: isFound.PropertyName,
            LanguageID: lang.LanguageID,
            LanguageCode: lang.Code,
            PropertyName: 'futureStock',
            PropertyValue: isFound.PropertyValue,
            TranslationID: isFound.TranslationID,
            ObjectID: isFound.ObjectID
          }
        } else {
          formJson = {
            formcontrolname: 'futureStockName' + lang.Code,
            translationID: 'TranslationID' + lang.Code,
            objectID: 'ObjectID' + lang.Code,
            class: lang.Code,
            name: '',
            LanguageID: lang.LanguageID,
            LanguageCode: lang.Code,
            PropertyName: 'futureStock',
            PropertyValue: '',
            TranslationID: '',
            ObjectID: ''
          }
        }
      }
      else {
        formJson = {
          formcontrolname: 'futureStockName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Code,
          name: 'FutureStockName',
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: 'futureStock',
          PropertyValue: 'Incoming Stock',
          TranslationID: '',
          ObjectID: ''
        }
        this.inherit = true;
      }

      this.translateFormItems.push(formJson);
    });
    let group: any = {
      ProductAdditionalCostID: stockVal.ProductAdditionalCostID,
      SupplierID: stockVal.SupplierID,
      Value: stockVal.Value,
      nextShipmentDates: this.getDateFormat(stockVal.Date),
      FutureStockID: 22,
      ProductID: 89
    }
    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })

    return this.formBuilders.group({ ...group });
  }




  // this.createItem.push(stock));

  addStock() {
    if (this.StockForms.value.Value && this.StockForms.value.nextShipmentDates &&
      (this.StockForms.value.futureStockNameen)) {
      if (this.StockForms.value.futureStockNameen) {
        if (this.StockForms.value.futureStockNameen !== '' && this.StockForms.value.futureStockNameen !== undefined) {
          this.showError = false;
          let translations = [];
          this.translateFormItems.forEach(item => {
            const data = {
              LanguageID: item.LanguageID,
              LanguageCode: item.LanguageCode,
              ObjectType: "ProductFutureStocks",
              PropertyName: 'FutureStockName',
              PropertyValue: this.StockForms.value[item.formcontrolname],
              Action: "Insert"
            }
            translations.push(data);
          });

          this.requestData = {
            Action: "Insert",
            Value: this.StockForms.value.Value,
            Date: this.dateFormatchange(this.StockForms.value.nextShipmentDates),
            Translations: translations,
            FutureStockID: 0,
            ProductID: 0,

          }
          this.productDetails.ProductFutureStock.push(this.requestData)
          // this.request.futurestock.push(this.requestData)
          // this.newArray.push(this.requestData)
          // console.log('Array list', this.newArray)

          this.toast.success('Added Successfully')
          this.datePicker.clearDate()
          this.setFormControlNameDefault()
          this.items.push(this.createItem(this.requestData));
          this.translateFormItems = this.translateFormItemsDefault
          this.StockForms.patchValue({
            futureStockNameen: 'Incoming Stock',
            futureStockNamedk: 'NÃ¦ste forsendelse'
          });
          this.sendProductStock.emit(this.request);
          this.clearValue();
          this.StockForms.value.nextShipmentDates = '';
          this.hideLabel = false
          // this.datePicker.label

        }
        else {
          this.showError = true;

        }
      }
      else {
        this.toast.error('Name field is required for english language')
      }
    }
    else {
      this.toast.error('Please fill all the required fields')
    }
    // this.ngOnInit()
  }

  checklabel() {
    this.hideLabel = true
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  elements: any = [
    { id: 1, first: 'Mark', last: 'Otto', handle: '@mdo' },
    { id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat' },
    { id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter' },
    { id: 1, first: 'Mark', last: 'Otto', handle: '@mdo' },
    { id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat' },
    { id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter' },
  ];

  headElements = ['Sub-order number', 'Quantity', 'Username', 'Company Name'];
}


