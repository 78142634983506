import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductTabComponent } from './product-tab.component';
import { TabScrollModule } from 'ngx-tab-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,NgbModule,
    CommonFilterModule,
    TabScrollModule.forRoot({
      autoRecalculate: false,
      showDropDown: true,
      showTooltips: false,
      tooltipLeftPlacement: 'top',
      tooltipRightPlacement: 'top',
      scrollBy: 50,
      leftScrollAddition: 0
    }),
    NgbDropdownModule,NgbTooltipModule
  ],
  declarations: [ProductTabComponent],
  exports: [ProductTabComponent]
})

export class ProducttabModule { }
