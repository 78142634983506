import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';

@Injectable()
export class UserListHttpService {
  webshopDetail: any;
  constructor(
    private apiService: ApiService,
    private loginService: LoginService
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (
      webshop !== null &&
      webshop !== undefined &&
      webshop !== '' &&
      webshop !== 'undefined'
    ) {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      //const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  getPointsSummary(e) {
    const path =
      'en/customer/RewardUsed/' +
      e.userid +
      '?pageSize=' +
      e.pageSize +
      '&pageIndex=' +
      e.pageIndex;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getUsersList(searchCriteria: any) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (
      webshop !== null &&
      webshop !== undefined &&
      webshop !== '' &&
      webshop !== 'undefined'
    ) {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      //const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    let includChildShopUsers = '';
    if (searchCriteria.customerId === '') {
      includChildShopUsers = '&includChildShopUsers=true';
    }
    let request = {
      activePage:
        searchCriteria.activePage !== undefined
          ? searchCriteria.activePage
          : '',
      companyName:
        searchCriteria.companyName !== undefined
          ? searchCriteria.companyName
          : '',
      country:
        searchCriteria.country !== undefined ? searchCriteria.country : '',
      // tslint:disable-next-line:max-line-length
      customerId:
        searchCriteria.customerId !== undefined &&
          searchCriteria.customerId !== ''
          ? searchCriteria.customerId
          : this.webshopDetail.CustomerID,
      email: searchCriteria.email !== undefined ? searchCriteria.email : '',
      name: searchCriteria.name !== undefined ? searchCriteria.name : '',
      pageIndex:
        searchCriteria.pageIndex !== undefined ? searchCriteria.pageIndex : '',
      pendinguserlist:
        searchCriteria.pendinguserlist !== undefined
          ? searchCriteria.pendinguserlist
          : false,
      roleId: searchCriteria.roleId !== undefined ? searchCriteria.roleId : '',
      username:
        searchCriteria.username !== undefined ? searchCriteria.username : '',
    };
    const path =
      'en/users/list/' +
      request.customerId +
      '?' +
      'pageIndex=' +
      request.activePage +
      '&pageSize=' +
      searchCriteria.pageIndex +
      '&roleID=' +
      request.roleId +
      '&name=' +
      request.name +
      '&username=' +
      request.username +
      '&email=' +
      request.email +
      '&companyName=' +
      request.companyName +
      '&country=' +
      request.country +
      '&roleCode=Webshop_Manager&IsRequireUsersApproved=' +
      request.pendinguserlist +
      includChildShopUsers;
    return this.apiService.get(path).map(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  }

  getAllCountries() {
    const path = 'en/countries';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getCustomerCountries(customerId) {
    let IsUserCountries = true;
    if (Number(customerId) === 0) {
      IsUserCountries = false;
    }
    const path =
      'en/countries?IsUserCountries=' +
      IsUserCountries +
      '&customerID=' +
      customerId;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getAllRoles() {
    const path = 'en/roles?type=webshop&CustomerID=' + this.webshopDetail.CustomerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getAllCustomers() {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (
      webshop !== null &&
      webshop !== undefined &&
      webshop !== '' &&
      webshop !== 'undefined'
    ) {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      //const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    let path;
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe((data) => {
        if (data) {
          //    const parentCustomerID = data.CustomerID;
          path =
            'en/customers/list?ParentCustomerID=' +
            this.webshopDetail.CustomerID;
        }
      });
    } else {
      const parentCustomerID = this.webshopDetail.CustomerID;
      path = 'en/customers/list?ParentCustomerID=' + parentCustomerID;
    }

    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getParentCustomersList(customerId) {
    const path =
      'en/customers/list?ParentCustomerID=' +
      customerId +
      '&returnParentCustomer=true&isOrderbyname=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getAllLanguages(custID?) {
    let path;
    if (custID) {
      path = 'en/languages?CustomerID=' + custID + '&isOrderbyname=true';
    } else {
      path = 'en/languages?CustomerID=&isOrderbyname=true';
    }
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getAllCurrencies() {
    const path = 'en/currencies?isOrderbyname=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getCurrenciesById(custID) {
    let path;
    if (custID) {
      path = 'en/currencies?CustomerID=' + custID + '&isOrderbyname=true';
    } else {
      path = 'en/currencies?CustomerID=&isOrderbyname=true';
    }
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  deleteUser(data) {
    const path = 'en/users?id=' + data;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }

  addUser(data) {
    const path = 'en/users';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  addUserBudget(data) {
    const path = 'en/userbudgets';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  /**
   * Service to Check Postal Code for Selected Country
   */

  checkPostalCode(countryCode, postalCode) {
    // const userSettings = this.globalService.getUserSettings();
    const path = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  uploadFile(data, params, headers) {
    const path = 'en/uploadfile';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  updateUserBudgets(data) {
    const path = 'en/userbudgets/' + data.BudgetId;
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }
  updateUser(data) {
    const path = 'en/Campaign/RewardPoints';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  extraRewards(data) {
    const path = 'en/Campaign/ExtraRewards';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  createUserAdditionalDeliveryAddress(data) {
    const path = 'en/users/createuserdeliveryaddress';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  updateUserAdditionalDeliveryAddress(data) {
    const path =
      'en/users/updateuserdeliveryaddress/' + data.UsersDeliveryAddressID;
    return this.apiService.put(path, data).map((res) => {
      return res;
    });
  }

  deleteUserAdditionalDeliveryAddress(deliveryAddressId) {
    const path = 'en/users/deleteuserdeliveryaddress/' + deliveryAddressId;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }

  validateUser(data) {
    const path =
      'en/users?UserName=' +
      data.username +
      '&customerID=' +
      data.customerID +
      '&UserID=0';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getproductRoles(custId) {
    const path = 'en/productroles?customerID=' + custId;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  addProductRoles(request) {
    const path =
      'en/productroles/user/' + request.roleid + '/' + request.userId;
    return this.apiService.post(path).map((res) => {
      return res;
    });
  }

  getUserData(userid) {
    const path = 'en/customer/GetRewardPoints/' + userid;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getCampaignData() {
    const path = 'en/customer/Campaign';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }


  mappedUserRewardList(id) {
    const path = 'en/UserCampaignList/' + id;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }


  deleteProductRole(request) {
    const path =
      'en/productroles/user/' + request.roleid + '/' + request.userId;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }

  getUsersBudgetList(userid) {
    const path = 'en/userbudgets/' + userid + '?history=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  deleteUserBudget(userBudgetID) {
    const path = 'en/userbudgets/' + userBudgetID;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }
  showAddressList(userid) {
    const path = 'en/users/' + userid;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }
}
