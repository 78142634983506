import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() orderItem = {};
  @Input() title = '';
  @Input() keys: any = {};
  @Input() countries = [];
  constructor() { }

  ngOnInit() {
  }
}
