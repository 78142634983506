import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../state/services/user.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastService, IMyOptions, MDBDatePickerComponent, ModalDirective } from 'ng-uikit-pro-standard';
import { UserHttpService } from '../state/services/user.http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/common-services/api.service';
import * as moment from 'moment';
import { ControlValidationService } from 'src/app/shared/control-validation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-budgetsedit',
  templateUrl: './user-budgetsedit.component.html',
  styleUrls: ['./user-budgetsedit.component.scss']
})
export class UserBudgetseditComponent implements OnInit {
  @ViewChild('deleteModal1', { static: true }) deleteModal1: ModalDirective;
  @ViewChild('datepicker', { static: false }) datepicker: any;
  @ViewChild('datepickerTo', { static: false }) datepickerTo: any;
  addUserBudgetButton: any;
  header: any;
  totalBudget: any;
  BudgetAmount = 0;
  userTotaBalance = 0;
  userBalanceAmount = 0;
  userCrditAmount = 0;
  userDebitAmount = 0;
  customerBalanceAmount = 0;
  customerCrditAmount = 0;
  customerDebitAmount = 0;
  isEditbudget: boolean = false;
  showLoader: Boolean = false;
  optionsSelectCurrencies: Array<any>;
  userBudgetTransactions: any = [''];
  customerBudgetTransactions: any = [''];
  userBudgetsDetails: FormGroup;
  date = new Date();
  userName: any;
  BudgetList: any = [''];
  userBudgetList1: any = [];
  selectedValue: any;
  Roles = [];
  roleName: any;
  loginData: any;
  loginUserId: any;
  editBudgetsData: any;
  actualValue: any;
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
    inputAutoFill: false,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  }
  userBudgetlist: any;
  userID: number;
  editBudgetInitialTotalAmount: number;
  companyName: any;
  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router, private userHttpService: UserHttpService,
    private translate: TranslateService) {
    this.userID = this.activatedRoute.snapshot.params ? this.activatedRoute.snapshot.params.userID : null;
    this.loadForm();
    this.onLoadFunctions();

    this.companyName = history.state.data.companyName;
    if (history.state.data.isEditbudget) {
      this.isEditbudget = true;
      this.addUserBudgetButton = this.translate.instant('USERBUDGETEDIT.UPDATE');
      this.header = this.translate.instant('USERBUDGETEDIT.EDIT_USER_BUDGET') + ' - ' + this.companyName;
    }
    else {
      this.isEditbudget = false;
      this.addUserBudgetButton = this.translate.instant('USERBUDGETEDIT.SAVE');
      this.header = this.translate.instant('USERBUDGETEDIT.ADD_USER_BUDGET') + ' - ' + this.companyName;
    }
  }

  ngOnInit() {
    this.userHttpService.getBudget().subscribe(res => {
      this.totalBudget = res;
    })
    this.getCustomerBudgetTransactions();
    // this.getCurrencies();
  }
  onLoadFunctions() {

    this.loginData = this.apiService.getLoginData();
    this.userService.loadCurrencies();
    if (this.loginData !== undefined && this.loginData !== null && this.loginData !== '') {
      this.loginUserId = this.loginData.UserID;
      this.Roles = this.loginData.Roles;
      this.userName = this.loginData.UserName;
      this.roleName = this.Roles.map((data) => { return data.RoleName })
    }
    /** to get Currencies list from Redux State */
    this.getCurrencies();
    this.getUserTransactions();
  }
  loadForm() {
    // ControlValidationService.isNumberCheck
    this.userBudgetsDetails = this.formBuilder.group({
      selectBudgetCurrency: ['', Validators.required],
      amounttotal: ['', [Validators.required, ControlValidationService.isDecimalCheck]],
      budgetsValidFrom: ['', Validators.required],
      budgetsValidTo: ['', Validators.required],
      amountleft: [''],
      isEnabled: ['']
    })

    /** For Edit user budget */
    if (history.state.data.isEditbudget) {
      const editData = history.state.data;
      this.editBudgetInitialTotalAmount = editData.AmountTotal;
      this.userBudgetsDetails.patchValue({
        selectBudgetCurrency: editData.CurrencyID,
        amounttotal: editData.AmountTotal,
        budgetsValidFrom: this.getDateFormat(new Date(editData.ValidFrom)),
        budgetsValidTo: this.getDateFormat(new Date(editData.ValidTo)),
        amountleft: editData.AmountLeft,
        isEnabled: editData.isEnabled
      })
      this.actualValue = editData.AmountTotal;
    }
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }
  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  dateFormatchange(getDate) {
    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    return myDate;
  }

  getUserTransactions() {
    this.userHttpService.getUserbudgetTransaction(this.loginUserId).subscribe(
      data => {
        if (data) {
          this.userBudgetTransactions = data;
          this.userBudgetTransactions.forEach(element => {
            this.BudgetAmount += Number(element.AmountDebit);
          });

        };
      });

  }


  getCustomerBudgetTransactions() {
    this.userHttpService.getUserbudgetTransaction(this.userID).subscribe(
      (data: any) => {
        if (data.length !== 0) {
          this.customerBudgetTransactions = data;
          let len = this.customerBudgetTransactions.length;
          /* this.customerBudgetTransactions = data;
           this.customerBudgetTransactions.forEach(element => {
             this.customerBalanceAmount += Number(element.Balance);
             this.customerCrditAmount += Number(element.AmountCredit);
             this.customerDebitAmount += Number(element.AmountDebit);
           });
 
           if (this.customerBalanceAmount == null || this.customerBalanceAmount == 0) {
             this.customerBalanceAmount = 0;
           }
           else if (this.customerCrditAmount == null || this.customerCrditAmount == 0) {
             this.customerCrditAmount = 0;
           }
           else if (this.customerDebitAmount == null || this.customerDebitAmount == 0) {
             this.customerDebitAmount = 0;
           }
           let total = ((Number(this.customerBalanceAmount) - Number(this.customerDebitAmount))) + (Number(this.userBudgetsDetails.value.amounttotal));*/
          this.userTotaBalance = this.customerBudgetTransactions[len - 1].Balance;
        };
      });
  }

  getCurrencies() {
    this.userService.getAllCurrencies().subscribe(
      data => {
        if (data) {
          this.optionsSelectCurrencies = [];
          // data.forEach(element => {
          const langVal = {
            value: '20',
            label: 'Indian Rupees',
            // value: element.CurrencyID,
            // label: element.Name,
          };
          this.selectedValue = langVal.value = '20';
          this.optionsSelectCurrencies.push(langVal);

          // });
        }
      });
  }

  addUserBudgetTransaction() {
    let balance: number = 0;
    if (this.roleName[0] !== "Super Admin" && (this.totalBudget < this.userBudgetsDetails.value.amounttotal)) {
      this.toast.error('Insufficient Funds');
      return false;
    }
    if (this.roleName[0] === "Super Admin") {
      balance = 0;
    } else {
      balance = Number(this.totalBudget) - Number(this.userBudgetsDetails.value.amounttotal)
    }
    const addUserBudgetData1 = {
      "UserID": Number(this.loginUserId),
      "CurrencyID": this.userBudgetsDetails.value.selectBudgetCurrency,
      "AmountCredit": 0,
      "TransactionDate": this.dateFormatchange(this.userBudgetsDetails.value.budgetsValidFrom),
      "Spent": " Add to " + this.userID,
      "AssignedUserID": Number(this.userID),
      "IsActive": 1,
      "Balance": balance,
      "AmountDebit": Number(this.userBudgetsDetails.value.amounttotal),
    }
    this.totalBudget = this.totalBudget - this.userBudgetsDetails.value.amounttotal;
    this.userHttpService.setBudget(this.totalBudget);
    this.showLoader = true;
    this.userHttpService.addUserbudgetTransaction(addUserBudgetData1).subscribe((res: any) => {
      this.showLoader = false;
      if (res) {
        this.userBudgetlist = res
        this.loadForm();
        this.toast.success(res);
        let data: any = [];
        data.companyName = this.companyName;
        this.router.navigate(['/admin-user-budgetlist/' + this.userID], { state: { data }, replaceUrl: false });
      }
      else {
        this.toast.error(res.message);
      }
    },
      err => {
        this.showLoader = false;
        this.toast.error(err.error.Message);
      });
  }

  addCustomerBudgetTransaction() {
    if (this.roleName[0] !== "Super Admin" && (this.totalBudget < this.userBudgetsDetails.value.amounttotal)) {
      this.toast.error('Insufficient Funds');
      return false;
    }

    const addCustomerBudgetData = {
      "UserID": Number(this.userID),
      "CurrencyID": this.userBudgetsDetails.value.selectBudgetCurrency,
      "AmountCredit": Number(this.userBudgetsDetails.value.amounttotal),
      "TransactionDate": this.dateFormatchange(this.userBudgetsDetails.value.budgetsValidFrom),
      "Spent": " Credit By " + this.userID,
      "AssignedUserID": Number(this.loginUserId),
      "IsActive": 1,
      "Balance": this.userTotaBalance ? (Number(this.userTotaBalance) + Number(this.userBudgetsDetails.value.amounttotal)) : Number(this.userBudgetsDetails.value.amounttotal),
      "AmountDebit": 0,
    }

    this.showLoader = true;
    this.userHttpService.addUserbudgetTransaction(addCustomerBudgetData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.addUserBudgetTransaction()
      }
      else {
        this.toast.error(data.message);
      }
    },
      err => {
        this.showLoader = false;
        this.toast.error(err.error.Message);
      });
  }

  addUserBudgetSubmit() {

    if (this.roleName[0] !== "Super Admin" && (this.totalBudget < this.userBudgetsDetails.value.amounttotal)) {
      this.toast.error('Insufficient Funds');
      return false;
    }

    const addUserBudgetData = {
      "UserID": this.userID,
      "CurrencyID": this.userBudgetsDetails.value.selectBudgetCurrency,
      "AmountTotal": Number(this.userBudgetsDetails.value.amounttotal),
      "ValidFrom": this.dateFormatchange(this.userBudgetsDetails.value.budgetsValidFrom),
      "ValidTo": this.dateFormatchange(this.userBudgetsDetails.value.budgetsValidTo),
      "LoggedInsUserID": this.loginUserId
    }
    console.log(addUserBudgetData)
    this.showLoader = true;
    this.userHttpService.addUserBudget(addUserBudgetData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.addCustomerBudgetTransaction();
      }
      else {
        this.toast.error(data.message);
      }
    },
      err => {
        this.showLoader = false;
        this.toast.error(err.error.Message);
      });

  }


  updateAddUserBudget() {

    const updateUserBudgetsData = {
      "CurrencyID": this.userBudgetsDetails.value.selectBudgetCurrency,
      "AmountTotal": this.userBudgetsDetails.value.amounttotal,
      "ValidFrom": this.dateFormatchange(this.userBudgetsDetails.value.budgetsValidFrom),
      "ValidTo": this.dateFormatchange(this.userBudgetsDetails.value.budgetsValidTo),
      "AmountLeft": this.userBudgetsDetails.value.amountleft,
      "isEnabled": this.userBudgetsDetails.value.isEnabled,
      "UserID": this.userID,
      "BudgetId": history.state.data.UserBudgetID,
      "LoggedInsUserID": this.loginUserId
    }
    /* console.log(this.userTotaBalance)
     console.log(updateUserBudgetsData)*/
    //this.userHttpService.setBudget(this.userTotaBalance);
    /* this.userHttpService.updateUserBudgets(updateUserBudgetsData).subscribe((data: any) => {
       if (data) {
         this.loadForm();
         this.toast.success(data);
         this.router.navigate(['/admin-user-budgetlist/' + this.userID], { replaceUrl: false });
       } else {
         this.toast.error(data.message);
       }
     },
       err => {
         this.showLoader = false;
         this.toast.error(err.error.Message);
       })*/
  }

  setAmountLeft(event) {
    let getValue = event.target.value;
    getValue = getValue - this.actualValue;
    getValue = getValue + this.userBudgetsDetails.value.amountleft;
    if (event.target.value > this.userBudgetsDetails.value.amountleft) {
      this.actualValue = this.userBudgetsDetails.value.amounttotal;
      this.userBudgetsDetails.patchValue({
        amountleft: Number(getValue)
      })
    }
  }
  userBudgetlistClear() {
    this.userBudgetsDetails.reset();
    this.datepicker.clearDate();
    this.datepickerTo.clearDate();
    this.deleteModal1.hide();
    this.userBudgetsDetails.patchValue({
      amountleft: ''
    })
    this.actualValue = 0;
  }

  UserBudgetlistUrl() {
    let data: any = [];
    data.companyName = this.companyName;
    this.router.navigate(['/admin-user-budgetlist/' + this.userID], { state: { data }, replaceUrl: false });
  }

}
