import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { UserHttpService } from '../state/services/user.http.service';
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
@Component({
  selector: 'rewardkart-admin-user-payment-report',
  templateUrl: './user-paymentreport.component.html',
  styleUrls: ['./user-paymentreport.component.scss']
})
export class UserPaymentReportComponent implements OnInit {
  @ViewChild('userModal', { static: true }) userModal: ModalDirective;
  @ViewChild('userError', { static: true }) userError: ModalDirective;
  @Input() domain: any;
  filterFields: any = [];
  creationError: boolean;
  status:any =[];
  companyList:any=[];
  // @ViewChild('bulkCreationModal', { static: true }) bulkCreationModal: ModalDirective;
  pageSize :number = 10;
  pageIndex:number = 1;
  header: any;
  bankRefNo:any = '';
  statusValue: any = 'All';
  showLoader: boolean;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'CompanyName',
      header: 'Company Name',
    },
    {
      primaryKey: 'CreatedOn',
      header: 'Created On'
    },
    {
      primaryKey: 'ProgramManager',
      header: 'Program Manager'
    },
    {
      primaryKey: 'ImagePath',
      header: 'View/Download User list',
      format: 'xls'
    },
    {
      primaryKey: 'OrginalAmount',
      header: 'Requested Reward points'
    },
    {
      primaryKey: 'DiscountAmount',
      header: 'Amout Payable',
    },
    {
      primaryKey: 'PaymentStatus',
      header: 'Payment Status',
    },
    {
      primaryKey: 'UploadStatus',
      header: 'User Import Status',
    }
    ,
    {
      primaryKey: 'PaymentDetails',
      header: 'Action',
      format: 'userpaymentaction',
      // format: 'delete'
    },

  ];
  companyName: any;
  selectedUser: any;
  userData: any;
  userdatacount: any;
  enablepagination: boolean = true;
  resetStatus: boolean = false;
  activePage: number = 1;
  perPagedata: number = 10;
  searchCriteria: any = {};
  submitted: boolean;
  pointsType: any = '';
  constructor(private linkTableService: LinkTableService,private userHttpService: UserHttpService, private toastr: ToastService) { 
    this.status = [
      { value: 'All', label: 'Select All' },
      { value: 'OnlyNotDeleted', label: 'Payment Done' },
      { value: 'OnlyDeleted', label: 'Payment Gateway- NEFT/RTGS' },
      { value: 'OnlyWithoutWebshop', label: 'Pay Later' },
    ]
    this.userData = JSON.parse(sessionStorage.getItem('userdetails'));
  }

  ngOnInit() {
    this.setfilterData()
    if(this.userData.DomainName === "pc" || this.userData.DomainName === "pennacement" || this.domain === "pc"){
      this.columns = [
    {
      primaryKey: 'CompanyName',
      header: 'Company Name',
    },
    {
      primaryKey: 'CreatedOn',
      header: 'Created On'
    },
    {
      primaryKey: 'ProgramManager',
      header: 'Program Manager'
    },
    {
      primaryKey: 'OrginalAmount',
      header: 'Requested Reward points'
    },
    {
      primaryKey: 'DiscountAmount',
      header: 'Amout Payable',
    },
    {
      primaryKey: 'PaymentStatus',
      header: 'Payment Status',
    },
    {
      primaryKey: 'AdminApproved',
      header: 'Action',
      format: 'userpaymentaction',
      // format: 'delete'
    }
  ]

    }
  }

  refreshTable(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.getPaymentgrid(this.searchCriteria)
    }
}

  setfilterData() {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Company Name',
        type: 'text',
        class: 'col-md-12',
        required: false,
        key: 'CompanyName',
        id: 'CompanyName',
        filterkey: 'CompanyName',
        selected: this.companyName
      },/*{
      label: 'Status',
      type: 'select',
      class: 'col-md-12 status-items',
      key: 'status',
      data: this.status,
      selected: this.statusValue,
      required: false,
      filterkey: 'status'
    },*/{
      label: 'Clear',
      type: 'button',
      class: 'col-sm-6 text-lefts mt-3 margin-width',
      key: 'clear',
    },
    {
      label: 'Search',
      type: 'button',
      class: 'col-sm-6 text-left mt-3 margin-width',
      key: 'button',
    }
  ]
  this.pageIndex = 1;
  this.searchCriteria.pageIndex = 1;
  this.searchCriteria.pageSize = 10;
  this.activePage = 1;
  this.perPagedata = 10;
  this.getPaymentgrid(this.searchCriteria)
  }

  getReset(_event){
    this.companyName = '';
    this.setfilterData();
  }
  
  getPaymentgrid(data){
    this.showLoader = true;
    let userid = 0;
    if(this.domain === "91sb" || this.domain === "sw" || this.domain === "247hrm" || this.domain === "aed"){
      userid = 0;
    }else{
      userid = this.userData.UserID
    }

    if(this.userData.DomainName === "91sb" || this.userData.DomainName === "91springboard" || this.domain === "91sb" || this.userData.DomainName === "aed"){
      
      this.userHttpService.get91sbPaymentList(data,userid,this.companyName).subscribe((data:any)=> {
        this.showLoader = false;
        this.companyList = data;
        this.userdatacount = data[0].TotalCount
      }, _error =>{
        this.showLoader = false;
      })
    }
    if(this.userData.DomainName === "sw" || this.userData.DomainName === "smartworks" || this.domain === "sw"){

      this.userHttpService.getSwPaymentList(data,userid,this.companyName).subscribe((data:any)=> {
        this.showLoader = false;
        this.companyList = data;
        this.userdatacount = data[0].TotalCount
      }, _error =>{
        this.showLoader = false;
      })
    }
    if(this.userData.DomainName === "pc" || this.userData.DomainName === "pennacement" || this.domain === "pc"){

      this.userHttpService.getPcPaymentList(data,userid,this.companyName).subscribe((data:any)=> {
        this.showLoader = false;
        this.companyList = data;
        this.userdatacount = data[0].TotalCount
      }, _error =>{
        this.showLoader = false;
      })
    }

    if(this.userData.DomainName === "247hrm" || this.domain === "247hrm"){

      this.userHttpService.gethrmPaymentList(data,userid,this.companyName).subscribe((data:any)=> {
        this.showLoader = false;
        this.companyList = data;
        this.userdatacount = data[0].TotalCount
      }, _error =>{
        this.showLoader = false;
      })
    }
   
  }

  getFilterResult(event){
  }

  getSelectData(event){
    this.companyName = event.CompanyName.value;
  }

  getSubmit(_event){
    this.pageIndex = 1;
    this.searchCriteria.pageIndex = 1;
    this.searchCriteria.pageSize = 10;
    this.activePage = 1;
    this.perPagedata = 10;
    this.getPaymentgrid(this.searchCriteria);
  }
  

  approveUserPay(event){
    this.selectedUser = event;
    this.bankRefNo = '';
    if(this.userData.DomainName === "247hrm" || this.domain === "247hrm"){
      this.pointsType = this.selectedUser.PointType
    }
    this.userModal.show();
  }

  approveUser(){
    let params = {
      "TransId": this.selectedUser.TransNo,
      "PaymentStatus":"A",
      "BankRefNo": this.bankRefNo,
      "AtomTranId":"",
      "UserId":this.userData.UserID
  }
  this.userModal.hide();
  this.showLoader = true;
    if(this.userData.DomainName === "91sb" || this.userData.DomainName === "91springboard" || this.domain === "91sb" || this.userData.DomainName === "aed"){
      this.userHttpService.admin91sbAction(params).subscribe((data:any)=>{
        this.showLoader = false;
        if(data.IsSuccess){
          this.createUser();
        }else{
          this.toastr.error('Try Again Later');
        }
        this.getPaymentgrid(this.searchCriteria);
      }, _error =>{
        this.showLoader = false;
        this.getPaymentgrid(this.searchCriteria);
      })
    }
    if(this.userData.DomainName === "sw" || this.userData.DomainName === "smartworks" || this.domain === "sw"){
      this.userHttpService.adminswAction(params).subscribe((data:any)=>{
        this.showLoader = false;
        if(data.IsSuccess){
          this.createUser();
        }else{
          this.toastr.error('Try Again Later');
        }
        this.getPaymentgrid(this.searchCriteria);
      }, _error =>{
        this.showLoader = false;
      })
    }

    if(this.userData.DomainName === "pc" || this.userData.DomainName === "pennacement" || this.domain === "pc"){
      this.userHttpService.adminpcAction(params).subscribe((data:any)=>{
        this.showLoader = false;
        if(data.IsSuccess){
          this.createOrder();
        }else{
          this.toastr.error('Try Again Later');
        }
        this.getPaymentgrid(this.searchCriteria);
      }, _error =>{
        this.showLoader = false;
      })
    }

    if(this.userData.DomainName === "247hrm" || this.domain === "247hrm"){
      this.userHttpService.adminhrmAction(params).subscribe((data:any)=>{
        this.showLoader = false;
        if(data.IsSuccess){
          this.createUser();
        }else{
          this.toastr.error('Try Again Later');
        }
        this.getPaymentgrid(this.searchCriteria);
      }, _error =>{
        this.showLoader = false;
      })
    }
  }
  // pending(){
  //   this.bulkCreationModal.show();
  // }

  createOrder(){
      let params = {
        "TransId": this.selectedUser.TransNo
      }
      this.userHttpService.createOrder(params).subscribe((res:any)=>{
        this.toastr.success('Purchase Approved');
      })
  }

  createUser(){
    let webshop = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if(this.domain === "sw"){
      let formData = new FormData();
      formData.append("DomainName", "sw");
      formData.append("TransActionId", this.selectedUser.TransNo);
      this.showLoader = true;
      this.userHttpService.bulkUserswRwrdPt(formData).subscribe((res:any)=>{
          this.showLoader = false;
          if(res.ErrorMessage){
            this.toastr.error(res.ErrorMessage);
          }
          else if(res.IsSuccess){
              if(res.creationError.length === 0 ){
                 this.toastr.success('User Approved');
              }else{
                  this.userError.show();
                  this.creationError = res.creationError;
              }
          }
        }, error => {
          this.showLoader = false;
          this.toastr.error(error.error.Message);
        })
    }else if(this.domain === "91sb" || this.domain === "aed"){
      let formData = new FormData();
      formData.append("DomainName", this.domain === "91sb" ? "91sb" : "aed");
      formData.append("TransActionId", this.selectedUser.TransNo);
      this.showLoader = true;
      this.userHttpService.bulkUserRwrdPt(formData).subscribe((res:any)=>{
          this.showLoader = false;
          this.userModal.hide();
          if(res.ErrorMessage){
            this.toastr.error(res.ErrorMessage);
          }
          else if(res.IsSuccess){
              if(res.creationError.length === 0 ){
                this.toastr.success('User Approved');
              }else{
                  this.userError.show();
                  this.creationError = res.creationError;
              }
          }
        }, error => {
          this.showLoader = false;
          this.toastr.error(error.error.Message);
        })
    }
    else if(this.domain === "247hrm"){
      let formData = new FormData();
      formData.append("DomainName", "247hrm");
      formData.append("TransActionId", this.selectedUser.TransNo);
      formData.append("PointsType", this.pointsType);
      
      this.showLoader = true;
      this.userHttpService.bulkhrmUserswRwrdPt(formData).subscribe((res:any)=>{
          this.showLoader = false;
          this.userModal.hide();
          if(res.ErrorMessage){
            this.toastr.error(res.ErrorMessage);
          }
          else if(res.IsSuccess){
              if(res.creationError.length === 0 ){
                this.toastr.success('User Approved');
              }else{
                  this.userError.show();
                  this.creationError = res.creationError;
              }
          }
        }, error => {
          this.showLoader = false;
          this.toastr.error(error.error.Message);
        })
    }
  }

  closeModal(){
    this.userError.hide();
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.searchCriteria.pageSize = event;
    this.getPaymentgrid(this.searchCriteria);
  }

  paginationNum(event) {
  this.pageIndex = event;
  this.activePage = event;
  this.searchCriteria.pageIndex = event;
  this.getPaymentgrid(this.searchCriteria);
  }
}
