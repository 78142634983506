import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { UserHttpService } from 'src/app/user/state/services/user.http.service';
import { UserService } from 'src/app/user/state/services/user.service';
import { SettingsHttpService } from '../services/settings.http.service';

@Component({
  selector: 'rewardkart-admin-product-groups',
  templateUrl: './product-groups.component.html',
  styleUrls: ['./product-groups.component.scss']
})
export class ProductGroupsComponent implements OnInit {

  header: any;
  userdatacount: any;
  userList: any = [];
  questions: any[];
  submitted = false;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  showUserEditpage = false;
  showUserBudgetlist = false;
  actions: any = ['edit', 'delete'];
  filterData: any = {};
  paginationData: any = {};
  collection = [];
  activePage: number = 1;
  rowactivePage: number = 1;
  enablepagination: boolean = true;
  resetStatus: boolean = false;
  commonRemoveFilter: any;
  userBudgetList: any = [];
  columns: ColumnSetting[] = [];
  filterFields: any = [];
  tableEvent: any;
  optionsSelectCustomers: Array<any>;
  optionSelectSupplier: Array<any>;
  optionsSelectCountries: Array<any>;
  productGroupSearchForm: FormGroup;
  searchCriteria: any = {};
  isPendingUser = false;
  passFilterresult: any = [];
  tableName: string = 'pendinguserlist';
  showLoader: Boolean = false;
  addUserLabel;
  webshopDetail: any;
  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private userHttpService: UserHttpService, private toast: ToastService,
    private translate: TranslateService, private settingsHttpService: SettingsHttpService,) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.addUserLabel = this.translate.instant('PRODUCTGROUPS.ADD_GROUP');
    this.columns = [
      {
        primaryKey: 'Name',
        header: this.translate.instant('PRODUCTGROUPS.GROUP_NAME')
      },
      {
        primaryKey: 'productsNames',
        header: this.translate.instant('PRODUCTGROUPS.PRODUCTS'),
        // primaryKeyItem: 'Name',
        // format: 'array'
      },
      {
        primaryKey: 'action',
        format: 'useraction',
      }
    ];
  }

  ngOnInit() {
    const searchproductgroupskey = sessionStorage.getItem('searchproductgroupskey');
    if (searchproductgroupskey !== null && searchproductgroupskey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchproductgroupskey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      if (sessionStorage.getItem('productGroupEditRedirect') !== null && sessionStorage.getItem('productGroupEditRedirect') !== undefined) {
        this.activePage = this.searchCriteria.activePage;
        sessionStorage.removeItem('productGroupEditRedirect');
      } else {
        this.activePage = 1;
        this.searchCriteria.activePage = 1;
      }
      sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
    }
    this.header = this.translate.instant('USERLIST.USER_DETAILS'); // Multilingual to be implemented
    //  this.isPendingUser = (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false;
    this.onLoadFunctions();
    this.createForms();
    this.getCustomers();
    // this.searchCriteria = JSON.parse(sessionStorage.getItem('searchproductgroupskey'));
    // console.log(sessionStorage.getItem('searchproductgroupskey'));
    // const getsearchproductgroupskey = JSON.parse(sessionStorage.getItem('searchproductgroupskey'));
    // this.getProductGroupsList(getsearchproductgroupskey);
    // const getsearchproductgroupsresult = JSON.parse(sessionStorage.getItem('searchproductgroupsresult'));
    // console.log(getsearchproductgroupsresult);
    // this.filterFields = getsearchproductgroupsresult;
  }

  ngOnChanges() {
    this.getCustomers()
  }


  getCustomers() {
    if (sessionStorage.getItem('parentCustomerList') !== undefined && sessionStorage.getItem('parentCustomerList') !== null
      && sessionStorage.getItem('parentCustomerList') !== '') {
      const customerList = JSON.parse(sessionStorage.getItem('parentCustomerList'));
      this.optionsSelectCustomers = [];
      this.optionsSelectCustomers.push({ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' });
      customerList.forEach(option => {
        this.optionsSelectCustomers.push({ label: option.Name, value: option.CustomerID })
      })
      this.getSupplierList();

    } else {
      this.settingsHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
        (data: any) => {
          if (data) {
            this.optionsSelectCustomers = [];
            this.optionsSelectCustomers.push({ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' });
            data.forEach(element => {
              const customerVal = {
                value: element.CustomerID,
                label: element.Name,
              };
              this.optionsSelectCustomers.push(customerVal);
            });
            this.getSupplierList();
          }
        }),
        error => {
          this.toast.error('unable to get webshop customers');
        }
    }

  }

  getPerPage(event) {
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(true);
  }

  getSupplierList() {
    let customerId = this.webshopDetail.CustomerID;
    this.settingsHttpService.getSuppliersList(customerId, this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.optionSelectSupplier = [];
        this.optionSelectSupplier.push({ label: this.translate.instant('PRODUCTGROUPS.SELECT_SUPPLIER'), value: '' });
        data.ItemsCollection.forEach(element => {
          const countryVal = {
            value: element.SupplierID,
            label: element.Name,
          };
          this.optionSelectSupplier.push(countryVal);
        });

        this.getCountries();
      }
    });


  }

  onLoadFunctions() {
    // this.userService.loadCountries();
    this.userService.loadCustomers();
    this.userService.loadRoles();
    this.userService.loadSearchFormFields();
    this.onLoadUsers();
  }

  onLoadUsers() {
    const searchproductgroupskey = sessionStorage.getItem('searchproductgroupskey');
    if (searchproductgroupskey !== null && searchproductgroupskey !== '[object Object]') {
      this.getProductGroupsList(JSON.parse(searchproductgroupskey));
    }
    else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        supplierId: '',
        productName: '',
        productManualId: '',
        groupName: '',
        pageIndex: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
      // this.userService.loadUsers(this.searchCriteria);
      this.getProductGroupsList(this.searchCriteria);
    }

  }

  getSelectData(data) {
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        customerId: data.selectwebshop !== undefined && data.selectwebshop.selectMethod === 'selectwebshop' ? data.selectwebshop.value : this.webshopDetail.CustomerID,
        supplierId: data.selectsupplier !== undefined && data.selectsupplier.selectMethod === 'selectsupplier' ? data.selectsupplier.value : '',
        productName: data.productName !== undefined && data.productName.selectMethod === 'productName' ? data.productName.value : '',
        productManualId: data.productManualId !== undefined && data.productManualId.selectMethod === 'productManualId' ? data.productManualId.value : '',
        groupName: data.groupName !== undefined && data.groupName.selectMethod === 'groupName' ? data.groupName.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }
    else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        supplierId: '',
        productName: '',
        productManualId: '',
        groupName: '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }

    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }

    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'productName') {
        element.selected = this.searchCriteria.productName
      }
      else if (element.filterkey === "productManualId") {
        element.selected = this.searchCriteria.productManualId
      }
      else if (element.filterkey === "groupName") {
        element.selected = this.searchCriteria.groupName
      }
      else if (element.key === "selectsupplier") {
        element.selected = this.searchCriteria.supplierId
      }
      else if (element.key === "selectwebshop" && this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
        element.selected = this.searchCriteria.customerId
      }
    });

    //    
    // sessionStorage.setItem('searchproductgroupsresult', JSON.stringify(this.searchCriteria))

  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    this.getProductGroupsList(this.filterData);
    this.getCountries();
    sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchproductgroupsresult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'pendinguserlist') {
        element.selected = false;
        element.data = false;
      }
    });
    this.resetStatus = true;
    // this.getPerPage(10);
    this.getSelectData(undefined);
    sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchproductgroupsresult', JSON.stringify(this.filterFields));
    this.onLoadFunctions();
    // this.getCountries();
    // if (reset.selectMethod !== undefined && reset.value) {
    //   this.searchCriteria.perPagedata = event;

    // }
  }

  paginationNum(event) {
    this.activePage = event;

    const searchproductgroupskey = sessionStorage.getItem('searchproductgroupskey');
    if (searchproductgroupskey !== null && searchproductgroupskey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchproductgroupskey);
    }
    this.searchCriteria.activePage = event;
    sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
    this.perPagedata = this.searchCriteria.pageIndex;
    // this.getSubmit(true);
    this.getProductGroupsList(this.searchCriteria);
  }

  getCountries() {
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }

    let customerId = 0;
    if (this.searchCriteria !== null && this.searchCriteria !== undefined) {
      // tslint:disable-next-line:max-line-length
      if (this.searchCriteria.customerId !== null && this.searchCriteria.customerId !== undefined && this.searchCriteria.customerId !== '') {
        customerId = this.searchCriteria.customerId;
      }
    }
    console.log("isParentCustomer : ", isParentCustomer);
    this.userHttpService.getCustomerCountries(customerId).subscribe(
      (data: any) => {
        if (data) {
          console.log("Response country data : ", data);
          this.optionsSelectCountries = [];
          this.optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });

          const searchproductgroupsresult = sessionStorage.getItem('searchproductgroupsresult')
          if (searchproductgroupsresult !== null) {
            this.filterFields = JSON.parse(sessionStorage.getItem('searchproductgroupsresult'))
            /** Display Webshop Name Column Based on Parent/Child Shop */

            /** To Prefill Webshop dropdown value(based on Login) */
            // tslint:disable-next-line:max-line-length
            if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
              const getIndex = this.filterFields.findIndex(ind => ind.key === 'selectwebshop');
              if (this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
                this.filterFields[getIndex].selected = this.searchCriteria.customerId;
              } else {
                this.filterFields[getIndex].selected = '';
              }
            }
          }
          else {
            this.filterFields = [
              {
                label: this.translate.instant('USERLIST.FILTER'),
                class: 'col-md-12',
                key: 'filterHeading',
              },
              {
                label: 'Select Webshop',
                type: 'select',
                data: this.optionsSelectCustomers,
                class: 'col-md-12 no-icon',
                key: 'selectwebshop',
                required: false,
                filterkey: 'selectwebshop',
                selected: (this.webshopDetail.IsWebshopDropdown && isParentCustomer) ? this.searchCriteria.customerId : ''
              },
              {
                label: this.translate.instant('PRODUCTGROUPS.SELECT_SUPPLIER'),
                type: 'select',
                data: this.optionSelectSupplier,
                class: 'col-md-12 no-icon',
                key: 'selectsupplier',
                required: false,
                filterkey: 'selectsupplier',
                selected: this.searchCriteria.supplierId
              },
              {
                label: this.translate.instant('PRODUCTGROUPS.PRODUCT_NAME'),
                type: 'text',
                class: 'col-md-12',
                required: false,
                key: 'productName',
                id: 'productName',
                filterkey: 'productName',
                selected: this.searchCriteria.productName
              },
              {
                label: this.translate.instant('PRODUCTGROUPS.PRODUCT_MANUAL_ID'),
                type: 'text',
                class: 'col-md-12',
                required: false,
                key: 'productManualId',
                id: 'productManualId',
                filterkey: 'productManualId',
                selected: this.searchCriteria.productManualId
              },
              {
                label: this.translate.instant('PRODUCTGROUPS.GROUP_NAME'),
                type: 'text',
                class: 'col-md-12',
                required: false,
                key: 'groupName',
                id: 'groupName',
                filterkey: 'groupName',
                selected: this.searchCriteria.groupName
              },
              {
                label: this.translate.instant('USERLIST.CLEAR'),
                type: 'button',
                class: 'col-sm-6 text-left mt-3 margin-width ',
                key: 'clear',
              },
              {
                label: this.translate.instant('USERLIST.SEARCH'),
                type: 'button',
                class: 'col-sm-6 text-left mt-3 margin-width',
                key: 'button',
              }
            ];

          }

          sessionStorage.setItem('searchproductgroupsresult', JSON.stringify(this.filterFields))

        }

      });
  }

  createForms() {
    this.productGroupSearchForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      supplierId: [''],
      name: [''],
      email: [''],
      companyName: [''],
      country: [''],
    });
  }

  getFilterResult(filterresult) {
    this.passFilterresult = filterresult;
  }

  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchproductgroupskey = sessionStorage.getItem('searchproductgroupskey');
    if (searchproductgroupskey !== null && searchproductgroupskey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchproductgroupskey);
    }
    if (val !== undefined) {
      // this.searchCriteria[val.key] = '';
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = '';
        this.getCountries();
      }
      else if (val.key === "selectsupplier") {
        this.searchCriteria.supplierId = '';
      }
      else if (val.key === "selectcountry") {
        this.searchCriteria.country = '';
      }
      else if (val.key === "productName") {
        this.searchCriteria.productName = '';
      }
      else if (val.key === "productManualId") {
        this.searchCriteria.productManualId = '';
      }
      else if (val.key === "groupName") {
        this.searchCriteria.groupName = '';
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
        // if (element.filterkey === 'pendinguserlist') {
        //   element.selected = false;
        //   element.data = false;
        // }
      });

      // this.getSubmit('');
      sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchproductgroupsresult', JSON.stringify(this.filterFields));
      this.getProductGroupsList(this.filterData);

    }
  }

  onSubmit() {
    const searchCriteria = {
      customerId: this.productGroupSearchForm.value.customerId !== undefined ? this.productGroupSearchForm.value.customerId : '',
      supplierId: this.productGroupSearchForm.value.supplierId !== undefined ? this.productGroupSearchForm.value.supplierId : '',
      productName: '',
      productManualId: '',
      groupName: '',
    };
    this.getProductGroupsList(searchCriteria);
  }

  resetForm() {
    this.productGroupSearchForm.reset();
  }

  getProductGroupsList(data) {
    this.showLoader = true;
    if (!this.webshopDetail.IsWebshopDropdown) {
      data.customerId = this.webshopDetail.CustomerID;
    }
    if (data.customerId === '') {
      data.customerId = '';
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (this.webshopDetail.ParentCustomerID !== null) {
          data.customerId = this.webshopDetail.CustomerID;
        }
      }
    }
    this.settingsHttpService.getProductGroupsList(data).subscribe((data: any) => {
      if (data) {
        this.userdatacount = data.TotalItemsCount;
        this.userList = data;
        // this.userList = this.userList.ItemsCollection;
        if (this.userList && this.userList.ItemsCollection.length > 0) {
          this.userList = this.userList.ItemsCollection.map(groupItem => {
            let productNames = this.getGroupProductNames(groupItem.Products);
            const groupItems = Object.assign(groupItem, {
              subOrderItems: groupItem.Products ? groupItem.Products.length : 0, productsNames: productNames
            });
            return groupItems;
          })
        }
        else {
          this.userList = [];
          this.userdatacount = 0;
          this.showLoader = false;
          this.toast.error('No Orders Found');
        }
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      });
  }

  getGroupProductNames(productGroups: any) {
    let productNames = '';
    let index = 0;
    productGroups.forEach(productGroup => {
      if (index > 0) {
        productNames += ', ';
      }
      productNames = productNames + productGroup.Name;
      index++;
    });
    return productNames;
  }

  addUser(event: boolean) {
    this.submitted = event;
  }

  viewUser(event: boolean) {
    this.submitted = event;
  }

  editProductGroups(userData: boolean) {
    if (userData) {
      const searchproductgroupskey = sessionStorage.getItem('searchproductgroupskey');
      if (searchproductgroupskey !== null && searchproductgroupskey !== '[object Object]') {
        this.searchCriteria = JSON.parse(searchproductgroupskey);
        this.perPagedata = 10;
        this.searchCriteria.pageIndex = 10;
        this.resetStatus = true;
        sessionStorage.setItem('searchproductgroupskey', JSON.stringify(this.searchCriteria));
      }
      this.showUserEditpage = true;
      try {
        this.userService.selectedUser(userData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            data.dashboardRedirectStatus = false;
            this.router.navigate(['/product-groups-edit'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }

  }



  deleteProductGroups(event) {
    this.submitted = event;
    // this.userService.loadUserdelete(event.UserID);
    this.settingsHttpService.deleteProductGroups(event.ProductGroupID).subscribe((data: any) => {
      if (data !== null) {
        this.toast.success('Product group deleted successfully');
        setTimeout(() => {
          this.onLoadUsers();
        }, 300);
      }
    },
      (error) => {
        this.showLoader = false;
        if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
          let errorMessage = '';
          const objResponse = Object.values(error.error.ModelState);
          objResponse.forEach(errmessage => {
            errorMessage += errmessage;
          });
          this.toast.error(errorMessage);
        }
      });
  }

  addNewProductGroup() {
    this.router.navigate(['/product-groups-add'], { replaceUrl: false });
  }

  refreshTable(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.onLoadUsers();
    }
  }

  selectedTableRow(event: boolean) {
    if (event) {
    }
  }
  
   ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }


}
