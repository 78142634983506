import { Action } from '@ngrx/store';
export const LOAD_BRANDS = '[Brands] Load all brands';
export const LOAD_BRAND_SUCCESS = '[Brands] Load Brand Success';
export const LOAD_BRAND_FAIL = '[Brands] Load Brand Fail';

export const FILE_UPLOAD = '[Brands] Load Fileupload';
export const FILE_UPLOAD_SUCCESS = '[Brands] Load Fileupload Success';
export const FILE_UPLOAD_FAIL = '[Brands] Load Fileupload Fail';

export const SELECTED_BRAND = '[Brands] Brand Selected';

export class LoadBrands implements Action {
    readonly type = LOAD_BRANDS;
    constructor(public payload: any) { }
}

export class LoadBrandSuccess implements Action {
    readonly type = LOAD_BRAND_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadBrandFail implements Action {
    readonly type = LOAD_BRAND_FAIL;
    constructor(public payload: any) { }
}

export class LoadFileUpload implements Action {
    readonly type = FILE_UPLOAD;
    constructor(public payload: any) { }
}

export class LoadFileUploadSuccess implements Action {
    readonly type = FILE_UPLOAD_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadFileUploadFail implements Action {
    readonly type = FILE_UPLOAD_FAIL;
    constructor(public payload: any) { }
}

export class SelectedBrand implements Action {
    readonly type = SELECTED_BRAND;
    constructor(public payload: any) { }
}

export type All =
    | LoadBrands
    | LoadBrandSuccess
    | LoadBrandFail
    | LoadFileUpload
    | LoadFileUploadSuccess
    | LoadFileUploadFail
    | SelectedBrand;