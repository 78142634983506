import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OrderProcessOverviewHttpService } from '../../state/services/order-process-overview-http.service';
import { Router } from '@angular/router';
import { ToastService, MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { OrderCancelComponent } from './order-cancel/order-cancel.component';
import { CaptureAmountComponent } from './capture-amount/capture-amount.component';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { InternalNoteComponent } from '../order-process-info-container/internal-note/internal-note.component';
import { OrderOldProfomaInvoiceComponent } from '../order-old-profoma-invoice/order-old-profoma-invoice.component';
import { OrderHttpService } from '../../state/services/order.http.service';

@Component({
  selector: 'rewardkart-admin-order-item-list',
  templateUrl: './order-item-list.component.html',
  styleUrls: ['./order-item-list.component.scss']
})
export class OrderItemListComponent implements OnInit {
  @Input() order: any = {};
  @Input() orderItems = [];
  @Input() currency = '';
  @Input() subOrderID = '';
  @Input() orderId = '';
  @Input() profit = {};
  @Input() PaymentTypeCode = '';
  @Input() returnPage = '';
  @Input() customerID = '';
  @Input() unAuthorised: any;
  @Input() finishedPL: any;
  @Output() confirmationDialogEvent = new EventEmitter<any>();
  @Output() orderDelete = new EventEmitter<any>();
  @Input() returnListPage: any;
  proformaInvoice: any;
  OrderPdfUrlOrderConfirmation: any;
  OrderPdfUrl: any;
  orderExpire = false;
  loading: Boolean = false;
  @Input() subOrderItemData: any;

  modalRef: MDBModalRef;

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };

  itemToExpand = {};
  editNotes = {};
  webshopDetail: any;
  showField: boolean = false;
  haveInvoice: Boolean = false;
  // tslint:disable-next-line: max-line-length
  constructor(private httpService: OrderProcessOverviewHttpService, private router: Router, private toast: ToastService, private modalService: MDBModalService, private apiService: ApiService, private translate: TranslateService, private toastService: ToastService, public orderHttpService: OrderHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.OrderPdfUrlOrderConfirmation = this.apiService.baseUrl + this.apiService.baseLang + '/invoices/pdf/' + this.orderId + '?forceOrderConfirmationTitle=true';
    // tslint:disable-next-line:max-line-length
    this.OrderPdfUrl = this.apiService.baseUrl + this.apiService.baseLang + '/invoices/pdf/' + this.orderId + '?forceProformaInvoiceTitle=true';
    this.proformaInvoice = this.OrderPdfUrl;
    // console.log('this.proFormaInvoice', this.proFormaInvoice)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.order) {
      this.getPaymentType();
    }
    // if (this.returnListPage) {
    //   this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
    // }
    if (changes.order.currentValue !== null) {
      if (this.webshopDetail.DomainName !== null && this.order.domainName !== null) {
        this.showField = this.webshopDetail.DomainName == 'wsa' || this.order.domainName == 'wsa' || this.webshopDetail.DomainName == 'gn' || this.order.domainName == 'gn' || this.webshopDetail.DomainName == 'bo' || this.order.domainName == 'bo' ? true : false;
      }
    }
    if (changes.orderItems !== undefined && changes.orderItems !== null) {
      if (changes.orderItems.currentValue !== null) {
        const orderDetailItem = changes.orderItems.currentValue
          .filter(data => (Number(data.OrderItemID) === Number(this.subOrderID)) || (Number(data.SuborderID) === Number(this.subOrderID)));
        if (orderDetailItem[0] && orderDetailItem[0].OrderItemStates !== undefined) {
          this.checkOrderItemStates(orderDetailItem[0].OrderItemStates);
          orderDetailItem[0].OrderItemStates.forEach((state, index) => {
            if (state.StateCode === 'DELIVERED' && state.Properties.StateOptionCode === 'DELIVERED') {
              // this.proFormaInvoice = this.OrderPdfUrl;
              this.OrderPdfUrl += this.OrderPdfUrlOrderConfirmation + '&invoice=true&subOrderID=' + this.subOrderID;
            }
          });

        }
      }
    }
  }

  oldOrderProformaInvoice() {
    const data = {
      header: this.translate.instant('ORDERDETAILS.OLD_ORDERINFO_TITLE')
    };
    this.modalRef = this.modalService.show(OrderOldProfomaInvoiceComponent, {
      ...this.modalOptions, data
    });
  }

  toggleBody(id, expand) {
    this.itemToExpand[id] = expand;
  }

  goBackToUnAuthorize() {
    if (this.returnPage === 'unauthorised') {
      this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
    }
  }

  goBack(orderId, productId) {
    const id = orderId;
    const customerID = this.customerID;
    const finishedPL = this.finishedPL;
    if (this.returnPage === 'productionLine' && productId) {
      this.router.navigate([`/admin-order-production-line-details/${productId}/${this.finishedPL}`], {
        state: { id, customerID },
        replaceUrl: false
      });
    } else if (this.returnPage === 'orderFromProductionLine') {
      const { lineId } = history.state;
      this.router.navigate([`/admin-order-item/${id}`], {
        state: { id, lineId, customerID, finishedPL },
        replaceUrl: false
      });
    } else {
      const { lineId } = history.state;
      this.router.navigate([`/admin-order-item/${id}`], {
        state: { id, lineId, finishedPL },
        replaceUrl: false
      });
    }

  }

  edit(orderId, subOrderId, orderItem) {
    // this.router.navigate([`/admin-order-process/${orderId}/${subOrderId}/edit`], { replaceUrl: false });
    const orderDatas = {
      orderID: orderId,
      orderItemID: subOrderId,
      isSubOrder: (orderItem.SuborderID !== undefined && orderItem.SuborderID !== null) ? true : false,
      viewDetails: true,
      subOrderID: (orderItem.SuborderID !== undefined && orderItem.SuborderID !== null) ? orderItem.SuborderID : '',
    };
    const customerID = this.customerID;
    this.router.navigate(['/edit-orderitems'], {
      state: {
        data: orderDatas, returnPage: this.returnPage,
        customerID: customerID,
        subOrderItemData: this.subOrderItemData,
      }, replaceUrl: false
    });
  }

  redirectProductPage(productId) {
    const webshop = sessionStorage.getItem('webshopDetail'); 0
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    const orderDetailData = {
      orderID: this.orderId,
      subOrderID: this.subOrderID,
      customerID: this.webshopDetail.CustomerID
    }
    const productData = {
      ProductID: productId,
      dashboardRedirectStatus: false
    };
    this.router.navigate(['/admin-product-edit'], { state: { data: productData, orderDetailData }, replaceUrl: false });
  }

  confirmOrder() {
    this.toast.success(this.translate.instant('ORDERDETAILS.ORDER_CONFIRMATION_FILEDOWNLOAD'));
  }

  cancelOrder(orderId, orderItemId) {
    const data = {
      header: `Order Cancel`,
      orderId,
      orderItemId
    };
    this.modalRef = this.modalService.show(OrderCancelComponent, {
      ...this.modalOptions, data
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.deleteOrderItem(result, orderItemId, orderId);
    });
  }

  deleteOrderItem(result, OrderItemID, orderId) {
    this.httpService.cancelOrder(OrderItemID, result.sendMail).subscribe(cancelResponse => {
      this.modalRef.hide();
      this.toast.success(this.translate.instant('ORDERDETAILS.DELETE_ORDER_SUCCESS'));
      //todo trigger api to fetch order list or goback to its source page(order process detail)
      if (this.orderItems.length > 1) {
        const id = orderId;
        this.router.navigate([`/admin-order-item/${id}`], {
          state: { id },
          replaceUrl: false
        });
      } else {
        this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
      }
    });
  }

  captureAmount(orderItem, index) {
    const { OrderItemID, isSampleOrder } = orderItem;
    this.httpService.getCaptureAmount(this.orderId, OrderItemID, isSampleOrder).subscribe((response) => {
      const data = {
        header: `Capture Amount`,
        price: response
      };
      this.modalRef = this.modalService.show(CaptureAmountComponent, {
        ...this.modalOptions, data
      });

      const payload = {
        Amount: orderItem.OrderItemAmount,
        Email: this.order.Email,
        OrderID: this.orderId,
        PaymentTypeID: this.order.PaymentTypeID,
        TXNID: this.order.ePayTxnID,
        currencyCode: this.currency
      };

      this.modalRef.content.modalAction.subscribe((result: any) => {
        this.updateCaptureAmount(result, OrderItemID, payload, index);
      });
    });
  }

  updateCaptureAmount(result, OrderItemID, payload, index) {
    this.modalRef.hide();
    const { confirm } = result;
    if (confirm) {
      this.httpService.captureAmount(OrderItemID, payload).subscribe(captureResponse => {
        this.orderItems[index].isCaptureEnable = false;
        const data = {
          header: `Capture Amount Status`,
          price: captureResponse,
          type: 'message',
          cancelText: 'Close'
        };
        this.modalRef = this.modalService.show(CaptureAmountComponent, {
          ...this.modalOptions, data
        });
      });
    }
  }

  /*** Check Order Item Statees ****/
  checkOrderItemStates = function (itemState) {
    this.orderExpire = true;
    itemState.forEach((state, i) => {
      if ((state.StateName === 'Delivered' && state.Properties.StateOptionCode === 'DELIVERED') ||
        (state.StateName === 'Dispatched' && state.Properties.StateOptionCode === 'DISPATCHED')) {
        this.orderExpire = false;
      }
    });
    return this.orderExpire;
  };

  toggleEditNotes(orderItemId, index, note = '') {
    const data = {
      header: `Edit Internal Note`,
      note: `${note}`
    };
    this.modalRef = this.modalService.show(InternalNoteComponent, {
      ...this.modalOptions, data
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.updateInternalNote(orderItemId, result.note, index);
    });
  }

  hideEditNoteInputField(orderItemId) {
    if (this.editNotes[orderItemId]) {
      delete this.editNotes[orderItemId];
    }
  }

  updateInternalNote(orderId, note, index) {
    this.httpService.updateInternalNote(orderId, note).subscribe((response) => {
      if (response) {
        this.toastService.success('Internal note updated successfully');

        this.orderItems[index].InternalNote = note;
        this.modalRef.hide();
      } else {
        this.toastService.error('Something went wrong.Please try after sometimes');

      }
    }, (error) => {
      this.toastService.error('Something went wrong. Please try after sometimes');
    });
  }

  deleteOwtOrderItem(orderItem) {
    if (confirm("Are you sure you want to delete this OrderItem?")) {
      const path =
        this.httpService.deleteOwtOrderItem(orderItem.OrderItemID).subscribe(result => {
          if (result) {
            this.toastService.success('OrderItem Deleted Successfully..!');
            this.orderDelete.emit();
            // if (this.orderItems.length == 0) {
            //   this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
            // }

          } else {
            this.toastService.error('OrderItem Deleted Failed!');
          }
        }, error => {
          this.toastService.error(error.Message);
        });
    }
  }

  modalConfirmation(type) {
    const eventData = { type: type, haveInvoice: this.haveInvoice };
    this.confirmationDialogEvent.emit(eventData);
  }

  isDisabled(item) {
    if (item == true) {
      return false;
    } else {
      return true;
    }
  }

  getPaymentType() {
    this.loading = true;
    const request = {
      userID: this.order.UserID,
      customerID: this.order.CustomerID
    }
    console.log(request)

    this.orderHttpService.newPayment(request).subscribe((response: any) => {
      this.loading = false;
      let getInvoiceStatus = response.filter(data => data.PaymentTypeID == 3);
      this.haveInvoice = getInvoiceStatus.length > 0 ? true : false;
    },
      (error) => {
        this.loading = false;
      });
  }

}
