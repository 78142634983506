import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductGovernmentalcostsComponent } from './product-governmentalcosts.component';
import { ProductManageGovernmentalCostsModule } from './product-manage-governmentalcosts/product-manage-governmentalcosts.module';
import { CustomdirectiveModule } from '../customdirective/customdirective.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CommonFormModule,
    SharedModule,
    TranslateModule,
    ProductManageGovernmentalCostsModule,
    CustomdirectiveModule

  ],
  declarations: [ProductGovernmentalcostsComponent],
  exports: [ProductGovernmentalcostsComponent]
})

export class ProductGovernmentalCostsModule { }

