import { Directive, AfterViewInit, ElementRef, HostListener, Renderer } from '@angular/core';

@Directive({
    selector: '[scrollValidate]'
})
export class ScrollDirective {
    constructor(private elRef: ElementRef, public renderer: Renderer) {
    }
    @HostListener('wheel', ['$event'])
    handleWheelEvent(event) {
        event.preventDefault();
    }
}