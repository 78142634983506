import { Directive, ElementRef, Input, Renderer, OnInit } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[appStyleCell]'
})

export class StyleCellDirective implements OnInit {

    @Input() appStyleCell: string;
    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer) { }

    ngOnInit() {
        if (this.appStyleCell === undefined) {
            this.renderer.setElementStyle(
                this.elementRef.nativeElement,
                'color',
                '#dcdcdc');
            this.renderer.setElementStyle(
                this.elementRef.nativeElement,
                'text-align',
                'left');
        } else {
            this.renderer.setElementStyle(
                this.elementRef.nativeElement,
                'text-align',
                'left');
        }
        if (typeof this.appStyleCell === 'number') {
            this.renderer.setElementStyle(
                this.elementRef.nativeElement,
                'text-align',
                'left');
        }
        this.renderer.setElementStyle(
            this.elementRef.nativeElement,
            'vertical-align',
            'middle');
    }
}