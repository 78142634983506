import { Action } from '@ngrx/store';

export const SELECTED_ROW = '[LinkTable] Selected Row';

export class SelectedRow implements Action {
    readonly type = SELECTED_ROW;
    constructor(public payload: any) { }
}

export type All = SelectedRow;
