import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkCsvTableComponent } from './link-csv-table.component';
import { LinkTableModule } from '../link-table/link-table.module';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [LinkCsvTableComponent],
  imports: [CommonModule, LoaderModule, LinkTableModule],
  exports: [LinkCsvTableComponent]
})
export class LinkCsvTableModule { }
