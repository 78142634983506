import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SupplierHttpService } from './state/services/supplier.http.service';
import { SupplierService } from './state/services/supplier.service';
import { supplierReducers } from './state/reducers/supplier.reducer';
import { SupplierEffects } from './state/effects/supplier.effects';
import { SupplierAddModule } from './supplier-add/supplier-add.module';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { SupplierListModule } from './supplier-list/supplier-list.module';
import { ManageSupplierSettingsModule } from './manage-supplier-settings/manage-supplier-settings.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SupplierAddModule,
    SupplierListModule,
    CommonFilterModule,
    ManageSupplierSettingsModule,
    EffectsModule.forFeature([SupplierEffects]),
    StoreModule.forFeature('supplierModule', supplierReducers)
  ],
  declarations: [],
  providers: [SupplierHttpService, SupplierService],
  exports: []
})

export class SupplierModule { }
