import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { MessageDraftComponent } from './message-draft.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessagedraftdetailsModule } from './message-draftdetails/message-draftdetails.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    MessagedraftdetailsModule
  ],
  declarations: [MessageDraftComponent],
  exports: [MessageDraftComponent]
})

export class MessagedraftModule { }