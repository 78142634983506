import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BillboardHttpService } from './state/services/billboard.http.service';
import { BillboardService } from './state/services/billboard.service';
import { billboardReducers } from './state/reducers/billboard.reducer';
import { BillboardEffects } from './state/effects/billboard.effects';
import { BillboardListComponent } from '../billboard/billboard-list/billboard-list.component';
import { EditBillboardModule } from './billboard-edit/billboard-edit.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EditBillboardModule,
    EffectsModule.forFeature([BillboardEffects]),
    StoreModule.forFeature('billboardModule', billboardReducers)
  ],
  declarations: [BillboardListComponent],
  providers: [BillboardHttpService, BillboardService],
  exports: [BillboardListComponent]
})
export class BillboardModule {}
