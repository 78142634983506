import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductEditComponent } from './product-edit.component';
import { SharedModule } from '../../shared/shared.module';
import { ProductAdditionalInfoModule } from './product-additional-info/product-additional-info.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductCategoryInfoComponent } from './product-category-info/product-category-info.component';
import { ProductStockComponent } from './product-stock/product-stock.component';
import { ProductImagesModule } from './product-images/product-images.module';
import { ProductPricesModule } from './product-prices/product-prices.module';
import { ProductColorsModule } from './product-colors/product-colors.module';
import { ProductSizesModule } from './product-sizes/product-sizes.module';
import { ProductPropertiesModule } from './product-properties/product-properties.module';
import { ProductLogopositionAreaModule } from './product-logopositionarea/product-logopositionarea.module';
import { ProductAddModule } from '../product-add/product-add.module';
import { ProductBasicInfoModule } from './product-basic-info/product-basic-info.module';
import { ProductCategoryInfoModule } from './product-category-info/product-category-info.module';
import { TabScrollModule } from 'ngx-tab-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ProductAdditionalCostsModule } from './product-additional-costs/product-additional-costs.module';
import { UnpublishModalModule } from './unpublishmodal/unpublishmodal.module';
import { ProductGovernmentalCostsModule } from './product-governmentalcosts/product-governmentalcosts.module';
import { MomentModule } from 'ngx-moment';
import { CustomdirectiveModule } from './customdirective/customdirective.module';
import { ProductGovernmentalAdditionalCostComponent } from './product-governmental-additional-cost/product-governmental-additional-cost.component';
import { ProductColorSizeComponent } from './product-color-size/product-color-size.component';
import { CustomerOriginalDataComponent } from './customer-original-data/customer-original-data.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ProductAdditionalInfoModule,
    ProductColorsModule,
    ReactiveFormsModule,
    SharedModule,
    ProductImagesModule,
    ProductPricesModule,
    ProductSizesModule,
    ProductPropertiesModule,
    ProductLogopositionAreaModule,
    ProductAddModule,
    ProductBasicInfoModule,
    ProductCategoryInfoModule,
    ProductAdditionalCostsModule,
    NgbModule,
    TranslateModule,
    TabScrollModule.forRoot({
      autoRecalculate: true,
      showDropDown: true,
      showTooltips: false,
      tooltipLeftPlacement: 'top',
      tooltipRightPlacement: 'top',
      scrollBy: 150,
      leftScrollAddition: 0
    }),
    NgbDropdownModule,
    NgbTooltipModule,
    UnpublishModalModule,
    ProductAdditionalCostsModule,
    ProductGovernmentalCostsModule,
    MomentModule,
    CustomdirectiveModule

  ],
  declarations: [ProductEditComponent, ProductStockComponent, ProductGovernmentalAdditionalCostComponent, ProductColorSizeComponent, CustomerOriginalDataComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [ProductEditComponent]
})

export class ProductEditModule { }
