import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignHttpService } from './state/services/campaign.http.service';
import { CampaignService } from './state/services/campaign.service';
import { campaignReducers } from './state/reducers/campaign.reducer';
import { CampaignEffects } from './state/effects/campaign.effects';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { CampaignEditModule } from './campaign-edit/campaign-edit.module';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { SharedModule } from '../shared/shared.module';
import { CampaignBillboardListComponent } from './billboard-list/billboard-list.component';
import { CampaignEditBillboardModule } from './billboard-edit/billboard-edit.module';

@NgModule({
  imports: [
    CommonModule,
    CampaignEditModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CampaignEditBillboardModule,
    EffectsModule.forFeature([CampaignEffects]),
    StoreModule.forFeature('campaignModule', campaignReducers),
    ColorPickerModule,
  ],
  declarations: [CampaignListComponent, CampaignBillboardListComponent],
  providers: [CampaignHttpService, CampaignService],
  exports: [CampaignListComponent, CampaignEditModule, CampaignBillboardListComponent, CampaignEditBillboardModule],
})
export class CampaignModule { }
