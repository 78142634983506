import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { element } from 'protractor';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-manage-additional-option',
  templateUrl: './product-manage-additional-option.component.html',
  styleUrls: ['./product-manage-additional-option.component.scss']
})
export class ProductManageAdditionalOptionComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  name
  defaultPrice
  productManageAdditionalOptionForm: FormGroup;
  @Input() additionalCostOptionsList: any;
  @Input() translateOptionItems: any;
  @Output() getAdditionalOptionsList = new EventEmitter();
  selectedLang: any = 'English'
  languageOption: any = [];
  translationLanguages: any[];
  deleteManageOption: any;
  webshopDetail: any;
  constructor(public formBuilder: FormBuilder,
    public toast: ToastService,
    public apiService: ApiService) {
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      } else {
        const loginData = this.apiService.getLoginData();
        if (loginData !== undefined && loginData !== null && loginData !== '') {
          this.webshopDetail.CustomerID = loginData.CustomerID;
        }
      }
  
    this.buildForm();
  }
  ngOnInit() {

  }
  ngOnChanges() {
    //this.buildForm();
  }
  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let group = {
      Price: new FormControl(''),
      Action: new FormControl('Insert')
    };
    let translationText = {
      formControlName: 'AdditionalCostOptionName'
    }
    this.translationLanguages = [];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      const option = {
        formControlName: 'AdditionalCostOptionName' + lang.Code,
        class: lang.Name
      }
      this.translationLanguages.push(option);
    });
    this.translationLanguages.forEach(data => {
      group[data.formControlName] = new FormControl('');
    });
    this.productManageAdditionalOptionForm = new FormGroup(group);
  }

  getCurrentLang(lang) {
    this.selectedLang = lang.label;
  }

  deleteOptins(index) {
    this.deleteManageOption = index
    this.deleteModal.show();
  }
  getDeleteStatus(status) {
    if (status) {
      if (this.additionalCostOptionsList[this.deleteManageOption].ProductAdditionalCostOptionID) {
        this.additionalCostOptionsList[this.deleteManageOption]['Action'] = 'Delete';
        this.deleteModal.hide();
      } else {
        this.additionalCostOptionsList.splice(this.deleteManageOption, 1);
        this.toast.success('Product additional cost Option deleted successfully');
        this.deleteModal.hide();
      }
    }
    else {
      this.deleteModal.hide()
    }
  }
  addOption() {
    if (this.productManageAdditionalOptionForm.value.Price !== ''
      && this.productManageAdditionalOptionForm.value.formControlName !== '') {
      if (this.productManageAdditionalOptionForm.value.Price !== null
        && this.productManageAdditionalOptionForm.value.formControlName !== null) {
        if (this.productManageAdditionalOptionForm.value.AdditionalCostOptionNameen !== '') {
          if (
            this.productManageAdditionalOptionForm.value.AdditionalCostOptionNameen !== null) {
            this.productManageAdditionalOptionForm.value['Action'] = 'Insert';
            console.log(this.productManageAdditionalOptionForm.value);
            this.additionalCostOptionsList.push(this.productManageAdditionalOptionForm.value);
            this.toast.success('Additional cost Option Added successfully');
            this.productManageAdditionalOptionForm.reset();
          }
          else {
            this.toast.error('Name  field is required for English language')
          }
        }
        else {
          this.toast.error('Name field is required for English language')
        }
      }
      else {
        this.toast.error('Please fill the fields')
      }
    }
    else {
      this.toast.error('Please fill the fields')
    }
  }
  saveOptions() {
    this.getAdditionalOptionsList.emit(this.additionalCostOptionsList);
    // this.toast.success('Additional cost Saved successfully');
  }
  // for default price lock
  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Negative values are not accepted here')
      return false
    }
    else {
      return true
    }
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
    // console.log(event.target.value);
  }
}
