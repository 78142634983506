import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { DashboardUserManagementComponent } from './dashboard-user-management-report.component';
import { CompanyOrderSummaryModule } from '../../company-order/company-order-summary/company-order-summary.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        CommonFilterModule,
        CompanyOrderSummaryModule,
        NgxChartsModule,
        TranslateModule
    ],
    declarations: [DashboardUserManagementComponent],
    exports: [DashboardUserManagementComponent]
})

export class DashboardUserManagementReportModule { }