import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable()
export class CompanyOrderHttpService {
    constructor(private apiService: ApiService) { }
    ordersummary(customerId, start, end) {
        const path = 'en/ordersummary?customerID=' + customerId + '&orderPlacedFromDate=' + start + '&orderPlacedToDate=' + end;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    orderdetails(customerId, start, end, orderid) {
        const path = 'en/orderdetails?customerID=' + customerId + '&includChildShopOrders=true&orderPlacedFromDate=' + start + '&orderPlacedToDate=' + end + '&orderID=' + orderid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
}