import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierState, supplierReducers } from './supplier.reducer';

export interface State {
    supplierModule: SupplierState;
}

export const reducers = supplierReducers;

export const getSupplierState = createFeatureSelector<SupplierState>('supplierModule');

export const getSupplierListSuccess = createSelector(getSupplierState,
    (state: SupplierState) => state.supplierList);

export const getAllSuppliers = createSelector(getSupplierState,
    (state: SupplierState) => state.suppliers);

