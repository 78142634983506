import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { UserHttpService } from '../state/services/user.http.service';
import { TabsetComponent, ToastService } from "ng-uikit-pro-standard";
@Component({
  selector: 'rewardkart-admin-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['./user-payment.component.scss']
})
export class UserPaymentComponent implements OnInit {
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  domainsw: string = "sw";
  doamin91sb: string = "aed";
  doaminpc: string = "pc";
  doaminhrm: string = "247hrm";
  ngOnInit() {
    
  }
}
