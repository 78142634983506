import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-order-profit',
  templateUrl: './order-profit.component.html',
  styleUrls: ['./order-profit.component.scss']
})
export class OrderProfitComponent implements OnInit {
  @Input() orderItem: any = null;
  @Input() profit: any = {};
  @Input() orderId: any = '';
  @Input() currency: any = '';
  @Input() header: any = '';
  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  modalCanceled() {
    this.modalRef.hide();
  }
}
