import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProductionLineService {
    defaultSearch = {
        CustomerID: 0,
        SupplierID: '',
        ProductName: '',
        ProductIdManual: '',
        ProductionLineID: '',
        SortBy: 0,
        finishedPLs: false,
        InternalOrders: false
    };
    defaultPageLimit = {
        pageSize: 10,
        pageIndex: 1
    };
    updatedSearch: any = {};
    updatedPageLimit: any = {};
    webshopDetail;
    includChildShopOrders = false;
    constructor() {
        this.updatedSearch = { ...this.defaultSearch };
        this.updatedPageLimit = { ...this.defaultPageLimit };
    }

    updateSearchCriteria(searchData) {
        this.updatedSearch = searchData;
    }
}

