import { NgModule } from '@angular/core';
import { StyleCellDirective } from './style-cell-directive';
import { ConDraggableDirective } from './con-drag-drop-directive';

@NgModule({
    declarations: [StyleCellDirective, ConDraggableDirective],
    exports: [
        StyleCellDirective, ConDraggableDirective
    ]
})
export class LinkTableDirectiveModule { }
