import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';
import { ColumnSetting } from '../../../../shared/link-table/models/link-table-model';
@Component({
  selector: 'rewardkart-admin-product-roles-user',
  templateUrl: './product-roles-user.component.html',
  styleUrls: ['./product-roles-user.component.scss']
})
export class ProductRolesUserComponent implements OnInit {
  @Input() getusersRoleList: any;
  @Input() selectedUserRole: any;
  @Output() loadUser = new EventEmitter<any>();
  @Output() loaderStatus = new EventEmitter<any>();
  tableName: string = 'productrolesuser';
  userName: any = '';
  name: any = '';
  userList: any = [];
  actions: any = ['delete'];
  userResult: any;
  webshopDetail: any;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    console.log(this.getusersRoleList)
    if (this.getusersRoleList) {
      this.userList = this.getusersRoleList.Users;
    }
  }

  columns: ColumnSetting[] = [
    {
      primaryKey: 'Username',
      header: 'User Name'
    },
    {
      primaryKey: 'UserName',
      header: 'Name'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];

  findUser() {
    this.loaderStatus.emit(true);
    const request = {
      roleCode: 'user',
      username: this.userName,
      name: this.name,
      customerID: this.webshopDetail.CustomerID
    }
    this.webshopHttpService.searchUser(request).subscribe((data: any) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.userResult = data.ItemsCollection;
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  addUser(request) {
    this.loaderStatus.emit(true);
    const req = {
      ProductRoleID: this.selectedUserRole.ProductRoleID,
      UserID: request.UserID
    }
    this.webshopHttpService.addUserRole(req).subscribe((data: any) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.userName = '';
        this.name = '';
        this.userResult = null;
        this.toast.success('Role added to user');
        this.loadUserList();
        this.loadUser.emit(true);
      }
      else {
        this.toast.error('Role cannot be added to user. Check if user already has this role.');
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  loadUserList() {
    this.webshopHttpService.productRole(this.selectedUserRole.ProductRoleID).subscribe((user: any) => {
      if (user) {
        this.userList = user.Users;
      }
    },
      (error) => {

      })
  }

  deleteUser(request) {
    this.loaderStatus.emit(true);
    const req = {
      ProductRoleID: this.selectedUserRole.ProductRoleID,
      UserID: request.UserID
    }
    console.log(req);
    this.webshopHttpService.deleteUser(req).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.loadUser.emit(true);
        this.loadUserList();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

}
