import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { MessageboxActions } from '../actions/index';
import { State } from '../reducers/index';
import { MessageboxHttpService } from '../services/messagebox.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class MessageboxEffects {
    @Effect()
    getMessageDraftData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEDRAFTSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageDraftData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessagedraftSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessagedraftFail(error));
        })
    );

    @Effect()
    getMessageLineData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEINBOXSTATE),
        withLatestFrom(this.store$),
        mergeMap(([payload]: any) => 
            this.messageboxHttpService.getMessageLineData(payload.payload).pipe(switchMap(data => [
                new MessageboxActions.LoadMessageinboxSuccess(data)
            ]),
            catchError((error:any) => of(new MessageboxActions.LoadMessageinboxFail(MessageboxActions.LOAD_MESSAGEINBOX_FAIL)))
        )
        // catchError((error: any) => {
        //     return Observable.of(new MessageboxActions.LoadMessageinboxFail(error));
        // })
    ));

    @Effect()
    getMessageImportantData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEIMPORTANTSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageImportantData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageImportantSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageImportantFail(error));
        })
    );

    @Effect()
    getMessageInquiriesData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEINQUIRIESSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageInquiriesData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageInquiriesSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageInquiriesFail(error));
        })
    );

    @Effect()
    getMessageTrashData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGETRASHSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageTrashData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageTrashSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageTrashFail(error));
        })
    );

    @Effect()
    getMessageClaimsData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGECLAIMSSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageClaimsData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageClaimsSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageClaimsFail(error));
        })
    );


    @Effect()
    getMessageSentData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGESENTSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageSentData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageSentSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageSentFail(error));
        })
    );

    @Effect()
    getCustomers$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_CUSTOMERS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getCustomers(payload.payload).map((data) => {
                return new MessageboxActions.LoadCustomerSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadCustomerFail(error));
        })
    );

    @Effect()
    getMessageInboxdetailsData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEINBOXDETAILSSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageInboxdetailsData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageinboxdetailsSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageinboxdetailsFail(error));
        })
    );

    @Effect()
    getMessageImportantdetailsData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEINPORTANTDETAILSSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageImportantdetailsData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageimportantdetailsSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageimportantdetailsFail(error));
        })
    );

    @Effect()
    getMessageClaimsdetailsData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGECLAIMSDETAILSSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageClaimsdetailsData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageclaimsdetailsSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageclaimsdetailsFail(error));
        })
    );

    @Effect()
    getMessageInquiriesdetailsData$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_MESSAGEINQUIRIESDETAILSSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getMessageInquiriesdetailsData(payload.payload).map((data) => {
                return new MessageboxActions.LoadMessageinquiriesdetailsSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadMessageinquiriesdetailsFail(error));
        })
    );


    @Effect()
    bulkAction$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_BULKACTION),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.bulkAction(payload.payload, '').map((data) => {
                return new MessageboxActions.LoadBulkActionSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadBulkActionFail(error));
        })
    );

    @Effect()
    sendMessage$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.SEND_MESSAGE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.sendMessage(payload.payload).map((data) => {
                return new MessageboxActions.SendMessageSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.SendMessageFail(error));
        })
    );

    @Effect()
    sendDraftMessage$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.SEND_DRAFT_MESSAGE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.sendDraftMessage(payload.payload).map((data) => {
                return new MessageboxActions.SendDraftMessageSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.SendDraftMessageFail(error));
        })
    );

    @Effect()
    deleteMessage$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.DELETE_MESSAGE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.deleteMessage(payload.payload, '').map((data) => {
                return new MessageboxActions.SendMessageSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.SendMessageFail(error));
        })
    );
    @Effect()
    deleteMail$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.DELETE_MAIL),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.deleteMail(payload.payload).map((data) => {
                return new MessageboxActions.DeleteMailSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.DeleteMailFail(error));
        })
    );
    @Effect()
    getUserMailAddress$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_USERMAILADDRESS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.getUserMailAddress(payload.payload).map((data) => {
                return new MessageboxActions.LoadUserMailAddressSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadUserMailAddressFail(error));
        })
    );

    @Effect()
    unreadMessage$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_EMAILUNREAD),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.unreadMessage(payload.payload).map((data) => {
                return new MessageboxActions.LoadEmailUnreadSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadEmailUnreadFail(error));
        })
    );

    @Effect()
    claimsunreadMessage$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_CLAIMSUNREAD),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.claimsunreadMessage(payload.payload).map((data) => {
                return new MessageboxActions.LoadEmailClaimsSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadClaimsUnreadFail(error));
        })
    );

    @Effect()
    inquriesunreadMessage$: Observable<Action> = this.actions$.pipe(
        ofType(MessageboxActions.LOAD_INQURIESUNREAD),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.messageboxHttpService.inquriesunreadMessage(payload.payload).map((data) => {
                return new MessageboxActions.LoadEmailInquriesSuccess(data);
            })
        }),
        catchError((error: any) => {
            return Observable.of(new MessageboxActions.LoadInquriesUnreadFail(error));
        })
    );

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private messageboxHttpService: MessageboxHttpService) { }
}
