import { UserHttpService } from './state/services/user.http.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { UserSearchModule } from './user-search/user-search.module';
import { UserEditModule } from './user-edit/user-edit.module';
import { UserListComponent } from './user-list/user-list.component';
import { SharedModule } from '../shared/shared.module';
import { UserEffects } from './state/effects/user.effects';
import { userReducers } from './state/reducers/user.reducer';
import { UserService } from './state/services/user.service';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { CommonFormModule } from '../common/common-form/common-form.module';
import { FilterTagModule } from '../common/common-filter/filter-tags/filter-tags.module';
import { UserBudgetEditModule } from './user-budgetsedit/user-budgetsedit.module';
import { showListModule } from './showlist/showlist.module';
import { userAdditionalDeliveryListModule } from './useradditionaldeliverylist/useradditionaldeliverylist.module';
import { UserBudgetListModule } from './user-budgetslist/user-budgetslist.module';
import { BulkUserImportComponent } from './user-import/user-import.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { UserPaymentReportComponent } from './user-paymentreport/user-paymentreport.component';
import { EmailconfigComponent } from './emailconfig/emailconfig.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    UserEditModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    UserSearchModule,
    CommonFilterModule,
    CommonFormModule,
    FilterTagModule,
    UserBudgetEditModule,
    UserBudgetListModule,
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('userModule', userReducers),
    showListModule,
    userAdditionalDeliveryListModule,
    TranslateModule
  ],
  declarations: [UserListComponent, BulkUserImportComponent, UserPaymentComponent, UserPaymentReportComponent, EmailconfigComponent],
  providers: [UserHttpService, UserService],
  exports: [UserListComponent, BulkUserImportComponent, UserPaymentComponent, UserPaymentReportComponent, UserEditModule]
})

export class UserModule { }
