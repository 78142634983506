import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from '../../shared/shared.module';
import { BlockEditComponent } from './block-edit.component';
import { BlockHttpService } from '../state/services/block.http.service';
import { BlockService } from '../state/services/block.service';
import { ProductSelectModule } from 'src/app/product/product-select/product-select.module';
import { ToastModule } from 'ng-uikit-pro-standard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    SharedModule,
    ProductSelectModule,
    ToastModule
  ],
  declarations: [BlockEditComponent],
  providers: [BlockHttpService, BlockService],
  exports: [BlockEditComponent]
})

export class BlockEditModule { }
