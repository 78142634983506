import {
    MessageinboxState, MessageDraftState, MessageImportantState, MessageInquiriesState,
    MessageinportantdetailsState, MessageTrashState, MessageClaimsState, MessageSentState,
    MessageclaimsdetailsState, MessageinboxdetailsState, MessageinquiriesdetailsState ,MessagereadState
} from '../models/index';
import { MessageboxActions } from '../actions/index';

export interface MessageboxState {
    messageinboxwidget: MessageinboxState[];
    messagedraftwidget: MessageDraftState[];
    messageimportantwidget: MessageImportantState[];
    messageinquirieswidget: MessageInquiriesState[];
    messagetrashswidget: MessageTrashState[];
    messageclaimswidget: MessageClaimsState[];
    messagesentwidget: MessageSentState[];
    messageinboxdetailswidget: MessageinboxdetailsState[];
    messageimportantdetailswidget: MessageinportantdetailsState[];
    messageclaimsdetailswidget: MessageclaimsdetailsState[];
    messageinquiriesdetailswidget: MessageinquiriesdetailsState[];
    messagecustomer: any;
    bulkAction: any;
    sendMessageSuccess: any;
    sendDraftMessageSuccess: any;
    deleteMessageSuccess: any;
    deleteMailSuccess: any;
    getUserMailAddressSuccess: any;
    messagereadwidget: MessagereadState[];
    unreadMessage: any;
    claimsunreadMessage: any;
    inquriesunreadMessage: any;
}

export const initialState: MessageboxState = {
    messageinboxwidget: null,
    messagedraftwidget: null,
    messageimportantwidget: null,
    messageinquirieswidget: null,
    messagetrashswidget: null,
    messageclaimswidget: null,
    messagesentwidget: null,
    messageinboxdetailswidget: null,
    messageimportantdetailswidget: null,
    messageclaimsdetailswidget: null,
    messageinquiriesdetailswidget: null,
    messagecustomer: null,
    bulkAction: null,
    sendMessageSuccess: null,
    sendDraftMessageSuccess: null,
    deleteMessageSuccess: null,
    deleteMailSuccess: null,
    getUserMailAddressSuccess: null,
    messagereadwidget: null,
    unreadMessage: null,
    claimsunreadMessage: null,
    inquriesunreadMessage: null
};

export function MessageboxReducers(state: MessageboxState = initialState, action: MessageboxActions.All) {
    switch (action.type) {

        case MessageboxActions.LOAD_MESSAGEINBOX_SUCCESS:
            return Object.assign({}, state, { messageinboxwidget: action.payload });
        // message inboxdetails 
        case MessageboxActions.LOAD_MESSAGEINBOXDETAILS_SUCCESS:
            return Object.assign({}, state, { messageinboxdetailswidget: action.payload });

        case MessageboxActions.LOAD_MESSAGEINPORTANTDETAILS_SUCCESS:
            return Object.assign({}, state, { messageimportantdetailswidget: action.payload });

        case MessageboxActions.LOAD_MESSAGECLAIMSDETAILS_SUCCESS:
            return Object.assign({}, state, { messageclaimsdetailswidget: action.payload });

        case MessageboxActions.LOAD_MESSAGEINQUIRIESDETAILS_SUCCESS:
            return Object.assign({}, state, { messageinquiriesdetailswidget: action.payload });
       
        case MessageboxActions.LOAD_MESSAGEDRAFT_SUCCESS:
            return Object.assign({}, state, { messagedraftwidget: action.payload });

        case MessageboxActions.LOAD_MESSAGEIMPORTANT_SUCCESS:
            return Object.assign({}, state, { messageimportantwidget: action.payload });

        case MessageboxActions.LOAD_MESSAGEINQUIRIES_SUCCESS:
            return Object.assign({}, state, { messageinquirieswidget: action.payload });

        case MessageboxActions.LOAD_MESSAGETRASH_SUCCESS:
            return Object.assign({}, state, { messagetrashswidget: action.payload });

        case MessageboxActions.LOAD_MESSAGECLAIMS_SUCCESS:
            return Object.assign({}, state, { messageclaimswidget: action.payload });

        case MessageboxActions.LOAD_MESSAGESENT_SUCCESS:
            return Object.assign({}, state, { messagesentwidget: action.payload });

        case MessageboxActions.LOAD_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, { messagecustomer: action.payload });

        case MessageboxActions.LOAD_BULKACTION_SUCCESS:
            return Object.assign({}, state, { bulkAction: action.payload });

        case MessageboxActions.SEND_MESSAGE_SUCCESS:
            return Object.assign({}, state, { sendMessageSuccess: action.payload });

        case MessageboxActions.SEND_DRAFT_MESSAGE_SUCCESS:
            return Object.assign({}, state, { sendDraftMessageSuccess: action.payload });

        case MessageboxActions.DELETE_MESSAGE_SUCCESS:
            return Object.assign({}, state, { deleteMessageSuccess: action.payload });

        case MessageboxActions.DELETE_MAIL_SUCCESS:
            return Object.assign({}, state, { deleteMailSuccess: action.payload });

        case MessageboxActions.LOAD_USERMAILADDRESS_SUCCESS:
            return Object.assign({}, state, { getUserMailAddressSuccess: action.payload });

        case MessageboxActions.LOAD_MESSAGEREAD_SUCCESS:
            return Object.assign({}, state, { messagereadwidget: action.payload });
    
        case MessageboxActions.LOAD_EMAILUNREAD_SUCCESS:
            return Object.assign({}, state, { unreadMessage: action.payload }); 

        case MessageboxActions.LOAD_CLAIMSUNREAD_SUCCESS:
            return Object.assign({}, state, { claimsunreadMessage: action.payload });  

        case MessageboxActions.LOAD_INQURIESUNREAD_SUCCESS:
            return Object.assign({}, state, { inquriesunreadMessage: action.payload });            

        default: {
            return state;
        }
    }
}
