import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { DashboardOrderComponent } from './dashboard-order.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommonFilterModule,
    PageLoaderModule,
    TranslateModule
  ],
  declarations: [DashboardOrderComponent],
  exports: [DashboardOrderComponent]
})

export class DashboardOrderModule { }
