import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';

interface ModalActionType {
  data: any;
  action: string;
  type: string;
}
@Component({
  selector: 'rewardkart-admin-delivery-note-modal',
  templateUrl: './delivery-note-modal.component.html',
  styleUrls: ['./delivery-note-modal.component.scss']
})
export class DeliveryNoteModalComponent implements OnInit {
  header: any;
  type = '';
  isSubOrderItem = '';
  newDeliveryNoteItems: any[] = [];
  newDeliveryNoteOrderItemIds = [];
  newDeliveryNoteOrderItemQuantities = [];
  newSelectedDeliveryNoteOrderItems = [];
  deliveryNoteOrderItemQuantities: any;
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  deliveryNoteOrderItemIds: any;
  deliveryNoteItems: any;
  deliveryNotes: any;
  showSoButton: Boolean = false;
  constructor(public modalRef: MDBModalRef, public toastService: ToastService) {
  }

  ngOnInit() {
    console.log("deliveryNotes : ", this.deliveryNotes);
    if (this.isSubOrderItem) {
      setTimeout(() => {
        document.getElementById("myModalLabel").click();
      }, 100);
      this.newDeliveryNoteItems.forEach(item => {
        this.onCheckboxClick(false, item.OrderItemID);
      });
    }
  }

  modalConfirmed(data) {

    switch (this.type) {
      case 'newDeliveryNote':
        if (this.newSelectedDeliveryNoteOrderItems.length === 0) {
          this.toastService.error('Please select atleast one order');
          return;
        }
        this.modalAction.next({ data: this.newSelectedDeliveryNoteOrderItems, action: 'update', type: this.type });
        break;
    }
  }
  modalCanceled() {
    this.modalRef.hide();
  }

  ngOnDestroy() {
    this.modalAction.unsubscribe();
  }

  orderItemQuantityChange(orderItemID) {
    this.newSelectedDeliveryNoteOrderItems.map(item => {
      if (item.OrderItemID == orderItemID) {
        item.Quantity = this.newDeliveryNoteOrderItemQuantities[orderItemID];
      }
    });
  }

  onCheckboxClick(eventHandler, orderItemID?) {
    if (!eventHandler) {
      this.newSelectedDeliveryNoteOrderItems.push({
        "OrderItemID": orderItemID,
        "Quantity": this.newDeliveryNoteOrderItemQuantities[orderItemID]
      });
    } else {
      this.newSelectedDeliveryNoteOrderItems.map((item, index) => {
        if (item.OrderItemID === orderItemID) {
          this.newSelectedDeliveryNoteOrderItems.splice(index, 1);
        }
      });
    }
  }

  downloadDeliveryNote(invoiceID, orderID) {
    this.modalAction.next({ data: { invoiceID: invoiceID, orderID: orderID }, action: 'download', type: this.type });
  }

  deleteDeliveryNote(invoiceID, orderID) {
    if (confirm('Are you sure you want to delete this delivery note?'))
      this.modalAction.next({ data: { invoiceID: invoiceID, orderID: orderID }, action: 'delete', type: this.type });
  }

  createSoOrder(deliverynotenumber, customerID) {
    // tslint:disable-next-line:max-line-length
    this.modalAction.next({ data: { deliverynotenumber: deliverynotenumber, customerID: customerID }, action: 'createso', type: this.type });
  }

  createIsOrder(deliverynotenumber, customerID) {
    // tslint:disable-next-line:max-line-length
    this.modalAction.next({ data: { deliverynotenumber: deliverynotenumber, customerID: customerID }, action: 'createis', type: this.type });
  }
}
