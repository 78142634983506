import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { UserHttpService } from '../state/services/user.http.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../../shared/common-services/api.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'rewardkart-admin-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent implements OnInit, OnChanges {
  @ViewChild('bulkCreationModal', { static: true }) bulkCreationModal: ModalDirective;
  header: any;
  userdatacount: any;
  userList: any = [];
  questions: any[];
  submitted = false;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  showUserEditpage = false;
  showUserBudgetlist = false;
  actions: any = ['view', 'edit', 'delete'];
  filterData: any = {};
  paginationData: any = {};
  collection = [];
  activePage: number = 1;
  rowactivePage: number = 1;
  enablepagination: boolean = true;
  resetStatus: boolean = false;
  userName: any;
  commonRemoveFilter: any;
  userBudgetList: any = [];
  columns: ColumnSetting[] = [];
  filterFields: any = [];
  roleId: any;
  optionsSelectCustomers: Array<any>;
  optionsSelectRoles: Array<any>;
  optionsSelectCountries: Array<any>;
  userSearchForm: FormGroup;
  searchCriteria: any = {};
  isPendingUser = false;
  passFilterresult: any = [];
  tableName: string = 'pendinguserlist';
  showLoader: Boolean = false;
  addUserLabel;
  webshopDetail: any;
  isParentCustomer: Boolean = false;
  showImageError: Boolean = false;
  tableEvent: any;
  public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public fileExtension = '.xlsx';
  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private userHttpService: UserHttpService, private toast: ToastService,
    private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
        this.roleId = loginData.RoleID;
      }
    }
    this.addUserLabel = this.translate.instant('USERADDEDIT.ADD_USER');
    this.columns = [
      {
        primaryKey: 'Name',
        header: this.translate.instant('USERLIST.NAME')
      },
      {
        primaryKey: 'UserName',
        header: this.translate.instant('USERADDEDIT.USERNAME')
      },
      // {
      //   primaryKey: 'CustomerName',
      //   header: 'Webshop'
      // },
      {
        primaryKey: 'Roles',
        header: this.translate.instant('USERLIST.ROLE'),
        primaryKeyItem: 'RoleName',
        format: 'array'
      },

      {
        primaryKey: 'ProductRoles',
        header: this.translate.instant('USERLIST.PRODUCT_ROLES'),
        primaryKeyItem: '',
        format: 'arrayobject'
      },
      {
        primaryKey: 'ImageURL',
        header: this.translate.instant('USERLIST.PROFILE_IMAGES'),
        format: 'icon'
      },
      {
        primaryKey: 'CompanyName',
        header: this.translate.instant('USERADDEDIT.COMPANY_NAME')
      },
      {
        primaryKey: 'CustomerName',
        header: 'Webshop',
        format: 'webshopname'
      },
      // {
      //   primaryKey: 'ImageURL',
      //   header: 'Profile Image',
      //   format: 'userimage'
      // },
      {
        primaryKey: 'Email',
        header: this.translate.instant('USERADDEDIT.EMAIL')
      },
      {
        primaryKey: 'PhoneNumber',
        header: this.translate.instant('USERADDEDIT.PHONE_NUMBER')
      },
      /* {
         primaryKey: 'EANNumber',
         header: this.translate.instant('USERLIST.EAN_NO')
       },*/
      {
        primaryKey: 'VAT',
        header: this.translate.instant('USERLIST.VAT_NUMBER')
      },
      {
        primaryKey: 'action',
        format: 'useraction',
      }
    ];
  }

  ngOnInit() {
    const loginData = this.apiService.getLoginData();
    this.userName = loginData.UserName;
    const searchkey = sessionStorage.getItem('searchkey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      if (sessionStorage.getItem('userEditRedirect') !== null && sessionStorage.getItem('userEditRedirect') !== undefined) {
        this.activePage = this.searchCriteria.activePage;
        sessionStorage.removeItem('userEditRedirect');
      } else {
        this.activePage = 1;
        this.searchCriteria.activePage = 1;
      }
      this.filterData = this.searchCriteria;
      if (this.filterData.pendinguserlist) {
        this.actions = ['approve', 'view', 'edit', 'delete'];
      } else {
        this.actions = ['view', 'edit', 'delete'];
      }
      sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
    }
    this.header = this.translate.instant('USERLIST.USER_DETAILS'); // Multilingual to be implemented
    //  this.isPendingUser = (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false;
    this.onLoadFunctions();
    this.createForms();
    this.getCustomers();
  }

  ngOnChanges() {

    this.getCustomers()
  }


  getCustomers() {

    if (sessionStorage.getItem('parentCustomerList') !== undefined && sessionStorage.getItem('parentCustomerList') !== null
      && sessionStorage.getItem('parentCustomerList') !== '') {
      const customerList = JSON.parse(sessionStorage.getItem('parentCustomerList'));
      this.optionsSelectCustomers = [];
      this.optionsSelectCustomers.push({ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' });
      customerList.forEach(option => {
        this.optionsSelectCustomers.push({ label: option.Name, value: option.CustomerID })
      })
      const searchResult = sessionStorage.getItem('searchresult')
      if (searchResult !== null) {
        this.filterFields = JSON.parse(sessionStorage.getItem('searchresult'))

        /** To Prefill Webshop dropdown value(based on Login) **/
        if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
          const getIndex = this.filterFields.findIndex(ind => ind.key === 'selectwebshop');
          this.filterFields[getIndex].data = this.optionsSelectCustomers;
          sessionStorage.setItem('searchresult', JSON.stringify(this.filterFields));
        }

      }
      this.getRoles();

    } else {
      this.userService.getAllCustomers().subscribe(
        data => {
          if (data) {
            this.optionsSelectCustomers = [];
            this.optionsSelectCustomers.push({ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' });
            data.forEach(element => {
              const customerVal = {
                value: element.CustomerID,
                label: element.Name,
              };
              this.optionsSelectCustomers.push(customerVal);
            });
            this.getRoles();
          }
        });
    }
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(true);
  }

  getRoles() {

    this.userService.getAllRoles().subscribe(
      data => {
        if (data) {
          this.optionsSelectRoles = [];
          this.optionsSelectRoles.push({ label: this.translate.instant('USERLIST.SELECT_ROLE'), value: '' });
          data.forEach(element => {
            const countryVal = {
              value: element.RoleID,
              label: element.RoleName,
            };
            this.optionsSelectRoles.push(countryVal);
          });

          this.getCountries();
        }
      });


  }

  onLoadFunctions() {
    // this.userService.loadCountries();
    this.userService.loadCustomers();
    this.userService.loadRoles();
    this.userService.loadSearchFormFields();
    this.onLoadUsers();
  }

  onLoadUsers() {

    const searchkey = sessionStorage.getItem('searchkey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.getUsersList(JSON.parse(searchkey));
    }
    else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        roleId: '',
        name: '',
        email: '',
        companyName: '',
        country: '',
        pendinguserlist: (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false,
        username: '',
        pageIndex: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
      // this.userService.loadUsers(this.searchCriteria);
      this.getUsersList(this.searchCriteria);
    }

  }

  getSelectData(data) {
console.log(data);
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        customerId: data.selectwebshop !== undefined && data.selectwebshop.selectMethod === 'selectwebshop' ? data.selectwebshop.value : this.webshopDetail.CustomerID,
        roleId: data.selectrole !== undefined && data.selectrole.selectMethod === 'selectrole' ? data.selectrole.value : '',
        name: data.name !== undefined && data.name.selectMethod === 'name' ? data.name.value : '',
        email: data.email !== undefined && data.email.selectMethod === 'email' ? data.email.value : '',
        companyName: data.companyname !== undefined && data.companyname.selectMethod === 'companyname' ? data.companyname.value : '',
        country: data.selectcountry !== undefined && data.selectcountry.selectMethod === 'selectcountry' ? data.selectcountry.value : '',
        pendinguserlist: data.checkbox !== undefined && data.checkbox.selectMethod === 'checkbox' ? data.checkbox.value : false,
        username: data.username !== undefined && data.username.selectMethod === 'username' ? data.username.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };

      if (data.pendinguserlist.value && data.checkbox === undefined) {
        this.searchCriteria.pendinguserlist = true;
      }
    }
    else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        roleId: '',
        name: '',
        email: '',
        companyName: '',
        country: '',
        pendinguserlist: false,
        username: '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }

    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    this.isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
        this.isParentCustomer = false;
      }
    }

    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'name') {
        element.selected = this.searchCriteria.name
      }
      else if (element.filterkey === "username") {
        element.selected = this.searchCriteria.username
      }
      else if (element.filterkey === "email") {
        element.selected = this.searchCriteria.email
      }
      else if (element.key === "companyname") {
        element.selected = this.searchCriteria.companyName
      }
      else if (element.key === "pendinguserlist") {
        element.selected = this.searchCriteria.pendinguserlist;
        element.data = this.searchCriteria.pendinguserlist;
      }
      else if (element.key === "selectrole") {
        element.selected = this.searchCriteria.roleId
      }
      else if (element.key === "selectcountry") {
        element.selected = this.searchCriteria.country
      }
      else if (element.key === "selectwebshop" && this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
        element.selected = this.searchCriteria.customerId
      }
    });

  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    if (this.filterData.pendinguserlist) {
      this.actions = ['approve', 'view', 'edit', 'delete'];
    } else {
      this.actions = ['view', 'edit', 'delete'];
    }
    this.getUsersList(this.filterData);
    this.getCountries();
    sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchresult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'pendinguserlist') {
        element.selected = false;
        element.data = false;
      }
    });
    this.resetStatus = true;
    this.getSelectData(undefined);
    sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchresult', JSON.stringify(this.filterFields));
    this.onLoadFunctions();
  }

  paginationNum(event) {
    this.activePage = event;

    const searchkey = sessionStorage.getItem('searchkey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    this.searchCriteria.activePage = event;
    sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
    this.perPagedata = this.searchCriteria.pageIndex;
    // this.getSubmit(true);
    this.getUsersList(this.searchCriteria);
  }

  getCountries() {
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }

    let customerId = 0;
    if (this.searchCriteria !== null && this.searchCriteria !== undefined) {
      // tslint:disable-next-line:max-line-length
      if (this.searchCriteria.customerId !== null && this.searchCriteria.customerId !== undefined && this.searchCriteria.customerId !== '') {
        customerId = this.searchCriteria.customerId;
      }
    }
    this.userHttpService.getCustomerCountries(customerId).subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCountries = [];
          this.optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });

          const searchResult = sessionStorage.getItem('searchresult');
          if (searchResult !== null) {
            this.filterFields = JSON.parse(sessionStorage.getItem('searchresult'))
            /** Display Webshop Name Column Based on Parent/Child Shop */

            /** To Prefill Webshop dropdown value(based on Login) */
            // tslint:disable-next-line:max-line-length
            if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
              const getIndex = this.filterFields.findIndex(ind => ind.key === 'selectwebshop');
              if (getIndex >= 0) {
                if (this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
                  this.filterFields[getIndex].selected = this.searchCriteria.customerId;
                } else {
                  this.filterFields[getIndex].selected = '';
                }
              }
            }
          }
          else {
            const loginData = this.apiService.getLoginData();
            if (loginData.Roles[0].RoleName === 'Super Admin') {
              this.filterFields = [
                {
                  label: this.translate.instant('USERLIST.FILTER'),
                  class: 'col-md-12',
                  key: 'filterHeading',
                },
                {
                  label: 'Select Webshop',
                  type: 'select',
                  data: this.optionsSelectCustomers,
                  class: 'col-md-12 no-icon',
                  key: 'selectwebshop',
                  required: false,
                  filterkey: 'selectwebshop',
                  selected: (this.webshopDetail.IsWebshopDropdown && isParentCustomer) ? this.searchCriteria.customerId : ''
                },
                {
                  label: this.translate.instant('USERLIST.SELECT_ROLE'),
                  type: 'select',
                  data: this.optionsSelectRoles,
                  class: 'col-md-12 no-icon',
                  key: 'selectrole',
                  required: false,
                  filterkey: 'selectrole',
                  selected: this.searchCriteria.roleId
                },
                {
                  label: this.translate.instant('USERLIST.SELECT_COUNTRY'),
                  type: 'select',
                  data: this.optionsSelectCountries,
                  class: 'col-md-12 no-icon',
                  key: 'selectcountry',
                  required: false,
                  filterkey: 'selectcountry',
                  selected: this.searchCriteria.country
                },
                {
                  label: this.translate.instant('USERADDEDIT.NAME'),
                  type: 'text',
                  class: 'col-md-12',
                  required: false,
                  key: 'name',
                  id: 'name',
                  filterkey: 'name',
                  selected: this.searchCriteria.name
                },
                {
                  label: this.translate.instant('USERADDEDIT.USERNAME'),
                  type: 'text',
                  class: 'col-md-12',
                  required: false,
                  key: 'username',
                  id: 'username',
                  filterkey: 'username',
                  selected: this.searchCriteria.username
                },
                {
                  label: this.translate.instant('USERADDEDIT.EMAIL'),
                  type: 'email',
                  class: 'col-md-12',
                  required: false,
                  key: 'email',
                  id: 'email',
                  filterkey: 'email',
                  selected: this.searchCriteria.email
                },
                {
                  label: this.translate.instant('USERADDEDIT.COMPANY_NAME'),
                  type: 'text',
                  class: 'col-md-12',
                  required: false,
                  key: 'companyname',
                  id: 'companyname',
                  filterkey: 'companyname',
                  selected: this.searchCriteria.companyName
                },
                {
                  label: this.translate.instant('USERLIST.PENDING_USER_LIST'),
                  type: 'checkbox',
                  data: (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false,
                  class: 'col-md-12 d-flex justify-content-start',
                  required: false,
                  key: 'pendinguserlist',
                  filterkey: 'pendinguserlist',
                  selected: (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false
                },
                {
                  label: this.translate.instant('USERLIST.CLEAR'),
                  type: 'button',
                  class: 'col-sm-6 text-left mt-3 margin-width ',
                  key: 'clear',
                },
                {
                  label: this.translate.instant('USERLIST.SEARCH'),
                  type: 'button',
                  class: 'col-sm-6 text-left mt-3 margin-width',
                  key: 'button',
                }
              ];
            } else {
              this.filterFields = [
                {
                  label: this.translate.instant('USERLIST.FILTER'),
                  class: 'col-md-12',
                  key: 'filterHeading',
                },
                {
                  label: this.translate.instant('USERADDEDIT.NAME'),
                  type: 'text',
                  class: 'col-md-12',
                  required: false,
                  key: 'name',
                  id: 'name',
                  filterkey: 'name',
                  selected: this.searchCriteria.name
                },
                {
                  label: this.translate.instant('USERADDEDIT.USERNAME'),
                  type: 'text',
                  class: 'col-md-12',
                  required: false,
                  key: 'username',
                  id: 'username',
                  filterkey: 'username',
                  selected: this.searchCriteria.username
                },
                {
                  label: this.translate.instant('USERADDEDIT.EMAIL'),
                  type: 'email',
                  class: 'col-md-12',
                  required: false,
                  key: 'email',
                  id: 'email',
                  filterkey: 'email',
                  selected: this.searchCriteria.email
                },
                {
                  label: this.translate.instant('USERLIST.PENDING_USER_LIST'),
                  type: 'checkbox',
                  data: (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false,
                  class: 'col-md-12 d-flex justify-content-start',
                  required: false,
                  key: 'pendinguserlist',
                  filterkey: 'pendinguserlist',
                  selected: (window.history.state.data !== undefined) ? window.history.state.data.isPendingUser : false
                },
                {
                  label: this.translate.instant('USERLIST.CLEAR'),
                  type: 'button',
                  class: 'col-sm-6 text-left mt-3 margin-width ',
                  key: 'clear',
                },
                {
                  label: this.translate.instant('USERLIST.SEARCH'),
                  type: 'button',
                  class: 'col-sm-6 text-left mt-3 margin-width',
                  key: 'button',
                }
              ];
            }



          }

          sessionStorage.setItem('searchresult', JSON.stringify(this.filterFields))

        }

      });
  }

  createForms() {
    this.userSearchForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      roleId: [''],
      name: [''],
      email: [''],
      companyName: [''],
      country: [''],
    });
  }

  getFilterResult(filterresult) {
    //this.passFilterresult = filterresult;
  }

  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchkey = sessionStorage.getItem('searchkey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    if (val !== undefined) {
      // this.searchCriteria[val.key] = '';
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = '';
        this.getCountries();
      }
      else if (val.key === "selectrole") {
        this.searchCriteria.roleId = '';
      }
      else if (val.key === "selectcountry") {
        this.searchCriteria.country = '';
      }
      else if (val.key === "name") {
        this.searchCriteria.name = '';
      }
      else if (val.key === "username") {
        this.searchCriteria.username = '';
      }
      else if (val.key === "email") {
        this.searchCriteria.email = '';
      }
      else if (val.key === "companyname") {
        this.searchCriteria.companyName = '';
      }
      else if (val.key === "pendinguserlist") {
        this.searchCriteria.pendinguserlist = false;
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
        // if (element.filterkey === 'pendinguserlist') {
        //   element.selected = false;
        //   element.data = false;
        // }
      });

      // this.getSubmit('');
      sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchresult', JSON.stringify(this.filterFields));
      this.getUsersList(this.filterData);

    }
  }

  // onSubmit() {
  //   const searchCriteria = {
  //     customerId: this.userSearchForm.value.customerId !== undefined ? this.userSearchForm.value.customerId : '',
  //     roleId: this.userSearchForm.value.roleId !== undefined ? this.userSearchForm.value.roleId : '',
  //     name: '',
  //     email: '',
  //     companyName: '',
  //     country: this.userSearchForm.value.country !== undefined ? this.userSearchForm.value.country : ''
  //   };
  //   this.userService.loadUsers(searchCriteria);
  // }

  resetForm() {
    this.userSearchForm.reset();
  }

  getUsersList(data) {
    this.showLoader = true;
    if (!this.webshopDetail.IsWebshopDropdown) {
      data.customerId = this.webshopDetail.CustomerID;
    }
    if (data.customerId === '') {
      data.customerId = '';
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (this.webshopDetail.ParentCustomerID !== null) {
          data.customerId = this.webshopDetail.CustomerID;
        }
      }
    }
    this.userHttpService.getUsersList(data).subscribe((data: any) => {
      if (data) {
        this.userdatacount = data.TotalItemsCount;
        this.userList = data;
        this.userList = this.userList.ItemsCollection;
        this.userList.forEach(element => {
          element.EANNumber = (element.EANNumber !== '') ? element.EANNumber : '-';
        });

      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      });
  }

  editBudgetList(budgetList) {
    if (budgetList) {
      this.showUserBudgetlist = true;
      this.userHttpService.getUsersBudgetList(budgetList.UserID).subscribe((data: any) => {
        if (data) {
          data.companyName = budgetList.DomainName;
          this.userBudgetList = data;
          this.router.navigate(['/admin-user-budgetlist/' + budgetList.UserID], { state: { data }, replaceUrl: false });
        }
      })

      // 
      // try {
      //   this.userService.selectedUser(budgetList);
      //   this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
      //     if (data) {
      //       this.router.navigate(['/admin-user-budgetlist'], { state: { data }, replaceUrl: false });
      //     }
      //   });
      // } catch (error) {
      // }
    }
  }

  addUser(event: boolean) {
    this.submitted = event;
  }

  viewUser(event: boolean) {
    this.submitted = event;
  }

  approveUser(userData) {
    const updateUserData = {
      "Salutation": userData.salutation,
      "Name": userData.Name,
      "FirstName": userData.FirstName,
      "LastName": userData.LastName,
      "UserName": userData.UserName,
      "JobPosition": userData.jobPosition,
      "Password": userData.Password,
      "CompanyName": userData.CompanyName,
      "CustomerID": userData.CustomerID,
      "RoleCode": userData.RoleCode,
      "LanguageID": userData.LanguageID,
      "CurrencyID": userData.CurrencyID,
      "PhoneNumber": userData.PhoneNumber,
      "Email": userData.Email,
      "EmailForInvoices": userData.EmailForInvoices,
      "Address": userData.Address,
      "Address2": userData.Address2,
      "Address3": userData.Address3,
      "City": userData.City,
      "PostalCode": userData.PostalCode,
      "Country": 'IN',
      "DeliveryAddress": userData.DeliveryAddress,
      "DeliveryAddress2": userData.DeliveryAddress2,
      "DeliveryAddress3": userData.DeliveryAddress3,
      "DeliveryCity": userData.DeliveryCity,
      "DeliveryPostalCode": userData.DeliveryPostalCode,
      "DeliveryCountry": userData.DeliveryCountry,
      "DeliveryEmail": userData.DeliveryEmail,
      "Department": userData.Department,
      "DeliveryContactPerson": userData.DeliveryContactPerson,
      "DeliveryInstructions": userData.DeliveryInstructions,
      "DeliveryCompanyName": userData.DeliveryCompanyName,
      "isSameAsInvoiceAddress": userData.isSameAsInvoiceAddress,
      "Comment": userData.Comment,
      "ImageName": userData.ImageName,
      "PanImageName": userData.PanImageName,
      "GSTImageName": userData.GSTImageName,
      "ROCImageName": userData.ROCImageName,
      "PreviousRoleCode": "user",
      "IsRequireUsersApproved": true,
      "UserType": "U",
      "FirstLogin": true,
      "VAT": userData.VAT,
      "EANNumber": '-',
      "DiscountPercentage": userData.DiscountPercentage,
      "PaymentByInvoice": userData.PaymentByInvoice,
      "PaymentByCard": userData.PaymentByCard,
      "isDeleted": false,
      "DeliveryPhoneNumber": userData.DeliveryPhoneNumber,
      "KYCStatus": userData.KYCStatus,
      "IsParentShop": this.isParentCustomer
    }
    let showToast = true;
    const updateData = {
      userId: userData.UserID,
      updateData: updateUserData
    }
    this.userHttpService.updateUser(updateData).subscribe((data: any) => {
      if (data) {
        if (showToast) {
          this.toast.success(this.translate.instant('USERADDEDIT.USER_UPDATE_SUCCESS'));
        }
        showToast = false;
        this.getSubmit(true);
      }
    },
      err => {
        this.showLoader = false;
        let errMsg = '';
        this.showLoader = false;
        for (var key in err.error.ModelState) {
          errMsg += err.error.ModelState[key] + ' ';
        }
        this.toast.error(errMsg);
      })
  }

  editUser(userData: boolean) {
    if (userData) {
      const searchkey = sessionStorage.getItem('searchkey');
      if (searchkey !== null && searchkey !== '[object Object]') {
        this.searchCriteria = JSON.parse(searchkey);
        this.perPagedata = 10;
        this.searchCriteria.pageIndex = 10;
        this.resetStatus = true;
        sessionStorage.setItem('searchkey', JSON.stringify(this.searchCriteria));
      }
      this.showUserEditpage = true;
      try {
        this.userService.selectedUser(userData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            data.dashboardRedirectStatus = false;
            this.router.navigate(['/admin-user-edit'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }

  }



  deleteUser(event) {
    //this.submitted = event;
    this.userService.loadUserdelete(event.UserID);
    this.userService.deleteUser().subscribe((data: any) => {
      if (data !== null) {
        setTimeout(() => {
          this.onLoadUsers();
        }, 300);
      }
    });
  }

  addBulkUser() {
    this.bulkCreationModal.show();
  }

  addNewUser() {
    this.router.navigate(['/admin-user-add'], { replaceUrl: false });
  }
  addNewUserBudget() {
    this.router.navigate(['/admin-userbudget-add'], { replaceUrl: false });
  }

  downloadAllUser(){
    this.showLoader = true;
    this.userHttpService.downloadAllUser().subscribe((data:any)=>{
      this.showLoader = false;
      if(data && data.ItemsCollection){
        let exportData = data.ItemsCollection;
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, 'all-user');
      }
    });
  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  refreshTable(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.onLoadUsers();
    }
  }

  selectedTableRow(event: boolean) {
    if (event) {
    }
  }

  onSelectFile(event) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg.toLowerCase() !== '.xlsx' && getImg.toLowerCase() !== '.xls' && getImg.toLowerCase() !== '.csv')) {
      this.showImageError = true;
      return;
    } else {
      this.showImageError = false;
    }

    if (event.target.files && event.target.files[0]) {
      let httpHeaders = new HttpHeaders();
      let httpParams = new HttpParams();
      httpHeaders.set('Content-Type', null);
      httpHeaders.set('Accept', "multipart/form-data");
      const formData = new FormData();
      formData.append('CustomerId', this.webshopDetail.CustomerID);
      formData.append('Domain', this.webshopDetail.DomainName);
      formData.append('CampaignID', '0');
      formData.append('File', event.target.files[0]);
      event.target.value = '';
      this.userHttpService.loaduploadFile(formData).subscribe((data: any) => {
        if (data.creationError) {
          let l = data.creationError.length;
          for (let i = 0; i < l; i++) {
            this.toast.error(data.creationError[i].UserName + ' ' + data.creationError[i].Error);
          }
          //this.toast.error('Please provide data in valid format');

        } else {
          this.bulkCreationModal.hide();
          this.toast.success('File imported successfully')
        }
      }, _error => {
        this.toast.error('Please provide data in valid format');
      });

    }
  }

  ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }

}

