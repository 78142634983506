import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-product-roles-add',
  templateUrl: './product-roles-add.component.html',
  styleUrls: ['./product-roles-add.component.scss']
})
export class ProductRolesAddComponent implements OnInit {
  addRole: FormGroup;
  showLoader: Boolean = false;
  @Input() isOpenCreateRole: any;
  @Input() getEditData: any;
  @Output() addRoleStatus = new EventEmitter<any>();
  webshopDetail: any;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.addRole = this.formBuilder.group({
      roleName: ['', Validators.required]
    });

  }

  ngOnChanges() {
    if (this.isOpenCreateRole) {
      this.addRole.reset();
    }
    this.prefillData();
  }

  prefillData() {
    if (this.getEditData) {
      this.addRole.patchValue({
        roleName: this.getEditData.Name
      })
    }
    else {
      if (this.addRole) {
        this.addRole.reset();
      }
    }
  }

  addRoleSubmit() {
    this.showLoader = true;
    const request = {
      "Name": this.addRole.value.roleName,
      "CustomerID": this.webshopDetail.CustomerID
    }
    this.webshopHttpService.addRole(request).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.addRoleStatus.emit(true);
        if (data.Message) {
          this.toast.error(data.Message);
        }
        else {
          this.toast.success('Product role created successfully');
          this.addRole.controls.roleName.setValue('')
        }
      }
      else {
        this.addRoleStatus.emit(false);
      }
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
        this.addRoleStatus.emit(false);
      })
  }

  updateRole() {
    const request = {
      "ProductRoleID": this.getEditData.ProductRoleID,
      "Name": this.addRole.value.roleName,
      "CustomerID": this.webshopDetail.CustomerID
    }
    this.webshopHttpService.updateRole(request).subscribe((data: any) => {
      console.log(data);
      if (data) {
        this.addRoleStatus.emit(true);
        if (data.Message) {
          this.toast.error(data.Message);
        }
        else {
          this.toast.success('Product role updated successfully');
        }
      }
    },
      (error) => {
        this.toast.error(error.error.Message);
      })
  }

}