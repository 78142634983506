import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { BrandActions } from '../actions/index';
import { State } from '../reducers/index';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import { BrandHttpService } from '../services/brand.http.service';

@Injectable()
export class BrandEffects {

    @Effect()
    getBrandList$: Observable<Action> = this.actions$.pipe(
        ofType(BrandActions.LOAD_BRANDS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.brandHttpService.getBrandList(payload.payload).map((data) => {
                return new BrandActions.LoadBrandSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new BrandActions.LoadBrandFail(error));
        })
    );

    @Effect()
    uploadFile$: Observable<Action> = this.actions$.pipe(
        ofType(BrandActions.FILE_UPLOAD),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.brandHttpService.uploadFile(payload.payload, payload.params, payload.header).map((data) => {
                return new BrandActions.LoadFileUploadSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new BrandActions.LoadFileUploadFail(error));
        })
    );

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private brandHttpService: BrandHttpService) { }

}