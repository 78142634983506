import { IMyOptions } from 'ng-uikit-pro-standard';

export abstract class ReportAbstract {
  abstract reportHttpService: any;
  abstract excludeKeyInUrlParam: Array<string>;
  reportData = { ReportFileURL: null };
  noProductFoundText = 'No orders found.';
  showLoader = false;
  filterFields = [];
  passFilterresult = [];

  public datePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
  };

  abstract buildReportURL(urlParam, formData): string;

  handleFilterResult(filterResult) {
    this.passFilterresult = filterResult;
    let parameter = '';
    this.passFilterresult.forEach(field => {
      const shouldSkipParam = this.excludeKeyInUrlParam.some(
        keyToExclude => keyToExclude === field.key
      );
      if (!shouldSkipParam) {
        const formatedParam = `${field.key}=${field.value}`;
        parameter = parameter ? `${parameter}&${formatedParam}` : formatedParam;
      }
    });

    if (parameter) {
      this.getReport(this.buildReportURL(parameter, this.passFilterresult));
    }
  }

  handleResetForm(event: any) { }

  getRemovedFilter(event: any) { }

  handleSubmit(formData: any) { }

  getReport(url) {
    if (url) {
      this.showLoader = true;
      this.reportHttpService.getReport(url).subscribe(reportResponse => {
        this.reportData = reportResponse;
        this.showLoader = false;
      }, error => {
        this.reportData.ReportFileURL = this.noProductFoundText;
        this.showLoader = false;
      });
    }
  }
}
