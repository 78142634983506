import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ProductCardModule } from 'src/app/product/product-card/product-card.module';
import { TranslateModule } from '@ngx-translate/core';
import { ViewOrderitemsModalComponent } from './view-orderitems-modal.component';

@NgModule({
    declarations: [
        ViewOrderitemsModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        CommonFilterModule,
        ProductCardModule,
        TranslateModule
    ],
    providers: [],
    exports: [ViewOrderitemsModalComponent]
})

export class viewOrderitemsModalModule { }
