import { LoaderModule } from './loader/loader.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTableModule } from './link-table/link-table.module';
import { LinkTableBorderModule } from './link-table-border/link-table-border.module';
import { HeaderContentModule } from './header-content/header-content.module';
import { PageControlModule } from './page-control/page-control.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModulePro, MDBBootstrapModule, WavesModule, ButtonsModule, DatepickerModule } from 'ng-uikit-pro-standard';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { LinkCsvTableModule } from './link-csv-table/link-csv-table.module';
import { LinkDownloadIconModule } from './link-download-icon/link-download-icon.module';
import { LinkEditableTableModule } from './link-editable-table/link-editable-table.module';
import { LinkPaginationModule } from './link-pagination/link-pagination.module';
import { ShortdatePipe } from './pipe/shortdate.pipe';
import { LinkDragAndDropTableModule } from './link-draganddrop-table/link-draganddrop-table.module';
import { OrderBy } from './pipe/order-by.pipe'
import { ScrollDirective } from './custom-directive/scroll-directive';
@NgModule({
  imports: [
    CommonModule,
    WavesModule,
    ButtonsModule,
    LinkTableModule,
    LinkTableBorderModule,
    HeaderContentModule,
    FormsModule,
    LoaderModule,
    TooltipModule,
    DatepickerModule,
    ReactiveFormsModule,
    LinkCsvTableModule,
    LinkDownloadIconModule
  ],
  declarations: [ImageUploaderComponent, ShortdatePipe, OrderBy, ScrollDirective],
  exports: [
    MDBBootstrapModule,
    MDBBootstrapModulePro,
    LinkTableModule,
    LinkTableBorderModule,
    HeaderContentModule,
    PageControlModule,
    LoaderModule,
    TooltipModule,
    DatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ImageUploaderComponent,
    LinkCsvTableModule,
    LinkDownloadIconModule,
    LinkEditableTableModule,
    LinkPaginationModule,
    ShortdatePipe,
    LinkDragAndDropTableModule,
    OrderBy,
    ScrollDirective
  ]
})
export class SharedModule { }
