import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillboardEditComponent } from './billboard-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductSelectModule } from 'src/app/product/product-select/product-select.module';
import { ProductBrandModule } from 'src/app/product/product-brand/product-brand.module';

@NgModule({
  declarations: [BillboardEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlValidationModule,
    CommonFormModule,
    SharedModule,
    ProductSelectModule,
    ProductBrandModule
  ]
})
export class EditBillboardModule {}
