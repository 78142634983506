import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { MessageImportantComponent } from './message-important.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { MessageimportantdetailsModule } from './message-importantdetails/message-importantdetails.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    PageLoaderModule,
    CommonFilterModule,
    MessageimportantdetailsModule
  ],
  declarations: [MessageImportantComponent],
  exports: [MessageImportantComponent]
})

export class MessageimportantModule { }