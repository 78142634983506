import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { CampaignService } from '../state/services/campaign.service';
import { LoginService } from '../../login/state/services/login.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { CampaignHttpService } from '../state/services/campaign.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { error } from 'console';

@Component({
  selector: 'rewardkart-admin-brand-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
})
export class CampaignListComponent implements OnInit, OnChanges {
  header: any;
  campaignList: any = [];
  submitted = false;
  tableEvent: any;
  actions: any = ['edit'];
  isLoading = false;
  campaignCount: any;
  pageIndex: number = 1;
  searchCriteria: any = {};
  perPagedata: number = 10;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'CampaignId',
      header: 'ID',
      width: '40px'
    },
    {
      primaryKey: 'CampaignName',
      header: 'Name',
      width: '160px'
      // primaryKeyItem: 'PropertyValue',
      // format: 'array',
    },
    {
      primaryKey: 'CampaignDesc',
      header: 'Description',
      width: '320px'
    },
    {
      primaryKey: 'CampaignExpiredFrom',
      header: 'Start From',
      format: 'date',
      width: '100px'
    },
    {
      primaryKey: 'CampaignExpiredTo',
      header: 'Expiry Date',
      format: 'date',
      width: '100px'
    },
    {
      primaryKey: 'ProductCount',
      header: 'No Of Products',
      width: '120px'
    },
    {
      primaryKey: 'Aprovel',
      header: 'Status',
      width: '120px'
    },
    {
      primaryKey: 'action',
      format: 'action',
    },
  ];
  tableName = 'rewardPointsDetails';
  webshopDetail: any;
  isParentCustomer: boolean;
  userID: any;
  userDetails:any;
  isSaas:boolean = false;
  constructor(
    private campaignService: CampaignService,
    private loginService: LoginService,
    private router: Router,
    private linkTableService: LinkTableService,
    private campaignHttpService: CampaignHttpService,
    private toaster: ToastService,
    private http: CampaignHttpService
  ) {
    this.onLoadFunctions();
  }

  onLoadFunctions() {
    const webshop = sessionStorage.getItem('webshopDetail');

    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));

      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
      else {
        this.isParentCustomer = true;
      }
    }
    this.loginService.loginSuccess().subscribe(data => {
      if (data) {
        this.userID = data.UserID;
        //  this.loadProducts();
      }
    });
    this.isLoading = true;

    this.campaignService.loadCampaigns(this.webshopDetail.CustomerID);
  }

  ngOnInit() {
    this.isLoading = true;

    this.header = 'Campaign Details'; // Multilingual to be implemented
    this.getCampaignList();
    this.userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    if(this.userDetails && this.userDetails.ISSaas === "Y"){
      this.isSaas = true;
    }
  }

  getCampaignList() {
    this.isLoading = true;
    let param = 'pageSize=' + this.perPagedata + '&pageIndex=' + this.pageIndex + '&userId=' + this.userID;
    this.campaignHttpService.getCampaignList(param).subscribe((data: any) => {
      if (data) {
        this.isLoading = false;
        this.campaignList = data;
        this.campaignCount = data[0].TotalCount;
      }
    });
  }

  refreshTable(event: boolean) {
    this.submitted = event;
    if (this.submitted) {
      this.getCampaignList();
    }
  }

  addNewCampaign() {
    if(this.isSaas){
      let userdetails = JSON.parse(sessionStorage.getItem("userdetails"));
      if(userdetails){
        let req = {
          "UserId": userdetails.UserID
        }
        this.http.validateSaasCampaign(req).subscribe((data:any) => {
          if(data && data.IsSuccess){
            this.router.navigate(['/admin-campaign-add'], { replaceUrl: false });
          }
          else{
            this.toaster.error(data.Message);
            return;
          }
        },(error) => {
          // this.toaster.error(error.error.Message);
        }
      )
      }
    }
    else{
      this.router.navigate(['/admin-campaign-add'], { replaceUrl: false });
    }
    
  }

  addCampaign(event: boolean) {
    this.submitted = event;
  }

  viewCampaign(campaignData: any) { }

  editCampaign(event) {
    try {
      if (event) {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe((selectedRow) => {
          if (selectedRow) {
            const id = selectedRow.CampaignId;
            const icon = selectedRow.Icon;
            const status = selectedRow.Aprovel;
            this.router.navigate(['/admin-campaign-edit'], {
              state: { id, icon, status },
              replaceUrl: true,
            });
          }
        });
      }
    } catch (error) { }
    this.submitted = event;
  }

  deleteCampaign(event) {
    if (event) {
      this.campaignHttpService.deleteCampaign(event.CampaignId).subscribe(
        (data: any) => {
          if (data !== null) {
            this.toaster.success('Campaign Deleted Successfully');
            this.onLoadFunctions();
          }
        },
        (error) => {
          this.toaster.error('Unable to Delete Campaign');
        }
      );
    }
  }

  changeOrderCampaign(event) {
    const dragEvent = {
      CurrentSortOrder: event.previousIndex + 1,
      DesiredSortOrder: event.currentIndex + 1,
    };
    this.campaignHttpService.changeSortOrderOfCampaign(dragEvent).subscribe(
      (res: any) => {
        if (res) {
          this.toaster.success(res);
        }
      },
      (error) => {
        this.toaster.error(error);
      }
    );
  }

  paginationNum(event) {
    this.pageIndex = event;
    this.getCampaignList();
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.getCampaignList();
  }

  ngOnChanges(changes: SimpleChanges): void { }
}
