import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
let numberRegex = "/^\d{1,3}(\.\d{1,9})?$/";

@Component({
  selector: 'rewardkart-admin-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  @Input() webshopData: any;
  @Input() bankDetails: any;
  @Input() loadAgain: any;
  @Output() getShopPaymentData = new EventEmitter<any>();
  @Output() ShopPaymentDetailData = new EventEmitter<any>();
  webshopEditPayment: FormGroup;
  showLoader: Boolean = false;
  PaymentTypes: any = [];
  SelectedPaymentIds: any = [];
  selectedPaymentTypes: any = []
  CutomerSelectedPaymentId: any = [];
  deletePaymentIds: any = [];
  linkcommissionprofitData: any = [];
  webshopcostprofitData: any = [];
  parentwebshopcostprofitData: any = [];
  costLevelValues: any = []
  linkLevelValues: any = []
  parentLevelValues: any = []
  // PaymentOptions: any = []
  PaymentData: any = []
  /*** Static Level Types ***/
  ProfitLevels = [
    { 'Name': 'Level 1', 'LevelId': 1 },
    { 'Name': 'Level 2', 'LevelId': 2 },
    { 'Name': 'Level 3', 'LevelId': 3 },
    { 'Name': 'Level 4', 'LevelId': 4 },
    { 'Name': 'Level 5', 'LevelId': 5 },
    { 'Name': 'Level 6', 'LevelId': 6 },
    { 'Name': 'Level 7', 'LevelId': 7 },
    { 'Name': 'Level 8', 'LevelId': 8 },
    { 'Name': 'Level 9', 'LevelId': 9 },
    { 'Name': 'Level 10', 'LevelId': 10 }
  ];
  getActivePayment: boolean = false;
  webshopDetail: any;
  loginType: any = false;
  constructor(private webshopHttpService: WebshopHttpService, private formBuilder: FormBuilder, private translate: TranslateService, private toast: ToastService) { }

  ngOnInit() {
    const getWebshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.webshopDetail = getWebshopDetail;
    this.loginType = sessionStorage.getItem('isParentLoginAsChildShop');
    console.log(this.loginType);
    this.paymentType()
    this.webshopPaymentForm()
    this.getLinkCommission()
    this.getWebshopcostprofit()
    this.prefillData();
    this.getparentwebshopcostprofit();
  }
  ngOnChanges() {

    this.setPaymentOptions(this.webshopData.PaymentTypes);
    this.selectedPaymentTypes = this.webshopData.PaymentTypes;
    if (this.loadAgain) {
      this.paymentType();
      this.getWebshopcostprofit()
      this.prefillData();
      this.getparentwebshopcostprofit()
    }
    if (this.bankDetails) {
      this.prefillData();
    }
  }
  prefillData() {
    console.log('this.bankDetails', this.bankDetails)
    if (this.bankDetails) {
      this.webshopEditPayment.patchValue({
        BankName: this.bankDetails['BankName'],
        AccountNumber: this.bankDetails['AccountNumber'],
        IbanNumber: this.bankDetails['IbanNumber'],
        SwiftNumber: this.bankDetails['SwiftNumber'],
        // selectedPaymentTypes: this.webshopData.PaymentTypes
        // ProductPricePosition:this.webshopEditPayment.value[]
        // ProductPricePosition1: this.linkcommissionprofitData[0].Percentage
      })

    }
  }
  webshopPaymentForm() {
    this.webshopEditPayment = this.formBuilder.group({
      BankName: ['', Validators.required],
      AccountNumber: ['', Validators.required],
      IbanNumber: ['', Validators.required],
      SwiftNumber: ['', Validators.required],
      // levelValues: [],
      // SelectedPayment: [],
      // ProductPricePosition: new FormControl('', [Validators.required]),
      // webshopProductPricePosition: new FormControl('', [Validators.required]),
      // ProductPricePosition1: []
    })
  }
  // updatepaymentData() {

  //   let updatepaymentData = {};
  //   updatepaymentData['CustomerID'] = this.webshopData.CustomerID;
  //   updatepaymentData['bankName'] = this.webshopEditPayment.value.BankName
  //   updatepaymentData['AccountNumber'] = this.webshopEditPayment.value.AccountNumber
  //   updatepaymentData['IbanNumber'] = this.webshopEditPayment.value.IbanNumber
  //   updatepaymentData['SwiftNumber'] = this.webshopEditPayment.value.SwiftNumber
  //   updatepaymentData['PaymentTypes'] = this.PaymentData
  //   this.getShopPaymentData.emit(updatepaymentData);

  // }
  updatelinkcommision() {
    let profitDatacommission = []
    this.ProfitLevels.forEach((element, index) => {
      var per = (this.linkLevelValues[index] !== undefined && this.linkLevelValues[index] != '') ? this.linkLevelValues[index] : 0;
      profitDatacommission.push({ 'Percentage': per, 'ProductPricePosition': element.LevelId });
    })
    var profiltDetailData = {
      'CustomerID': this.webshopData.CustomerID,
      'LinkNetworkCommissionRates': profitDatacommission
    };
    this.webshopHttpService.updateLinkcommission(profiltDetailData).subscribe(data => {

    })
  }
  // linkcommision() {
  //   let profitcommission = {}
  //   let profitDatacommission = []
  //   this.webshopHttpService.linkcommission(this.webshopData.CustomerID).subscribe(data => {
  //     if (data.data != '' && data[0].LinkNetworkCommissionRates != undefined) {
  //       this.ProfitLevels.forEach((element, index) => {
  //         var per = (this.linkLevelValues[index] !== undefined && this.linkLevelValues[index] != '') ? this.linkLevelValues[index] : 0;
  //         profitDatacommission.push({ 'Percentage': per, 'ProductPricePosition': element.LevelId });
  //         console.log('profitDatacommission', profitDatacommission)
  //         profitcommission['ProductPricePosition' + element.LevelId] = new FormControl('', [Validators.pattern(numberRegex)]);
  //       });
  //       this.webshopEditPayment = new FormGroup(profitcommission);
  //       this.linkcommissionprofitData = data[0].LinkNetworkCommissionRates;
  //       this.linkcommissionprofitData.forEach((profit, index) => {
  //         let pricePositionControl = 'ProductPricePosition' + index;
  //         this.webshopEditPayment.get(pricePositionControl).patchValue(profit.Percentage);
  //       })
  //       console.log(this.webshopEditPayment.value);
  //     }
  //   })
  // }
  getLinkCommission() {
    this.webshopHttpService.linkcommission(this.webshopData.CustomerID).subscribe((data: any) => {
      if (data.length > 0) {
        this.linkcommissionprofitData = data[0].LinkNetworkCommissionRates;
        if (data.data !== '' && data[0].LinkNetworkCommissionRates !== undefined) {
          this.linkcommissionprofitData.forEach((profit, index) => {
            this.linkLevelValues[index] = profit.Percentage
          })
        }
      }

    })
  }
  getparentwebshopcostprofit() {
    const data = {
      customerID: this.webshopData.CustomerID,
      ParentCustomerID: this.webshopData.ParentCustomerID
    };

    this.webshopHttpService.parentwebshopcostprofit(data).subscribe((data: any) => {
      console.log('parent', data)
      if (data.length > 0) {
        if (data.data !== '' && data[0].CustomerFeeRates !== undefined) {
          this.parentwebshopcostprofitData = data[0].CustomerFeeRates;
          this.parentwebshopcostprofitData.forEach((profit, index) => {
            this.parentLevelValues[index] = profit.Percentage
          })
        }
      }
      else {
        this.webshopHttpService.webshopcostprofit(this.webshopData.ParentCustomerID).subscribe((res: any) => {
          console.log('Parent commission', res);
          if (res !== '' && res[0].CustomerFeeRates !== undefined) {
            this.parentwebshopcostprofitData = res[0].CustomerFeeRates;
            this.parentwebshopcostprofitData.forEach((profit, index) => {
              this.parentLevelValues[index] = profit.Percentage
            })
          }
        })
      }
    })
  }
  updateparentwebshopcostprofit() {
    let parentwebshopcostcommission = []
    this.ProfitLevels.forEach((element, index) => {
      var per = (this.parentLevelValues[index] !== undefined && this.parentLevelValues[index] != '') ? this.parentLevelValues[index] : 0;
      parentwebshopcostcommission.push({ 'Percentage': per, 'ProductPricePosition': element.LevelId });
    })
    var profiltDetailData = {
      'CustomerID': this.webshopData.ParentCustomerID,
      'ChildCustomerID': this.webshopData.CustomerID,
      'CustomerFeeRates': parentwebshopcostcommission
    };
    this.webshopHttpService.updateParentwebshopcostprofit(profiltDetailData).subscribe(data => {

    })
  }
  // parentwebshopcostprofit() {
  //   const data = {
  //     customerID: this.webshopData.CustomerID,
  //     ParentCustomerID: this.webshopData.ParentCustomerID
  //   };
  //   let parentwebshopprofitcommission = {}

  //   this.webshopHttpService.parentwebshopcostprofit(data).subscribe(data => {
  //     if (data.data != '' && data[0].CustomerFeeRates != 'undefined') {
  //       this.ProfitLevels.forEach((element, index) => {
  //         parentwebshopprofitcommission['parentwebshopProductPricePosition' + element.LevelId] = new FormControl('', [Validators.required]);
  //       });

  //       this.webshopEditPayment = new FormGroup(parentwebshopprofitcommission);
  //       this.parentwebshopcostprofitData = data[0].CustomerFeeRates;
  //       this.parentwebshopcostprofitData.forEach((profit, index) => {
  //         let parentwebshoppricePositionControl = 'parentwebshopProductPricePosition' + index;
  //         console.log(index)
  //         this.webshopEditPayment.get(parentwebshoppricePositionControl).patchValue(profit.Percentage);

  //       })
  //       console.log(this.webshopEditPayment)
  //     }
  //   })
  // }
  getWebshopcostprofit() {
    this.webshopHttpService.webshopcostprofit(this.webshopData.CustomerID).subscribe(data => {
      if (data.data !== '' && data[0].CustomerFeeRates !== undefined) {
        this.webshopcostprofitData = data[0].CustomerFeeRates;
        this.webshopcostprofitData.forEach((profit, index) => {
          this.costLevelValues[index] = profit.Percentage
        })
      }
    })
  }
  updateWebshopcostprofit() {
    let costwebshopcostcommission = []
    this.ProfitLevels.forEach((element, index) => {
      var per = (this.costLevelValues[index] !== undefined && this.costLevelValues[index] != '') ? this.costLevelValues[index] : 0;
      costwebshopcostcommission.push({ 'Percentage': per, 'ProductPricePosition': element.LevelId });
    })
    var profiltDetailData = {
      'CustomerID': this.webshopData.CustomerID,
      'CustomerFeeRates': costwebshopcostcommission
    };
    this.webshopHttpService.updatewebshopcostprofit(profiltDetailData).subscribe(data => {

    })
  }
  // webshopcostprofit() {
  //   let webshopprofitcommission = {}

  //   this.webshopHttpService.webshopcostprofit(this.webshopData.CustomerID).subscribe(data => {
  //     if (data.data != '' && data[0].CustomerFeeRates != 'undefined') {
  //       this.ProfitLevels.forEach((element, index) => {
  //         webshopprofitcommission['webshopProductPricePosition' + element.LevelId] = new FormControl('', [Validators.required]);
  //       });

  //       this.webshopEditPayment = new FormGroup(webshopprofitcommission);
  //       this.webshopcostprofitData = data[0].CustomerFeeRates;
  //       const profitDataFormcontrol = [];
  //       this.webshopcostprofitData.forEach((profit, index) => {
  //         let webshoppricePositionControl = 'webshopProductPricePosition' + index;
  //         console.log(index)
  //         this.webshopEditPayment.get(webshoppricePositionControl).patchValue(profit.Percentage);
  //       })
  //       console.log(this.webshopEditPayment)
  //     }
  //   })
  // }
  // paymentCustmerIdSave() {
  //   if (this.selectedPaymentTypes != 'undefined' && this.selectedPaymentTypes != null) {
  //     this.CutomerSelectedPaymentId.forEach(SelectedPayment => {
  //       var update = false;
  //       this.selectedPaymentTypes.forEach(SelectedPaymentId => {
  //         if (SelectedPayment == SelectedPaymentId.PaymentTypeID && SelectedPaymentId.Action != 'undefined' && SelectedPaymentId.Action != 'update' && SelectedPaymentId.Action != 'insert') {
  //           var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
  //           this.PaymentOptions.push(customepaymentData)
  //           update = true;
  //           alert('1')
  //         }
  //         else if (SelectedPayment == SelectedPaymentId.PaymentTypeID && SelectedPaymentId.Action != 'undefined' && SelectedPaymentId.Action == 'delete') {
  //           var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
  //           this.PaymentOptions.push(customepaymentData)
  //           update = true;
  //           alert('2')

  //         }
  //         else if (SelectedPayment == SelectedPaymentId.PaymentTypeID) {
  //           var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
  //           this.PaymentOptions.push(customepaymentData)
  //           alert('3')

  //         }
  //       });
  //       if (update == false) {
  //         var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
  //         this.PaymentOptions.push(customepaymentData)
  //         alert('4')

  //       }
  //     });
  //   }
  //   else {
  //     this.CutomerSelectedPaymentId.forEach(SelectedPaymentId => {
  //       var customepaymentData = { 'PaymentTypeID': SelectedPaymentId, 'Action': 'insert' };
  //       this.PaymentOptions.push(customepaymentData)
  //     })
  //   }
  // }
  paymentType() {
    this.webshopHttpService.paymentType().subscribe((result: any) => {
      this.showLoader = false;
      if (result !== null && result !== undefined && result !== '') {
        this.PaymentTypes = result;

        this.PaymentTypes.forEach(pay => {
          this.webshopData.PaymentTypes.forEach(element => {
            if (element.PaymentTypeID === pay.PaymentTypeID) {
              pay.status = true;
            }
          });
        });
        const getActivePayment = this.PaymentTypes.filter(data => data.status);
        this.getActivePayment = getActivePayment.length > 0 ? true : false;
        // this.PaymentTypes.forEach(element => {
        //   const ret = this.webshopData.PaymentTypes.filter(function (obj) {
        //     return obj.PaymentTypeID === element.PaymentTypeID;
        //   })
        //   element['isSelected'] = false;
        //   if (ret.length !== 0) {
        //     element['isSelected'] = true;
        //   }
        // })
      }
    })
    // this.setPaymentOptions()
  }
  // PaymentOptionsSelect(event, paymentDataOptions) {
  //   const checkIfAllChecked = this.PaymentData.filter(data => data.PaymentTypeID === paymentDataOptions.PaymentTypeID);
  //   if (checkIfAllChecked.length === 0) {
  //     this.PaymentData.push(paymentDataOptions)
  //   } else {
  //     const getCheckedIndex = this.PaymentData.findIndex(data => data.PaymentTypeID === paymentDataOptions.PaymentTypeID)
  //     this.PaymentData.slice(getCheckedIndex, 1)
  //   }
  // }



  saveWebshop() {
    if (this.CutomerSelectedPaymentId === undefined || this.CutomerSelectedPaymentId.length <= 0) {
      this.toast.error('Please choose at least one Payment Option');
    }
    if (this.webshopEditPayment.value.BankName === "" || this.webshopEditPayment.value.BankName === undefined) {
      this.toast.error('Please enter Bank Name');
    }
    if (this.webshopEditPayment.value.AccountNumber === "" || this.webshopEditPayment.value.AccountNumber === undefined) {
      this.toast.error('Please enter Account Number');
    }
    if (this.webshopEditPayment.value.IbanNumber === "" || this.webshopEditPayment.value.IbanNumber === undefined) {
      this.toast.error('Please enter IBAN Number');
    }
    if (this.webshopEditPayment.value.IbanNumber === "" || this.webshopEditPayment.value.IbanNumber === undefined) {
      this.toast.error('Please enter IBAN Number');
    }
    var PaymentOptions: any = []
    if (this.selectedPaymentTypes !== undefined && this.selectedPaymentTypes !== null) {
      this.CutomerSelectedPaymentId.forEach(SelectedPayment => {
        var update = false;
        this.selectedPaymentTypes.forEach(SelectedPaymentId => {
          if (SelectedPayment == SelectedPaymentId.PaymentTypeID && SelectedPaymentId.Action !== undefined && SelectedPaymentId.Action != 'update' && SelectedPaymentId.Action != 'insert') {
            var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
            PaymentOptions.push(customepaymentData)
            update = true;
          }
          else if (SelectedPayment == SelectedPaymentId.PaymentTypeID && SelectedPaymentId.Action != 'undefined' && SelectedPaymentId.Action == 'delete') {
            var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
            PaymentOptions.push(customepaymentData)
            update = true;
          }
          else if (SelectedPayment == SelectedPaymentId.PaymentTypeID) {
            var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'update' };
            PaymentOptions.push(customepaymentData)
            update = true;
          }
        });
        if (update == false) {
          var customepaymentData = { 'PaymentTypeID': SelectedPayment, 'Action': 'insert' };
          PaymentOptions.push(customepaymentData)
        }
      });
    }
    else {
      this.CutomerSelectedPaymentId.forEach(SelectedPaymentId => {
        var customepaymentData = { 'PaymentTypeID': SelectedPaymentId, 'Action': 'insert' };
        PaymentOptions.push(customepaymentData)
      })
    }
    /*** Delete Customer Payment Options ****/
    if (this.deletePaymentIds.length > 0) {
      this.deletePaymentIds.forEach((data, key) => {
        var customepaymentData = { 'PaymentTypeID': data, 'Action': 'delete' };
        PaymentOptions.push(customepaymentData);
      });
    }


    this.getShopPaymentData.emit(this.webshopEditPayment.value);
    this.ShopPaymentDetailData.emit(PaymentOptions);

    this.updatelinkcommision()
    this.updateparentwebshopcostprofit()
    this.updateWebshopcostprofit()
  }
  /**** Delete Payment Ids ***/
  paymentDataAdd(eventHandle, PaymentId) {
    // const getActivePayment = this.PaymentTypes.filter(data => data.status);
    // this.getActivePayment = getActivePayment.length > 0 ? true : false;
    const getIndex = this.PaymentTypes.findIndex(data => data.PaymentTypeID === PaymentId);
    this.PaymentTypes[getIndex]['status'] = eventHandle.checked;
    const getActivePayment = this.PaymentTypes.filter(data => data.status);
    this.getActivePayment = getActivePayment.length > 0 ? true : false;
    if (eventHandle.checked) {
      this.CutomerSelectedPaymentId.push(PaymentId);

      var index = this.deletePaymentIds.indexOf(PaymentId);
      if (index > -1) {
        this.deletePaymentIds.splice(index, 1);
      }
      // this.getActivePayment = this.CutomerSelectedPaymentId.length > 0 ? true : false;
    }
    else {
      var index = this.CutomerSelectedPaymentId.indexOf(PaymentId);
      if (index > -1) {
        this.CutomerSelectedPaymentId.splice(index, 1);
        this.deletePaymentIds.push(PaymentId);
      }
      // this.getActivePayment = this.CutomerSelectedPaymentId.length > 0 ? true : false;
    }
  }
  // setPaymentOptions() {
  //   if (this.PaymentTypes != null) {
  //     this.webshopData.PaymentTypes.forEach(data => {
  //       this.SelectedPaymentIds[data['PaymentTypeID']] = true;
  //       this.CutomerSelectedPaymentId.push(data['PaymentTypeID']);
  //     });
  //   }
  // }
  setPaymentOptions(PaymentTypes) {
    if (PaymentTypes != null) {
      PaymentTypes.forEach(data => {
        this.SelectedPaymentIds[data['PaymentTypeID']] = true;
        this.CutomerSelectedPaymentId.push(data['PaymentTypeID']);
      });
    }
  }
  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Number only accepted here')
      return false
    }
    else {
      return true
    }
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
