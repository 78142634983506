import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoginComponent } from './login.component';
import { LoginHttpService } from '../login/state/services/login.http.service';
import { LoginEffects } from '../login/state/effects';
import { LoginService } from '../login/state/services/login.service';
import { loginReducers } from '../login/state/reducers/login.reducer';
import { AdminRouterModule } from '../router/router.module';
import { SharedModule } from '../shared/shared.module';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AdminRouterModule,
    SharedModule,
    PageLoaderModule,
    EffectsModule.forFeature([LoginEffects]),
    StoreModule.forFeature('loginModule', loginReducers)
  ],
  declarations: [LoginComponent, ForgotPasswordComponent],
  exports: [LoginComponent, ForgotPasswordComponent],
  providers: [LoginEffects, LoginHttpService, LoginService]
})
export class LoginModule { }
