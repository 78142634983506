import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductManageLogoAreasComponent } from './product-manage-logo-areas.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomdirectiveModule } from '../../customdirective/customdirective.module';

 
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule ,
    CommonFormModule,
    SharedModule,
    TranslateModule,
    CustomdirectiveModule
  ],
  declarations: [ProductManageLogoAreasComponent],
  exports: [ProductManageLogoAreasComponent]
})

export class ProductManageLogoAreasModule { } 
