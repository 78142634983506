import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { ProductBasicInfoComponent } from './product-basic-info.component';
import { ProductAddModule } from '../../product-add/product-add.module';
import { ProductAdditionalInfoModule } from '../product-additional-info/product-additional-info.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    ControlValidationModule,
    ProductAddModule,
    ControlValidationModule,
    ProductAdditionalInfoModule
  ],
  declarations: [ProductBasicInfoComponent],
  exports: [ProductBasicInfoComponent]
})

export class ProductBasicInfoModule { }