import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChildshopListComponent } from './childshop-list/childshop-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { PageLoaderModule } from '../common/pageloader/pageloader.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FilterTagModule } from '../common/common-filter/filter-tags/filter-tags.module';



@NgModule({
  declarations: [ChildshopListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommonFilterModule,
    PageLoaderModule,
    TranslateModule,
    RouterModule,
    FilterTagModule
  ],
  exports:[ChildshopListComponent]
})
export class ChildshopModule { }
