import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-production-line-item-timeline',
  templateUrl: './production-line-item-timeline.component.html',
  styleUrls: ['./production-line-item-timeline.component.scss']
})
export class ProductionLineItemTimeLineComponent implements OnInit {
  @Input() Order = null;
  @Input() visibleTopLabel = false;

  @Output() timelineEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  emitAction(action: string, event?) {
    this.timelineEvent.emit({ action, data: this.Order });
  }
}
