import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent implements OnInit {

  @Input() selectedProducts = [];
  @Input() dragEnabled = false;
  @Input() intersectResult = false;
  @Output() productSelect = new EventEmitter();
  @Output() productRemovedEvent = new EventEmitter();
  @Output() proudctsChange = new EventEmitter();
  @Input() campaignID: any;
  @Input() budget: any;

  searchResult = [];


  ngOnInit() {
  }

  updateSearchResult(searchResult: any) {
    this.searchResult = searchResult;
  }

  handleProductSelect(product: any) {
    this.productSelect.emit(product);
  }

  handleProductRemove(product) {
    this.productRemovedEvent.emit(product);
  }

  handleproudctsChange(addStatus: Boolean) {
    this.proudctsChange.emit(addStatus)
  }
}
