import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../../shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-webshop-settings',
  templateUrl: './webshop-settings.component.html',
  styleUrls: ['./webshop-settings.component.scss']
})
export class WebshopSettingsComponent implements OnInit {
  colorPickerChange: any;
  settingsDetails: FormGroup;
  @Input() languages: any = '';
  @Input() currencies: any = '';
  @Input() categories: any = '';
  @Input() webshopData: any = '';
  @Output() getSettingsData = new EventEmitter<any>();
  languageData = [];
  currenciesData = [];
  categoryData = [];
  subCategoryData = [{ label: 'None', value: '' }];
  socialMediaMasterData = [];
  socialMedias = [];
  /** Image Section */
  selectedImg: any = '';
  uploadImgPath: any = '';
  uploadImgName: any = '';
  showImageError: Boolean = false;
  showLoader: boolean = false;
  color: any;
  isWebshopColorSettings = false;
  isWebshopLogoSettings = false;
  isWebshopHomecategorySettings = false;
  isWebshopFeaturesSettings = false;
  isWebshopSocialMediaSettings = false;
  message: any;
  constructor(private webshophttpService: WebshopHttpService, private formBuilder: FormBuilder, private translate: TranslateService, private toast: ToastService) {
  }

  ngOnInit() {
    console.log("webshopData testing data : ", this.webshopData.CustomerID);
    console.log("window.location.pathname : ", window.location.pathname);
    // tslint:disable-next-line:max-line-length
    if (window.location.pathname !== '/homepage-category' && window.location.pathname !== '/webshop-color' && window.location.pathname !== '/webshop-logo') {
      this.getSocialMediaMasterData();
    }

    this.socialMedias = this.webshopData.SocialMedias;
    this.uploadImgName = this.webshopData.ImageName;
    if (window.location.pathname === '/webshop-color') {
      this.isWebshopColorSettings = true;
    }
    if (window.location.pathname === '/webshop-logo') {
      this.isWebshopLogoSettings = true;
    }
    if (window.location.pathname === '/homepage-category') {
      this.isWebshopHomecategorySettings = true;
    }
    if (window.location.pathname === '/webshop-features') {
      this.isWebshopFeaturesSettings = true;
    }
    if (window.location.pathname === '/social-media') {
      this.isWebshopSocialMediaSettings = true;
    }
    // if (this.languages.length > 0) {
    //   this.languages.forEach(lang => {
    //     const langData = { label: lang.Name, value: lang.LanguageID };
    //     this.languageData.push(langData)
    //   });
    //   console.log("languageData : ", this.languageData);
    // }
    if (this.currencies !== undefined) {
      if (this.currencies.length > 0) {
        this.currencies.forEach(res => {
          const cdata = { label: res.CurrencyName, value: res.CurrencyID };
          this.currenciesData.push(cdata);
        });
      }
    }

    if (this.categories !== undefined) {
      if (this.categories.length > 0) {
        this.categories.forEach(res => {
          const categorydata = { label: res.Name, value: res.CategoryID };
          this.categoryData.push(categorydata);
        });
        this.categoryData.unshift({ label: "None", value: "" })
        console.log("categoryData : ", this.categoryData);
      }
    }
    this.loadForm();
    // tslint:disable-next-line:max-line-length
    if (window.location.pathname !== '/homepage-category' && window.location.pathname !== '/social-media' && window.location.pathname !== '/webshop-color' && window.location.pathname !== '/webshop-features' && window.location.pathname !== '/webshop-logo') {
      this.getWebshopLanguages(this.webshopData.CustomerID)
    }
  }

  ngOnChanges() {
    console.log(this.languages);
  }
  getWebshopLanguages(custID) {
    this.webshophttpService.getLanguages(custID).subscribe(
      (data: any) => {
        if (data) {
          let languageData = data;
          languageData.forEach(element => {
            element.value = element.LanguageID;
            element.label = element.Name;
          });
          this.languageData = languageData;
          this.settingsDetails.patchValue({
            DefaultLanguageID: this.webshopData.DefaultLanguageID
          });
        }
      });
  }

  loadForm() {
    this.settingsDetails = this.formBuilder.group({
      Name: ['', Validators.required],
      Main: ['', Validators.required],
      Lable: ['', Validators.required],
      Alert: ['', Validators.required],
      webshopImageDragDrop: [''],
      webshopImage: ['', Validators.required],
      ShowSupplierRating: ['', Validators.required],
      FirstSubcategoryRule: ['', Validators.required],
      ShowPricesIncludingVAT: ['', Validators.required],
      OrderConfirmationOnlyForISPs: ['', Validators.required],
      ShowAdvancedSearchBranding: ['', Validators.required],
      ShowAdvancedSearchColors: ['', Validators.required],
      ShowAdvancedSearchPrices: ['', Validators.required],
      ShowSortByFilter: ['', Validators.required],
      ShowAllProductsInSortByFilter: ['', Validators.required],
      RequireUsersApproval: ['', Validators.required],
      ShowFiltersOnHomePage: ['', Validators.required],
      ShowJoinOrdersOnHomePage: ['', Validators.required],
      UseManualIdWhenSortingByNewestOldest: ['', Validators.required],
      ShowCategoriesOnHomePage: ['', Validators.required],
      EnableCookiePolicy: ['', Validators.required],
      ChangeDeliveryDateText: ['', Validators.required],
      TreatDeliveryWeeksAsDaysForISPs: ['', Validators.required],
      isSocialMediaSharingEnabled: ['', Validators.required],
      EnableNewsletterSignupLink: ['', Validators.required],
      IsSendEmailCheckedByDefault: ['', Validators.required],
      // DefaultLanguageID: ['', Validators.required],
      // DefaultCurrencyID: ['', Validators.required],
      UseChildCurrencyWhenPossible: ['', Validators.required],
      selectedCat: ['', Validators.required],
      HomePageCategoryID: ['', Validators.required],
      LinkFacebook: ['', Validators.required],
      LinkTwitter: ['', Validators.required],
      LinkInstagram: ['', Validators.required],
      LinkGoogleplus: ['', Validators.required],
      LinkLinkedin: ['', Validators.required],
      passwordProtected: [false]
    });

    /** To prefill Values */
    this.settingsDetails.patchValue({
      Name: (this.webshopData.Themes[0] !== undefined) ? this.webshopData.Themes[0].Name : '',
      Main: (this.webshopData.Themes[0] !== undefined) ? this.webshopData.Themes[0].Main : '',
      Lable: (this.webshopData.Themes[0] !== undefined) ? this.webshopData.Themes[0].Lable : '',
      Alert: (this.webshopData.Themes[0] !== undefined) ? this.webshopData.Themes[0].Alert : '',
      ShowSupplierRating: (this.webshopData.ShowSupplierRating !== undefined) ? this.webshopData.ShowSupplierRating : false,
      FirstSubcategoryRule: (this.webshopData.FirstSubcategoryRule !== undefined) ? this.webshopData.FirstSubcategoryRule : false,
      ShowPricesIncludingVAT: (this.webshopData.ShowPricesIncludingVAT !== undefined) ? this.webshopData.ShowPricesIncludingVAT : false,
      // tslint:disable-next-line:max-line-length
      OrderConfirmationOnlyForISPs: (this.webshopData.OrderConfirmationOnlyForISPs !== undefined) ? this.webshopData.OrderConfirmationOnlyForISPs : false,
      // tslint:disable-next-line:max-line-length
      ShowAdvancedSearchBranding: (this.webshopData.ShowAdvancedSearchBranding !== undefined) ? this.webshopData.ShowAdvancedSearchBranding : false,
      // tslint:disable-next-line:max-line-length
      ShowAdvancedSearchColors: (this.webshopData.ShowAdvancedSearchColors !== undefined) ? this.webshopData.ShowAdvancedSearchColors : false,
      // tslint:disable-next-line:max-line-length
      ShowAdvancedSearchPrices: (this.webshopData.ShowAdvancedSearchPrices !== undefined) ? this.webshopData.ShowAdvancedSearchPrices : false,
      // tslint:disable-next-line:max-line-length
      ShowSortByFilter: (this.webshopData.ShowSortByFilter !== undefined) ? this.webshopData.ShowSortByFilter : false,
      // tslint:disable-next-line:max-line-length
      ShowAllProductsInSortByFilter: (this.webshopData.ShowAllProductsInSortByFilter !== undefined) ? this.webshopData.ShowAllProductsInSortByFilter : false,
      RequireUsersApproval: (this.webshopData.RequireUsersApproval !== undefined) ? this.webshopData.RequireUsersApproval : false,
      ShowFiltersOnHomePage: (this.webshopData.ShowFiltersOnHomePage !== undefined) ? this.webshopData.ShowFiltersOnHomePage : false,
      // tslint:disable-next-line:max-line-length
      ShowJoinOrdersOnHomePage: (this.webshopData.ShowJoinOrdersOnHomePage !== undefined) ? this.webshopData.ShowJoinOrdersOnHomePage : false,
      // tslint:disable-next-line:max-line-length
      UseManualIdWhenSortingByNewestOldest: (this.webshopData.UseManualIdWhenSortingByNewestOldest !== undefined) ? this.webshopData.UseManualIdWhenSortingByNewestOldest : false,
      // tslint:disable-next-line:max-line-length
      ShowCategoriesOnHomePage: (this.webshopData.ShowCategoriesOnHomePage !== undefined) ? this.webshopData.ShowCategoriesOnHomePage : false,
      EnableCookiePolicy: (this.webshopData.EnableCookiePolicy !== undefined) ? this.webshopData.EnableCookiePolicy : false,
      ChangeDeliveryDateText: (this.webshopData.ChangeDeliveryDateText !== undefined) ? this.webshopData.ChangeDeliveryDateText : false,
      // tslint:disable-next-line:max-line-length
      TreatDeliveryWeeksAsDaysForISPs: (this.webshopData.TreatDeliveryWeeksAsDaysForISPs !== undefined) ? this.webshopData.TreatDeliveryWeeksAsDaysForISPs : false,
      // tslint:disable-next-line:max-line-length
      isSocialMediaSharingEnabled: (this.webshopData.isSocialMediaSharingEnabled !== undefined) ? this.webshopData.isSocialMediaSharingEnabled : false,
      // tslint:disable-next-line:max-line-length
      EnableNewsletterSignupLink: (this.webshopData.EnableNewsletterSignupLink !== undefined) ? this.webshopData.EnableNewsletterSignupLink : false,
      // tslint:disable-next-line:max-line-length
      IsSendEmailCheckedByDefault: (this.webshopData.IsSendEmailCheckedByDefault !== undefined) ? this.webshopData.IsSendEmailCheckedByDefault : false,
      // DefaultLanguageID: (this.webshopData.DefaultLanguageID !== undefined) ? this.webshopData.DefaultLanguageID : '',
      // DefaultCurrencyID: (this.webshopData.DefaultCurrencyID !== undefined) ? this.webshopData.DefaultCurrencyID : '',
      // tslint:disable-next-line:max-line-length
      UseChildCurrencyWhenPossible: (this.webshopData.UseChildCurrencyWhenPossible !== undefined) ? this.webshopData.UseChildCurrencyWhenPossible : '',
      LinkFacebook: (this.webshopData.LinkFacebook !== null) ? this.webshopData.LinkFacebook : '',
      LinkTwitter: (this.webshopData.LinkTwitter !== null) ? this.webshopData.LinkTwitter : '',
      LinkInstagram: (this.webshopData.LinkInstagram !== null) ? this.webshopData.LinkInstagram : '',
      LinkGoogleplus: (this.webshopData.LinkGoogleplus !== null) ? this.webshopData.LinkGoogleplus : '',
      LinkLinkedin: (this.webshopData.LinkLinkedin !== null) ? this.webshopData.LinkLinkedin : '',
      passwordProtected: (this.webshopData.isPrivate !== null) && (this.webshopData.isPrivate === 'False' || !this.webshopData.isPrivate) ? false : true,
    });

    /** To prefill Category, Subcategory in Edit page */
    if (this.webshopData.HomePageCategoryID !== undefined && this.webshopData.HomePageCategoryID !== null) {
      console.log("this.categoriessss : ", this.categories);
      let mainCategoryStatus = false;
      this.categories.forEach(res => {
        if (res.CategoryID === this.webshopData.HomePageCategoryID) {
          this.settingsDetails.patchValue({
            selectedCat: (this.webshopData.HomePageCategoryID !== null) ? this.webshopData.HomePageCategoryID : '',
            HomePageCategoryID: ''
          });
          this.subCategoryData = [];
          if (res.SubCategories.length > 0) {
            this.subCategoryData = [{ label: 'None', value: '' }];
            res.SubCategories.forEach(res => {
              const subcategorydata = { label: res.Name, value: res.CategoryID };
              this.subCategoryData.push(subcategorydata);
            });
          }
          mainCategoryStatus = true;
        }
      });
      if (!mainCategoryStatus) {
        this.subCategoryData = [];
        this.categories.forEach(res => {
          if (res.SubCategories.length > 0) {
            // const reqData = {
            //   value: res.CategoryID
            // };
            // this.loadSubCategory(reqData);
            res.SubCategories.forEach(sub => {
              if (sub.CategoryID === this.webshopData.HomePageCategoryID) {
                this.subCategoryData = [];
                this.subCategoryData = [{ label: 'None', value: '' }];
                res.SubCategories.forEach(res => {
                  const subcategorydata = { label: res.Name, value: res.CategoryID };
                  this.subCategoryData.push(subcategorydata);
                });
                this.settingsDetails.patchValue({
                  selectedCat: res.CategoryID,
                  HomePageCategoryID: this.webshopData.HomePageCategoryID
                });
              }
            });
          }
        })
      };
    }

    if (this.webshopData.ImageURL !== undefined && this.webshopData.ImageURL !== null && this.webshopData.ImageURL !== '') {
      this.selectedImg = this.webshopData.ImageURL;
    }
  }

  loadSubCategory(data) {
    this.settingsDetails.patchValue({
      HomePageCategoryID: ''
    });
    const checkSubCategoryExists = this.categories.filter(data1 => data1.CategoryID === data.value);
    if (checkSubCategoryExists.length > 0) {
      this.subCategoryData = [];
      this.subCategoryData = [{ label: 'None', value: '' }];
      checkSubCategoryExists[0].SubCategories.forEach(res => {
        const subcategorydata = { label: res.Name, value: res.CategoryID };
        this.subCategoryData.push(subcategorydata);
      });
    } else {
      this.subCategoryData = [{ label: 'None', value: '' }];
    }
  }

  updateSettingsData() {
    console.log("Update webshopData : ", this.webshopData);
    let errorCount = 0;
    if (this.settingsDetails.value.LinkFacebook !== null && this.settingsDetails.value.LinkFacebook !== undefined
      && this.settingsDetails.value.LinkFacebook !== '') {
      if (!this.validURL(this.settingsDetails.value.LinkFacebook)) {
        this.toast.error('Enter Valid Facebook URL(incl http:// or https://)');
        errorCount += 1;
      }
    }
    if (this.settingsDetails.value.LinkTwitter !== null && this.settingsDetails.value.LinkTwitter !== undefined
      && this.settingsDetails.value.LinkTwitter !== '') {
      if (!this.validURL(this.settingsDetails.value.LinkTwitter)) {
        this.toast.error('Enter Valid Twitter URL(incl http:// or https://)');
        errorCount += 1;
      }
    }
    if (this.settingsDetails.value.LinkInstagram !== null && this.settingsDetails.value.LinkInstagram !== undefined
      && this.settingsDetails.value.LinkInstagram !== '') {
      if (!this.validURL(this.settingsDetails.value.LinkInstagram)) {
        this.toast.error('Enter Valid Instagram URL(incl http:// or https://)');
        errorCount += 1;
      }
    }
    if (this.settingsDetails.value.LinkGoogleplus !== null && this.settingsDetails.value.LinkGoogleplus !== undefined
      && this.settingsDetails.value.LinkGoogleplus !== '') {
      if (!this.validURL(this.settingsDetails.value.LinkGoogleplus)) {
        this.toast.error('Enter Valid Googleplus URL(incl http:// or https://)');
        errorCount += 1;
      }
    }
    if (this.settingsDetails.value.LinkLinkedin !== null && this.settingsDetails.value.LinkLinkedin !== undefined
      && this.settingsDetails.value.LinkLinkedin !== '') {
      if (!this.validURL(this.settingsDetails.value.LinkLinkedin)) {
        this.toast.error('Enter Valid Linkedin URL(incl http:// or https://)');
        errorCount += 1;
      }
    }

    if (errorCount > 0) {
      return false;
    }
    let themeData = [];
    themeData.push({
      ThemeId: this.webshopData.Themes[0].ThemeId,
      Name: this.settingsDetails.value.Name,
      Main: this.settingsDetails.value.Main,
      Lable: this.settingsDetails.value.Lable,
      Alert: this.settingsDetails.value.Alert
    });
    console.log(this.settingsDetails.value.HomePageCategoryID, 'checking')
    let updateSettingsData = {};
    updateSettingsData['CustomerID'] = this.webshopData.CustomerID;
    updateSettingsData['Themes'] = themeData;
    updateSettingsData['ImageName'] = this.uploadImgName;
    updateSettingsData['ShowSupplierRating'] = this.settingsDetails.value.ShowSupplierRating;
    updateSettingsData['FirstSubcategoryRule'] = this.settingsDetails.value.FirstSubcategoryRule;
    updateSettingsData['ShowPricesIncludingVAT'] = this.settingsDetails.value.ShowPricesIncludingVAT;
    updateSettingsData['OrderConfirmationOnlyForISPs'] = this.settingsDetails.value.OrderConfirmationOnlyForISPs;
    updateSettingsData['ShowAdvancedSearchBranding'] = this.settingsDetails.value.ShowAdvancedSearchBranding;
    updateSettingsData['ShowAdvancedSearchColors'] = this.settingsDetails.value.ShowAdvancedSearchColors;
    updateSettingsData['ShowAdvancedSearchPrices'] = this.settingsDetails.value.ShowAdvancedSearchPrices;
    updateSettingsData['ShowSortByFilter'] = this.settingsDetails.value.ShowSortByFilter;
    updateSettingsData['ShowAllProductsInSortByFilter'] = this.settingsDetails.value.ShowAllProductsInSortByFilter;
    updateSettingsData['RequireUsersApproval'] = this.settingsDetails.value.RequireUsersApproval;
    updateSettingsData['ShowFiltersOnHomePage'] = this.settingsDetails.value.ShowFiltersOnHomePage;
    updateSettingsData['ShowJoinOrdersOnHomePage'] = this.settingsDetails.value.ShowJoinOrdersOnHomePage;
    updateSettingsData['UseManualIdWhenSortingByNewestOldest'] = this.settingsDetails.value.UseManualIdWhenSortingByNewestOldest;
    updateSettingsData['ShowCategoriesOnHomePage'] = this.settingsDetails.value.ShowCategoriesOnHomePage;
    updateSettingsData['EnableCookiePolicy'] = this.settingsDetails.value.EnableCookiePolicy;
    updateSettingsData['ChangeDeliveryDateText'] = this.settingsDetails.value.ChangeDeliveryDateText;
    updateSettingsData['TreatDeliveryWeeksAsDaysForISPs'] = this.settingsDetails.value.TreatDeliveryWeeksAsDaysForISPs;
    updateSettingsData['isSocialMediaSharingEnabled'] = this.settingsDetails.value.isSocialMediaSharingEnabled;
    updateSettingsData['EnableNewsletterSignupLink'] = this.settingsDetails.value.EnableNewsletterSignupLink;
    updateSettingsData['IsSendEmailCheckedByDefault'] = this.settingsDetails.value.IsSendEmailCheckedByDefault;
    // updateSettingsData['DefaultLanguageID'] = this.settingsDetails.value.DefaultLanguageID;
    // updateSettingsData['DefaultCurrencyID'] = this.settingsDetails.value.DefaultCurrencyID;
    updateSettingsData['UseChildCurrencyWhenPossible'] = this.settingsDetails.value.UseChildCurrencyWhenPossible;
    // tslint:disable-next-line:max-line-length
    updateSettingsData['HomePageCategoryID'] = this.settingsDetails.value.HomePageCategoryID !== '' ? this.settingsDetails.value.HomePageCategoryID : this.settingsDetails.value.selectedCat;
    updateSettingsData['LinkFacebook'] = this.settingsDetails.value.LinkFacebook;
    updateSettingsData['LinkTwitter'] = this.settingsDetails.value.LinkTwitter;
    updateSettingsData['LinkInstagram'] = this.settingsDetails.value.LinkInstagram;
    updateSettingsData['LinkGoogleplus'] = this.settingsDetails.value.LinkGoogleplus;
    updateSettingsData['LinkLinkedin'] = this.settingsDetails.value.LinkLinkedin;
    updateSettingsData['socialMedias'] = this.socialMedias;
    updateSettingsData['passwordProtected'] = this.settingsDetails.value.passwordProtected;
    this.getSettingsData.emit(updateSettingsData);
    console.log(updateSettingsData['HomePageCategoryID'], 'home')
  }

  onColorChange(val) {
    this.settingsDetails.controls['Main'].setValue('#' + val.substring(1).toUpperCase());
  }

  onLableColorChange(val) {
    this.settingsDetails.controls['Lable'].setValue('#' + val.substring(1).toUpperCase());
  }

  onAlertColorChange(val) {
    this.settingsDetails.controls['Alert'].setValue('#' + val.substring(1).toUpperCase());
  }

  onSelectFile(event) {
    console.log(event);
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg !== '.jfif' && getImg !== '.jpg' && getImg !== '.jpeg' && getImg !== '.png' && getImg !== '.gif' &&
      getImg !== '.JPG' && getImg !== '.JPEG' && getImg !== '.PNG' && getImg !== '.GIF' && getImg !== '.JFIF')) {
      this.showImageError = true;
      this.selectedImg = '';
      this.uploadImgPath = '';
      return;
    } else {
      this.showImageError = false;
    }

    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].size !== '') {
          const maximumMb = event.target.files[i].size / 1024 / 1024;
          if (maximumMb > 10) {
            this.toast.error(this.translate.instant('EDITORDERSITEMS.LOG_FILE_VALIDATION'));
            return false;
          }
        }
        const reader = new FileReader();
        reader.onload = (event1: any) => {
          this.selectedImg = event1.target.result;
        };
        reader.readAsDataURL(event.target.files[i]);
        this.uploadImgPath = event.target.files[0];

        let formdata = new FormData();
        formdata.append('file', this.uploadImgPath);
        formdata.append('filePath', 'CustomerImages');
        this.showLoader = true;
        this.webshophttpService.uploadwebshopimage(formdata).subscribe((uploadImageStatus: any) => {
          this.showLoader = false;
          if (uploadImageStatus[0] !== null && uploadImageStatus[0] !== undefined) {
            this.uploadImgName = uploadImageStatus[1];
          }
        });
      }
    }
  }

  /*** Get Country Items ***/
  getSocialMediaMasterData() {
    this.webshophttpService.getSocialMediaMasterData().subscribe(
      (result: any) => {
        if (result !== null && result !== undefined && result !== '') {
          this.socialMediaMasterData = result;
          this.socialMediaMasterData.forEach(element => {
            const ret = this.webshopData.SocialMedias.filter(function (obj) {
              return obj.SocialMediaID === element.SocialMediaID;
            });
            element['isSelected'] = false;
            if (ret.length !== 0) {
              element['isSelected'] = true;
            }
          });
        }
      });
  }

  socialMediaSelect(event, socialData) {
    const checkIfAllChecked = this.socialMedias.filter(data => data.SocialMediaID === socialData.SocialMediaID);
    if (checkIfAllChecked.length === 0) {
      this.socialMedias.push(socialData);
    } else {
      const getCheckedIndex = this.socialMedias.findIndex(data => data.SocialMediaID === socialData.SocialMediaID);
      this.socialMedias.splice(getCheckedIndex, 1);
    }
    // console.log("socialMediaMasterData : ", this.socialMediaMasterData);
    console.log("socialMedias : ", this.socialMedias);

  }

  validURL(str) {
    const RegExp1 = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (RegExp1.test(str)) {
      return true;
    } else {
      return false;
    }
  }
}
