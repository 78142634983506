import { Component, OnInit, OnChanges, ViewChild, EventEmitter, SimpleChanges, Input, Output } from '@angular/core';
import { ProductService } from '../../../state/services/product.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ApiService } from '../../../../shared/common-services/api.service';
import { WebService } from '../../../../restful-service/web.service';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import * as _ from 'lodash';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';

@Component({
  selector: 'app-manage-logo-type',
  templateUrl: './manage-logo-type.component.html',
  styleUrls: ['./manage-logo-type.component.scss']
})
export class ManageLogoTypeComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input('resetForm') resetForm: Boolean
  logoTypeList: any;
  @Input() productDetails: any;
  @Input() logoTypeoptions: any;
  @Input('productPrice') productPrice: any
  @Output('closeLogoType') closeLogoType = new EventEmitter<any>()
  logoTypeForm: FormGroup;
  addLogoType: FormGroup;
  selectedLang: 'en';
  languageOption: any = [];
  getlang: any = [];
  language: string = 'en';
  areaDeleteData: any;
  addLogoDataPush: any = [];
  areadelete: any = [];
  updateData: any = [];
  addLogo: boolean = false;
  submitAdd: boolean = false;
  logoname: string;
  selfManageLogotype: any = [];
  searchManageLogoType: any;
  hideSave: boolean = false;
  autoSave: boolean;
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  @Output() loadPropertyDropdown = new EventEmitter<any>();
  groupCollection: any;
  searchText: any = '';
  selectedlogotype: any;
  selectedLangCode: string = 'en';
  webshopDetail: any;
  constructor(private productService: ProductService, public apiService: ApiService, public webService: WebService, public toast: ToastService, public productHttpService: ProductHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.getLanguage();
    this.getLogoType();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      if (this.addLogoType) {
        this.addLogoType.reset();
      }
      this.resetFilter();
      this.filterReset();
    }
  }

  // getLogoType() {
  //   let group = {};

  //   this.productService.loadLogoType();
  //   this.productService.getLogoType().subscribe(data => {
  //     if (data) {
  //       const logoTypeArray = []
  //       data.forEach((element, index) => {
  //         logoTypeArray.push({ label: element.Name, value: element.LogoTypeID, Code: element.Code })
  //         group['name'+index] = new FormControl(element.Name);
  //         group['description'+index] = new FormControl(element.Description); 
  //       });
  //       this.logoTypeList = logoTypeArray;
  //       this.logoTypeForm = new FormGroup(group);
  //     }
  //   })

  // }

  getLanguage() {
    const langOption = [];
    const group = {}
    this.getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);


    this.getlang.forEach((element, index) => {
      group['logoname' + element.Code + index] = new FormControl('');
      group['description' + element.Code + index] = new FormControl('');
      let langData = { label: element.Name, value: element.Code };
      if (langData !== null) {
        langOption.push(langData)
      }
      this.getlang[index]['fields'] = [{ fieldname: 'LogoTypeName', formfield: 'logoname' + element.Code + index }, { fieldname: 'LogoTypeDescription', formfield: 'description' + element.Code + index }];
    });
    this.languageOption = langOption;
    this.addLogoType = new FormGroup(group);
  }

  getCurrentLang(lang) {
    // this.clearAddFormValues()
    this.selectedLang = lang.label;
    this.selectedLangCode = lang.value;
    if (this.searchText) this.filterReset();
  }

  loadFormGroup() {
    let group = {};
    for (let i = 0; i < this.logoTypeList.length; i++) {

      const controlName = [];
      const getLang = [];

      this.logoTypeList[i].Translations.forEach((element, index) => {
        if (element.LanguageCode === 'en') {
          group[element.PropertyName + element.LanguageCode + i] = new FormControl(element.PropertyValue, Validators.required);
          element['required'] = true;
        }
        else {
          group[element.PropertyName + element.LanguageCode + i] = new FormControl(element.PropertyValue);
          element['required'] = false;
        }
        const getType = element.PropertyName == 'LogoTypeName' ? 1 : 2;
        controlName.push({ controlname: element.PropertyName + element.LanguageCode + i, lang: element.LanguageCode, controls: element.PropertyName + element.LanguageCode, type: getType })
        element['controls'] = element.PropertyName + element.LanguageCode;
      });

      let arrayForm = _.sortBy(controlName, (obj) => parseInt(obj.type, 10));
      this.logoTypeList[i]['controlName'] = arrayForm;
    }
    this.logoTypeForm = new FormGroup(group);


  }

  searchManageLogotype() {
    let getResult = [];
    const getData = this.selfManageLogotype;
    getData.filter((data, index) => {
      data.Translations.filter((ele) => {
        if (ele.PropertyName === "LogoTypeName") {
          let value = (ele.PropertyValue).toLowerCase();
          let searchData = (this.searchManageLogoType).toLowerCase();
          if ((value).includes(searchData)) {
            getResult.push(data)
          }
        }
      })
    });
    this.logoTypeList = getResult;

    this.loadParentDropDown();
    if (this.logoTypeList.length < 0) {
      this.hideSave = true
    }
  }

  resetFilter() {
    this.searchText = '';
    this.logoTypeList = this.selfManageLogotype;
    // this.hideSave = false
  }

  resetDropDown() {
    this.getLogoType();
  }

  loadParentDropDown() {
    this.loadPropertyDropdown.emit()
  }

  getLogoType() {
    // this.productService.loadLogoTypeAll();
    // this.productService.getLogoTypeAll().subscribe(data => {
    //   if (data) {
    //     this.logoTypeList = data;
    //     this.loadFormGroup();
    //     this.selfManageLogotype = data;
    //     this.selflogoTypeData = data;
    //     this.loadLogotypePaginationData();
    //   }

    // });

    this.searchCriteria = {
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      logotypeid: this.selectedlogotype,
      SupplierID: this.productDetails.SupplierID
    };
    this.productHttpService.getlogoTypePagination(this.searchCriteria).subscribe((data: any) => {
      this.logoTypeList = data.ItemsCollection;
      this.totalCount = data.TotalItemsCount;
      this.loadFormGroup();
      this.selfManageLogotype = data.ItemsCollection;
      this.selflogoTypeData = data.ItemsCollection;
      let getPageList = data.TotalItemsCount / this.selectedperpage;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = []
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;
      this.loadParentDropDown();
    });

  }


  addLogoTypeForm() {
    this.autoSave = true
    this.submitAdd = true;
    this.addLogo = true;
    const { logonameen0 } = this.addLogoType.value;
    if (logonameen0 === null || logonameen0 === '') {
      this.toast.error("Name is mandatory for English");
      return null
    }
    const addLogoData = [];
    this.getlang.forEach(element => {
      element.fields.forEach(fields => {
        const langData = {
          "LanguageID": element.LanguageID,
          "LanguageCode": element.Code,
          "ObjectType": "LogoTypes",
          "PropertyName": fields.fieldname,
          "PropertyValue": this.addLogoType.value[fields.formfield],
          "Action": "Insert",
          "isRequired": fields.isRequired
        };
        addLogoData.push(langData)

      });
    });
    const addLogoRequest = {
      "Action": "Insert",
      "Translations": addLogoData
    }
    this.addLogoDataPush.push(addLogoRequest)
    // }
    this.addLogoType.reset();
    this.logoTypeList.push(addLogoRequest);
    // }
    this.loadFormGroup();
    this.submitAdd = false;
    this.toast.success('Logotype added successfully');
    this.saveDatas();
    this.loadParentDropDown();
  }

  deleteLogotype(data, i) {

    // ParentlogoTypeList
    // LogoTypeID



    let isToBeDeleted = true


    this.loadParentDropDown();
    if (this.productPrice instanceof Array) {
      isToBeDeleted = !(this.productPrice.some(type => {
        if (type.Action === "Delete") return false
        const ProductPrintPricesFlag = type.ProductPrintPrices.some(price => {
          if (price.Action === "Delete") return false
          return price.LogoTypeID === data.LogoTypeID
        })
        return ProductPrintPricesFlag
      }))
    }
    if (isToBeDeleted) {
      this.deleteModal.show();
      this.areaDeleteData = data;
      this.areaDeleteData.index = i;
    } else {
      this.toast.error("This Option already Mapped. Cannot Be Deleted")
    }



  }

  getDeleteStatus(status) {
    if (status) {
      this.autoSave = true
      this.logoTypeList.splice(this.areaDeleteData.index, 1);
      this.areadelete.push(this.areaDeleteData);
      this.deleteModal.hide();
      this.saveDatas()
      this.loadParentDropDown();
    }
    else {
      this.deleteModal.hide();
    }
  }

  saveDatas() {
    let toastShowed = false
    if (this.addLogoDataPush.length > 0) {
      toastShowed = true
      const addLogoLength = this.addLogoDataPush.length - 1;
      this.addLogoDataPush.forEach((element, index) => {
        this.webService.addLogoType(element).subscribe((data: any) => {
          if (data) {
            if (index === addLogoLength) {
              if (!this.autoSave) {
                this.closeLogoType.emit()
                this.toast.success('Logotype Saved successfully');
              }
              this.getLogoType();
              this.addLogoDataPush = [];
              this.loadParentDropDown();
            }
          }
        })
      });
    }


    if (this.updateData.length > 0) {
      toastShowed = true
      const updateDataLength = this.updateData.length - 1;
      this.updateData.forEach((element, index) => {
        element.Action = 'Update';
        this.webService.updateLogoType(element).subscribe((data) => {
          if (data) {
            if (index === updateDataLength) {

              // if (!this.autoSave) {
              this.toast.success('Logotype updated successfully');
              // }
              this.getLogoType();
              // this.closeLogoType.emit()
              this.updateData = [];
              this.loadParentDropDown();
            }
          }
        })
      });
    }

    if (this.areadelete.length > 0) {
      const deleteLogoLength = this.areadelete.length - 1;
      this.areadelete.forEach((element, index) => {
        this.webService.deleteLogotype(element).subscribe((data: any) => {
          if (data && data !== null) {
            if (index === deleteLogoLength) {
              if (!toastShowed) {
                if (!this.autoSave) {
                  this.closeLogoType.emit()
                }
                this.toast.success('Logotype Deleted successfully')
              }
              this.getLogoType();
              this.loadParentDropDown();
              this.areadelete = [];
            }
          }
        })
      });

    }



  }

  getUpdateData(data, value, getValue) {
    data.Translations.forEach((element, index) => {

      const controlName = element.PropertyName + element.LanguageCode;
      if (controlName === value) {
        element.PropertyValue = this.logoTypeForm.value[getValue];
        element.Action = "Update"
      }
    });
    if (this.updateData.length === 0) {
      this.updateData.push(data);
    }
    else {
      //const arr = [...this.updateData]
      this.updateData.forEach((element, index) => {

        if (element.LogoTypeID === data.LogoTypeID) {
          // this.updateData.splice(index,1);
          // this.updateData.push(data)
          delete this.updateData[index];
          this.updateData[index] = data;
        }
        else {
          this.updateData.push(data);
        }
      });
      //this.updateData = arr

      const newArr = []
      this.updateData.forEach((item, index) => {
        if (newArr.findIndex(i => i.LogoTypeID == item.LogoTypeID) === -1) {
          newArr.push(item)
        }

      });
      this.updateData = newArr
    }


  }
  // loadLogotypePaginationData() {
  //   this.searchCriteria = {     
  //     PageIndex: this.selectedpage,
  //     PageSize: this.selectedperpage
  //   };
  //   this.productHttpService.getlogoTypePagination(this.searchCriteria).subscribe((data:any)=>{
  //       this.selflogoTypeData = data
  //       let getPageList = this.selflogoTypeData.length / this.selectedperpage;
  //       getPageList = Math.ceil(getPageList);
  //       this.pageList = Array.from({length: getPageList}, (v, k) => k+1);
  //       const pageLisArray = []
  //       for(let i=1; i <= getPageList; i++){
  //         pageLisArray.push({label: i, value:i})
  //       }
  //       this.pageList = pageLisArray;

  //   });

  // }
  pageChanged(page) {
    this.selectedpage = page;
    // this.loadPagination();
    this.getLogoType();
    // this.pageList = page;
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    // this.loadPagination();
    this.getLogoType();
    // this.rowPerPage=perpage;
  }

  loadPagination() {
    if (this.selflogoTypeData !== undefined) {
      let getPageList = this.selflogoTypeData.ItemsCollection.length / this.selectedperpage;
      getPageList = Math.ceil(getPageList);
      this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
      const pageLisArray = []
      for (let i = 1; i <= getPageList; i++) {
        pageLisArray.push({ label: i, value: i })
      }
      this.pageList = pageLisArray;

      const getArray = [];
      const startArray = this.selectedpage * this.selectedperpage - this.selectedperpage;
      const endArray = this.selectedpage * this.selectedperpage;
      this.selflogoTypeData.slice([startArray], [endArray]).map((item, i) => {
        getArray.push(item)
      });
      this.languageOption = getArray;

    }
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }

  autoCompletelogo(data) {

    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      supplierid: this.productDetails.SupplierID,
      selectedLangCode: this.selectedLangCode
    }
    this.groupCollection = [];
    this.productHttpService.autoCompleteLogoType(request).subscribe((res: any) => {
      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if (res) {
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedlogotype = selected.LogoTypeID;
    this.setPageSizeOptions(10);
  }

  onOptionSelected(event: any) {
    this.selectedlogotype = event.text.LogoTypeID
  }

  searchLogotype() {
    this.selectedpage = 1;
    this.getLogoType();
  }

  filterReset() {
    this.searchText = '';
    this.selectedlogotype = '';
    this.textNoResults = '';
    this.groupCollection = [];
    this.getLogoType();
  }

}
