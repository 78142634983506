import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ProductSearchComponent } from './product-search.component';
import { ProductHttpService } from '../state/services/product.http.service';
import { ProductService } from '../state/services/product.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [ProductSearchComponent],
  providers: [ProductHttpService, ProductService],
  exports: [ProductSearchComponent]
})

export class ProductSearchModule { }
