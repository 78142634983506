import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageboxState } from '../reducers/messagebox.reducer';
import { MessageboxActions } from '../actions/index';
import {
    getMessageinboxStateSuccess,
    getMessagedraftStateSuccess,
    getMessageinboxdetailsStateSuccess,
    getMessageimportantdetailsStateSuccess,
    getMessageimportantStateSuccess,
    getMessageinquiriesStateSuccess,
    getMessagetrashStateSuccess,
    getMessageclaimsStateSuccess,
    getMessagesentStateSuccess,
    getCustomerSuccess,
    BulkActionSuccess,
    getSendMessageSuccess,
    getSendDraftMessageSuccess,
    getDeleteMessageSuccess,
    getDeleteMailSuccess,
    getUserMailAddressSuccess,
    getMessageclaimsdetailsStateSuccess,
    getMessageinquiriesdetailsStateSuccess,
    getMessagereadStateSuccess,
    unreadMessageSuccess,
    claimsunreadMessageSuccess,
    inquriesunreadMessageSuccess
} from '../reducers/index';
import { Subject } from 'rxjs';

@Injectable()
export class MessageboxService {

    reloadTrashMessages: Subject<any> = new Subject();
    reloadMessages: Subject<any> = new Subject();
    constructor(private store: Store<MessageboxState>) { }

    selectedMessage(messageData) {
        this.store.dispatch(new MessageboxActions.SelectedMessage(messageData));
    }


    loadMessageboxState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageinboxState(searchCriteria));
    }
    getMessageInboxData() {
        return this.store.select(getMessageinboxStateSuccess);
    }


    // message inbox detail page
    loadMessageboxdetailState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageinboxdetailsState(searchCriteria));
    }
    getMessageInboxdetailsData() {
        return this.store.select(getMessageinboxdetailsStateSuccess);
    }

    // message inbox detail page
    loadMessageboximportantState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageimportantdetailsState(searchCriteria));
    }
    getMessageimportantdetailsData() {
        return this.store.select(getMessageimportantdetailsStateSuccess);
    }

    // message inbox detail page
    loadMessageboxclaimsState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageclaimsdetailsState(searchCriteria));
    }
    getMessageclaimsdetailsData() {
        return this.store.select(getMessageclaimsdetailsStateSuccess);
    }

    // message inquiries detail page
    loadMessageboxinquiriesdetailsState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageinquiriesdetailsState(searchCriteria));
    }
    getMessageinquiriesdetailsData() {
        return this.store.select(getMessageinquiriesdetailsStateSuccess);
    }

    loadMessagedraftState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageDraftState(searchCriteria));
    }
    getMessageDraftDatas() {
        return this.store.select(getMessagedraftStateSuccess);
    }


    // message important
    loadMessageimportantState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageImportantState(searchCriteria));
    }

    getMessageImportantData() {
        return this.store.select(getMessageimportantStateSuccess);
    }


    // message inquiries
    loadMessageinquiriesState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageInquiriesState(searchCriteria));
    }

    getMessageInquiriesData() {
        return this.store.select(getMessageinquiriesStateSuccess);
    }


    // message trash
    loadMessagetrashState(searchCriteria: any) {

        this.store.dispatch(new MessageboxActions.LoadMessageTrashState(searchCriteria));
    }

    getMessageTrashData() {
        return this.store.select(getMessagetrashStateSuccess);
    }

    // message claims
    loadMessageclaimsState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageClaimsState(searchCriteria));
    }

    getMessageClaimsData() {
        return this.store.select(getMessageclaimsStateSuccess);
    }

    // message sentmessage
    loadMessagesentState(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadMessageSentState(searchCriteria));
    }

    getMessageSentData() {
        return this.store.select(getMessagesentStateSuccess);
    }

    loadCustomers(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadCustomer(searchCriteria));
    }

    getCustomers() {
        return this.store.select(getCustomerSuccess);
    }

    loadbulkAction(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadBulkAction(searchCriteria))
    }

    bulkAction() {
        return this.store.select(BulkActionSuccess);
    }

    sendMessage(data) {
        this.store.dispatch(new MessageboxActions.SendMessage(data));
    }

    getSendMessageSuccess() {
        return this.store.select(getSendMessageSuccess);
    }
    sendDraftMessage(data) {
        this.store.dispatch(new MessageboxActions.SendDraftMessage(data));
    }

    getSendDraftMessageSuccess() {
        return this.store.select(getSendDraftMessageSuccess);
    }

    deleteMessage(data) {
        this.store.dispatch(new MessageboxActions.DeleteMessage(data));
    }
    deleteMail(data) {
        this.store.dispatch(new MessageboxActions.DeleteMail(data));
    }

    getDeleteMessageSuccess() {
        return this.store.select(getDeleteMessageSuccess);
    }
    getDeleteMailSuccess() {
        return this.store.select(getDeleteMailSuccess);
    }

    getUserMailAddress(data: any) {
        this.store.dispatch(new MessageboxActions.LoadUserMailAddress(data));
    }

    getUserMailAddressSuccess() {
        return this.store.select(getUserMailAddressSuccess);
    }


    // message read and unread 

    loadUnread(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadEmailUnread(searchCriteria))
    }

    unreadMessage() {
        return this.store.select(unreadMessageSuccess);
    }

    loadUnreadinquries(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadInquriesUnread(searchCriteria))
    }

    inquriesunreadMessage() {
        return this.store.select(inquriesunreadMessageSuccess);
    }

    loadUnreadclaims(searchCriteria: any) {
        this.store.dispatch(new MessageboxActions.LoadClaimsUnread(searchCriteria))
    }

    claimsunreadMessage() {
        return this.store.select(claimsunreadMessageSuccess);
    }

}

