import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CommonFilterComponent } from './common-filter.component';
import { FormsModule } from '@angular/forms';
import { FilterTagModule } from './filter-tags/filter-tags.module';
import { TooltipModule, ButtonsModule, WavesModule } from 'ng-uikit-pro-standard';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FilterTagModule,
    TooltipModule,
    ButtonsModule,
    WavesModule
  ],
  declarations: [CommonFilterComponent],
  exports: [CommonFilterComponent],
})

export class CommonFilterModule { }
