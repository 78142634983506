import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ColumnSetting } from '../../../shared/link-table/models/link-table-model';
import { ApiService } from '../../../shared/common-services/api.service';
import { MessageboxService } from '../../state/services/messagebox.service';
import { LinkTableService } from '../../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastService } from 'ng-uikit-pro-standard';
import { FormGroup, FormControl } from '@angular/forms';
import { MessageInboxService } from '../../message-inbox.service';
import { MessageboxHttpService } from '../../state/services/messagebox.http.service';

@Component({
  selector: 'app-message-inboxdetails',
  templateUrl: './message-inboxdetails.component.html',
  styleUrls: ['./message-inboxdetails.component.scss']
})
export class MessageInboxdetailsComponent implements OnInit {
  replyMessageForm = new FormGroup({
    mailBody: new FormControl()
  });
  mailInfo;
  customerList: any = [];
  showMessageDetailpage = true;
  customerId: any;
  messageinboxQueryParams: any = {};
  inboxdetailsData: any;
  emailid: any;
  filterFields: any;
  showReplyEditor = false;
  webshopDetail: any;
  constructor(public messageboxService: MessageboxService, public apiService: ApiService,
    private linkTableService: LinkTableService,
    private messageInboxService: MessageInboxService,
    private router: Router,
    private location: Location,
    private toast: ToastService,
    private messageboxhttpservice: MessageboxHttpService
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.inboxdetailsData = history.state.data;
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.mailInfo = window.history.state;
    this.emailid = this.mailInfo.data.EmailID;
    //this.MessageBoxLineData();
    this.filterFields = [{
      label: 'Delete',
      type: 'button',
      class: 'deleteico',
      key: 'messagedelete',
      model: 'deleteclaims'
    }];
    if (history.state.data.AdminRead !== 'Read') {
      const userSettings = JSON.parse(sessionStorage.getItem('userdetails'))
      this.updateStatus(history.state.data.emailid, userSettings.Roles[0].RoleName);
    }
  }
  ngOnChanges() {
  }

  updateStatus(id, role) {
    this.messageboxhttpservice.updateStatus(id, role).subscribe(
      (_data: any) => {

      }, error => {
        // this.toastService.error('Error: ' + error._body);

      }
    );
  }

  loadCustomer() {
    this.messageboxService.getCustomers().subscribe((data: any) => {
      if (data) {
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });
      }
    })
  }
  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.customerId = this.webshopDetail.CustomerID;
    this.messageinboxQueryParams.customerID = this.customerId;
    this.messageinboxQueryParams.UserID = loginData.UserID;
    this.messageboxService.loadCustomers(this.messageinboxQueryParams)
    // this.filterParams = {

    // }
  }
  MessageBoxLineData() {
    this.messageinboxQueryParams.emailID = this.emailid;
    this.messageboxService.loadMessageboxdetailState(this.messageinboxQueryParams);
    this.messageboxService.getMessageInboxdetailsData().subscribe((data: any) => {
      if (data) {
        this.inboxdetailsData = data;
        // const loginData = this.apiService.getLoginData();
        let unreadData = {
          emailid: this.inboxdetailsData.EmailID,
          customerid: this.webshopDetail.CustomerID
        }
        this.messageboxService.loadUnread(unreadData);
        setTimeout(() => {
          this.messageInboxService.updateReadUnreadCount.next(true);
        }, 300);
        // this.messageboxService.unreadMessage().subscribe(data => {
        //   console.log(data);
        // })
        // this.messageinbox = data.ItemsCollection;
        // this.router.navigate(['/admin-messagedetails'], { state: {data}, replaceUrl: false });
      }
    })
  }
  pageredirect() {
    this.router.navigate(['/app-message-inbox'], { replaceUrl: false });
  }


  getRequestBody(val) {
    const loginData = this.apiService.getLoginData();
    const requestBody = {
      "SenderID": loginData.UserID,
      "ReceiverID": this.mailInfo.data.SenderID,
      "SenderType": val == 'draft' ? 'SU' : this.mailInfo.data.SenderType,
      "ReceiverType": 'U',
      "Subject": this.mailInfo.data.Subject,
      "Body": this.replyMessageForm.value.mailBody,
      "Status": this.mailInfo.data.Status,
      "EmailType": "Email",
      "isDraft": val == 'draft' ? true : false,
      "CustomerID": this.mailInfo.data.CustomerID,
    };
    return requestBody;
  }

  sendMail() {
    this.messageboxService.sendMessage(this.getRequestBody('send'));
    this.messageboxService.getSendMessageSuccess().subscribe(data => {
      if (data == null) {
        this.toast.success('Message sent successfully');
        this.pageredirect();
      }
    });
  }

  draftMessage() {
    this.messageboxService.sendMessage(this.getRequestBody('draft'));
    this.messageboxService.getSendMessageSuccess().subscribe(data => {
      if (data == null) {
        this.toast.success('Draft Saved');
        this.pageredirect();
      }
    });
  }

  deleteMail(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deleteclaims) {
      // const loginData = this.apiService.getLoginData();
      // console.log('loginData', loginData);
      // console.log('mailInfo', this.mailInfo);
      // console.log('this.inboxdetailsData', this.inboxdetailsData);
      // const requestData = {
      //   "EmailID": this.inboxdetailsData.EmailID,
      //   "SenderID": this.inboxdetailsData.SenderID,
      //   "ReceiverID": this.inboxdetailsData.ReceiverID,
      //   "CustomerID": this.inboxdetailsData.CustomerID,
      //   "EmailTypeID": this.inboxdetailsData.EmailTypeID,
      //   "Subject": this.inboxdetailsData.Subject,
      //   "Body": this.inboxdetailsData.Body,
      //   "SenderType": this.inboxdetailsData.SenderType,
      //   "ReceiverType": this.inboxdetailsData.ReceiverType,
      //   "SendingTime": this.inboxdetailsData.SendingTime,
      //   "Status": this.inboxdetailsData.Status,
      //   "SenderName": this.inboxdetailsData.SenderName,
      //   "SenderEmail": this.inboxdetailsData.SenderEmail,
      //   "SenderImageName": this.inboxdetailsData.SenderImageName,
      //   "SenderImageURL": this.inboxdetailsData.SenderImageURL,
      //   "ReceiverName": this.inboxdetailsData.ReceiverName,
      //   "ReceiverEmail": this.inboxdetailsData.ReceiverEmail,
      //   "ReceiverImageName": this.inboxdetailsData.ReceiverImageName,
      //   "ReceiverImageURL": this.inboxdetailsData.ReceiverImageURL,
      //   "senderName": this.inboxdetailsData.SenderName,
      //   "senderEmail": this.inboxdetailsData.SenderEmail,
      //   "recieverName": this.inboxdetailsData.RecieverName,
      //   "recieverEmail": this.inboxdetailsData.RecieverEmail,
      //   "isTrash": true,
      //   "isDeleted": true,
      //   "UserID": loginData.UserID,
      //   "EmailType": "Email"
      // }

      // this.messageboxService.deleteMail(requestData);
      // this.messageboxService.getSendMessageSuccess().subscribe(data => {
      //   if (data == null) {
      //     setTimeout(() => {
      //       console.log('data', data);
      //       this.toast.success('Message Successfully Deleted');
      //       this.pageredirect();
      //     }, 300);
      //   }
      // });

      const loginData = this.apiService.getLoginData();
      const deleteData = {
        "ActionType": "TRASH",
        "Value": true,
        "EmailIDs": [this.inboxdetailsData.EmailID]
      }

      // this.messageboxservice.loadbulkAction(deleteData)
      this.messageboxhttpservice.bulkAction(deleteData, loginData.UserID).subscribe((bulkdata: any) => {
        if (bulkdata != null) {
          if (bulkdata) {
            setTimeout(() => {
              this.toast.success('Email deleted successfully');
              this.pageredirect();
            }, 300)
          }
          else {
            this.MessageBoxLineData();
          }
        }
      })




    }
  }


}
