import { Action } from '@ngrx/store';
export const LOAD_PRODUCTS = '[Product] Load all Products';
export const LOAD_PRODUCTS_SUCCESS = '[Product] Load Products Success';
export const LOAD_PRODUCTS_FAIL = '[Product] Load Products Fail';
export const LOAD_PRODUCT_DETAILS = '[Product] Load Product details';
export const LOAD_PRODUCT_DETAILS_SUCCESS = '[Product] Load Product details Success';
export const LOAD_PRODUCT_DETAILS_FAIL = '[Product] Load Product details Fail';
export const LOAD_SUPPLIERS = '[Product] Load all Suppliers';
export const LOAD_SUPPLIERS_SUCCESS = '[Product] Load Suppliers Success';
export const LOAD_SUPPLIERS_FAIL = '[Product] Load Suppliers Fail';
export const LOAD_CUSTOMER_INFO = '[Product] Load Customer info';
export const LOAD_CUSTOMER_INFO_SUCCESS = '[Product] Load Customer info Success';
export const LOAD_CUSTOMER_INFO_FAIL = '[Product] Load Customer info Fail';
export const LOAD_PRODUCT_COLORS = '[Product] Load Colors';
export const LOAD_PRODUCT_COLORS_SUCCESS = '[Product] Load Colors Success';
export const LOAD_PRODUCT_COLORS_FAIL = '[Product] Load Colors Fail';
export const LOAD_PRODUCT_SIZES = '[Product] Load En Sizes';
export const LOAD_PRODUCT_SIZES_SUCCESS = '[Product] Load En Sizes Success';
export const LOAD_PRODUCT_SIZES_FAIL = '[Product] Load En Sizes Fail';
export const LOAD_PRODUCT_LOGO_POSITION = '[Product] Load logo positions';
export const LOAD_PRODUCT_LOGO_POSITION_SUCCESS = '[Product] Load logo positions Success';
export const LOAD_PRODUCT_LOGO_POSITION_FAIL = '[Product] Load logo positions Fail';
export const LOAD_EN_PRODUCT_SIZES = '[Product] Load Sizes';
export const LOAD_EN_PRODUCT_SIZES_SUCCESS = '[Product] Load Sizes Success';
export const LOAD_EN_PRODUCT_SIZES_FAIL = '[Product] Load Sizes Fail';
export const LOAD_ALL_PRODUCT_COLORS = '[Product] Load all Colors';
export const LOAD_ALL_PRODUCT_COLORS_SUCCESS = '[Product] Load all Colors Success';
export const LOAD_ALL_PRODUCT_COLORS_FAIL = '[Product] Load all Colors Fail';
export const LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS = '[Product] Load all Governamental Costs';
export const LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS = '[Product] Load all Governamental Costs Success';
export const LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS_FAIL = '[Product] Load all Governamental Costs Fail';
export const LOAD_ALL_PRODUCT_ADDITIONAL_COSTS = '[Product] Load all Additional Costs';
export const LOAD_ALL_PRODUCT_ADDITIONAL_COSTS_SUCCESS = '[Product] Load all Additional Costs Success';
export const LOAD_ALL_PRODUCT_ADDITIONAL_COSTS_FAIL = '[Product] Load all Additional Costs Fail';
export const CHECK_PRODUCT_CATEGORY = '[Product] Check product category';
export const CHECK_PRODUCT_CATEGORY_SUCCESS = '[Product] Check product category Success';
export const CHECK_PRODUCT_CATEGORY_FAIL = '[Product] Check product category Fail';
export const LOAD_PARENT_PRODUCT_COLORS = '[Product] Load parent Colors';
export const LOAD_PARENT_PRODUCT_COLORS_SUCCESS = '[Product] Load parent Colors Success';
export const LOAD_PARENT_PRODUCT_COLORS_FAIL = '[Product] Load parent Colors Fail';
export const ADD_PRODUCT_COLOR = '[Product] Add Product Color';
export const ADD_PRODUCT_COLOR_SUCCESS = '[Product] Add Product Color Success';
export const ADD_PRODUCT_COLOR_FAIL = '[Product] Add Product Color Fail';
export const ADD_PRODUCT_GOVERNMENTAL_COSTS = '[Product] Add Product Governmental Cost';
export const ADD_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS = '[Product] Add Product Governmental Cost Success';
export const ADD_PRODUCT_GOVERNMENTAL_COSTS_FAIL = '[Product] Add Product Governmental Cost Fail';
export const ADD_PRODUCT_ADDITIONAL_COSTS = '[Product] Add Product Additional Cost';
export const ADD_PRODUCT_ADDITIONAL_COSTS_SUCCESS = '[Product] Add Product Additional Cost Success';
export const ADD_PRODUCT_ADDITIONAL_COSTS_FAIL = '[Product] Add Product Additional Cost Fail';
export const LOAD_PRODUCT_GOVERNMENTAL_COSTS = '[Product] Load Product Governmental Cost';
export const LOAD_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS = '[Product] Load Product Governmental Cost Success';
export const LOAD_PRODUCT_GOVERNMENTAL_COSTS_FAIL = '[Product] Load Product Governmental Cost Fail';
export const ADD_PRODUCT_SIZE = '[Product] Add Product Size';
export const ADD_PRODUCT_SIZE_SUCCESS = '[Product] Add Product Size Success';
export const ADD_PRODUCT_SIZE_FAIL = '[Product] Add Product Size Fail';
export const ADD_PRODUCT_LOGO_POSITION = '[Product] Add Product Logo Position';
export const ADD_PRODUCT_LOGO_POSITION_SUCCESS = '[Product] Add Product Logo Position Success';
export const ADD_PRODUCT_LOGO_POSITION_FAIL = '[Product] Add Product Logo Position Fail';
export const ADD_PRODUCT_PROPERTY = '[Product] Add Product Property';
export const ADD_PRODUCT_PROPERTY_SUCCESS = '[Product] Add Product Property Success';
export const ADD_PRODUCT_PROPERTY_FAIL = '[Product] Add Product Property Fail';
export const UPDATE_PRODUCT_COLOR = '[Product] Update Product Color';
export const UPDATE_PRODUCT_COLOR_SUCCESS = '[Product] Update Product Color Success';
export const UPDATE_PRODUCT_COLOR_FAIL = '[Product] Update Product Color Fail';
export const UPDATE_PRODUCT_GOVERNMENTAL_COSTS = '[Product] Update Product Governmental Cost';
export const UPDATE_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS = '[Product] Update Product Governmental Cost Success';
export const UPDATE_PRODUCT_GOVERNMENTAL_COSTS_FAIL = '[Product] Update Product Governmental Cost Fail';
export const UPDATE_PRODUCT_ADDITIONAL_COSTS = '[Product] Update Product Additional Cost';
export const UPDATE_PRODUCT_ADDITIONAL_COSTS_SUCCESS = '[Product] Update Product Additional Cost Success';
export const UPDATE_PRODUCT_ADDITIONAL_COSTS_FAIL = '[Product] Update Product Additional Cost Fail';
export const UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS = '[Product] Update All Product Additional Cost';
export const UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS_SUCCESS = '[Product] Update All Product Additional Cost Success';
export const UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS_FAIL = '[Product] Update All Product Additional Cost Fail';
export const UPDATE_PRODUCT_SIZE = '[Product] Update Product Size';
export const UPDATE_PRODUCT_SIZE_SUCCESS = '[Product] Update Product Size Success';
export const UPDATE_PRODUCT_SIZE_FAIL = '[Product] Update Product Size Fail';
export const UPDATE_PRODUCT_LOGO_POSITION = '[Product] Update Product Logo position';
export const UPDATE_PRODUCT_LOGO_POSITION_SUCCESS = '[Product] Update Product Logo position Success';
export const UPDATE_PRODUCT_LOGO_POSITION_FAIL = '[Product] Update Product Logo position Fail';
export const DELETE_PRODUCT_COLOR = '[Product] Delete Product Color';
export const DELETE_PRODUCT_COLOR_SUCCESS = '[Product] Delete Product Color Success';
export const DELETE_PRODUCT_COLOR_FAIL = '[Product] Delete Product Color Fail';
export const DELETE_PRODUCT_GOVERNMENTAL_COSTS = '[Product] Delete Product Governmental Costs';
export const DELETE_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS = '[Product] Delete Product Governmental Costs Success';
export const DELETE_PRODUCT_GOVERNMENTAL_COSTS_FAIL = '[Product] Delete Product Governmental Costs Fail';
export const DELETE_PRODUCT_ADDITIONAL_COSTS = '[Product] Delete Product Additional Costs';
export const DELETE_PRODUCT_ADDITIONAL_COSTS_SUCCESS = '[Product] Delete Product Additional Costs Success';
export const DELETE_PRODUCT_ADDITIONAL_COSTS_FAIL = '[Product] Delete Product Additional Costs Fail';
export const DELETE_PRODUCT_SIZE = '[Product] Delete Product Size';
export const DELETE_PRODUCT_SIZE_SUCCESS = '[Product] Delete Product Size Success';
export const DELETE_PRODUCT_SIZE_FAIL = '[Product] Delete Product Size Fail';
export const DELETE_PRODUCT_LOGO_POSITION = '[Product] Delete Product Logo Position';
export const DELETE_PRODUCT_LOGO_POSITION_SUCCESS = '[Product] Delete Product Logo Position Success';
export const DELETE_PRODUCT_LOGO_POSITION_FAIL = '[Product] Delete Product Logo Position Fail';
export const DELETE_PRODUCT_UNIT = '[Product] Delete Product Unit';
export const DELETE_PRODUCT_UNIT_SUCCESS = '[Product] Delete Product Unit Size Success';
export const DELETE_PRODUCT_UNIT_FAIL = '[Product] Delete Product Unit Fail';
export const DELETE_PRODUCT_PROPERTY = '[Product] Delete Product Property';
export const DELETE_PRODUCT_PROPERTY_SUCCESS = '[Product] Delete Product Property Success';
export const DELETE_PRODUCT_PROPERTY_FAIL = '[Product] Delete Product Property Fail';
export const LOAD_UNDER_SUPPLIERS = '[Product] Load all Under Suppliers';
export const LOAD_UNDER_SUPPLIERS_SUCCESS = '[Product] Load Under Suppliers Success';
export const LOAD_UNDER_SUPPLIERS_FAIL = '[Product] Load Under Suppliers Fail';
export const SAVE_PRODUCT = '[Product] Save Product';
export const SAVE_PRODUCT_SUCCESS = '[Product] Save Products Success';
export const SAVE_PRODUCTS_FAIL = '[Product] Save Product Fail';
export const UPDATE_PRODUCT = '[Product] Update Product';
export const UPDATE_PRODUCT_SUCCESS = '[Product] Update Products Success';
export const UPDATE_PRODUCTS_FAIL = '[Product] Update Product Fail';
export const DELETE_PRODUCT = '[Product] Delete Product';
export const DELETE_PRODUCT_SUCCESS = '[Product] Delete Products Success';
export const DELETE_PRODUCTS_FAIL = '[Product] Delete Product Fail';
export const LOAD_PRODUCT_UNITS = '[Product] Save Product Units';
export const LOAD_PRODUCT_UNITS_SUCCESS = '[Product] Save Products Units Success';
export const LOAD_PRODUCT_UNITS_FAIL = '[Product] Save Product Units Fail';
export const LOAD_PRODUCT_SUPPLIERS = '[Product] Save Product Suppliers';
export const LOAD_PRODUCT_SUPPLIERS_SUCCESS = '[Product] Save Products Suppliers Success';
export const LOAD_PRODUCT_SUPPLIERS_FAIL = '[Product] Save Product Suppliers Fail';
export const LOAD_PRODUCT_CURRENCY = '[Product] Save Product Currency';
export const LOAD_PRODUCT_CURRENCY_SUCCESS = '[Product] Save Products Currency Success';
export const LOAD_PRODUCT_CURRENCY_FAIL = '[Product] Save Product Currency Fail';
export const LOAD_PRODUCT_ADDITIONAL_COST_TYPES = '[Product] Load Product Additional Cost Types';
export const LOAD_PRODUCT_ADDITIONAL_COST_TYPES_SUCCESS = '[Product] Load Products Additional Cost Types Success';
export const LOAD_PRODUCT_ADDITIONAL_COST_TYPES_FAIL = '[Product] Load Product Additional Cost Types Fail';
export const LOAD_PRODUCT_CREATE = '[Product] Save Product Create';
export const LOAD_PRODUCT_CREATE_SUCCESS = '[Product] Save Products Create Success';
export const LOAD_PRODUCT_CREATE_FAIL = '[Product] Save Product Create Fail';
export const LOAD_PRODUCTS_CATEGORY = '[Product] Load Product Category';
export const LOAD_PRODUCTS_CATEGORY_SUCCESS = '[Product] Load Products Category Success';
export const LOAD_PRODUCTS_CATEGORY_FAIL = '[Product] Load Product Category Fail';
export const LOAD_PRODUCTS_PROPERTIES = '[Product] Load Product Properties';
export const LOAD_PRODUCTS_PROPERTIES_SUCCESS = '[Product] Load Products Properties Success';
export const LOAD_PRODUCTS_PROPERTIES_FAIL = '[Product] Load Product Properties Fail';
export const ADD_PRODUCT_UNITS = '[Product] Add Product Units';
export const ADD_PRODUCT_UNITS_SUCCESS = '[Product] Add Products Units Success';
export const ADD_PRODUCT_UNITS_FAIL = '[Product] Add Product Units Fail';
export const UPDATE_PRODUCT_UNITS = '[Product] Update Product Units';
export const UPDATE_PRODUCT_UNITS_SUCCESS = '[Product] Update Products Units Success';
export const UPDATE_PRODUCT_UNITS_FAIL = '[Product] Update Product Units Fail';
export const LOAD_ALL_PRODUCT_UNITS = '[Product] Load All Product Units';
export const LOAD_ALL_PRODUCT_UNITS_SUCCESS = '[Product] Load All Products Units Success';
export const LOAD_ALL_PRODUCT_UNITS_FAIL = '[Product] Load All Product Units Fail';

export const LOAD_PRODUCT_TOC = '[Product] Load Transportation Origin Countries';
export const LOAD_PRODUCT_TOC_SUCCESS = '[Product] Load Transportation Origin Countries Success';
export const LOAD_PRODUCT_TOC_FAIL = '[Product] Load Transportation Origin Countries Fail';


export const LOAD_MANUALID_VALIDATION = '[Product] Manual Id validation';
export const LOAD_MANUALID_VALIDATION_SUCCESS = '[Product] Manual Id validation Success';
export const LOAD_MANUALID_VALIDATION_FAIL = '[Product] Manual Id validation Fail';
export const LOAD_UNITS = '[Product] Units';
export const LOAD_UNITS_SUCCESS = '[Product] Units Success';
export const LOAD_UNITS_FAIL = '[Product] Units Fail';

export const LOAD_LOGOTYPES = '[Product] LogoType';
export const LOAD_LOGOTYPES_SUCCESS = '[Product] LogoType Success';
export const LOAD_LOGOTYPES_FAIL = '[Product] LogoType Fail';

export const LOAD_LOGOTYPESAll = '[Product] LogoTypeAll';
export const LOAD_LOGOTYPESAll_SUCCESS = '[Product] LogoTypeAll Success';
export const LOAD_LOGOTYPESAll_FAIL = '[Product] LogoTypeAll Fail';

export const LOAD_LOGOAREA = '[Product] LogoArea';
export const LOAD_LOGOAREA_SUCCESS = '[Product] LogoArea Success';
export const LOAD_LOGOAREA_FAIL = '[Product] LogoArea Fail';
export const LOAD_CHANGEVISIBILITY = '[Product] ChangeVisibility';
export const LOAD_CHANGEVISIBILITY_SUCCESS = '[Product] ChangeVisibility Success';
export const LOAD_CHANGEVISIBILITY_FAIL = '[Product] ChangeVisibility Fail';

export const LOAD_LOGOMAXAREA = '[Product] logomaxarea';
export const LOAD_LOGOMAXAREA_SUCCESS = '[Product] logomaxarea Success';
export const LOAD_LOGOMAXAREA_FAIL = '[Product] logomaxarea Fail';

export const LOAD_LOGOMAXAREADEL = '[Product] logomaxareadel';
export const LOAD_LOGOMAXAREADEL_SUCCESS = '[Product] logomaxareadel Success';
export const LOAD_LOGOMAXAREADEL_FAIL = '[Product] logomaxareadel Fail';

export const LOAD_LOGOMAXAREAUPDATE = '[Product] logomaxareaUpdate';
export const LOAD_LOGOMAXAREAUPDATE_SUCCESS = '[Product] logomaxareaUpdate Success';
export const LOAD_LOGOMAXAREAUPDATE_FAIL = '[Product] logomaxareaUpdate Fail';

export const LOAD_HANDLINGCOST = '[Product] handlingcost';
export const LOAD_HANDLINGCOST_SUCCESS = '[Product] handlingcost Success';
export const LOAD_HANDLINGCOST_FAIL = '[Product] handlingcost Fail';

export const LOAD_CUSTOMERPRODUCT = '[Product] customerproduct';
export const LOAD_CUSTOMERPRODUCT_SUCCESS = '[Product] customerproduct Success';
export const LOAD_CUSTOMERPRODUCT_FAIL = '[Product] customerproduct Fail';





export class LoadProducts implements Action {
    readonly type = LOAD_PRODUCTS;
    constructor(public payload: any) { }
}

export class LoadProductsSuccess implements Action {
    readonly type = LOAD_PRODUCTS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductsFail implements Action {
    readonly type = LOAD_PRODUCTS_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductDetails implements Action {
    readonly type = LOAD_PRODUCT_DETAILS;
    constructor(public payload: any) { }
}

export class LoadProductDetailsSuccess implements Action {
    readonly type = LOAD_PRODUCT_DETAILS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductDetailsFail implements Action {
    readonly type = LOAD_PRODUCT_DETAILS_FAIL;
    constructor(public payload: any) { }
}

export class SaveProduct implements Action {
    readonly type = SAVE_PRODUCT;
    constructor(public payload: any) { }
}

export class SaveProductSuccess implements Action {
    readonly type = SAVE_PRODUCT_SUCCESS;
    constructor(public payload: any) { }
}

export class SaveProductFail implements Action {
    readonly type = SAVE_PRODUCTS_FAIL;
    constructor(public payload: any) { }
}
export class UpdateProduct implements Action {
    readonly type = UPDATE_PRODUCT;
    constructor(public payload: any) { }
}

export class UpdateProductSuccess implements Action {
    readonly type = UPDATE_PRODUCT_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductFail implements Action {
    readonly type = UPDATE_PRODUCTS_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProduct implements Action {
    readonly type = DELETE_PRODUCT;
    constructor(public payload: any) { }
}

export class DeleteProductSuccess implements Action {
    readonly type = DELETE_PRODUCT_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductFail implements Action {
    readonly type = DELETE_PRODUCTS_FAIL;
    constructor(public payload: any) { }
}


export class LoadSuppliers implements Action {
    readonly type = LOAD_SUPPLIERS;
    constructor() { }
}

export class LoadSuppliersSuccess implements Action {
    readonly type = LOAD_SUPPLIERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadSuppliersFail implements Action {
    readonly type = LOAD_SUPPLIERS_FAIL;
    constructor(public payload: any) { }
}

export class LoadCustomerInfo implements Action {
    readonly type = LOAD_CUSTOMER_INFO;
    constructor(public payload: any) { }
}

export class LoadCustomerInfoSuccess implements Action {
    readonly type = LOAD_CUSTOMER_INFO_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCustomerInfoFail implements Action {
    readonly type = LOAD_CUSTOMER_INFO_FAIL;
    constructor(public payload: any) { }
}
export class LoadProductColors implements Action {
    readonly type = LOAD_PRODUCT_COLORS;
    constructor(public payload: any) { }
}

export class LoadProductColorsSuccess implements Action {
    readonly type = LOAD_PRODUCT_COLORS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductColorsFail implements Action {
    readonly type = LOAD_PRODUCT_COLORS_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductSizes implements Action {
    readonly type = LOAD_PRODUCT_SIZES;
    constructor(public payload: any) { }
}

export class LoadProductSizesSuccess implements Action {
    readonly type = LOAD_PRODUCT_SIZES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductSizesFail implements Action {
    readonly type = LOAD_PRODUCT_SIZES_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductLogoPosition implements Action {
    readonly type = LOAD_PRODUCT_LOGO_POSITION;
    constructor(public payload: any) { }
}

export class LoadProductLogoPositionSuccess implements Action {
    readonly type = LOAD_PRODUCT_LOGO_POSITION_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductLogoPositionFail implements Action {
    readonly type = LOAD_PRODUCT_LOGO_POSITION_FAIL;
    constructor(public payload: any) { }
}

export class LoadEnProductSizes implements Action {
    readonly type = LOAD_EN_PRODUCT_SIZES;
    constructor(public payload: any) { }
}

export class LoadEnProductSizesSuccess implements Action {
    readonly type = LOAD_EN_PRODUCT_SIZES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadEnProductSizesFail implements Action {
    readonly type = LOAD_EN_PRODUCT_SIZES_FAIL;
    constructor(public payload: any) { }
}

export class LoadAllProductColors implements Action {
    readonly type = LOAD_ALL_PRODUCT_COLORS;
    constructor(public payload: any) { }
}

export class LoadAllProductColorsSuccess implements Action {
    readonly type = LOAD_ALL_PRODUCT_COLORS_SUCCESS;
    constructor(public payload: any) { }
}


export class LoadAllProductColorsFail implements Action {
    readonly type = LOAD_ALL_PRODUCT_COLORS_FAIL;
    constructor(public payload: any) { }
}

export class LoadAllProductGovtCosts implements Action {
    readonly type = LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS;
    constructor(public payload: any) { }
}

export class LoadAllProductGovtCostsSuccess implements Action {
    readonly type = LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadAllProductGovtCostsFail implements Action {
    readonly type = LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class LoadAllProductAdditionalCosts implements Action {
    readonly type = LOAD_ALL_PRODUCT_ADDITIONAL_COSTS;
    constructor(public payload: any) { }
}

export class LoadAllProductAdditionalCostsSuccess implements Action {
    readonly type = LOAD_ALL_PRODUCT_ADDITIONAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadAllProductAdditionalCostsFail implements Action {
    readonly type = LOAD_ALL_PRODUCT_ADDITIONAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class CheckProductCategory implements Action {
    readonly type = CHECK_PRODUCT_CATEGORY;
    constructor(public payload: any) { }
}

export class CheckProductCategorySuccess implements Action {
    readonly type = CHECK_PRODUCT_CATEGORY_SUCCESS;
    constructor(public payload: any) { }
}

export class CheckProductCategoryFail implements Action {
    readonly type = CHECK_PRODUCT_CATEGORY_FAIL;
    constructor(public payload: any) { }
}

export class LoadParentProductColors implements Action {
    readonly type = LOAD_PARENT_PRODUCT_COLORS;
    constructor(public payload: any) { }
}

export class LoadParentProductColorsSuccess implements Action {
    readonly type = LOAD_PARENT_PRODUCT_COLORS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadParentProductColorsFail implements Action {
    readonly type = LOAD_PARENT_PRODUCT_COLORS_FAIL;
    constructor(public payload: any) { }
}
export class AddProductColor implements Action {
    readonly type = ADD_PRODUCT_COLOR;
    constructor(public payload: any) { }
}

export class AddProductColorSuccess implements Action {
    readonly type = ADD_PRODUCT_COLOR_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductColorFail implements Action {
    readonly type = ADD_PRODUCT_COLOR_FAIL;
    constructor(public payload: any) { }
}

export class AddProductGovernmentalCosts implements Action {
    readonly type = ADD_PRODUCT_GOVERNMENTAL_COSTS;
    constructor(public payload: any) { }
}

export class AddProductGovernmentalCostsSuccess implements Action {
    readonly type = ADD_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductGovernmentalCostsFail implements Action {
    readonly type = ADD_PRODUCT_GOVERNMENTAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class AddProductAdditionalCosts implements Action {
    readonly type = ADD_PRODUCT_ADDITIONAL_COSTS;
    constructor(public payload: any) { }
}

export class AddProductAdditionalCostsSuccess implements Action {
    readonly type = ADD_PRODUCT_ADDITIONAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductAdditionalCostsFail implements Action {
    readonly type = ADD_PRODUCT_ADDITIONAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductGovernmentalCosts implements Action {
    readonly type = LOAD_PRODUCT_GOVERNMENTAL_COSTS;
    constructor() { }
}

export class LoadProductGovernmentalCostsSuccess implements Action {
    readonly type = LOAD_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductGovernmentalCostsFail implements Action {
    readonly type = LOAD_PRODUCT_GOVERNMENTAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class AddProductSize implements Action {
    readonly type = ADD_PRODUCT_SIZE;
    constructor(public payload: any) { }
}

export class AddProductSizeSuccess implements Action {
    readonly type = ADD_PRODUCT_SIZE_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductSizeFail implements Action {
    readonly type = ADD_PRODUCT_SIZE_FAIL;
    constructor(public payload: any) { }
}

export class AddProductLogoPosition implements Action {
    readonly type = ADD_PRODUCT_LOGO_POSITION;
    constructor(public payload: any) { }
}

export class AddProductLogoPositionSuccess implements Action {
    readonly type = ADD_PRODUCT_LOGO_POSITION_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductLogoPositionFail implements Action {
    readonly type = ADD_PRODUCT_LOGO_POSITION_FAIL;
    constructor(public payload: any) { }
}

export class AddProductProperties implements Action {
    readonly type = ADD_PRODUCT_PROPERTY;
    constructor(public payload: any) { }
}

export class AddProductPropertiesSuccess implements Action {
    readonly type = ADD_PRODUCT_PROPERTY_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductPropertiesFail implements Action {
    readonly type = ADD_PRODUCT_PROPERTY_FAIL;
    constructor(public payload: any) { }
}

export class UpdateProductColor implements Action {
    readonly type = UPDATE_PRODUCT_COLOR;
    constructor(public payload: any) { }
}

export class UpdateProductColorSuccess implements Action {
    readonly type = UPDATE_PRODUCT_COLOR_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductColorFail implements Action {
    readonly type = UPDATE_PRODUCT_COLOR_FAIL;
    constructor(public payload: any) { }
}

export class UpdateProductGovernmentalCosts implements Action {
    readonly type = UPDATE_PRODUCT_GOVERNMENTAL_COSTS;
    constructor(public payload: any) { }
}

export class UpdateProductGovernmentalCostsSuccess implements Action {
    readonly type = UPDATE_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductGovernmentalCostsFail implements Action {
    readonly type = UPDATE_PRODUCT_GOVERNMENTAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class UpdateProductAdditionalCosts implements Action {
    readonly type = UPDATE_PRODUCT_ADDITIONAL_COSTS;
    constructor(public payload: any) { }
}

export class UpdateProductAdditionalCostsSuccess implements Action {
    readonly type = UPDATE_PRODUCT_ADDITIONAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductAdditionalCostsFail implements Action {
    readonly type = UPDATE_PRODUCT_ADDITIONAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class UpdateAllProductAdditionalCosts implements Action {
    readonly type = UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS;
    constructor(public payload: any) { }
}

export class UpdateAllProductAdditionalCostsSuccess implements Action {
    readonly type = UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateAllProductAdditionalCostsFail implements Action {
    readonly type = UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class UpdateProductSize implements Action {
    readonly type = UPDATE_PRODUCT_SIZE;
    constructor(public payload: any) { }
}

export class UpdateProductSizeSuccess implements Action {
    readonly type = UPDATE_PRODUCT_SIZE_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductSizeFail implements Action {
    readonly type = UPDATE_PRODUCT_SIZE_FAIL;
    constructor(public payload: any) { }
}

export class UpdateProductLogoPosition implements Action {
    readonly type = UPDATE_PRODUCT_LOGO_POSITION;
    constructor(public payload: any) { }
}

export class UpdateProductLogoPositionSuccess implements Action {
    readonly type = UPDATE_PRODUCT_LOGO_POSITION_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductLogoPositionFail implements Action {
    readonly type = UPDATE_PRODUCT_LOGO_POSITION_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductColor implements Action {
    readonly type = DELETE_PRODUCT_COLOR;
    constructor(public payload: any) { }
}

export class DeleteProductColorSuccess implements Action {
    readonly type = DELETE_PRODUCT_COLOR_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductColorFail implements Action {
    readonly type = DELETE_PRODUCT_COLOR_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductGovernmentalCosts implements Action {
    readonly type = DELETE_PRODUCT_GOVERNMENTAL_COSTS;
    constructor(public payload: any) { }
}

export class DeleteProductGovernmentalCostsSuccess implements Action {
    readonly type = DELETE_PRODUCT_GOVERNMENTAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductGovernmentalCostsFail implements Action {
    readonly type = DELETE_PRODUCT_GOVERNMENTAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductAdditionalCosts implements Action {
    readonly type = DELETE_PRODUCT_ADDITIONAL_COSTS;
    constructor(public payload: any) { }
}

export class DeleteProductAdditionalCostsSuccess implements Action {
    readonly type = DELETE_PRODUCT_ADDITIONAL_COSTS_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductAdditionalCostsFail implements Action {
    readonly type = DELETE_PRODUCT_ADDITIONAL_COSTS_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductSize implements Action {
    readonly type = DELETE_PRODUCT_SIZE;
    constructor(public payload: any) { }
}

export class DeleteProductSizeSuccess implements Action {
    readonly type = DELETE_PRODUCT_SIZE_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductSizeFail implements Action {
    readonly type = DELETE_PRODUCT_SIZE_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductLogoPosition implements Action {
    readonly type = DELETE_PRODUCT_LOGO_POSITION;
    constructor(public payload: any) { }
}

export class DeleteProductLogoPositionSuccess implements Action {
    readonly type = DELETE_PRODUCT_LOGO_POSITION_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductLogoPositionFail implements Action {
    readonly type = DELETE_PRODUCT_LOGO_POSITION_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductUnit implements Action {
    readonly type = DELETE_PRODUCT_UNIT;
    constructor(public payload: any) { }
}

export class DeleteProductUnitSuccess implements Action {
    readonly type = DELETE_PRODUCT_UNIT_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductUnitFail implements Action {
    readonly type = DELETE_PRODUCT_UNIT_FAIL;
    constructor(public payload: any) { }
}

export class DeleteProductProperties implements Action {
    readonly type = DELETE_PRODUCT_PROPERTY;
    constructor(public payload: any) { }
}

export class DeleteProductPropertiesSuccess implements Action {
    readonly type = DELETE_PRODUCT_PROPERTY_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteProductPropertiesFail implements Action {
    readonly type = DELETE_PRODUCT_PROPERTY_FAIL;
    constructor(public payload: any) { }
}

export class LoadUnderSuppliers implements Action {
    readonly type = LOAD_UNDER_SUPPLIERS;
    constructor() { }
}

export class LoadUnderSuppliersSuccess implements Action {
    readonly type = LOAD_UNDER_SUPPLIERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadUnderSuppliersFail implements Action {
    readonly type = LOAD_UNDER_SUPPLIERS_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductsCategory implements Action {
    readonly type = LOAD_PRODUCTS_CATEGORY;
    constructor(public payload: any) { }
}

export class LoadProductsCategorySuccess implements Action {
    readonly type = LOAD_PRODUCTS_CATEGORY_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductsCategoryFail implements Action {
    readonly type = LOAD_PRODUCTS_CATEGORY_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductProperties implements Action {
    readonly type = LOAD_PRODUCTS_PROPERTIES;
    constructor() { }
}

export class LoadProductPropertiesSuccess implements Action {
    readonly type = LOAD_PRODUCTS_PROPERTIES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductPropertiesFail implements Action {
    readonly type = LOAD_PRODUCTS_PROPERTIES_FAIL;
    constructor(public payload: any) { }
}

export class AddProductUnits implements Action {
    readonly type = ADD_PRODUCT_UNITS;
    constructor(public payload: any) { }
}

export class AddProductUnitsSuccess implements Action {
    readonly type = ADD_PRODUCT_UNITS_SUCCESS;
    constructor(public payload: any) { }
}

export class AddProductUnitsFail implements Action {
    readonly type = ADD_PRODUCT_UNITS_FAIL;
    constructor(public payload: any) { }
}

export class UpdateProductUnits implements Action {
    readonly type = UPDATE_PRODUCT_UNITS;
    constructor(public payload: any) { }
}

export class UpdateProductUnitsSuccess implements Action {
    readonly type = UPDATE_PRODUCT_UNITS_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateProductUnitsFail implements Action {
    readonly type = UPDATE_PRODUCT_UNITS_FAIL;
    constructor(public payload: any) { }
}
export class LoadProductUnits implements Action {
    readonly type = LOAD_PRODUCT_UNITS;
    constructor() { }
}

export class LoadProductUnitsSuccess implements Action {
    readonly type = LOAD_PRODUCT_UNITS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductUnitsFail implements Action {
    readonly type = LOAD_PRODUCT_UNITS_FAIL;
    constructor(public payload: any) { }
}

export class LoadAllProductUnits implements Action {
    readonly type = LOAD_ALL_PRODUCT_UNITS;
    constructor() { }
}

export class LoadAllProductUnitsSuccess implements Action {
    readonly type = LOAD_ALL_PRODUCT_UNITS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadAllProductUnitsFail implements Action {
    readonly type = LOAD_ALL_PRODUCT_UNITS_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductSuppliers implements Action {
    readonly type = LOAD_PRODUCT_SUPPLIERS;
    constructor() { }
}

export class LoadProductsSuppliersSuccess implements Action {
    readonly type = LOAD_PRODUCT_SUPPLIERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductsSuppliersFail implements Action {
    readonly type = LOAD_PRODUCT_SUPPLIERS_FAIL;
    constructor(public payload: any) { }
}

export class LoadTransportationOriginCountries implements Action {
    readonly type = LOAD_PRODUCT_TOC;
    constructor() { }
}

export class LoadTransportationOriginCountriesSuccess implements Action {
    readonly type = LOAD_PRODUCT_TOC_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadTransportationOriginCountriesFail implements Action {
    readonly type = LOAD_PRODUCT_TOC_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductCurrency implements Action {
    readonly type = LOAD_PRODUCT_CURRENCY;
    constructor() { }
}

export class LoadProductCurrencySuccess implements Action {
    readonly type = LOAD_PRODUCT_CURRENCY_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductCurrencyFail implements Action {
    readonly type = LOAD_PRODUCT_CURRENCY_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductAdditionalCostTypes implements Action {
    readonly type = LOAD_PRODUCT_ADDITIONAL_COST_TYPES;
    constructor() { }
}

export class LoadProductAdditionalCostTypesSuccess implements Action {
    readonly type = LOAD_PRODUCT_ADDITIONAL_COST_TYPES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductAdditionalCostTypesFail implements Action {
    readonly type = LOAD_PRODUCT_ADDITIONAL_COST_TYPES_FAIL;
    constructor(public payload: any) { }
}

export class LoadProductCreate implements Action {
    readonly type = LOAD_PRODUCT_CREATE;
    constructor(public payload: any) { }
}

export class LoadProductCreateSuccess implements Action {
    readonly type = LOAD_PRODUCT_CREATE_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadProductCreateFail implements Action {
    readonly type = LOAD_PRODUCT_CREATE_FAIL;
    constructor(public payload: any) { }
}

export class LoadManualID implements Action {
    readonly type = LOAD_MANUALID_VALIDATION;
    constructor(public payload: any) { }
}

export class LoadManualIDSuccess implements Action {
    readonly type = LOAD_MANUALID_VALIDATION_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadManualIDFail implements Action {
    readonly type = LOAD_MANUALID_VALIDATION_FAIL;
    constructor(public payload: any) { }
}

export class LoadUnits implements Action {
    readonly type = LOAD_UNITS;
    constructor() { }
}

export class LoadUnitsSuccess implements Action {
    readonly type = LOAD_UNITS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadUnitsFail implements Action {
    readonly type = LOAD_UNITS_FAIL;
    constructor(public payload: any) { }
}

export class LoadLogoType implements Action {
    readonly type = LOAD_LOGOTYPES;
    constructor() { }
}

export class LoadLogoTypeSuccess implements Action {
    readonly type = LOAD_LOGOTYPES_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadLogoTypeFail implements Action {
    readonly type = LOAD_LOGOTYPES_FAIL;
    constructor(public payload: any) { }
}

export class LoadTypeAll implements Action {
    readonly type = LOAD_LOGOTYPESAll;
    constructor() { }
}

export class LoadTypeAllSuccess implements Action {
    readonly type = LOAD_LOGOTYPESAll_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadTypeAllFail implements Action {
    readonly type = LOAD_LOGOTYPESAll_FAIL;
    constructor(public payload: any) { }
}

export class LoadLogoArea implements Action {
    readonly type = LOAD_LOGOAREA;
    constructor(public payload: any) { }
}

export class LoadLogoAreaSuccess implements Action {
    readonly type = LOAD_LOGOAREA_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadLogoAreaFail implements Action {
    readonly type = LOAD_LOGOAREA_FAIL;
    constructor(public payload: any) { }
}


export class LoadChangeVisibility implements Action {
    readonly type = LOAD_CHANGEVISIBILITY;
    constructor(public payload: any) { }
}

export class LoadChangeVisibilitySuccess implements Action {
    readonly type = LOAD_CHANGEVISIBILITY_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadChangeVisibilityFail implements Action {
    readonly type = LOAD_CHANGEVISIBILITY_FAIL;
    constructor(public payload: any) { }
}

export class LoadMaxArea implements Action {
    readonly type = LOAD_LOGOMAXAREA;
    constructor(public payload: any) { }
}

export class LoadMaxAreaSuccess implements Action {
    readonly type = LOAD_LOGOMAXAREA_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadMaxAreaFail implements Action {
    readonly type = LOAD_LOGOMAXAREA_FAIL;
    constructor(public payload: any) { }
}

export class LoadAreaDel implements Action {
    readonly type = LOAD_LOGOMAXAREADEL;
    constructor(public payload: any) { }
}

export class LoadAreaDelSuccess implements Action {
    readonly type = LOAD_LOGOMAXAREADEL_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadAreaDelFail implements Action {
    readonly type = LOAD_LOGOMAXAREADEL_FAIL;
    constructor(public payload: any) { }
}

export class LoadAreaUpdate implements Action {
    readonly type = LOAD_LOGOMAXAREAUPDATE;
    constructor(public payload: any) { }
}

export class LoadAreaUpdateSuccess implements Action {
    readonly type = LOAD_LOGOMAXAREAUPDATE_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadAreaUpdateFail implements Action {
    readonly type = LOAD_LOGOMAXAREAUPDATE_FAIL;
    constructor(public payload: any) { }
}

export class LoadHandlingCost implements Action {
    readonly type = LOAD_HANDLINGCOST;
    constructor() { }
}

export class LoadHandlingCostSuccess implements Action {
    readonly type = LOAD_HANDLINGCOST_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadHandlingCostFail implements Action {
    readonly type = LOAD_HANDLINGCOST_FAIL;
    constructor(public payload: any) { }
}

export class LoadCustomerProducts implements Action {
    readonly type = LOAD_CUSTOMERPRODUCT;
    constructor(public payload: any) { }
}

export class LoadCustomerProductsSuccess implements Action {
    readonly type = LOAD_CUSTOMERPRODUCT_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCustomerProductsFail implements Action {
    readonly type = LOAD_CUSTOMERPRODUCT_FAIL;
    constructor(public payload: any) { }
}



export type All =
    | LoadProducts
    | LoadProductsSuccess
    | LoadProductsFail
    | LoadProductDetails
    | LoadProductDetailsSuccess
    | LoadProductDetailsFail
    | SaveProduct
    | SaveProductSuccess
    | SaveProductFail
    | UpdateProduct
    | UpdateProductSuccess
    | UpdateProductFail
    | DeleteProduct
    | DeleteProductSuccess
    | DeleteProductFail
    | LoadSuppliers
    | LoadSuppliersSuccess
    | LoadSuppliersFail
    | LoadCustomerInfo
    | LoadCustomerInfoSuccess
    | LoadCustomerInfoFail
    | AddProductColor
    | AddProductColorSuccess
    | AddProductColorFail
    | AddProductGovernmentalCosts
    | AddProductGovernmentalCostsSuccess
    | AddProductGovernmentalCostsFail
    | AddProductAdditionalCosts
    | AddProductAdditionalCostsSuccess
    | AddProductAdditionalCostsFail
    | LoadProductGovernmentalCosts
    | LoadProductGovernmentalCostsSuccess
    | LoadProductGovernmentalCostsFail
    | AddProductSize
    | AddProductSizeSuccess
    | AddProductSizeFail
    | AddProductLogoPosition
    | AddProductLogoPositionSuccess
    | AddProductLogoPositionFail
    | AddProductProperties
    | AddProductPropertiesSuccess
    | AddProductPropertiesFail
    | UpdateProductColor
    | UpdateProductColorSuccess
    | UpdateProductColorFail
    | UpdateProductGovernmentalCosts
    | UpdateProductGovernmentalCostsSuccess
    | UpdateProductGovernmentalCostsFail
    | UpdateProductAdditionalCosts
    | UpdateProductAdditionalCostsSuccess
    | UpdateProductAdditionalCostsFail
    | UpdateAllProductAdditionalCosts
    | UpdateAllProductAdditionalCostsSuccess
    | UpdateAllProductAdditionalCostsFail
    | UpdateProductSize
    | UpdateProductSizeSuccess
    | UpdateProductSizeFail
    | UpdateProductLogoPosition
    | UpdateProductLogoPositionSuccess
    | UpdateProductLogoPositionFail
    | DeleteProductColor
    | DeleteProductColorSuccess
    | DeleteProductColorFail
    | DeleteProductGovernmentalCosts
    | DeleteProductGovernmentalCostsSuccess
    | DeleteProductGovernmentalCostsFail
    | DeleteProductAdditionalCosts
    | DeleteProductAdditionalCostsSuccess
    | DeleteProductAdditionalCostsFail
    | DeleteProductSize
    | DeleteProductSizeSuccess
    | DeleteProductSizeFail
    | DeleteProductLogoPosition
    | DeleteProductLogoPositionSuccess
    | DeleteProductLogoPositionFail
    | DeleteProductUnit
    | DeleteProductUnitSuccess
    | DeleteProductUnitFail
    | DeleteProductProperties
    | DeleteProductPropertiesSuccess
    | DeleteProductPropertiesFail
    | LoadProductColors
    | LoadProductColorsSuccess
    | LoadProductColorsFail
    | LoadProductSizes
    | LoadProductSizesSuccess
    | LoadProductSizesFail
    | LoadProductLogoPosition
    | LoadProductLogoPositionSuccess
    | LoadProductLogoPositionFail
    | LoadEnProductSizes
    | LoadEnProductSizesSuccess
    | LoadEnProductSizesFail
    | LoadAllProductColors
    | LoadAllProductColorsSuccess
    | LoadAllProductColorsFail
    | LoadAllProductGovtCosts
    | LoadAllProductGovtCostsSuccess
    | LoadAllProductGovtCostsFail
    | LoadAllProductAdditionalCosts
    | LoadAllProductAdditionalCostsSuccess
    | LoadAllProductAdditionalCostsFail
    | CheckProductCategory
    | CheckProductCategorySuccess
    | CheckProductCategoryFail
    | LoadParentProductColors
    | LoadParentProductColorsSuccess
    | LoadParentProductColorsFail
    | LoadUnderSuppliers
    | LoadUnderSuppliersSuccess
    | LoadUnderSuppliersFail
    | LoadProductsCategory
    | LoadProductsCategorySuccess
    | LoadProductsCategoryFail
    | LoadProductProperties
    | LoadProductPropertiesSuccess
    | LoadProductPropertiesFail
    | LoadProductUnits
    | LoadProductUnitsSuccess
    | LoadProductUnitsFail
    | AddProductUnits
    | AddProductUnitsSuccess
    | AddProductUnitsFail
    | UpdateProductUnits
    | UpdateProductUnitsSuccess
    | UpdateProductUnitsFail
    | LoadAllProductUnits
    | LoadAllProductUnitsSuccess
    | LoadAllProductUnitsFail
    | LoadProductSuppliers
    | LoadProductsSuppliersSuccess
    | LoadProductsSuppliersFail
    | LoadTransportationOriginCountries
    | LoadTransportationOriginCountriesSuccess
    | LoadTransportationOriginCountriesFail
    | LoadProductCurrency
    | LoadProductCurrencySuccess
    | LoadProductCurrencyFail
    | LoadProductAdditionalCostTypes
    | LoadProductAdditionalCostTypesSuccess
    | LoadProductAdditionalCostTypesFail
    | LoadProductCreate
    | LoadProductCreateSuccess
    | LoadProductCreateFail
    | LoadManualID
    | LoadManualIDSuccess
    | LoadManualIDFail
    | LoadUnits
    | LoadUnitsSuccess
    | LoadUnitsFail
    | LoadLogoType
    | LoadLogoTypeSuccess
    | LoadLogoTypeFail
    | LoadTypeAll
    | LoadTypeAllSuccess
    | LoadTypeAllFail
    | LoadLogoArea
    | LoadLogoAreaSuccess
    | LoadLogoAreaFail
    | LoadChangeVisibility
    | LoadChangeVisibilitySuccess
    | LoadChangeVisibilityFail
    | LoadMaxArea
    | LoadMaxAreaSuccess
    | LoadMaxAreaFail
    | LoadAreaDel
    | LoadAreaDelSuccess
    | LoadAreaDelFail
    | LoadAreaUpdate
    | LoadAreaUpdateSuccess
    | LoadAreaUpdateFail
    | LoadHandlingCost
    | LoadHandlingCostSuccess
    | LoadHandlingCostFail
    | LoadCustomerProducts
    | LoadCustomerProductsSuccess
    | LoadCustomerProductsFail;
