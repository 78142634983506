import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ParentshopHttpService } from '../state/services/parentshop.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';

@Component({
    selector: 'link-admin-parentshop-list',
    templateUrl: './parentshop-list.component.html',
    styleUrls: ['./parentshop-list.component.scss']
})
export class ParentshopListComponent implements OnInit {
    header: any;
    webshopList: any = [];
    submitted = false;
    webshopdatacount: any;
    tableName: string = 'webshopList';
    filterFields: any = [];
    perPagedata: number = 10;
    rowPagepage: number = 10;
    enablepagination: boolean = true;
    activePage: number = 1;
    rowactivePage: number = 1;
    searchCriteria: any = {};
    filterData: any = {};
    childwebshop: any = [];
    passFilterresult: any = [];
    CustomerID: any = "";
    resetStatus: boolean = false;
    showLoader: Boolean = false;
    webshopDetail: any;
    columns: ColumnSetting[] = [
        {
            primaryKey: 'Name',
            header: 'Webshop Name'
        },
        {
            primaryKey: 'CustomerID',
            header: 'Webshop ID'
        },
        {
            primaryKey: 'ImageURL',
            header: 'Webshop Logo',
            format: 'image'
        },
        {
            primaryKey: 'TotalOrdersCount',
            header: 'No Of Orders'
        },
        {
            primaryKey: 'TotalClaimsCount',
            header: 'No Of Claims'
        },
        {
            primaryKey: 'isWebShopActive',
            header: 'Status'
        }
    ];
    partner = '.constient.us';
    shop = '.constients.com';
    constructor(private apiService: ApiService, private parentshopHttpService: ParentshopHttpService, private toast: ToastService, private router: Router) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }

    }
    ngOnInit() {
        this.onLoadParentshopList();
    }

    onLoadParentshopList() {
        const searchkey = sessionStorage.getItem('searchWebshopListKey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.getParentshopList(JSON.parse(searchkey));
        } else {
            this.searchCriteria = {
                childShopId: '',
                pageSize: 10,
                rowpageIndex: this.rowPagepage,
                activePage: this.activePage,
                pageIndex: 10
            };
            this.filterData = this.searchCriteria;
            sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
            // this.userService.loadUsers(this.searchCriteria);
            this.getParentshopList(this.searchCriteria);
        }
    }


    getParentshopList(searchCriteria?) {
        const searchdata = {
            activePage: this.activePage,
            pageSize: (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10,
            childShopId: (searchCriteria.childShopId !== null && searchCriteria.childShopId !== undefined) ? searchCriteria.childShopId : '',
        };
        this.showLoader = true;
        this.parentshopHttpService.getParentshopList(searchdata).subscribe(data => {
            this.showLoader = false;
            if (data) {
                const webshopListData = data;
                this.webshopList = [];
                this.webshopList = webshopListData['ItemsCollection'];
                this.webshopList.forEach(element => {
                    if (Number(element.TotalOrdersCount) === 0) {
                        element.TotalOrdersCount = element.TotalOrdersCount.toString();
                    }
                    if (Number(element.TotalClaimsCount) === 0) {
                        element.TotalClaimsCount = element.TotalClaimsCount.toString();
                    }
                });
                this.webshopdatacount = webshopListData['TotalItemsCount'];
            }
        },
            err => {
                console.log(err);
                this.showLoader = false;
            });
    }

    /** Paginaion code Section Functions */
    getPerPage(event) {
        console.log("Get Per Page : ", event);
        this.perPagedata = event;
        this.searchCriteria.pageIndex = event;
        this.getSubmit();
    }

    paginationNum(event) {
        console.log("paginationNum : ", event);
        this.activePage = event;
        const searchkey = sessionStorage.getItem('searchWebshopListKey');
        if (searchkey !== null && searchkey !== '[object Object]') {
            this.searchCriteria = JSON.parse(searchkey);
        }
        this.searchCriteria.activePage = event;
        this.perPagedata = this.searchCriteria.pageIndex;
        this.getParentshopList(this.searchCriteria);
    }

    getSubmit() {
        this.activePage = 1;
        this.searchCriteria.activePage = this.activePage;
        this.filterData = this.searchCriteria;
        this.getParentshopList(this.filterData);
        sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
        sessionStorage.setItem('searchWebshopListResult', JSON.stringify(this.filterFields));
    }

}