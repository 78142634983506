import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';
import { WebService } from '../../restful-service/web.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';

@Component({
  selector: 'app-message-inquiries',
  templateUrl: './message-inquiries.component.html',
  styleUrls: ['./message-inquiries.component.scss']
})
export class MessageInquiriesComponent implements OnInit {
  // @Input() loadApiStatus:any;
  enablepagination: boolean = true;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  rowactivePage: number = 1;
  activePage: number = 1;
  customerList: any = [];
  filterFields: any = [];
  showMessageDetailpage: boolean = false;
  showMessageInbox: boolean = true;
  messageRead: boolean = false;
  messageUnread: boolean = true;
  titles: any;
  tableName: string = 'message';
  InquiryID: any = 0;
  public messageinquiries: any = [];
  tableEvent: any;
  messageinquiriesQueryParams: any = {};
  isBulkEditSelectedForDelete = {
    data: []
  }
  public inboxInquiriesData: ColumnSetting[] = [
    {
      primaryKey: 'ProductionLineID',
      header: 'Select',
      format: 'bulkaction',
      key: 'inquiriescheckbox'
    },
    {
      primaryKey: 'CompanyName',
      header: '	CompanyName',
    },
    {
      primaryKey: 'Email',
      header: 'Email',
    },
    {
      primaryKey: 'ProductDescription',
      header: 'ProductDescription',
    },
    {
      primaryKey: 'InquiryDate',
      header: 'InquiryDate',
      format: 'time'
    },
    {
      primaryKey: 'InquiryStatusCode',
      header: 'InquiryStatusCode',
    },
  ];
  bulkEmailIds: any[] = [];
  inquirycount: any;
  showLoader: Boolean = false;
  webshopDetail: any;
  constructor(public messageboxservice: MessageboxService, public apiService: ApiService, private toast: ToastService,
    public webservice: WebService, private linkTableService: LinkTableService, private router: Router, private messageboxhttpservice: MessageboxHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.loadCustomer();
    // this.Messageboxinquiries();
    this.apiService.updateCount.next(true);
  }

  onLoadFunctions() {
    // const loginData = this.apiService.getLoginData();
    this.InquiryID = '';
    this.messageinquiriesQueryParams.InquiryID = this.InquiryID;
    this.messageinquiriesQueryParams.customerID = this.webshopDetail.CustomerID;
    this.messageinquiriesQueryParams.pageSize = this.perPagedata;
    this.messageinquiriesQueryParams.activePage = this.activePage;
    this.Messageboxinquiries(this.messageinquiriesQueryParams);
    this.messageboxservice.loadCustomers(this.messageinquiriesQueryParams)
  }

  Messageboxinquiries(request) {
    this.showLoader = true;
    this.messageboxhttpservice.getMessageInquiriesData(request).subscribe((data: any) => {
      if (data) {
        this.messageinquiries = data.ItemsCollection;
        this.inquirycount = data.TotalItemsCount;
        this.showMessageDetailpage = false;
        this.showMessageInbox = true;
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
      })
  }
  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        console.log(data)
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });

        this.filterFields = [
          {
            label: 'Refresh',
            type: 'button',
            class: 'ml-auto',
            key: 'reset',
          },
          {
            label: 'Delete',
            type: 'button',
            class: 'ml-2 mt-2 pt-1',
            key: 'messagedelete',
            model: 'deleteinquiry'
          }
        ]
      }
    })
  }


  // detail page
  inquiriesdetails(messageData: boolean) {
    if (messageData) {
      this.showMessageDetailpage = true;
      try {
        this.messageboxservice.selectedMessage(messageData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.showMessageDetailpage = true;
            this.showMessageInbox = false;
            this.InquiryID = data.InquiryID;
            this.router.navigate(['/app-message-inquiredetails'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.messageinquiriesQueryParams.pageSize = event;
    this.messageinquiriesQueryParams.pageIndex = event;
    this.getSubmit(true);
  }
  paginationNum(event) {
    this.activePage = event;
    this.messageinquiriesQueryParams.activePage = event;
    this.getSubmit(true);
  }

  getBulkAction(event) {
    console.log(event);
    if (event.bulkaction.inquiriescheckbox !== undefined) {
      let getaction = event.bulkaction.inquiriescheckbox;
      this.bulkEmailIds = [];
      getaction.forEach(element => {
        this.bulkEmailIds.push(element.InquiryID)
      });
    }
    this.isBulkEditSelectedForDelete.data = this.bulkEmailIds;
  }


  deleteRow(event) {
    console.log(event)
    if (event.deleteAction !== undefined && event.deleteAction.deleteinquiry && this.bulkEmailIds.length > 0) {
      let getLastArray = this.bulkEmailIds.length - 1;

      this.bulkEmailIds.forEach((element, index) => {
        const deleteData = {
          "ActionType": "TRASH",
          "Value": true,
          "type": 'inquiry',
          "InquiryID": element
        }

        this.webservice.deleteInquiry(deleteData).subscribe((data: any) => {
          if (data) {
            if (index === getLastArray) {
              setTimeout(() => {
                this.toast.success('Inquiry deleted successfully');
                this.Messageboxinquiries(this.messageinquiriesQueryParams);
                this.bulkEmailIds = [];
                this.isBulkEditSelectedForDelete.data = [];
              }, 500);
            }
          }

        })
      })
    } else {
      this.toast.error("Please select atleast one inquiry to delete");
    }
  }

  getSelectData(event) {
  }

  getSubmit(submit) {
    this.Messageboxinquiries(this.messageinquiriesQueryParams);
  }

  getReset(reset) {
    if (reset.selectMethod === 'reset' && reset.value) {
      this.Messageboxinquiries(this.messageinquiriesQueryParams);
      this.loadCustomer();
    }
  }



}
