import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { BrandService } from '../state/services/brand.service';
import { BrandHttpService } from '../state/services/brand.http.service';
import { Router } from '@angular/router';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { timeStamp } from "console";

@Component({
    selector: 'rewardkart-admin-brand-edit',
    templateUrl: './brand-edit.component.html',
    styleUrls: ['./brand-edit.component.scss']
})

export class BrandEditComponent implements OnInit {

    brandDetails: FormGroup;
    brandId: any;
    showLoader: Boolean = false;
    showImageError: Boolean = false;
    selectedbrandImg: any;
    selectedImg: any;
    editData: any;
    editResponse: any;
    btnLabel: string = 'Add Brand';
    isUpdate: string = 'N';
    princepipes: boolean = false;
    princeAct: boolean = true;
    isParentshop: boolean = false;

    constructor(private router: Router, private formBuilder: FormBuilder, private toast: ToastService, private brandHttpService: BrandHttpService, private brandService: BrandService,
        private cdr: ChangeDetectorRef
    ) {
        const webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if(webshop && webshop.DomainName === "princepipes"){
            this.princepipes = true;
        }
        if ((webshop && webshop.ParentCustomerDomainName) === (webshop && webshop.DomainName)) {
            this.isParentshop = true;
        }
        if(this.princepipes || this.isParentshop){
            this.brandDetails = this.formBuilder.group({
                brandName: ['', Validators.required],
                description: ['', Validators.required],
                isActive: [true],
                princeActive: [this.princeAct],
                brandImage: ['']
            })
        }else{
            this.brandDetails = this.formBuilder.group({
                brandName: ['', Validators.required],
                description: ['', Validators.required],
                isActive: [true],
                brandImage: ['']
            })
        }
        
    }

    ngOnInit(): void {
        this.editData = history.state.data;
        if (this.editData) {
            this.getBrandData();
        }
    }

    validateFields() {
        let validateStatus = true;
        if (!this.brandDetails.controls.brandName.value) {
            this.brandDetails.controls['brandName'].markAllAsTouched();
            validateStatus = false;
        }

        if (!this.brandDetails.controls.description.value) {
            this.brandDetails.controls['description'].markAllAsTouched();
            validateStatus = false;
        }

        return validateStatus;
    }

    onSelectFile(event, imgName: any) {
        const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
        if ((getImg.toLowerCase() !== '.jfif' && getImg.toLowerCase() !== '.jpg' && getImg.toLowerCase() !== '.jpeg' && getImg.toLowerCase() !== '.png' && getImg.toLowerCase() !== '.gif')) {
            this.showImageError = true;
            return;
        }
        else {
            this.showImageError = false;
        }

        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;

            for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.selectedImg = event.target.result;
                }

                reader.readAsDataURL(event.target.files[i]);
                let httpHeaders = new HttpHeaders();
                let httpParams = new HttpParams();
                httpHeaders.set('Content-Type', null);
                httpHeaders.set('Accept', "multipart/form-data");
                const formData = new FormData();
                formData.append('file', event.target.files[0]);
                formData.append('filePath', 'BrandImage');
                this.brandHttpService.uploadFile(formData).subscribe({
                    next: data => {
                        if(data){
                            this.selectedbrandImg = data[1];
                        }
                    }
                })
            //     this.brandService.loaduploadFile(formData);
            //     this.brandService.uploadFile().subscribe((data: any) => {
            //         if (data) {
            //             this.selectedbrandImg = data[1];
            //         }
            //     })
             }
        }
    }

    getBrandData() {
        let newAPI:boolean = false;
        if(this.princepipes || this.isParentshop) {
            newAPI = true;
        }
        this.brandHttpService.getBrandData(this.editData.BrandId,newAPI).subscribe(res => {
            if (res) {
                this.editResponse = res;
                if(this.princepipes){
                    this.princeAct = this.editResponse.PrincePipe === "Y" ? true : false;
                }
                if (this.isParentshop) {
                    this.princeAct = this.editResponse.IsActiveBrand === "Y" ? true : false;
                }
                this.editAddBrandForm();
            }
        })
    }

    editAddBrandForm() {
        if (this.princepipes || this.isParentshop) {
            this.brandDetails = this.formBuilder.group({
                brandName: [this.editData !== undefined ? this.editData.BrandName : '', Validators.required],
                description: [this.editData !== undefined ? this.editData.BrandDescription : '', Validators.required],
                isActive: [this.editData.IsActive],
                princeActive: [this.princeAct],
                brandImage: [this.editData !== undefined ? this.editData.ImageName : '']
            });
        } else {
            this.brandDetails = this.formBuilder.group({
                brandName: [this.editData !== undefined ? this.editData.BrandName : '', Validators.required],
                description: [this.editData !== undefined ? this.editData.BrandDescription : '', Validators.required],
                isActive: [this.editData.IsActive],
                brandImage: [this.editData !== undefined ? this.editData.ImageName : '']
            });
        }
    
        this.selectedbrandImg = this.editData.ImageName;
        if (this.editData.ImagePath) {
            this.selectedImg = this.editData.ImagePath;
        }
        this.btnLabel = 'Update';
        this.isUpdate = 'Y';
    }

    activeStatus(event){
        this.princeAct = event.checked;
        this.brandDetails.value.princeActive = this.princeAct;
    }
    

    addBrand() {
        const validStatus = this.validateFields();

        if (!validStatus) {
            this.toast.error("Fill Mandatory Fields");
            return false;
        } else {
            let active = 0;
            if (this.brandDetails.value.isActive) {
                active = 1;
            }
            let updateData:any = {
                "BrandName": this.brandDetails.value.brandName,
                "BrandDescription": this.brandDetails.value.description,
                "ImageName": this.selectedbrandImg,
                "IsActive": active,
                "IsUpdate": this.isUpdate,
            }

            if(this.princepipes){
                updateData.PrincePipe = this.brandDetails.value.princeActive ? "Y" : "N";
            }
            if (this.isParentshop) {
                updateData.IsActiveBrand = this.brandDetails.value.princeActive ? "Y" : "N";
            }

            if(this.isUpdate === 'Y'){
                updateData.BrandId = this.editData.BrandId;
            }

            this.showLoader = true;
            this.brandHttpService.addBrand(updateData).subscribe((data: any) => {
                if (data) {
                    this.toast.success(this.isUpdate === 'Y' ? 'Brand Updated successfully' : 'Brand added successfully');
                    this.showLoader = false;
                    if (this.isUpdate === 'Y' && (this.princepipes || this.isParentshop)){
                        this.updateActiveCampaign(this.brandDetails.value.princeActive,this.editData.BrandId);
                    }
                    this.router.navigate(['/admin-brand-list'], { replaceUrl: false });
                }
            }, error => {
                this.toast.error(error.error.Message);
                this.showLoader = false;
            })
        }
    }

    updateActiveCampaign(active, brandId) {
        this.showLoader = true;
        const customerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
        const data = {
            Brand : brandId,
            customerID : customerDetails && customerDetails.CustomerID ? customerDetails.CustomerID : '',
            type: active ? 'I' : 'D'
        }
        this.brandHttpService.updateActiveCampaign(data).subscribe({
            next: data => {
                this.showLoader = false;
                if(data){
                    
                }
            },error(err) {
                this.showLoader = false;
                this.toast.error(err.error.Message || 'Something went wrong');
            },
        })
    }

}