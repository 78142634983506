import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/state/services/user.service';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';
import { WebService } from 'src/app/restful-service/web.service';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';

@Component({
  selector: 'app-message-claims',
  templateUrl: './message-claims.component.html',
  styleUrls: ['./message-claims.component.scss']
})
export class MessageClaimsComponent implements OnInit {
  //  @Input() loadApiStatus:any;
  enablepagination: boolean = true;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  rowactivePage: number = 1;
  customerList: any = [];
  filterFields: any = [];
  activePage: number = 1;
  showMessageDetailpage: boolean = false;
  showMessageInbox: boolean = true;
  EmailID: any = 0;
  tableName: string = 'message';
  ClaimsID: any = 0;
  tableEvent: any;
  showAll;
  customerId;
  roleCode;
  showLoader: Boolean = false;
  public messageclaims: any = [];
  messageclaimsQueryParams: any = {};
  public inboxClaimsData: ColumnSetting[] = [
    {
      primaryKey: 'ProductionLineID',
      header: 'Select',
      format: 'bulkaction',
      key: 'claimscheckbox'
    },
    {
      primaryKey: 'OrderNo',
      header: '		Order Item Number',
    },
    {
      primaryKey: 'QuantityOfWholeShipment',
      header: 'Quantity Of Whole Shipment	',
    },
    {
      primaryKey: 'QuantityOfDefectGoods',
      header: 'Quantity Of Defect Goods	',
    },
    {
      primaryKey: 'ClaimType',
      header: 'Compensation Type	',
    },
    {
      primaryKey: 'DeliveryDate',
      header: 'Delivery Date',
      format: 'time'
    },
  ];
  titles: any;
  bulkEmailIds: any = [];
  claimscount: any;
  isBulkEditSelectedForDelete = {
    data: []
  }
  webshopDetail: any;
  constructor(public messageboxservice: MessageboxService, public webservice: WebService, private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private toast: ToastService, private messageboxHttpService: MessageboxHttpService) {

    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.loadCustomer();
    // this.Messageboxclaims();
    this.apiService.updateCount.next(true);
  }


  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.EmailID = '';
    this.ClaimsID = '';
    this.messageclaimsQueryParams.EmailID = this.EmailID;
    this.messageclaimsQueryParams.ClaimsID = this.ClaimsID;
    this.messageclaimsQueryParams.UserID = loginData.UserID;
    this.messageclaimsQueryParams.customerID = this.webshopDetail.CustomerID;
    this.messageclaimsQueryParams.activePage = this.activePage;
    this.messageclaimsQueryParams.pageSize = this.perPagedata;
    // this.messageboxservice.loadMessageclaimsState(this.messageclaimsQueryParams);
    this.Messageboxclaims(this.messageclaimsQueryParams);
    this.messageboxservice.loadCustomers(this.messageclaimsQueryParams)
  }

  Messageboxclaims(request) {
    this.showLoader = true;
    this.messageboxHttpService.getMessageClaimsData(request).subscribe((data: any) => {
      if (data) {
        this.messageclaims = data.ItemsCollection;
        this.claimscount = data.TotalItemsCount;
        this.showMessageDetailpage = false;
        this.showMessageInbox = true;
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
      })
  }

  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });

        this.filterFields = [
          {
            label: 'Refresh',
            type: 'button',
            class: 'ml-auto',
            key: 'reset',
          },
          {
            label: 'Delete',
            type: 'button',
            class: 'ml-2 mt-2 pt-1',
            key: 'messagedelete',
            model: 'deleteclaims'
          }
        ]
      }
    })
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.messageclaimsQueryParams.pageSize = event;
    this.getSubmit(true);
  }
  paginationNum(event) {
    this.activePage = event;
    this.messageclaimsQueryParams.activePage = event;
    this.getSubmit(true);
  }


  selectedTableRow(event: boolean) {
    if (event) {
    }
  }
  claimsdetails(messageData: boolean) {
    if (messageData) {
      this.showMessageDetailpage = true;
      try {
        this.messageboxservice.selectedMessage(messageData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.showMessageDetailpage = true;
            this.showMessageInbox = false;
            this.EmailID = data.ClaimID;
            this.ClaimsID = data.ClaimID
            this.router.navigate(['/app-message-claims/app-message-claimsdetails'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }
  }
  getBulkAction(event) {
    if (event.bulkaction.claimscheckbox !== undefined) {
      let getaction = event.bulkaction.claimscheckbox;
      this.bulkEmailIds = [];
      getaction.forEach(element => {
        this.bulkEmailIds.push(element.ClaimID)
      });
    }
    this.isBulkEditSelectedForDelete.data = this.bulkEmailIds;

  }

  deleteRow(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deleteclaims) {
      let getLastArray = this.bulkEmailIds.length - 1;
      this.bulkEmailIds.forEach((element, index) => {
        const deleteData = {
          "ActionType": "TRASH",
          "Value": true,
          "claimid": element
        }
        this.webservice.deleteClaim(deleteData).subscribe((data: any) => {
          if (data) {
            if (index === getLastArray) {
              setTimeout(() => {
                this.toast.success('Email deleted successfully');
                this.Messageboxclaims(this.messageclaimsQueryParams);
                this.bulkEmailIds = [];
                this.isBulkEditSelectedForDelete.data = [];
              }, 500);
            }
          }
        })
        //      this.messageboxservice.loadbulkAction(deleteData)
        // this.messageboxservice.bulkAction().subscribe((data:any)=>{
        //     if(data){
        //       if(index === getLastArray){
        //         this.toast.success('Email deleted successfully');
        //         this.messageboxservice.loadMessageclaimsState(this.messageclaimsQueryParams);
        //         this.messageclaimsQueryParams();
        //       }
        //     }
        // })
      })
    }
  }

  getSelectData(event) {
  }

  getSubmit(submit) {
    this.Messageboxclaims(this.messageclaimsQueryParams);
  }

  getReset(reset) {
    if (reset.selectMethod === 'reset' && reset.value) {
      this.Messageboxclaims(this.messageclaimsQueryParams);
      this.loadCustomer();
    }
  }
}
