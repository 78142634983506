import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';

@Component({
  selector: 'rewardkart-admin-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})

export class OrderListComponent {
  @Input() orderListData: any;
  @Input() searchLoader: boolean = true;
  @Output() viewOrderItem = new EventEmitter<any>();
  columns: ColumnSetting[] = [
    {
      primaryKey: 'OrderIdManual',
      header: 'Order Number'
    },
    {
      primaryKey: 'productsNames',
      header: 'Product Name'
    },
    {
      primaryKey: 'UserName',
      header: 'User Name'
    },
    {
      primaryKey: 'CompanyName',
      header: 'Company Name'
    },
    {
      primaryKey: 'CustomerName',
      header: 'Web Shop'
    },
    {
      primaryKey: 'NumberOfOrderItems',
      header: 'No Of Items'
    },
    {
      primaryKey: 'DeliveryAddressType',
      header: 'Delivery Requirement',
    },
    {
      primaryKey: 'PaymentBy',
      header: 'Payment By',
    },
    {
      primaryKey: 'OrderStatus',
      header: 'Order Status',
    }
  ];
  get noDataFound() {
    return !this.searchLoader ? 'No Data Found' : '';
  }

  header: any;
  tableName = 'orderList';
  constructor() {
    let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));

    if(webshop.ParentCustomerDomainName !== webshop.DomainName){
      this.columns = [
        {
          primaryKey: 'OrderIdManual',
          header: 'Order Number'
        },
        {
          primaryKey: 'productsNames',
          header: 'Product Name'
        },
        {
          primaryKey: 'UserName',
          header: 'User Name'
        },
        {
          primaryKey: 'CompanyName',
          header: 'Company Name'
        },
        {
          primaryKey: 'CustomerName',
          header: 'Web Shop'
        },
        {
          primaryKey: 'NumberOfOrderItems',
          header: 'No Of Items'
        },
        {
          primaryKey: 'DeliveryAddressType',
          header: 'Delivery Requirement',
        },
        {
          primaryKey: 'OrderAmount',
          header: 'No of Points'
        },
        {
          primaryKey: 'PointStatus',
          header: 'Points Status'
        },
        {
          primaryKey: 'OrderStatus',
          header: 'Order Status',
        }
      ];
    }
  }

  viewOrder(event: boolean) {
    if (event) {
      this.viewOrderItem.emit(event);
    }
  }
}
