import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkEditableTableComponent } from './link-editable-table.component';
import { WavesModule, ButtonsModule, TooltipModule, TableModule } from 'ng-uikit-pro-standard';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [LinkEditableTableComponent],
  imports: [
    CommonModule,
    // BrowserModule,
    FormsModule,
    WavesModule,
    ButtonsModule,
    TooltipModule,
    TableModule,
  ],
  exports: [
    CommonModule,
    LinkEditableTableComponent
  ]
})
export class LinkEditableTableModule { }
