import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SupplierActions } from '../actions/index';
import { State } from '../reducers/index';
import { UnderSupplierHttpService } from '../services/undersupplier.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class SupplierEffects {

    @Effect()
    getSupplierList$: Observable<Action> = this.actions$.pipe(
        ofType(SupplierActions.LOAD_SUPPLIER_LIST),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.undersupplierHttpService.getUnderSupplierList(payload).map((data) => {
                return new SupplierActions.LoadSupplierListSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new SupplierActions.LoadSupplierListFail(error));
        })
    );

    @Effect()
    getAllSuppliers$: Observable<Action> = this.actions$.pipe(
        ofType(SupplierActions.LOAD_SUPPLIERS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.undersupplierHttpService.getAllSuppliers().map((data) => {
                return new SupplierActions.LoadSuppliersSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new SupplierActions.LoadSuppliersFail(error));
        })
    );

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private undersupplierHttpService: UnderSupplierHttpService) { }
}
