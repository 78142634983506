import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryState, categoryReducers } from './category.reducer';

export interface State {
    categoryModule: CategoryState;
}

export const reducers = categoryReducers;

export const getCategoryState = createFeatureSelector<CategoryState>('categoryModule');

export const getCategoryListSuccess = createSelector(getCategoryState,
    (state: CategoryState) => state.categorys);

export const getCategoryUpdateSuccess = createSelector(getCategoryState,
    (state: CategoryState) => state.updateCategorySuccess);

export const getSelectedCategory = createSelector(getCategoryState,
    (state: CategoryState) => state.selectedCategory);

export const getCustomerCategoryListSuccess = createSelector(getCategoryState,
    (state: CategoryState) => state.customerCategories);
