import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'rewardkart-admin-order-cancel',
  templateUrl: './order-cancel.component.html',
  styleUrls: ['./order-cancel.component.scss']
})
export class OrderCancelComponent implements OnInit {

  @Input() header = '';
  @Input() orderId = '';
  @Input() orderItemId = '';
  senMail = true;
  modalAction: Subject<any> = new Subject<any>();

  constructor(public modalRef: MDBModalRef) { }

  modalCanceled() {
    this.modalRef.hide();
  }
  ngOnInit() {
  }

  modalConfirm() {
    this.modalAction.next({
      confirm: true,
      sendMail: this.senMail
    });
  }
}
