import { BannerenquiryActions } from '../actions/index';

export interface BannerenquiryState {
    BannerInfoId: any
}

export const initialState: BannerenquiryState = {
    BannerInfoId: null
}

export function bannerenquiryReducers(state: BannerenquiryState = initialState, action: BannerenquiryActions.All) {
    switch (action.type) {
        default: {
            return state;
        }
    }
}

