
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryHttpService } from '../state/services/category.http.service';
import { CategoryService } from '../category-services/category.service';
import { PageControlModule } from 'src/app/shared/page-control/page-control.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManageSupplierComponent } from './manage-supplier.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    PageControlModule,
    NgxPaginationModule,
    TranslateModule
  ],
  declarations: [ManageSupplierComponent],
  providers: [CategoryHttpService, CategoryService],
  exports: [ManageSupplierComponent]
})

export class ManageSupplierModule { }
