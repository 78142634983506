import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterLinkSettingsComponent } from './footer-link-settings.component';
import { WavesModule, ButtonsModule, TableModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ControlValidationModule,
        SharedModule,
        TranslateModule,
        NgSelectModule,
        PageLoaderModule,
        CommonFormModule,
        DragDropModule,
        WavesModule,
        ButtonsModule,
        TooltipModule,
        TableModule,
        MDBBootstrapModulesPro.forRoot()


    ],
    declarations: [FooterLinkSettingsComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    exports: [FooterLinkSettingsComponent]

})
export class FooterLinksModule {
}