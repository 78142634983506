import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, SimpleChanges, SimpleChange, OnChanges, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WarehouseHttpService } from '../../state/services/warehouse.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ControlValidationService } from 'src/app/shared/control-validation';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-logistic-vendor-add',
  templateUrl: './logistic-vendor-add.component.html',
  styleUrls: ['./logistic-vendor-add.component.scss']
})
export class LogisticVendorAddComponent implements OnInit {
  optionsSelectCountries: any = [];
  countriesList: any = [];
  currencyList: any = [];
  logisticVendorDetails: FormGroup;
  showLoader: Boolean = false;
  editData: any;
  editPage: Boolean = false;
  viewPage: Boolean = false;
  public postalCodeErrorCount = 0;
  inputName: string;
  webshopDetail: any;
  isParentCustomer: Boolean = false;
  showWebshopDropdown: Boolean = false;
  searchCriteria: any;
  constructor(private router: Router, private warehousehttpservice: WarehouseHttpService,
    private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, private renderer: Renderer2, private translate: TranslateService) {
    this.loadForm();
  }

  ngOnInit() {
    this.getCountryList();
    this.getCurrencies();

    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = false;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID === null) {
        this.isParentCustomer = true;
      }
    }
  }

  loadForm() {
    const editData = history.state.data;
    console.log("Load Form Edit Data : ", editData);
    this.editData = history.state.data;
    if (editData !== undefined && editData.HandlingCurrencyID !== undefined) {
      editData.HandlingCurrencyID = (editData.HandlingCurrencyID !== '') ? editData.HandlingCurrencyID : '';
    } else {
      if (editData !== undefined) {
        editData.HandlingCurrencyID = '';
      }
    }
    if (editData !== undefined) {
      this.editPage = true;
      if (!editData.IsEditable && !editData.isParentCustomer) {
        this.viewPage = true;
      }
    }

    this.logisticVendorDetails = this.formBuilder.group({
      Name: [editData !== undefined ? editData.Name : '', Validators.required],
      Address: [editData !== undefined ? editData.Address : ''],
      ContactPerson: [editData !== undefined ? editData.ContactPerson : ''],
      Email: [editData !== undefined ? editData.Email : '', Validators.required],
      PhoneNumber: [editData !== undefined ? editData.PhoneNumber : ''],
      City: [editData !== undefined ? editData.City : ''],
      PostalCode: [editData !== undefined ? editData.PostalCode : ''],
      Country: [editData !== undefined ? editData.Country : ''],
      HandlingPricePerOrderItem: [editData !== undefined ? editData.HandlingPricePerOrderItem : ''],
      MinimumHandlingPricePerSuborder: [editData !== undefined ? editData.MinimumHandlingPricePerSuborder : ''],
      HandlingCurrencyID: [editData !== undefined ? editData.HandlingCurrencyID : ''],
    });
  }

  validateFields() {
    let validateStatus = true;
    // tslint:disable-next-line:max-line-length
    if ((this.logisticVendorDetails.controls.Name.value === '') || (this.logisticVendorDetails.controls.Email.value === '')) {
      this.logisticVendorDetails.controls['Name'].markAllAsTouched();
      this.logisticVendorDetails.controls['Email'].markAllAsTouched();
      validateStatus = false;
    }
    return validateStatus;
  }

  allowDecimal(event, name) {
    let returnValue;
    returnValue = true;
    const charCode = event.charCode;
    if (Number(charCode) === 44) {
      setTimeout(() => {
        if (name === 'handling') {
          // tslint:disable-next-line:max-line-length
          this.logisticVendorDetails.patchValue({
            HandlingPricePerOrderItem: this.logisticVendorDetails.value.HandlingPricePerOrderItem.replace(/,/g, '.')
          });
        } else {
          // tslint:disable-next-line:max-line-length
          this.logisticVendorDetails.patchValue({
            MinimumHandlingPricePerSuborder: this.logisticVendorDetails.value.MinimumHandlingPricePerSuborder.replace(/,/g, '.')
          });
        }
      }, 10);
    }
    if (
      (charCode !== 44) &&      // “,” CHECK COMMA AND ONLY ONE.
      (charCode !== 46) &&      // “.” CHECK DOT AND ONLY ONE.
      (charCode < 48 || charCode > 57)) {
      return false;
    }
    return returnValue;
  }


  isPhoneNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (((charCode < 40 || charCode > 57) || (charCode === 42) || (charCode === 44)) && (charCode !== 32)) {
      return false;
    }
    return true;
  }

  addLogisticVendor() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      let errorCount = 0;
      /** To Validate HandlingPricePerOrderItem Field */
      if (this.logisticVendorDetails.value.HandlingPricePerOrderItem !== '' &&
        this.logisticVendorDetails.value.HandlingPricePerOrderItem !== null) {
        if (String(this.logisticVendorDetails.value.HandlingPricePerOrderItem).match(/^\d*\.?\d{0,4}$/)) {
        } else {
          this.toast.error(this.translate.instant('WAREHOUSEMANAGEMENT.HANDLINEPRICEPERITEM_VALIDATION'));
          errorCount += 1;
        }
      }

      /** To Validate MinimumHandlingPricePerSuborder Field */
      if (this.logisticVendorDetails.value.MinimumHandlingPricePerSuborder !== '' &&
        this.logisticVendorDetails.value.MinimumHandlingPricePerSuborder !== null) {
        if (String(this.logisticVendorDetails.value.MinimumHandlingPricePerSuborder).match(/^\d*\.?\d{0,4}$/)) {
        } else {
          this.toast.error(this.translate.instant('WAREHOUSEMANAGEMENT.MINIMUMHANLDINEPRICEPERSUBORDER_VALIDATION'));
          errorCount += 1;
        }
      }

      /** To Validate Invoice Email Field */
      if (this.logisticVendorDetails.value.Email !== '' && this.logisticVendorDetails.value.Email !== null) {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.logisticVendorDetails.value.Email) === false) {
          this.toast.error(this.translate.instant('WAREHOUSEMANAGEMENT.EMAIL_VALIDATION'));
          errorCount += 1;
        }
      }

      if (errorCount > 0) {
        return false;
      }
      let createdCustomerId = this.webshopDetail.CustomerID;
      /** Rewrite CustomerID post param based on selection dropdown(webshop drop down) value in filter section of Logistic list page */
      if (this.editData === undefined) {
        const searchkey = sessionStorage.getItem('searchLogisticVendorKey');
        if (searchkey !== null && searchkey !== '[object Object]') {
          this.searchCriteria = JSON.parse(searchkey);
        }
        if (this.searchCriteria.customerId !== null && this.searchCriteria.customerId !== undefined) {
          if (this.searchCriteria.customerId !== '' && Number(this.searchCriteria.customerId) !== 0) {
            createdCustomerId = this.searchCriteria.customerId;
          }
        }
      }
      const addLogisticVendorData = {
        Name: this.logisticVendorDetails.value.Name,
        Address: this.logisticVendorDetails.value.Address,
        ContactPerson: this.logisticVendorDetails.value.ContactPerson,
        Email: this.logisticVendorDetails.value.Email,
        PhoneNumber: this.logisticVendorDetails.value.PhoneNumber,
        City: this.logisticVendorDetails.value.City,
        PostalCode: this.logisticVendorDetails.value.PostalCode,
        Country: this.logisticVendorDetails.value.Country,
        HandlingPricePerOrderItem: this.logisticVendorDetails.value.HandlingPricePerOrderItem,
        MinimumHandlingPricePerSuborder: this.logisticVendorDetails.value.MinimumHandlingPricePerSuborder,
        HandlingCurrencyID: this.logisticVendorDetails.value.HandlingCurrencyID,
        CustomerID: createdCustomerId
      };

      if (addLogisticVendorData.HandlingPricePerOrderItem !== '' && addLogisticVendorData.HandlingPricePerOrderItem !== null) {
        addLogisticVendorData['HandlingPricePerOrderItem'] = Number(addLogisticVendorData['HandlingPricePerOrderItem']).toFixed(2);
      }
      if (addLogisticVendorData.MinimumHandlingPricePerSuborder !== '' && addLogisticVendorData.MinimumHandlingPricePerSuborder !== null) {
        // tslint:disable-next-line:max-line-length
        addLogisticVendorData['MinimumHandlingPricePerSuborder'] = Number(addLogisticVendorData['MinimumHandlingPricePerSuborder']).toFixed(2);
      }

      let country = '';
      let postalCode = '';
      if (this.logisticVendorDetails.controls.Country.value !== undefined) {
        country = this.logisticVendorDetails.controls.Country.value.trim();
      }
      if (this.logisticVendorDetails.controls.PostalCode.value !== undefined) {
        postalCode = this.logisticVendorDetails.controls.PostalCode.value.trim();
      }

      let postalcodeErrorExample = '';
      let postalcodeErrorIs = '';
      let postalcodeError = '';
      let postalcodeProceedText = '';

      this.translate.get('USER.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
        postalcodeErrorExample = res;
      });
      this.translate.get('USER.POSTALCODE_ERROR_IS').subscribe((res: string) => {
        postalcodeErrorIs = res;
      });
      this.translate.get('USER.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
        postalcodeError = res;
      });
      this.translate.get('USER.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
        postalcodeProceedText = res;
      });

      this.validatePostalCodeCheck(country, postalCode).subscribe((data) => {
        let proceedStatus = false;
        if (data['ValidPostalRegex']) {
          proceedStatus = true;
        } else {
          this.postalCodeErrorCount += 1;
          if (Number(this.postalCodeErrorCount) > 1) {
            proceedStatus = true;
          } else {
            proceedStatus = false;
            const countryResponse = this.countriesList.find(item => item.Alpha2Code === country);
            if (data['VaildPostalcode'] !== '' && data['VaildPostalcode'] !== undefined) {
              const displayText = postalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;
              // tslint:disable-next-line:max-line-length
              this.toast.error(this.translate.instant('USERADDEDIT.ERROR') + ': ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus = true;
            }
          }
        }

        if (proceedStatus) {
          if (this.editData !== undefined) {
            addLogisticVendorData['LogisticsVendorID'] = this.editData.LogisticsVendorID;
          }
          this.showLoader = true;
          this.warehousehttpservice.addLogisticVendor(addLogisticVendorData).subscribe((responsedata: any) => {
            this.showLoader = false;
            if (responsedata) {
              if (this.editData !== undefined) {
                this.toast.success('Logisticvendor updated successfully');
                sessionStorage.setItem('logisticvendorEditRedirect', JSON.stringify(true));
              } else {
                this.toast.success('Logisticvendor added successfully');
              }
              this.router.navigate(['/logisticvendor'], { replaceUrl: false });
            }
          },
            err => {
              let errMsg = '';
              this.showLoader = false;
              if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
                for (var key in err.error.ModelState) {
                  errMsg += err.error.ModelState[key] + ' ';
                }
              } else {
                errMsg += err.error;
              }
              this.toast.error(errMsg);
            });
        }
      });
    }
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    var subject = new Subject<any>();
    this.warehousehttpservice.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  getCountryList() {
    this.warehousehttpservice.getAllCountries().subscribe(
      (data: any) => {
        if (data) {
          this.optionsSelectCountries = [];
          this.optionsSelectCountries.push({ label: this.translate.instant('USERLIST.SELECT_COUNTRY'), value: '' });
          this.countriesList = [];
          this.countriesList = data;
          data.forEach(element => {
            const langVal = {
              value: element.Alpha2Code,
              label: element.Name,
            };
            this.optionsSelectCountries.push(langVal);
          });
        }
      });
  }

  getCurrencies() {
    this.warehousehttpservice.getAllCurrencies().subscribe(
      (data: any) => {
        if (data) {
          this.currencyList = [];
          this.currencyList.push({ label: this.translate.instant('USERBUDGETEDIT.SELECT_CURRENCY'), value: '' });
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            this.currencyList.push(langVal);
          });
        }
      });
  }

  /** Page Reedirect Functions */
  pageredirect() {
    sessionStorage.setItem('logisticvendorEditRedirect', JSON.stringify(true));
    this.router.navigate(['/logisticvendor'], { replaceUrl: true });
  }

  addLogisticVendorDetails() {

  }
}
