import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ColumnSetting } from '../../shared/link-table-border/models/link-table-model';
import { ReportsHttpService } from '../services/reports-http.service';
import { WebshopHttpService } from '../../webshop/state/services/webshop.http.service';
import { CampaignHttpService } from '../../campaign/state/services/campaign.http.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
    selector: 'rewardkart-report-budget',
    templateUrl: './report-budget.component.html',
    styleUrls: ['./report-budget.component.scss']
})
export class ReportBudgetComponent implements OnInit {
    fromDate: any;
    toDate: any;
    date = new Date();
    userName: string = '';
    customerName: string = '';
    campaignID: any;
    domainName: any = '';
    activePage: number = 1;
    isParentCustomer: boolean = true;
    webshop: any;
    childwebshop: any;
    childShop: any;
    showLoader: boolean = false;
    campaignList: any;
    showTable: boolean = false;
    budgetReport: any;
    noData: boolean = false;
    public fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    public fileExtension = '.xlsx';
    public exportData: any = [];
    columns: ColumnSetting[] = [];
    totalPointsCredited: any = '0';
    totalPointsUsed: any = '0';
    totalBalancePoints: any = '0';
    customerid: any;
    constructor(private reportHttpService: ReportsHttpService, private datePipe: DatePipe, private webshophttpservice: WebshopHttpService, private campaignHttpService: CampaignHttpService, private toastr: ToastService) {
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));

            let user = JSON.parse(sessionStorage.getItem('userdetails'));
            this.customerid = user.CustomerID;
        if (this.webshop.ParentCustomerID !== null) {
            this.isParentCustomer = false;
            this.getCampaigns(user.UserID);
            this.domainName = this.webshop.DomainName;
        }
        else {
            this.isParentCustomer = true;
        }
    }
    ngOnInit() {
        this.getChildShops();
    }

    getChildShops() {
        const searchdata = {
            activePage: this.activePage,
            pageSize: 1000,
        };
        this.showLoader = true;
        this.webshophttpservice.getWebshopList(searchdata).subscribe((res: any) => {
            this.showLoader = false;
            this.getReport();
            if (res) {
                let data = res.ItemsCollection;
                // let childcustomer = data.ChildCustomers
                this.childwebshop = [];
                this.childwebshop.push({ label: 'All Child Shop', value: 'All Child Shop' });
                data.forEach(element => {
                    const langVal = {
                        value: element.CustomerID,
                        label: element.Name,
                    };
                    this.childwebshop.push(langVal);
                });
                this.childShop = this.childwebshop[0].value;
            }
        },
            err => {
                this.showLoader = false;
                this.getReport();
            });
    }

    getCampaigns(id) {
        let param = 'pageSize=100&pageIndex=1&userId=' + id;
        this.showLoader = true;
        this.campaignHttpService.getCampaignList(param).subscribe((res: any) => {
            this.showLoader = false;
            this.campaignList = [];
            this.campaignID = '';
            if (res && res.length > 0) {
                let data = res;
                this.campaignList.push({ label: 'All Campaign', value: 'All Campaign' });
                data.forEach(element => {
                    const c = {
                        value: element.CampaignId,
                        label: element.CampaignName,
                    };
                    this.campaignList.push(c);
                });
                this.campaignID = this.campaignList[0].value;
            }
        }, _e => {
            this.showLoader = false;
        });
    }

    getReport() {

        let params = {
            'campaignID': this.campaignID,
            'domainName': !this.isParentCustomer ? this.webshop.DomainName : this.domainName,
            'customerID': this.customerid
        }
        this.showLoader = true;
        this.reportHttpService.getBudgetReport(params).subscribe((data: any) => {
            this.showLoader = false;
            this.budgetReport = '';
            this.showTable = false;
            if (data && data.IsSuccess) {
                this.totalPointsUsed = data.UserReportDetails[0].TotalUsedPoints;
                this.totalPointsCredited = data.UserReportDetails[0].TotalCreditedPoints;
                this.totalBalancePoints = data.UserReportDetails[0].TotalBalancePoints;
                this.columns = [
                    {
                        primaryKey: "ParentShop",
                        header: 'Parent Shop',
                        footer: true,
                        footerKey: 'Total'
                    },
                    {
                        primaryKey: "Childhop",
                        header: 'Child Shop',
                    },
                    {
                        primaryKey: "Name",
                        header: 'Name'
                    },
                    {
                        primaryKey: "UserName",
                        header: 'User Name'
                    },
                    {
                        primaryKey: "UserCreatedDate",
                        header: 'User Created Date',
                        format: 'date'
                    },
                    {
                        primaryKey: "PhoneNumber",
                        header: 'Phone Number'
                    },
                    {
                        primaryKey: "Email",
                        header: 'Email'
                    },
                    {
                        primaryKey: "CampaignName",
                        header: 'Campaign Name'
                    },
                    {
                        primaryKey: "CreditedPoint",
                        header: 'Credited Point',
                        format: 'number',
                        footer: true,
                        footerKey: this.totalPointsCredited
                    },
                    {
                        primaryKey: "RewardPointsUsed",
                        header: 'Points Used',
                        format: 'number',
                        footer: true,
                        footerKey: this.totalPointsUsed
                    },
                    {
                        primaryKey: "BalancePoint",
                        header: 'Balance Point',
                        format: 'number',
                        footer: true,
                        footerKey: this.totalBalancePoints
                    }

                ]
                this.budgetReport = data.UserReportDetails;
                this.budgetReport.forEach(e => {
                    if (!e.RewardPointsUsed) {
                        e.RewardPointsUsed = '0';
                    }

                    if (!e.CreditedPoint) {
                        e.CreditedPoint = '0';
                    }

                    if (!e.BalancePoint) {
                        e.BalancePoint = '0';
                    }
                })
                this.showTable = true;
                this.noData = false;
            } else {
                this.noData = true;
            }
        }, (_error) => {
            this.showLoader = false;
            this.noData = true;
        })

    }

    download() {

        this.exportData = [];

        this.budgetReport.forEach(e => {
            this.exportData.push({
                'Parent Shop': e.ParentShop,
                'Child Shop': e.Childhop,
                'Name': e.Name,
                'User Name': e.UserName,
                'User Created Date': this.datePipe.transform(e.UserCreatedDate, 'dd/MM/yyyy'),
                'Phone Number': e.PhoneNumber,
                'Email': e.Email,
                'Campaign Name': e.CampaignName,
                'Credited Point': e.CreditedPoint,
                'Points Used': e.RewardPointsUsed,
                'Balance Point': e.BalancePoint
            })
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
        const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        this.saveExcelFile(excelBuffer, 'budget-allocation-report');
    }

    saveExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.fileType });
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }

    getSelectedValue(event) {
        this.childwebshop.forEach((element) => {
            if (element.value === event) {
                this.domainName = element.label;
            }
        })
        if (event === 'All Child Shop') {
            this.campaignID = '';
            if (this.isParentCustomer) {
                this.domainName = '';
            }
        } else {
            this.getCampaigns(event);
        }
    }

    getCampaignSelectedValue(_event) {

    }
}