import { Action } from '@ngrx/store';
export const LOAD_BLOCKS = '[Block] Load all Blocks';
export const LOAD_BLOCKS_SUCCESS = '[Block] Load Blocks Success';
export const LOAD_BLOCKS_FAIL = '[Block] Load Blocks Fail';
export const SAVE_BLOCK = '[Block] Save Block';
export const SAVE_BLOCK_SUCCESS = '[Block] Save Blocks Success';
export const SAVE_BLOCKS_FAIL = '[Block] Save Block Fail';
export const SELECTED_BLOCK = '[Block] Selected Block';

export class LoadBlock implements Action {
    readonly type = LOAD_BLOCKS;
    constructor(public payload: any) { }
}

export class LoadBlockSuccess implements Action {
    readonly type = LOAD_BLOCKS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadBlockFail implements Action {
    readonly type = LOAD_BLOCKS_FAIL;
    constructor(public payload: any) { }
}

export class SaveBlock implements Action {
    readonly type = SAVE_BLOCK;
    constructor(public payload: any) { }
}

export class SaveBlockSuccess implements Action {
    readonly type = SAVE_BLOCK_SUCCESS;
    constructor(public payload: any) { }
}

export class SaveBlockFail implements Action {
    readonly type = SAVE_BLOCKS_FAIL;
    constructor(public payload: any) { }
}

export class SelectedBlock implements Action {
    readonly type = SELECTED_BLOCK;
    constructor(public payload: any) { }
}

export type All =
    | LoadBlock
    | LoadBlockSuccess
    | LoadBlockFail
    | SaveBlock
    | SaveBlockSuccess
    | SaveBlockFail;

