import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignupState } from '../reducers/signup.reducer';
import { SignupActions } from '../actions/index';
import { ValidateUserSuccess, GenerateMobileOTPSuccess } from '../reducers/index';
@Injectable()
export class SignupService {
    constructor(private store: Store<SignupState>) { }

    loadvalidateUser(searchCriteria: any) {
        this.store.dispatch(new SignupActions.LoadValidateUser(searchCriteria));
    }

    validateUser() {
        return this.store.select(ValidateUserSuccess);
    }

    loadMobileOTP(searchCriteria){
        this.store.dispatch(new SignupActions.LoadGenerateMobileOTP(searchCriteria));
    }

    triggerMobileOTP(){
        return this.store.select(GenerateMobileOTPSuccess);
    }
}