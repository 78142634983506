import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/state/services/login.service';
import { ApiService } from '../shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  loggedIn: any = false;
  customerImage: any;
  title: any;
  loggedInUser: any;
  webshopDetail: any;


  constructor(private router: Router, private apiService: ApiService, private loginService: LoginService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.loggedInAdmin();
  }

  ngOnInit() {
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe(data => {
        if (data) {
          this.title = data.UserName;
          this.loggedInUser = data.UserName;
        }
      });
    } else {
      if (sessionStorage.getItem('userdetails') !== null) {
        const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
        this.title = loginuserdetails.UserName;
        this.loggedInUser = loginuserdetails.UserName;
      }
    }
  }

  clickEvent() {

  }

  navigateTo() {
    this.router.navigate(['/admin-login'], { replaceUrl: false });
  }

  loggedInAdmin() {
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
      this.loginService.loginSuccess().subscribe(data => {
        if (data) {
          this.customerImage = this.getCustomerImage();
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
      });
    } else {
      if (sessionStorage.getItem('userdetails') !== null) {
        const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
        this.customerImage = this.getCustomerImage();
        this.loggedIn = true;
      }
    }
  }

  getCustomerImage() {
    let imageURL = null;
    this.loginService.getSelectedCustomer().subscribe(data => {
      console.log("Get Selected Cusomer : ", data);
      if (data) {
        imageURL = data.ImageURL;
      }
    });
    return imageURL;
  }
}
