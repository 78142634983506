import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';


@Component({
  selector: 'rewardkart-admin-customer-original-data',
  templateUrl: './customer-original-data.component.html',
  styleUrls: ['./customer-original-data.component.scss']
})
export class CustomerOriginalDataComponent implements OnInit {
  @Input() customerOriginalData: any;
  originalData: any = [];
  selectedlanguage: any = 'en';
  languageOption: any = [];
  @Input() originalDatas: any
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.originalData = this.customerOriginalData.OriginalTranslations;
    this.originalData.forEach(element => {
      if (element.PropertyName === "ProductName") {
        element.order = 1;
        element.label = "Product Name";
      }
      else if (element.PropertyName === "ProductMetaDescription") {
        element.order = 2;
        element.label = "Product Meta Description";
      }
      else if (element.PropertyName === "ProductH1Name") {
        element.order = 3;
        element.label = "Product H1Name";
      }
      else if (element.PropertyName === "ProductDescription") {
        element.order = 4;
        element.label = "Product Description";
      }
      else if (element.PropertyName === "Material") {
        element.order = 5;
        element.label = "Material";
      }
      else if (element.PropertyName === "ImageAlternativeText") {
        element.order = 6;
        element.label = "Image Alternative Text";
      }
      else if (element.PropertyName === "AdditionalData") {
        element.order = 7;
        element.label = "Additional Data";
      }
      else if (element.PropertyName === "OriginCountry") {
        element.order = 8;
        element.label = "Origin Country";
      }
      else if (element.PropertyName === "ProductCardLabel") {
        element.order = 9;
        element.label = "Product Card Label";
      }


    });
    const getSort = this.originalData.sort(function (a, b) {
      return a.order - b.order;
    });
    this.loadLanguage();
  }

  loadLanguage() {
    let getlang = this.apiService.getLanguages();

    getlang.forEach(element => {
      element.label = element.Name;
      element.value = element.Code
    });
    this.languageOption = getlang;
  }

  getCurrentLang(lang) {
    this.selectedlanguage = lang.value;
  }

}
