import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { UserSearchComponent } from './user-search.component';
import { UserListHttpService } from '../state/services/user.http.service';
import { UserService } from '../state/services/user.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [UserSearchComponent],
  providers: [UserListHttpService, UserService],
  exports: [UserSearchComponent]
})

export class UserSearchModule { }
