import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';

@Injectable()
export class DashboardHttpService {
    constructor(private apiService: ApiService) { }

    getDashboardOrderList(searchCriteria) {
        const path = 'en/orders?suborders=true&customerID=' + searchCriteria.customerID + '&pageIndex=0&pageSize=20&unfinishedOrders=true&orderItemStateID=' + searchCriteria.orderItemStateId + '&sampleorder=' + searchCriteria.sampleorder + '&orderItemStateOptionID=' + searchCriteria.orderItemStateOptionID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getStockList(searchCriteria) {
        // let queryString = 'customerID=' + searchCriteria.customerID;
        let queryString = '';
        if (searchCriteria !== null) {
            if (searchCriteria.webshopID !== null && searchCriteria.webshopID !== '') {
                queryString += 'webshopID=' + searchCriteria.webshopID;
            }
            // tslint:disable-next-line:max-line-length
            if (searchCriteria.IncludeChildCustomers !== null && searchCriteria.IncludeChildCustomers !== undefined && searchCriteria.IncludeChildCustomers !== '') {
                queryString += '&IncludeChildCustomers=true';
            }
            if (searchCriteria.alertType !== null && searchCriteria.alertType !== '') {
                queryString += '&alertType=' + searchCriteria.alertType;
            }
            if (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== '') {
                queryString += '&pageIndex=' + searchCriteria.pageIndex;
            }
            if (searchCriteria.pageSize !== null && searchCriteria.pageSize !== '') {
                queryString += '&pageSize=' + searchCriteria.pageSize;
            }
        }
        const path = 'en/products/stockalertswithpaging?' + queryString;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getOrderNotificationAlarmList(searchCriteria) {
        // let queryString = 'customerID=' + searchCriteria.customerID;
        let queryString = '';
        if (searchCriteria !== null) {

            // tslint:disable-next-line:max-line-length
            if (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== '') {
                queryString += '&pageIndex=' + searchCriteria.pageIndex;
            }
            if (searchCriteria.pageSize !== null && searchCriteria.pageSize !== '') {
                queryString += '&pageSize=' + searchCriteria.pageSize;
            }
            if (searchCriteria.webshopID !== null && searchCriteria.webshopID !== '') {
                queryString += '&LoggedInCustomerID=' + searchCriteria.webshopID;
            }
        }
        const path = 'en/ordernotificationsalarmwidget?' + queryString;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getInActiveUsersList(searchCriteria) {
        //console.log("CustomerID in service" + searchCriteria.customerID);
        // let queryString = 'customerID=' + searchCriteria.customerID;
        let queryString = '';
        if (searchCriteria !== null) {
            // tslint:disable-next-line:max-line-length
            if (searchCriteria.webshopID !== null && searchCriteria.webshopID !== '') {
                queryString += 'customerID=' + searchCriteria.webshopID;
            }
            if (searchCriteria.includChildShopUsers !== null && searchCriteria.includChildShopUsers !== '') {
                queryString += '&includChildShopUsers=' + searchCriteria.includChildShopUsers;
            }
            if (searchCriteria.showDeletedUsers !== null && searchCriteria.showDeletedUsers !== '') {
                queryString += '&showDeletedUsers=' + searchCriteria.showDeletedUsers;
            }
            if (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== '') {
                queryString += '&pageIndex=' + searchCriteria.pageIndex;
            }
            if (searchCriteria.pageSize !== null && searchCriteria.pageSize !== '') {
                queryString += '&pageSize=' + searchCriteria.pageSize;
            }
        }

        const path = 'en/users/widget?' + queryString;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }


    getOrderFlow(searchCriteria) {
        let id = (searchCriteria.customerID)
        if (!id) {
            let web = JSON.parse(sessionStorage.getItem('customerDetails'));
            id = web.CustomerID;
        }
        const path = 'en/ordersbymonths/6?customerID=' + id;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductionLine(searchCriteria) {
        let queryString = '';
        //console.log("searchCriteria : ", searchCriteria);
        if (searchCriteria !== null) {
            queryString = '?customerID=' + searchCriteria.webshopID;
            if (searchCriteria.productionLineStatusID !== null && searchCriteria.productionLineStatusID !== '') {
                queryString += '&productionLineStatusID=' + searchCriteria.productionLineStatusID;
            }
            if (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== '') {
                queryString += '&pageIndex=' + searchCriteria.pageIndex;
            }
            if (searchCriteria.pageSize !== null && searchCriteria.pageSize !== '') {
                queryString += '&pageSize=' + searchCriteria.pageSize;
            }
        }
        const path = 'en/productionlineswidget' + queryString;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getProductList(searchCriteria) {
        const path = 'en/productspreview?customerID=' + searchCriteria.customerID + '&sortBy=1&pageIndex=1&pageSize=5';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getMessage(searchCriteria) {
        const path = 'en/emails?user_id=' + searchCriteria.UserID + '&user_type=admin&inbox=true&pageIndex=1&pageSize=5&CustomerID=' + searchCriteria.customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getOrderState(searchCriteria) {
        const path = 'en/orderitemstates?roleCode=Webshop_Admin&altaPayStates=true&isOrderbyname=true&customerID=' + searchCriteria.customerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getPendingUser(searchCriteria) {
        const path = 'en/users/list/' + searchCriteria.customerID + '?pageIndex=1&pageSize=5&roleID=&roleCode=Webshop_Admin&IsRequireUsersApproved=true&includChildShopUsers=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getLikedProducts(searchCriteria) {
        let queryString = '';
        //console.log("searchCriteria : ", searchCriteria);
        if (searchCriteria !== null) {
            if (searchCriteria.CustomerId !== '') {
                queryString = '?CustomerID=' + searchCriteria.CustomerId;
            }
            if (searchCriteria.ProductIdManual !== null && searchCriteria.ProductIdManual !== '') {
                queryString += '&ProductIdManual=' + searchCriteria.ProductIdManual;
            }
            if (searchCriteria.PageIndex !== null && searchCriteria.PageIndex !== '') {
                queryString += '&PageIndex=' + searchCriteria.PageIndex;
            }
            if (searchCriteria.PageSize !== null && searchCriteria.PageSize !== '') {
                queryString += '&PageSize=' + searchCriteria.PageSize;
            }
        }
        const path = 'en/productlikes/LikedProductOverview' + queryString;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCustomersList() {
        const path = 'en/customers/list';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getParentCustomersList(customerId) {
        const path = 'en/customers/list?ParentCustomerID=' + customerId + '&returnParentCustomer=true&isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getChildShops(customerId, start, end) {
        let user = JSON.parse(sessionStorage.getItem('userdetails'));
        const path = 'en/customer/childshops?ParentCustomerID=' + customerId + '&orderPlacedFromDate=' + start + '&orderPlacedToDate=' + end+'&UserID='+user.UserID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
}
