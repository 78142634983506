import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { CommonFilterComponent } from '../common-filter.component';
import { FormsModule } from '@angular/forms';
import { FilterTagsComponent } from '../filter-tags/filter-tags.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  declarations: [FilterTagsComponent],
  exports: [FilterTagsComponent],
})

export class FilterTagModule { }