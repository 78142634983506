import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';

@Injectable({
    providedIn: 'root'
})
export class BlockHttpService {
    baseUrl = 'en/productblocks';
    webshopDetail: any;
    constructor(private apiService: ApiService, private loginService: LoginService) {
    }

    getBlockList(payloadData: any) {
        const path = 'all/productblocks?admin=true&translations=true&customerID=' + payloadData.payload;
        return this.apiService.get(path).map((res) => {
            return res;
        });

    }

    getBlockDataByBlockId(ProductBlockID) {
        const path = 'all/productblocks/' + ProductBlockID.id + '?admin=true&translations=true&customerID=' + ProductBlockID.CustomerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    saveBlock(data: any) {
        const path = 'en/productblocks/' + data.ProductBlockID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateBlock(ProductBlockID: number, data: any) {
        const path = 'en/productblocks/' + ProductBlockID;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    addBlock(data: any) {
        return this.apiService.post('en/productblocks', data).map((res) => {
            return res;
        });
    }

    deleteBlock(id: number) {
        const path = `${this.baseUrl}/${id}`;
        return this.apiService.delete(path).map(res => {
            return res;
        });
    }

    getLanguages() {
        const path = 'en/languages';
        return this.apiService.get(path);
    }

    changeSortOrderOfBlock(data) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
        const customerID = this.webshopDetail.CustomerID;
        return this.apiService.put('en/productblocks/changeSortOrder', { ...data, customerID }).map((res) => {
            return res;
        });
    }
}
