import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-link-download-icon',
  templateUrl: './link-download-icon.component.html',
  styleUrls: ['./link-download-icon.component.scss']
})
export class LinkDownloadIconComponent implements OnChanges {
  @Input() url = '';
  @Input() label = '';
  @Input() invoiceurl = '';
  @Input() invoiceLabel = '';
  @Input() noProductFoundText = '';
  constructor() { }

  ngOnChanges() {
  }

}
