import { Component, OnInit, Input } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { DashboardService } from '../state/services/dashboard.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-stock-alarms',
  templateUrl: './dashboard-stock-alarms.component.html',
  styleUrls: ['./dashboard-stock-alarms.component.scss']
})
export class DashboardStockAlarmsComponent implements OnInit {
  @Input() customerList: any = [];
  activePage: number = 1;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  public stockList: any = [];
  public stockListMasterData: any = [];
  public stockListCustomize: any = [];
  public titles: any;
  public totalCountStock: any;
  public totalPagingCountStock: any;
  stockQueryParams: any = {};
  showLoader: boolean = false;
  public stockcolumns: ColumnSetting[] = [];
  tableName: any = 'dashboardstockalarm';

  public filterFields: any = [];
  public stockAlarmListQuery: any = {};
  tableEvent: any;
  resetPaginationNumber: boolean = false;
  loginuserdetails: any;
  webshopDetail: any;
  isParentCustomer: any;

  constructor(public dashboardservice: DashboardService, public dashboardhttpservice: DashboardHttpService, public apiService: ApiService, private router: Router,
    private linkTableService: LinkTableService, private translate: TranslateService) {
    this.titles = this.translate.instant('DASHBOARD.STOCKALARMS');
    const webshop = sessionStorage.getItem('webshopDetail');
    this.isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.stockcolumns = [
      {
        primaryKey: 'ProductName',
        header: this.translate.instant('DASHBOARD.PRODUCTNAME')
      },
      {
        primaryKey: 'ProductIdManual',
        header: this.translate.instant('DASHBOARD.PRODUCTID')
      },
      {
        primaryKey: 'AlertTypeName',
        header: this.translate.instant('DASHBOARD.ALERTTYPE')
      },
      {
        primaryKey: 'StockValue',
        header: this.translate.instant('DASHBOARD.STOCKBALANCEINCOMINGSTOCK')
      },
      {
        primaryKey: 'StockBalanceAlertTypeName',
        header: this.translate.instant('DASHBOARD.STOCKBALANCEINCOMINGSTOCKSTATUS'),
        format: 'StockBalanceAlertType'
      },
      {
        primaryKey: 'webshopName',
        header: this.translate.instant('MENU.WEBSHOPS'),
      },
      {
        primaryKey: 'PublishedStatus',
        header: this.translate.instant('DASHBOARD.PRODUCTSTATUS'),
        format: 'dashboardStockAlarmStatus',
      },
      {
        primaryKey: '',
        header: this.translate.instant('DASHBOARD.EDITPRODUCT'),
        format: 'actionedit'
      }
    ];
    this.onLoadFunctions();
  }

  ngOnInit() {
    let webshopEnableStatus = true;
    if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
      webshopEnableStatus = false;
      if (this.webshopDetail.IsWebshopDropdown) {
        webshopEnableStatus = true;
      }
    }
    this.filterFields = [
      {
        label: this.translate.instant('MENU.WEBSHOPS'),
        type: 'select',
        key: 'selectwebshop',
        data: [],
        placeholder: this.translate.instant('CATEGORIES.SELECT_ALL'),
        class: (webshopEnableStatus && this.isParentCustomer) ? 'col-6' : ''
      },
      {
        label: this.translate.instant('DASHBOARD.ALARM'),
        type: 'select',
        selected: 'All',
        key: 'Alarm',
        class: 'col-6',
        data: [{
          label: 'All',
          value: 'All',
          selected: true
        },
        {
          label: this.translate.instant('DASHBOARD.INCOMINGSTOCK') + ' ( )',
          value: 2
        },
        {
          label: this.translate.instant('DASHBOARD.STOCKBALANCE') + ' ( )',
          value: 1
        }
        ]
      }
    ];
    this.stockAlarmListQuery.webshopID = 'All';
    this.getDashboardStockList();
    this.getAllCustomerList();
  }

  getSelectData(alarmName) {
    //console.log("alarmName : ", alarmName);
    if (alarmName.selectwebshop.value !== '') {
      if (this.stockAlarmListQuery.webshopID !== alarmName.selectwebshop.value) {
        this.stockAlarmListQuery.alertType = '';
        if (alarmName.Alarm !== undefined) {
          alarmName.Alarm.value = 'All';
        }
        this.filterFields[1].selected = 'All';
      }
    }
    if (alarmName.Alarm !== undefined && alarmName.Alarm.selectMethod === 'Alarm') {
      if (alarmName.Alarm.value !== '') {
        this.stockAlarmListQuery.alertType = alarmName.Alarm.value;
      } else {
        this.stockAlarmListQuery.alertType = '';
      }
    }
    if (alarmName.selectwebshop !== undefined && alarmName.selectwebshop.selectMethod === 'selectwebshop') {
      if (alarmName.selectwebshop.value !== '') {
        this.stockAlarmListQuery.webshopID = alarmName.selectwebshop.value;
      } else {
        this.stockAlarmListQuery.webshopID = 'All';
      }
      this.activePage = 1;
      this.perPagedata = 10;
    }
    this.stockAlarmListQuery.pageIndex = 1;
    this.stockAlarmListQuery.pageSize = 10;
    this.getDashboardStockList();
  }

  editProduct(productData: any) {
    // this.router.navigate(['/admin-product-edit'], { state: { productData }, replaceUrl: false });
    //console.log("product Data : ", productData);
    if (productData) {
      this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
        if (data) {
          data.dashboardRedirectStatus = true;
          if (data.isMarketingProduct) {
            this.router.navigate(['/product-market-edit'], { state: { productData, data }, replaceUrl: false });
          }
          else {
            const type = {
              type: 'stock',
              customer: this.stockAlarmListQuery.webshopID
            };
            this.router.navigate(['/admin-product-edit'], { state: { productData, data, type }, replaceUrl: false });
          }
        }
      },
        _err => {
          //console.log(err);
        });
    }
  }

  onLoadFunctions() {
    this.stockQueryParams.customerID = this.webshopDetail.CustomerID;
    // this.dashboardservice.loadStock(this.stockQueryParams);
  }

  paginationNum(event) {
    //console.log("paginationNum : ", event);
    this.activePage = (this.resetPaginationNumber) ? 1 : event;
    this.stockAlarmListQuery.pageIndex = (this.resetPaginationNumber) ? 1 : event;
    this.resetPaginationNumber = false;
    this.getDashboardStockList();
  }

  getPerPage(event) {
    //console.log(event)
    this.perPagedata = event;
    this.stockAlarmListQuery.pageSize = event;
    this.resetPaginationNumber = true;
  }

  getDashboardStockList() {

    this.showLoader = true;
    const loginData = this.apiService.getLoginData();
    let searchData = {
      'customerID': loginData.CustomerID,
      // 'webshopID': (this.stockAlarmListQuery.webshopID !== undefined && this.stockAlarmListQuery.webshopID !== null) ?
      //  this.stockAlarmListQuery.webshopID : '',
      'alertType': (this.stockAlarmListQuery.alertType !== undefined && this.stockAlarmListQuery.alertType !== null) ?
        this.stockAlarmListQuery.alertType : '',
      'pageIndex': (this.stockAlarmListQuery.pageIndex !== undefined && this.stockAlarmListQuery.pageIndex !== null) ?
        this.stockAlarmListQuery.pageIndex : this.activePage,
      'pageSize': (this.stockAlarmListQuery.pageSize !== undefined && this.stockAlarmListQuery.pageSize !== null) ?
        this.stockAlarmListQuery.pageSize : this.rowPagepage
    }
    if (this.webshopDetail.ParentCustomerID === null) {
      searchData['webshopID'] = (this.stockAlarmListQuery.webshopID !== undefined && this.stockAlarmListQuery.webshopID !== null) ?
        this.stockAlarmListQuery.webshopID : '';
      if (searchData['webshopID'] === 'All') {
        searchData['webshopID'] = this.webshopDetail.CustomerID;
        searchData['IncludeChildCustomers'] = true;
      }
    } else {
      searchData['webshopID'] = this.webshopDetail.CustomerID;
    }
    //console.log("this.stockAlarmListQuery : ", this.stockAlarmListQuery);
    this.dashboardhttpservice.getStockList(searchData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        data.ItemsCollection.forEach(element => {
          element.AlertTypeName = this.translate.instant('DASHBOARD.INCOMINGSTOCK');
          // element.PublishedStatus = '<p class="text-success">Published</p>';
          element.PublishedStatus = this.translate.instant('DASHBOARD.PUBLISHED');
          // tslint:disable-next-line:max-line-length
          element.webshopName = (element.ProductCustomers !== null && element.ProductCustomers !== undefined && element.ProductCustomers.length > 0) ? element.ProductCustomers[0].CustomerName : '-';
          if (Number(element.StockValue) === 0) {
            element.StockValue = '0';
          }
          if (element.AlertType === 1) {
            element.AlertTypeName = this.translate.instant('DASHBOARD.STOCKBALANCE');
          }
          if (element.AlertType === 1) {
            element.StockBalanceAlertTypeName = Number(element.StockBalanceAlert) ? element.StockBalanceAlert : '0';
          }
          if (element.AlertType === 2) {
            if (Number(element.ExpiresInDays) > 0) {
              // element.StockBalanceAlertTypeName = '<p class="text-success">Expires in ' + element.ExpiresInDays + ' days</p>';
              // tslint:disable-next-line:max-line-length
              element.StockBalanceAlertTypeName = this.translate.instant('DASHBOARD.EXPIRESIN') + ' ' + element.ExpiresInDays + ' ' + this.translate.instant('DASHBOARD.DAYS');
            } else {
              // element.StockBalanceAlertTypeName = '<p class="text-danger">Delayed</p>';
              element.StockBalanceAlertTypeName = this.translate.instant('DASHBOARD.DELAYED');
            }
          }
          // let div1 = document.createElement('div');
          // div1.innerHTML = '<b>Hello!</b>';
          // const check = div1.getElementsByTagName('iframe')[0];
          // element.StockBalanceAlertTypeName = check;

          if (!element.IsPublished) {
            //  element.PublishedStatus = '<p class="text-danger">Unpublished</p>';
            element.PublishedStatus = this.translate.instant('DASHBOARD.UNPUBLISHED');
          }

        });
        this.stockList = data.ItemsCollection;
        this.totalCountStock = data.TotalItemsCountAllStatuses;
        this.totalPagingCountStock = data.TotalItemsCount;
        this.enablepagination = true;
        if (data.ItemsCollection.length === 0) {
          this.enablepagination = false;
        }
        this.stockListMasterData = data;
        this.filterFields[1].data = [{
          label: 'All',
          value: 'All',
          selected: true
        },
        {
          label: this.translate.instant('DASHBOARD.INCOMINGSTOCK') + ' (' + data.Status2Count + ')',
          value: 2
        },
        {
          label: this.translate.instant('DASHBOARD.STOCKBALANCE') + ' (' + data.Status1Count + ')',
          value: 1
        }
        ];
      }
      else {
        this.showLoader = false;
      }
    },
      _err => {
        //console.log(err);
        this.showLoader = false;
      });
  }

  getAllCustomerList() {

    const webshopData = [{ label: this.translate.instant('CATEGORIES.SELECT_ALL'), value: '' }];
    this.customerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    })
    this.filterFields[0].data = webshopData;
  }

}
