import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsHttpService } from '../services/reports-http.service';
import { ReportAbstract } from '../services/report-abstract';
import { ToastService } from 'ng-uikit-pro-standard';
import { CommonFilterComponent } from 'src/app/common/common-filter/common-filter.component';
@Component({
    selector: 'rewardkart-admin-report-orders',
    templateUrl: './report-orders.component.html',
    styleUrls: ['./report-orders.component.scss']
})
export class ReportOrdersComponent extends ReportAbstract
    implements OnInit {
    header = 'Order Reports';
    excludeKeyInUrlParam = ['orderDateFrom', 'CustomerID', 'orderDateTo'];
    noProductFoundText = 'No orders found.';
    downloadReport = 'Download Report';
    invoiceReport = 'Download Invoice';
    selectedPayment = null;
    supplierLoader = false;
    suppliers: any = [];
    payments = [];
    searchCriteria: any = {};
    filterFields: any = [];
    commonRemoveFilter: any;
    orderDateFrom = '';
    orderDateTo = '';
    languages = [];
    selectedLanguage = '';
    customerList = [];
    @ViewChild('commonFilter', { static: true }) commonFilter: CommonFilterComponent;
    customerLoader = false;
    selectedCustomerID = '';
    reportData: any;
    webshopDetail: any;
    includChildShopOrders = '&includChildShopOrders=false';
    paymentFetched: Boolean = false;
    selectAll: Boolean = false;
    constructor(public reportHttpService: ReportsHttpService, private toaster: ToastService) {
        super();
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
    }

    ngOnInit() {
        this.searchCriteria = {
            selectedCustomerID: '',
            selectedPayment: '',
            selectedLanguage: '',
            selectedSupplier: ''

        }
        this.getLanguages(this.webshopDetail.CustomerID);
        this.getSuppliers(this.webshopDetail.CustomerID);
    }

    stateChangeEvent(eventData) {
        if (eventData.key === 'CustomerID') {
            this.getSuppliers(eventData.value);
        }
    }


    getLanguages(CustomerID) {
        this.reportHttpService.getLanguages(CustomerID).subscribe(
            (languageResponse: any) => {
                if (languageResponse) {
                    this.languages = languageResponse.map(language => ({
                        value: language.Code,
                        label: language.Name
                    }));
                    this.updateSearchFormFields('languageID', this.languages);
                    if (this.paymentFetched === false) {
                        this.getPayments();
                        this.paymentFetched = true;
                    }

                }
            },
            error => {
                this.toaster.error('unable to get language');
            }
        );
    }

    getPayments() {
        this.reportHttpService.getPayments().subscribe(
            (paymentResponse: any) => {
                if (paymentResponse) {
                    this.payments = paymentResponse.map(payment => ({
                        value: payment.PaymentTypeID,
                        label: payment.Name
                    }));
                    this.getCustomers();
                }
            },
            error => {
                this.toaster.error('unable to get payment');
            }
        );
    }

    getCustomers() {
        this.customerLoader = true;
        this.reportHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
            (customersRes: any) => {
                if (customersRes) {
                    const customerlist = [{ label: 'Select All', value: 0 }];
                    customersRes.forEach(cust => {
                        let custData = {
                            label: cust.Name,
                            value: cust.CustomerID
                        }
                        customerlist.push(custData);
                    });
                    this.customerList = customerlist;
                    this.updateSearchFormFields('CustomerID', this.customerList);
                    this.formFields();
                }
                this.customerLoader = false;
            },
            error => {
                this.customerLoader = false;
                this.toaster.error('unable to get webshop customers');
            }
        );
    }

    formFields() {
        this.filterFields = [
            {
                label: 'Filter',
                class: 'col-md-12 pl-3 pt-4',
                key: 'filterHeading',
                filterkey: ''
            }];
        const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
        if (getCustomerDetails !== null) {
            if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
                this.filterFields.push(
                    {
                        label: 'Select Webshop',
                        type: 'select',
                        data: this.customerList,
                        class: 'col-md-12 no-icon',
                        key: 'CustomerID',
                        filterkey: 'CustomerID',
                        selected: this.searchCriteria.selectedCustomerID
                    }
                );
            }
        }
        this.filterFields.push(
            {
                label: 'Language*',
                type: 'select',
                class: 'col-md-12',
                key: 'languageID',
                data: this.languages,
                selected: this.searchCriteria.selectedLanguage,
                required: true,
                filterkey: 'languageID'
            },
            {
                label: 'Select Payments',
                type: 'select',
                data: this.payments,
                class: 'col-md-12 no-icon',
                key: 'paymentTypeID',
                selected: this.searchCriteria.selectedPayment,
                filterkey: 'paymentTypeID'
            },
            {
                label: 'Select Supplier',
                type: 'select',
                data: this.suppliers,
                class: 'col-md-12 no-icon',
                key: 'supplierID',
                selected: this.searchCriteria.selectedSupplier,
                filterkey: 'supplierID'
            },
            {
                label: 'Order placed date from ',
                type: 'date',
                class: 'col-md-12',
                key: 'orderDateFrom',
                default: '',
                selected: '',
                format: {
                    closeAfterSelect: true,
                    dateFormat: 'dd/mm/yyyy',
                },
                filterkey: 'orderDateFrom'
            },
            {
                label: 'Order placed date To',
                type: 'date',
                class: 'col-md-12',
                key: 'orderDateTo',
                default: '',
                selected: '',
                format: {
                    closeAfterSelect: true,
                    dateFormat: 'dd/mm/yyyy',
                },
                filterkey: 'orderDateTo'
            },
            {
                label: 'Order with VAT',
                type: 'checkbox',
                data: false,
                class: 'col-md-12 justify-content-center',
                key: 'onlyOrdersWithVAT',
                filterkey: 'onlyOrdersWithVAT',
                selected: false
            },
            {
                label: 'Order without VAT',
                type: 'checkbox',
                data: false,
                class: 'col-md-12 justify-content-center',
                key: 'onlyOrdersWithoutVAT',
                filterkey: 'onlyOrdersWithoutVAT',
                selected: false
            },
            {
                label: 'Export Invoices',
                type: 'checkbox',
                data: false,
                class: 'col-md-12 justify-content-center',
                key: 'exportInvoices',
                filterkey: 'exportInvoices',
                selected: false
            },
            {
                label: 'Clear',
                type: 'button',
                class: 'col-sm-6 text-lefts mt-3 margin-width',
                key: 'clear',
            },
            {
                label: 'Search',
                type: 'button',
                class: 'col-sm-6 text-left mt-3 margin-width',
                key: 'button',
            }
        );
    }

    updateSearchFormFields(key: any, value: any) {
        if (this.commonFilter && this.commonFilter.filterFormField !== undefined) {
            this.commonFilter.filterFormField.forEach((field) => {
                if (field.key === key) {
                    field.data = value;
                    field.selected = this.searchCriteria[field.key];
                }
            });
        }
    }

    buildReportURL(parameter) {
        return `${this.selectedLanguage}/reports/OrdersReport?${parameter}${this.selectedCustomerID}
        ${this.orderDateFrom}${this.orderDateTo}${this.includChildShopOrders}`;
    }

    getSuppliers(customerId: any) {
        this.supplierLoader = true;
        if (customerId === 0) {
            customerId = this.webshopDetail.CustomerID;
        }
        this.reportHttpService.getSuppliers(customerId, this.webshopDetail.CustomerID).subscribe(
            (supplierRes: any) => {
                if (supplierRes) {
                    this.suppliers = supplierRes.ItemsCollection.map(supplier => ({
                        value: supplier.SupplierID,
                        label: supplier.Name
                    }));
                    const selectSupplier = { value: '', label: 'Select Supplier' };
                    this.suppliers = this.suppliers.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label));
                    this.suppliers = [selectSupplier, ...this.suppliers];
                    this.updateSearchFormFields('supplierID', this.suppliers);
                }
                this.supplierLoader = false;
            },
            error => {
                this.toaster.error('unable to get suppliers');
            }
        );
    }

    getSelectData(filterResult) {
        const { languageID, orderDateFrom, orderDateTo, CustomerID } = filterResult;
        this.selectedLanguage = (languageID ? languageID.value : 'en') || 'en';
        const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
        if (orderDateFrom && orderDateFrom.value !== '') {
            const formatedDate = this.reportHttpService.dateFormatchange(orderDateFrom.value);
            this.orderDateFrom = `&${orderDateFrom.selectMethod}=${formatedDate}`;
        }
        if (orderDateTo && orderDateTo.value !== '') {
            const formatedDate = this.reportHttpService.dateFormatchange(orderDateTo.value);
            this.orderDateTo = `&${orderDateTo.selectMethod}=${formatedDate}`;
        }
        if (CustomerID && CustomerID.value !== 0 && CustomerID.value !== '') {
            this.includChildShopOrders = `&includChildShopOrders=false`;
            if (this.selectedCustomerID !== `&${CustomerID.selectMethod}=${CustomerID.value}` || this.selectedCustomerID == '') {
                this.selectedCustomerID = `&${CustomerID.selectMethod}=${CustomerID.value}`;
                this.selectAll = false;
                this.getLanguages(CustomerID.value)
            }
        } else if (CustomerID && CustomerID.value === 0) {
            this.selectedCustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
            this.includChildShopOrders = `&includChildShopOrders=true`;
            if (this.selectAll == false) {
                this.getLanguages(this.webshopDetail.CustomerID)
                this.selectAll = true;
            }
        } else if (CustomerID === undefined && getCustomerDetails &&
            getCustomerDetails.ParentCustomerID != null) {
            this.selectedCustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
            this.includChildShopOrders = `&includChildShopOrders=false`;

        }

    }

    handleResetForm(event) {

        this.filterFields.forEach(element => {
            if (event[element.key] !== undefined)
                event[element.key].value = '';
        });
        if (this.selectedCustomerID !== '') {
            this.getLanguages(this.webshopDetail.CustomerID);
        }
        this.selectedCustomerID = '';
        this.selectedLanguage = '';
        this.orderDateFrom = '';
        this.orderDateTo = '';
        if (this.reportData && this.reportData.ReportFileURL != null) {
            this.reportData.ReportFileURL = null;
        }
        if (this.reportData && this.reportData.InvoicesFileURL != null) {
            this.reportData.InvoicesFileURL = null;
        }
    }
}
