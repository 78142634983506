import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersEditComponent } from './orders-edit.component';
import { AddressComponent } from './address/address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({

  declarations: [OrdersEditComponent, AddressComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot()
  ]
})
export class OrdersEditModule { }
