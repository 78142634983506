import { Component, OnInit, Input } from '@angular/core';
import { OrderProcessOverviewHttpService } from 'src/app/order/state/services/order-process-overview-http.service';
import { ToastService } from 'ng-uikit-pro-standard';


@Component({
  selector: 'rewardkart-admin-order-item-summary',
  templateUrl: './order-item-summary.component.html',
  styleUrls: ['./order-item-summary.component.scss']
})
export class OrderItemSummaryComponent implements OnInit {
  @Input() orderItem: any = {};
  @Input() currency = '';
  @Input() subOrderID = '';
  @Input() orderId = '';
  @Input() profit = {};

  constructor(private httpService: OrderProcessOverviewHttpService, private toastService: ToastService) { }

  ngOnInit() {
  }



}
