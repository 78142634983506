import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { CompanyOrderSummaryModule } from "./company-order-summary/company-order-summary.module";
import { CompanyOrderDetailComponent } from "./company-order-detail/company-order-detail.component";
import { CompanyOrderDetailViewComponent } from "./company-order-detail-view/company-order-detail-view.component";
import { CompanyOrderHttpService } from './state/services/company-order.http.services';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        CompanyOrderSummaryModule
    ],
    declarations: [
        CompanyOrderDetailComponent,
        CompanyOrderDetailViewComponent
    ],
    providers: [CompanyOrderHttpService],
    exports: [CompanyOrderSummaryModule]
})
export class CompanyModule { }