import { Brand } from '../models/index';
import { BrandActions } from '../actions/index';

export interface BrandState {
    fileUpload: any;
}

export const initialState: BrandState = {
    fileUpload: null
}

export function brandReducers(state: BrandState = initialState, action: BrandActions.All) {
    switch (action.type) {
        case BrandActions.FILE_UPLOAD_SUCCESS:
            return Object.assign({}, state, { fileUpload: action.payload });
    }
}