import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { ColumnSetting, ColumnMap } from '../link-table/models/link-table-model';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { LinkTableService } from '../link-table/state/services/link-table.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'rewardkart-admin-link-draganddrop-table',
  templateUrl: './link-draganddrop-table.component.html',
  styleUrls: ['./link-draganddrop-table.component.scss']
})
export class LinkDraganddropTableComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Input() rows: any[];
  @Input() columns: ColumnSetting[];
  @Input() header: any;
  @Input() actions: any;
  @Input() noRecordFoundText: any;

  @Output() editSelectedRowEvent = new EventEmitter<boolean>();
  @Output() deleteSelectedRowEvent = new EventEmitter<boolean>();
  @Output() draganddropEvent = new EventEmitter();

  deleteData: any;
  actiontype: any = '';
  columnMaps: ColumnMap[];
  isDesc: any;
  column: any;
  constructor(private linkTableService: LinkTableService) { }

  ngOnChanges() {
    if (this.columns) {
      this.columnMaps = this.columns.map(col => new ColumnMap(col));
    } else {
      this.columnMaps = Object.keys(this.rows[0])
        .map(key => {
          return new ColumnMap({ primaryKey: key });
        });
    }

    if (this.noRecordFoundText === undefined) {
      this.noRecordFoundText = 'No Records Found';
    }
  }

  ngOnInit() {
  }

  editSelectedRow(row: any, type?) {
    if (type === 'delete') {
      return false;
    }
    if (type === 'viewbudget') {
      return false;
    }
    if (type !== 'delete') {
      this.linkTableService.setSelectedRow(row);
      this.editSelectedRowEvent.emit(true);
    }
  }

  deleteSelectedRow(row: any, type?) {
    this.deleteData = row;
    this.actiontype = 'delete';
    this.deleteModal.show();
  }

  getDeleteStatus(deleteData, action) {
    if (action) {
      this.deleteSelectedRowEvent.emit(deleteData);
      this.deleteModal.hide();
      this.actiontype = '';
    }
    else {
      this.deleteModal.hide();
      this.actiontype = '';
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
      this.draganddropEvent.emit(event);
    }
  }
  sort(any) { }

}
