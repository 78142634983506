import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { LoginHttpService } from '../state/services/login.http.service';

@Component({
  selector: 'rewardkart-admin-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  @Input() wheshopCustomerLogo: any;
  @Output() forgotPasswordEvent = new EventEmitter<boolean>();
  username: String;

  constructor(
    private loginService: LoginHttpService,
    private toast: ToastService
  ) {
  }
  ngOnInit() {
    console.log(this.wheshopCustomerLogo)
  }
  resetPassword() {
    let webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.loginService.resetPassword(this.username, webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.toast.success(data);
        this.username = '';
        this.forgotPasswordEvent.emit(true);
      }
    }, error => {
      this.toast.error(error.error.Message);
    })
  }

  back() {
    this.forgotPasswordEvent.emit(true);
  }
}
