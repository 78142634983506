import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProductManageAdditionalCostsComponent } from './product-manage-additional-cost.component';
import { ProductManageAdditionalOptionsModule } from '../product-manage-additional-option/product-manage-additional-option.module';
import { CustomdirectiveModule } from '../../customdirective/customdirective.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule ,
    CommonFormModule,
    SharedModule,
    TranslateModule,ProductManageAdditionalOptionsModule,
    CustomdirectiveModule
  ],
  declarations: [ProductManageAdditionalCostsComponent],
  exports: [ProductManageAdditionalCostsComponent]
})

export class ProductManageAdditionalCostsModule { }
