import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderState, orderReducers } from './order.reducer';

export interface State {
    orderModule: OrderState;
}

export const reducers = orderReducers;

export const getOrderState = createFeatureSelector<OrderState>('orderModule');

export const getOrderListSuccess = createSelector(getOrderState,
    (state: OrderState) => state.orders);

