
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ProductActions } from '../actions/index';
import { State } from '../reducers/index';
import { ProductHttpService } from '../services/product.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class ProductEffects {



    @Effect()
    getProductList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>
            this.productHttpService.getProductList(payload.payload).pipe(
                map(data => (
                    new ProductActions.LoadProductsSuccess(data)
                )),
                catchError((error: any) => of(new ProductActions.LoadProductsFail(error)))
            )
        ),
    );

    @Effect()
    getProductDetails$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_DETAILS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getProductDetails(payload.payload).map((data) => {
                return new ProductActions.LoadProductDetailsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductDetailsFail(error));
        })
    );

    @Effect()
    saveProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.SAVE_PRODUCT),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.saveProduct(payload.payload).map((data) => {
                return new ProductActions.SaveProductSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.SaveProductFail(error));
        })
    );

    @Effect()
    updateProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProduct(payload.payload).map((data) => {
                return new ProductActions.UpdateProductSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductFail(error));
        })
    );

    @Effect()
    deleteProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProduct(payload.payload).map((data) => {
                return new ProductActions.DeleteProductSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductFail(error));
        })
    );

    @Effect()
    getSuppliersList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_SUPPLIERS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getSuppliersList(payload.payload, '').map((data) => {
                return new ProductActions.LoadSuppliersSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadSuppliersFail(error));
        })
    );

    @Effect()
    getProductColorList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_COLORS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getProductColorList(payload.payload).map((data) => {
                return new ProductActions.LoadProductColorsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductColorsFail(error));
        })
    );

    @Effect()
    getProductSizeList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_SIZES),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getProductSizeList(payload.payload).map((data) => {
                return new ProductActions.LoadProductSizesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductSizesFail(error));
        })
    );

    @Effect()
    getProductLogoList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_LOGO_POSITION),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getProductLogoList(payload.payload).map((data) => {
                return new ProductActions.LoadProductLogoPositionSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductLogoPositionFail(error));
        })
    );

    @Effect()
    getProductLogoArea$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_LOGOAREA),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getProductLogoArea(payload.payload).map((data) => {
                return new ProductActions.LoadLogoAreaSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadLogoAreaFail(error));
        })
    );



    @Effect()
    getEnProductSizeList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_EN_PRODUCT_SIZES),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getEnProductSizeList(payload.payload).map((data) => {
                return new ProductActions.LoadEnProductSizesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadEnProductSizesFail(error));
        })
    );

    @Effect()
    addProductColor$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_COLOR),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductColor(payload.payload).map((data) => {
                return new ProductActions.AddProductColorSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductColorFail(error));
        })
    );

    @Effect()
    getProductGovernmentalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_GOVERNMENTAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(() => {
            return this.productHttpService.getProductGovernmentalCosts().map((data) => {
                return new ProductActions.LoadProductGovernmentalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductGovernmentalCostsFail(error));
        })
    );

    @Effect()
    addProductGovernmentalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_GOVERNMENTAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductGovernmentalCosts(payload.payload).map((data) => {
                return new ProductActions.AddProductGovernmentalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductGovernmentalCostsFail(error));
        })
    );

    @Effect()
    addProductAdditionalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_ADDITIONAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductAdditionalCosts(payload.payload).map((data) => {
                return new ProductActions.AddProductAdditionalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductAdditionalCostsFail(error));
        })
    );

    @Effect()
    addProductSize$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_SIZE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductSize(payload.payload).map((data) => {
                return new ProductActions.AddProductSizeSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductSizeFail(error));
        })
    );

    @Effect()
    addProductLogoPosition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_LOGO_POSITION),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductLogoPosition(payload.payload).map((data) => {
                return new ProductActions.AddProductLogoPositionSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductLogoPositionFail(error));
        })
    );

    @Effect()
    addProductProperty$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_PROPERTY),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductProperty(payload.payload).map((data) => {
                return new ProductActions.AddProductPropertiesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductPropertiesFail(error));
        })
    );

    @Effect()
    updateProductColor$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT_COLOR),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProductColor(payload.payload).map((data) => {
                return new ProductActions.UpdateProductColorSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductColorFail(error));
        })
    );

    @Effect()
    updateProductGovernmentalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT_GOVERNMENTAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProductGovernmentalCosts(payload.payload).map((data) => {
                return new ProductActions.UpdateProductGovernmentalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductGovernmentalCostsFail(error));
        })
    );

    @Effect()
    updateProductAdditionalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT_ADDITIONAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProductAdditionalCosts(payload.payload).map((data) => {
                return new ProductActions.UpdateProductAdditionalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductAdditionalCostsFail(error));
        })
    );

    @Effect()
    updateAllProductAdditionalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_ALL_PRODUCT_ADDITIONAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateAllProductAdditionalCosts(payload.payload).map((data) => {
                return new ProductActions.UpdateAllProductAdditionalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateAllProductAdditionalCostsFail(error));
        })
    );

    @Effect()
    updateProductSize$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT_SIZE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProductSize(payload.payload).map((data) => {
                return new ProductActions.UpdateProductSizeSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductSizeFail(error));
        })
    );
    @Effect()
    updateProductLogoPosition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT_LOGO_POSITION),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProductLogoPosition(payload.payload).map((data) => {
                return new ProductActions.UpdateProductLogoPositionSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductLogoPositionFail(error));
        })
    );

    @Effect()
    deleteProductColor$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_COLOR),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductColor(payload.payload).map((data) => {
                return new ProductActions.DeleteProductColorSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductColorFail(error));
        })
    );

    @Effect()
    deleteProductGovernmentalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_GOVERNMENTAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductGovernmentalCosts(payload.payload).map((data) => {
                return new ProductActions.DeleteProductGovernmentalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductGovernmentalCostsFail(error));
        })
    );

    @Effect()
    deleteProductAdditionalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_ADDITIONAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductAdditionalCosts(payload.payload).map((data) => {
                return new ProductActions.DeleteProductAdditionalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductAdditionalCostsFail(error));
        })
    );

    @Effect()
    deleteProductSize$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_SIZE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductSize(payload.payload).map((data) => {
                return new ProductActions.DeleteProductSizeSuccess(data);
            });
        }),

        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductSizeFail(error));
        })
    );

    @Effect()
    deleteProductLogoPosition$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_LOGO_POSITION),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductLogoPosition(payload.payload).map((data) => {
                return new ProductActions.DeleteProductLogoPositionSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductLogoPositionFail(error));
        })
    );


    @Effect()
    deleteProductUnit$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_UNIT),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductUnit(payload.payload).map((data) => {
                return new ProductActions.DeleteProductUnitSuccess(data);
            });
        }),

        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductUnitFail(error));
        })
    );

    @Effect()
    deleteProductProperty$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.DELETE_PRODUCT_PROPERTY),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteProductProperty(payload.payload).map((data) => {
                return new ProductActions.DeleteProductPropertiesSuccess(data);
            });
        }),

        catchError((error: any) => {
            return Observable.of(new ProductActions.DeleteProductPropertiesFail(error));
        })
    );

    // @Effect()
    // getUnderSuppliersList$: Observable<Action> = this.actions$.pipe(
    //     ofType(ProductActions.LOAD_UNDER_SUPPLIERS),
    //     withLatestFrom(this.store$),
    //     switchMap(([]: any) => {
    //         return this.productHttpService.getUnderSuppliersList().map((data) => {
    //             return new ProductActions.LoadUnderSuppliersSuccess(data);
    //         });
    //     }),
    //     catchError((error: any) => {
    //         return Observable.of(new ProductActions.LoadUnderSuppliersFail(error));
    //     })
    // );
    @Effect()
    getCustomerInfo$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_CUSTOMER_INFO),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getCustomerInfo(payload.payload).map((data) => {
                return new ProductActions.LoadCustomerInfoSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadCustomerInfoFail(error));
        })
    );

    @Effect()
    getAllProductColorList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_ALL_PRODUCT_COLORS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getAllProductColorList(payload.payload).map((data) => {
                return new ProductActions.LoadAllProductColorsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadAllProductColorsFail(error));
        })
    );

    @Effect()
    getAllProductGovtCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_ALL_PRODUCT_GOVERNMENTAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getAllProductGovtCosts(payload.payload).map((data) => {
                return new ProductActions.LoadAllProductGovtCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadAllProductGovtCostsFail(error));
        })
    );

    @Effect()
    getAllProductAdditionalCosts$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_ALL_PRODUCT_ADDITIONAL_COSTS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getAllProductAdditionalCosts(payload.payload).map((data) => {
                return new ProductActions.LoadAllProductAdditionalCostsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadAllProductAdditionalCostsFail(error));
        })
    );


    @Effect()
    checkProductCategory$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.CHECK_PRODUCT_CATEGORY),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.checkProductCategory(payload.payload).map((data) => {
                return new ProductActions.CheckProductCategorySuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.CheckProductCategoryFail(error));
        })
    );

    @Effect()
    getProductCategory$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCTS_CATEGORY),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getProductCategory(payload.payload).map((data) => {
                return new ProductActions.LoadProductsCategorySuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductsCategoryFail(error));
        })
    );

    @Effect()
    getProductProperties$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCTS_PROPERTIES),
        withLatestFrom(this.store$),
        switchMap(() => {
            return this.productHttpService.getProductProperties().map((data) => {
                return new ProductActions.LoadProductPropertiesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductPropertiesFail(error));
        })
    );

    @Effect()
    getParentProductColorList$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PARENT_PRODUCT_COLORS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getParentProductColorList(payload.payload).map((data) => {
                return new ProductActions.LoadParentProductColorsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadParentProductColorsFail(error));
        })
    );

    @Effect()
    getProductUnits$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_UNITS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getProductUnits().map((data) => {
                return new ProductActions.LoadProductUnitsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductUnitsFail(error));
        })
    );

    @Effect()
    addProductUnits$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.ADD_PRODUCT_UNITS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProductUnits(payload.payload).map((data) => {
                return new ProductActions.AddProductUnitsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.AddProductUnitsFail(error));
        })
    );

    @Effect()
    updateProductUnits$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.UPDATE_PRODUCT_UNITS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateProductUnits(payload.payload).map((data) => {
                return new ProductActions.UpdateProductUnitsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.UpdateProductUnitsFail(error));
        })
    );

    @Effect()
    getAllProductUnits$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_ALL_PRODUCT_UNITS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getAllProductUnits().map((data) => {
                return new ProductActions.LoadAllProductUnitsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadAllProductUnitsFail(error));
        })
    );

    @Effect()
    getProductSuppliers$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_SUPPLIERS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getProductSuppliers().map((data) => {
                return new ProductActions.LoadProductsSuppliersSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductsSuppliersFail(error));
        })
    );

    @Effect()
    getTransportationOriginCountries$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_TOC),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getTransportationOriginCountries().map((data) => {
                return new ProductActions.LoadTransportationOriginCountriesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadTransportationOriginCountriesFail(error));
        })
    );

    @Effect()
    getProductCurrency$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_CURRENCY),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getProductCurrency().map((data) => {
                return new ProductActions.LoadProductCurrencySuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductCurrencyFail(error));
        })
    );

    @Effect()
    getProductAdditionalCostType$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_ADDITIONAL_COST_TYPES),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getProductAdditionalCostTypes().map((data) => {
                return new ProductActions.LoadProductAdditionalCostTypesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductAdditionalCostTypesFail(error));
        })
    );

    @Effect()
    addProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_PRODUCT_CREATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addProduct(payload.payload).map((data) => {
                return new ProductActions.LoadProductCreateSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadProductCreateFail(error));
        })
    );

    @Effect()
    validateManualId$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_MANUALID_VALIDATION),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.validateManualId(payload.payload).map((data) => {
                return new ProductActions.LoadManualIDSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadManualIDFail(error));
        })
    );

    @Effect()
    getUnits$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_UNITS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getUnits().map((data) => {
                return new ProductActions.LoadUnitsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadUnitsFail(error));
        })
    );

    @Effect()
    getLogoType$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_LOGOTYPES),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getLogoType().map((data) => {
                return new ProductActions.LoadLogoTypeSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadLogoTypeFail(error));
        })
    );

    @Effect()
    getLogoTypeAll$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_LOGOTYPESAll),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getLogoTypeAll().map((data) => {
                return new ProductActions.LoadTypeAllSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadTypeAllFail(error));
        })
    );

    @Effect()
    getChangeVisibility$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_CHANGEVISIBILITY),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getChangeVisibility(payload.payload).map((data) => {
                return new ProductActions.LoadChangeVisibilitySuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadChangeVisibilityFail(error));
        })
    );

    @Effect()
    addLogoMax$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_LOGOMAXAREA),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.addLogoMax(payload.payload).map((data) => {
                return new ProductActions.LoadMaxAreaSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadMaxAreaFail(error));
        })
    );

    @Effect()
    deleteLogoArea$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_LOGOMAXAREADEL),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.deleteLogoArea(payload.payload).map((data) => {
                return new ProductActions.LoadAreaDelSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadAreaDelFail(error));
        })
    );

    @Effect()
    updateLogoArea$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_LOGOMAXAREAUPDATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.updateLogoArea(payload.payload).map((data) => {
                return new ProductActions.LoadAreaUpdateSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadAreaUpdateFail(error));
        })
    );

    @Effect()
    getHandlingCost$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_HANDLINGCOST),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.productHttpService.getHandlingCost().map((data) => {
                return new ProductActions.LoadHandlingCostSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadHandlingCostFail(error));
        })
    );

    @Effect()
    getCustomerProduct$: Observable<Action> = this.actions$.pipe(
        ofType(ProductActions.LOAD_CUSTOMERPRODUCT),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.productHttpService.getCustomerProduct(payload.payload).map((data) => {
                return new ProductActions.LoadCustomerProductsSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new ProductActions.LoadCustomerProductsFail(error));
        })
    );



    constructor(private actions$: Actions,
        private store$: Store<State>,
        private productHttpService: ProductHttpService) { }
}
