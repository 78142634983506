import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';

@Component({
  selector: 'rewardkart-admin-product-tags',
  templateUrl: './product-tags.component.html',
  styleUrls: ['./product-tags.component.scss']
})
export class ProductTagsComponent implements OnInit {
  @Output() enableTagStatus = new EventEmitter<any>();
  @ViewChild('producttagsadd', { static: false }) producttagsadd: ModalDirective;
  @ViewChild('deleteModal', { static: false }) deleteModal: ModalDirective;
  @ViewChild('producttagsaddproducts', { static: false }) producttagsaddproducts: ModalDirective;
  @ViewChild('producttagsgroupadd', { static: false }) producttagsgroupadd: ModalDirective;
  @ViewChild('deleteTagModal', { static: false }) deleteTagModal: ModalDirective;
  // @ViewChild(ModalDirective, { static: true }) public deleteTagModal: ModalDirective;

  @Input() webshopData: any;
  tagsList: any;
  manualID: any = '';
  editTagGroup: any;
  deleteData: any;
  productData: any = [];
  sendTag: any;
  deleteproductData: any;
  enableProductTags: Boolean = false;
  tagName: any = '';
  webshopDetail: any;
  deleteTagName: any = '';
  isOpenGroupAdd: Boolean = false;
  isOpenTagAdd: Boolean = false;
  productModal: Boolean = false;
  showLoader: Boolean = false;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.loadTags();

  }

  ngOnChanges() {
    if (this.webshopData) {
      this.enableProductTags = this.webshopData.EnableProductTags;
    }
  }

  loadTags() {

    let request: any = {
      CustomerID: this.webshopDetail.CustomerID
    }

    if (this.manualID) {
      request.manualID = this.manualID;
    }

    this.webshopHttpService.loadTagList(request).subscribe((data) => {
      if (data) {
        this.tagsList = data;
      }
    },
      (error) => {

      })
  }

  loadTagStatus(event) {
    this.producttagsadd.hide();
    this.producttagsgroupadd.hide();
    this.loadTags();
  }

  findTag() {

  }

  reLoadTag() {
    this.manualID = '';
    this.loadTags();
  }

  editTagsGroup(tag) {
    this.editTagGroup = tag;
    this.producttagsadd.show();
  }

  deleteTag(tag?) {
    this.deleteData = tag;
    this.deleteTagName = this.deleteData.Translations.length > 0 ? this.deleteData.Translations.filter(data => data.LanguageCode === 'en')[0]['PropertyValue'] : '';
    this.deleteModal.show();
    // this.webshopHttpService.deleteTagGroup().subscribe((data:any)=>{
    //   if(data){

    //   }
    // });
  }

  confirmDelete() {
    this.webshopHttpService.deleteTagGroup(this.deleteData.ProductTagsGroupID).subscribe((data: any) => {
      if (data) {
        this.toast.success('Group tag deleted successfully');
        this.deleteModal.hide();
        this.loadTags();
      }
    },
      (error) => {

      });
  }

  createTagGroup() {
    this.editTagGroup = '';
    this.sendTag = '';
    this.producttagsadd.show();
  }

  showProduct(data) {
    this.productData = data;
    if (this.productData) {
      this.tagName = this.productData.Translations.length > 0 ? this.productData.Translations.filter(data => data.LanguageCode === 'en')[0]['PropertyValue'] : '';
    }
    this.producttagsaddproducts.show();
  }

  addTag(tag, type) {
    // if (type !== 'add') {
    this.sendTag = tag;
    this.sendTag.type = type;
    // }

    this.producttagsgroupadd.show();
  }

  deleteProductTag(deleteData) {
    this.deleteproductData = deleteData;
    this.deleteTagModal.show();
    // this.webshopHttpService.deleteTag(deleteData.ProductTagID).subscribe((data)=>{
    //   if(data){

    //   }
    // })
  }

  confirmTagDelete() {
    this.webshopHttpService.deleteTag(this.deleteproductData.ProductTagID).subscribe((data) => {
      if (data) {
        this.toast.success('Tag deleted successfully');
        this.deleteTagModal.hide();
        this.loadTags();
      }
    })
  }

  enableProductTag(event) {
    this.enableTagStatus.emit(event.checked);
  }


  openCloseGroupTagAdd(event, type) {
    this.isOpenGroupAdd = type === 'open' ? true : false;
  }

  openCloseTagAdd(event, type) {
    this.isOpenTagAdd = type === 'open' ? true : false;
  }

  openCloseProduct(event, type) {
    this.productModal = type === 'open' ? true : false;
  }

  getloaderStatus(event) {
    this.showLoader = event;
  }

}
