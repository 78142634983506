import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../state/services/product.service';
import { ProductHttpService } from '../../state/services/product.http.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { LoginService } from '../../../login/state/services/login.service';
import * as _ from 'lodash';
import { ApiService } from '../../../shared/common-services/api.service';

@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss']
})
export class ProductPricesComponent implements OnInit, OnChanges {
  @Input() productDetails: any;
  @Input() loadApi: any;
  @Output() getproductPriceEdit = new EventEmitter<any>();
  @Output() productPriceData = new EventEmitter<any>();
  @Output() priceFormData = new EventEmitter<any>();
  @Output() fixedLogoData = new EventEmitter<any>();
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('selectedfixedlogo', { static: true }) selectedfixedlogo: ModalDirective;
  @ViewChild('fixedlogodeleteModal', { static: true }) fixedlogodeleteModal: ModalDirective;
  @Output() defaultLogotype = new EventEmitter<any>();
  @Input() customerProduct: any;
  optionsSelect: Array<any>;
  productPrice: any;
  minimumQuantity: any;
  maximumQuantity: any;
  enterPrice: any;
  isChecked: boolean;
  currencyList: any;
  logoTypeList: any = [];
  loadManageApi: any = {
    managePrice: false
  };
  loadApiFlag: Boolean = false;
  deleteData: any;
  resetLogoTypeModal: Boolean = false;
  resetPrintPriceModal: Boolean = false;
  showLogoTypeModal: Boolean = false;
  printPriceForm: FormGroup;
  priceForm: FormGroup;
  printPriceArray: any[];
  linknetworkcost: any;
  supplierprofits: any;
  customerCost: any;
  customerData: any;
  currentPrintPrice: number = 0;
  prevVal: any;
  nextVal: any;
  showchecklabel: boolean = false;
  viewmode: any
  viewmodeAdd: any;
  oldvaluelist: any;
  oldvalueadd: any;
  disableEvent: boolean;
  passdata: boolean = false;
  addFormCheck: any;
  defaultLogoType: any;
  defaultLogoDescription: string = '';
  popupList: any;
  selectlabel: any;
  dropdownData: any = {};
  selectData: any;
  optionsFixedLogos: Array<any>;
  fixedLogoPosition: any = [];
  fixedLogoMaxAreas: any = [];
  fixedLogoTypes: any = [];
  selectedLogoPosition: any = '';
  selectedLogoType: any = '';
  selectedLogoArea: any = '';
  selectedLogos: any = '';
  selectedLogosList: any = [];
  deleteIndex: any;
  testvalue: any;
  webshopDetail: any;
  productCustomerID: any;
  showParent: Boolean = false;
  getByePassStatus: any;
  childCustomerCost: any;
  constructor(public toast: ToastService, public translate: TranslateService, private productService: ProductService, public formBuilder: FormBuilder, public loginService: LoginService, private productHttpService: ProductHttpService, private apiService: ApiService) {
    const prodID = sessionStorage.getItem('productCustomerID');
    this.productCustomerID = Number(prodID);
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.addFormCheck = {
      minimumQuantity: false,
      maximumQuantity: false,
      enterPrice: false,
      customerCost: false,
      supplierprofits: false,
      linknetworkcost: false
    }

    this.getByePassStatus = sessionStorage.getItem('isParentLoginAsChildShop');
    this.showParent = this.webshopDetail.ParentCustomerID === null || this.webshopDetail.IsOpenBook || this.webshopDetail.ParentCustomerID && (this.getByePassStatus || this.getByePassStatus === 'true') ? true : false;

  }



  productPriceCheckBoxUtilityAdd(value) {
    return {
      ...value,
      check: {
        MinimumQuantity: false,
        MaximumQuantity: false,
        Price: false,
        CustomerFeePercentage: false,
        SupplierProfitPercentage: false,
        LinkNetworkCommissionPercentage: false
      }
    }
  }


  ngOnInit() {
    if (this.customerProduct) {
      this.selectedLogosList = this.customerProduct.CustomerProductFixedLogos;
      this.fixedLogoData.emit(this.selectedLogosList);
      this.getFixedLogoData();
    }

    this.popupList = {
      apiurl: 'all/LogoTypesWithPagination',
      key: 'LogoTypeID',
      label: 'Logo Type',
      autoComplete: {
        apiUrl: 'AutoCompleteLogotypes',
        searchKey: 'name'
      }
    }
    this.productPrice = this.productDetails.ProductPrices.map(this.productPriceCheckBoxUtilityAdd);

    this.formArray();
    this.getLogoType();

    this.prefillData()
    this.getValue();
  }

  prefillData() {

    this.defaultLogoType = this.productDetails.DefaultLogoTypeID;
    this.defaultLogotype.emit(this.defaultLogoType);
    setTimeout(() => this.getLogoType(), 100);
    this.priceForm.patchValue({
      currency: this.productDetails.CurrencyID,
      allowordersketch: this.productDetails.AllowOrderSketch,
      samplewithoutlogo: this.productDetails.AllowOrderSampleWithoutLogo,
      samplewithlogo: this.productDetails.AllowOrderSampleWithLogo,
      pricecurrency: this.productDetails.PrintPricesCurrencyID,
      sketchPrice: this.productDetails.SketchPrice,
      samplewithoutlogoprice: this.productDetails.SampleWithoutLogoPrice,
      samplewithlogoprice: this.productDetails.SampleWithLogoPrice,
      withoutLogoDeliveryDays: this.productDetails.SampleWithoutLogoDeliveryDays,
      withLogoDeliveryDays: this.productDetails.SampleWithLogoDeliveryDays,
    })
  }

  changeCheckBox(value, type) {
    if (type === 'samplewithlogo') {
      this.priceForm.patchValue({
        samplewithoutlogo: !value
      })
    }
    if (type === 'samplewithoutlogo') {
      this.priceForm.patchValue({
        samplewithlogo: !value
      })
    }
  }

  ngOnChanges() {
    if (this.loadApi) {
      this.productPrice = this.productDetails.ProductPrices;
      this.productPrice = this.productDetails.ProductPrices.map(this.productPriceCheckBoxUtilityAdd);
      this.getproductPriceEdit.emit(this.productPrice)
      this.formArray();
    }

    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.getCurrenyList();
      this.getLogoTypeAll();
    }

    // this.getValue();
  }

  resetDropDown() {
    this.getLogoType()
  }

  showCheckLabel() {
    this.showchecklabel = true
  }

  selectedPrintPrice(index, data) {
    this.currentPrintPrice = index;
    this.passdata = !this.passdata;
    this.selectData = data;
  }

  getValue() {
    if (this.printPriceForm !== undefined) {
      this.printPriceForm.valueChanges.subscribe(() => {
      })
      this.getproductPriceEdit.emit(this.productPrice)
    }

    // if (this.printPriceForm !== undefined) {
    this.priceForm.valueChanges.subscribe(() => {
      this.priceFormData.emit(this.priceForm.value)
    })
    this.priceFormData.emit(this.priceForm.value)
    // }
  }

  sendValue() {
    this.productDetails.CurrencyID = this.priceForm.value.currency;
    this.productDetails.AllowOrderSketch = this.priceForm.value.allowordersketch;
    this.productDetails.AllowOrderSampleWithoutLogo = this.priceForm.value.samplewithoutlogo;
    this.productDetails.AllowOrderSampleWithLogo = this.priceForm.value.samplewithlogo;
    this.productDetails.PrintPricesCurrencyID = this.priceForm.value.pricecurrency;
    this.productDetails.SketchPrice = this.priceForm.value.sketchPrice && (this.priceForm.value.sketchPrice.toString()).includes(',') ? this.priceForm.value.sketchPrice.replace(',', '.') : this.priceForm.value.sketchPrice;
    this.productDetails.SampleWithoutLogoPrice = this.priceForm.value.samplewithoutlogoprice && (this.priceForm.value.samplewithoutlogoprice.toString()).includes(',') ? this.priceForm.value.samplewithoutlogoprice.replace(',', '.') : this.priceForm.value.samplewithoutlogoprice;
    this.productDetails.SampleWithLogoPrice = this.priceForm.value.samplewithlogoprice && (this.priceForm.value.samplewithlogoprice.toString()).includes(',') ? this.priceForm.value.samplewithlogoprice.replace(',', '.') : this.priceForm.value.samplewithlogoprice;
    this.productDetails.SampleWithoutLogoDeliveryDays = this.priceForm.value.withoutLogoDeliveryDays;
    this.productDetails.SampleWithLogoDeliveryDays = this.priceForm.value.withLogoDeliveryDays;

    if (!this.priceForm.value.allowordersketch) {
      this.priceForm.patchValue({
        sketchPrice: ''
      });
      this.productDetails.AllowOrderSketch = this.priceForm.value.allowordersketch;
      this.productDetails.SketchPrice = '';
    }
    this.priceFormData.emit(this.priceForm.value)
  }


  resetLogoType(event) {
    this.resetLogoTypeModal = true;
    setTimeout(() => {
      this.resetLogoTypeModal = false;
    }, 1000)
  }


  resetPrintPrice(event) {
    this.resetPrintPriceModal = true;
    setTimeout(() => {
      this.resetPrintPriceModal = false;
    }, 1000)
  }

  // getInputValue(value, control, index){
  //   this.productPrice.forEach((element, index) => {
  //   })
  // }

  addClear(controlname, i, type, action) {
    if (action) {

      if (this.prevVal !== undefined && this.nextVal !== undefined && this.prevVal !== '' && this.nextVal !== '') {
        this.productPrice.forEach((element, index) => {
          if (index === i && type === 'parent') {
            if (action === 'add') {
              element[controlname] = Number(this.nextVal);
              this.printPriceForm.patchValue({
                [controlname + i]: Number(this.nextVal)
              })
            }
            else {
              element[controlname] = Number(this.prevVal);
              this.printPriceForm.patchValue({
                [controlname + i]: Number(this.prevVal)
              })
            }

          }
          else if (index === i && type === 'customerFee') {
            const custFee = element.ProductPriceCustomerFees.filter(data => data.CustomerID === this.customerData.CustomerID);

            if (custFee.length > 0) {
              if (action === 'add') {
                custFee[0][controlname] = Number(this.nextVal);
                this.printPriceForm.patchValue({
                  [controlname + i]: Number(this.nextVal)
                })
              }
              else {
                custFee[0][controlname] = Number(this.prevVal);
                this.printPriceForm.patchValue({
                  [controlname + i]: Number(this.prevVal)
                })
              }
            }
            else {
              // element.ProductPriceCustomerFees.push({
              //   "CustomerID": this.customerData.CustomerID,
              //   "CustomerFeePercentage": Number(this.printPriceForm.value[controlname + i]),
              //   "Action": "Insert"
              // })
            }
          }
          else if (index === i && type === 'supplierprofit') {
            const supplierFee = element.ProductPriceSupplierProfits.filter(data => data.CustomerID === this.customerData.CustomerID);
            if (supplierFee.length > 0) {
              // supplierFee[0][controlname] = Number(this.printPriceForm.value[controlname + i]);
              if (action === 'add') {
                supplierFee[0][controlname] = Number(this.nextVal);
                this.printPriceForm.patchValue({
                  [controlname + i]: Number(this.nextVal)
                })
              }
              else {
                supplierFee[0][controlname] = Number(this.prevVal);
                this.printPriceForm.patchValue({
                  [controlname + i]: Number(this.prevVal)
                })
              }
            }
            else {
              // element.ProductPriceSupplierProfits.push({
              //   "CustomerID": this.customerData.CustomerID,
              //   "SupplierProfitPercentage": Number(this.printPriceForm.value[controlname + i]),
              //   "Action": "Insert"
              // })
            }
          }
          else if (index === i && type === 'linknetwork') {
            const linkNetworkFee = element.ProductPriceLinkNetworkCommissions.filter(data => data.CustomerID === this.customerData.CustomerID);
            if (linkNetworkFee.length > 0) {
              // linkNetworkFee[0][controlname] = Number(this.printPriceForm.value[controlname + i]);
              if (action === 'add') {
                linkNetworkFee[0][controlname] = Number(this.nextVal);
                this.printPriceForm.patchValue({
                  [controlname + i]: Number(this.nextVal)
                })
              }
              else {
                linkNetworkFee[0][controlname] = Number(this.prevVal);
                this.printPriceForm.patchValue({
                  [controlname + i]: Number(this.prevVal)
                })
              }
            }
            else {
            }
          }

        });
        this.prevVal = '';
        this.nextVal = '';
      }
    }

    this.toggleFocusCheck(controlname, i, false)
  }

  updateminqty(controlname, i) {
    if (this.productPrice[i + 1] !== undefined) {
      if (this.productPrice[i + 1]['Action'] !== 'Insert' && this.productPrice[i + 1]['Action'] !== 'Delete') {
        this.productPrice[i + 1]['Action'] = 'Update';
      }
      // if(!(this.productDetails.ProductPrices[i+1] !== undefined && this.productDetails.ProductPrices[i+1]['Action'] === 'Insert')){
      //   this.productDetails.ProductPrices[i+1]['Action'] = 'Update';
      // }

      if (this.productPrice[i + 1]['Action'] !== 'Delete' && Number(this.printPriceForm.value[controlname + i]) < 2147483647) {
        this.productPrice[i + 1]['MinimumQuantity'] = Number(this.printPriceForm.value[controlname + i]) + 1;
      }
      // this.productDetails.ProductPrices[i+1]['MinimumQuantity'] = Number(this.printPriceForm.value[controlname+i])+1;
    }
  }

  changeData(controlname, i, type, value?) {
    const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
    this.productPrice.forEach((element, index) => {
      if (element.Action !== 'Delete') {
        if (index === i && type === 'parent') {
          this.prevVal = element[controlname];
          // element[controlname] = Number(this.printPriceForm.value[controlname + i]);
          element[controlname] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
          this.nextVal = element[controlname];
          if (element['Action'] !== 'Insert') {
            element['Action'] = 'Update';
          }
        }
        else if (index === i && type === 'customerFee') {
          let custFee = [];
          if (this.webshopDetail.ParentCustomerID === null) {
            if (controlname === 'CustomerFeePercentage') {
              custFee = element.ProductPriceCustomerFees !== undefined ? element.ProductPriceCustomerFees.filter(data => data.CustomerID === this.webshopDetail.CustomerID) : [];
            }
            if (controlname === 'ChildCustomerFeePercentage') {
              custFee = element.ProductPriceCustomerFees !== undefined ? element.ProductPriceCustomerFees.filter(data => data.CustomerID === this.customerData.CustomerID) : [];
            }
          }
          else {
            custFee = element.ProductPriceCustomerFees !== undefined ? element.ProductPriceCustomerFees.filter(data => data.CustomerID === this.customerData.CustomerID) : [];
          }

          if (controlname === 'CustomerFeePercentage') {

            if (this.webshopDetail.ParentCustomerID === null) {
              if (element.ProductPriceCustomerFees !== undefined) {
                let getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === loginuserdetails.CustomerID);
                if (getIndex !== -1) {
                  element.ProductPriceCustomerFees[getIndex]['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                  element.ProductPriceCustomerFees[getIndex]['Action'] = element.ProductPriceCustomerFees[getIndex]['Action'] === 'Insert' ? 'Insert' : 'Update';
                }
              }
            }
          }
          else if (controlname === 'ChildCustomerFeePercentage') {

            let getIndex;
            if (element.ProductPriceCustomerFees !== undefined) {
              getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.productCustomerID)
            }

            if (this.webshopDetail.ParentCustomerID) {
              if (controlname === 'CustomerFeePercentage') {
                getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.webshopDetail.ParentCustomerID)
              }
              else if (controlname === 'ChildCustomerFeePercentage') {
                getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.productCustomerID)
              }
            }

            console.log(JSON.stringify(element.ProductPriceCustomerFees));
            console.log(getIndex);
            if (getIndex !== -1 && getIndex !== undefined) {
              // if (!this.getByePassStatus) {
              element.ProductPriceCustomerFees[getIndex]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              if (element.ProductPriceCustomerFees[getIndex]['Action'] !== 'Insert') {
                element.ProductPriceCustomerFees[getIndex]['Action'] = 'Update';
              }
              if (this.getByePassStatus && controlname === 'ChildCustomerFeePercentage') {
                element.ProductPriceCustomerFees[getIndex]['CustomerID'] = this.productCustomerID;
                if (element.ProductPriceCustomerFees[getIndex]['Action'] !== 'Insert') {
                  element.ProductPriceCustomerFees[getIndex]['Action'] = 'Update';
                }
              }
              if (this.getByePassStatus && (value.target.value === '' || value.target.value === null || value.target.value === undefined)) {
                element.ProductPriceCustomerFees[getIndex]['Action'] = "Delete";
              }
              // }

              if (this.getByePassStatus && (value.target.value === '' || value.target.value === null || value.target.value === undefined)) {
                element.ProductPriceCustomerFees[getIndex]['Action'] = 'Delete';
              }
            }
            else {
              if (element.ProductPriceCustomerFees !== undefined && element.ProductPriceCustomerFees.length > 0) {

                console.log(element.ProductPriceCustomerFees[i])
                console.log((i));
                if (element.ProductPriceCustomerFees[i] !== undefined) {
                  if (this.productCustomerID === element.ProductPriceCustomerFees[i]['CustomerID']) {
                    element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                    if (element.ProductPriceCustomerFees[i]['Action'] !== 'Insert') {
                      element.ProductPriceCustomerFees[i]['Action'] = 'Update';
                    }
                    if (this.getByePassStatus && (value.target.value === '' || value.target.value === null || value.target.value === undefined)) {
                      element.ProductPriceCustomerFees[i]['Action'] = 'Delete';
                    }
                    element.ProductPriceCustomerFees[i]['CustomerID'] = this.productCustomerID;
                  }
                }
              }
            }
          }

          if (custFee.length > 0) {
            // this.prevVal = custFee[0][controlname];
            if (!this.getByePassStatus) {
              custFee[0][controlname] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              this.nextVal = custFee[0][controlname];
              console.log(custFee);
              if (controlname === 'ChildCustomerFeePercentage') {
                custFee[0]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                this.nextVal = custFee[0]['ChildCustomerFeePercentage'];

              }

              if (custFee[0]['Action'] !== 'Insert') {
                custFee[0]['Action'] = 'Update';
              }
              if (value.target.value === '' || value.target.value === null || value.target.value === undefined) {
                custFee[0]['Action'] = 'Delete';
              }
            }


            if (this.webshopDetail.ParentCustomerID) {
              console.log(element.ProductPriceCustomerFees[i])
              // if (element.ProductPriceCustomerFees[i] !== undefined) {
              //   if (controlname === 'ChildCustomerFeePercentage') {
              //     element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              //     element.ProductPriceCustomerFees[i]['Action'] = 'Update';
              //   }
              //   if (controlname === 'CustomerFeePercentage') {
              //     element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              //     element.ProductPriceCustomerFees[i]['Action'] = 'Update';
              //   }
              // }


              let getIndex;
              if (element.ProductPriceCustomerFees !== undefined) {
                if (controlname === 'CustomerFeePercentage') {

                  getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.webshopDetail.ParentCustomerID)
                }
                else if (controlname === 'ChildCustomerFeePercentage') {
                  getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.productCustomerID)
                }
                if (getIndex !== -1) {

                  // if (controlname === 'ChildCustomerFeePercentage') {
                  // element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                  // }



                }
              }
              console.log('getIndex', getIndex)
              console.log('controlname', controlname)
              if (getIndex === -1 || getIndex === undefined) {
                let custObj = {
                  "Action": "Insert"
                }

                if (controlname === 'CustomerFeePercentage') {
                  console.log('pass1')
                  custObj['CustomerID'] = this.webshopDetail.ParentCustomerID,
                    custObj['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                }

                if (controlname === 'ChildCustomerFeePercentage') {
                  console.log('pass2')
                  custObj['CustomerID'] = this.productCustomerID,
                    custObj['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                }

                // if (controlname === 'ChildCustomerFeePercentage') {
                // element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                // }

                if (this.getByePassStatus && (value.target.value === '' || value.target.value === null || value.target.value === undefined)) {
                  custObj['Action'] = 'Delete';
                }
                console.log('custObj', custObj);
                if (element.ProductPriceCustomerFees !== undefined) {
                  element.ProductPriceCustomerFees.push(custObj)
                }
                else {
                  element.ProductPriceCustomerFees = [custObj];
                }
              }
              else {
                // if (this.productCustomerID === element.ProductPriceCustomerFees[i]['CustomerID'] && controlname === 'ChildCustomerFeePercentage') {
                //   element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                // }
                console.log(element.ProductPriceCustomerFees[i])
                if (controlname === 'CustomerFeePercentage') {
                  let getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.webshopDetail.ParentCustomerID);
                  if (getIndex !== -1) {
                    element.ProductPriceCustomerFees[getIndex]['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                    if (element.ProductPriceCustomerFees[getIndex]['Action'] !== 'Insert') {
                      element.ProductPriceCustomerFees[getIndex]['Action'] = "Update";
                    }
                    if (this.getByePassStatus && (value.target.value === '' || value.target.value === null || value.target.value === undefined)) {
                      element.ProductPriceCustomerFees[getIndex]['Action'] = "Delete";
                    }
                  }
                }
                if (controlname === 'ChildCustomerFeePercentage' && this.getByePassStatus) {
                  element.ProductPriceCustomerFees[getIndex]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                  if (element.ProductPriceCustomerFees[getIndex]['Action'] !== 'Insert') {
                    element.ProductPriceCustomerFees[getIndex]['Action'] = "Update";
                  }
                  if (this.getByePassStatus && (value.target.value === '' || value.target.value === null || value.target.value === undefined)) {
                    element.ProductPriceCustomerFees[getIndex]['Action'] = "Delete";
                  }
                }
                // else if (controlname === 'ChildCustomerFeePercentage') {
                //   let getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.productCustomerID);
                //   if (getIndex !== -1 && this.webshopDetail.ParentCustomerID) {
                //     element.ProductPriceCustomerFees[getIndex]['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                //     element.ProductPriceCustomerFees[getIndex]['Action'] = "Update";
                //   }
                // }

              }
              console.log('element.ProductPriceCustomerFees', element.ProductPriceCustomerFees);
              // if (this.webshopDetail.ParentCustomerID === element.ProductPriceCustomerFees[i]['CustomerID']) {
              //   element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              // }

            }
            else {

              let getIndex;
              if (controlname === 'CustomerFeePercentage') {
                console.log(element.ProductPriceCustomerFees)
                getIndex = element.ProductPriceCustomerFees.findIndex(custfee => custfee.CustomerID === this.webshopDetail.CustomerID);
                console.log(getIndex)
                if (getIndex === undefined || getIndex === -1) {
                  let custObj = {
                    "CustomerID": this.webshopDetail.CustomerID,
                    "Action": "Insert"
                  }
                  custObj['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
                  if (element.ProductPriceCustomerFees !== undefined) {
                    element.ProductPriceCustomerFees.push(custObj)
                  }
                  else {
                    element.ProductPriceCustomerFees = [custObj];
                  }
                }
              }
              console.log(element.ProductPriceCustomerFees);

            }

          }
          else {
            if (!this.getByePassStatus || this.getByePassStatus && this.webshopDetail.ParentCustomerID && controlname === 'CustomerFeePercentage') {
              let custObj = {
                "CustomerID": this.customerData.CustomerID,
                "Action": "Insert"
              }

              if (this.webshopDetail.ParentCustomerID) {
                custObj.CustomerID = controlname === 'CustomerFeePercentage' ? this.webshopDetail.ParentCustomerID : this.customerData.CustomerID;
                if (this.getByePassStatus && controlname === 'ChildCustomerFeePercentage') {
                  custObj.CustomerID = this.productCustomerID;
                }
              }
              else {
                custObj.CustomerID = controlname === 'CustomerFeePercentage' ? this.webshopDetail.CustomerID : this.customerData.CustomerID;
              }

              if (controlname === 'CustomerFeePercentage') {
                custObj['CustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              }
              if (controlname === 'ChildCustomerFeePercentage') {
                custObj['ChildCustomerFeePercentage'] = value.target.value.includes(',') ? value.target.value.replace(',', '.') : value.target.value;
              }
              if (element.ProductPriceCustomerFees !== undefined) {
                element.ProductPriceCustomerFees.push(custObj)
              }
              else {
                element.ProductPriceCustomerFees = [custObj];
              }
            }


          }
        }
        else if (index === i && type === 'supplierprofit') {
          const supplierFee = element.ProductPriceSupplierProfits !== undefined ? element.ProductPriceSupplierProfits.filter(data => data.CustomerID === this.customerData.CustomerID) : [];
          if (supplierFee.length > 0) {
            this.prevVal = supplierFee[0][controlname];
            supplierFee[0][controlname] = Number(this.printPriceForm.value[controlname + i]);
            this.nextVal = supplierFee[0][controlname];
            if (supplierFee[0]['Action'] !== 'Insert') {
              supplierFee[0]['Action'] = 'Update';
            }
          }
          else {
            element.ProductPriceSupplierProfits = [{
              "CustomerID": this.customerData.CustomerID,
              "SupplierProfitPercentage": Number(this.printPriceForm.value[controlname + i]),
              "Action": "Insert"
            }]
          }
        }
        else if (index === i && type === 'linknetwork') {
          const linkNetworkFee = element.ProductPriceLinkNetworkCommissions !== undefined ? element.ProductPriceLinkNetworkCommissions.filter(data => data.CustomerID === this.customerData.CustomerID) : [];
          if (linkNetworkFee.length > 0) {
            this.prevVal = linkNetworkFee[0][controlname];
            linkNetworkFee[0][controlname] = Number(this.printPriceForm.value[controlname + i]);
            this.nextVal = linkNetworkFee[0][controlname];
            if (linkNetworkFee[0]['Action'] !== 'Insert') {
              linkNetworkFee[0]['Action'] = 'Update';
            }
          }
          else {
            element.ProductPriceLinkNetworkCommissions = [{
              "CustomerID": this.customerData.CustomerID,
              "LinkNetworkCommissionPercentage": Number(this.printPriceForm.value[controlname + i]),
              "Action": "Insert"
            }]
          }
        }
      }
    });

    console.log((this.productPrice));

    this.formArray(controlname);
    this.getproductPriceEdit.emit(this.productPrice);
  }

  formArray(controlname?) {
    const loginuserdetails = JSON.parse(sessionStorage.getItem('userdetails'));
    console.log(this.productPrice);
    this.productPrice.forEach(element => {
      if (element.ProductPriceCustomerFees !== undefined) {
        console.log(element)
        element.ProductPriceCustomerFees.forEach(fee => {
          if ((this.webshopDetail.ParentCustomerID === null && loginuserdetails.CustomerID !== this.productCustomerID || this.webshopDetail.ParentCustomerID && this.webshopDetail.ParentCustomerID !== this.productCustomerID) && fee.CustomerID === this.productCustomerID && (controlname === 'ChildCustomerFeePercentage' || controlname === undefined)) {
            if (!fee.ChildCustomerFeePercentage) {
              fee.ChildCustomerFeePercentage = controlname === undefined ? fee.CustomerFeePercentage : fee.ChildCustomerFeePercentage;
            }
            // delete fee.CustomerFeePercentage;
          }
        });
      }
    });

    let customerID;
    const getData = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {

      customerID = this.productCustomerID ? this.productCustomerID : getData.CustomerID;
      this.customerData = getData;
      this.customerData.CustomerID = customerID;
      // this.loginService.loginSuccess().subscribe(data => {
      //   if (data) {
      //     alert(data.CustomerID);
      //     customerID = data.CustomerID;
      //     console.log(customerID);
      //     this.customerData = data;
      //   }
      // });
    } else {
      if (sessionStorage.getItem('userdetails') !== null) {

        customerID = loginuserdetails.CustomerID;
        this.customerData = loginuserdetails;
      }
    }
    const printPriceArray = [];
    this.productPrice.forEach(element => {
      const customerFee = [];
      const supplierFee = [];
      const linkNetworkFee = [];
      let priceObject: any = {
        MinimumQuantity: element.MinimumQuantity,
        MaximumQuantity: element.MaximumQuantity,
        Price: element.Price
      };


      //Customer Fee
      if (element.ProductPriceCustomerFees !== undefined) {
        const custFeeLen = element.ProductPriceCustomerFees.length;
        for (let i = 0; i < custFeeLen; i++) {

          if (element.ProductPriceCustomerFees.length !== null) {
            if (getData.ParentCustomerID === null || getData.ParentCustomerID === undefined) {
              if (element.ProductPriceCustomerFees[i]['CustomerID'] === loginuserdetails.CustomerID) {

                priceObject.CustomerFeePercentage = element.ProductPriceCustomerFees[i]['CustomerFeePercentage'];
              }
              // if (element.ProductPriceCustomerFees[i]['CustomerID'] !== loginuserdetails.CustomerID) {
              //   priceObject.ChildCustomerFeePercentage = element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'];
              // }
              if (element.ProductPriceCustomerFees[i]['CustomerID'] === this.productCustomerID) {
                priceObject.ChildCustomerFeePercentage = element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'];
              }
            }
            else {
              if (!this.getByePassStatus) {
                if (element.ProductPriceCustomerFees[i]['CustomerID'] === loginuserdetails.CustomerID) {
                  priceObject.ChildCustomerFeePercentage = element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'];
                }
                if (element.ProductPriceCustomerFees[i]['CustomerID'] !== loginuserdetails.CustomerID) {
                  priceObject.CustomerFeePercentage = element.ProductPriceCustomerFees[i]['CustomerFeePercentage'];
                }
              }
              else {
                console.log('element.tes', element.ProductPriceCustomerFees)
                if (element.ProductPriceCustomerFees[i]['CustomerID'] === this.productCustomerID) {
                  priceObject.ChildCustomerFeePercentage = element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'];
                }
                const getIndex = element.ProductPriceCustomerFees.findIndex(cust => cust.CustomerID === this.webshopDetail.ParentCustomerID)
                if (getIndex !== -1) {
                  priceObject.CustomerFeePercentage = element.ProductPriceCustomerFees[getIndex]['CustomerFeePercentage'];
                }

              }

              const getIndex = element.ProductPriceCustomerFees.findIndex(res => res.CustomerID === this.webshopDetail.ParentCustomerID);
              if (getIndex !== -1) {
                console.log('pass1')
                priceObject.CustomerFeePercentage = element.ProductPriceCustomerFees[getIndex]['CustomerFeePercentage'];
              }
              else {

                if (this.getByePassStatus) {
                  if (this.webshopDetail.ParentCustomerID) {
                    if (this.productCustomerID === element.ProductPriceCustomerFees[i]['CustomerID'] || this.webshopDetail.ParentCustomerID === element.ProductPriceCustomerFees[i]['CustomerID']) {
                      priceObject.CustomerFeePercentage = '';
                      element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] = '';
                    }
                  }
                }

              }

              if (this.getByePassStatus && element.ProductPriceCustomerFees[i]['CustomerID'] === this.webshopDetail.ParentCustomerID) {
                priceObject.CustomerFeePercentage = element.ProductPriceCustomerFees[i]['CustomerFeePercentage'];
              }

              if (element.ProductPriceCustomerFees[i]['CustomerID'] === this.productCustomerID) {
                priceObject.ChildCustomerFeePercentage = element.ProductPriceCustomerFees[i]['ChildCustomerFeePercentage'];
              }
            }

            if (element.ProductPriceCustomerFees[i]['Action'] === 'Delete') {
              element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] = element.ProductPriceCustomerFees[i]['CustomerFeePercentage'] === 0 ? '' : element.ProductPriceCustomerFees[i]['CustomerFeePercentage'];
              priceObject.CustomerFeePercentage = priceObject.CustomerFeePercentage === 0 ? '' : priceObject.CustomerFeePercentage;
            }


          }
        }
      }
      else {
        element.ProductPriceCustomerFees = [];
      }

      //SupplierProfit
      if (element.ProductPriceSupplierProfits !== undefined) {
        const supplierLen = element.ProductPriceSupplierProfits.length;
        for (let j = 0; j < supplierLen; j++) {
          if (element.ProductPriceSupplierProfits !== null && element.ProductPriceSupplierProfits[j]['CustomerID'] === customerID) {
            priceObject.SupplierProfitPercentage = element.ProductPriceSupplierProfits[j]['SupplierProfitPercentage']
          }
        }
      }
      else {
        element.ProductPriceSupplierProfits = [];
      }

      if (element.ProductPriceLinkNetworkCommissions !== undefined) {
        const linkNetworkLen = element.ProductPriceLinkNetworkCommissions.length;
        for (let k = 0; k < linkNetworkLen; k++) {
          if (element.ProductPriceLinkNetworkCommissions !== null && element.ProductPriceLinkNetworkCommissions[k]['CustomerID'] === customerID) {
            priceObject.LinkNetworkCommissionPercentage = element.ProductPriceLinkNetworkCommissions[k]['LinkNetworkCommissionPercentage']
          }
        }
      }
      else {
        element.ProductPriceLinkNetworkCommissions = []
      }


      printPriceArray.push(priceObject)
    });
    console.log(this.printPriceArray);
    this.printPriceArray = printPriceArray;
    this.loadForm(controlname);

  }


  loadForm(controlname?) {
    let group: any = {};
    this.printPriceArray.forEach((element, index) => {
      group['MinimumQuantity' + index] = new FormControl(element.MinimumQuantity);
      group['MaximumQuantity' + index] = new FormControl(element.MaximumQuantity);
      group['Price' + index] = new FormControl(element.Price);
      group['printcost' + index] = new FormControl();
      group['CustomerFeePercentage' + index] = new FormControl(element.CustomerFeePercentage);
      group['ChildCustomerFeePercentage' + index] = new FormControl(element.ChildCustomerFeePercentage);
      group['SupplierProfitPercentage' + index] = new FormControl(element.SupplierProfitPercentage);
      group['LinkNetworkCommissionPercentage' + index] = new FormControl(element.LinkNetworkCommissionPercentage);
      group['pricetotalcost' + index] = new FormControl();
    });
    group.currency = new FormControl(this.productDetails.CurrencyID);
    this.printPriceForm = new FormGroup(group);


    let pricegroup: any = {};

    pricegroup.allowordersketch = new FormControl();
    pricegroup.samplewithoutlogo = new FormControl();
    pricegroup.samplewithlogo = new FormControl();
    pricegroup.currency = new FormControl();
    pricegroup.pricecurrency = new FormControl();
    pricegroup.sketchPrice = new FormControl();
    pricegroup.samplewithoutlogoprice = new FormControl();
    pricegroup.samplewithlogoprice = new FormControl();
    pricegroup.withoutLogoDeliveryDays = new FormControl();
    pricegroup.withLogoDeliveryDays = new FormControl();

    this.priceForm = new FormGroup(pricegroup)
    this.prefillData();
  }



  onOpen(event: any) {

  }




  formValueChange() {

  }

  getProductPricedata(data) {
    if (this.productPrice) {
      this.productPrice.forEach((element, index) => {
        if (index === this.currentPrintPrice) {
          element.ProductPrintPrices = data;
        }
      });
    }
    if (this.productDetails.ProductPrices) {
      this.productDetails.ProductPrices.forEach((element, index) => {
        if (index === this.currentPrintPrice) {
          element.ProductPrintPrices = data;
        }
      });
    }

    this.getproductPriceEdit.emit(this.productPrice)
  }

  addQuantity() {
    let quantityData: any = {
      "Action": "Insert",
      "isFixedPrice": false,
      "MinimumQuantity": this.minimumQuantity,
      "MaximumQuantity": this.maximumQuantity,
      "Price": this.enterPrice && this.enterPrice.includes(',') ? this.enterPrice.replace(',', '.') : this.enterPrice,
      "linknetworkcost": this.linknetworkcost,
      "supplierprofit": this.supplierprofits,
      "customerCost": this.customerCost && this.customerCost.includes(',') ? this.customerCost.replace(',', '.') : this.customerCost,
      "ProductPriceCustomerFees": [],
      "ProductPriceSupplierProfits": [{
        "CustomerID": this.customerData.CustomerID,
        "SupplierProfitPercentage": this.supplierprofits,
        "Action": "Insert"
      }],
      "ProductPriceLinkNetworkCommissions": [{
        "CustomerID": this.customerData.CustomerID,
        "LinkNetworkCommissionPercentage": this.linknetworkcost,
        "Action": "Insert"
      }],
      "check": {
        MinimumQuantity: false,
        MaximumQuantity: false,
        Price: false,
        CustomerFeePercentage: false,
        SupplierProfitPercentage: false,
        LinkNetworkCommissionPercentage: false
      }
    }

    if (this.webshopDetail.ParentCustomerID === null) {
      // if (this.productCustomerID === this.webshopDetail.CustomerID) {

      // }
      // quantityData.ProductPriceCustomerFees.push({
      //   "CustomerID": this.webshopDetail.CustomerID,
      //   "CustomerFeePercentage": this.customerCost && this.customerCost.includes(',') ? this.customerCost.replace(',', '.') : this.customerCost,
      //   "Action": "Insert"
      // })
      console.log(this.productCustomerID)
      console.log(this.webshopDetail.CustomerID)
      if (this.productCustomerID === this.webshopDetail.CustomerID) {
        let custobj = {
          "CustomerID": this.webshopDetail.CustomerID,
          "CustomerFeePercentage": this.customerCost && this.customerCost.includes(',') ? this.customerCost.replace(',', '.') : this.customerCost,
          "Action": "Insert"
        }
        console.log(quantityData.ProductPriceCustomerFees)
        if (quantityData.ProductPriceCustomerFees !== undefined) {
          if (quantityData.ProductPriceCustomerFees.length === 0 || quantityData.ProductPriceCustomerFees.length > 0) {
            quantityData.ProductPriceCustomerFees.push(custobj)
          }
          else {
            quantityData.ProductPriceCustomerFees = [custobj];
          }
        }
        else {
          quantityData.ProductPriceCustomerFees = [custobj];
        }

      }
      console.log(quantityData);

      // if (this.productCustomerID !== this.webshopDetail.CustomerID) {
      //   quantityData.ProductPriceCustomerFees.push({
      //     "CustomerID": this.productCustomerID,
      //     "CustomerFeePercentage": Number(this.childCustomerCost),
      //     "ChildCustomerFeePercentage": Number(this.childCustomerCost),
      //     "Action": "Insert"
      //   })
      // }

      if (this.productCustomerID !== this.webshopDetail.CustomerID) {
        if (this.childCustomerCost) {
          let obj = {
            "CustomerID": this.productCustomerID,
            "CustomerFeePercentage": Number(this.childCustomerCost),
            "ChildCustomerFeePercentage": Number(this.childCustomerCost),
            "Action": "Insert"
          };
          if (quantityData.ProductPriceCustomerFees !== undefined) {
            if (quantityData.ProductPriceCustomerFees.length === 0 || quantityData.ProductPriceCustomerFees > 0) {
              quantityData.ProductPriceCustomerFees.push(obj)
            }
            else {
              quantityData.ProductPriceCustomerFees = [obj]
            }
          }
          else {
            quantityData.ProductPriceCustomerFees = [obj]
          }

        }

        if (this.customerCost) {
          if (this.productCustomerID !== this.webshopDetail.CustomerID) {
            let custobj = {
              "CustomerID": this.webshopDetail.CustomerID,
              "CustomerFeePercentage": this.customerCost && this.customerCost.includes(',') ? this.customerCost.replace(',', '.') : this.customerCost,
              "Action": "Insert"
            }
            if (quantityData.ProductPriceCustomerFees !== undefined) {
              if (quantityData.ProductPriceCustomerFees.length === 0 || quantityData.ProductPriceCustomerFees.length > 0) {
                quantityData.ProductPriceCustomerFees.push(custobj)
              }
              else {
                quantityData.ProductPriceCustomerFees = [custobj];
              }
            }
            else {
              quantityData.ProductPriceCustomerFees = [custobj];
            }

          }

        }


      }

    }
    else {
      if (this.productCustomerID === this.webshopDetail.CustomerID) {
        if (this.childCustomerCost) {
          let obj = {
            "CustomerID": this.productCustomerID,
            "CustomerFeePercentage": Number(this.childCustomerCost),
            "ChildCustomerFeePercentage": Number(this.childCustomerCost),
            "Action": "Insert"
          };
          if (quantityData.ProductPriceCustomerFees !== undefined) {
            if (quantityData.ProductPriceCustomerFees.length === 0 || quantityData.ProductPriceCustomerFees.length > 0) {
              quantityData.ProductPriceCustomerFees.push(obj)
            }
            else {
              quantityData.ProductPriceCustomerFees = [obj]
            }
          }
          else {
            quantityData.ProductPriceCustomerFees = [obj]
          }

        }



      }
      if (this.customerCost) {

        let custobj = {
          "CustomerID": this.webshopDetail.ParentCustomerID,
          "CustomerFeePercentage": this.customerCost && this.customerCost.includes(',') ? this.customerCost.replace(',', '.') : this.customerCost,
          "Action": "Insert"
        }
        if (quantityData.ProductPriceCustomerFees !== undefined) {
          if (quantityData.ProductPriceCustomerFees.length === 0 || quantityData.ProductPriceCustomerFees.length > 0) {
            quantityData.ProductPriceCustomerFees.push(custobj)
          }
          else {
            quantityData.ProductPriceCustomerFees = [custobj];
          }
        }
        else {
          quantityData.ProductPriceCustomerFees = [custobj];
        }

      }



    }
    if (this.minimumQuantity && this.maximumQuantity && this.enterPrice) {
      let priceObj = {
        MinimumQuantity: this.minimumQuantity,
        MaximumQuantity: this.maximumQuantity,
        Price: this.enterPrice && this.enterPrice.includes(',') ? this.enterPrice.replace(',', '.') : this.enterPrice,
        CustomerFeePercentage: this.customerCost && this.customerCost.includes(',') ? this.customerCost.replace(',', '.') : this.customerCost,
        // SupplierProfitPercentage: this.supplierprofits,
        LinkNetworkCommissionPercentage: this.linknetworkcost,
      }
      if (this.webshopDetail.ParentCustomerID === null) {
        if (this.productCustomerID !== this.webshopDetail.CustomerID) {
          priceObj['ChildCustomerFeePercentage'] = this.childCustomerCost;
        }
      }
      else {
        if (this.productCustomerID === this.webshopDetail.CustomerID) {
          priceObj['ChildCustomerFeePercentage'] = this.childCustomerCost;
        }
      }
      this.printPriceArray.push(priceObj);
      this.productPrice.push(quantityData)
      this.loadForm();
      this.minimumQuantity = "";
      this.maximumQuantity = "";
      this.enterPrice = "";
      this.customerCost = '';
      this.supplierprofits = '';
      this.linknetworkcost = '';
      this.childCustomerCost = '';
      this.toast.success('Product price Added successfully');


      this.getproductPriceEdit.emit(this.productPrice)
    }
    else {
      this.toast.error('please fill the mandatory fields')
    }

    this.prefillData()
  }

  clearData(data) {
    this.printPriceForm.patchValue({
      [data]: ''
    })
  }

  getCurrenyList() {
    this.productService.loadProductCurrency();
    this.productService.getProductCurrency().subscribe((data) => {
      this.currencyList = [{ label: 'Select currency', value: '' }];
      if (data) {
        data.forEach(currency => {
          let currencyData = {
            label: currency.Name,
            value: currency.CurrencyID
          }
          this.currencyList.push(currencyData);
        })
      }
    })
  }

  deleteProductWithConfirmation() {

    if (this.productPrice[this.deleteData.index]['Action'] === undefined || this.productPrice[this.deleteData.index]['Action'] === "Update") {
      this.productPrice[this.deleteData.index]['Action'] = 'Delete';
    }
    else {
      this.productPrice.splice(this.deleteData.index, 1);
    }
    this.getproductPriceEdit.emit(this.productPrice)
  }


  deleteProduct(data, index) {
    this.deleteData = { data, index }
  }

  getLogoType() {
    // this.productService.loadLogoType();
    this.productHttpService.getLogoType().subscribe((data: any) => {
      if (data) {
        let logoTypeArray = []
        data.forEach(element => {
          if (element.LogoTypeID == this.productDetails.DefaultLogoTypeID) {
            this.defaultLogoDescription = element.Name;
          }
          logoTypeArray.push({ label: element.Name, value: element.LogoTypeID })
        });
        this.logoTypeList = logoTypeArray;
      }
    })
  }


  getLogoTypeAll() {
    this.productService.loadLogoTypeAll();
    this.productService.getLogoTypeAll().subscribe(data => {
      if (data) {
      }
    })
  }

  addValueControl(type, action, checkTab) {
    if (checkTab === 'firstTab') {

      if (action === 'clear') {
        this.minimumQuantity = this.oldvaluelist
      }
    }
    else if (checkTab === 'secondTab') {
      if (action === 'clear') {
        this.maximumQuantity = this.oldvaluelist
      }
    }
    else if (checkTab === 'thirdTab') {
      if (action === 'clear') {
        this.enterPrice = this.oldvaluelist
      }
    }
    else if (checkTab === 'fourthTab') {
      if (action === 'clear') {
        this.customerCost = this.oldvaluelist
      }
    }
    else if (checkTab === 'fivethTab') {
      if (action === 'clear') {
        this.supplierprofits = this.oldvaluelist
      }
    }
    else if (checkTab === 'sixTab') {
      if (action === 'clear') {
        this.linknetworkcost = this.oldvaluelist
      }
    }
  }

  priceFormBlur(name, index, toggle, event) {
    event.preventDefault()
    const buttonName = event.relatedTarget && event.relatedTarget.name
    if (!(buttonName === "priceFormAdd" || buttonName === "priceFormClear")) {
      this.toggleFocusCheck(name, index, toggle)
    }
  }



  toggleFocusCheck(name: any, index: any, toggle: Boolean) {
    this.productPrice[index].check[name] = toggle
  }

  toggleAddForm(name: any, toggle: Boolean, event?: any) {
    const buttonName = event && event.relatedTarget && event.relatedTarget.name
    if (!(buttonName === "addPriceFormButton")) {
      this.addFormCheck[name] = toggle
    }
  }

  setDefaultLogoType(logotype) {
    this.defaultLogotype.emit(logotype.value);
  }

  getSelectedDropdown(dropdown) {
    this.defaultLogotype.emit(dropdown.LogoTypeID);
    this.defaultLogoDescription = dropdown.Name;
  }

  resetPopupDropdown() {
    this.defaultLogotype.emit('');
    this.defaultLogoDescription = '';

  }

  getFixedLogoData() {
    this.selectedLogoType = '';
    this.selectedLogoArea = '';
    this.selectedLogos = '';
    this.selectedLogoPosition = '';
    this.customerProduct.ProductLogoPositions.forEach(element => {
      element.label = element.Description
      element.value = element.LogoPositionID
    });

    this.customerProduct.ProductLogoMaxAreas.forEach(element => {
      element.label = element.Description
      element.value = element.LogoMaxAreaID
    });

    this.customerProduct.ProductLogoTypes.forEach(element => {
      element.label = element.Name
      element.value = element.LogoTypeID
    });

    this.customerProduct.ProductPrintPrices.forEach(element => {
      let checkLogoPosition = element.LogoPositionDescription ? element.LogoPositionDescription + ' - ' : '';
      let checkLogoArea = element.LogoMaxAreaDescription ? ' - ' + element.LogoMaxAreaDescription : '';
      let checkLogoType = element.LogoTypeName ? element.LogoTypeName + ' - ' : '';
      let noOfColor = element.NumberOfColors ? element.NumberOfColors + ' colors' : '';
      element.label = checkLogoPosition + checkLogoType + noOfColor + checkLogoArea;
      element.value = element.LogoTypeID
    });
    this.fixedLogoPosition = this.customerProduct.ProductLogoPositions;
    this.fixedLogoMaxAreas = this.customerProduct.ProductLogoMaxAreas;
    this.fixedLogoTypes = this.customerProduct.ProductLogoTypes;
    this.optionsFixedLogos = this.customerProduct.ProductPrintPrices;
  }

  fixedLogoFilter(event, type) {
    if (type === 'logoposition') {
      this.selectedLogoPosition = event.value;
      if (this.customerProduct.ProductLogoPositions.length > 0) {
        if (this.selectedLogoArea === '' && this.selectedLogoType === '') {
          this.optionsFixedLogos = this.customerProduct.ProductPrintPrices;

          const getLogoData = this.optionsFixedLogos.filter(data => data.LogoPositionID === event.value || data.LogoPositionID === undefined);
          this.optionsFixedLogos = getLogoData;
        }
      }
    }
    else if (type === 'logotype') {
      this.selectedLogoType = event.value;
      if (this.customerProduct.ProductLogoTypes.length > 0) {
        if (this.selectedLogoPosition === '' && this.selectedLogoArea === '') {
          this.optionsFixedLogos = this.customerProduct.ProductPrintPrices;

          const getLogoData = this.optionsFixedLogos.filter(data => data.LogoTypeID === event.value || data.LogoTypeID === undefined);
          this.optionsFixedLogos = getLogoData;
        }
      }
    }
    else if (type === 'logoarea') {
      this.selectedLogoArea = event.value;
      if (this.customerProduct.ProductLogoMaxAreas.length > 0) {
        if (this.selectedLogoPosition === '' && this.selectedLogoType === '') {
          this.optionsFixedLogos = this.customerProduct.ProductPrintPrices;

          const getLogoData = this.optionsFixedLogos.filter(data => data.LogoMaxAreaID === event.value || data.LogoMaxAreaID === undefined);
          this.optionsFixedLogos = getLogoData;
        }
      }
    }
    else if (type === 'fixedLogo') {
      // this.selectedLogos = event.value;
      this.selectedLogosList.push(event);
      this.optionsFixedLogos = [];
      this.getFixedLogoData();
    }
    this.fixedLogoData.emit(this.selectedLogosList);
    if (this.optionsFixedLogos.length > 0) {
      const getLogoPosition = [];
      const getLogoType = [];
      const getLogoArea = [];
      this.optionsFixedLogos.forEach(element => {
        if (this.customerProduct.ProductLogoPositions.length > 0) {
          if (element.LogoPositionID !== undefined) {
            getLogoPosition.push({ label: element.LogoPositionDescription, value: element.LogoPositionID });
          }
        }
        if (this.customerProduct.ProductLogoMaxAreas.length > 0) {
          if (element.LogoMaxAreaID !== undefined) {
            getLogoArea.push({ label: element.LogoMaxAreaDescription, value: element.LogoMaxAreaID });
          }
        }
        if (this.customerProduct.ProductLogoTypes.length > 0) {
          if (element.LogoTypeID !== undefined) {
            getLogoType.push({ label: element.LogoTypeName, value: element.LogoTypeID });
          }
        }
      })
      this.fixedLogoPosition = this.uniqueData(getLogoPosition);
      this.fixedLogoMaxAreas = this.uniqueData(getLogoArea);
      this.fixedLogoTypes = this.uniqueData(getLogoType);
    }
    else {
      this.fixedLogoPosition = this.customerProduct.ProductLogoPositions;
      this.fixedLogoMaxAreas = this.customerProduct.ProductLogoMaxAreas;
      this.fixedLogoTypes = this.customerProduct.ProductLogoTypes;
      this.optionsFixedLogos = [];
    }
  }

  removeFixedLogo(data) {
    this.optionsFixedLogos = this.customerProduct.ProductPrintPrices;
    if (data === 'logoposition') {
      this.selectedLogoPosition = '';
    }
    else if (data === 'logotype') {
      this.selectedLogoType = '';
    }
    else if (data === 'logoarea') {
      this.selectedLogoArea = '';
    }

    if (this.selectedLogoType !== '') {
      const getData = this.optionsFixedLogos.filter(data => data.LogoTypeID === this.selectedLogoType);
      this.optionsFixedLogos = getData;
    }
    else if (this.selectedLogoArea !== '') {
      const getData = this.optionsFixedLogos.filter(data => data.LogoMaxAreaID === this.selectedLogoArea);
      this.optionsFixedLogos = getData;
    }
    else if (this.selectedLogoPosition === '') {
      const getData = this.optionsFixedLogos.filter(data => data.LogoPositionID === this.selectedLogoPosition);
      this.optionsFixedLogos = getData;
    }



    const getLogoPosition = [];
    const getLogoType = [];
    const getLogoArea = [];

    this.optionsFixedLogos.forEach(element => {
      getLogoPosition.push({ label: element.LogoPositionDescription, value: element.LogoPositionID });
      getLogoType.push({ label: element.LogoTypeName, value: element.LogoTypeID });
      getLogoArea.push({ label: element.LogoMaxAreaDescription, value: element.LogoMaxAreaID });
    })
    this.fixedLogoPosition = this.uniqueData(getLogoPosition);
    this.fixedLogoMaxAreas = this.uniqueData(getLogoArea);
    this.fixedLogoTypes = this.uniqueData(getLogoType);
    if (this.selectedLogoPosition === '' && this.selectedLogoType === '' && this.selectedLogoArea === '') {
      this.getFixedLogoData();
    }
  }

  uniqueData(data) {
    return data.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === data.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });
  }

  deleteFixedLogo(i) {
    this.deleteIndex = i;
  }

  confirmDelete() {
    this.selectedLogosList.splice(this.deleteIndex, 1);
    this.fixedlogodeleteModal.hide();
    this.fixedLogoData.emit(this.selectedLogosList);
  }

  openFixedLogoPopup() {
    this.selectedLogoPosition = '';
    this.selectedLogoType = '';
    this.selectedLogoArea = '';
    this.selectedLogos = '';
    this.getFixedLogoData();
    this.selectedfixedlogo.show();
  }

  allowDecimal(event) {

    let returnValue;
    returnValue = true;
    const charCode = event.charCode;
    if (Number(charCode) === 44) {
      setTimeout(() => {
        event.target.value = event.target.value.replace(',', '.')
      }, 100);
    }
    if (
      (charCode !== 44) &&      // “,” CHECK COMMA AND ONLY ONE.
      (charCode !== 46) &&      // “.” CHECK DOT AND ONLY ONE.
      (charCode < 48 || charCode > 57)) {
      return false;
    }
    return returnValue;
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
    // console.log(event.target.value);
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  blockSpecialChar(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}