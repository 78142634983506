
import { Component, OnInit, Output, Input, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../../state/services/product.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-manage-unit',
  templateUrl: './product-manage-unit.component.html',
  styleUrls: ['./product-manage-unit.component.scss']
})
export class ProductManageUnitComponent implements OnInit {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  productManageUnit: FormGroup;
  @Input('resetForm') resetForm: Boolean
  // parentColor: any;
  code: any;
  name: any;

  allColors: any = [];
  activeLanguage: any;
  showcolorname: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English'
  @Input('addProductForm') addProductForm: any;
  @Input() editProduct: any;
  @Output() showManageUnit = new EventEmitter<any>();
  ProductUnitID: any;
  enableDisable: Boolean = true;
  webshopDetail: any;
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public apiService: ApiService,
    public toast: ToastService,
    private productHttpService: ProductHttpService,
    public translate: TranslateService
  ) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      console.log('webshopDetailmanageunit....', this.webshopDetail)

    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;

      }
    }
    translate.setDefaultLang('en');

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.editProduct) {
      this.enableDisable = !this.editProduct.isWebshopProduct ? false : true;
    }
    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFormFilter()
    }
  }
  ngOnInit() {
    // this.productManageUnit.valueChanges.subscribe(() => {
    //   this.showManageUnit.emit(false);
    // })

    this.buildForm()
    this.getAllProductUnit();
  }
  buildForm() {
    let customerDetail = JSON.parse(sessionStorage.getItem('customerDetails'));
    let getlang = this.apiService.getWebshopLanguages(customerDetail.CustomerID);
    let translationFieldJson = [
      {
        formcontrolname: 'ProductUnitName',
        label: 'Name',
        PropertyName: "ProductUnitName",
      }
    ];

    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })
    let group: any = {
      language: new FormControl(1),
      items: this.formBuilder.array([])
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.productManageUnit = new FormGroup(group);
  }


  createItem(unitVal?): FormGroup {
    let customerDetail = JSON.parse(sessionStorage.getItem('customerDetails'));
    let getlang = this.apiService.getWebshopLanguages(customerDetail.CustomerID);
    this.translateFormItems = [];
    getlang.forEach(lang => {
      unitVal.Translations.forEach(item => {
        if (lang.Code == item.LanguageCode) {
          let formJson = {
            formcontrolname: 'ProductUnitName' + lang.Code,
            translationID: 'TranslationID' + lang.Code,
            objectID: 'ObjectID' + lang.Code,
            class: lang.Name,
            name: item.PropertyName,
            LanguageID: lang.LanguageID,
            LanguageCode: lang.Code,
            PropertyName: item.PropertyName,
            PropertyValue: item.PropertyValue,
            TranslationID: item.TranslationID,
            ObjectID: item.ObjectID
          }
          this.translateFormItems.push(formJson);
        }
      });
    })
    let group: any = {
      productUnitID: unitVal.ProductUnitID,
    }
    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })
    return this.formBuilder.group({ ...group });
  }


  getAllProductUnit() {
    this.items = this.productManageUnit.get('items') as FormArray;
    let dataResp = [];
    this.productHttpService.getAllProductUnits().subscribe((data: any) => {
      if (data && data !== null) {
        data.forEach(element => {
          this.items.push(this.createItem(element));
        });
      }
    });

    dataResp.forEach(element => {
      this.items.push(this.createItem(element));
    });
    // this.productService.loadAllProductUnits();
    // this.productService.getAllProductUnits().subscribe((data) => {
    //   if (data && data !== null) {
    //     console.log('!@#', data);
    //     data.forEach(element => {
    //       this.items.push(this.createItem(element));
    //     });
    //   }
    // });
    this.showManageUnit.emit(true);
  }

  resetFormFilter() {
    this.translateFormItems.forEach(item => {
      this.productManageUnit.controls[item.formcontrolname].setValue('');
    });
    // this.clearFormItems();
  }

  clearFormItems() {
    const control = <FormArray>this.productManageUnit.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }
  addUnit() {
    let translations = [];
    let isValueExists = false;
    this.translateFormItems.forEach(item => {
      const data = {
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectType: "ProductUnits",
        PropertyName: item.name,
        PropertyValue: this.productManageUnit.value[item.formcontrolname],
        Action: "Insert"
      }
      if (this.productManageUnit.value[item.formcontrolname] != '') {
        isValueExists = true;
      }
      translations.push(data);
    })
    let requestData = {
      Translations: translations
    }
    if (isValueExists) {
      this.productHttpService.addProductUnits(requestData).subscribe((data) => {
        if (data) {
          this.clearFormItems();
          this.translateFormItems.forEach(item => {
            this.productManageUnit.controls[item.formcontrolname].setValue('');
          });
          this.getAllProductUnit();
          this.toast.success('Product unit added Successfully');
        }
      },
        (error) => {
          let errMsg = '';
          for (var key in error.error.ModelState) {
            errMsg += error.error.ModelState[key] + ' ';
          }
          this.toast.error(errMsg);
        });
    } else {
      this.toast.error('Please provide name.');
    }

    // this.productService.addProductUnits(requestData);
    // this.productService.addProductUnitsSuccess().subscribe((data) => {
    //   if (data !== null) {
    //     console.log(data);
    //     this.translateFormItems.forEach(item => {
    //       this.productManageUnit.controls[item.formcontrolname].setValue('');
    //     });
    //     this.getAllProductUnit();
    //     this.toast.success('Product unit added Successfully');
    //   }
    // });
  }

  updateUnit(productUnit) {
    let translations = [];
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: productUnit.value[item.translationID],
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: productUnit.value[item.objectID],
        ObjectType: "productUnits",
        PropertyName: item.name,
        PropertyValue: productUnit.value[item.formcontrolname],
        Action: "Update",
      }
      translations.push(data);
    })

    const requestData = {
      ProductUnitID: productUnit.value.productUnitID,
      data: {
        Translations: translations
      }
    }

    this.productHttpService.updateProductUnits(requestData).subscribe((data: any) => {
      if (data !== null) {
        this.clearFormItems();
        this.getAllProductUnit();
        this.toast.success('Product unit updated Successfully');
      }
    });


    // this.productService.updateProductUnits(requestData);
    // this.productService.updateProductUnitsSuccess().subscribe((data) => {
    //   if (data !== null) {
    //     this.clearFormItems();
    //     console.log(data);
    //     this.getAllProductUnit();
    //     this.toast.success('Product unit updated Successfully');
    //   }
    //   return false;
    // });
  }

  deleteUnit(productUnit) {

    // productUnitList
    // ProductUnitID




    let isToBeDeleted = true




    if (this.addProductForm.value.unitType === productUnit.value.productUnitID) {
      isToBeDeleted = false
    }
    if (isToBeDeleted) {
      this.ProductUnitID = productUnit.value.productUnitID;
      this.deleteModal.show();
    } else {
      this.toast.error("This Option already Mapped. Cannot Be Deleted")
    }


  }
  getDeleteStatus(status) {
    if (status) {
      this.productHttpService.deleteProductUnit(this.ProductUnitID).subscribe((data) => {
        if (data !== null) {
          this.clearFormItems();
          this.getAllProductUnit();
          this.toast.success('Product unit delete successfully');
        }
      });
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }
  }
  getCurrentLang(lang) {
    debugger
    this.selectedLang = lang.label;
  }
}



