import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { RouterModule } from '@angular/router';
import { FilterTagModule } from 'src/app/common/common-filter/filter-tags/filter-tags.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ProductManageColorModule } from 'src/app/product/product-edit/product-colors/product-manage-colors/product-manage-colors.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomdirectiveModule } from 'src/app/product/product-edit/customdirective/customdirective.module';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';
import { ManageSupplierSettingsComponent } from './manage-supplier-settings.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CommonFormModule,
    ProductManageColorModule,
    ControlValidationModule,
    SharedModule,
    TranslateModule,
    NgSelectModule,
    CustomdirectiveModule,
    DropdownPopupModule,
    RouterModule,
    PageLoaderModule,
    CommonFilterModule,
    FilterTagModule
  ],
  declarations: [ManageSupplierSettingsComponent],
  exports: [ManageSupplierSettingsComponent]
})

export class ManageSupplierSettingsModule { }

