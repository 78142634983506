import { Component, OnInit, Output, EventEmitter, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import * as _ from 'lodash';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-manage-logo-positions',
  templateUrl: './product-manage-logo-positions.component.html',
  styleUrls: ['./product-manage-logo-positions.component.scss']
})
export class ProductManageLogoPositionsComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  productManageLogoPosition: FormGroup;
  // parentColor: any;
  code: any;
  name: any;
  optionsSelectParentSize: any = [{
    value: '',
    label: '',
  }];
  allColors: any = [];
  activeLanguage: any;
  showcolorname: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English'
  @Input('resetForm') resetForm: Boolean
  @Input() productDetails: any
  @Output() loadlogoPostionDropdown = new EventEmitter<any>();
  @Output() showManageLogoPosition = new EventEmitter<any>();
  @Input('productLogoPosition') productLogoPosition: any
  selectedName: any;
  deleteLogoPositions: any;
  subscribeMethod: boolean;
  deletePositions: any;
  englishTrue: boolean;
  selfLogoPosition: any = [];
  LogoPositionList: any;
  searchLogoPosition: string;
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage:any = 1;
  selectedperpage:any = 10;
  rowPerPage:any = [{label:10, value:10}, {label:20, value:20}, {label:30, value:30}, {label:40, value:40}];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText:any = '';
  selectedLogoPositions: any = {
    LogoPositionID: '', 
    SupplierID: ''
  };
  showLoader: Boolean = false
  selectedLangCode:string = 'en';
  webshopDetail: any;
  // englishFieldFlag: boolean;
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public productHttpService: ProductHttpService,
    public apiService: ApiService,
    public toast: ToastService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en'); 
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

  }

  ngOnInit() {
    this.buildForm()
    this.getAllProductLogoPosition();
    // this.getParentProductSizes();
  }



  ngOnChanges(changes: SimpleChanges) {
    
    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFilter()
      this.filterReset();
    }
  }

  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let translationFieldJson = [
      {
        formcontrolname: 'LogoPositionDescription',
        label: 'Name',
        PropertyName: "LogoPositionDescription",
      }
    ];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })
    let group: any = {
      language: new FormControl(1),
      items: this.formBuilder.array([])
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('', Validators.required);
    })
    this.productManageLogoPosition = new FormGroup(group);
  }


  createItem(logoPositionVal?): FormGroup {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    this.translateFormItems = [];
    getlang.forEach(lang => {
      logoPositionVal.Translations.forEach(item => {
        if (lang.Code == item.LanguageCode) {
          let formJson = {
            formcontrolname: 'LogoPositionDescription' + lang.Code,
            translationID: 'TranslationID' + lang.Code,
            objectID: 'ObjectID' + lang.Code,
            class: lang.Name,
            name: item.PropertyName,
            LanguageID: lang.LanguageID,
            LanguageCode: lang.Code,
            PropertyName: item.PropertyName,
            PropertyValue: item.PropertyValue,
            TranslationID: item.TranslationID,
            ObjectID: item.ObjectID
          }
          this.translateFormItems.push(formJson);
        }
      });
    })
    let group: any = {
      LogoPositionID: logoPositionVal.LogoPositionID,
    }
    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })
    return this.formBuilder.group({ ...group });
  }

  searchPosition() {
    // let getResult = [];
    // const getData = this.selfLogoPosition;
    // getData.filter((data, index) => {
    //   data.Translations.filter((ele) => {
    //     let value = (ele.PropertyValue).toLowerCase();
    //     let searchData = (this.searchLogoPosition).toLowerCase();
    //     if ((value).includes(searchData)) {
    //       getResult.push(data)
    //     }
    //   })
    // });
    // const filterResult = _.uniq(getResult, 'LogoPositionID');
    // this.clearFormItems();
    // filterResult.forEach(element => {
    //   this.items.push(this.createItem(element));
    // });
    // this.LogoPositionList = filterResult;
    this.setPageSizeOptions(this.selectedperpage);
  }

  resetFilter() {
    this.searchLogoPosition = '';
    this.clearFormItems();
    this.selfLogoPosition.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.LogoPositionList = this.selfLogoPosition;
  }


  getAllProductLogoPosition() {
    this.clearFormItems();
   
    if (!this.productManageLogoPosition) return true;
    this.items = this.productManageLogoPosition.get('items') as FormArray;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    // this.productService.loadProductLogoPosition(requestData);

    this.searchCriteria = {     
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      LogoPositionID: this.selectedLogoPositions.LogoPositionID,
      SupplierID: this.productDetails.SupplierID
    };
    this.productHttpService.getLogoPositionsPagination(this.searchCriteria).subscribe((data:any)=>{
      if (data && data !== null) {
        this.LogoPositionList = data.ItemsCollection;
        this.selfLogoPosition = data.ItemsCollection;
        data.ItemsCollection.forEach(element => {
          this.items.push(this.createItem(element));
        });

        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({length: getPageList}, (v, k) => k+1);
        const pageLisArray = []
        for(let i=1; i <= getPageList; i++){
          pageLisArray.push({label: i, value:i})
        }
        this.pageList = pageLisArray;
      }
    });
  }

  getParentProductSizes() {
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    // this.productService.loadEnProductSizes(requestData);
    this.productHttpService.getEnProductSizeList(requestData).subscribe((data: any) => {
      if (data !== null) {
        this.optionsSelectParentSize = [];
        data.forEach(element => {
          const sizeVal = {
            value: element.ProductSizeID,
            label: element.Name,
          };
          this.optionsSelectParentSize.push(sizeVal);
        });
      }
    });
  }

  clearFormItems() {
    if (this.productManageLogoPosition !== undefined) {
      const control = <FormArray>this.productManageLogoPosition.controls['items'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i)
      }
    }
  }

  addLogoPosition() {
    let translations = [];
    let enflag = true
    let flag = true;
    let englishFieldFlag = true;
    this.translateFormItems.forEach(item => {
      const data = {
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectType: "LogoPositions",
        PropertyName: item.name,
        PropertyValue: this.productManageLogoPosition.value[item.formcontrolname],
        Action: "Insert"
      }
      if (item.LanguageCode === 'en' && !this.productManageLogoPosition.value[item.formcontrolname]) {
        englishFieldFlag = false;
      }
      if (data.PropertyValue && flag) {
        flag = false
      }
      translations.push(data);
    })


    if (!flag) {
      if (!englishFieldFlag && !flag) {
        this.toast.error("The LogoPositionDescription field is required for English language")
        return null
      }
      let requestData = {
        SupplierID: this.productDetails.SupplierID,
        Translations: translations
      }
      // this.productService.addProductLogoPosition(requestData);
      // if(this.englishTrue){
      this.showLoader = true;
      this.productHttpService.addProductLogoPosition(requestData).subscribe((data) => {
        this.showLoader = false;
        if (data !== null) {
          this.clearFormItems();
          this.translateFormItems.forEach(item => {
            this.productManageLogoPosition.controls[item.formcontrolname].setValue('');
          });
          this.getAllProductLogoPosition();
          // this.getParentProductSizes()
          this.toast.success('Added Successfully');
          this.clearAddFormValues()
          this.loadParentDropDown()
        }
        else {
          this.toast.error('The request is invalid. The LogoPositionDescription field is required for English language.')
        }
      },
        err => {
          this.showLoader = false;
          if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
            if (err.error.ModelState.LogoPositions !== null && err.error.ModelState.LogoPositions !== undefined) {
              this.toast.error(err.error.ModelState.LogoPositions);
            }
          }
        });
    }
    else {
      this.toast.error('Please fill the name field')
    }
    // }
  }



  updateLogoPosition(productLogoPosition) {
    let translations = [];
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: productLogoPosition.value[item.translationID],
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: productLogoPosition.value[item.objectID],
        ObjectType: "ProductSizes",
        PropertyName: item.name,
        PropertyValue: productLogoPosition.value[item.formcontrolname],
        Action: "Update",
      }
      translations.push(data);
    })

    const requestData = {
      LogoPositionID: productLogoPosition.value.LogoPositionID,
      data: {
        SupplierID: this.productDetails.SupplierID,
        Translations: translations
      }
    }
    // this.productService.updateProductLogoPosition(requestData);
    // this.productService.updateProductLogoPositionSuccess().subscribe((data) => {
    this.showLoader = true;
    this.productHttpService.updateProductLogoPosition(requestData).subscribe((data) => {
      this.showLoader = false;
      if (data !== null) {
        this.clearFormItems();
        this.getAllProductLogoPosition();
        // this.getParentProductSizes();
        this.toast.success('Updated Successfully');
        this.clearAddFormValues()
        this.loadParentDropDown()
      }
    },
    err => {
      this.showLoader = false;
      if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
        if (err.error.ModelState.LogoPositions !== null && err.error.ModelState.LogoPositions !== undefined) {
          this.toast.error(err.error.ModelState.LogoPositions);
        }
      }
    });
  }


  deleteLogoPosition(productLogoPosition) {
    let isToBeDeleted = true
    if (this.productLogoPosition instanceof Array) {
      isToBeDeleted = !(this.productLogoPosition.some(position => {
        if (position.Action === "Delete") return false
        return position.LogoPositionID === productLogoPosition.value.LogoPositionID
      }))
    }
    if (isToBeDeleted) {
      this.deletePositions = productLogoPosition
      this.deleteModal.show();
    } else {
      this.toast.error("This Option already Mapped. Cannot Be Deleted")
    }
  }
  getDeleteStatus(status) {
    if (status) {





      const LogoPositionID = this.deletePositions.value.LogoPositionID;
      // this.productService.deleteProductLogoPosition(LogoPositionID);
      // this.productService.deleteProductLogoPositionSuccess().subscribe((data) => {
      this.showLoader = true;
      this.productHttpService.deleteProductLogoPosition(LogoPositionID).subscribe((data) => {
        this.showLoader = false;
        if (data !== null) {
          this.clearFormItems();
          this.getAllProductLogoPosition();
          // this.getParentProductSizes();
          this.toast.success('Deleted Successfully');
          this.clearAddFormValues()
          this.loadParentDropDown()
          this.deleteModal.hide();
        }
      },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.LogoPositions !== null && err.error.ModelState.LogoPositions !== undefined) {
            this.toast.error(err.error.ModelState.LogoPositions);
          }
        }
      });
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide()
    }

  }

  getCurrentLang(lang) { 
    // this.clearAddFormValues()
    this.selectedLang = lang.label;
    this.selectedLangCode = <any>this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID).find(language =>  language.LanguageID == lang.value).Code;
    if (this.searchText) this.filterReset();
  }

  closeModal() {
    this.showManageLogoPosition.emit(false);
  }

  checkManageLogo() {
    if (this.selectedName && this.selectedLang === "English") {
      return false
    }
    else {
      return true;
    }
  }
  loadParentDropDown() {
    this.loadlogoPostionDropdown.emit()
  }
  clearAddFormValues() {
    let group: any = {}
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = "";
    })
    this.productManageLogoPosition.patchValue({ ...group });
  }


  @ViewChild('auto', {static: false}) autoCompleter: MdbAutoCompleterComponent;

  textNoResults:string ='';

  onDisplayValue = (option) => {
    const selectedItem:any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Description'];
  }

  autoCompleteProductLogoPosition(data) {
    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      SupplierID: this.productDetails.SupplierID,
      translations: true,
      selectedLangCode: this.selectedLangCode
    }

    this.groupCollection = [];

    this.productHttpService.productLogoPositionsAutoComplete(request).subscribe((res:any)=>{
      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if(res){
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedLogoPositions = selected;   
    this.setPageSizeOptions(10); 
  }

  onOptionSelected(event) {
    this.selectedLogoPositions = event.text
  }

  filterReset() {
    this.searchText = '';
    this.selectedLogoPositions.LogoPositionID = '';    
    this.selectedLogoPositions.SupplierID = '';  
    this.textNoResults = '';
    this.groupCollection = [];     
    this.getAllProductLogoPosition();
  }

  pageChanged(page) {
    this.selectedpage = page;
    this.getAllProductLogoPosition();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.groupCollection = [];
    this.getAllProductLogoPosition();
  }
}
  