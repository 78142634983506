import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { UserHttpService } from '../state/services/user.http.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/common-services/api.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-budgetslist',
  templateUrl: './user-budgetslist.component.html',
  styleUrls: ['./user-budgetslist.component.scss']
})
export class UserBudgetslistComponent implements OnInit {
  header: any;
  userdatacount: any;
  userBudgetList: any = [];
  questions: any[];
  submitted = false;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  showUserEditpage = false;
  actions: any = ['edit', 'delete'];
  filterData: any = {};
  paginationData: any = {};
  collection = [];
  activePage: number = 1;
  rowactivePage: number = 1;
  enablepagination: boolean = true;
  resetStatus: boolean = false;
  commonRemoveFilter: any;
  tableName: any = ''
  userID: number;
  searchCriteria: any = {};
  noRecordFoundText;
  columns: ColumnSetting[] = [];
  companyName: any;
  tableEvent: any;
  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
    private userService: UserService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private userHttpService: UserHttpService, public toast: ToastService, private translate: TranslateService) {
    this.noRecordFoundText = this.translate.instant('USERBUDGETLIST.NO_USER_BUDGET_FOUND');

    this.companyName = history.state.data.companyName;
    this.columns = [
      {
        primaryKey: 'AmountTotalDecimal',
        header: this.translate.instant('USERBUDGETEDIT.AMOUNT_TOTAL')
      },
      {
        primaryKey: 'AmountLeftDecimal',
        header: this.translate.instant('USERBUDGETEDIT.AMOUNT_LEFT')
      },
      {
        primaryKey: 'ValidFrom',
        header: this.translate.instant('USERBUDGETEDIT.VALID_FROM'),
        format: 'date'
      },
      {
        primaryKey: 'ValidTo',
        header: this.translate.instant('USERBUDGETEDIT.VALID_TO'),
        format: 'date'
      },
      {
        primaryKey: 'isEnabled',
        header: this.translate.instant('USERBUDGETEDIT.IS_ENABLED')
      },
      {
        primaryKey: 'action',
        header: this.translate.instant('USERBUDGETLIST.ACTION'),
        format: 'action',
      }
    ];
    this.userID = this.activatedRoute.snapshot.params ? this.activatedRoute.snapshot.params.userID : null;
  }

  ngOnInit() {
    this.getUserbudgetList();
  }

  addNewUserBudget() {
    let data: any = []
    data.companyName = this.companyName;
    this.router.navigate(['/admin-userbudget-add/' + this.userID], { state: { data }, replaceUrl: false });
  }
  UserBudgetlistUrl() {
    this.router.navigate(['/admin-user-list'], { replaceUrl: false });
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(true);
  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
  }

  getUserbudgetList() {
    this.userHttpService.getUsersBudgetList(this.userID).subscribe((data: any) => {
      if (data) {
        this.userdatacount = data.length;
        this.userBudgetList = data;
        if (data.length === 0) {
          this.enablepagination = false;
        }
        this.userBudgetList.forEach(element => {
          element.AmountLeftDecimal = Number(element.AmountLeft).toFixed(2) + " " + element.CurrencyCode;
          element.AmountTotalDecimal = Number(element.AmountTotal).toFixed(2) + " " + element.CurrencyCode;
        });
      }
    });
  }

  editUser(event) { }
  deleteUser(event) { }
  refreshTable(event) { }
  paginationNum(event) { }
  editUserBudget(userData) {
    if (userData) {
      this.showUserEditpage = true;
      try {
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            data.companyName = this.companyName;
            data.isEditbudget = true;
            this.router.navigate(['/admin-userbudget-add/' + this.userID], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }

  }

  deleteUserBudget(event) {
    this.submitted = event;
    this.userHttpService.deleteUserBudget(event.UserBudgetID).subscribe((data: any) => {
      if (data !== null) {
        this.toast.success(this.translate.instant('USERBUDGETLIST.BUDGET_DELETE_MESSAGE'))
        setTimeout(() => {
          this.getUserbudgetList();
        }, 200);
      }
    });
  }
  
   ngOnDestroy() {
    if (this.tableEvent) {
      this.tableEvent.unsubscribe();
    }
  }
}
