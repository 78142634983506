import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductImagesComponent } from './product-images.component';
import { CustomdirectiveModule } from '../customdirective/customdirective.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    SharedModule,
    CustomdirectiveModule
  ],
  declarations: [ProductImagesComponent],
  exports: [ProductImagesComponent]
})
export class ProductImagesModule { }


