import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';
import { BehaviorSubject, Observable } from 'rxjs/Rx';


@Injectable()
export class UserHttpService {
    webshopDetail: any;
    roleCoode: string = '';
    balanceBudget: BehaviorSubject<any> = new BehaviorSubject(0);
    constructor(private apiService: ApiService, private loginService: LoginService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            //const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getUsersList(searchCriteria: any) {
        let path = "";
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop) {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
        const loginData = this.apiService.getLoginData();
        //const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
        //console.log(loginData)
        if (loginData) {
            this.webshopDetail.CustomerID = loginData.CustomerID;
            this.roleCoode = loginData.Roles[0].Code;
        }

        let includChildShopUsers = '&includChildShopUsers=true';
        /*if (searchCriteria.customerId === '') {
            includChildShopUsers = '&includChildShopUsers=true';
        }*/
        let request = {
            activePage: searchCriteria.activePage !== undefined ? searchCriteria.activePage : '',
            companyName: searchCriteria.companyName !== undefined ? searchCriteria.companyName : '',
            country: searchCriteria.country !== undefined ? searchCriteria.country : '',
            // tslint:disable-next-line:max-line-length
            customerId: (searchCriteria.customerId !== undefined && searchCriteria.customerId !== '') ? searchCriteria.customerId : this.webshopDetail.CustomerID,
            email: searchCriteria.email !== undefined ? searchCriteria.email : '',
            name: searchCriteria.name !== undefined ? searchCriteria.name : '',
            pageIndex: searchCriteria.pageIndex !== undefined ? searchCriteria.pageIndex : '',
            pendinguserlist: searchCriteria.pendinguserlist !== undefined ? searchCriteria.pendinguserlist : false,
            roleId: searchCriteria.roleId !== undefined ? searchCriteria.roleId : '',
            username: searchCriteria.username !== undefined ? searchCriteria.username : ''
        }
        if (loginData.Roles[0].RoleName !== 'Super Admin') {
            request.companyName = loginData.CompanyName;
        }
        // if(loginData.ISSaas === "Y"){
        //     request.SassCreatedByAdmin = loginData.UserID;
        // }
        if(loginData.ISSaas === "Y"){
            path = 'en/Sassusers/list/' + request.customerId + '?' +
            'pageIndex=' + request.activePage + '&pageSize=' + searchCriteria.pageIndex + '&roleID=' + request.roleId + '&name=' +
            request.name + '&username=' + request.username + '&email=' + request.email + '&companyName=' + request.companyName + '&country=' + request.country + '&roleCode=' + this.roleCoode + '&IsRequireUsersApproved=' + request.pendinguserlist + includChildShopUsers + '&SassCreatedByAdmin=' + loginData.UserID;
        }else{
            path = 'en/users/list/' + request.customerId + '?' +
            'pageIndex=' + request.activePage + '&pageSize=' + searchCriteria.pageIndex + '&roleID=' + request.roleId + '&name=' +
            request.name + '&username=' + request.username + '&email=' + request.email + '&companyName=' + request.companyName + '&country=' + request.country + '&roleCode=' + this.roleCoode + '&IsRequireUsersApproved=' + request.pendinguserlist + includChildShopUsers;
        }
        
        return this.apiService.get(path).map((res) => {
            return res;
        }, error => {
            return error;
        });
    }

    getAllCountries() {
        const path = 'en/countries';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomerCountries(customerId) {
        let IsUserCountries = true;
        if (Number(customerId) === 0) {
            IsUserCountries = false;
        }
        const path = 'en/countries?IsUserCountries=' + IsUserCountries + '&customerID=' + customerId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllRoles() {
        const path = 'en/roles?type=webshop&CustomerID=' + this.webshopDetail.CustomerID;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCustomers() {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            //const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        let path;
        if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
            this.loginService.loginSuccess().subscribe(data => {
                if (data) {
                    //    const parentCustomerID = data.CustomerID;
                    path = 'en/customers/list?ParentCustomerID=' + this.webshopDetail.CustomerID;
                }
            });
        } else {
            const parentCustomerID = this.webshopDetail.CustomerID;
            path = 'en/customers/list?ParentCustomerID=' + parentCustomerID;
        }

        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getParentCustomersList(customerId) {
        const path = 'en/customers/list?ParentCustomerID=' + customerId + '&returnParentCustomer=true&isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllLanguages(custID?) {
        let path;
        if (custID) {
            path = 'en/languages?CustomerID=' + custID + '&isOrderbyname=true';
        }
        else {
            path = 'en/languages?CustomerID=&isOrderbyname=true';
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getAllCurrencies() {
        const path = 'en/currencies?isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCurrenciesById(custID) {
        let path;
        if (custID) {
            path = 'en/currencies?CustomerID=' + custID + '&isOrderbyname=true';
        }
        else {
            path = 'en/currencies?CustomerID=&isOrderbyname=true';
        }
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteUser(data) {
        const path = 'en/users?id=' + data;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }


    addUser(data) {
        const path = 'en/users';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    addUserBudget(data) {
        const path = 'en/userbudgets';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    /**
    * Service to Check Postal Code for Selected Country
    */

    checkPostalCode(countryCode, postalCode) {
        // const userSettings = this.globalService.getUserSettings();
        const path = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    uploadFile(data, params, headers) {
        const path = 'en/uploadfile';
        return this.apiService.post(path, data).map((res) => {

            return res;
        });
    }
    updateUserBudgets(data) {
        const path = 'en/userbudgets/' + data.BudgetId;
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    updateUser(data) {
        const path = 'en/users/' + data.userId;
        return this.apiService.put(path, data.updateData).map((res) => {
            return res;
        });
    }

    createUserAdditionalDeliveryAddress(data) {
        const path = 'en/users/createuserdeliveryaddress';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateUserAdditionalDeliveryAddress(data) {
        const path = 'en/users/updateuserdeliveryaddress/' + data.UsersDeliveryAddressID;
        return this.apiService.put(path, data).map((res) => {
            return res;
        });
    }

    deleteUserAdditionalDeliveryAddress(deliveryAddressId) {
        const path = 'en/users/deleteuserdeliveryaddress/' + deliveryAddressId;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    validateUser(data) {
        const path = 'en/users?UserName=' + data.username + '&customerID=' + data.customerID + '&UserID=0';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getproductRoles(custId) {
        const path = 'en/productroles?customerID=' + custId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addProductRoles(request) {
        const path = 'en/productroles/user/' + request.roleid + '/' + request.userId;
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    getUserData(userid) {
        const path = 'en/users/' + userid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteProductRole(request) {
        const path = 'en/productroles/user/' + request.roleid + '/' + request.userId;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    getUsersBudgetList(userid) {
        const path = 'en/userbudgets/' + userid + '?history=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    deleteUserBudget(userBudgetID) {
        const path = 'en/userbudgets/' + userBudgetID;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }
    showAddressList(userid) {
        const path = 'en/users/' + userid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    newPassword(params) {
        const path = 'en/users/?autopass=' + params.autopass + '&username=' + params.username + '&newpass=' + params.newpass + '&confpass=' + params.confpass + '&CustomerID=' + params.CustomerID
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }
    getUserbudgetTransaction(userid) {
        const path = 'en/userbudgettransactions/' + userid;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    addUserbudgetTransaction(data) {
        const path = 'en/userbudgettransactions/';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    loaduploadFile(data) {
        const path = 'en/BulkCreation/BulkUserCreationV1';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getRewardPoints(CampaignId) {
        const path = 'en/Campaign/GetRewardPointsCampaign/' + CampaignId;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    updateRewards(data) {
        const path = 'en/Campaign/updatenRewardPoints';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getallSubScriptions() {
        const path = 'en/customer/GetallSubScriptions';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getSubcriptionValidityDetails(id) {
        const path = 'en/Users/GetSubcriptionValidityDetails?CustomerID=' + id;
        //const path = 'en/Users/GetSubcriptionValidityDetails?CustomerID=1236';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    upgradeSubscription(id, planid) {
        const path = 'en/Users/UpgradeSubscription?CustomerID=' + id + '&SubscriptionID=' + planid;
        //const path = 'en/Users/UpgradeSubscription?CustomerID=1236&SubscriptionID=3';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    downloadAllUser(){
        const path = 'en/GetUsersDownload/list/'+this.webshopDetail.CustomerID+'?includChildShopUsers=true';

        return this.apiService.get(path).map((res)=>{
            return res;
        })
    }

    setBudget(budget: any) {
        this.balanceBudget.next(budget)
    }

    getBudget(): Observable<any> {
        return this.balanceBudget.asObservable();
    }

    bulkUserCreation(data){
        const path = 'en/BulkCreation/BulkUserCreation91Spring';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    isSaasPayment(data){
        const path = 'en/BulkCreation/CampaignPaymentCreationSW';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    updateSaasPayment(data){
        const path = 'en/SassPaymentStatus';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    cancelPayment(data){
        const path = 'en/CancelUserupdate';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    updateSaasRewardPoints(data){
        const path = 'en/UpdateRewardPoints';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    EditSaasPayment(data){
        const path = 'en/BulkCreation/UpdateSaasPayment';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    validateSaas(data){
        const path = 'en/ValidateCampaigndetails';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    getSaasUsers(data) {
        const path = 'en/GetUserdetails'
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }
    

    bulkUserRwrdPt(data){
        const path = 'en/BulkCreation/SB91BulkRewardPoint';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    bulkUserswRwrdPt(data){
        const path = 'en/BulkCreation/SwBulkRewardPoint';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    bulkhrmUserswRwrdPt(data){
        const path = 'CRM247/UserUpload';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    createOrder(data){
        const path = 'en/Penna/PlaceOrder';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    webTinyUrl(){
        const path = 'en/ProductBrand/GetTinyUrl?longUrl=' + window.location.origin+window.location.pathname;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    availablePaymentTypes() {
        const path = 'en/orders/PaymentOption'
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    user91sbPayment(transid, payid){
        const path = 'en/ShopProgram/SB91UserPayment?TransId='+transid+'&PayType='+payid
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    userswPayment(transid, payid){
        const path = 'en/ShopProgram/SWUserPayment?TransId='+transid+'&PayType='+payid
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    update91sbPayment(params){
        const path = 'en/BulkCreation/UpdateSB91Payment';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    updateswPayment(params){
        const path = 'en/BulkCreation/UpdateSWPayment';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    get91sbPaymentList(data,UserId:number, CompanyName: any) {
        let path;
        if(CompanyName){
            path = 'en/BulkCreation/GetSB91UserList?PageIndex=' + data.pageIndex + '&PageSize=' + data.pageSize + '&CompanyName='+CompanyName+'&UserId=' + UserId;
        }else{
            path = 'en/BulkCreation/GetSB91UserList?PageIndex=' +  data.pageIndex + '&PageSize=' + data.pageSize+ '&UserId=' + UserId;
        }
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    getSwPaymentList(data,UserId:number, CompanyName: any) {
        let path;
        if(CompanyName){
            path = 'en/BulkCreation/GetSWUserList?PageIndex=' + data.pageIndex + '&PageSize=' + data.pageSize + '&CompanyName='+CompanyName+'&UserId=' + UserId;
        }else{
            path = 'en/BulkCreation/GetSWUserList?PageIndex=' +  data.pageIndex + '&PageSize=' + data.pageSize+ '&UserId=' + UserId;
        }
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    getPcPaymentList(data,UserId:number, CompanyName: any) {
        let path;
        if(CompanyName){
            path = 'en/PennaCement/GetPCOrderApproveList?PageIndex=' + data.pageIndex + '&PageSize=' + data.pageSize + '&CompanyName='+CompanyName+'&UserId=' + UserId;
        }else{
            path = 'en/PennaCement/GetPCOrderApproveList?PageIndex=' +  data.pageIndex + '&PageSize=' + data.pageSize;
        }
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    gethrmPaymentList(data,UserId:number, CompanyName: any) {
        let path;
        if(CompanyName){
            path = 'CRM247/GetCRM247UserList?PageIndex=' + data.pageIndex + '&PageSize=' + data.pageSize + '&CompanyName='+CompanyName+'&UserId=' + UserId;
        }else{
            path = 'CRM247/GetCRM247UserList?PageIndex=' +  data.pageIndex + '&PageSize=' + data.pageSize;
        }
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

    admin91sbAction(params){
        const path = 'en/BulkCreation/UpdateSB91AdminAction';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    adminswAction(params){
        const path = 'en/BulkCreation/UpdateSWAdminAction';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    adminpcAction(params){
        const path = 'en/PennaCement/UpdatePcAdminAction';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    adminhrmAction(params){
        const path = 'CRM247/UpdateCRM247AdminAction';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }

    getCustomerList(id:any){
        const path = `en/customers/list?ParentCustomerID=${id}&returnParentCustomer=true`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    saastransaction(id:any){
        const path = `en/GetSassInfoadmin?UserId=${id}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    postCustomerList(params){
        const path = 'en/AutoGeneratedEmails/UpdtCCEmail';
        return this.apiService.post(path, params).map((res) => {
            return res;
        });
    }
    getAutoGeneratedEmails(id:any){
        const path = `en/AutoGeneratedEmails/GetCCEmail?CustomerId=${id}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCurrency() {
        const path = 'en/currencies/GetCurrenciesMaster';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    countryy() {
        const path = 'en/countries/FetchCountry';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
}
