import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';

@Component({
  selector: 'link-admin-childshop-list',
  templateUrl: './childshop-list.component.html',
  styleUrls: ['./childshop-list.component.scss']
})
export class ChildshopListComponent implements OnInit {
  header: any;
  filterFields: any = [];
  userdatacount: any;
  userList: any = [];
  questions: any[];
  submitted = false;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  showUserEditpage = false;
  showUserBudgetlist = false;
  actions: any = ['view', 'edit', 'delete'];
  filterData: any = {};
  paginationData: any = {};
  collection = [];
  activePage: number = 1;
  rowactivePage: number = 1;
  enablepagination: boolean = true;
  resetStatus: boolean = false;
  commonRemoveFilter: any;
  userBudgetList: any = [];
  templateColumns: ColumnSetting[] = [];
  campaignColumns: ColumnSetting[] = [];

  optionsSelectCustomers: Array<any>;
  optionsSelectRoles: Array<any>;
  optionsSelectCountries: Array<any>;
  childShopSearchForm: FormGroup;
  searchCriteria: any = {};
  passFilterresult: any = [];
  tableName: string = 'childshoplist';
  showLoader: Boolean = false;
  templateLabel: String = "Template";
  campaignLabel: String = "Campaign";
  showTemplate: Boolean = true;
  showCampaign: Boolean = false;
  filterCampaignFields:any = [];
  campaigns=[{id:1,name:"Pongal"},{id:2,name:"Christmas"}];
  campaignId:String="";


  constructor(private formBuilder: FormBuilder, private toast: ToastService, private translate: TranslateService) {
    this.templateColumns = [
      {
        primaryKey: 'Product Id',
        header: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_ID')
      },
      {
        primaryKey: 'Product Name',
        header: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_NAME')
      },
      {
        primaryKey: 'Product Image',
        header: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_IMAGE'),
        format: 'icon'
      },
      {
        primaryKey: 'UR',
        header: this.translate.instant('CHILDSHOPTEMPLATE.UR_PERCENTAGE')
      },
      {
        primaryKey: 'UR Amount',
        header: this.translate.instant('CHILDSHOPTEMPLATE.UR_AMOUNT'),
        
      },
      {
        primaryKey: 'R Percentage',
        header: this.translate.instant('CHILDSHOPTEMPLATE.R_PERCENTAGE')
      },
      {
        primaryKey: 'R Amount',
        header: this.translate.instant('CHILDSHOPTEMPLATE.R_AMOUNT')
      },
      {
        primaryKey: 'Parental Least Price',
        header: this.translate.instant('CHILDSHOPTEMPLATE.PARENTAL LEAST PRICE')
      }
    ];

  }

  ngOnInit() {
    this.setFilterFields();
    this.createForms();
  }

  createForms() {
    this.childShopSearchForm = this.formBuilder.group({
      productId: ['', Validators.required],
      productName: [''],
    });
  }

  setFilterFields() {
    this.filterFields.push(
      {
        label: this.translate.instant('USERLIST.FILTER'),
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_ID'),
        type: 'text',
        class: 'col-md-12',
        required: false,
        key: 'productid',
        id: 'productid',
        filterkey: 'productid',
        selected: this.searchCriteria.productid
      },
      {
        label: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_NAME'),
        type: 'text',
        class: 'col-md-12',
        required: false,
        key: 'productname',
        id: 'productname',
        filterkey: 'productname',
        selected: this.searchCriteria.productname
      },
      {
        label: this.translate.instant('USERLIST.CLEAR'),
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width ',
        key: 'clear',
      },
      {
        label: this.translate.instant('USERLIST.SEARCH'),
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    )
    this.filterCampaignFields.push(
      {
        label: this.translate.instant('USERLIST.FILTER'),
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Campaign',
        type: 'select',
        class: 'col-md-12',
        key: 'supplier',
        data: this.campaigns,
        selected: 'name',
        required: false,
        filterkey: 'name'
      },
      {
        label: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_ID'),
        type: 'text',
        class: 'col-md-12',
        required: false,
        key: 'productid',
        id: 'productid',
        filterkey: 'productid',
        selected: this.searchCriteria.productid
      },
      {
        label: this.translate.instant('CHILDSHOPTEMPLATE.PRODUCT_NAME'),
        type: 'text',
        class: 'col-md-12',
        required: false,
        key: 'productname',
        id: 'productname',
        filterkey: 'productname',
        selected: this.searchCriteria.productname
      },
      {
        label: this.translate.instant('USERLIST.CLEAR'),
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width ',
        key: 'clear',
      },
      {
        label: this.translate.instant('USERLIST.SEARCH'),
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    )
    
  }

  getSubmit(event: any) {

  }

  getSelectData(event: any) {

  }

  getReset(event: any) {

  }

  getFilterResult(event: any) {

  }

  getRemovedFilter(event: any) {

  }

  showTemplateData() {
    this.showTemplate = true;
    this.showCampaign = false;
  }

  showCampaignData() {
    this.showTemplate = false;
    this.showCampaign = true;
  }

}
