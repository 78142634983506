import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-suborder-item',
  templateUrl: './suborder-item.component.html',
  styleUrls: ['./suborder-item.component.scss']
})
export class SuborderItemComponent {
  @Output() getOrderList = new EventEmitter<any>();
  @Input() summaryData: any;
  @Input() subOrderItem: any;
  @Input() lineId: any;
  @Input() currency = '';

  constructor() { }

  getOrderListData(event) {
    this.getOrderList.emit(event);
  }
}
