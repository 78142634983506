import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ModalModule, ButtonsModule, WavesModule } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderSearchComponent } from './order-search.component';
import { OrderAdvanceSearchModalComponent } from './order-advance-search-modal/order-advance-search-modal.component';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [OrderSearchComponent,
    OrderAdvanceSearchModalComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    WavesModule,
    SharedModule,
    ModalModule,
    CommonFilterModule
  ],
  entryComponents: [OrderAdvanceSearchModalComponent],
  providers: [TitleCasePipe],
  exports: [OrderSearchComponent]
})
export class OrderSearchModule { }
