import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebshopState, webshopReducers } from './webshop.reducer';

export interface State {
    webshopModule: WebshopState;
}

export const reducers = webshopReducers;

export const getWebshopState = createFeatureSelector<WebshopState>('webshopModule');

export const getWebshopListSuccess = createSelector(getWebshopState,
    (state: WebshopState) => state.webshops);

