import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-page-control',
  templateUrl: './page-control.component.html',
  styleUrls: ['./page-control.component.scss']
})
export class PageControlComponent implements OnInit {
  // @Input() tabledata: any[];
  pager: any = {};
  pagedItems: any[];
  constructor() { }

  ngOnInit() {
    //this.calculatePage(30, 1, 10);
   // this.setPage(2);
  }


  // calculatePage(totalItems, currentPage, pageSize) {
  //   let totalPages = Math.ceil(totalItems / pageSize);

  //   let startPage: number, endPage: number;

  //   if (totalPages <= 5) {
  //     startPage = 1;
  //     endPage = totalPages;
  //   } else {
  //     if (currentPage <= 3) {
  //       startPage = 1;
  //       endPage = 5;
  //     } else if (currentPage + 1 >= totalPages) {
  //       startPage = totalPages - 4;
  //       endPage = totalPages;
  //     } else {
  //       startPage = currentPage - 2;
  //       endPage = currentPage + 2;
  //     }
  //   }

  //   let startIndex = (currentPage - 1) * pageSize;
  //   let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  //   this.setPage(2);
  //   return {
  //     totalItems: totalItems,
  //     currentPage: currentPage,
  //     pageSize: pageSize,
  //     totalPages: totalPages,
  //     startPage: startPage,
  //     endPage: endPage,
  //     startIndex: startIndex,
  //     endIndex: endIndex,
  //   };

  // }

  // setPage(page: number) {
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.calculatePage(30, 1, 10);
  //   this.pagedItems = this.tabledata;
  //   console.log(this.pagedItems);
  // }

}
