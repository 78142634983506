import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import * as _ from 'lodash';
import { debug } from 'util';
import { SupplierHttpService } from 'src/app/supplier/state/services/supplier.http.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-manage-color',
  templateUrl: './product-manage-colors.component.html',
  styleUrls: ['./product-manage-colors.component.scss']
})
export class ProductManageColorComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  colorPickerChange: any;
  showLoader: boolean = false
  productManageColors: FormGroup;
  code: any;
  name: any;
  optionsSelectParentColor: any = [{
    value: '',
    label: '',
  }];
  allColors: any = [];
  color: any;
  activeLanguage: any;
  // showLoader: Boolean = false
  showcolorname: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English';
  toBeDeleted: any;
  // showLoader: Boolean = false;
  @Input('resetForm') resetForm: Boolean
  @Input('productColorForm') productColorForm: Boolean
  @Input() productDetails: any
  @Output() showManageColor = new EventEmitter<any>();
  @Output() loadColorDropdown = new EventEmitter<any>();
  subscribeMethod: boolean = false;
  toggle: boolean = false;
  toggleEdit: boolean = false;
  ColorNew: { colorHash: string; };
  // newColor: any = '<span style="color:red;" class="common">The Tortoise</span> &amp; the Hare &nbsp;';
  newColor: any = []
  isavailable: boolean;
  updateValues: any;
  newUpdateValue: any;
  trimValues: any;
  newTrimValues: any;
  setActivate: boolean;
  firstTrue: boolean;
  thirdTrue: boolean;
  secondTrue: boolean;
  hashHide: string;
  totalColorValue: any;
  newTotalColorValue: any;
  getColors: any = [];
  searchColorName: any;
  colorsList: any;
  hideForm: boolean;
  parentColor: any;
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  selectedProductColor: any = {
    ProductColorID: '',
    SupplierID: ''
  };
  productColorsList: any;
  selectedLangCode = 'en';
  selectedLanguageId: number = 1;
  supplierList: any = [];
  supplierEnableStatus: boolean = false;
  loginData: any;
  webshopDetail: any;
  customerlist: any = [];
  productCustID: any;
  searchSupplierList: any = [];
  isProductEdit: Boolean = false;
  searchedCustomer: any = '';
  searchedSupplier: any = '';
  showAll: Boolean = true;
  selfParentColor: any = [];
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public apiService: ApiService,
    public toast: ToastService,
    public translate: TranslateService,
    public productHttpService: ProductHttpService,
    private supplierHttpService: SupplierHttpService,
    private route: ActivatedRoute
  ) {
    translate.setDefaultLang('en');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    const getCustID = sessionStorage.getItem('productCustomerID');
    if (getCustID !== undefined && getCustID !== null && getCustID !== '') {
      this.productCustID = getCustID;
    }
    else {
      this.productCustID = this.webshopDetail.CustomerID;
    }

  }
  scrollTopManageColors() {
    const list = document.getElementById("managecolorList")
    list.scrollTo(0, 0)
  }
  ngOnInit() {
    this.searchedCustomer = this.webshopDetail.CustomerID;

    this.selectedSearchCustomer(this.searchedCustomer);
    this.isProductEdit = this.productDetails.ProductID ? true : false;
    let actAsSupplier = false;
    if (sessionStorage.getItem('ActasSupplier') !== null && sessionStorage.getItem('ActasSupplier') !== undefined &&
      sessionStorage.getItem('ActasSupplier') !== 'undefined') {
      actAsSupplier = true;
    }
    if (this.route.routeConfig.data['supplierEnableStatus'] !== undefined &&
      this.route.routeConfig.data['supplierEnableStatus'] !== null && actAsSupplier) {
      this.supplierEnableStatus = true;
    }
    this.buildForm();
    this.loadCustomer();
    this.getParentProductColors();
    if (this.productDetails && this.productDetails.ProductID) {
      this.getAllProductColors();
    }
    this.scrollTopManageColors();
    this.getSupplierList();
  }


  //  clearScrollTime = setInterval(this.scrollTopManageColors, 0)
  // ngAfterViewInit(){
  //   clearInterval(this.clearScrollTime)
  // }

  getCurrentLang(lang) {
    this.selectedLang = lang.label;
    this.selectedLangCode = <any>this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID).find(language => language.LanguageID == lang.value).Code;
    if (this.searchText) this.filterReset();
    // this.getParentProductColors();
    this.loadDropdown();
  }

  loadDropdown() {
    let optionsSelectParentColor = [];
    this.selfParentColor.forEach(element => {
      let getName = element.Translations.filter(name => name.LanguageCode === this.selectedLangCode);
      let colorVal = {
        value: element.ProductColorID,
        label: getName.length > 0 ? getName[0].PropertyValue : '',
        colorHash: '#' + element.ColorCode
      };
      optionsSelectParentColor.push(colorVal);
      this.isavailable = true
    });
    this.optionsSelectParentColor = optionsSelectParentColor;
  }


  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let translationFieldJson = [
      {
        formcontrolname: 'ProductColorName',
        label: 'Name',
        PropertyName: "ProductColorName",
      }
    ];

    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: 'ProductColorName' + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })

    let group: any = {};
    if (this.supplierEnableStatus) {
      group = {
        language: new FormControl(1),
        parentColor: new FormControl(null),
        colorCode: new FormControl(''),
        supplierId: new FormControl(''),
        selectCustomers: new FormControl(''),
        items: this.formBuilder.array([])
      };
    } else {
      group = {
        language: new FormControl(1),
        parentColor: new FormControl(null),
        colorCode: new FormControl(''),
        supplierId: new FormControl(''),
        selectCustomers: new FormControl(''),
        items: this.formBuilder.array([])
      };
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.productManageColors = new FormGroup(group);
  }

  loadParentDropDown() {
    this.loadColorDropdown.emit()
  }

  createItem(colorVal?): FormGroup {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    this.translateFormItems = [];

    getlang.forEach(lang => {

      let isFound = colorVal.Translations.find(data => data.LanguageCode == lang.Code);
      let formJson;
      if (isFound) {
        formJson = {
          formcontrolname: 'ProductColorName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: "ProductColorName",
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: "ProductColorName",
          PropertyValue: isFound.PropertyValue,
          TranslationID: isFound.TranslationID,
          ObjectID: isFound.ObjectID
        }
      } else {
        formJson = {
          formcontrolname: 'ProductColorName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: 'ProductColorName',
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: "ProductColorName",
          PropertyValue: '',
          TranslationID: '',
          ObjectID: ''
        }
      }
      this.translateFormItems.push(formJson);

      // colorVal.Translations.forEach(item => {
      //   // if (lang.Code == item.LanguageCode) {
      //   let formJson = {
      //     formcontrolname: 'ProductColorName' + lang.Code,
      //     translationID: 'TranslationID' + lang.Code,
      //     objectID: 'ObjectID' + lang.Code,
      //     class: lang.Name,
      //     name: item.PropertyName,
      //     LanguageID: lang.LanguageID,
      //     LanguageCode: lang.Code,
      //     PropertyName: item.PropertyName,
      //     PropertyValue: item.PropertyValue,
      //     TranslationID: item.TranslationID,
      //     ObjectID: item.ObjectID
      //   }
      // }
      // });
    });

    let group: any = {
      parentId: colorVal.ProductColorID,
      parentColor: colorVal.ParentColorID,
      colorCode: colorVal.ColorCode,
      supplierId: colorVal.SupplierID
    }
    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })

    return this.formBuilder.group({ ...group });
  }

  getAllProductColors() {
    this.clearFormItems();
    this.showLoader = true;
    this.items = this.productManageColors.get('items') as FormArray;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    if (!this.productDetails.ProductID) {
      if (!this.searchedSupplier) {
        this.searchedSupplier = this.searchSupplierList.length > 0 ? this.searchSupplierList[0]['value'] : '';
        if (!this.searchedSupplier) {
          return false;
        }
      }
    }
    this.searchCriteria = {
      translations: true,
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      ProductColorID: this.selectedProductColor.ProductColorID,
      SupplierID: this.productDetails.ProductID ? this.productDetails.SupplierID : this.searchedSupplier,
      CustomerID: this.productDetails.ProductID ? this.productCustID : this.searchedCustomer,
      showAll: this.showAll,
      langCode: this.selectedLangCode
    };
    // let subscription;
    //this.productService.loadAllProductColors(requestData);
    // const list = document.getElementById("managecolorList")
    // list.scrollTo(0 , 0)
    // if (!this.subscribeMethod) {
    // subscription = this.productService.getAllProductColorsList().subscribe((data) => {
    //   if (data !== null && data) {
    //     this.subscribeMethod = true;
    //     data.forEach(element => {
    //       this.items.push(this.createItem(element));
    //     });
    //   }
    // });
    this.productHttpService.getProductColorsPagination(this.searchCriteria).subscribe((data: any) => {
      if (data !== null && data) {
        this.colorsList = data.ItemsCollection;
        this.getColors = data.ItemsCollection;
        this.subscribeMethod = true;
        this.showLoader = false;

        data.ItemsCollection.forEach(element => {
          this.items.push(this.createItem(element));
        });
        setTimeout(this.scrollTopManageColors, 0)

        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      }
    });

    // }

    // if (this.subscribeMethod && subscription) {
    //   subscription.unsubscribe();
    // }
  }
  searchColor() {
    let getResult = [];
    let newResult = []
    let newResultName = []
    const getData = this.getColors;
    getData.filter((data, index) => {
      this.parentColor = (data.ParentColorName).toLowerCase()
      let searchData = (this.searchColorName).toLowerCase();
      if ((this.parentColor).includes(searchData)) {
        getResult.push(data)
        newResult.push(data)
      }
      data.Translations.filter((ele) => {
        let value = (ele.PropertyValue).toLowerCase();
        let searchData = (this.searchColorName).toLowerCase();
        if ((value).includes(searchData)) {
          getResult.push(data)
          newResultName.push(data)
        }
      })
    });
    getResult = newResult.concat(newResultName)

    const filterResult = _.uniq(getResult, 'ColorName');
    this.clearFormItems();
    filterResult.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.colorsList = filterResult;

    this.hideForm = true
    this.showLoader = true

    setInterval(() => {
      this.showLoader = false
      this.hideForm = false
    }, 500);
    setTimeout(this.scrollTopManageColors, 0)
  }

  resetFilter() {
    this.showLoader = true;

    this.searchColorName = '';
    this.clearFormItems();
    this.getColors.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.colorsList = this.getColors;
    this.hideForm = true
    setInterval(() => {
      this.showLoader = false
      this.hideForm = false
    }, 500);
    this.setDefaultLang();
    this.filterReset();
    setTimeout(this.scrollTopManageColors, 0)
  }

  setDefaultLang() {
    this.translate.setDefaultLang('en');
    this.selectedLangCode = 'en';
    this.selectedLang = 'English';
    this.selectedLanguageId = 1;
  }

  getParentProductColors() {
    this.showLoader = true;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    this.productService.loadParentProductColors(requestData);
    let apiCalled = false;
    // const list = document.getElementById("productColors")
    // list.scrollTo(0 , 0)
    this.productHttpService.getParentProductColorList(requestData).subscribe((data: any) => {
      if (data !== null && !apiCalled) {
        this.showLoader = false;
        apiCalled = true
        this.optionsSelectParentColor = [{ label: 'Select parent color', value: '' }];
        data.forEach(element => {
          this.ColorNew = {
            colorHash: '#' + element.ColorCode
          };
          // this.optionsSelectParentColor.push(colorVal);
        });
        // this.newColor: any = '<span style="color:red;" class="common">The Tortoise</span> &amp; the Hare &nbsp;';
        this.newColor = this.ColorNew.colorHash;
        this.selfParentColor = data;
        data.forEach(element => {
          let getName = element.Translations.filter(name => name.LanguageCode === this.selectedLangCode);
          let colorVal = {
            value: element.ProductColorID,
            label: getName[0].PropertyValue,
            colorHash: '#' + element.ColorCode
          };
          this.optionsSelectParentColor.push(colorVal);
          this.isavailable = true
        });
        setTimeout(this.scrollTopManageColors, 0)
      }
    });
  }


  clearFormItems() {
    if (this.productManageColors) {
      const control = <FormArray>this.productManageColors.controls['items'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i)
      }
    }
  }


  clearAddFormValues() {
    let group: any = {};
    if (this.supplierEnableStatus) {
      group = {
        language: "",
        parentColor: null,
        colorCode: "",
        supplierId: "",
        selectCustomers: ""
      };
    } else {
      group = {
        language: "",
        parentColor: null,
        colorCode: "",
        selectCustomers: this.productDetails.ProductID ? this.productCustID : this.webshopDetail.CustomerID,
        supplierId: this.productDetails.SupplierID
      };
      this.productManageColors.patchValue({
        language: "",
        parentColor: null,
        colorCode: "",
      })
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = "";
    })
    this.productManageColors.patchValue({ ...group });
  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFilter()
    }
  }

  getSupplierList(customerID?) {
    let custID = this.productDetails.ProductID ? this.productCustID : this.webshopDetail.CustomerID;
    if (customerID !== undefined) {
      custID = customerID;
    }
    this.supplierHttpService.SupplierList(custID).subscribe((data: any) => {
      if (data) {
        const responseList = [];
        data.ItemsCollection.forEach(element => {
          const langVal = {
            value: element.SupplierID,
            label: element.Name,
          };
          responseList.push(langVal);
        });
        this.supplierList = responseList;
        if (this.productDetails && this.productDetails.ProductID !== undefined && !this.supplierEnableStatus) {
          this.productManageColors.patchValue({
            supplierId: this.productDetails.SupplierID
          })
        }

      }
    });
  }

  addColor() {
    const { value } = this.productManageColors
    let parentColorLoad = false;
    let childColorLoad = false
    let translations = [];
    let flag = true;
    let englishFieldFlag = true;
    let toasted = false
    this.translateFormItems.forEach(item => {
      if (item.LanguageCode === 'en' && !value[item.formcontrolname]) {
        englishFieldFlag = false;
      }
      const data = {
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectType: "ProductColors",
        PropertyName: item.name,
        PropertyValue: value[item.formcontrolname],
        Action: "Insert"
      }
      if (!data.PropertyValue && flag) {
        flag = false
      }


      translations.push(data);
    })

    if (this.supplierEnableStatus) {
      if (value.supplierId === '') {
        this.toast.error("Please fill all the fields")
        return null;
      }
    }

    if (value.colorCode && value.parentColor && value.selectCustomers) {


      if (!englishFieldFlag && !flag) {
        this.toast.error("The ProductColorName field is required for English language")
        return null
      }
      const loginData = this.apiService.getLoginData();
      let requestData = {
        ColorCode: value.colorCode,
        SupplierID: value.supplierId,
        ParentColorID: value.parentColor,
        Translations: translations,
        // CustomerID: value.selectCustomers
      }

      // this.productService.addProductColor(requestData);

      // this.productService.addProductColorSuccess().subscribe((data) => {  
      try {
        this.showLoader = true
        this.productHttpService.addProductColor(requestData).subscribe((data) => {
          this.showLoader = false
          if (data !== null) {
            if (!toasted) {
              toasted = true
              this.toast.success('Colors Added successfully');
            }
            this.clearAddFormValues();
            this.loadParentDropDown();
            this.subscribeMethod = false;
            if (!parentColorLoad) {
              this.getParentProductColors();
              parentColorLoad = true
            }
            if (!childColorLoad) {
              this.getAllProductColors();
              childColorLoad = true
            }
          }
        },
          err => {
            this.showLoader = false;
            if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
              if (err.error.ModelState.ProductColor !== null && err.error.ModelState.ProductColor !== undefined) {
                this.toast.error(err.error.ModelState.ProductColor);
              }
            }
          });
      } catch (error) {
      }
    }
    else {
      this.showLoader = false
      this.toast.error("Please fill all the fields")
    }


  }


  updateColor(productColor) {
    this.updateValues = productColor
    this.newUpdateValue = this.updateValues.value.colorCode
    this.newTrimValues = this.newUpdateValue
    this.showLoader = true;
    let translations = [];
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: productColor.value[item.translationID],
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: productColor.value[item.objectID],
        ObjectType: "ProductColors",
        PropertyName: item.name,
        PropertyValue: productColor.value[item.formcontrolname],
        Action: "Update",
      }
      translations.push(data);
    })
    // var re = '#'; 
    // var str = this.newTrimValues;
    // var newstr = str.replace(re, "");  
    this.hashHide = '#';
    this.totalColorValue = this.newTrimValues
    this.newTotalColorValue = this.totalColorValue.replace(this.hashHide, "")

    const requestData = {
      parentId: productColor.value.parentId,
      data: {
        ColorCode: this.newTotalColorValue,
        SupplierID: (this.supplierEnableStatus) ? productColor.value.supplierId : this.productDetails.SupplierID,
        Translations: translations,
        ParentColorID: productColor.value.parentColor
      }
    }


    // this.productService.updateProductColor(requestData);
    this.productHttpService.updateProductColor(requestData).subscribe((data) => {
      if (data !== null) {
        this.setActivate = true
        this.toast.success('Updated Successfully');
        // this.clearFormItems();
        this.getAllProductColors();
        this.getParentProductColors();
        this.loadParentDropDown();
        this.subscribeMethod = false;
        this.showLoader = false;
        const list = document.getElementById("managecolorList")
        list.scrollTo(0, 0)
        // document.body.scrollTop = 0;
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.ProductColor !== null && err.error.ModelState.ProductColor !== undefined) {
            this.toast.error(err.error.ModelState.ProductColor);
          }
        }
      });
  }

  deleteConfirmation(productColor) {
    let isToBeDeleted = true

    if (this.productColorForm instanceof Array) {
      isToBeDeleted = !(this.productColorForm.some(color => {
        return color.value.data.ProductColorID === productColor.value.parentId
      }))
    }
    if (isToBeDeleted) {
      this.deleteModal.show();
      this.toBeDeleted = productColor.value.parentId;
    } else {
      this.toast.error("This Option already Mapped. Cannot be Deleted")
    }
  }
  // scrollToTop(el) {
  //   alert(el.scrollTop)
  //   el.scrollTop = 0;          
  // }

  deleteColor() {
    const ProductColorID = this.toBeDeleted;
    // this.productService.deleteProductColor(ProductColorID);

    // this.productService.deleteProductColorSuccess().subscribe((data) => {
    //   if (data !== null && data) {
    //     this.loadList = true;
    //     this.toast.success('Deleted Successfully');
    //     this.clearFormItems();
    //     this.getAllProductColors();
    //     this.getParentProductColors();
    //     this.subscribeMethod = false;
    //   }
    // });

    this.productHttpService.deleteProductColor(ProductColorID).subscribe((data: any) => {
      if (data !== null && data) {

        this.deleteModal.hide();
        // this.clearFormItems();
        this.getAllProductColors();
        this.getParentProductColors();
        this.loadParentDropDown();
        // this.subscribeMethod = false;  
        this.toast.success('Deleted Successfully');
        const list = document.getElementById("managecolorList")
        list.scrollTo(0, 0)
      }
      if (data === false) {
        this.deleteModal.hide();
        this.toast.error('Delete was Unsuccessfully');
        const list = document.getElementById("managecolorList")
        list.scrollTo(0, 0)
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.ProductColor !== null && err.error.ModelState.ProductColor !== undefined) {
            this.toast.error(err.error.ModelState.ProductColor);
          }
        }
      });


  }

  onColorChange(val) {
    this.productManageColors.controls['colorCode'].setValue(val.substring(1).toUpperCase());
    // this.toggle = false
  }

  onColorChange1(val, item, i) {
    item.controls['colorCode'].setValue(val.substring(1).toUpperCase());
    // this.toggleEdit = !this.toggleEdit;

  }

  closeModal() {
    this.showManageColor.emit(false);
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }


  autoCompleteProductColors(data) {

    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      SupplierID: this.productDetails.ProductID ? this.productDetails.SupplierID : this.searchedSupplier,
      translations: true,
      selectedLangCode: this.selectedLangCode,
      customerID: this.productDetails.ProductID ? this.productCustID : this.searchedCustomer
    }
    this.productHttpService.popupAutocomplete(request).subscribe((res: any) => {

      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if (res) {
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedProductColor = selected;
    this.setPageSizeOptions(10);
  }

  onOptionSelected(event) {
    this.selectedProductColor = event.text
  }

  searchProductColor() {
    this.setPageSizeOptions(this.selectedperpage);
  }

  filterReset() {
    this.showAll = true;
    this.searchText = '';
    this.selectedProductColor.ProductColorID = '';
    this.selectedProductColor.SupplierID = '';
    this.textNoResults = '';
    this.searchedCustomer = this.webshopDetail.CustomerID;
    this.searchedSupplier = this.webshopDetail.SupplierID;
    this.groupCollection = [];
    this.selectedpage = 1;
    this.selectedperpage = 10;
    this.getAllProductColors();
  }

  pageChanged(page) {
    this.selectedpage = page;
    this.getAllProductColors();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.groupCollection = [];
    this.getAllProductColors();
  }

  setThree() { }

  setFirst() { }

  loadCustomer() {
    const loginData = this.apiService.getLoginData();
    let custID = this.productDetails.ProductID ? this.productCustID : this.webshopDetail.CustomerID;
    this.productHttpService.getChildChildCustomer(custID).subscribe((data: any) => {
      if (data) {
        const customerlist = [];
        data.forEach(cust => {
          let custData = {
            label: cust.Name,
            value: cust.CustomerID
          }
          customerlist.push(custData)
        })
        this.customerlist = customerlist;
        if (this.productDetails && !this.supplierEnableStatus) {
          this.productManageColors.patchValue({
            selectCustomers: Number(this.productCustID)
          })
        }
      }
    })
  }

  selectedCustomer(customer) {
    this.getSupplierList(customer.value);
  }

  selectedSearchCustomer(customerID, type?) {
    this.searchedCustomer = customerID;
    let custID = this.productDetails.ProductID ? this.productCustID : this.searchedCustomer;
    if (customerID !== undefined) {
      custID = customerID;
    }
    this.supplierHttpService.SupplierList(custID).subscribe((data: any) => {
      if (data) {
        const responseList = [];
        data.ItemsCollection.forEach(element => {
          const langVal = {
            value: element.SupplierID,
            label: element.Name,
          };
          responseList.push(langVal);
        });
        this.searchSupplierList = responseList;
        if (type === 'change') {
          this.searchedSupplier = '';
        }
        else {
          this.searchedSupplier = this.webshopDetail.SupplierID;
          this.getAllProductColors();
        }
        // if (this.productDetails && this.productDetails.ProductID !== undefined && !this.supplierEnableStatus) {
        //   this.productManageColors.patchValue({
        //     supplierId: this.productDetails.SupplierID
        //   })
        // }
      }
    });
  }

  selectedSearchSupplier(supplier) {
    this.searchedSupplier = supplier;
  }


}