import { BrandHttpService } from './state/services/brand.http.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BrandSearchModule } from './brand-search/brand-search.module';
import { BrandEditModule } from './brand-edit/brand-edit.module';
import { BrandListComponent } from './brand-list/brand-list.component';
import { SharedModule } from '../shared/shared.module';
import { BrandEffects } from './state/effects/brand.effects';
import { brandReducers } from './state/reducers/brand.reducer';
import { BrandService } from './state/services/brand.service';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { CommonFormModule } from '../common/common-form/common-form.module';
import { FilterTagModule } from '../common/common-filter/filter-tags/filter-tags.module';


@NgModule({
    imports: [
        BrandEditModule,
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        BrandSearchModule,
        CommonFilterModule,
        CommonFormModule,
        FilterTagModule,
        EffectsModule.forFeature([BrandEffects]),
        StoreModule.forFeature('brandModule', brandReducers),
    ],
    declarations: [BrandListComponent],
    providers: [BrandHttpService, BrandService],
    exports: [BrandListComponent, BrandEditModule]
})

export class BrandModule { }
