import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { BrandSearchComponent } from '../brand-search/brand-search.component';
import { BrandHttpService } from '../state/services/brand.http.service';
import { BrandService } from '../state/services/brand.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule
    ],
    declarations: [BrandSearchComponent],
    providers: [BrandHttpService, BrandService],
    exports: [BrandSearchComponent]
})

export class BrandSearchModule { }