import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { UserService } from '../../user/state/services/user.service';
import { Subject } from 'rxjs';
import { getSubDomainName } from 'src/app/login/state/reducers';
import { LoginState } from 'src/app/login/state/reducers/login.reducer';
import { LoginService } from 'src/app/login/state/services/login.service';


export interface ApiResponse {
    data?: any;
    status?: any;
}

@Injectable()
export class ApiService {
    updateCount = new Subject<any>();

    baseUrl = 'https://aeapi.rewardkartapi.in:8080/api/';
    baseUrl2 = 'https://aeapi.rewardkartapi.in:8080/api/';
    baseUrl3 = 'http://15.206.52.226:7000/api/';
    // baseUrl = 'http://localhost:2072/api/';
    baseLang = 'en';

    constructor(private httpClient: HttpClient, private store: Store<LoginState>, public loginService: LoginService, public userService: UserService) {
        let domain = (location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[1];
        let full = window.location.host;
        let part = full.split(".");
        let sub = part[0];
        var subDomain = part[1];
        if (domain === 'rewardkartadmin' && sub === "voucherkart" && subDomain !== "hyperkart") { 
            this.baseUrl = 'https://b2capi.voucherkart.co.in:8066/api/';
            this.baseUrl2 = 'https://rewardkartapi.in:8002/api/';
            this.baseUrl3 = 'https://rewardkartapi.in:8031/api/';
        }
        else if (sub === "voucherkart" && subDomain === "hyperkart") {
            this.baseUrl = 'https://ae.gift1to1.com:8066/api/';
            this.baseUrl2 = 'https://ae.gift1to1.com:8066/api/';
            this.baseUrl3 = 'https://ae.gift1to1.com:8066/api/';
            sessionStorage.setItem("Hyperkart",JSON.stringify(false));
        }
        else if (domain === 'rewardkartadmin') {
            //this.baseUrl = 'http://52.43.40.173:8080/api/';
            this.baseUrl = 'https://rewardkartapi.in:8060/api/';
            this.baseUrl2 = 'https://rewardkartapi.in:8002/api/';
            this.baseUrl3 = 'https://rewardkartapi.in:8031/api/';
        }
        else if (domain === 'uk-constient') {
            this.baseUrl = 'https://api.constient.us:8081/api/';
            this.baseUrl2 = 'https://api.constient.us:8081/api/';
        }
        else if (domain === "hyperkart") {
            this.baseUrl = 'https://aeapi.rewardkartapi.in:8080/api/';
            this.baseUrl2 = 'https://aeapi.rewardkartapi.in:8080/api/';
            sessionStorage.setItem("Hyperkart",JSON.stringify(true));
        }
        this.store.select(getSubDomainName).subscribe(subdomain => {
            //this.baseUrl = subdomain !== 'nil' && subdomain ? this.baseUrl.replace('https://', `https://${subdomain}-`) : this.baseUrl;
        });
    }

    get(path: string): Observable<ApiResponse> {
        return this.httpClient.get(this.baseUrl + path).map(this._toAPIResponse);
    }

    get2(path: string): Observable<ApiResponse> {
        return this.httpClient.get(this.baseUrl2 + path).map(this._toAPIResponse);
    }

    post(path: string, options?: any): Observable<ApiResponse> {
        return this.httpClient.post(this.baseUrl + path, options).map(this._toAPIResponse);
    }

    post2(path: string, options?: any): Observable<ApiResponse> {
        return this.httpClient.post(this.baseUrl2 + path, options).map(this._toAPIResponse);
    }

    post3(path: string, options?: any): Observable<ApiResponse> {
        return this.httpClient.post(this.baseUrl3 + path, options).map(this._toAPIResponse);
    }

    put(path: string, options?: any): Observable<ApiResponse> {
        return this.httpClient.put(this.baseUrl + path, options).map(this._toAPIResponse);
    }

    loopdelete(getdata: any): Observable<ApiResponse> {
        return this.httpClient.delete(this.baseUrl + getdata).map(this._toAPIResponse);
    }

    postFileUpload(path: string, formData?: any, params?: any, headers?: any): Observable<ApiResponse> {
        return this.httpClient.post(this.baseUrl + path, formData, { params, headers });
    }


    //this is the important step. You need to set content type as null
    // httpHeaders.set('Content-Type', null);
    // httpHeaders.set('Accept', "multipart/form-data");
    // let params = new HttpParams();
    // const formData: FormData = new FormData();
    // for (let i = 0; i < this.filesList.length; i++) {
    //     formData.append('fileArray', this.filesList[i], this.filesList[i].name);
    // }
    // formData.append('param1', this.param1);
    // formData.append('param2', this.param2);
    // this.http.post(this.ROOT_URL + this.SERVICE_ENDPOINT, formData, { params, headers }).subscribe((res) => {
    //     console.log(res);
    // });


    delete(path: string): Observable<ApiResponse> {
        return this.httpClient.delete(this.baseUrl + path).map(this._toAPIResponse);
    }

    deleteWithJson(path: string, request): Observable<ApiResponse> {
        return this.httpClient.delete(this.baseUrl + path, request).map(this._toAPIResponse);
    }

    _toAPIResponse(response: any) {
        return response;
    }

    getLoginData() {
        let loginData;
        this.loginService.loginSuccess().subscribe(data => {
            if (data !== null) {
                /** Direct Login Code */
                loginData = data;
                //console.log(loginData, "LoginData")
            } else {
                /** ByePass Login Code */
                // tslint:disable-next-line:max-line-length
                if (sessionStorage.getItem('isParentLoginAsChildShop') !== undefined && sessionStorage.getItem('isParentLoginAsChildShop') !== null) {
                    if (sessionStorage.getItem('userdetails') !== undefined && sessionStorage.getItem('userdetails') !== null) {
                        loginData = JSON.parse(sessionStorage.getItem('userdetails'));
                    }
                }
            }
        }, (error) => {
            //console.log(error);
        });
        return loginData;
    }
    getLoginCustomerData() {
        let loginCustomerData;
        this.loginService.getAllCustomers().subscribe(data => {
            loginCustomerData = data;
        });
        //console.log("get All Customer : ", loginCustomerData);
        return loginCustomerData;
    }
    getLanguages() {
        let getLanguage;
        this.loginService.getLanguage().subscribe(data => {
            if (data) {
                getLanguage = data;
            }
        });
        return getLanguage;
    }



    getWebshopLanguages(customerid) {
        let getLanguage = [{ "LanguageID": 1, "Name": "English", "Code": "en", "IsDefault": true, "isDeleted": false }];

        /* this.loginService.getwebshopLanguage(customerid).subscribe(data => {
             if (data) {
                 getLanguage = data;
             }
         });
 */
        // this.loginService.getLanguage().subscribe(data => {
        //     if (data) {
        //         getLanguage = data;
        //     }
        // });

        return getLanguage;


    }


}

