import { categoryReducers } from './state/reducers/category.reducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CategoryService } from './category-services/category.service';
import { CategoryEditModule } from './category-edit/category-edit.module';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryEffects } from './state/effects/category.effects';
import { CategoryHttpService } from './state/services/category.http.service';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';
import { SubCategoriesModule } from './subcategories/subcategories.module';
import { CategoryGridDetailsModule } from './category-griddetails/category-griddetails.module';
import { CategoryListDetailsModule } from './category-listdetails/category-listdetails.module';
import { CategoriesModule } from './categories/categories.module';
import { AngularSplitModule } from 'angular-split';
import { CategoryProductgridModule } from './category-productgrid/category-productgrid.module';
import { AdvanceSearchWebshopOnlineProductsModule } from './advance-search-webshop-online-products/advance-search-webshop-online-products.module';
import { CategoryProductlistModule } from './category-productlist/category-productlist.module';
import { ManageSupplierModule } from './manage-supplier/manage-supplier.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ColorPickerModule,
    CategoryEditModule,
    AdvancedSearchModule,
    AdvanceSearchWebshopOnlineProductsModule,
    SubCategoriesModule,
    CategoryGridDetailsModule,
    CategoryListDetailsModule,
    CategoriesModule,
    CategoryProductgridModule,
    EffectsModule.forFeature([CategoryEffects]),
    StoreModule.forFeature('categoryModule', categoryReducers),
    AngularSplitModule.forRoot(),
    CategoryProductlistModule,
    ManageSupplierModule
  ],
  declarations: [CategoryListComponent],
  providers: [CategoryHttpService, CategoryService],
  exports: [CategoryListComponent, CategoryEditModule]
})

export class CategoryModule { }