import { Component, OnInit, SimpleChanges, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CategoryService } from '../state/services/category.service';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-productlist',
  templateUrl: './category-productlist.component.html',
  styleUrls: ['./category-productlist.component.scss']
})
export class CategoryProductlistComponent implements OnInit {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('alertModal', { static: true }) alertModal: ModalDirective;
  @Input() advanceSearchData: any;
  @Input() webshopOnlineProductsSearchStatus: any;
  @Input() advanceSearchDataWebshopOnlineproducts: any;
  @Input() webshopOnlineProductsSelectStatus: any;
  @Input() searchStatus: any;
  @Input() formResetStatus: any;
  @Output() formResetStatusChange = new EventEmitter();
  selectedCategoryName: string;
  selectedCustomerID: any;
  selectedSubCategoryName: any;
  showLoader: boolean;
  categoryList: any
  categoryExists = false;
  selectedCategoryID: any;
  selectedSubCategoryID: any;
  subCategoryExists = false;
  productExists = false;
  subCategoryList: any = []; productList: any = []; selectedProductList: any = [];
  productListOnly: any;
  subSubCategory: any = [];
  productSelected = false;
  configpaginate: any = {};
  subSubCategoryExist: Boolean = false;
  selectedperpage: any = 20;
  pageSize: number = 0;
  activepage: number = 1;
  pageSizeOptions: number[] = [10, 30, 60, 90];
  pageList: any = [];
  rowPerPage: any = [{ label: 20, value: 20 }, { label: 40, value: 40 }, { label: 60, value: 60 }, { label: 80, value: 80 }];
  selectedpage: any = 1;
  totalCount: any;
  request: any = {};
  productTotalCount = 0;
  config: any;
  addCategoryIdReq: boolean = false;
  productsOnlyDelete: boolean;
  public selectIds = [];
  showMsg: any;
  getID: any;
  deletesendIdspro: any = [];
  selectAllCheck: boolean = false;
  showDelete: boolean;
  sendIds: any = [];
  deleteRelated: Boolean = false;
  getProduct: any = [];
  mainCategoryID: any;
  webshopDetail: any;
  constructor(public categoryServices: CategoryService, private categoryHttpService: CategoryHttpService,
    public categoryService: CategoryHttpService, public apiService: ApiService, public toast: ToastService,
    private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    // this.populateCategoryList()
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this happens when click "Search" button in Advanced Search of  Webshop Library Section
    this.webshopOnlineProductsSearchStatus = this.searchStatus ? true : false;
    this.webshopOnlineProductsSelectStatus = this.searchStatus ? false : true;

    if (this.webshopOnlineProductsSearchStatus) {
      this.categoryExists = false;
      this.subCategoryExists = false;
      this.activepage = 1;
      this.selectedperpage = 20;
      this.advanceSearchDataWebshopOnlineproducts.pageIndex = 1;
      this.advanceSearchDataWebshopOnlineproducts.pageSize = 20;
      this.populateCategoryList()
      this.advanceSearchCategoryList();
    }
    if (this.webshopOnlineProductsSelectStatus) {
      this.populateCategoryList();
      this.formResetStatusChange.emit(false);
    }
  }

  populateCategoryList() {
    this.getID = null;
    this.selectAllCheck = false;
    this.sendIds = [];
    this.deletesendIdspro = [];
    this.subCategoryList = [];
    // const loginData = this.apiService.getLoginData();
    this.selectedCustomerID = this.webshopDetail.CustomerID;
    this.selectedCategoryName = '';
    this.selectedSubCategoryName = '';
    this.showLoader = true;
    // this.categoryServices.loadCategorys(this.selectedCustomerID);
    this.categoryService.populateOnlineCategoryList(this.selectedCustomerID).subscribe(data => {
      if (data) {
        if (!this.webshopOnlineProductsSearchStatus) {
          this.productList = [];
          this.getProduct = [];
        }
        this.productTotalCount = 0;
        this.categoryList = data;
        this.categoryList.forEach(element => {
          element.status = false;
        });
        this.categoryExists = !this.webshopOnlineProductsSearchStatus ? true : false;
      } else {
        this.categoryExists = false;
      }
      this.showLoader = false
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
    // debugger
  }

  setSelectedCategory(id: any, name: any, data?) {
    this.getID = id;
    this.categoryExists = false; //Hide category list
    this.activepage = 1;
    this.selectedperpage = 20;
    if (this.configpaginate !== undefined) {
      this.configpaginate.itemsPerPage = 20;
    }
    this.selectedCategoryID = id;
    this.mainCategoryID = id;
    this.selectedCategoryName = name;
    this.webshopOnlineProductsSearchStatus = false;
    this.webshopOnlineProductsSelectStatus = true;
    this.resetSubCategoryDetailsValues();
    this.populateSubCategoryAndProductList(id);
    this.selectAllCheck = false;
    this.selectIds = [];
    this.sendIds = [];
    this.deletesendIdspro = [];
  }
  resetSubCategoryDetailsValues() {
    this.selectedSubCategoryID = null;
    this.selectedSubCategoryName = null;
    this.subCategoryList = []; this.productList = []; this.selectedProductList = [];
  }
  populateSubCategoryAndProductList(categoryID: any) {
    const category = this.categoryList.find(x => x.CategoryID === categoryID);
    if (category.SubCategories.length > 0) {
      this.subCategoryExists = true;
      this.subCategoryList = category.SubCategories;
      this.subCategoryList.forEach(element => {
        element.status = false;
      });
    } else {
      this.subCategoryExists = false;
    }
    if (category.ProductsMainCount > 0) {
      this.productExists = true;
      this.populateProductList(true);
    } else {
      this.productExists = false;
    }
    this.productExists = false;
  }
  populateProductList(isCategory, request?: any) {
    this.getSearchData(isCategory, request)

  }
  getSearchData(isCategory: boolean, request?) {
    this.showLoader = true
    let searchData: any;
    if (this.selectedCustomerID === 0) {
      searchData = {
        CurrencyID: 2, CustomerID: null, categoryId: this.selectedCategoryID, isAdmin: true, pageIndex: this.activepage, pageSize: this.configpaginate.itemsPerPage
      };
    } else {
      if (isCategory) {
        searchData = {
          CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedCategoryID, isAdmin: true, pageIndex: this.activepage, pageSize: this.configpaginate.itemsPerPage
        };
      } else {
        searchData = {
          CurrencyID: 2, CustomerID: this.selectedCustomerID, categoryId: this.selectedSubCategoryID, isAdmin: true, request: request, pageIndex: this.activepage, pageSize: this.configpaginate.itemsPerPage
        };
      }
    }
    this.categoryHttpService.searchData(searchData).subscribe((data: any) => {

      // /debugger
      if (data) {
        this.showLoader = false
        this.productExists = true;
        this.productList = data;
        this.getProduct = data.ItemsCollection;
        this.productList.ItemsCollection.forEach(element => {
          element.status = false;
        });
        this.productListOnly = this.productList.ItemsCollection
        let getPageList = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
        this.totalCount = data['TotalItemsCount'];
        if (!isCategory) {
          this.loadPaginate();
        }
      } else {
        this.productExists = false;
      }
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
    // return searchData;
  }
  setSelectedSubCategory(id: any, name: any, data?) {
    this.getID = id;
    this.subCategoryExists = false;
    this.selectedSubCategoryID = id;
    this.selectedSubCategoryName = name;
    this.selectedCategoryID = id;
    this.selectAllCheck = false;
    this.selectIds = [];
    this.sendIds = [];
    this.deletesendIdspro = [];
    this.activepage = 1;
    this.selectedperpage = 20;
    if (this.configpaginate !== undefined) {
      this.configpaginate.itemsPerPage = 20;
    }
    this.populateSubCategoryProductList(id);
    if (data.SubCategories.length > 0) {
      this.subSubCategoryExist = true;
      this.subSubCategory = data.SubCategories;
      this.populateSubSubCategoryProductList(id);
    }
    else {
    }
  }
  populateSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subCategoryList.find(x => x.CategoryID === subCategoryID);
    const subSubCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    // if(subCategory !== undefined){
    if ((subCategory !== undefined && subCategory.ProductsMainCount > 0) || (subSubCategory !== undefined && subSubCategory.ProductsMainCount > 0)) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
  }
  populateSubSubCategoryProductList(subCategoryID: any) {
    this.productSelected = null;
    const subCategory = this.subSubCategory.find(x => x.CategoryID === subCategoryID);
    if (subCategory.ProductsMainCount > 0) {
      this.populateProductList(false);
    } else {
      this.productExists = false;
    }
  }
  loadPaginate() {
    const productListLength = this.productList.TotalItemsCount;

    this.configpaginate = {
      itemsPerPage: this.productList.PageSize,
      currentPage: this.productList.PageIndex,
      totalItems: productListLength
    };
  }
  setProductSelected(selectedProduct: any) {
    const product = this.selectedProductList.find(x => x.ProductID === selectedProduct.ProductID);
    if (product === undefined) {
      this.selectedProductList.push(selectedProduct);
    } else {
      this.selectedProductList.splice(product);
    }
    this.productSelected = !this.productSelected;
  }
  pageChanged(event) {
    this.configpaginate.currentPage = Number(event);
    this.activepage = event;
    if (this.webshopOnlineProductsSelectStatus) {
      this.advanceSearchDataWebshopOnlineproducts = {
        pageIndex: event,
        pageSize: this.configpaginate.itemsPerPage,
        isAdmin: true,
        CurrencyID: 2,
        CustomerID: this.selectedCustomerID,
        CategoryID: this.selectedCategoryID,
      };
    }
    else {
      this.advanceSearchDataWebshopOnlineproducts.pageIndex = event;
    }
    this.advanceSearchCategoryList();
  }

  advanceSearchCategoryList() {
    if (this.webshopOnlineProductsSearchStatus) {
      this.selectedCategoryName = '';
      this.selectedSubCategoryName = '';
    }
    this.showLoader = true
    // this.categoryServices.loadCategorys(this.selectedCustomerID);
    this.categoryService.advacedSearchWebshopLibraryCategoryList(this.advanceSearchDataWebshopOnlineproducts).subscribe((data: any) => {
      if (data) {
        this.configpaginate = {
          itemsPerPage: Number(this.selectedperpage),
          currentPage: Number(this.activepage),
          totalItems: Number(data['TotalItemsCount'])
        };
        this.categoryExists = false;
        // this.subCategoryExists = false;
        this.productExists = true;
        this.productList = data;
        this.getProduct = data.ItemsCollection;
        this.productTotalCount = data['TotalItemsCount'];
        this.totalCount = data['TotalItemsCount'];

        let getPageList = data['TotalItemsCount'] / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      } else {
        this.categoryExists = true;
      }
      this.showLoader = false;
    }, error => {
      if (error.error.ModelState !== undefined && error.error.ModelState !== null) {
        let errorMessage = '';
        const objResponse = Object.values(error.error.ModelState);
        objResponse.forEach(errmessage => {
          errorMessage += errmessage;
        });
        this.toast.error(errorMessage);
      }
      if ((error.error.ModelState === undefined || error.error.ModelState === null)) {
        if (error.error.Message === undefined || error.error.Message === null) {
          this.toast.error(error.error);
        } else {
          this.toast.error(error.error.Message);
        }
      }
      this.showLoader = false;
    });
  }

  setPageSizeOptions(setPageSizeOptionsInput) {
    this.configpaginate.itemsPerPage = Number(setPageSizeOptionsInput);
    this.selectedperpage = this.configpaginate.itemsPerPage;
    this.configpaginate.currentPage = 1;
    this.activepage = 1;
    this.advanceSearchDataWebshopOnlineproducts.pageSize = setPageSizeOptionsInput;
    this.advanceSearchDataWebshopOnlineproducts.pageIndex = this.activepage;
    // this.perPageData.emit(setPageSizeOptionsInput.value);
    this.loadPaginate();
    if (this.webshopOnlineProductsSelectStatus) {
      this.advanceSearchDataWebshopOnlineproducts = {
        pageIndex: this.activepage,
        pageSize: setPageSizeOptionsInput,
        isAdmin: true,
        CurrencyID: 2,
        CustomerID: this.selectedCustomerID,
        CategoryID: this.selectedCategoryID,
      };
    }
    this.advanceSearchCategoryList();
  }

  onDroppedData(data: any) {
    // const loginData = this.apiService.getLoginData();
    let returnList: any = {
      customerID: this.webshopDetail.CustomerID
    }
    if (data.dropData !== undefined) {
      returnList.categoryID = data.dropData.ParentID ? data.dropData.ParentID : data.dropData.CategoryID;
    }
    let request = {
      "CopyBatchProducts": data.typeData.leftCopyRelatedProducts,
      "DestinationCustomerid": this.webshopDetail.CustomerID,
      "ExcludeCategories": data.typeData.excludeCategory,
      "IncludeProducts": data.typeData.withProducts,
      "IncludeSubcategories": data.typeData.withSubCategory,
      "SourceCustomerID": data.typeData.SourceCustomerID ? data.typeData.SourceCustomerID : null
    }
    if (data.dropData !== undefined) {
      request['DestinationCategoryID'] = data.dropData.CategoryID;
    }
    if (data.typeData.type === 'category') {
      request['Categories'] = [data.dragData.CategoryID];
    }
    else if (data.typeData.type === 'product') {
      request['Products'] = data.dragData.productIds;
    }

    // return false;
    this.showLoader = true;
    this.categoryHttpService.dragDrop(request).subscribe((res: any) => {
      if (res) {
        if (res.Status) {
          this.toast.success(res.Message);
          this.categoryHttpService.getSubCategory(returnList).subscribe((response: any) => {
            if (response) {
              // this.categoryList = response;
              const getCategoryIndex = this.categoryList.findIndex(get => get.CategoryID === response.CategoryID);
              if (data.dropData !== undefined) {
                this.categoryList[getCategoryIndex]['SubCategories'] = response.SubCategories;
                this.categoryList[getCategoryIndex]['ProductsMainCount'] = response.ProductsMainCount;
                this.categoryList[getCategoryIndex]['ProductsCount'] = response.ProductsCount;
                if (this.subCategoryExists) {
                  this.subCategoryList = response.SubCategories;
                }
              }
              if (!this.categoryExists) {
                this.getSearchData(true, data.dropData);
              }
              else {
                // this.showLoader = false;
                this.populateCategoryList();
              }
              // if(this.categoryExists){
              //   this.populateCategoryList();
              // }
            }
          })
        }
        else {
          this.showLoader = false;
          this.toast.error(res.Message);
        }
      }
    }, error => {
      this.showLoader = false;
    });
  }

  setDelete() {
    const loginData = this.apiService.getLoginData();
    this.deleteRelated = false;
    this.deleteModal.show();
  }

  deleterelate(event) {
    this.deleteRelated = event.checked;
  }

  confirmDelete() {
    // const loginData = this.apiService.getLoginData();
    let requestData = {
      CustomerID: this.webshopDetail.CustomerID,
      RemoveBatchProducts: this.deleteRelated
    }

    //if(this.selectIds.length > 0){

    if (this.productExists) {
      requestData['Products'] = this.deletesendIdspro;
    }

    if (this.categoryExists || this.subCategoryExists || this.subSubCategoryExist) {
      requestData['Categories'] = this.selectIds;
    }
    //}
    if (this.getID !== undefined && this.getID !== null && this.getID !== '') {
      requestData['CategoryID'] = this.getID;
    }

    // this.showLoader = true;
    this.categoryService.deleteOnlineProductsBulk(requestData).subscribe((data: any) => {
      if (data.Status) {
        this.showMsg = data.Message;
        this.alertModal.show();
      }
      else {
        this.confirmDel();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.Message);
    });



    // this.deleteModal.hide();
    // this.alertModal.hide();
  }

  confirmDel() {
    // const loginData = this.apiService.getLoginData();
    let requestDatas = {
      CustomerID: this.webshopDetail.CustomerID,
      RemoveBatchProducts: this.deleteRelated
    }
    if (this.productExists) {
      requestDatas['Products'] = this.deletesendIdspro;
    }

    if (this.categoryExists || this.subCategoryExists || this.subSubCategoryExist) {
      requestDatas['Categories'] = this.selectIds;
    }

    if (this.getID !== undefined && this.getID !== null && this.getID !== '') {
      requestDatas['CategoryID'] = this.getID;
    }

    this.showLoader = true;

    this.categoryService.deleteOnlineProductsConfirm(requestDatas).subscribe((data: any) => {
      if (data !== null) {
        if (data.Status) {
          this.toast.success(data.Message);
          if (this.mainCategoryID !== undefined && this.mainCategoryID !== null) {
            const returnList = {
              categoryID: this.mainCategoryID,
              customerID: requestDatas.CustomerID
            }
            this.categoryHttpService.getSubCategory(returnList).subscribe((response: any) => {
              if (response) {
                const getCategoryIndex = this.categoryList.findIndex(get => get.CategoryID === response.CategoryID);
                this.categoryList[getCategoryIndex]['SubCategories'] = response.SubCategories;
                this.categoryList[getCategoryIndex]['ProductsMainCount'] = response.ProductsMainCount;
                this.categoryList[getCategoryIndex]['ProductsCount'] = response.ProductsCount;
              }
            });
          }
        }
        else {
          this.toast.error(data.Message);
        }
        if (!this.categoryExists) {
          this.getSearchData(true);
        }
        this.selectIds.forEach(deleteId => {
          if (this.categoryExists) {
            const getSubDeleteId = this.categoryList.findIndex(findSub => findSub.CategoryID === deleteId);
            this.categoryList[getSubDeleteId]['isDeleted'] = true;
          }
          if (this.subCategoryExists) {
            const getSubDeleteId = this.subCategoryList.findIndex(findSub => findSub.CategoryID === deleteId);
            this.subCategoryList[getSubDeleteId]['isDeleted'] = true;
          }
          if (this.subSubCategoryExist) {
            const getSubDeleteId = this.subSubCategory.findIndex(findSub => findSub.CategoryID === deleteId);
            this.subSubCategory[getSubDeleteId]['isDeleted'] = true;
          }
        });
        this.selectIds = [];
        this.deletesendIdspro = [];
        if (this.categoryExists) {
          this.populateCategoryList();
        }
        this.alertModal.hide();
        this.deleteModal.hide();
      }
    }, error => {
      this.showLoader = false;
      this.toast.error(error.error.Message);
    });
  }


  changeEvent(event) {
    this.selectIds = [];
    this.deletesendIdspro = [];
    this.selectAllCheck = event.checked;
    if (event.checked) {
      if (this.categoryExists) {
        if (this.categoryList.length > 0) {
          this.changeStatus(this.categoryList, event.checked);
          this.categoryList.forEach(element => {
            this.selectIds.push(element.CategoryID);
          });
        }
      }
      if (this.subCategoryExists) {
        if (this.subCategoryList.length > 0) {
          this.changeStatus(this.subCategoryList, event.checked);
          this.subCategoryList.forEach(element => {
            this.selectIds.push(element.CategoryID);
          });
        }
      }
      if (this.subSubCategoryExist) {
        if (this.subSubCategory.length > 0) {
          this.changeStatus(this.subSubCategory, event.checked);
          this.subSubCategory.forEach(element => {
            this.selectIds.push(element.CategoryID);
          });
        }
      }
      if (this.productExists) {
        if (this.productList.ItemsCollection.length > 0) {
          this.changeStatus(this.productList.ItemsCollection, event.checked);
          this.productList.ItemsCollection.forEach(element => {
            this.deletesendIdspro.push(element.ProductID);
          });
        }
      }

    }
    else {
      this.changeStatus(this.categoryList, event.checked);
      this.changeStatus(this.subCategoryList, event.checked);
      this.changeStatus(this.subSubCategory, event.checked);
      this.changeStatus(this.productList.ItemsCollection, event.checked);
      this.selectIds = [];
      this.deletesendIdspro = [];
    }
  }

  ShowDeleteIcon(event) {
    if (event.target.control.checked == false) {
      this.showDelete = true
      this.setDeleteAll()
    }
    else {
      this.showDelete = false
    }
  }

  changeStatus(type, status) {
    type.forEach(element => {
      element.status = status;
    });
  }

  setDeleteAll() {
    this.addCategoryIdReq = true
    // if (this.productListOnly !== undefined) {
    //   for (var i = 0; i < this.productListOnly.length; i++) {
    //     if (this.productListOnly !== undefined) {
    //       this.sendId(this.productListOnly[i].ProductID, this.checkboxEvent)
    //       this.setAllCheck = true
    //       this.setSubcategorisCheck = true
    //       this.setCategoryCheck = true
    //     }
    //   }
    // }
    return false;


  }

  checkUnchecked(event, id, type, index) {
    if (event.checked) {
      if (type === 'category' || type === 'subCategory' || type === 'subsubCategory') {
        this.selectIds.push(id);
      }
      else {
        this.deletesendIdspro.push(id);
      }

      this.showDelete = true;

    }
    else {
      if (type === 'category' || type === 'subCategory' || type === 'subsubCategory') {
        let getIndex = this.selectIds.indexOf(this.sendIds)
        this.selectIds.splice(getIndex, 1)
      }
      else {
        let getIndex = this.deletesendIdspro.indexOf(id)
        this.deletesendIdspro.splice(getIndex, 1)
      }
    }
    if (type === 'category') {
      this.dynamicCheckUncheck(this.categoryList, index, event)
    }
    else if (type === 'subCategory') {
      this.dynamicCheckUncheck(this.subCategoryList, index, event)
    }
    else if (type === 'subsubCategory') {
      this.dynamicCheckUncheck(this.subSubCategory, index, event)
    }
    else {
      this.dynamicCheckUncheck(this.productList.ItemsCollection, index, event)
    }
  }

  dynamicCheckUncheck(type, index, event) {
    type[index]['status'] = event.checked;
    if (type.length > 0) {
      const checkIfAllChecked = type.filter(data => data.status === false);
      if (checkIfAllChecked.length === 0) {
        this.selectAllCheck = true;
      }
      else {
        this.selectAllCheck = false;
      }
    }
  }
  setHeight() {
    let getWindowHeight = window.innerHeight;
    getWindowHeight = getWindowHeight - 60;
    return getWindowHeight;
  }
}