import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { supplierReducers } from './state/reducers/supplier.reducer';
import { SupplierEffects } from './state/effects/supplier.effects';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { UnderSupplierAddModule } from './undersupplier-add/undersupplier-add.module';
import { UnderSupplierListModule } from './undersupplier-list/undersupplier-list.module';
import { UnderSupplierService } from './state/services/undersupplier.service';
import { UnderSupplierHttpService } from './state/services/undersupplier.http.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CommonFilterModule,
    UnderSupplierAddModule,
    UnderSupplierListModule,
    EffectsModule.forFeature([SupplierEffects]),
    StoreModule.forFeature('supplierModule', supplierReducers)
  ],
  declarations: [],
  providers: [UnderSupplierService, UnderSupplierHttpService],
  exports: []
})

export class UnderSupplierModule { }
