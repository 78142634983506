import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnChanges {
  @Input() productionLineData: any;
  @Input() selectedOrderBy: any;
  @Input() noDataFoundText: any;
  @Output() productDetailEvent = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  gotoDetail(event: any) {
    this.productDetailEvent.emit(event);
  }

}
