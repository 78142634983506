import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class OrderProcessOverviewHttpService {
    webshopDetail: any;
    constructor(private apiService: ApiService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
    }

    updateOrderInternalNote(orderId, InternalNote) {
        const path = `en/orders/internalnote/${orderId}/?InternalNote=`;
        return this.apiService.put(path, { InternalNote });
    }

    updatePaymentAsReceived(orderId) {
        const path = `en/orders/updateOrderIsPaymentReceived/${orderId}/true`;
        return this.apiService.put(path);
    }
    updateInternalNote(orderItemId, InternalNote) {
        const path = `en/orderitems/internalnote/${orderItemId}/?InternalNote=`;
        return this.apiService.put(path, { InternalNote });
    }

    getCaptureAmount(OrderID, orderItemId, issampleOrder) {
        const path = `en/orders/getCaptureAmount/${orderItemId}`;
        return this.apiService.post(path, { OrderID, issampleOrder });
    }

    captureAmount(orderItemId, payload) {
        const path = `en/orders/capture/${orderItemId}`;

        return this.apiService.post(path, { ...payload });
    }

    cancelOrder(orderItemId, sendMailStatus) {
        let queryParam = '';
        if (!sendMailStatus) {
            queryParam += '?noMail=true';
        }
        const path = `en/orderitems/${orderItemId}` + queryParam;
        return this.apiService.delete(path);
    }

    getOrderOverView(orderId, customerID) {
        const path = `en/orders/${orderId}/?suborders=true&deliveryNotesData=true&LoggedInCustomerID=${customerID}`;
        return this.apiService.get(path);
    }

    // getOrderDetails(orderId, subOrderFlag) {
    //     const path = `en/orders/${orderId}?adminCustomerID=1&suborders=${subOrderFlag}&LoggedInCustomerID=${this.webshopDetail.CustomerID}`;
    //     return this.apiService.get(path);
    // }

    getEditDetails(orderId) {
        const path = `en/orders/${orderId}?suborders=false`;
        return this.apiService.get(path);
    }

    getCountries() {
        const path = `en/countries`;
        return this.apiService.get(path);
    }

    deleteOwtOrderItem(orderItemId) {
        const path = `en/orderitems/simpledelete/${orderItemId}`;
        return this.apiService.delete(path);
    }
}
