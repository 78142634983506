import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductManageColorComponent } from './product-manage-colors.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CommonFormModule,
    SharedModule,
    TranslateModule,
    TooltipModule,
    NgSelectModule
  ],
  declarations: [ProductManageColorComponent],
  exports: [ProductManageColorComponent]
})

export class ProductManageColorModule { }
