import { Component, OnInit, Input, Output, ViewChild, ElementRef, Renderer2, SimpleChanges, SimpleChange, OnChanges, EventEmitter } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ControlValidationService } from '../../shared/control-validation/index';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../../shared/common-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { WebshopHttpService } from '../state/services/webshop.http.service';
import { DashboardHttpService } from 'src/app/dashboard/state/services/dashboard.http.service';

@Component({
  selector: 'rewardkart-admin-webshop-add',
  templateUrl: './webshop-add.component.html',
  styleUrls: ['./webshop-add.component.scss']
})
export class WebshopAddComponent implements OnInit {
  webshopDetails: FormGroup;
  showLoader: Boolean = false;
  selectedImg: any = '';
  uploadImgPath: any = '';
  showImageError: Boolean = false;
  webshopDetail: any;
  productTypeList: any;
  invoiceDiscount: any;
  userID: any;
  isSaas:boolean = false;
  // tslint:disable-next-line:max-line-length
  constructor(private webshophttpService: WebshopHttpService, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router, private renderer: Renderer2, private translate: TranslateService, private dashboardhttpservice: DashboardHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.productTypeList = [{
      label: 'All',
      value: 1
    }, {
      label: 'Digital',
      value: 2
    }, {
      label: 'Physical',
      value: 3
    }]
    this.loadForm();
  }

  ngOnInit() {
    const userDetails = JSON.parse(sessionStorage.getItem('userdetails'))
    this.userID = userDetails.UserID;
    if(userDetails.ISSaas === "Y"){
      this.isSaas = true;
    }
  }

  changeType() {

  }

  loadForm() {
    this.webshopDetails = this.formBuilder.group({
      Name: ['', Validators.required],
      CompanyName: ['', Validators.required],
      DomainName: ['', [Validators.required, ControlValidationService.domainNameValidator]],
      webshopImage: ['', Validators.required],
      productType: ['', Validators.required],
      invoiceDiscount: [false, Validators.required]
    });
  }

  validateFields() {
    let validateStatus = true;
    // tslint:disable-next-line:max-line-length
    if ((this.webshopDetails.controls.Name.value === '') || (this.webshopDetails.controls.CompanyName.value === '') || (this.webshopDetails.controls.DomainName.value === '') || (this.webshopDetails.controls.webshopImage.value === '') || (this.webshopDetails.controls.productType.value === '') || (this.webshopDetails.controls.invoiceDiscount.value === '')) {
      this.webshopDetails.controls['Name'].markAllAsTouched();
      this.webshopDetails.controls['CompanyName'].markAllAsTouched();
      this.webshopDetails.controls['DomainName'].markAllAsTouched();
      this.webshopDetails.controls['webshopImage'].markAllAsTouched();
      this.webshopDetails.controls['productType'].markAllAsTouched();
      this.webshopDetails.controls['invoiceDiscount'].markAllAsTouched();
      validateStatus = false;
    }
    return validateStatus;
  }

  addSupplier() {
    const validStatus = this.validateFields();
    if (!validStatus) {
      this.toast.error(this.translate.instant('USERADDEDIT.FILL_MANDATORY_FIELDS'));
      return false;
    } else {
      /** For Domain Name check Code */
      this.showLoader = true;
      this.webshophttpService.checkdomainname(this.webshopDetails.value.DomainName).subscribe((domaindata: any) => {
        this.showLoader = false;
        if (domaindata) {
          /** For Image Upload Code */
          this.showLoader = true;
          let formdata = new FormData();
          formdata.append('file', this.uploadImgPath);
          formdata.append('filePath', 'CustomerImages');
          this.showLoader = true;
          this.webshophttpService.uploadwebshopimage(formdata).subscribe((uploadImageStatus: any) => {
            this.showLoader = false;
            if (uploadImageStatus[0] !== null && uploadImageStatus[0] !== undefined) {
              const addWebshopData = {
                Name: this.webshopDetails.value.Name,
                CompanyName: this.webshopDetails.value.CompanyName,
                DomainName: this.webshopDetails.value.DomainName,
                ParentCustomerID: this.webshopDetail.CustomerID,
                ProductsCategoryID: this.webshopDetails.value.productType,
                InvoiceDiscount: this.webshopDetails.value.invoiceDiscount,
                isWebShopActive: false,
                Action: 'insert',
                ImageName: uploadImageStatus[1],
                LoginId: this.userID,
                Translations: [
                  {
                    LanguageID: 1,
                    LanguageCode: 'en',
                    ObjectID: 0,
                    ObjectType: 'Name',
                    PropertyName: 'Name',
                    PropertyValue: this.webshopDetails.value.Name,
                    Action: 1
                  }
                ],
                Themes: [
                  {
                    Name: ' ',
                    Main: '#9dbd17',
                    Lable: '#666666',
                    Alert: '#da522f'
                  }
                ],
              };
              /** For Insert/Create webshop Code */
              this.showLoader = true;
              this.webshophttpService.addWebshop(addWebshopData,this.isSaas).subscribe((data: any) => {
                this.showLoader = false;
                if (data) {
                  this.toast.success('Webshop created successfully');
                  this.router.navigate(['/admin-webshop-list'], { replaceUrl: false });
                  this.dashboardhttpservice.getParentCustomersList(this.webshopDetail.CustomerID).subscribe((data: any) => {
                    if (data) {
                      this.showLoader = false;
                      sessionStorage.setItem('parentCustomerList', JSON.stringify(data));
                    }
                  },
                    _err => {
                      this.showLoader = false;
                    });
                }
              },
                err => {
                  let errMsg = '';
                  this.showLoader = false;
                  if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
                    // tslint:disable-next-line:forin
                    for (var key in err.error.ModelState) {
                      errMsg += err.error.ModelState[key] + ' ';
                    }
                  } else {
                    errMsg += err.error;
                  }
                  this.toast.error(errMsg);
                });
            } else {
              this.toast.error('Image upload error');
              return false;
            }
          },
            err => {
              let errMsg = '';
              this.showLoader = false;
              if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
                for (var key in err.error.ModelState) {
                  errMsg += err.error.ModelState[key] + ' ';
                }
              } else {
                errMsg += err.error;
              }
              this.toast.error(errMsg);
            });
        } else {
          this.toast.error(this.webshopDetails.value.DomainName + ' already exists');
          return false;
        }
      },
        err => {
          let errMsg = '';
          this.showLoader = false;
          if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
            for (var key in err.error.ModelState) {
              errMsg += err.error.ModelState[key] + ' ';
            }
          } else {
            errMsg += err.error;
          }
          this.toast.error(errMsg);
        });
    }
  }


  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }
  }

  onSelectFile(event) {
    const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
    if ((getImg !== '.jfif' && getImg !== '.jpg' && getImg !== '.jpeg' && getImg !== '.png' && getImg !== '.gif' &&
      getImg !== '.JPG' && getImg !== '.JPEG' && getImg !== '.PNG' && getImg !== '.GIF' && getImg !== '.JFIF')) {
      this.showImageError = true;
      this.selectedImg = '';
      this.uploadImgPath = '';
      return;
    } else {
      this.showImageError = false;
    }

    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].size !== '') {
          const maximumMb = event.target.files[i].size / 1024 / 1024;
          if (maximumMb > 10) {
            this.toast.error(this.translate.instant('EDITORDERSITEMS.LOG_FILE_VALIDATION'));
            return false;
          }
        }
        const reader = new FileReader();
        reader.onload = (event1: any) => {
          this.selectedImg = event1.target.result;
        };
        reader.readAsDataURL(event.target.files[i]);
        this.uploadImgPath = event.target.files[0];
      }
    }
  }

  redirectToWebshopList() {
    this.router.navigate(['/admin-webshop-list'], { replaceUrl: false });
  }

  // domainCheck() {
  //   if (this.webshopDetails.controls.DomainName.value !== '') {
  //     this.showLoader = true;
  //     this.webshophttpService.checkdomainname(this.webshopDetails.controls.DomainName.value).subscribe((domaindata: any) => {
  //       this.showLoader = false;
  //       if (!domaindata) {
  //         /** For Image Upload Code */
  //         this.toast.error(this.webshopDetails.value.DomainName + ' already exists');
  //         return false;
  //       }
  //     },
  //       err => {
  //         let errMsg = '';
  //         this.showLoader = false;
  //         if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
  //           for (var key in err.error.ModelState) {
  //             errMsg += err.error.ModelState[key] + ' ';
  //           }
  //         } else {
  //           errMsg += err.error;
  //         }
  //         this.toast.error(errMsg);
  //       });
  //   }
  // }
}
