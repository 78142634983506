import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageboxState, MessageboxReducers } from './messagebox.reducer';

export interface State {
    messageboxModule: MessageboxState;
}

export const reducers = MessageboxReducers;

export const getMessageinboxState = createFeatureSelector<MessageboxState>('messageboxModule');


export const getMessageinboxStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageinboxwidget);

export const getMessageinboxdetailsStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageinboxdetailswidget);

export const getMessageimportantdetailsStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageimportantdetailswidget);

export const getMessageclaimsdetailsStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageclaimsdetailswidget);

export const getMessageinquiriesdetailsStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageinquiriesdetailswidget);


export const getMessagedraftStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messagedraftwidget);

export const getMessageimportantStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageimportantwidget);

export const getMessageinquiriesStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageinquirieswidget);

export const getMessagetrashStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messagetrashswidget);

export const getMessageclaimsStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messageclaimswidget);

export const getMessagesentStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messagesentwidget);


export const getCustomerSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messagecustomer);


export const BulkActionSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.bulkAction);


export const getSendMessageSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.sendMessageSuccess);

export const getSendDraftMessageSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.sendDraftMessageSuccess);

export const getDeleteMessageSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.deleteMessageSuccess);

export const getDeleteMailSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.deleteMailSuccess);

export const getUserMailAddressSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.getUserMailAddressSuccess);

export const getMessagereadStateSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.messagereadwidget);

export const unreadMessageSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.unreadMessage);

export const claimsunreadMessageSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.claimsunreadMessage);

export const inquriesunreadMessageSuccess = createSelector(getMessageinboxState,
    (state: MessageboxState) => state.inquriesunreadMessage);

