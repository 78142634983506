import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { ParentshopHttpService } from '../state/services/parentshop.http.service';
import { ParentshopListComponent } from './parentshop-list.component';

@NgModule({

    declarations: [ParentshopListComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        ControlValidationModule,
        TranslateModule,
        PageLoaderModule,
        CommonFilterModule,
        MDBBootstrapModule.forRoot()
    ],
    providers: [ParentshopHttpService],
})
export class ParentshopListModule { }
