import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { WebshopService } from '../state/services/webshop.service';
import { WebshopHttpService } from '../state/services/webshop.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';

@Component({
  selector: 'rewardkart-admin-webshop-list',
  templateUrl: './webshop-list.component.html',
  styleUrls: ['./webshop-list.component.scss']
})

export class WebshopListComponent implements OnInit {

  header: any;
  webshopList: any = [];
  submitted = false;
  webshopdatacount: any;
  actions: any = ['viewwebshop', 'editwebshopdata', 'delete'];
  tableName: string = 'webshopList';
  filterFields: any = [];
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  activePage: number = 1;
  rowactivePage: number = 1;
  searchCriteria: any = {};
  filterData: any = {};
  childwebshop: any = [];
  passFilterresult: any = [];
  CustomerID: any = "";
  resetStatus: boolean = false;
  showLoader: Boolean = false;
  commonRemoveFilter: any;
  webshopDetail: any;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'Name',
      header: 'Webshop Name'
    },
    {
      primaryKey: 'CustomerID',
      header: 'Webshop ID'
    },
    {
      primaryKey: 'ImageURL',
      header: 'Webshop Logo',
      format: 'image'
    },
    {
      primaryKey: 'TotalOrdersCount',
      header: 'No Of Orders'
    },
    {
      primaryKey: 'TotalClaimsCount',
      header: 'No Of Claims'
    },
    {
      primaryKey: 'isWebShopActive',
      header: 'Status'
    },
    {
      primaryKey: 'action',
      format: 'webshopaction'
    }
  ];
  partner = '.constient.us';
  shop = '.constients.com';

  constructor(private webshopService: WebshopService, private apiService: ApiService, private webshophttpservice: WebshopHttpService, private toast: ToastService, private router: Router) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
    this.getChildWebshop();
  }

  ngOnInit() {
    let domain = (location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[1]
    if (domain === 'rewardkartadmin') {
      this.partner = '.rewardkartadmin.in';
      this.shop = '.rewardkartshop.in';
    }
    if (domain === 'uk-constient') {
      this.partner = '.uk-constient.com';
      this.shop = '.us-constient.com';
    }
    if (domain === 'hyperkart') {
      this.partner = '.hyperkart.co.in';
      this.shop = '.hyperkart.in';
    }
    this.header = 'Webshop Details'; // Multilingual to be implemented
    const searchkey = sessionStorage.getItem('searchWebshopListKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
    }
    this.onLoadWebshopList();
  }

  onLoadFunctions() {
    // this.webshopService.loadWebshops();
  }

  onLoadWebshopList() {
    const searchkey = sessionStorage.getItem('searchWebshopListKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.getWebshopList(JSON.parse(searchkey));
    } else {
      this.searchCriteria = {
        childShopId: '',
        pageSize: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        pageIndex: 10
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
      // this.userService.loadUsers(this.searchCriteria);
      this.getWebshopList(this.searchCriteria);
    }
  }

  getChildWebshop() {
    this.webshophttpservice.getchildWebshopList(this.webshopDetail.CustomerID, false).subscribe((data: any) => {
      if (data) {

        // let childcustomer = data.ChildCustomers
        this.childwebshop = [];
        this.childwebshop.push({ label: 'Select Child Webshop', value: '' });
        data.ChildCustomers.forEach(element => {
          const langVal = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.childwebshop.push(langVal);
        });
        this.setfilterData();
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  getWebshopList(searchCriteria?) {
    const searchdata = {
      activePage: this.activePage,
      pageSize: (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10,
      childShopId: (searchCriteria.childShopId !== null && searchCriteria.childShopId !== undefined) ? searchCriteria.childShopId : '',
    };
    this.showLoader = true;
    this.webshophttpservice.getWebshopList(searchdata).subscribe(data => {
      this.showLoader = false;
      if (data) {
        const webshopListData = data;
        this.webshopList = [];
        this.webshopList = webshopListData['ItemsCollection'];
        this.webshopList.forEach(element => {
          if (Number(element.TotalOrdersCount) === 0) {
            element.TotalOrdersCount = element.TotalOrdersCount.toString();
          }
          if (Number(element.TotalClaimsCount) === 0) {
            element.TotalClaimsCount = element.TotalClaimsCount.toString();
          }
        });
        this.webshopdatacount = webshopListData['TotalItemsCount'];
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  /** Paginaion code Section Functions */
  getPerPage(event) {
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(this.searchCriteria);
  }

  paginationNum(event) {
    this.activePage = event;
    const searchkey = sessionStorage.getItem('searchWebshopListKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    this.searchCriteria.activePage = event;
    this.perPagedata = this.searchCriteria.pageIndex;
    this.getWebshopList(this.searchCriteria);
  }

  setfilterData() {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Select Child Webshop',
        type: 'select',
        class: 'col-md-12',
        key: 'childwebshopname',
        data: this.childwebshop,
        selected: this.CustomerID,
        required: false,
        filterkey: 'childwebshopname'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      },
    ];

    const searchResult = sessionStorage.getItem('searchWebshopListResult')
    if (searchResult !== null) {
      const searchWebshopListResult = JSON.parse(sessionStorage.getItem('searchWebshopListResult'))
      /** To Prefill Webshop dropdown value(based on Login) */
      // tslint:disable-next-line:max-line-length
      const getIndex = searchWebshopListResult.findIndex(ind => ind.key === 'childwebshopname');
      this.filterFields[getIndex].selected = searchWebshopListResult[getIndex].selected;
    }
  }

  getSelectData(data) {
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        childShopId: data.childwebshopname !== undefined && data.childwebshopname.selectMethod === 'childwebshopname' ? data.childwebshopname.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    } else {
      this.searchCriteria = {
        childShopId: '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }

    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'childwebshopname') {
        element.selected = this.searchCriteria.childShopId;
      }
    });
  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    this.getWebshopList(this.filterData);
    sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchWebshopListResult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'childwebshopname') {
        element.selected = '';
      }
    });
    this.resetStatus = true;
    this.getPerPage(10);
    this.getSelectData(undefined);
    sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchWebshopListResult', JSON.stringify(this.filterFields));
    this.onLoadWebshopList();
  }


  /** Remove Filter Tags Section Code */
  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchkey = sessionStorage.getItem('searchWebshopListKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    if (val !== undefined) {
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = this.webshopDetail.CustomerID;
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
      });
      sessionStorage.setItem('searchWebshopListKey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchWebshopListResult', JSON.stringify(this.filterFields));
      this.getWebshopList(this.filterData);
    }
  }

  getFilterResult(filterresult) {
    this.passFilterresult = filterresult;
  }

  addWebshop(event: boolean) {
    this.submitted = event;
  }

  viewWebshop(event: any) {
    // const hostname = 'conxion.linkpartner-staging.dk';  //for local
    const hostname = window.location.hostname; // for deploy 
    let baseURL = '';
    if (String(hostname) !== '') {
      baseURL = event.DomainName.toLowerCase() + this.shop;
      window.open(window.location.protocol + '//' + baseURL, '_blank');
    }
  }

  editWebshop(editdata: any) {
    if (editdata !== '') {
      const loginData = this.apiService.getLoginData();
      const requestData = {
        UserID: loginData.UserID,
        ChildCustomerID: editdata.CustomerID
      };
      this.showLoader = true;
      this.webshophttpservice.getUserToken(requestData).subscribe(
        data => {
          // const test = 'tOuMArPZOA07/sdbmqupVJYwGeU0DnRBuCPw6sO76fA=';
          // const crypto = require('crypto');
          // const currentDate = moment().format("MM/DD/YYYY HH:mm:ss") + '-' + 'yfcd65cv8ew@';
          // console.log("currentDate : ", currentDate);
          // const key = 'xioej#12$f3xioej#12$f3xioej#12$w'; //replace with your key
          // const iv = 'pyrkju@os8tmj*8h'; //replace with your IV

          // const cipher = crypto.createCipheriv('aes256', key, iv);
          // let crypted = cipher.update(currentDate, 'utf8', 'base64');
          // crypted += cipher.final('base64');
          // console.log("Generate token : ", crypted);

          // const decipher = crypto.createDecipheriv('aes256', key, iv);
          // let dec = decipher.update(test, 'base64', 'utf8');
          // dec += decipher.final('utf8');
          // console.log("DEcryption Token : ", dec);


          this.showLoader = false;
          /** Static URL for Newsletter with Dynamic domain set based on Dev, Qatest, Staging, Prod Server */
          // const hostname = 'conxion.linkpartner-qatest.dk';  //for local
          const hostname = window.location.hostname; // for deploy 
          let baseURL = '';
          if (String(hostname) !== '' && String(hostname) !== 'localhost') {
            baseURL = editdata.DomainName.toLowerCase() + this.partner;
            // tslint:disable-next-line:max-line-length
            const childURL = window.location.protocol + '//' + baseURL + '/admin-login?token=' + encodeURIComponent(data.toString()) + '&customerId=' + editdata.CustomerID;
            window.open(childURL, '_blank');
          }
          if (String(hostname) !== '' && String(hostname) === 'localhost') {
            const childURL = 'http://localhost:5200/admin-login?token=' + encodeURIComponent(data.toString()) + '&customerId=' + editdata.CustomerID;
            window.open(childURL, '_blank');
          }
        },
        error => {
          if (error['status'] == 404) {
            this.toast.success('');
          }
        }
      );
      //  data.undersupplierEditPage = true;
      //  this.router.navigate(['/admin-undersupplier-edit'], { state: { data }, replaceUrl: false });
    }
  }

  deleteWebshop(deletedData: any) {
    this.showLoader = true;
    this.webshophttpservice.deleteWebshop(deletedData.CustomerID).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.toast.success('Webshop(' + deletedData.Name + ') deleted successfully');
        this.getChildWebshop();
        this.onLoadWebshopList();
      } else {
        this.toast.error('Webshop(' + deletedData.Name + ') cannot be deleted');
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);
      });
  }

  refreshTable(event: boolean) {
    this.submitted = event;
    this.onLoadFunctions();
  }

  selectedTableRow(event: boolean) {
    this.submitted = event;
  }

  pageredirect() {
    this.router.navigate(['/admin-webshop-add'], { replaceUrl: true });
  }


}

