import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';

@Injectable()
export class ParentshopHttpService {
    webshopDetail: any;
    constructor(private apiService: ApiService, private loginService: LoginService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            //const loginData = this.apiService.getLoginData();
            const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getParentshopList(searchData: any) {
        const webshop = sessionStorage.getItem('webshopDetail');
        //const loginData = this.apiService.getLoginData();
        const loginData = JSON.parse(sessionStorage.getItem('userdetails'));
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {

            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
        let path;
        let searchCriteria: any = {
            activePage: searchData.activePage !== undefined ? searchData.activePage : '',
            pageSize: searchData.pageSize !== undefined ? searchData.pageSize : '',
        }

        path = 'en/customers?pageIndex=' + searchCriteria.activePage + '&pageSize=' + searchCriteria.pageSize + '&ParentCustomerID=' + this.webshopDetail.CustomerID + '&RoleCode=' + loginData.Roles[0].Code + '&IsNeedParentShop=true';


        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
}