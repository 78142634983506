import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampaignState } from '../reducers/campaign.reducer';
import { CampaignActions } from '../actions/index';
import { getCampaignListSuccess, getCampaignUpdateSuccess, FileUploadSuccess } from '../reducers/index';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Injectable()
export class CampaignService {
  constructor(
    private store: Store<CampaignState>,
    private apiService: ApiService
  ) { }

  loadCampaigns(searchCriteria: any) {
    this.store.dispatch(new CampaignActions.LoadCampaign(searchCriteria));
  }

  getCampaignList() {
    return this.store.select(getCampaignListSuccess);
  }

  saveCampaign(data) {
    this.store.dispatch(new CampaignActions.SaveCampaign(data));
  }

  getCampaignUpdateSuccess() {
    return this.store.select(getCampaignUpdateSuccess);
  }

  addProductToCampaign(campaignId, productId, userId) {
    const url = `en/CampaignProduct/${userId}/${campaignId}/${productId}`;
    return this.apiService.get(url);
  }

  removeProductFromCampaign(campaignId, productId, userId) {
    const url = `en/DeleteProduct/${userId}/${campaignId}/${productId}`;
    return this.apiService.get(url);
  }

  loaduploadFile(searchCriteria: any) {
    this.store.dispatch(new CampaignActions.LoadFileUpload(searchCriteria));
  }

  uploadFile() {
    return this.store.select(FileUploadSuccess);
  }
}
