import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnSetting } from 'src/app/shared/link-table/models/link-table-model';

@Component({
  selector: 'rewardkart-admin-suborder-item-details',
  templateUrl: './suborder-item-details.component.html',
  styleUrls: ['./suborder-item-details.component.scss']
})
export class SuborderItemDetailsComponent implements OnChanges {
  @Input() subOrderItemDetails: any;
  @Input() summaryData: any;
  @Input() subOrderItem: any;
  @Input() lineId: any;
  columns: ColumnSetting[] = [
    {
      primaryKey: 'ProductImageURL',
      header: 'Product',
      format: 'icon',
      showSort: false
    },
    {
      primaryKey: 'ProductIdManual',
      header: 'Item Number'
    },
    {
      primaryKey: 'ProductName',
      header: 'Product Name'
    },
    {
      primaryKey: 'OrderedQuantity',
      header: 'Ordered Quantity'
    },
    {
      primaryKey: 'DeliveryNoteQuantity',
      header: 'Delivery Note Qty'
    },
    {
      primaryKey: 'DispatchedQuantity',
      header: 'Dispatched Qty'
    },
    {
      primaryKey: 'CreditNoteQuantity',
      header: 'Credit Note Qty'
    },
    {
      primaryKey: 'isExpressOrder',
      header: 'Express Order'
    },
    {
      primaryKey: 'isSampleOrderItem',
      header: 'Sample Order'
    },
    {
      primaryKey: 'WarehouseName',
      header: 'Warehouse'
    }
  ];
  tableName = 'orderList';
  constructor(private router: Router) {
  }

  ngOnChanges() {
    const getArray = [];
    this.summaryData.UPSShipment.forEach(element => {
      if (this.subOrderItem.SuborderNumber === element.SuborderNumber) {
        getArray.push(element);
      }
    });
    if (getArray.length > 0) {
      const obj = {
        primaryKey: 'files',
        header: 'Files',
        array: getArray,
        format: 'pdf'
      }
      this.columns.push(obj)
    }
  }

  navigateToOrderDetailsFromSuborder(event) {
    const lineId = this.lineId;
    const subOrderItemData = this.subOrderItem;
    const finishedPL = history.state.finishedPL;
    const isSubOrder = true;
    const type = history.state.type === 'productionline' ? 'orderFromProductionLine' : 'orderProcessDetail';
    this.router.navigate(
      [`/admin-order-process/orderProcess/${this.subOrderItem.OrderID}/${this.subOrderItem.SuborderID}/${1}`],
      { state: { lineId, customerID: history.state.customerID, finishedPL, subOrderItemData, type: type, isSubOrder }, replaceUrl: false });
  }

}
