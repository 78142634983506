import { Component, OnInit, Input, OnChanges, Output, SimpleChanges, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { CategoryHttpService } from '../state/services/category.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { CategoryService } from '../category-services/category.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnChanges {
  @ViewChild('autoComp', { static: true }) autoComp;
  ProductName = ''
  keyword = 'ProductName';
  displayMode: number = 1;
  searchproduct: any = [];
  searchText: any = '';
  searchForm: FormGroup;
  customerList = [];
  customerOptionList = [];
  productStatusList = [];
  productDeletedStatusList = [];
  webshopLibraryCustomerList = [];
  webshopOnlineProductCustomerList = [];
  suppliersCategoriesList = [];
  sizesList = [];
  supplier: any = [];
  category = [];
  @Input() colorList;
  @Output() searchData = new EventEmitter<any>();
  @Output() selectSearchData = new EventEmitter<any>();
  @Input() webshopLibrarySupplierStatus;
  @Output() searchStatus = new EventEmitter<any>();
  showLoader: boolean = false;
  isParentWebshopStatus: boolean = true;
  selectedColorID = '';
  productName: any = '';
  webshopDetail: any;
  constructor(private categoryHttpService: CategoryHttpService, public apiService: ApiService, public toast: ToastService, private categoryService: CategoryService, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

    let selectedPublishedStatus = '';
    let publishedProducts = '';
    let unPublishedProducts = '';
    let both = '';
    this.translate.get('CATEGORIES.SELECT_PUBLISHED_STATUS').subscribe((res: string) => {
      selectedPublishedStatus = res;
    });
    this.translate.get('CATEGORIES.PUBLISHED_PRODUCTS').subscribe((res: string) => {
      publishedProducts = res;
    });
    this.translate.get('CATEGORIES.UNPUBLISHED_PRODUCTS').subscribe((res: string) => {
      unPublishedProducts = res;
    });
    this.translate.get('CATEGORIES.BOTH').subscribe((res: string) => {
      both = res;
    });
    this.productStatusList = [{ label: selectedPublishedStatus, value: '' }, { label: publishedProducts, value: 1 },
    { label: unPublishedProducts, value: 0 }, { label: both, value: 2 }];


    let selectedDeletedStatus = '';
    let deletedProducts = '';
    let nonDeletedProducts = '';
    this.translate.get('CATEGORIES.SELECT_DELETED_STATUS').subscribe((res: string) => {
      selectedDeletedStatus = res;
    });
    this.translate.get('CATEGORIES.DELETED_PRODUCTS').subscribe((res: string) => {
      deletedProducts = res;
    });
    this.translate.get('CATEGORIES.NON_DELETED_PRODUCTS').subscribe((res: string) => {
      nonDeletedProducts = res;
    });
    this.productDeletedStatusList = [{ label: selectedDeletedStatus, value: '' }, { label: deletedProducts, value: 1 },
    { label: nonDeletedProducts, value: 0 }, { label: both, value: 2 }];
  }

  ngOnInit() {

    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ParentCustomerID !== null && webshopDetail.ParentCustomerID !== 'null') {
        this.isParentWebshopStatus = false;
      }
    }
    this.loadForm();
    const isParentWebshop = JSON.parse(sessionStorage.getItem('isParentWebshop'));
    // load Product categoey list in advanced search section
    if (isParentWebshop) {
      this.populateCategoryList(0, this.webshopDetail.CustomerID);
    } else {
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        this.populateCategoryList(webshopDetail.ParentCustomerID, this.webshopDetail.CustomerID);
      } else {
        this.populateCategoryList(this.webshopDetail.CustomerID);
      }
    }
    this.getAllCustomerList();
    this.supplierswithcategories();
    this.getSupplierSizes();
    this.supplierDropdown();
    // this.customerSelect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.webshopLibrarySupplierStatus !== undefined && changes.webshopLibrarySupplierStatus !== null) {
      /** Called supplierDropdown Func based on manage supplier popup update function in webshop Product Detail section */
      if (this.searchForm !== undefined) {
        if (Number(this.searchForm.value.CustomerID) !== 0) {
          this.supplierDropdown();
        }
      }
    }
  }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something
  }

  loadForm() {
    this.searchForm = new FormGroup({
      CustomerID: new FormControl(''),
      CustomerCreated: new FormControl(''),
      productCategory: new FormControl(''),
      supplier: new FormControl(''),
      quantity: new FormControl(''),
      priceFrom: new FormControl(''),
      priceTo: new FormControl(''),
      childwebshop: new FormControl(''),
      maxDeliveryWeeks: new FormControl(''),
      SizeID: new FormControl(''),
      relatedProducts: new FormControl(true),
      OnlyWithoutWebshop: new FormControl(''),
      productStatusList: new FormControl(''),
      productDeletedStatusList: new FormControl(''),
      //  OnlyWithoutWebshopCustomerID: new FormControl(''),
      OnlyInCategoriesOfSupplierID: new FormControl(''),
      //  webshopProducts: new FormControl(''),
      excludeCategory: new FormControl(''),
      withSubCategory: new FormControl(''),
      withProducts: new FormControl('')
    })
  }

  /** Check Login Webshop is Parent or Child webshop */
  supplierswithcategories() {
    let allCategories = '';
    let noSupplierCategories = '';
    this.translate.get('CATEGORIES.ALL_CATEGORIES').subscribe((res: string) => {
      allCategories = res;
    });
    this.translate.get('CATEGORIES.NO_SUPPLIER_CATEGORIES').subscribe((res: string) => {
      noSupplierCategories = res;
    });
    this.categoryHttpService.getSuppliersWithCategories().subscribe((data: any) => {
      if (data) {
        this.showLoader = false;
        const supplierData = [{ label: allCategories, value: '' }];
        supplierData.push({ label: noSupplierCategories, value: '0' });
        data.ItemsCollection.forEach(option => {
          supplierData.push({ label: option.Name, value: option.SupplierID })
        })
        this.suppliersCategoriesList = supplierData;
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  /** Check Login Webshop is Parent or Child webshop */
  getSupplierSizes() {
    let selectSizeLabel = '';
    this.translate.get('CATEGORIES.SELECT_SIZE').subscribe((res: string) => {
      selectSizeLabel = res;
    });
    let request = {
      supplierId: null
    };
    if (this.searchForm.value.supplier !== '' && this.searchForm.value.supplier !== null && this.searchForm.value.supplier !== undefined) {
      request.supplierId = this.searchForm.value.supplier;
    }
    this.categoryHttpService.getSupplierSizes(request).subscribe((data: any) => {
      if (data) {
        this.searchForm.value.SizeID = '';
        this.searchForm.patchValue({
          SizeID: '',
        });
        this.showLoader = false;
        const sizeData = [{ label: selectSizeLabel, value: '' }];
        data.forEach(option => {
          sizeData.push({ label: option.Name, value: option.ProductSizeID })
        })
        this.sizesList = sizeData;
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  /** GET All customer list based on Login CustomerId */
  getAllCustomerList() {
    let linknetworkLabel = '';
    this.translate.get('CATEGORIES.LINKNETWORK').subscribe((res: string) => {
      linknetworkLabel = res;
    });
    let noneoftheWebshopLabel = '';
    this.translate.get('CATEGORIES.NONE_OF_THE_WEBSHOP').subscribe((res: string) => {
      noneoftheWebshopLabel = res;
    });
    if (sessionStorage.getItem('customerList') !== undefined && sessionStorage.getItem('customerList') !== null
      && sessionStorage.getItem('customerList') !== '') {
      const customerList = JSON.parse(sessionStorage.getItem('customerList'));
      const webshopData = [{ label: linknetworkLabel, value: 0 }];
      const webshopData1 = [{ label: noneoftheWebshopLabel, value: '' }];
      customerList.forEach(option => {
        webshopData.push({ label: option.Name, value: option.CustomerID })
        webshopData1.push({ label: option.Name, value: option.CustomerID })
      })
      this.customerList = webshopData;
      this.customerOptionList = webshopData1;
      this.setWebshopLibraryCustomerList();
    } else {
      this.showLoader = true;
      this.categoryHttpService.getCustomersList().subscribe((data: any) => {
        if (data) {
          this.showLoader = false;
          sessionStorage.setItem('customerList', JSON.stringify(data));
          const webshopData = [{ label: linknetworkLabel, value: 0 }];
          const webshopData1 = [{ label: noneoftheWebshopLabel, value: '' }];
          data.forEach(option => {
            webshopData.push({ label: option.Name, value: option.CustomerID })
            webshopData1.push({ label: option.Name, value: option.CustomerID })
          })
          this.customerList = webshopData;
          this.customerOptionList = webshopData1;
          this.setWebshopLibraryCustomerList();
        }
      },
        err => {
          this.showLoader = false;
        });
    }
  }

  supplierDropdown() {
    let selectSupplierLabel = '';
    this.translate.get('CATEGORIES.SELECTSUPPLIER').subscribe((res: string) => {
      selectSupplierLabel = res;
    });
    const customerId = this.searchForm.value.CustomerID;
    this.categoryService.supplierDropdown(customerId).subscribe((data: any) => {
      if (data) {
        const supplierData = [{ label: selectSupplierLabel, value: '' }];
        data.ItemsCollection.forEach(option => {
          supplierData.push({ label: option.Name, value: option.SupplierID })
        });
        this.supplier = supplierData;
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  setWebshopLibraryCustomerList() {
    /** Webshop Libraray Section (Left Side section) => To load Webshop List Based on Login Shop(Ex., Conxion, Ambu) */
    const isParentWebshop = JSON.parse(sessionStorage.getItem('isParentWebshop'));
    // check parent or child webshop
    if (isParentWebshop) {
      this.webshopLibraryCustomerList = this.customerList.filter(x => (x.value === this.webshopDetail.CustomerID));
      this.webshopOnlineProductCustomerList = this.customerList.filter(x => x.value === this.webshopDetail.CustomerID);
      this.searchForm.patchValue({
        CustomerID: this.webshopLibraryCustomerList[0].value,
      });
      this.customerSelect();
    } else {
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        // tslint:disable-next-line:max-line-length
        this.webshopLibraryCustomerList = this.customerList.filter(x => (x.value === this.webshopDetail.CustomerID || x.value === webshopDetail.ParentCustomerID));

        // tslint:disable-next-line:max-line-length
        this.webshopOnlineProductCustomerList = this.customerList.filter(x => (x.value === this.webshopDetail.CustomerID || x.value === webshopDetail.ParentCustomerID));
        this.searchForm.patchValue({
          CustomerID: webshopDetail.ParentCustomerID,
        });
      }
      this.customerSelect();
    }
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }


  search() {
    if (Number(this.searchForm.value.priceFrom) > 0 || Number(this.searchForm.value.priceTo) > 0) {
      if (this.searchForm.value.quantity === '' || Number(this.searchForm.value.quantity) === 0) {
        this.translate.get('CATEGORIES.QTY_VALIDATION').subscribe((res: string) => {
          this.toast.error(res);
        });
        return false;
      }
    }
    const searchData = {
      productName: this.productName,
      Quantity: Number(this.searchForm.value.quantity) ? this.searchForm.value.quantity : '',
      CategoryID: this.searchForm.value.productCategory,
      SupplierID: this.searchForm.value.supplier,
      MaxDeliveryWeeks: this.searchForm.value.maxDeliveryWeeks,
      SizeID: this.searchForm.value.SizeID,
      PriceFrom: Number(this.searchForm.value.priceFrom > 0) ? this.searchForm.value.priceFrom : '',
      PriceTo: Number(this.searchForm.value.priceTo) ? this.searchForm.value.priceTo : '',
      ReturnBatchProducts: this.searchForm.value.relatedProducts,
      CurrencyID: 2,
      CustomerID: (Number(this.searchForm.value.CustomerID) !== 0) ? this.searchForm.value.CustomerID : null,
      isAdmin: false,
      // childCustomerID: (Number(this.searchForm.value.CustomerID) === 0) ? loginData.CustomerID : '',
      childCustomerID: (this.isParentWebshopStatus) ? this.webshopDetail.CustomerID : null,
      customerID: (Number(this.searchForm.value.CustomerID) !== 0) ? this.searchForm.value.CustomerID : null,
      OnlyWithoutWebshop: this.searchForm.value.OnlyWithoutWebshop,
      // OnlyWithoutWebshopCustomerID: (this.searchForm.value.OnlyWithoutWebshop) ? this.searchForm.value.OnlyWithoutWebshopCustomerID : '',
      OnlyWithoutWebshopCustomerID: (this.searchForm.value.OnlyWithoutWebshop) ? this.webshopDetail.CustomerID : '',
      productStatusList: (this.searchForm.value.productStatusList !== '') ? this.searchForm.value.productStatusList : '',
      // tslint:disable-next-line:max-line-length
      productDeletedStatusList: (this.searchForm.value.productDeletedStatusList !== '') ? this.searchForm.value.productDeletedStatusList : '',
      OnlyInCategoriesOfSupplierID: this.searchForm.value.OnlyInCategoriesOfSupplierID,
      CustomerCreated: this.searchForm.value.CustomerCreated,
      ColorID: (this.selectedColorID !== '') ? Number(this.selectedColorID) : ''
    }
    /** If OnlyWithoutWebshop is disable then Reset OnlyWithoutWebshopCustomerID */
    // if (!this.searchForm.value.OnlyWithoutWebshop) {
    //   this.searchForm.patchValue({
    //     OnlyWithoutWebshopCustomerID: '',
    //   });
    // }
    this.searchData.emit(searchData);
    this.searchStatus.emit(true);
    // productsWithoutWebshop=true&productsWithoutWebshopCustomerID=undefined&childCustomerID=1
  }

  customerSelect(data = null) {
    console.log("customerSelect");
    this.supplierDropdown();  // Supplier Dropdown load based on select customer
    let childCustomerID = null;
    if ((Number(this.searchForm.value.CustomerID) === 0)) {
      childCustomerID = this.webshopDetail.CustomerID;
    } else {
      if (this.searchForm.value.CustomerID !== this.webshopDetail.CustomerID) {
        childCustomerID = this.webshopDetail.CustomerID;
      }
    }

    /** For Product Category Prefill in Advanced Search Section - Start code */
    let selectCategoryLabel = '';
    this.translate.get('CATEGORIES.SELECT_PRODUCT_CATEGORY').subscribe((res: string) => {
      selectCategoryLabel = res;
    });
    // debugger
    let params = {};
    if (Number(this.searchForm.value.CustomerID) === 0) {
      params['childCustomerID'] = childCustomerID;
    }
    if (childCustomerID !== null && Number(this.searchForm.value.CustomerID) !== 0) {
      params['childCustomerID'] = childCustomerID;
    }
    if (this.searchForm.value.CustomerCreated) {
      params['CustomerCreated'] = this.searchForm.value.CustomerCreated;
    }
    if (this.searchForm.value.OnlyWithoutWebshop) {
      params['OnlyWithoutWebshop'] = this.searchForm.value.OnlyWithoutWebshop;
    }
    this.categoryHttpService.getCustomerCategoryList(this.searchForm.value.CustomerID, params).subscribe((data: any) => {
      if (data) {
        const categoryData = [{ label: selectCategoryLabel, value: '' }];
        data.forEach(option => {
          categoryData.push({ label: option.Name, value: option.CategoryID })
        });
        this.category = categoryData;
      }
    },
      err => {
        this.showLoader = false;
      });
    /** For Product Category Prefill in Advanced Search Section - End code */

    const selectSearchData = {
      productName: this.productName,
      Quantity: this.searchForm.value.quantity,
      CategoryID: this.searchForm.value.productCategory,
      SupplierID: this.searchForm.value.supplier,
      MaxDeliveryWeeks: this.searchForm.value.maxDeliveryWeeks,
      SizeID: this.searchForm.value.SizeID,
      PriceFrom: this.searchForm.value.priceFrom,
      PriceTo: this.searchForm.value.priceTo,
      // ColorID: this.searchForm.value.supplier,
      ReturnBatchProducts: this.searchForm.value.relatedProducts,
      CurrencyID: 2,
      CustomerID: (Number(this.searchForm.value.CustomerID) !== 0) ? this.searchForm.value.CustomerID : null,
      isAdmin: false,
      // childCustomerID: (Number(this.searchForm.value.CustomerID) === 0) ? loginData.CustomerID : '',
      childCustomerID: childCustomerID,
      customerID: (Number(this.searchForm.value.CustomerID) !== 0) ? this.searchForm.value.CustomerID : null,
      OnlyWithoutWebshop: this.searchForm.value.OnlyWithoutWebshop,
      // OnlyWithoutWebshopCustomerID: (this.searchForm.value.OnlyWithoutWebshop) ? this.searchForm.value.OnlyWithoutWebshopCustomerID : '',
      OnlyWithoutWebshopCustomerID: (this.searchForm.value.OnlyWithoutWebshop) ? this.webshopDetail.CustomerID : '',
      productStatusList: (this.searchForm.value.productStatusList !== '') ? this.searchForm.value.productStatusList : '',
      // tslint:disable-next-line:max-line-length
      productDeletedStatusList: (this.searchForm.value.productDeletedStatusList !== '') ? this.searchForm.value.productDeletedStatusList : '',
      OnlyInCategoriesOfSupplierID: this.searchForm.value.OnlyInCategoriesOfSupplierID,
      CustomerCreated: this.searchForm.value.CustomerCreated,
      ColorID: (this.selectedColorID !== '') ? Number(this.selectedColorID) : ''
    }

    /** If OnlyWithoutWebshop is disable then Reset OnlyWithoutWebshopCustomerID */
    // if (!this.searchForm.value.OnlyWithoutWebshop) {
    //   this.searchForm.patchValue({
    //     OnlyWithoutWebshopCustomerID: '',
    //   });
    // }
    this.selectSearchData.emit(selectSearchData)
  }

  resetForm() {
    // this.searchForm.reset();
    this.searchForm.patchValue({
      relatedProducts: true,
      quantity: '',
      productCategory: '',
      supplier: '',
      maxDeliveryWeeks: '',
      SizeID: '',
      priceFrom: '',
      priceTo: '',
      productStatusList: '',
      productDeletedStatusList: ''
    });
    this.selectedColorID = '';
    this.productName = '';
    this.searchText = '';
    this.searchproduct = [];
    this.autoComp.clear();
    this.autoComp.close();
    this.searchStatus.emit(false);
    this.customerSelect();
    this.getSupplierSizes();
  }

  populateCategoryList(CustomerId, childCustomerId = null) {
    console.log("populateCategoryList");
    let selectCategoryLabel = '';
    this.translate.get('CATEGORIES.SELECT_PRODUCT_CATEGORY').subscribe((res: string) => {
      selectCategoryLabel = res;
    });
    // debugger
    let params = {};
    if (Number(CustomerId) === 0) {
      CustomerId = null;
      params['childCustomerID'] = childCustomerId;
    }
    if (childCustomerId !== null && Number(CustomerId) !== 0) {
      params['childCustomerID'] = childCustomerId;
    }
    this.categoryHttpService.getCustomerCategoryList(CustomerId, params).subscribe((data: any) => {
      if (data) {
        const categoryData = [{ label: selectCategoryLabel, value: '' }];
        data.forEach(option => {
          categoryData.push({ label: option.Name, value: option.CategoryID })
        });
        this.category = categoryData;
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  autocomplete(value) {
    this.productName = value;
    if (value !== '') {
      this.categoryHttpService.searchProduct(value, this.searchForm.value.CustomerID).subscribe((data: any) => {
        if (data) {
          this.searchproduct = data;
        }
      },
        err => {
          this.showLoader = false;
        });
    }
  }

  selected(option) {
    this.productName = option.ProductName;
  }

  selectedSearch(key?) {
    if (key !== undefined) {
      this.productName = key.target.value;
    }
    this.search();
  }

  clearProductName() {
    this.productName = '';
  }
}
