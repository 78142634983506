import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ApiService } from '../../../shared/common-services/api.service';
import { ProductService } from '../../state/services/product.service';
import { LoginService } from '../../../login/state/services/login.service';
import { Router } from '@angular/router';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-product-basic-info',
  templateUrl: './product-basic-info.component.html',
  styleUrls: ['./product-basic-info.component.scss']
})
export class ProductBasicInfoComponent implements OnInit {
  @Input() productDetails: any;
  @Input() updateStatus: boolean;
  @Input() clonestatus: Boolean;
  @Input() siblingstatus: Boolean;
  @Input() customerProduct: any;
  @Input() productData: any;
  @Output() basicInfoFormData = new EventEmitter<any>();
  @Output() sendAdditionInfo = new EventEmitter<any>();
  addProductForm: FormGroup;
  customerlist: any;
  categoryList: any;
  currencyList: any;
  productUnitList: any[];
  categoryData: any;
  getSelectedCategoryObj: any;
  supplierList: any;
  showproductname: any = 'showproductNameEng';
  showmetaDescription: any = 'showmetaDescriptionEng';
  getCustomerId: any;
  getCategoryId: any = 0;
  productNameList: {}[];
  metaDescriptionList: {}[];
  h1NameList: {}[];
  productMaterialList: {}[];
  productDescriptionList: {}[];
  imageAlternativeTextList: {}[];
  productAdditionalDataList: {}[];
  productCardLabelList: {}[];
  activeLanguage: any = {};
  showh1Name: any = 'showh1NameEng';
  showproductMaterial: any = 'showproductMaterialEng';
  showproductDescription: any = 'showproductDescriptionEng';
  showimageAlternativeText: any = 'showimageAlternativeTextEng';
  showproductAdditionalData: any = 'showproductAdditionalDataEng';
  showproductCardLabel: any = 'showproductCardLabelEng';
  showManualValidation: boolean = false;
  getTranslateData: any = {};
  isEditScreen: boolean = true;
  additionalApi: Boolean = true;
  sendproductData: any;
  webshopDetail: any;
  sendproductDetails: any;
  constructor(public translate: TranslateService, private formbuilder: FormBuilder, private apiService: ApiService, private productService: ProductService, private loginService: LoginService, private router: Router, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.sendproductData = this.productData;
    const loginData = this.apiService.getLoginData();
    this.getCustomerId = this.webshopDetail.CustomerID;
  }

  ngOnChanges() {
    this.sendproductDetails = this.productDetails;
  }


  getSendBasic(data) {
    this.basicInfoFormData.emit(data);
  }

  getAdditionInfo(data) {
    this.sendAdditionInfo.emit(data);
  }


}
