import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { LoginService } from './login/state/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { LoginHttpService } from './login/state/services/login.http.service';
import * as CryptoJS from 'crypto-js';
import { Store } from '@ngrx/store';
import { LoginState } from './login/state/reducers/login.reducer';
import { getSubDomainName } from './login/state/reducers';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rewardkart-admin-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {



  loggedIn: any = false;
  showLoader: Boolean = false;
  loginData: any;
  webshopDetail: any;
  customerId = this.getParameterByName('customerId');
  subscriptionShow: boolean = false;
  additionalUser: boolean = false;
  hideLeftBar: boolean = false;
  token: string;
  signupPage: boolean = false;
  isSaas: boolean = false;
  loginDatas:any;
  constructor(private loginService: LoginService, private toast: ToastService, public router: Router, private loginServcie: LoginService, private translate: TranslateService, private activatedRoute: ActivatedRoute, private loginHttpService: LoginHttpService, private store: Store<LoginState>) {
    let userdetails = sessionStorage.getItem('userdetails');
    
    let fullurl = window.location.href;
    let url1 = this.getParameterByName('Status', fullurl);
    let url2 = this.getParameterByName('Message', fullurl);
    let url3 = this.getParameterByName('BankTrans', fullurl);
    let url4 = this.getParameterByName('AtomTrans', fullurl);

    sessionStorage.setItem('status', url1);
    sessionStorage.setItem('message', url2);
    sessionStorage.setItem('bankRefNo', url3);
    sessionStorage.setItem('atomID', url4);
    

    if (userdetails) {
      this.loginDatas = JSON.parse(userdetails)
      if(this.loginDatas && this.loginDatas.ISSaas === "Y"){
        this.isSaas = true;
      }
    }
    if(this.isSaas && url1 === "Y"){
      sessionStorage.setItem("SaasPayment",url1);
      sessionStorage.setItem('Saasmessage', url2);
      sessionStorage.setItem('SaasbankRefNo', url3);
      sessionStorage.setItem('SaasatomID', url4);
    }else if(this.isSaas && url1 !== "Y"){
      sessionStorage.setItem("SaasPayment",url1);
    }
    if (this.customerId) {
      sessionStorage.setItem('customerId', this.customerId);
    }
    if (sessionStorage.getItem('isParentLoginAsChildShop') !== null) {
      sessionStorage.removeItem('isParentLoginAsChildShop');

    }

    this.webshopLogo();
    this.loggedInAdmin();
    let selectedLang = sessionStorage.getItem('lang');
    if (selectedLang === null) {
      sessionStorage.setItem('lang', 'en');
    }

    translate.setDefaultLang('en');
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if ( event.id === 1 && event.url === event.urlAfterRedirects) {
          // Your code here for when the page is refreshd
          this.router.navigate(['/admin-login'])
          sessionStorage.removeItem('customerDetails');
          sessionStorage.removeItem('webshopDetail');
          sessionStorage.removeItem('userdetails');
          sessionStorage.removeItem('parentCustomerList');
          sessionStorage.removeItem('isParentLoginAsChildShop');
          if (!this.token) {
            sessionStorage.removeItem('customerId');
          }
        }
      })
  }



  ngOnInit() {
    // this.buildBreadCrumb('Dashboard')
  }


  getParameterByName(name, url = '') {
    if (url === '') url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  webshopLogo() {

    //const hostname = 'conxion.linkpartner-qatest.dk';  //for local
    const hostname = window.location.hostname; // for deploy 
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    if (urlParts !== null) {
      let getCust = hostname.replace(urlParts[0], '').slice(0, -1);
      this.loginHttpService.getCustomer(getCust).subscribe(
        (data1: any) => {
          //let webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'))
          //let webshoplanguage = this.loginService.loadwebshopLanguage(webshopDetail.CustomerID);
          //console.log("dashboard-lang 1" + webshopDetail.CustomerID)
          data1.CustomerID = this.customerId;
          if (data1.ParentCustomerID === undefined) {
            data1.ParentCustomerID = null;
            //console.log('ParentCustomerIDdata1', data1)

          }
          if (!this.loggedIn) {
            sessionStorage.setItem('webshopDetail', JSON.stringify(data1));
          }
          //console.log('ParentCustomerIDdata1', data1)
          // let webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'))
          // let webshoplanguage = this.loginService.loadwebshopLanguage(webshopDetail.CustomerID);
          // console.log("dashboard-lang" + webshopDetail.CustomerID)

        });
    }
  }

  loggedInAdmin() {
    this.activatedRoute.queryParams.subscribe(params => {
      /* ByePass Login Code */
      if (params.token !== undefined && params.token !== null) {
        const getTokenParam = this.getParameterByName('token');
        this.token = getTokenParam;
        this.customerId;
        if (getTokenParam !== null) {
          this.store.select(getSubDomainName).subscribe(subdomain => {
            if (subdomain !== 'nil') {

              // const crypto = require('crypto');
              // const key = 'xioej#12$f3xioej#12$f3xioej#12$w'; //replace with your key
              // const iv = 'pyrkju@os8tmj*8h'; //replace with your IV

              var key1 = CryptoJS.enc.Utf8.parse('xioej#12$f3xioej#12$f3xioej#12$w');
              var iv1 = CryptoJS.enc.Utf8.parse('pyrkju@os8tmj*8h');
              var decrypted = CryptoJS.AES.decrypt(params.token, key1, {
                keySize: 128 / 8,
                iv: iv1,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
              });

              const dec = decrypted.toString(CryptoJS.enc.Utf8);
              const check = dec.split('-');
              const currentDate = check[1] + '-' + check[0] + '-' + 'yfcd65cv8ew@';

              var key2 = CryptoJS.enc.Utf8.parse('xioej#12$f3xioej#12$f3xioej#12$w');
              var iv2 = CryptoJS.enc.Utf8.parse('pyrkju@os8tmj*8h');
              var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(currentDate), key2,
                {
                  keySize: 128 / 8,
                  iv: iv2,
                  mode: CryptoJS.mode.CBC,
                  padding: CryptoJS.pad.Pkcs7
                });

              const crypted = encrypted.toString();

              /* Decrypt Token which is from API response */
              // const decipher = crypto.createDecipheriv('aes256', key, iv);
              // let dec = decipher.update(params.token, 'base64', 'utf8');
              // dec += decipher.final('utf8');
              // console.log("DEcryption Token : ", dec);

              /* Encrypt Token Section */
              // const currentDate = moment().format("MM/DD/YYYY HH:mm:ss") + '-' + 'yfcd65cv8ew@';
              // const check = dec.split('-');
              // const currentDate = check[1] + '-' + check[0] + '-' + 'yfcd65cv8ew@';
              // console.log("Before Encryption Format is : ", currentDate);
              // const cipher = crypto.createCipheriv('aes256', key, iv);
              // let crypted = cipher.update(currentDate, 'utf8', 'base64');
              // crypted += cipher.final('base64');
              // console.log("Generate token : ", crypted);

              const postData = {
                ClientToken: crypted,
                OldToken: params.token
              };
              this.showLoader = true;
              this.loginHttpService.verifyToken(postData).subscribe(
                data1 => {
                  //sessionStorage.clear();
                  const selectedLang = sessionStorage.getItem('lang');
                  if (selectedLang === null) {
                    sessionStorage.setItem('lang', 'en');
                  }
                  this.loginData = data1;
                  this.showLoader = false;
                  this.getCustomer(this.customerId);
                  sessionStorage.setItem('isParentLoginAsChildShop', JSON.stringify(true));
                  sessionStorage.setItem('parentInChildShop', JSON.stringify(true));
                  sessionStorage.setItem('userdetails', JSON.stringify(data1));
                  // const hostname = 'conxion.linkpartner-qatest.dk';  //for local
                  const hostname = window.location.hostname; // for deploy 
                  const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
                  const urlParts = regexParse.exec(hostname);
                  if (urlParts !== null) {
                    const getCust = hostname.replace(urlParts[0], '').slice(0, -1);
                    this.loginHttpService.getCustomer(getCust).subscribe(
                      (data2: any) => {
                        if (data2.ParentCustomerID === undefined) {
                          data2.ParentCustomerID = null;
                        }
                        data2.CustomerID = this.customerId;
                        //console.log(data2);
                        sessionStorage.setItem('webshopDetail', JSON.stringify(data2));
                        let webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'))
                        let webshoplanguage = this.loginService.loadwebshopLanguage(webshopDetail.CustomerID);
                        //console.log("dashboard-lang 2" + webshopDetail.CustomerID)
                        if((webshopDetail.DomainName === "sw" || webshopDetail.DomainName === "smartworks") && this.loginData.Roles[0].RoleName === 'SmartWorksAdmin'){
                          this.router.navigate(['/admin-order-process/true'], { replaceUrl: false });
                        }else{
                          this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
                        }

                      });
                  }
                },
                (error) => {
                  this.showLoader = false;
                  //sessionStorage.clear();
                  let errMsg = '';
                  for (var key in error.error.ModelState) {
                    errMsg += error.error.ModelState[key] + ' ';
                  }
                  if (errMsg !== '') {
                    this.toast.error(errMsg);
                  }
                  if (error['status'] === 404) {
                    this.toast.error('Token Failure');
                  }
                }
              );
            }
          });
        }
        //console.log('childdddd')
        const webshop = sessionStorage.getItem('webshopDetail');
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        //console.log('childddddthis.webshopDetail', this.webshopDetail)
        let webshoplanguage = this.loginServcie.loadwebshopLanguage(this.webshopDetail.CustomerID);

      } else {
        if (sessionStorage.getItem('isParentLoginAsChildShop') !== null) {
          // if (sessionStorage.getItem('userdetails') !== null) {
          //   this.loginData = JSON.parse(sessionStorage.getItem('userdetails'));
          // }
          if (sessionStorage.getItem('parentInChildShop') === null) {
            this.loginData = null;
            this.loggedIn = false;
          }
          if (sessionStorage.getItem('parentInChildShop') !== null && sessionStorage.getItem('parentInChildShop') !== undefined) {
            sessionStorage.removeItem('parentInChildShop');
          }
        }
        /* Direct Login Code */
       /* if (sessionStorage.getItem('userdetails') && (this.subscriptionShow || this.additionalUser)) {
          this.loggedIn = true;
          let user = JSON.parse(sessionStorage.getItem('userdetails'))
          this.getCustomer(user.CustomerID);

          if (this.subscriptionShow) {
            let payment = 'subscription';
            this.router.navigate(['/admin-dashboard'], { state: { payment } })
          }
          if (this.additionalUser) {
            let payment = 'additionaluser';
            this.router.navigate(['/admin-dashboard'], { state: { payment } })
          }
        }
        else*/ if (sessionStorage.getItem('isParentLoginAsChildShop') === null) {
          this.loginServcie.loginSuccess().subscribe(data => {
            this.loginData = data;
            this.hideLeftBar = false;
            if (data && !data.IsSubScriptionValid && data.ISSaas !== "Y") {
              this.hideLeftBar = true;
              this.loggedIn = true;
            }
            else if (data && !data.IsSubScriptionValid && data.ISSaas === "Y") {
              // this.hideLeftBar = true;
              this.loggedIn = true;
            }
            else if (data && data.IsSubScriptionValid) {
              this.loggedIn = true;
            } else {
              this.loggedIn = false;
            }
          });
        } else {
          //console.log("login Data : ", this.loginData);
        }
      }
    });

    // this.loginServcie.loginSuccess().subscribe(data => {
    //   this.loginData = data;
    //   console.log("App Component : ", this.loginData);
    //   if (data) {
    //     this.loggedIn = true;
    //   } else {
    //     this.loggedIn = false;
    //   }
    // });

  }

  getCustomer(customerID) {
    let customer;
    this.loginServcie.getAllCustomers().subscribe(data => {
      if (data) {
        // this.router.navigate(['/admin-dashboard']);
        customer = data.find(x => Number(x.CustomerID) === Number(customerID));
        // sessionStorage.setItem('webshopDetail', JSON.stringify(customer));
      }
    });
    return customer;
  }

  getLoginStatus(data) {
    if (data) {
      this.loginData = null;
      if (sessionStorage.getItem('isParentLoginAsChildShop') !== null) {
        sessionStorage.removeItem('isParentLoginAsChildShop');
      }
    }
  }

  logout() {
    this.router.navigate(['/admin-login'], { replaceUrl: true });
  }

  signup($event){
    this.signupPage = true;
    this.router.navigate(['/admin-signup'], { replaceUrl: true });
  }

}

