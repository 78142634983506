import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductPropertiesComponent } from './product-properties.component';
import { ProductManagePropertiesModule } from './product-manage-properties/product-manage-properties.module'
import { ProductOptionsModule } from './product-options/product-options.module';
import { CustomdirectiveModule } from '../customdirective/customdirective.module';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    SharedModule,
    ProductManagePropertiesModule,
    ProductOptionsModule,
    CustomdirectiveModule,
    DropdownPopupModule
  ],
  declarations: [ProductPropertiesComponent],
  exports: [ProductPropertiesComponent]
})
export class ProductPropertiesModule { }