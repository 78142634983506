import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../state/services/user.service';
import { UserListHttpService } from '../state/services/user.http.service';
import { UserHttpService } from '../../user/state/services/user.http.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from '../../shared/common-services/api.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';

@Component({
  selector: 'rewardkart-admin-user-points-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})

export class UserPointsEditComponent implements OnInit {

  userPersonalDetails: FormGroup;
  isParentCustomer: Boolean = false;
  showLoader: Boolean = false;
  inputElement: ElementRef;
  date = new Date();
  showUserValidation: Boolean = false;
  newReward: Boolean = false;
  extraReward: Boolean = false;
  userId: any;
  optionsSelectCampaign: Array<any>;
  emailId: any;
  userHistory: any;
  totalBudget = 0;
  public campaignList = [];
  public datePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd-mm-yyyy',
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  };
  assignedCampaign: Array<any>;
  loginData: any;
  loginUserId: any;
  Roles = [];
  roleName: any;
  constructor(private userService: UserService, private formBuilder: FormBuilder, private toast: ToastService, public apiService: ApiService, public router: Router, private userHttpService: UserHttpService, private renderer: Renderer2,
    private translate: TranslateService, private userListHttpService: UserListHttpService) {
    this.loginData = this.apiService.getLoginData();
    const searchResult = sessionStorage.getItem('searchresult')
    this.emailId = history.state.data.Email;

    this.userHistory = history.state.data;
    if (searchResult !== null) {
      if (history.state.data !== undefined) {
        this.userId = history.state.data.UserID;
      }
    }
    if (this.loginData !== undefined && this.loginData !== null && this.loginData !== '') {
      this.loginUserId = this.loginData.UserID;
      this.Roles = this.loginData.Roles;
      this.roleName = this.Roles.map((data) => { return data.RoleName })
    }
    this.userHttpService.getBudget().subscribe((data: any) => {
      this.totalBudget = data;
    });
  }
  ngOnInit() {
    this.loadForm();
    this.getCampaign();
    //this.getMappedCampaign();
  }

  loadForm() {
    this.userPersonalDetails = this.formBuilder.group({
      rewardPoints: ['', Validators.required],
      description: ['', Validators.required],
      expiryDate: [''],
      selectCampaign: [null, Validators.required]
    })
  }


  validateFields() {
    let validateStatus = true;
    if (!this.userPersonalDetails.controls.rewardPoints.value) {
      this.userPersonalDetails.controls['rewardPoints'].markAllAsTouched();
      validateStatus = false;
    }
    if (!this.userPersonalDetails.controls.description.value) {
      this.userPersonalDetails.controls['description'].markAllAsTouched();
      validateStatus = false;
    }

    return validateStatus;
  }

  addPoints() {
    const validStatus = this.validateFields();

    if (!validStatus) {
      this.toast.error("Fill Mandatory Fields");
      return false;
    } else {
      let eDt = '';
      if (this.userPersonalDetails.value.expiryDate) {
        let d = this.userPersonalDetails.value.expiryDate.split('-');
        eDt = d[2] + '-' + d[1] + '-' + d[0] + ' 23:59:59';
      }


      if (this.roleName[0] !== "Super Admin" && (this.totalBudget < this.userPersonalDetails.value.rewardPoints)) {
        this.toast.error('Insufficient Funds');
        return false;
      }

      let updateData = {
        "UserId": this.userId,
        "RewardPoints": parseInt(this.userPersonalDetails.value.rewardPoints),
        "Desc": this.userPersonalDetails.value.description,
        "ExpairyDate": eDt,
        "CampaignId": this.userPersonalDetails.value.selectCampaign,
        "Email": this.emailId,
        "IsActive": 1,
        "Action": 'i'
      }
      this.showLoader = true;
      this.userListHttpService.updateUser(updateData).subscribe((data: any) => {
        if (data) {
          this.toast.success('Rewards Added');
          this.showLoader = false;
          //this.router.navigate(['/admin-user-points'], { replaceUrl: false });
          this.addCustomerBudgetTransaction();
        }
      }, error => {
        this.toast.error(error.error.Message);
        this.showLoader = false;
      })
    }
  }


  updatePoints() {
    const validStatus = this.validateFields();

    if (!validStatus) {
      this.toast.error("Fill Mandatory Fields");
      return false;
    } else {
      let eDt = '';
      if (this.userPersonalDetails.value.expiryDate) {
        let d = this.userPersonalDetails.value.expiryDate.split('-');
        eDt = d[2] + '-' + d[1] + '-' + d[0] + ' 23:59:59';
      }

      if (this.roleName[0] !== "Super Admin" && (this.totalBudget < this.userPersonalDetails.value.rewardPoints)) {
        this.toast.error('Insufficient Funds');
        return false;
      }

      let updateData = {
        "UserId": this.userId,
        "RewardPoints": parseInt(this.userPersonalDetails.value.rewardPoints),
        "Desc": this.userPersonalDetails.value.description,
        "ExpairyDate": eDt,
        "CampaignId": this.userPersonalDetails.value.selectCampaign,
        "Email": this.emailId,
        "IsActive": 1,
        "Action": 'i'
      }
      this.showLoader = true;
      this.userListHttpService.extraRewards(updateData).subscribe((data: any) => {
        if (data) {
          this.toast.success('Rewards Added');
          this.showLoader = false;
          //this.router.navigate(['/admin-user-points'], { replaceUrl: false });
          this.addCustomerBudgetTransaction();
        }
      }, error => {
        this.toast.error(error.error.Message);
        this.showLoader = false;
      })
    }
  }


  ngAfterViewInit(): void {
    if (this.inputElement != null) {
      this.renderer.listen(this.inputElement.nativeElement, 'paste', (event) => {
        // handle the event
      });
    }
  }

  ispasteFunc(_event: ClipboardEvent, formControlName) {
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode < 48 || charCode > 58)) {
      return false;
    }
    return true;
  }

  getUserData() {
    this.userListHttpService.getUserData(this.userId).subscribe((data: any) => {
      if (data) {
        this.userPersonalDetails = this.formBuilder.group({
          rewardPoints: [data[0].AvailableCredit !== undefined ? data[0].AvailableCredit : '', Validators.required],
          description: [data[0].Description !== undefined ? data[0].Description : '', Validators.required],
          expiryDate: [data[0].ExpairyDate !== undefined ? data[0].ExpairyDate : ''],
          selectCampaign: [data[0].CampaignId !== undefined ? data[0].CampaignId : null, Validators.required]
        });
      }
    })
  }

  getCampaign() {
    this.userListHttpService.getCampaignData().subscribe((data: any) => {
      if (data) {
        this.optionsSelectCampaign = [];
        data.forEach(element => {
          if (element.Status === 'Aproved') {
            const campaignVal = {
              value: element.CampaignId,
              label: element.CampaignName,
            };
            this.optionsSelectCampaign.push(campaignVal);
          }
        });
        this.getMappedCampaign();
      }
      //this.getUserData();
    }, error => {
      //this.getUserData();
    })
  }


  getMappedCampaign() {
    this.userListHttpService.mappedUserRewardList(this.userId).subscribe((data: any) => {
      if (data) {
        this.campaignList = data;

        let a1 = this.optionsSelectCampaign;
        let selected = [];
        this.assignedCampaign = [];
        data.map(function (item) {
          let a = item.CampaignName.split('-');
          for (let i = 0; i < a1.length; i++) {
            if (a[0].trim() === a1[i].label.trim()) {
              selected.push({ label: a1[i].label, value: a1[i].value })
            }
          }
        });
        this.assignedCampaign = selected;

      }
    }, _error => {
    });
  }

  showNewReward() {
    this.newReward = true;
    this.extraReward = false;
  }

  shwoExtraReward() {
    this.newReward = false;
    this.extraReward = true;
  }

  addCustomerBudgetTransaction() {

    const addCustomerBudgetData = {
      "UserID": Number(this.userId),
      "CurrencyID": '20',
      "AmountCredit": Number(this.userPersonalDetails.value.rewardPoints),
      "TransactionDate": this.dateFormatchange(new Date()),
      "Spent": " Credit By " + this.userId,
      "AssignedUserID": Number(this.loginUserId),
      "IsActive": 1,
      "Balance": this.totalBudget ? (Number(this.totalBudget) + Number(this.userPersonalDetails.value.rewardPoints)) : Number(this.userPersonalDetails.value.rewardPoints),
      "AmountDebit": 0,
    }

    this.showLoader = true;
    this.userHttpService.addUserbudgetTransaction(addCustomerBudgetData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.addUserBudgetTransaction()
      }
      else {
        this.toast.error(data.message);
      }
    }, err => {
      this.showLoader = false;
      this.toast.error(err.error.Message);
    });
  }

  addUserBudgetTransaction() {
    let balance: number = 0;

    if (this.roleName[0] === "Super Admin") {
      balance = 0;
    } else {
      balance = Number(this.totalBudget) - Number(this.userPersonalDetails.value.rewardPoints)
    }
    const addUserBudgetData1 = {
      "UserID": Number(this.loginUserId),
      "CurrencyID": '20',
      "AmountCredit": 0,
      "TransactionDate": this.dateFormatchange(new Date()),
      "Spent": " Add to " + this.userId,
      "AssignedUserID": Number(this.userId),
      "IsActive": 1,
      "Balance": balance,
      "AmountDebit": Number(this.userPersonalDetails.value.rewardPoints),
    }
    this.totalBudget = this.totalBudget - this.userPersonalDetails.value.rewardPoints;
    this.userHttpService.setBudget(this.totalBudget);
    this.showLoader = true;
    this.userHttpService.addUserbudgetTransaction(addUserBudgetData1).subscribe((res: any) => {
      this.showLoader = false;
      if (res) {
        this.loadForm();
        this.toast.success(res);
        this.router.navigate(['/admin-user-points'], { replaceUrl: false });
      }
      else {
        this.toast.error(res.message);
      }
    }, err => {
      this.showLoader = false;
      this.toast.error(err.error.Message);
    });
  }

  dateFormatchange(getDate) {
    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    return myDate;
  }

}
