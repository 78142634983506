import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule, Actions } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardComponent } from './dashboard.component';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { FormsModule } from '@angular/forms';
import { WidgetModalModule } from '../widget-modal/widget-modal.module';
import { DashboardOrderModule } from './dashboard-order/dashboard-order.module';
import { DashboardProductionlineModule } from './dashboard-productionline/dashboard-productionline.module';
import { DashboardMessageModule } from './dashboard-message/dashboard-message.module';
import { DashboardStockAlarmModule } from './dashboard-stock-alarms/dashboard-stockalarm.module';
import { DashboardOrderFlowModule } from './dashboard-orderflow/dashboard-orderflow.module';
import { DashboardProductComponent } from './dashboard-product/dashboard-product.component';
import { DashboardProductModule } from './dashboard-product/dashboard-product.module';
import { dashboardReducers } from './state/reducers/dashboard.reducer';
import { DashboardHttpService } from './state/services/dashboard.http.service';
import { DashboardService } from './state/services/dashboard.service';
import { DashboardEffects } from './state/effects';
import { DashboardPendingUserModule } from './dashboard-pendinguser/dashboard-product.module';
import { DashboardLikedproductsModule } from './dashboard-likedproduct/dashboard-likedproduct.module';
import { DashboardOrderNotificationAlarmModule } from './dashboard-order-notification-alarm/dashboard-order-notification-alarm.module';
import { DashboardInactiveUsersModule } from './dashboard-inactive-users/dashboard-inactive-users.module';
import { DashboardBudgetReportModule } from './dashboard-budget-report/dashboard-budget-report.module';
import { DashboardUserManagementReportModule } from './dashboard-user-management-report/dashboard-user-management-report.module'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    WidgetModalModule,
    DashboardOrderModule,
    DashboardProductionlineModule,
    DashboardMessageModule,
    DashboardStockAlarmModule,
    DashboardOrderFlowModule,
    DashboardBudgetReportModule,
    DashboardUserManagementReportModule,
    DashboardProductModule,
    DashboardPendingUserModule,
    DashboardLikedproductsModule,
    DashboardOrderNotificationAlarmModule,
    DashboardInactiveUsersModule,
    EffectsModule.forFeature([DashboardEffects]),
    StoreModule.forFeature('dashboardModule', dashboardReducers)
  ],
  declarations: [DashboardComponent],
  providers: [DashboardHttpService, DashboardService],
  exports: [DashboardComponent],
})

export class DashboardModule { }
