import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/common-services/api.service';
import { DashboardService } from '../state/services/dashboard.service';
import { DashboardHttpService } from '../state/services/dashboard.http.service';
import { TranslateService } from '@ngx-translate/core';
import { IMyOptions } from 'ng-uikit-pro-standard/lib/pro/date-picker/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-orderflow',
  templateUrl: './dashboard-orderflow.component.html',
  styleUrls: ['./dashboard-orderflow.component.scss']
})
export class DashboardOrderflowComponent implements OnInit {
  public chartType: string = 'line';

  public chartDatasets: any;

  public chartLabels: Array<any> = [];

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(0, 137, 132, .2)',
      borderColor: 'rgba(0, 10, 130, .7)',
      borderWidth: 2,
    }
  ];
  public chartOptions: any = {
    responsive: true
  };
  public orderFlowList: any = [];
  webshop: any;
  childShopData: any;
  totalCount: any;
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
  orderFlowQueryParams: any = {};
  startDate: any = '';
  endDate: any = '';
  date = new Date();
  datePickerOptions: IMyOptions = {};
  enddatePickerOptions: IMyOptions = {};
  public colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#1DA1F2']
  };
  public pieChartData: any = [];
  public position: any = "bottom";
  showLoader: boolean = false;
  isParentCustomer: boolean = true;
  customer: any;
  constructor(private router: Router, public dashboardhttpservice: DashboardHttpService, public apiService: ApiService, public dashboardservice: DashboardService, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshop.ParentCustomerID !== null) {
        this.isParentCustomer = false;
      }
      else {
        this.isParentCustomer = true;
      }
      this.customer = this.webshop.CustomerID;
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshop.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();
  }

  ngOnInit() {
    this.getDashboardOrderFlowList();
    let dt = new Date();
    const disableDate = new Date(dt.setDate(dt.getDate() + 1));
    const startDate = new Date(dt.setDate(dt.getDate() - 7));
    this.startDate = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
    this.endDate = this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear();
    this.datePickerOptions = {
      closeAfterSelect: true,
      dateFormat: 'dd/mm/yyyy',
      disableSince: {
        year: disableDate.getFullYear(),
        month: disableDate.getMonth() + 1,
        day: disableDate.getDate()
      },
    };
    this.enddatePickerOptions = {
      closeAfterSelect: true,
      dateFormat: 'dd/mm/yyyy',
      disableSince: {
        year: disableDate.getFullYear(),
        month: disableDate.getMonth() + 1,
        day: disableDate.getDate()
      },
      disableUntil: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate()
      },
    };
    this.getReport();
  }

  onLoadFunctions() {
    this.orderFlowQueryParams.customerID = this.webshop.CustomerID;
    this.dashboardservice.loadOrderFlow(this.orderFlowQueryParams);
  }

  getDashboardOrderFlowList() {
    this.dashboardservice.getOrderFlow().subscribe((getdata: any) => {
      if (getdata) {
        this.orderFlowList = getdata;
        let nooforders = []
        let dateList = []
        getdata.forEach(element => {
          nooforders.push(element.NumberOfOrders);
          dateList.push(element.Month + '-' + element.Year);
        });
        this.chartDatasets = [{ 'data': nooforders, 'label': this.translate.instant('DASHBOARD.ORDERFLOW') }]
        this.chartLabels = dateList;
      }
    },
      _err => {
        // console.log(err);
      });
  }

  dateChange(event) {
    this.enddatePickerOptions = {
      closeAfterSelect: true,
      dateFormat: 'dd/mm/yyyy',
      disableUntil: {
        year: event.date.year,
        month: event.date.month,
        day: event.date.day,
      },
    };
  }

  getReport() {
    this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.showLoader = true;
    this.customer = this.webshop.CustomerID;
    this.dashboardhttpservice.getChildShops(this.webshop.CustomerID, this.startDate, this.endDate).subscribe((data) => {
      this.childShopData = data;
      this.pieChartData = [];
      this.showLoader = false;
      const keys = Object.keys(data);
      keys.forEach((key: any, index: any) => {
        this.totalCount = this.totalCount + data[index].TotalOrdersCount;
        this.pieChartData.push({ name: data[index].Name, value: data[index].TotalOrdersCount })
      })
    }, (error) => {
      this.showLoader = false;
    })
  }

  pieChartLabel(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  getSummary(id) {
    let data = {
      id: id,
      startDate: this.startDate,
      endDate: this.endDate
    }
    this.router.navigate(['/admin-company-order-summary'], { state: { data }, replaceUrl: false });
  }

}
