import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormModule } from 'src/app/common/common-form/common-form.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductManageLogoPositionsComponent } from './product-manage-logo-positions.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule ,
    CommonFormModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [ProductManageLogoPositionsComponent],
  exports: [ProductManageLogoPositionsComponent]
})

export class ProductManageLogopositionsModule { }
  