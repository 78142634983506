import { Component, OnInit } from '@angular/core';
import { ReportsHttpService } from '../services/reports-http.service';
import { ReportAbstract } from '../services/report-abstract';
import { ToastService } from 'ng-uikit-pro-standard';
@Component({
    selector: 'rewardkart-admin-report-products-search',
    templateUrl: './report-products-search.component.html',
    styleUrls: ['./report-products-search.component.scss']
})
export class ReportProductsSearchComponent extends ReportAbstract
    implements OnInit {
    header = 'Products Search';
    excludeKeyInUrlParam = [];
    noProductFoundText = 'No orders found.';
    downloadReport = 'Download Report';
    dateFrom = '';
    dateTo = '';
    customerList = [];
    customerLoader = false;
    selectedCustomerID = '';
    filterFields: any = [];
    commonRemoveFilter: any;
    webshopDetail: any;
    constructor(public reportHttpService: ReportsHttpService, private toaster: ToastService) {
        super();
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
    }

    ngOnInit() {
        this.getCustomers();
    }

    getCustomers() {
        this.customerLoader = true;
        this.reportHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
            (customersRes: any) => {
                if (customersRes) {
                    this.selectedCustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
                    const customerlist = [{ label: 'Select All', value: this.webshopDetail.CustomerID }];
                    customersRes.forEach(cust => {
                        let custData = {
                            label: cust.Name,
                            value: cust.CustomerID
                        }
                        customerlist.push(custData);
                    });
                    this.customerList = customerlist;
                    this.formFields();
                }
                this.customerLoader = false;
            },
            error => {
                this.customerLoader = false;
                this.toaster.error('unable to get webshop customers');
            }
        );
    }

    formFields() {
        this.filterFields = [
            {
                label: 'Filter',
                class: 'col-md-12 pl-3 pt-4',
                key: 'filterHeading',
                filterkey: ''
            }];
        const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
        if (getCustomerDetails !== null) {
            if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
                this.filterFields.push(
                    {
                        label: 'Select Webshop',
                        type: 'select',
                        data: this.customerList,
                        class: 'col-md-12 no-icon',
                        key: 'CustomerID',
                        filterkey: 'CustomerID',
                        selected: this.selectedCustomerID
                    }
                );
            }
        }
        this.filterFields.push(
            {
                label: 'Date From ',
                type: 'date',
                class: 'col-md-12',
                key: 'dateFrom',
                default: '',
                selected: '',
                required: true,
                format: {
                    closeAfterSelect: true,
                    dateFormat: 'dd/mm/yyyy'
                },
                filterkey: 'dateFrom'
            },
            {
                label: 'Date To',
                type: 'date',
                class: 'col-md-12',
                key: 'dateTo',
                default: '',
                selected: '',
                required: true,
                format: {
                    dateFormat: 'dd/mm/yyyy',
                    closeAfterSelect: true,
                },
                filterkey: 'dateTo'
            },
            {
                label: 'Clear',
                type: 'button',
                class: 'col-sm-6 text-lefts mt-3 margin-width',
                key: 'clear',
            },
            {
                label: 'Search',
                type: 'button',
                class: 'col-sm-6 text-left mt-3 margin-width',
                key: 'button',
            }
        );
    }

    buildReportURL(parameter) {
        return `en/reports/SearchPhrasesReport?${this.selectedCustomerID}${this.dateFrom}${this.dateTo}`;
    }

    getSelectData(filterResult) {
        const { dateFrom, dateTo, CustomerID } = filterResult;
        if (dateFrom) {
            const formatedDate = this.reportHttpService.dateFormatchange(dateFrom.value);
            this.dateFrom = `&${dateFrom.selectMethod}=${formatedDate}`;
        }
        if (dateTo) {
            const formatedDate = this.reportHttpService.dateFormatchange(dateTo.value);
            this.dateTo = `&${dateTo.selectMethod}=${formatedDate}`;
        }
        if (CustomerID && CustomerID.value !== '') {
            this.selectedCustomerID = `${CustomerID.selectMethod}=${CustomerID.value}`;
        }
    }

    handleResetForm(event) {
        this.filterFields.forEach(element => {
            event[element.key].value = '';
        });
        this.dateFrom = '';
        this.dateTo = '';
        if (this.reportData && this.reportData != null) {
            this.reportData = null;
        }
    }
}
