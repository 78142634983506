import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MessageBoxComponent } from './message-box.component'
import { MessageClaimsComponent } from './message-claims/message-claims.component'
import { MessageClaimsdetailsComponent } from './message-claims/message-claimsdetails/message-claimsdetails.component'
import { MessageComposeComponent } from './message-compose/message-compose.component'
import { MessageDraftComponent } from './message-draft/message-draft.component'
import { MessageDraftdetailsComponent } from './message-draft/message-draftdetails/message-draftdetails.component'
import { MessageImportantComponent } from './message-important/message-important.component'
import { MessageImportantdetailsComponent } from './message-important/message-importantdetails/message-importantdetails.component'
import { MessageInboxComponent } from './message-inbox/message-inbox.component'
import { MessageInboxdetailsComponent } from './message-inbox/message-inboxdetails/message-inboxdetails.component'
import { MessageInquiriesComponent } from './message-inquiries/message-inquiries.component'
import { MessageInquiredetailsComponent } from './message-inquiries/message-inquiredetails/message-inquiredetails.component'
import { MessageSentmessageComponent } from './message-sentmessage/message-sentmessage.component'
import { SentmessagedetailsComponent } from './message-sentmessage/sentmessagedetails/sentmessagedetails.component'
import { MessageTrashComponent } from './message-trash/message-trash.component'
import { MessageTrashdetailsComponent } from './message-trash/message-trashdetails/message-trashdetails.component'

const routes: Routes = [
  {
    path: '',
    component: MessageBoxComponent,
    data: {  },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'app-message-inbox', data: { breadcrumb1: 'Inbox' } },
      { path: 'app-message-claims', component: MessageClaimsComponent, data: { breadcrumb: 'Claims' } },
      { path: 'app-message-claims/app-message-claimsdetails', component: MessageClaimsdetailsComponent, data: { breadcrumb: 'Claims Details' } },
      { path: 'app-message-compose', component: MessageComposeComponent, data: { breadcrumb: 'Compose' } },
      { path: 'app-message-draft', component: MessageDraftComponent, data: { breadcrumb: 'Draft' } },
      { path: 'app-message-draftdetails', component: MessageDraftdetailsComponent, data: { breadcrumb: 'Draft Details' } },
      { path: 'app-message-important', component: MessageImportantComponent, data: { breadcrumb: 'Important' } },
      { path: 'app-message-importantdetails', component: MessageImportantdetailsComponent, data: { breadcrumb: 'Important Details' } },
      { path: 'app-message-inbox', component: MessageInboxComponent, data: { breadcrumb: 'Inbox' } },
      { path: 'app-message-inboxdetails', component: MessageInboxdetailsComponent, data: { breadcrumb: 'Inbox Details' } },
      { path: 'app-message-inquiries', component: MessageInquiriesComponent, data: { breadcrumb: 'Inquiries' } },
      { path: 'app-message-inquiredetails', component: MessageInquiredetailsComponent, data: { breadcrumb: 'Inquiries Details' } },
      { path: 'app-message-sentmessage', component: MessageSentmessageComponent, data: { breadcrumb: 'Sent Message' } },
      { path: 'app-sentmessagedetails', component: SentmessagedetailsComponent, data: { breadcrumb: 'Sent Message Details' } },
      { path: 'app-message-trash', component: MessageTrashComponent, data: { breadcrumb: 'Trash' } },
      { path: 'app-message-trashdetails', component: MessageTrashdetailsComponent, data: { breadcrumb: 'Trash Details' } },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessageBoxRoutingModule { } 