import { Component, OnInit, OnChanges, Output, EventEmitter, Input, ViewChild, HostListener } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { } from 'events';
import { ProductService } from '../../state/services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ProductHttpService } from '../../state/services/product.http.service';

@Component({
  selector: 'app-product-additional-info',
  templateUrl: './product-additional-info.component.html',
  styleUrls: ['./product-additional-info.component.scss']
})
export class ProductAdditionalInfoComponent implements OnInit, OnChanges {
  @ViewChild('datePicker', { static: false }) datePicker: MDBDatePickerComponent;
  date = new Date()
  @HostListener('keydown.esc')
  handleInput() {
    if (this.datePicker.isOpen) {
      this.datePicker.closeBtnClicked();
    }
  }
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
    inputAutoFill: false,
    markCurrentDay: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  }
  productAdditionalInfo: FormGroup;
  optionsUnderSupplier: any;
  model: any;
  webshopDetail: any;
  @Input() productDetails: any;
  // myDatePickerOptions: any;
  @Input() loadApi: any;

  @Output() sendAdditionInfo = new EventEmitter<any>();
  productCustID: any;
  constructor(public formBuilder: FormBuilder,
    public translate: TranslateService,
    public productService: ProductService, private apiService: ApiService, private productHttpService: ProductHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

    const getCustID = sessionStorage.getItem('productCustomerID');
    if (getCustID !== undefined && getCustID !== null && getCustID !== '') {
      this.productCustID = getCustID;
    }
    else {
      this.productCustID = this.webshopDetail.CustomerID;
    }

  }

  ngOnChanges(changes: any): void {
    if (this.loadApi === true) {
      this.getUnderSuppliers();
    }
    this.productDetails = this.productDetails;
    this.loadForm();
  }



  ngOnInit() {

    this.loadForm();

    this.productAdditionalInfo.valueChanges.subscribe(() => {
      this.sendAdditionInfo.emit(this.productAdditionalInfo.value);
    });
    this.sendAdditionInfo.emit(this.productAdditionalInfo.value);
  }

  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }

  dontAllowDecimal(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

  loadForm() {
    debugger
    this.productAdditionalInfo = this.formBuilder.group({
      linkToVideo: [this.productDetails !== undefined ? this.productDetails.LinkToVideo : '', Validators.required],
      unitHeight: [this.productDetails !== undefined ? this.productDetails.UnitHeight : '', Validators.required],
      unitWidth: [this.productDetails !== undefined ? this.productDetails.UnitWidth : '', Validators.required],
      unitDepth: [this.productDetails !== undefined ? this.productDetails.UnitDepth : '', Validators.required],
      unitsPerCarton: [this.productDetails !== undefined ? this.productDetails.UnitsPerCarton : '', Validators.required],
      cartonHeight: [this.productDetails !== undefined ? this.productDetails.CartonHeight : '', Validators.required],
      cartonWidth: [this.productDetails !== undefined ? this.productDetails.CartonWidth : '', Validators.required],
      cartonDepth: [this.productDetails !== undefined ? this.productDetails.CartonDepth : '', Validators.required],
      cartonWeight: [this.productDetails !== undefined ? this.productDetails.CartonWeight : '', Validators.required],
      unitsOnPallet: [this.productDetails !== undefined ? this.productDetails.UnitsOnPallet : '', Validators.required],
      // underSupplierProductID: [this.productDetails !== undefined ? this.productDetails.UndersupplierProductID : '', Validators.required],
      // underSupplier: [this.productDetails !== undefined ? this.productDetails.UndersupplierID : '', Validators.required],
      discontinuedDate: [this.productDetails.DiscontinuedDate !== undefined ? this.getDateFormat(this.productDetails.DiscontinuedDate) : '', Validators.required],
      updateReminderDate: [this.productDetails.UpdateReminderDate !== undefined ? this.getDateFormat(this.productDetails.UpdateReminderDate) : '', Validators.required],
      productNewEndDate: [this.productDetails.ProductNewEndDate !== undefined ? this.getDateFormat(this.productDetails.ProductNewEndDate) : '', Validators.required],
      productFeaturedEndDate: [this.productDetails.ProductFeaturedEndDate !== undefined ? this.getDateFormat(this.productDetails.ProductFeaturedEndDate) : '', Validators.required],
      productDiscountPercentage: [this.productDetails !== undefined ? this.productDetails.ProductDiscountPercentage : '', Validators.required],
      productDiscountEndDate: [this.productDetails.ProductDiscountEndDate !== undefined ? this.getDateFormat(this.productDetails.ProductDiscountEndDate) : '', Validators.required],
    })
  }

  datetest() {
    const disconDate = new Date(this.productAdditionalInfo.value.discontinuedDate);
  }


  getUnderSuppliers() {
    // this.productService.loadUnderSuppliers();
    // const loginData = this.apiService.getLoginData();
    this.productHttpService.getUnderSuppliers(this.productCustID).subscribe((data: any) => {
      if (data) {
        this.optionsUnderSupplier = [];
        data.ItemsCollection.forEach(element => {
          const customerVal = {
            value: element.UndersupplierID,
            label: element.Name,
          };
          this.optionsUnderSupplier.push(customerVal);
        });
      }
    });
  }

  formValueChange(val) {
  }

}
