import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ControlValidationService } from './control-validation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-control-validation',
  templateUrl: './control-validation.component.html',
  styleUrls: ['./control-validation.component.scss']
})
export class ControlValidationComponent implements OnInit {
  @Input() control: FormControl;
  resultVal: any;
  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        this.translate.get('ERRORS.' + ControlValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName])).subscribe((res: string) => {
          this.resultVal = JSON.stringify(res);
          this.resultVal = JSON.parse(this.resultVal);
        });
        return this.resultVal;
      }
    }
    return null
  }
}
