import { Component, Input, OnInit } from "@angular/core";
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
    selector: 'rewardkart-admin-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit{
    landingPageImageUrl: any;
    @Input() webshopData: any = '';
    landingPageImageName: any;
    webshopDetail: any;
    constructor(private _http: WebshopHttpService, private toastr: ToastService){
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if(this.webshopDetail.ShopImageUrl){
            this.landingPageImageUrl = this.webshopDetail.ShopImageUrl;
        }
    }
    ngOnInit() {
        
    }

    handleUploadedImage(event) {
        const { data, error } = event;
        if (error) {
          //console.log('unable to upload image', event.error);
        } else {
          this.landingPageImageUrl = data[2] || '';
          if(data[2]){
                this.landingPageImageName = data[1];
                this.landingPageImageUrl = data[2];
            }
            
        }
    }

    upldImage(){
        this._http.imgUpld(this.landingPageImageName).subscribe((data)=>{
            if(data){
                this.toastr.success("Image Updated Successfully.")
            }
        });
    }
      
}