import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colorssetting',
  templateUrl: './colorssetting.component.html',
  styleUrls: ['./colorssetting.component.scss']
})
export class ColorssettingComponent implements OnInit {
  resetColorModal: boolean = false;
  productDetails: any = {};  
  productColorForm:any = []
  constructor() { }

  ngOnInit() {
    this.productDetails.SupplierID = 1;
  }


  resetColor() {
    this.resetColorModal = true;
    setTimeout(() => {
      this.resetColorModal = false;
    }, 1000)
  }

  resetDropDown(){}

}
