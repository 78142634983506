
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ElementRef, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { LoginHttpService } from '../login/state/services/login.http.service';
import { LoginService } from '../login/state/services/login.service';
import { ApiService } from '../shared/common-services/api.service';


@Component({
  selector: 'app-left-sidbar',
  templateUrl: './left-sidbar.component.html',
  styleUrls: ['./left-sidbar.component.scss']
})
export class LeftSidbarComponent implements OnInit {
  appLogo: any;
  title: any;
  config: any;
  loggedIn: any = true;
  loggedInUser: any;
  customerImage: any;
  logoName: any;
  selectedMenuItem: any = { itemName: '', subItemName: '' };
  navIsClosed: any = false;
  dashboard = 'DASHBOARD';
  inbox = 'INBOX';
  blocks = 'BLOCKS';
  orders = 'ORDERS';
  users = 'USERS';
  products = 'PRODUCTS';
  webshops = 'WEBSHOPS';
  category = 'CATEGORY';
  settings = 'SETTINGS';
  reports = 'REPORTS';
  email = 'EMAILCONFIG'
  categoryDetails = 'Category List';
  categoryCopy = 'Category Copy';
  dispatchedProducts = 'Dispatched Products';
  profitCenter = 'Profit Center';
  shopDetails = 'Shop Details';
  paymentDetails: any = {className: 'd-none', subsubItems: []};
  socialMedia = 'Social Media';
  webshopTheme = 'Webshop Theme';
  billboardImages = 'Billboard';
  publishSettings = 'Publish Settings';
  changePassword = 'Change Password';
  suppliers = 'SUPPLIERS';
  billboard = 'BILLBOARD';
  webshopcurrencies = 'Webshop Currencies'
  enableWebshopDropdown: any = { className: 'd-none', subsubItems: [] };
  enableOpenBookDropdown: any = { className: 'd-none', subsubItems: [] };
  webshopMenu: any = { className: 'd-none', subsubItems: [] };
  parentshopMenu: any = { className: 'd-none', subsubItems: [] };
  subscriptionMenu: any = { className: 'd-none', subsubItems: [] };
  userPointsMenu: any = { className: 'd-none', subsubItems: [] };
  campaignMenu: any = { className: 'd-none', subsubItems: [] };
  childShopMenu: any = { className: 'd-none', subsubItems: [] };

  status: boolean = false;
  isSpringboard: boolean;
  isHrm: boolean = false;
  clickEvent() {
    this._ren.addClass(this._el.nativeElement, 'hide-labels');
    setTimeout(() => {
      this._ren.removeClass(this._el.nativeElement, 'hide-labels');
    }, 250);
    this.status = !this.status;
    if (this.status == true) {
      this._ren.addClass(this._el.nativeElement, 'menu-sm');
      this._ren.removeClass(this._el.nativeElement, 'menu-lg');
    } else {
      this._ren.removeClass(this._el.nativeElement, 'menu-sm');
      this._ren.addClass(this._el.nativeElement, 'menu-lg');
    }
  }
  selectedLang: any = sessionStorage.getItem('lang');

  menuItems: any = [];
  webshopDetail: any;
  userDetails: any;
  isSmartworks: boolean = false;
  isSaas: boolean = false;
  planName: string = "";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private _ren: Renderer2,
    private _el: ElementRef,
    public apiservice: ApiService,
    private loginServcie: LoginService,
    private loginHttpService: LoginHttpService
  ) {
    this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.userDetails = JSON.parse(sessionStorage.getItem('userdetails'));
    let isParentCustomer = false;
    if(this.webshopDetail.DomainName === "sw" || this.webshopDetail.DomainName === "smartworks"){
      this.isSmartworks = true;
    }
    if(this.webshopDetail.DomainName === "91sb" || this.webshopDetail.DomainName === "91springbaord"){
      this.isSpringboard = true;
    }
    if(this.webshopDetail.DomainName === "247hrm"){
      this.isHrm = true;
    }
    if(this.userDetails.ISSaas === "Y"){
      this.isSaas = true;
      this.planName = this.userDetails.SubScriptionPlanName;
    }
    if (this.webshopDetail !== null && this.webshopDetail !== undefined && this.webshopDetail !== '' && this.webshopDetail !== 'undefined') {
     
      // if (this.webshopDetail.ParentCustomerID !== null) {
      //   isParentCustomer = false;
      // }
      if (this.webshopDetail.ParentCustomerDomainName === this.webshopDetail.DomainName) {
        isParentCustomer = true;
      }
    }
    if (isParentCustomer) {
      /** Settings EPIC =>  Enable Webshop dropdown in Settings Tab */
      this.enableWebshopDropdown = {
        subItemName: 'Admin Settings',
        link: 'settings-webshop',
        className: 'd-block',
        subsubItems: []
      };
      if(this.webshopDetail.DomainName === "rewardkart"){
        this.paymentDetails = {
          itemName: 'User Payment Details',
          iconName: 'assets/icons/Blocks.svg',
          subItems: [],
          link: 'admin-user-payment'
        };
      }

      /** Webshop EPIC => Webshop menu enable based on Parent Customer Login */
      translate.get('MENU', {}).subscribe((res: any) => {
        this.webshopMenu = {
          itemName: res.WEBSHOPS,
          iconName: 'assets/icons/Webshops.svg',
          subItems: [],
          link: 'admin-webshop-list',
          subsubItems: []
        };
        /*if (user.Roles[0].Code !== 'Super_Admin') {
          this.subscriptionMenu = {
            itemName: 'Subscription',
            iconName: 'assets/icons/Inbox.svg',
            subItems: [],
            link: 'admin-subscription'
          };
        }*/
      });
    } else {
      translate.get('MENU', {}).subscribe((res: any) => {
        this.userPointsMenu = {
          itemName: res.REWARDPOINTS,
          iconName: 'assets/icons/users.svg',
          subItems: [],
          link: 'admin-user-points'
        };
        this.childShopMenu = {
          itemName: res.CHILDSHOP,
          iconName: 'assets/icons/Blocks.svg',
          subItems: [],
          link: 'admin-childshop-list'
        };
      });
    }

    /*if (user.Roles[0].Code === 'Super_Admin') {
      this.parentshopMenu = {
        itemName: 'Parent Shop',
        iconName: 'assets/icons/Webshops.svg',
        subItems: [],
        link: 'admin-parentshop-list'
      }
    }*/

    /** Settings EPIC => Open-Book menu enable based on Parent Customer Login into Child Shops from Webshop List */
    const isParentLogin = sessionStorage.getItem('isParentLoginAsChildShop');
    let isParentLoginAsChildShop = false;
    if (isParentLogin !== null && isParentLogin !== undefined && isParentLogin !== '' && isParentLogin !== 'undefined') {
      isParentLoginAsChildShop = true;
    }
    if (isParentLoginAsChildShop) {
      this.enableOpenBookDropdown = {
        subItemName: 'Enable Open-book',
        link: 'settings-openbook',
        subsubItems: []
      };
    }

    /** Supplier EPIC => Supplier/Undersupplier menu enable based on ActasSupplier Login */
    // const hostname = 'conxion.linkpartner-qatest.dk';  //for local
    const hostname = window.location.hostname;// for deploy 
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    if (urlParts !== null) {
      const getCust = hostname.replace(urlParts[0], '').slice(0, -1);
      this.getCustomerDetail(getCust).subscribe((supplierResponse) => {
        //console.log("supplierResponse : ", supplierResponse);
        if (supplierResponse) {
          sessionStorage.setItem('ActasSupplier', JSON.stringify(true));
          /** Display Supplier Menu Based on ActasSupplier */

        } else {
          if (sessionStorage.getItem('ActasSupplier') !== null && sessionStorage.getItem('ActasSupplier') !== undefined &&
            sessionStorage.getItem('ActasSupplier') !== 'undefined') {
            sessionStorage.removeItem('ActasSupplier');
          }
        }

        translate.get('MENU', {}).subscribe((res: any) => {
          if((this.webshopDetail.DomainName === "91sb" || this.webshopDetail.DomainName === "91springboard" || this.webshopDetail.DomainName === "aed") && this.userDetails.Roles[0].RoleName === 'Web Shop Manager'){
            this.menuItems = [
              {
                itemName: 'Order History',
                iconName: 'assets/icons/Orders.svg',
                subItems: [],
                link: 'admin-order-process/true',
              },
              {
                itemName: 'User Import',
                iconName: 'assets/icons/users.svg',
                subItems: [],
                link: 'admin-bulk-user-import',
              },
              {
                itemName: 'User Payment Details',
                iconName: 'assets/icons/Blocks.svg',
                subItems: [],
                link: 'admin-user-payment-report'
              },
              // {
              //   itemName: "Email Config",
              //   iconName: 'assets/icons/Products.svg',
              //   subItems: [],
              //   link: 'email-config'
              // },
            ]
          }
          else if((this.isSmartworks && this.userDetails.Roles[0].RoleName !== 'SmartWorksAdmin') || this.isHrm){
            this.menuItems = [
              {
                itemName: 'Order Process',
                iconName: 'assets/icons/Orders.svg',
                subItems: [],
                link: 'admin-order-process/true',
              },
              {
                itemName: "Email Config",
                iconName: 'assets/icons/Products.svg',
                subItems: [],
                link: 'email-config'
              },
            ]
          }else if(this.isSaas){
            this.menuItems = [
              {
                itemName: res.DASHBOARD,
                iconName: 'assets/icons/dashboard.svg',
                subItems: [],
                link: 'admin-dashboard',
              },
              {
                itemName: res.INBOX,
                iconName: 'assets/icons/Inbox.svg',
                subItems: [],
                link: 'admin-messagebox'
              },
              // {
              //   itemName: res.BANNERENQUIRY,
              //   iconName: 'assets/icons/Inbox.svg',
              //   subItems: [],
              //   link: 'admin-banner-enquiry'
              // },
              // {
              //   itemName: res.CATEGORY, iconName: 'assets/icons/category.svg', subItems: [], link: 'admin-category'
              // },
              // {
              //   itemName: res.PRODUCTS,
              //   iconName: 'assets/icons/Products.svg',
              //   subItems: [],
              //   link: 'admin-product-list'
              // },
              // {
              //   itemName: "Email Config",
              //   iconName: 'assets/icons/Products.svg',
              //   subItems: [],
              //   link: 'email-config'
              // },
              // {
              //   itemName: res.WEBSHOPS,
              //   iconName: 'assets/icons/Webshops.svg',
              //   subItems: [],
              //   link: 'admin-webshop-list',
              //   },
              // {
              //   itemName: res.BRANDS,
              //   iconName: 'assets/icons/Products.svg',
              //   subItems: [],
              //   link: 'admin-brand-list'
              // },
              this.campaignMenu = {
                itemName: res.CAMPAIGN,
                iconName: 'assets/icons/Blocks.svg',
                subItems: [],
                link: 'admin-campaign-list'
              },
              // {
              //   itemName: res.BLOCKS,
              //   iconName: 'assets/icons/Blocks.svg',
              //   subItems: [],
              //   link: 'admin-block-list'
              // },
              this.paymentDetails,
              {
                itemName: res.ORDERS,
                iconName: 'assets/icons/Orders.svg',
                link: '',
                subItems: [
                  {
                    subItemName: 'Order Process',
                    link: 'admin-order-process/true',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Production Line',
                    link: 'admin-order-production-line-list/true',
                    subsubItems: []
                  }
                ],
              },
              {
                itemName: res.USERS,
                iconName: 'assets/icons/users.svg',
                subItems: [],
                link: 'admin-user-list'
              },
              this.webshopMenu,
            /* this.subscriptionMenu,
              this.parentshopMenu,*/
              {
                itemName: res.BILLBOARD,
                iconName: 'assets/icons/billboard.svg',
                subItems: [],
                link: 'admin-billboard-list'
              },
             
              // this.userPointsMenu,

              {
                itemName: res.REPORTS,
                iconName: 'assets/icons/reports.svg',
                link: '',
                subItems: [
                  {
                    subItemName: 'Budget Report',
                    link: 'admin-budget-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Payment Report',
                    link: 'admin-payment-report',
                    subsubItems: []
                  },
                  /*  {
                      subItemName: 'Order Reports',
                      link: 'admin-report-orders',
                      subsubItems: []
                    },*/
                  {
                    subItemName: 'Delivery Status Reports',
                    link: 'admin-delivery-status',
                    subsubItems: []
                  },
                  {
                    subItemName: 'User MIS Reports',
                    link: 'admin-report-user-mis',
                    subsubItems: []
                  },
                  {
                    subItemName: 'User Activity Reports',
                    link: 'admin-report-user-activity',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Campaign Usage',
                    link: 'admin-campaign-usage-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Campaign Allocation',
                    link: 'admin-campaign-allocation-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Order Fulfillment',
                    link: 'admin-order-fulfillment-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'User Management',
                    link: 'admin-user-management-report',
                    subsubItems: []
                  }
                ]
              }
              // {
              //   itemName: 'Webshop Edit',
              //   iconName: 'assets/icons/dashboard.svg',
              //   link: 'webshop-edit',
              //   subItems: [],
              // }
              // {
              //   itemName: 'Webshop Edit',
              //   iconName: 'assets/icons/dashboard.svg',
              //   link: '',
              //   subItems: [
              //     {
              //       subItemName: 'Shop-details',
              //       link: 'shop-details'
              //     },
              //     {
              //       subItemName: 'user-details',
              //       link: 'webshop-edit'
              //     },
              //     {
              //       subItemName: 'Payment-details',
              //       link: 'webshop-payment'
              //     },
              //     {
              //       subItemName: 'Transportation hub',
              //       link: 'webshop-edit'
              //     }
              //   ]
              // }
              // {
              //   itemName: 'Webshop Edit',
              //   iconName: 'assets/icons/dashboard.svg',
              //   link: '',
              //   subItems: [
              //     {
              //       subItemName: 'Shop-details',
              //       link: 'webshop-edit'
              //     },
              //     {
              //       subItemName: 'user-details',
              //       link: 'webshop-edit'
              //     },
              //     {
              //       subItemName: 'Payment-details',
              //       link: 'webshop-payment'
              //     },
              //     {
              //       subItemName: 'Transportation hub',
              //       link: 'webshop-edit'
              //     }
              //   ]
              // }
            ]
            if(this.isSaas && (this.userDetails.SubScriptionId === "3" || this.userDetails.SubScriptionId === "4" || this.userDetails.SubScriptionId === 3 || this.userDetails.SubScriptionId === 4)){
              this.menuItems.push(
                {
                  itemName: res.WEBSHOPS,
                  iconName: 'assets/icons/Webshops.svg',
                  subItems: [],
                  link: 'admin-webshop-list',
                  },
                  {
                    itemName: 'Webshop Edit',
                    iconName: 'assets/icons/dashboard.svg',
                    link: '',
                    subItems: [
                      {
                        subItemName: 'Shop-details',
                        link: 'shop-details',
                        subsubItems: []
                      },
                      {
                        subItemName: 'user-details',
                        link: 'user-details',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Landing Page Image',
                        link: 'webshop-landing-page',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Payment-details',
                        link: 'webshop-payment',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Currencies',
                        link: 'webshop-currencies',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Languages',
                        link: 'webshop-language',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Maintenance',
                        link: 'webshop-maintenance',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Product Roles',
                        link: 'product-roles',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Product Tags',
                        link: 'product-tags',
                        subsubItems: []
                      },
    
                      // {
                      //   subItemName: 'Handling charges',
                      //   link: 'handling-charge',
                      //   subsubItems: []
                      // },
    
                      {
                        subItemName: 'Transportation hub',
                        link: 'transportation-hub',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Webshop Details',
                        link: '',
                        subsubItems: [
                          {
                            subItemName: 'Webshop Color',
                            link: 'webshop-color'
                          },
                          {
                            subItemName: 'Webshop Logo',
                            link: 'webshop-logo'
                          },
                          {
                            subItemName: 'Homepage Category',
                            link: 'homepage-category'
                          },
                          {
                            subItemName: 'Webshop features ',
                            link: 'webshop-features'
                          },
                          {
                            subItemName: 'Social Media',
                            link: 'social-media'
                          }
                        ]
                      },
                      {
                        subItemName: 'Publish',
                        link: 'publish',
                        subsubItems: []
                      }
                    ]
                  },
                  {
                    itemName: res.SETTINGS,
                    iconName: 'assets/icons/Settings.svg',
                    subItems: [
                      this.enableWebshopDropdown,
                      this.enableOpenBookDropdown,
                      {
                        subItemName: 'Manage colors',
                        link: 'manage-color',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Footer Link Setting',
                        link: 'footer-link',
                        subsubItems: []
                      },
                      {
                        subItemName: 'Product Groups',
                        link: 'product-groups',
                        subsubItems: []
                      }
                      // { subItemName: this.shopDetails },
                      // { subItemName: this.paymentDetails },
                      // { subItemName: this.socialMedia },
                      // { subItemName: this.webshopTheme },
                      // { subItemName: this.publishSettings },
                      // { subItemName: this.changePassword }
                    ],
                    link: ''
                  },
              )
            }
          }
          else{
            this.menuItems = [
              {
                itemName: res.DASHBOARD,
                iconName: 'assets/icons/dashboard.svg',
                subItems: [],
                link: 'admin-dashboard',
              },
              {
                itemName: res.INBOX,
                iconName: 'assets/icons/Inbox.svg',
                subItems: [],
                link: 'admin-messagebox'
              },
              {
                itemName: res.BANNERENQUIRY,
                iconName: 'assets/icons/Inbox.svg',
                subItems: [],
                link: 'admin-banner-enquiry'
              },
              {
                itemName: res.CATEGORY, iconName: 'assets/icons/category.svg', subItems: [], link: 'admin-category'
              },
              {
                itemName: res.PRODUCTS,
                iconName: 'assets/icons/Products.svg',
                subItems: [],
                link: 'admin-product-list'
              },
              {
                itemName: "Email Config",
                iconName: 'assets/icons/Products.svg',
                subItems: [],
                link: 'email-config'
              },
              {
                itemName: res.BRANDS,
                iconName: 'assets/icons/Products.svg',
                subItems: [],
                link: 'admin-brand-list'
              },
              this.campaignMenu = {
                itemName: res.CAMPAIGN,
                iconName: 'assets/icons/Blocks.svg',
                subItems: [],
                link: 'admin-campaign-list'
              },
              {
                itemName: res.BLOCKS,
                iconName: 'assets/icons/Blocks.svg',
                subItems: [],
                link: 'admin-block-list'
              },
              this.paymentDetails,
              {
                itemName: res.ORDERS,
                iconName: 'assets/icons/Orders.svg',
                link: '',
                subItems: [
                  {
                    subItemName: 'Order Process',
                    link: 'admin-order-process/true',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Production Line',
                    link: 'admin-order-production-line-list/true',
                    subsubItems: []
                  }
                ],
              },
              {
                itemName: res.USERS,
                iconName: 'assets/icons/users.svg',
                subItems: [],
                link: 'admin-user-list'
              },
              this.webshopMenu,
            /* this.subscriptionMenu,
              this.parentshopMenu,*/
              {
                itemName: res.BILLBOARD,
                iconName: 'assets/icons/billboard.svg',
                subItems: [],
                link: 'admin-billboard-list'
              },
              {
                itemName: res.SETTINGS,
                iconName: 'assets/icons/Settings.svg',
                subItems: [
                  this.enableWebshopDropdown,
                  this.enableOpenBookDropdown,
                  {
                    subItemName: 'Manage colors',
                    link: 'manage-color',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Footer Link Setting',
                    link: 'footer-link',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Product Groups',
                    link: 'product-groups',
                    subsubItems: []
                  }
                  // { subItemName: this.shopDetails },
                  // { subItemName: this.paymentDetails },
                  // { subItemName: this.socialMedia },
                  // { subItemName: this.webshopTheme },
                  // { subItemName: this.publishSettings },
                  // { subItemName: this.changePassword }
                ],
                link: ''
              },
              this.userPointsMenu,

              {
                itemName: res.REPORTS,
                iconName: 'assets/icons/reports.svg',
                link: '',
                subItems: [
                  {
                    subItemName: 'Budget Report',
                    link: 'admin-budget-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Payment Report',
                    link: 'admin-payment-report',
                    subsubItems: []
                  },
                  /*  {
                      subItemName: 'Order Reports',
                      link: 'admin-report-orders',
                      subsubItems: []
                    },*/
                  {
                    subItemName: 'Delivery Status Reports',
                    link: 'admin-delivery-status',
                    subsubItems: []
                  },
                  {
                    subItemName: 'User MIS Reports',
                    link: 'admin-report-user-mis',
                    subsubItems: []
                  },
                  {
                    subItemName: 'User Activity Reports',
                    link: 'admin-report-user-activity',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Campaign Usage',
                    link: 'admin-campaign-usage-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Campaign Allocation',
                    link: 'admin-campaign-allocation-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Order Fulfillment',
                    link: 'admin-order-fulfillment-report',
                    subsubItems: []
                  },
                  {
                    subItemName: 'User Management',
                    link: 'admin-user-management-report',
                    subsubItems: []
                  }
                ]
              },
              {
                itemName: 'Webshop Edit',
                iconName: 'assets/icons/dashboard.svg',
                link: '',
                subItems: [
                  {
                    subItemName: 'Shop-details',
                    link: 'shop-details',
                    subsubItems: []
                  },
                  {
                    subItemName: 'user-details',
                    link: 'user-details',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Landing Page Image',
                    link: 'webshop-landing-page',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Payment-details',
                    link: 'webshop-payment',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Currencies',
                    link: 'webshop-currencies',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Languages',
                    link: 'webshop-language',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Maintenance',
                    link: 'webshop-maintenance',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Product Roles',
                    link: 'product-roles',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Product Tags',
                    link: 'product-tags',
                    subsubItems: []
                  },

                  // {
                  //   subItemName: 'Handling charges',
                  //   link: 'handling-charge',
                  //   subsubItems: []
                  // },

                  {
                    subItemName: 'Transportation hub',
                    link: 'transportation-hub',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Webshop Details',
                    link: '',
                    subsubItems: [
                      {
                        subItemName: 'Webshop Color',
                        link: 'webshop-color'
                      },
                      {
                        subItemName: 'Webshop Logo',
                        link: 'webshop-logo'
                      },
                      {
                        subItemName: 'Homepage Category',
                        link: 'homepage-category'
                      },
                      {
                        subItemName: 'Webshop features ',
                        link: 'webshop-features'
                      },
                      {
                        subItemName: 'Social Media',
                        link: 'social-media'
                      }
                    ]
                  },
                  {
                    subItemName: 'Publish',
                    link: 'publish',
                    subsubItems: []
                  }
                  ,
                  {
                    subItemName: 'Currency',
                    link: 'currency',
                    subsubItems: []
                  },
                  {
                    subItemName: 'Country',
                    link: 'country',
                    subsubItems: []
                  }
                ]
              }
              // {
              //   itemName: 'Webshop Edit',
              //   iconName: 'assets/icons/dashboard.svg',
              //   link: 'webshop-edit',
              //   subItems: [],
              // }
              // {
              //   itemName: 'Webshop Edit',
              //   iconName: 'assets/icons/dashboard.svg',
              //   link: '',
              //   subItems: [
              //     {
              //       subItemName: 'Shop-details',
              //       link: 'shop-details'
              //     },
              //     {
              //       subItemName: 'user-details',
              //       link: 'webshop-edit'
              //     },
              //     {
              //       subItemName: 'Payment-details',
              //       link: 'webshop-payment'
              //     },
              //     {
              //       subItemName: 'Transportation hub',
              //       link: 'webshop-edit'
              //     }
              //   ]
              // }
              // {
              //   itemName: 'Webshop Edit',
              //   iconName: 'assets/icons/dashboard.svg',
              //   link: '',
              //   subItems: [
              //     {
              //       subItemName: 'Shop-details',
              //       link: 'webshop-edit'
              //     },
              //     {
              //       subItemName: 'user-details',
              //       link: 'webshop-edit'
              //     },
              //     {
              //       subItemName: 'Payment-details',
              //       link: 'webshop-payment'
              //     },
              //     {
              //       subItemName: 'Transportation hub',
              //       link: 'webshop-edit'
              //     }
              //   ]
              // }
            ]
            // if(this.isSaas){
            //   this.menuItems.push(
            //     {itemName: res.WEBSHOPS,
            //     iconName: 'assets/icons/Webshops.svg',
            //     subItems: [],
            //     link: 'admin-webshop-list',
            //     }
            //   )
            // }
          }
          //=> 'hello world';
          //console.log(this.menuItems)
        });
      });
    }
  }

  ngOnInit() {
    this.loadLoginData();
  }

  loadLoginData() {
    if (this.webshopDetail === undefined) {
      // const hostname = 'conxion.linkpartner-qatest.dk';  //for local
      const hostname = window.location.hostname; // for deploy 
      const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
      const urlParts = regexParse.exec(hostname);
      if (urlParts !== null) {
        const getCust = hostname.replace(urlParts[0], '').slice(0, -1);
        this.loginHttpService.getCustomer(getCust).subscribe(
          (data2: any) => {
            const customerLogin = this.apiservice.getLoginCustomerData();
            //console.log(customerLogin);
            //console.log(data2);
            const getLogo = customerLogin.filter(data => data.CustomerID === data2.CustomerID)
            this.logoName = getLogo[0].Name.charAt(0);
            this.appLogo = getLogo[0].ImageURL;
          });
      }
    } else {
      const customerLogin = this.apiservice.getLoginCustomerData();
      if(customerLogin){
        const getLogo = customerLogin.filter(data => data.CustomerID === parseInt(this.webshopDetail.CustomerID));
        //console.log(customerLogin);
        //console.log(this.webshopDetail);
        this.logoName = getLogo[0].Name.charAt(0);
        this.appLogo = getLogo[0].ImageURL;
      }
      
    }
  }

  getCustomerDetail(getCust): Observable<any> {
    const customerDetail = new Subject<any>();
    this.loginHttpService.getCustomer(getCust).subscribe((data: any) => {
      if (data) {
        if (data.SupplierID !== null && data.SupplierID !== undefined) {
          //console.log("Get Customer DATAs : ", data);
          customerDetail.next(true);
        } else {
          customerDetail.next(false);
        }
      }
    }, error => {
      customerDetail.next(false);
    });
    return customerDetail.asObservable();
  }

  getLanguage(data) {
    sessionStorage.setItem('lang', data);
    location.reload();
  }

  navigateTo(item: any, pagelink) {
    if (pagelink) {
      this.router.navigate([pagelink]);
    }
  }
}

