import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsHttpService } from '../services/reports-http.service';
import { ReportAbstract } from '../services/report-abstract';
import { ToastService } from 'ng-uikit-pro-standard';
import { CommonFilterComponent } from 'src/app/common/common-filter/common-filter.component';

@Component({
  selector: 'rewardkart-admin-report-dispatched-products',
  templateUrl: './report-dispatched-products.component.html',
  styleUrls: ['./report-dispatched-products.component.scss']
})
export class ReportDispatchedProductsComponent extends ReportAbstract
  implements OnInit {
  header = 'Dispatched Products';
  excludeKeyInUrlParam = ['languageID', 'CustomerID', 'productType', 'dispatchDateFrom', 'dispatchDateTo'];
  splitString = '	';
  languages = [];
  selectedLanguage = 'en';
  CustomerID = '';
  filterFields: any = [];
  searchCriteria: any = {};
  filterData: any = {};
  commonRemoveFilter: any;
  selectedProductType = '';
  downloadReport = 'Download Report';
  downloadInvoiceReport = 'Download Invoice'
  dispatchDateFrom = '';
  dispatchDateTo = '';
  customerLoader = false;
  customerList = [];
  @ViewChild('commonFilter', { static: true }) commonFilter: CommonFilterComponent;
  webshopDetail: any;
  includChildShopOrders = '&includChildShopOrders=false';
  selectall: Boolean = false;
  reportData: any;
  constructor(public reportHttpService: ReportsHttpService, private toaster: ToastService) {
    super();
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    this.searchCriteria = {
      CustomerID: '',
      selectedLanguage: '',
      dispatchDateFrom: '',
      dispatchDateTo: '',
      productType: '',
      productTypeData: [
        {
          value: 'internalStockProducts',
          label: 'Internal Stock Products',
          key: 'internalStockProducts'
        },
        {
          value: 'productionProducts',
          label: 'Production Product',
          key: 'productionProducts'
        }
      ],
      excludeTransportation: false,
      showAmountsInDKK: false,
      onlyTransportation: false,
      onlyOrdersWithVAT: false,
      onlyOrdersWithoutVAT: false,
      exportInvoice: false,
      onlyOrdersWithShipmentInvoice: false,
      onlyOrdersWithNormalInvoice: false,
      showTotalsInEUR: false
    };
    this.formFields();
    this.getCustomers();
    this.getLanguages(this.webshopDetail.CustomerID);
  }

  getLanguages(CustomerID) {
    this.reportHttpService.getLanguages(CustomerID).subscribe(
      (languageResponse: any) => {
        if (languageResponse) {
          this.languages = languageResponse.map(language => ({
            value: language.Code,
            label: language.Name
          }));
          this.updateSearchFormFields('languageID', this.languages);
        }
      },
      error => {
        this.toaster.error('unable to get language');
      }
    );
  }

  getCustomers() {
    this.customerLoader = true;
    this.reportHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
      (customersRes: any) => {
        if (customersRes) {
          // this.searchCriteria.CustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
          const customerlist = [{ label: 'Select All', value: 0 }];
          customersRes.forEach(cust => {
            let custData = {
              label: cust.Name,
              value: cust.CustomerID
            }
            customerlist.push(custData);
          });
          this.customerList = customerlist;
          this.updateSearchFormFields('CustomerID', this.customerList);
        }
        this.customerLoader = false;
      },
      error => {
        this.customerLoader = false;
        this.toaster.error('unable to get webshop customers');
      }
    );
  }

  updateSearchFormFields(key: any, value: any) {
    if (this.commonFilter && this.commonFilter.filterFormField !== undefined) {
      this.commonFilter.filterFormField.forEach((field) => {
        if (field.key === key) {
          field.data = value;
          field.selected = this.searchCriteria[field.key];
        }
      });
    }
  }

  buildReportURL(urlParam: any, formData: any): string {
    debugger
    return (`${this.selectedLanguage}/reports/DeliveredOrdersReport?${urlParam}${this.CustomerID}
    ${this.selectedProductType}${this.dispatchDateFrom}${this.dispatchDateTo}${this.includChildShopOrders}`);
  }

  formFields() {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12 pl-3 pt-4',
        key: 'filterHeading',
        filterkey: ''
      }];
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.filterFields.push(
          {
            label: 'Select Webshop',
            type: 'select',
            data: this.customerList,
            class: 'col-md-12 no-icon',
            key: 'CustomerID',
            filterkey: 'CustomerID',
            selected: this.searchCriteria.CustomerID
          }
        );
      }
    }
    this.filterFields.push(
      {
        label: 'Select Language *',
        type: 'select',
        data: this.languages,
        class: 'col-md-12 no-icon',
        key: 'languageID',
        selected: this.searchCriteria.selectedLanguage,
        required: true,
        filterkey: 'languageID'
      },
      {
        label: 'Dispatch Date From',
        type: 'date',
        class: 'col-md-12',
        key: 'dispatchDateFrom',
        format: {
          dateFormat: 'dd/mm/yyyy',
          closeAfterSelect: true,
        },
        filterkey: 'dispatchDateFrom',
        selected: this.searchCriteria.dispatchDateFrom
      },
      {
        label: 'Dispatch Date To',
        type: 'date',
        class: 'col-md-12',
        key: 'dispatchDateTo',
        format: {
          dateFormat: 'dd/mm/yyyy',
          closeAfterSelect: true,
        },
        filterkey: 'dispatchDateTo',
        selected: this.searchCriteria.dispatchDateTo
      },
      {
        label: 'Select Product Type',
        type: 'select',
        data: this.searchCriteria.productTypeData,
        class: 'col-md-12 no-icon',
        key: 'productType',
        selected: this.searchCriteria.productType,
        filterkey: 'productType'
      },
      {
        label: 'Exclude Transportation',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'excludeTransportation',
        filterkey: 'excludeTransportation',
        selected: this.searchCriteria.excludeTransportation
      },
      {
        label: 'Show Amount in DKK',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'showAmountsInDKK',
        filterkey: 'showAmountsInDKK',
        selected: this.searchCriteria.showAmountsInDKK
      },
      {
        label: 'VAT and Total only for Transportation costs',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyTransportation',
        filterkey: 'onlyTransportation',
        selected: this.searchCriteria.onlyTransportation
      },
      {
        label: 'Order with VAT',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyOrdersWithVAT',
        filterkey: 'onlyOrdersWithVAT',
        selected: this.searchCriteria.onlyOrdersWithVAT
      },
      {
        label: 'Order without VAT',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyOrdersWithoutVAT',
        filterkey: 'onlyOrdersWithoutVAT',
        selected: this.searchCriteria.onlyOrdersWithoutVAT
      },
      {

        label: 'Export Invoices',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'exportInvoices',
        filterkey: 'exportInvoices',
        selected: this.searchCriteria.exportInvoice
      },
      {
        label: 'Only shipping Invoices',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyOrdersWithShipmentInvoice',
        filterkey: 'onlyOrdersWithShipmentInvoice',
        selected: this.searchCriteria.onlyOrdersWithShipmentInvoice
      },
      {
        label: 'Do not show shipping Invoices',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'onlyOrdersWithNormalInvoice',
        filterkey: 'onlyOrdersWithNormalInvoice',
        selected: this.searchCriteria.onlyOrdersWithNormalInvoice
      },
      {
        label: 'Show totals in EUR',
        type: 'checkbox',
        data: false,
        class: 'col-md-12 justify-content-center',
        key: 'showTotalsInEUR',
        filterkey: 'showTotalsInEUR',
        selected: this.searchCriteria.showTotalsInEUR
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    );
  }

  handleResetForm(event) {
    if (event) {
      this.filterFields.forEach(element => {
        if (event[element.key] !== undefined)
          event[element.key].value = '';
      });
      if (this.CustomerID !== '') {
        this.getLanguages(this.webshopDetail.CustomerID);
      }
      this.CustomerID = '';
      this.selectedProductType = '';
      this.dispatchDateFrom = '';
      this.dispatchDateTo = '';
      this.selectedLanguage = '';
    }
    if (this.reportData && this.reportData.ReportFileURL != null) {
      this.reportData.ReportFileURL = null;
    }
  }

  getSelectData(filterResult) {
    const { languageID, dispatchDateFrom, dispatchDateTo, productType, CustomerID } = filterResult;
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (productType && productType.value !== '') {
      this.selectedProductType = `&${productType.value}=true`;
    }
    if (dispatchDateFrom && dispatchDateFrom.value !== '') {
      const formatedDate = this.reportHttpService.dateFormatchange(dispatchDateFrom.value);
      this.dispatchDateFrom = `&${dispatchDateFrom.selectMethod}=${formatedDate}`;
    }
    if (dispatchDateTo && dispatchDateTo.value !== '') {
      const formatedDate = this.reportHttpService.dateFormatchange(dispatchDateTo.value);
      this.dispatchDateTo = `&${dispatchDateTo.selectMethod}=${formatedDate}`;
    }
    if (CustomerID && CustomerID.value !== 0 && CustomerID.value !== '') {
      if (this.CustomerID !== `&${CustomerID.selectMethod}=${CustomerID.value}` || this.CustomerID == '') {
        this.CustomerID = `&${CustomerID.selectMethod}=${CustomerID.value}`;
        this.selectall = false;
        this.getLanguages(CustomerID.value)
      }
      this.includChildShopOrders = `&includChildShopOrders=false`;
    } else if (CustomerID && CustomerID.value === 0) {
      this.CustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
      this.includChildShopOrders = `&includChildShopOrders=true`;
    } else if (CustomerID === undefined && getCustomerDetails &&
      getCustomerDetails.ParentCustomerID != null) {
      this.CustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
      this.includChildShopOrders = `&includChildShopOrders=false`;
    }
    if (CustomerID && CustomerID.value === 0) {
      if (this.selectall === false) {
        this.getLanguages(this.webshopDetail.CustomerID)
        this.selectall = true;
      }

    }

    this.selectedLanguage = (languageID ? languageID.value : 'en') || 'en';
  }
}
