import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-product-color-size',
  templateUrl: './product-color-size.component.html',
  styleUrls: ['./product-color-size.component.scss']
})
export class ProductColorSizeComponent implements OnInit {
  @Output() sendProductSizeDetails = new EventEmitter<any>();
  @Output() sendProductColorDetails = new EventEmitter<any>();
  @Input() loadApiColor:any;
  @Input() loadApiSize:any;
  @Input() productDetails:any;
  constructor() { }

  ngOnInit() {
  }


  getSizes(data){
    this.sendProductSizeDetails.emit(data);
  }

  getProductColors(data){
    this.sendProductColorDetails.emit(data);
  }

}
