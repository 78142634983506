import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProductMarketEditComponent } from './product-market-edit.component';
import { ProductImagesModule } from '../product-edit/product-images/product-images.module';
import { ProductAddMarketingModule } from '../product-add-marketing/product-add-marketing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



@NgModule({
  declarations: [ProductMarketEditComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ProductAddMarketingModule,
    ProductImagesModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [ProductMarketEditComponent]
})
export class ProductMarketEditModule { }

