import { Webshop } from '../models/index';
import { WebshopActions } from '../actions/index';

export interface WebshopState {
    webshops: Webshop[];
}

export const initialState: WebshopState = {
    webshops: null
};

export function webshopReducers(state: WebshopState = initialState, action: WebshopActions.All) {

    switch (action.type) {

        case WebshopActions.LOAD_WEBSHOPS_SUCCESS:
            return Object.assign({}, state, { webshops: action.payload });

        default: {
            return state;
        }
    }
}
