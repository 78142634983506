import { Component, OnInit, Output, EventEmitter, OnChanges, Input, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import * as _ from 'lodash';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-product-manage-size',
  templateUrl: './product-manage-sizes.component.html',
  styleUrls: ['./product-manage-sizes.component.scss']
})
export class ProductManageSizeComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  productManageSize: FormGroup;
  // parentColor: any;
  code: any;
  name: any;
  optionsSelectParentSize: any = [{
    value: '',
    label: '',
  }];
  allColors: any = [];
  activeLanguage: any;
  showcolorname: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  selectedLang: any = 'English';
  // subscribeMethod: boolean = false;
  deleteSubscribe: boolean = false;
  @Input() productDetails: any
  @Input() loadApi: Boolean;
  @Input('resetForm') resetForm: Boolean
  @Input('productSizeForm') productSizeForm: Boolean
  @Output() showManageSize = new EventEmitter<any>();
  @Output() loadSizeDropdown = new EventEmitter<any>();
  productSize: any;
  loadApiFlag: Boolean = false
  showLoader: Boolean = false
  danishlang: boolean;
  term: any;
  selfProductSizes: any = [];
  searchProductSizes: any;
  productSizeList: any;
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  selectedProductSize: any = {
    ProductSizeID: '',
    SupplierID: ''
  };
  selectedLangCode: string = 'en';
  webshopDetail: any;

  // productColorsList:any;
  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public apiService: ApiService,
    public toast: ToastService,
    public translate: TranslateService,
    public productHttpService: ProductHttpService,
  ) {
    translate.setDefaultLang('en'); const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFilter()
      this.filterReset()
    }
  }

  ngOnInit() {
    this.buildForm();
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.getParentProductSizes();
      this.getAllProductSizes();
    }
  }


  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    let translationFieldJson = [
      {
        formcontrolname: 'ProductSizeName',
        label: 'Name',
        PropertyName: "ProductSizeName",
      }
    ];
    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: jsonField.formcontrolname + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })
    let group: any = {
      language: new FormControl(1),
      items: this.formBuilder.array([])
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.productManageSize = new FormGroup(group);
  }


  createItem(sizeVal?): FormGroup {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    this.translateFormItems = [];
    getlang.forEach(lang => {
      if (sizeVal) {
        sizeVal.Translations.forEach(item => {
          if (lang.Code == item.LanguageCode) {
            let formJson = {
              formcontrolname: 'ProductSizeName' + lang.Code,
              translationID: 'TranslationID' + lang.Code,
              objectID: 'ObjectID' + lang.Code,
              class: lang.Name,
              name: item.PropertyName,
              LanguageID: lang.LanguageID,
              LanguageCode: lang.Code,
              PropertyName: item.PropertyName,
              PropertyValue: item.PropertyValue,
              TranslationID: item.TranslationID,
              ObjectID: item.ObjectID
            }
            this.translateFormItems.push(formJson);
          }
        });
      }
      else {
        let formJson = {
          formcontrolname: 'ProductSizeName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: 'ProductSizeName',
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: 'ProductSizeName',
          PropertyValue: '',
          TranslationID: '',
          ObjectID: ''
        }
        this.translateFormItems.push(formJson);
      }

    })
    let group: any = {};
    if (sizeVal) {
      group['productSizeID'] = sizeVal.ProductSizeID
    }

    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })

    return this.formBuilder.group({ ...group });
  }



  loadParentDropDown() {
    this.loadSizeDropdown.emit()
  }

  searchProductSize() {
    // let getResult = [];
    // const getData = this.selfProductSizes;
    // getData.filter((data, index) => {
    //   data.Translations.filter((ele) => {
    //     let value = (ele.PropertyValue).toLowerCase();
    //     let searchData = (this.searchProductSizes).toLowerCase();
    //     if ((value).includes(searchData)) {
    //       getResult.push(data)
    //     }
    //   })
    // });
    // const filterResult = _.uniq(getResult, 'ProductSizeID');
    // this.clearFormItems();
    // filterResult.forEach(element => {
    //   this.items.push(this.createItem(element));
    // });
    // this.productSizeList = filterResult;

    this.setPageSizeOptions(this.selectedperpage);
  }

  resetFilter() {

    this.searchProductSizes = '';
    this.clearFormItems();
    this.selfProductSizes.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.productSizeList = this.selfProductSizes;
  }


  getAllProductSizes() {

    this.showLoader = true;
    this.items = this.productManageSize.get('items') as FormArray;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };

    this.searchCriteria = {
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      ProductSizeID: this.selectedProductSize.ProductSizeID,
      SupplierID: this.productDetails.SupplierID
    };

    this.productHttpService.getProductSizesPagination(this.searchCriteria).subscribe((data: any) => {

      if (data.ItemsCollection !== null) {

        this.clearFormItems();
        this.productSizeList = data.ItemsCollection;
        this.selfProductSizes = data.ItemsCollection;
        if (data.ItemsCollection.length > 0) {
          data.ItemsCollection.forEach(element => {
            this.items.push(this.createItem(element));
          });
        }
        else {
          this.createItem();
        }

        this.showLoader = false;

        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      }
    });
  }

  getParentProductSizes() {
    this.showLoader = true;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    // this.productService.loadEnProductSizes(requestData);
    this.productHttpService.getEnProductSizeList(requestData).subscribe((data: any) => {
      if (data !== null) {
        this.showLoader = false;
        this.optionsSelectParentSize = [];
        data.forEach(element => {
          const sizeVal = {
            value: element.ProductSizeID,
            label: element.Name,
          };
          this.optionsSelectParentSize.push(sizeVal);
        });

      }
    });
  }


  clearAddFormValues() {
    let group: any = {}
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = "";
    })
    this.productManageSize.patchValue({ ...group });
  }

  addSize() {
    // if(!this.danishlang){
    let translations = [];
    let flag = true;
    let englishFieldFlag = true;
    this.translateFormItems.forEach(item => {
      if (item.LanguageCode === 'en' && !this.productManageSize.value[item.formcontrolname]) {
        englishFieldFlag = false;
      }
      const data = {
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectType: "ProductSizes",
        PropertyName: item.name,
        PropertyValue: this.productManageSize.value[item.formcontrolname],
        Action: "Insert"
      }
      if (data.PropertyValue && flag) {
        flag = false
      }
      translations.push(data);
    })
    if (!flag) {
      if (!englishFieldFlag && !flag) {
        this.toast.error("The ProductSizeName field is required for English language")
        return null
      }
      let requestData = {
        SupplierID: this.productDetails.SupplierID,
        Translations: translations,
        selectedLangCode: this.selectedLangCode
      }
      this.showLoader = true;
      this.productHttpService.addProductSize(requestData).subscribe((data) => {
        this.showLoader = false;
        if (data !== null) {
          this.clearFormItems();
          this.clearAddFormValues();
          this.getAllProductSizes();
          this.toast.success('Added Successfully');
          this.loadParentDropDown()
          this.getParentProductSizes();
        }
      },
        err => {
          this.showLoader = false;
          if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
            if (err.error.ModelState.ProductSizes !== null && err.error.ModelState.ProductSizes !== undefined) {
              this.toast.error(err.error.ModelState.ProductSizes);
            }
          }
        }
      )

    } else {
      this.toast.error("Please Fill Name")
    }
    // }
    // else{
    //   this.toast.error('The ProductSizeName field is required for English language')
    // }


  }

  updateSize(productSize) {
    this.showLoader = true;
    let translations = [];
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: productSize.value[item.translationID],
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: productSize.value[item.objectID],
        ObjectType: "ProductSizes",
        PropertyName: item.name,
        PropertyValue: productSize.value[item.formcontrolname],
        Action: "Update",
      }
      translations.push(data);
    })

    const requestData = {
      ProductSizeID: productSize.value.productSizeID,
      data: {
        SupplierID: this.productDetails.SupplierID,
        Translations: translations
      }
    }
    this.showLoader = true;
    // this.productService.updateProductSize(requestData);
    this.productHttpService.updateProductSize(requestData).subscribe((data: any) => {
      this.showLoader = false;
      if (data !== null) {
        this.clearFormItems();
        this.clearAddFormValues();
        this.getAllProductSizes();
        this.toast.success('Updated Successfully');
        this.loadParentDropDown()
        this.getParentProductSizes();
        // this.subscribeMethod = false;
        const list = document.getElementById("managesizeList")
        list.scrollTo(0, 0)
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.ProductSizes !== null && err.error.ModelState.ProductSizes !== undefined) {
            this.toast.error(err.error.ModelState.ProductSizes);
          }
        }
      });
  }
  clearFormItems() {
    if (this.productManageSize == undefined) return true;

    const control = <FormArray>this.productManageSize.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  getDeleteStatus(status) {
    const productSize = this.productSize;
    if (status) {
      // let deletesubscribe;
      const ProductSizeID = productSize.value.productSizeID;
      //this.productService.deleteProductSize(ProductSizeID);
      // if (!this.deleteSubscribe) {
      //   deletesubscribe = this.productHttpService.deleteProductSize(ProductSizeID).subscribe((data) => {
      //     if (data !== null) {
      //       this.deleteSubscribe = true;
      //       this.clearFormItems();            
      //       this.getAllProductSizes();            
      //       // this.getParentProductSizes();
      //       this.deleteModal.hide();                      

      //     }
      //   });
      // }
      // if (this.deleteSubscribe) {
      //   deletesubscribe.unsubscribe();
      // }
      this.showLoader = true;
      this.productHttpService.deleteProductSize(ProductSizeID).subscribe((data) => {
        this.showLoader = false;
        if (data !== null) {
          this.clearFormItems();
          this.getAllProductSizes();
          this.getParentProductSizes();
          this.loadParentDropDown()
          this.deleteModal.hide();
          const list = document.getElementById("managecolorList")
          list.scrollTo(0, 0)
        }
        this.toast.success('Deleted Successfully');
        this.loadParentDropDown()
      },
        err => {
          this.showLoader = false;
          if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
            if (err.error.ModelState.ProductSizes !== null && err.error.ModelState.ProductSizes !== undefined) {
              this.toast.error(err.error.ModelState.ProductSizes);
            }
          }
        });

    }
    else {
      this.deleteModal.hide();
    }

  }

  deleteSize(productSize) {


    // ProductSizeID



    let isToBeDeleted = true

    if (this.productSizeForm instanceof Array) {
      isToBeDeleted = !(this.productSizeForm.some(sizee => {
        return sizee.value.data.ProductSizeID === productSize.value.productSizeID
      }))
    }
    if (isToBeDeleted) {
      this.productSize = productSize;
      //this.deleteSubscribe = false;
      this.deleteModal.show();
    } else {
      this.toast.error("This Option already Mapped. Cannot be Deleted")
    }



  }

  getCurrentLang(lang) {
    this.selectedLang = lang.label;
    if (this.selectedLang === 'Danish') {
      this.danishlang = true
    }
    else {
      this.danishlang = false
    }

    this.selectedLangCode = <any>this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID).find(language => language.LanguageID == lang.value).Code;
    if (this.searchText) this.filterReset();
  }

  closeModal() {
    this.showManageSize.emit(false);
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }

  autoCompleteProductSize(data) {

    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      translations: true,
      SupplierID: this.productDetails.SupplierID,
      selectedLangCode: this.selectedLangCode
    }

    this.groupCollection = [];

    this.productHttpService.productSizeAutoComplete(request).subscribe((res: any) => {
      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if (res) {
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedProductSize = selected;
    this.setPageSizeOptions(10);
  }

  onOptionSelected(event) {
    this.selectedProductSize = event.text
  }

  filterReset() {
    this.searchText = '';
    this.selectedProductSize.ProductSizeID = '';
    this.selectedProductSize.SupplierID = '';
    this.textNoResults = '';
    this.groupCollection = [];
    if (this.productManageSize) {
      this.getAllProductSizes();
    }

  }

  pageChanged(page) {
    this.selectedpage = page;
    this.clearFormItems();
    this.getAllProductSizes();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    if (this.searchText == '') {
      this.groupCollection = [];
    }
    this.getAllProductSizes();
  }
}
