import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoginState } from '../reducers/login.reducer';
import { LoginActions } from '../actions';
import { getAllCustomers, getLoginSuccess, getSelectedCustomer, getLanguage, Language, getCustomerName, getLoginFail, getIsLoggedIn, getSubDomainName } from '../reducers/index';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {
    constructor(private store: Store<LoginState>) { }

    loadCustomers() {
        this.store.dispatch(new LoginActions.LoadCustomers());
    }

    getAllCustomers() {
        return this.store.select(getAllCustomers);
    }

    doLogin(formValue: any, selectedCustomer: any) {
        this.store.dispatch(new LoginActions.Login(formValue));
        this.setSelectedCustomer(selectedCustomer);
    }

    loginSuccess() {
        return this.store.select(getLoginSuccess);
    }

    loginFail() {
        return this.store.select(getLoginFail);
    }

    isLoggedIn(): Observable<any> {
        return this.store.select(getIsLoggedIn);
    }

    loginStatus(status: boolean) {
        this.store.dispatch(new LoginActions.LoggedIn(status));
    }

    dispatchLoginFail(status: any = null) {
        this.store.dispatch(new LoginActions.LoginFail(status));
    }

    setSelectedCustomer(selectedCustomer: any) {
        this.store.dispatch(new LoginActions.SelectedCustomer(selectedCustomer));
    }
    setSelectedCustomers(selectedCustomers: any) {
        this.store.dispatch(new LoginActions.WebshopLanguage(selectedCustomers));
    }

    getSelectedCustomer() {
        return this.store.select(getSelectedCustomer);
    }

    loadLanguage() {
        this.store.dispatch(new LoginActions.Language())
    }

    getLanguage() {
        return this.store.select(getLanguage);
    }
    loadwebshopLanguage(formValue: any) {
        this.store.dispatch(new LoginActions.WebshopLanguage(formValue));
    }
    getwebshopLanguage(formValue: any) {
        //console.log('store...', this.store.select(Language))
        // this.store.dispatch(new LoginActions.WebshopLanguage(formValue));
        return this.store.select(Language);

    }


    loadCustomer(data: any) {
        this.store.dispatch(new LoginActions.LoadCustomerName(data))
    }

    getCustomer() {
        return this.store.select(getCustomerName);
    }

    loadSubDomain(customerName: string) {
        this.store.dispatch(new LoginActions.LoadSubDomain(customerName));
    }

    getSubDomain() {
        return this.store.select(getSubDomainName);
    }

}
