import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderHttpService } from '../../state/services/order.http.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService, ModalDirective, IMyOptions } from 'ng-uikit-pro-standard';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { state } from '@angular/animations';
import { error } from 'protractor';
import * as moment from 'moment';

@Component({
  selector: 'rewardkart-admin-view-orderstatus',
  templateUrl: './view-orderstatus.component.html',
  styleUrls: ['./view-orderstatus.component.scss']
})
export class ViewOrderstatusComponent implements OnInit {
  stateForm: FormGroup;
  listHeading: any = [];
  getOrderList: any = [];
  getActualOrderList: any = [];
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  dropDownList: any = [];
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selectedOrder: any;
  isSubOrder: Boolean = false;
  orderId: any;
  orderItemId: any;
  dispatchedStatus: Boolean = false;
  optionsList: any;
  getOrderStatus: any = '';
  date = new Date();
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true
  };
  @ViewChild('orderaccepted', { static: false }) orderaccepted: ModalDirective;
  @ViewChild('deleteorder', { static: false }) deleteorder: ModalDirective;
  @ViewChild('dispatchModal', { static: false }) dispatchModal: ModalDirective;
  deleteData: any;
  orderAcceptStatus: Boolean = false;
  statusCodeLogoSample: Boolean = false;
  orderAccepted: Boolean = false;
  subName: any = '';
  itemName: any = '';
  activeOrder: any = '';
  orderItem: any;
  showLoader: Boolean = false;
  enableDeliveryModal: Boolean = false;
  companyName: any = '';
  trackingNumber: any = '';
  orderData: any;
  showDispatch: Boolean = false;
  webshopDetail: any;
  multipleTrackingNo: any = [];
  showTrackingNo: boolean;
  mutipleOrderItems: any = [];
  showMultipleItems: boolean = false;
  updateDispatchDetails: any = [];
  statusOrdersList: any = [];
  showMultipleOrderItems: boolean;
  checkOrderItems: boolean = false;
  checkTrackingNo: boolean = false;
  checkTrackingAll: boolean = false;
  constructor(private orderHttpService: OrderHttpService, private apiService: ApiService, private toast: ToastService,
    private router: Router) {

    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    if (history.state.data !== undefined) {
      if (history.state.data !== undefined && history.state.data !== null) {
        if (history.state.data.isSubOrder !== undefined && history.state.data.isSubOrder !== null) {
          this.isSubOrder = history.state.data.isSubOrder;
        }
        if (history.state.data.orderID !== undefined && history.state.data.orderID !== null) {
          this.orderId = history.state.data.orderID;
        }
        if (history.state.data.orderItemID !== undefined && history.state.data.orderItemID !== null) {
          this.orderItemId = history.state.data.orderItemID;
        }
      }

    }
  }

  ngOnInit() {
    this.getListHeading();
  }

  getListHeading() {
    this.orderHttpService.orderStates().subscribe((data) => {
      if (data) {
        this.listHeading = data;
        this.ordersList();
      }
    })
  }


  ordersList() {

    this.showLoader = true;
    const orderData = JSON.parse(sessionStorage.getItem('OrderCustomerData'));
    let custID = this.webshopDetail.CustomerID;
    if (orderData !== null && orderData !== undefined && orderData !== '') {
      if (orderData.CustomerID) {
        custID = orderData.CustomerID;
      } else {
        custID = this.webshopDetail.CustomerID;
      }
    } else {
      custID = this.webshopDetail.CustomerID;
    }

    const request = {
      customerID: custID,
      selectedpage: this.selectedpage,
      selectedperpage: this.selectedperpage,
      orderId: this.orderId,
      subOrder: this.isSubOrder
    }
    this.orderHttpService.ordersList(request).subscribe((data: any) => {
      if (data) {
        this.getOrderList = [];
        this.statusOrdersList = [];
        this.statusOrdersList = data;
        if (typeof (this.getOrderList) === 'object') {
          this.getOrderList.push(data);
        }
        else {
          this.getOrderList = data;
        }


        if (history.state.type === "productionline") {
          if (this.getOrderList[0].Suborders) {
            if (this.getOrderList[0].Suborders.length > 0) {
              this.isSubOrder = true;
            }
          }
          if (this.getOrderList[0].OrderItems) {
            if (this.getOrderList[0].OrderItems.length > 0) {
              this.isSubOrder = false;
            }
          }
        }
        /** To filter Order List => Remove Sub order Array from Order List */
        if (!this.isSubOrder) {
          this.getOrderList.forEach((list, index) => {

            const getArry = list.OrderItems.filter(element => element.SuborderID === undefined);
            if (getArry.length > 0) {
              this.getOrderList[index].OrderItems = getArry;
            }
          });
        }

        if (this.isSubOrder) {
          const dropDownList = [{ label: 'Show all', value: 'All' }];
          this.getOrderList.forEach(element => {
            element.Suborders.forEach(suborder => {
              dropDownList.push({ label: suborder.SuborderNumber, value: suborder.SuborderID })
              // suborder.OrderItems.forEach(items => {
              //   dropDownList.push({ label: suborder.SuborderNumber, value: suborder.SuborderID })
              // });
            });
          });
          if (history.state.data !== undefined) {
            if (history.state.data.suborderID !== undefined)
              this.activeOrder = history.state.data.suborderID;
          }

          this.dropDownList = dropDownList;
          this.listHeading.forEach((heading) => {
            this.getOrderList.forEach(list => {
              list.Suborders.forEach(suborder => {
                suborder.OrderItems.forEach((items, index) => {
                  this.subName = '';
                  let status = false;
                  let dispatchStatus = false;
                  items.OrderItemStates.forEach(element => {

                    if (element.StateCode === 'DISPATCHED') {
                      if (element.Properties.StateOptionCode === 'DISPATCHED') {
                        dispatchStatus = true;
                      }
                    }

                    if (element.StateCode === 'PRODUCTION_STARTED') {
                      if (element.Properties !== undefined && element.Properties.date !== undefined) {
                        status = true;
                      }
                    }
                    if (element.StateCode === 'LOGO_SKETCH_APPROVED') {
                      if (element.Properties !== undefined) {
                        this.subName = element.Properties.StateOptionCode;
                      }
                    }
                    if (element.StateCode === 'LOGO_SAMPLE_APPROVED') {
                      element.logoSketchType = this.subName;
                    }

                  });
                  items.acceptstatus = status;
                  items.dispatchStatus = dispatchStatus;
                  const getArray = items.OrderItemStates.filter(element => heading.StateCode === element.StateCode);
                  if (getArray.length === 0) {
                    // orderList[index].push(heading);
                    items.OrderItemStates.push(heading);
                  }
                  items.OrderItemStates.sort(function (a, b) {
                    return a.OrderItemStateID - b.OrderItemStateID;
                  });
                });
              });
            });

          });
          sessionStorage.setItem('orderList', JSON.stringify(this.getOrderList));
          this.getActualOrderList = this.getOrderList;
          if (history.state.data !== undefined) {
            if (history.state.data.suborderID !== undefined) {
              var searchData = {
                value: history.state.data.suborderID
              }
            }
          }

          this.getSelectedOrder(searchData);
        }
        else {
          const dropDownList = [{ label: 'Show all', value: 'All' }];
          this.getOrderList.forEach(element => {
            element.OrderItems.forEach(items => {
              if (items.SuborderID === undefined || items.SuborderID === null) {
                dropDownList.push({ label: items.OrderItemIdManual, value: items.OrderItemID });
              }
            });
          });
          this.dropDownList = dropDownList;
          this.activeOrder = this.orderItemId;

          this.listHeading.forEach((heading) => {
            this.getOrderList.forEach(list => {
              list.OrderItems.forEach((items, index) => {
                this.itemName = '';
                let acceptstatus = false;
                let dispatchStatus = false;
                items.OrderItemStates.forEach(element => {
                  if (element.StateCode === 'DISPATCHED') {
                    if (element.Properties.StateOptionCode === 'DISPATCHED') {
                      dispatchStatus = true;
                    }
                  }
                  if (element.StateCode === 'PRODUCTION_STARTED') {
                    if (element.Properties !== undefined && element.Properties.date !== undefined) {
                      acceptstatus = true;
                    }
                  }
                  if (element.StateCode === 'LOGO_SKETCH_APPROVED') {
                    if (element.Properties !== undefined) {
                      this.itemName = element.Properties.StateOptionCode;
                    }
                  }
                  if (element.StateCode === 'LOGO_SAMPLE_APPROVED') {
                    element.logoSketchType = this.itemName;
                  }
                  let status = false;
                  if (element.StateCode === "ORDER_ACCEPTED" && element.Properties !== undefined) {
                    if (element.Properties.StateOptionCode === 'ACCEPT_ORDER') {
                      status = true;
                    }
                  }
                  element.status = status;
                });
                items.acceptstatus = acceptstatus;
                items.dispatchStatus = dispatchStatus;
                const getArry = items.OrderItemStates.filter(element => heading.StateCode === element.StateCode);
                if (getArry.length === 0) {
                  // orderList[index].push(heading);
                  items.OrderItemStates.push(heading);
                }
                items.OrderItemStates.sort(function (a, b) {
                  return a.OrderItemStateID - b.OrderItemStateID;
                });
              });
            });
          });
          sessionStorage.setItem('orderList', JSON.stringify(this.getOrderList));
          this.getActualOrderList = this.getOrderList;
          const searchData = {
            value: this.orderItemId
          }
          this.getSelectedOrder(searchData);
        }
        if (this.getOrderList.length > 0) {
          this.getOrderList[0]['CurrencyCode'] = data.CurrencyCode;
          this.getOrderList[0]['PaymentTypeID'] = data.PaymentTypeID;
          this.getOrderList[0]['OrderAmount'] = data.OrderAmount;
        }


        // this.listHeading.forEach((heading) => {

        //   this.getOrderList.forEach(list => {
        //     list.Suborders.forEach(suborder => {
        //       suborder.OrderItems.forEach((items, index) => {
        //         const getArry = items.OrderItemStates.filter(element => heading.StateCode === element.StateCode);
        //         if (getArry.length === 0) {
        //           // orderList[index].push(heading);
        //           items.OrderItemStates.push(heading);
        //         }
        //         items.OrderItemStates.sort(function (a, b) {
        //           return a.OrderItemStateID - b.OrderItemStateID;
        //         });
        //       });
        //     });

        //     list.OrderItems.forEach((items, index) => {
        //       const getArry = items.OrderItemStates.filter(element => heading.StateCode === element.StateCode);
        //       if (getArry.length === 0) {
        //         // orderList[index].push(heading);
        //         items.OrderItemStates.push(heading);
        //       }
        //       items.OrderItemStates.sort(function (a, b) {
        //         return a.OrderItemStateID - b.OrderItemStateID;
        //       });
        //     });

        //   });

        // });

        // console.log(orderList)


        // sort by value
        // const getSortOrder = this.getOrderList.sort(function (a, b) {
        //   return a.value - b.value;
        // });
        // console.log(getSortOrder)
        // let getPageList = data.TotalItemsCount / this.selectedperpage;
        // getPageList = Math.ceil(getPageList);
        // this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        // const pageLisArray = []
        // for (let i = 1; i <= getPageList; i++) {
        //   pageLisArray.push({ label: i, value: i })
        // }
        // this.pageList = pageLisArray;
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message)
      })
  }

  getOptions(getData) {
  }

  deleteOrder(orderId) {
    this.deleteData = orderId;
    this.deleteData = {
      orderID: orderId
    }
    this.deleteorder.show();
  }

  confirmDelete(status) {
    this.showLoader = true;
    if (status) {
      this.orderHttpService.deleteOrder(this.deleteData).subscribe((data: any) => {
        this.deleteorder.hide();
        if (data) {
          this.toast.success(data);
          // this.backToOrderItem();
          this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
        }
        this.showLoader = false;
      },
        (error) => {
          this.showLoader = false;
          this.toast.error(error.error.Message);
        })
    }
  }

  getSelectedOrder(order) {
    this.getActualOrderList = JSON.parse(sessionStorage.getItem('orderList'));
    if (order !== undefined) {
      if (order.value === 'All') {
        this.getOrderList = this.getActualOrderList;
        return false;
      }
    }

    if (this.isSubOrder) {
      if (order !== undefined) {
        if (order.value !== '') {
          // this.getActualOrderList.forEach((list, index) => {
          //   list.Suborders.forEach((suborderList, index1) => {
          //     const getArry = suborderList.OrderItems.filter(element => element.OrderItemID === order.value);
          //     if (getArry.length > 0) {
          //       this.getOrderList[index].Suborders[index1].OrderItems = getArry;
          //     }
          //   });
          // });

          this.getActualOrderList.forEach((list, index) => {
            const getData = list.Suborders.filter(suborder => suborder.SuborderID === order.value);
            this.getOrderList = getData;
            if (getData.length > 0) {
              this.getOrderList[index].Suborders = getData;
            }
          })

        }
      } else {
        this.getOrderList[0] = this.getActualOrderList;
      }
    } else {
      if (order !== undefined) {
        if (order.value !== '') {
          this.getActualOrderList.forEach((list, index) => {
            const getArry = list.OrderItems.filter(element => element.OrderItemID === order.value);
            if (getArry.length > 0) {
              this.getOrderList[index].OrderItems = getArry;
            }
          });
        }
      } else {
        this.getOrderList[0] = this.getActualOrderList;
      }
    }

  }

  showForm(orderID, orderItems, Order, chart, type, suborder?, getorderData?, showDispatch?) {

    this.showDispatch = showDispatch ? true : false;
    this.checkOrderItems = false;
    this.checkTrackingNo = false;
    this.checkTrackingAll = false;
    //Mutiple Order Items
    this.getOrderList.forEach(element => {
      if (element.OrderItems.length >= 2) {
        this.showMultipleItems = true;
      }
      else {
        this.showMultipleItems = false;
        return;
      }
    })
    this.getActualOrderList.forEach(element => {
      if (element.OrderItems.length >= 2) {
        this.showMultipleOrderItems = true;
      }
      else {
        this.showMultipleOrderItems = false;
        return;
      }
    })
    this.mutipleOrderItems = [];

    const getDate = chart.UpdateTime != undefined ? new Date(chart.UpdateTime) : new Date();
    const request = {
      "OrderItemNumber": chart.OrderItemID != undefined ? chart.OrderItemID : '',
      "date": getDate.toISOString(),
      "StateOptionCode": chart.Properties != undefined ? chart.Properties.StateOptionCode : '',
      "message": chart.Properties != undefined ? chart.Properties.message : ''
    }
    if (this.showMultipleItems == true || this.showMultipleOrderItems == true) {
      this.mutipleOrderItems.push(request);
    }

    if (chart.StateCode === "DISPATCHED" && chart.Properties) {
      if (chart.Properties.StateOptionCode !== undefined && chart.Properties.StateOptionCode !== '') {
        this.updateDispatchDetails = [];
        this.orderData = getorderData;
        this.companyName = getorderData.ShippingCompany;
        this.trackingNumber = getorderData.TrackingNumber;
        if ((this.companyName || this.trackingNumber) && this.showDispatch) {
          this.getOrderList.forEach(element => {
            if (element.OrderItems.length >= 2) {

              element.OrderItems.forEach(suborder => {
                if (suborder.dispatchStatus == true) {
                  this.updateDispatchDetails.push(suborder);
                  if (this.updateDispatchDetails.length >= 2) {
                    this.showTrackingNo = true;
                  }
                }
              })
            }
          })
          this.multipleTrackingNo = [];
          const request = {
            "OrderItemID": chart.OrderItemID,
            "ShippingCompany": this.companyName,
            "TrackingNumber": this.trackingNumber
          }
          this.multipleTrackingNo.push(request);
          this.dispatchModal.show();
        }
      }
    }
    let group: any = {};
    this.orderItem = orderItems;
    this.selectedOrder = Order;
    this.optionsList = chart;
    if (chart.OrderItemID === undefined) {
      this.optionsList.OrderItemID = orderItems[0]['OrderItemID']
    }

    group.status = new FormControl(this.optionsList.Properties !== undefined ? this.optionsList.Properties.StateOptionCode : '');
    group.date = new FormControl(this.optionsList.Properties !== undefined ? new Date(this.optionsList.Properties.date) : new Date());
    group.message = new FormControl(this.optionsList.Properties !== undefined ? this.optionsList.Properties.message : '');
    // if (this.optionsList.StateCode === "USER_PAID") {
    //   group.date = new FormControl(this.optionsList.Properties !== undefined && this.optionsList.Properties.StateOptionCode !== "NOT_PAID" ? new Date(this.optionsList.Properties.date) : new Date());
    // }
    if (this.optionsList.Properties !== undefined) {
      const getStatus = this.optionsList.Options.filter(data => data.StateOptionCode === this.optionsList.Properties.StateOptionCode);
      this.getOrderStatus = getStatus.length > 0 ? getStatus[0]['StateOptionName'] : '';
    }
    this.stateForm = new FormGroup(group);
    if (type === 'suborder') {
      const urlData = {
        orderID: Order.OrderID,
        orderItemID: this.orderItem[0]['OrderItemID'],
        isSubOrder: true,
        suborderID: suborder.SuborderID
      }
      if (this.optionsList.StateCode === 'PRODUCTION_COMPLETED' || this.optionsList.StateCode === 'PRODUCTION_STARTED') {
        this.router.navigate([`/admin-order-production-line-details/${this.orderItem[0]['ProductID']}/false`], { state: { urlData }, replaceUrl: false });
      }

      // orderItems.forEach(Suborders => {
      this.updateStatus(orderItems, Order, chart);
      // });

    }
    else {
      this.updateStatus(orderItems, Order, chart);
    }

  }

  updateStatus(orderItems, Order, chart) {

    this.orderAcceptStatus = false;
    this.orderAccepted = false;
    this.statusCodeLogoSample = true;
    orderItems.forEach(OrderedItemDetail => {
      if (OrderedItemDetail.OrderItemStates.length > 0) {
        // var itemOrderStatus = OrderedItemDetail.OrderItemStates;
        OrderedItemDetail.OrderItemStates.forEach((statusDetails: any) => {
          if (statusDetails.StateCode === 'DISPATCHED') {
            if (statusDetails.Properties.StateOptionCode === 'DISPATCHED') {
              this.enableDeliveryModal = true;
            }
          }

          if (statusDetails.StateCode === "LOGO_SKETCH_APPROVED") {
            statusDetails.Options.forEach(element => {
              if (element.StateOptionCode === 'ENABLE_LOGO_SAMPLE') {
                this.statusCodeLogoSample = false;
              }
            });
          }
          if (statusDetails.StateCode === "ORDER_ACCEPTED") {
            statusDetails.Options.forEach(element => {
              if (element.StateOptionCode === 'ACCEPT_ORDER') {
                this.orderAccepted = true;
              }
            })
          }
          const status = statusDetails.StateCode + '_status';
          statusDetails[status] = false;
          // this.orderAcceptStatus = false;
          // orderAcceptStatus = false;
          statusDetails['ORDER_ACCEPTED_status'] = false;
          if (statusDetails.StartTime !== statusDetails.UpdateTime) {
            statusDetails[status] = true;
            statusDetails['ORDER_ACCEPTED_status'] = true;
            this.orderAcceptStatus = true;
            if (statusDetails.StateCode === 'DISPATCHED') {
              this.dispatchedStatus = true;
              statusDetails[status] = true;
              this.orderAcceptStatus = true;
              statusDetails['DISPATCHED_status'] = true;
              statusDetails['ORDER_ACCEPTED_status'] = true;
            }
          }
          if (statusDetails.StateCode == 'PRODUCTION_COMPLETED') {
            this.orderAcceptStatus = true;
            statusDetails[status] = true;
          }
        });
      }
    });


    if (this.isSubOrder) {

      if (!this.showDispatch && chart.StateCode === "DISPATCHED") {
        this.orderaccepted.show();
      }

      if (((Order.PaymentTypeID == 3 || chart.StateCode == 'DELIVERED') && (this.enableDeliveryModal)) || ((chart.StateCode == 'LOGO_SKETCH_APPROVED') && this.orderAcceptStatus) || ((chart.StateCode == 'LOGO_SAMPLE_APPROVED') && this.orderAcceptStatus) || ((chart.StateCode == 'USER_PAID') && this.orderAcceptStatus) || ((chart.StateCode == 'WEBSHOP_PAID') && (Order.PaymentTypeID == 3 || chart.USER_PAID_status)) || ((chart.StateCode == 'LINKNETWORK_PAID') && (Order.PaymentTypeID == 3 || chart.USER_PAID_status)) || chart.StateCode === 'ORDER_ACCEPTED' && chart.Properties !== undefined && chart.Properties.StateOptionCode === 'ACCEPT_ORDER') {
        if (!this.showDispatch) {
          // if (chart.StateCode === "DISPATCHED" && chart.Properties.StateOptionCode === "DISPATCHED") {
          //   // this.backToOrderItem();
          //   return false;
          // }
          this.orderaccepted.show();
        }
      }
      else {
        // alert('fail')
      }
    }
    else {
      // alert('main')

      // (Order.PaymentTypeID == 3) && !(OrderItem.isInternalStockProduct && (orderStatusData.StateCode=='LOGO_SKETCH_APPROVED' || orderStatusData.StateCode=='LOGO_SAMPLE_APPROVED')) &&  orderStatusData.StateCode!='DISPATCHED' && !this['StateOptionCodes_'+orderStatusData.StateCode+'_'+ OrderItem.OrderItemID] && this['orderAccept_'+OrderItem.OrderItemID] || orderStatusData.StateCode=='ORDER_ACCEPTED' && (Order.PaymentAuthorizationStatusCode=='VERIFIED' || !Order.PaymentAuthorizationStatusCode)
      if ((Order.PaymentTypeID == 3) && !(orderItems.isInternalStockProduct && (chart.StateCode == 'LOGO_SKETCH_APPROVED' || chart.StateCode == 'LOGO_SAMPLE_APPROVED')) && chart.StateCode != 'DISPATCHED' && !this.statusCodeLogoSample && this.orderAccepted || chart.StateCode == 'ORDER_ACCEPTED' && (Order.PaymentAuthorizationStatusCode == 'VERIFIED' || !Order.PaymentAuthorizationStatusCode) || chart.StateCode === 'ORDER_ACCEPTED' && chart.Properties !== undefined && chart.Properties.StateOptionCode === 'ACCEPT_ORDER') {
        if (!this.showDispatch) {
          // if (chart.StateCode === "DISPATCHED" && chart.Properties.StateOptionCode === "DISPATCHED") {
          //   // this.backToOrderItem();
          //   return false;
          // }
          this.orderaccepted.show();
        }
      }
      else {
        const getSelOrder = orderItems.filter(get => get.OrderItemID === chart.OrderItemID);
        const status = getSelOrder[0]['OrderItemStates'].filter(element => element.StateCode === "ORDER_ACCEPTED");
        if (status[0]['Properties']['StateOptionCode'] === 'ACCEPT_ORDER') {
          if (((Order.PaymentTypeID == 3 || chart.StateCode == 'DELIVERED') && (chart.DISPATCHED_status)) || ((chart.StateCode == 'LOGO_SKETCH_APPROVED') && this.orderAcceptStatus) || ((chart.StateCode == 'LOGO_SAMPLE_APPROVED') && this.orderAcceptStatus) || ((chart.StateCode == 'USER_PAID') && this.orderAcceptStatus) || ((chart.StateCode == 'WEBSHOP_PAID') && (Order.PaymentTypeID == 3 || chart.USER_PAID_status)) || ((chart.StateCode == 'LINKNETWORK_PAID') && (Order.PaymentTypeID == 3 || chart.USER_PAID_status))) {
            if (!this.showDispatch) {
              // if (chart.StateCode === "DISPATCHED" && chart.Properties.StateOptionCode === "DISPATCHED") {
              //   // this.backToOrderItem();
              //   return false;
              // }
              this.orderaccepted.show();
            }
          }
          else {
            // alert('fail')
          }
        }
        else {
          // alert('fail12');
        }


      }
    }

  }

  updateState() {
    let request = {
      "message": this.stateForm.value.message,
      "date": this.stateForm.value.date,
      "StateOptionCode": this.stateForm.value.status
    }
  }

  stateSubmit(orderData) {

    this.showLoader = true;
    if (this.stateForm.value.date !== undefined && this.stateForm.value.date !== '') {
      this.stateForm.value.date = moment(this.stateForm.value.date, "DD/MM/YYYY").format("YYYY/MM/DD");
    }
    if ((this.showMultipleItems == true || this.showMultipleOrderItems == true) && this.mutipleOrderItems.length == 0) {
      this.toast.error('Please select at least 1 product');
      this.showLoader = false;
      return;
    }
    if (this.mutipleOrderItems.length != 0) {
      if (this.stateForm.value.date !== undefined && this.stateForm.value.date !== '') {
        for (var item of this.mutipleOrderItems) {
          item.date = this.stateForm.value.date;
        }
      }
    }
    const getDate = new Date(this.stateForm.value.date);
    let request: any = {
      "date": getDate.toISOString(),
      "StateOptionCode": this.stateForm.value.status
    }
    if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
      request.message = this.stateForm.value.message
    }
    const requestID = {
      OrderItemID: orderData.OrderItemID,
      OrderItemStateID: orderData.OrderItemStateID
    }

    if (this.mutipleOrderItems.length != 0) {
      this.orderHttpService.multipleOrderItemState(requestID, this.mutipleOrderItems).subscribe(data => {
        this.orderaccepted.hide();
        this.mutipleOrderItems = [];
        // setTimeout(() => {
        this.ordersList();
        // }, 1000);
        this.toast.success('Order status changed successfully');
        if (orderData.StateCode === "ORDER_ACCEPTED") {
          this.backToOrderItem();
        }
      },
        (error) => {
          this.showLoader = false;
          this.orderaccepted.hide();
          this.toast.error(error.error.Message);
        })
    }
    else {
      this.orderHttpService.orderItemState(requestID, request).subscribe(data => {
        this.orderaccepted.hide();
        // setTimeout(() => {
        this.ordersList();
        // }, 1000);
        this.toast.success('Order status changed successfully');
        if (orderData.StateCode === "ORDER_ACCEPTED") {
          this.backToOrderItem();
        }
      },
        (error) => {
          this.showLoader = false;
          this.orderaccepted.hide();
          this.toast.error(error.error.Message);
        })
    }
  }

  //*** Mutiple Order Items Value

  optionChange(event) {
    if (this.mutipleOrderItems.length != 0) {
      for (var item of this.mutipleOrderItems) {
        item.StateOptionCode = this.stateForm.value.status;
      }
    }
  }

  messageMutipleOrderItems() {
    if (this.mutipleOrderItems.length != 0) {
      if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
        for (var item of this.mutipleOrderItems) {
          item.message = this.stateForm.value.message;
        }
      }
    }
  }

  updateCalcs(event) {

    if (this.mutipleOrderItems.length != 0) {
      if (this.stateForm.value.date !== undefined && this.stateForm.value.date !== '') {
        for (var item of this.mutipleOrderItems) {
          item.date = new Date(this.stateForm.value.date);
        }
      }
    }
  }
  // ***/

  editOrder(orderData, isSubOrder) {
    let isSubOrderStatus = isSubOrder;
    if (orderData.SuborderID !== undefined && orderData.SuborderID !== null) {
      isSubOrderStatus = true;
    }
    const orderDatas = {
      'orderID': this.orderId,
      'orderItemID': orderData.OrderItemID,
      'isSubOrder': isSubOrderStatus,
      'suborderID': orderData.SuborderID
    }
    this.router.navigate(['/edit-orderitems'], { state: { data: orderDatas }, replaceUrl: false });
  }

  backToOrderItem() {
    const customerID = history.state.customerID;
    const lineId = history.state.lineId;
    const finishedPL = history.state.finishedPL;
    if (history.state.type === "productionline") {
      this.router.navigate([`/admin-order-production-line-details/${history.state.data.productID}/${finishedPL}`],
        { state: { customerID }, replaceUrl: false });
    } else if (history.state.type === 'orderOverviewFromProductionLine' || history.state.type === 'orderFromProductionLine') {
      const subOrderID = history.state.data.orderItemID || history.state.data.suborderID;
      this.router.navigate([`/admin-order-process/orderProcess/${this.orderId}/${subOrderID}/${0}`],
        {
          state: {
            lineId,
            customerID,
            subOrderItemData: history.state.subOrderItemData,
            type: history.state.type,
            finishedPL
          }, replaceUrl: false
        });
    } else if (history.state.type === 'orderOverview') {
      const lineId = history.state.lineId;
      const subOrderID = history.state.data.orderItemID || history.state.data.suborderID;
      const isSubOrder = this.isSubOrder;
      this.router.navigate([`/admin-order-process/orderProcess/${this.orderId}/${subOrderID}/${0}`],
        { state: { lineId, customerID, finishedPL, subOrderItemData: history.state.subOrderItemData, isSubOrder }, replaceUrl: false });
    } else {
      let id = this.orderId;
      this.router.navigate([`/admin-order-item/${id}`], {
        state: { id, customerID, lineId, finishedPL },
        replaceUrl: false
      });
    }
  }

  viewDetail(detail) {
    if (this.isSubOrder) {
      this.router.navigate([`admin-order-process/orderProcess/${this.orderId}/${detail.SuborderID}/${0}`], { replaceUrl: false });
    } else {
      this.router.navigate([`admin-order-process/orderProcess/${this.orderId}/${detail.OrderItemID}/${0}`], { replaceUrl: false });
    }
  }

  enableAcceptOrder(state) {
    // console.log(state);
    // let status = false;
    // if (state.StateCode === 'PRODUCTION_STARTED') {
    //   if (state.Properties !== undefined && state.Properties.date !== undefined) {
    //     status = true;
    //   }
    // }
    // if (state.StateCode === 'ORDER_ACCEPTED') {
    return status;
    // }
  }

  updateDispatch() {

    this.showLoader = true;
    const request = {
      "OrderItemID": this.orderData.OrderItemID,
      "ShippingCompany": this.companyName,
      "TrackingNumber": this.trackingNumber
    }
    if (this.showMultipleItems == true && this.multipleTrackingNo.length == 0) {
      this.toast.error('Please select at least 1 product');
      this.showLoader = false;
      return;
    }
    if (this.multipleTrackingNo.length != 0) {
      this.orderHttpService.updatedispatch(this.multipleTrackingNo).subscribe((res: any) => {
        this.showLoader = false;
        if (res) {
          this.dispatchModal.hide();
          this.toast.success('Updated successfully');
          this.ordersList();
          this.multipleTrackingNo = [];
        }
      },
        (error) => {
          this.showLoader = false;
        })
    }
    else {
      this.multipleTrackingNo.push(request);
      this.orderHttpService.updatedispatch(this.multipleTrackingNo).subscribe((res: any) => {
        this.showLoader = false;
        if (res) {
          this.dispatchModal.hide();
          this.toast.success('Updated successfully');
          this.ordersList();
          this.multipleTrackingNo = [];
        }
      },
        (error) => {
          this.showLoader = false;
        })
    }

  }

  eventHandler(event) {
    if (event.keyCode === 32) {
      return false;
    }
  }

  dispatchCompanyName() {
    if (this.multipleTrackingNo.length > 0) {
      for (var companyName of this.multipleTrackingNo) {
        companyName.ShippingCompany = this.companyName;
      }
    }
  }

  dispatchTrackingNo() {
    if (this.multipleTrackingNo.length > 0) {
      for (var trackingNo of this.multipleTrackingNo) {
        trackingNo.TrackingNumber = this.trackingNumber;
      }
    }
  }

  //Mutiple Tracking No
  addMutipleTrackingNumber(event, order) {

    const request = {
      "OrderItemID": order.OrderItemID,
      "ShippingCompany": this.companyName,
      "TrackingNumber": this.trackingNumber
    }
    if (event.checked) {
      this.multipleTrackingNo.push(request);
    }
    else {
      const index = this.multipleTrackingNo.findIndex((ch) => ch.OrderItemID === order.OrderItemID);
      this.multipleTrackingNo.splice(index, 1);
    }
  }

  cancelDispatchModal() {
    this.multipleTrackingNo = [];
  }

  //Multiple orders action
  checkMutipleItems(event, order) {

    const getDate = new Date(this.stateForm.value.date);
    const request = {
      "OrderItemNumber": order.OrderItemID,
      "date": getDate.toISOString(),
      "StateOptionCode": this.stateForm.value.status,
      "message": null
    }
    if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
      request.message = this.stateForm.value.message
    }
    if (event.checked) {
      this.mutipleOrderItems.push(request);
    }
    else {
      const index = this.mutipleOrderItems.findIndex((ch) => ch.OrderItemNumber === order.OrderItemID);
      this.mutipleOrderItems.splice(index, 1);
    }
  }

  cancelMultipleOrderItems() {
    this.mutipleOrderItems = [];
  }

  checkAllOrderItems(event, optionsList) {

    if (event.checked) {
      this.checkOrderItems = true;
      this.checkTrackingAll = true;
      for (var item of this.getOrderList) {
        for (var orderItems of item.OrderItems) {
          const getDate = new Date(this.stateForm.value.date);
          const request = {
            "OrderItemNumber": orderItems.OrderItemID,
            "date": getDate.toISOString(),
            "StateOptionCode": this.stateForm.value.status,
            "message": null
          }
          if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
            request.message = this.stateForm.value.message
          }
          var found = this.mutipleOrderItems.filter(x => x.OrderItemNumber === orderItems.OrderItemID);
          if (found.length == 0) {
            this.mutipleOrderItems.push(request);
          }
        }
      }
    }
    else {
      this.checkOrderItems = false;
      this.mutipleOrderItems = [];
      const getDate = new Date(this.stateForm.value.date);
      const request = {
        "OrderItemNumber": optionsList.OrderItemID,
        "date": getDate.toISOString(),
        "StateOptionCode": this.stateForm.value.status,
        "message": null
      }
      if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
        request.message = this.stateForm.value.message
      }
      this.mutipleOrderItems.push(request);
    }
  }


  checkAllTrackingNo(event, optionsList) {

    if (event.checked) {
      this.checkTrackingNo = true;
      this.checkTrackingAll = true;
      for (var item of this.updateDispatchDetails) {
        const request = {
          "OrderItemID": item.OrderItemID,
          "ShippingCompany": this.companyName,
          "TrackingNumber": this.trackingNumber
        }
        var found = this.multipleTrackingNo.filter(x => x.OrderItemNumber === item.OrderItemID);
        if (found.length == 0) {
          this.multipleTrackingNo.push(request);
        }
      }
    }
    else {
      this.checkTrackingNo = false;
      this.multipleTrackingNo = [];
      const request = {
        "OrderItemID": optionsList.OrderItemID,
        "ShippingCompany": this.companyName,
        "TrackingNumber": this.trackingNumber
      }
      this.multipleTrackingNo.push(request);
    }
  }


  checkAllNotDecidedItems(event, optionsList) {

    if (event.checked) {
      this.checkOrderItems = true;
      this.checkTrackingAll = true;
      for (var item of this.getActualOrderList) {
        for (var orderItems of item.OrderItems) {
          const getDate = new Date(this.stateForm.value.date);
          const request = {
            "OrderItemNumber": orderItems.OrderItemID,
            "date": getDate.toISOString(),
            "StateOptionCode": this.stateForm.value.status,
            "message": null
          }
          if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
            request.message = this.stateForm.value.message
          }
          var found = this.mutipleOrderItems.filter(x => x.OrderItemNumber === orderItems.OrderItemID);
          if (found.length == 0) {
            this.mutipleOrderItems.push(request);
          }
        }
      }
    }
    else {
      this.checkOrderItems = false;
      this.mutipleOrderItems = [];
      const getDate = new Date(this.stateForm.value.date);
      const request = {
        "OrderItemNumber": optionsList.OrderItemID,
        "date": getDate.toISOString(),
        "StateOptionCode": this.stateForm.value.status,
        "message": null
      }
      if (this.stateForm.value.message !== undefined && this.stateForm.value.message !== '') {
        request.message = this.stateForm.value.message
      }
      this.mutipleOrderItems.push(request);
    }
  }



}
