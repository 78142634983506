import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BlockState } from '../reducers/block.reducer';
import { BlockActions } from '../actions/index';
import { getBlockListSuccess, getBlockUpdateSuccess } from '../reducers/index';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Injectable()
export class BlockService {
    constructor(private store: Store<BlockState>, private apiService: ApiService) { }

    loadBlocks(searchCriteria: any) {
        this.store.dispatch(new BlockActions.LoadBlock(searchCriteria));
    }

    getBlockList() {
        return this.store.select(getBlockListSuccess);
    }

    saveBlock(data) {
        this.store.dispatch(new BlockActions.SaveBlock(data));
    }

    getBlockUpdateSuccess() {
        return this.store.select(getBlockUpdateSuccess);
    }

    addProductToBlock(blockId, productId) {
        const url = `en/productblocks/${blockId}/${productId}`;
        return this.apiService.post(url);
    }

    removeProductFromBlock(blockId, productId) {
        const url = `en/productblocks/${blockId}/${productId}`;
        return this.apiService.delete(url);
    }
}
