import { Component, OnInit } from "@angular/core";
import { UserListHttpService } from '../state/services/user.http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rewardkart-admin-points-summary',
    templateUrl: './points-summary.component.html',
    styleUrls: ['./points-summary.component.scss']
})

export class PointsSummaryComponent implements OnInit {
    userId: any;
    pointsSummary: any = [];
    datacount: any;
    constructor(private userListHttpService: UserListHttpService, private translate: TranslateService) {
        const searchResult = sessionStorage.getItem('searchresult')
        if (searchResult !== null) {
            if (history.state.data !== undefined) {
                this.userId = history.state.data.UserID;
            }
        }
    }
    ngOnInit(): void {
        const pages = {
            'userid': this.userId,
            'pageIndex': 1,
            'pageSize': 20
        }
        this.userListHttpService.getPointsSummary(pages).subscribe((data) => {
            this.pointsSummary = data['Summary'];
            this.datacount = this.pointsSummary.length;
        });
    }
}