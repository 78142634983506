import { Component, OnInit } from '@angular/core';
import { UserHttpService } from '../user/state/services/user.http.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
    subscriptionDetails: any;
    customerID: any;
    header: string = 'Subscription Details';
    planID: any;
    isLoading: boolean;
    isParentCustomer: boolean = true;
    constructor(private _http: UserHttpService, private router: Router, private toastr: ToastService) {
        const webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.customerID = webshop.CustomerID;
            if (webshop.ParentCustomerDomainName === webshop.DomainName) {
                this.isParentCustomer = true;
            } else {
                this.isParentCustomer = false;
            }
        }
        if (history.state.payment && history.state.payment === 'success') {
            this.planID = sessionStorage.getItem('subscriptionid');
            this.updateSubscription();
        }
    }
    ngOnInit(): void {
        this.getDetails();
    }

    getDetails() {
        this._http.getSubcriptionValidityDetails(this.customerID).subscribe((data) => {
            this.subscriptionDetails = data[0];
        })
    }

    renew() {
        this.router.navigate(['/admin-subscription-list'], {
            state: this.subscriptionDetails,
            replaceUrl: true,
        });

    }

    updateSubscription() {
        this.isLoading = true;
        this._http.upgradeSubscription(this.customerID, this.planID).subscribe((data) => {
            sessionStorage.removeItem('subscriptionid');
            this.isLoading = false;
            this.toastr.success('Plan Subscribed Successfully');
        })
    }
}