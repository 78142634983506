import { Action } from '@ngrx/store';
export const LOAD_WEBSHOPS = '[Webshop] Load all Webshops';
export const LOAD_WEBSHOPS_SUCCESS = '[Webshop] Load Webshops Success';
export const LOAD_WEBSHOPS_FAIL = '[Webshop] Load Webshops Fail';

export class LoadWebshop implements Action {
    readonly type = LOAD_WEBSHOPS;
}

export class LoadWebshopSuccess implements Action {
    readonly type = LOAD_WEBSHOPS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadWebshopFail implements Action {
    readonly type = LOAD_WEBSHOPS_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | LoadWebshop
    | LoadWebshopSuccess
    | LoadWebshopFail;

