import { Component, OnInit, SimpleChange } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/common-services/api.service';
import { DashboardHttpService } from './state/services/dashboard.http.service';
import { LoginService } from '../login/state/services/login.service';
import { LoginHttpService } from '../login/state/services/login.http.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public header: any;
  public dashboardList: any;
  public productionLine: any = [];
  public customerList: any = [];
  showLoader: boolean = false;
  selectState: any = [{ label: 'test', value: 'test' }, { label: 'test1', value: 'test1' }]
  selectedValue: any;
  customerID: any;

  constructor(public translate: TranslateService, public router: Router, public dashboardhttpservice: DashboardHttpService,
    public apiService: ApiService, private loginService: LoginService, private loginHttpService: LoginHttpService) {

    const webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.customerID = webshop.CustomerID
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.customerID = loginData.CustomerID;
      }
    }

  }

  ngOnInit() {
    this.getAllCustomerList();
    if (sessionStorage.getItem('dashboardRedirectStatus') !== undefined && sessionStorage.getItem('dashboardRedirectStatus') !== null) {
      sessionStorage.removeItem('dashboardRedirectStatus');
    }
    if (sessionStorage.getItem('productionlineRedirectStatus') !== undefined &&
      sessionStorage.getItem('productionlineRedirectStatus') !== null) {
      sessionStorage.removeItem('productionlineRedirectStatus');
    }
    if (sessionStorage.getItem('status') && sessionStorage.getItem('status') === 'Y') {
      if (sessionStorage.getItem('subscriptionid')) {
        let payment = "success";
        setTimeout(() => {
          // this.router.navigate(['/admin-subscription'], { state: { payment } })
        }, 1500);
      }
      if (sessionStorage.getItem('adduser')) {
        let payment = "success";
        setTimeout(() => {
          this.router.navigate(['/admin-user-add'], { state: { payment } })
        }, 1500);
      }
    }
  }

  Dashboardpendinguser() {
    const selectedSearchKey = sessionStorage.getItem('searchkey');
    if (selectedSearchKey !== null && selectedSearchKey !== undefined) {
      sessionStorage.removeItem('searchkey');
    }
    let fields = JSON.parse(sessionStorage.getItem('searchresult'));
    if (fields !== null && fields !== undefined && fields !== '') {
      const index = fields.findIndex(item => item.key === 'pendinguserlist');
      fields[index]['selected'] = true;
      fields[index]['data'] = true;
      sessionStorage.setItem('searchresult', JSON.stringify(fields));
    }
    const data = { 'isPendingUser': true };
    this.router.navigate(['/admin-user-list'], { state: { data }, replaceUrl: false });

  }

  goToRecentOrders() {
    this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
  }

  goToMsgInbox() {
    this.router.navigate(['/app-message-inbox'], { replaceUrl: false });
  }

  getAllCustomerList() {
    if (sessionStorage.getItem('parentCustomerList') !== undefined && sessionStorage.getItem('parentCustomerList') !== null
      && sessionStorage.getItem('parentCustomerList') !== '') {
      this.customerList = JSON.parse(sessionStorage.getItem('parentCustomerList'));
    } else {
      this.showLoader = true;
      this.dashboardhttpservice.getParentCustomersList(this.customerID).subscribe((data: any) => {
        if (data) {
          this.showLoader = false;
          this.customerList = data;
          sessionStorage.setItem('parentCustomerList', JSON.stringify(data));
        }
      },
        _err => {
          //console.log(err);
          this.showLoader = false;
        });
    }
  }


}
