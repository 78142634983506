import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductSelectComponent } from './product-select.component';
import { AdvancedSearchModule } from '../product-adv-search/product-adv-search.module';
import { ProductGridModule } from '../product-grid/product-grid.module';

@NgModule({
  declarations: [ProductSelectComponent],
  imports: [CommonModule, AdvancedSearchModule, ProductGridModule],
  exports: [ProductSelectComponent, AdvancedSearchModule]
})
export class ProductSelectModule { }
