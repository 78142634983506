import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportAbstract } from '../services/report-abstract';
import { ReportsHttpService } from '../services/reports-http.service';
import { CommonFilterComponent } from 'src/app/common/common-filter/common-filter.component';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-report-internal-stock-products',
  templateUrl: './report-internal-stock-products.component.html',
  styleUrls: ['./report-internal-stock-products.component.scss']
})
export class ReportInternalStockProductsComponent extends ReportAbstract
  implements OnInit {
  header = 'Internal Stock Products';
  excludeKeyInUrlParam = ['languageID', 'CustomerID', 'stockBalanceDate'];
  currencies = [];
  languages = [];
  selectedLanguage = '';
  noProductFoundText = 'No products found.';
  selectedCurrency = null;
  filterFields = [];
  downloadReport = 'Download Report';
  commonRemoveFilter: any;
  stockBalanceDate = '';
  customerLoader = false;
  customerList = [];
  selectedCustomerID = '';
  reportData = null;
  @ViewChild('commonFilter', { static: true }) commonFilter: CommonFilterComponent;
  webshopDetail: any;
  selectAll: Boolean = false;
  currencyFetched: Boolean = false;
  searchCriteria: any = {};
  constructor(public reportHttpService: ReportsHttpService, private toaster: ToastService) {
    super();
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    this.searchCriteria = {
      selectedCustomerID: '',
      selectedCurrency: '',
      selectedLanguage: '',

    }
    this.getLanguages(this.webshopDetail.CustomerID);

  }

  getLanguages(CustomerID) {
    this.reportHttpService.getLanguages(CustomerID).subscribe(
      (languageResponse: any) => {
        if (languageResponse) {
          this.languages = languageResponse.map(language => ({
            value: language.Code,
            label: language.Name
          }));
          this.updateSearchFormFields('languageID', this.languages);
          if (this.currencyFetched === false) {
            this.getCurrencies();
            this.currencyFetched = true;
          }

        }
      },
      error => {
        this.toaster.error('unable to get language');
      }
    );
  }

  getCustomers() {
    this.customerLoader = true;
    this.reportHttpService.getWebshopCustomers(this.webshopDetail.CustomerID).subscribe(
      (customersRes: any) => {
        if (customersRes) {
          this.selectedCustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
          const customerlist = [{ label: 'Select All', value: this.webshopDetail.CustomerID }];
          customersRes.forEach(cust => {
            let custData = {
              label: cust.Name,
              value: cust.CustomerID
            }
            customerlist.push(custData);
          });
          this.customerList = customerlist;
          this.updateSearchFormFields('CustomerID', this.customerList);
          this.setFilterData();
        }
        this.customerLoader = false;
      },
      error => {
        this.customerLoader = false;
        this.toaster.error('unable to get webshop customers');
      }
    );
  }

  buildReportURL(parameter, formData) {
    this.selectedLanguage = (this.selectedLanguage ? this.selectedLanguage : 'en');
    return `${this.selectedLanguage}/reports/InternalStockProductsReport?${parameter}${this.selectedCustomerID}${this.stockBalanceDate}`;
  }
  updateSearchFormFields(key: any, value: any) {
    if (this.commonFilter && this.commonFilter.filterFormField !== undefined) {
      this.commonFilter.filterFormField.forEach((field) => {
        if (field.key === key) {
          field.data = value;
          field.selected = this.searchCriteria[field.key];
        }
      });
    }
  }


  handleSubmit(formData) { }

  handleResetForm(event) {
    this.filterFields.forEach(element => {
      if (event[element.key] !== undefined) {
        if (event[element.key] === 'simulatePrimeCargoStockBalance') {
          event[element.key].value = event[element.key].value;
        } else {
          event[element.key].value = '';
        }
      }
    });
    if (this.selectedCustomerID !== '') {
      this.getLanguages(this.webshopDetail.CustomerID);
    }
    this.selectedCustomerID = '';
    this.stockBalanceDate = '';
    this.selectedLanguage = '';
    if (this.reportData && this.reportData != null) {
      this.reportData = null;
    }
  }

  getSelectData(filterResult) {
    debugger
    const { languageID, stockBalanceDate, CustomerID } = filterResult;
    this.selectedLanguage = (languageID ? languageID.value : 'en') || 'en';
    if (parseInt(this.selectedCustomerID.split('=')[1]) != filterResult.CustomerID.value) {
      filterResult.languageID = undefined;
      // filterResult.languageID.value = '';
      this.selectedLanguage = '';
      for (var item of this.commonFilter.filterFormField) {
        if (item.label == 'Language *') {
          item.selected = undefined;
          break;
        }
      }
      this.commonFilter.validateRequiredField();
    }

    if (stockBalanceDate && stockBalanceDate.value !== '') {
      const formatedDate = this.reportHttpService.dateFormatchange(stockBalanceDate.value);
      this.stockBalanceDate = `&${stockBalanceDate.selectMethod}=${formatedDate}`;
    }
    if (CustomerID && CustomerID.value !== '' && CustomerID.value !== 0) {
      if (this.selectedCustomerID !== `&${CustomerID.selectMethod}=${CustomerID.value}` || this.selectedCustomerID == '') {
        this.selectedCustomerID = `&${CustomerID.selectMethod}=${CustomerID.value}`;
        this.selectAll = false;
        this.getLanguages(CustomerID.value)
      }
    } else if (CustomerID && CustomerID.value === 0) {
      this.selectedCustomerID = `&CustomerID=${this.webshopDetail.CustomerID}`;
      if (this.selectAll == false) {
        this.getLanguages(this.webshopDetail.CustomerID)
        this.selectAll = true;
      }
    }
  }

  getCurrencies() {
    this.reportHttpService.getCurrencyList(this.webshopDetail.CustomerID).subscribe((data: any) => {
      this.currencies = data.map(element => ({
        value: element.CurrencyID,
        label: element.Name
      }));
      this.getCustomers();
    });
  }

  setFilterData() {
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12 p1-3 pt-4',
        key: 'filterHeading',
        filterkey: ''
      }];
    const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
    if (getCustomerDetails !== null) {
      if (getCustomerDetails.ParentCustomerID === null || getCustomerDetails.ParentCustomerID === undefined || getCustomerDetails.ParentCustomerID === '') {
        this.filterFields.push(
          {
            label: 'Select Webshop',
            type: 'select',
            data: this.customerList,
            class: 'col-md-12 no-icon',
            key: 'CustomerID',
            filterkey: 'CustomerID',
            selected: this.searchCriteria.selectedCustomerID
          }
        );
      }
    }
    this.filterFields.push(
      {
        label: 'Language *',
        type: 'select',
        class: 'col-md-12',
        key: 'languageID',
        data: this.languages,
        selected: this.searchCriteria.selectedLanguage,
        required: true,
        filterkey: 'languageID'
      },
      {
        label: 'Currency',
        type: 'select',
        class: 'col-md-12',
        key: 'currencyID',
        data: this.currencies,
        selected: this.searchCriteria.selectedCurrency,
        filterkey: 'currencyID'
      },
      {
        label: 'Stock Balance Date',
        type: 'date',
        class: 'col-md-12',
        key: 'stockBalanceDate',
        format: {
          dateFormat: 'dd/mm/yyyy',
          closeAfterSelect: true,
        },
        selected: '',
        filterkey: 'stockBalanceDate'
      },
      {
        label:
          'Simulate Prime Cargo Stock Balance (only dispatched orders will be taken into account)',
        type: 'checkbox',
        class: 'col-md-12',
        key: 'simulatePrimeCargoStockBalance',
        selected: true,
        alwaysEnable: true,
        filterkey: 'simulatePrimeCargoStockBalance'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      }
    );
  }
}
