import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/common-services/api.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportsHttpService {
  // languages: any;
  webshopDetail: any;
  userDetails: any;
  constructor(private apiService: ApiService) {
    this.userDetails = JSON.parse(sessionStorage.getItem('userdetails'));
    // this.languages = this.getLanguages();
  }

  getWebshopCustomers(customerID) {
    const path = 'en/customers/list?ParentCustomerID=' + customerID + '&returnParentCustomer=true';
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getUsermisReport(params) {
    const path = 'en/reports/GetUserCampaignOrdersReport?FromDate=' + params.fromDate + '&ToDate=' + params.toDate + '&CampaignID=' + params.campaignID + '&DomainName=' + params.domainName + '&UserName=' + params.userName + '&CustomerName=' + params.customerName +'&CustomerId=' + params.customerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getUseractivityReport(params) {
    const path = 'en/reports/GetUserActivityReport?FromDate=' + params.fromDate + '&ToDate=' + params.toDate + '&CampaignID=' + params.campaignID + '&DomainName=' + params.domainName + '&UserName=' + params.userName + '&CustomerName=' + params.customerName +'&UserID=' + params.userID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getOrderDetails(params) {
    const path = 'en/reports/GetActivityOrderDetails?OrderNo=' + params;
    return this.apiService.get2(path).map((res) => {
      return res;
    });
  }

  getUserCampaignReport(params) {
    const path = 'en/reports/GetUserCampaignReport?FromDate=' + params.fromDate + '&ToDate=' + params.toDate + '&CampaignID=' + params.campaignID + '&DomainName=' + params.domainName + '&UserName=' + params.userName + '&CustomerName=' + params.customerName +'&UserID=' + params.userID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getBudgetReport(params) {
    const path = 'en/reports/GetBudgetReport?CampaignID=' + params.campaignID + '&DomainName=' + params.domainName + '&CustomerId=' + params.customerID+'&UserID='+ this.userDetails.UserID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getPaymentReport(params) {
    let user = JSON.parse(sessionStorage.getItem('userdetails'));
    const path = 'en/reports/GetPaymentReport?FromDate=' + params.FromDate + '&ToDate=' + params.ToDate + '&UserName=' + params.UserName +'&Email=' + params.Email+'&Mobile=' + params.Mobile+'&UserId=&OrderID=' + params.OrderID+'&DomainName=' + user.DomainName;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getCampaignUsageReport(params) {
    const path = 'en/reports/GetCampaignUsageReport?CampaignID=' + params.campaignID + '&DomainName=' + params.domainName + '&CustomerId=' + params.customerID + '&pageSize=' + params.pageSize + '&pageIndex=' + params.activePage; 
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getOrderFulfilmentReport(params) {
    const path = 'en/reports/GetOrderFulfilmentReport?FromDate=' + params.fromDate + '&ToDate=' + params.toDate + '&DeliveryStatus=' + params.deliveryStatus+ '&CustomerId=' + params.customerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getUserManagementReport(params) {
    const path = 'en/reports/GetUserManagementReport?FromDate=' + params.fromDate + '&ToDate=' + params.toDate + '&CampaignID=' + params.campaignID + '&DomainName=' + params.domainName + '&UserName=' + params.userName + '&CustomerName=' + params.customerName + '&CustomerId=' + params.customerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getReport(url: string) {
    var URL = url.replace(/\s/g, "");
    return this.apiService.get(URL);
  }

  getCurrencyList(customerID) {
    return this.apiService.get('en/currencies?isOrderbyname=1&customerID=' + customerID);
  }

  getLanguages(customerID) {
    const path = 'en/languages?isOrderbyname=true&customerID=' + customerID;
    return this.apiService.get(path);
  }

  getPayments() {
    const path = 'en/paymenttypes';
    return this.apiService.get(path);
  }

  dateFormatchange(value) {
    if (value !== '') {
      const myDate = moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD');
      return myDate;
    } else {
      return value;
    }
  }
  getSuppliers(customerId, LoggedInCustomerID) {
    const path = `en/suppliers?returnOnlyCustomerSupplier=true&customerID=${customerId}&LoggedInCustomerID=${LoggedInCustomerID}`;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

}
