import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';
import { ColumnSetting } from '../../../../shared/link-table/models/link-table-model';

@Component({
  selector: 'rewardkart-admin-product-tags-products',
  templateUrl: './product-tags-products.component.html',
  styleUrls: ['./product-tags-products.component.scss']
})
export class ProductTagsProductsComponent implements OnInit {
  @Output() loadTag = new EventEmitter<any>();
  @Output() loaderStatus = new EventEmitter<any>();
  @Input() productData: any;
  @Input() productModal: any;
  tableName: string = 'productrolesproducts';
  productList: any;
  actions: any = ['delete'];
  manualID: any = '';
  manualList: any;
  addRelatedProducts: Boolean = false;
  webshopDetail: any;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }
  ngOnInit() {
  }
  columns: ColumnSetting[] = [
    {
      primaryKey: 'ProductName',
      header: '	Product Name'
    },
    {
      primaryKey: 'ProductIdManual',
      header: 'Product Item Number'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];

  ngOnChanges() {
    if (this.productModal) {
      this.manualID = '';
      this.manualList = null;
    }
    this.productList = this.productData.Products;


  }

  deleteProduct(deleteData) {
    this.loaderStatus.emit(true);
    const request = {
      ProductID: deleteData.ProductID,
      ProductTagID: this.productData.ProductTagID
    }
    this.webshopHttpService.deleteProTag(request).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success(`Product tag deleted successfully`);
        this.loadTag.emit(true);
        this.tagList();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  searchManualID() {
    this.loaderStatus.emit(true);
    this.manualList = null;
    let request = {
      "ProductIdManualExact": this.manualID,
      "CustomerID": this.webshopDetail.CustomerID
    }
    if (this.webshopDetail.ParentCustomerID === null || this.webshopDetail.ParentCustomerID === undefined || this.webshopDetail.ParentCustomerID === '') {
      request['IncludeChildCustomers'] = true;
    }
    this.webshopHttpService.searchManualID(request).subscribe((data: any) => {
      this.addRelatedProducts = false;
      this.loaderStatus.emit(false);
      if (data) {
        this.manualList = data.ItemsCollection;
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  addProductTag(data) {
    this.loaderStatus.emit(true);
    const request = {
      ProductTagID: this.productData.ProductTagID,
      ProductID: data.ProductID,
      addBatchProduct: this.addRelatedProducts
    }
    this.webshopHttpService.addProductTags(request).subscribe((data: any) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success('Product added to tag(s)');
        this.manualList = undefined;
        this.manualID = '';
        this.loadTag.emit(true);
        this.tagList();
      }
      else {
        this.toast.error('Product cannot be added to tag. Check if product already has this tag.');
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

  tagList() {
    this.loaderStatus.emit(true);
    this.webshopHttpService.tagList(this.productData.ProductTagsGroupID).subscribe((data: any) => {
      this.loaderStatus.emit(false);
      if (data) {
        console.log(data);
        const getResult = data.ProductTags.filter(get => get.ProductTagID === this.productData.ProductTagID);
        if (getResult.length > 0) {
          this.productList = getResult[0]['Products'];
        }
        else {
          this.productList = [];
        }
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
      })
  }

}
