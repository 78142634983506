import {CategoryHttpService} from '../state/services/category.http.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryDetailsModule } from '../category-details/category-details.module';
import { CategoryService } from '../state/services/category.service';
import { CategoriesComponent } from './categories.component';
import { CategoryGridDetailsModule } from '../category-griddetails/category-griddetails.module';
import { CategoryListDetailsModule } from '../category-listdetails/category-listdetails.module';
import { AdvancedSearchModule } from '../advanced-search/advanced-search.module';
import { AngularSplitModule } from 'angular-split';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CategoryProductgridModule } from '../category-productgrid/category-productgrid.module';
import { AdvanceSearchWebshopOnlineProductsModule } from '../advance-search-webshop-online-products/advance-search-webshop-online-products.module';
import { CategoryProductlistModule } from '../category-productlist/category-productlist.module';
import { TranslateModule } from '@ngx-translate/core';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CategoryDetailsModule,
    FormsModule,
    CategoryGridDetailsModule,
    CategoryProductgridModule,
    CategoryListDetailsModule,
    AdvancedSearchModule,
    AdvanceSearchWebshopOnlineProductsModule,
    PerfectScrollbarModule,
    AngularSplitModule.forRoot(),
    CategoryProductlistModule,
    TranslateModule
  ],
  declarations: [CategoriesComponent],
  providers: [CategoryHttpService, CategoryService, { 
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  exports: [CategoriesComponent]
})

export class CategoriesModule { } 
