import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-widget-modal',
  templateUrl: './widget-modal.component.html',
  styleUrls: ['./widget-modal.component.scss']
})
export class WidgetModalComponent implements OnInit {
  @Input() modalcomponent: any;
  modalSelector: any;
  constructor() { }

  ngOnInit() {
    this.modalSelector = '<'+this.modalcomponent+'></'+this.modalcomponent+'>';
  }

}
