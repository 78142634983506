import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ProductHttpService } from '../state/services/product.http.service';
import { CategoryHttpService } from './../../category/state/services/category.http.service';
import { BrandHttpService } from './../../brand/state/services/brand.http.service';

@Component({
  selector: 'rewardkart-admin-product-adv-search',
  templateUrl: './product-adv-search.component.html',
  styleUrls: ['./product-adv-search.component.scss']
})
export class AdvancedProductSearchComponent implements OnInit, OnChanges {
  @Output() updatedSearchResult = new EventEmitter();
  @Input() productsearch: any;
  @Input() campaignID: any;
  @Input() isParentCustomer: boolean;
  @Input() pageindex: any;
  @Input() pagesize: any;
  @Input() max: any = '';
  @Input() min: any = '';
  searchFormGroup: FormGroup;
  colorsList: Array<any>;
  categories: Array<any>;
  brands: Array<any>;
  categoriesList: Array<any>;
  brandsList: Array<any>;
  subCategories: Array<any>;
  filterItems: Array<any> = [{ label: "Category", value: "category" }, { label: "Brand", value: "brand" }];
  productfilterItems: Array<any> = [{ label: "All", value: 1 }, { label: "Digital", value: 2 }, { label: "Physical", value: 3 }]
  selectedColorID;
  selectField = {
    categoryLabel: 'Select Category', selectedCategory: '',
    subCategoryLabel: 'Select SubCategory', selectedSubCategory: '', categoryID: '',
    brandLabel: 'Select Brand', selectedBrand: '',
    subBrandLabel: 'Select SubBrand', selectedSubBrand: '', brandID: ''
  };

  id: any;
  showColorList = false;
  searchResult = {
    error: null,
    isSearching: false,
    showMessage: false,
    message: '',
    messageType: ''
  };

  webshopDetail: any;
  userdetails: any;
  selectedFilter: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private productHttpService: ProductHttpService,
    private categoryHttpService: CategoryHttpService,
    private BrandHttpService: BrandHttpService
  ) {

    const userdetails = sessionStorage.getItem('userdetails');
    if (userdetails) {
      this.userdetails = JSON.parse(userdetails);
    }
  }

  ngOnInit() {
    this.createForm();
    this.getColors();
    this.getCategories();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.searchFormGroup) {
      this.searchProduct();
    }
  }

  createForm() {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    let min = Number(this.min)
    let max = Number(this.max)
    min = (min - (min * 5) / 100);
    if (min) {
      this.min = min;
    } else {
      this.min = '';
    }
    max = (max + (max * 5) / 100);
    if (max) {
      this.max = max;
    } else {
      this.max = '';
    }

    this.searchFormGroup = this.formBuilder.group({
      filterId: ['category'],
      productType: [1],
      CurrencyID: [''],
      CustomerID: [this.webshopDetail.CustomerID],
      supplierID: [''],
      CategoryId: [''],
      subCategoryId: [''],
      BrandId: [''],
      ColorID: [''],
      ProductName: ['', Validators.required],
      ProductManualID: [''],
      MinPriceRangeID: [this.min],
      MaxPriceRangeID: [this.max]
    });
  }

  searchbyType(value) {
    if (value === 'category') {
      this.searchFormGroup.controls['BrandId'].setValue('');
      this.getCategories();
    } else {
      this.searchFormGroup.controls['CategoryId'].setValue('');
      this.getBrands();
    }
  }

  getCategoryValue(value, type) {
    this.selectField.categoryID = value;
    if (value && type === 'Category') {
      this.selectField.selectedCategory = value;
      this.getSubCategories(value);
    } else if (value && type === 'SubCategory') {
      this.selectField.selectedSubCategory = value;
    }
  }

  getColors() {
    const data = {
      customerID: this.webshopDetail.CustomerID,
      translations: true,
      onlyParentColors: true
    };
    this.productHttpService.getProductColorList(data).subscribe((res: any) => {
      if (res) {
        this.colorsList = res;
        //this.showColorList = true;
      }
    }, error => {
      this.showColorList = false;
      console.log('unable to get product colors');
    });
  }

  getCategories() {
    this.categoryHttpService.getCustomerCategoryList(this.webshopDetail.CustomerID).subscribe((categoryRes: any) => {
      if (categoryRes) {
        this.categoriesList = categoryRes;
        this.categories = categoryRes.map((category: any) => ({
          value: category.CategoryID,
          label: category.Name
        }));
      }
    }, _error => {
      //console.log('unable to get categories');
    });
  }

  getBrands() {
    this.BrandHttpService.getBrandList('').subscribe((res: any) => {
      if (res) {
        this.brandsList = res;
        this.brands = res.map((data: any) => ({
          value: data.BrandId,
          label: data.BrandName
        }));
      }
    }, _error => {
      //console.log('unable to get categories');
    });
  }

  getSubCategories(categoryId) {
    const category: any = this.categoriesList.filter(data => data.CategoryID === categoryId);
    this.subCategories = category[0].SubCategories.map((subCategory: any) => ({
      value: subCategory.CategoryID,
      label: subCategory.Name
    }));
  }

  selectedColor(param) {
    this.selectedColorID = param;
  }

  clearSelection() {
    this.id = null;
    this.selectedColorID = null;
    this.selectField.selectedCategory = '';
    this.selectField.selectedSubCategory = '';
    this.subCategories = [];
    this.searchFormGroup.patchValue({
      ColorID: '',
      CategoryId: ''
    });
    this.searchFormGroup.reset();
    this.updateSearchResult(null);
  }

  searchProduct(limit = { pageIndex: this.pageindex, pageSize: this.pagesize }) {
    const formValue = this.searchFormGroup.value;
    const searchCriteria = {
      BrandId: formValue.BrandId ? formValue.BrandId : null,
      CurrencyID: 20,
      CustomerID: this.webshopDetail.CustomerID,
      CategoryID: formValue.CategoryId ? formValue.CategoryId : null,
      ColorID: this.selectedColorID ? this.selectedColorID : '',
      ProductName: formValue.ProductName,
      PriceFrom: formValue.MinPriceRangeID,
      PriceTo: formValue.MaxPriceRangeID,
      ProductsCategoryID: formValue.productType,
      UserID: this.userdetails.UserID,
      DeliveryDate: null,
      FilterBatchProducts: true,
      ProductTagIDs: [],
      Quantity: "",
      MinimumSupplierQualityLevel: "",
      isWebshop: true
    };
    if (formValue.ProductName && !formValue.ProductName.trim()) {
      //delete searchCriteria.ProductName;
    }

    this.productHttpService.getProductListBySearchCriteria(searchCriteria, limit).subscribe(
      (res: any) => {
        if (res) {
          let message = '';
          let showMessage = false;
          if (res && res.ItemsCollection && res.ItemsCollection.length == 0) {
            message = 'No products found';
            showMessage = true;
          }
          this.updateSearchResult(res, message, 'info', showMessage);
        }
      },
      error => {
        this.updateSearchResult(null, 'Something went wrong', 'error', true);
      }
    );
  }

  searchCampaignProduct(limit = { pageIndex: 1, pageSize: 5000 }) {
    const formValue = this.searchFormGroup.value;
    const searchCriteria = {
      CurrencyID: 20,
      CustomerID: this.webshopDetail.CustomerID,
      DeliveryDate: null,
      ProductTagIDs: [],
      CategoryId: formValue.CategoryId ? formValue.CategoryId : null,
      BrandId: formValue.BrandId ? formValue.BrandId : null,
      CampaignId: this.campaignID,
      ProductName: formValue.ProductName,
      PriceFrom: formValue.MinPriceRangeID,
      PriceTo: formValue.MaxPriceRangeID,
      UserID: this.userdetails.UserID,
      FilterBatchProducts: true
    };
    if (formValue.ProductName && !formValue.ProductName.trim()) {
      delete searchCriteria.ProductName;
    }

    this.productHttpService.getCampaignProductListBySearchCriteria(searchCriteria, limit).subscribe(
      (res: any) => {
        if (res) {
          let message = '';
          let showMessage = false;
          if (res && res.ItemsCollection && res.ItemsCollection.length == 0) {
            message = 'No products found';
            showMessage = true;
          }
          this.updateSearchResult(res, message, 'info', showMessage);
        }
      },
      error => {
        this.updateSearchResult(null, 'Something went wrong', 'error', true);
      }
    );
  }

  updateSearchResult(data, message = '', messageType = 'info', showMessage = false, isSearching = false) {
    this.searchResult = {
      productSearch: this.productsearch,
      error: message,
      isSearching,
      message,
      showMessage,
      messageType,
      ...data
    };
    this.updatedSearchResult.emit(this.searchResult);
  }
}
