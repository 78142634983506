import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebshopHttpService } from 'src/app/webshop/state/services/webshop.http.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-product-tags-group-add',
  templateUrl: './product-tags-group-add.component.html',
  styleUrls: ['./product-tags-group-add.component.scss']
})
export class ProductTagsGroupAddComponent implements OnInit {
  @Output() loadTag = new EventEmitter<any>();
  @Output() loaderStatus = new EventEmitter<any>();
  @Input() editTagGroup: any;
  @Input() isOpenGroupAdd: any;
  addProductTag: FormGroup;
  languageList: Array<any>;
  language: any = 1;
  tagName: any = '';
  webshopDetail: any;
  constructor(private webshopHttpService: WebshopHttpService, private apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.loadLanguage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isOpenGroupAdd) {
      this.language = 1;
      this.addProductTag.reset();
    }

    this.prefillData();
  }

  prefillData() {

    if (this.editTagGroup) {
      this.editTagGroup.forEach(element => {
        this.addProductTag.get('tag' + element.LanguageID).patchValue(element.PropertyValue);
      });
    }
    else {
      if (this.addProductTag) {
        this.language = 1;
        this.addProductTag.reset();
      }
    }
  }

  loadTagForm() {
    // this.addProductTag = this.formBuilder.group({
    //   tagName: new FormControl('')
    // });
  }

  loadLanguage() {
    let group = {}
    let customerDetail = JSON.parse(sessionStorage.getItem('customerDetails'));
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    getlang.forEach((element: any) => {
      if (element.LanguageID === 1) {
        group['tag' + element.LanguageID] = new FormControl('', Validators.required);
      }
      else {
        group['tag' + element.LanguageID] = new FormControl('')
      }
      element.label = element.Name;
      element.value = element.LanguageID;
    });
    this.languageList = getlang;
    this.addProductTag = new FormGroup(group);
  }

  createTag() {
    this.loaderStatus.emit(true);
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    const loginData = this.apiService.getLoginData();
    const tagName = [];
    this.languageList.forEach(element => {
      const name = {
        "LanguageID": element.LanguageID,
        "LanguageCode": element.Code,
        "ObjectType": 'ProductTagsGroups',
        "PropertyName": 'ProductTagsGroupName',
        "PropertyValue": this.addProductTag.value['tag' + element.LanguageID]
      }
      tagName.push(name);
    });
    const request = {
      "CustomerID": this.webshopDetail.CustomerID,
      "Translations": tagName
    }
    this.webshopHttpService.addTag(request).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success('Product Group tag created successfully');
        this.loadTag.emit();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
        this.toast.error(error.error.Message);
      })
  }

  updateTag() {
    this.loaderStatus.emit(true);
    const tagGroup = [];
    let groupID: any;
    this.editTagGroup.forEach(element => {
      groupID = element.ObjectID;
      tagGroup.push({
        "TranslationID": element.TranslationID,
        "LanguageID": element.LanguageID,
        "LanguageCode": element.LanguageCode,
        "ObjectID": element.ObjectID,
        "ObjectType": element.ObjectType,
        "PropertyName": element.PropertyName,
        "PropertyValue": this.addProductTag.value['tag' + element.LanguageID],
        "Action": "Update"
      })
    });
    const request = {
      Translations: tagGroup
    }
    this.webshopHttpService.updateProductTagGroup(request, groupID).subscribe((data) => {
      this.loaderStatus.emit(false);
      if (data) {
        this.toast.success('Product group tag updated successfully');
        this.loadTag.emit();
      }
    },
      (error) => {
        this.loaderStatus.emit(false);
        this.toast.error(error.error.Message);
      })
  }
}
