import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
@Injectable()
export class BannerEnquiryHttpService {
    constructor(private apiService: ApiService) { }

    getBannerEnquiryList(id:any) {
        const path = `en/Campaign/GetBanners/${id}`;
        return this.apiService.get(path).map(res => {
            return res;
        });
    }

    getBannerEnquiryDetails(id) {
        const path = "en/Campaign/GetBanner/" + id;
        return this.apiService.get(path).map(res => {
            return res;
        });
    }
}