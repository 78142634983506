import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { FilterTagModule } from 'src/app/common/common-filter/filter-tags/filter-tags.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { MDBBootstrapModule } from 'ng-uikit-pro-standard';
import { LogisticVendorAddComponent } from './logistic-vendor-add.component';
import { WarehouseHttpService } from '../../state/services/warehouse.http.service';
import { PageLoaderModule } from 'src/app/common/pageloader/pageloader.module';


@NgModule({
  declarations: [LogisticVendorAddComponent],
  imports: [
    CommonModule,
    CommonFilterModule,
    FilterTagModule,
    TranslateModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ControlValidationModule,
    PageLoaderModule,
    MDBBootstrapModule.forRoot()
  ],
  exports: [LogisticVendorAddComponent],
  providers: [WarehouseHttpService],
})
export class LogisticVendorAddModule { }