import { Component, OnInit } from '@angular/core';
import { CategoryHttpService } from '../state/services/category.http.service';
import { CategoryService } from '../category-services/category.service';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  advanceSearchDataWebshopLibrary: any = {};
  advanceSearchSelectDataWebshopLibrary: any = {};
  advanceSearchDataWebshopOnlineproducts: any = {};
  displayMode: number = 1;
  displayModeonlinewebshop: number = 1;
  colorList: any = [];
  getParentWebshopDetail: any = '';
  config: any;
  webshopLibrarySearchStatus: boolean = false;
  webshopLibrarySelectStatus: boolean = false;
  webshopOnlineProductsSearchStatus: boolean = false;
  webshopOnlineProductsSelectStatus: boolean = false;
  formResetStatus: boolean = false;
  webshopLibrarySupplierStatus: boolean = false;
  showLoader: boolean = false;
  optionsSelect: any;
  isSearch: Boolean = false;
  isLibrarySearch: Boolean = false;
  webshopDetail: any;
  constructor(private categoryHttpService: CategoryHttpService, public apiService: ApiService, private categoryService: CategoryService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    this.isParentWebshopCheck();
    this.getColorList();
  }

  setHeight() {
    let getWindowHeight = window.innerHeight;
    getWindowHeight = getWindowHeight - 120;
    return getWindowHeight;
  }

  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }

  onDisplayModeChangeonlinewebshop(mode: number): void {
    this.displayModeonlinewebshop = mode;
  }

  getWebshopLibrarySearchData(request) {
    this.advanceSearchDataWebshopLibrary = {
      CurrencyID: 2,
      CategoryID: (request.CategoryID !== '') ? Number(request.CategoryID) : '',
      // ColorID: 10939,
      CustomerID: (Number(request.CustomerID) !== 0) ? Number(request.CustomerID) : null,
      childCustomerID: (request.childCustomerID !== '' && request.childCustomerID !== null) ? Number(request.childCustomerID) : null,
      customerID: (Number(request.CustomerID) !== 0) ? Number(request.customerID) : null,
      MaxDeliveryWeeks: (request.MaxDeliveryWeeks !== '') ? Number(request.MaxDeliveryWeeks) : null,
      SizeID: (request.SizeID !== '') ? Number(request.SizeID) : null,
      OnlyWithoutWebshop: (request.OnlyWithoutWebshop !== '') ? Number(request.OnlyWithoutWebshop) : null,
      OnlyWithoutWebshopCustomerID: (request.OnlyWithoutWebshopCustomerID !== '') ? Number(request.OnlyWithoutWebshopCustomerID) : null,
      productStatusList: (request.productStatusList !== '') ? Number(request.productStatusList) : null,
      productDeletedStatusList: (request.productDeletedStatusList !== '') ? Number(request.productDeletedStatusList) : null,
      OnlyInCategoriesOfSupplierID: (request.OnlyInCategoriesOfSupplierID !== '') ? Number(request.OnlyInCategoriesOfSupplierID) : null,
      PriceFrom: (request.PriceFrom !== '') ? Number(request.PriceFrom) : '',
      PriceTo: (request.PriceTo !== '') ? Number(request.PriceTo) : '',
      Quantity: (request.Quantity !== '') ? request.Quantity : '',
      SupplierID: (request.SupplierID !== '') ? request.SupplierID : '',
      ReturnBatchProducts: (request.ReturnBatchProducts !== '') ? Number(request.ReturnBatchProducts) : '',
      isAdmin: request.isAdmin,
      productName: (request.productName !== '') ? request.productName : '',
      ColorID: (request.ColorID !== '') ? request.ColorID : ''
    }
    this.webshopLibrarySearchStatus = true;
    this.webshopLibrarySelectStatus = false;

  }

  getWebshopLibrarySelectData(request) {
    this.advanceSearchSelectDataWebshopLibrary = {
      CurrencyID: 2,
      CategoryID: (request.CategoryID !== '') ? Number(request.CategoryID) : '',
      // ColorID: 10939,
      CustomerID: (Number(request.CustomerID) !== 0) ? Number(request.CustomerID) : null,
      childCustomerID: (request.childCustomerID !== '') ? Number(request.childCustomerID) : null,
      customerID: (Number(request.CustomerID) !== 0) ? Number(request.customerID) : null,
      MaxDeliveryWeeks: (request.MaxDeliveryWeeks !== '') ? Number(request.MaxDeliveryWeeks) : null,
      SizeID: (request.SizeID !== '') ? Number(request.SizeID) : null,
      OnlyWithoutWebshop: (request.OnlyWithoutWebshop !== '') ? Number(request.OnlyWithoutWebshop) : null,
      OnlyWithoutWebshopCustomerID: (request.OnlyWithoutWebshopCustomerID !== '') ? Number(request.OnlyWithoutWebshopCustomerID) : null,
      productStatusList: (request.productStatusList !== '') ? Number(request.productStatusList) : null,
      productDeletedStatusList: (request.productDeletedStatusList !== '') ? Number(request.productDeletedStatusList) : null,
      OnlyInCategoriesOfSupplierID: (request.OnlyInCategoriesOfSupplierID !== '') ? Number(request.OnlyInCategoriesOfSupplierID) : null,
      PriceFrom: (request.PriceFrom !== '') ? Number(request.PriceFrom) : '',
      PriceTo: (request.PriceTo !== '') ? Number(request.PriceTo) : '',
      Quantity: (request.Quantity !== '') ? request.Quantity : '',
      SupplierID: (request.SupplierID !== '') ? request.SupplierID : '',
      ReturnBatchProducts: (request.ReturnBatchProducts !== '') ? Number(request.ReturnBatchProducts) : '',
      isAdmin: request.isAdmin,
      productName: (request.productName !== '') ? request.productName : '',
      CustomerCreated: (request.CustomerCreated !== '') ? request.CustomerCreated : '',
      ColorID: (request.ColorID !== '') ? request.ColorID : ''
    }
    this.webshopLibrarySelectStatus = true;
    this.webshopLibrarySearchStatus = false;

  }

  getWebshopOnlineProductSearchData(request) {
    this.advanceSearchDataWebshopOnlineproducts = {
      CurrencyID: 2,
      CategoryID: (request.CategoryID !== '') ? Number(request.CategoryID) : '',
      // ColorID: 10939,
      CustomerID: (request.CustomerID !== '') ? Number(request.CustomerID) : null,
      childCustomerID: (request.childCustomerID !== '') ? Number(request.childCustomerID) : null,
      customerID: (request.customerID !== '') ? Number(request.customerID) : null,
      SizeID: (request.SizeID !== '') ? Number(request.SizeID) : null,
      MaxDeliveryWeeks: (request.MaxDeliveryWeeks !== '') ? Number(request.MaxDeliveryWeeks) : null,
      PriceFrom: (request.PriceFrom !== '') ? Number(request.PriceFrom) : '',
      PriceTo: (request.PriceTo !== '') ? Number(request.PriceTo) : '',
      Quantity: (request.Quantity !== '') ? request.Quantity : '',
      SupplierID: (request.SupplierID !== '') ? request.SupplierID : '',
      ReturnBatchProducts: (request.ReturnBatchProducts) ? request.ReturnBatchProducts : false,
      isAdmin: request.isAdmin,
      productName: (request.productName !== '') ? request.productName : '',
      ColorID: (request.ColorID !== '') ? request.ColorID : ''
    }
    this.webshopOnlineProductsSearchStatus = true;
    this.webshopOnlineProductsSelectStatus = false;
    this.formResetStatus = false;

  }

  getWebshopProductSelectData(request) {
    this.webshopOnlineProductsSelectStatus = true;
    this.webshopOnlineProductsSearchStatus = false;
    this.formResetStatus = true;
  }

  /** TO Ger COLOR List */
  getColorList() {
    if (sessionStorage.getItem('parentColorList') !== undefined && sessionStorage.getItem('parentColorList') !== null
      && sessionStorage.getItem('parentColorList') !== '') {
      this.colorList = JSON.parse(sessionStorage.getItem('parentColorList'));
    } else {
      this.categoryHttpService.getParentColorList().subscribe((data: any) => {
        if (data) {
          this.colorList = data;
          sessionStorage.setItem('parentColorList', JSON.stringify(this.colorList));
        }
      });
    }
  }

  /** Check Login Webshop is Parent or Child webshop */
  isParentWebshopCheck() {
    // const loginData = this.apiService.getLoginData();
    this.categoryHttpService.getParentWebshopDetail(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.getParentWebshopDetail = data;
        this.showLoader = false;
        if (data.ParentCustomerID !== undefined && parseInt(data.ParentCustomerID) > 0) {
          sessionStorage.setItem('isParentWebshop', JSON.stringify(false));
          sessionStorage.setItem('parentWebshopId', JSON.stringify(data.ParentCustomerID));
        } else {
          sessionStorage.setItem('isParentWebshop', JSON.stringify(true));
        }
      }
    },
      err => {
        this.showLoader = false;
      });
  }

  webshopLibrarySupplierRecallStatus() {
    this.webshopLibrarySupplierStatus = !this.webshopLibrarySupplierStatus;
  }

  getSearchStatus(status) {
    this.isSearch = status ? true : false;
  }

  getLibrarySearchStatus(status) {
    this.isLibrarySearch = status ? true : false;
  }

}
