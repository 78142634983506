import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ProductionLineListComponent } from './production-line-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonFilterModule } from 'src/app/common/common-filter/common-filter.module';
import { OrderHttpService } from '../state/services/order.http.service';
import { OrderService } from '../state/services/order.service';
import { ProductCardModule } from 'src/app/product/product-card/product-card.module';

@NgModule({
    declarations: [
        ProductionLineListComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        CommonFilterModule,
        ProductCardModule
    ],
    providers: [OrderHttpService, OrderService, TitleCasePipe],
    exports: [ProductionLineListComponent]
})

export class ProductionLineListModule { }