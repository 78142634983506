import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-internal-note',
  templateUrl: './internal-note.component.html',
  styleUrls: ['./internal-note.component.scss']
})
export class InternalNoteComponent implements OnInit {

  @Input() header = '';
  @Input() note = '';
  @Input() cancelText = 'Cancel';
  modalAction: Subject<any> = new Subject<any>();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  modalCanceled() {
    this.modalRef.hide();
  }

  modalConfirm() {
    this.modalAction.next({
      note: this.note
    });
  }

}
