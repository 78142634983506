import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'rewardkart-admin-capture-amount',
  templateUrl: './capture-amount.component.html',
  styleUrls: ['./capture-amount.component.scss']
})
export class CaptureAmountComponent implements OnInit {
  @Input() header = '';
  @Input() price = '';
  @Input() type = '';
  @Input() cancelText = 'Cancel';
  modalAction: Subject<any> = new Subject<any>();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  modalCanceled() {
    this.modalRef.hide();
  }

  modalConfirm() {
    this.modalAction.next({
      confirm: true
    });
  }

}
