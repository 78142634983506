import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-governmental-additional-cost',
  templateUrl: './product-governmental-additional-cost.component.html',
  styleUrls: ['./product-governmental-additional-cost.component.scss']
})
export class ProductGovernmentalAdditionalCostComponent implements OnInit {
  @Input() productDetails:any;
  @Input() loadApi:any;
  @Output() sendAdditionalCosts = new EventEmitter<any>();
  @Output() sendGovernmentalCosts = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }


  getProductAdditionalCosts(data){
    this.sendAdditionalCosts.emit(data)
  }

  getProductGovernmentalCosts(data){
    this.sendGovernmentalCosts.emit(data);
  }

}
