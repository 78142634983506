
import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryHttpService } from '../state/services/category.http.service';
import { CategoryService } from '../category-services/category.service';
import { CategoryAddeditComponent } from './category-addedit.component';
import { SafePipe } from '../category-griddetails/category-griddetails.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [CategoryAddeditComponent, SafePipe],
  providers: [CategoryHttpService, CategoryService],
  exports: [CategoryAddeditComponent]
})

export class CategoryAddEditModule { }
