import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlockState, blockReducers } from './block.reducer';

export interface State {
    blockModule: BlockState;
}

export const reducers = blockReducers;

export const getBlockState = createFeatureSelector<BlockState>('blockModule');

export const getBlockListSuccess = createSelector(getBlockState,
    (state: BlockState) => state.blocks);

export const getBlockUpdateSuccess = createSelector(getBlockState,
    (state: BlockState) => state.updateBlockSuccess);
