import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoginService } from './state/services/login.service';
import { LoginHttpService } from './state/services/login.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { LoginActions } from './state/actions';
import { Store } from '@ngrx/store';
import { LoginState } from './state/reducers/login.reducer';
import { getSubDomainName } from './state/reducers';
import { CookieService } from 'ngx-cookie-service';

import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ApiService } from '../shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  customerList: Array<any>;
  loginForm: FormGroup;
  selectedCustomer = '';
  showLoader: boolean = false;
  redirecToForgotPasswordPage: Boolean = false;
  wheshopCustomerLogo: any;
  signupBtn: boolean = false;
  @Output() signup = new EventEmitter<boolean>();

  constructor(private toast: ToastService, public apiService: ApiService, private formBuilder: FormBuilder, private loginService: LoginService, private router: Router, private loginHttpService: LoginHttpService, private cookieService: CookieService, private store: Store<LoginState>,
    private activatedRoute: ActivatedRoute) {
    this.createForms();
    this.store.select(getSubDomainName).subscribe(subdomain => {
      if (subdomain !== 'nil') {
        this.loginService.loadCustomers();
        this.loginService.loadLanguage();
        this.getSubdomain();
      }
    });
  }

  dateFormatchange(getDate) {
    let myDate = moment(getDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    return myDate;
  }

  createForms() {
    this.loginForm = this.formBuilder.group({
      Domain: ['', Validators.required],
      customerId: [sessionStorage.getItem('customerId')],
      roleCode: ['admin'],
      userName: ['', Validators.required],
      password: ['', Validators.required],
      savepassword: ['']
    });

    /** Set Cookie for Login Username, Password */
    const cookieExists: Boolean = this.cookieService.check('loginUsername');
    if (cookieExists) {
      this.loginForm.patchValue({
        userName: this.cookieService.get('loginUsername'),
        password: this.cookieService.get('loginUserPassword'),
        savepassword: true
      }, { onlySelf: false, emitEvent: false });
    }
  }

  ngOnInit() {
    sessionStorage.removeItem('productListFilter');
    sessionStorage.removeItem('presearch');
    this.setSubDomain();

    const cookieExists: Boolean = this.cookieService.check('payment');


    this.loginService.getAllCustomers().subscribe(
      data => {
        if (data) {
          this.customerList = [];
          data.forEach(element => {
            const customerVal = {
              value: element.CustomerID,
              label: element.Name,
            };
            this.customerList.push(customerVal);
          });
        }
      });
    if (cookieExists) {
      let user = JSON.parse(sessionStorage.getItem('userdetails'));
      this.loginForm.patchValue({
        userName: user.UserName,
        password: this.cookieService.get('payment'),
        savepassword: false
      }, { onlySelf: false, emitEvent: false });
      this.cookieService.delete('payment');
      this.doLogin();
    }
    //Handle login fail
    this.loginService.loginFail().subscribe(data => {
      this.showLoader = false;
      if (data == LoginActions.LOGIN_FAIL) {
        this.toast.error('Please check your credentials and try again');
        this.loginService.dispatchLoginFail();
      }
    });

    //Handle login success
    this.loginService.loginSuccess().subscribe(data => {
      if (data) {
        if (sessionStorage.getItem('isParentLoginAsChildShop') !== null) {
          sessionStorage.removeItem('isParentLoginAsChildShop');
        }
        sessionStorage.setItem('userdetails', JSON.stringify(data));
        /** Set Cookie for Login Username, Password */
        if (this.loginForm.value.savepassword) {
          const cookieExists: Boolean = this.cookieService.check('loginUsername');
          if (cookieExists) {
            this.cookieService.delete('loginUsername');
            this.cookieService.delete('loginUserPassword');
          }
          setTimeout(() => {
            this.cookieService.set('loginUsername', this.loginForm.value.userName);
            this.cookieService.set('loginUserPassword', this.loginForm.value.password);
          }, 50);
        }

        this.showLoader = false;
        if((data.DomainName === "247hrm" || data.DomainName === "sw" || data.DomainName === "smartworks") && data.Roles[0].RoleName !== "SmartWorksAdmin"){
          this.router.navigate(['/admin-order-process/true'], { replaceUrl: false });
        }
        else if((data.DomainName === "91springboard" || data.DomainName === "91sb" || data.DomainName === "aed")){
          this.router.navigate(['/admin-bulk-user-import'], { replaceUrl: false });
        }
        // else if (data.IsSubScriptionValid) {
        //   this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
        // }
        else if (data.ISSaas === "Y" && !sessionStorage.getItem("SaasPayment")) {
          this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
        }
        else if(sessionStorage.getItem("SaasPayment") && data.ISSaas === "Y" ){
          this.router.navigate(['/admin-campaign-add'], { replaceUrl: false });
        }
        else {
          this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
        }
        this.loginService.isLoggedIn().subscribe((status: boolean) => {
          if (!status) {
            //this.toast.success("Login success");
            this.loginService.loginStatus(true);
          }
        });
      }
    });
  }



  //new Function
  // doLogin() {
  //   const customer = this.getCustomer(this.loginForm.value.customerId);
  //   //   this.loginService.doLogin(this.loginForm.value, customer);
  //   this.loginHttpService.doLogin(this.loginForm.value).subscribe(
  //     data => {
  //       if (data) {
  //         console.log(data)
  //         this.router.navigate(['/admin-dashboard'], { replaceUrl: false });
  //         //admin-dashboard for default 
  //       }
  //       else {
  //         // this.toast.error('Please check your credentials and try again');
  //       }
  //     },
  //     // error =>{
  //     //   console.log('negative msg',error) 
  //     //   if (error['status'] == 404) {
  //     //   this.toast.success('');
  //     //   }
  //     // } 
  //   );
  // }



  //old Function
  doLogin() {
    this.showLoader = true;
    const customer = this.getCustomer(this.loginForm.value.customerId);
    this.loginService.doLogin(this.loginForm.value, customer);
    let webshoplanguage = this.loginService.loadwebshopLanguage(this.loginForm.value.customerId);
    this.loginHttpService.setUser(this.loginForm.value);
    //console.log('webshoplanguages...', webshoplanguage)
  }


  getCustomer(customerID) {
    let customer;
    this.loginService.getAllCustomers().subscribe(data => {
      if (data) {
        // this.router.navigate(['/admin-dashboard']);
        customer = data.find(x => Number(x.CustomerID) === Number(customerID));
        //  sessionStorage.setItem('webshopDetail', JSON.stringify(customer));
      }
    });
    return customer;
  }

  getSubdomain() {
    // const hostname = 'conxion.linkpartner-staging.dk';  //for local
    const hostname = window.location.hostname; // for deploy 
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(hostname);


    if (urlParts !== null) {
      const getCust = hostname.replace(urlParts[0], '').slice(0, -1);

      this.loginService.loadCustomer(getCust);
      this.loginService.getCustomer().subscribe(data => {
        if (data) {
          if (data.ParentCustomerID === undefined) {
            data.ParentCustomerID = null;
          }
          if (data.ImageURL) {
            //console.log(data.ImageURL)
            this.wheshopCustomerLogo = data.ImageURL
          }
          if (sessionStorage.getItem('customerId')) {
            data.CustomerID = sessionStorage.getItem('customerId');
          }
          if(data.DomainName === "91sb" || data.DomainName === "aed"){
              this.signupBtn = true;
          }   
          sessionStorage.setItem('webshopDetail', JSON.stringify(data));
          sessionStorage.setItem('customerDetails', JSON.stringify(data));
          if (data.SupplierID !== null && data.SupplierID !== undefined) {
            sessionStorage.setItem('ActasSupplier', JSON.stringify(true));
          } else {
            if (sessionStorage.getItem('ActasSupplier') !== null && sessionStorage.getItem('ActasSupplier') !== undefined &&
              sessionStorage.getItem('ActasSupplier') !== 'undefined') {
              sessionStorage.removeItem('ActasSupplier');
            }
          }
          this.loginForm.patchValue({
            customerId: data.CustomerID
          });
        }
      });
      return getCust;

    }
  }

  setSubDomain() {
    //let hostName = 'conxion.linkpartner-staging.dk';  //for local
    // let hostName = 'wooz.linkpartner-staging.dk'; //window.location.origin
    let hostName = window.location.hostname;
    // if (window.location.host.indexOf('www.') === 0) {
    //   hostName = window.location.host.replace('www.', '');
    // }
    let hostSplit = hostName.split('.')
    let customerName = hostSplit.length > 2 ? hostSplit[0] : '';
    if (!customerName) {
      customerName = 'hyperkart';
    }
    this.loginForm.patchValue({
      Domain: customerName
    });
    if (customerName) {
      this.loginService.loadSubDomain(customerName);
    } else {
      this.store.dispatch(new LoginActions.SetSubDomain(''));
    }
  }

  /** Set Cookie for Login Username, Password */
  savePassword(event) {
    if (event.checked) {
      const cookieExists: Boolean = this.cookieService.check('loginUsername');
      if (cookieExists) {
        this.cookieService.delete('loginUsername');
        this.cookieService.delete('loginUserPassword');
      }
      setTimeout(() => {
        this.cookieService.set('loginUsername', this.loginForm.value.userName);
        this.cookieService.set('loginUserPassword', this.loginForm.value.password);
      }, 50);
    } else {
      this.cookieService.delete('loginUsername');
      this.cookieService.delete('loginUserPassword');
    }
  }

  forgotPassword(event: any) {
    //console.log(event);
    this.redirecToForgotPasswordPage = !this.redirecToForgotPasswordPage;
  }

  signUP(){
    this.signup.emit(true)
  }
}
