import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { MDBModalService, MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { OrderHttpService } from '../../state/services/order.http.service';
import { OrderItemModalComponent } from '../order-item-modal/order-item-modal.component';
import { DeliveryNoteModalComponent } from '../delivery-note-modal/delivery-note-modal.component';
import { OrderModalHttpService } from '../../state/services/order.modal.http.service';
import { CreditNoteModalComponent } from '../credit-note-modal/credit-note-modal.component';
import { PerformaInvoiceComponent } from '../modals/performa-invoice/performa-invoice.component';

@Component({
  selector: 'rewardkart-admin-order-item-summary',
  templateUrl: './order-item-summary.component.html',
  styleUrls: ['./order-item-summary.component.scss']
})
export class OrderItemSummaryComponent implements OnInit, OnChanges {
  @Input() summaryData: any;
  @Input() subOrderItems: any;
  @Output() getOrderList = new EventEmitter<any>();
  @Output() dispatchStatus = new EventEmitter<any>();
  @Input() orderId = '';

  acceptedOIs: any;
  showDeliveryNote: boolean = false;
  showDispatched: boolean = false;
  showCreditNote: boolean = false;
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };
  modalRef: MDBModalRef;
  isDialogOpened = false;
  showLoader: boolean = false;
  newCommercialData: any[];
  getDataArrayList: any[];
  constructor(private modalService: MDBModalService, private orderHttpService: OrderHttpService,
    private orderModalHttpService: OrderModalHttpService,
    private toastServcie: ToastService) { }

  ngOnInit() {
  }
  ngOnChanges() {
    this.getDataOfList();
    this.acceptedOIs = (this.summaryData.NumberOfOrderItems && this.summaryData.OrderItems) ? this.summaryData.NumberOfOrderItems - this.summaryData.OrderItems.length : 0;
    if (this.subOrderItems) {
      this.newCommercialData = this.subOrderItems;
      this.showDispatched = false;
      this.showDeliveryNote = false;
      this.subOrderItems.map(subOrderItem => {
        if (subOrderItem) {
          subOrderItem.OrderItems.map(item => {
            if (item.OrderedQuantity > item.DeliveryNoteQuantity) {
              this.showDeliveryNote = true;
            } if (item.isDispatchEnabled) {
              this.showDispatched = true;
              /** For Task LIN-5923 => SO Button (Always Hide Dispatch, Dispatch All button when customer is GN) */
              /** For Task LIN-5964 => IS Button (Always Hide Dispatch, Dispatch All button when customer is GN) */
              // if (Number(this.summaryData.CustomerID) === 15) {
              //   this.showDispatched = false;
              // }
            } if (item.isCreditNoteEnabled) {
              this.showCreditNote = true;
            }
          });
        }
      });
      // this.commonFunc();
    }
  }
  commonFunc() {
    const selectedProformaInvoicesData = [];
    this.subOrderItems.forEach((subOrder: any, index: number) => {
      const orderItems = [];
      subOrder.OrderItems.forEach((orderItem, index) => {
        if (orderItem.isPartialProformaEnabled) {
          // orderItem.checked = false;
          orderItems.push(orderItem);
        }
      });

      if (orderItems.length > 0) {
        // subOrder.OrderItems = orderItems;
        selectedProformaInvoicesData.push(subOrder);
      }
    });
    this.newCommercialData = selectedProformaInvoicesData;
    console.log('this.newCommercialData', this.newCommercialData)
    // console.log('this.subOrderItems', this.subOrderItems)

  }
  handleOrderAction(event) {
    switch (event) {
      case 'deliveryNote':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.newDeliveryNotePopup(event);
        }
        break;
      case 'deliveryNotes':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.deliveryNotesPopup(event);
        }
        break;
      case 'newProformaInvoice':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.newProformaInvoicesPopup(event);
        }
        break;
      case 'proformaInvoices':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.proformaInvoicesPopup(event);
        }
        break;
      case 'invoices':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.invoicesPopup(event);
        }
        break;
      case 'dispatch':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.dispatchPopup(event);
        }
        break;
      case 'dispatchAll':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.dispatchAllPopup(event);
        }
        break;
      case 'newCreditNote':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.newCreditNotePopup(event);
        }
        break;
      case 'creditNotes':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.creditNotePopup(event);
        }
        break;
      case 'suborderCaptureAmount':
        if (!this.isDialogOpened) {
          this.isDialogOpened = true;
          this.subOrdercaptureAmountPopup(event);
        }
        break;
    }
  }

  subOrdercaptureAmountPopup(SuborderId: any) {
    debugger
    var data = [];
    for (var item of this.subOrderItems) {
      data.push({ SubOrderID: item.SuborderID, OrderID: item.OrderID })
    }
    this.orderHttpService.getSubOrderCaptureUnSuborderAmount(data).subscribe((captureamount: any) => {
      const modalData = {
        type: 'suborderCaptureAmount',
        header: `Capture Amount`,
        subOrderCaptureAmountItems: [...captureamount],
        CurrencyCode: this.summaryData.CurrencyCode
      };
      this.modalRef = this.modalService.show(OrderItemModalComponent, {
        ...this.modalOptions, data: modalData
      });

      this.modalService.closed.subscribe(result => {
        this.isDialogOpened = false;
      });

      this.modalRef.content.modalAction.subscribe((result: any) => {
        debugger
        // const senddata = {
        //   OrderID,
        //   TXNID: this.summaryData.ePayTxnID,
        //   Amount: captureamount.SuborderAmountWithTransportationCostAndVAT,
        //   currencyCode: this.currency,
        //   Email: this.summaryData.Email,
        // };
        this.orderHttpService.getSubOrderCaptureSuborderAmount(result.data).subscribe((response: any) => {
          if (response) {
            this.modalRef.hide();
            this.toastServcie.success(response);
          } else {
            this.toastServcie.error('unable to capture the amount');
          }
        }, (error: any) => {
          if (error.error) {
            this.toastServcie.error(error.error);
          } else {
            this.toastServcie.error('unable to capture the amount');
          }
          this.modalRef.hide();
        });
      });
    }, (apiError: any) => {
      this.toastServcie.error(apiError.error);
    });
  }

  newDeliveryNotePopup(data) {
    this.orderHttpService.getDeliveryNoteOrderItems(this.summaryData.OrderID)
      .subscribe((deliveryNoteData: any) => {
        if (deliveryNoteData) {
          let newDeliveryNoteOrderItemIds = [];
          let newDeliveryNoteOrderItemQuantities = [];
          let selectedDeliveryNoteOrderItems = [];
          deliveryNoteData.forEach(item => {
            newDeliveryNoteOrderItemIds[item.OrderItemID] = false;
            newDeliveryNoteOrderItemQuantities[item.OrderItemID] = item.OrderedQuantity;
          });
          const modalData = {
            type: 'newDeliveryNote',
            header: `New Delivery Note `,
            newDeliveryNoteItems: deliveryNoteData,
            newDeliveryNoteOrderItemIds: newDeliveryNoteOrderItemIds,
            newDeliveryNoteOrderItemQuantities: newDeliveryNoteOrderItemQuantities,
            newSelectedDeliveryNoteOrderItems: selectedDeliveryNoteOrderItems
          };
          this.modalRef = this.modalService.show(DeliveryNoteModalComponent, {
            ...this.modalOptions, data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
          });

          this.modalRef.content.modalAction.subscribe((result: any) => {
            const { data } = result;
            this.orderHttpService.createDeliveryNote(data).subscribe(response => {
              if (response) {
                this.modalRef.hide();
                this.isDialogOpened = false;
                this.getOrderList.emit(this.summaryData.OrderID);
              } else {
                this.toastServcie.error('Error while creating delivery note.');
              }
            }, error => {
              if (error['status'] === 500) {
                this.toastServcie.error(error['error']);
              } else {
                this.toastServcie.error('Error while creating delivery note.');
              }
            });
          });
        }
      }, error => {
        this.toastServcie.error('Error while getting delivery note order items');
        this.isDialogOpened = false;
      });
  }

  dispatchPopup(type) {
    if (this.summaryData.Suborders.length > 0) {
      this.summaryData.Suborders.forEach(subOrder => {
        subOrder.OrderItems.forEach(item => {
          if (item.DeliveryNoteQuantity > item.OrderedQuantity) {
          }
        });
      });
    }
    const modalData = {
      type: type === 'dispatch' ? 'dispatch' : 'dispatchAll',
      header: 'Dispatch Orders',
      dispatch: this.summaryData,
      method: 'dispatchOrder',
      invoiceRecipientEmail: this.summaryData.EmailForInvoices === this.summaryData.Email ? this.summaryData.EmailForInvoices : this.summaryData.Email,
      generalEmail: this.summaryData.DeliveryEmail
    };
    this.modalRef = this.modalService.show(OrderItemModalComponent, {
      ...this.modalOptions, data: modalData
    });
    this.modalService.closed.subscribe(result => {
      this.isDialogOpened = false;
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      this.getOrderList.emit(result.data);
    });
  }

  dispatchAllPopup(type) {
    this.dispatchPopup(type);
  }

  deliveryNotesPopup(data) {
    this.orderHttpService.listDeliveryNotes(this.summaryData.OrderID)
      .subscribe((deliveryNotesData: any) => {
        /** Code to display "Create SO" button based on ISP products when click "Delivery Notes" Button */
        deliveryNotesData.forEach(item => {
          let callgetSoForIspProductStatus = false;
          item.displaySoButton = false;
          item.displaySoSuccessMessage = '';
          item.displaySoErrorMessage = '';
          item.OrderItems.forEach(suborderitem => {
            if (suborderitem.isInternalStockProduct && Number(item.CustomerID) === 15) {
              callgetSoForIspProductStatus = true;
            }
          });
          if (callgetSoForIspProductStatus) {
            const requestdata = { deliveryNoteNumber: item.DeliveryNoteNumber };
            this.showLoader = true;
            this.orderModalHttpService.getSoForIspProduct(requestdata).subscribe(getresponse => {
              this.showLoader = false;
              if (getresponse) {
                item.displaySoButton = false;
                item.displaySoSuccessMessage = '';
                item.displaySoErrorMessage = '';
                item.displaySoErrorDetailMessage = '';
                item.displaySoTrackingURLDetailMessage = [];
                item.displaySoTransporterName = '';
                // if (getresponse['IsError'] && (getresponse['Status'] === 30 || getresponse['Status'] === 999)) {
                //   item.displaySoButton = true;
                // }
                // if (getresponse['IsError'] && (getresponse['Status'] !== 30 && getresponse['Status'] !== 999)) {
                //   item.displaySoErrorMessage = getresponse['Message'];
                // }
                // if (!getresponse['IsError']) {
                //   item.displaySoSuccessMessage = getresponse['Message'];
                // }

                if (!getresponse['IsInQueue']) {
                  item.displaySoButton = true;
                } else {
                  if (!getresponse['IsError']) {
                    item.displaySoSuccessMessage = getresponse['StateMessage'];
                  } else {
                    item.displaySoErrorMessage = getresponse['StateMessage'];
                    if (getresponse['ErrorMessages'].length > 0) {
                      getresponse['ErrorMessages'].forEach((dataerror: any, _index: number) => {
                        let addError = '';
                        let lastItem = getresponse['ErrorMessages'][getresponse['ErrorMessages'].length - 1]
                        let lastIndex = getresponse['ErrorMessages'].lastIndexOf(lastItem);
                        if (_index != lastIndex) {
                          addError = ", "
                        }
                        else {
                          addError = '';
                        }
                        item.displaySoErrorDetailMessage += dataerror + addError;
                      });
                    }
                  }

                  /** To get and display Tracking URL's, TransporterName for SO orders */
                  if (getresponse['SentQuantities'].length > 0) {
                    getresponse['SentQuantities'].forEach((datasend: any, _index: number) => {
                      if (datasend['TransporterName'] !== undefined && datasend['TransporterName'] !== null &&
                        datasend['TransporterName'] !== '') {
                        item.displaySoTransporterName = datasend['TransporterName'];
                      }
                      if (datasend['TrackingNumbers'].length > 0) {
                        datasend['TrackingNumbers'].forEach((datatrackingurls: any, _index: number) => {
                          item.displaySoTrackingURLDetailMessage.push(datatrackingurls);
                        });
                      }
                    });
                  }
                  else {
                    if (getresponse['TransporterName'] !== undefined && getresponse['TransporterName'] !== null &&
                      getresponse['TransporterName'] !== '') {
                      item.displaySoTransporterName = getresponse['TransporterName'];
                    }
                    if (getresponse['TrackingNumbers'].length > 0) {
                      getresponse['TrackingNumbers'].forEach((datatrackingurls: any, _index: number) => {
                        item.displaySoTrackingURLDetailMessage.push(datatrackingurls);
                      });
                    }
                  }
                }
              }
            }, (error: any) => {
              this.showLoader = false;
              if (error['status'] === 404) {
                this.toastServcie.error('Internal Error Occur');
              }
              if (error.error) {
                this.toastServcie.error(error.error);
              }
            });
          }
        });

        /** Code to display "Create IS" button based on Non-ISP products when click "Delivery Notes" Button */
        deliveryNotesData.forEach(item => {
          console.log("item : ", item);
          let callgetIsForNonIspProductStatus = false;
          item.displayIsButton = false;
          item.displayIsSuccessMessage = '';
          item.displayIsErrorMessage = '';
          item.displayIsErrorDetailMessage = '';
          item.OrderItems.forEach(suborderitem => {
            if (!suborderitem.isInternalStockProduct && Number(item.CustomerID) === 15) {
              callgetIsForNonIspProductStatus = true;
            }
          });
          if (callgetIsForNonIspProductStatus) {
            const requestdata = { deliveryNoteNumber: item.DeliveryNoteNumber };
            this.showLoader = true;
            this.orderModalHttpService.getSoForNonIspProduct(requestdata).subscribe(getresponse => {
              this.showLoader = false;
              if (getresponse) {
                item.displayIsButton = false;
                item.displayIsSuccessMessage = '';
                item.displayIsErrorMessage = '';
                item.displayIsErrorDetailMessage = '';
                item.displayIsTrackingURLDetailMessage = [];
                item.displayIsTransporterName = '';
                if (!getresponse['IsInQueue']) {
                  item.displayIsButton = true;
                } else {
                  // if (getresponse['ErrorMessages'] !== null) {
                  //   if (getresponse['ErrorMessages'].length > 0) {
                  //     item.displayIsErrorMessage = getresponse['ErrorMessages'];
                  //   }
                  // }
                  // if (getresponse['StateMessage'] !== '') {
                  //   item.displayIsSuccessMessage = getresponse['StateMessage'];
                  // }
                  if (!getresponse['IsError']) {

                    item.displayIsSuccessMessage = getresponse['StateMessage'];
                  } else {
                    item.displayIsErrorMessage = getresponse['StateMessage'];
                    if (getresponse['ErrorMessages'].length > 0) {
                      getresponse['ErrorMessages'].forEach((dataerror: any, _index: number) => {
                        let addError = '';
                        let lastItem = getresponse['ErrorMessages'][getresponse['ErrorMessages'].length - 1]
                        let lastIndex = getresponse['ErrorMessages'].lastIndexOf(lastItem);
                        if (_index != lastIndex) {
                          addError = ", "
                        }
                        else {
                          addError = '';
                        }
                        item.displayIsErrorDetailMessage += dataerror + addError;
                      });
                    }
                  }

                  /** To get and display Tracking URL's, TransporterName for SO orders */
                  if (getresponse['SentQuantities'] && getresponse['SentQuantities'].length > 0) {
                    getresponse['SentQuantities'].forEach((datasend: any, _index: number) => {
                      if (datasend['TransporterName'] !== undefined && datasend['TransporterName'] !== null &&
                        datasend['TransporterName'] !== '') {
                        item.displayIsTransporterName = datasend['TransporterName'];
                      }
                      if (datasend['TrackingNumbers'].length > 0) {
                        datasend['TrackingNumbers'].forEach((datatrackingurls: any, _index: number) => {
                          item.displayIsTrackingURLDetailMessage.push(datatrackingurls);
                        });
                      }
                    });
                  }
                  else {
                    if (getresponse['TransporterName'] !== undefined && getresponse['TransporterName'] !== null &&
                      getresponse['TransporterName'] !== '') {
                      item.displaySoTransporterName = getresponse['TransporterName'];
                    }
                    if (getresponse['TrackingNumbers'] && getresponse['TrackingNumbers'].length > 0) {
                      getresponse['TrackingNumbers'].forEach((datatrackingurls: any, _index: number) => {
                        item.displaySoTrackingURLDetailMessage.push(datatrackingurls);
                      });
                    }
                  }
                }
              }
            }, (error: any) => {
              this.showLoader = false;
              if (error['status'] === 404) {
                this.toastServcie.error('Internal Error Occur');
              }
              if (error.error) {
                this.toastServcie.error(error.error);
              }
            });
          }
        });

        if (deliveryNotesData) {
          const modalData = {
            type: 'deliveryNotes',
            header: `Delivery Notes`,
            deliveryNotes: deliveryNotesData
          };
          this.modalRef = this.modalService.show(DeliveryNoteModalComponent, {
            ...this.modalOptions, data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
          });

          this.modalRef.content.modalAction.subscribe((result: any) => {
            const { data, action } = result;
            if (action === 'download') {
              this.toastServcie.success('Please wait for download to start');
              this.orderModalHttpService.downloadDeliveryNote(data.invoiceID);
            } else if (action === 'delete') {
              this.orderModalHttpService.deleteDeliveryNote(data.invoiceID).subscribe(response => {
                if (response) {
                  this.toastServcie.success('Delivery note successfully deleted.');
                  this.getOrderList.emit(this.summaryData.OrderID);
                  this.modalRef.hide();
                  this.isDialogOpened = false;
                } else {
                  this.toastServcie.error('Delivery note could not be deleted.');
                }
              }, error => {
                this.toastServcie.error('Delivery note could not be deleted.');
              });
            } else if (action === 'createso') {
              const requestdata = { deliveryNoteNumber: data.deliverynotenumber, customerId: data.customerID };
              this.showLoader = true;
              this.orderModalHttpService.createSoForIspProduct(requestdata).subscribe(createresponse => {
                this.showLoader = false;
                this.modalRef.hide();
                this.isDialogOpened = false;
                this.toastServcie.success('SO created Successfull for ' + data.deliverynotenumber);
              }, (error: any) => {
                this.showLoader = false;
                if (error.error) {
                  this.toastServcie.error(error.error);
                }
                if (error['status'] !== 500 && error['status'] !== 400 && error['status'] !== 404) {
                  this.toastServcie.error('unable to Sales order items');
                }
              });
            } else if (action === 'createis') {
              const requestdata = { deliveryNoteNumber: data.deliverynotenumber, customerId: data.customerID };
              this.showLoader = true;
              this.orderModalHttpService.createSoForNonIspProduct(requestdata).subscribe(createresponse => {
                this.showLoader = false;
                this.modalRef.hide();
                this.isDialogOpened = false;
                this.toastServcie.success('IS created Successfull for ' + data.deliverynotenumber);
              }, (error: any) => {
                this.showLoader = false;
                if (error.error) {
                  this.toastServcie.error(error.error);
                }
                if (error['status'] !== 500 && error['status'] !== 400 && error['status'] !== 404) {
                  this.toastServcie.error('unable to Send incoming stock order to warehouse');
                }
              });
            }
          });
        }
      }, error => {
        this.toastServcie.error('Error while getting delivery notes');
        this.isDialogOpened = false;
      });
  }



  newProformaInvoicesPopup(data) {
    const selectedProformaInvoicesData = [];
    this.subOrderItems.forEach((subOrder: any, index: number) => {
      const orderItems = [];
      subOrder.OrderItems.forEach((orderItem, index) => {
        if (orderItem.isPartialProformaEnabled) {
          orderItem.checked = false;
          orderItems.push(orderItem);
        }
      });
      if (orderItems.length > 0) {
        console.log('if')
        subOrder.OrderItems = orderItems;
        selectedProformaInvoicesData.push(subOrder);
      }
    });

    const modalData = {
      type: 'newProformaInvoices',
      header: `New Commercial Invoice`,
      proformaInvoices: selectedProformaInvoicesData,
      invoiceRecipientEmail: this.summaryData.InvoiceRecipientEmail,

    };
    this.modalRef = this.modalService.show(PerformaInvoiceComponent, {
      ...this.modalOptions, data: modalData
    });

    this.modalService.closed.subscribe(result => {
      this.isDialogOpened = false;
      this.getOrderList.emit(this.summaryData.OrderID);
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      const { data, sendEmail } = result;
      console.log(result.handlingCost)
      this.orderHttpService.creteProformaInvoices(sendEmail, this.orderId, data, result.handlingCost).subscribe(response => {
        this.modalRef.hide();
        this.isDialogOpened = false;
        this.toastServcie.success('Commercial Invoice created succesfully');

      }, error => {
        this.toastServcie.error('Error while creating performa invoice');
      });
    });
  }

  proformaInvoicesPopup(data) {
    this.orderHttpService.listProformaInvoices(this.summaryData.OrderID)
      .subscribe((proformaInvoicesData: any) => {
        if (proformaInvoicesData) {

          const modalData = {
            type: 'proformaInvoices',
            header: `Commercial Invoices`,
            proformaInvoices: proformaInvoicesData,
            orderId: this.orderId
          };
          this.modalRef = this.modalService.show(OrderItemModalComponent, {
            ...this.modalOptions, class: 'modal-lg modal-top', data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
            this.getOrderList.emit(this.summaryData.OrderID);
          });

        }
      });
  }


  invoicesPopup(data) {
    this.orderHttpService.getInvoices(this.summaryData.OrderID)
      .subscribe((invoicesData: any) => {
        if (invoicesData) {
          const modalData = {
            type: 'invoices',
            header: `Invoices`,
            proformaInvoices: invoicesData,
            orderId: this.orderId
          };
          this.modalRef = this.modalService.show(OrderItemModalComponent, {
            ...this.modalOptions, class: 'modal-lg modal-top', data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
            this.getOrderList.emit(this.summaryData.OrderID);
          });
        }
      });
  }



  newCreditNotePopup(event) {
    let newCreditNoteOrderItemIds = [];
    let newCreditNoteOrderItemQuantities = [];
    let newCreditNoteOrderItemTransportation = [];
    this.orderHttpService.getCreditNoteOrderItems(this.summaryData.OrderID)
      .subscribe((creditNoteData: any) => {
        if (creditNoteData) {
          creditNoteData.forEach(item => {
            newCreditNoteOrderItemIds[item.OrderItemID] = false;
            newCreditNoteOrderItemQuantities[item.OrderItemID] = item.DispatchedQuantity - item.CreditNoteQuantity;
            newCreditNoteOrderItemTransportation[item.OrderItemID] = false;
          });
          const modalData = {
            type: 'newCreditNote',
            header: `New Credit Note `,
            newCreditNoteItems: creditNoteData,
            newCreditNoteOrderItemIds: newCreditNoteOrderItemIds,
            newCreditNoteOrderItemQuantities: newCreditNoteOrderItemQuantities,
            newCreditNoteOrderItemTransportation: newCreditNoteOrderItemTransportation,
            invoiceRecipientEmail: this.summaryData.InvoiceRecipientEmail
          };
          this.modalRef = this.modalService.show(CreditNoteModalComponent, {
            ...this.modalOptions, data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
          });

          this.modalRef.content.modalAction.subscribe((result: any) => {
            const { data } = result;
            this.orderModalHttpService.createCreditNote(data).subscribe(response => {
              if (response) {
                this.toastServcie.success('Credit note successfully created.');
                this.modalRef.hide();
                this.isDialogOpened = false;
                this.getOrderList.emit(this.summaryData.OrderID);
              } else {
                this.toastServcie.error('Error while creating credit note.');
              }
            }, error => {
              this.toastServcie.error('Error while creating credit note.');
            });
          });
        }
      });
  }

  creditNotePopup(event) {
    this.orderHttpService.getCreditNotes(this.summaryData.OrderID)
      .subscribe((creditNotesData: any) => {
        if (creditNotesData) {
          const modalData = {
            type: 'creditNotes',
            header: `Credit Notes`,
            creditNotesList: creditNotesData
          };
          this.modalRef = this.modalService.show(CreditNoteModalComponent, {
            ...this.modalOptions, data: modalData
          });

          this.modalService.closed.subscribe(result => {
            this.isDialogOpened = false;
          });

          this.modalRef.content.modalAction.subscribe((result: any) => {
            const { data, action } = result;
            if (action === 'sendCreditNoteMail') {
              this.toastServcie.success('Please wait for email with attached credit note to be sent.');
              this.orderModalHttpService.sendCreditNoteMail(data.invoiceID).subscribe(response => {
                if (response) {
                  // alert(response);
                }
              });
            }
            if (action === 'download') {
              this.toastServcie.success('Please wait for download to start');
              this.orderModalHttpService.downloadCreditNote(data.invoiceID);
            } else if (action === 'delete') {
              this.orderModalHttpService.deleteCreditNote(data.invoiceID).subscribe(response => {
                if (response) {
                  this.toastServcie.success('Credit note successfully deleted.');
                  this.getOrderList.emit(this.summaryData.OrderID);
                  this.modalRef.hide();
                  this.isDialogOpened = false;
                } else {
                  this.toastServcie.error('Credit note could not be deleted.');
                }
              }, error => {
                this.toastServcie.error(error.error);
              });
            }
          });
        }
      });
  }
  getDataOfList() {
    const arrayList = [];
    this.subOrderItems.forEach((subOrder: any, index: number) => {
      const orderItems = [];
      subOrder.OrderItems.forEach((orderItem, index) => {
        if (orderItem.isPartialProformaEnabled) {
          orderItems.push(orderItem);
        }
      });
      console.log(orderItems);
      if (orderItems.length > 0) {
        // subOrder.OrderItems = orderItems;
        arrayList.push(subOrder);
      }
    });
    this.getDataArrayList = arrayList;
  }
}
