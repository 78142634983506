import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { DashboardActions } from '../actions/index';
import { State } from '../reducers/index';
import { DashboardHttpService } from '../services/dashboard.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class DashboardEffects {

    @Effect()
    getDashboardOrderList$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_DASHBOARD_ORDERLIST),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.dashboardHttpService.getDashboardOrderList(payload.payload).map((data) => {
                // data preparation done here... image to be made image tag, etc.
                return new DashboardActions.LoadDashboardOrderListSuccess(data);
            });
        }),
        catchError((error: any) => {
                return Observable.of(new DashboardActions.LoadDashboardOrderListFail(error));
            })
        );


    @Effect()
    getStockList$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_STOCK),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getStockList(payload.payload).map((data)=>{
                return new DashboardActions.LoadStockSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadStockFail(error));
        })
    );

    @Effect()
    getOrderFlow$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_ORDERFLOW),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getOrderFlow(payload.payload).map((data)=>{
                return new DashboardActions.LoadOrderFlowSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadOrderFlowFail(error));
        })
    );

    @Effect()
    getProductionLine$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_PRODUCTIONLINE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getProductionLine(payload.payload).map((data)=>{
                return new DashboardActions.LoadProductionLineSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadProductionLineFail(error));
        })
    );

    @Effect()
    getProductList$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_PRODUCTIONLINE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getProductList(payload.payload).map((data)=>{
                return new DashboardActions.LoadProductListSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadProductListFail(error));
        })
    );

    @Effect()
    getMessage$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_MESSAGE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getMessage(payload.payload).map((data)=>{
                return new DashboardActions.LoadMessageSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadMessageFail(error));
        })
    );

    @Effect()
    getOrderState$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_ORDERSTATE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getOrderState(payload.payload).map((data)=>{
                return new DashboardActions.LoadOrderStateSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadOrderStateFail(error));
        })
    );

    @Effect()
    getPendingUser$: Observable<Action> = this.actions$.pipe(
        ofType(DashboardActions.LOAD_PENDINGUSER),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) =>{
            return this.dashboardHttpService.getPendingUser(payload.payload).map((data)=>{
                return new DashboardActions.LoadPendingUserSuccess(data);
            })
        }),
        catchError((error: any) =>{
            return Observable.of(new DashboardActions.LoadPendingUserFail(error));
        })
    );

    

    constructor(private actions$: Actions,
                private store$: Store<State>,
                private dashboardHttpService: DashboardHttpService) { }
}
