import { Component, OnInit } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { SupplierService } from '../state/services/supplier.service';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { SupplierHttpService } from '../state/services/supplier.http.service';
import { LinkTableService } from 'src/app/shared/link-table/state/services/link-table.service';
import { TabsetComponent, ToastService, ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})

export class SupplierListComponent implements OnInit {

  header: any;
  supplierList: any = [];
  supplierdatacount: any;
  tableName: string = 'supplierList';
  actions: any = ['editsupplier', 'deletesupplier', 'viewsupplier'];
  submitted = false;
  filterFields: any = [];
  suppliers: any = [];
  supplierName: any = "";
  perPagedata: number = 10;
  rowPagepage: number = 10;
  enablepagination: boolean = true;
  activePage: number = 1;
  rowactivePage: number = 1;
  resetStatus: boolean = false;
  searchCriteria: any = {};
  filterData: any = {};
  passFilterresult: any = [];
  commonRemoveFilter: any;
  showLoader: Boolean = false;
  webshopDetail: any;
  childwebshop: any = [];
  CustomerID: any = "";
  columns: ColumnSetting[] = [
    // {
    //   primaryKey: 'SupplierID',
    //   header: 'Supplier ID'
    // },
    {
      primaryKey: 'Name',
      header: 'Supplier Name'
    },
    {
      primaryKey: 'QualityLevel',
      header: 'Supplier quality level',
      format: 'supplierquality'
    },
    {
      primaryKey: 'action',
      format: 'action'
    }
  ];

  constructor(private supplierHttpService: SupplierHttpService, private supplierService: SupplierService,
    private apiService: ApiService, private productHttpService: ProductHttpService, private router: Router,
    private linkTableService: LinkTableService, private toast: ToastService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      this.webshopDetail.CustomerID = this.webshopDetail.CustomerID;
    }
    this.CustomerID = this.webshopDetail.CustomerID;
    this.getCustomerSupplier();
    this.getChildWebshop();
  }

  ngOnInit() {
    this.header = 'Supplier Details'; // Multilingual to be implemented
    const searchkey = sessionStorage.getItem('searchSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
      this.perPagedata = 10;
      this.searchCriteria.pageIndex = 10;
      this.resetStatus = true;
      if (sessionStorage.getItem('supplierEditRedirect') !== null && sessionStorage.getItem('supplierEditRedirect') !== undefined) {
        this.activePage = this.searchCriteria.activePage;
        sessionStorage.removeItem('supplierEditRedirect');
      } else {
        this.activePage = 1;
        this.searchCriteria.activePage = 1;
      }
      sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
    }
    this.onLoadSuppliers();
  }

  /** Customer Based Supplier List in filter section dropdown */
  getCustomerSupplier() {
    this.supplierHttpService.getWebshopSupplier(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.suppliers = [];
        this.suppliers.push({ label: 'Select Supplier', value: '' });
        data.ItemsCollection.forEach(element => {
          const langVal = {
            value: element.SupplierID,
            label: element.Name,
          };
          this.suppliers.push(langVal);
        });
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  getChildWebshop() {
    this.supplierHttpService.getCustomersListInclParent(this.webshopDetail.CustomerID).subscribe((data: any) => {
      if (data) {
        this.childwebshop = [];
        this.childwebshop.push({ label: 'Select All', value: '0' });
        data.forEach(element => {
          const langVal = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.childwebshop.push(langVal);
        });
        this.setfilterData();
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }

  onLoadSuppliers() {
    const searchkey = sessionStorage.getItem('searchSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.getSupplierList(JSON.parse(searchkey));
    } else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        supplierName: '',
        pageSize: 10,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        pageIndex: 10
      };
      this.filterData = this.searchCriteria;
      sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
      // this.userService.loadUsers(this.searchCriteria);
      this.getSupplierList(this.searchCriteria);
    }
  }

  /** Supplier Table List Data */
  getSupplierList(searchCriteria?) {
    console.log("searchCriteria : ", searchCriteria);
    if (!this.webshopDetail.IsWebshopDropdown) {
      searchCriteria.customerId = this.webshopDetail.CustomerID;
    }
    if (searchCriteria.customerId === '' || Number(searchCriteria.customerId) === 0) {
      searchCriteria.customerId = '';
      const webshop = sessionStorage.getItem('webshopDetail');
      if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
        this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (this.webshopDetail.ParentCustomerID !== null) {
          searchCriteria.customerId = this.webshopDetail.CustomerID;
        }
      }
    }
    const searchdata = {
      activePage: this.activePage,
      pageSize: (searchCriteria.pageIndex !== null && searchCriteria.pageIndex !== undefined) ? searchCriteria.pageIndex : 10,
      //  supplierId: (searchCriteria.supplierID !== null && searchCriteria.supplierID !== undefined) ? searchCriteria.supplierID : '',
      supplierName: (searchCriteria.supplierName !== null && searchCriteria.supplierName !== undefined) ? searchCriteria.supplierName : '',
      customerId: (searchCriteria.customerId !== null && searchCriteria.customerId !== undefined) ? searchCriteria.customerId : '',
    };
    this.showLoader = true;
    this.supplierHttpService.getSupplierList(searchdata).subscribe((datas: any) => {
      this.showLoader = false;
      if (datas) {
        this.supplierList = [];
        // if (searchdata.supplierId !== null && searchdata.supplierId !== '' && searchdata.supplierId !== undefined) {
        //   this.supplierList.push(datas);
        //   this.supplierdatacount = this.supplierList.length;
        // } else {
        //   this.supplierList = datas.ItemsCollection;
        //   this.supplierdatacount = datas.TotalItemsCount;
        // }

        this.supplierList = datas.ItemsCollection;
        this.supplierdatacount = datas.TotalItemsCount;

        const webshop = sessionStorage.getItem('webshopDetail');

        // tslint:disable-next-line:max-line-length
        /** If login as child customer then we need to restrict Edit/Delete Supplier based on condition (CreatedByCustomerID = loggedincustomerId) */
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
          //  const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
          this.supplierList.forEach(element => {
            element.EditActionEnabled = true;
            element.DeleteActionEnabled = true;
            // if (webshopDetail.ParentCustomerID !== null) {
            if (!element.IsEditable) {
              element.EditActionEnabled = false;
            }
            if (!element.IsDeletable) {
              element.DeleteActionEnabled = false;
            }
            // }
          });
        }
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }


  /** Paginaion code Section Functions */
  getPerPage(event) {
    console.log("Get Per Page : ", event);
    this.perPagedata = event;
    this.searchCriteria.pageIndex = event;
    this.getSubmit(this.searchCriteria);
  }

  paginationNum(event) {
    console.log("paginationNum : ", event);
    this.activePage = event;
    const searchkey = sessionStorage.getItem('searchSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    this.searchCriteria.activePage = event;
    sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
    this.perPagedata = this.searchCriteria.pageIndex;
    this.getSupplierList(this.searchCriteria);
  }


  /** Filter code Section Functions */
  setfilterData() {
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }
    this.filterFields = [
      {
        label: 'Filter',
        class: 'col-md-12',
        key: 'filterHeading',
        filterkey: ''
      },
      {
        label: 'Select Webshop',
        type: 'select',
        class: 'col-md-12',
        key: 'selectwebshop',
        data: this.childwebshop,
        selected: (this.webshopDetail.IsWebshopDropdown && isParentCustomer) ? this.CustomerID : '',
        required: false,
        filterkey: 'selectwebshop'
      },
      {
        label: 'Supplier',
        type: 'text',
        class: 'col-md-12',
        key: 'supplier',
        data: this.suppliers,
        selected: this.supplierName,
        required: false,
        filterkey: 'supplier'
      },
      {
        label: 'Clear',
        type: 'button',
        class: 'col-sm-6 text-lefts mt-3 margin-width',
        key: 'clear',
      },
      {
        label: 'Search',
        type: 'button',
        class: 'col-sm-6 text-left mt-3 margin-width',
        key: 'button',
      },
    ];

    const searchResult = sessionStorage.getItem('searchSupplierResult')
    if (searchResult !== null) {
      const searchSupplierResult = JSON.parse(sessionStorage.getItem('searchSupplierResult'))
      /** To Prefill Supplier dropdown value(based on Login) */
      // tslint:disable-next-line:max-line-length
      const getIndex = searchSupplierResult.findIndex(ind => ind.key === 'supplier');
      console.log("searchSupplierResult[getIndex].selected : ", searchSupplierResult[getIndex].selected);
      this.filterFields[getIndex].selected = searchSupplierResult[getIndex].selected;

      /** To Prefill Webshop dropdown value(based on Login) */
      // tslint:disable-next-line:max-line-length
      if (this.webshopDetail.IsWebshopDropdown !== undefined && this.webshopDetail.IsWebshopDropdown !== null) {
        const getIndex1 = searchSupplierResult.findIndex(ind => ind.key === 'selectwebshop');
        if (this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
          this.filterFields[getIndex1].selected = searchSupplierResult[getIndex1].selected;
        } else {
          this.filterFields[getIndex1].selected = '';
        }
      }
    }
  }

  getSelectData(data) {
    console.log("getSelectData : ", data);
    if (data !== undefined) {
      this.searchCriteria = {
        // tslint:disable-next-line:max-line-length
        customerId: data.selectwebshop !== undefined && data.selectwebshop.selectMethod === 'selectwebshop' ? data.selectwebshop.value : '',
        // tslint:disable-next-line:max-line-length
        supplierName: data.supplier !== undefined && data.supplier.selectMethod === 'supplier' ? data.supplier.value : '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    } else {
      this.searchCriteria = {
        customerId: this.webshopDetail.CustomerID,
        supplierName: '',
        pageIndex: this.perPagedata,
        rowpageIndex: this.rowPagepage,
        activePage: this.activePage,
        rowactivePage: this.rowactivePage
      };
    }
    const webshop = sessionStorage.getItem('webshopDetail');
    let isParentCustomer = true;
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (this.webshopDetail.ParentCustomerID !== null) {
        isParentCustomer = false;
      }
    }

    this.filterData = this.searchCriteria;
    this.filterFields.forEach(element => {
      if (element.filterkey === 'supplier') {
        element.selected = this.searchCriteria.supplierName;
      } else if (element.filterkey === 'selectwebshop' && this.webshopDetail.IsWebshopDropdown && isParentCustomer) {
        element.selected = this.searchCriteria.customerId;
      }
    });
  }

  getSubmit(submit) {
    this.activePage = 1;
    this.searchCriteria.activePage = this.activePage;
    this.filterData = this.searchCriteria;
    this.getSupplierList(this.filterData);
    sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchSupplierResult', JSON.stringify(this.filterFields));
  }

  getReset(reset) {
    this.CustomerID = this.webshopDetail.CustomerID;
    this.passFilterresult = [];
    this.activePage = 1;
    this.perPagedata = 10;
    this.searchCriteria.pageIndex = 10;
    // this.filterFields.forEach(element => {
    //   if (element.filterkey === 'supplier') {
    //     element.selected = '';
    //   }
    //   if (element.filterkey === 'selectwebshop') {
    //     element.selected = '';
    //   }
    // });
    this.resetStatus = true;
    // this.getPerPage(10);
    this.getSelectData(undefined);
    sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
    sessionStorage.setItem('searchSupplierResult', JSON.stringify(this.filterFields));
    this.setfilterData();
    this.onLoadSuppliers();
  }


  /** Remove Filter Tags Section Code */
  getRemovedFilter(val) {
    this.commonRemoveFilter = val;
    const searchkey = sessionStorage.getItem('searchSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      this.searchCriteria = JSON.parse(searchkey);
    }
    if (val !== undefined) {
      if (val.key === "selectwebshop") {
        this.searchCriteria.customerId = this.webshopDetail.CustomerID;
      }
      this.filterData = this.searchCriteria;
      this.filterFields.forEach(element => {
        if (element.filterkey === val.key) {
          element.selected = '';
        }
      });
      sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
      sessionStorage.setItem('searchSupplierResult', JSON.stringify(this.filterFields));
      this.getSupplierList(this.filterData);
    }
  }

  getFilterResult(filterresult) {
    this.passFilterresult = filterresult;
  }

  /** Table Actions(Edit, Delete, View, Add) Functions */
  addSupplier(event: boolean) {
    this.submitted = event;
  }

  viewSupplier(event: boolean) {
    this.submitted = event;
  }


  editSupplier(data: any) {
    console.log("Edit Dt : ", data);
    if (data !== '') {
      const searchkey = sessionStorage.getItem('searchSupplierKey');
      if (searchkey !== null && searchkey !== '[object Object]') {
        this.searchCriteria = JSON.parse(searchkey);
        this.perPagedata = 10;
        this.searchCriteria.pageIndex = 10;
        this.resetStatus = true;
        sessionStorage.setItem('searchSupplierKey', JSON.stringify(this.searchCriteria));
      }
      //  this.showUserEditpage = true;
      data.supplierEditPage = true;
      this.router.navigate(['/admin-supplier-edit'], { state: { data }, replaceUrl: false });
    }
  }

  deleteSupplier(deletedData: any) {
    console.log("deletedData : ", deletedData);
    this.showLoader = true;
    this.supplierHttpService.deleteSupplier(deletedData).subscribe((data: any) => {
      this.showLoader = false;
      if (data) {
        this.toast.success('Supplier deleted successfully');
        this.getCustomerSupplier();
        this.onLoadSuppliers();
      } else {
        this.toast.error('Supplier cannot be deleted');
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);
      });
  }

  /** Page Reedirect Functions */
  pageredirect() {
    this.router.navigate(['/admin-supplier-add'], { replaceUrl: true });
  }

  ratingUpdate(data) {
    console.log("ratingUpdate : ", data);
    let customerId = '';
    const searchkey = sessionStorage.getItem('searchSupplierKey');
    if (searchkey !== null && searchkey !== '[object Object]') {
      const searchCriteria = JSON.parse(searchkey);
      if (searchCriteria.customerId === '' || Number(searchCriteria.customerId) === 0) {
        customerId = '';
      } else {
        customerId = searchCriteria.customerId;
      }
    }
    if (!this.webshopDetail.IsWebshopDropdown) {
      customerId = this.webshopDetail.CustomerID;
    }

    this.showLoader = true;
    const updateData = {
      QualityLevel: data.QualityLevel
    }
    this.supplierHttpService.supplierRatingUpdata(updateData, data.SupplierID, customerId).subscribe((datas: any) => {
      this.showLoader = false;
      // this.onLoadSuppliers();
      if (datas) {
        this.toast.success('Supplier Rating updated successfully');
      } else {
        this.toast.error('Supplier Rating update failure');
      }
    },
      err => {
        console.log(err);
        this.showLoader = false;
      });
  }
}
