import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { UserActions } from '../actions/index';
import { State } from '../reducers/index';
import { UserListHttpService } from '../services/user.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';


@Injectable()
export class UserEffects {

    @Effect()
    getUsersList$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_USERS),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.userListHttpService.getUsersList(payload.payload).map((data) => {
                return new UserActions.LoadUsersSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadUsersFail(error));
        })
    );

    @Effect()
    getAllCountries$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_COUNTRIES),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.userListHttpService.getAllCountries().map((data) => {
                return new UserActions.LoadCountriesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadCountriesFail(error));
        })
    );

    @Effect()
    getAllCustomers$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_CUSTOMERS),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.userListHttpService.getAllCustomers().map((data) => {
                return new UserActions.LoadCustomersSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadCustomersFail(error));
        })
    );

    @Effect()
    getAllRoles$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_ROLES),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.userListHttpService.getAllRoles().map((data) => {
                return new UserActions.LoadRolesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadCountriesFail(error));
        })
    );

    @Effect()
    getAllLanguages$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_LANGUAGES),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.userListHttpService.getAllLanguages().map((data) => {
                return new UserActions.LoadLanguagesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadLanguagesFail(error));
        })
    );


    @Effect()
    getAllCurrencies$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_CURRENCIES),
        withLatestFrom(this.store$),
        switchMap(([]: any) => {
            return this.userListHttpService.getAllCurrencies().map((data) => {
                return new UserActions.LoadCurrenciesSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadCurrenciesFail(error));
        })
    );


    @Effect()
    deleteUser$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.LOAD_DELETE),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.userListHttpService.deleteUser(payload.payload).map((data) => {
                return new UserActions.LoadDeleteSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadDeleteFail(error));
        })
    );

    @Effect()
    addUser$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.ADD_USER),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.userListHttpService.addUser(payload.payload).map((data) => {
                return new UserActions.AddUserSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.AddUserFail(error));
        })
    );

    @Effect()
    uploadFile$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.FILE_UPLOAD),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.userListHttpService.uploadFile(payload.payload, payload.params, payload.header).map((data) => {
                return new UserActions.LoadFileUploadSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadFileUploadFail(error));
        })
    );

    @Effect()
    updateUser$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.UPDATE_USER),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.userListHttpService.updateUser(payload.payload).map((data) => {
                return new UserActions.UpdateUserSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.UpdateUserFail(error));
        })
    );

    @Effect()
    validateUser$: Observable<Action> = this.actions$.pipe(
        ofType(UserActions.VALIDATE_USER),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.userListHttpService.validateUser(payload.payload).map((data) => {
                return new UserActions.LoadValidateUserSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new UserActions.LoadValidateUserFail(error));
        })
    );

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private userListHttpService: UserListHttpService) { }
}
