import { Action } from '@ngrx/store';
export const LOAD_ORDERS = '[Webshop] Load all Orders';
export const LOAD_ORDERS_SUCCESS = '[Webshop] Load Orders Success';
export const LOAD_ORDERS_FAIL = '[Webshop] Load Orders Fail';

export class LoadOrders implements Action {
    readonly type = LOAD_ORDERS;
    constructor(public payload: any) { }
}

export class LoadOrdersSuccess implements Action {
    readonly type = LOAD_ORDERS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadOrdersFail implements Action {
    readonly type = LOAD_ORDERS_FAIL;
    constructor(public payload: any) { }
}

export type All =
    | LoadOrders
    | LoadOrdersSuccess
    | LoadOrdersFail;

