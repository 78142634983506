import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import * as moment from 'moment';
import { request } from 'http';
import { exists } from 'fs';

@Injectable({
    providedIn: 'root'
})
export class OrderHttpService {
    webshopDetail: any;
    userData: any
    constructor(private apiService: ApiService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        this.userData = sessionStorage.getItem('userdetails');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        }
    }

    getProductionLinesOverview(limit, loggedInCustomerID, payload, includChildShopOrders) {
        const path = 'en/productionlinesoverview?pageIndex=' + limit.pageIndex +
            '&pageSize=' + limit.pageSize + '&LoggedInCustomerID=' + loggedInCustomerID +
            '&includChildShopOrders=' + includChildShopOrders;
        let payloadData = {};
        if (payload.CustomerID === 0) {
            payloadData = { ...payload, ...{ 'CustomerID': loggedInCustomerID } };
        } else {
            payloadData = { ...payload };
        }
        return this.apiService.post(path, payloadData).map((res) => {
            return res;
        });
    }

    getOrdersList(pageLimit, queryString, payload = [], orderProcessPayload) {
        const query = (queryString && queryString !== '') ? '&' + queryString : '';
        let path;
        if(this.webshopDetail.DomainName === "91sb" || this.webshopDetail.DomainName === "91springboard" || this.webshopDetail.DomainName === "aed" ){
            path = 'en/orderprocess?suborders=true&pageIndex=' +
            pageLimit.pageIndex + '&pageSize=' + pageLimit.pageSize +
            '&LoggedInCustomerID=' + orderProcessPayload.loggedInCustomerID +
            decodeURIComponent(query) +
            '&includChildShopOrders=' + orderProcessPayload.includChildShopOrders+'&UserID='+this.userData.UserID;
        }else{
            path = 'en/orderprocess?suborders=true&pageIndex=' +
            pageLimit.pageIndex + '&pageSize=' + pageLimit.pageSize +
            '&LoggedInCustomerID=' + orderProcessPayload.loggedInCustomerID +
            decodeURIComponent(query) +
            '&includChildShopOrders=' + orderProcessPayload.includChildShopOrders;
        }
        return this.apiService.post(path, payload).map((res) => {
            return res;
        });
    }

    getOrderProductionLineDetail(id, finishedPL, customerID, loggedInCustomerID) {
        let param = '';
        if (finishedPL === 'true') {
            param = param + '&finishedPLs=true';
        }
        const url = `en/productionlinesdetails/${id}?CustomerID=${customerID}&LoggedInCustomerID=${loggedInCustomerID}${param}`;

        return this.apiService.get(url);
    }

    getSuppliers(customerId, LoggedInCustomerID) {
        const path = `en/suppliers?returnOnlyCustomerSupplier=true&customerID=${customerId}&LoggedInCustomerID=${LoggedInCustomerID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getListOrderStatusDetails() {
        const path = 'en/orderitemstates?roleCode=LinkNetwork_SuperAdmin&altaPayStates=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    productionlinestatesURL(parameter, loggedInCustomerID) {
        return `en/productionlinestates?roleCode=Webshop_Admin&customerID=${parameter}`;
    }

    getProductionLineStates(query: any, customerId) {
        let parameter = ''
        if ((typeof query === "boolean" && query === true) || query == 0) {
            parameter = '1&showCounts=false&includChildShopOrders=true';
        }
        else {
            parameter = query + '&showCounts=false&includChildShopOrders=false';
        }
        const apiURL = this.productionlinestatesURL(parameter, customerId);
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    updateProductionLineDate(orderId, date, IsProductionLineEmail, DiscountPercentage, DeliveryNotificationMessage, DonotAllowJoinOrders, customerID) {
        const { Roles } = this.apiService.getLoginData();
        let roleCode = '';
        if (Roles) {
            if (Roles.length === 1) {
                roleCode = Roles[0].Code;
            }
        }
        const data = {
            DeliveryDate: date,
            LastUpdatedByUserID: customerID,
            IsProductionLineEmail,
            DeliveryNotificationMessage,
            DonotAllowJoinOrders
        };
        if (DiscountPercentage > 0) {
            data[DiscountPercentage] = DiscountPercentage;
        }
        const url = `en/productionlines/${orderId}/?roleCode=${roleCode}&customerID=${customerID}`;
        return this.apiService.post(url, data);
    }

    getDeliveryNotificationMsgHistory(productionLineId) {
        const apiURL = `en/deliveryNotificationMessages/${productionLineId}`;
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    updateProductionLineProcessOrderStatus(orderId, field, data) {
        const url = `en/${field}/${orderId}`;
        return this.apiService.put(url, data);
    }

    updateInternalProductionLine(orderId, data) {
        const url = `en/productionlines/internalnote/${orderId}`;
        return this.apiService.put(url, data);
    }

    getPaymentsData() {
        const path = 'en/paymenttypes';
        return this.apiService.get(path);
    }

    getUnderSuppliers(customerId, supplierID, loggedInCustomerID) {
        const customerID = customerId !== 0 ? customerId : loggedInCustomerID;
        const path = `en/undersuppliers?customerID=${customerID}&SupplierID=${supplierID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }
    /** get order details */
    getOrderDetail(orderId: any, isSuborder, isUnauthorised, customerID) {
        const queryString = (isUnauthorised) ? '?unauthorisedOrder=true' : '?suborders=' + isSuborder;
        const apiURL = 'en/orders/' + orderId + queryString + '&LoggedInCustomerID=' + customerID;
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    /** get Country List */
    getCountryList() {
        const apiURL = 'en/countries';
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    getCompanyList() {
        const getCustomerDetails = JSON.parse(sessionStorage.getItem('customerDetails'));
        const apiURL = 'en/customer/GetCompanyList?ParentCustomerID='+getCustomerDetails.CustomerID;
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    /** get User Logos */
    getUserLogos(userId) {
        const apiURL = 'en/userlogos/' + userId;
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    /** get Product Detail */
    getProductDetail(productId) {
        const apiURL = 'en/products/' + productId + '?deleted=true&invisible=true';
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    /** get Product Detail */
    getProductionLineDetail(productionLineId, CustomerId, CurrencyID) {
        const apiURL = 'en/productionLines/' + productionLineId + '?customerID=' + CustomerId + '&CurrencyID=' + CurrencyID;
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    /** To Update order Details */
    updateOrder(orderData, orderID) {
        const path = 'en/orders/' + orderID + '?roleCode=Webshop_Admin';
        return this.apiService.post(path, orderData).map((res) => {
            return res;
        });
    }

    /** Upload User Logos */
    uploadLogos(userLogos) {
        const path = 'en/uploadfile';
        return this.apiService.postFileUpload(path, userLogos, { responseType: 'json' });
    }

    /** Upload User Logos */
    uploadUserLogos(userLogos) {
        const path = 'en/userlogos';
        return this.apiService.post(path, userLogos).map((res) => {
            return res;
        });
    }

    dateFormatchange(value) {
        if (value !== '') {
            const myDate = moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD');
            return myDate;
        } else {
            return value;
        }
    }

    viewDateFormat(value) {
        if (value !== '' && value.split('/').pop().length != 4) {
            const myDate = moment(value, 'YYYY/MM/DD').format('DD/MM/YYYY');
            return myDate;
        } else {
            return value;
        }
    }

    getOrderOverView(orderId, customerID) {
        const path = `en/orders/${orderId}?suborders=true&deliveryNotesData=true&LoggedInCustomerID=${customerID}`;
        return this.apiService.get(path);
    }

    getOrderDetails(orderId, subOrderFlag, customerID) {
        const path = `en/orders/${orderId}?adminCustomerID=1&suborders=${subOrderFlag}&LoggedInCustomerID=${customerID}`;
        return this.apiService.get(path);
    }

    getEditDetails(orderId) {
        const path = `en/orders/${orderId}?suborders=false`;
        return this.apiService.get(path);
    }

    getCountries() {
        const path = `en/countries`;
        return this.apiService.get(path);
    }

    getOrderItemDetails(orderId, customerId) {
        const path = `en/orders/${orderId}?suborders=true&deliveryNotesData=true&LoggedInCustomerID=${customerId}`;
        return this.apiService.get(path);
    }

    getDeliveryNoteOrderItems(orderId, subOrderItemId = null) {
        let subOrderItemQuery = '';
        if (subOrderItemId !== null) {
            subOrderItemQuery += '?suborderID=' + subOrderItemId;
        }
        const path = `en/deliverynote/availableorderitems/${orderId}${subOrderItemQuery}`;
        return this.apiService.get(path);
    }

    getTrackOrderSearch(data){
        const path = 'trackOrder/GetTrackOrderSearch'
        return this.apiService.post3(path, data);
      }

    listDeliveryNotes(orderId) {
        const path = `en/deliverynote/list/${orderId}`;
        return this.apiService.get(path);
    }

    creteProformaInvoices(sendMail, orderId, data, handTransCost) {

        const path = `en/partialproforma/order/${orderId}?sendEmail=${sendMail}&IncludeHandlingandTC=${handTransCost}`;
        return this.apiService.post(path, data);
    }


    //performa invoice

    listProformaInvoices(orderId) {
        const path = `en/invoices/getAllInvoices/${orderId}?isProforma=true`;
        return this.apiService.get(path);
    }

    getInvoices(orderId) {
        const path = `en/invoices/getAllInvoices/${orderId}`;
        return this.apiService.get(path);
    }
    sendInvoice(invoiceNumber, orderWiseFlag = undefined) {
        const path = `en/sendInvoiceToEmail/pdf/${invoiceNumber}?OrderWise=${orderWiseFlag}`;
        return this.apiService.get(path);
    }
    downloadInvoice(invoiceNumber, OrderWise) {
        const path = `${this.apiService.baseUrl}/en/invoiceDownload/pdf/${invoiceNumber}?OrderWise=${OrderWise}`;
        window.location.href = path;
        // return this.apiService.get(path);
    }
    deleteInvoice(invoiceNumber) {
        const path = `en/invoices/${invoiceNumber}`;
        return this.apiService.delete(path);
    }
    getCaptureSuborderAmount(suborderId, data) {
        const url = `en/orders/getUnCapturedItems/${suborderId}`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };
    getSubOrderCaptureUnSuborderAmount(data) {
        const url = `en/orders/getMultipleSubOrderUnCapturedAmount`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };
    getSubOrderCaptureSuborderAmount(data) {
        const url = `en/orders/captureMultipleSubOrderAmount`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };
    postnewNotification(data) {
        const url = `en/orders/notifications`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };
    getNotifications(suborderId) {
        const url = `en/orders/notifications/${suborderId}`;
        return this.apiService.get(url).map(res => {
            return res;
        });
    };
    captureSuborderAmount(suborderId, data) {
        const url = `en/orders/captureSubOrder/${suborderId}`;
        return this.apiService.post(url, data).map(res => {
            return res;
        });
    };

    createDeliveryNote(payload) {
        const path = 'en/deliverynote';
        return this.apiService.post(path, payload).map((res) => {
            return res;
        });
    }
    /** Delete Logos */
    deleteLogo(orderlogoId) {
        const path = 'en/userlogos/' + orderlogoId;
        return this.apiService.delete(path).map(res => {
            return res;
        });
    }

    /** Delete Logos */
    getPaymentTypes() {
        const apiURL = 'en/paymentTypes';
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    /**
     * Service to Check Postal Code for Selected Country
     */

    checkPostalCode(countryCode, postalCode) {
        const apiURL = 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode;
        return this.apiService.get(apiURL).map((res) => {
            return res;
        });
    }

    orderStates() {
        // const url = 'en/orderitemstates?roleCode=LinkNetwork_SuperAdmin';
        const url = 'en/orderitemstates?roleCode=Webshop_Admin';
        return this.apiService.get(url).map((res) => {
            return res;
        });
    }

    ordersList(request) {
        // const url = `en/orders?pageIndex=${request.selectedpage}&pageSize=${request.selectedperpage}&CustomerID=${request.customerID}`;
        // const url = 'en/orders/5741?deliveryNotesData=true&CustomerID=1&suborders=false';
        const url = 'en/orders/' + request.orderId + '?deliveryNotesData=true&CustomerID=' + request.customerID + '&suborders=' + request.subOrder;
        return this.apiService.get(url).map((res) => {
            return res;
        });
    }

    /** To Send Email To Customer for Edit order Details */
    sendUpdateOrderEmail(requestData) {
        const path = 'en/orders/SendUpdateEmailtoCustomer';
        return this.apiService.post(path, requestData).map((res) => {
            return res;
        });
    }

    deleteOrder(request) {
        const path = `en/orderitems/${request.orderID}`;
        return this.apiService.delete(path).map((res) => {
            return res;
        });
    }

    orderItemState(requestID, request) {
        const path = `en/orderitemstates/${requestID.OrderItemID}/enter/${requestID.OrderItemStateID}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    multipleOrderItemState(requestID, request) {
        const path = `en/multipleorderitemstates/enter/${requestID.OrderItemStateID}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    getWebshopCustomers(customerID) {
        const path = 'en/customers/list?ParentCustomerID=' + customerID + '&returnParentCustomer=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    dispatchOrder(requestVal, request) {
        let shippingInvoice = requestVal.isShippingInvoice ? '&isShipmentInvoice=' + requestVal.isShippingInvoice : '';
        const path = `en/${requestVal.urlType}/${requestVal.orderID}/enter?sendEmail=${requestVal.sendMail}${shippingInvoice}`;
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    getCreditNoteOrderItems(orderId) {
        const path = `en/creditnote/availableorderitems/${orderId}`;
        return this.apiService.get(path);
    }

    getCreditNotes(orderId) {
        const path = `en/creditnote/list/${orderId}?noInvoiceData=true`;
        return this.apiService.get(path);
    }

    getUnauthorisedOrders(orderId, loggedInCustomerID) {
        const path = `en/orders/${orderId}?unauthorisedOrder=true&LoggedInCustomerID=${loggedInCustomerID}`;
        return this.apiService.get(path);
    }

    authorizedOrderToInvoice(orderId, payload) {
        const path = `en/orders/authorizeorderwithrecalculatedtransportation/${orderId}?isAuthorizeToInvoice=true
        `;
        return this.apiService.put(path, payload);
    }

    recalculateTransportation(orderID, payload) {
        const path = `en/orders/recalculateordertransportation/${orderID}`;
        return this.apiService.put(path, payload);
    }

    authorize(orderID, payload) {
        const path = `en/orders/authorizeorderwithrecalculatedtransportation/${orderID}`;
        return this.apiService.put(path, payload);
    }

    sendOrderConfirmation(orderID) {
        const path = `en/orders/sendtransportationcalculatedmail/${orderID}`;
        return this.apiService.get(path);
    }

    deleteunauthorisedOrder(orderID) {
        const path = `en/orders/unauthorized/${orderID}`;
        return this.apiService.put(path);
    }

    updatedispatch(request) {
        const path = 'en/UpdateTrackingNumberandCompany';
        return this.apiService.put(path, request);
    }

    orderCustomer(customerID) {
        const path = `en/customers/${customerID}`;
        return this.apiService.get(path);

    }

    downloadDeliveryNote(filepath) {
        const path = `${this.apiService.baseUrl}/en/deliverynote/pdf/${filepath}`;
        window.location.href = path;
    }

    dispatchDetailOrder(requestVal, request) {
        let shippingInvoice = requestVal.isShippingInvoice ? '&isShipmentInvoice=' + requestVal.isShippingInvoice : '';
        const path = `en/${requestVal.urlType}/${requestVal.orderID}/enter?sendEmail=${requestVal.sendMail}${shippingInvoice}`;
        console.log(path);
        return this.apiService.post(path, request).map((res) => {
            return res;
        });
    }

    paymentType() {
        const path = 'en/paymenttypes/';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getDeletedOrders(orderId, loggedInCustomerID) {
        const path = `en/orders/${orderId}?deletedOrder=true&LoggedInCustomerID=${loggedInCustomerID}`;
        return this.apiService.get(path);
    }

    newPayment(request) {
        const path = `en/availablepaymenttypes?userID=${request.userID}&customerID=${request.customerID}`;
        return this.apiService.get(path).map((res) => {
            return res;
        });

    }
    updateNewTransportationCost(suborderno, payload) {
        const path = `en/orders/UpdateTransportationCostByAmount?suborderID=${suborderno}`;
        return this.apiService.put(path, payload);
    }

    // delivery date change for sub-order for ISP date
    deliveryDateUpdateISO(updatedValues, payload) {
        const path = `en/orderitems/DeliveryDate/${updatedValues.changedDeliveryDate}?sendMail=${updatedValues.emailCheck}`;
        return this.apiService.put(path, payload);
    }

    downloadProductionLine(ProductionLineID, LoggedInCustomerID) {
        const path = `/en/reports/ProductionLineExport/${ProductionLineID}?LoggedInCustomerID=${LoggedInCustomerID}`
        return this.apiService.get(path).map((res) => {
            return res['ReportFileURL'];
        });
    }
}
