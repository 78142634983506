import { Login } from '../models/index';
import { LoginActions } from '../actions/index';

export interface LoginState {
    customers: any;
    loginData: any;
    loginFail: any;
    isLoggedIn: boolean;
    selectedCustomer: any;
    languages: any;
    customerName: any;
    subDomain: any;
    lang: any;
}

export const initialState: LoginState = {
    customers: null,
    loginData: null,
    loginFail: null,
    isLoggedIn: false,
    selectedCustomer: null,
    languages: null,
    customerName: null,
    subDomain: 'nil',
    lang: null


};

export function loginReducers(state: LoginState = initialState, action: LoginActions.All) {
    switch (action.type) {
        case LoginActions.LOAD_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, { customers: action.payload });

        case LoginActions.LOGIN_SUCCESS:
            return Object.assign({}, state, { loginData: action.payload });

        case LoginActions.LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload
            }

        case LoginActions.LOGIN_FAIL:
            return {
                ...state,
                loginFail: action.payload
            }

        case LoginActions.SELECTED_CUSTOMER:
            return Object.assign({}, state, { selectedCustomer: action.payload });

        case LoginActions.LANGUAGE_SUCCESS:
            return Object.assign({}, state, { languages: action.payload });

        case LoginActions.WEBSHOPLANGUAGE_SUCCESS:
            return Object.assign({}, state, { lang: action.payload });


        case LoginActions.CUSTOMERNAME_SUCCESS:
            return Object.assign({}, state, { customerName: action.payload });

        case LoginActions.SET_SUBDOMAIN:
            return Object.assign({}, state, { subDomain: action.payload });

        default: {
            return state;
        }
    }
}
