import { Injectable } from "@angular/core";
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';

@Injectable()
export class BrandHttpService {
    webshopDetail: any;
    constructor(private apiService: ApiService, private loginService: LoginService) {
        const webshop = sessionStorage.getItem('webshopDetail');
        if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
            this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        } else {
            const loginData = this.apiService.getLoginData();
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
        }
    }

    getBrandList(searchCriteria: any) {
        let request = {
            pageSize: searchCriteria.pageSize !== undefined ? searchCriteria.pageSize : '',
            pageIndex: searchCriteria.pageIndex !== undefined ? searchCriteria.pageIndex : '',
        }
        let path = 'en/ProductBrand?pageSize=' + request.pageSize + '&pageIndex=' + request.pageIndex;
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getBrandData(brandid: any, apiFor?:boolean) {
        let path = "";
        if(apiFor) {
            const loginData = JSON.parse(sessionStorage.getItem('customerDetails'));
            if (loginData !== undefined && loginData !== null && loginData !== '') {
                this.webshopDetail.CustomerID = loginData.CustomerID;
            }
            path = `en/GetBrand?Id=${brandid}&CustomerId=${this.webshopDetail.CustomerID}`;
        } else {
            path = `en/ProductBrand/${brandid}`
        }
        
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }


    addBrand(data: any) {
        const path = 'en/ProductBrand';
        return this.apiService.post(path, data).map((res) => {
            return res;
        });
    }

    uploadFile(data, params?, headers?) {
        const path = 'en/uploadfile';
        return this.apiService.post(path, data).map((res) => {

            return res;
        });
    }
    updateActiveCampaign(data: any) {
        const path = `en/billboard/UpdateActiveBrands?BrandID=${data.Brand}&CustomerID=${data.customerID}&Type=${data.type}`;
        return this.apiService.post(path).map((res) => {
            return res;
        });
    }

}