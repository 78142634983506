import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { BrandState } from '../reducers/brand.reducer';
import { BrandActions } from '../actions/index';
import { FileUploadSuccess } from '../reducers/index';

@Injectable()
export class BrandService {
    constructor(private store: Store<BrandState>) { }
    loaduploadFile(searchCriteria: any) {
        this.store.dispatch(new BrandActions.LoadFileUpload(searchCriteria));
    }

    uploadFile() {
        return this.store.select(FileUploadSuccess);
    }

    selectedBrand(brandData) {
        this.store.dispatch(new BrandActions.SelectedBrand(brandData));
    }
}