import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../../shared/common-services/api.service';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

@Injectable()
export class LoginHttpService {
    constructor(@Inject(DOCUMENT) private _document: HTMLDocument, private apiService: ApiService) { }
    userData: BehaviorSubject<any> = new BehaviorSubject('');
    hyperKart: boolean = false;
    getAllCustomers() {
        const path = 'en/customers/list';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    doLogin(payload: any) {
        let hyperkart = JSON.parse(sessionStorage.getItem("Hyperkart"));
        if(hyperkart){
            this.hyperKart = true;
        }else{
            this.hyperKart = false;
        }
        let dom = "rewardkart";
        // const path = 'en/users?username=' + payload.userName +
        //     '&password=' + payload.password + '&RoleCode=' + payload.roleCode +
        //     '&customerId=' + payload.customerId;
        const request = {
            "UserName": payload.userName,
            "Password": payload.password,
            "RoleCode": payload.roleCode,
            "Domain": this.hyperKart ? "rewardkart" : payload.Domain === "hyperkart" ? dom : payload.Domain  ,

        }
        const path = 'en/users/login';
        let part = location.host.split('.');
        if (part.length === 4) {
            request['Domain'] = part[0];
        }
        return this.apiService.post(path, request);
    }

    getSubDomain(payload: any) {
        if(payload === "hyperkart"){
            payload = "rewardkart";
        }
        return this.apiService.get(`en/customers/subdomain?domain=${payload}`);
    }

    getLanguage() {
        const path = 'en/languages?isOrderbyname=true';
        return this.apiService.get(path).map((res) => {
            return res;
        });
    }

    getCustomer(customername) {
        let hyperkart = JSON.parse(sessionStorage.getItem("Hyperkart"));
        if(hyperkart){
            this.hyperKart = true;
        }else{
            this.hyperKart = false;
        }
        if (!customername && !this.hyperKart) {
            customername = 'rewardkart';
        }
        if(this.hyperKart){
            customername = "rewardkart";
        }
        let part = location.host.split('.');
        if (part.length === 4) {
            customername = part[0];
        }
        const path = 'en/customers?name=' + customername;
        return this.apiService.get(path).map((res) => {
            const response = res;
            //  this._document.getElementById('favicon').setAttribute('href', response['ImageURL']);
            return res;
        })
    }

    getCustomerLanguage(customerid?) {
        let path = 'en/languages';
        if (customerid) {
            path = 'en/languages?customerId=' + customerid;
        }
        return this.apiService.get(path).map((res) => {
            const response = res;
            this._document.getElementById('favicon').setAttribute('href', response['ImageURL']);
            return res;
        })
    }
    getUserToken() {
        const data = {
            UserID: 6669,
            ParentCustomerID: 1,
            ChildCustomerID: 2
        }
        const path = 'en/users/Token';
        return this.apiService.post(path, data);
    }

    verifyToken(postData) {
        const path = 'en/users/verifyToken';
        return this.apiService.post(path, postData);
    }

    resetPassword(username: String, customerId: Number) {
        const path = 'en/users/ForgotPassword';
        return this.apiService.post(path, { 'username': username, 'customerID': customerId });
    }

    setUser(user: any) {
        this.userData.next(user)
    }

    getUser(): Observable<any> {
        return this.userData.asObservable();
    }

}


