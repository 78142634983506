import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule, Actions } from '@ngrx/effects';
import { NgxPrintModule } from 'ngx-print';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { FormsModule } from '@angular/forms';
import { WidgetModalModule } from '../widget-modal/widget-modal.module';
import { NgxEditorModule } from 'ngx-editor';
import { MessageboxReducers } from './state/reducers/messagebox.reducer';
import { MessageboxHttpService } from './state/services/messagebox.http.service';
import { MessageboxService } from './state/services/messagebox.service';
import { MessageboxEffects } from './state/effects';
import { MessageinboxModule } from './message-inbox/message-inbox.module';
import { MessageBoxComponent } from './message-box.component';
import { MessagetrashModule } from './message-trash/message-trash.module';
import { MessagesentmessageModule } from './message-sentmessage/message-sentmessage.module';
import { MessageenquiriesModule } from './message-inquiries/message-inquiries.module';
import { MessageimportantModule } from './message-important/message-important.module';
import { MessagedraftModule } from './message-draft/message-draft.module';
import { MessagecomposeModule } from './message-compose/message-compose.module';
import { MessageclaimsModule } from './message-claims/message-claims.module';
import { MessageboxlistModule } from './message-boxlist/message-boxlist.module';
import { FiltersearchModule } from './filter-search/filtersearch.module';
import { MessageBoxRoutingModule } from './message-box-routing.module';
import { MessageinboxdetailsModule } from './message-inbox/message-inboxdetails/message-inboxdetails.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MessageBoxRoutingModule,
    NgxEditorModule,
    WidgetModalModule,
    MessageinboxModule,
    NgxPrintModule,
    MessagetrashModule,
    MessagesentmessageModule,
    MessageenquiriesModule,
    MessageimportantModule,
    MessagedraftModule,
    MessagecomposeModule,
    MessageclaimsModule,
    MessageboxlistModule,
    FiltersearchModule,
    MessageinboxdetailsModule,
    EffectsModule.forFeature([MessageboxEffects]),
    StoreModule.forFeature('messageboxModule', MessageboxReducers)
  ],
  declarations: [MessageBoxComponent],
  providers: [MessageboxHttpService, MessageboxService],
  exports: [MessageBoxComponent],
})

export class MessageboxModule { }