import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../login/state/services/login.service';
import { UserService } from '../../user/state/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rewardkart-admin-category-copy',
  templateUrl: './category-copy.component.html',
  styleUrls: ['./category-copy.component.scss']
})

export class CategoryCopyComponent implements OnInit {

  headerSource: any;
  headerTarget: any;
  optionsSelectSourceCustomers: Array<any>;
  optionsSelectTargetCustomers: Array<any>;

  constructor(private loginService: LoginService, private userService: UserService, private translate: TranslateService) {
    this.onLoadFunctions();
  }

  ngOnInit() {
    // this.headerSource = 'Source Category Details';
    // this.headerTarget = 'Target Category Details';
    this.translate.get('CATEGORIES.SOURCE_CATEGORY_DETAILS').subscribe((res: string) => {
      this.headerSource = res;
    });
    this.translate.get('CATEGORIES.TARGET_CATEGORY_DETAILS').subscribe((res: string) => {
      this.headerTarget = res;
    });
  }

  onLoadFunctions() {
    this.getSourceCustomer();
    this.getTargetCustomer();
  }

  getSourceCustomer() {
    this.optionsSelectSourceCustomers = [];
    let customerVal = { value: 0, label: 'Linknetwork' };
    this.optionsSelectSourceCustomers.push(customerVal);

    this.loginService.getSelectedCustomer().subscribe(data => {
      if (data) {
        customerVal = { value: data.CustomerID, label: data.Name };
        this.optionsSelectSourceCustomers.push(customerVal);
      }
    });
  }


  getTargetCustomer() {
    this.userService.getAllCustomers().subscribe(data => {
      if (data) {
        this.optionsSelectTargetCustomers = [];
        data.forEach(element => {
          const customerVal = {
            value: element.CustomerID,
            label: element.Name
          };
          this.optionsSelectTargetCustomers.push(customerVal);
        });
      }
    });
  }

}
