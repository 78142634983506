import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragdropDirective, DragItemImage, ConDropPointDirective } from './dragdrop.directive';


@NgModule({
  declarations: [DragdropDirective, DragItemImage, ConDropPointDirective],
  imports: [
    CommonModule
  ],
  exports: [DragdropDirective, DragItemImage, ConDropPointDirective],
  entryComponents: [
    DragItemImage
  ],
})
export class DragdropModule { }