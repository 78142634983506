import { Component, OnInit, OnDestroy } from '@angular/core';
import { MDBModalRef, IMyOptions } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { OrderHttpService } from 'src/app/order/state/services/order.http.service';

interface ModalActionType {
  data: any;
  action: string;
  type: string;
}
@Component({
  selector: 'rewardkart-admin-prodction-line-details-edit-modal',
  templateUrl: './production-line-status-modal.component.html',
  styleUrls: ['./production-line-status-modal.component.scss']
})
export class ProdctionLineStatusModalComponent implements OnInit, OnDestroy {
  order = null;
  header = '';
  type = 'deliveryEdit';
  deliveryDateLabel = '';
  dateModel = new Date();
  deleteData: any;
  message = ''; //used to show server status
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  DatePickerOptions: any;
  date = new Date();
  public datePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
  };
  IsProductionLineEmail = true;
  DonotAllowJoinOrders: any;
  discountPercentage = '';
  DeliveryNotificationMessage = '';
  enableDeliveryNot: boolean = true;
  allowJoinOrders: boolean = false;
  DeliveryHistory: any = [];
  showHistory: boolean;
  isVisible: any;
  constructor(public modalRef: MDBModalRef, private orderService: OrderHttpService) {

  }

  ngOnInit() {
    debugger
    if (this.DonotAllowJoinOrders != undefined) {
      this.DonotAllowJoinOrders = JSON.parse(this.DonotAllowJoinOrders.toLowerCase());
    }
    console.log("DonotAllowJoinOrders" + this.DonotAllowJoinOrders);
    this.IsProductionLineEmail = true;
  }

  textAreaAdjust(element) {
    debugger
    element.style.height = "1px";
    element.style.height = (25 + element.scrollHeight) + "px";
  }

  modalConfirmed(data) {
    switch (this.type) {
      case 'deliveryEdit':
        this.modalAction.next({
          data: {
            dateModel: this.dateModel, IsProductionLineEmail: this.IsProductionLineEmail,
            discountPercentage: this.discountPercentage, DeliveryNotificationMessage: this.DeliveryNotificationMessage,
            DonotAllowJoinOrders: this.DonotAllowJoinOrders
          }, action: 'update', type: this.type
        });
        break;
      case 'productionStarted':
        this.modalAction.next({
          data: {
            dateModel: this.dateModel, status:
              this.order.isProductionStarted, Note: this.order.ProductionStartedNote
          }, action: 'update', type: this.type
        });
        break;
      case 'productionCompleted':
        this.modalAction.next({
          data: {
            dateModel: this.dateModel, status:
              this.order.isProductionCompleted, Note: this.order.ProductionCompletedNote
          }, action: 'update', type: this.type
        });
        break;
      case 'dispatched':
        this.modalAction.next({
          data, action: 'view', type: this.type
        });

    }
  }
  modalCanceled() {
    this.modalRef.hide();
  }

  ngOnDestroy() {
    this.modalAction.unsubscribe();
  }

  checkDeliveryNotfication(event) {
    if (event.checked == true) {
      this.enableDeliveryNot = true;
      this.IsProductionLineEmail = true;
    }
    else {
      this.enableDeliveryNot = false;
      this.IsProductionLineEmail = false;
    }
  }

  getJoinOrderValue(event) {
    if (event.checked == true) {
      this.DonotAllowJoinOrders = true;
    }
    else {
      this.DonotAllowJoinOrders = false;
    }
  }

  toggleVisibillity(event) {
    if (event == true) {
      this.showHistory = true;
      document.getElementById("deliveryDateModal").setAttribute("style", "overflow-y : auto !important;max-height: 450px;");
    }
    else {
      this.showHistory = false;
      document.getElementById("deliveryDateModal").setAttribute("style", "overflow-y : visible !important");
    }
  }
}
