import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductGridComponent } from './product-grid.component';
import { ControlValidationModule } from 'src/app/shared/control-validation/control-validation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdvancedSearchModule } from '../product-adv-search/product-adv-search.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSplitModule } from 'angular-split';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [ProductGridComponent],

  imports: [
    CommonModule,
    ControlValidationModule,
    SharedModule,
    AdvancedSearchModule,
    FormsModule,
    NgxPaginationModule,
    PerfectScrollbarModule,
    AngularSplitModule.forRoot(),
    DragDropModule
  ],
  exports: [ProductGridComponent]
})
export class ProductGridModule { }
