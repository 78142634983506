import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ToastService, MdbAutoCompleterComponent, ModalDirective } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';
import { SupplierHttpService } from '../state/services/supplier.http.service';
import * as _ from 'lodash';

@Component({
  selector: 'rewardkart-admin-manage-supplier-settings',
  templateUrl: './manage-supplier-settings.component.html',
  styleUrls: ['./manage-supplier-settings.component.scss']
})
export class ManageSupplierSettingsComponent implements OnInit, OnChanges {
  @ViewChild('deleteSupplierModal', { static: true }) deleteSupplierModal: ModalDirective;
  @Input() supplier;
  @Input() customerList;
  @Output() modalCloseStatus = new EventEmitter<any>();
  @Output() supplierUpdateStatus = new EventEmitter<any>();
  @Input() setCustomerSupplierCallStatus;
  // @Input() manageSupplier: any = [];
  manageSupplier: any = [];
  webshopCustomerList = [];
  searchForm: FormGroup;
  showLoader: boolean = false;

  perPagedata: number = 20;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 20;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  selectedSupplier: any = {
    SupplierID: ''
  };
  selectSupplierForSubmit: Array<any> = new Array<any>();
  customerSupplier: any = [];
  selectedCustomerId: any;
  isParentWebshop: Boolean = true;
  webshopName: any;
  initialCustomerSupplier: any = [];
  deletedSupplier: any = [];
  deletedSupplierProductCount: any = [];
  webshopDetail: any;
  orderDetails: any;
  pagewiseUnselectSupplierList: any = [];
  constructor(private supplierHttpService: SupplierHttpService,
    public apiService: ApiService, public toast: ToastService, private translate: TranslateService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.selectedCustomerId = this.webshopDetail.CustomerID;
    if (this.customerList === undefined) {
      this.getAllCustomerList();
    }
  }

  ngOnInit() {
    /** Customer Dropdown Enable/Disable based on Parent/Child Login Shop(Ex., Conxion, Ambu) */
    const webshopExists = sessionStorage.getItem('webshopDetail');
    if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.webshopName = webshopDetail.Name;
      if (webshopDetail.ParentCustomerID !== null && webshopDetail.ParentCustomerID !== undefined) {
        this.isParentWebshop = false;
      }
    }
    this.loadForm();
    if (this.customerList !== undefined) {
      this.setCustomerList();
    }
    this.supplierDropdown(null, null, true, true);
    // this.setCustomerSupplier();
  }
  loadForm() {
    this.searchForm = new FormGroup({
      CustomerID: new FormControl('')
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setCustomerSupplierCallStatus !== undefined && changes.setCustomerSupplierCallStatus !== null) {
      this.setCustomerSupplier();
    }
    if (changes.customerList) this.setCustomerList();
  }
  // supplierDropdown() {
  //   const loginData = this.apiService.getLoginData();
  //   this.categoryService.supplierDropdown(loginData.CustomerID).subscribe((data: any) => {
  //     if (data) {
  //       data.ItemsCollection.forEach(element => {
  //         element.label = element.Name;
  //         element.value = element.SupplierID;
  //       });
  //       this.manageSupplier = data.ItemsCollection;
  //       this.setCustomerSupplier();
  //     }
  //   },
  //   error => {
  //     this.toast.error(error)
  //   });
  // }

  /** GET All customer list based on Login CustomerId */
  getAllCustomerList() {
    if (sessionStorage.getItem('customerList') !== undefined && sessionStorage.getItem('customerList') !== null
      && sessionStorage.getItem('customerList') !== '') {
      this.customerList = JSON.parse(sessionStorage.getItem('customerList'));
      this.setCustomerList();
    } else {
      this.showLoader = true;
      this.supplierHttpService.getCustomersList().subscribe((data: any) => {
        if (data) {
          this.showLoader = false;
          this.customerList = data;
          sessionStorage.setItem('customerList', JSON.stringify(data));
          this.setCustomerList();
        }
      },
        err => {
          console.log(err);
          this.showLoader = false;
        });
    }
  }

  setCustomerSupplier(initialCustomerSupplierAllowStatus = true) {
    this.supplierHttpService.getCustomerSupplier(this.selectedCustomerId).subscribe((data: any) => {
      this.customerSupplier = [];
      if (!this.customerSupplier.length) {
        this.customerSupplier = data;
        this.customerSupplier.forEach(row => {
          let idx = this.selectSupplierForSubmit.findIndex((val: any) => val.SupplierID === row.SupplierID)
          if (idx < 0) this.selectSupplierForSubmit.push(row)
        });
      }

      if (this.selectSupplierForSubmit) {
        this.manageSupplier.forEach(option => {
          option.isSelected = false;
          if (option.IsCustomerSupplier !== undefined && option.IsCustomerSupplier !== null) {
            delete option.IsCustomerSupplier;
          }
        });
        if (this.selectSupplierForSubmit.length > 0) {
          this.selectSupplierForSubmit.forEach(value => {
            const test = this.manageSupplier.filter((data1) => data1.SupplierID === value.SupplierID);
            if (test.length > 0) {
              const index = this.manageSupplier.findIndex(record => record.SupplierID === test[0].SupplierID);
              this.manageSupplier[index].isSelected = true;
              if (value.IsCustomerSupplier !== undefined && value.IsCustomerSupplier !== null) {
                if (value.IsCustomerSupplier) {
                  this.manageSupplier[index].IsCustomerSupplier = true;
                }
              }
            }
          });
        } else {
          this.translate.get('CATEGORIES.SUPPLIER_NOT_FOUND').subscribe((res: string) => {
            this.toast.info(res);
          });
        }
        if (this.selectSupplierForSubmit.length > 0 && initialCustomerSupplierAllowStatus) {
          this.initialCustomerSupplier = [];
          this.selectSupplierForSubmit.forEach(row => {
            this.initialCustomerSupplier.push(row.SupplierID);
          });
        }

        // tslint:disable-next-line:max-line-length
        /** code to manage unselect supplier while change pagination and revert back to same page (Ex.,Page 1 to 2 and come back from Page 2 to 1) */
        if (this.pagewiseUnselectSupplierList.length > 0) {
          this.pagewiseUnselectSupplierList.forEach(value => {
            const test = this.manageSupplier.filter((data1) => data1.SupplierID === value);
            if (test.length > 0) {
              const index = this.manageSupplier.findIndex(record => record.SupplierID === test[0].SupplierID);
              this.manageSupplier[index].isSelected = false;
            }
            let sIdx = this.selectSupplierForSubmit.findIndex((s: any) => s.SupplierID === value)
            if (sIdx >= 0) {
              this.selectSupplierForSubmit.splice(sIdx, 1);
            }
          });
        }
      }
    },
      error => {
        this.toast.error(error)
      });
  }

  supplierSelect(event, item, index) {
    //Push select supplier in array
    let sIdx = this.selectSupplierForSubmit.findIndex((s: any) => s.SupplierID === item.SupplierID)
    if (sIdx < 0 && event.checked) {
      this.selectSupplierForSubmit = [...this.selectSupplierForSubmit, item];
      const unSelectIndex = this.pagewiseUnselectSupplierList.findIndex(x => Number(x) === Number(item.SupplierID));
      if (unSelectIndex >= 0) {
        this.pagewiseUnselectSupplierList.splice(unSelectIndex, 1);
      }
    } else {
      this.selectSupplierForSubmit.splice(sIdx, 1);
      this.pagewiseUnselectSupplierList.push(item.SupplierID);
    }
    this.manageSupplier[index].isSelected = event.checked;
  }

  selectAll(event) {
    if (event.checked) {
      this.manageSupplier.forEach(option => {
        option.isSelected = true;
      });
    } else {
      this.manageSupplier.forEach(option => {
        if (option.IsCustomerSupplier !== undefined && option.IsCustomerSupplier !== null) {
          if (option.IsCustomerSupplier) {
            option.isSelected = true;
          }
        } else {
          option.isSelected = false;
        }
      });
    }
  }

  checkCustomerSupplierDelete() {
    let supplierDeleteCount = 0;
    let deletedSupplierIDs = [];
    const SupplierIDs = this.selectSupplierForSubmit.map(val => val.SupplierID);
    this.initialCustomerSupplier.forEach(intialSupplierId => {
      if (!SupplierIDs.includes(intialSupplierId)) {
        supplierDeleteCount += 1;
        deletedSupplierIDs.push(intialSupplierId);
      }
    });
    if (supplierDeleteCount > 0) {
      let customerId = '';
      const webshopExists = sessionStorage.getItem('webshopDetail');
      if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
        const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        customerId = webshopDetail.CustomerID;
      } else {
        // const loginData = this.apiService.getLoginData();
        // if (loginData !== undefined && loginData !== null && loginData !== '') {
        //   customerId = loginData.CustomerID;
        // }
        customerId = this.webshopDetail.CustomerID;
      }
      const requestData = {
        CustomerID: customerId,
        SupplierIDs: deletedSupplierIDs
      };
      this.supplierHttpService.setCustomerSupplierProductCount(requestData).subscribe((data: any) => {
        if (data !== null) {
          this.deletedSupplier = [];
          this.deletedSupplierProductCount = [];
          data.forEach(element => {
            this.deletedSupplier.push(element.SupplierName);
            const productCount = (element.ProductCount !== undefined) ? element.ProductCount : 0;
            this.deletedSupplierProductCount.push(productCount)
          });
          this.deleteSupplierModal.show();
        }
      },
        err => {
          let errMsg = '';
          this.showLoader = false;
          if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
            for (var key in err.error.ModelState) {
              errMsg += err.error.ModelState[key] + ' ';
            }
          } else {
            errMsg += err.error;
          }
          this.toast.error(errMsg);
        });
    } else {
      this.updateCustomerSupplier();
    }
  }

  updateCustomerSupplier() {
    let SupplierIDs = this.selectSupplierForSubmit.map(val => val.SupplierID);
    let finalSupplierIDs = {};
    let deletecount = 0;
    let insertcount = 0;
    this.initialCustomerSupplier.forEach(intialSupplierId => {
      if (SupplierIDs.includes(intialSupplierId)) {
        Object.assign(finalSupplierIDs, { [`${intialSupplierId}`]: "NoChange" });
      } else {
        deletecount += 1;
        Object.assign(finalSupplierIDs, { [`${intialSupplierId}`]: "Delete" });
      }
    });

    SupplierIDs.forEach(updatedSupplierId => {
      if (!this.initialCustomerSupplier.includes(updatedSupplierId)) {
        insertcount += 1;
        Object.assign(finalSupplierIDs, { [`${updatedSupplierId}`]: "Insert" });
      }
    });
    const requestData = {
      CustomerID: this.selectedCustomerId,
      //  SupplierIDs
      SupplierIDs: finalSupplierIDs
    }
    this.supplierHttpService.setCustomerSupplier(requestData).subscribe((data: any) => {
      if (data) {
        this.modalCloseStatus.emit(true);
        this.supplierUpdateStatus.emit(true);
        setTimeout(() => {
          this.setCustomerSupplier();
        }, 300);
        if (deletecount > 0 && insertcount === 0) {
          this.translate.get('CATEGORIES.SUPPLIER_DELETE_TO_CUSTOMER').subscribe((res: string) => {
            this.toast.success(res);
          });
        } else {
          this.translate.get('CATEGORIES.SUPPLIER_ASSIGN_TO_CUSTOMER').subscribe((res: string) => {
            this.toast.success(res);
          });
        }
      }
    },
      err => {
        let errMsg = '';
        this.showLoader = false;
        if (err.error.ModelState !== undefined && err.error.ModelState !== null) {
          for (var key in err.error.ModelState) {
            errMsg += err.error.ModelState[key] + ' ';
          }
        } else {
          errMsg += err.error;
        }
        this.toast.error(errMsg);
      });
  }

  supplierDropdown(customerId?, pageReset?, pagewiseUnselectSupplierListResetStatus?, initialCustomerSupplierAllowStatus?) {
    this.selectedCustomerId = (customerId !== null && customerId !== undefined) ? customerId.value : this.webshopDetail.CustomerID;
    // this.categoryService.supplierDropdown().subscribe((data: any) => {
    //   if (data) {
    //     console.log(data)
    //     data.ItemsCollection.forEach(element => {
    //       element.label = element.Name;
    //       element.value = element.SupplierID;
    //     });
    //     this.manageSupplier = data.ItemsCollection;
    //     this.setCustomerSupplier();
    //   }
    // })

    /** Pafe Reset when customer dropdown change */
    if (pageReset !== null && pageReset !== undefined) {
      this.selectedpage = 1;
      this.selectedperpage = 20;
    }
    this.searchCriteria = {
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      SupplierID: this.selectedSupplier.Name
    };


    this.supplierHttpService.supplierDropdownWithPagination(this.searchCriteria).subscribe((data: any) => {
      if (data) {
        if (pagewiseUnselectSupplierListResetStatus) {
          this.pagewiseUnselectSupplierList = [];
        }
        this.manageSupplier = [];
        data.ItemsCollection.forEach(element => {
          element.label = element.Name;
          element.value = element.SupplierID;
        });
        this.manageSupplier = data.ItemsCollection;
        this.setCustomerSupplier(initialCustomerSupplierAllowStatus);

        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      }
    })
  }

  setCustomerList() {
    /** Webshop Libraray Section (Left Side section) => To load Webshop List Based on Login Shop(Ex., Conxion, Ambu) */
    let isParentWebshop = true;

    const webshopExists = sessionStorage.getItem('webshopDetail');
    if (webshopExists !== null && webshopExists !== undefined && webshopExists !== 'undefined') {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ParentCustomerID !== null) {
        isParentWebshop = false;
      }
    }
    let webshopCustomerList = [];
    // check parent or child webshop
    if (isParentWebshop) {
      // tslint:disable-next-line:max-line-length
      webshopCustomerList = this.customerList.filter(x => (x.ParentCustomerID === this.webshopDetail.CustomerID || x.CustomerID === this.webshopDetail.CustomerID || x.ParentCustomerID === null));
    } else {
      webshopCustomerList = this.customerList.filter(x => x.CustomerID === this.webshopDetail.CustomerID);
    }
    const webshopData = [];
    webshopCustomerList.forEach(option => {
      webshopData.push({ label: option.Name, value: option.CustomerID })
    });
    this.webshopCustomerList = webshopData;

    if (this.searchForm) {
      this.searchForm.patchValue({
        CustomerID: this.webshopDetail.CustomerID
      });
    }

  }

  cancelFunc() {
    this.setCustomerSupplier();
    this.modalCloseStatus.emit(true);
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }

  autoCompleteProductSize(data) {

    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      translations: true,
      SupplierID: 1,
      selectedLangCode: 'en'
    }

    this.groupCollection = [];

    this.supplierHttpService.supplierAutoComplete(request, this.searchForm.value.CustomerID).subscribe((res: any) => {

      if (!res.ItemsCollection.length) this.textNoResults = 'No Results found';

      if (res) {
        this.groupCollection = res.ItemsCollection;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedSupplier = selected;
    this.setPageSizeOptions(20);
  }

  onOptionSelected(event) {
    this.selectedSupplier = event.text
  }

  filterReset(options: any = {}) {
    this.searchText = '';
    this.selectedSupplier.SupplierID = '';
    this.selectedSupplier.Name = '';
    this.textNoResults = '';
    this.groupCollection = [];
    if (options.paginationReset) {
      this.customerSupplier = []
      this.serarchSupplier();
      this.selectSupplierForSubmit = []
      return;
    }
    if (this.manageSupplier) {
      const data = {
        value: this.selectedCustomerId
      };
      this.supplierDropdown(data, null, false, false);
    }
  }

  pageChanged(page) {
    this.selectedpage = page;
    const data = {
      value: this.selectedCustomerId
    };
    this.supplierDropdown(data, null, false, false);
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    if (this.searchText == '') {
      this.groupCollection = [];
    }
    const data = {
      value: this.selectedCustomerId
    };
    this.supplierDropdown(data, null, false, false);
  }

  serarchSupplier() {
    this.setPageSizeOptions(20);
  }
}
