import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { UserActions } from '../actions/index';
import {
    getUserListSuccess, getAllCountries, getAllCustomers,
    getAllRoles, getDynamicFormFields, getAllLanguages, getAllCurrencies, getdeleteUserSuccess, AddUserSuccess, FileUploadSuccess, UpdateUserSuccess, ValidateUserSuccess
} from '../reducers/index';

@Injectable()
export class UserService {
    constructor(private store: Store<UserState>) { }

    loadUsers(searchCriteria: any) {
        this.store.dispatch(new UserActions.LoadUsers(searchCriteria));
    }

    loadCountries() {
        this.store.dispatch(new UserActions.LoadCountries());
    }

    loadCustomers() {
        this.store.dispatch(new UserActions.LoadCustomers());
    }

    loadRoles() {
        this.store.dispatch(new UserActions.LoadRoles());
    }

    loadSearchFormFields() {
        this.store.dispatch(new UserActions.PrepareDynamicForm());
    }

    loadLanguages() {
        this.store.dispatch(new UserActions.LoadLanguages());
    }

    loadCurrencies() {
        this.store.dispatch(new UserActions.LoadCurrencies());
    }

    getUsersList() {
        return this.store.select(getUserListSuccess);
    }

    getAllCountries() {
        return this.store.select(getAllCountries);
    }

    getAllCurrencies() {
        return this.store.select(getAllCurrencies);
    }

    getAllCustomers() {
        return this.store.select(getAllCustomers);
    }

    getAllRoles() {
        return this.store.select(getAllRoles);
    }

    getAllLanguages() {
        return this.store.select(getAllLanguages);
    }

    getUserDynamicFormFields() {
        return this.store.select(getDynamicFormFields);
    }

    selectedUser(userData) {
        this.store.dispatch(new UserActions.SelectedUser(userData));
    }

    loadUserdelete(searchCriteria: any) {
        this.store.dispatch(new UserActions.LoadDelete(searchCriteria));
    }

    deleteUser() {
        return this.store.select(getdeleteUserSuccess);
    }

    loadAddUser(searchCriteria: any) {
        this.store.dispatch(new UserActions.AddUser(searchCriteria));
    }

    addUser() {
        return this.store.select(AddUserSuccess);
    }

    loaduploadFile(searchCriteria: any) {
        this.store.dispatch(new UserActions.LoadFileUpload(searchCriteria));
    }

    uploadFile() {

        return this.store.select(FileUploadSuccess);
    }

    loadUpdateuser(searchCriteria: any) {
        this.store.dispatch(new UserActions.UpdateUser(searchCriteria));
    }
    updateUser() {
        return this.store.select(UpdateUserSuccess);
    }

    loadvalidateUser(searchCriteria: any) {
        this.store.dispatch(new UserActions.LoadValidateUser(searchCriteria));
    }

    validateUser() {
        return this.store.select(ValidateUserSuccess);
    }


}
