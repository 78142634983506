import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageboxHttpService } from './state/services/messagebox.http.service';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
  public titles: any = 'COMPOSE MESSAGE';
  public showData: any = '';
  isInboxDetailsNavigation = false;
  loadApi: Boolean = false;
  // activeTab: any = 'inbox';

  constructor(public router: Router, private messageboxhttpservice: MessageboxHttpService) {
    this.router.navigate(['/app-message-inbox'], { replaceUrl: true });
  }

  ngOnInit() {
    const mailInfo = window.history.state;
    this.isInboxDetailsNavigation = mailInfo.data ? mailInfo.data.isInboxDetailsNavigation : false;

  }

  toggle(data) {
    if (data === 'composemessage') {
      this.showData = 'composemessage';
    }
    if (data === 'Inboxdata') {
      this.showData = 'Inboxdata';
    }
  }

  getCurrentTab(activetab) {
    if (this.isInboxDetailsNavigation == false) {
      if (activetab == 'composemessage') {
        this.router.navigate(['/app-message-compose'], { replaceUrl: true });
      } else if (activetab == 'sentmessage') {
        this.router.navigate(['/app-message-sentmessage'], { replaceUrl: true });
      } else if (activetab == 'important') {
        this.router.navigate(['/app-message-important'], { replaceUrl: true });
      } else if (activetab == 'draft') {
        this.router.navigate(['/app-message-draft'], { replaceUrl: true });
      } else if (activetab == 'trash') {
        this.router.navigate(['/app-message-trash'], { replaceUrl: true });
      } else if (activetab == 'inquiries') {
        this.router.navigate(['/app-message-inquiries'], { replaceUrl: true });
      } else if (activetab == 'claims') {
        this.router.navigate(['/app-message-claims'], { replaceUrl: true });
      } else {
        this.router.navigate(['/app-message-inbox'], { replaceUrl: true });
      }
    } else {
      this.isInboxDetailsNavigation = false;
    }

  }


  getData(event) {
    console.log(event);
  }


}
