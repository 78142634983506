import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductLogopositionareaComponent } from './product-logopositionarea.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductManageLogopositionsModule } from './product-manage-logo-positions/product-manage-logo-positions.module';
import { ProductManageLogoAreasModule } from './product-manage-logo-areas/product-manage-logo-areas.module';
import { DropdownPopupModule } from 'src/app/common/dropdownpopup/dropdownpopup.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    ProductManageLogopositionsModule,
    ProductManageLogoAreasModule,
    DropdownPopupModule
  ],
  declarations: [ProductLogopositionareaComponent],
  exports: [ProductLogopositionareaComponent]
})
export class ProductLogopositionAreaModule { }
