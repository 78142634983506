import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-product-brand',
  templateUrl: './product-brand.component.html',
  styleUrls: ['./product-brand.component.scss']
})
export class ProductBrandComponent implements OnInit {
  @Input() billboardId: number = null;

  @Input('ProductRoles')
  set ProductRoles(roles) {
    this.selectedRole = this.getRoleIds(
      roles || this.billboardProductroles || []
    );
    this.selectedServerRole = this.selectedRole;
  }
  language = 'en';

  customerId: number = null;

  customerProductRoles = [];

  selectedRole = [];

  selectedServerRole = [];

  billboardProductroles = [];
  webshopDetail: any;
  constructor(private apiService: ApiService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
  }

  ngOnInit() {
    const loginData = this.apiService.getLoginData();
    this.customerId = this.webshopDetail.CustomerID;
    this.language = loginData.UserLanguageCode || 'en';
    this.getCustomerProductRoles(this.customerId);

    if (this.billboardId) {
      this.getBillboardProductRole(this.billboardId);
    }
  }

  getCustomerProductRoles(customerId) {
    const path = `${this.language}/productroles/?customerID=${customerId}`;
    this.apiService.get(path).subscribe(
      (customerProductRoleRes: any) => {
        if (customerProductRoleRes) {
          this.customerProductRoles = customerProductRoleRes.map(
            productRole => ({
              value: productRole.ProductRoleID,
              label: productRole.Name
            })
          );
        }
      },
      error => this.handleError(error)
    );
  }

  getRoleIds(roles) {
    return roles.map(role => role.ProductRoleID);
  }

  getBillboardProductRole(billboardId) {
    const path = `${this.language}/billboard/${billboardId}`;
    this.apiService.get(path).subscribe(
      (roles: any) => {
        this.billboardProductroles = roles.ProductRoles;
        this.selectedServerRole = this.getRoleIds(roles.ProductRoles);
      },
      error => this.handleError(error)
    );
  }

  handleSelectedValue(selectedValue) {
    if (selectedValue.length > this.selectedServerRole.length) {
      const idsToAdd = this.getDifference(
        selectedValue,
        this.selectedServerRole
      );
      for (const id of idsToAdd) {
        this.addProdutRoleToBillboard(id);
      }
    } else if (selectedValue.length < this.selectedServerRole.length) {
      const idsToDelete = this.getDifference(
        this.selectedServerRole,
        selectedValue
      );
      for (const id of idsToDelete) {
        this.deleteProductroleFromBillBoard(id);
      }
    }
  }
  getDifference(primaryRole, secondaryRole) {
    const difference = new Set(primaryRole);
    for (const elem of secondaryRole) {
      difference.delete(elem);
    }
    return difference.values();
  }

  addProdutRoleToBillboard(productRoleID) {
    const path = `${this.language}/productroles/billboardad/${productRoleID}/${this.billboardId}`;
    this.apiService.post(path).subscribe(
      roleAddRes => {
        if (roleAddRes) {
          this.getBillboardProductRole(this.billboardId);
        } else {
          this.showErrorNotification('Unable to add role to billboard');
        }
      },
      error => this.handleError(error)
    );
  }

  deleteProductroleFromBillBoard(productRoleID) {
    const path = `${this.language}/productroles/billboardad/${productRoleID}/${this.billboardId}`;
    this.apiService.delete(path).subscribe(
      (roles: any) => {
        this.getBillboardProductRole(this.billboardId);
      },
      error => this.handleError(error)
    );
  }

  showErrorNotification(message: string) {
    console.log('msg', message);
  }
  handleError(error) {
    console.log('error in product role component', error);
  }
}
