import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ColumnSetting } from '../../shared/link-table/models/link-table-model';
import { ApiService } from '../../shared/common-services/api.service';
import { MessageboxService } from '../state/services/messagebox.service';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { Router } from '@angular/router';
import { TabsetComponent, ToastService } from 'ng-uikit-pro-standard';
import { MessageboxHttpService } from '../state/services/messagebox.http.service';
import { Subject, Observable } from 'rxjs';
@Component({
  selector: 'app-message-sentmessage',
  templateUrl: './message-sentmessage.component.html',
  styleUrls: ['./message-sentmessage.component.scss']
})
export class MessageSentmessageComponent implements OnInit {
  // @Input() loadApiStatus:any;
  //public titles: any = 'Compose Message';
  enablepagination: boolean = true;
  perPagedata: number = 10;
  rowPagepage: number = 10;
  rowactivePage: number = 1;
  titles: any;
  activePage: number = 1;
  customerList: any = [];
  filterFields: any = [];
  showMessageDetailpage: boolean = false;
  showMessageInbox: boolean = true;
  EmailID: any = 0;
  public messagesentbox: any = [];
  messagesentmessageQueryParams: any = {};
  bulkEmailIds: any = [];
  showAll: boolean = true;
  customerId: any;
  tableName: string = 'message';
  roleCode: any = 'ANY';
  sentdatacount: any;
  tableEvent: any;
  public sentmessageData: ColumnSetting[] = [
    {
      primaryKey: 'ProductionLineID',
      header: 'Select',
      format: 'bulkaction',
      key: 'sentmessagecheckbox'
    },
    {
      primaryKey: 'SenderName',
      header: 'Name',
    },
    {
      primaryKey: 'CustomerName',
      header: 'Customer'
    },
    {
      primaryKey: 'SenderEmail',
      header: 'Email',
    },
    {
      primaryKey: 'Subject',
      header: 'Subject',
    },
    {
      primaryKey: 'SendingTime',
      header: 'Date',
      format: 'time'
    }
  ];
  isBulkEditSelectedForDelete = { data: [] };
  showLoader: Boolean = false;
  webshopDetail: any;

  constructor(public messageboxservice: MessageboxService, private router: Router, public apiService: ApiService, private linkTableService: LinkTableService, private toast: ToastService, private messageboxhttpservice: MessageboxHttpService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.onLoadFunctions();

  }

  ngOnInit() {
    this.loadCustomer();
  }

  onLoadFunctions() {
    const loginData = this.apiService.getLoginData();
    this.EmailID = '';
    this.messagesentmessageQueryParams.EmailID = this.EmailID;
    this.messagesentmessageQueryParams.UserID = loginData.UserID;
    this.messagesentmessageQueryParams.customerID = this.webshopDetail.CustomerID;
    this.messagesentmessageQueryParams.showAll = true;
    this.messagesentmessageQueryParams.roleCode = loginData.roleCode;
    this.messagesentmessageQueryParams.activePage = this.activePage;
    this.messagesentmessageQueryParams.pageSize = this.perPagedata;
    sessionStorage.setItem('sentmsgfilter', JSON.stringify(this.messagesentmessageQueryParams))
    this.messageboxservice.loadCustomers(this.messagesentmessageQueryParams)
    this.Messageboxtrash(this.messagesentmessageQueryParams, true);
  }


  Messageboxtrash(request, initialLoad?) {
    this.showLoader = true;
    if (request.customerID === 0 || request.customerID === undefined || request.customerID === '') {
      // const loginData = this.apiService.getLoginData();
      request.showAll = true;
      request.customerID = this.webshopDetail.CustomerID;
    }

    this.messageboxhttpservice.getMessageSentData(request).subscribe((data: any) => {
      sessionStorage.setItem('sentmsgfilter', JSON.stringify(request));
      if (data) {
        this.messagesentbox = data.ItemsCollection;
        this.sentdatacount = data.TotalItemsCount;
        this.showMessageDetailpage = false;
        this.showMessageInbox = true;
      }
      this.showLoader = false;
    },
      (error) => {
        this.showLoader = false;
        this.toast.error(error.error.Message);
      })
  }

  loadCustomer() {
    this.messageboxservice.getCustomers().subscribe((data: any) => {
      if (data) {
        this.customerList = [{ label: 'Select All', value: 0 }];
        data.ItemsCollection.forEach(element => {
          const customers = {
            value: element.CustomerID,
            label: element.Name,
          };
          this.customerList.push(customers);
        });

        this.filterFields = [];
        if (this.customerList.length > 1) {
          this.filterFields.push({
            label: 'Customer',
            type: 'select',
            data: this.customerList,
            class: 'col-md-2',
            key: 'inboxcustomer',
            required: false
          },
            // {
            //   label: 'Role',
            //   type: 'select',
            //   data: [{ label: 'Select webshop', value: '' }, { label: 'Webshop', value: 'Webshop' }],
            //   class: 'col-md-2',
            //   key: 'inboxrole',
            //   required: false
            // },
            {
              label: 'Search',
              type: 'button',
              class: '',
              key: 'button',
            },
            {
              label: 'Refresh',
              type: 'button',
              class: '',
              key: 'reset',
            },
            {
              label: 'Show all',
              type: 'checkbox',
              class: 'ml-auto mr-3',
              key: 'showall',
              required: false,
              checked: true,
              selected: true
            });
        }
        this.filterFields.push({
          label: 'Delete',
          type: 'button',
          class: 'ml-auto mt-2 pt-1',
          key: 'messagedelete',
          model: 'deletesentmessage'
        });
      }
    })
  }

  getPerPage(event) {
    this.perPagedata = event;
    this.messagesentmessageQueryParams.pageSize = event;
    let getFilter = JSON.parse(sessionStorage.getItem('sentmsgfilter'));
    getFilter.pageSize = event;
    sessionStorage.setItem('sentmsgfilter', JSON.stringify(getFilter))
    this.getSubmit('pagechange');
  }
  paginationNum(event) {
    this.activePage = event;
    this.messagesentmessageQueryParams.activePage = event;
    let getFilter = JSON.parse(sessionStorage.getItem('sentmsgfilter'));
    getFilter.activePage = event;
    sessionStorage.setItem('sentmsgfilter', JSON.stringify(getFilter))
    this.getSubmit('pagechange');
  }

  sentdetails(messageData: boolean) {
    if (messageData) {
      this.showMessageDetailpage = true;
      try {
        this.messageboxservice.selectedMessage(messageData);
        this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
          if (data) {
            this.showMessageDetailpage = true;
            this.showMessageInbox = false;
            this.EmailID = data.EmailID;
            this.router.navigate(['/app-sentmessagedetails'], { state: { data }, replaceUrl: false });
          }
        });
      } catch (error) {
      }
    }
  }

  getBulkAction(event) {
    if (event.bulkaction.sentmessagecheckbox !== undefined) {
      let getaction = event.bulkaction.sentmessagecheckbox;
      this.bulkEmailIds = [];
      getaction.forEach(element => {
        this.bulkEmailIds.push(element.EmailID)
      });
    }
    this.isBulkEditSelectedForDelete.data = this.bulkEmailIds;
  }

  deleteRow(event) {
    if (event.deleteAction !== undefined && event.deleteAction.deletesentmessage) {
      const loginData = this.apiService.getLoginData();
      const deleteData = {
        "ActionType": "TRASH",
        "Value": true,
        "EmailIDs": this.bulkEmailIds
      }
      // this.messageboxservice.loadbulkAction(deleteData)

      this.messageboxhttpservice.bulkAction(deleteData, loginData.UserID).subscribe((data: any) => {
        if (data != null) {
          if (data) {
            setTimeout(() => {
              this.toast.success('Email deleted successfully');
              // this.Messageboxtrash();
              this.Messageboxtrash(this.messagesentmessageQueryParams);
              this.bulkEmailIds = [];
              this.isBulkEditSelectedForDelete.data = [];
            }, 300)
          }
        }
      })
    }
  }

  getSubmit(type?) {
    let getFilter = this.messagesentmessageQueryParams;
    if (type === 'pagechange') {
      getFilter = JSON.parse(sessionStorage.getItem('sentmsgfilter'));
    }
    else {
      this.activePage = 1;
      getFilter.activePage = this.activePage;
    }
    this.Messageboxtrash(getFilter);
  }

  getReset(reset) {
    if (reset.selectMethod === 'reset' && reset.value) {
      this.customerId = 0;
      this.showAll = true;
      this.messagesentmessageQueryParams.showAll = true;
      this.messagesentmessageQueryParams.customerID = this.customerId;
      this.messagesentmessageQueryParams.roleCode = 'ANY';
      this.roleCode = "ANY";
      this.activePage = 1;
      this.Messageboxtrash(this.messagesentmessageQueryParams);
      this.loadCustomer();
    }
  }

  getSelectData(event) {
    if (event.inboxcustomer !== undefined && event.inboxcustomer.selectMethod === 'inboxcustomer') {
      this.customerId = event.inboxcustomer.value;
      this.messagesentmessageQueryParams.customerID = this.customerId;
      const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
      this.showAll = false;
      this.filterFields[showStatus]['selected'] = this.showAll;
      this.filterFields[showStatus]['checked'] = this.showAll;
      if (event.inboxcustomer.value === 0) {
        this.messagesentmessageQueryParams.showAll = true;
        this.showAll = true;
        this.filterFields[showStatus]['selected'] = this.showAll;
        this.filterFields[showStatus]['checked'] = this.showAll;
      }
      else {
        this.messagesentmessageQueryParams.showAll = false;
      }
    }
  }

  getFilterType(event) {
    if (event.key === "showall") {
      this.roleCode = 'ANY';
      this.messagesentmessageQueryParams.showAll = event.value;
      this.showAll = event.value;
      // const loginData = this.apiService.getLoginData();
      this.customerId = this.webshopDetail.CustomerID;
      this.messagesentmessageQueryParams.customerID = this.customerId;
      const showStatus = this.filterFields.findIndex(data => data.key === 'showall');
      this.filterFields[showStatus]['selected'] = event.value;
      this.filterFields[showStatus]['checked'] = event.value;
      if (event.value) {
        // this.customerId = '';
        const customerIndex = this.filterFields.findIndex(data => data.key === 'inboxcustomer');
        this.filterFields[customerIndex]['selected'] = '';
        const roleIndex = this.filterFields.findIndex(data => data.key === 'inboxrole');
        if (this.filterFields[roleIndex]) {
          this.filterFields[roleIndex]['selected'] = '';
        }
        this.Messageboxtrash(this.messagesentmessageQueryParams);
      }
    }
    if (event.key === 'inboxrole') {
      this.roleCode = "Webshop";
    }
  }

}