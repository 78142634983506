
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CategoryHttpService } from '../state/services/category.http.service';
import { CategoryService } from '../category-services/category.service';
import { PageControlModule } from 'src/app/shared/page-control/page-control.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CategoryProductlistComponent } from './category-productlist.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DragdropModule } from '../directives/dragdrop.module';
import { TranslateModule } from '@ngx-translate/core';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    PageControlModule,
    NgxPaginationModule,
    PerfectScrollbarModule,
    DragdropModule,
    TranslateModule
  ],
  declarations: [CategoryProductlistComponent],
  providers: [CategoryHttpService, CategoryService, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  exports: [CategoryProductlistComponent]
})

export class CategoryProductlistModule { }
