import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { WebshopActions } from '../actions/index';
import { State } from '../reducers/index';
import { WebshopHttpService } from '../services/webshop.http.service';
import { withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class WebshopEffects {

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private webshopHttpService: WebshopHttpService) { }
}
