import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LinkTableState, linkTableReducers } from './link-table.reducer';

export interface State {
    linkTableModule: LinkTableState;
}

export const reducers = linkTableReducers;

export const getLinkTableState = createFeatureSelector<LinkTableState>('linkTableModule');

export const getSelectedRow = createSelector(getLinkTableState,
    (state: LinkTableState) => state.selectedRow);
