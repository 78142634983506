import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/common-services/api.service';
import { LoginService } from '../../../login/state/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignHttpService {
  baseUrl = 'en/Campaigns';
  webshopDetail: any;
  constructor(
    private apiService: ApiService,
    private loginService: LoginService
  ) { }

  getCampaignList(param) {
    const path = 'en/CampaignProductList?' + param;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  getCampaignDataByCampaignId(ProductCampaignID) {
    const path =
      'en/CampaignProduct/' +
      ProductCampaignID.id +
      '/' +
      ProductCampaignID.CustomerID;
    return this.apiService.get(path).map((res) => {
      return res;
    });
  }

  saveCampaign(data: any) {
    const path = 'en/Campaigns/' + data.ProductCampaignID;
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  updateCampaign(data: any) {
    const path = 'en/UpdateCampaign';
    return this.apiService.post(path, data).map((res) => {
      return res;
    });
  }

  addCampaign(data: any) {
    return this.apiService.post('en/CreateCampaign', data).map((res) => {
      return res;
    });
  }
  updateCampaignID(data: any) {
    return this.apiService.post('en/UpdateCampaignId', data).map((res) => {
      return res;
    });
  }

  validateSaasCampaign(data: any) {
    return this.apiService.post('en/ValidateCampaigndetails', data).map((res) => {
      return res;
    });
  }

  uploadFile(data) {
    const path = 'en/uploadfile';
    return this.apiService.post(path, data).map((res) => {

      return res;
    });
  }

  deleteCampaign(id: number) {
    const path = `${this.baseUrl}/${id}`;
    return this.apiService.delete(path).map((res) => {
      return res;
    });
  }

  getLanguages() {
    const path = 'en/languages';
    return this.apiService.get(path);
  }

  changeSortOrderOfCampaign(data) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (
      webshop !== null &&
      webshop !== undefined &&
      webshop !== '' &&
      webshop !== 'undefined'
    ) {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
    const customerID = this.webshopDetail.CustomerID;
    return this.apiService
      .put('en/Campaigns/changeSortOrder', { ...data, customerID })
      .map((res) => {
        return res;
      });
  }
}
