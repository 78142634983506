import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'rewardkart-admin-internal-note',
  templateUrl: './update-suborder-cost.component.html',
  styleUrls: ['./update-suborder-cost.component.scss']
})
export class UpdateSubOrder implements OnInit {

  @Input() header = '';
  @Input() suborderno = '';
  @Input() subtranscost = '';
  @Input() newsubtranscost = '';
  @Input() cancelText = 'Cancel';
  modalAction: Subject<any> = new Subject<any>();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  modalCanceled() {
    this.modalRef.hide();
  }

  modalConfirm() {
    this.modalAction.next({
      newsubtranscost: this.newsubtranscost,
      suborderno: this.suborderno
    });
  }

}
