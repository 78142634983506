import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MDBModalRef, ToastService, IMyOptions } from 'ng-uikit-pro-standard';
import { OrderHttpService } from '../../state/services/order.http.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

interface ModalActionType {
  data: any;
  action: string;
  type: string;
}

@Component({
  selector: 'rewardkart-admin-order-item-modal',
  templateUrl: './order-item-modal.component.html',
  styleUrls: ['./order-item-modal.component.scss']
})
export class OrderItemModalComponent implements OnInit {
  type = '';
  method: any;
  modalAction: Subject<ModalActionType> = new Subject<ModalActionType>();
  deliveryNoteItems: any[] = [];
  deliveryNotes: any[] = [];
  proformaInvoices: any[] = [];
  captureAmountItems: any = {};
  subOrderCaptureAmountItems: any = {};
  CurrencyCode: any;
  notificationItems: any[] = [];
  showProductDetails = false;
  deliveryNoteOrderItemIds = [];
  selectedDeliveryNoteOrderItems = [];
  deliveryNoteOrderItemQuantities = [];
  header: any;
  dispatch: any = [];
  dispatchClone: any = [];
  checkedDispatch: any = [];
  dispatchMail: Boolean = true;
  createinvoice: any = 'createinvoice';
  dispatchData: any = [];
  invoiceRecipientEmail: any;
  generalEmail: any;
  orderId = '';
  shippingCompany: any;
  trackingNumber: any;
  isLoading: Boolean = false;
  dispatchDetailStatus: Boolean;
  date = new Date();
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true
  };
  datePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: 'dd/mm/yyyy',
  };

  myChooseOption: IMyOptions = {
    closeAfterSelect: true,
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  }
  customerDeatil: any;
  applyAllBtnEnabled: boolean = true;
  applyAllTrackingChecked: boolean = true;
  applyDispatchAllBtnEnabled: boolean = true;
  applyDispatchAllChecked: boolean = true;
  changedCompanyName: any;
  changedTrackingNo: any;
  notificationMsg: any;
  subOrderCaptureAmntItems: any = [];
  captureAmonutSelected: boolean;
  applyAllCaptureAntEnabled: boolean;
  captureTotalAmnt: number;
  changedDispatchDate: any;
  currentDeliveryDate: any;
  getEmailCheckDeliveryDate = false;
  applyAllDispatchDateChecked: boolean = true;
  applyDispatchDateAllChecked: boolean = true;
  constructor(public modalRef: MDBModalRef, private router: Router, private orderHttpService: OrderHttpService, public toast: ToastService) { }

  ngOnInit() {
    this.captureTotalAmnt = 0;
    if (this.subOrderCaptureAmountItems.length > 0) {
      for (var item of this.subOrderCaptureAmountItems) {
        this.captureTotalAmnt += +item.SuborderAmountWithTransportationCostAndVAT;
      }
    }

    if (this.captureTotalAmnt > 0) {
      this.applyAllCaptureAntEnabled = true;
    }
    else {
      this.applyAllCaptureAntEnabled = false;
    }

    if (sessionStorage.getItem('customerDetails')) {
      this.customerDeatil = JSON.parse(sessionStorage.getItem('customerDetails'))
    }
    if (this.dispatchDetailStatus === undefined) {
      this.dispatchDetailStatus = false;
    }
    const orderCustomer = JSON.parse(sessionStorage.getItem('OrderCustomerData'));
    if (orderCustomer !== null && orderCustomer !== undefined && orderCustomer !== '') {
      this.createinvoice = orderCustomer.DefaultInvoiceType === 1 ? 'createinvoice' : 'createshipmentinvoice';
    }

    if (this.method === 'dispatchOrder') {
      this.dispatchData = this.dispatch.Suborders;
    } else {
      this.dispatchData = [{ OrderItems: this.dispatch.OrderItems }];
    }
    this.dispatchData.forEach(suborder => {
      if (suborder.OrderItems) {
        suborder.OrderItems.forEach(element => {
          this.currentDeliveryDate = element.DeliveryDate
          element.ShippingCompany = element.ShippingCompany ? element.ShippingCompany : '';
          element.TrackingNumber = element.TrackingNumber ? element.TrackingNumber : '';
          const getDispatchDate = element.DispatchDate ? element.DispatchDate : new Date();
          let newDate = moment(getDispatchDate, 'DD/MM/YYYY').format('DD/MM/YYYY');
          element.DispatchDate = newDate;
        });
      }
    });
  }


  modalConfirmed(data) {
    switch (this.type) {
      case 'newDeliveryNote':
        this.modalAction.next({ data: this.selectedDeliveryNoteOrderItems, action: 'update', type: this.type });
        break;
    }
  }

  onClick(eventHandler, orderItemID?) {
    let isExists = false;
    this.selectedDeliveryNoteOrderItems = [];
    if (eventHandler) {
      this.selectedDeliveryNoteOrderItems.map(item => {
        if (item.OrderItemID == orderItemID) {
          item.Quantity = this.deliveryNoteOrderItemQuantities[orderItemID];
          isExists = true;
        }
      });
      if (!isExists) {
        this.selectedDeliveryNoteOrderItems.push({
          OrderItemID: orderItemID,
          Quantity: this.deliveryNoteOrderItemQuantities[orderItemID]
        });
      }
    } else {
      this.selectedDeliveryNoteOrderItems.map((item, index) => {
        if (item.OrderItemID === orderItemID) {
          this.selectedDeliveryNoteOrderItems.splice(index, 1);
        }
      });
    }
  }

  modalCanceled() {
    this.modalRef.hide();

    // this.ngOnInit()

  }

  downloadInvoice(invoiceId, OrderWise) {
    // const orderWiseFlag = this.type === 'invoices' ? undefined : true;
    this.orderHttpService.downloadInvoice(invoiceId, OrderWise);
    this.toast.success('Please wait for download to start');
  }

  deleteInvoice(invoiceId) {
    const confirmation = confirm(`Are you sure you want to delete this invoice ${invoiceId}  ? `);
    if (confirmation) {
      this.orderHttpService.deleteInvoice(invoiceId).subscribe((response: any) => {
        this.toast.success(response);
        if (this.type === 'invoices') {
          this.orderHttpService.getInvoices(this.orderId)
            .subscribe((proformaInvoicesData: any) => {
              this.proformaInvoices = proformaInvoicesData;
              if (proformaInvoicesData) {
                this.orderHttpService.getOrderOverView(this.orderId, this.customerDeatil.CustomerID).subscribe(res => {
                  if (res) {
                    this.orderHttpService.orderCustomer(this.customerDeatil.CustomerID).subscribe(res => {
                    })
                  }
                });
              }
            }, error => {
              this.toast.error(error);
            });
        } else {
          this.orderHttpService.listProformaInvoices(this.orderId)
            .subscribe((proformaInvoicesData: any) => {
              this.proformaInvoices = proformaInvoicesData;
            }, error => {
              this.toast.error(error);
            });
        }
      });
    }
  }

  checkEmailNotificationDate(checkValue) {
    this.getEmailCheckDeliveryDate = checkValue.checked
  }
  // delivery date change for sub-order for ISP
  deliveryDateChange() {
    const formatDate = new Date(this.currentDeliveryDate)
    const dateISO = formatDate.getFullYear() + '-' + (formatDate.getMonth() + 1) + '-' + formatDate.getDate();
    this.modalAction.next({ data: { changedDeliveryDate: dateISO, emailCheck: this.getEmailCheckDeliveryDate }, action: 'deliveryDate', type: this.type })
  }

  captureAmount(data) {
    this.modalAction.next({ data: this.captureAmountItems, action: 'captureAmount', type: this.type });
  }

  sendNotifications(data) {
    this.modalAction.next({ data: this.notificationMsg, action: 'newNotification', type: this.type });
  }

  captureAmountCheck(event, suborder) {
    if (event.checked == true) {
      this.subOrderCaptureAmntItems.push({ SubOrderID: suborder.SuborderID, OrderID: suborder.OrderID })
    }
    else {
      const index = this.subOrderCaptureAmntItems.findIndex((ch) => ch.SubOrderID === suborder.SuborderID);
      this.subOrderCaptureAmntItems.splice(index, 1);
    }
  }

  captureAmountSubOrderCheck(event) {
    if (event.checked == true) {
      for (var item of this.subOrderCaptureAmountItems) {
        if (item.SuborderAmountWithTransportationCostAndVAT !== 0) {
          this.subOrderCaptureAmntItems.push({ SubOrderID: item.SuborderID, OrderID: item.OrderID })
          this.captureAmonutSelected = true;
        }
      }
    }
    else {
      this.subOrderCaptureAmntItems = [];
      this.captureAmonutSelected = false;
    }
  }

  suborderCaptureAmount() {
    this.modalAction.next({ data: this.subOrderCaptureAmntItems, action: 'suborderCaptureAmount', type: this.type });
  }

  ngOnDestroy() {
    this.modalAction.unsubscribe();
  }

  orderItemQuantityChange(orderItemID) {
    this.selectedDeliveryNoteOrderItems.map(item => {
      if (item.OrderItemID == orderItemID) {
        item.Quantity = this.deliveryNoteOrderItemQuantities[orderItemID];
      }
    });
  }

  // checkDispatchItemsForOrder(){
  //   this.dispatch.Suborders.forEach(subOrder => {
  //     subOrder.OrderItems.forEach(item => {
  //       status
  //     });
  //   });
  // }


  dispatchCheck(event, data) {

    if (event.checked) {
      let selectedArray;
      // let actualDate = data.DispatchDate ? data.DispatchDate : moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
      // let getDate = this.dateToMonth(actualDate)
      // getDate = new Date(getDate).toISOString();
      // let d = new Date(getDate);
      // let dispatchDate: any = d.setDate(d.getDate() + 1);
      // dispatchDate = new Date(dispatchDate).toISOString();
      if (this.method === 'dispatchOrder') {
        selectedArray = {
          SuborderID: data.SuborderID,
          OrderItemID: data.OrderItemID,
          OrderItems: [{ OrderItemID: data.OrderItemID, ShippingCompany: data.ShippingCompany, TrackingNumber: data.TrackingNumber, DispatchDate: data.DispatchDate }]
        };
      } else {
        selectedArray = {
          OrderItemID: data.OrderItemID, ShippingCompany: data.ShippingCompany,
          TrackingNumber: data.TrackingNumber, DispatchDate: data.DispatchDate
        };

      }
      this.checkedDispatch.push(selectedArray);
      // console.log('ifcheckedDispatch', this.checkedDispatch)
    } else {
      const getIndex = this.checkedDispatch.findIndex(get => get.OrderItemID === data.OrderItemID);
      this.checkedDispatch.splice(getIndex, 1);
      // let suborder = this.checkedDispatch.filter(x => x.OrderItems);
      // console.log('suborder', suborder)

      // this.checkedDispatch.forEach(x =>{
      //   if(!x.OrderItems){
      //     const getIndex = this.checkedDispatch.findIndex(get => get.SuborderID === data.SuborderID);
      //     this.checkedDispatch.splice(getIndex, 1);
      //   } else{

      //   }
      // })

    }
    if (this.checkedDispatch.length >= 2 && this.applyAllTrackingChecked == false) {
      var i = 0;
      if (this.method === 'dispatchOrder') {
        for (var suborder of this.checkedDispatch) {
          for (var checkedItems of suborder.OrderItems) {
            if (i == 0) {
              var shippedCompanyName = checkedItems.ShippingCompany;
              var trackingNo = checkedItems.TrackingNumber;
              var dispatchDate = checkedItems.DispatchDate;
              i = i + 1;
            }
            if (i != 0) {
              if (checkedItems.ShippingCompany == shippedCompanyName && checkedItems.TrackingNumber == trackingNo) {
                this.applyAllTrackingChecked = true;
              }
              else {
                this.applyAllTrackingChecked = false;
                break;
              }
            }
          }
        }
      }
      else {
        for (var items of this.checkedDispatch) {
          if (i == 0) {
            var shippedCompanyName = items.ShippingCompany;
            var trackingNo = items.TrackingNumber;
            i = i + 1;
          }
          if (i != 0) {
            if (items.ShippingCompany == shippedCompanyName && items.TrackingNumber == trackingNo) {
              this.applyAllTrackingChecked = true;
            }
            else {
              this.applyAllTrackingChecked = false;
              break;
            }
          }
        }
      }


    }


    if (event.checked && this.applyAllTrackingChecked == true) {
      if (this.method === 'dispatchOrder') {
        this.checkedDispatch.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            // if (checkedItems.ShippingCompany != "" && checkedItems.ShippingCompany !== undefined || checkedItems.TrackingNumber != "" && checkedItems.TrackingNumber !== undefined) {
            //   ShippingCompany = checkedItems.ShippingCompany;
            //   TrackingNumber = checkedItems.TrackingNumber;
            // }
            if (this.changedCompanyName != undefined) {
              checkedItems.ShippingCompany = this.changedCompanyName;
              this.dispatchData.forEach(suborder => {
                const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
                if (getIndex != -1) {
                  suborder.OrderItems[getIndex].ShippingCompany = this.changedCompanyName;
                }
              })
            }
            if (this.changedTrackingNo != undefined) {
              checkedItems.TrackingNumber = this.changedTrackingNo;
              this.dispatchData.forEach(suborder => {
                const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
                if (getIndex != -1) {
                  suborder.OrderItems[getIndex].TrackingNumber = this.changedTrackingNo;
                }
              })
            }
          })
        })
      }
      else {
        for (var checkedItems of this.checkedDispatch) {
          // if (checkedItems.ShippingCompany != "" && checkedItems.ShippingCompany !== undefined || checkedItems.TrackingNumber != "" && checkedItems.TrackingNumber !== undefined) {
          //   var ShippingCompany = checkedItems.ShippingCompany;
          //   var TrackingNumber = checkedItems.TrackingNumber;
          // }
          if (this.changedCompanyName != undefined) {
            checkedItems.ShippingCompany = this.changedCompanyName;
            const getIndex = this.dispatchData[0].OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
            if (getIndex != -1) {
              // if (this.dispatchData[0].OrderItems[getIndex].ShippingCompany == "" || this.dispatchData[0].OrderItems[getIndex].TrackingNumber == "") {
              this.dispatchData[0].OrderItems[getIndex].ShippingCompany = this.changedCompanyName;
              // }
            }
          }
          if (this.changedTrackingNo != undefined) {
            checkedItems.TrackingNumber = this.changedTrackingNo;
            const getIndex = this.dispatchData[0].OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
            if (getIndex != -1) {
              // if (this.dispatchData[0].OrderItems[getIndex].ShippingCompany == "" || this.dispatchData[0].OrderItems[getIndex].TrackingNumber == "") {
              this.dispatchData[0].OrderItems[getIndex].TrackingNumber = this.changedTrackingNo;
              // }
            }
          }
        }
      }
    }

    if (event.checked && this.applyAllDispatchDateChecked == true) {
      if (this.method === 'dispatchOrder') {
        this.checkedDispatch.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            // if (checkedItems.ShippingCompany != "" && checkedItems.ShippingCompany !== undefined || checkedItems.TrackingNumber != "" && checkedItems.TrackingNumber !== undefined) {
            //   ShippingCompany = checkedItems.ShippingCompany;
            //   TrackingNumber = checkedItems.TrackingNumber;
            // }
            if (this.changedDispatchDate != undefined) {
              checkedItems.DispatchDate = this.changedDispatchDate;
              this.dispatchData.forEach(suborder => {
                const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
                if (getIndex != -1) {
                  suborder.OrderItems[getIndex].DispatchDate = this.changedDispatchDate;
                }
              })
            }
          })
        })
      }
      else {
        for (var checkedItems of this.checkedDispatch) {
          // if (checkedItems.ShippingCompany != "" && checkedItems.ShippingCompany !== undefined || checkedItems.TrackingNumber != "" && checkedItems.TrackingNumber !== undefined) {
          //   var ShippingCompany = checkedItems.ShippingCompany;
          //   var TrackingNumber = checkedItems.TrackingNumber;
          // }
          if (this.changedDispatchDate != undefined) {
            checkedItems.DispatchDate = this.changedDispatchDate;
            const getIndex = this.dispatchData[0].OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
            if (getIndex != -1) {
              // if (this.dispatchData[0].OrderItems[getIndex].ShippingCompany == "" || this.dispatchData[0].OrderItems[getIndex].DispatchDate == "") {
              this.dispatchData[0].OrderItems[getIndex].DispatchDate = this.changedDispatchDate;
              // }
            }
          }
        }
      }
    }
  }

  applyAllTracking(event) {

    if (event.checked == true) {
      this.applyAllTrackingChecked = true;
      if (this.method === 'dispatchOrder') {
        var ShippingCompany = "";
        var TrackingNumber = "";
        this.checkedDispatch.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            if (this.changedCompanyName != undefined) {
              checkedItems.ShippingCompany = this.changedCompanyName;
              this.dispatchData.forEach(suborder => {
                const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
                if (getIndex != -1) {
                  suborder.OrderItems[getIndex].ShippingCompany = this.changedCompanyName;
                }
              })
            }
            if (this.changedTrackingNo != undefined) {
              checkedItems.ShippingCompany = this.changedTrackingNo;
              this.dispatchData.forEach(suborder => {
                const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
                if (getIndex != -1) {
                  suborder.OrderItems[getIndex].TrackingNumber = this.changedTrackingNo;
                }
              })
            }
          })
        })
      }
      else {
        for (var checkedItems of this.checkedDispatch) {
          if (this.changedCompanyName != undefined) {
            checkedItems.ShippingCompany = this.changedCompanyName;
            const getIndex = this.dispatchData[0].OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
            if (getIndex != -1) {
              this.dispatchData[0].OrderItems[getIndex].ShippingCompany = this.changedCompanyName;
            }
          }
          if (this.changedTrackingNo != undefined) {
            checkedItems.TrackingNumber = this.changedTrackingNo;
            const getIndex = this.dispatchData[0].OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
            if (getIndex != -1) {
              this.dispatchData[0].OrderItems[getIndex].TrackingNumber = this.changedTrackingNo;
            }
          }
        }
      }
    }
    else {
      this.applyAllTrackingChecked = false;
    }
  }

  applyDispatchAllTracking(event) {

    if (event.checked == true) {
      this.applyDispatchAllChecked = true;
      if (this.method === 'dispatchOrder') {
        this.dispatchData.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            if (this.changedCompanyName != undefined) {
              checkedItems.ShippingCompany = this.changedCompanyName;
            }
            if (this.changedTrackingNo != undefined) {
              checkedItems.TrackingNumber = this.changedTrackingNo;
            }
          })
        })
      }
      else {
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          // if (dispatchItems.ShippingCompany != "" && dispatchItems.ShippingCompany !== undefined || dispatchItems.TrackingNumber != "" && dispatchItems.TrackingNumber !== undefined) {
          //   var ShippingCompany = dispatchItems.ShippingCompany;
          //   var TrackingNumber = dispatchItems.TrackingNumber;
          // }
          if (this.changedCompanyName != undefined) {
            dispatchItems.ShippingCompany = this.changedCompanyName;
          }
          if (this.changedTrackingNo != undefined) {
            dispatchItems.TrackingNumber = this.changedTrackingNo;
          }
        }
      }
    }
    else {
      this.applyDispatchAllChecked = false;
    }
  }

  applyAllDispatchDate(event) {
    if (event.checked == true) {
      this.applyAllDispatchDateChecked = true;
      if (this.method === 'dispatchOrder') {
        var ShippingCompany = "";
        var TrackingNumber = "";
        this.checkedDispatch.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            if (this.changedDispatchDate != undefined) {
              checkedItems.DispatchDate = this.changedDispatchDate;
              this.dispatchData.forEach(suborder => {
                const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
                if (getIndex != -1) {
                  suborder.OrderItems[getIndex].DispatchDate = this.changedDispatchDate;
                }
              })
            }
          })
        })
      }
      else {
        for (var checkedItems of this.checkedDispatch) {
          if (this.changedDispatchDate != undefined) {
            checkedItems.DispatchDate = this.changedDispatchDate;
            const getIndex = this.dispatchData[0].OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
            if (getIndex != -1) {
              this.dispatchData[0].OrderItems[getIndex].DispatchDate = this.changedDispatchDate;
            }
          }
        }
      }
    }
    else {
      this.applyAllDispatchDateChecked = false;
    }
  }

  applyDispatchDateAllTracking(event) {
    if (event.checked == true) {
      this.applyDispatchDateAllChecked = true;
      if (this.method === 'dispatchOrder') {
        this.dispatchData.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            if (this.changedDispatchDate != undefined) {
              checkedItems.DispatchDate = this.changedDispatchDate;
            }
          })
        })
      }
      else {
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          // if (dispatchItems.ShippingCompany != "" && dispatchItems.ShippingCompany !== undefined || dispatchItems.TrackingNumber != "" && dispatchItems.TrackingNumber !== undefined) {
          //   var ShippingCompany = dispatchItems.ShippingCompany;
          //   var TrackingNumber = dispatchItems.TrackingNumber;
          // }
          if (this.changedDispatchDate != undefined) {
            dispatchItems.DispatchDate = this.changedDispatchDate;
          }
        }
      }
    }
    else {
      this.applyDispatchDateAllChecked = false;
    }
  }


  dispatchOrder(type) {
    if (type === 'dispatchAll') {
      let allData = [];
      this.dispatchData.forEach(suborder => {
        const itemID = [];
        suborder.OrderItems.forEach(item => {
          // if (item.DispatchDate) {
          //   var newValidDate = item.DispatchDate;
          //   let isValidDate = (moment(item.DispatchDate, 'DD/MM/YYYY', true).isValid());
          //   if (!isValidDate) {
          //     newValidDate = moment(item.DispatchDate).subtract(1, "days").format('DD/MM/YYYY')
          //   }
          //   let getDate = this.dateToMonth(newValidDate)
          //   getDate = new Date(getDate).toISOString();
          //   let d = new Date(getDate);
          //   let dispatchDate: any = d.setDate(d.getDate() + 1);
          //   item.DispatchDate = new Date(dispatchDate).toISOString();
          // }
          let actualDate = item.DispatchDate ? item.DispatchDate : moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
          let getTodayDate = (moment(actualDate, 'DD/MM/YYYY', true).isValid());
          if (getTodayDate) {
            var getActualDate = this.dateToMonth(actualDate)
          }
          else {
            getActualDate = actualDate
          }
          itemID.push({ OrderItemID: item.OrderItemID, ShippingCompany: item.ShippingCompany, TrackingNumber: item.TrackingNumber, DispatchDate: getActualDate });
        });
        if (this.method === 'dispatchOrder') {
          allData.push({
            SuborderID: suborder.SuborderID,
            OrderItems: itemID
          });
        } else {
          allData = itemID;
        }

      });
      this.checkedDispatch = allData;
    } else {
      if (this.checkedDispatch.length === 0) {
        this.toast.error('Please select atleast one order');
        return false;
      }
      else {
        this.checkedDispatch.forEach(suborder => {
          if (suborder.hasOwnProperty('SuborderID')) {
            suborder.OrderItems.forEach(order => {
              var newDate = order.DispatchDate;
              let isValidDate = (moment(order.DispatchDate, 'DD/MM/YYYY', true).isValid());
              if (!isValidDate) {
                newDate = moment(order.DispatchDate).subtract(1, "days").format('DD/MM/YYYY')
              }
              let getDate = this.dateToMonth(newDate)
              getDate = new Date(getDate).toISOString();
              let d = new Date(getDate);
              let dispatchDate: any = d.setDate(d.getDate() + 1);
              order.DispatchDate = new Date(dispatchDate).toISOString();
            })
          }
          else {
            var newDate = suborder.DispatchDate;
            let isValidDate = (moment(suborder.DispatchDate, 'DD/MM/YYYY', true).isValid());
            if (!isValidDate) {
              newDate = moment(suborder.DispatchDate).subtract(1, "days").format('DD/MM/YYYY')
            }
            let getDate = this.dateToMonth(newDate)
            getDate = new Date(getDate).toISOString();
            let d = new Date(getDate);
            let dispatchDate: any = d.setDate(d.getDate() + 1);
            suborder.DispatchDate = new Date(dispatchDate).toISOString();
          }
        })

      }
    }
    this.isLoading = true;
    let request = {
      orderID: this.method === 'dispatchOrder' ? this.dispatch.OrderID : this.dispatch.SuborderID,
      sendMail: this.dispatchMail,
      isShippingInvoice: this.createinvoice === 'createshipmentinvoice' ? true : false,
      urlType: this.method === 'dispatchOrder' ? 'dispatchOrder' : 'dispatchItems',
    };
    let shippingInvoice = request.isShippingInvoice ? '&isShipmentInvoice=' + request.isShippingInvoice : '';
    const path = `en/${request.urlType}/${request.orderID}/enter?sendEmail=${request.sendMail}${shippingInvoice}`;
    if (!this.dispatchDetailStatus) {
      this.orderHttpService.dispatchOrder(request, this.checkedDispatch).subscribe(res => {
        // if (res) {
        this.toast.success('Dispatched successfully');
        this.checkedDispatch = [];
        this.isLoading = false;
        this.modalAction.next({ data: this.dispatch.OrderID, action: '', type: '' });
        this.modalCanceled();
        // }
      },
        (error) => {
          this.isLoading = false;
          this.toast.error(error.error.Message);
        });


    } else {
      request.urlType = 'suborderDispatchDetails';
      this.orderHttpService.dispatchDetailOrder(request, this.checkedDispatch).subscribe(res => {
        this.isLoading = false;
        //  this.modalAction.next({ data: this.dispatch.OrderID, action: '', type: '' });
        this.modalCanceled();
      },
        (error) => {
          this.isLoading = false;
          this.toast.error(error.error.Message);
        });

    }
    // console.log(type, this.checkedDispatch)
  }


  // performa invoice
  sendPerformaInvoice(invoiceId) {
    const orderWiseFlag = this.type === 'invoices' ? undefined : true;
    this.orderHttpService.sendInvoice(invoiceId).subscribe((response: any) => {
      this.toast.info(response);
    });
  }

  changeValue(event, type, mainindex, subindex, data?, dispatchType?) {

    let getDataIndex: any;
    let common: boolean;
    // console.log(' this.checkedDispatch', this.checkedDispatch);
    this.checkedDispatch.forEach((x, index) => {
      if (x.OrderItems)
        x.OrderItems.forEach(o => {
          if (o.OrderItemID == data.OrderItemID) {
            getDataIndex = index;
          }
        })
      // getDataIndex = x.OrderItems.findIndex(res => res.OrderItemID === data.OrderItemID);
    })
    if (this.checkedDispatch.length != 0) {
      if (!this.checkedDispatch[0].OrderItems) {
        common = true
        getDataIndex = this.checkedDispatch.findIndex(res => res.OrderItemID === data.OrderItemID);
      }
    }
    // let getDataIndex = this.checkedDispatch.findIndex(res => res.OrderItemID === data.OrderItemID);
    if (type === 'hyperkart') {
      this.dispatchData[mainindex]['OrderItems'][subindex]['ShippingCompany'] = event.target.value;
      // if (this.checkedDispatch[subindex]) {
      //   this.checkedDispatch[subindex]['ShippingCompany'] = event.target.value;
      // }
      if (common && this.checkedDispatch[getDataIndex]) {
        this.checkedDispatch[getDataIndex]['ShippingCompany'] = event.target.value;

      } else if (!common && this.checkedDispatch.length != 0) {
        this.checkedDispatch[getDataIndex].OrderItems[0]['ShippingCompany'] = event.target.value;

      }
      this.changedCompanyName = event.target.value;
    }
    else if (type === 'trackNumber') {
      this.dispatchData[mainindex]['OrderItems'][subindex]['TrackingNumber'] = event.target.value;
      if (common && this.checkedDispatch[getDataIndex]) {
        this.checkedDispatch[getDataIndex]['TrackingNumber'] = event.target.value;
      } else if (!common && this.checkedDispatch.length != 0) {
        this.checkedDispatch[getDataIndex].OrderItems[0]['TrackingNumber'] = event.target.value;

      }
      this.changedTrackingNo = event.target.value;
    }
    else if (type === 'dispatchDate') {
      let actualDate = event.actualDateFormatted ? event.actualDateFormatted : moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
      let getDate = this.dateToMonth(actualDate)
      getDate = new Date(getDate).toISOString();
      let d = new Date(getDate);
      let dispatchDate: any = d.setDate(d.getDate() + 1);
      dispatchDate = new Date(dispatchDate).toISOString();
      this.dispatchData[mainindex]['OrderItems'][subindex]['DispatchDate'] = dispatchDate;
      // if (this.checkedDispatch[getDataIndex]) {
      if (this.checkedDispatch.length > 0)
        if (this.checkedDispatch[getDataIndex].OrderItems) {
          // this.checkedDispatch[getDataIndex]['DispatchDate'] = dispatchDate;
          let getSubDataIndex = this.checkedDispatch[getDataIndex].OrderItems.findIndex(res => res.OrderItemID === data.OrderItemID);
          // console.log('getSubDataIndex', getSubDataIndex);
          this.checkedDispatch[getDataIndex]['OrderItems'][getSubDataIndex]['DispatchDate'] = dispatchDate;
          // console.log('this.checkedDispatch', this.checkedDispatch);
        } else {
          this.checkedDispatch[getDataIndex]['DispatchDate'] = dispatchDate;
        }
      // console.log('this.checkedDispatch', this.checkedDispatch);
      this.changedDispatchDate = event.actualDateFormatted;
    }
    // Enable dispatch apply all button 

    // if (this.checkedDispatch.length >= 2) {
    //   if (this.method === 'dispatchOrder') {
    //     for (var suborder of this.checkedDispatch) {
    //       for (var checkedItems of suborder.OrderItems) {
    //         if (checkedItems.ShippingCompany != "" || checkedItems.TrackingNumber != "") {
    //           this.applyAllBtnEnabled = false;
    //           break;
    //         }
    //         else {
    //           this.applyAllBtnEnabled = true;
    //         }
    //       }
    //       if (!this.applyAllBtnEnabled) {
    //         break;
    //       }
    //     }
    //   }
    //   else {
    //     for (var dispatchItems of this.dispatchData[0].OrderItems) {
    //       if (dispatchItems.ShippingCompany != "" && dispatchItems.ShippingCompany != undefined || dispatchItems.TrackingNumber != "" && dispatchItems.TrackingNumber != undefined) {
    //         this.applyAllBtnEnabled = false;
    //         break;
    //       }
    //       else {
    //         this.applyAllBtnEnabled = true;
    //       }
    //     }
    //   }
    // } else {
    //   this.applyAllBtnEnabled = true;
    // }

    // Enable dispatch all apply all button 
    // if (dispatchType == "dispatchAll") {
    //   for (var dispatchItems of this.dispatchData[0].OrderItems) {
    //     if (dispatchItems.ShippingCompany != "" && dispatchItems.ShippingCompany != undefined || dispatchItems.TrackingNumber != "" && dispatchItems.TrackingNumber != undefined) {
    //       this.applyDispatchAllBtnEnabled = false;
    //       break;
    //     }
    //     else {
    //       this.applyDispatchAllBtnEnabled = true;
    //     }
    //   }
    // }

    //Mutiple company Name for order items
    if (type === 'hyperkart' && this.applyAllTrackingChecked && dispatchType == "dispatch") {
      if (this.method === 'dispatchOrder') {
        // var checkedDispatchItems = [];
        // this.checkedDispatch.forEach(suborder => {
        //   suborder.OrderItems.forEach(checkedItems => {
        //     checkedDispatchItems.push(checkedItems);
        //   })
        // })
        // const dataFound = checkedDispatchItems.some(res => res.OrderItemID === data.OrderItemID);
        // if (dataFound) {
        if (this.checkedDispatch.length > 0) {
          for (var suborder of this.checkedDispatch) {
            for (var checkedItems of suborder.OrderItems) {
              checkedItems.ShippingCompany = event.target.value;
            }
          }
        }
        for (var suborder of this.dispatchData) {
          for (var dispatchItems of suborder.OrderItems) {
            dispatchItems.ShippingCompany = event.target.value;
          }
        }
        // this.dispatchData.forEach(suborder => {
        //   const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
        //   if (getIndex != -1) {
        //     suborder.OrderItems[getIndex].ShippingCompany = event.target.value;
        //   }
        // })
        // }
      }
      else {
        if (this.checkedDispatch.length > 0) {
          for (var checkedItems of this.checkedDispatch) {
            checkedItems.ShippingCompany = event.target.value;
          }
        }
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          dispatchItems.ShippingCompany = event.target.value;
        }
      }
    }
    if (type === 'hyperkart' && dispatchType == "dispatchAll" && this.applyDispatchAllChecked) {
      if (this.method === 'dispatchOrder') {
        this.dispatchData.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            checkedItems.ShippingCompany = event.target.value;
          })
        })
      }
      else {
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          dispatchItems.ShippingCompany = event.target.value;;
        }
      }
    }
    // ***/

    //Mutiple Tracking Number for order items
    if (type === 'trackNumber' && this.applyAllTrackingChecked && dispatchType == "dispatch") {
      if (this.method === 'dispatchOrder') {
        // var checkedDispatchItems = [];
        // this.checkedDispatch.forEach(suborder => {
        //   suborder.OrderItems.forEach(checkedItems => {
        //     checkedDispatchItems.push(checkedItems);
        //   })
        // })
        // const dataFound = checkedDispatchItems.some(res => res.OrderItemID === data.OrderItemID);
        // if (dataFound) {
        //   for (var checkedItems of checkedDispatchItems) {
        //     checkedItems.TrackingNumber = event.target.value;
        //     this.dispatchData.forEach(suborder => {
        //       const getIndex = suborder.OrderItems.findIndex(get => get.OrderItemID === checkedItems.OrderItemID);
        //       if (getIndex != -1) {
        //         suborder.OrderItems[getIndex].TrackingNumber = event.target.value;
        //       }
        //     })
        //   }
        // }
        if (this.checkedDispatch.length > 0) {
          for (var suborder of this.checkedDispatch) {
            for (var checkedItems of suborder.OrderItems) {
              checkedItems.TrackingNumber = event.target.value;
            }
          }
        }

        for (var suborder of this.dispatchData) {
          for (var dispatchItems of suborder.OrderItems) {
            dispatchItems.TrackingNumber = event.target.value;
          }
        }
      }
      else {
        if (this.checkedDispatch.length > 0) {
          for (var checkedItems of this.checkedDispatch) {
            checkedItems.TrackingNumber = event.target.value;
          }
        }
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          dispatchItems.TrackingNumber = event.target.value;;
        }
      }
    }
    if (type === 'trackNumber' && dispatchType == "dispatchAll" && this.applyDispatchAllChecked) {
      if (this.method === 'dispatchOrder') {
        this.dispatchData.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            checkedItems.TrackingNumber = event.target.value;
          })
        })
      }
      else {
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          dispatchItems.TrackingNumber = event.target.value;
        }
      }
    }
    // ***/
    //Mutiple Dispatch date for order items
    if (type === 'dispatchDate' && this.applyAllDispatchDateChecked && dispatchType == "dispatchDate") {
      if (this.method === 'dispatchOrder') {
        if (this.checkedDispatch.length > 0) {
          for (var suborder of this.checkedDispatch) {
            for (var checkedItems of suborder.OrderItems) {
              checkedItems.DispatchDate = event.actualDateFormatted;
            }
          }
        }

        for (var suborder of this.dispatchData) {
          for (var dispatchItems of suborder.OrderItems) {
            dispatchItems.DispatchDate = event.actualDateFormatted;
          }
        }
      }
      else {
        if (this.checkedDispatch.length > 0) {
          for (var checkedItems of this.checkedDispatch) {
            checkedItems.DispatchDate = event.actualDateFormatted;
          }
        }
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          dispatchItems.DispatchDate = event.actualDateFormatted;;
        }
      }
    }
    if (type === 'dispatchDate' && dispatchType == "dispatchDateAll" && this.applyDispatchDateAllChecked) {
      if (this.method === 'dispatchOrder') {
        this.dispatchData.forEach(suborder => {
          suborder.OrderItems.forEach(checkedItems => {
            // let getDate = this.dateToMonth(event.actualDateFormatted)
            // getDate = new Date(getDate).toISOString();
            // let d = new Date(getDate);
            // let dispatchDate: any = d.setDate(d.getDate() + 1);
            // dispatchDate = new Date(dispatchDate).toISOString();
            checkedItems.DispatchDate = event.actualDateFormatted;
          })
        })
      }
      else {
        for (var dispatchItems of this.dispatchData[0].OrderItems) {
          // let getDate = this.dateToMonth(event.actualDateFormatted)
          // getDate = new Date(getDate).toISOString();
          // let d = new Date(getDate);
          // let dispatchDate: any = d.setDate(d.getDate() + 1);
          // dispatchDate = new Date(dispatchDate).toISOString();
          dispatchItems.DispatchDate = event.actualDateFormatted;
        }
      }
    }
  }

  dateToMonth(date: String) {
    if (date !== undefined && date !== null) {
      const val = date.split('/');
      return [val[1], val[0], val[2]].join('-')
    }
  }

  eventHandler(event) {
    if (event.keyCode === 32) {
      return false;
    }
  }

}