
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SignupActions } from '../actions/index';
import { State } from '../reducers/index';
import { SignupHttpService } from '../services/signup.http.service';

@Injectable()
export class SignupEffects {
    @Effect()
    validateUser$: Observable<Action> = this.actions$.pipe(
        ofType(SignupActions.VALIDATE_USER),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.signupHttpService.validateUser(payload.payload).map((data) => {
                return new SignupActions.LoadValidateUserSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new SignupActions.LoadValidateUserFail(error));
        })
    );

    @Effect()
    triggerMobileOTP$: Observable<Action> = this.actions$.pipe(
        ofType(SignupActions.GENERATE_MOBILE_OTP),
        withLatestFrom(this.store$),
        switchMap(([payload]: any) => {
            return this.signupHttpService.triggerMobileOTP(payload.payload).map((data) => {
                return new SignupActions.LoadGenerateMobileOTPSuccess(data);
            });
        }),
        catchError((error: any) => {
            return Observable.of(new SignupActions.LoadGenerateMobileOTPFail(error));
        })
    );

    constructor(private actions$: Actions,
        private store$: Store<State>,
        private signupHttpService: SignupHttpService) { }
}