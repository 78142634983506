import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-color-settings',
  templateUrl: './manage-color-settings.component.html',
  styleUrls: ['./manage-color-settings.component.scss']
})
export class ManageColorSettingsComponent implements OnInit {
  resetColorModal: boolean = false;
  productDetails: any = {};
  productColorForm: any = []
  constructor() { }

  ngOnInit() {
    sessionStorage.removeItem('productCustomerID');
    // this.productDetails.SupplierID = 1;
  }


  resetColor() {
    this.resetColorModal = true;
    setTimeout(() => {
      this.resetColorModal = false;
    }, 1000)
  }

  resetDropDown() { }

}
