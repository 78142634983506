import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ProductService } from '../../../state/services/product.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../../shared/common-services/api.service';
import { WebService } from '../../../../restful-service/web.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { debug } from 'util';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-manage-print-price',
  templateUrl: './manage-print-price.component.html',
  styleUrls: ['./manage-print-price.component.scss']
})
export class ManagePrintPriceComponent implements OnInit, OnChanges {
  @Input() productDetails: any;
  @Input() getCustomerProduct: any;
  @Input() loadApi: Boolean;
  @Input() passdata: Boolean;
  @Input('resetForm') resetForm: Boolean
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Output() getproductPrice = new EventEmitter<any>();
  @Input() selectedPopup: number;
  logoTypeList: any = [];
  printpriceForm: FormGroup;
  listpriceForm: FormGroup;
  handlingCost: any = [];
  logoAreaList: any = [];
  optionsSelect: Array<any>;
  logoPosition: any = [];
  loadApiFlag: Boolean = false
  languageOption: any = [];
  selectedLang: any = 'English';
  printPriceList: any;
  getlang: any;
  priceLogoType: any = [{ label: "Select All types", value: "" }];
  getSelectedPrice: any = '';
  loadDatalogoName: boolean = false;
  loadDatalogoposdesc: boolean = false;
  loadDatalogoareadesc: boolean = false;
  language: any = 'English';
  deleteData: any;
  logoPosiValues: any = [];
  logoPosi: any[];
  logoarea: any[];
  logoArea: any[];
  logoAreaValues: any[];
  isChecked: any;
  searchManagePrintprice: any;
  selfManagePrintprice: any = []
  ShowNoRecords: boolean;
  duplicateArray: any = [];
  isClone: Boolean = false;
  webshopDetail: any;
  selectedLanguageId: number = 1;

  constructor(public logoService: ProductHttpService, private productService: ProductService, public toast: ToastService, private formBuilder: FormBuilder, public translate: TranslateService, public apiService: ApiService, private webService: WebService) {
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }
    this.getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
  }




  ngOnInit() {
    // this.getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    console.log('this.getlang', this.getlang)
    // let langData = { label: 'English', value: 1 };
    // this.getCurrentLang(langData);
    // this.changeListLang(langData);
    this.isClone = history.state.isClone;
    this.printPriceList = (this.productDetails[this.selectedPopup]) ? (this.productDetails[this.selectedPopup].ProductPrintPrices) : [];

    this.loadPriceForm();
    this.loadPriceListForm();
    // this.getLogoType();
    this.getHandlingCost();
    // this.getLogoPosition();
    // this.getLogoArea();
    // this.getHandlingCost();
    this.optionsSelect = [
      { value: '1', label: 'Option 1' },
      { value: '2', label: 'Option 2' },
      { value: '3', label: 'Option 3' },
    ];
    // this.printpriceForm.valueChanges.subscribe(()=>{
    //   this.getproductPrice.emit(this.printpriceForm.value);
    // })
    // this.getproductPrice.emit(this.printpriceForm.value);

    if (this.listpriceForm) {
      this.listpriceForm.valueChanges.subscribe(() => {
        this.getValue('change');
      })
    }
    this.logoService.logoPositionlistArray.subscribe(
      data => {
        const selectLogoPos = [{ label: 'Select logo position', value: '' }];
        // if (data.length > 0) {
        //   this.logoPosi.push(data);
        // }
        this.logoPosi = selectLogoPos.concat(data);
        this.logoPosiValues = this.logoPosi.filter(x => x.action === undefined || x.action === "Insert");
      }
    )
    //logoArea
    this.logoService.logoArealistArray.subscribe(
      data => {
        const selectLogoArea = [{ label: 'Select logo area', value: '' }];
        this.logoArea = selectLogoArea.concat(data);
        this.logoAreaValues = this.logoArea.filter(x => x.action === undefined || x.action === "Insert");
      }
    )

  }


  ngOnChanges(changes: SimpleChanges) {
    // if(this.passdata){
    //   this.resetFilter();

    // }
    // else{
    //   this.resetFilter();
    // }
    if (this.productDetails[this.selectedPopup]) {
      this.printPriceList = (this.productDetails[this.selectedPopup].ProductPrintPrices) || [];
    }
    else {
      this.printPriceList = [];
    }


    this.loadPriceForm();
    this.loadPriceListForm();

    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true;
      this.getLogoType();
      this.getLogoPosition();
      this.getLogoArea();
      this.getHandlingCost();
    }



    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.getLogoType();
      this.resetFilter()
    }



  }

  checkShowPrintPriceFor() {
    if (this.printPriceList && this.printPriceList.length > 0) {
      let flag = this.printPriceList.filter(element => element.Action !== 'Delete');
      if (flag.length > 0) {
        return true
      }
    }
    return false
  }


  // loadProductData() {
  //   const data = {
  //     ProductID: this.productDetails.ProductID
  //   }
  //   this.webService.productEditdata(data).subscribe((editdata) => {
  //     if (editdata) {
  //       this.productDetails = editdata;
  //       // this.checkPublishedStatus()
  //       // this.loadBasicInfo = true;
  //       // this.showLoader = false;
  //     }
  //   })
  // }

  descriptionChange(value, controlname, childindex, parentindex) {

    this.listpriceForm.patchValue({
      controlname: value
    })
    this.printPriceList.forEach((element, index) => {
      const elementLength = element.Translations.length;
      if (index === parentindex) {
        for (let i = 0; i < elementLength; i++) {
          if (childindex === i) {

            element.Translations[i].PropertyValue = value;
            element.Translations[i].Action = 'Update';
          }
        }
      }
    });

    this.getValue('change');
  }


  searchManagePrintpricelist() {
    this.ShowNoRecords = true
    let getResult = [];
    this.printPriceList.filter((data) => {
      let value = (data.LogoTypeName).toLowerCase();
      let searchData = (this.searchManagePrintprice).toLowerCase();
      if (((value).includes(searchData))) {
        this.ShowNoRecords = false
        data.search = 'show';
        getResult.push(data)
      }
      else {
        data.search = 'hide';
        getResult.push(data)
      }
    })
    this.printPriceList = getResult;
  }

  resetFilter() {
    this.searchManagePrintprice = '';
    this.printPriceList.forEach(element => {
      element.search = 'show';
    });
    this.ShowNoRecords = false
  }

  getValue(status): void {
    let currentAction;
    if (status === 'Insert') {
      currentAction = 'Insert'
    }
    else if (status === 'change') {
      currentAction = 'Update'
    }
    const savePrintPrice = [];
    this.printPriceList.forEach((element, index) => {
      element.search = 'show';
      const logoTypeData = this.logoTypeList.filter(list => list.value === this.listpriceForm.value['logotype' + index]);
      const logoPositionData = this.logoPosition.filter(list => list.value === this.listpriceForm.value['logoposition' + index]);
      const logoAreaListData = this.logoAreaList.filter(list => list.value === this.listpriceForm.value['logoarea' + index]);
      const translations = [];

      this.getlang.forEach((element) => {
        let translateData = {
          "LanguageID": element.LanguageID,
          "LanguageCode": element.Code,
          "ObjectType": "ProductPrintPrices",
          "PropertyName": "PrintPriceDescription",
          "PropertyValue": this.listpriceForm.value[element.desc + index],
          "Action": "Insert",
          // "TranslationID": element.TranslationID,
          // "ObjectID": element.ObjectID,
        }
        translations.push(translateData);
      });

      const updateTranslation = [];
      if (element.Translations.length === 0) {
        this.getlang.forEach((element) => {
          let translateData = {
            "LanguageID": element.LanguageID,
            "LanguageCode": element.Code,
            "ObjectType": "ProductPrintPrices",
            "PropertyName": "PrintPriceDescription",
            "PropertyValue": this.listpriceForm.value[element.desc + index],
            "Action": "Update",
            // "TranslationID": element.TranslationID,
            // "ObjectID": element.ObjectID,
          }
          updateTranslation.push(translateData);
        });
        element.Translations = updateTranslation;
      }

      let priceData: any = (this.listpriceForm.value['prices' + index]).toString();
      let colorValue: any = (this.listpriceForm.value['colors' + index]).toString();
      let setUpValue: any = (this.listpriceForm.value['setup' + index]).toString();
      let toolsValue: any = (this.listpriceForm.value['tools' + index]).toString();
      let setData = {
        "ProductPrintPriceID": element.ProductPrintPriceID,
        "LogoTypeID": this.listpriceForm.value['logotype' + index],
        "LogoTypeName": status === 'change' ? logoTypeData.length > 0 ? logoTypeData[0].label : '' : element.LogoTypeName,
        "LogoTypeCode": status === 'change' ? logoTypeData.length > 0 ? logoTypeData[0].Code : '' : element.Code,
        "NumberOfColors": colorValue.includes(',') ? Number(this.listpriceForm.value['colors' + index].replace(',', '')) : Number(this.listpriceForm.value['colors' + index]),
        "LogoPositionID": this.listpriceForm.value['logoposition' + index],
        "LogoPositionDescription": status === 'change' ? logoPositionData.length > 0 ? logoPositionData[0].label : '' : '',
        "LogoMaxAreaID": this.listpriceForm.value['logoarea' + index],
        "LogoMaxAreaDescription": status === 'change' ? logoAreaListData.length > 0 ? logoAreaListData[0].label : '' : '',
        "Price": priceData.includes(',') ? Number((this.listpriceForm.value['prices' + index]).replace(',', '.')) : Number(this.listpriceForm.value['prices' + index]),
        "ScreenChargesCost": (this.listpriceForm.value['screencharges' + index].toString()).includes(',') ? Number((this.listpriceForm.value['screencharges' + index]).replace(',', '.')) : Number(this.listpriceForm.value['screencharges' + index]),
        "SetupCost": setUpValue.includes(',') ? Number((this.listpriceForm.value['setup' + index]).replace(',', '.')) : Number(this.listpriceForm.value['setup' + index]),
        "HandlingCost": ((this.listpriceForm.value['handlingcost' + index].toString()).includes(',')) ? Number((this.listpriceForm.value['handlingcost' + index]).replace(',', '.')) : Number(this.listpriceForm.value['handlingcost' + index]),
        "HandlingCostTypeID": this.listpriceForm.value['handlingcosttype' + index],
        "HandlingCostTypeCode": this.listpriceForm.value['handlingcosttype' + index],
        "HandlingCostTypeName": this.listpriceForm.value['logotype' + index],
        "ToolCost": toolsValue.includes(',') ? Number((this.listpriceForm.value['tools' + index]).replace(',', '.')) : Number(this.listpriceForm.value['tools' + index]),
        "isFixedLogo": this.listpriceForm.value['fixedlogo' + index],
        "isDefault": element.isDefault,
        "DisableLogoColors": element.DisableLogoColors,
        "Translations": status === 'change' ? element.Translations : element.Translations,
        "Action": element.Action !== undefined ? element.Action : 'Update'
      }
      savePrintPrice.push(setData)
    });
    this.getproductPrice.emit(savePrintPrice);
  }


  clearprintpriceForm() {
    const val = { ...this.printpriceForm.value }
    for (let key in val) {
      if (key === "fixedlogo") {
        val[key] = false
      } else {
        val[key] = ""
      }
    }
    this.printpriceForm.patchValue({ ...val })
  }

  loadPriceForm() {


    let group: any = {};
    group.logotype = new FormControl('');
    group.colors = new FormControl('');
    group.prices = new FormControl('');
    group.screencharges = new FormControl('');
    group.setup = new FormControl('');
    group.tools = new FormControl('');
    group.handlingcosttype = new FormControl('');
    group.handlingcost = new FormControl('');
    group.logoposition = new FormControl('');
    group.logoarea = new FormControl('');
    group.fixedlogo = new FormControl(false);

    // for (let i = 0; i < this.printPriceList.length; i++) {
    //this.printPriceList[i]['test'] = []

    const controlArray = [];
    const langOption = [];
    this.getlang.forEach((element, index) => {

      let langData = { label: element.Name, value: element.LanguageID };
      langOption.push(langData)
      const controlName = 'description' + element.Code;
      group[controlName] = new FormControl('');
      controlArray.push(controlName)
      this.getlang[index]['desc'] = controlName;
      //  this.getlang[i]['data'].push(controlName);
    });

    this.languageOption = langOption;

    // this.printPriceList[index]['s'] = controlArray;
    // }
    this.printpriceForm = new FormGroup(group);
    // this.selfManagePrintprice = new FormGroup(group)
    // let langData = { label: 'English', value: 1 };
    // this.getCurrentLang(langData);
    // this.changeListLang(langData);
  }

  loadPriceListForm() {
    if (this.printPriceList.length > 0) {
      let group: any = {};
      let flagArr = this.priceLogoType.map((ele) => ele.value)
      this.printPriceList.forEach((element, index) => {

        const controlArray = [];
        group['logotype' + index] = new FormControl(element.LogoTypeID, Validators.required);
        group['colors' + index] = new FormControl(element.NumberOfColors, Validators.required);
        group['prices' + index] = new FormControl(element.Price, Validators.required);
        group['screencharges' + index] = new FormControl(element.ScreenChargesCost);
        group['setup' + index] = new FormControl(element.SetupCost);
        group['tools' + index] = new FormControl(element.ToolCost);
        group['handlingcosttype' + index] = new FormControl(element.HandlingCostTypeID, Validators.required);
        group['handlingcost' + index] = new FormControl(element.HandlingCost);
        group['logoposition' + index] = new FormControl(element.LogoPositionID);
        group['logoarea' + index] = new FormControl(element.LogoMaxAreaID);
        group['fixedlogo' + index] = new FormControl(element.isFixedLogo);


        this.printPriceList[index]['controlname'] = {
          logotype: 'logotype' + index,
          colors: 'colors' + index,
          prices: 'prices' + index,
          screencharges: 'screencharges' + index,
          setup: 'setup' + index,
          tools: 'tools' + index,
          handlingcosttype: 'handlingcosttype' + index,
          handlingcost: 'handlingcost' + index,
          logoposition: 'logoposition' + index,
          logoarea: 'logoarea' + index,
          fixedlogo: 'fixedlogo' + index
        };

        // this.getlang.forEach((element, indexs) => {
        //   const controlName = 'description' + element.Code+indexs;
        //   group[controlName] = new FormControl('');
        //   //this.getlang[index]['desc'] = controlName;
        // });

        for (let j = 0; j < element.Translations.length; j++) {
          for (var lang of this.getlang) {
            if (element.Translations[j].LanguageCode === lang.Code) {
              // if (this.getlang.includes(element.Translations[j].LanguageCode)) {
              const controlName = 'description' + element.Translations[j].LanguageCode + index;
              group[controlName] = new FormControl(element.Translations[j].PropertyValue);
              controlArray.push({ controlName: controlName, Name: this.getlang.find(l => l.Code === element.Translations[j].LanguageCode).Name })
            }
          }
        }

        if (element.Translations.length === 0) {
          this.printPriceList[index]['lang'] = this.getlang;
        }
        else {
          this.printPriceList[index]['lang'] = controlArray;
        }


        if (flagArr.indexOf(element.LogoTypeID) === -1) {
          const g = [...this.priceLogoType]
          g.push({ label: element.LogoTypeName, value: element.LogoTypeID });
          this.priceLogoType = [...g]
        }
      });

      this.listpriceForm = new FormGroup(group);
      this.getValue('Insert')
    }
    console.log('this.languageOption....', this.languageOption)
    this.getCurrentLang({ label: 'English', value: 1 });
    this.changeListLang({ label: 'English', value: 1 })
  }

  // getFormvalue() {
  //   const printPriceArray = [
  //     {
  //       "Translations": [
  //         {
  //           "LanguageID": 1,
  //           "LanguageCode": "en",
  //           "ObjectType": "ProductPrintPrices",
  //           "PropertyName": "PrintPriceDescription",
  //           "PropertyValue": "dssdsdsd",
  //           "Action": "Insert"
  //         },
  //         {
  //           "LanguageID": 2,
  //           "LanguageCode": "dk",
  //           "ObjectType": "ProductPrintPrices",
  //           "PropertyName": "PrintPriceDescription",
  //           "PropertyValue": "",
  //           "Action": "Insert"
  //         },
  //         {
  //           "LanguageID": 3,
  //           "LanguageCode": "ro",
  //           "ObjectType": "ProductPrintPrices",
  //           "PropertyName": "PrintPriceDescription",
  //           "PropertyValue": "",
  //           "Action": "Insert"
  //         }
  //       ],
  //       "LogoTypeID": 1,
  //       "NumberOfColors": 1,
  //       "Price": 3,
  //       "ScreenChargesCost": 1,
  //       "SetupCost": 1,
  //       "ToolCost": 1,
  //       "HandlingCost": 1,
  //       "isFixedLogo": true,
  //       "LogoPositionID": 4,
  //       "LogoMaxAreaID": 5010,
  //       "Action": "Insert"
  //     }
  //   ];
  // }


  checkAddPrintPrice() {
    if (this.printpriceForm) {
      const { logotype, colors, prices, handlingcosttype, } = this.printpriceForm.value
      if (!(logotype && colors && prices && handlingcosttype)) {
        return true
      }
      return false
    }
  }

  addPrintPrice() {
    const logoTypeData = this.logoTypeList.filter(list => list.value === this.printpriceForm.value.logotype);
    const logoPositionData = this.logoPosition.filter(list => list.value === this.printpriceForm.value.logoposition);
    const logoAreaListData = this.logoAreaList.filter(list => list.value === this.printpriceForm.value.logoarea);


    // if (this.priceLogoType.length > 0) {
    //   let flag = true
    //   this.priceLogoType.forEach(ele => {
    //     if (this.printpriceForm.value['logotype'] === ele.value && flag) {
    //       flag = false
    //     }
    //   })
    //   if (!flag) {
    //     this.toast.error(`${logoTypeData[0].label} already added`)
    //     return null
    //   }
    // }

    let setData = {
      "LogoTypeID": this.printpriceForm.value['logotype'],
      "LogoTypeName": logoTypeData.length > 0 ? logoTypeData[0].label : '',
      "LogoTypeCode": logoTypeData.length > 0 ? logoTypeData[0].Code : '',
      "NumberOfColors": (this.printpriceForm.value['colors']).includes(',') ? (this.printpriceForm.value['colors']).replace(',', '') : this.printpriceForm.value['colors'],
      "LogoPositionID": this.printpriceForm.value['logoposition'],
      "LogoPositionDescription": logoPositionData.length > 0 ? logoPositionData[0].label : '',
      "LogoMaxAreaID": this.printpriceForm.value['logoarea'],
      "LogoMaxAreaDescription": logoAreaListData.length > 0 ? logoAreaListData[0].label : '',
      "Price": (this.printpriceForm.value['prices']).includes(',') ? (this.printpriceForm.value['prices']).replace(',', '.') : this.printpriceForm.value['prices'],
      "ScreenChargesCost": this.printpriceForm.value['screencharges'].includes(',') ? this.printpriceForm.value['screencharges'].replace(',', '.') : this.printpriceForm.value['screencharges'],
      "SetupCost": (this.printpriceForm.value['setup']).includes(',') ? (this.printpriceForm.value['setup']).replace(',', '.') : this.printpriceForm.value['setup'],
      "HandlingCost": this.printpriceForm.value['handlingcost'].includes(',') ? this.printpriceForm.value['handlingcost'].replace(',', '.') : this.printpriceForm.value['handlingcost'],
      "HandlingCostTypeID": this.printpriceForm.value['handlingcosttype'],
      "HandlingCostTypeCode": this.printpriceForm.value['handlingcosttype'],
      "HandlingCostTypeName": this.printpriceForm.value['logotype'],
      "ToolCost": (this.printpriceForm.value['tools']).includes(',') ? (this.printpriceForm.value['tools']).replace(',', '.') : this.printpriceForm.value['tools'],
      "isFixedLogo": this.printpriceForm.value['fixedlogo'],
      // "Translations": [
      //   {
      //     "TranslationID": 46222489,
      //     "LanguageID": 1,
      //     "LanguageCode": "en",
      //     "ObjectID": 13070696,
      //     "ObjectType": "ProductPrintPrices",
      //     "PropertyName": "PrintPriceDescription",
      //     "PropertyValue": "new"
      //   },
      //   {
      //     "TranslationID": 46222490,
      //     "LanguageID": 2,
      //     "LanguageCode": "dk",
      //     "ObjectID": 13070696,
      //     "ObjectType": "ProductPrintPrices",
      //     "PropertyName": "PrintPriceDescription",
      //     "PropertyValue": ""
      //   },
      //   {
      //     "TranslationID": 46222491,
      //     "LanguageID": 3,
      //     "LanguageCode": "ro",
      //     "ObjectID": 13070696,
      //     "ObjectType": "ProductPrintPrices",
      //     "PropertyName": "PrintPriceDescription",
      //     "PropertyValue": ""
      //   }
      // ],
      "Action": "Insert"
    }


    let translations = []


    this.getlang.forEach((element) => {
      for (let key in this.printpriceForm.value) {
        if ("description" + element.Code === key) {
          let k = {
            "LanguageID": element.LanguageID,
            "LanguageCode": element.Code,
            "ObjectType": "ProductPrintPrices",
            "PropertyName": "PrintPriceDescription",
            "PropertyValue": this.printpriceForm.value[key],
            "Action": "Insert"
          }
          translations.push(k)
        }

      }

    });

    setData['Translations'] = translations;
    this.printPriceList.push(setData);
    this.selectedLang = 'English'
    this.loadPriceForm();
    this.loadPriceListForm();
    setTimeout(() => {
      this.getSelectedPrice = this.printpriceForm.value['logotype'];
    }, 500);
    // this.loadProductData();
  }

  getLogoType() {
    // this.productService.loadLogoType();
    this.logoService.getLogoType().subscribe((data: any) => {
      if (data) {
        const logoTypeArray = [{ label: 'Select logo type', value: '', Code: '' }];
        data.forEach(element => {
          logoTypeArray.push({ label: element.Name, value: element.LogoTypeID, Code: element.Code })
        });
        this.logoTypeList = logoTypeArray;
        this.loadDatalogoName = true;
        this.getValue('change');
      }
    })
  }

  getHandlingCost() {
    this.productService.loadHandlingCost();
    this.productService.getHandlingCost().subscribe(data => {
      if (data) {
        const handlingCost = [{ label: 'Select handling cost type', value: '' }];
        data.forEach(element => {
          handlingCost.push({ label: element.Name, value: element.ProductAdditionalCostTypeID })
        });
        this.handlingCost = handlingCost;
      }
    })
  }

  getLogoPosition() {
    const data = {
      supplierID: this.productDetails.SupplierID,
      translations: true
    };
    this.productService.loadProductLogoPosition(data);
    this.productService.getProductLogoPositionList().subscribe((getdata: any) => {
      if (getdata) {
        const logoPosition = [];
        getdata.forEach(element => {
          const getLogoPosition = element.Translations.filter(logoList => logoList.LanguageID === 1)
          logoPosition.push({ label: getLogoPosition[0].PropertyValue, value: element.LogoPositionID })
        })
        this.logoPosition = logoPosition;
        this.logoPosition = this.logoPosiValues
        this.loadDatalogoposdesc = true;
        this.getValue('change');
      }
    })
  }

  getLogoArea() {
    const supplierData = {
      supplierID: this.productDetails.SupplierID
    }
    this.productService.loadProductLogoArea(supplierData);
    this.productService.getProductLogoArea().subscribe(data => {
      if (data) {
        const logoArea = [{ label: 'Select logo area', value: '' }]
        data.forEach(element => {
          logoArea.push({ label: element.Description, value: element.LogoMaxAreaID })
        })
        this.logoAreaList = logoArea;
        this.loadDatalogoareadesc = true;
        this.getValue('change');
      }
    })
  }

  deleteprintprice(data, index) {
    this.deleteModal.show()
    this.deleteData = data;
    this.deleteData.index = index;
  }

  getDeleteStatus(status) {
    if (status) {
      this.deleteData.Action = "Delete";
      this.printPriceList.splice(this.deleteData.index, 1);
      this.printPriceList.push(this.deleteData);
      this.priceLogoType = this.priceLogoType.filter(e => e.value !== this.deleteData.LogoTypeID);
      this.loadPriceListForm();
      this.deleteModal.hide()
    }
    else {
      this.deleteModal.hide()
    }

  }
  getCurrentLang(data) {
    this.selectedLang = data.label
    this.selectedLanguageId = data.value;


  }

  changeListLang(data) {
    this.language = data.label;
    this.selectedLanguageId = data.value;

  }

  checkTest() {
    return true
  }

  duplicateInt() {
    const duplicateArray = (this.productDetails[this.selectedPopup - 1]) ? (this.productDetails[this.selectedPopup - 1].ProductPrintPrices) : [];
    let setArray = [];
    duplicateArray.forEach(element => {
      let obj: any = {
        "LogoTypeID": element.LogoTypeID,
        "LogoTypeName": element.LogoTypeName,
        "LogoTypeCode": element.LogoTypeCode,
        "NumberOfColors": element.NumberOfColors,
        "LogoPositionID": element.LogoPositionID,
        "LogoPositionDescription": element.LogoPositionDescription,
        "LogoMaxAreaID": element.LogoMaxAreaID,
        "LogoMaxAreaDescription": element.LogoMaxAreaDescription,
        "Price": element.Price,
        "ScreenChargesCost": element.ScreenChargesCost,
        "SetupCost": element.SetupCost,
        "HandlingCost": element.HandlingCost,
        "HandlingCostTypeID": element.HandlingCostTypeID,
        "HandlingCostTypeCode": element.HandlingCostTypeCode,
        "HandlingCostTypeName": element.HandlingCostTypeName,
        "ToolCost": element.ToolCost,
        "isFixedLogo": element.isFixedLogo,
        "Action": "Insert"
      }
      let translations = [];
      element.Translations.forEach(lang => {
        let k = {
          "LanguageID": lang.LanguageID,
          "LanguageCode": lang.LanguageCode,
          "ObjectType": lang.ObjectType,
          "PropertyName": lang.PropertyName,
          "PropertyValue": lang.PropertyValue,
          "Action": "Insert"
        }
        translations.push(k)
      });
      obj.Translations = translations;
      setArray.push(obj);
    });

    let getArray = [...this.printPriceList, ...setArray];
    this.printPriceList = getArray;
    this.loadPriceForm();
    this.loadPriceListForm();
    this.getLogoType();
  }

  getFixedLogo(data) {
  }

  restrictAlphabets(evt) {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    let regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToDot(event, type?) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = type === 'empty' ? event.target.value.replace(',', '') : event.target.value.replace(',', '.');
    }
    event.target.value = t;
  }

  allowNumericDot(event) {
    let theEvent = event || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    let regex = /^[0-9.,\b]+$/;
    let speChar = /^[0-9a-zA-Z\_]+$/;

    if (event.keyCode === 46) {
      return true;
    }
    if ((!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57)))) {
      event.returnValue = false;
      return;
    }
    if (!regex.test(key) || !speChar.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToEmpty(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
  }



}