import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { ParentshopHttpService } from './state/services/parentshop.http.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFilterModule } from '../common/common-filter/common-filter.module';
import { ParentshopListModule } from './parentshop-list/parentshop-list.module';
// import { TransportationHubComponent } from './webshop-edit/transportation-hub/transportation-hub.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ColorPickerModule } from 'ngx-color-picker';
import { RouterModule } from '@angular/router';
import { ControlValidationModule } from '../shared/control-validation/control-validation.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        CommonFilterModule,
        ParentshopListModule,
        PerfectScrollbarModule,
        ColorPickerModule,
        RouterModule,
        ControlValidationModule
    ],
    declarations: [],
    providers: [ParentshopHttpService, {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
    exports: []
})
export class ParentshopModule { }
