import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../../shared/common-services/api.service';

@Component({
  selector: 'rewardkart-admin-handling-charges',
  templateUrl: './handling-charges.component.html',
  styleUrls: ['./handling-charges.component.scss']
})
export class HandlingChargesComponent implements OnInit {
  handlingChargeDetails: FormGroup;
  @Input() webshopData: any;
  @Input() customerCurrencies: any;
  @Input() handlingcharges: any;
  @Output() getHandlingChargeData = new EventEmitter<any>();
  showLoader: any;
  constructor(private webshopHttpService: WebshopHttpService, private formBuilder: FormBuilder, private translate: TranslateService, private toast: ToastService) { }

  ngOnInit() {
    this.loadForm();
  }

  loadForm() {
    this.handlingChargeDetails = this.formBuilder.group({
      PricePerOrderItem: ['', Validators.required],
      MinimumPricePerSuborder: ['', Validators.required],
      CurrencyID: ['', Validators.required]
    });
    console.log("handlingcharges :Data : ", this.handlingcharges);

    this.handlingChargeDetails.patchValue({
      PricePerOrderItem: this.handlingcharges.PricePerOrderItem,
      MinimumPricePerSuborder: this.handlingcharges.MinimumPricePerSuborder,
      CurrencyID: this.handlingcharges.CurrencyID
    });
  }

  allowDecimal(event) {
    // return event.charCode >= 48 && event.charCode <= 57;
    const charCode = event.charCode;
    console.log("charCode : ", charCode);
    if (
      (charCode !== 45) &&      // “-” CHECK MINUS, AND ONLY ONE.
      (charCode !== 46) &&      // “.” CHECK DOT, AND ONLY ONE.
      (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  saveHandlingCharge() {
    this.getHandlingChargeData.emit(this.handlingChargeDetails.value);
  }

  updateHandlingCharge() {
    let updateHandlingCostData = {};
    updateHandlingCostData['CustomerID'] = this.webshopData.CustomerID;
    updateHandlingCostData['PricePerOrderItem'] = this.handlingChargeDetails.value.PricePerOrderItem;
    updateHandlingCostData['MinimumPricePerSuborder'] = this.handlingChargeDetails.value.MinimumPricePerSuborder;
    updateHandlingCostData['CurrencyCode'] = this.handlingChargeDetails.value.CurrencyID;
    updateHandlingCostData['CurrencyID'] = this.handlingChargeDetails.value.CurrencyID;

    console.log("updateHandlingCostData : ", updateHandlingCostData);
    this.getHandlingChargeData.emit(updateHandlingCostData);

  }

}
