import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/state/services/user.service';
import { BillboardHttpService } from '../state/services/billboard.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
@Component({
  selector: 'rewardkart-admin-billboard-edit',
  templateUrl: './billboard-edit.component.html',
  styleUrls: ['./billboard-edit.component.scss']
})
export class BillboardEditComponent implements OnInit {
  billBoardFormGroup: FormGroup;
  formSubmitted = false;
  billBoardDefaultValue = {
    ImageName: '',
    LanguageID: 0,
    AdURL: 'www.google.com',
    VisibleOnLinkNetwork: true,
    newsletter: false
  };

  billBoardImageUrl = '';

  defaultLanguage = { label: 'Billboard visible on all Languages', value: 0 };

  saveButtonLabel = 'Save';
  backButtonLabel = "Cancel";
  languages = [];

  isProductSearchVisible = false;

  productRoles = [];

  billboardId: number = null;

  searchResult = [];
  newsLetterURL: any;
  billboardImageURL: any;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public toaster: ToastService,
    private billboardHttpService: BillboardHttpService
  ) {

    /** Static URL for Newsletter with Dynamic domain set based on Dev, Qatest, Staging, Prod Server */
    // let hostname = 'conxion.linkpartner-staging.dk';  //for local
    const hostname = window.location.hostname; // for deploy 
    let baseURL = '';
    if (String(hostname) !== '') {
      if (hostname.search('dev') !== -1 || hostname.search('qatest') !== -1 || hostname.search('staging') !== -1) {
        baseURL = hostname.replace('linkpartner', 'linknetwork');
      } else {
        const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
        baseURL = webshopDetail.Name.toLowerCase() + '.linkwebshop.com';
      }
      this.newsLetterURL = window.location.protocol + '//' + baseURL + '/#newsletter';
    }
  }

  ngOnInit() {
    this.createForm();
    this.getLanguages();
    const { id } = history.state;
    if (id) {
      this.saveButtonLabel = 'Update';
      this.backButtonLabel = "Back";
      this.billboardId = id;
      this.getBillboard(id);
    } else {
    }
  }

  getBillboard(id) {
    this.billboardHttpService.getBillboard(id).subscribe((billboardItemRes: any) => {
      if (billboardItemRes) {
        this.productRoles = billboardItemRes.ProductRoles;
        this.createForm(billboardItemRes);

        this.billBoardImageUrl = billboardItemRes.ImageURL;
      }
    });
  }

  createForm(billBoardItem = this.billBoardDefaultValue) {
    //this.billboardImageURL = (billBoardItem.AdURL !== null && billBoardItem.AdURL !== undefined) ? billBoardItem.AdURL : 'www.google.com';
    this.billBoardFormGroup = this.formBuilder.group({
      ImageName: [billBoardItem.ImageName, Validators.required],
      AdURL: [billBoardItem.AdURL || 'www.google.com'],
      LanguageID: [billBoardItem.LanguageID || 0],
      VisibleOnLinkNetwork: [billBoardItem.VisibleOnLinkNetwork],
      newsletter: [(billBoardItem.AdURL === this.newsLetterURL) ? true : false]
    });
  }

  goBack() {
    this.router.navigate(['/admin-billboard-list'], { replaceUrl: false });
  }

  getLanguages() {
    this.userService.loadLanguages();

    this.userService.getAllLanguages().subscribe(
      languageResponse => {
        if (languageResponse) {
          this.languages = [
            this.defaultLanguage,
            ...languageResponse.map(language => ({
              value: language.LanguageID,
              label: language.Name
            }))
          ];
        }
      },
      error => {
        console.log('unable to get language');
      }
    );
  }

  toggleProductSearchVisibility() {
    this.isProductSearchVisible = !this.isProductSearchVisible;
  }
  visibilityChange(event) {
    console.log(event, 'changeevent');
  }

  validateForm() {
    if (this.billBoardFormGroup.valid) {
      const formValue = this.billBoardFormGroup.value;
      if (formValue.LanguageID === 0) {
        delete formValue.LanguageID;
      }
      this.submitForm(formValue);
    } else {
      this.formSubmitted = true;
    }
  }

  submitForm(formValue) {
    if (this.billboardId) {
      this.billboardHttpService
        .updateBillboard(this.billboardId, formValue)
        .subscribe(
          editResponse => {
            this.goBack();
          },
          error => {
            console.log('unable to edit billboard', error);
          }
        );
    } else {
      this.billboardHttpService.addBillboard([formValue]).subscribe(
        addResponse => {
          this.goBack();
        },
        error => {
          this.toaster.error(error.error.Message);
        }
      );
    }
  }

  handleUploadedImage(event) {
    const { data, error } = event;
    if (error) {
      //console.log('unable to upload image', event.error);
    } else {
      this.billBoardFormGroup.controls.ImageName.setValue(data[1] || '');
    }
  }

  handleProductSelection(product) {
    const hostname = window.location.origin; // for deploy
    if (product) {
      const { ProductID, Name } = product;
      const linkUrl = `${hostname}/product${ProductID}/${Name}`;
      this.billBoardFormGroup.controls.AdURL.setValue(linkUrl);
    }
  }

  setAdURL() {
    if (this.billBoardFormGroup.value.newsletter) {
      this.billBoardFormGroup.controls.AdURL.setValue(this.newsLetterURL);
    } else {
      if (this.billboardImageURL === this.billBoardFormGroup.value.AdURL) {
        this.billBoardFormGroup.controls.AdURL.setValue(this.billBoardDefaultValue.AdURL);
      } else {
        this.billBoardFormGroup.controls.AdURL.setValue(this.billboardImageURL);
      }
    }
  }
}
