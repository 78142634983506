import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CategoryState } from '../reducers/category.reducer';
import { CategoryActions } from '../actions/index';
import { getCategoryListSuccess, getCategoryUpdateSuccess, getSelectedCategory } from '../reducers/index';
import { CategoryHttpService } from './category.http.service';
import { Subject } from 'rxjs';
@Injectable()
export class CategoryService {

    onSelecteCategory: Subject<any> = new Subject<any>();
    onSelecteSubCategory: Subject<any> = new Subject<any>();
    onLoadCategory: Subject<any> = new Subject<any>();

    constructor(private store: Store<CategoryState>, private categoryHttpService: CategoryHttpService) { }

    loadCategorys(customerID: any) {
        this.store.dispatch(new CategoryActions.LoadCategory(customerID));
    }

    getCategoryList() {
        return this.store.select(getCategoryListSuccess);
    }

    saveCategory(data) {
        this.store.dispatch(new CategoryActions.SaveCategory(data));
    }

    getCategoryUpdateSuccess() {
        return this.store.select(getCategoryUpdateSuccess);
    }

    selectedCategory(data) {
        this.store.dispatch(new CategoryActions.SelectedCategory(data));
    }

    getSelectedCategory() {
        return this.store.select(getSelectedCategory);
    }

    // This is a direct call to http service instead of using state based as it is parameter based.
    getCustomerCategoryList(customerID: any) {
        return this.categoryHttpService.getCustomerCategoryList(customerID);
    }



}
