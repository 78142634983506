import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { LinkTableService } from '../../shared/link-table/state/services/link-table.service';
import { ProductHttpService } from '../state/services/product.http.service';
import { WebService } from '../../restful-service/web.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, ToastService, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { ApiService } from 'src/app/shared/common-services/api.service';
import { ProductService } from '../state/services/product.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-market-edit',
  templateUrl: './product-market-edit.component.html',
  styleUrls: ['./product-market-edit.component.scss']
})
export class ProductMarketEditComponent implements OnInit {
  @ViewChild('datePicker', { static: true }) datePicker: MDBDatePickerComponent;
  showContent: boolean;
  @HostListener('keydown.esc')
  handleInput() {
    if (this.datePicker.isOpen) {
      this.datePicker.closeBtnClicked();
    }
  }
  marketingProductEditMenuTab: any = [];
  productDetails: any = [];
  loadApi: any = {};
  loadTab: Boolean = false;
  public activePage: any = 'marketingProducts';
  productImageDetails: any;
  basicInfo: any;
  marketingProducts = 'MARKETINGPRODUCTS';
  productimages = 'PRODUCTIMAGES';
  publish = 'PUBLISH';
  currentTab: any = { itemName: 'marketingProducts', Position: 0 };
  marketingProductEditTab: any = [
    'marketingProducts',
    'productImages', 'publish'
  ]
  isPublished: boolean = false;
  publishForm: FormGroup;
  displayPublishDate: any;
  isVisibleError: Boolean = false;
  showLoader: boolean = false
  date = new Date();
  productData: any = {};
  myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeAfterSelect: true,
    markCurrentDay: true,

    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() - 1 }
  };
  @ViewChild('publishModal', { static: false }) publishModal: ModalDirective;
  @ViewChild('unpublishWarningModal', { static: false }) unpublishWarningModal: ModalDirective;
  @ViewChild('visibleDatePopup', { static: false }) visibleDatePopup: ModalDirective;
  loadImages: Boolean = false;
  productCustID: any;
  constructor(private linkTableService: LinkTableService, private webService: WebService,
    public toast: ToastService, public productHttpService: ProductHttpService,
    private translate: TranslateService, private apiService: ApiService,
    private productService: ProductService, public formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.editmarketproduct();
    this.loadForm()
    this.loadTranslate();
    this.publishForm.valueChanges.subscribe(() => {
      const d = this.publishForm.value.visibleDate
      const date = new Date(this.dateToMonth(d))
      const currentDate = new Date(this.MMDDYYYY())
      if (!(date >= currentDate)) {
        this.isVisibleError = true
      } else {
        this.isVisibleError = false
      }
      this.displayPublishDate = this.publishForm.value.visibleDate
    });
  }
  loadForm() {
    this.displayPublishDate = this.getDateFormat(new Date())
    this.publishForm = this.formBuilder.group({
      visibleDate: [this.displayPublishDate, Validators.required],
    })
  }

  getTwoDigitDateFormat(monthOrDate) {
    return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
  }

  getDateFormat(data) {
    let getDate = new Date(data);
    let dateFormat = this.getTwoDigitDateFormat(getDate.getDate()) + '/' + this.getTwoDigitDateFormat(getDate.getMonth() + 1) + '/' + getDate.getFullYear();
    return dateFormat;
  }


  MMDDYYYY = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('-');
  }


  YYYYMMDD = (val) => {
    let d = new Date(this.dateToMonth(val)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('/');
  }


  dateToMonth(date: String) {
    const val = date.split('/');
    return [val[1], val[0], val[2]].join('-')
  }
  loadTranslate() {
    this.translate.get('PRODUCTMARKETINGPRODUCT', {}).subscribe((res: any) => {
      this.marketingProductEditMenuTab = [
        { itemName: res.MARKETINGPRODUCTS, id: 'marketingProducts', isActive: true, rightPosition: false },
        { itemName: res.PRODUCTIMAGES, id: 'productImages', isActive: false, rightPosition: false },
        { itemName: this.isPublished ? "Unpublish" : res.PUBLISH, id: 'publish', rightPosition: true },
      ]
    })

  }

  previous(prev) {
    this.marketingProductEditMenuTab.forEach(element => {
      if (element.id === prev) {
        element.isActive = true;
        this.activePage = prev;
      }
      else {
        element.isActive = false;
      }
    });
  }

  next(next) {
    this.marketingProductEditMenuTab.forEach(element => {
      if (element.id === next) {
        element.isActive = true;
        this.activePage = next;
      }
      else {
        element.isActive = false;
      }
    });
  }

  editmarketproduct() {
    this.showLoader = true;
    if (history.state.data !== undefined) {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      let custID;
      if (webshopDetail !== null && webshopDetail !== undefined) {
        custID = webshopDetail.CustomerID
      }
      this.productCustID = history.state.data.CustomerID;
      this.webService.productEditdata(history.state.data, true, custID).subscribe((editdata) => {
        if (editdata) {
          this.productDetails = editdata;
          this.checkPublishedStatus()
          this.loadTab = true;
          this.loadApi.productImages = true;
          this.showLoader = false;
        }
      },
        (error) => {
          this.showLoader = false;
          let errMsg = 'The request is invalid. ';
          for (var key in error.error.ModelState) {
            errMsg += error.error.ModelState[key].toString() + ' ';
          }
          this.toast.error(errMsg);
        })
    }
  }

  checkPublishedStatus() {
    if (this.productDetails["VisibleDate"] !== undefined) {
      const data = this.marketingProductEditMenuTab.map(tab => {
        if (tab.id === "publish") {
          return { itemName: "Unpublish", id: 'publish', isActive: false }
        }
        return tab
      })
      this.marketingProductEditMenuTab = data
      this.isPublished = true
    } else {
      const data = this.marketingProductEditMenuTab.map(tab => {
        if (tab.id === "publish") {
          return { itemName: "Publish", id: 'publish', isActive: false }
        }
        return tab
      })
      this.marketingProductEditMenuTab = data
      this.isPublished = false
      if (this.isPublished === false) {
        this.showContent = true;
      }
    }
  }

  setActive(val) {
    if (val.id === 'publish') {
      if (this.isPublished) {
        this.changeVisibility(true)
      } else {
        this.isVisibleError = false
        this.displayPublishDate = this.getDateFormat(new Date())
        this.publishForm.setValue({
          visibleDate: this.displayPublishDate
        })
        this.publishModal.show()
      }
    }

    if (!(val.id === 'publish')) {
      this.loadApi[val.id] = true
      this.marketingProductEditMenuTab.forEach(element => {
        element.isActive = false;
      });
      val.isActive = true;
      this.activePage = val.id;
      let currentPageIndex = this.marketingProductEditMenuTab.findIndex((x: any) => x === val.id);
      this.currentTab = { itemName: val.id, Position: currentPageIndex };
    }
  }
  selectTab(tabname) {
    this.activePage = tabname;
  }
  pushProductList() {
    window.scroll(0, 0);
    this.router.navigate(['/admin-product-list'], { replaceUrl: false });
  }
  cancel() {
    this.router.navigate(['/admin-product-list'], { replaceUrl: false });
  }
  // getProductDetails() {
  //   this.showLoader = true;
  //   this.tableEvent = this.linkTableService.getSelectedRow().subscribe(data => {
  //     if (data) {
  //       this.productData = data;
  //       this.webService.productEditdata(data).subscribe((editdata) => {
  //         if (editdata) {
  //           this.productDetails = editdata;
  //           this.checkPublishedStatus()
  //           this.loadBasicInfo = true;
  //           this.showLoader = false;
  //         }
  //       })
  //     }
  //   });
  // }
  changeVisibility = (push: any) => {
    const userID = this.apiService.getLoginData().UserID
    this.productService.loadChangeVisibility({ ...this.productDetails, userID });
    this.productService.getChangeVisibility().subscribe((data) => {
      if (data) {
        this.toast.success('Product updated successfully');
        this.editmarketproduct();
        if (!push) {
          this.unpublishWarningModal.hide()
        }
      }
    })
  }
  getbasicInfoData(basicinfo) {
    this.basicInfo = basicinfo;
  }

  getProductImageDetails(productImageDetails) {
    this.productImageDetails = productImageDetails;
  }
  unpublishWarning(data) {
    if (data.srcElement.tagName != "DIV" && data.srcElement.tagName != "A") {
      if (this.isPublished) {
        this.unpublishWarningModal.show()
      }
    }
  }
  utcDateformat = (dt) => {
    const date = this.dateToMonth(dt)
    return new Date(date + " UTC").toISOString()
  }
  updateProduct(IsVisible?: any, status?: any) {
    // const loginData = this.apiService.getLoginData();
    if (!this.isPublished) {
      this.productImageDetails.Translations.forEach(element => {
        this.basicInfo.response.Translations.forEach(ele => {
          if (element.PropertyName === ele.PropertyName && element.LanguageCode === ele.LanguageCode) {
            ele.PropertyValue = element.PropertyValue;
            ele.Action = 'Update';
          }
        });
        // if((element.ObjectID === '' || element.ObjectID === undefined) && (element.TranslationID === '' || element.TranslationID === undefined)){
        //   element.Action = 'Insert'
        // }
      });
      const getTranslation = this.basicInfo.response.Translations.filter(data => data.PropertyName === "ImageAlternativeText");
      if (getTranslation !== undefined && getTranslation.length === 0) {
        const imageTranslation = this.productImageDetails.Translations.filter(data => data.PropertyName === "ImageAlternativeText");

        imageTranslation.forEach(element => {
          element.Action = 'Insert';
          this.basicInfo.response.Translations.push(element)
        });

      }
      const loginData = this.apiService.getLoginData();
      let updateData = {
        "ProductID": this.basicInfo.response.ProductID,
        "SupplierID": this.basicInfo.formdata.SupplierID,
        "SupplierName": "Conxion",
        "SupplierQualityLevel": this.basicInfo.response.SupplierQualityLevel,
        "ProductIdManual": this.basicInfo.formdata.ProductIdManual,
        "IsStockParentProductDiscontinued": this.basicInfo.response.IsStockParentProductDiscontinued,
        "UndersupplierID": this.basicInfo.formdata.UndersupplierID,
        "VisibleOnLinkNetwork": this.basicInfo.response.VisibleOnLinkNetwork,
        "MinimumOrder": this.basicInfo.response.MinimumOrder,
        "MinimumProductionOrder": this.basicInfo.response.MinimumProductionOrder,
        "DeliveryWeeksFrom": this.basicInfo.response.DeliveryWeeksFrom,
        "DeliveryWeeksTo": this.basicInfo.response.DeliveryWeeksTo,
        "ImageName": this.productImageDetails ? this.productImageDetails.ImageName : this.productDetails.ImageName,
        "ImageURL": this.productImageDetails ? this.productImageDetails.ImageURL : this.productDetails.ImageURL,
        "PiecesPerUnit": this.basicInfo.response.PiecesPerUnit,
        "FixedUnits": this.basicInfo.response.FixedUnits,
        "UnitWeight": this.basicInfo.response.UnitWeight,
        "CurrencyID": this.basicInfo.response.CurrencyID,
        "PrintPricesCurrencyID": this.basicInfo.response.PrintPricesCurrencyID,
        "CurrencyCode": this.basicInfo.response.CurrencyCode,
        "CurrencySymbol": this.basicInfo.response.CurrencySymbol,
        "UpdateReminderDate": this.basicInfo.formdata.UpdateReminderDate,
        "AllowOrderSketch": this.basicInfo.response.AllowOrderSketch,
        "IsLogoDisabled": this.basicInfo.response.IsLogoDisabled,
        "IsSampleWithoutLogoDisabled": this.basicInfo.response.IsSampleWithoutLogoDisabled,
        "IsSampleWithLogoDisabled": this.basicInfo.response.IsSampleWithLogoDisabled,
        "AllowOrderSampleWithoutLogo": this.basicInfo.response.AllowOrderSampleWithoutLogo,
        "AllowOrderSampleWithLogo": this.basicInfo.response.AllowOrderSampleWithLogo,
        "isInternalStockProduct": this.basicInfo.response.isInternalStockProduct,
        "VATPercentageToInclude": this.basicInfo.response.VATPercentageToInclude,
        "ProductNewEndDate": this.basicInfo.formdata.ProductNewEndDate,
        "ProductFeaturedEndDate": this.basicInfo.formdata.ProductFeaturedEndDate,
        "IsFeaturedProduct": this.basicInfo.response.IsFeaturedProduct,
        "IsNewProduct": this.basicInfo.response.IsNewProduct,
        "isIndexedBySearchEngines": this.basicInfo.response.isIndexedBySearchEngines,
        "ProductUnitID": this.basicInfo.response.ProductUnitID,
        "ProductUnitName": this.basicInfo.response.ProductUnitName,
        "CreationDate": this.basicInfo.response.CreationDate,
        "CreatedByUserID": this.basicInfo.response.CreatedByUserID,
        "CreatedByUserName": this.basicInfo.response.CreatedByUserName,
        "CreatedByCustomerID": this.basicInfo.response.CreatedByCustomerID,
        "LastUpdateDate": this.basicInfo.response.LastUpdateDate,
        "LastUpdatedByUserID": this.basicInfo.response.LastUpdatedByUserID !== undefined ? this.basicInfo.response.LastUpdatedByUserID : loginData.UserID,
        "LastUpdatedByUserName": this.basicInfo.response.LastUpdatedByUserName,
        "TotalLikedQuantity": this.basicInfo.response.TotalLikedQuantity,
        "TotalNumberOfLikes": this.basicInfo.response.TotalNumberOfLikes,
        "DisablePrices": this.basicInfo.response.DisablePrices,
        "DisableStockData": this.basicInfo.response.DisableStockData,
        "isProductImported": this.basicInfo.response.isProductImported,
        "isExpressOrderEnabled": this.basicInfo.response.isExpressOrderEnabled,
        "isTransportationFree": this.basicInfo.response.isTransportationFree,
        "DisableTransportationCostCalculation": this.basicInfo.response.DisableTransportationCostCalculation,
        "isTransportationHandlingCostFree": this.basicInfo.response.isTransportationHandlingCostFree,
        "TransportationOriginCountryCode": this.basicInfo.response.TransportationOriginCountryCode,
        "TransportationOriginCountryName": this.basicInfo.response.TransportationOriginCountryName,
        "isVolumeItem": this.basicInfo.response.isVolumeItem,
        "EnableConvertToBatch": this.basicInfo.response.EnableConvertToBatch,
        "isLockedDeliveryDate": this.basicInfo.response.isLockedDeliveryDate,
        "isMarketingProduct": this.basicInfo.response.isMarketingProduct,
        "ProductPrices": [],
        "ProductImages": this.productImageDetails ? this.productImageDetails.ProductImages : '',
        "ProductSizes": [],
        "ProductColors": [],
        "ProductProperties": [],
        "ProductCustomers": this.basicInfo.response.ProductCustomers,
        "ProductCategories": this.basicInfo.response.ProductCategories,
        "ProductAdditionalCosts": [],
        "ProductGovernmentalCosts": [],
        "ProductLogoPositions": [],
        "ProductLogoMaxAreas": [],
        "ProductLogoTypes": [],
        "ProductRelatedProducts": [],
        "ProductFutureStock": [],
        "ProductRoles": [],
        "ProductTags": [],
        "ProductDownloads": this.basicInfo.response.ProductDownloads,
        "Translations": this.basicInfo.response.Translations,
        "isDeleted": this.basicInfo.response.isDeleted,
        "SelectedCategory": this.basicInfo.formdata.SelectedCategory,
        "VisibleDate": this.basicInfo.response.VisibleDate,
        "DiscontinuedDate": this.basicInfo.response.DiscontinuedDate,
        "OnStockFromDate": this.basicInfo.response.OnStockFromDate,
        "NextShipmentDate": this.basicInfo.response.NextShipmentDate,
        "ProductHotDealEndDate": this.basicInfo.response.ProductHotDealEndDate,
        "ProductDiscountEndDate": this.basicInfo.response.ProductDiscountEndDate,
        "CustomerID": this.basicInfo.formdata.CustomerID,
        "Action": "Update",
        "IsUpdated": false
      }



      if (IsVisible) {
        updateData["VisibleDate"] = this.YYYYMMDD(this.publishForm.value.visibleDate)
        updateData["NewVisibleDate"] = this.utcDateformat(this.publishForm.value.visibleDate)
        this.productDetails["VisibleDate"] = this.YYYYMMDD(this.publishForm.value.visibleDate)
      }

      this.productHttpService.updateProduct(updateData).subscribe((data) => {
        if (data !== null) {
          if (data === true) {
            this.editmarketproduct();
            this.loadImages = true;
            this.publishModal.hide();
            this.checkPublishedStatus();
            if (status === 'exit' || IsVisible) {
              this.cancel();
            }
            this.toast.success('Product updated successfully');
            localStorage.removeItem('dataSource');
          }
        }
      },
        (error) => {
          let errMsg = 'The request is invalid. ';
          for (var key in error.error.ModelState) {
            errMsg += error.error.ModelState[key].toString() + ' ';
          }
          this.toast.error(errMsg);
        })
    }
  }

  setDate() {
    this.displayPublishDate = this.getDateFormat(new Date())
    this.publishForm.setValue({
      visibleDate: this.displayPublishDate
    })
  }

}
