import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ProductService } from 'src/app/product/state/services/product.service';
import { ProductHttpService } from 'src/app/product/state/services/product.http.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ModalDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { UserService } from 'src/app/user/state/services/user.service';
import * as _ from 'lodash';
import { debug } from 'util';
import { ApiService } from 'src/app/shared/common-services/api.service';
@Component({
  selector: 'app-product-manage-additionalcosts',
  templateUrl: './product-manage-additional-cost.component.html',
  styleUrls: ['./product-manage-additional-cost.component.scss']
})

export class ProductManageAdditionalCostsComponent implements OnInit, OnChanges {
  colorPickerChange: any;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  productManageAdditionalCosts: FormGroup;
  optionsSelectParentColor: any = [{
    value: '',
    label: '',
  }];
  activeLanguage: any;
  items: FormArray;
  languageOption: any = [];
  translateForm: any = [];
  translateFormItems: any = [];
  translateOptionItems: any = [];
  selectedLang: any = 'English'
  @Input() productDetails: any
  @Input() loadApi: Boolean
  @Output() showManageColor = new EventEmitter<any>();
  @Output() setNewOptions = new EventEmitter<any>()
  @Output() updatestatusFun = new EventEmitter<any>();
  @Input() updatestatus: any;
  @ViewChild('ManageAdditionalCost', { static: true }) ManageAdditionalCost: ModalDirective;
  @Input('resetForm') resetForm: Boolean
  @Input('productAdditionalCostsForm') productAdditionalCostsForm: any
  subscribeMethod: boolean = false;
  optionsSelectCurrencies: any[];
  optionsSelectCountries: any[];
  optionsSelectTypes: any[];
  showManageAdditionalCostModal: boolean;
  additionalCosts: any;
  ProductAdditionalCostOptions: any;
  selectedProductCost: any;
  additionalCostOptionsList: any[];
  loadApiFlag: Boolean = false
  showLoader: Boolean = false;
  showAdditionalCostOptionModal: Boolean = false

  @Output() loadAdditionalcostDropdown = new EventEmitter<any>();
  saveProductCost: { ProductAdditionalCostID: any; SupplierID: any; DefaultCurrencyID: any; DefaultProductAdditionalCostTypeID: any; DefaultIsOptionalForBuyer: any; ProductAdditionalCostOptions: any[]; Action: string; Translations: any[]; };
  deleteaddCost: any;
  toasterAdd: boolean;
  toasterdelte: boolean;
  showLastEntry: any;
  getAdditionalCostDatas: any = [];
  searchAdditionalCostName: any;
  additionalCostLists: any
  perPagedata: number = 10;
  pageList: any = [];
  selectedpage: any = 1;
  selectedperpage: any = 10;
  rowPerPage: any = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 40, value: 40 }];
  selflogoTypeData: any;
  searchCriteria: any = {};
  totalCount: any;
  groupCollection: any;
  searchText: any = '';
  selectedAdditonalCost: any = {
    ProductAdditionalCostID: '',
    SupplierID: ''
  };
  selectedLangCode: string = 'en';
  webshopDetail: any;

  constructor(public formBuilder: FormBuilder,
    public productService: ProductService,
    public productHttpService: ProductHttpService,
    public apiService: ApiService,
    public userService: UserService,
    public toast: ToastService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    } else {
      const loginData = this.apiService.getLoginData();
      if (loginData !== undefined && loginData !== null && loginData !== '') {
        this.webshopDetail.CustomerID = loginData.CustomerID;
      }
    }


  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.resetForm && (changes.resetForm.currentValue !== changes.resetForm.previousValue)) {
      this.resetFilter()
      this.filterReset();
    }

    // getAllProductAdditionalCosts
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      //this.buildForm();
      this.loadApiFlag = true
      //this.userService.loadCountries();
      //this.userService.loadCurrencies();
      //this.productService.loadProductAdditionalCostTypes();
      //this.getCurrencies();
      // this.getCountries();
      //this.getAdditionalCostTypes();
      //this.getAllProductAdditionalCosts();
      //this.getProductAdditionalCosts();
    }
  }
  //  ngAfterViewInit(){
  //   this.userService.loadCountries();
  //   this.userService.loadCurrencies();
  //   this.productService.loadProductAdditionalCostTypes();
  //   this.getCurrencies();
  //   // this.getCountries();
  //   this.getAdditionalCostTypes();
  //   this.getAllProductAdditionalCosts();
  //   this.getProductAdditionalCosts();  
  //  } 

  ngOnInit() {
    this.buildForm();
    this.userService.loadCountries();
    this.userService.loadCurrencies();
    this.productService.loadProductAdditionalCostTypes();
    this.getCurrencies();
    // this.getCountries();
    this.getAdditionalCostTypes();
    this.getAllProductAdditionalCosts();
    this.getProductAdditionalCosts();
  }

  getProductAdditionalCosts() {
    let data = { translations: true };
    this.productHttpService.getAllProductAdditionalCosts(data).subscribe((data: any) => {
      if (data) {
        this.additionalCosts = data;
      }
    });
  }


  getCurrentLang(lang) {
    // debugger
    this.selectedLang = lang.label;
    this.selectedLangCode = <any>this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID).find(language => language.LanguageID == lang.value).Code;
    if (this.searchText) this.filterReset();
  }

  getCurrencies() {
    this.userService.getAllCurrencies().subscribe(
      data => {
        if (data) {
          const optionsSelectCurrencies = [{ label: 'Select currency', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.CurrencyID,
              label: element.Name,
            };
            optionsSelectCurrencies.push(langVal);
          });
          this.optionsSelectCurrencies = optionsSelectCurrencies;
        }
      });
  }

  getAdditionalCostTypes() {
    this.productHttpService.getProductAdditionalCostTypes().subscribe(
      (data: any) => {
        if (data) {
          const optionsSelectTypes = [{ label: 'Select type', value: '' }];
          data.forEach(element => {
            const langVal = {
              value: element.ProductAdditionalCostTypeID,
              label: element.Name,
            };
            optionsSelectTypes.push(langVal);
          });
          this.optionsSelectTypes = optionsSelectTypes;
        }
      });
  }

  // getCountries() {
  //   this.userService.getAllCountries().subscribe(
  //     data => {
  //       if (data) {
  //         this.optionsSelectCountries = [];
  //         data.forEach(element => {
  //           const langVal = {
  //             value: element.Alpha2Code,
  //             label: element.Name,
  //           };
  //           this.optionsSelectCountries.push(langVal);
  //         });
  //       }
  //     });
  // }

  buildForm() {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);
    let translationFieldJson = [
      {
        formcontrolname: 'AdditionalCostName',
        label: 'Name',
        PropertyName: "AdditionalCostName",
      }
    ];

    getlang.forEach(lang => {
      let langData = { label: lang.Name, value: lang.LanguageID };
      this.languageOption.push(langData)
      translationFieldJson.forEach(jsonField => {
        let formJson = {
          formcontrolname: 'AdditionalCostName' + lang.Code,
          label: jsonField.label,
          class: lang.Name,
          name: jsonField.formcontrolname,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: jsonField.PropertyName
        }
        this.translateForm.push(formJson)
      });
    })

    let group: any = {
      ProductAdditionalCostID: new FormControl(),
      SupplierID: new FormControl(),
      // Country: new FormControl(),
      DefaultPrice: new FormControl(),
      DefaultCurrencyID: new FormControl(),
      DefaultProductAdditionalCostTypeID: new FormControl(),
      DefaultIsOptionalForBuyer: new FormControl(),
      Name: new FormControl(),
      language: new FormControl(1),
      items: this.formBuilder.array([])
    }
    this.translateForm.forEach(element => {
      group[element.formcontrolname] = new FormControl('');
    })
    this.productManageAdditionalCosts = new FormGroup(group);
  }

  createItem(additionalCostVal?): FormGroup {
    let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

    this.translateFormItems = [];
    getlang.forEach(lang => {
      let isFound = additionalCostVal.Translations.find(data => data.LanguageCode == lang.Code);
      let formJson;
      if (isFound) {
        formJson = {
          formcontrolname: 'AdditionalCostName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: isFound.PropertyName,
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: isFound.PropertyName,
          PropertyValue: isFound.PropertyValue,
          TranslationID: isFound.TranslationID,
          ObjectID: isFound.ObjectID
        }
      } else {
        formJson = {
          formcontrolname: 'AdditionalCostName' + lang.Code,
          translationID: 'TranslationID' + lang.Code,
          objectID: 'ObjectID' + lang.Code,
          class: lang.Name,
          name: '',
          LanguageID: lang.LanguageID,
          LanguageCode: lang.Code,
          PropertyName: '',
          PropertyValue: '',
          TranslationID: '',
          ObjectID: ''
        }
      }
      this.translateFormItems.push(formJson);
    });
    let group: any = {
      ProductAdditionalCostID: additionalCostVal.ProductAdditionalCostID,
      SupplierID: additionalCostVal.SupplierID,
      DefaultPrice: additionalCostVal.Price,
      DefaultCurrencyID: additionalCostVal.DefaultCurrencyID,
      DefaultProductAdditionalCostTypeID: additionalCostVal.DefaultProductAdditionalCostTypeID,
      DefaultIsOptionalForBuyer: additionalCostVal.DefaultIsOptionalForBuyer,
    }
    this.translateFormItems.forEach(element => {
      group[element.formcontrolname] = element.PropertyValue;
      group[element.translationID] = element.TranslationID;
      group[element.objectID] = element.ObjectID;
    })
    return this.formBuilder.group({ ...group });
  }


  getAllProductAdditionalCosts() {
    // debugger
    this.clearFormItems();
    this.showLoader = true;
    this.subscribeMethod = false;
    this.items = this.productManageAdditionalCosts.get('items') as FormArray;
    const requestData = { 'supplierID': this.productDetails.SupplierID, 'translations': true };
    let subscription;
    // this.productService.loadAllProductAdditionalCosts(requestData);
    // if (!this.subscribeMethod) {
    // this.productService.getAllProductAdditionalCostsList().subscribe((data) => {

    this.searchCriteria = {
      PageIndex: this.selectedpage,
      PageSize: this.selectedperpage,
      ProductAdditionalCostID: this.selectedAdditonalCost.ProductAdditionalCostID
    };

    this.pageList = [];
    this.productHttpService.getProductAdditionalCostPagination(this.searchCriteria).subscribe((data: any) => {
      if (data !== null) {
        this.additionalCostLists = data.ItemsCollection;
        this.getAdditionalCostDatas = data.ItemsCollection;
        this.subscribeMethod = true;
        this.showLoader = false;
        data.ItemsCollection.forEach(element => {
          this.items.push(this.createItem(element));
        });

        //For pagination
        this.totalCount = data.TotalItemsCount;
        let getPageList = data.TotalItemsCount / this.selectedperpage;
        getPageList = Math.ceil(getPageList);
        this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
        const pageLisArray = []
        for (let i = 1; i <= getPageList; i++) {
          pageLisArray.push({ label: i, value: i })
        }
        this.pageList = pageLisArray;
      }
    });
    // }
    // if (this.subscribeMethod) {
    //   subscription.unsubscribe();
    // }
  }



  searchAdditionalCost() {
    // let getResult = [];
    // const getData = this.getAdditionalCostDatas;
    // getData.filter((data, index) => {
    //   data.Translations.filter((ele) => {
    //     let value = (ele.PropertyValue).toLowerCase();
    //     let searchData = (this.searchAdditionalCostName).toLowerCase();
    //     if ((value).includes(searchData)) {
    //       getResult.push(data)
    //     }
    //   })
    // });
    // const filterResult = _.uniq(getResult, 'AdditionalCost');
    // this.clearFormItems();
    // filterResult.forEach(element => {
    //   this.items.push(this.createItem(element));
    // });
    // this.additionalCostLists = filterResult;

    this.setPageSizeOptions(10);

  }

  resetFilter() {
    this.searchAdditionalCostName = '';
    this.clearFormItems();
    this.getAdditionalCostDatas.forEach(element => {
      this.items.push(this.createItem(element));
    });
    this.additionalCostLists = this.getAdditionalCostDatas;
  }
  clearFormItems() {

    if (this.productManageAdditionalCosts == undefined) {
      return true;
    }
    const control = <FormArray>this.productManageAdditionalCosts.controls['items'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  getcosttype(type) {
    this.productManageAdditionalCosts.patchValue({
      DefaultProductAdditionalCostTypeID: type.value
    })
  }

  addAdditionalCosts() {
    this.toasterAdd = true
    let translations = [];
    this.translateFormItems.forEach(item => {

      const data = {
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectType: "ProductAdditionalCosts",
        PropertyName: item.name,
        PropertyValue: this.productManageAdditionalCosts.value[item.formcontrolname],
        Action: "Insert"
      }
      translations.push(data);
    });

    let requestData = {
      SupplierID: this.productDetails.SupplierID,
      // Country: this.productManageAdditionalCosts.value.Country,
      Price: this.productManageAdditionalCosts.value.DefaultPrice.includes(',') ? this.productManageAdditionalCosts.value.DefaultPrice.replace(',', '.') : this.productManageAdditionalCosts.value.DefaultPrice,
      DefaultCurrencyID: this.productManageAdditionalCosts.value.DefaultCurrencyID,
      DefaultProductAdditionalCostTypeID: this.productManageAdditionalCosts.value.DefaultProductAdditionalCostTypeID,
      DefaultIsOptionalForBuyer: this.productManageAdditionalCosts.value.DefaultIsOptionalForBuyer,
      Translations: translations
    }
    console.log('requestData', requestData);
    if (requestData.SupplierID && requestData.Price && requestData.DefaultCurrencyID
      && requestData.DefaultProductAdditionalCostTypeID &&
      requestData.Translations) {
      // this.productService.addProductAdditionalCosts(requestData);
      this.showLoader = true;
      this.productHttpService.addProductAdditionalCosts(requestData).subscribe((data) => {
        this.showLoader = false;
        if (data !== null) {
          this.subscribeMethod = false;
          this.clearFormItems();
          this.clearValue();
          this.getAllProductAdditionalCosts();
          if (this.toasterAdd) {
            this.toast.success('Product additional cost added successfully');
            // this.buildForm();
            this.userService.loadCountries();
            this.userService.loadCurrencies();
            this.productService.loadProductAdditionalCostTypes();
            this.getCurrencies();
            this.getAdditionalCostTypes();
            this.getAllProductAdditionalCosts();
            this.getProductAdditionalCosts();
            this.toasterAdd = false
          }
          this.loadParentDropDown()
        }
      },
        err => {
          this.showLoader = false;
          if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
            if (err.error.ModelState.ProductAdditionalCosts !== null && err.error.ModelState.ProductAdditionalCosts !== undefined) {
              this.toast.error(err.error.ModelState.ProductAdditionalCosts);
            }
          }
        }
      );

    }
    else {
      this.toast.error("Please Fill  All the Fields")
    }
  }

  clearValue() {
    this.translateFormItems.forEach(data => {
      this.productManageAdditionalCosts.get(data.formcontrolname).patchValue('');
    });
    this.productManageAdditionalCosts.patchValue({
      DefaultPrice: '',
      DefaultCurrencyID: '',
      DefaultProductAdditionalCostTypeID: '',
      DefaultIsOptionalForBuyer: false,
    })
  }

  updateAdditionalCosts(productAdditionalCosts) {
    let translations = [];
    this.translateFormItems.forEach(item => {
      const data = {
        TranslationID: productAdditionalCosts.value[item.translationID],
        LanguageID: item.LanguageID,
        LanguageCode: item.LanguageCode,
        ObjectID: productAdditionalCosts.value[item.objectID],
        ObjectType: "ProductAdditionalCosts",
        PropertyName: item.name,
        PropertyValue: productAdditionalCosts.value[item.formcontrolname],
        Action: "Update"
      }
      translations.push(data);
    })

    console.log(productAdditionalCosts.value);
    const requestData = {
      parentId: productAdditionalCosts.value.ProductAdditionalCostID,
      data: {
        SupplierID: this.productDetails.SupplierID,
        Price: (productAdditionalCosts.value.DefaultPrice.toString()).includes(',') ? productAdditionalCosts.value.DefaultPrice.replace(',', '.') : productAdditionalCosts.value.DefaultPrice,
        DefaultCurrencyID: productAdditionalCosts.value.DefaultCurrencyID,
        DefaultProductAdditionalCostTypeID: productAdditionalCosts.value.DefaultProductAdditionalCostTypeID,
        DefaultIsOptionalForBuyer: productAdditionalCosts.value.DefaultIsOptionalForBuyer,
        Translations: translations,

      }
    }
    // this.productService.updateProductAdditionalCosts(requestData);
    this.showLoader = true;
    this.productHttpService.updateProductAdditionalCosts(requestData).subscribe((data) => {
      this.showLoader = false;
      if (data) {
        this.clearFormItems();
        this.getAllProductAdditionalCosts();
        this.updatestatusFun.emit(true);
        this.toast.success('Product additional cost Updated successfully');
        this.loadParentDropDown()
        // this.buildForm()
        // this.getAllProductAdditionalCosts()
      }
    },
      err => {
        this.showLoader = false;
        if (err.error.ModelState !== null && err.error.ModelState !== undefined) {
          if (err.error.ModelState.ProductAdditionalCosts !== null && err.error.ModelState.ProductAdditionalCosts !== undefined) {
            this.toast.error(err.error.ModelState.ProductAdditionalCosts);
          }
        }
      });
  }

  deleteAdditionalCosts(productAdditionalCosts) {

    // 
    // ProductAdditionalCostID


    let isToBeDeleted = true

    if (this.productAdditionalCostsForm instanceof Array) {
      isToBeDeleted = !(this.productAdditionalCostsForm.some(cost => {
        return cost.value.additionalCost === productAdditionalCosts.value.ProductAdditionalCostID
      }))
    }
    if (isToBeDeleted) {
      this.deleteaddCost = productAdditionalCosts
      this.deleteModal.show();
    } else {
      this.toast.error("This Option already Mapped. Cannot be Deleted")
    }



  }
  getDeleteStatus(status) {
    if (status) {
      this.toasterdelte = true
      const ProductAdditionalCostID = this.deleteaddCost.value.ProductAdditionalCostID;
      // this.productService.deleteProductAdditionalCosts(ProductAdditionalCostID);
      this.productHttpService.deleteProductAdditionalCosts(ProductAdditionalCostID).subscribe((data) => {
        if (data !== null) {
          this.clearFormItems();
          this.getAllProductAdditionalCosts();
          if (this.toasterdelte) {
            this.toast.success('Product additional cost deleted successfully');
            this.toasterdelte = false;
            this.loadParentDropDown()
          }
          this.loadParentDropDown()
        }
      });
      this.deleteModal.hide();
    }
    else {
      this.deleteModal.hide();
    }

  }

  closeModal() {
    this.showManageColor.emit(false);
  }


  showManageAdditionalCost(val, item?) {
    this.showLastEntry = item
    this.showManageAdditionalCostModal = val;
    if (val) {
      let data = { translations: true };
      this.productHttpService.getAllProductAdditionalCosts(data).subscribe((data: any) => {
        if (data) {
          this.additionalCosts = data;
          const selectedDataDetails = this.additionalCosts.find(data => data.ProductAdditionalCostID == item.value.ProductAdditionalCostID);
          this.ProductAdditionalCostOptions = selectedDataDetails !== undefined ? selectedDataDetails.ProductAdditionalCostOptions : [];
          this.selectedProductCost = item.value;
          this.selectedProductCost['ProductAdditionalCostOptions'] = this.ProductAdditionalCostOptions
          this.additionalCostOptionsList = [];
          let getlang = this.apiService.getWebshopLanguages(this.webshopDetail.CustomerID);

          if (this.ProductAdditionalCostOptions !== undefined && this.ProductAdditionalCostOptions.length > 0) {
            this.ProductAdditionalCostOptions.forEach(item => {
              this.translateOptionItems = [];
              getlang.forEach(lang => {
                let isFound = item.Translations.find(data => data.LanguageCode == lang.Code);
                let formJson;
                if (isFound) {
                  formJson = {
                    formcontrolname: 'AdditionalCostOptionName' + lang.Code,
                    translationID: 'TranslationID' + lang.Code,
                    objectID: 'ObjectID' + lang.Code,
                    class: lang.Name,
                    name: isFound.PropertyName,
                    LanguageID: lang.LanguageID,
                    LanguageCode: lang.Code,
                    PropertyName: isFound.PropertyName,
                    PropertyValue: isFound.PropertyValue,
                    TranslationID: isFound.TranslationID,
                    ObjectID: isFound.ObjectID
                  }
                } else {
                  formJson = {
                    formcontrolname: 'AdditionalCostOptionName' + lang.Code,
                    translationID: 'TranslationID' + lang.Code,
                    objectID: 'ObjectID' + lang.Code,
                    class: lang.Name,
                    name: '',
                    LanguageID: lang.LanguageID,
                    LanguageCode: lang.Code,
                    PropertyName: '',
                    PropertyValue: '',
                    TranslationID: '',
                    ObjectID: ''
                  }
                }
                this.translateOptionItems.push(formJson);
              });
              let options: any = {
                ProductAdditionalCostOptionID: item.ProductAdditionalCostOptionID,
                Price: item.Price
              };
              this.translateOptionItems.forEach(element => {
                options[element.formcontrolname] = element.PropertyValue;
                options[element.translationID] = element.TranslationID;
                options[element.objectID] = element.ObjectID;
              });
              this.additionalCostOptionsList.push(options);
            });
          }
          else {
            getlang.forEach(lang => {
              let formJson = {
                formcontrolname: 'AdditionalCostOptionName' + lang.Code,
                translationID: 'TranslationID' + lang.Code,
                objectID: 'ObjectID' + lang.Code,
                class: lang.Name,
                name: '',
                LanguageID: lang.LanguageID,
                LanguageCode: lang.Code,
                PropertyName: '',
                PropertyValue: '',
                TranslationID: '',
                ObjectID: ''
              }
              this.translateOptionItems.push(formJson);
            })
          }

          this.showAdditionalCostOptionModal = true
          this.ManageAdditionalCost.show();
        }
      });

    } else {
      this.ManageAdditionalCost.hide();
    }
  }

  getAdditionalOptionsList(optionsListValues) {
    let additionalOptions = [];
    let options;
    optionsListValues.forEach(data => {
      options = {
        ProductAdditionalCostOptionID: data.ProductAdditionalCostOptionID,
        Price: data.Price,
        Translations: []
      }
      let translations = [];
      this.translateOptionItems.forEach(element => {
        const translationsVal = {
          TranslationID: data[element.translationID],
          LanguageID: element.LanguageID,
          LanguageCode: element.LanguageCode,
          ObjectID: data[element.objectID],
          ObjectType: "ProductAdditionalCostOptions",
          PropertyName: "AdditionalCostOptionName",
          PropertyValue: data[element.formcontrolname]
        }
        translations.push(translationsVal);
      });
      options.Translations = translations;
      if (data.Action) {
        options['Action'] = data.Action;
      }
      additionalOptions.push(options);
    })
    const productCost = {
      ProductAdditionalCostID: this.selectedProductCost.ProductAdditionalCostID,
      SupplierID: this.selectedProductCost.SupplierID,
      DefaultCurrencyID: this.selectedProductCost.DefaultCurrencyID,
      DefaultProductAdditionalCostTypeID: this.selectedProductCost.DefaultProductAdditionalCostTypeID,
      DefaultIsOptionalForBuyer: this.selectedProductCost.DefaultIsOptionalForBuyer,
      ProductAdditionalCostOptions: additionalOptions,
      Action: "Update",
      Translations: []
    }
    let translations1 = [];
    this.translateOptionItems.forEach(element => {
      const translationsVal1 = {
        TranslationID: this.selectedProductCost[element.translationID],
        LanguageID: element.LanguageID,
        LanguageCode: element.LanguageCode,
        ObjectID: this.selectedProductCost[element.objectID],
        ObjectType: "ProductAdditionalCosts",
        PropertyName: "AdditionalCostName",
        PropertyValue: this.selectedProductCost['AdditionalCostName' + element.LanguageCode]
      }
      translations1.push(translationsVal1);
    });
    productCost.Translations = translations1;
    this.saveProductCost = productCost;
    // this.ManageAdditionalCost.hide();
    this.saveProductCostOptions();
  }

  saveProductCostOptions() {
    // this.productService.updateAllProductAdditionalCosts(this.saveProductCost);
    this.productHttpService.updateAllProductAdditionalCosts(this.saveProductCost).subscribe((data) => {
      if (data) {
        this.clearFormItems();
        this.getAllProductAdditionalCosts();
        this.setNewOptions.emit(true);
        this.ManageAdditionalCost.hide();
        this.toast.success('Product additional cost updated successfully');
      }
    });
  }
  loadParentDropDown() {
    this.loadAdditionalcostDropdown.emit()
  }

  numberOnly(event) {
    var x = event.which || event.keyCode;
    if (x == 45) {
      this.toast.error('Negative values are not accepted here')
      return false
    }
    else {
      return true
    }
  }

  setmaxlength(price, controlName, index?) {
    const priceLength = price.target.value.length;
    let priceValue = price.target.value;
    if (Number(priceLength) > Number(15)) {
      price.target.value = priceValue.slice(0, -1);
      if (index !== undefined) {
        this.items.controls[index]['value']['DefaultPrice'] = priceValue.slice(0, -1);
      }
    }
  }

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  textNoResults: string = '';

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text['Name'];
  }

  autoCompleteProductAdditionalCost(data) {
    if (data == "") {
      this.filterReset();
      return true;
    }

    const request = {
      name: data,
      SupplierID: this.productDetails.SupplierID,
      translations: true,
      selectedLangCode: this.selectedLangCode
    }

    this.groupCollection = [];
    this.productHttpService.productAdditionalCostAutoComplete(request).subscribe((res: any) => {
      if (res.length === 0) {
        this.textNoResults = 'No Results found';
      }

      if (res) {
        this.groupCollection = res;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 5)
      }
    })
  }

  selected(selected) {
    this.selectedAdditonalCost = selected;
    this.setPageSizeOptions(10);
  }

  onOptionSelected(event) {
    this.selectedAdditonalCost = event.text
  }

  filterReset() {
    this.searchText = '';
    this.selectedAdditonalCost.ProductAdditionalCostID = '';
    this.selectedAdditonalCost.SupplierID = '';
    this.textNoResults = '';
    this.groupCollection = [];
    this.getAllProductAdditionalCosts();
  }

  pageChanged(page) {
    this.selectedpage = page;
    this.getAllProductAdditionalCosts();
  }

  setPageSizeOptions(perpage) {
    this.selectedpage = 1;
    this.selectedperpage = perpage;
    this.groupCollection = [];
    this.getAllProductAdditionalCosts();
  }

  restrictAlphabets(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.,\b]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  commaToDot(event) {
    let t = event.target.value;
    if (event.target.value.includes(',')) {
      t = event.target.value.replace(',', '.');
    }
    event.target.value = t;
    // console.log(event.target.value);
  }

}
