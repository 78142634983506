import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; 
import { WebshopHttpService } from '../../state/services/webshop.http.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { Params } from '../currencyModal';

@Component({
  selector: 'link-admin-currencyadd',
  templateUrl: './currencyadd.component.html',
  styleUrls: ['./currencyadd.component.scss']
})
export class CurrencyaddComponent implements OnInit {
  currencyForm: FormGroup;
  action: string = "ADD";
  edit: boolean = false;
  editedValues: any = [];
  currencyId: string | number;
  isLoading: boolean = false;
  constructor(private formBuilder: FormBuilder,private http:WebshopHttpService, private toastr:ToastService, private router: Router) { }

  ngOnInit() {

    this.currencyForm = this.formBuilder.group({
      name: ['', Validators.required],
      code: [''],
      symbol: [''],
      isActive: [true],
  })

    if(history.state.editCurrency){
      this.editedValues = history.state.editCurrency;
      this.currencyId = this.editedValues.CurrencyID;
      this.action = "UPDATE";
      this.edit = true;
      this.currencyForm.patchValue({
        name:this.editedValues.Name,
        code:this.editedValues.Code,
        symbol:this.editedValues.Symbol,
        isActive: this.editedValues.active
      })
    }
  }

  addCurency() {
    // if(this.currencyForm.valid){
      if(!this.currencyForm.value.name){
        this.toastr.error("Please Enter Currency Name");
        return;
      }
      this.isLoading = true;
      let params:Params = {
        "Name": this.currencyForm.value.name,
        "Code": this.currencyForm.value.code,
        "Symbol": this.currencyForm.value.symbol ? this.currencyForm.value.symbol : "",
        "active": this.currencyForm.value.isActive ? 1 : 0
      }
      if(this.edit){
        params.CurrencyID = this.currencyId;
      }


      this.http.addCurrency(params).subscribe({
        next : (data:any) => {
          this.isLoading = false;
          if(data){
            this.router.navigate(['/currency'],{replaceUrl : true})
          }
        },error : (e:any) => {
          this.isLoading = false;
        }
      })

  }

  currencyActive(event) {
    this.currencyForm.value.isActive = event;
  }

}
