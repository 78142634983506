import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState, loginReducers } from './login.reducer';

export interface State {
    loginModule: LoginState;
}

export const reducers = loginReducers;

export const getLoginState = createFeatureSelector<LoginState>('loginModule');

export const getAllCustomers = createSelector(getLoginState,
    (state: LoginState) => state.customers);

export const getLoginSuccess = createSelector(getLoginState,
    (state: LoginState) => state.loginData);

export const getLoginFail = createSelector(getLoginState,
    (state: LoginState) => state.loginFail);

export const getIsLoggedIn = createSelector(getLoginState,
    (state: LoginState) => state.isLoggedIn);

export const getSelectedCustomer = createSelector(getLoginState,
    (state: LoginState) => state.selectedCustomer);

export const getLanguage = createSelector(getLoginState,
    (state: LoginState) => state.languages);

export const Language = createSelector(getLoginState,
    (state: LoginState) => state.lang);

export const getCustomerName = createSelector(getLoginState,
    (state: LoginState) => state.customerName);

export const getSubDomainName = createSelector(getLoginState,
    (state: LoginState) => state.subDomain);


