import { Component, OnInit, Output, OnChanges, EventEmitter, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductHttpService } from '../../state/services/product.http.service';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import * as _ from 'lodash';


@Component({
  selector: 'app-product-logopositionarea',
  templateUrl: './product-logopositionarea.component.html',
  styleUrls: ['./product-logopositionarea.component.scss']
})
export class ProductLogopositionareaComponent implements OnInit, OnChanges {
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @Output() sendLogoPosAreaData = new EventEmitter<any>();
  @Output('autoUpdate') autoUpdate = new EventEmitter<any>()
  optionsSelect: any[];
  logoposList: any;
  logoAreaList: any;
  productLogoPosition: any;
  productLogoArea: any;
  addLogoAreaData: any;
  addLogoPositionData: any;
  @Input() productDetails: any
  @Input() loadApi: any;
  resetLogoPositionModal: Boolean = false
  resetLogoAreaModal: Boolean = false
  showManageLogoPositionModal: boolean;
  selectedLogoArea: any = {};
  selectedArea: any = "";
  selectedLogoPosition: any = {}
  selectedPosition: any = "";
  newproductLogoPosition: any = [];
  deleteLogoPositionData: any = {};
  deleteType: any;
  deleteLogoAreaData: any;
  logoPositionLength: any = [];
  productLogoAreaLength: any = [];
  loadManageApi: any;
  loadApiFlag: Boolean = false
  showManageLogoAreaModal: Boolean = false;
  showManageLogoPositionAreaModal: Boolean = false;
  defaultLogoPosition: any;
  defaultLogoMaxArea: any;
  newproductLogoArea: any = [];
  logoPosiSelectedOnly: any[];
  selectedObjOnly: {};
  logoAreaSelectedOnly: any[];
  selectedAreaObjOnly: any
  searchKey: any;
  searchKeyArea: any;
  responsemessage: any;
  responsepositionmessage: any;
  newProductLogoPosition: any;
  newLogoPosiLen: any;
  newProductLogoArea: any;
  newLogoAreaLen: any;

  logoPosPopupList: any;
  logoPosDropdownData: any = {};
  logoPosPopupLabel: string = 'Select Logo Position';

  logoAreaPopupList: any;
  logoAreaDropdownData: any = {};
  logoAreaPopupLabel: string = 'Select Logo Area';

  constructor(public translate: TranslateService, public productService: ProductHttpService, public toast: ToastService) {
    this.loadManageApi = {
      logoAreas: false
    }
  }

  ngOnInit() {
    this.defaultLogoPosition = this.productDetails.DefaultLogoPositionID;
    this.defaultLogoMaxArea = this.productDetails.DefaultLogoMaxAreaID;
    this.productLogoPosition = this.productDetails.ProductLogoPositions;
    this.logoPositionLength = this.productLogoPosition;
    this.productLogoArea = this.productDetails.ProductLogoMaxAreas;
    this.productLogoAreaLength = this.productLogoArea;
    this.emitdata();


    this.logoPosPopupList = {
      apiurl: 'all/LogoPositionsWithPagination',
      supplierid: this.productDetails.SupplierID,
      key: 'LogoPositionID',
      displayKey: 'Description',
      label: 'Logo Position',
      autoComplete: {
        apiUrl: 'AutoCompleteLogoPositions',
        searchKey: 'description'
      }
    }

    this.logoAreaPopupList = {
      apiurl: 'all/LogoMaxAreasWithPagination',
      supplierid: this.productDetails.SupplierID,
      key: 'LogoMaxAreaID',
      displayKey: 'Description',
      label: 'Logo Area',
      autoComplete: {
        apiUrl: 'AutoCompleteLogoMaxAreas',
        searchKey: 'description'
      }
    }
  }

  ngOnChanges() {
    if (this.loadApi === true) {
      this.productLogoPosition = this.productDetails.ProductLogoPositions;
      this.newProductLogoPosition = this.productLogoPosition
      this.productLogoArea = this.productDetails.ProductLogoMaxAreas;
      this.newProductLogoArea = this.productLogoArea
      this.sendLogoPosAreaData.emit({ position: this.productLogoPosition, area: this.productLogoArea });
    }
    if (this.loadApi === true && this.loadApiFlag !== this.loadApi) {
      this.loadApiFlag = true
      this.getLogoPositionList();
    }
  }

  searchAreaList() {
    let getResult = [];
    const getData = this.productLogoAreaLength;
    getData.filter((data, index) => {
      let value = (data.Description).toLowerCase();
      let searchData = (this.searchKeyArea).toLowerCase();
      if (((value).includes(searchData))) {
        getResult.push(data)
      }
    });
    this.productLogoArea = _.uniq(getResult, 'Description');
    this.responsemessage = this.productLogoArea;
  }

  resetFilterArea() {
    this.searchKeyArea = '';
    this.productLogoArea = this.productLogoAreaLength;
    this.responsemessage = this.productLogoArea;
  }

  searchList() {
    let getResult = [];
    const getData = this.logoPositionLength;
    getData.filter((data, index) => {
      let value = (data.Description).toLowerCase();
      let searchData = (this.searchKey).toLowerCase();
      if (((value).includes(searchData))) {
        getResult.push(data)
      }
    });
    this.productLogoPosition = _.uniq(getResult, 'Description');
    this.responsepositionmessage = this.productLogoPosition;
  }


  resetFilter() {
    this.searchKey = '';
    this.productLogoPosition = this.logoPositionLength;
    this.responsepositionmessage = this.productLogoPosition;
  }

  resetLogoPosition(event) {
    this.resetLogoPositionModal = true
    setTimeout(() => {
      this.resetLogoPositionModal = false
    }, 1000)
  }

  resetLogoArea(event) {
    this.resetLogoAreaModal = true
    setTimeout(() => {
      this.resetLogoAreaModal = false
    }, 1000)
  }


  emitdata() {
    this.logoPosiSelectedOnly = []
    this.productLogoPosition.forEach(element => {
      element.label = element.Description;
      element.value = element.LogoPositionID;
      this.selectedObjOnly = {
        label: element.Description,
        value: element.LogoPositionID,
        action: element.Action
      }
      this.logoPosiSelectedOnly.push(this.selectedObjOnly)
    });
    this.productService.setLogoPositions(this.logoPosiSelectedOnly)
    const logoPositionLength = this.productLogoPosition.filter(getposition => getposition.Action !== 'Delete');
    this.newproductLogoPosition = logoPositionLength;
    this.logoAreaSelectedOnly = []
    this.productLogoArea.forEach(element => {
      element.label = element.Description;
      element.value = element.LogoMaxAreaID;
      this.selectedAreaObjOnly = {
        label: element.Description,
        value: element.LogoMaxAreaID,
        action: element.Action
      }
      if (this.selectedAreaObjOnly.action !== 'Delete') {
        this.logoAreaSelectedOnly.push(this.selectedAreaObjOnly)
      }
    })
    this.productService.setLogoArea(this.logoAreaSelectedOnly)
    this.sendLogoPosAreaData.emit({ position: this.productLogoPosition, area: this.productLogoArea })
  }

  getLogoPositionList() {
    const data = {
      supplierID: this.productDetails.SupplierID,
      translations: true
    };

    this.productService.getProductLogoList(data).subscribe((getdata: any) => {
      if (getdata) {
        const logoposList = [];
        getdata.forEach(element => {
          const getLogoPosition = element.Translations.filter(logoList => logoList.LanguageID === 1)
          const getLogoPositionObj = { label: getLogoPosition[0].PropertyValue, value: element.LogoPositionID, alldata: element }
          logoposList.push(getLogoPositionObj)
        });
        this.logoposList = logoposList;
      }
    })
    this.productService.getProductLogoArea(data).subscribe((getarea: any) => {
      if (getarea) {
        const getareaList = [];
        getarea.forEach(element => {
          const width = element.Width !== undefined ? 'Width:' + element.Width : '';
          const height = element.Height !== undefined ? ' Height:' + element.Height : '';
          const diameter = element.Diameter !== undefined ? ' Diameter:' + element.Diameter : '';
          const surface = element.Surface !== undefined ? ' Surface:' + element.Surface : '';
          const getareaListObj = {
            label: element.Description + ' (' + width + height + diameter + surface + ')',
            value: element.LogoMaxAreaID,
            alldata: element
          };
          getareaList.push(getareaListObj);
        });
        this.logoAreaList = getareaList;
      }
    })
  }

  selectedData(data, type) {
    if (type === 'logoarea') {
      this.selectedLogoArea = data;
      this.selectedArea = data.value
    }
    else if (type === 'logoposition') {
      this.selectedLogoPosition = data;
      this.selectedPosition = data.value
    }
  }

  checkAddLogoPosition() {
    if (this.selectedPosition) {
      return false
    }
    return true
  }

  addLogoPosition(event) {
    event.preventDefault();
    let pushLogoPosition = [];
    const pushselectedLogoPosition = {
      Action: "Insert",
      LogoPositionID: this.selectedLogoPosition.value,
      Description: this.selectedLogoPosition.label,
      value: this.selectedLogoPosition.value,
      label: this.selectedLogoPosition.label
    }
    this.newLogoPosiLen = this.newProductLogoPosition.filter(data => data.Description === pushselectedLogoPosition.Description)

    if (this.newLogoPosiLen.length === 0) {
      this.productLogoPosition = [...this.productLogoPosition, pushselectedLogoPosition];
      const logoPositionLength = this.productLogoPosition.filter(getposition => getposition.Action !== 'Delete');
      this.logoPositionLength = logoPositionLength;
      this.emitdata();
      this.selectedPosition = "";
      this.selectedLogoPosition = {};
      this.autoUpdate.emit()
    }
    else {
      this.toast.error("Can't add duplicate logo positions.");
      this.selectedPosition = "";
      this.selectedLogoPosition = {};
    }
  }

  checkAddLogoArea() {
    if (this.selectedArea) {
      return false
    }
    return true
  }

  addLogoArea(event) {
    event.preventDefault();
    const pushselectedLogoArea = {
      Action: "Insert",
      LogoMaxAreaID: this.selectedLogoArea.alldata.LogoMaxAreaID,
      Description: this.selectedLogoArea.alldata.Description,
      Width: this.selectedLogoArea.alldata.Width,
      Height: this.selectedLogoArea.alldata.Height,
      Diameter: this.selectedLogoArea.alldata.Diameter,
      Surface: this.selectedLogoArea.alldata.Surface,
    }
    // this.productLogoArea.push(pushselectedLogoArea);
    this.newLogoAreaLen = this.newProductLogoArea.filter(datas => datas.Description === pushselectedLogoArea.Description)
    if (this.newLogoAreaLen.length === 0) {
      this.productLogoArea = [...this.productLogoArea, pushselectedLogoArea];
      const productLogoAreaLength = this.productLogoArea.filter(getposition => getposition.Action !== 'Delete');
      this.productLogoAreaLength = productLogoAreaLength;
      this.emitdata();
      this.selectedArea = ""
      this.selectedLogoArea = {};
      this.autoUpdate.emit()
    }
    else {
      this.toast.error("Can't add duplicate logo areas.");
      this.selectedArea = "";
      this.selectedLogoArea = {};
    }
  }

  deleteLogoPosition(data, index) {
    this.deleteModal.show();
    this.deleteType = 'deleteLogoPosition';
    this.deleteLogoPositionData = {
      data: data,
      index: index
    }

  }

  getDeleteStatus(status) {
    if (status) {
      if (this.deleteType === 'deleteLogoPosition') {
        const data = this.deleteLogoPositionData.data;
        const index = this.deleteLogoPositionData.index;
        let deleteData = data;
        this.productLogoPosition.splice(index, 1);
        deleteData.Action = "Delete";
        this.productLogoPosition.push(deleteData);
        // this.productService.setProductTagSource(this.logoPosiSelectedOnly)
        this.toast.success('Deleted Successfully');
        this.autoUpdate.emit()
        this.deleteModal.hide();
        const logoPositionLength = this.productLogoPosition.filter(getposition => getposition.Action !== 'Delete');
        this.logoPositionLength = logoPositionLength;
        this.emitdata();
      }
      if (this.deleteType === 'deleteLogoArea') {
        const data = this.deleteLogoAreaData.data;
        const index = this.deleteLogoAreaData.index;
        let deleteData = data;
        this.productLogoArea.splice(index, 1);
        deleteData.Action = "Delete";
        this.productLogoArea.push(deleteData);
        this.toast.success('Deleted Successfully');
        this.autoUpdate.emit()
        this.deleteModal.hide();
        const productLogoAreaLength = this.productLogoArea.filter(getposition => getposition.Action !== 'Delete');
        this.productLogoAreaLength = productLogoAreaLength;
        this.emitdata();
      }
    }
    else {
      this.deleteModal.hide();
    }
  }

  deleteLogoArea(data, index) {
    this.deleteModal.show();
    this.deleteType = 'deleteLogoArea';
    this.deleteLogoAreaData = {
      data: data,
      index: index
    }

  }

  defaultLogoType(data) {
    this.productDetails.DefaultLogoPositionID = data.LogoPositionID;
  }

  defaultLogoArea(data) {
    this.productDetails.DefaultLogoMaxAreaID = data.value;
  }
  resetDropDown() {
    this.getLogoPositionList()
  }
  resetLogoareaDropDown() {
    this.getLogoPositionList()
  }


  getSelectedDropDown(data, type) {

    if (type === 'logoArea') {
      this.selectedLogoArea = {
        alldata: data,
        label: data.Description,
        value: data.LogoMaxAreaID
      }
      this.selectedArea = data.LogoMaxAreaID;
    }

    if (type === 'logoPosition') {
      this.selectedLogoPosition = {
        alldata: data,
        label: data.Description,
        value: data.LogoPositionID
      }
      this.selectedPosition = data.LogoPositionID;
    }

  }

  resetPopupDropdown(type: string) {

    if (type == 'logoPosition') {
      this.selectedLogoPosition = {};
      this.selectedPosition = "";
    }
    if (type == 'logoArea') {
      this.selectedLogoArea = {};
      this.selectedArea = "";
    }
  }

}


