import { Component, OnInit } from '@angular/core';
import { OrderHttpService } from '../../state/services/order.http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalService, MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { UnauthorisedModalComponent } from './unauthorised-modal/unauthorised-modal.component';

@Component({
  selector: 'rewardkart-admin-order-process-unauthorised-details',
  templateUrl: './order-process-unauthorised-details.component.html',
  styleUrls: ['./order-process-unauthorised-details.component.scss']
})
export class OrderProcessUnauthorisedDetailsComponent implements OnInit {
  orderId = '';
  customerID = '';
  orderData = null;
  loading: boolean = false;
  subOrderItemData = {};
  lineId = '';
  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-md modal-top', // for modal dialog
    animated: true
  };
  modalRef: MDBModalRef;
  returnPage: any;
  subOrderId: any;
  subOrder: any;
  webshopDetail: any;
  customerPickUpItem: boolean = false;
  haveInvoice: Boolean = false;
  returnListPage: Boolean = false;
  constructor(public modalService: MDBModalService,
    private orderHttpService: OrderHttpService,
    private route: ActivatedRoute,
    private toasterServcie: ToastService,
    private router: Router) {
    this.orderId = this.getUrlParam('orderId');
    const { customerID } = history.state;
    console.log('history.state', history.state);
    this.returnPage = 'unauthorised';
    const webshop = sessionStorage.getItem('webshopDetail');
    if (webshop !== null && webshop !== undefined && webshop !== '' && webshop !== 'undefined') {
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    }
  }

  ngOnInit() {
    this.loading = true;
    this.loadUnAuthorize();
  }

  loadUnAuthorize(type?) {
    this.orderHttpService.getUnauthorisedOrders(this.orderId, this.webshopDetail.CustomerID).subscribe(res => {
      this.orderData = res;
      if (type == 'delete') {
        if (this.orderData.OrderItems.length === 0) {
          this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
        }
      }
      for (var item of this.orderData.OrderItems) {
        if (item.IsPickupatWarehouse == true) {
          this.customerPickUpItem = true;
        }
        else {
          this.customerPickUpItem = false;
          break;
        }
      }
      this.loading = false;
    },
      (error) => {
        if (type == 'delete' && error.error == 'Order not Exist.') {
          this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
        }
      });
  }

  getUrlParam(paramValue): string {
    return this.route.snapshot.paramMap.get(paramValue);
  }

  confirmationDialog(eventData) {
    this.haveInvoice = eventData.haveInvoice;
    const { type } = eventData;
    switch (type) {
      case 'authorizedorderToInvoice':
        this.authorizedOrderToInvoice();
        break;
      case 'deleteunauthorisedOrder':
        this.deleteunauthorisedOrder();
        break;
      case 'recalculateTransportation':
        this.recalculateTransportation()
        break;
      case 'sendOrderConfirmation':
        this.sendOrderConfirmation();
        break;
      case 'authorize':
        this.authorize();
        break;
    }
  }

  authorizedOrderToInvoice() {
    const modalData = {
      type: 'authorizedorderToInvoice',
      header: `Authorised Order To Invoice`,
      content: `This order will be authorized and payment method will be changed to Invoice. 
      Are you sure?`,
      buttonlabel: this.haveInvoice ? 'OK' : 'PROCEED'
    };
    modalData['content'] = this.haveInvoice ? 'This order will be authorized and payment method will be changed to Invoice. Are you sure?' : 'User does not have invoice payment method available. Select invoice as payment method in user profile or click proceed to authorize the order with payment in advance';
    this.modalRef = this.modalService.show(UnauthorisedModalComponent, {
      ...this.modalOptions, data: modalData
    });

    this.modalService.closed.subscribe(result => {
      this.modalRef.hide();
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      const payload = { changePaymentToInvoice: true };

      this.orderHttpService.authorizedOrderToInvoice(this.orderId, payload).subscribe(response => {
        if (response) {
          this.toasterServcie.success('Authorized Succesfully');
          this.modalRef.hide();
          this.goBack();
        } else {
          this.toasterServcie.error('Authorized Failed');
        }
      }, error => {
        console.log(error)
        let errMsg = error.Message;
        if (!errMsg) {
          errMsg = error.error;
        }
        this.toasterServcie.error(errMsg);
      });
    });
  }

  deleteunauthorisedOrder() {
    const modalData = {
      type: 'deleteunauthorisedOrder',
      header: `Delete Unauthorised Order`,
      content: `Are you sure you want to delete this order?`
    };
    this.modalRef = this.modalService.show(UnauthorisedModalComponent, {
      ...this.modalOptions, data: modalData
    });

    this.modalService.closed.subscribe(result => {
      this.modalRef.hide();
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      const payload = { changePaymentToInvoice: true };

      this.orderHttpService.deleteunauthorisedOrder(this.orderId).subscribe((response: any) => {
        if (response) {
          this.toasterServcie.success(response);
          this.modalRef.hide();
          this.goBack();
        } else {
          this.toasterServcie.error('Delete Authorised Order Failed');
        }
      }, error => {
        this.toasterServcie.error(error.Message);
      });
    });
  }

  recalculateTransportation() {
    const modalData = {
      type: 'recalculateTransportation',
      header: `Recalculate Transportation`,
      content: `Are you sure you want to recalculate transportation costs for this order?`
    };
    this.modalRef = this.modalService.show(UnauthorisedModalComponent, {
      ...this.modalOptions, data: modalData
    });

    this.modalService.closed.subscribe(result => {
      this.modalRef.hide();
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      const payload = {};

      this.orderHttpService.recalculateTransportation(this.orderId, payload).subscribe(response => {
        if (response) {
          this.toasterServcie.success('Recalculate transportation costs Updated Successfully..!');
          this.modalRef.hide();
          this.goBack();
        } else {
          this.toasterServcie.error('Recalculate transportation costs Updated Failed!');
        }
      }, error => {
        this.toasterServcie.error(error.Message);
      });
    });
  }

  authorize() {
    const modalData = {
      type: 'authorize',
      header: `Authorize`,
      content: `Are you sure you want to authorize this order?`
    };
    this.modalRef = this.modalService.show(UnauthorisedModalComponent, {
      ...this.modalOptions, data: modalData
    });

    this.modalService.closed.subscribe(result => {
      this.modalRef.hide();
    });

    this.modalRef.content.modalAction.subscribe((result: any) => {
      const payload = {};

      this.orderHttpService.authorize(this.orderId, {}).subscribe(response => {
        if (response) {
          this.toasterServcie.success('Authorized this order Successfully..!');
          this.modalRef.hide();
          this.goBack();
        } else {
          this.toasterServcie.error('Authorized this order Failed!');
        }
      }, error => {
        this.loading = false;
        let errMsg = error.Message;
        if (error.error) {
          errMsg = error.error;
        }
        this.toasterServcie.error(errMsg);
      });
    });
  }

  sendOrderConfirmation() {
    this.orderHttpService.sendOrderConfirmation(this.orderId).subscribe(response => {
      if (response) {
        this.toasterServcie.success('Review email successfully sent to user');
      } else {
        this.toasterServcie.error('Email not sent. Please contact IT.');
      }
    }, error => {
      this.toasterServcie.error(error);
    });
  }

  goBack() {
    this.router.navigate([`/admin-order-process/false`], { replaceUrl: false });
  }

  getOrderDelete(event) {
    console.log(event);
    this.loadUnAuthorize('delete');
  }

}

