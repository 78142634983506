import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
import * as XLSX from 'xlsx';
import { UserHttpService } from "../state/services/user.http.service";
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { LoginHttpService } from 'src/app/login/state/services/login.http.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'rewardkart-admin-bulk-user-import',
    templateUrl: './user-import.component.html',
    styleUrls: ['./user-import.component.scss']
})

export class BulkUserImportComponent implements OnInit{
    @ViewChild('importSuccess', { static: true }) importSuccess: ModalDirective;
    @ViewChild('userModal', { static: true }) userModal: ModalDirective;
    @ViewChild('userError', { static: true }) userError: ModalDirective;
    @ViewChild('userDataPolicyModal', { static: true }) userDataPolicyModal: ModalDirective;
    @ViewChild('userFaqPolicyModal', { static: true }) userFaqPolicyModal: ModalDirective;
    @ViewChild('paymentModal', { static: true }) paymentModal: ModalDirective;
    showImageError: boolean = false;
    beneficiary: any = 'I';
    file: any;
    arrayBuffer: any;
    excelFile: any;
    exelLen: number;
    showLoader: boolean = false;
    selectedImg: any;
    uploadImage: any;
    imageName: any;
    userTerms: boolean;
    creationError: any;
    pbase: any;
    adduserData: any;
    transactionID: any;
    domainName: any;
    imgURL: any;
    path: any;
    paymentData: any;
    paymentTypes: any;
    payClicked: boolean = false;
    discountValue: any;
    paymentTypeID: any;
    faqdomainName: any;
  atomID: any;
  bankRefNo: any;
  paymentStatus: any;
  status: string;
  userData: any;
    constructor(private cookieService: CookieService, private loginHttpService: LoginHttpService, private toastr: ToastService,  private userHttpService: UserHttpService, private sanitizer: DomSanitizer){
      this.userData = JSON.parse(sessionStorage.getItem('userdetails'));  
      let domain = (location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[1];
        if (domain === 'rewardkartadmin') {
            this.pbase =  'https://rewardkartapi.in:8061/'
        }
        else if(domain === 'uk-constient'){
          this.pbase =  'https://uk-constient.com:8885/'
        }
        else{
            this.pbase = 'https://dotnet.constient.com/RewardKartPayWeb/';
        }
        if (sessionStorage.getItem('status')) {
          this.status = sessionStorage.getItem('status');
          if (sessionStorage.getItem('subscriptionid')) {
            this.transactionID = sessionStorage.getItem('subscriptionid');
            sessionStorage.removeItem('subscriptionid');
            sessionStorage.removeItem('status');
            if(sessionStorage.getItem('atomID')){
              this.atomID = sessionStorage.getItem('atomID');
              sessionStorage.removeItem('atomID');
            }
            if(sessionStorage.getItem('bankRefNo')){
              this.bankRefNo = sessionStorage.getItem('bankRefNo');
              sessionStorage.removeItem('bankRefNo');
            }
                //this.createUser()
            if(this.status === 'Y') {
              this.proceed('Y');
            }
          }  
        } 
    }

    ngOnInit(){
        this.path = 'https://fs.rewardkartapi.in:8050' +'/UserDocuments/';
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(this.path+'terms_and_conditions.pdf');
        setTimeout(()=>{
          if(this.status && this.status === 'N'){
            if(!this.atomID || !this.bankRefNo){
              this.proceed('PG')
            }
            else if(sessionStorage.getItem('message') && sessionStorage.getItem('message') === "CHALLAN GENERATED SUCCESSFULY"){
              sessionStorage.removeItem('message');
              this.proceed('PNR')
            }else{
              this.proceed('PF')
            }
          }
        }, 3000)
    }

    onImportUser(event){
        const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
        if ((getImg.toLowerCase() !== '.xlsx' && getImg.toLowerCase() !== '.xls' && getImg.toLowerCase() !== '.csv')) {
          this.showImageError = true;
          return;
        } else {
          this.showImageError = false;
          this.file = event.target.files[0];
          event.target.value = '';
          this.Upload();
        }
    }

    termsModal(){
      this.userTerms = !this.userTerms;
      this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(this.path+'91sb_terms_and_conditions.pdf');
      this.userDataPolicyModal.show();
    }

    faqModal(){
      this.faqdomainName = this.sanitizer.bypassSecurityTrustResourceUrl(this.path+'91sb_faqs.pdf');
      this.userFaqPolicyModal.show();
    }

    onSelectFile(event) {
        const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
        if ((getImg.toLowerCase() !== '.jpeg' && getImg.toLowerCase() !== '.jpg' && getImg.toLowerCase() !== '.png' && getImg.toLowerCase() !== '.gif')) {
          this.showImageError = true;
        } else {
          this.showImageError = false;
          if (event.target.files && event.target.files[0]) {
            this.showLoader = true;
            var filesAmount = event.target.files.length;
    
            for (let i = 0; i < filesAmount; i++) {
              var reader = new FileReader();
              reader.onload = (event: any) => {
                const imgurl = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
    
                this.selectedImg = event.target.result;
              }
    
              reader.readAsDataURL(event.target.files[i]);
              let httpHeaders = new HttpHeaders();
              httpHeaders.set('Content-Type', null);
              httpHeaders.set('Accept', "multipart/form-data");
              const formData = new FormData();
              formData.append('file', event.target.files[0]);
              formData.append('filePath', 'CampaignIcon');
              event.target.value = '';
              this.userHttpService.uploadFile(formData, '', '').subscribe((data: any) => {
                if (data) {
                  this.toastr.success('Image updated successfully!')
                  this.uploadImage = data[1];
                  this.imageName = data[1];
                  this.imgURL = data[2];
                }
                this.showLoader = false;
              }, error => {
                this.showLoader = false;
                this.toastr.error(error.error);
              })
            }
          }
        }
      }

    Upload() {
        let fileReader = new FileReader();
        fileReader.onload = (_e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
    
          this.excelFile = XLSX.utils.sheet_to_json(worksheet, { raw: true })

          if(this.excelFile.length > 0){
            this.exelLen = this.excelFile.length;
            this.importSuccess.show();
          }else{
            this.toastr.error('Please provide user data')
          }
        }
        fileReader.readAsArrayBuffer(this.file);
    }

    closeModal(){
        this.importSuccess.hide();
        this.userError.hide();
    }

    finishModal(){
      this.userModal.hide();
      this.payClicked = false;
      this.transactionID = '';
      this.imgURL = '';
      this.imageName = '';
      this.userTerms = false;
      this.excelFile = [];
      this.exelLen = 0;
    }

    userUpload(){
      if(this.excelFile.length > 0){
        let loggedInData = JSON.parse(sessionStorage.getItem("userdetails"));
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append("DomainName", loggedInData.DomainName);
        formData.append("CompanyName", loggedInData.CompanyName);
        formData.append("UserType", this.beneficiary);
        formData.append("CompanyLog", this.imageName);
        formData.append("UserId", loggedInData.UserID);
        this.showLoader = true;
        this.userHttpService.bulkUserCreation(formData).subscribe((data:any)=>{
          this.showLoader = false;
          if(data.IsSuccess){
            this.transactionID = data.TransId;
            this.excelFile[0].TransactionId = this.transactionID;
            //this.userModal.show();
            this.payClicked = true;
            this.paymentData = data;
            this.discountValue = (data.TotalAmount  * data.DiscountPercenate)/100;
            data.DiscountPercenate = data.DiscountPercenate.toFixed(2);
            this.discountValue = Math.round(this.discountValue);
          }
        }, error => {
          this.showLoader = false;
          this.toastr.error(error.error.Message);
        })

      }else{
        this.toastr.error('Please provide user data')
      }
    }

    clickPay(){
        if(!this.excelFile || this.excelFile.length < 1){
            this.toastr.error('Please import user data');
        }
        else if(!this.imageName){
            this.toastr.error('Please upload company or campaign logo');
        }
        else if(!this.userTerms){
            this.toastr.error('Please read and accept terms & conditions');
        }else{
           this.userUpload();
           this.paymentOptions();
        }
    }

    paymentOptions(){
      this.userHttpService.availablePaymentTypes().subscribe((data1: any) => {
          this.paymentTypes = data1;
          this.paymentTypeID = this.paymentTypes[0].ID;
      })
    }

    /*createUser(){
      let webshop = JSON.parse(sessionStorage.getItem("webshopDetail"));
      let formData = new FormData();
      formData.append("DomainName", webshop.DomainName);
      formData.append("TransActionId", this.transactionID);
      this.showLoader = true;
      this.userHttpService.bulkUserRwrdPt(formData).subscribe((res:any)=>{
          this.showLoader = false;
          this.payClicked = false;
          if(res.IsSuccess){
              if(res.creationError.length === 0 || res.creationError.length !== 0){
                  this.userModal.show();
              }else{
                  this.userError.show();
                  this.creationError = res.creationError;
              }
          }
        }, error => {
          this.showLoader = false;
          this.payClicked = false;
          this.toastr.error(error.error.Message);
        })
    }*/

    makePayment(){
      if(this.userData.DomainName === "91sb" || this.userData.DomainName === "91springboard"){
        this.userHttpService.user91sbPayment(this.transactionID, this.paymentTypeID).subscribe(_res =>{

        });
      } 
      if(this.userData.DomainName === "sw" || this.userData.DomainName === "smartworks"){
        this.userHttpService.userswPayment(this.transactionID, this.paymentTypeID).subscribe(_res =>{

        });
      }
      this.paymentModal.show();
    }
    goToPayemnt(){
      this.paymentModal.hide();
      this.showLoader = true;
      let logIndata = JSON.parse(sessionStorage.getItem("userdetails"));
      let params = {
        "UserId": logIndata.UserID.toString(),
        "Desc": "",
        "ExpairyDate": "",
        "CampaignId": 298,
        "IsActive": true,
        "Action": "Add",
        "IsPointAdd": true,
        "Email": logIndata.Email,
        "PhoneNumber": logIndata.PhoneNumber,
        "UserName": logIndata.UserName,
        "TransActionId": this.transactionID,
        "page": 'checkout'
      }               
      sessionStorage.setItem('swpay', JSON.stringify(params))
      sessionStorage.setItem('userlist', JSON.stringify(this.excelFile))
      
      this.loginHttpService.getUser().subscribe(userdata => {
        this.cookieService.set('payment', userdata.password);
      });
      this.showLoader = true;
      this.userHttpService.webTinyUrl().subscribe(dataweb =>{
        let query = "OrderId="+ this.transactionID +"&customerID="+logIndata.UserID+"&IsRewardKart=Y&Domain="+logIndata.DomainName+"&Pre=SB&ReturnURL="+dataweb+"&Email="+logIndata.Email+"&Mobile="+logIndata.PhoneNumber+"&campaignID=298";
        sessionStorage.setItem('subscriptionid', this.transactionID);
        const paymentURL = this.pbase + 'AtomPayment/AtomPaymentRequest?' + query;
        this.showLoader = false;
        window.location.href = paymentURL;   
      });
    }

    inrFormat(val: number) {
      return Number(val).toLocaleString("en-IN");
    }

    paymentSelect(i){
      this.paymentTypeID = i;
    }

    proceed(status){
      this.paymentStatus = status;
      let user = JSON.parse(sessionStorage.getItem('userdetails'))
      let params = {
        "TransId": this.transactionID,
        "PaymentStatus": status,
        "AtomTranId": this.atomID, 
        "BankRefNo": this.bankRefNo,
        "UserId": user.UserID
      }
      this.showLoader = true;
      if(this.userData.DomainName === "91sb" || this.userData.DomainName === "91springboard" || this.userData.DomainName === "aed"){
        this.userHttpService.update91sbPayment(params).subscribe(data =>{
            this.showLoader = false;
            if(data['IsSuccess']){
              //this.toastr.success(data['Message']);
              this.userModal.show();
            }else{
              this.toastr.error(data['Message']);
            }
        }, _error=>{
          this.showLoader = false;
        }); 
      }

      if(this.userData.DomainName === "sw" || this.userData.DomainName === "smartworks"){
        this.userHttpService.updateswPayment(params).subscribe(data =>{
          this.showLoader = false;
          if(data['IsSuccess']){
            //this.toastr.success(data['Message']);
            this.userModal.show();
          }else{
            this.toastr.error(data['Message']);
          }
        }, _error=>{
          this.showLoader = false;
        });
      }
    }
}