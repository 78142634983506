import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ModalDirective, MdbAutoCompleterDirective, MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { ProductHttpService } from '../../product/state/services/product.http.service';

@Component({
  selector: 'app-dropdownpopup',
  templateUrl: './dropdownpopup.component.html',
  styleUrls: ['./dropdownpopup.component.scss']
})
export class DropdownpopupComponent implements OnInit {
  @ViewChild('dropdownPopup', { static: true }) dropdownPopup: ModalDirective;
  @Input() label: any;
  @Input() popupList: any;
  @Output() selectedDropdown = new EventEmitter<any>();
  searchkey: any = '';
  autoCompleteData: any = [];
  listData: any = [];
  getSelectedOption: any = '';
  selectedpage: number = 1;
  selectedperpage: number = 10;
  pageList: any = [];
  totalCount: any;
  optionsSelect = [
    { value: '10', label: '10', selected: true },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
    { value: '40', label: '40' },
  ];

  groupCollection: any;
  autoCompleteList: any = {
    ProductSizeID: '',
    SupplierID: ''
  };

  selectedLangCode: string = 'en';
  selectedAutoCompleteData: any;
  displayKey: string;
  selectedDropdownValue: any = null;
  textNoResults: string = '';
  isLoading: Boolean = false;
  @Output() reset = new EventEmitter<any>();

  @ViewChild('auto', { static: false }) autoCompleter: MdbAutoCompleterComponent;

  @ViewChild(MdbAutoCompleterDirective, { static: true }) mdbAutoCompleter: MdbAutoCompleterDirective;

  @ViewChild('autoCompleteInput', { static: false }) autoCompleteInput: ElementRef;
  showInnerLoader: boolean = false;

  constructor(private producthttpservice: ProductHttpService) {


  }

  onDisplayValue = (option) => {
    const selectedItem: any = this.autoCompleter.getSelectedItem();
    return selectedItem.text[this.popupList.displayKey ? this.popupList.displayKey : 'Name'];
  }

  ngOnInit() {
    this.displayKey = this.popupList ? this.popupList.displayKey : 'Name';
    //this.autoCompleteData = [];
    // this.filterReset();

  }

  autoCompleteResult(event) {

    const requestData = {
      'supplierID': this.popupList.supplierid,
      'translations': true,
      'name': event,
    };
    this.producthttpservice.popupAutocomplete(requestData).subscribe((data) => {
      if (data !== null) {
        this.autoCompleteData = data;
      }
    });
  }

  selectedOption(option) {
    this.getSelectedOption = option[this.popupList.key];
  }

  searchReset() {
    this.searchkey = '';
    this.getSelectedOption = '';
    this.selectedpage = 1;
    this.selectedperpage = 10;
    this.getList();
  }

  getList(type?) {
    if (type === 'search') {
      this.selectedpage = 1;
      this.selectedperpage = 10;
    }
    setTimeout(() => {
      const request: { [key: string]: any } = {
        apiurl: this.popupList.apiurl,
        supplierID: this.popupList.supplierid,
        pageIndex: this.selectedpage,
        pageSize: this.selectedperpage,
        translations: true,
        id: this.getSelectedOption,
        customerID: this.popupList.customerID,
        name: this.getSelectedOption ? '' : this.searchkey
      }
      this.showInnerLoader = true;
      this.producthttpservice.getPopupList(request).subscribe((data: any) => {
        if (data) {
          this.listData = data;
          this.totalCount = data.TotalItemsCount
          let getPageList = this.listData.TotalItemsCount / this.selectedperpage;
          this.showInnerLoader = false;
          getPageList = Math.ceil(getPageList);
          this.pageList = Array.from({ length: getPageList }, (v, k) => k + 1);
          const pageLisArray = [];
          for (let i = 1; i <= getPageList; i++) {

            pageLisArray.push({ label: i, value: i })
          }
          this.pageList = pageLisArray;
        }

      })
    }, 500)
  }

  onOptionSelected(event) {
    this.getSelectedOption = event.text[this.popupList['key']]
    // this.searchDropDownList();
  }

  onOptionSelect(event) {
  }

  pageChanged(event) {
    this.selectedpage = event;
    this.getList();
  }

  perpage(pagesize) {
    this.selectedpage = 1;
    this.selectedperpage = pagesize;
    this.getList();
  }

  getselectedDropdown(event) {
    this.selectedDropdown.emit(event);
    this.dropdownPopup.hide();
    this.listData = [];
  }

  autoCompletePopupList(data) {
    this.isLoading = true;
    if (data == "") {
      this.filterReset();
      return true;
    }

    const apiUrl = this.selectedLangCode + '/' + this.popupList.autoComplete.apiUrl;
    const queryParams: { [key: string]: any } = {
      translations: true
    }

    if (this.popupList.autoComplete.supplierid !== undefined) {
      queryParams['supplierID'] = this.popupList.supplierid;
    }
    queryParams[this.popupList.autoComplete.searchKey] = data;

    if (this.popupList.customerID) {
      queryParams['customerID'] = this.popupList.customerID;
    }

    const searchRequest = {
      apiUrl,
      queryParams
    };

    this.autoCompleteData = [];
    this.producthttpservice.autoCompleteDropDownPopup(searchRequest).subscribe((data: any) => {
      this.isLoading = false;
      if (data.length === 0) {
        this.textNoResults = 'No Results Found';
        this.autoCompleteData = [];
      }
      if (data !== null) {
        this.autoCompleteData = data;
        setTimeout(_ => this.autoCompleter.highlightRow(0), 10)
      }
    },
      (error) => {
        this.isLoading = false;
      });
  }

  showPopup(event?: Event) {
    const inputEl = event.target as HTMLInputElement;
    if (!inputEl.value) {
      this.filterReset();
    }
    // this.selectedpage = 1;
    // this.selectedperpage = 10;
    this.textNoResults = '';
    if (!this.selectedDropdownValue) {
      this.getSelectedOption = '';
      this.searchkey = '';
      this.autoCompleteData = [];
      // this.getList();
    }
    this.getList();

    this.dropdownPopup.show();

  }

  selectedAutoComplete(selected) {
    this.getSelectedOption = selected[this.popupList.key];
  }

  searchDropDownList() {
    this.selectedpage = 1;
    this.selectedperpage = 10;
    this.getList();
  }

  filterReset() {
    this.searchkey = '';
    this.selectedpage = 1;
    this.selectedperpage = 10;
    this.selectedDropdownValue = '';
    this.getSelectedOption = '';
    this.textNoResults = '';
    this.autoCompleteData = [];
    this.getList();
  }

  searchProductSize() { }


}
