import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rewardkart-admin-view-orderitems-modal',
  templateUrl: './view-orderitems-modal.component.html',
  styleUrls: ['./view-orderitems-modal.component.scss']
})
export class ViewOrderitemsModalComponent implements OnInit {
  basicModal: any;
  constructor() { }

  ngOnInit() {
  }

}
