import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WebshopState } from '../reducers/webshop.reducer';
import { WebshopActions } from '../actions/index';
import { getWebshopListSuccess } from '../reducers/index';

@Injectable()
export class WebshopService {
    constructor(private store: Store<WebshopState>) { }

    loadWebshops() {
        this.store.dispatch(new WebshopActions.LoadWebshop());
    }

    getWebshopList() {
        return this.store.select(getWebshopListSuccess);
    }

}