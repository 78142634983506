import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState, userReducers } from './user.reducer';

export interface State {
    userModule: UserState;
}

export const reducers = userReducers;

export const getUserState = createFeatureSelector<UserState>('userModule');

export const getUserListSuccess = createSelector(getUserState,
    (state: UserState) => state.users);

export const getAllCountries = createSelector(getUserState,
    (state: UserState) => state.countries);

export const getAllCustomers = createSelector(getUserState,
    (state: UserState) => state.customers);

export const getAllRoles = createSelector(getUserState,
    (state: UserState) => state.roles);

export const getAllLanguages = createSelector(getUserState,
    (state: UserState) => state.language);

export const getAllCurrencies = createSelector(getUserState,
    (state: UserState) => state.currencies);

export const getDynamicFormFields = createSelector(getUserState,
    (state: UserState) => state.dynamicFormFields);

export const getdeleteUserSuccess = createSelector(getUserState,
    (state: UserState) => state.userID);

export const AddUserSuccess = createSelector(getUserState,
    (state: UserState) => state.addUser);

export const FileUploadSuccess = createSelector(getUserState,
    (state: UserState) => state.fileUpload)

export const UpdateUserSuccess = createSelector(getUserState,
    (state: UserState) => state.updateUser)

export const ValidateUserSuccess = createSelector(getUserState,
    (state: UserState) => state.validateuser)
